// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tasks-panel {
  padding: 30px;
  margin: 20px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: white;
  height: 90%;
  border-radius: 10px;
  overflow-y: auto;
}

.task-toolbar {
  margin-bottom: 50px;
  border: 3px solid gray;
  border-radius: 10px;
  padding: 10px;
}

.task-button {
  cursor: pointer;
  height: 40px;
  margin-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.task-button[color=red] {
  color: white;
  background-color: red;
}
.task-button.l-shift-10 {
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/service-tasks/service-tasks/service-tasks.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,+GAAA;EACA,uBAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AACF;AAAE;EACE,YAAA;EACA,qBAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".tasks-panel {\n  padding: 30px;\n  margin: 20px;\n  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);\n  background-color: white;\n  height: 90%;\n  border-radius: 10px;\n  overflow-y: auto;\n}\n\n.task-toolbar {\n  margin-bottom: 50px;\n  border: 3px solid gray;\n  border-radius: 10px;\n  padding: 10px;\n}\n\n.task-button {\n  cursor: pointer;\n  height: 40px;\n  margin-right: 20px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  &[color=red] {\n    color: white;\n    background-color: red;\n  }\n  &.l-shift-10 {\n    margin-left: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
