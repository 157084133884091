import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentDetailService {

  qActiveQuestionnaireTabKey = new BehaviorSubject<string>(null);
  qOnChangeCurrentShowParams = new Subject<boolean>();
  qShowDetailedResults = new Subject<boolean>();
  qOnChangeAcceptAnswers = new Subject<boolean>();
  qOnShowResults = new Subject<boolean>();
  showTaskResultSubject = new Subject<boolean>();

  constructor() { }
}
