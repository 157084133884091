import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {LoginService} from './login.service';
import {map, Observable, take, tap} from 'rxjs';

@Injectable()
export class CanActivateAuthGuard  {

  constructor(private router: Router,
              private loginService: LoginService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.loginService.getAuthenticatedUser()
      .pipe(
        take(1),
        map(it => !!it),
        tap(authenticated => {
          if (!authenticated) {
            this.loginService.lastUrl = state.url;
            this.router.navigate(['login']);
          }
        }),
/*
        filter(auth => !!auth),
        mergeMap(auth => {
          return this.loginService.client_id$_
            .pipe(
              take(1),
              map(it => !!it),
              tap(canActivate => {
                if (!canActivate) {
                  this.router.navigate(['go']);
                }
              })
            );
        })
*/
      );
  }
}
