import {ServiceTasksComponent} from '../service-tasks.component';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {firstValueFrom} from 'rxjs';
import {UPLOAD_TYPE} from '../../../core/upload.service';
import {ContentContainer} from '../../../model/content/ContentContainer';

const COLLECTIONS = {
  'contents': true
};

const getLocationCollection = (location: 'timeline' | 'modules') => {
  switch (location) {
    case 'timeline':
      return 'conference';
    case 'modules':
      return 'modules';
  }
};

export const jsonExport = async (thiz: ServiceTasksComponent, location: 'timeline' | 'modules',  eventId: string) => {
  const exportToJSON = async (clientId: string) => {
    let counter = 0;
    const sectionsObject = {};
    const sectionsDocs = await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection(getLocationCollection(location))
      .doc(eventId).collection('timeline').get()).then(snap => snap.docs);
    for (const doc of sectionsDocs) {
      const timelineObj = {...doc.data()};
      const collections = Object.keys(COLLECTIONS);
      for (const collection of collections) {
        const collectionDocs = await doc.ref.collection(collection).get();
        const collectionObj = {};
        for (const clDoc of collectionDocs.docs) {
          collectionObj[clDoc.id] = {...clDoc.data()};
        }
        if (!isEmpty(collectionObj)) {
          timelineObj[`collection-${collection}`] = collectionObj;
        }
      }
      console.log(`export ${++counter} of ${sectionsDocs.length}`);
      sectionsObject[doc.id] = timelineObj;
    }
    thiz.utils.downloadCsvReport(JSON.stringify(sectionsObject), `${location}-${eventId}.json`, 'application/json;encoding:utf-8');
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Export to JSON.', thiz.prevChoice))) {
    return;
  }
  thiz.prevChoice = clientIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    await exportToJSON(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('End process.');
};

export const jsonImport = async (thiz: ServiceTasksComponent, location: 'timeline' | 'modules',  eventId: string) => {
  const importFromJSON = async (clientId: string, json: string) => {
    const sections = JSON.parse(json);
    const count = Object.keys(sections).length;
    let counter = 0;
    for (const sectionId of Object.keys(sections)) {
      console.log(`added ${++counter} of ${count}`);
      const sectionObj = cloneDeep(sections[sectionId]);
      for (const collection of Object.keys(COLLECTIONS)) {
        delete sectionObj[`collection-${collection}`];
      }
      const tlDoc = await thiz.afs.collection('client_data').doc(clientId)
        .collection(getLocationCollection(location))
        .doc(eventId).collection('timeline')
        .doc(sectionId);
      tlDoc.set(sectionObj, {merge: false});
      for (const collection of Object.keys(COLLECTIONS)) {
        const collectionItems = cloneDeep(sections[sectionId]?.[`collection-${collection}`]);
        if (isEmpty(collectionItems)) {
          continue;
        }
        for (const clItemId of Object.keys(collectionItems)) {
          const clDoc = tlDoc.collection(collection).doc(clItemId);
          clDoc.set(collectionItems[clItemId], {merge: false});
        }
      }
    }
  };

  const afterUpload = async (json: string) => {
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await importFromJSON(clientId, json);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Import from JSON', thiz.prevChoice)) || clientIds.length > 1) {
    return;
  }
  thiz.prevChoice = clientIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});
  if (!(await thiz.commonService.confirmation(
    `<div style="color: red; font-weight: bold;">All data will be deleted and overwritten with new values!!!</div>
     <div>Are you sure you want to create an import?</div>`))) {
    return;
  }
  thiz.uppy = thiz.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM,
    {restrictions: {maxNumberOfFiles: 1, allowedFileTypes: ['.json']}},
    (result) => {
      let json: string = result[0].response;
      json = atob(json.substring(json.indexOf(';base64,') + ';base64,'.length));
      thiz.uploadService.closeModal(thiz.uppy);
      afterUpload(json);
    });
  thiz.uploadService.openUploadWindow(thiz.uppy);
};

export const jsonContentExport = async (thiz: ServiceTasksComponent, location: 'timeline' | 'modules',
                                        eventId: string, sectionId: string, contentId: string) => {
  const exportToJSON = async (clientId: string) => {
    const contentDoc = await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection(getLocationCollection(location)).doc(eventId)
      .collection('timeline').doc(sectionId)
      .collection('contents').doc(contentId)
      .get());
    if (contentDoc.exists) {
      thiz.utils.downloadCsvReport(JSON.stringify(new ContentContainer({id: contentDoc.id, ...contentDoc.data()})),
        `${location}-content-${contentId}.json`, 'application/json;encoding:utf-8');
    }
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Export content to JSON.', thiz.prevChoice))) {
    return;
  }
  thiz.prevChoice = clientIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    await exportToJSON(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('End process.');
};
