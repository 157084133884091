import {Injectable} from '@angular/core';
import {LoginService} from '../login/login.service';
import {CommonService} from './common.service';
import {ClientConfig} from '../model/ClientConfig';

export const HIDDEN_FEATURES = ['enableDebug', 'hideSelfLearningUsersDetailDefaultValue'];

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {

  constructor(private loginService: LoginService,
              private common: CommonService) {}

  getFeatureValue(feature: string, clientConfig?: ClientConfig) {
    const envFeatures = this.common.utils.getEnv()?.features ?? {};
    const clientFeatures = (clientConfig ?? this.loginService.clientConfig$.getValue())?.features ?? {};
    return clientFeatures.hasOwnProperty(feature) ? clientFeatures[feature] : envFeatures[feature];
  }

  isSlotModeEnabled() {
    return !!this.getFeatureValue('enableSlotMode');
  }

  isImportSlotsEnabled() {
    return !!this.getFeatureValue('importSlots');
  }

  isMyKnowledgeEnabled() {
    return !!this.getFeatureValue('myKnowledge');
  }

  isQuestionCardsEnabled() {
    return !!this.getFeatureValue('questionCards');
  }

  isExamsEnabled() {
    return !!this.getFeatureValue('exams');
  }

  isUseEventApiEnabled() {
    return !!this.getFeatureValue('useEventApi');
  }
}
