import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {take} from 'rxjs';
import {TEXT_AI_OPTION_LIST} from '../../../../../../../services/ai-text.service';
import {QuizQuestionSheetComponent} from '../../../quiz-question-sheet/quiz-question-sheet.component';
import {Constants} from '../../../../../../../core/constants';
import {IDisabledAiAction, QUESTION_TYPES_COMPONENTS} from '../quiz-quiestion-types';
import {cloneDeep, isEmpty} from 'lodash';

/**
 * generate subjectId to exchange messages within the questionnaire,
 * so that each questionnaire in the content processes only its own messages.
 */
export const getCurrentSubjectId = (quizQuestionSheet: QuizQuestionSheetComponent) => {
  const contentId = quizQuestionSheet.documentPathParams.contentId;
  const containerId = quizQuestionSheet.documentPathParams.containerId;
  return `${contentId}.${containerId}.${quizQuestionSheet.currentQKeySignal()}`;
};

export const textBalloonsAIAction = (
  aiTextOption: TEXT_AI_OPTION_LIST,
  quizQuestionSheet: QuizQuestionSheetComponent
) => {
  const subjectId = getCurrentSubjectId(quizQuestionSheet);
  const currentQKey = quizQuestionSheet.currentQKeySignal();

  const answers = quizQuestionSheet.answersSummary().questions[currentQKey]
    ?.map(s => JSON.parse(s))?.map(a => a?.['a']);
  if (isEmpty(answers)) {
    return;
  }
  quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: aiTextOption});

  const sendAnswers = (res: string, deleteAiText?: boolean) => {
    if ((res === null || res === undefined) && !deleteAiText) {
      quizQuestionSheet.common.log.error('Incorrect AI functions');
      return;
    }
    const answer = new AnswersQuestion(
      currentQKey,
      {
        a: !deleteAiText ? JSON.stringify({ [aiTextOption]: res }) : null,
        c: 'aiGen',
      },
      quizQuestionSheet.questionList[
        currentQKey
        ].timelineId
    );

    quizQuestionSheet.answers$.next(cloneDeep(answer));
    const sendTime = new Date().getTime();
    const question = quizQuestionSheet.questionList[currentQKey];
    quizQuestionSheet.dataService.sendQuizQuestionAnswer(quizQuestionSheet.documentPathParams, quizQuestionSheet.currentUserId,
      answer.questionId, question.storypoint,
      QUESTION_TYPES_COMPONENTS[question.storypoint].questionAnswersSummaryMethodName(question),
      QUESTION_TYPES_COMPONENTS[question.storypoint].questionAnswersSummarySaveType(question),
      QUESTION_TYPES_COMPONENTS[question.storypoint].directFeedbackCheck,
      answer.answers, false, quizQuestionSheet.currentUserProperties, sendTime, answer.properties, quizQuestionSheet.contentLocation)
      .finally(() => quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: null}));
  };

  if (aiTextOption.startsWith('delete')) {
    return deleteAiAnswer(quizQuestionSheet)
      .finally(() => quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: null}));
  }

  switch (aiTextOption) {
    case TEXT_AI_OPTION_LIST.TEXT_SUMMARIZE:
      const caption = quizQuestionSheet.questionList[currentQKey].getCaptionByLanguage(quizQuestionSheet.languageParams$.getValue());
      return quizQuestionSheet.aiTextService
        .useAIForTest(Constants.AI_TEXT_FUNCTION_SUMMARIZE_ANSWERS, '-', caption, answers.join(', '))
        .pipe(take(1))
        .subscribe((res) => sendAnswers(res?.data));
    case TEXT_AI_OPTION_LIST.CREATE_LYRICS:
      return quizQuestionSheet.aiTextService
        .createLyrics(answers.join(', '))
        .pipe(take(1))
        .subscribe((res) => sendAnswers(res?.data));
    default:
      return quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: null});
  }
};

export const openTextAIAction = (
  aiTextOption: TEXT_AI_OPTION_LIST,
  quizQuestionSheet: QuizQuestionSheetComponent
) => {
  const subjectId = getCurrentSubjectId(quizQuestionSheet);
  const currentQKey = quizQuestionSheet.currentQKeySignal();
  const answers = quizQuestionSheet.answersSummary().questions[currentQKey]
    ?.map(s => JSON.parse(s))?.map(a => a?.['a'])
    ?.reduce((acc: string[], it) => {
      acc.push(...it.map(v => v.a));
      return acc;
    }, []);
  if (isEmpty(answers)) {
    return;
  }
  quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: aiTextOption});

  const sendAnswers = (res: string, deleteAiText?: boolean) => {
    if ((res === null || res === undefined) && !deleteAiText) {
      quizQuestionSheet.common.log.error('Incorrect AI functions');
      return;
    }
    const answer = new AnswersQuestion(
      currentQKey,
      {a: [{a: !deleteAiText ? JSON.stringify({ [aiTextOption]: res }) : null, c: 'aiGen'}]},
      quizQuestionSheet.questionList[currentQKey].timelineId
    );

    quizQuestionSheet.answers$.next(cloneDeep(answer));
    const sendTime = new Date().getTime();
    const question = quizQuestionSheet.questionList[currentQKey];
    quizQuestionSheet.dataService.sendQuizQuestionAnswer(quizQuestionSheet.documentPathParams, quizQuestionSheet.currentUserId,
      answer.questionId, question.storypoint,
      QUESTION_TYPES_COMPONENTS[question.storypoint].questionAnswersSummaryMethodName(question),
      QUESTION_TYPES_COMPONENTS[question.storypoint].questionAnswersSummarySaveType(question),
      QUESTION_TYPES_COMPONENTS[question.storypoint].directFeedbackCheck,
      answer.answers, false, quizQuestionSheet.currentUserProperties, sendTime, answer.properties, quizQuestionSheet.contentLocation)
      .finally(() => quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: null}));
  };

  if (aiTextOption.startsWith('delete')) {
    return deleteAiAnswer(quizQuestionSheet)
      .finally(() => quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: null}));
  }

  switch (aiTextOption) {
    case TEXT_AI_OPTION_LIST.TEXT_SUMMARIZE:
      const caption = quizQuestionSheet.questionList[currentQKey].getCaptionByLanguage(quizQuestionSheet.languageParams$.getValue());
      return quizQuestionSheet.aiTextService
        .useAIForTest(Constants.AI_TEXT_FUNCTION_SUMMARIZE_ANSWERS, '-', caption, answers.join(', '))
        .pipe(take(1))
        .subscribe((res) => sendAnswers(res?.data));
    case TEXT_AI_OPTION_LIST.CREATE_LYRICS:
      return quizQuestionSheet.aiTextService
        .createLyrics(answers.join(', '))
        .pipe(take(1))
        .subscribe((res) => sendAnswers(res?.data));
    default:
      return quizQuestionSheet.quizService.aiGenerated$.next({[subjectId]: null});
  }
};

const deleteAiAnswer = (quizQS: QuizQuestionSheetComponent) => {
  const questionId = quizQS.currentQKeySignal();
  const question = quizQS.qContent.questions[questionId];
  const storypoint = quizQS.qContent.questions[questionId].storypoint;
  return quizQS.dataService.deleteQuizUserAnswers(quizQS.currentUserId, quizQS.documentPathParams,
    {[questionId]: {
        methodName: QUESTION_TYPES_COMPONENTS[storypoint].questionAnswersSummaryMethodName(question),
        saveType: QUESTION_TYPES_COMPONENTS[storypoint].questionAnswersSummarySaveType(question)
      }});
};

export const textQuestionDisabledAIActions = (answers): IDisabledAiAction => {
  return {
    disableAiGen: isEmpty(answers),
    // todo: There is currently no handler for this action. So it is always disable.
    // Some condition from the initial development
    // isEmpty(answers) || new Set(answers.map(a => a?.['c'])).size === answers.map(a => a?.['c']).length
    [TEXT_AI_OPTION_LIST.GROUP_BY_COLOR]: true,
  };
};
