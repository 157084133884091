import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  transform(value: any, args?: any[]): any {
    if (args && args[0] === 'keys') {
      return Object.keys(value);
    } else {
      return Object.keys(value).map(function (e) {
        return value[e];
      });
    }
  }

}
