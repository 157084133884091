import {Event} from './Event';
import {Constants, DATE_FORMAT, EVENT_DATE_MODE} from '../core/constants';
import {UtilsService} from '../core/utils.service';

enum ANYTIME_TYPES {
  ANYTIME = 1200,
  ANYTIME_IN_TIME_RANGE = 1300
}

export class ExtEvent extends Event {
  private get TIMER_PERIOD() {return 1800000; }

  constructor(private utils: UtilsService, obj) {
    super(obj);
  }

  private get currentTime() {
    return this.utils.getCurrentTime();
  }

  private get eventStartDateStr() {
    return this.utils.formatDate(this.startDate, DATE_FORMAT.DD_MM_YYYY_HH_mm);
  }

  private get eventEndDateStr() {
    return this.utils.formatDate(this.endDate, DATE_FORMAT.DD_MM_YYYY_HH_mm);
  }

  private get eventPrepPhaseStartDate() {
      return new Date(this.prepPhaseStart);
  }

  private get eventPrepPhaseStartDateStr() {
    return this.utils.formatDate(this.eventPrepPhaseStartDate, DATE_FORMAT.DD_MM_YYYY_HH_mm);
  }

  private get eventWrapUpPhaseEndDate() {
    return new Date(this.wrapUpPhaseEnd);
  }

  private get eventWrapUpPhaseEndDateStr() {
    return this.utils.formatDate(this.eventWrapUpPhaseEndDate, DATE_FORMAT.DD_MM_YYYY_HH_mm);
  }

  public get eventDuration() {
    return this.duration ? this.duration * Constants.ONE_MINUTE_MS : 0;
  }

  private get countdownStartDate() {
    const countdown = new Date(this.startDate.getTime() - this.currentTime);
    return this.utils.formatDate(countdown, DATE_FORMAT.mm_ss);
  }

  private get countdownFinishDate() {
    const countdown = new Date(this.startDate.getTime() + this.eventDuration - this.currentTime);
    return this.utils.formatDate(countdown, DATE_FORMAT.mm_ss);
  }

  get draftStr() {
    return this.published ? '' : this.utils.i18n(Constants.EVENT_STATUS_DRAFT).toUpperCase();
  }

  get currentStatusStr() {
    if (!this.published) {
      return this.utils.i18n(Constants.EVENT_STATUS_DRAFT);
    } else {
      const status = this.getEventStatus();
      return status[status.length - 1];
    }
  }

  eventStatusDisplayMenu() {
    const status = this.getEventStatus();
    return this.published &&
      (
      (status[0] === Constants.EVENT_STATUS_PREP_START_ON ||
        status[0] === Constants.EVENT_STATUS_RUNNING ||
        status[0] === Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN ||
        status[0] === Constants.EVENT_STATUS_WRAP_UP_UNTIL) ||
      (!this.prepPhaseStart &&
        (this.startDate.getTime() - Constants.ONE_HOUR <= this.currentTime && this.currentTime < this.startDate.getTime()))
      ||
      (!this.wrapUpPhaseEnd &&
        (this.startDate.getTime() < this.currentTime &&
          this.currentTime <= this.startDate.getTime() + this.eventDuration + Constants.ONE_HOUR))
      );
  }

  get isEventStatusRunning(): boolean {
    const status = this.getEventStatus();
    return this.published &&
      (
        status[0] === Constants.EVENT_STATUS_PREP_START_ON ||
        status[0] === Constants.EVENT_STATUS_RUNNING ||
        status[0] === Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN ||
        status[0] === Constants.EVENT_STATUS_WRAP_UP_UNTIL ||
        status[0] === Constants.EVENT_STATUS_EVENT_STARTS_IN
      );
  }

  get statusRunning() {
    const status = this.getEventStatus();
    return this.published && ((this.dateMode !== EVENT_DATE_MODE.ANYTIME &&
      (
        status[0] === Constants.EVENT_STATUS_RUNNING ||
        status[0] === Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN
      )) || this.dateMode === EVENT_DATE_MODE.ANYTIME);
  }

  get statusPrep(): boolean {
    const status = this.getEventStatus();
    return this.published &&
      (
        status[0] === Constants.EVENT_STATUS_PREP_START_ON ||
        status[0] === Constants.EVENT_STATUS_PREP_OPENS_ON
      );
  }

  get statusWrapUp(): boolean {
    const status = this.getEventStatus();
    return this.published &&
      (
        status[0] === Constants.EVENT_STATUS_WRAP_UP_UNTIL
      );
  }

  get statusFinish(): boolean {
    const status = this.getEventStatus();
    return this.published &&
      (
        status[0] === Constants.EVENT_STATUS_FINISHED
      );
  }

  getEventStatus(ctime?: number): string[] {
    ctime = ctime ? ctime : this.utils.getSystemCurrentTime();
    if (this.dateMode === EVENT_DATE_MODE.ANYTIME) {
      return [Constants.EVENT_STATUS_RUNNING, this.utils.i18n(Constants.EVENT_STATUS_RUNNING)];
    }
    if (this.startDate && this.eventDuration === 0 && this.startDate.getTime() === this.endDate &&
      (!this.prepPhaseStart || this.prepPhaseStart === 0) &&
      (!this.wrapUpPhaseEnd || this.wrapUpPhaseEnd === 0)) {
      const cDate = new Date();
      const currentDate = String(cDate.getFullYear()) +
        String(cDate.getMonth()).padStart(2, '0') +
        String(cDate.getDate()).padStart(2, '0');
      const eventDate = String(this.startDate.getFullYear()) +
        String(this.startDate.getMonth()).padStart(2, '0') +
        String(this.startDate.getDate()).padStart(2, '0');
      if (currentDate === eventDate) {
        return [Constants.EVENT_STATUS_RUNNING, this.utils.i18n(Constants.EVENT_STATUS_RUNNING)];
      } else {
        return [Constants.EVENT_STATUS_FINISHED, this.utils.i18n(Constants.EVENT_STATUS_FINISHED)];
      }
    }
    if (this.startDate && this.prepPhaseStart && this.prepPhaseStart > 0 && ctime < this.prepPhaseStart) {
      return [Constants.EVENT_STATUS_PREP_OPENS_ON, this.utils.i18n(Constants.EVENT_STATUS_PREP_OPENS_ON),
        this.eventPrepPhaseStartDateStr, this.utils.i18n(Constants.EVENT_STATUS_PREP_OPENS_ON) + this.eventPrepPhaseStartDateStr];
    }
    if (this.startDate && this.prepPhaseStart && this.prepPhaseStart > 0 &&
      this.prepPhaseStart < ctime && ctime < this.startDate.getTime() - this.TIMER_PERIOD) {
      return this.eventStartInformationString(Constants.EVENT_STATUS_PREP_START_ON);
    }
    if (this.startDate && (!this.prepPhaseStart || this.prepPhaseStart === 0) &&
        ctime < this.startDate.getTime() - this.TIMER_PERIOD) {
      return this.eventStartInformationString(Constants.EVENT_STATUS_EVENT_STARTS_ON);
    }
    if (this.startDate && this.startDate.getTime() - this.TIMER_PERIOD < ctime && ctime < this.startDate.getTime()) {
      return this.eventStartInformationString(Constants.EVENT_STATUS_EVENT_STARTS_IN); // countdown
    }
    if ((this.startDate && this.startDate.getTime() < ctime && ctime < this.startDate.getTime() + this.eventDuration - this.TIMER_PERIOD) ||
         this.eventDuration === 0) {
      return [Constants.EVENT_STATUS_RUNNING, this.utils.i18n(Constants.EVENT_STATUS_RUNNING)];
    }
    if (this.startDate && this.startDate.getTime() + this.eventDuration - this.TIMER_PERIOD < ctime &&
       ctime < this.startDate.getTime() + this.eventDuration) {
      return [Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN, this.countdownFinishDate,
        this.utils.i18n(Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN + '.1'),
        this.utils.i18n(Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN + '.2'),
        this.utils.i18n(Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN + '.1') +
        this.utils.i18n(Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN + '.2') + this.countdownFinishDate]; // countdown
    }
    if (this.startDate && (!this.wrapUpPhaseEnd || this.wrapUpPhaseEnd === 0) &&
      this.startDate.getTime() + this.eventDuration < ctime) {
      return [Constants.EVENT_STATUS_FINISHED, this.utils.i18n(Constants.EVENT_STATUS_FINISHED)];
    }
    if (this.startDate && this.wrapUpPhaseEnd && this.wrapUpPhaseEnd !== 0 &&
      this.startDate.getTime() + this.eventDuration < ctime &&
         ctime < (this.eventWrapUpPhaseEndDate ? this.eventWrapUpPhaseEndDate.getTime() : this.startDate.getTime() + this.eventDuration)) {
      return [Constants.EVENT_STATUS_WRAP_UP_UNTIL, this.eventWrapUpPhaseEndDateStr,
        this.utils.i18n(Constants.EVENT_STATUS_WRAP_UP_UNTIL + '.1'),
        this.utils.i18n(Constants.EVENT_STATUS_WRAP_UP_UNTIL + '.2'),
        this.utils.i18n(Constants.EVENT_STATUS_WRAP_UP_UNTIL + '.1') +
        this.utils.i18n(Constants.EVENT_STATUS_WRAP_UP_UNTIL + '.2') + this.eventWrapUpPhaseEndDateStr];
    }
    if (this.startDate && this.wrapUpPhaseEnd && this.wrapUpPhaseEnd !== 0 &&
      (this.eventWrapUpPhaseEndDate ? this.eventWrapUpPhaseEndDate.getTime() : this.startDate.getTime() + this.eventDuration) < ctime) {
      return [Constants.EVENT_STATUS_FINISHED, this.utils.i18n(Constants.EVENT_STATUS_FINISHED)];
    }

    return ['', '', '', '', ''];
  }

  eventStartInformationString(status) {
    if (status === Constants.EVENT_STATUS_EVENT_STARTS_ON && this.dateMode === EVENT_DATE_MODE.SPECIFIC_DATE_TIME) {
      return [Constants.EVENT_STATUS_EVENT_STARTS_ON, this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_ON),
        this.eventStartDateStr, this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_ON) + this.eventStartDateStr];
    }
    if (status === Constants.EVENT_STATUS_EVENT_STARTS_IN && this.dateMode === EVENT_DATE_MODE.SPECIFIC_DATE_TIME) {
      return [Constants.EVENT_STATUS_EVENT_STARTS_IN, this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN),
        this.countdownStartDate, this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN + '.1'),
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN) + this.countdownStartDate +
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN + '.1')];
    }
    if (status === Constants.EVENT_STATUS_EVENT_STARTS_ON && this.dateMode === EVENT_DATE_MODE.ANYTIME) {
      return [Constants.EVENT_STATUS_EVENT_STARTS_ON, this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_ON),
        this.eventStartDateStr, this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_ON) + this.eventStartDateStr];
    }
    if (status === Constants.EVENT_STATUS_EVENT_STARTS_ON && this.dateMode === EVENT_DATE_MODE.PUBLISHED_WITH_TIME_RANGE) {
      return [Constants.EVENT_STATUS_EVENT_STARTS_ON,
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_FROM, {date: this.eventStartDateStr}),
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_TO, {date: this.eventEndDateStr}),
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_FROM, {date: this.eventStartDateStr}) +
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_TO, {date: this.eventEndDateStr})];
    }
    if (status === Constants.EVENT_STATUS_EVENT_STARTS_IN && this.dateMode === EVENT_DATE_MODE.ANYTIME) {
      return [Constants.EVENT_STATUS_EVENT_STARTS_IN, this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_IN),
        this.countdownStartDate, this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN + '.1'),
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_IN) + this.countdownStartDate +
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN + '.1')];
    }
    if (status === Constants.EVENT_STATUS_EVENT_STARTS_IN && this.dateMode === EVENT_DATE_MODE.PUBLISHED_WITH_TIME_RANGE) {
      return [Constants.EVENT_STATUS_EVENT_STARTS_IN, this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_IN),
        this.countdownStartDate, this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN + '.1'),
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_IN) + this.countdownStartDate +
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_STARTS_IN + '.1')];
    }
    if (status === Constants.EVENT_STATUS_PREP_START_ON && this.dateMode === EVENT_DATE_MODE.SPECIFIC_DATE_TIME) {
      return [Constants.EVENT_STATUS_PREP_START_ON, this.eventStartDateStr,
        this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.1'), this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.2'),
        this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.1') + this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.2') +
        this.eventStartDateStr];
    }
    if (status === Constants.EVENT_STATUS_PREP_START_ON && this.dateMode === EVENT_DATE_MODE.ANYTIME) {
      return [Constants.EVENT_STATUS_PREP_START_ON, this.eventStartDateStr,
        this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.1'), this.utils.i18n(Constants.EVENT_STATUS_PREP_OPEN_ON + '.2'),
        this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.1') + ', ' + this.utils.i18n(Constants.EVENT_STATUS_PREP_OPEN_ON + '.2') +
        this.eventStartDateStr];
    }
    if (status === Constants.EVENT_STATUS_PREP_START_ON && this.dateMode === EVENT_DATE_MODE.PUBLISHED_WITH_TIME_RANGE) {
      return [Constants.EVENT_STATUS_PREP_START_ON, this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_TO, {date: this.eventEndDateStr}),
        this.utils.i18n(Constants.EVENT_STATUS_PREP_START_ON + '.1'),
        this.utils.i18n(Constants.EVENT_STATUS_PREP_OPEN_FROM + '.2', {date: this.eventStartDateStr}),
        this.utils.i18n(Constants.EVENT_STATUS_PREP_OPEN_ON + '.1', {date: this.eventStartDateStr}) +
        this.utils.i18n(Constants.EVENT_STATUS_PREP_OPEN_FROM + '.2', {date: this.eventEndDateStr}) +
        this.utils.i18n(Constants.EVENT_STATUS_EVENT_OPEN_TO, {date: this.eventEndDateStr})];
    }
  }
}
