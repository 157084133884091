import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as ui from '../../../../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {StdComponent} from '../../../../../core/std-component';
import {Constants, TRIPLE} from '../../../../../core/constants';
import {CommonService} from '../../../../../core/common.service';
import {UtilsService} from '../../../../../core/utils.service';

@Component({
  selector: 'app-html-editor-dialog',
  templateUrl: './html-editor-dialog.component.html',
  styleUrls: ['./html-editor-dialog.component.scss']
})
export class HtmlEditorDialogComponent extends StdComponent implements OnInit, OnDestroy {

  private _html: string;
  dataHash: string;
  isModify = false;


  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<HtmlEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<fromRoot.State>,
              private common: CommonService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this._html = data.html;
    this.dataHash = UtilsService.md5(data.html);
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get html(): string {
    return this._html;
  }

  set html(value: string) {
    this._html = value;
    this.isModify = this.dataHash !== UtilsService.md5(value);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onOkClick(): void {
    this.dialogRef.close(this.html);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
