import {AbstractContent} from './AbstractContent';
import {Constants} from '../../core/constants';
import {DependencyQuestionnaire, IDependencyAnswers} from './DependencyQuestionnaire';
import {isEmpty} from 'lodash';
import {IBodyConfig, IPdfConfig} from '../../components/html-text-editor/html-text-editor-config/config';

export class ModularContent extends AbstractContent {
  showTitleInTabOnly: boolean;
  description: string;
  type = 'modular';
  items: Module[] = [];
  dependencyQuestionnaire: DependencyQuestionnaire;
  // version control system for modules
  releaseVersion: string;
  dirty: boolean;

  constructor(obj?: any) {
    super(obj);
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.items) {
      this.items = obj.items.map(o => new Module(o));
    }
    if (obj.dependencyQuestionnaire) {
      this.dependencyQuestionnaire = new DependencyQuestionnaire(obj.dependencyQuestionnaire);
    }
    if (obj.hasOwnProperty('showTitleInTabOnly')) {
      this.showTitleInTabOnly = obj.showTitleInTabOnly;
    }
    if (obj.hasOwnProperty('releaseVersion')) {
      this.releaseVersion = obj.releaseVersion;
    }
    if (obj.hasOwnProperty('dirty')) {
      this.dirty = obj.dirty;
    }
  }

  mergeContent(src: ModularContent) {
    const vm = this;
    super.mergeContent(src);
    if (this.description !== src.description) {
      this.description = src.description;
    }
    if (this.items !== src.items) {
      this.items = src.items.map(obj => new Module(obj));
    }
    if (this.dependencyQuestionnaire !== src.dependencyQuestionnaire) {
      this.dependencyQuestionnaire = new DependencyQuestionnaire(src.dependencyQuestionnaire);
    }
    if (this.showTitleInTabOnly !== src.showTitleInTabOnly) {
      this.showTitleInTabOnly = src.showTitleInTabOnly;
    }
    if (this.releaseVersion !== src.releaseVersion) {
      this.releaseVersion = src.releaseVersion;
    }
    if (this.dirty !== src.dirty) {
      this.dirty = src.dirty;
    }
  }

  private comparator(field, direction?) {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {
        return 1;
      }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {
        return -1;
      }
      if (!direction || direction === 'asc') {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
      } else if (direction === 'desc') {
        if (a[field] > b[field]) {
          return -1;
        }
        if (a[field] < b[field]) {
          return 1;
        }
      }
      return 0;
    };
  }

  isItemTypeText(index) {
    return this.items && this.items[index] && this.items[index].type === Constants.MODULE_TYPE_TEXT;
  }

  isItemTypeImage(index) {
    return this.items && this.items[index] && this.items[index].type === Constants.MODULE_TYPE_IMAGE;
  }

  getItemBodyConverterVer(index) {
    if (this.items && this.items[index]) {
      if ([Constants.MODULE_TYPE_TEXT, Constants.MODULE_TYPE_HTML].includes(this.items[index].type)) {
        return this.items[index].moduleContent;
      } else if (this.items[index].type === Constants.MODULE_TYPE_IMAGE) {
        return JSON.parse(this.items[index].moduleContent)[0].src;
      }
    } else {
      return null;
    }
  }

  setItemBodyConverterVer(index, value) {
    if (this.items && this.items[index]) {
      if (this.items[index].type === Constants.MODULE_TYPE_TEXT) {
        this.items[index].moduleContent = value;
      } else if (this.items[index].type === Constants.MODULE_TYPE_IMAGE) {
        const uploadValue = value && value.indexOf('data:image/png;base64,') !== -1 ?
          Constants.NEED_REUPLOAD : Constants.NEED_RELOAD;
        this.items[index].moduleContent = JSON.stringify([{id: this.id, src: value, upload: uploadValue}]);
      }
    }
  }

  getItemBodyByType(index): string | [] | {}[] {
    if (this.items && this.items[index]) {
      if ([Constants.MODULE_TYPE_TEXT, Constants.MODULE_TYPE_HTML].includes(this.items[index].type)) {
        return this.items[index].moduleContent;
      } else if ([Constants.MODULE_TYPE_IMAGE, Constants.MODULE_TYPE_HIGHLIGHT].includes(this.items[index].type)) {
        try {
          return this.items[index].moduleContent ? JSON.parse(this.items[index].moduleContent) : [];
        } catch (e) {
          return [];
        }
      }
    } else {
      return null;
    }
  }

  setItemBodyByType(index, value: string | [] | {}[]) {
    if ([Constants.MODULE_TYPE_TEXT, Constants.MODULE_TYPE_HTML].includes(this.items[index].type)) {
      this.items[index].moduleContent = value;
    } else if ([Constants.MODULE_TYPE_IMAGE, Constants.MODULE_TYPE_HIGHLIGHT].includes(this.items[index].type)) {
      this.items[index].moduleContent = JSON.stringify(value ? value : '[]');
    }
  }

  getItems() {
    return this.items ? this.items : [];
  }

  getDependencyVisibility(dependencyAnswers: IDependencyAnswers) {
    if (this.dependencyQuestionnaire && this.dependencyQuestionnaire.questionnaireId) {
      const questionnaireId = this.dependencyQuestionnaire.contentId ?
        `${this.dependencyQuestionnaire.contentId}-${this.dependencyQuestionnaire.questionnaireId}` :
        `${this.dependencyQuestionnaire.questionnaireId}`;
      const answers = !isEmpty(dependencyAnswers) &&
      !isEmpty(dependencyAnswers[questionnaireId]) &&
      !isEmpty(dependencyAnswers[questionnaireId][this.dependencyQuestionnaire.questionId]) ?
        dependencyAnswers[questionnaireId][this.dependencyQuestionnaire.questionId] : {};
      if (this.dependencyQuestionnaire.dependencyConditionShowIf) {
        return this.dependencyQuestionnaire.dependencyResult(answers);
      } else
      if (this.dependencyQuestionnaire.dependencyConditionHideIf) {
        return !this.dependencyQuestionnaire.dependencyResult(answers);
      }
    }
    return true;
  }

  toObject() {
    const obj = super.toObject();
    if (obj.items) {
      obj.items = obj.items.map(it => it.toObject());
    } else {
      obj.items = [];
    }
    if (obj.dependencyQuestionnaire && obj.dependencyQuestionnaire instanceof DependencyQuestionnaire) {
      obj.dependencyQuestionnaire = obj.dependencyQuestionnaire.toObject();
    }
    return obj;
  }

}

export class Module {
  id: string = this.genId();
  eventId: string;
  parentId: string;
  orderIndex = 0;
  title: string;
  subtitle: string;
  type: string; // MODULE_TYPE_TEXT | MODULE_TYPE_HTML | MODULE_TYPE_HIGHLIGHT | MODULE_TYPE_IMAGE | MODULE_TYPE_PDF
  displayWidth: number;
  /*
  moduleContent =
     object is type of:
       MODULE_TYPE_TEXT, MODULE_TYPE_HTML = simple string,
     JSON.stringify object is type of:
       MODULE_TYPE_HIGHLIGHT = array ['title', 'description'],
       MODULE_TYPE_IMAGE = array of objects [{id, src, upload}]
         upload = -1 | 0 | 1
           -1: prepared for delete from store on update content
            0: prepared for upload to store on insert/update content
            1: object uploaded to store, do not need change on insert/update content
           -2, -3: use only for convert from old clipboard content format
   */
  moduleContent;
  autoPlay: number; // autoplay in milliseconds for type = MODULE_TYPE_IMAGE, if value 0 autoplay is off.
  /*
     module status, need for insert/update content. not for save into DB.
     moduleStatus = -1 | 0 | 1
           -1: delete. if module type image, module content data can be delete before delete module.
            0: new. if module type image, module content data can be upload before add module.
            1: saved in DB. if module type image, module content data can be upload/delete before update module.
   */
  pdfConfig: IPdfConfig;
  bodyConfig: IBodyConfig;
  moduleStatus = {
    value: null,
    set: function (value: number) {
      this.value = value;
    },
    get: function(): number {
      return this.value;
    },
    toJSON: function() {
      return undefined;
    }
  };

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.eventId) {
      this.eventId = obj.eventId;
    }
    if (obj.orderIndex) {
      this.orderIndex = obj.orderIndex;
    }
    if (obj.type) {
      this.type = obj.type;
    }
    if (obj.moduleContent) {
      this.moduleContent = obj.moduleContent;
    }
    if (obj.parentId) {
      this.parentId = obj.parentId;
    }
    if (obj.autoPlay) {
      this.autoPlay = obj.autoPlay;
    }
    if (obj.title) {
      this.title = obj.title;
    }
    if (obj.subtitle) {
      this.subtitle = obj.subtitle;
    }
    if (obj.displayWidth) {
      this.displayWidth = obj.displayWidth;
    }
    if (obj.moduleStatus && obj.moduleStatus.get() !== null) {
      this.moduleStatus.set(obj.moduleStatus.get());
    }
    if (obj.pdfConfig) {
      this.pdfConfig = obj.pdfConfig;
    }
    if (obj.bodyConfig) {
      this.bodyConfig = obj.bodyConfig;
    }
  }

  private genId(): string {
    return 'M' + (new Date()).getTime().toString();
  }

  getModuleContent(): string {
    return this.moduleContent ? this.moduleContent :
      (this.type === Constants.MODULE_TYPE_TEXT || this.type === Constants.MODULE_TYPE_HTML ? '' : '[]');
  }

  getModuleType() {
    return this.type ? this.type : Constants.MODULE_TYPE_TEXT;
  }

  getModuleAutoPlay() {
    return this.autoPlay ? this.autoPlay : 0;
  }

  getModuleTitle() {
    return this.title;
  }

  getModuleSubtitle() {
    return this.subtitle;
  }

  getDisplayWidth() {
    return this.displayWidth === undefined || this.displayWidth === null ? 100 : this.displayWidth;
  }

  getModulePdfConfig() {
    return this.pdfConfig;
  }

  getModuleBodyConfig() {
    return this.bodyConfig ? this.bodyConfig : {};
  }

  toObject() {
    const obj = {...this};
    Object.keys(obj).forEach(key => obj[key] === undefined && (obj[key] = null));
    Object.keys(obj).forEach(key => typeof obj[key] === 'function' && (delete obj[key]));
    // todo what is? can i delete it here?
    if (obj.moduleStatus) {
      delete obj.moduleStatus;
    }

    return obj;
  }
}
