import {ILanguageParams, TCaption} from '../../../../../core/constants';
import {UtilsService} from '../../../../../core/utils.service';

export class THighlight {
  id: number; // equals index in array
  title: TCaption = null;
  description: TCaption = null;

  constructor(obj: {id: number, title?: TCaption, description?: TCaption}) {
    this.id = obj.id;
    this.title = obj.title;
    this.description = obj.description;
  }

  getTitleByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'title', languageParams);
  }

  setTitleByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'title', value, languageParams);
  }

  getDescriptionByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'description', languageParams);
  }

  setDescriptionByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'description', value, languageParams);
  }

  toObject() {
    return {...this};
  }
}

export class THighlightTableRow extends THighlight {
  constructor(object, public languageParams: ILanguageParams) {
    super(object);
  }

  get rowTitle() {
    return this.getTitleByLanguage(this.languageParams);
  }

  set rowTitle(value: string) {
    this.setTitleByLanguage(value, this.languageParams);
  }

  get rowDescription() {
    return this.getDescriptionByLanguage(this.languageParams);
  }

  set rowDescription(value: string) {
    this.setDescriptionByLanguage(value, this.languageParams);
  }

  simplify() {
    return new THighlight({id: this.id, title: this.title, description: this.description});
  }
}


export const DISPLAY_COLUMNS = ['rowTitle', 'rowDescription'];

export enum DIRECTION {
  MOVE_UP = -1,
  MOVE_DOWN = 1
}
