export class Page {
  url: string = null;
  name: string = null;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.url) {
      this.url = obj.url;
    }
    if (obj.name) {
      this.name = obj.name;
    }
  }
}
