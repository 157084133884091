import {AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import {PdfViewerComponent} from '../../../../../components/pdf-viewer/pdf-viewer.component';
import {CommonService} from '../../../../../core/common.service';
import {cloneDeep, merge} from 'lodash';
import {BehaviorSubject, filter, firstValueFrom, Subject} from 'rxjs';
import {Constants} from '../../../../../core/constants';

interface IPdfParams {
  pdfTotalPages: number;
  pdfCurrentPage: number;
  pdfCurrentZoom: number;
  pdfPages: string;
  pdfRotation: number;
  pdfAllPage: boolean;
  pdfFitToScreen: boolean;
}

type IPdfFollowMeParams = Pick<IPdfParams, 'pdfCurrentZoom' | 'pdfRotation' | 'pdfCurrentPage'>;

const defParams: IPdfParams = {
  pdfTotalPages: 0,
  pdfCurrentPage: 1,
  pdfCurrentZoom: 1,
  pdfPages: '',
  pdfRotation: 0,
  pdfAllPage: false,
  pdfFitToScreen: false
};

enum PDF_COMMAND {
  NEXT = 'next',
  PREV = 'prev',
  ZOOM_OUT = 'zoom-out',
  ZOOM_IN = 'zoom-in',
  ZOOM_RESET = 'zoom-reset',
  ROTATE = 'rotate',
  DOWNLOAD = 'download'
}

@Component({
  selector: 'app-pdf-document',
  templateUrl: './pdf-document.component.html',
  styleUrls: ['./pdf-document.component.scss']
})
export class PdfDocumentComponent extends AbstractContainerComponent implements OnInit, AfterViewInit {

  readonly defParams = defParams;
  readonly PDF_COMMAND = PDF_COMMAND;

  private uppy: any;
  private closeUploaderResult$ = new Subject<boolean>();
  params: IPdfParams = cloneDeep(defParams);
  detectChanges$ = new BehaviorSubject<number>(0);
  srcUrl: string;

  private resizeObserver = new ResizeObserver(entries => {
    const entry = entries[0];
    if (entry && this.pdfComponent && this.pdfComponent.pdfViewer && this.pdfComponent.pdfViewer && this.params.pdfTotalPages > 0) {
      this.pdfComponent.updateSize();
    }
  });

  @ViewChild(PdfViewerComponent, {static: false}) pdfComponent: PdfViewerComponent;

  constructor(protected injector: Injector,
              private common: CommonService,
              private uploadService: UploadService,
              private elementRef: ElementRef) {
    super(injector);
    this.detectChanges$.next(new Date().getTime());
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.PDF, null, result => {
      this.params = cloneDeep(defParams);
      const prefix = this.common.utils.generateRandomString(4).toLowerCase();
      this.data = {
        id: `${result[0].id.replace(/\//g, '-').replace('uppy', prefix)}.${result[0].extension}`,
        srcUrl: this.srcUrl,
        src: result[0].response
      };
      this.closeUploaderResult$.next(true);
      this.uploadService.closeModal(this.uppy);
    },
     () => this.closeUploaderResult$.next(false));
  }

  ngOnInit(): void {
    this.data$.pipe(
      filter(() => !this.srcUrl),
      this.takeUntilAlive())
      .subscribe(value => {
        if (value?.id?.indexOf(';base64,') === -1) {
          this.srcUrl = value?.src;
        }
      });
  }

  protected inputFollowMeData(value: IPdfFollowMeParams) {
    this.params = merge(this.params, value);
    this.params.pdfPages = this.params.pdfCurrentPage + '/' + this.params.pdfTotalPages;
    this.detectChanges$.next(new Date().getTime());
  }

  onEdit() {
    this.uploadService.openUploadWindow(this.uppy);
    const uppyDashboards = document.getElementsByClassName('uppy-Dashboard');
    for (const elem of Array.from(uppyDashboards)) {
      (elem as HTMLElement).onkeydown = (ev: KeyboardEvent) => {
        if (ev.code === Constants.ESCAPE) {
          ev.stopPropagation();
          this.uploadService.closeModal(this.uppy);
          this.closeUploaderResult$.next(false);
        }
      };
    }
    return firstValueFrom(this.closeUploaderResult$);
  }

  pdfLoadComplete(pdfData: any) {
    this.params.pdfTotalPages = pdfData.numPages;
    this.params.pdfPages = 1 + '/' + this.params.pdfTotalPages;
    this.params.pdfCurrentPage = 1;
    this.params.pdfCurrentZoom = 1;
    this.pdfComponent.updateSize();
  }

  private getFollowMeConfig(): IPdfFollowMeParams {
    return {
      pdfCurrentZoom: this.params.pdfCurrentZoom,
      pdfCurrentPage: this.params.pdfCurrentPage,
      pdfRotation: this.params.pdfRotation,
    };
  }

  onNext(): boolean {
    if (this.params.pdfCurrentPage === this.params.pdfTotalPages) {
      return false;
    }
    this.execPDFCommand(PDF_COMMAND.NEXT);
    return true;
  }

  onPrev(): boolean {
    if (this.params.pdfCurrentPage === 1) {
      return false;
    }
    this.execPDFCommand(PDF_COMMAND.PREV);
    return true;
  }

  onDestroy() {
    super.onDestroy();
    this.resizeObserver.disconnect();
    this.uppy.close();
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.elementRef.nativeElement.parentElement);
  }

  execPDFCommand(command: PDF_COMMAND) {
    switch (command) {
      case PDF_COMMAND.NEXT:
        this.params.pdfCurrentPage = this.params.pdfCurrentPage < this.params.pdfTotalPages ?
          (this.params.pdfCurrentPage + 1) : this.params.pdfTotalPages;
        this.params.pdfPages = this.params.pdfCurrentPage + '/' + this.params.pdfTotalPages;
        break;
      case PDF_COMMAND.PREV:
        this.params.pdfCurrentPage = this.params.pdfCurrentPage > 1 ?
          (this.params.pdfCurrentPage - 1) : 1;
        this.params.pdfPages = this.params.pdfCurrentPage + '/' + this.params.pdfTotalPages;
        break;
      case PDF_COMMAND.ZOOM_IN:
        this.params.pdfCurrentZoom = this.params.pdfCurrentZoom < 3 ?
          (this.params.pdfCurrentZoom + 0.1) : 3;
        break;
      case PDF_COMMAND.ZOOM_OUT:
        this.params.pdfCurrentZoom = this.params.pdfCurrentZoom > 0.1 ?
          (this.params.pdfCurrentZoom - 0.1) : 0.1;
        break;
      case PDF_COMMAND.ZOOM_RESET:
        this.params.pdfCurrentZoom = 1;
        break;
      case PDF_COMMAND.ROTATE:
        this.params.pdfRotation = this.params.pdfRotation < 270 ?
          this.params.pdfRotation + 90 : 0;
        break;
      case PDF_COMMAND.DOWNLOAD:
        this.common.utils.simpleDownloadFile(this.data.src);
        return;
    }
    this.outputFollowMeData.emit(this.getFollowMeConfig());
  }
}
