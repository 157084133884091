import {Injectable} from '@angular/core';
import {UtilsService} from '../core/utils.service';
import {clone, merge} from 'lodash';
import {LoggerService} from '../core/logger.service';

const clientName = 'gapi';

@Injectable()
export class GoogleAPIService {

  private gapi: any;
  private loadAPI: Promise<any>;
  public customParams: any = {};
  private libs = [];
  private apiEndPoint;
  googleAuth: gapi.auth2.GoogleAuth;

  constructor(private utils: UtilsService,
              private logger: LoggerService
  ) {
    this.apiEndPoint = this.utils.getEnv().BACKEND_BASE + '/_ah/api';
/*
    this.libs = [
      {name: 'userApi', version: 'v2', url: this.apiEndPoint}
      , {name: 'eventApi', version: 'v2', url: this.apiEndPoint}
      , {name: 'eventModeApi', version: 'v2', url: this.apiEndPoint}
      , {name: 'notificationApi', version: 'v1', url: this.apiEndPoint}
      , {name: 'hkvbsApi', version: 'v1', url: this.apiEndPoint}
    ];
*/
  }

  public getLoadAPI(forceLogin = false) {
    if (!this.loadAPI || forceLogin) {
      this.loadAPI = new Promise((resolve, reject) => {
        this.gapi = window[clientName];
        const service = this;
        const envParams = this.utils.getEnv().gapi;
        const gapiParams = {
          client_id: envParams.client_id
          , scope: envParams.scopes.join(' ')
        };

        service.logger.debug('gapi.load "client" START...');
        service.gapi.load('client', function () {
          service.logger.debug('gapi.load "client" OK');
          const params: any = clone(gapiParams);
          params.login_hint = (<any>service.customParams).login_hint;
          if (!forceLogin) {
            // params.prompt = 'login';
            // params.prompt = 'select_account';
            params.prompt = 'none';
          }

          const auth = function () {
              // Loads the OAuth and other APIs asynchronously, and triggers login
              // when they have completed.
              let apisToLoad;
              const callback = function () {
                if (--apisToLoad === 0) {
                  service.logger.debug('gapi.client.load ALL SUCCESS');
                  resolve(window[clientName]);
                }
              };
              apisToLoad = service.libs.length;
              service.libs.forEach(it => {
                service.logger.debug('gapi.client.load START... "' + it.name + ':' + it.version + '"', it.url);
                service.gapi.client.load(it.name, it.version, function () {
                  service.logger.debug('gapi.client.load OK "' + it.name + ':' + it.version + '"', it.url);
                  callback();
                }, it.url);
              });
          };
          auth();
        });
      });
    }
    return this.loadAPI;
  }

  public GetClient(forceLogin = false): any {
    return this.getLoadAPI(forceLogin).then((gapi) => {
      return gapi;
    });
  }

  loadGapiClient() {
    return gapi.client ? Promise.resolve() : new Promise((resolve, reject) => {
      gapi.load('client:auth2', resolve);
    });
  }

  initApi(apiName: string, options: {client_id?: string, apiKey?: string, DISCOVERY_DOCS: string[], scopes: string}) {
    const CLIENT_ID = options.client_id || this.utils.getEnv().gapi.client_id;
    const API_KEY = options.apiKey || this.utils.getEnv().gapi.apiKey;
    const DISCOVERY_DOCS = options.DISCOVERY_DOCS;
    const SCOPES = options.scopes;

    return this.loadGapiClient()
      .then(() => {
        return gapi.client[apiName] ? Promise.resolve() : gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then(() => {
          this.googleAuth = gapi.auth2.getAuthInstance();
        }, (err) => {
          this.logger.error(err);
        });
      });
  }

  getCurrentUser() {
    return this.googleAuth ? this.googleAuth.currentUser.get() : null;
  }

  grantScopes(scopes: string[]) {
    const SCOPES = scopes.join(' ');
    if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
      const googleUser = this.googleAuth.currentUser.get();
      if (!googleUser) {
        return Promise.reject();
      }
      if (googleUser && !scopes.every(it => googleUser.hasGrantedScopes(it))) {
        const optionsBuilder = new gapi.auth2.SigninOptionsBuilder();
        optionsBuilder.setScope(SCOPES);
        return googleUser.grant(optionsBuilder);
      } else {
        return Promise.resolve();
      }
    } else {
      return this.googleAuth.signIn({
        prompt: 'consent',
        scope: SCOPES
      }).then(() => {
        this.googleAuth = gapi.auth2.getAuthInstance();
      });
    }

  }

  signOut() {
    if (this.googleAuth) {
      this.googleAuth.signOut();
    }
  }

  loadAuthApi() {
    return new Promise((resolve, reject) => {
      this.gapi = window[clientName];
      const service = this;
      service.gapi.load('client', function () {
        service.gapi.client.load('authApi', 'v1', function () {
          service.logger.debug('gapi.client.load OK !!!!!!!!!!!!!!!!');
          resolve(true);
        }, service.apiEndPoint);
      });
    });
  }

}
