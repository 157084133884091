import { Injectable } from '@angular/core';
import {filter, Subject, take} from 'rxjs';

export enum REQUEST_TYPE {
  GET_ANNOTATION_LIST = 'get-annotation-list',
  GOTO_ANNOTATION = 'goto-annotation',
  STOP_ANIMATION = 'stop-animation',
  STOP_HOTSPOTS = 'stop-hotspots',
  DELETE_CONTAINER = 'delete-container'
}

export interface IRequest {
  receiverId: string;
  requestType: REQUEST_TYPE;
  task?: any;
}

export interface IResponse {
  senderId: string;
  value: any;
}


@Injectable({
  providedIn: 'root'
})
export class InternalContentExchangeService {

  request$ = new Subject<IRequest>();
  response$ = new Subject<IResponse>();

  constructor() { }
}
