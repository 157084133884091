import {Component, inject, OnDestroy, signal} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ILanguageParams} from '../../../../../../../../core/constants';
import {CommonService} from '../../../../../../../../core/common.service';
import {ToasterService} from '../../../../../../../../core/toaster.service';
import {TranslateApiService} from '../../../../../../../../services/translate-api.service';

@Component({
  selector: 'app-base-settings',
  template: ''
})
export abstract class BaseSettingsComponent implements OnDestroy {

  protected languageParams: ILanguageParams;
  protected languageParams$ = signal<ILanguageParams>(null);
  protected translateHint: string;
  protected translating: any;
  protected destroy$ = new Subject<void>();
  protected languageChanged$ = new Subject<any>();


  protected translateApiService = inject(TranslateApiService);
  protected common = inject(CommonService);
  protected fb = inject(FormBuilder);
  protected toasterService = inject(ToasterService);


  init(obj: any) {
    this.languageParams = obj.languageParams;
    this.languageParams$.set(this.languageParams);
    this.translateHint = obj.translateHint;
    this.translating = obj.translating;
  }

  onLanguageChange(value) {
    this.languageParams.currentLanguage = value;
    this.languageParams$.set(this.languageParams);
    this.languageChanged$.next(true);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  translateFormControlValue(ctrl: FormControl, translateId?: string) {
    const row = ctrl.value;
    const rowId = row?.id || (translateId ?? 'translate');
    const text = row;
    this.translating[rowId] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => {
        ctrl.patchValue(v);
      })
      .finally(() => delete this.translating[rowId]);
  }


}
