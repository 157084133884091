import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersSelectListComponent} from './users-select-list.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../core/core.module';
import {HttpClient} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {UserAvatarComponent} from '../../user-avatar/user-avatar.component';


@NgModule({
  declarations: [
    UsersSelectListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatButtonModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    UserAvatarComponent
  ],
  exports: [
    UsersSelectListComponent
  ]
})
export class UsersSelectListModule { }
