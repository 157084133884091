import { Injectable } from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';
import {firstValueFrom} from 'rxjs';

export enum WARNING_TYPES {
  TIMELINE_INVALID_DATE_TIME_STRUCTURE = '"Timeline" has an invalid DateTime(plannedTime) structure relative to the orderIndex.',
  TIMELINE_INVALID_ORDER_INDEX_STRUCTURE = '"Timeline" has an invalid orderIndex structure.',
  DEBUG_ERROR_UNKNOWN_OBJECT = 'Unknown object'
}

@Injectable({
  providedIn: 'root'
})

export class DebugService {

  private warningCache: {[type: string]: boolean} = {};
  clientId: string;

  constructor(private aff: AngularFireFunctions,
              private router: Router,
              private loginService: LoginService,
              private utils: UtilsService) {
    this.loginService.clientConfig$
      .subscribe(client => {
        this.clientId = client?.id;
      });
  }

  postCustomError(message: string, stackTrace?: string) {
    const gt = this.aff.httpsCallable('postCrashReport');
    const obj: {
      key: string,
      appClientId: string,
      stackTrace: string,
      url: string,
      href: string,
      appVersion: string,
      platform: string,
      userAgent: string,
      debugMessage: string
    } = {
      key: 'JzimQq9kJPGYjTwV4oyJUxPB0dfIo4lV',
      appClientId: this.clientId,
      stackTrace: stackTrace,
      url: this.router.url,
      href: window.location.href,
      appVersion: this.utils.getEnv().version,
      platform: window.navigator['platform'],
      userAgent: window.navigator['userAgent'],
      debugMessage: message,
    };
    firstValueFrom(gt(obj));
  }

  debug(message: string, stackTrace?: string) {
    if (!this.utils.getEnv().features?.enableDebug) {
      return Promise.resolve();
    }
    const appClientId = this.loginService.clientConfig$.getValue() ? this.loginService.clientConfig$.getValue().id : null;
    const gt = this.aff.httpsCallable('postCrashReport');
    const obj: {
      key: string,
      appClientId: string,
      stackTrace: string,
      url: string,
      href: string,
      appVersion: string,
      platform: string,
      userAgent: string,
      debugMessage: string
    } = {
      key: 'JzimQq9kJPGYjTwV4oyJUxPB0dfIo4lV',
      appClientId: appClientId,
      stackTrace: stackTrace,
      url: this.router.url,
      href: window.location.href,
      appVersion: this.utils.getEnv().version,
      platform: window.navigator['platform'],
      userAgent: window.navigator['userAgent'],
      debugMessage: message,
    };
    gt(obj).toPromise();
  }

  criticalWarning(warningType: WARNING_TYPES, message: string, stackTrace?: string, notCachedWarningType?: boolean) {
    if (!this.warningCache[warningType] || notCachedWarningType) {
      if (!notCachedWarningType) {
        this.warningCache[warningType] = true;
      }
      const appClientId = this.loginService.clientConfig$.getValue() ? this.loginService.clientConfig$.getValue().id : null;
      const gt = this.aff.httpsCallable('postCrashReport');
      const obj: {
        key: string,
        appClientId: string,
        stackTrace: string,
        url: string,
        href: string,
        appVersion: string,
        platform: string,
        userAgent: string,
        debugMessage: string
      } = {
        key: 'JzimQq9kJPGYjTwV4oyJUxPB0dfIo4lV',
        appClientId: appClientId,
        stackTrace: stackTrace,
        url: this.router.url,
        href: window.location.href,
        appVersion: this.utils.getEnv().version,
        platform: window.navigator['platform'],
        userAgent: window.navigator['userAgent'],
        debugMessage: `${warningType} ${message}`,
      };
      return gt(obj).toPromise();
    } else {
      return Promise.resolve();
    }
  }

  resetServiceValues() {
    this.warningCache = {};
  }
}
