import {Injectable} from '@angular/core';
import {Constants, EVENT_DATE_MODE, PHASE_ID_SUFFIX} from '../core/constants';
import {BehaviorSubject, Subject} from 'rxjs';
import {FeaturesService} from '../core/features.service';
import {FilteredUser} from '../model/AppUser';
import {ISectionSubject, SectionContent} from '../model/content/SectionContent';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {LoginService} from '../login/login.service';
import {isEmpty, max, min, unionBy} from 'lodash';
import {LevelTimePoints, TimePointMap} from '../model/event-mode/RowDatesObject';
import {UtilsService} from '../core/utils.service';
import {PlaneContent} from '../model/content/PlaneContent';
import {Event} from '../model/Event';

export interface ITimelineFilter {
  speakers?: FilteredUser[];
  subjects?: {[name: string]: string};
  sectionsIds?: string[];
}

export interface ITimelineIndexRange {
  firstIndex: number;
  lastIndex: number;
}

type TDirection = 'after' | 'before' | 'hold' | null;

@Injectable({
  providedIn: 'root'
})
export class TimelineFilterService {

  private _sectionShowDaysBefore = 0;
  private _sectionShowDaysAfter = 0;
  private daysChangedDirection: TDirection = null;
  private daysFetchCount = 0;
  sectionShowDaysPage = Constants.ONE_DAY * 14;

  private beforeDateFilter = 0;
  private afterDateFilter = 0;

  timelineFilter$ = new BehaviorSubject<ITimelineFilter>(null);
  sectionsSubjectsList$ = new BehaviorSubject<ISectionSubject[]>(null);
  sectionDaysBeforeAfterLoading$ = new BehaviorSubject<PHASE_ID_SUFFIX.BEFORE | PHASE_ID_SUFFIX.AFTER>(null);
  timelineFilterOrderIndexRange$ = new BehaviorSubject<ITimelineIndexRange>(null);
  timelineFilterRefreshFirstLevelDays$ = new BehaviorSubject<number>(null);
  private filterValues: {
    range;
    daysChangedDirection;
    sectionShowDaysBefore;
    sectionShowDaysAfter;
    beforeDateFilter;
    afterDateFilter;
  };

  constructor(private featuresService: FeaturesService,
              private loginService: LoginService,
              private utils: UtilsService) {
    this.initValues();
  }

  get sectionShowDaysBefore(): number {
    return this._sectionShowDaysBefore;
  }

  nextDaysBefore(value) {
    this.daysChangedDirection = 'before';
    this._sectionShowDaysBefore += value;
  }

  get sectionShowDaysAfter(): number {
    return this._sectionShowDaysAfter;
  }

  nextDaysAfter(value) {
    this.daysChangedDirection = 'after';
    this._sectionShowDaysAfter += value;
  }

  private get readyToDaysFetch() {
    return this.daysChangedDirection === 'before' || this.daysChangedDirection === 'after';
  }

  private initValues() {
    this.daysChangedDirection = null;
    this.daysFetchCount = 0;
    this.beforeDateFilter = 0;
    this.afterDateFilter = 0;
    this._sectionShowDaysBefore = Constants.ONE_DAY * (this.featuresService.getFeatureValue('timelineDaysRange') ?? 0);
    this._sectionShowDaysAfter = Constants.ONE_DAY * (this.featuresService.getFeatureValue('timelineDaysRange') ?? 0);
    this.sectionShowDaysPage = Constants.ONE_DAY * (this.featuresService.getFeatureValue('timelineDaysPage') ?? 0);
    this.filterValues = null;
  }

  resetServiceValues() {
    this.timelineFilterOrderIndexRange$ = new BehaviorSubject<ITimelineIndexRange>(null);
    this.timelineFilter$ = new BehaviorSubject<ITimelineFilter>(null);
    this.sectionsSubjectsList$ = new BehaviorSubject<ISectionSubject[]>(null);
    this.sectionDaysBeforeAfterLoading$ = new BehaviorSubject<PHASE_ID_SUFFIX.BEFORE | PHASE_ID_SUFFIX.AFTER>(null);
    this.timelineFilterRefreshFirstLevelDays$ = new BehaviorSubject<number>(null);
    this.initValues();
  }

  isSectionIndexInRange(s: SectionContent, timelineSlotMode: boolean) {
    if (timelineSlotMode || this.loginService.educationMode || !s) {
      return true;
    }
    const range = this.timelineFilterOrderIndexRange$?.getValue();
    if (range) {
      return s.isRoot || !!s.sectionEventPhase || s.orderIndex >= range.firstIndex && s.orderIndex <= range.lastIndex;
    }
    return true;
  }

  getFilteredSectionsByDate(sectionsList: SectionTimeline[], event: Event, firstLevelTimePoints: LevelTimePoints,
                            manageTimeMap: TimePointMap, timelineSlotMode: boolean,
                            treeCache: {[sectionId: string]: PlaneContent[]},
                            refreshDisplayTimeline: Subject<boolean>, filteredToSectionId?: string): SectionTimeline[] {
    const checkStart = (checkList: SectionTimeline[]) => {
      const startId = firstLevelTimePoints.start.sectionId;
      if (checkList.find(s => s.id === startId)) {
        this._sectionShowDaysBefore = 0;
        this.beforeDateFilter = this.utils.getDateDay(event.startDate.getTime());
      }
    };

    const checkEnd = (checkList: SectionTimeline[]) => {
      const endId = firstLevelTimePoints.end.sectionId;
      if (checkList.find(s => s.id === endId)) {
        this._sectionShowDaysAfter = 0;
        this.afterDateFilter = max([this.utils.getDateDayEnd(event.endDate), this.utils.getDateDayEnd(firstLevelTimePoints.end.endValue)]);
      }
    };

    const checkDateOutOfEventEnd = (date: number) =>
      date > max([this.utils.getDateDayEnd(event.endDate), this.utils.getDateDayEnd(firstLevelTimePoints.end.endValue)]);

    const beforeDate = (cDate: number) => {
      const startDate = firstLevelTimePoints.start.startValue;
      const endDate = firstLevelTimePoints.end.endValue;
      return cDate < startDate ? (startDate + this._sectionShowDaysBefore) :
        (cDate > endDate ? endDate : cDate);
    };

    const afterDate = (cDate: number) => {
      const startDate = firstLevelTimePoints.start.startValue;
      const endDate = firstLevelTimePoints.end.endValue;
      return cDate > endDate ? (endDate - this._sectionShowDaysAfter) :
        (cDate < startDate ? startDate : cDate);
    };

    const isExistsInFilter = (section: SectionTimeline, filteredList: SectionTimeline[]) => {
      const indexes = !isEmpty(filteredList) ?
        unionBy(filteredList, this.utils.getSectionChildrenTree(filteredList[filteredList.length - 1], sections), 'id')
        .map(o => o.orderIndex) : [0];
      return section.orderIndex >= min(indexes) && section.orderIndex <= max(indexes);
    };

    const isParentsExistsInFilter = (filteredList: SectionTimeline[]) => {
      let maxTop: SectionTimeline = null;
      for (const s of filteredList) {
        if (!s.parentId || s.parentId === rootSection.id) {
          continue;
        }
        const p = filteredList.find(sp => sp.id === s.parentId);
        if (!p) {
          const parent = sectionsList.find(o => o.id === s.parentId);
          maxTop = !maxTop || (maxTop && maxTop.orderIndex > parent.orderIndex) ? parent : maxTop;
        }
      }
      return maxTop;
    };

    if (!this.loginService.educationMode && timelineSlotMode && !this.filterValues) {
      this.saveFilterValues();
    } else if (!this.loginService.educationMode && !timelineSlotMode && this.filterValues) {
      this.restoreFilterValues();
    }

    const sections = sectionsList.sort(this.utils.comparator(Constants.ORDERINDEX));
    const rootSection = sectionsList.find(s => s.isRoot);

    if (isEmpty(sections) || this.loginService.educationMode || timelineSlotMode || event.dateMode === EVENT_DATE_MODE.ANYTIME ||
        (isEmpty(firstLevelTimePoints.daysStart) && isEmpty(firstLevelTimePoints.daysEnd))) {
      this.timelineFilterOrderIndexRange$.next(null);
      this.daysChangedDirection = 'hold';
      this._sectionShowDaysBefore = 0;
      this._sectionShowDaysAfter = 0;
      this.beforeDateFilter = this.utils.getDateDay(event.startDate?.getTime());
      this.afterDateFilter = this.utils.getDateDayEnd(event.endDate);
      return sections;
    }
    if (this._sectionShowDaysBefore || this._sectionShowDaysAfter) {
      const filteredSectionTo = sectionsList.find(s => s.id === filteredToSectionId);
      const currentDate = new Date().getTime();
      let startEndList: SectionTimeline[];
      let fetchNext = true;
      let needRefreshDisplayTimeline = false;
      let firstLevelEndSectionIdWithoutTime: string;
      do {
        this.beforeDateFilter = this._sectionShowDaysBefore && this.daysChangedDirection !== 'hold' ?
          this.utils.getDateDay(beforeDate(currentDate) - this._sectionShowDaysBefore) : this.beforeDateFilter;
        this.afterDateFilter = this._sectionShowDaysAfter && this.daysChangedDirection !== 'hold' ?
          this.utils.getDateDayEnd(afterDate(currentDate) + this._sectionShowDaysAfter) : this.afterDateFilter;
        startEndList = sections.map(s => {
          if (s.isRoot) {
            return new SectionTimeline({...s, plannedTime: firstLevelTimePoints.start.startValue});
          } else if (s.id === firstLevelTimePoints.end.sectionId && (!s.plannedTime || s.sectionTimeIsNotActive)) {
            firstLevelEndSectionIdWithoutTime = s.id;
            return new SectionTimeline({...s, plannedTime: firstLevelTimePoints.end.endValue});
          } else if (s.id !== firstLevelTimePoints.end.sectionId &&
              !s.plannedTime && !s.sectionTimeIsNotActive && manageTimeMap[s.id]?.startValue) {
            return new SectionTimeline({...s, plannedTime: manageTimeMap[s.id].startValue});
          } else if (firstLevelEndSectionIdWithoutTime && this.getMaxParent(s, sections) === firstLevelEndSectionIdWithoutTime) {
            return new SectionTimeline({...s, plannedTime: firstLevelTimePoints.end.endValue});
          } else {
            return s;
          }
        });
        startEndList = startEndList.filter(it => !it.fixedSectionType && !it.sectionEventPhase &&
          it.plannedTime >= this.beforeDateFilter && it.plannedTime <= this.afterDateFilter);
        fetchNext = isEmpty(startEndList) || (this.readyToDaysFetch && this.daysFetchCount === startEndList.length);
        if (fetchNext && checkDateOutOfEventEnd(this.afterDateFilter)) {
          fetchNext = false;
        }
        if (fetchNext || (!isEmpty(startEndList) && startEndList[0].sectionTimeIsNotActive)) {
          if (!isEmpty(startEndList) && startEndList[0].sectionTimeIsNotActive) {
            fetchNext = true;
            this._sectionShowDaysBefore += this.sectionShowDaysPage;
          } else {
            this.nextDays(startEndList?.length, firstLevelTimePoints.end.endValue <= currentDate);
          }
        }
        if (!fetchNext && filteredSectionTo && !isExistsInFilter(filteredSectionTo, startEndList)) {
          needRefreshDisplayTimeline = fetchNext =
            this.extendDateFilter(filteredSectionTo, sectionsList, firstLevelTimePoints, manageTimeMap, startEndList, currentDate);
        }
        let notExistsParent;
        if (!fetchNext && (notExistsParent = isParentsExistsInFilter(startEndList))) {
          needRefreshDisplayTimeline = fetchNext =
            this.extendDateFilter(notExistsParent, sectionsList, firstLevelTimePoints, manageTimeMap, startEndList, currentDate);
        }

      } while (fetchNext);

      this.daysChangedDirection = 'hold';
      this.daysFetchCount = startEndList.length;
      if (!isEmpty(startEndList)) {
        const firstOrderIndex = startEndList[0].orderIndex;
        const lastItems = startEndList[startEndList.length - 1].items.filter(s => !s.fixedSectionType);
        const lastSection = lastItems[lastItems.length - 1];
        const lastParent = lastSection ? lastSection : startEndList[startEndList.length - 1];
        const lastParentCache = (treeCache || {})[lastParent?.id];
        const lastTree = !isEmpty(lastParentCache) ? lastParentCache : this.utils.getSectionChildrenTree(lastParent, sections);
        const lastOrderIndex = lastTree[lastTree.length - 1].orderIndex;
        this.timelineFilterOrderIndexRange$.next({firstIndex: firstOrderIndex, lastIndex: lastOrderIndex});
      } else {
        this.timelineFilterOrderIndexRange$.next({firstIndex: -1, lastIndex: -1});
      }
      const range = this.timelineFilterOrderIndexRange$.getValue();
      const filteredSections = sections.filter(s => s.isRoot || !!s.sectionEventPhase ||
        s.orderIndex >= range.firstIndex && s.orderIndex <= range.lastIndex && !s.fixedSectionType)
        .map(s => new SectionTimeline(new SectionContent(s)));
      checkStart(filteredSections);
      checkEnd(filteredSections);
      for (const s of filteredSections) {
        const p = filteredSections.find(it => it.id === s.parentId);
        if (p) {
          p.items.push(s);
          p.items = p.items.sort((a, b) => a.orderIndex < b.orderIndex ? -1 : 1);
        }
      }
      if (needRefreshDisplayTimeline) {
        refreshDisplayTimeline.next(true);
      }
      return filteredSections;
    } else {
      this.timelineFilterOrderIndexRange$.next(null);
      return sections;
    }
  }

  private nextDays(fetchCount: number, currentTimeIsOutOfEndDate: boolean) {
    if (!this.daysChangedDirection && currentTimeIsOutOfEndDate) {
      this._sectionShowDaysBefore += this.sectionShowDaysPage;
    } else if (!this.daysChangedDirection || this.daysChangedDirection === 'after') {
      this._sectionShowDaysAfter += this.sectionShowDaysPage;
    } else if (this.daysChangedDirection === 'before') {
      this._sectionShowDaysBefore += this.sectionShowDaysPage;
    } else if (this.daysChangedDirection === 'hold' && !fetchCount) {
      this._sectionShowDaysAfter += this.sectionShowDaysPage;
    }
  }

  private extendDateFilter(section: SectionTimeline, fullList: SectionTimeline[],
                           firstLevelTimePoints: LevelTimePoints, manageTimeMap: TimePointMap,
                           filteredList: SectionTimeline[], currentDate: number) {
    const root = fullList.find(s => s.isRoot);
    const list = fullList.sort(this.utils.comparator(Constants.ORDERINDEX))
      .map(s => {
        if (s.isRoot) {
          return new SectionTimeline({...s, plannedTime: firstLevelTimePoints.start.startValue});
        } else if (s.id === firstLevelTimePoints.end.sectionId && (!s.plannedTime || s.sectionTimeIsNotActive)) {
          return new SectionTimeline({...s, plannedTime: firstLevelTimePoints.end.endValue});
        } else if (s.id !== firstLevelTimePoints.end.sectionId &&
            !s.plannedTime && !s.sectionTimeIsNotActive && manageTimeMap[s.id]?.startValue) {
          return new SectionTimeline({...s, plannedTime: manageTimeMap[s.id].startValue});
        } else {
          return s;
        }
      })
      .filter(s => s.orderIndex <= section.orderIndex && s.plannedTime && (s.isRoot || s.parentId === root.id));
    if (!isEmpty(list)) {
      if (isEmpty(filteredList)) {
        if (section.plannedTime < this.beforeDateFilter) {
          do {
            this._sectionShowDaysBefore += this.sectionShowDaysPage;
          } while (this.utils.getDateDay(currentDate - this._sectionShowDaysBefore) > section.plannedTime);
          this.daysChangedDirection = 'before';
          return true;
        }
      } else {
        const sortedFilteredList = filteredList.sort(this.utils.comparator(Constants.ORDERINDEX));
        const first = sortedFilteredList[0];
        const last = sortedFilteredList[sortedFilteredList.length - 1];
        const extendDateTo = list[list.length - 1].plannedTime;
        if (last.orderIndex < section.orderIndex) {
          do {
            this._sectionShowDaysAfter += this.sectionShowDaysPage;
          } while (this.utils.getDateDayEnd(currentDate + this._sectionShowDaysAfter) < extendDateTo);
          this.daysChangedDirection = 'after';
          return true;
        } else if (first.orderIndex > section.orderIndex) {
          do {
            this._sectionShowDaysBefore += this.sectionShowDaysPage;
          } while (this.utils.getDateDay(currentDate - this._sectionShowDaysBefore) > extendDateTo);
          this.daysChangedDirection = 'before';
          return true;
        }
      }
    }
    return false;
  }

  private saveFilterValues() {
    this.filterValues = {
      range: this.timelineFilterOrderIndexRange$.getValue(),
      daysChangedDirection: this.daysChangedDirection,
      sectionShowDaysBefore: this._sectionShowDaysBefore,
      sectionShowDaysAfter: this._sectionShowDaysAfter,
      beforeDateFilter: this.beforeDateFilter,
      afterDateFilter: this.afterDateFilter
    };
  }

  private restoreFilterValues() {
    this.daysChangedDirection = this.filterValues.daysChangedDirection;
    this._sectionShowDaysBefore = this.filterValues.sectionShowDaysBefore;
    this._sectionShowDaysAfter = this.filterValues.sectionShowDaysAfter;
    this.beforeDateFilter = this.filterValues.beforeDateFilter;
    this.afterDateFilter = this.filterValues.afterDateFilter;
    this.timelineFilterOrderIndexRange$.next(this.filterValues.range);
    this.filterValues = null;
  }

  private getMaxParent(section: SectionTimeline, sections: SectionTimeline[]) {
    const root = sections.find(s => s.isRoot);
    let parent = sections.find(s => s.id === section.parentId);
    while (parent && parent.parentId && parent.parentId !== root.id) {
      parent = sections.find(s => s.id === parent.parentId);
    }
    return parent.id;
  }
}
