import {Component, Injector} from '@angular/core';
import {UPLOAD_TYPE, UploadService} from '../../../../../../../core/upload.service';
import {isEmpty} from 'lodash';
import {firstValueFrom} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {MatchingMapEditorDialogComponent} from '../matching-map/matching-map-editor-dialog/matching-map-editor-dialog.component';

@Component({
  selector: 'app-question-map-mathing-editor',
  templateUrl: './question-map-mathing-editor.component.html',
  styleUrls: ['./question-map-mathing-editor.component.scss']
})
export class QuestionMapMathingEditorComponent extends AbstractQuizQuestionAnswersEditorComponent  {

  loadedImage = true;
  uppy: any;

  constructor(protected injector: Injector,
              private uploadService: UploadService,
              private dialog: MatDialog) {
    super(injector);
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.SINGLE_IMAGE, null, (result) => {
      this.question.files[0] = !isEmpty(result) ? result[0].response : null;
      if (this.question.files[0]) {
        const prefix = this.common.utils.generateRandomString(4).toLowerCase();
        const fileName = `${result[0].id.replace(/\//g, '-').replace('uppy', prefix)}.${result[0].extension}`;
        const mimeType = result[0].type;
        const metadata = this.question.files[0].substring(0, this.question.files[0].indexOf(';base64,'));
        const extMetadata = `${metadata};mimeType=${mimeType};fileName=${fileName}`;
        this.question.files[0] = this.question.files[0].replace(metadata, extMetadata);
      }
      this.uploadService.closeModal(this.uppy);
    });
  }

  uploadImage() {
    if (!this.question.files[0]) {
      this.uploadService.openUploadWindow(this.uppy);
    } else {
      this.question.files = [];
    }
  }

  editMatchingMap() {
    const dialogRef = this.dialog.open(MatchingMapEditorDialogComponent, {
      data: {
        answerData: this.answerData,
        correctEquality: this.question.correctEquality,
        imageUrl: this.question.files[0]
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.question.correctEquality = result.correctEquality;
        }
        return !!result;
      });
  }

  validate(): IValidated {
    return !isEmpty(this.dataSource.data) && !this.dataSource.data.some(o => isEmptyCaption(o.answer)) ? {validated: true} :
      {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }

  onDestroy() {
    super.onDestroy();
    this.uppy.close();
  }
}
