import {EventsDataService} from '../../../../../services/events-data.service';
import {BehaviorSubject, firstValueFrom, interval, of, Subject, takeUntil} from 'rxjs';
import {ANNOTATION_TYPE, ATTR_IS_PLAYING, MEDIA_LOOP, TEXT_MARK_TAGS, YOUTUBE_PLAYER_STATE} from '../../../../../core/constants';
import {IDocumentPathParams} from '../../../../../services/event-mode-api.service';

// static random id
const YOUTUBE_VIDEO_ID = 'youtube-ytb5di8ovsh7';
// static random name
const YOUTUBE_VIDEO_NAME = 'youtube video';

declare const YT: any;

interface IPlayAction {
  state: string;
  time: number;
}

interface IParams {
  synchronizePosition: boolean;
  autoplay: boolean;
  isManager: boolean;
  usedFollowMe: boolean;
}

interface IVideos {
  annotation_index: number;
  name?: string;
  description?: string;
  annotation_type: ANNOTATION_TYPE;
  options?: {
    duration: number;
  };
}

export class YoutubePlayer {
  private player;
  private _destroy = new Subject();
  private playerCurrentState: IPlayAction = {state: null, time: 0};
  private startInitialization = true;
  public videoDuration;
  public videoTitle;
  public selectedSpeed = 1; // Default speed
  public loopMode: MEDIA_LOOP;
  public startTime = 0;
  public endTime;
  public animationElementId;

  videoList$ = new BehaviorSubject<IVideos>(null);
  playerReady$ = new BehaviorSubject<boolean>(false);

  constructor(private videoElementId: string,
              private videoId: string,
              public params: IParams,
              private documentPathParams: IDocumentPathParams,
              private dataService: EventsDataService) {
    this.initPlayer();
  }

  destroy() {
    this._destroy.next(true);
    this._destroy.complete();
    this._destroy = null;
    this.player?.destroy();
    this.player = null;
  }

  playVideo() {
    this.player.stopVideo();
    this.player.loadVideoById({
      'videoId': this.videoId,
      'startSeconds': this.startTime,
      'endSeconds': this.endTime
    });
  }

  onSpeedChange(newSpeed: number) {
    const availableRates = this.player.getAvailablePlaybackRates();
    if (availableRates.includes(newSpeed)) {
      this.player.setPlaybackRate(newSpeed);
      this.selectedSpeed = newSpeed;
    }
  }

  private initPlayer() {
    firstValueFrom(this.params.usedFollowMe ? this.dataService.getFollowMePlayerState(this.documentPathParams) : of(null))
      .then((action: IPlayAction) => {
        this.playerCurrentState = action;
        this.player = new YT.Player(this.videoElementId, {
          height: '100%',
          width: '100%',
          videoId: this.videoId,
          playerVars: {
            autoplay: this.params.autoplay || action?.state === 'play',
            controls: 1,
            rel: 0,
            start: Math.floor(action?.time ?? 0),
          },
          events: {
            onReady: this.onReady.bind(this),
            onStateChange: this.onStateChange.bind(this)
          }
        });
      }).catch(e => this.dataService.common.log.error(e));
  }

  private getVideos() {
    this.videoList$.next(new Object({
      annotation_index: YOUTUBE_VIDEO_ID,
      name: this.videoTitle,
      annotation_type: ANNOTATION_TYPE.VIDEO,
      options: {duration: this.videoDuration}
    }) as IVideos);
  }

  private onReady(ev) {
    if (this.params.synchronizePosition && this.params.usedFollowMe) {
      if (ev.target.isMuted() && !this.dataService.common.userApplicationInteraction.getValue()) {
        this.dataService.common.userApplicationInteraction.next(true);
      }
      if (this.params.isManager) {
        this.managerSynchronization();
      } else {
        this.userSynchronization();
      }
    }
    this.videoDuration = ev.target.getDuration();
    this.videoTitle = ev.target.videoTitle ?? YOUTUBE_VIDEO_NAME;
    this.playerReady$.next(true);
    this.getVideos();
  }

  gotoVideo(params?: any) {
    this.animationElementId = params?.elementId;
    this.loopMode = params.video_options_loop ?? MEDIA_LOOP.ONE;
    this.startTime = params?.video_options_start_time ? Number(params.video_options_start_time) : 0;
    this.endTime = params?.video_options_end_time ? Number(params.video_options_end_time) : this.videoDuration;
    this.onSpeedChange(Number(params.video_options_speed ?? 1));
    this.playVideo();
  }

  stopVideo() {
    this.player.stopVideo();
  }

  private onStateChange(ev) {
    if (this.params.isManager) {
      if (this.startInitialization && this.playerCurrentState?.state === 'play' &&
        (ev.target.data && ev.target.data !== YT.PlayerState.PLAYING)) {
        this.dataService.common.userApplicationInteraction.next(false);
        this.startInitialization = false;
      } else {
        this.startInitialization = false;
        this.setCurrentYTState();
      }
    }

    if (ev.data === YOUTUBE_PLAYER_STATE.FINISHED) {
      if (this.loopMode === MEDIA_LOOP.LOOP_ONE) {
        this.playVideo();
      } else {
          this.stopVideoElement();
          this.player.stopVideo();
      }
    } else if (ev.data === YOUTUBE_PLAYER_STATE.PAUSE) {
      this.stopVideoElement();
    }
  }

  private stopVideoElement() {
    if (this.animationElementId) {
      Array.from(document.getElementsByTagName(TEXT_MARK_TAGS.HOTSPOT))
        .filter((it: HTMLElement) => it.dataset.element_id === this.animationElementId)
        .forEach((elem: HTMLElement) => {
          elem.removeAttribute(ATTR_IS_PLAYING.VIDEO);
        });
    }
  }

  private managerSynchronization() {
    interval(20000)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.setCurrentYTState();
      });
  }

  private userSynchronization() {
    this.dataService.getFollowMePlayerState(this.documentPathParams)
      .pipe(takeUntil(this._destroy))
      .subscribe((playback: IPlayAction) => {
        if (playback) {
          if (this.player) {
            const playerState = this.player.getPlayerState();
            const currentTime = this.player.getCurrentTime();
            if (playback.time && Math.abs(currentTime - playback.time) > 5) {
              this.player.seekTo(playback.time, true);
            }
            if (playback.state === 'pause' && playerState !== YOUTUBE_PLAYER_STATE.PAUSE) {
              this.player.pauseVideo();
            }
            if (playback.state === 'stop' && (playerState !== YOUTUBE_PLAYER_STATE.NOT_STARTED ||
              playerState !== YOUTUBE_PLAYER_STATE.FINISHED)) {
              this.player.stopVideo();
            }
            if (playback.state === 'play' && (playerState !== YOUTUBE_PLAYER_STATE.PLAY ||
              playerState !== YOUTUBE_PLAYER_STATE.BUFFERING)) {
              this.player.playVideo();
              if (!this.dataService.common.userApplicationInteraction.getValue()) {
                this.dataService.common.userApplicationInteraction.next(true);
              }
            }
          }
        }
      });
  }

  private setCurrentYTState() {
    if (!this.player || !this.player.getCurrentTime() ||
        !this.dataService.common.userApplicationInteraction.getValue() || !this.params.synchronizePosition) {
      return;
    }
    let time = this.player.getCurrentTime();
    const playerState = this.player.getPlayerState();
    let state = 'play';
    if (playerState === YOUTUBE_PLAYER_STATE.NOT_STARTED) {
      state = 'stop';
    }
    if (playerState === YOUTUBE_PLAYER_STATE.FINISHED) {
      state = 'stop';
      time = null;
    }
    if (playerState === YOUTUBE_PLAYER_STATE.PAUSE) {
      state = 'pause';
    }
    if (playerState === YOUTUBE_PLAYER_STATE.IN_QUEUE || playerState === YOUTUBE_PLAYER_STATE.BUFFERING ||
      playerState === YOUTUBE_PLAYER_STATE.NOT_STARTED) {
      return;
    }
    if (JSON.stringify(this.playerCurrentState) !== JSON.stringify({state: state, time: time})) {
      this.playerCurrentState = {state: state, time: time};
      const action = {
        state: state,
        time: time
      };
      this.dataService.setFollowMePlayerState(this.documentPathParams, action)
        .catch(e => this.dataService.common.log.error(e));
    }
  }
}
