import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {CommonService} from '../../core/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../login.service';
import {ClientDataService} from '../../services/client-data.service';
import {ClientConfig} from '../../model/ClientConfig';
import {ReferenceDataService} from '../../services/reference-data.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from '@angular/material/card';
import {NgForOf, NgStyle} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-join-to-client',
  templateUrl: './join-to-client.component.html',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    NgForOf,
    NgStyle,
    TranslateModule
  ],
  styleUrls: ['./join-to-client.component.scss']
})
export class JoinToClientComponent implements OnInit {

  loading = true;
  joinMode = true;
  client_id;
  clients: ClientConfig[];
  isLogged = false;

  constructor(private router: Router
            , private activatedRoute: ActivatedRoute
            , private afs: AngularFirestore
            , private afa: AngularFireAuth
            , private common: CommonService
            , private loginService: LoginService
            , private referenceDataService: ReferenceDataService
            , private clientDataService: ClientDataService) { }

  ngOnInit(): void {
    this.afa.authState.subscribe(it => this.isLogged = !!it);
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.has('id')) {
        this.client_id = params.get('id');
        this.autoJoinToClient(this.client_id);
      } else {
        if (!this.loginService.getAuthUser()) {
          this.loginService.saveLastURL('dashboard');
          return this.router.navigate(['login']);
        }
        this.joinMode = false;
        this.clientDataService.getAllowedClientsDto()
          .then(clients => {
            this.clients = clients;
          });
      }
      this.loading = false;
    });
  }

  autoJoinToClient(link) {
    this.loginService.requestClientConfig({link: link})
      .then((clientConfig: ClientConfig) => {
        if (this.isLogged) {
          this.common.localStorage.set('reload', true);
        }
        this.loginService.saveLastURL('/dashboard');
      });
  }

  joinToClient(client_id) {
    const isMainDomain = this.common.getEnv().domains.includes(window.location.host);
    if (!isMainDomain && !this.loginService.isLocalhost && this.common.getEnv().production) {
      this.loginService.goToMainDomain(`/go/${client_id}`);
    } else {
      this.common.showProgress.next(true);
      this.router.navigate(['go', client_id])
        .finally(() => this.common.showProgress.next(false));
    }
  }

}
