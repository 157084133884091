import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CLOCK_TYPE} from '../../../../core/constants';

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss']
})

export class TimeSelectComponent implements OnInit, OnChanges {
  readonly CLOCK_TYPE = CLOCK_TYPE;
  @Input() value: number;
  @Input() clockType: CLOCK_TYPE;
  @Output() change = new EventEmitter<number>();

  private STEP_DEG_H = 360 / 24;
  private STEP_DEG_M = 360 / 12;
  public steps = [];


  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setupUI();
  }

  private setupUI() {
    this.steps = [];
    switch (this.clockType) {
      case CLOCK_TYPE.HOURS:
        for (let i = 1; i <= 23; i++) {
          this.steps.push(i);
        }
        this.steps.push(0);
        break;
      case CLOCK_TYPE.MINUTES:
        for (let i = 5; i <= 55; i += 5) {
          this.steps.push(i);
        }
        this.steps.push(0);
        break;
    }
  }

  public getPointerStyle() {
    let divider = 1;
    switch (this.clockType) {
      case CLOCK_TYPE.HOURS:
        divider = 24;
        break;
      case CLOCK_TYPE.MINUTES:
        divider = 60;
        break;
    }
    let degrees = 0;
    if (this.clockType === CLOCK_TYPE.HOURS) {
      degrees = Math.round(this.value * (360 / divider)) - 180;
    } else {
      degrees = Math.round(this.value * (360 / divider)) - 180;
    }
    return {transform: 'rotate(' + degrees + 'deg)'};
  }

  getTimeValueClass(step: number, index: number) {
    const STEP_DEG = this.clockType === CLOCK_TYPE.MINUTES ? this.STEP_DEG_M : this.STEP_DEG_H;
    let classes = 't-clock-deg' + (STEP_DEG * (index + 1));
    if ((this.value || 0) === step) {
      classes += ' selected';
    }
    return classes;
  }

  changeTimeValue(step: number) {
    this.value = step;
    this.change.emit(this.value);
  }

}
