import { Component, computed, effect, Inject, Injector, OnInit } from '@angular/core';
import { DailyCoService } from '../../../../modules/meeting-frame/services/daily-co.service';
import { StdComponent } from '../../../../core/std-component';
import {BehaviorSubject, debounceTime, distinctUntilChanged, Subject, takeUntil} from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeLineService } from '../../../../services/time-line.service';
import { UtilsService } from '../../../../core/utils.service';
import { MEETING_VIEW_MODE } from '../../../../core/constants';
import { CommonService } from '../../../../core/common.service';
import { MeetingService } from '../../../../services/meeting.service';
import { TranslateService } from '@ngx-translate/core';
import { DailyParticipant } from '@daily-co/daily-js';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-conference-settings',
  templateUrl: './conference-settings.component.html',
  styleUrls: ['./conference-settings.component.scss']
})
export class ConferenceSettingsComponent extends StdComponent implements OnInit {
  videoMediaStream: {stream: MediaStream, id: string};
  microphoneActive = false;
  connectToDeviceV$ = new BehaviorSubject<boolean>(false);
  connectToDeviceM$ = new BehaviorSubject<boolean>(false);
  loaded = false;
  okButtonText = '';
  localUser: DailyParticipant;
  activeCamera = false;
  activeCameraCache: { active: boolean };
  activeSpeaker = false;
  clickedDone = false;
  allowedToSpeak = computed(() => this.dailyCoService.usersAllowedToSpeak().some(x => x === this.timeLineService.currentUser.userId));

  get isSpeaker(): boolean {
    return this.dailyCoService.isSpeaker;
  }

  get backgroundEffectsForm() {
    return this.dailyCoService.backgroundEffectsForm;
  }

  get currentUser() {
    return this.timeLineService.currentUser;
  }

  get meetingSettings() {
    return this.dailyCoService.meetingSettings;
  }

  get disableMicrophoneButton(): boolean {
    const disable = this.dailyCoService.meetingViewMode !== MEETING_VIEW_MODE.BREAKOUT_ROOMS &&
      (!this.isSpeaker && !this.dailyCoService.isConcierge) && (this.dailyCoService.meetingSettings?.onlySpeakersCanTalk && !this.allowedToSpeak());
    if (disable) this.microphoneActive = false;
    return disable;
  }

  get microphones(): MediaDeviceInfo[] {
    return this.dailyCoService.microphones;
  }

  get speakers(): MediaDeviceInfo[] {
    return this.dailyCoService.speakers;
  }

  get cameras(): MediaDeviceInfo[] {
    return this.dailyCoService.cameras;
  }

  get microphoneAccess(): boolean {
    return this.dailyCoService.micPermission;
  }

  get cameraAccess(): boolean {
    return this.dailyCoService.cameraPermission;
  }

  get isOnMeetingDailyCoMeeting(): boolean {
    return this.dailyCoService.isOnMeetingDailyCoMeeting;
  }

  get canSpeak(): boolean {
    return this.dailyCoService.isSpeaker || !this.meetingSettings?.onlySpeakersCanTalk;
  }

  get betaFeaturesEnabled(): boolean {
    return this.dailyCoService.betaFeaturesEnabled;
  }

  selectedCamera = null;
  private preAuthToken: string;
  private preAuthUrl: string;

  constructor(
    injector: Injector,
    public dailyCoService: DailyCoService,
    private timeLineService: TimeLineService,
    public dialogRef: MatDialogRef<ConferenceSettingsComponent>,
    public utils: UtilsService,
    public common: CommonService,
    private translateService: TranslateService,
    private meetingService: MeetingService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, topicToJoin: any, url: string, token: string}
  ) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.dailyCoService.common.userApplicationInteraction.next(true);
    this.dailyCoService.conferenceSettingsDialogOpen.next(true);
  }

  async ngOnInit() {
    this.router.navigate([], {queryParams: {cid: 'meeting'}, relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
    if (this.data.topicToJoin && !this.dailyCoService.settingsAlreadyOpened$.getValue()) {
      this.okButtonText = this.translateService.instant('common.ready');
      this.common.showProgress.next(true);
      this.preAuthToken = this.data.token;
      this.preAuthUrl = this.data.url;
      this.dailyCoService.initDefaultMediaDeviceState();
      await this.dailyCoService.preAuthUser(this.data.topicToJoin, this.preAuthUrl, this.preAuthToken);
      setTimeout(() => {
        this.common.showProgress.next(false);
      }, 500);
    } else {
      this.okButtonText = this.translateService.instant('user.task.status.done');
    }
    this.dailyCoService.allParticipants$.pipe(debounceTime(500), distinctUntilChanged(), this.takeUntilAlive())
      .subscribe(users => {
        this.toggleVideo();
        this.localUser = users.find(u => u.local)
        this.microphoneActive = !!this.localUser?.audio;
        this.activeCamera = this.localUser?.video;
        if (!this.activeCameraCache) this.activeCameraCache = { active: this.localUser?.video };
        if (this.localUser?.tracks?.video?.state !== 'playable') {
          this.dailyCoService.callFrame.setUserData({ ...(this.localUser.userData as Object), blockCamera: true })
        }
      });
    this.dailyCoService.settingsAlreadyOpened$.next(true);
    this.dailyCoService.destroyWatcher$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (value) {
          this.dialogRef.close(null);
        }
      });
    this.activeSpeaker = this.dailyCoService.speakersAudioOn;

    if (this.data.title === this.translateService.instant('virtual.conference.dailyco.you.can.talk.now')) {
      if (!this.microphoneActive && this.microphones.length) {
        this.toggleMicrophone();
      }
      if (!this.activeCamera && this.cameras.length) {
        this.activeCamera = true;
        this.toggleCamera();
      }
    }
  }

  async toggleCamera() {
    if (this.activeCamera) await this.dailyCoService.toggleCamera({ active: this.activeCamera });
  }

  async toggleMicrophone() {
    if (this.disableMicrophoneButton) {
      return;
    }
    await this.dailyCoService.toggleMicrophone();
  }

  setMicrophone(deviceId) {
    this.dailyCoService.setMicrophone(deviceId);
  }

  setCamera(deviceId) {
    this.dailyCoService.setCamera(deviceId);
  }

  setSpeaker(deviceId) {
    this.dailyCoService.setSpeaker(deviceId);
  }

  async testSelectedSpeaker() {
    if (this.activeSpeaker) {
      const deviceId = ((await this.dailyCoService.callFrame.getInputDevices()).speaker as any).deviceId;
      if (deviceId) {
        const audio = new Audio('/assets/sound/inflicted.mp3');
        (audio as any).setSinkId(deviceId);
        audio.play();
      }
    }
  }

  toggleVideo() {
    const user = this.dailyCoService.allParticipants$.getValue().find(u => u.local);
    if (user?.tracks?.video?.state !== 'playable') {
      this.videoMediaStream = null;
    } else {
      if (!this.videoMediaStream || this.videoMediaStream.id !== user.tracks.video.track.id) {
        this.videoMediaStream = {
          id: user.tracks.video.track.id,
          stream: new MediaStream([user.tracks.video.track])
        };
      }
    }
  }

  async askAccess() {
    if (!this.cameraAccess || !this.microphoneAccess) {
      this.common.toaster.pop(`info`, null, `You need to allow device access in your browser`);
      this.dailyCoService.askDeviceAccess();
    }
  }

  close() {
    if (this.dailyCoService.callFrame?.meetingState() === 'loaded') {
      this.meetingService.leaveMeeting().then(() => this.dialogRef.close());
    } else if (this.dailyCoService.callFrame?.meetingState() === 'joined-meeting') {
      this.closeDialog(true);
    } else {
      this.meetingService.closeMeetingFrame.next(true);
    }
  }

  async closeDialog(closeBtn = false) {
    if (!closeBtn) {
      await this.dailyCoService.toggleMicrophone({ active: this.microphoneActive });
      await this.dailyCoService.toggleCamera({ active: this.activeCamera });
      this.dailyCoService.toggleSpeakersAudio({ active: this.activeSpeaker });
      this.clickedDone = true;
    }
    const cameraActive = !!this.videoMediaStream?.stream;
    const microphoneActive = this.microphoneActive && this.canSpeak;
    this.dialogRef.close({microphoneActive, cameraActive, close: closeBtn});
  }

  async onDestroy() {
    super.onDestroy();
    if (!this.clickedDone) {
      await this.dailyCoService.toggleCamera(this.activeCameraCache);
    }
    if (this.dailyCoService.callFrame) {
      await this.dailyCoService.callFrame.setUserData({ ...(this.localUser.userData as Object), blockCamera: false });
    }
    this.dailyCoService.conferenceSettingsDialogOpen.next(false);
  }
}
