import {AbstractContent} from './AbstractContent';


export class SeparatorContent extends AbstractContent {
  type = 'separator';
  description: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.description) {
      this.description = obj.description;
    }
  }

  mergeContent(src: SeparatorContent) {
    super.mergeContent(src);

    if (this.description !== src.description) {
      this.description = src.description;
    }
  }

}

