import {MEETING_STATE} from '../../core/constants';

export class Meeting {

  id: number | string = null;
  startTime: Date = null;
  duration: number = null;
  url: string = null;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
  }

}

export interface IMeeting {
  meetingId?: number | string;
  topic?: string;
  state: MEETING_STATE;
  url?: string;
  password?: string;
}

export interface IMeetingParams {
  meetingInfoUrl?: any;
  externalUrl?: string;
  provider?: string;
  password?: string;
}
