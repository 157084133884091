import {AbstractContent} from './AbstractContent';

export class TextContent extends AbstractContent {
  type = 'text';
  content: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.content) {
      this.content = obj.content;
    }
  }

  mergeContent(src: TextContent) {
    super.mergeContent(src);

    if (this.content !== src.content) {
      this.content = src.content;
    }
  }
}
