import { Injectable } from '@angular/core';
import {TimeLineService} from '../../services/time-line.service';
import {Subject} from 'rxjs';
import {ROW_TIME_TYPE} from '../../model/event-mode/RowDatesObject';

@Injectable({
  providedIn: 'root'
})
export class EditSectionSettingDialogService {

  freeSlotTimeChange = new Subject<{value: number, type: ROW_TIME_TYPE}>();

  constructor(public timeLineService: TimeLineService) { }

  roleCanEditContent(isPresenter: boolean, isSpeaker: boolean) {
    if (isPresenter || isSpeaker) {
      return false;
    }
    return this.timeLineService.conferenceUser.isEditor ||
      (this.timeLineService.conferenceUser.isTranslator && this.timeLineService.event.getMultilingual().multilingual);
  }

  isRoleTranslator(isPresenter: boolean, isSpeaker: boolean) {
    if (isPresenter || isSpeaker) {
      return false;
    }
    return this.timeLineService.conferenceUser.isTranslator;
  }
}
