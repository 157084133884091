import {Injectable, signal} from '@angular/core';
import DailyIframe, {DailyCall, DailyInputSettings, DailyParticipant, DailyParticipantsObject, DailyInputAudioProcessorSettings} from '@daily-co/daily-js';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  fromEvent,
  interval,
  of,
  Subject,
  Subscription,
  take,
  takeUntil
} from 'rxjs';
import {DailyDataService} from '../../../services/daily-data.service';
import {CommonService} from '../../../core/common.service';
import {LoginService} from '../../../login/login.service';
import {Constants, IUserMediaDevicesState, MEETING_SETTING_QUESTION1, MEETING_VIEW_MODE} from '../../../core/constants';
import {EventsDataService} from '../../../services/events-data.service';
import {cloneDeep, isEmpty} from 'lodash';
import {ScreenSharingContent} from '../../../model/content/ScreenSharingContent';
import {DailyCoSettings} from '../../../model/event-mode/InstantSettings';
import {TimeLineService} from '../../../services/time-line.service';
import {MeetingContent} from '../../../model/content/MeetingContent';
import {CanYouHear, MeetingSettings} from '../../../model/content/meetingSettings';
import {IFollowMeAction, IFollowMeMeetingSpeaker} from '../../../services/follow-me.service';
import {UntypedFormBuilder} from '@angular/forms';
import {RootLocalStorageService} from '../../../core/root-local-storage.service';
import * as fromRoot from '../../../reducers';
import {Store} from '@ngrx/store';
import * as ui from '../../../actions/ui';
import {DAILY_CO_ACTION, IChatMessage, IDashboardUser, IMeetingRoom, IParticipantPictureCacheMap} from './daily-co.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {ConferenceSettingsComponent} from '../../../event-mode/event-mode/conference/conference-settings/conference-settings.component';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Event } from '../../../model/Event';
import { SectionUser } from '../../../model/content/SectionContent';
import { EventModeApiService } from '../../../services/event-mode-api.service';
import {AppUser} from '../../../model/AppUser';

declare const IVSPlayer: any;

@Injectable({
  providedIn: 'root'
})
export class DailyCoService {
  private readonly API_URL = this.common.getEnv().meeting.daily.api;
  private readonly GRID_SHUFFLE_INTERVAL = 30000;
  private readonly MAX_USERS_ON_GRID = 15;

  defaultSettings: MeetingSettings = {
    allowScreenSharingForViewers: false,
    mandatoryEventBackgroundForSpeakers: false,
    onlySpeakersCanTalk: false,
    muteAll: null,
    muteAllAudience: null,
    canYouHear: null,
    disableAutoGridShuffle: null,
    soundAlertRaiseHand: true,
    usersAllowedToSpeak: []
  };

  callFrame: DailyCall;
  speakersAudioOn = true;
  player: any;

  localUser: DailyParticipant;
  localUserMediaDevicesState: IUserMediaDevicesState = {cameraAccess: null, microphoneAccess: null};
  user_id: string;
  session_id: string;
  audioDevices: MediaDeviceInfo[] = [];
  videoDevices: MediaDeviceInfo[] = [];
  speakerDevices: MediaDeviceInfo[] = [];
  /** participant by page for small view */
  participants$: BehaviorSubject<DailyParticipant[]> = new BehaviorSubject<DailyParticipant[]>([]);
  /** all participants */
  allParticipants$: BehaviorSubject<DailyParticipant[]> = new BehaviorSubject<DailyParticipant[]>([]);
  allParticipantsSignal = signal<DailyParticipant[]>([]);
  speakers$: BehaviorSubject<DailyParticipant[]> = new BehaviorSubject<DailyParticipant[]>([]);
  participantsAudio$: BehaviorSubject<DailyParticipant[]> = new BehaviorSubject<DailyParticipant[]>([]);
  screenSharingOn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentEventId: string;
  activeUserId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  networkQuality$: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  activeSpeakerSessionId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  removeActiveUserId$: BehaviorSubject<DailyParticipant> = new BehaviorSubject<DailyParticipant>(null);
  activeSpeakerEvent$: BehaviorSubject<DailyParticipant> = new BehaviorSubject<DailyParticipant>(null);
  startUserId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  followMeSpeaker$: BehaviorSubject<IFollowMeMeetingSpeaker> = new BehaviorSubject<IFollowMeMeetingSpeaker>(null);
  participantsPages: any[] = [];
  currentParticipantsPage = -1;
  private _screenSharingContent: ScreenSharingContent;
  screenSharingContent$: BehaviorSubject<ScreenSharingContent> = new BehaviorSubject<ScreenSharingContent>(null);
  private _meetingContent: MeetingContent;
  meetingContent$: BehaviorSubject<MeetingContent> = new BehaviorSubject<MeetingContent>(null);
  followMeScreenSharingContentActive = false;
  dailyCoSettings$: BehaviorSubject<DailyCoSettings> = new BehaviorSubject<DailyCoSettings>(null);
  streamingRunning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  recordingStart$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  eventId: string;
  private _ownerStreamSectionId: string;
  public ownerStreamSectionChange$ = new BehaviorSubject<string>(null);
  streamingViewer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  streamingViewerUrl$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isM3U8$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  playM3U8$: Subject<number> = new Subject<number>();
  dailyCoLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  localStorageUsers = {};
  chatMessage$ = new Subject<IChatMessage>();
  dailyCoAction$ = new Subject<DAILY_CO_ACTION>();
  private _destroy$ = new Subject();
  private subscriptions: {[key: string]: Subscription} = {};
  readyDashboardParticipants: {[userId: string]: IDashboardUser} = {};
  dashboardParticipants: {[userId: string]: IDashboardUser} = {};
  muteAll$ = new BehaviorSubject<boolean>(false);
  cameraAccess = new BehaviorSubject<boolean>(null);
  microphoneAccess = new BehaviorSubject<boolean>(null);
  backgroundEffectsForm = this.fb.group({
    type: ['none'],
    blurStrength: [0.5]
  });
  meetingViewMode: MEETING_VIEW_MODE;
  meetingViewModeOption$: BehaviorSubject<MEETING_VIEW_MODE.SMALL | MEETING_VIEW_MODE.FREE> = new BehaviorSubject(MEETING_VIEW_MODE.FREE);
  allRoomList$ = new BehaviorSubject<IMeetingRoom[]>([]);
  roomList$ = new BehaviorSubject<IMeetingRoom[]>([]);
  currentRoomName: string;
  loadedCachePromise: {[key: string]: boolean} = {};
  roomsSubscriptions: {[name: string]: Subscription} = {};
  isSpeaker = false;
  isSpeakerNotFromSection = false;
  isConcierge = false;
  isPresenter = false;
  conferenceUserSelected$ = new BehaviorSubject<string>(null);
  conferenceSettingsDialogOpen = new BehaviorSubject<boolean>(false);
  canYouHear = new Subject<CanYouHear>();
  meetingSettings: MeetingSettings = null;
  meetingSettingsSignal = signal<MeetingSettings>(null);
  mediaDevicesLoaded$ = new BehaviorSubject<boolean>(false);
  /** daily.co ready for select devices (fired after 'preAuth' or 'join' room) */
  preLoadState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** click 'I'm ready' on ConferenceSettingsDialog. Required for preAuth way */
  readyClick$: Subject<boolean> = new Subject<boolean>();
  /** destroy progress */
  destroyWatcher$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** Settings dialog was already opened */
  settingsAlreadyOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  firstInitialization = true;
  usersMedia: {
    [key: string]: { audioEl: any, audioTrack?: any }
  } = {};
  gridPriority: string[] = [];
  gridShuffleType: 'ALL' | `SPEAKERS` | `VIEWERS` = 'ALL';
  currentRoomId: string;
  presenceInterval: Subscription;
  gridShuffleInterval = null;
  lastMuteAll: string = null;
  lastMuteAllAudience: string = null;
  lastCanYouHear: string = null;
  micPermission = false;
  cameraPermission = false;
  devices: MediaDeviceInfo[] = [];
  preAuth$ = new Subject();
  selectedDevices = {
    mic: ``,
    camera: ``,
    speaker: ``
  };
  sessionJoinedName = '';
  activeSpeakerUsers: {userSessionId: string, date: Date}[] = [];
  event: Event;
  audioElements: HTMLAudioElement[] = [];
  handsUp$: BehaviorSubject<{id: string, createdAt: Date}[]> = new BehaviorSubject<{id: string, createdAt: Date}[]>([]);
  handsUpSignal = signal<{id: string, createdAt: Date}[]>([]);
  usersAllowedToSpeak = signal<string[]>([]);
  joinedMeeting$ = new Subject();
  leaveMeeting$ = new Subject();
  leftMeeting$ = new Subject();
  loadingStartRecording$ = new BehaviorSubject(false);
  participantsAudioLevel$ = new BehaviorSubject<{[key: string]: number}>({});
  localAudioLevel$ = new BehaviorSubject<number>(0);
  conferenceGridOpen = signal(false);

  get microphones(): MediaDeviceInfo[] {
    return this.devices.filter(d => d.kind === 'audioinput');
  }

  get speakers(): MediaDeviceInfo[] {
    return this.devices.filter(d => d.kind === 'audiooutput');
  }

  get cameras(): MediaDeviceInfo[] {
    return this.devices.filter(d => d.kind === 'videoinput');
  }

  get isOnMeetingDailyCoMeeting(): boolean {
    return this.callFrame?.meetingState() === 'joined-meeting';
  }

  get betaFeaturesEnabled(): boolean {
    return this.timeLineService?.eventInstantSettings?.virtualConferenceSettings?.dailyCoSettings?.betaFeatures;
  }

  constructor(
    private dailyDataService: DailyDataService,
    private eventDataService: EventsDataService,
    private eventModeApi: EventModeApiService,
    public common: CommonService,
    private loginService: LoginService,
    public timeLineService: TimeLineService,
    private fb: UntypedFormBuilder,
    private localStorageService: RootLocalStorageService,
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    window.addEventListener('offline', () => {
      alert(this.translateService.instant('meeting.internet.connection.lost'));
      window.location.reload();
    });
  }

  async createClient(callWrapper?: HTMLDivElement | HTMLIFrameElement) {
    if (this.callFrame) {
      return;
    }
    if (callWrapper instanceof HTMLDivElement) {
      this.callFrame = await DailyIframe.createFrame(callWrapper);
    } else if (callWrapper instanceof HTMLIFrameElement) {
      this.callFrame = await DailyIframe.wrap(callWrapper);
    } else {
      this.callFrame = await DailyIframe.createCallObject();
    }
    this.showEvent = this.showEvent.bind(this);
    this.subscribeRoomsOnViewModeChange();
    this.subscribeMeetingRoomsParticipants();
    this.handleParticipantsState = this.handleParticipantsState.bind(this);
    this.handleParticipantsMessage = this.handleParticipantsMessage.bind(this);
    this.dailyCoErrorHandler = this.dailyCoErrorHandler.bind(this);
    this.dailyCoNonFatalErrorHandler = this.dailyCoNonFatalErrorHandler.bind(this);
    this.handleParticipantsJoined = this.handleParticipantsJoined.bind(this);
    this.handleParticipantsLeave = this.handleParticipantsLeave.bind(this);
    this.dailyCoDevicesErrorHandler = this.dailyCoDevicesErrorHandler.bind(this);
    this.dailyCoJoinedMeetingHandler = this.dailyCoJoinedMeetingHandler.bind(this);
    this.dailyCoLoadedMeetingHandler = this.dailyCoLoadedMeetingHandler.bind(this);
    this.dailyCoActiveSpeakerChangeMeetingHandler = this.dailyCoActiveSpeakerChangeMeetingHandler.bind(this);
    this.dailyCoNetworkQualityChangeHandler = this.dailyCoNetworkQualityChangeHandler.bind(this);
    this.networkConnectionHandler = this.networkConnectionHandler.bind(this);
    this.callFrame.on('live-streaming-error', this.showEvent)
      .on('live-streaming-started', this.showEvent)
      .on('live-streaming-stopped', this.showEvent)
      .on('participant-joined', this.handleParticipantsJoined)
      .on('participant-updated', this.handleParticipantsState)
      .on('participant-left', this.handleParticipantsLeave)
      .on('left-meeting', this.handleParticipantsLeave)
      .on('app-message', this.handleParticipantsMessage)
      .on('error', this.dailyCoErrorHandler)
      .on('camera-error', this.dailyCoDevicesErrorHandler)
      .on('joined-meeting', this.dailyCoJoinedMeetingHandler)
      .on('loaded', this.dailyCoLoadedMeetingHandler)
      .on('active-speaker-change', this.dailyCoActiveSpeakerChangeMeetingHandler)
      .on('network-quality-change', this.dailyCoNetworkQualityChangeHandler)
      .on('network-connection', this.networkConnectionHandler)
      .on('nonfatal-error', this.dailyCoNonFatalErrorHandler)
      .on('recording-started', this.onRecordingStart.bind(this))
      .on('recording-stopped', this.onRecordingStop.bind(this))
      .on('remote-participants-audio-level', this.onParticipantAudioLevel.bind(this))
      .on('local-audio-level', this.onLocalAudioLevel.bind(this))
      .on('selected-devices-updated', (x) => console.log(x));


    let cachedBackgroundEffect: any = this.localStorageService.get(`background_effects`);
    if (cachedBackgroundEffect) {
      cachedBackgroundEffect = JSON.parse(cachedBackgroundEffect);
      this.backgroundEffectsForm.setValue(cachedBackgroundEffect);
    }

    combineLatest([
      this.timeLineService.sections$,
      this.timeLineService.featureLineContentId$
    ])
      .pipe(takeUntil(this._destroy$), debounceTime(500))
      .subscribe(() => this.setAllParticipants(this.allParticipants$.getValue()));

    this.backgroundEffectsForm.valueChanges
      .pipe(takeUntil(this._destroy$), debounceTime(1000))
      .subscribe(() => this.updateBackgroundEffect());

    this.timeLineService.featureLineContentId$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.setSpeakersParticipants());

      this.timeLineService.instantSettings$
      .pipe(takeUntil(this._destroy$))
      .subscribe(settings => {
        if (!settings.virtualConferenceSettings?.dailyCoSettings?.enableRecording) {
          if (this.recordingStart$.getValue()) {
            this.stopRecording();
          }
        }
      });

    await this.askDeviceAccess();
    await this.setDevicesList();
    await this.setSelectedDevicesFromCache();
    this.setIsSpeakerListener();
    this.callFrame.startRemoteParticipantsAudioLevelObserver(250);
    this.callFrame.startLocalAudioLevelObserver(250)
  }

  onParticipantAudioLevel(ev) {
    this.participantsAudioLevel$.next(ev.participantsAudioLevel);
  }

  onLocalAudioLevel(ev) {
    this.localAudioLevel$.next(ev.audioLevel);
  }

  async onRecordingStart(ev) {
    if (this.loadingStartRecording$.getValue()) {
      const startTimestamp = Date.now();
      this.recordingStart$.next(startTimestamp);
      await this.eventDataService.setRecordingStart(this.ownerStreamSectionId, {
        recordingId: ev.recordingId,
        recordingStart: startTimestamp,
        roomId: this.ownerStreamSectionId,
        eventId: this.timeLineService.event.eventId
      });
      await this.addRecordingContentChange(true);
      this.common.showProgress.next(false);
      this.loadingStartRecording$.next(false);
      await this.eventDataService.setRecordingStart(this.ownerStreamSectionId, {
        recordingId: ev.recordingId,
        recordingStart: startTimestamp,
        roomId: this.ownerStreamSectionId,
        eventId: this.timeLineService.event.eventId
      });
    } else {
      this.eventDataService.getMeetingParams(this.timeLineService.timelineEvent.eventId, this.ownerStreamSectionId)
        .pipe(filter(x => !!x && x.length && x[0].recordingStarted), take(1))
        .subscribe(x => this.recordingStart$.next(x[0].recordingStarted));
    }
  }

  addRecordingContentChange(ignoreFollowMe = false) {
    const isUserFollowed = this.timeLineService.getFeatureLineSectionContent()?.container ? this.timeLineService.followMeSpeakerUser?.userId === this.timeLineService.currentUser.userId : this.timeLineService.followMePresenterUser?.userId === this.timeLineService.currentUser.userId;

    if (this.recordingStart$.getValue() && (isUserFollowed || ignoreFollowMe)) {
      let sectionId: string;
      let contentId: string;

      if (isUserFollowed) {
        sectionId = this.activatedRoute.snapshot.queryParams.sid;
        contentId = this.activatedRoute.snapshot.queryParams.cid;
      } else {
        const roomSection = this.timeLineService.getSection(this.ownerStreamSectionId);
        sectionId = roomSection.isRoot ? this.timeLineService.featureLineContentId : roomSection.id;
        contentId = null;
      }

      this.eventDataService.addRecordingContentChange(
        this.timeLineService.event.eventId,
        this.ownerStreamSectionId,
        sectionId,
        contentId,
        this.recordingStart$.getValue(),
        this.getUserData()
      );
    }
  }


  onRecordingStop(ev) {
    this.recordingStart$.next(0);
  }

  networkConnectionHandler(ev) {
    this.common.log.debug('networkConnectionHandler', ev);
  }

  createPlayer() {
    if (IVSPlayer.isPlayerSupported && !this.player) {
      this.player = IVSPlayer.create();
    }
  }

  playVideo(url: string, elPlayer: HTMLVideoElement, play: boolean) {
    if (!this.player || !elPlayer) {
      return;
    }
    if (play) {
      this.dailyCoLoaded.next(false);
      this.player.attachHTMLVideoElement(elPlayer);
      this.player.setAutoplay(true);
      this.player.setMuted(false);
      this.player.load(url);
      this.player.play();
      this.dailyCoLoaded.next(true);
    }
  }

  playM3U8() {
    this.playM3U8$.next(new Date().getTime());
  }

  leaveRoom() {
    return this.callFrame && this.callFrame.leave().then(async () => {
      this.leaveMeeting$.next(null);
      this.currentRoomId = null;
      this.deleteAllGridCamerasDiv();
      return this.leaveCurrentRoom();
    });
  }

  private leaveCurrentRoom() {
    const rooms = this.roomList$.getValue();
    const userRoom = (rooms || []).find(r => r.participants.find(id => id === this.timeLineService.currentUser.userId));
    if (userRoom && userRoom.roomId.includes('ROOM-')) {
      return this.eventDataService.saveMeetingRoomParticipants(
        this.timeLineService.timelineEvent.eventId,
        this.ownerStreamSectionId,
        userRoom.roomId,
        userRoom.roomName, 'delete-user', this.timeLineService.currentUser.userId);
    }
    return Promise.resolve();
  }

  get screenSharingContent(): ScreenSharingContent {
    return this._screenSharingContent;
  }

  set screenSharingContent(value: ScreenSharingContent) {
    this._screenSharingContent = value;
    this.screenSharingContent$.next(value);
  }

  get meetingContent(): MeetingContent {
    return this._meetingContent;
  }

  set meetingContent(value: MeetingContent) {
    this._meetingContent = value;
    this.meetingContent$.next(value);
  }

  get ownerStreamSectionId(): string {
    return this._ownerStreamSectionId;
  }

  set ownerStreamSectionId(value: string) {
    this._ownerStreamSectionId = value;
    this.ownerStreamSectionChange$.next(value);
  }

  resetServiceValues() {
    this.currentParticipantsPage = -1;
    this.dailyCoSettings$.next(null);
    this.streamingViewer$.next(false);
    this.streamingViewerUrl$.next(null);
    this.isM3U8$.next(false);
    this.playM3U8$.next(0);
    this.streamingRunning$.next(false);
    this.recordingStart$.next(0);
    this.participants$.next([]);
    this.setAllParticipants([]);
    this.participantsAudio$.next([]);
    this.screenSharingContent$.next(null);
    this.meetingContent = null;
    this.localStorageUsers = {};
    this.ownerStreamSectionId = null;
    this.common.utils.unsubscribeAll(this.subscriptions);
    this.cameraAccess.next(null);
    this.microphoneAccess.next(null);
    this.dailyCoLoaded.next(false);
    this.readyDashboardParticipants = {};
    this.dashboardParticipants = {};
    this.screenSharingContent = null;
    this.muteAll$.next(false);
  }

  private resetOwnerAndLists() {
    this.ownerStreamSectionId = null;
    this.participants$.next([]);
    this.setAllParticipants([]);
    this.allRoomList$.next([]);
    this.roomList$.next([]);
  }

  destroy() {
    this.destroyWatcher$.next(true);
    this.common.utils.unsubscribeAll(this.subscriptions);
    this._destroy$.next(true);
    this._destroy$.complete();
    this._destroy$ = null;
    this._destroy$ = new Subject();
    Object.values(this.roomsSubscriptions).forEach(v => v.unsubscribe());
    this.roomsSubscriptions = {};
    this.meetingViewMode = null;
    this.meetingViewModeOption$ = new BehaviorSubject(MEETING_VIEW_MODE.FREE);
    this.participantsAudio$.next([]);
    this.activeUserId$.next(null);
    this.removeActiveUserId$.next(null);
    this.followMeSpeaker$.next(null);
    this.muteAll$.next(false);
    this.dailyCoLoaded.next(false);
    this.store.dispatch(new ui.SetMeetingViewMode(null));
    this.cameraAccess.next(null);
    this.microphoneAccess.next(null);
    this.readyDashboardParticipants = {};
    this.dashboardParticipants = {};
    this.meetingContent = null;
    this.screenSharingContent = null;
    this.currentRoomName = '';
    this.currentRoomId = null;
    this.localUserMediaDevicesState = {cameraAccess: null, microphoneAccess: null};
    this.settingsAlreadyOpened$.next(false);
    this.recordingStart$.next(0);
    if (this.player) {
      this.player.delete();
      this.player = null;
    }
    if (this.callFrame) {
      return this.leaveRoom()
        .then(() => {
          this.resetOwnerAndLists();
          if (this.screenSharingOn$.getValue() && this.dailyCoSettings$.getValue() && this.dailyCoSettings$.getValue().liveStreamingOn) {
            if (this.screenSharingContent && this.screenSharingContent.userId === this.user_id) {
              this.eventDataService.saveScreenSharingStatus(this.eventId, this.ownerStreamSectionId, this.user_id, false);
            } else if (this.streamingViewer$.getValue()) {
              this.screenSharingContent = null;
            }
          }
          this.screenSharingOn$.next(false);
          this.meetingContent = null;
          return Promise.resolve();
        })
        .then(() => this.callFrame.destroy())
        .then(() => this.callFrame = null)
        .finally(() => this.destroyWatcher$.next(false));
    } else if (this.streamingViewer$.getValue()) {
      this.resetOwnerAndLists();
      this.screenSharingContent = null;
      this.meetingContent = null;
      this.screenSharingOn$.next(false);
    }
    this.destroyWatcher$.next(false);
    return Promise.resolve();
  }

  showEvent(e) {
    if (this.common && this.common.log) {
      this.common.log.debug('callFrame event', e);
    }
  }

  removeAllElements() {
    const videoElements: HTMLCollectionOf<HTMLVideoElement> = document.getElementsByTagName('video');
    Array.from(videoElements)
      .filter(el => el.id?.includes('participant_') && el.id?.includes('_video_track'))
      .forEach(el => el.remove());

    this.deleteAllGridCamerasDiv();

    Object.values(this.usersMedia)
      .forEach(audio => {
        if (audio) {
          audio.audioEl.remove();
        }
      });
    this.usersMedia = {};
  }

  deleteAllGridCamerasDiv() {
    const mainContainer = document.querySelector('#main-container');
    if (!mainContainer) {
      return;
    }
    const videoEls = document.querySelectorAll('[conference-camera=\'grid\']');
    videoEls.forEach(videoEl => videoEl.remove());
  }

  joinToRoom(roomId: string, _url?: string, _token?: string): Promise<void | DailyParticipantsObject> {
    if (!roomId) {
      return Promise.resolve();
    }

    this.common.showProgress.next(true); // avoid interaction before all being executed
    this.currentParticipantsPage = -1;

    const url = _url ? _url : this.API_URL + roomId;
    return firstValueFrom(this.destroyWatcher$.pipe(filter(v => !v)))
      .then(async () => {
        await this.eventDataService.removeFromQueue(this.timeLineService.event.eventId, this.ownerStreamSectionId, this.timeLineService.currentUser.userId);
      })
      .then(() => !this.callFrame ? this.createClient() : this.leaveRoom())
      .then(() => firstValueFrom(_token ? of(_token) : this.dailyDataService.getToken(this.currentEventId, roomId)))
      .then(async (token) => {
        await firstValueFrom(this.ownerStreamSectionChange$.pipe(filter(x => !!x)));
        this.subscribeToMeetingSettings();
        const viewerInWebinar = this.meetingSettings?.onlySpeakersCanTalk && !this.isUserSpeaker(this.timeLineService.currentUser.userId);
        if (this.settingsAlreadyOpened$.getValue() || viewerInWebinar) {
          return {token};
        } else {
          const dialog = this.dialog.open(ConferenceSettingsComponent, {
            id: 'conference-settings',
            width: '812px',
            panelClass: 'conference-settings-dialog',
            data: {title: this.common.i18n('home.welcome.welcome'), topicToJoin: roomId, url, token}
          }).afterClosed().pipe(take(1));

          return firstValueFrom(dialog).then((value) => {
            return value ? {token, ...value} : null;
          });
        }
      })
      .then((value: {token: string, microphoneActive: boolean, cameraActive: boolean, closed?: boolean}) => {
        if (value) {
          this.common.showProgress.next(true);
          if (this.activatedRoute.snapshot.queryParams?.cid !== 'meeting') {
            this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {sid: this.activatedRoute.snapshot.queryParams.sid, cid: 'meeting'}});
          }
          const {token, microphoneActive, cameraActive} = value;
          return firstValueFrom(this.dailyDataService.getRoom(roomId))
            .then(async (room) => {
              if (room) {
                this.session_id = room.id;
                const backgroundSettings = this.createBackgroundSettings();
                const cameraId = this.selectedDevices.camera;
                const microphoneId = this.selectedDevices.mic;
                const videoSource = cameraActive ? cameraId : false;
                const audioSource = microphoneActive ? microphoneId : false;
                const properties = {
                  url, token, showLeaveButton: true,
                  videoSource,
                  audioSource,
                  inputSettings: backgroundSettings,
                  userData: this.getUserData()
                };
                if (!this.callFrame) {
                  this.common.showProgress.next(false);
                  return;
                }
                return this.callFrame.join(properties)
                  .then(async res => {
                    await this.setSelectedDevicesFromCache();
                    this.sessionJoinedName = (res as any).local.user_name;
                    this.currentRoomId = roomId;

                    this.subscribeToRaiseHandQueue();

                    if (roomId !== this.ownerStreamSectionId) {
                      if (Object.keys(this.callFrame.participants()).length === 1) {
                        this.checkPresence(this.timeLineService.currentUser.userId, true);
                      }
                      const rm = (this.roomList$.getValue() || []).find(r => r.roomId === roomId);
                      if (rm) {
                        return this.eventDataService.saveMeetingRoomParticipants(
                          this.timeLineService.timelineEvent.eventId,
                          this.ownerStreamSectionId,
                          roomId,
                          rm.roomName, 'add-user', this.timeLineService.currentUser.userId)
                          .then(() => res);
                      }
                    }

                    const isSpeaker = this.isUserSpeaker(this.timeLineService.currentUser.userId);
                    const isConcierge = !!this.event.concierges[this.timeLineService.currentUser.userId];
                    if (!isSpeaker && !isConcierge && this.meetingSettings?.onlySpeakersCanTalk) {
                      this.callFrame.setLocalAudio(false);
                    }

                    const settings = this.dailyCoSettings$.getValue();
                    if (settings?.enableRecording && settings?.autoRecord) {
                      const participants = this.callFrame.participantCounts();
                      if (participants.hidden + participants.present === 1 && !this.recordingStart$.getValue()) {
                        this.startRecording();
                      }
                    }
                    return res;
                  })
                  .then(result => {
                    this.dailyCoLoaded.next(true);
                    return result;
                  })
                  .finally(() => this.common.showProgress.next(false));
              } else {
                this.common.showError('Room not found');
                this.common.showProgress.next(false);
                return null;
              }
            });
        } else {
          this.dailyCoAction$.next(DAILY_CO_ACTION.KICK_USER);
          this.common.showProgress.next(false);
        }
      });
  }

  private subscribeToRaiseHandQueue() {
    this.eventDataService.getQueue(this.event.eventId, this.ownerStreamSectionId)
    .pipe(takeUntil(this._destroy$))
    .subscribe(async _res => {
      const allParticipants = this.allParticipantsSignal();
      const newQueue = _res.filter(item => allParticipants.some(x => x.user_id === item.userId)).map(item => {
        return {
          id: item.userId,
          createdAt: new Date(item.createdAt)
        };
      });


      const onlineList = this.handsUp$.getValue().filter(item => this.allParticipants$.getValue().some(x => x.user_id === item.id));
      const inNewQueue = newQueue.some(x => !this.handsUp$.getValue().some(y => y.id === x.id));
      if (this.meetingSettings?.soundAlertRaiseHand && this.speakersAudioOn && inNewQueue && !onlineList.length) {
        const audio = new Audio('/assets/sound/hands-up.mp3');
        if ((audio as any).setSinkId) {
          const deviceId = ((await this.callFrame.getInputDevices()).speaker as any).deviceId;
          (audio as any).setSinkId(deviceId);
        }
        audio.play();
      }

      this.handsUp$.next(newQueue);
      this.handsUpSignal.set(newQueue);
    });
  }

  private muteAll() {
    if (!this.callFrame) {
      return Promise.resolve();
    }
    return this.callFrame.setInputDevicesAsync({audioSource: false, videoSource: false})
      .then(() => {
        this.callFrame.setLocalAudio(false);
        this.callFrame.setLocalVideo(false);
        this.localUserMediaDevicesState = {cameraAccess: null, microphoneAccess: null};
        if (this.localUser && this.localUser.tracks.video.track) {
          this.localUser.tracks.video.track.stop();
        }
        if (this.localUser && this.localUser.tracks.audio.track) {
          this.localUser.tracks.audio.track.stop();
        }
        return Promise.resolve();
      });
  }

  setActiveUserId(userId) {
    this.activeUserId$.next(userId);
  }

  getUserData() {
    return {
      userId: this.timeLineService.currentUser.userId,
      name: this.timeLineService.currentUser.fullName,
      picture: this.timeLineService.currentUser.picture
    };
  }

  initDefaultMediaDeviceState() {
    const isBreakoutRooms = this.meetingViewMode === MEETING_VIEW_MODE.BREAKOUT_ROOMS;
    if (isBreakoutRooms || this.timeLineService.userInitiatorEventMeeting?.userId === this.timeLineService.currentUser.userId) {
      this.localUserMediaDevicesState.microphoneAccess = true;
      this.localUserMediaDevicesState.cameraAccess = true;
    } else {
      this.localUserMediaDevicesState.microphoneAccess = false;
      this.localUserMediaDevicesState.cameraAccess = false;
    }
  }

  async toggleCamera(camera?: { active: boolean }) {
    if (!this.callFrame) {
      return;
    }
    if (this.cameras.length) {
      this.callFrame.setLocalVideo(camera ? camera.active : !this.callFrame.participants().local.video);
      this.setCamera(this.selectedDevices.camera);
      const devices = await this.callFrame.getInputDevices();
      if (!(devices.camera as any)?.deviceId) {
        await this.setCamera(this.selectedDevices.camera);
      }
    }
  }

  async toggleMicrophone(mic?: { active: boolean }) {
    this.callFrame.setLocalAudio(mic ? mic.active : !this.callFrame.participants().local.audio);
    const devices = await this.callFrame.getInputDevices();
    if (!(devices.mic as any)?.deviceId) {
      await this.setMicrophone(this.selectedDevices.mic);
    }
    this.localAudioLevel$.next(0);
  }

  async setVideo(value: null | boolean = null): Promise<boolean> {
    if (!this.callFrame) {
      return Promise.resolve(false);
    }
    if (value === null) {
      value = !this.callFrame.participants().local.video;
    }
    if (value) {
      await this.callFrame.setInputDevicesAsync({videoDeviceId: this.selectedDevices.camera});
      this.backgroundEffectsForm.updateValueAndValidity();
    } else {
      if (this.localUser.tracks.video.track) {
        this.localUser.tracks.video.track.stop();
      }
    }
    this.localUserMediaDevicesState.cameraAccess = value;
    this.callFrame.setLocalVideo(value);
    return Promise.resolve(value);
  }

  setShare(value?: boolean) {
    const recordingStart = this.recordingStart$.getValue();
    if (this.localUser && !this.localUser.screen && value) {
      this.callFrame.startScreenShare();
      if (recordingStart > 0) {
        this.eventDataService.addRecordingMetadata(this.timeLineService.event.eventId, this.ownerStreamSectionId, "SCREEN_SHARE_START", recordingStart);
      }
    } else {
      this.callFrame.stopScreenShare();
      if (recordingStart > 0) {
        this.eventDataService.addRecordingMetadata(this.timeLineService.event.eventId, this.ownerStreamSectionId, "SCREEN_SHARE_STOP", recordingStart);
      }
    }
  }

  startLiveStreaming(rtmpUrl: string, streamKey: string) {
    let url = rtmpUrl;
    if (url && url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }
    this.callFrame.startLiveStreaming({rtmpUrl: `${url}/${streamKey}`});
  }

  stopLiveStreaming() {
    this.callFrame.stopLiveStreaming();
  }

  startRecording() {
    this.common.showProgress.next(true);
    this.loadingStartRecording$.next(true);
    this.callFrame.startRecording({
        layout: {
          preset: 'active-participant',
        }
      }
    );
    return 0;
  }

  stopRecording() {
    this.callFrame.stopRecording();
    this.recordingStart$.next(0);
    return 0;
  }

  handleParticipantsMessage(ev) {
    if (ev && ev.data) {
      const messageType = ev.data.messageType;
      const messageText = ev.data.message;
      if (messageType && messageType === DAILY_CO_ACTION.KICK_USER) {
        const user = JSON.parse(messageText);
        if (user['userId'] === this.localUser.user_id && user['sessionId'] === this.localUser.session_id) {
          this.dailyCoAction$.next(DAILY_CO_ACTION.KICK_USER);
        }
      }
    }
  }

  handleParticipantsJoined(ev) {
    if (ev.participant.user_id === this.user_id) {
      if (ev.participant.user_name > this.sessionJoinedName) {
        alert(this.translateService.instant('meeting.new.session.redirecting'));
        this.router.navigate(['/']);
      } else {
        this.reJoin();
      }

      return;
    }

    if (!this.gridPriority.some(gp => gp === ev.participant.session_id)) {
      this.addUserToGridPriority(ev.participant.session_id);
    }
    this.user_id = this.callFrame.participants().local.user_id;
    this.localUser = this.callFrame.participants().local;
    let pList: DailyParticipant[] = Object.values(this.callFrame.participants());

    const cloneAboveIamJoinTime = pList.filter(p => p.user_id === this.user_id);
    if (!isEmpty(cloneAboveIamJoinTime)) {
      let maxJoinTime = 0;
      let sessionId: string = null;
      for (const pClone of cloneAboveIamJoinTime) {
        if (parseInt(pClone.user_name, 10) >= maxJoinTime) {
          maxJoinTime = parseInt(pClone.user_name, 10);
          sessionId = pClone.session_id;
        }
      }
      pList.filter(p => p.user_id === this.user_id && p.session_id !== sessionId)
        .forEach(cl => {
          if (cl.user_id === this.localUser.user_id && cl.session_id === this.localUser.session_id) {
            this.dailyCoAction$.next(DAILY_CO_ACTION.KICK_USER);
          } else {
            this.sendCloneKickMessage(cl.user_id, cl.session_id);
          }
        });
      pList = pList.filter(p => (p.session_id === sessionId) || p.user_id !== this.user_id);
    }
    this.setAllParticipants(pList);
    if (this.meetingViewMode === MEETING_VIEW_MODE.BREAKOUT_ROOMS) {
      const room = this.getRoom(this.user_id);
      if (room) {
        const rRoom = this.roomList$.getValue().find(r => r.roomId === room.roomId);
        if (rRoom && !rRoom.participants.find(id => id === ev.participant.user_id)) {
          rRoom.participants.push(ev.participant.user_id);
          this.roomList$.next(this.roomList$.getValue());
        }
      }
    }
  }

  getRoom(userId?: string) {
    if (!userId) {
      userId = this.timeLineService.currentUser.userId;
    }
    const rooms = this.roomList$.getValue() || [];
    return rooms.find(r => (r.participants || []).find(id => id === userId));
  }

  checkPresence(userId: string, force = false) {
    const isRoomParticipant = () => {
      const rooms = this.roomList$.getValue() || [];
      const room = rooms.find(r => r.roomId === this.currentRoomId);
      if (room) {
        return !!room.participants.find(it => it === userId);
      }
      return false;
    };
    if (isRoomParticipant() || force) {
      if (!this.presenceInterval || this.presenceInterval.closed) {
        const randomMs = this.common.utils.getRandomInt(30000, 60000);
        this.presenceInterval = interval(randomMs).pipe(take(1)).subscribe(() => {
          this.presenceInterval = null;
          if (isRoomParticipant() || force) {
            firstValueFrom(this.dailyDataService.checkPresence(this.eventId, this.ownerStreamSectionId));
          }
        });
      }
    }
  }

  handleParticipantsLeave(ev) {
    const pList: DailyParticipant[] = Object.values(this.callFrame.participants());
    if (ev.action === 'left-meeting') {
      this.user_id = null;
      this.localUser = null;
      this.removeAllElements();
      this.leftMeeting$.next(null);
    } else {
      this.gridPriority = this.gridPriority.filter(gp => gp !== ev.participant.session_id);
    }
    if (ev.action === 'participant-left' && ev.participant) {
      this.checkPresence(ev.participant.user_id);
      const index = pList.findIndex(p => p.user_id === ev.participant.user_id);
      if (index > -1) {
        pList.splice(index, 1);
      }
      if (this.activeUserId$.getValue() && this.activeUserId$.getValue().includes(ev.participant.user_id)) {
        this.removeActiveUserId$.next(ev.participant);
      }

      const videoEl: HTMLVideoElement = document.getElementById(`participant_${ev.participant.user_id}_video_track`) as any;
      if (videoEl) {
        videoEl.remove();
      }

      const uid = ev.participant.user_id + '_' + ev.participant.session_id;
      if (this.usersMedia[uid]) {
        this.usersMedia[uid].audioEl.remove();
        delete this.usersMedia[uid];
      }
    }
    this.setAllParticipants(pList);
    this.updateParticipantCache(pList);
  }

  reJoin() {
    return this.joinToRoom(this.ownerStreamSectionId);
  }

  async recreateClient() {
    await this.callFrame.destroy();
    this.callFrame = null;
    return this.joinToRoom(this.ownerStreamSectionId);
  }

  getParticipantTracks(participant: DailyParticipant) {
    const tracks = participant?.tracks;
    if (!tracks) { return { video: null, audio: null }; }

    const vt = <{ [key: string]: any }>tracks.video;
    const at = <{ [key: string]: any }>tracks.audio;

    const videoTrack =
      vt?.state === 'playable' ? vt['persistentTrack'] : null;
    const audioTrack =
      at?.state === 'playable' ? at['persistentTrack'] : null;
    return {
      video: videoTrack,
      audio: audioTrack,
    };
  }

  handleParticipantsState(ev) {
    const updateAudioTrack = (id, track) => {
      this.usersMedia[id].audioTrack = track;
      this.usersMedia[id].audioEl.muted = false;
      this.usersMedia[id].audioEl.srcObject = new MediaStream([track]);
      this.usersMedia[id].audioEl.play();
    };

    const participant = ev.participant;
    if (!participant.local) {
      const tracks = this.getParticipantTracks(participant);
      if (tracks.audio) {
        const uid = participant.user_id + '_' + participant.session_id;
        if (!this.usersMedia[uid]) {
          const audioEl = new Audio();
          this.audioElements.push(audioEl);
          audioEl.autoplay = true;
          this.usersMedia[uid] = {
            audioEl
          };
        }
        if (tracks.audio.id !== this.usersMedia[uid].audioTrack?.id) {
          updateAudioTrack(uid, tracks.audio);
        }

        this.setAudioElementsMutedState();
      }
    }

    this.user_id = this.callFrame.participants().local.user_id;
    this.localUser = this.callFrame.participants().local;
    let pList: DailyParticipant[] = Object.values(this.callFrame.participants());
    const cloneAboveIamJoinTime = pList.filter(p => p.user_id === this.user_id);
    if (!isEmpty(cloneAboveIamJoinTime)) {
      let maxJoinTime = 0;
      let sessionId: string = null;
      for (const pClone of cloneAboveIamJoinTime) {
        if (parseInt(pClone.user_name, 10) >= maxJoinTime) {
          maxJoinTime = parseInt(pClone.user_name, 10);
          sessionId = pClone.session_id;
        }
      }
      pList.filter(p => p.user_id === this.user_id && p.session_id !== sessionId)
        .forEach(cl => {
          if (cl.user_id === this.localUser.user_id && cl.session_id === this.localUser.session_id) {
            this.dailyCoAction$.next(DAILY_CO_ACTION.KICK_USER);
          } else {
            this.sendCloneKickMessage(cl.user_id, cl.session_id);
          }
        });
      pList = pList.filter(p => (p.session_id === sessionId) || p.user_id !== this.user_id);
    }
    this.setAllParticipants(pList);
    this.updateParticipantCache(pList);
  }

  toggleSpeakersAudio(speaker?: { active: boolean }) {
    this.speakersAudioOn = speaker? speaker.active : !this.speakersAudioOn;
    this.setAudioElementsMutedState();
  }

  setAudioElementsMutedState() {
    this.audioElements.forEach(el => el.muted = !this.speakersAudioOn);
  }

  updateParticipantCache(pList: any[], moveLocalToFirst = true) {
    const moveToFirstPosition = () => {
      // remove duplicate
      const objectsList: { [userId: string]: DailyParticipant } =
        pList.reduce((acc: { [userId: string]: DailyParticipant }, current: DailyParticipant) => {
          if (!acc[current.user_id + '_' + current.session_id]) {
            acc[current.user_id + '_' + current.session_id] = current;
          }
          return acc;
        }, {});
      const list = Object.values(objectsList).sort(this.common.utils.comparator('user_name'));
      let moveUserId;
      let activeUserId;
      if (this.followMeSpeaker$.getValue() && this.followMeSpeaker$.getValue().sectionId === this.ownerStreamSectionId) {
        moveUserId = list.find(o => o.user_id === this.followMeSpeaker$.getValue().userId) ?
          this.followMeSpeaker$.getValue().userId : null;
      }
      if (!moveUserId && this.timeLineService.followMePresenterUser) {
        moveUserId = list.find(o => o.user_id === this.timeLineService.followMePresenterUser.userId) ?
          this.timeLineService.followMePresenterUser.userId : null;
      }
      if (!moveUserId && this.timeLineService.followMeSpeakerUser) {
        moveUserId = list.find(o => o.user_id === this.timeLineService.followMeSpeakerUser.userId) ?
          this.timeLineService.followMeSpeakerUser.userId : null;
      }
      if (!moveUserId) {
        const s = this.timeLineService.getSectionWithSpeakersSelfOrParent(this.timeLineService.featureLineContentId);
        if (s) {
          for (const u of s.users || []) {
            const obj = list.find(o => o.user_id === u.userId);
            if (!isEmpty(obj)) {
              moveUserId = u.userId;
              break;
            }
          }
        }
      }
      if (!moveUserId && this.timeLineService.timelineEvent) {
        for (const uId of Object.keys(this.timeLineService.timelineEvent.managers || {})) {
          const obj = list.find(o => o.user_id === uId);
          if (!isEmpty(obj)) {
            moveUserId = uId;
            break;
          }
        }
      }
      if (moveUserId) {
        const uObj = list.find(o => o.user_id === moveUserId);
        if (!isEmpty(uObj)) {
          activeUserId = moveUserId + '_' + uObj.session_id;
          const index = list.findIndex(o => o.user_id === moveUserId);
          if (index > -1 && list[0].user_id !== moveUserId) {
            const user = cloneDeep(list[index]);
            list[index] = cloneDeep(list[0]);
            list[0] = user;
          }
          this.startUserId$.next(activeUserId);
        } else {
          this.startUserId$.next(null);
        }
      }
      return list;
    };

    const splitByPage = (list: any[]) => {
      const pages = [];
      let current = [];
      for (let i = 0; i < list.length; i++) {
        if ((i + 1) % Constants.DAILY_CO_PARTICIPANT_ON_PAGE !== 0) {
          current.push(list[i]);
        } else if ((i + 1) % Constants.DAILY_CO_PARTICIPANT_ON_PAGE === 0) {
          current.push(list[i]);
          pages.push(current);
          current = [];
        }
      }
      if (current.length > 0) {
        pages.push(current);
      }
      // append into last page prev page users if last page length = 1
      if (pages.length > 1 && pages[pages.length - 1].length === 1) {
        const lastPage = [];
        pages[pages.length - 2]
          .forEach((p, index) => {
            if (index < pages[pages.length - 2].length - 1) {
              lastPage.push(p);
            }
          });
        lastPage.push(pages[pages.length - 1][0]);
        pages[pages.length - 1] = lastPage;
      }
      return pages;
    };

    if (moveLocalToFirst) {
      const list = moveToFirstPosition();
      let speakersList = list.filter(p =>
        !!this.timeLineService.timelineEvent.managers[p.user_id] ||
        !!this.timeLineService.isSectionSpeaker(p.user_id, this.ownerStreamSectionId));
      if (this.meetingViewMode === MEETING_VIEW_MODE.BREAKOUT_ROOMS) {
        speakersList = list;
      }
      this.participantsAudio$.next(list);
      this.participantsPages = splitByPage(speakersList);
      if (this.currentParticipantsPage < 0 || this.participantsPages.length - 1 < this.currentParticipantsPage) {
        this.currentParticipantsPage = 0;
      }
      const userScreenSharing: DailyParticipant = speakersList.find(o => o.screen);
      if (userScreenSharing && !this.participantsPages[this.currentParticipantsPage].find(u => u.screen)) {
        userScreenSharing['hiddenOnScreenSharing'] = true;
        this.participantsPages[this.currentParticipantsPage].push(userScreenSharing);
      }
      this.participants$.next(this.participantsPages[this.currentParticipantsPage]);
    }
    return Promise.resolve();
  }

  nextPage() {
    if (this.currentParticipantsPage < this.participantsPages.length - 1) {
      this.currentParticipantsPage++;
      this.participants$.next(this.participantsPages[this.currentParticipantsPage]);
    }
  }

  prevPage() {
    if (this.currentParticipantsPage > 0) {
      this.currentParticipantsPage--;
      this.participants$.next(this.participantsPages[this.currentParticipantsPage]);
    }
  }

  dailyCoErrorHandler(ev) {
    if (ev.errorMsg === 'Meeting has ended') {
      this.currentRoomName = '';
      this.setAllParticipants([]);
      this.participants$.next([]);
      this.common.showPopupWarning(ev.errorMsg);
    } else {
      this.common.showPopupError('error', ev.errorMsg);
    }
  }

  dailyCoNonFatalErrorHandler(ev) {
    this.common.showPopupWarning(ev.type, ev.errorMsg);
  }

  dailyCoJoinedMeetingHandler(ev) {
    this.joinedMeeting$.next(ev);
  }

  dailyCoLoadedMeetingHandler(ev) {
    if (this.common && this.common.log) {
      this.common.log.debug('callFrame event', ev);
    }
  }

  getUsersByRole(userRole: 'ALL' | `SPEAKERS` | `VIEWERS` = `ALL`): DailyParticipant[] {
    const allParticipants = this.allParticipants$.getValue();
    const event = this.timeLineService._event$.getValue().value;
    switch (userRole) {
      case `ALL`:
        return allParticipants;
      case `SPEAKERS`:
        return allParticipants.filter(user => event.isManager(user.user_id) || this.isSectionSpeaker(user.user_id));
      case `VIEWERS`:
        return allParticipants.filter(user => !event.isManager(user.user_id) && !this.isSectionSpeaker(user.user_id));
      default:
        return [];
    }
  }

  dailyCoActiveSpeakerChangeMeetingHandler(ev) {
    const sessionId = ev.activeSpeaker.peerId;
    this.activeSpeakerEvent$.next(Object.values(this.callFrame.participants()).find(user => user.session_id === sessionId));
    if (sessionId) {
      this.activeSpeakerUsers.push({userSessionId: sessionId, date: new Date()});

      const users = this.getUsersByRole(this.gridShuffleType);
      const filteredUsers = users.filter((u, idx) => u.session_id === sessionId && idx < this.MAX_USERS_ON_GRID - 2);
      if (this.isUserInList(sessionId, users) && !this.isUserInList(sessionId, filteredUsers)) {
        this.replaceUserWithNotSpeakingUser(sessionId);
      }
    }
    if (this.common && this.common.log) {
      this.common.log.debug('callFrame event', ev);
    }
  }

  isUserInList(userSessionId: string, list: DailyParticipant[]): boolean {
    return list.some(item => item.session_id === userSessionId);
  }

  replaceUserWithNotSpeakingUser(userSessionId: string) {
    const gridUsersForReplacement = this.getUsersByRole(this.gridShuffleType).filter((u, idx) => idx <= this.MAX_USERS_ON_GRID - 2);
    const activeSpeakersTime = [...this.activeSpeakerUsers.filter(u => gridUsersForReplacement.some(gufp => gufp.session_id === u.userSessionId))];
    gridUsersForReplacement.forEach(gufp => {
      if (!activeSpeakersTime.some(ast => ast.userSessionId === gufp.session_id)) {
        activeSpeakersTime.push({userSessionId: gufp.session_id, date: new Date(1)});
      }
    });

    const minDate = Math.min(...activeSpeakersTime.map(ast => ast.date.getTime()));
    const inactiveUser = activeSpeakersTime.find(ast => ast.date.getTime() === minDate);

    if (inactiveUser) {
      this.addUserToGridPriority(userSessionId, inactiveUser.userSessionId);
    }
  }

  addUserToGridPriority(userSessionId, replaceSessionId = null) {
    if (replaceSessionId) {
      const idxUser = this.gridPriority.findIndex(gp => gp === userSessionId);

      const idxReplacement = this.gridPriority.findIndex(gp  => gp === replaceSessionId);

      this.gridPriority[idxReplacement] = userSessionId;
      this.gridPriority[idxUser] = replaceSessionId;
    } else {
      this.gridPriority = this.gridPriority.filter(id => id !== userSessionId);
      this.gridPriority.unshift(userSessionId);
    }
    this.setAllParticipants(this.allParticipants$.getValue());
  }

  dailyCoNetworkQualityChangeHandler(ev) {
    this.common.log.debug('networkQualityChangeHandler', ev);
    this.networkQuality$.next(ev.quality);
  }

  dailyCoDevicesErrorHandler(ev) {
    const setDevicesState = () => {
      return this.eventDataService.saveUserMediaDevicesState(this.timeLineService.timelineEvent.eventId,
        this.timeLineService.currentUser.userId,
        {cameraAccess: this.cameraAccess.getValue(), microphoneAccess: this.microphoneAccess.getValue()})
        .then(() => this.updateParticipantCache(this.allParticipants$.getValue(), false));
    };
    if (ev.error.type === 'cam-in-use') {
      this.common.showPopupError('Camera ' + ev.errorMsg.errorMsg);
      this.cameraAccess.next(false);
      setDevicesState();
    } else if (ev.error.type === 'mic-in-use') {
      this.common.showPopupError('Microphone ' + ev.errorMsg.errorMsg);
      this.microphoneAccess.next(false);
      setDevicesState();
    } else if (ev.error.type === 'cam-mic-in-use') {
      this.common.showPopupError('Camera and Microphone ' + ev.errorMsg.errorMsg);
      this.cameraAccess.next(false);
      this.microphoneAccess.next(false);
      setDevicesState();
    }
  }

  dailyCoAccessMediaPanel() {
    const userId = this.timeLineService.currentUser.userId;
    const speaker = !!this.timeLineService.timelineEvent.managers[userId] ||
      !!this.timeLineService.isSectionSpeaker(userId, this.ownerStreamSectionId);
    return this.dashboardParticipants[this.timeLineService.currentUser.userId] || speaker;
  }

  checkCameraAccess() {
    return navigator.mediaDevices.getUserMedia({video: true})
      .then(stream => {
        stream.getVideoTracks().forEach(t => {
          t.enabled = false;
          t.stop();
        });
        this.cameraAccess.next(true);
        return Promise.resolve();
      }).catch(err => {
        this.common.showPopupError(err);
        this.cameraAccess.next(false);
        return Promise.resolve();
      });
  }

  checkMicrophoneAccess() {
    return navigator.mediaDevices.getUserMedia({audio: true})
      .then(stream => {
        stream.getAudioTracks().forEach(t => {
          t.enabled = false;
          t.stop();
        });
        this.microphoneAccess.next(true);
        return Promise.resolve();
      }).catch(err => {
        this.common.showPopupError(err);
        this.microphoneAccess.next(false);
        return Promise.resolve();
      });
  }

  checkMediaDevicesAccessList() {
    return this.muteAll().then(() => {
      return this.eventDataService.saveUserMediaDevicesState(this.timeLineService.timelineEvent.eventId,
        this.timeLineService.currentUser.userId,
        {cameraAccess: false, microphoneAccess: false})
        .then(() => this.checkCameraAccess()
          .then(() => this.checkMicrophoneAccess()
            .then(() =>
              this.eventDataService.saveUserMediaDevicesState(this.timeLineService.timelineEvent.eventId,
                this.timeLineService.currentUser.userId,
                {cameraAccess: this.cameraPermission, microphoneAccess: this.micPermission})
                .then(() => navigator.mediaDevices.enumerateDevices()))));
    });
  }

  sendCloneKickMessage(userId, sessionId) {
    if (this.callFrame) {
      this.callFrame.sendAppMessage({
        messageType: DAILY_CO_ACTION.KICK_USER,
        message: JSON.stringify({userId: userId, sessionId: sessionId})
      }, '*');
    }
  }

  private createBackgroundSettings(): DailyInputSettings {
    const obj = this.backgroundEffectsForm.value;
    let type: "none" | "background-blur" | "background-image" = 'none';
    let config: any;

    if (obj.type === `blur` && obj.blurStrength) {
      type = `background-blur`;
      config = { strength: obj.blurStrength };
    }

    switch (obj.type) {
      case `background-blur`:
        if (obj.blurStrength) {
          type = `background-blur`;
          config = { strength: obj.blurStrength };
        }
        break;
      case `background-image`:
        if (this.timeLineService.event.background) {
          type = `background-image`;
          config = { source: this.timeLineService.event.background };
        }
        break;
      default:
        break;
    }
    const settings: DailyInputSettings = {
      video: {
        processor: {
          type,
          config
        }
      }
    };
    return settings;
  }

  applyBackgroundEffectSettings() {
    if (!this.betaFeaturesEnabled || !this.cameras.length || !this.cameraPermission) {
      return;
    }
    const settings: DailyInputSettings = this.createBackgroundSettings();
    return this.callFrame.updateInputSettings(settings);
  }

  async updateBackgroundEffect() {
    if (!this.betaFeaturesEnabled) {
      this.backgroundEffectsForm.patchValue({type: 'none'});
      return;
    }
    await this.applyBackgroundEffectSettings();
    if (this.localStorageService.get(`background_effects`)) {
      this.localStorageService.remove(`background_effects`);
    }
    this.localStorageService.set(`background_effects`, JSON.stringify(this.backgroundEffectsForm.value));
  }

  getMeetingRoomsParticipants() {
    return this.eventDataService.getMeetingRoomsParticipants(this.eventId, this.ownerStreamSectionId);
  }

  /**
   * Delete meeting room from DB and Daily.Co
   * @param ownerSectionId
   * @param roomId
   */
  deleteMeetingRoomParticipants(ownerSectionId: string, roomId: string) {
    return this.eventDataService
      .deleteMeetingRoomParticipants(this.timeLineService.timelineEvent.eventId, ownerSectionId, roomId)
      .then(() => {
        if (roomId !== ownerSectionId) {
          this.dailyDataService.deleteRoom(roomId).pipe(take(1)).toPromise();
        }
      });
  }

  /**
   * Delete meeting room only from Daily.Co
   * @param roomId
   */
  deleteDailyCoMeetingRoom(roomId: string) {
    return  firstValueFrom(this.dailyDataService.deleteRoom(roomId));
  }

  /**
   * Delete meeting room only from DB
   * @param ownerSectionId
   * @param roomId
   */
  deleteAllParticipantsFromRoom(ownerSectionId: string, roomId: string) {
    return this.eventDataService
      .deleteAllParticipantsFromMeetingRoom(this.timeLineService.timelineEvent.eventId, ownerSectionId, roomId);
  }

  subscribeRoomsOnViewModeChange() {
    combineLatest([
      this.timeLineService.followMePresenterUser$,
      this.store.select(fromRoot.getMeetingViewMode)
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(async ([presenter, mode]) => {
        if (this.meetingViewMode !== MEETING_VIEW_MODE.BREAKOUT_ROOMS && mode === MEETING_VIEW_MODE.BREAKOUT_ROOMS) {
          this.meetingViewMode = mode;
          await this.leaveRoom();
          if (this.roomsSubscriptions['roomsChangeParticipant$']) {
            this.roomsSubscriptions['roomsChangeParticipant$'].unsubscribe();
          }
          this.roomsSubscriptions['roomsChangeParticipant$'] = this.getMeetingRoomsParticipants()
            .pipe(debounceTime(600), takeUntil(this._destroy$))
            .subscribe(async (list: IMeetingRoom[]) => {
              this.allRoomList$.next(list);
              this.roomList$.next(list.filter(o => o.roomId !== this.ownerStreamSectionId));
              const room = this.getRoom();
              if (room &&
                !(this.allParticipants$.getValue() || []).find(p => p.user_id === this.timeLineService.currentUser.userId)) {
                await this.joinToRoom(room.roomId);
              }
            });
        } else if (this.meetingViewMode === MEETING_VIEW_MODE.BREAKOUT_ROOMS && mode !== MEETING_VIEW_MODE.BREAKOUT_ROOMS) {
          this.meetingViewMode = mode;
          if (this.roomsSubscriptions['roomsChangeParticipant$']) {
            this.roomsSubscriptions['roomsChangeParticipant$'].unsubscribe();
          }
          if (presenter?.userId === this.timeLineService.currentUser.userId) {
            for (const room of this.allRoomList$.getValue()) {
              if (!!this.ownerStreamSectionId) {
                // kick all users from room, but not delete room
                await this.deleteAllParticipantsFromRoom(this.ownerStreamSectionId, room.roomId);
              }
            }
          }
          this.allRoomList$.next([]);
          this.roomList$.next([]);
          await this.joinToRoom(this.ownerStreamSectionId);
        }
        this.meetingViewMode = mode;
      });
  }

  subscribeMeetingRoomsParticipants() {
    if (this.timeLineService.followMePresenterUser &&
      this.timeLineService.followMePresenterUser.userId !== this.timeLineService.currentUser.userId) {
      this.getMeetingRoomsParticipants()
        .pipe(takeUntil(this._destroy$))
        .subscribe((list: IMeetingRoom[]) => {
          if (!isEmpty(this.allRoomList$.getValue()) && isEmpty(list)) {
            this.joinToRoom(this.ownerStreamSectionId);
          }
          this.allRoomList$.next(list);
          this.roomList$.next(list.filter(o => o.roomId !== this.ownerStreamSectionId));
        });
    }
  }

  /**
   * Delete meeting room from Daily.Co and if this room not permanent delete from DB
   * @param ownerSectionId
   */
  async deleteAllBreakoutRooms(ownerSectionId: string) {
    for (const room of this.allRoomList$.getValue()) {
      if (!room.permanent) {
        await this.deleteAllParticipantsFromRoom(ownerSectionId, room.roomId);
        await this.deleteDailyCoMeetingRoom(room.roomId)
          .catch(e => this.common.log.error(e));
      } else {
        await this.deleteMeetingRoomParticipants(ownerSectionId, room.roomId)
          .catch(e => this.common.log.error(e));
      }
    }
    // todo may be remove this ???
    await this.eventDataService.setFollowMeCurrentMeetingViewMode(this.timeLineService.timelineEvent.eventId, null);
  }

  setConferenceUserSelected(id: string) {
    this.conferenceUserSelected$.next(id);
  }

  setAllParticipants(list: DailyParticipant[]) {
    let speakersPriority = Number.MAX_SAFE_INTEGER * -1;
    let sectionSpeakers: SectionUser[] = [];
    if (this.timeLineService.featureLineContentId) {
      sectionSpeakers = this.timeLineService.sections.find(s => s.id === this.timeLineService.featureLineContentId).users?.filter(u => u.speaker) || [];
    }
    let newOrder = list.map(p => {
      let gridPriority = this.gridPriority.findIndex(id => id === p.session_id);
      gridPriority = gridPriority === -1 ? 999999 : gridPriority;
      if (this.isSectionSpeaker(p.user_id)) {
        gridPriority = speakersPriority + sectionSpeakers.findIndex(ss => ss.userId === p.user_id);
      }
      return {...p, gridPriority};
    }).sort((p1, p2) => {
      return p1.gridPriority - p2.gridPriority;
    }).map(item => {
      if (item.user_id.startsWith('fake-participant-')) {
        item.userData = {
          name: `FP ${item.user_id}`,
          picture: null
        };
      } else if ((item.userData as any)?.p) {
        item.userData = {
          name: `Robot ${item.user_id}`,
          picture: null
        };
      }
      return item;
    });
    this.allParticipants$.next(newOrder);
    this.allParticipantsSignal.set(newOrder);
    this.setSpeakersParticipants();
  }

  setSpeakersParticipants() {
    if (!this.callFrame) {
      return;
    }

    const participants = this.allParticipants$.getValue();
    const speakers: DailyParticipant[] = participants.filter(user => this.isUserSpeaker(user.user_id));
    const orderedSpeakers: DailyParticipant[] = [];

    speakers.filter(speaker => this.isUserSectionSpeaker(speaker.user_id)).forEach(speaker => orderedSpeakers.push(speaker));
    speakers.filter(speaker => !this.isUserSectionSpeaker(speaker.user_id)).forEach(speaker => orderedSpeakers.push(speaker));

    this.speakers$.next(speakers);
    this.setAudioElementsMutedState();
  }

  async preAuthUser(roomId: string, url: string, token: string) {
    return new Promise(resolve => {
      this.eventDataService
        .getMeetingParams(this.timeLineService.timelineEvent.eventId, this.ownerStreamSectionId)
        .pipe(take(1))
        .subscribe(async val => {
          this.meetingSettings = val[0];
          const audioSource = (this.isUserSpeaker(this.timeLineService.currentUser.userId) || !this.meetingSettings?.onlySpeakersCanTalk) &&
            this.micPermission;
          const opts = {url, token, audioSource, videoSource: true};
          await this.callFrame.preAuth(opts).catch(_ => this.destroyWatcher$.next(true));
          this.preAuth$.next(null);
          const user = this.allParticipants$.getValue().find(x => x.local);
          if (user?.video && !this.cameras.length) {
            this.callFrame.setLocalVideo(false);
          }
          await this.setSelectedDevicesFromCache();

          if (this.cameraPermission && this.cameras.length) {
            await this.callFrame.startCamera({url});
            await this.setCamera(this.selectedDevices.camera);
          }
          await this.updateBackgroundEffect();
          resolve(null);
        });
    });
  }

  /** Set and get default meeting view mode value from database and navigate to meeting tab if it's first load*/
  setDefaultMeetingViewMode() {
    let followAction: IFollowMeAction;
    const defaultMode = () => {
      const defMode = this.dailyCoSettings$.getValue().getMeetingDefaultViewMode();
      this.store.dispatch(new ui.SetMeetingViewMode(defMode));
      this.meetingViewModeOption$.next(MEETING_VIEW_MODE.FREE);
      navigate();
      return defMode;
    };
    const navigate = () => {
      if (this.firstInitialization && followAction && !followAction.contentId?.includes('meeting')) {
        this.router.navigate([], {queryParams:
            {sid: followAction.sectionId, cid: followAction.contentId}, relativeTo: this.activatedRoute});
      }
      this.firstInitialization = false;
    };

    if (this.timeLineService.followMePresenterUser$.getValue()) {
      return firstValueFrom(this.eventDataService.getFollowMeCurrentMeetingViewMode(this.timeLineService.timelineEvent.eventId)
        .pipe(takeUntil(this._destroy$)))
        .then(list => {
          return firstValueFrom(this.eventDataService.getFollowMeAction(this.timeLineService.timelineEvent.eventId))
            .then(action => {
              followAction = action;
              const vmObj = list ? list[this._ownerStreamSectionId] : null;
              if (vmObj) {
                this.store.dispatch(new ui.SetMeetingViewMode(vmObj.viewMode));
                this.meetingViewModeOption$.next(vmObj.viewModeOption);
                navigate();
                return Promise.resolve(vmObj.viewMode);
              } else {
                return Promise.resolve(defaultMode());
              }
            });
        });
    } else {
      return Promise.resolve(defaultMode());
    }
  }

  subscribeToMeetingSettings() {
    this.eventDataService.getMeetingParams(this.timeLineService.timelineEvent.eventId, this.ownerStreamSectionId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(async value => {
        this.meetingSettings = value[0] ?? this.defaultSettings;
        this.meetingSettingsSignal.set(this.meetingSettings);

        this.usersAllowedToSpeak.set(this.meetingSettings.usersAllowedToSpeak || []);
        const section = this.timeLineService.getSection(this.ownerStreamSectionId);
        if (!value[0] && this.timeLineService.hasPresenterOrSpeakerAccessRightsToSection(section)) {
          await this.eventDataService.setMeetingParams(this.timeLineService.timelineEvent.eventId, section.id, this.defaultSettings);
        }

        if (this.isSpeaker && this.meetingSettings.mandatoryEventBackgroundForSpeakers && this.backgroundEffectsForm.value.type !== `background-image`) {
          this.backgroundEffectsForm.patchValue({type: `background-image`});
        }

        if (!this.meetingSettings.allowScreenSharingForViewers && !this.isSpeaker && this.localUser?.screen && !this.isConcierge) {
          this.setShare(false);
        }

        const isBreakoutRoom = this.meetingViewMode !== MEETING_VIEW_MODE.BREAKOUT_ROOMS;
        if (isBreakoutRoom && !this.isSpeaker && !this.isConcierge && this.meetingSettings?.onlySpeakersCanTalk && this.localUser?.audio && !this.usersAllowedToSpeak().some(x => x === this.timeLineService.currentUser.userId)) {
          this.callFrame.setLocalAudio(false);
        }

        if (this.meetingSettings.muteAll && this.lastMuteAll !== this.meetingSettings.muteAll) {
          this.lastMuteAll = this.meetingSettings.muteAll;
          const diffTime = new Date().getTime() - new Date(this.meetingSettings.muteAll).getTime();
          if (diffTime < 10000) {
            this.callFrame.setLocalAudio(false);
          }
        }

        if (this.meetingSettings.muteAllAudience && !this.isSpeaker && this.lastMuteAllAudience !== this.meetingSettings.muteAllAudience && !this.isConcierge) {
          this.lastMuteAllAudience = this.meetingSettings.muteAllAudience;
          const diffTime = new Date().getTime() - new Date(this.meetingSettings.muteAllAudience).getTime();
          if (diffTime < 10000) {
            this.callFrame.setLocalAudio(false);
          }
        }

        if (this.meetingSettings.canYouHear && this.lastCanYouHear !== this.meetingSettings.canYouHear.date) {
          this.lastCanYouHear = this.meetingSettings.canYouHear.date;
          const diffTime = new Date().getTime() - new Date(this.meetingSettings.canYouHear.date).getTime();
          if (diffTime < 10000) {
            this.canYouHear.next(this.meetingSettings.canYouHear);
          }
        }
      });
  }

  private isSectionSpeaker(user_id) {
    return this.timeLineService.isSectionSpeaker(user_id, this.ownerStreamSectionId) ||
      this.timeLineService.isSectionSpeaker(user_id, this.timeLineService.featureLineContentId || this.ownerStreamSectionId);
  }

  setGridShuffleInterval() {
    this.unsetGridShuffleInterval();
    this.gridShuffleInterval = setInterval(() => {
      if (!this.meetingSettings?.disableAutoGridShuffle) {
        const mainSectionMaxUsers = 9;
        const allParticipants = this.allParticipants$.getValue();
        const event = this.timeLineService._event$.getValue().value;

        const getRandomItemFromArray = (arr: any[]) => arr[Math.floor(Math.random() * arr.length)];
        const getRandomUsers = (users) => {
          return getRandomItemFromArray(users.filter((x, idx) => idx > mainSectionMaxUsers - 2 && !x.local));
        };
        if (allParticipants.length) {
          switch (this.gridShuffleType) {
            case `ALL`:
              if (allParticipants.length > mainSectionMaxUsers) {
                const randomUser = getRandomUsers(allParticipants);
                const replacementRandomUser = getRandomUsers(allParticipants);
                this.addUserToGridPriority(randomUser.session_id, replacementRandomUser.session_id);
              }
              break;
            case `SPEAKERS`:
              const speakers = allParticipants.filter(user =>
                event.isManager(user.user_id) || this.isSectionSpeaker(user.user_id));
              if (speakers.length > mainSectionMaxUsers) {
                const randomUser = getRandomUsers(speakers);
                const replacementRandomUser = getRandomUsers(speakers);
                this.addUserToGridPriority(randomUser.session_id, replacementRandomUser.session_id);
              }
              break;
            case `VIEWERS`:
              const viewers = allParticipants.filter(user =>
                !event.isManager(user.user_id) && !this.isSectionSpeaker(user.user_id));
              if (viewers.length > mainSectionMaxUsers) {
                const randomUser = getRandomUsers(viewers);
                const replacementRandomUser = getRandomUsers(viewers);
                this.addUserToGridPriority(randomUser.session_id, replacementRandomUser.session_id);
              }
              break;
            default:
              break;
          }
        }
      }
    }, this.GRID_SHUFFLE_INTERVAL);
  }

  unsetGridShuffleInterval() {
    if (this.gridShuffleInterval) {
      clearInterval(this.gridShuffleInterval);
      this.gridShuffleInterval = null;
    }
  }

  async setDevicesList() {
    if (this.callFrame) {
      this.devices = (await this.callFrame.enumerateDevices()).devices;
    } else {
      this.devices = [];
    }
  }

  async askDeviceAccess() {
    let micPermission = false;
    let cameraPermission = false;
    try {
      await navigator.mediaDevices.getUserMedia({audio: true});
      micPermission = true;
      await navigator.mediaDevices.getUserMedia({video: true});
      cameraPermission = true;
    } catch (e) {
      // nopermission
    }

    this.micPermission = micPermission;
    this.cameraPermission = cameraPermission;
    this.setDevicesList();
    this.setSelectedDevicesFromCache();
  }

  async setMicrophone(deviceId) {
    if (this.callFrame) {
      await this.callFrame.setInputDevicesAsync({audioDeviceId: deviceId });
      this.selectedDevices.mic = deviceId;
      this.localStorageService.set('selected_mic', deviceId);
    }
  }

  async setCamera(deviceId) {
    if (this.callFrame) {
      await this.callFrame.setInputDevicesAsync({videoDeviceId: deviceId });
      this.selectedDevices.camera = deviceId;
      this.localStorageService.set('selected_camera', deviceId);
    }
  }

  async setSpeaker(deviceId) {
    if (this.callFrame) {
      await this.callFrame.setOutputDeviceAsync({outputDeviceId: deviceId });
      this.localStorageService.set('selected_speaker', deviceId);
      this.selectedDevices.speaker = deviceId;
    }
  }

  async setSelectedDevicesFromCache() {
    if (this.callFrame) {
      const selectedDevices = await this.callFrame.getInputDevices();
      const selectedMic = this.localStorageService.get('selected_mic');
      const selectedCamera = this.localStorageService.get('selected_camera');
      const selectedSpeaker = this.localStorageService.get('selected_speaker');

      if (selectedMic && this.microphones.find(m => m.deviceId === selectedMic)) {
        if (selectedDevices.mic !== selectedMic) {
          await this.setMicrophone(selectedMic);
        }
      } else {
        if (this.microphones.length) {
          await this.setMicrophone(this.microphones[0].deviceId);
        }
      }

      if (selectedCamera && this.cameras.find(c => c.deviceId === selectedCamera)) {
        if (selectedDevices.camera !== selectedCamera) {
          await this.setCamera(selectedCamera);
        }
      } else {
        if (this.cameras.length) {
          await this.setCamera(this.cameras[0].deviceId);
        }
      }

      if (selectedSpeaker && this.speakers.find(s => s.deviceId === selectedSpeaker)) {
        if (selectedDevices.camera !== selectedSpeaker) {
          await this.setSpeaker(selectedSpeaker);
        }
      } else {
        if (this.speakers.length) {
          await this.setSpeaker(this.speakers[0].deviceId);
        }
      }
    }
  }

  setIsSpeakerListener() {
    this.timeLineService.event$
      .pipe(takeUntil(this._destroy$))
      .subscribe((event) => {
        this.event = event;
        this.isSpeaker = this.isUserSpeaker(this.timeLineService.currentUser.userId);
        this.isSpeakerNotFromSection = this.isUserSpeaker(this.timeLineService.currentUser.userId, false);
        this.isConcierge = !!this.event.concierges[this.timeLineService.currentUser.userId];
        this.isPresenter = this.timeLineService.isPresenter;
      });

    this.timeLineService.featureLineContentId$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.isSpeaker = this.isUserSpeaker(this.timeLineService.currentUser.userId);
        this.isSpeakerNotFromSection = this.isUserSpeaker(this.timeLineService.currentUser.userId, false);
        this.isConcierge = !!this.event.concierges[this.timeLineService.currentUser.userId];
      });
  }

  disableMicrophoneIfNotSpeaker() {
    if (!this.localUser) {
      return;
    }
    if ((!this.isSpeaker && !this.isConcierge && this.meetingSettings.onlySpeakersCanTalk) && this.localUser.audio) {
      this.callFrame.setLocalAudio(false);
    }
  }

  isUserSpeaker(userId, checkForSection = true): boolean {
    return this.event && (this.event.isPresenter(userId) || this.event.isConcierge(userId) ||
      (checkForSection && this.isUserSectionSpeaker(userId)));
  }

  isUserSectionSpeaker(userId): boolean {
    const flSection = this.timeLineService.getFeatureLineSectionContent();
    if (!flSection) {
      return false;
    }
    let section;
    if (flSection.container) {
      section = this.timeLineService.userRoom(flSection, this.timeLineService.conferenceUser);
    }
    return this.timeLineService.hasUserSpeakerAccessRightsToSection(section ?? flSection, {userId: userId} as AppUser);
  }

  async setNoiseCancellation(value: boolean) {
    if (this.callFrame) {
      return await this.callFrame.updateInputSettings({
        audio: {
          processor: {
            type: value ? 'noise-cancellation' : 'none'
          }
        }
      });
    }
  }
}
