import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {AnswerEquality, AnswerQuestion, EventQuestion, IGapResultDataMap} from '../../../../../../../model/EventQuestion';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-question-map-matching-results',
  templateUrl: './question-map-matching-results.component.html',
  styleUrls: ['./question-map-matching-results.component.scss']
})
export class QuestionMapMatchingResultsComponent extends AbstractQuizQuestionResultsComponent {

  gapResultData$ = new BehaviorSubject<IGapResultDataMap>({});
  answersData$ = new BehaviorSubject<AnswerQuestion[]>([]);
  correctEquality$ = new BehaviorSubject<AnswerEquality[]>([]);
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    const correctEquality = this.question.groupsCorrectAnswers?.filter(g => g.defaultGroup)?.reduce((acc, g) => {
      (g.correctEquality || []).forEach(it => acc.push(it));
      return acc;
    }, []) ?? [];
    this.answersData$.next((this.question.items || [])
      .map(v => new Object({...v, answer: v.getAnswerByLanguage(this.languageParams)}) as AnswerQuestion));
    this.correctEquality$.next(this.isPresenter  ?
      correctEquality : correctEquality.map(o => new AnswerEquality({id: o.id, mapPosition: o.mapPosition})));
    this.answers = this.groupSelectGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$.next(!this.editorMode ? this.answers : {});
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.groupSelectGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$.next(!this.editorMode ? this.answers : {});
  }

  private groupSelectGapFillingSummaryAnswers(question: EventQuestion, qContentAnswers: {[answerEquality: string]: number}) {
    const correctEquality = question.reduceGroupsCorrectAnswersCorrectEquality();
    const correctAnswers = correctEquality.map(o => `${o.id}=${o.answerId}`);
    const result: {[gapId: string]: {correctCount: number, errorCount: number, correctPercent: number, errorPercent: number}} =
      correctEquality.reduce((acc, item) => {
        acc[item.id] = {correctCount: 0, errorCount: 0, correctPercent: 0, errorPercent: 0};
        return acc;
      }, {});
    for (const answerEquality of Object.keys(qContentAnswers || {})) {
      const count = qContentAnswers[answerEquality] ?? 0;
      if (!count) {
        continue;
      }
      const av = answerEquality.split('=');
      if (result[av[0]]) {
        if (correctAnswers.includes(answerEquality)) {
          result[av[0]].correctCount += count;
        } else {
          result[av[0]].errorCount += count;
        }
      }
    }
    for (const r of Object.values(result)) {
      const maxCount = r.correctCount + r.errorCount;
      if (r.correctCount > 0) {
        r.correctPercent = 100 / (maxCount / r.correctCount);
      }
      if (r.errorCount > 0) {
        r.errorPercent = 100 / (maxCount / r.errorCount);
      }
    }
    return result;
  }

}
