import {Constants, FOLLOW_ME_USER_PARAMS, ISectionUserMeetingState, IUserMediaDevicesState} from '../../core/constants';
import {ChatMessage} from '../content/ChatMessage';

export type ConferenceUserRole = 'viewer' | 'editor' | 'translator';

export class ConferenceUser {
  userId: string;
  email: string;
  fullName: string;
  picture: string;
  blocked = false;
  guest = false;
  lastActivity = 0;
  joinedToEvent = 0;
  invitedToEvent = 0;
  userRoleViewer = false;
  roles: ConferenceUserRole[] = [];
  showWelcomeScreen = false;
  assistantId: string;
  devices: {};
  messages: ChatMessage[] = [];
  sections: {};
  followMeSettings: {
    followEvent: FOLLOW_ME_USER_PARAMS,
    followSections: {[sectionId: string]: FOLLOW_ME_USER_PARAMS}};
  sectionMeetingState: {[sectionId: string]: ISectionUserMeetingState};
  mediaDevicesState: IUserMediaDevicesState;
  userName: string;
  language: string;
  countryCode: string;
  country: string;
  permissionsAllowed: string[];
  permissionsDenied: string[];

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
    if (obj.fullName) {
      this.fullName = obj.fullName;
    }
    if (obj.picture) {
      this.picture = obj.picture;
    }
    if (obj.hasOwnProperty('blocked')) {
      this.blocked = obj.blocked;
    }
    if (obj.hasOwnProperty('guest')) {
      this.guest = obj.guest;
    }
    if (obj.lastActivity) {
      this.lastActivity = obj.lastActivity;
    }
    if (obj.joinedToEvent) {
      this.joinedToEvent = obj.joinedToEvent;
    }
    if (obj.invitedToEvent) {
      this.invitedToEvent = obj.invitedToEvent;
    }
    if (obj.devices) {
      this.devices = obj.devices;
    }
    if (obj.email) {
      this.email = obj.email;
    }
    if (obj.messages) {
      this.messages = Object.keys(obj.messages).map(function(k) {
        const modifyObj = obj.messages[k];
        modifyObj.fbId = k;
        return modifyObj;
      });
    }
    if (obj.userRoleViewer) {
      this.userRoleViewer = obj.userRoleViewer;
    }
    if (obj.roles) {
      this.roles = obj.roles;
    }
    if (obj.showWelcomeScreen) {
      this.showWelcomeScreen = obj.showWelcomeScreen;
    }
    if (obj.sections) {
      this.sections = obj.sections;
    }
    if (obj.followMeSettings) {
      this.followMeSettings = obj.followMeSettings;
    }
    if (obj.assistantId) {
      this.assistantId = obj.assistantId;
    }
    if (obj.sectionMeetingState) {
      this.sectionMeetingState = obj.sectionMeetingState;
    }
    if (obj.mediaDevicesState) {
      this.mediaDevicesState = obj.mediaDevicesState;
    }
    if (obj.userName) {
      this.userName = obj.userName;
    }
    if (obj.language) {
      this.language = obj.language;
    }
    if (obj.countryCode) {
      this.countryCode = obj.countryCode;
    }
    if (obj.country) {
      this.country = obj.country;
    }
    if (obj.permissionsAllowed) {
      this.permissionsAllowed = obj.permissionsAllowed;
    }
    if (obj.permissionsDenied) {
      this.permissionsDenied = obj.permissionsDenied;
    }
  }

  onlineInfo(pingDeviceList): string {
    let ret = '';
    const vm = this;
    if (this.devices) {
      Object.keys(this.devices).forEach(function (key) {
        const device = vm.devices[key];
        const pingTime = pingDeviceList ? pingDeviceList[key] : undefined;
          if (device['userOnline'] === true && !vm.exceedOnlineInterval(pingTime)) {
          ret = ret + (ret.length > 0 ?
            (', ' + vm.decorateDevice(device['platform'], device['userAgent'])) :
            vm.decorateDevice(device['platform'], device['userAgent']));
        }
      });
    }
    return ret;
  }

  private decorateDevice(platform: string, subplatform: string): string {
    if (platform === 'Win32') {
      return Constants.WINDOWS;
    }
    if (platform === 'Win64') {
      return Constants.WINDOWS;
    }
    if (platform === 'MacIntel') {
      return Constants.MAC_OS;
    }
    if (platform.match('Linux.')) {
      if (subplatform.match('.Android.')) {
        return Constants.ANDROID;
      } else {
        return Constants.LINUX;
      }
    }
    return platform;
  }


  exceedOnlineInterval(pingTime) {
    if (!pingTime) {return true; }
    if ((new Date()).getTime() - pingTime > Constants.ONLINE_INTERVAL) {
      return true;
    } else {
      return false;
    }
  }

  get userSections(): string[] {
    if (this.sections) {
      const result: string[] = [];
      Object.keys(this.sections).forEach(id => result.push(id));
      return result;
    } else {
      return [];
    }
  }

  setEmailAsUserId(email): ConferenceUser {
    this.userId = email ? email.replace(/\./g, '_').replace('@', '_') : null;
    return this;
  }

  getEventFollowMeUserParam() {
    return !!this.followMeSettings && !!this.followMeSettings.followEvent ?
      this.followMeSettings.followEvent : FOLLOW_ME_USER_PARAMS.FOLLOW_AS_PRESENTER;
  }

  getSectionFollowMeUserParam(sectionId) {
    return !!this.followMeSettings && !!this.followMeSettings.followSections && this.followMeSettings.followSections[sectionId] ?
      this.followMeSettings.followSections[sectionId] : FOLLOW_ME_USER_PARAMS.FOLLOW_AS_PRESENTER;
  }

  getSectionMeetingState(sectionId, fieldName) {
    return !!this.sectionMeetingState && !!this.sectionMeetingState[sectionId] ? this.sectionMeetingState[sectionId][fieldName] : null;
  }

  getMediaDevicesMicrophoneAccess() {
    return !!this.mediaDevicesState ? this.mediaDevicesState.microphoneAccess : null;
  }

  getMediaDevicesCameraAccess() {
    return !!this.mediaDevicesState ? this.mediaDevicesState.cameraAccess : null;
  }

  get isViewer() {
    return (this.roles || []).includes('viewer');
  }

  get isEditor() {
    return (this.roles || []).includes('editor');
  }

  get isTranslator() {
    return (this.roles || []).includes('translator');
  }
}


export class ResultConferenceUser extends ConferenceUser {
  userRole: number;

  constructor(obj: any) {
    super(obj);

    if (obj.hasOwnProperty('userRole')) {
      this.userRole = obj.userRole;
    }
  }
}
