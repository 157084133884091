export interface TextEditorCustomClass {
  name: string;
  class: string;
  tag?: string;
}

export interface TextEditorFont {
  name: string;
  class: string;
}

export interface TextEditorConfig {
  editable?: boolean;
  spellcheck?: boolean;
  height?: 'auto' | string;
  minHeight?: '0' | string;
  maxHeight?: 'auto' | string;
  width?: 'auto' | string;
  minWidth?: '0' | string;
  translate?: 'yes' | 'now' | string;
  enableToolbar?: boolean;
  showToolbar?: boolean;
  placeholder?: string;
  defaultParagraphSeparator?: string;
  defaultFontName?: string;
  defaultFontSize?: '15' | string;
  useSystemFonts?: true | boolean;
  uploadUrl?: string;
  fonts?: TextEditorFont[];
  customClasses?: TextEditorCustomClass[];
}

export const TextEditorConfig: TextEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '0',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: '0',
  translate: 'no',
  enableToolbar: true,
  showToolbar: true,
  placeholder: '',
  defaultParagraphSeparator: '',
  defaultFontName: '',
  defaultFontSize: '15px',
  fonts: [],
  uploadUrl: null,
};

export enum JUSTIFY {
  JUSTIFY_LEFT = 'justifyLeft',
  JUSTIFY_RIGHT = 'justifyRight',
  JUSTIFY_CENTER = 'justifyCenter',
  JUSTIFY_FULL = 'justifyFull'
}

export enum FONT_SIZE {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
  HUGE = 'huge'
}

export const FONT_SIZE_VALUES = {
  [FONT_SIZE.SMALL]: '0.75em',
  [FONT_SIZE.NORMAL]: '1em',
  [FONT_SIZE.LARGE]: '1.25em',
  [FONT_SIZE.HUGE]: '1.5em'
};

export const FONT_SIZE_CODE = {
  [FONT_SIZE.SMALL]: '1',
  [FONT_SIZE.NORMAL]: '2',
  [FONT_SIZE.LARGE]: '5',
  [FONT_SIZE.HUGE]: '6'
};

export const JUSTIFY_LIST = [JUSTIFY.JUSTIFY_LEFT, JUSTIFY.JUSTIFY_CENTER, JUSTIFY.JUSTIFY_RIGHT, JUSTIFY.JUSTIFY_FULL];

export const JUSTIFY_LIST_TITLE = {
  [JUSTIFY.JUSTIFY_LEFT]: 'text.editor.left.aligned',
  [JUSTIFY.JUSTIFY_CENTER]: 'text.editor.centered.aligned',
  [JUSTIFY.JUSTIFY_RIGHT]: 'text.editor.right.aligned',
  [JUSTIFY.JUSTIFY_FULL]: 'text.editor.justify.aligned'
};
