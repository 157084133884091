import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {EventsDataService} from '../../services/events-data.service';
import {CommonService} from '../../core/common.service';
import {UtilsService} from '../../core/utils.service';
import {IUsersSectionsData, TimeLineService} from '../../services/time-line.service';
import {UploadService} from '../../core/upload.service';
import {LoginService} from '../../login/login.service';
import {DRMService} from '../../core/drm.service';
import {ContentService} from '../../services/content.service';
import {TranslateApiService} from '../../services/translate-api.service';
import {clone, cloneDeep, isEmpty, merge, union} from 'lodash';
import {Event} from '../../model/Event';
import {AppUser} from '../../model/AppUser';
import {SectionContent, SectionUser} from '../../model/content/SectionContent';
import {RegistrationSettings} from '../../model/event-mode/RegistrationSettings';
import {MatTableDataSource} from '@angular/material/table';
import {InstantSettings, VirtualConferenceSettings} from '../../model/event-mode/InstantSettings';
import {BehaviorSubject, filter, firstValueFrom} from 'rxjs';
import {LANGUAGE} from '../../core/language-constants';
import {
  ADD_NEW_SLOT_MODE,
  AVAILABLE_MEETING_VIEW_MODE,
  CLOCK_TICKING,
  Constants,
  CONTENT_FROM_AUDIENCE_MODE,
  EMULATE_RESULT,
  EVENT_REGISTRATION_MODE,
  ILanguageParams,
  MEETING_VIEW_MODE,
  SECTION_USER_LIST_PAGE,
  TRIPLE,
  VIRTUAL_CONFERENCE_EVENT,
  VIRTUAL_CONFERENCE_TYPE
} from '../../core/constants';
import {ROW_TIME_TYPE, TimePoint} from '../../model/event-mode/RowDatesObject';
import {StdComponent} from '../../core/std-component';
import * as ui from '../../actions/ui';
import {PlaneContent} from '../../model/content/PlaneContent';
import {SectionTimeline} from '../../model/content/SectionTimeline';
import {TimeLineEmulator} from '../../time-line-emulator/time-line-emulator';
import {IRoom} from '../meeting-frame/services/daily-co.constants';
import {ContentDependencySettingsDialogComponent} from '../content-dependency-settings-dialog/content-dependency-settings-dialog.component';
import {EditSectionSettingDialogService} from './edit-section-setting-dialog.service';
import {ConferenceUser} from '../../model/event-mode/ConferenceUser';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {FeaturesService} from '../../core/features.service';
import {ExamsReferenceComponent} from '../../exams/exams/exams-reference/exams-reference.component';
import {LanguageService} from '../../services/language.service';
import {Exam} from '../../exams/exam-model/exam';

export interface INewSlotInitData {
  addMode?: ADD_NEW_SLOT_MODE;
  sectionInitiator?: SectionContent | SectionTimeline;
}

enum SECTION_SETTINGS_PAGE {
  GENERAL = 'general',
  TYPE = 'type',
  COVER = 'cover',
  INSTANT_GROUP_SETTINGS = 'instant-group-settings',
  EXAM_SETTINGS = 'exam-settings',
  FEEDBACK_RATING_PANEL = 'feedback-rating-panel',
  SECTION_SPECIFIC_Q_N_A = 'section-specific-q-n-a',
  VIDEO_CONF_STREAMING = 'video-conf-streaming',
  AUTOPILOT_SETTINGS_PANEL = 'autopilot-settings-panel',
  REMINDER_PANEL = 'reminder-panel',
  REGISTRATION_SETTINGS = 'registration-settings',
  DIGITAL_RIGHTS = 'digital-rights',
  PEOPLE = 'people'
}

const DIALOG_HEIGHT = '750px';
const DIALOG_WIDTH = '840px';

@Component({
  selector: 'app-edit-section-setting-dialog-v2',
  templateUrl: './edit-section-setting-dialog-v2.component.html',
  styleUrl: './edit-section-setting-dialog-v2.component.scss'
})
export class EditSectionSettingDialogV2Component extends StdComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly SECTION_SETTINGS_PAGE = SECTION_SETTINGS_PAGE;
  readonly Constants = Constants;
  readonly EVENT_REGISTRATION_MODE = EVENT_REGISTRATION_MODE;
  readonly CLOCK_TICKING = CLOCK_TICKING;
  readonly CONTENT_FROM_AUDIENCE_MODE = CONTENT_FROM_AUDIENCE_MODE;
  readonly VIRTUAL_CONFERENCE_EVENT = VIRTUAL_CONFERENCE_EVENT;
  readonly VIRTUAL_CONFERENCE_TYPE = VIRTUAL_CONFERENCE_TYPE;
  readonly MEETING_VIEW_MODE = MEETING_VIEW_MODE;
  readonly AVAILABLE_MEETING_VIEW_MODE = AVAILABLE_MEETING_VIEW_MODE;
  readonly ROW_TIME_TYPE = ROW_TIME_TYPE;

  activeSettings = SECTION_SETTINGS_PAGE.GENERAL;
  displayedColumns = ['picture', 'userName', 'speaker'];
  currentEvent: Event;
  currentUser: AppUser;
  section: SectionContent;
  exam: Exam;
  title: string;
  registrationSettings: RegistrationSettings;
  requiredEventRegistration: boolean;
  isAdminOrModerator: boolean;
  isSuperadmin = this.loginService.isSuperadmin();
  isAdmin = this.loginService.isAdmin();
  isPresenter: boolean;
  isSpeaker: boolean;
  isFeatureLine: boolean;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  resultSet = [];
  initData = {};
  onlineUsers: ConferenceUser[] = [];
  presenters = {};
  viewerUsers = {};
  searchValue = '';
  speakers_attendees: number = Constants.SPEAKERS_ATTENDEES;
  sectionTypeList: any[] = [];
  containerFieldDisable;
  selfLearningSectionFieldsDisable;
  eventInstantSettings: InstantSettings;
  parentIsContainer = false;
  changedUserRole = {};
  pageLength = SECTION_USER_LIST_PAGE.INIT_PAGE_LENGTH;
  paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.changeDetector);
  isInstantGroupRestrictAccessMembers = false;
  oldBackgroundImage: string;
  oldUsersList: SectionUser[] = [];
  firstOpen = true;
  usersData: IUsersSectionsData[];
  freeSlotMode = false;
  slotTimeRestricted = false;
  srcVirtualSettings: VirtualConferenceSettings;
  newSlotInitData: INewSlotInitData = {};
  currentLanguage: LANGUAGE;
  defaultLanguage: LANGUAGE;
  multilingual = false;
  usedLanguages: string[];
  languageParams: ILanguageParams;
  translateHint: string;
  linkToLiveStreamingValid = true;
  sectionHash: string;
  eventOptionExamEnabled = false;
  sectionDetectChanges = new BehaviorSubject<boolean>(false);
  isModify = false;
  isUsersModify = false;
  sectionChangesHandler = {
    detectChanges: this.sectionDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<EditSectionSettingDialogV2Component>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<fromRoot.State>,
              public eventDataService: EventsDataService,
              public common: CommonService,
              private utils: UtilsService,
              public dialog: MatDialog,
              public timeLineService: TimeLineService,
              public languageService: LanguageService,
              public changeDetector: ChangeDetectorRef,
              public uploadService: UploadService,
              public loginService: LoginService,
              public drmService: DRMService,
              public contentService: ContentService,
              private translateApiService: TranslateApiService,
              private settingService: EditSectionSettingDialogService,
              private featuresService: FeaturesService,
              private elementRef: ElementRef) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.addPanelClass('questions-participants-detail');
    dialogRef.updateSize(DIALOG_WIDTH);
    this.elementRef.nativeElement.style.setProperty('--mdc-dialog__height', DIALOG_HEIGHT);
    dialogRef.disableClose = true;
    this.currentEvent = this.timeLineService.timelineEvent;
    this.eventOptionExamEnabled = this.timeLineService.instantSettings.examsEnabled && this.featuresService.isExamsEnabled();
    this.oldBackgroundImage = data.section.backgroundImage;
    this.setSectionObjects(data);
    this.newSlotInitData = data.newSlotInitData;
    this.freeSlotMode = this.section.freeSlotType;
    if (!this.freeSlotMode) {
      this.title = !this.section.id ? this.utils.i18n('action.add_section') :
        this.utils.i18n('title.edit.content.section');
      if (this.timeLineService.event.slotMode && this.timeLineService.timelineSlotMode.getValue() && !this.section.id) {
        this.section.education = true;
      }
      if (this.loginService.educationMode && !this.section.id) {
        this.section.sectionTimeIsNotActive = true;
        this.section.education = true;
      }
    } else {
      this.title = !this.section.id ? this.utils.i18n('slot.mode.add.slot') :
        this.utils.i18n('slot.mode.edit.slot');
      if (!this.section.id) {
        this.setNewSlotCalculatedTime();
      }
    }
    this.isFeatureLine = data.isFeatureLine;
    this.currentUser = data.currentUser;
    this.isPresenter = data.isPresenter;
    this.isSpeaker = this.timeLineService.hasSpeakerAccessRightsToSection(this.section);
    this.isAdminOrModerator = data.isAdminOrModerator;
    this.requiredEventRegistration = data.requiredEventRegistration;
    this.sectionTypeList = data.sectionTypeList;
    this.displayedColumns = !this.section.isPublic ?
      ['picture', 'userName', 'speaker', 'attendee'] : ['picture', 'userName', 'speaker'];
    this.setDisabledFields();
    this.parentIsContainer = this.getParentContainerProperty();
    this.eventInstantSettings = this.timeLineService.eventInstantSettings;
    this.isInstantGroupRestrictAccessMembers =
      this.section.parentId && this.timeLineService.planeListContent[this.section.parentId] &&
      this.timeLineService.planeListContent[this.section.parentId].sectionContent.isInstantGroupRestrictAccessMembers();
    this.defaultLanguage = this.languageService.DEFAULT_LANGUAGE();
    this.currentLanguage = this.defaultLanguage;
    const ml = this.languageService.event().getMultilingual();
    this.multilingual = ml.multilingual;
    this.usedLanguages = ml.usedLanguages;
    this.languageParams = {
      defaultLanguage: this.defaultLanguage,
      currentLanguage: this.currentLanguage,
      usedMultilingualContent: this.multilingual,
      usedLanguages: this.usedLanguages
    };
    this.translateHint = this.translateApiService.getTranslateHint(this.defaultLanguage, this.currentLanguage);
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get enabledLanguages(): string[] {
    return this.section.enabledLanguages || [];
  }

  set enabledLanguages(value: string[]) {
    this.section.enabledLanguages = value;
  }

  roleCanEditContent() {
    return this.settingService.roleCanEditContent(this.isPresenter, this.isSpeaker);
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this.sectionDetectChanges.pipe(filter(() => !!this.sectionHash && !!this.section?.id), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.sectionHash !== this.getSectionHash(this.section, this.registrationSettings);
      });
    if (this.roleCanEditContent()) {
      return;
    }
    this.paginator.pageSize = this.pageLength;
    this.paginator.ngOnInit();
    this.dataSource.paginator = this.paginator;
    this.store.select(fromRoot.getUsersOnline).pipe(this.takeUntilAlive())
      .subscribe(uOnline => {
        if (isEmpty(uOnline) && this.firstOpen) {
          this.firstOpen = false;
          this.eventDataService.dispatchUsersOnline(this.currentEvent.eventId);
        } else if (!isEmpty(uOnline)) {
          this.presenters = {};
          const ul = {};
          uOnline.forEach(u => {
            if (u.isViewer) {
              this.viewerUsers[u.userId] = true;
            }
          });
          this.onlineUsers = union(uOnline,
            Object.keys(this.currentEvent.managers || {})
              .map(userId => new ConferenceUser({userId: userId, ...this.currentEvent.managers[userId]})),
            Object.keys(this.currentEvent.speakers || {})
              .map(userId => new ConferenceUser({userId: userId, ...this.currentEvent.speakers[userId]})));
          const idList = Object.keys(this.currentEvent.presenters ? this.currentEvent.presenters : {});
          for (let i = 0; i < idList.length; i++) {
            const u = this.getOnlineUser(idList[i]);
            if (u) {
              ul[u.userId] = u;
            }
          }
          this.presenters = ul;
          this.dataSource.data = this.reinitData();
        }
      });
    if (!this.isPresenter && !!this.section.id) {
      this.eventDataService.getSectionUsersDataPromise(this.currentEvent.eventId, this.section.id)
        .then((value) => {
          this.dataSource.data = this.reinitData();
        });
    }
    this.eventDataService.getUsersDataPromise(this.currentEvent.eventId).then(value => {
      this.usersData = value;
      if (this.section.id && !isEmpty(this.usersData)) {
        this.oldUsersList = this.usersData.filter(u => (u.sections || []).find(sId => sId === this.section.id))
          .map(u => new SectionUser({userId: u.userId, attendee: true}));
      }
      this.dataSource.data = this.reinitData();
    });
    this.settingService.freeSlotTimeChange.pipe(this.takeUntilAlive())
      .subscribe(changeValue => {
        if (!this.section.id) {
          let sectionInitiator = this.newSlotInitData.sectionInitiator;
          const initiatorRoot = sectionInitiator.isRoot;
          if (this.newSlotInitData.addMode === ADD_NEW_SLOT_MODE.ABOVE) {
            const list = this.timeLineService.planeListContentSortedWithoutFixedAsSectionArray()
              .filter(ps => ps.parentId === this.timeLineService.rootSection.id);
            const index = list.findIndex(s => s.id === sectionInitiator.id);
            if (index === 0) {
              sectionInitiator = new SectionContent({...cloneDeep(this.timeLineService.rootSection),
                parentId: this.timeLineService.rootSection.id});
            } else {
              sectionInitiator = list[index - 1];
            }
          }
          this.contentService.cutContent(this.section, Constants.CONTENT_CLIPBOARD_CUT);
          this.contentService.clipboard.moveSectionList.push(new PlaneContent(new SectionTimeline(this.section), null));
          const emulator = new TimeLineEmulator(this.timeLineService, this.timeLineService.getManageTimeService(),
            this.contentService, this.utils, this.loginService.getAppUser(), true, sectionInitiator, !initiatorRoot);
          emulator.runEmulator(EMULATE_RESULT.AS_IS);
          const slotTP = this.timeLineService.getManageTimeService().testManageTimeMap['1'];
          const checkError = this.timeLineService.getManageTimeService()
            .checkCorrectTimeMap(slotTP, this.timeLineService.getManageTimeService().testManageTimeMap);
          this.slotTimeRestricted = !checkError.noError;
          this.contentService.cancelCutContent();
        } else {
          const slotTP = this.timeLineService.mangeTimeMap[this.section.id];
          const testTP = this.timeLineService.getManageTimeService()
            .createTestTimePoint(slotTP, changeValue.value, changeValue.type);
          this.timeLineService.createNewMangeTime(testTP, false);
          const checkError = this.timeLineService.getManageTimeService()
            .checkCorrectTimeMap(testTP, this.timeLineService.mangeTimeMap);
          this.slotTimeRestricted = !checkError.noError;
        }
      });
  }

  onDestroy() {
    super.onDestroy();
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  ngAfterViewInit() {
    if (this.roleCanEditContent()) {
      const panels = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('mat-expansion-panel');
      const length = panels.length;
      for (let i = 0; i < length; i++) {
        panels.item(0).remove();
      }
    }
  }

  onOkClick() {
    this.setSectionResultValues();
    this.dialogRef.close({
      section: this.section,
      registrationSettings: this.registrationSettings,
      updatedMeetingRooms: this.checkPermanentRoomsBeforeSaveSetting()
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setSectionResultValues() {
    this.section.users = this.getSectionUsersFromTable();
    if (this.section.id && !this.section.sectionTypeId) {
      this.section = merge(this.section, {sectionTypeId: null});
    }
    if (!!this.currentEvent.readonlyHKVBS) {
      this.section.sectionTimeIsNotActive = true;
    }
    if (this.section.isInstantGroup) {
      if (!this.section.id) {
        this.section.sectionTimeIsNotActive = true;
      }
    }
    if (this.oldBackgroundImage && !this.section.backgroundImage) {
      this.section.backgroundImage = '-' + this.oldBackgroundImage;
    }
  }

  getOnlineUser(userId: string): ConferenceUser {
    return this.onlineUsers.find( function (user) {
      return user.userId === userId;
    });
  }

  private reinitData() {
    const localOnlineUsers = this.onlineUsers.slice();
    const sectionUserData = !isEmpty(this.usersData) ?
      this.usersData : [];

    for (const eUser of localOnlineUsers) {
      const uId = eUser.userId;
      if (eUser && !eUser.blocked) {
        let attendeeSection: IUsersSectionsData;
        this.initData[uId] = {
          userId: uId,
          picture: eUser.picture ? eUser.picture : 'assets/images/photo.jpg',
          userName: eUser.fullName ? eUser.fullName : eUser.email,
          email: eUser.email,
          speakers_attendees: (this.presenters[uId] || this.currentEvent.speakers[uId] || this.currentEvent.concierges[uId]) ?
            (this.currentEvent.concierges[uId] ? Constants.CONCIERGES : Constants.SPEAKERS) : Constants.ATTENDEES,
          rightAboveSpeaker: this.rightAboveSpeaker(eUser) === Constants.ACCESS_SET_ABOVE,
          rightAboveAttendee: this.rightAboveAttendee(eUser) === Constants.ACCESS_SET_ABOVE,
          speaker: !(this.changedUserRole[uId] &&
            this.changedUserRole[uId]['roleType'] === Constants.SPEAKERS && this.changedUserRole[uId]['changed']) ?
            this.timeLineService.isSpeakerContent(eUser, this.section as SectionTimeline) :
            this.initData[uId].speaker,
          attendee: !(this.changedUserRole[uId] &&
            this.changedUserRole[uId]['roleType'] === Constants.ATTENDEES && this.changedUserRole[uId]['changed']) ?
            this.timeLineService.isAttendeeContent(eUser, this.section as SectionTimeline,
              ((attendeeSection = sectionUserData.find(o => o.userId === uId)) ? attendeeSection.sections : [])) :
            this.initData[uId].attendee,
          userRoleViewer: this.viewerUsers[uId],
        };
        this.initData[uId].rowHash = UtilsService.jsonSorted({attendee: this.initData[uId].attendee, speaker: this.initData[uId].speaker});
      }
    }
    return this.getResultSet();
  }

  private getResultSet(): any [] {
    this.resultSet = [];
    for (const rowId of Object.keys(this.initData)) {
      if (this.searchFilter(this.initData[rowId]) &&
        this.speakersAttendesAddRow(this.initData[rowId])) {
        const rowData = this.initData[rowId];
        this.resultSet.push(rowData);
      }
    }
    return this.resultSet;
  }

  applyFilter(searchValue: string) {
    this.searchValue = searchValue;
    this.dataSource.data = this.getResultSet();
  }

  private searchFilter(row): boolean {
    if (this.searchValue.length > 0) {
      return (row.userName && row.userName.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) ||
        (row.email && row.email.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1);
    } else {
      return true;
    }
  }

  onSpeakersAttendeesFilter(status) {
    this.speakers_attendees = status;
    this.dataSource.data = this.getResultSet();
  }

  private speakersAttendesAddRow(row) {
    if (this.speakers_attendees === Constants.SPEAKERS_ATTENDEES) {
      return true;
    }
    if (this.speakers_attendees === Constants.SPEAKERS &&
      row.speakers_attendees === Constants.SPEAKERS) {
      return true;
    }
    return this.speakers_attendees === Constants.ATTENDEES &&
      row.speakers_attendees === Constants.ATTENDEES;
  }

  private currentAccess(userId): SectionUser {
    if (!this.section.users) {return null; }
    return this.section.users.find(user => user.userId === userId);
  }

  private rightAboveSpeaker(cUser) {
    if (this.currentAccess(cUser.userId) && this.currentAccess(cUser.userId).speaker) {
      return Constants.ACCESS_SET_CURRENT;
    } else
    if (this.timeLineService.isSpeakerSectionExcludeItself(cUser, this.section as SectionTimeline)) {
      return Constants.ACCESS_SET_ABOVE;
    } else {
      return Constants.ACCESS_NOT_SET;
    }
  }

  private rightAboveAttendee(cUser) {
    const _userData = this.usersData;
    let sectionUserData: IUsersSectionsData;
    if (!isEmpty(_userData)) {
      sectionUserData = _userData.find(o => o.userId === cUser.userId);
    }
    if (this.currentAccess(cUser.userId) && this.currentAccess(cUser.userId).attendee) {
      return Constants.ACCESS_SET_CURRENT;
    } else
    if (this.timeLineService.isAttendeeSectionExcludeItself(
      cUser, this.section as SectionTimeline, !isEmpty(sectionUserData) ? sectionUserData.sections : [])
    ) {
      return Constants.ACCESS_SET_ABOVE;
    } else {
      return Constants.ACCESS_NOT_SET;
    }
  }

  getParentContainerProperty() {
    return this.timeLineService.planeListContent[this.section.parentId] &&
      this.timeLineService.planeListContent[this.section.parentId].container;
  }

  private sectionCanBeContainer() {
    let hasContainer, hasChildsChilds, hasFeatureLineWithin;
    // if content.id null this is new content.
    if (this.section.id) {
      const childList = this.timeLineService.childTreeInLineList(this.section.id);
      // check if some child section as container
      hasContainer = childList.some(item => item['container'] && item.id !== this.section.id);
      // check if some child have some child
      hasChildsChilds = childList.some(
        item => item.id !== this.section.id && item.items && item.items.length > 0
      );
      // check if some child is "featureLine" aka "currentTime"
      hasFeatureLineWithin = childList.some(item => item.id !== this.section.id && item.id === this.timeLineService.featureLineContentId);
    }
    // check if parent is container.
    const parentIsContainer = this.timeLineService.planeListContent[this.section.parentId] &&
      this.timeLineService.planeListContent[this.section.parentId].container;
    return hasContainer || hasChildsChilds || parentIsContainer || hasFeatureLineWithin;
  }

  displayPublicChange(event) {
    this.section.isPublic = !event.checked;
    this.displayedColumns = !this.section.isPublic ?
      ['picture', 'userName', 'speaker', 'attendee'] : ['picture', 'userName', 'speaker'];
  }

  onRoleChange(event: MatCheckboxChange, row, roleType) {
    const user = row;
    this.changedUserRole[row.userId] = {roleType: roleType,
      changed: !(this.changedUserRole[row.userId] ? this.changedUserRole[row.userId]['changed'] : false)};
    if (roleType === Constants.SPEAKERS) {
      const aboveValue = this.rightAboveSpeaker(user);
      if (row.rightAboveSpeaker && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.speaker = true;
        row.rightAboveSpeaker = false;
        event.checked = true;
      } else
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.speaker = true;
        row.rightAboveSpeaker = true;
        event.checked = true;
      }
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.speaker = this.timeLineService.isSpeakerSectionExcludeItself(user, this.section as SectionTimeline);
        row.rightAboveSpeaker = row.speaker;
        event.checked = row.speaker;
      }
      if (row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.speaker = this.timeLineService.isSpeakerSectionExcludeItself(user, this.section as SectionTimeline);
        row.rightAboveSpeaker = false;
        event.checked = row.speaker;
      }
    }

    if (roleType === Constants.ATTENDEES) {
      const _userData = this.usersData;
      let sectionUserData: IUsersSectionsData;
      if (!isEmpty(_userData)) {
        sectionUserData = _userData.find(o => o.userId === user.userId);
      }
      const aboveValue = this.rightAboveAttendee(user);
      if (row.rightAboveAttendee && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.attendee = true;
        row.rightAboveAttendee = false;
        event.checked = true;
      } else
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.attendee = true;
        row.rightAboveAttendee = true;
        event.checked = true;
      }
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.attendee = this.timeLineService.isAttendeeSectionExcludeItself(user,
          this.section as SectionTimeline, !isEmpty(sectionUserData) ? sectionUserData.sections : []);
        row.rightAboveAttendee = row.attendee;
        event.checked = row.attendee;
      }
      if (row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.attendee = this.timeLineService.isAttendeeSectionExcludeItself(user,
          this.section as SectionTimeline, !isEmpty(sectionUserData) ? sectionUserData.sections : []);
        row.rightAboveAttendee = false;
        event.checked = row.attendee;
      }
    }
    this.isUsersModify = this.dataSource.data.some(u =>
        u.rowHash !== UtilsService.jsonSorted({attendee: this.initData[u.userId].attendee, speaker: this.initData[u.userId].speaker}));
  }

  getSectionUsersFromTable() {
    const formatRow = (row) => {
      const ret = clone(row);
      ret.speaker = !ret.rightAboveSpeaker ? ret.speaker : false;
      ret.attendee = !ret.rightAboveAttendee ? ret.attendee : false;
      return ret;
    };

    const res: SectionUser[] = [];
    for (const row of this.utils.objectValues(this.initData)) {
      if (!row['rightAboveSpeaker'] && row['speaker']) {
        res.push(new SectionUser().setValues(formatRow(row)));
      } else
      if (!row['rightAboveAttendee'] && row['attendee']) {
        res.push(new SectionUser().setValues(formatRow(row)));
      }
    }
    // add and mark deleted users
    for (const u of this.oldUsersList) {
      if (isEmpty(res.find(o => o.userId === u.userId))) {
        u.userId = '*' + u.userId;
        res.push(u);
      }
    }
    return res;
  }

  onscroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const buffer = SECTION_USER_LIST_PAGE.BUFFER;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.pageLength += SECTION_USER_LIST_PAGE.PAGE_STEP;
      this.paginator._changePageSize(this.pageLength);
    }
  }

  checkPermanentRoomsBeforeSaveSetting() {
    if (this.section.specificVideoConferences && this.section.virtualConferenceSettings &&
      this.section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE &&
      this.section.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO &&
      this.section.virtualConferenceSettings.dailyCoSettings &&
      this.section.virtualConferenceSettings.dailyCoSettings.usePermanentRooms) {
      // sync with created dailyCo room
      const srcRooms: IRoom[] = this.srcVirtualSettings?.dailyCoSettings?.permanentRooms;
      const curRooms: IRoom[] = this.section.virtualConferenceSettings.dailyCoSettings.permanentRooms;
      (srcRooms || []).forEach(r => r['draft'] = true);
      (curRooms || []).forEach(r => {
        const rObj = (srcRooms || []).find(sr => sr.roomId === r.roomId);
        if (rObj) {
          delete rObj['draft'];
        }
      });
      return {deleted: (srcRooms || []).filter(r => r['draft']), updated: curRooms};
    }
    return null;
  }

  setNewSlotCalculatedTime() {
    let sectionInitiator = this.newSlotInitData.sectionInitiator;
    const initiatorRoot = sectionInitiator.isRoot;
    if (this.newSlotInitData.addMode === ADD_NEW_SLOT_MODE.ABOVE) {
      const list = this.timeLineService.planeListContentSortedWithoutFixedAsSectionArray()
        .filter(ps => ps.parentId === this.timeLineService.rootSection.id);
      const index = list.findIndex(s => s.id === sectionInitiator.id);
      if (index === 0) {
        sectionInitiator = new SectionContent({...cloneDeep(this.timeLineService.rootSection),
          parentId: this.timeLineService.rootSection.id});
      } else {
        sectionInitiator = list[index - 1];
      }
    }
    this.contentService.cutContent(this.section, Constants.CONTENT_CLIPBOARD_CUT);
    this.contentService.clipboard.moveSectionList.push(new PlaneContent(new SectionTimeline(this.section), null));
    const emulator = new TimeLineEmulator(this.timeLineService, this.timeLineService.getManageTimeService(),
      this.contentService, this.utils, this.loginService.getAppUser(), true, sectionInitiator, !initiatorRoot);
    emulator.runEmulator(EMULATE_RESULT.AS_IS);
    const slotTP: TimePoint = this.timeLineService.getManageTimeService().testManageTimeMap['1'];

    this.section.plannedTime = slotTP.startValue;
    this.section.plannedTimeFixed = true;
    this.section.plannedTimeFixedValue = this.section.plannedTime;

    this.section.duration = Math.trunc((slotTP.endValue - slotTP.startValue) / Constants.ONE_MINUTE_MS);
    this.section.durationFixedValue = this.section.duration;
    this.section.durationFixed = false;

    this.section.endTime = slotTP.endValue;
    this.section.endTimeFixed = true;
    this.section.endTimeFixedValue = slotTP.endValue;

    this.contentService.cancelCutContent();
  }

  showDependencyDialog() {
    let dialogWidth = '450px';
    if (window.innerWidth < 450) {
      dialogWidth = '99%';
    }
    const dialogRef = this.dialog.open(ContentDependencySettingsDialogComponent, {
      width: dialogWidth
      , minWidth: dialogWidth
      , disableClose: true
      , data: {
        eventId: this.timeLineService.event.eventId,
        dependencyQuestionnaire: this.section.dependencyQuestionnaire
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.section.dependencyQuestionnaire = !isEmpty(result) ? result : null;
      }
    });
  }

  onLanguageChange(value) {
    this.currentLanguage = value;
    this.languageParams = {
      defaultLanguage: this.defaultLanguage,
      currentLanguage: this.currentLanguage,
      usedMultilingualContent: this.multilingual,
      usedLanguages: this.usedLanguages
    };
    this.translateHint = this.translateApiService.getTranslateHint(this.defaultLanguage, this.currentLanguage);
  }

  setSectionObjects(data) {
    this.exam = (this.timeLineService.exams ?? []).find(ex => ex.examId === data.section.examId);
    this.section = UtilsService.wrapObjectToProxy(cloneDeep(data.section), this.sectionChangesHandler);
    this.registrationSettings = UtilsService.wrapObjectToProxy(data.registrationSettings ?
        cloneDeep(data.registrationSettings) : new RegistrationSettings(), this.sectionChangesHandler);
    this.srcVirtualSettings = cloneDeep(data.section.virtualConferenceSettings);
    this.sectionHash = this.getSectionHash(this.section, this.registrationSettings);
    this.isModify = !this.section.id;
  }

  getSectionHash(section: SectionContent, registrationSettings: RegistrationSettings, ) {
    const s = UtilsService.jsonSorted(section);
    const r = UtilsService.jsonSorted(registrationSettings);
    return UtilsService.md5(s + r);
  }

  isLiveStreamLinkValid(isLiveStreamLinkValid: boolean) {
    this.linkToLiveStreamingValid = isLiveStreamLinkValid;
  }

  setDisabledFields() {
    this.selfLearningSectionFieldsDisable = !isEmpty(this.timeLineService.isSectionHierarchicalParentSelfLearning(this.section)) ||
      (this.section.id && this.timeLineService.hasSelfLearningSectionInChilds(this.section)) ||
      this.section.freeSlotType || !isEmpty(this.section.examId);
    this.containerFieldDisable = !this.selfLearningSectionFieldsDisable && this.sectionCanBeContainer();
  }

  async onLinkExam() {
    if (!this.section.examId) {
      let result: any;
      if (!!(result = await firstValueFrom(this.dialog.open(ExamsReferenceComponent).afterClosed()))) {
        this.section.examId = result.exam.examId;
        this.exam = result.exam;
      }
    } else {
      this.section.examId = null;
    }
    this.setDisabledFields();
  }
}
