import {Injectable} from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {StorageDataService} from '../../../../services/storage-data.service';
import {CommonService} from '../../../../core/common.service';
import {IContainerAnnotationsMap, IExtAnnotationValue, ITextNodeFile, TextNode} from './text-note-model/text-note-model';
import {ITEM_MARKER} from '../../shared/container-interface';
import {isEmpty, merge} from 'lodash';
import {ILanguageParams} from '../../../../core/constants';
import {UtilsService} from '../../../../core/utils.service';
import {EditDialogComponent} from '../../../../dialog-components/edit-dialog/edit-dialog.component';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextNoteService extends AbstractContainerService {

  public static prepareId(id: string) {
    return id.startsWith(ITEM_MARKER.NEW) ? id.substring(1) : id;
  }

  public static getAnnotationByType(annotations: IContainerAnnotationsMap, annotationType: string, annotationTypeTitle: string,
                                    isMenuSingleMode?: (value) => void): IExtAnnotationValue[] {
    const containerList = annotations[annotationType].filter(o => !isEmpty(o.values));
    let currentAnnotationList: IExtAnnotationValue[] = [];
    if (containerList.length > 1) {
      if (isMenuSingleMode) {
        isMenuSingleMode(false);
      }
      currentAnnotationList = containerList.map((o, index) => {
        return new Object({
          containerId: o.containerId.replace(ITEM_MARKER.NEW, ''),
          title: `${annotationTypeTitle} ${index + 1}`,
          values: o.values.map((v, idx) => new Object({
            orderNumber: idx + 1,
            containerId: o.containerId.replace(ITEM_MARKER.NEW, ''),
            type: annotationType, ...v}))
        }) as IExtAnnotationValue;
      });
    } else {
      if (isMenuSingleMode) {
        isMenuSingleMode(true);
      }
      currentAnnotationList = containerList[0].values
        .map((v, idx) => new Object({
          orderNumber: idx + 1,
          containerId: annotations[annotationType][0].containerId.replace(ITEM_MARKER.NEW, ''),
          type: annotationType,
          ...v
        }) as IExtAnnotationValue);
    }
    return currentAnnotationList;
  }

  public static addFileToList(list, languageParams: ILanguageParams, common: CommonService, add: (value) => void) {
    const langObject = () => languageParams.usedMultilingualContent ? {lang: languageParams.currentLanguage} : {};
    for (const item of list) {
      const prefix = UtilsService.createId(4, true).toLowerCase();
      const trackId = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}`;
      const id = `${trackId}.${item.extension}`;
      const itemTitle = item.name.replace(`.${item.extension}`, '');
      TextNoteService.editAudioTrackTitle(itemTitle, common, (title) => {
        if (add) {
          add(merge({
            id: `${ITEM_MARKER.NEW}${id}`,
            src: item.response,
            trackId: trackId,
            title: title ?? itemTitle,
            mimeType: item.type
          }, langObject()));
        }
      });
    }
  }

  public static async editAudioTrackTitle(title: string, common: CommonService, edit: (value) => void) {
    const dialogTitle = common.i18n('common.edit') + ' ' + common.i18n('common.title').toLowerCase();
    const dialogData = {title: dialogTitle
      , fields: [
        {id: 'title', type: 'text'}
      ]
      , result: {title: title}
    };
    return await firstValueFrom(common.dialog.open(EditDialogComponent, {
      width: '350px',
      data: dialogData
    }).afterClosed())
      .then(result => {
        if (result) {
          if (edit) {
            edit(result.title);
          }
        }
      });
  }

  public static deleteNotUsedAudio(data: TextNode) {
    let text: string;
    if (!data) {
      return;
    }
    if (isEmpty(data.text) || typeof data.text === 'string') {
      text = String(data.text ?? '');
    } else if (typeof data.text === 'object') {
      text = Object.values(data.text).map(v => v ?? '').join(' ');
    }
    const files: ITextNodeFile[] = data.files ?? [];
    for (const f of files) {
      if (!text.includes(f.trackId)) {
        f.id = `${ITEM_MARKER.DELETED}${f.id}`;
      }
    }
  }

  constructor(private storageDataService: StorageDataService,
              private common: CommonService) {
    super();
  }

  async save(path: string, textNode): Promise<any> {
    if (textNode?.hasOwnProperty('files')) {
      const deletedIds: string[] = [];
      const files: ITextNodeFile[] = textNode.files ?? [];
      for (const file of files) {
        if (file.id.startsWith(ITEM_MARKER.NEW)) {
          const mimeType = file.mimeType;
          const fileName = this.common.utils.addTimeToFileName(file.id.replace(ITEM_MARKER.NEW, ''));
          await this.storageDataService.uploadAnyObjectToStorageByDataPath(path, fileName, file.src, mimeType)
            .then(snapshot => snapshot.ref.getDownloadURL()
              .catch(e => this.common.log.error(e))
              .then(url => file.src = url))
            .catch(e => this.common.log.error(e));
        } else if (file.id.startsWith(ITEM_MARKER.DELETED)) {
          const fileName = this.common.utils.extractFileNameFromUrl(file.src);
          await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
            .catch(e => this.common.log.error(e));
        }
        file.id = file.id.replace(ITEM_MARKER.NEW, '');
        if (file.id.startsWith(ITEM_MARKER.DELETED)) {
          deletedIds.push(file.id);
        }
      }
      for (const id of deletedIds) {
        const index = files.findIndex(it => it.id === id);
        files.splice(index, 1);
      }
    }
    return Promise.resolve(textNode);
  }

  async delete(path: string, textNode): Promise<boolean> {
    if (textNode?.hasOwnProperty('files')) {
      const files = textNode.files ?? [];
      for (const it of (files || [])) {
        const fileName = this.common.utils.extractFileNameFromUrl(it.src);
        await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
          .catch(e => this.common.log.error(e));
      }
    }
    return Promise.resolve(false);
  }
}
