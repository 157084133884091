import {Constants} from '../../core/constants';
import {COUNTRY_LANGUAGE} from '../../core/language-constants';
import {isEmpty, union} from 'lodash';
import {EXAM_ASSESSMENT_MODE, EXAM_LAUNCH_TYPE, EXAM_STATUS, IEventLink, IExamManagers, TExamUser} from '../exam-constants/exam-constants';

export class Exam {
  examId: string;
  createTime = new Date().getTime();
  title: string;
  description: string;
  owner: TExamUser;
  languages = [];
  defaultLanguage = Constants.TIMELINE_DEFAULT_LANGUAGE;
  users: IExamManagers;
  permissions: string[]; // users id list access to exam
  sectionsUsers: string[];  // users id list access to sections
  combinedUsers: string[]; // combined users id list with access to exam, sections, exam owner
  contentsCount = 0;
  totalPoints = 0;
  maxPoints = 0;
  countDone = 0;
  pointsDone = 0;
  countReviewed = 0;
  pointsReviewed = 0;
  countApproved = 0;
  pointsApproved = 0;
  coverImage: string;
  examLaunchType: EXAM_LAUNCH_TYPE = EXAM_LAUNCH_TYPE.SELF_START;
  countUsersExamAvailable: number;
  countUsersExamReadyToStart: number;
  countUsersExamRunning: number;
  countUsersExamPaused: number;
  countUsersExamEnded: number;
  countUsersExamAssessment: number;
  countUsersExamResults: number;
  countUsersExamInterrupted: number;
  anonymousAssessment: boolean;
  anonymousAssessmentQuickViewingNames: boolean;
  duration = 0;
  status: EXAM_STATUS = EXAM_STATUS.READY_TO_CREATE;
  eventsLinks: IEventLink[];
  assessmentMode: EXAM_ASSESSMENT_MODE = EXAM_ASSESSMENT_MODE.SIMPLE;
  archive: boolean;
  closed: boolean;

  constructor(obj?) {
    for (const name of Object.keys(obj ?? {})) {
      if (this.classFieldsNames().includes(name)) {
        this[name] = obj[name];
      }
    }
  }

  protected classFieldsNames(): string[] {
    return [
      'examId',
      'createTime',
      'title',
      'description',
      'owner',
      'status',
      'coverImage',
      'anonymousAssessment',
      'anonymousAssessmentQuickViewingNames',
      'duration',
      'languages',
      'defaultLanguage',
      'users',
      'permissions',
      'sectionsUsers',
      'combinedUsers',
      'contentsCount',
      'totalPoints',
      'maxPoints',
      'countDone',
      'pointsDone',
      'countReviewed',
      'pointsReviewed',
      'countApproved',
      'pointsApproved',
      'eventsLinks',
      'archive',
      'closed',
      'countUsersExamAvailable',
      'countUsersExamReadyToStart',
      'countUsersExamRunning',
      'countUsersExamPaused',
      'countUsersExamEnded',
      'countUsersExamAssessment',
      'countUsersExamResults',
      'countUsersExamInterrupted',
      'assessmentMode',
      'examLaunchType'
    ];
  }

  getMultilingual() {
    const languageList = Object.values(COUNTRY_LANGUAGE).map(l => l.languageISOCode);
    const multilingual = (this.languages || []).filter(l => languageList.includes(l)).length > 1;
    const usedLanguages = (this.languages || []).filter(l => languageList.includes(l));
    return {multilingual, usedLanguages};
  }

  getLanguages() {
    return !isEmpty(this.languages) ? this.languages : [Constants.TIMELINE_DEFAULT_LANGUAGE];
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || typeof object[key] === 'function')) {
            delete object[key];
          } else if (object[key] === null) {
            object[key] = null;
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = {...this};
    delete obj.examId;
    checkUnsupportedValue(obj);
    obj.permissions = union(Object.keys(this.users || {}), [this.owner.userId]);
    obj.combinedUsers = union(obj.permissions, obj.sectionsUsers ?? []);
    return obj;
  }

}
