import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {Constants, SMILE_RATING, SMILES_RATING_SETS} from '../../../../../../../core/constants';
import {IAnswer} from '../../../quiz-model/quiz';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';

@Component({
  selector: 'app-question-scale-participant',
  templateUrl: './question-scale-participant.component.html',
  styleUrls: ['./question-scale-participant.component.scss']
})
export class QuestionScaleParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  readonly SMILES_RATING_SETS = SMILES_RATING_SETS;

  dataSource: IAnswer[] = [];
  displayedColumns = ['answer'];

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];
    this.question.items.forEach(answer => dataList.push(
      {answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex}));
    let diff = this.dataSource.length !== dataList.length;
    if (!diff) {
      diff = this.dataSource.some(item => {
        const ind = dataList.findIndex(elem => {
          if (item.id === elem.id && item.answer === elem.answer && item.orderIndex === elem.orderIndex) {
            return true;
          }
        });
        return ind === -1;
      });
    }
    if (diff) {
      dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
      this.dataSource = dataList;
    }
  }

  setRating(rowId: string, rating: SMILE_RATING) {
    const answers = this.answers ?? [];
    let answersObj = answers[0] ?? {};
    if (!answersObj) {
      answersObj = {};
    }
    if (answersObj[rowId] !== rating) {
      answersObj[rowId] = rating;
    } else {
      delete answersObj[rowId];
    }
    answers[0] = answersObj;
    this.answers = answers;
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }
}
