import {Component, Injector, OnInit} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {firstValueFrom, take} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {UploadFilesEditorDialogComponent} from '../upload-files-editor-dialog/upload-files-editor-dialog.component';
import {ITEM_MARKER} from '../../../shared/container-interface';
import {IPresenterFiles, IUploadedFiles} from '../upload-files-model/upload-files-model';
import {isImage} from '../../quiz/quiz-components/question-upload-files/question-upload-files-utils';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrl: './upload-files.component.scss'
})
export class UploadFilesComponent extends AbstractContainerComponent implements OnInit {
  allFiles: IPresenterFiles[] = [];
  readonly ITEM_MARKER = ITEM_MARKER;
  isImage = isImage;

  constructor(protected injector: Injector,
              private dialog: MatDialog) {
    super(injector);
  }

  ngOnInit(): void {
    this.data$
      .pipe(this.takeUntilAlive())
      .subscribe((value: any) => {
        this.allFiles = this.getAllUploadedFiles();
      });
  }

  private getAllUploadedFiles(): IPresenterFiles[] {
    return (((this.data as IUploadedFiles)?.files || []) as IPresenterFiles[]).filter(p => !p.id.startsWith(ITEM_MARKER.DELETED));
  }

  onEdit(): Promise<boolean> {
    const dialogRef = this.dialog.open(UploadFilesEditorDialogComponent, {
      data: {
        files: this.data?.files
      }
    });
    return firstValueFrom(dialogRef.afterClosed().pipe(take(1)))
      .then(result => {
        if (result) {
          this.data = result;
        }
        return !!result;
      });
  }

  downloadFile(url) {
      window.open(url, '_blank');
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  protected inputFollowMeData(value) {
  }
}
