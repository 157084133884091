import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers/index';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './login/login.component';
import {CanActivateAuthGuard} from './login/can-activate.authguard';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {IConfig, provideEnvironmentNgxMask} from 'ngx-mask';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ToolbarMenuComponent} from './toolbar-menu/toolbar-menu.component';
import {UsersDataService} from './services/users-data.service';
import {EffectsModule} from '@ngrx/effects';
import {UsersDataEffects} from './services/users-data-effects.service';
import {EventsDataEffects} from './services/events-data-effects.service';
import {EventsDataService} from './services/events-data.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {GoogleAPIService} from './services/google-api.service';
import {EventApiService} from './services/event-api.service';
import {EventModeApiService} from './services/event-mode-api.service';
import {UserApiService} from './services/user-api.service';
import {LoginService} from './login/login.service';
import {LoginGuard} from './login/login.guard';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {CoreModule} from './core/core.module';
import {Constants} from './core/constants';
import {ContentService} from './services/content.service';
import {RegistrationProcessService} from './services/registration-process.service';
import {RegistrationExchangeService} from './services/registration-exchange.service';
import {AuthComponent} from './auth/auth.component';
import {EventComponentsModule} from './event-components/event-components.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SelfLearningService} from './services/self-learning.service';
import {AngularFireFunctionsModule, REGION} from '@angular/fire/compat/functions';
import {GetSupportDialogModule} from './modules/get-support-dialog/get-support-dialog.module';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {DataEffects} from './effects/data';
import {AuthHttpInterceptor} from './services/auth-http-interceptor';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {TermsAndPrivacyModule} from './modules/terms-and-privacy-dialog/terms-and-privacy.module';
import {DirectivesModule} from './directives/directives.module';
import {MainMenuFilesModule} from './modules/main-menu-files-dialog/main-menu-files.module';
import {GuestComponent} from './login/guest/guest.component';
import {ExportPrintDialogModule} from './modules/export-print-dialog/export-print-dialog.module';
import {MatMenuModule} from '@angular/material/menu';
import {JoinToClientComponent} from './login/join-to-client/join-to-client.component';
import {AngularFireAnalyticsModule, CONFIG} from '@angular/fire/compat/analytics';
import {AngularFirePerformanceModule, INSTRUMENTATION_ENABLED, PerformanceMonitoringService} from '@angular/fire/compat/performance';
import {GlobalErrorHandler} from './core/global-error-handler.service';
import {EmailVerificationComponent} from './login/email-verification/email-verification.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreModule as RootCoreModule} from '@ninescopesoft/core';
import {TranslationLoaderService} from './core/translation-loader-service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {ClientTermsAndPrivacyModule} from './modules/client-terms-and-privacy/client-terms-and-privacy.module';
import {MainMenuComponent} from './toolbar-menu/main-menu/main-menu.component';
import {UserMenuComponent} from './toolbar-menu/user-menu/user-menu.component';
import {EventCreateModule} from './event-create/event-create.module';
import { MtxColorpickerModule } from '@ng-matero/extensions/colorpicker';
import {LoadingProgressModule} from './components/loading-progress/loading-progress.module';
import { NotificationSnackbarComponent } from './modules/notification-snackbar/notification-snackbar.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import {NgOptimizedImage} from '@angular/common';
import {IconsModule} from './modules/icons/icons.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
Constants.initFirebaseName();

@NgModule({
  declarations: [
    AppComponent
    , LoginComponent
    , ToolbarMenuComponent
    , SpinnerComponent
    , AuthComponent
    , GuestComponent
    , JoinToClientComponent
    , EmailVerificationComponent
    , MainMenuComponent
    , UserMenuComponent
    , NotificationSnackbarComponent
  ],
  imports: [
    BrowserModule
    , HttpClientModule
    , TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient) => new TranslationLoaderService(httpClient),
        deps: [HttpClient]
      }
    })
    , AngularFireModule.initializeApp(environment.firebase)
    , AngularFireDatabaseModule
    , AngularFirestoreModule
    , AngularFireFunctionsModule
    , AngularFireStorageModule
    , AngularFireAuthModule
    , AngularFireAnalyticsModule
    , AngularFirePerformanceModule
    , EffectsModule.forRoot([
      EventsDataEffects
      , UsersDataEffects
      , DataEffects
    ])
    , FormsModule
    , CoreModule
    , RootCoreModule
    , DirectivesModule
    , AppRoutingModule
    , StoreModule.forRoot(reducers, {
        metaReducers, runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        }
      }
    )
    , BrowserAnimationsModule
    , IconsModule
    , EventComponentsModule
    , GetSupportDialogModule
    , TermsAndPrivacyModule
    , MainMenuFilesModule
    , ExportPrintDialogModule
    , MatButtonModule
    , MatFormFieldModule
    , MatInputModule
    , MatCardModule
    , MatToolbarModule
    , MatIconModule
    , MatListModule
    , MatDialogModule
    , MatSelectModule
    , MatCheckboxModule
    , MatStepperModule
    , ReactiveFormsModule
    , MatTabsModule
    , MatSlideToggleModule
    , MatRadioModule
    , MatTableModule
    , MatExpansionModule
    , MatProgressSpinnerModule
    , MatProgressBarModule
    , MatMenuModule
    , MatTooltipModule
    , MatSnackBarModule
    , MatDatepickerModule
    , MatNativeDateModule
    , MatChipsModule
    , MatAutocompleteModule
    , ClientTermsAndPrivacyModule
    , UserAvatarComponent
    , EventCreateModule
    , MtxColorpickerModule
    , LoadingProgressModule
    , FormInputComponent
    , NgOptimizedImage
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    }, {
      provide: ErrorHandler, useClass: GlobalErrorHandler
    }
    , {
      provide: REGION,
      useValue: 'europe-west6'
    }
    , {
      provide: CONFIG,
      useValue: {
        send_page_view: false,
        allow_ad_personalization_signals: false,
        anonymize_ip: true
      }
      // provide: DEBUG_MODE,
      // useValue: true
    }
    , PerformanceMonitoringService
    , {
      provide: INSTRUMENTATION_ENABLED,
      useValue: true
    }
    , LoginService
    , CanActivateAuthGuard
    , LoginGuard
    , UsersDataService
    , EventsDataService
    , GoogleAPIService
    , EventApiService
    , EventModeApiService
    , UserApiService
    , ContentService
    , RegistrationProcessService
    , RegistrationExchangeService
    , SelfLearningService
    , provideEnvironmentNgxMask()
    , {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
