import { AnswerQuestion } from '../../../../../../../../src/app/model/EventQuestion';
import { TCaption } from "../../../../../../../../src/app/core/constants";



export interface IAnswerSequence {
  id: string;
  answerText: TCaption;
  currPosition: number;
  correctPosition: number;
  correctAnswer?: boolean;
  wrongAnswer?: boolean;
}


export const SequenceUtils = {



  answerQuestionToIAnswerSequence(correctSequence: AnswerQuestion[],  answer: AnswerQuestion, answerIdx: number) {
    let correctIndex = correctSequence.findIndex(
      (item) => item.id == answer.id
    );

    let answersSequence: IAnswerSequence = {
      id: answer.id,
      answerText: correctSequence[correctIndex].answer,
      currPosition: answerIdx,
      correctPosition: correctIndex,
      correctAnswer: answerIdx == correctIndex,
      wrongAnswer: answerIdx != correctIndex
    };
    return answersSequence;
  }


}
