import {Component, EventEmitter, HostListener, Injector, Input, OnInit, Output} from '@angular/core';
import {CLOCK_TYPE, Constants} from '../../../../core/constants';
import {debounceTime, Subject} from 'rxjs';
import {StdComponent} from '../../../../core/std-component';

@Component({
  selector: 'app-select-hour-minutes',
  templateUrl: './select-hour-minutes.component.html',
  styleUrls: ['./select-hour-minutes.component.scss']
})
export class SelectHourMinutesComponent extends StdComponent implements OnInit {

  readonly CLOCK_TYPE = CLOCK_TYPE;

  @Input() dateTime: number;
  @Input() disabled: boolean;
  @Output() changedHours = new EventEmitter<number>();
  @Output() changedMinutes = new EventEmitter<number>();

  hours = 0;
  _hours: number[] = [0, 0];
  minutes = 0;
  _minutes: number[] = [0, 0];
  timeSwitcher = CLOCK_TYPE.HOURS;
  inputTimeSubject = new Subject();

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let digit;
    if (!this.disabled && (((digit = Constants.DIGIT_KEY.indexOf(event.code)) > -1) ||
        (digit === -1 && (digit = Constants.DIGIT_NUMPAD_KEY.indexOf(event.code)) > -1))) {
      this.setTimeValue(digit);
      this.inputTimeSubject.next(digit);
    } else if (!this.disabled && event.code === Constants.RIGHT_ARROW) {
      this.timeSwitcher = CLOCK_TYPE.MINUTES;
    } else if (!this.disabled && event.code === Constants.LEFT_ARROW) {
      this.timeSwitcher = CLOCK_TYPE.HOURS;
    } else if (!this.disabled && event.code === Constants.BACKSPACE) {
      this.clearTimeValue();
    } else if (!this.disabled && event.code === Constants.TAB) {
      this.timeSwitcher = this.timeSwitcher === CLOCK_TYPE.HOURS ? CLOCK_TYPE.MINUTES : CLOCK_TYPE.HOURS;
    }
  }

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.inputTimeSubject.pipe(debounceTime(800), this.takeUntilAlive()).subscribe(value => {
      this.clearInputTimeBuffer();
    });
    if (this.dateTime) {
      const cd = new Date(this.dateTime);
      this.hours = cd.getHours();
      this._hours = this.setTimeValueA(this.hours);
      this.minutes = cd.getMinutes();
      this._minutes = this.setTimeValueA(this.minutes);
    }
  }

  clearTimeValue() {
    switch (this.timeSwitcher) {
      case CLOCK_TYPE.HOURS:
        this.hours = 0;
        this._hours = [0, 0];
        break;
      case CLOCK_TYPE.MINUTES:
        this.minutes = 0;
        this._minutes = [0, 0];
        break;
      default:
        break;
    }
  }

  clearInputTimeBuffer() {
    this._hours = [0, 0];
    this._minutes = [0, 0];
  }

  setTimeValue(digit: number) {
    const value = this.timeSwitcher === CLOCK_TYPE.HOURS ? this._hours : this._minutes;
    value[0] = value[1];
    value[1] = digit;
    const res = value.reduce(function (v, d) {
      v = v + d;
      return v;
    }, '');
    const dValue = +res;
    switch (this.timeSwitcher) {
      case CLOCK_TYPE.HOURS:
        this.hours = dValue > 23 ? this.hours : dValue;
        this.changedHours.emit(this.hours);
        break;
      case CLOCK_TYPE.MINUTES:
        this.minutes = dValue > 59 ? this.minutes : dValue;
        this.changedMinutes.emit(this.minutes);
        break;
      default:
        break;
    }
  }

  setTimeValueA(digit: number): number[] {
    const result = [0, 0];
    if (digit < 10) {
      result[1] = digit;
    } else {
      result[0] = Math.floor(digit / 10);
      result[1] = digit - (result[0] * 10);
    }
    return result;
  }
}
