import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {LoggerService} from '../core/logger.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoginService} from '../login/login.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {UtilsService} from '../core/utils.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {COUNTRY_LANGUAGE, LANGUAGE} from '../core/language-constants';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

export enum TRANSLATE_RESPONSE_MIME_TYPE {
  TEXT_PLAIN = 'text/plain'
}

@Injectable({
  providedIn: 'root'
})
export class TranslateApiService extends StdApiService {
  protected API_URL: string;

  constructor(private http: HttpClient,
              protected logger: LoggerService,
              protected store: Store<fromRoot.State>,
              protected auth: LoginService,
              protected aFirestore: AngularFirestore,
              protected utils: UtilsService,
              protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/api/v3/translate';
  }

  getTranslateHint(languageFrom: LANGUAGE, languageTo: LANGUAGE) {
    const from = Object.values(COUNTRY_LANGUAGE).find(l => l.languageISOCode === languageFrom);
    const to = Object.values(COUNTRY_LANGUAGE).find(l => l.languageISOCode === languageTo);
    return languageFrom && languageTo && languageFrom !== languageTo ? this.utils.i18n('translate.hint', {
      languageFrom: this.utils.i18n(from?.name),
      languageTo: this.utils.i18n(to?.name)
    }) : '';
  }

  getRestoreTranslateHint(languageTo: LANGUAGE) {
    const to = Object.values(COUNTRY_LANGUAGE).find(l => l.languageISOCode === languageTo);
    return this.utils.i18n('translate.restore.to.default', {languageTo: this.utils.i18n(to?.name)});
  }

  /**
   * mimeType - response google translate mime type.
   * for example translate simple string "Australian Open".
   * if mimeType = null response "open d&#39;Australie"
   * if mimeType = 'text/plain' response "Open d'Australie".
   * but if translate big html text mime type stay equal null otherwise the text formatting may be lost
   */
  private translate(text: string, languageFrom: LANGUAGE, languageTo: LANGUAGE, mimeType: TRANSLATE_RESPONSE_MIME_TYPE = null) {
    const url = `${this.API_URL}?from=${languageFrom}&to=${languageTo}${mimeType ? `&mimeType=${mimeType}` : ''}`;
    return text ? firstValueFrom(this.http.post(url, text, {responseType: 'text'})) : Promise.resolve(null);
  }

  translateHTML(text: string, languageFrom: LANGUAGE, languageTo: LANGUAGE) {
    const url = `${this.API_URL}?from=${languageFrom}&to=${languageTo}`;
    return text ? firstValueFrom(this.http.post(url, text, {responseType: 'text'})) : Promise.resolve(null);
  }

  translateSimpleString(text: string, languageFrom: LANGUAGE, languageTo: LANGUAGE) {
    return this.translate(text, languageFrom, languageTo, TRANSLATE_RESPONSE_MIME_TYPE.TEXT_PLAIN);
  }
}
