import {FirestoreReference} from './FirestoreReference';

export class IcsTemplate  extends FirestoreReference {
  public static readonly DB_PATH = 'ics_template';

  subject: string;

  body: string;

  sectionId: string;

  constructor(obj?: any) {
    super(obj);
    if (!obj) {
      return;
    }
    if (obj.subject) {
      this.subject = obj.subject;
    }
    if (obj.body) {
      this.body = obj.body;
    }
    if (obj.sectionId) {
      this.sectionId = obj.sectionId;
    }
  }
}
