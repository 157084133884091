// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-size: 0.9em;
}

.text-note-content {
  width: 100%;
  min-height: calc(1em + 4px);
  height: auto;
  padding: 16px;
  box-sizing: border-box;
}
.text-note-content.empty-data {
  height: 100%;
}
.text-note-content.disabled {
  opacity: 0.5;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

@media (max-width: 767px) {
  :host {
    font-size: 0.7em;
  }
  :host.scale-equal-one {
    font-size: 0.9em;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/content-container/components/text-note/text-note/text-note.component.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;AADF;;AAIA;EACE,WAAA;EACA,2BAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AADF;AAEE;EACE,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,oBAAA;EACA,yBAAA;UAAA,iBAAA;AAAJ;;AAIA;EACE;IACE,gBAAA;EADF;EAEE;IACE,gBAAA;EAAJ;AACF","sourcesContent":["$border-size-x2: 4px;\n\n:host {\n  font-size: 0.9em;\n}\n\n.text-note-content {\n  width: 100%;\n  min-height: calc(1em + $border-size-x2);\n  height: auto;\n  padding: 16px;\n  box-sizing: border-box;\n  &.empty-data {\n    height: 100%;\n  }\n  &.disabled {\n    opacity: 0.5;\n    pointer-events: none;\n    user-select: none;\n  }\n}\n\n@media (max-width: 767px) {\n  :host {\n    font-size: 0.7em;\n    &.scale-equal-one {\n      font-size: 0.9em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
