export enum LANGUAGE {
  EN = 'en',
  DE = 'de',
  PT = 'pt',
  FR = 'fr',
  ES = 'es',
  ZH = 'zh',
  IT = 'it',
  TR = 'tr',
  JA = 'ja',
  RU = 'ru',
  BR = 'pt-BR'
}

export enum COUNTRY {
  GB = 'gb',
  DE = 'de',
  PT = 'pt',
  FR = 'fr',
  ES = 'es',
  CN = 'cn',
  IT = 'it',
  TR = 'tr',
  JP = 'jp',
  RU = 'ru',
  BR = 'br'
}

export interface IISOLanguage {
  name: string;
  countryISOCode: COUNTRY; // ISO 3166-1 alpha-2 codes
  languageISOCode: LANGUAGE; // ISO 639-1 codes
  icon: string;
  borderCSSStyle?: string;
}

export interface IISOLanguageMap {
  [country: string]: IISOLanguage;
}

export const COUNTRY_LANGUAGE: IISOLanguageMap = {
  [COUNTRY.GB]: {
    languageISOCode: LANGUAGE.EN,
    countryISOCode: COUNTRY.GB,
    name: 'language.english',
    icon: 'assets/images/flag_icons/gb.svg'
  },
  [COUNTRY.DE]: {
    languageISOCode: LANGUAGE.DE,
    countryISOCode: COUNTRY.DE,
    name: 'language.deutsch',
    icon: 'assets/images/flag_icons/de.svg'
  },
  [COUNTRY.PT]: {
    languageISOCode: LANGUAGE.PT,
    countryISOCode: COUNTRY.PT,
    name: 'language.portuguese',
    icon: 'assets/images/flag_icons/pt.svg'
  },
  [COUNTRY.FR]: {
    languageISOCode: LANGUAGE.FR,
    countryISOCode: COUNTRY.FR,
    name: 'language.french',
    icon: 'assets/images/flag_icons/fr.svg'
  },
  [COUNTRY.ES]: {
    languageISOCode: LANGUAGE.ES,
    countryISOCode: COUNTRY.ES,
    name: 'language.spanish',
    icon: 'assets/images/flag_icons/es.svg'
  },
  [COUNTRY.CN]: {
    languageISOCode: LANGUAGE.ZH,
    countryISOCode: COUNTRY.CN,
    name: 'language.simplified.chinese',
    icon: 'assets/images/flag_icons/cn.svg'
  },
  [COUNTRY.IT]: {
    languageISOCode: LANGUAGE.IT,
    countryISOCode: COUNTRY.IT,
    name: 'language.italian',
    icon: 'assets/images/flag_icons/it.svg'
  },
  [COUNTRY.TR]: {
    languageISOCode: LANGUAGE.TR,
    countryISOCode: COUNTRY.TR,
    name: 'language.turkish',
    icon: 'assets/images/flag_icons/tr.svg'
  },
  [COUNTRY.JP]: {
    languageISOCode: LANGUAGE.JA,
    countryISOCode: COUNTRY.JP,
    name: 'language.japanese',
    icon: 'assets/images/flag_icons/jp.svg',
    borderCSSStyle: '1px solid #DEE2E6'
  },
  [COUNTRY.RU]: {
    languageISOCode: LANGUAGE.RU,
    countryISOCode: COUNTRY.RU,
    name: 'language.russian',
    icon: 'assets/images/flag_icons/ru.svg',
    borderCSSStyle: '1px solid #8f8f8f'
  },
  [COUNTRY.BR]: {
    languageISOCode: LANGUAGE.BR,
    countryISOCode: COUNTRY.BR,
    name: 'language.portuguese-br',
    icon: 'assets/images/flag_icons/br.svg'
  }
};


