import { Component, Injector } from '@angular/core';
import { AbstractQuizQuestionAnswersEditorComponent } from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import { isEmpty } from 'lodash';
import { ILanguageParams } from '../../../../../../../core/constants';
import { EventQuestion } from '../../../../../../../model/EventQuestion';
import { isEmptyCaption } from '../../shared/lib/quiz-question-common-lib';
import { QUESTION_TYPE, IValidated } from '../../shared/quiz-quiestion-types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-question-sequence-editor',
  templateUrl: './question-sequence-editor.component.html',
  styleUrl: './question-sequence-editor.component.scss'
})
export class QuestionSequenceEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  displayedColumns: string[] = ['answer'];

  constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.question.useCorrectAnswers = true;
  }

  changeCorrectAnswerCheckBox(event, row) {
    if (this.question.storypoint === QUESTION_TYPE.SEQUENCE && this.question.useCorrectAnswers) {
      const checked = event.checked;
      if (checked) {
        for (const r of this.dataSource.data) {
          if (r.correctAnswer && r.id !== row.id) {
            r.correctAnswer = false;
          }
        }
      }
    }
  }

  validate(): IValidated {
    if (isEmpty(this.dataSource.data)) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required') };
    }
    if (this.dataSource.data.some(o => isEmptyCaption(o.answer))) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty') };
    }
    return { validated: true };
  }


  drop(event: CdkDragDrop<any[]>) {
    if(event.previousIndex !== event.currentIndex){
      moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
      this.dataSource.data.forEach((item, itemIndex) => item.orderIndex = (itemIndex + 1) * 100 );
      this.dataSource.data = [...this.dataSource.data]; // Refresh dataSource to reflect the change
    }
  }

}
