import {enableProdMode} from '@angular/core';

import {environment} from './environments/environment';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {Constants} from './app/core/constants';
import {getAppConfig} from './app/app.config';

if (environment.production) {
  enableProdMode();
}

Constants.initFirebaseName();

bootstrapApplication(AppComponent, getAppConfig());
