import {AfterViewInit, Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {cloneDeep, isEmpty} from 'lodash';
import {DIRECTION, DISPLAY_COLUMNS, THighlight, THighlightTableRow} from '../highlight-table-model/highlight-table-model';
import {BehaviorSubject, filter, take} from 'rxjs';
import {Constants, ILanguageParams, TRIPLE} from '../../../../../core/constants';
import {TranslateApiService} from '../../../../../services/translate-api.service';
import {StdComponent} from '../../../../../core/std-component';
import {UtilsService} from '../../../../../core/utils.service';
import {CommonService} from '../../../../../core/common.service';

interface IDialogData {
  data: THighlight[];
  languageParams$: BehaviorSubject<ILanguageParams>;
}

@Component({
  selector: 'app-highlight-table-editor-dialog',
  templateUrl: './highlight-table-editor-dialog.component.html',
  styleUrls: ['./highlight-table-editor-dialog.component.scss']
})
export class HighlightTableEditorDialogComponent extends StdComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly DISPLAY_COLUMNS = DISPLAY_COLUMNS;
  readonly DIRECTION = DIRECTION;

  dataSource = new MatTableDataSource<THighlightTableRow>();
  rowIndex = null;

  private tableElementRef: Element;
  languageParams: ILanguageParams;
  translateHint: string;
  translating = false;
  dataHash: string;
  isModify = false;
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<HighlightTableEditorDialogComponent>,
              private translateApiService: TranslateApiService,
              @Inject(MAT_DIALOG_DATA) public data: IDialogData,
              private common: CommonService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    dialogRef.updateSize('600px', '600px');
    this.data.languageParams$
      .pipe(filter(p => !isEmpty(p)), take(1))
      .subscribe(params => {
        this.languageParams = cloneDeep(params);
        this.translateHint = this.translateApiService
          .getTranslateHint(this.languageParams.defaultLanguage, this.languageParams.currentLanguage);
        if (isEmpty(data.data)) {
          this.add();
        } else {
          this.dataSource.data = cloneDeep(data.data).map(o => new THighlightTableRow(o, this.languageParams));
          this.rowIndex = 0;
        }
        this.dataSource.data = UtilsService.wrapObjectToProxy(cloneDeep(this.dataSource.data), this.dataChangesHandler);
        this.dataHash = UtilsService.md5(UtilsService.jsonSorted(this.dataSource.data));
      });
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  private indexing(d: THighlightTableRow[]) {
    (d || []).forEach((v, index) => v.id = index);
    return d;
  }

  ngOnInit(): void {
    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.dataSource.data));
      });
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.tableElementRef = document.getElementById('tableElementRef');
  }

  onOkClick(): void {
    this.dialogRef.close(cloneDeep(this.dataSource.data.map(o => o.simplify())));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete() {
    this.dataSource.data.splice(this.rowIndex, 1);
    this.dataSource.data = this.indexing(this.dataSource.data);
    this.rowIndex = null;
  }

  add() {
    let tmpData: THighlightTableRow[] = this.dataSource.data;
    tmpData = !isEmpty(tmpData) ? tmpData : [];
    const id = this.dataSource.data.length - 1;
    tmpData.push(new THighlightTableRow({id}, this.languageParams));
    this.dataSource.data = this.indexing(tmpData);
    this.rowIndex = this.dataSource.data.length - 1;
  }

  moveUpDown(move: DIRECTION) {
    const tmpData = this.dataSource.data;
    const index = this.rowIndex;
    const temp = tmpData[index];
    tmpData[index] = tmpData[index + move];
    tmpData[index + move] = temp;
    this.rowIndex += move;
    this.dataSource.data = this.indexing(tmpData);
  }

  onLanguageChange(value) {
    this.languageParams.currentLanguage = value;
    this.translateHint = this.translateApiService
      .getTranslateHint(this.languageParams.defaultLanguage, this.languageParams.currentLanguage);
  }

  async translate() {
    const throwCatch = (e) => {
      this.translating = false;
      throw e;
    };
    const row = this.dataSource.data[this.rowIndex];
    this.translating = true;
    const rowTitle = await this.translateApiService
      .translateSimpleString(row.rowTitle, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .catch(e => throwCatch(e));
    const rowDescription = await this.translateApiService
      .translateSimpleString(row.rowDescription, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .catch(e => throwCatch(e));
    row.rowTitle = rowTitle;
    row.rowDescription = rowDescription;
    this.translating = false;
  }

}
