import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NotificationSnackbarComponent} from '../modules/notification-snackbar/notification-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private snackBar: MatSnackBar) { }

  pop(type: 'success' | 'error' | 'warning' | 'info', title: string, message?: string, duration = 5000) {
    this.snackBar.openFromComponent(NotificationSnackbarComponent, {
      data: {
        title: title,
        message: message,
        type: type
      },
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['toaster-' + type]
    });
  }
}
