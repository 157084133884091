import {Constants} from '../core/constants';

export class AppUser {
  public static readonly DB_PATH = 'app_users';

  userId: string = null;
  email: string = null;
  userName: string = null;
  fullName: string = null;
  blocked = false;
  authEmail: string;
  authId: string;
  roles: Array<String> = [];
  picture?: string = null;
  userKey: string = null;
  description: string = null;
  socialNetwork: string = null;
  department: string;
  hideEmail = false;
  groupKey: string;
  unsubscribe: number;
  acceptTermsAndPrivacy: boolean;
  lastProfileUpdate: number;
  guest = false;
  guestEmail: string;
  client_id: string;
  approved: boolean;
  acceptClientTermsAndPrivacy: boolean;
  acceptCustomTerms: boolean;
  language: string;
  languageBrowser: string;
  searchIndex: string[];
  countryCode: string;
  country: string;
  createdAt: number;
  lastLoginAt: number;
  providers: string[] = [];

  get isSuperAdmin(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_SUPERADMIN) > -1);
  }

  get isAdmin(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_ADMIN) > -1);
  }

  get isModerator(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_MODERATOR) > -1);
  }

  get isTrainer(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_TRAINER) > -1);
  }

  get isSimpleRegistration(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_SIMPLE_REGISTRATION) > -1);
  }

  get isManage(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_MANAGE) > -1);
  }

  get isCopyMaster(): boolean {
    return this.roles && (this.roles.indexOf(Constants.ROLE_COPY_MASTER) > -1);
  }

  set isSuperAdmin(val: boolean) {
    if (!this.roles) {
      this.roles = [];
    }
    if (val) {
      if (this.roles.indexOf(Constants.ROLE_SUPERADMIN) < 0) {
        this.roles.push(Constants.ROLE_SUPERADMIN);
      }
    }
  }

  set isAdmin(val: boolean) {
    if (!this.roles) {
      this.roles = [];
    }
    if (val) {
      if (this.roles.indexOf(Constants.ROLE_ADMIN) < 0) {
        this.roles.push(Constants.ROLE_ADMIN);
      }
    }
  }

  set isModerator(val: boolean) {
    if (!this.roles) {
      this.roles = [];
    }
    if (val) {
      if (this.roles.indexOf(Constants.ROLE_MODERATOR) < 0) {
        this.roles.push(Constants.ROLE_MODERATOR);
      }
    }
  }

  set isTrainer(val: boolean) {
    if (!this.roles) {
      this.roles = [];
    }
    if (val) {
      if (this.roles.indexOf(Constants.ROLE_TRAINER) < 0) {
        this.roles.push(Constants.ROLE_TRAINER);
      }
    }
  }

  set isSimpleRegistration(val: boolean) {
    if (!this.roles) {
      this.roles = [];
    }
    if (val) {
      if (this.roles.indexOf(Constants.ROLE_SIMPLE_REGISTRATION) < 0) {
        this.roles.push(Constants.ROLE_SIMPLE_REGISTRATION);
      }
    }
  }

  set isCopyMaster(val: boolean) {
    if (!this.roles) {
      this.roles = [];
    }
    if (val) {
      if (this.roles.indexOf(Constants.ROLE_COPY_MASTER) < 0) {
        this.roles.push(Constants.ROLE_COPY_MASTER);
      }
    }
  }

  constructor(obj?: any, userKey?: string) {
    if (!obj) {
      return;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
    if (obj.email) {
      this.email = obj.email;
    }
    if (obj.userName) {
      this.userName = obj.userName;
    }
    if (obj.fullName) {
      this.fullName = obj.fullName;
    }
    if (obj.roles) {
      this.roles = obj.roles;
    }
    if (obj.picture) {
      this.picture = obj.picture;
    }
    if (obj.hasOwnProperty('blocked')) {
      this.blocked = obj.blocked;
    }
    if (obj.authEmail) {
      this.authEmail = obj.authEmail;
    }
    if (obj.authId) {
      this.authId = obj.authId;
    }
    if (userKey) {
      this.userKey = userKey;
    }
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.socialNetwork) {
      this.socialNetwork = obj.socialNetwork;
    }
    if (obj.department) {
      this.department = obj.department;
    }
    if (obj.hideEmail) {
      this.hideEmail = obj.hideEmail;
    }
    if (obj.groupKey) {
      this.groupKey = obj.groupKey;
    }
    if (obj.unsubscribe) {
      this.unsubscribe = obj.unsubscribe;
    }
    if (obj.hasOwnProperty('acceptTermsAndPrivacy')) {
      this.acceptTermsAndPrivacy = obj.acceptTermsAndPrivacy;
    }
    if (obj.hasOwnProperty('acceptCustomTerms')) {
      this.acceptCustomTerms = obj.acceptCustomTerms;
    }
    if (obj.hasOwnProperty('lastProfileUpdate')) {
      this.lastProfileUpdate = obj.lastProfileUpdate;
    }
    if (obj.hasOwnProperty('guest')) {
      this.guest = obj.guest;
    }
    if (obj.hasOwnProperty('guestEmail')) {
      this.guestEmail = obj.guestEmail;
    }
    if (obj.client_id) {
      this.client_id = obj.client_id;
    }
    if (obj.hasOwnProperty('approved')) {
      this.approved = obj.approved;
    }
    if (obj.hasOwnProperty('acceptClientTermsAndPrivacy')) {
      this.acceptClientTermsAndPrivacy = obj.acceptClientTermsAndPrivacy;
    }
    if (obj.language) {
      this.language = obj.language;
    }
    if (obj.languageBrowser) {
      this.languageBrowser = obj.languageBrowser;
    }
    if (obj.searchIndex) {
      this.searchIndex = obj.searchIndex;
    }
    if (obj.countryCode) {
      this.countryCode = obj.countryCode;
    }
    if (obj.country) {
      this.country = obj.country;
    }
    if (obj.createdAt) {
      this.createdAt = obj.createdAt;
    }
    if (obj.lastLoginAt) {
      this.lastLoginAt = obj.lastLoginAt;
    }
    if (obj.providers) {
      this.providers = obj.providers;
    }
  }

  getPicture() {
    return this.picture ? this.picture : Constants.DEFAULT_USER_LOGO;
  }

  getFullName() {
    return this.fullName ? this.fullName : (this.email ? this.email : '');
  }

  toObject() {
    const obj = {...this};
    delete obj.userId;
    return obj;
  }
}

export interface AppUserResponse {
  userId: string;
  user: AppUser;
  admin: boolean;
  moderator: boolean;
  trainer: boolean;
  simpleRegistration: boolean;
  copyMaster: boolean;
  blocked: boolean;
  loginUrl: string;
  guest: boolean;
  guestEmail: string;
  client_id: string;
}

export class FilteredUser extends AppUser {
  active: boolean;
}
