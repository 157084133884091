import {Injectable} from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {StorageDataService} from '../../../../services/storage-data.service';
import {CommonService} from '../../../../core/common.service';

interface IPdfData {
  id: string;
  srcUrl?: string;
  src: string;
}

export type TPdfData = Pick<IPdfData, 'id' | 'src'>;

@Injectable({
  providedIn: 'root'
})
export class PdfDocumentService extends AbstractContainerService {

  constructor(private storageDataService: StorageDataService,
              private common: CommonService) {
    super();
  }

  async save(storagePath: string, data: IPdfData): Promise<any> {
    if (data && data.src.indexOf(';base64,') > -1) {
      const fileName = this.common.utils.addTimeToFileName(data.id);
      await this.storageDataService.uploadAnyObjectToStorageByDataPath(storagePath, fileName, data.src, 'application/pdf')
        .then(snapshot => snapshot.ref.getDownloadURL()
          .catch(e => this.common.log.error(e))
          .then(async url => {
            if (data.srcUrl && data.srcUrl.indexOf(';base64,') === -1) {
              const deleteName = this.common.utils.extractFileNameFromUrl(data.srcUrl);
              await this.storageDataService.deleteObjectFromStorageByDataPath(storagePath, deleteName)
                .catch(e => {
                  this.common.log.error(e);
                  throw e;
                });
            }
            delete data.srcUrl;
            data.src = url;
          }))
        .catch(e => this.common.log.error(e));
    }
    return Promise.resolve(data);
  }

  async delete(path: string, data: IPdfData): Promise<boolean> {
    const fileName = this.common.utils.extractFileNameFromUrl(data.src);
    await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
      .catch(e => this.common.log.error(e));
    return Promise.resolve(true);
  }
}
