import {ISectionUserMeetingState, IUserMediaDevicesState} from '../../../core/constants';
import {ConferenceRoomInvite} from '../../../event-mode/event-mode/conference/models/conference-participant';

export interface IParticipantPictureCacheMap {
  [userId: string]: {
    name: string,
    picture: string
    meetingState: ISectionUserMeetingState;
    mediaDevicesState: IUserMediaDevicesState;
  };
}

export interface IDashboardUser {
  userId: string;
  picture: string;
  name: string;
  top?: string;
  left?: string;
  microphone?: boolean;
  webcam?: boolean;
}

export interface IFocusViewUser {
  user_id: string;
  picture: string;
  name: string;
}

export enum MESSAGE_TYPE {
  REFRESH_USER_INFO = '$REFRESH_USER_INFO',
  READ_USER_MESSAGE = '$READ_USER_MESSAGE',
  USER_LEAVE_MEETING = '$USER_LEAVE_MEETING',
}

export enum DASHBOARD_ACTION {
  ADD_TO_DASHBOARD = '$ADD_TO_DASHBOARD',
  UPDATE_DASHBOARD_USER = '$UPDATE_DASHBOARD_USER',
  REMOVE_FROM_DASHBOARD = '$REMOVE_FROM_DASHBOARD',
}

export enum FOCUS_VIEW_ACTION {
  ADD_TO_FOCUS_VIEW = '$ADD_TO_FOCUS_VIEW',
  REMOVE_FROM_FOCUS_VIEW = '$REMOVE_FROM_FOCUS_VIEW'
}

export enum DAILY_CO_ACTION {
  KICK_USER = '$KICK_USER',
  LEAVE_MEETING_GO_TO_ROOM = '$LEAVE_MEETING_GO_TO_ROOM',
  DELETE_ROOM = '$DELETE_ROOM'
}

export interface IChatMessage {
  userId: string;
  messageText: string;
}

export enum DAILY_CO_MEETING_PARAMS {
  MUTE_ALL = 'mute_all'
}

export interface IRoom {
  roomId: string;
  roomName: string;
  permanent?: boolean;
}

export interface IMeetingRoom extends IRoom {
  participants: string[];
  position?: {top: string, left: string, position: string};
  invites?: ConferenceRoomInvite[];
}

export class DailyCoConstants {

}
