import {Action} from '@ngrx/store';

const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unique`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}

export interface StdAction extends Action {
  payload: any;
}
