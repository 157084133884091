import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreloadDirective } from './image-preload.directive';
import { AutoScrollDirective } from './auto-scroll.directive';
import { MatBadgeIconDirective } from './mat-badge-icon.directive';
import { TextareaAutoresizeDirective } from './textarea.directive';
import { TlCenteredMenuDirective } from './tl-centered-menu.directive';



@NgModule({
  declarations: [
    ImagePreloadDirective,
    AutoScrollDirective,
    MatBadgeIconDirective,
    TextareaAutoresizeDirective,
    TlCenteredMenuDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImagePreloadDirective,
    AutoScrollDirective,
    MatBadgeIconDirective,
    TextareaAutoresizeDirective,
    TlCenteredMenuDirective
  ]
})
export class DirectivesModule { }
