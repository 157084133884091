import {AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../../core/common.service';

@Component({
  selector: 'app-terms-and-privacy-text',
  templateUrl: './terms-and-privacy-text.component.html',
  styleUrls: ['./terms-and-privacy-text.component.scss']
})
export class TermsAndPrivacyTextComponent implements OnInit, AfterViewChecked {

  @Input() customText;
  hideTermsAndPrivacyLicenseText = false;

  @ViewChild('customTextDiv') customTextDiv: ElementRef;

  constructor(private common: CommonService) { }

  ngOnInit() {
    this.hideTermsAndPrivacyLicenseText = this.common.utils.getEnv()['hideTermsAndPrivacyLicenseText'];
  }

  ngAfterViewChecked(): void {
    if (this.customText && this.customTextDiv && !this.customTextDiv.nativeElement.innerHTML) {
      this.customTextDiv.nativeElement.innerHTML = this.customText;
    }
  }
}
