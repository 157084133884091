import {Injectable, signal} from '@angular/core';
import {UtilsService} from './utils.service';
import {ToasterService} from './toaster.service';
import {BehaviorSubject, combineLatest, filter, firstValueFrom, map, Observable, Subject, switchMap, take, tap} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../create-event/confirm-dialog/confirm-dialog.component';
import {RootLocalStorageService} from './root-local-storage.service';
import {LoggerService} from './logger.service';
import {TranslateService} from '@ngx-translate/core';
import {
  ConfirmDialogThreeButtonComponent
} from '../create-event/confirm-dialog-three-button-component/confirm-dialog-three-button.component';
import {Constants, TRIPLE} from './constants';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionStorageService} from './session-storage.service';
import { BreakpointObserver } from '@angular/cdk/layout';

interface IDocumentLink {
  sectionId: string;
  contentId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private _showProgress = new Subject<boolean>();
  private _blockedCloseProgress: boolean;
  showProgressValue = new Subject<{}>();
  disableMainBackground = new Subject<boolean>();
  public userApplicationInteraction = new BehaviorSubject(false);
  backgroundBlurred$ = new BehaviorSubject(false);
  mobileSearch$ = new BehaviorSubject(false);
  searchOpened$: Observable<boolean>;
  blockedMoveByContents = signal<boolean>(false);

  constructor(public utils: UtilsService
    , public toaster: ToasterService
    , public dialog: MatDialog
    , public localStorage: RootLocalStorageService
    , public translateService: TranslateService
    , public log: LoggerService
    , protected router: Router
    , protected activatedRoute: ActivatedRoute
    , private sessionStorageService: SessionStorageService
    , private breakpointObserver: BreakpointObserver
) {
    this.searchOpened$ = combineLatest([this.isMobile$(), this.mobileSearch$.asObservable()])
      .pipe(map(([isMobile, mobileSearch]) => isMobile && mobileSearch));
  }

  public getEnv() {
    return this.utils.getEnv();
  }

  getSubsystemByCode(code: number) {
    switch (code) {
      case 3: return 'zoom';
      default: return null;
    }
  }

  set showProgress(value: Subject<boolean>) {
    this._showProgress = value;
  }

  get showProgress(): Subject<boolean> {
    if (this._blockedCloseProgress) {
      return new Subject<boolean>();
    }
    return this._showProgress;
  }

  get blockedCloseProgress(): boolean {
    return this._blockedCloseProgress;
  }

  set blockedCloseProgress(value: boolean) {
    this._blockedCloseProgress = value;
    if (!value) {
      this._showProgress.next(false);
    }
  }

  public showInfo(title: string, body?: string) {
    this.showMessage('info', title, body);
  }

  public showTypedMessage(ex: {code: number, errorCode: number, e: string}) {
    const subsystem = this.getSubsystemByCode(ex.code);
    const _i18n = `exception.${subsystem}.${ex.errorCode}`;
    let title = this.i18n(_i18n);
    if (!title || title === _i18n) {
      title = ex.e;
    }
    this.showMessage('error', title);
  }

  public showMessage(type: any, title: string, body?: string) {
    const _title = this.i18n(title, title);
    this.log.debug('Message ' + type + ': ' + title);
    if (type === 'error') {
      this.log.error(_title);
    }
    if (title !== 'FirebaseError') {
      this.toaster.pop(type, _title, body);
    }
  }

  public showPopupError(title: string, body?: string) {
    this.toaster.pop('error', title, body);
  }

  public showPopupSuccess(title: string, body?: string) {
    this.toaster.pop('success', this.i18n(title), this.i18n(body));
  }

  public showPopupWarning(title: string, body?: string) {
    this.toaster.pop('warning', this.i18n(title), this.i18n(body));
  }

  public showError(error: any, body?: string) {
    if (error instanceof HttpErrorResponse && error.error) {
      error = error.error;
    }
    if (error.code && error.errorCode && error.e) {
      this.showTypedMessage(error);
    } else
    if (error.code && error.e) {
      this.showMessage('error', error.e);
    } else
    if (error instanceof Error) {
      this.showMessage('error', error.name, error.message);
    } else if (error && error.result && error.result.error) {
      this.showMessage('error', error.result.error.code, error.result.error.message);
    } else if (error && error.name && error.message) {
      this.showMessage('error', error.name, error.message);
    } else if (error && error.message) {
      this.showMessage('error', error.message);
    } else if (error && error.status && error._body) {
      const _body = JSON.parse(error._body);
      this.showMessage('error', 'Status ' + error.status + ': ' + _body.error
        , _body.message ? _body.message : this.i18n(Constants.ERROR_REQUEST));
    } else {
      this.showMessage('error', this.utils.isString(error) ? error : this.i18n(Constants.ERROR_REQUEST));
    }
  }

  public confirm(title: string, message: string, showDontShowAgainOption?: boolean,
                 okButtonCaption?: string, cancelButtonCaption?: string,
                 showCloseButton?: boolean, cancelAsClose?: boolean,
                 dontAutoClose?: boolean, cancelAsFalse?: boolean): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.showDontShowAgainOption = showDontShowAgainOption;
    if (okButtonCaption) {
      dialogRef.componentInstance.okButtonCaption = okButtonCaption;
    }
    if (cancelButtonCaption) {
      dialogRef.componentInstance.cancelButtonCaption = cancelButtonCaption;
    }
    if (showCloseButton) {
      dialogRef.componentInstance.showCloseButton = showCloseButton;
    }
    if (cancelAsClose) {
      dialogRef.componentInstance.cancelAsClose = cancelAsClose;
    }
    if (cancelAsFalse) {
      dialogRef.componentInstance.cancelAsFalse = cancelAsFalse;
    }
    dialogRef.componentInstance.dontAutoClose = !!dontAutoClose;
    return dialogRef.afterClosed();
  }

  public confirmWithMessageList(title: string, messageList: string[], message: string): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.messageList = messageList;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.showDontShowAgainOption = false;

    return dialogRef.afterClosed();
  }

  public confirmTreeButton(title: string, message: string, customButton1: string,
                           customButton2: string = this.utils.i18n('common.yes')): Observable<TRIPLE> {

    let dialogRef: MatDialogRef<ConfirmDialogThreeButtonComponent>;

    dialogRef = this.dialog.open(ConfirmDialogThreeButtonComponent, {disableClose: true});
    dialogRef.componentInstance.title = title ? this.i18n(title) : null;
    dialogRef.componentInstance.message = message ? this.i18n(message) : null;
    dialogRef.componentInstance.customButtonTitle1 = customButton1 ? this.i18n(customButton1) : null;
    dialogRef.componentInstance.customButtonTitle2 = customButton2 ? this.i18n(customButton2) : null;

    return dialogRef.afterClosed();
  }

  public i18n(key, params?) {
    return this.utils.i18n(key, params);
  }

  public msToTime(duration: number, isShort: boolean, isFull: boolean = false) {
    const seconds = parseInt(((duration ) % 60).toString(), 10)
      , minutes = parseInt(((duration / (60)) % 60).toString(), 10)
      , hours = parseInt(((duration / (60 * 60)) % 24).toString(), 10);

    const hoursS = hours ? ((hours < 10) ? '0' + hours : hours) + ' hours ' : '';
    const minutesS = minutes ? ((minutes < 10) ? '0' + minutes : minutes) + ' min ' : '';
    const secondsS = seconds ? ((seconds < 10) ? '0' + seconds : seconds) + ' sec' : '';
    if (isFull) {
      return hoursS + minutesS + secondsS;
    } else if (isShort && duration > 60) {
      return hoursS + minutesS;
    } else {
      return hoursS + minutesS + secondsS;
    }
  }

  getCurrentDomain() {
    return this.localStorage.get('currentDomain', this.utils.getEnv().defaultProvider);
  }

  confirmation(confirmationText: string, okButtonCaption?: string): Promise<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.message = this.i18n(confirmationText);
    if (okButtonCaption) {
      dialogRef.componentInstance.okButtonCaption = this.i18n(okButtonCaption);
    }
    return firstValueFrom(dialogRef.afterClosed().pipe(take(1)));
  }

  confirmationDialog(confirmationText: string): MatDialogRef<any> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.message = this.i18n(confirmationText);
    return dialogRef;
  }

  confirmationSaveChanged(): Promise<TRIPLE> {
    return firstValueFrom(this.confirmTreeButton(null, this.i18n('edit_dialog.confirm_dialog.save.changes'),
      this.i18n('common.no'), this.i18n('common.yes')));
  }

  gotoDocumentByLink(documentLink: IDocumentLink) {
    this.router.navigate([], {
      queryParams: {sid: documentLink.sectionId, cid: documentLink?.contentId}, relativeTo: this.activatedRoute});
  }

  setBlurBackground(blur: boolean) {
    if (blur) {
      const toolbar = document.getElementById('main-toolbar');
      if (toolbar) {
        toolbar.classList.add('menu-blur');
      }
      const container = document.getElementById('main-container');
      if (container) {
        container.classList.add('menu-blur');
      }
    } else {
      const toolbar = document.getElementById('main-toolbar');
      if (toolbar) {
        toolbar.classList.remove('menu-blur');
      }
      const container = document.getElementById('main-container');
      if (container) {
        container.classList.remove('menu-blur');
      }
    }
    this.backgroundBlurred$.next(blur);
  }

  saveToSessionStorageDebugInfo(key: string, info: any) {
    this.sessionStorageService.set(key, typeof info === 'string' ? info : JSON.stringify(info));
  }

  removeSessionStorageDebugInfo(key: string) {
    this.sessionStorageService.remove(key);
  }

  getSessionStorageDebugInfo(key: string) {
    return this.sessionStorageService.get(key, null);
  }

  formatCounter(count: number): number | string {
    if (count > 999) {
      return Math.trunc(count / 1000) + 'k';
    }
    return count;
  }

  listenBackdropClick(dialogRef: MatDialogRef<any>): Observable<any> {
    return dialogRef.backdropClick()
      .pipe(
        filter(() => dialogRef.disableClose),
        switchMap(() => this.confirm(null, this.i18n('common.confirmation.close')).pipe(take(1))),
        tap(res => res ? dialogRef.close() : null)
      );
  }

    /**@description Checks width from device. If lower than 768px, returns true. */
    isMobile$(): Observable<boolean> {
      return this.breakpointObserver.observe([
        Constants.MOBILE_SCREEN_MEDIA
      ]).pipe(map(res => res.matches));
    }
}
