import {Injectable} from '@angular/core';
import {BehaviorSubject, interval, Subject, Subscription, take, takeUntil} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {LoggerService} from './logger.service';
import {Constants} from './constants';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private initServerTime: number;
  private _timer = new BehaviorSubject(0);
  private _initServerDelta = 0;
  private _intervalSubscription: Subscription;
  private _tickCount = 0;
  private _reinitializeTimer = new Subject();
  private _unsubscribe: Subject<any> = new Subject();

  constructor(private aff: AngularFireFunctions, private log: LoggerService) {
    this._reinitializeTimer.pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.createTimer();
      });
  }

  get timerTime(): number {
    return this._timer.getValue();
  }

  private initializeTimer() {
    const st = (new Date()).getTime();
    const gt = this.aff.httpsCallable('getServerTime');
    return gt(null).pipe(take(1)).toPromise().then((value) => {
      const localTime = (new Date()).getTime();
      this.initServerTime = value + Math.round(((localTime - st) / 2));
      this._initServerDelta = this.initServerTime - localTime;
      return Promise.resolve(localTime + this._initServerDelta);
    }).catch((err) => {
      this.log.error(err);
      return Promise.resolve(st);
    });
  }

  createTimer() {
    if (this._intervalSubscription) {
      this._intervalSubscription.unsubscribe();
    }
    this.initializeTimer().then((time) => {
      this._timer.next(time);
      this._intervalSubscription = interval(Constants.ONE_SECOND_INTERVAL)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(value => {
          this._tickCount++;
          const localTime = (new Date()).getTime();
          const serverCalculatedTime = (this.initServerTime + (this._tickCount * 1000));
          const localCalculatedTime = localTime + this._initServerDelta;
          const diff = localCalculatedTime - serverCalculatedTime;
          this._timer.next(localCalculatedTime);
          // Compensate lost ticks
          this._tickCount += Math.floor(diff / 1000);
/*
          // resync because client time can be wrong(user change local time for example)
          if (Math.abs(diff) >= 30 * 1000) {
            this._intervalSubscription.unsubscribe();
            this._tickCount = 0;
            this._reinitializeTimer.next(true);
          } else {
            // Compensate lost ticks
            this._tickCount += Math.floor(diff / 1000);
          }
*/
        });
    });
  }

  getTimer() {
    return this._timer;
  }

  get isTimerRunning() {
    return this._intervalSubscription && !this._intervalSubscription.closed;
  }

  destroy() {
    if (this._intervalSubscription) {
      this._intervalSubscription.unsubscribe();
    }
    this._unsubscribe.next(true);
    this._unsubscribe.complete();
    this._unsubscribe = new Subject();
  }
}
