import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {BehaviorSubject} from 'rxjs';
import {AnswerEquality, AnswerQuestion} from '../../../../../../../model/EventQuestion';
import {clone, isEmpty, merge} from 'lodash';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';

@Component({
  selector: 'app-question-map-matching-participant',
  templateUrl: './question-map-matching-participant.component.html',
  styleUrls: ['./question-map-matching-participant.component.scss']
})
export class QuestionMapMatchingParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  answersData$ = new BehaviorSubject<AnswerQuestion[]>([]);
  correctEquality$ = new BehaviorSubject<AnswerEquality[]>([]);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    this.answersData$.next((this.question.items || [])
      .map(v => new Object({...v, answer: v.getAnswerByLanguage(this.languageParams)}) as AnswerQuestion));
    let answersData: AnswerEquality[] = [];
    if (this.question.showCorrectAnswers) {
      answersData = this.prepareCorrectAnswersData();
    } else {
      answersData = this.prepareAnswersData();
    }
    this.correctEquality$.next(answersData);
  }

  protected onReceiveQuestionAnswers() {
    let answersData: AnswerEquality[] = [];
    if (this.question.showCorrectAnswers) {
      answersData = this.prepareCorrectAnswersData();
    } else {
      answersData = this.prepareAnswersData();
    }
    this.correctEquality$.next(answersData);
  }

  changeMatchingMapAnswer(value: AnswerEquality[]) {
    this.answers = (value || []).filter(o => !!o.answerId).map(o => `${o.id}=${o.answerId}`);
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  private prepareAnswersData() {
    return (this.question.correctEquality || [])
      .filter(o => !isEmpty(o.mapPosition))
      .map(o => new AnswerEquality({id: o.id, mapPosition: o.mapPosition,
        answerId: ((this.answers || []).find(a => a.split('=')[0] === o.id) || '').split('=')[1]}));
  }

  private prepareCorrectAnswersData() {
    return (this.question.correctEquality || [])
      // filtering duplicate row id
      .filter(o => !isEmpty(o.mapPosition))
      .map(o => {
        const uae = new AnswerEquality({
          id: o.id, mapPosition: o.mapPosition,
          answerId: ((this.answers || []).find(a => a.split('=')[0] === o.id) || '').split('=')[1] // fill answerId from user answer
        });
        // highlight row if answer is correct
        uae['correct'] = this.question.correctEquality.find(co => `${co.id}=${co.answerId}` === `${uae.id}=${uae.answerId}`) ;
        return uae;
      });
  }
}
