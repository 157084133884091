import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {LoginService} from '../login/login.service';
import {Event, IEventCreateDto, IGuestEventInfoDto, UserEvent} from '../model/Event';
import {LoggerService} from '../core/logger.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UtilsService} from '../core/utils.service';
import {AppUser} from '../model/AppUser';
import {catchError, filter, firstValueFrom, map, Observable, of, take} from 'rxjs';
import {AngularFirestore, DocumentData, DocumentSnapshot, QueryFn, QuerySnapshot} from '@angular/fire/compat/firestore';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {EventSectionType} from '../model/EventSectionType';
import {SectionContent} from '../model/content/SectionContent';
import {InstantSettings} from '../model/event-mode/InstantSettings';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {Constants, EVENT_DATE_MODE, EVENT_REGISTRATION_MODE, REGISTRATION_ACTION} from '../core/constants';
import {deleteField} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {IInvitedUser, IInviteUserEmail} from './event-mode-api.service';
import {MailTemplateApiService} from './mail-template-api.service';
import {CommonService} from '../core/common.service';

@Injectable()
export class EventApiService extends StdApiService {
  protected API_URL: string;

  constructor(
    private http: HttpClient,
    protected aFirestore: AngularFirestore,
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    protected storage: AngularFireStorage,
    private mailTemplateApiService: MailTemplateApiService,
    private common: CommonService
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/eventApi/v3/';
  }

  getDBPath() {
    return !this.auth.educationMode ? Event.DB_PATH : Event.DB_MODULE_PATH;
  }

  getTimelineCollectionPath() {
    return !this.auth.educationMode ? Event.DB_TIMELINE_PATH : Event.DB_MODULE_TIMELINE_PATH;
  }

  getEvent(eventId: string): Observable<Event> {
    if (!eventId) {
      return of(null);
    }
    return this.afs
      .collection(this.getDBPath())
      .doc<Event>(eventId)
      .snapshotChanges()
      .pipe(
        this.log('getEvent'),
        map(res => this.mapObject(res, (it) => new Event({eventId: it.payload.id, ...it.payload.data()}))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getEventPromise(eventId: string, fromPath?: string): Promise<Event> {
    return firstValueFrom(this.afs
      .collection(fromPath ?? this.getDBPath())
      .doc<Event>(eventId)
      .get()
      .pipe(
        this.log('getEventPromise'),
        filter((it: DocumentSnapshot<Event>) => it.exists),
        map(res => this.mapObject(res, (it) => new Event({eventId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err))
      ));
  }

  getEventByLink(link: string): Observable<Event> {
    return this.afs.collection<Event>(this.getDBPath(), ref => ref.where('shortLink', '==', link).where('deleted', '==', 0))
      .valueChanges({idField: 'eventId'})
      .pipe(
        this.log('getEventByLink'),
        map((list: Event[]) => list.length ? list[0] : null),
        map(res => this.mapObject(res, (it) => new Event(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getEventByLinkPromise(link: string, fromPath?: string): Promise<Event> {
    return firstValueFrom(this.afs.collection<Event>(fromPath ?? this.getDBPath(),
        ref => ref.where('shortLink', '==', link).where('deleted', '==', 0))
      .get()
      .pipe(
        this.log('getEventByLinkPromise'),
        map((list: QuerySnapshot<Event>) => list.docs.length ? list.docs[0] : null),
        map(res => this.mapObject(res, (it) => new Event({eventId: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err))
      ));
  }

  getEventIdByLink(link: string) {
    return firstValueFrom(
      this.afs.collection(this.getDBPath(), ref => ref.where('shortLink', '==', link).where('deleted', '==', 0).limit(1))
      .get()
      .pipe(
        this.log('getEventIdByLink'),
        map((res: DocumentData) => res.docs.length ? res.docs[0].id : null),
        catchError(err => this.firestoreCatchError(err))
      ));
  }

  getNewPublicEvents() {
    const currentTime = new Date();
    return this.afs
      .collection<Event>('events', q => q.where('state', '==', 'public')
        .where('deleted', '==', 0)
        .where('realFinishDate', '>', currentTime))
      .valueChanges({idField: 'eventId'})
      .pipe(
        this.log('getNewPublicEvents'),
        map(res => this.mapArray(res,   it => new Event(it)))
      );
  }

  getMyEvents(past = false): Observable<UserEvent[]> {
    const userId = this.auth.getAuthUser().uid;
    const currentTime = new Date();
    const queryFn: QueryFn = past ? q => {
      return q.where('deleted', '==', 0).where('realFinishDate', '<=', currentTime);
    } : q => {
      return q.where('deleted', '==', 0).where('realFinishDate', '>', currentTime);
    };
    return this.afs
      .collection('app_users').doc(userId)
      .collection<Event>('events', queryFn)
      .valueChanges({idField: 'eventId'})
      .pipe(
        this.log(past ? 'getMyEvents(past)' : 'getMyEvents'),
        map(res => this.mapArray(res,   it => new UserEvent(it)))
      );
  }

  getEvents(hideFinished = false, onlyGuest = false): Observable<Event[]> {
    const currentTime = new Date();
    let queryFn = q => q.where('deleted', '==', 0);
    if (hideFinished) {
      queryFn = q => q.where('deleted', '==', 0).where('realFinishDate', '>', currentTime);
    }
    if (onlyGuest) {
      queryFn = q => q
        .where('deleted', '==', 0)
        .where('isPublic', '==', true)
        .where('published', '==', true)
        .where('guest', '==', true);
      // we can't query by dopState because it's can be not exist, null or 0, that why we use client filter after
      // .where('dopState', '==', 0)
    }
    if (hideFinished && onlyGuest) {
      queryFn = q => q
        .where('deleted', '==', 0)
        .where('isPublic', '==', true)
        .where('realFinishDate', '>', currentTime)
        .where('published', '==', true)
        .where('guest', '==', true);
    }
    return this.afs.collection<Event[]>('events', queryFn)
          .valueChanges({idField: 'eventId'})
          .pipe(
            this.log('getEvents'),
            map(res => this.mapArray(res,   it => new Event(it)))
          );
  }

  getPublicEvents(hideFinished = false, onlyGuest = false): Observable<Event[]> {
    let queryFn = q => q
        .where('isPublic', '==', true)
        .where('deleted', '==', 0)
        .where('published', '==', true);
    if (hideFinished) {
      const currentTime = new Date();
      queryFn = q => q
        .where('isPublic', '==', true)
        .where('deleted', '==', 0)
        .where('realFinishDate', '>', currentTime)
        .where('published', '==', true);
    }
    if (onlyGuest) {
      queryFn = q => q
        .where('isPublic', '==', true)
        .where('deleted', '==', 0)
        .where('published', '==', true)
        .where('guest', '==', true);
    }
    if (hideFinished && onlyGuest) {
      const currentTime = new Date();
      queryFn = q => q
        .where('deleted', '==', 0)
        .where('isPublic', '==', true)
        .where('realFinishDate', '>', currentTime)
        .where('published', '==', true)
        .where('guest', '==', true);
    }
    return this.afs.collection('events', queryFn)
      .valueChanges({idField: 'eventId'})
      .pipe(
        this.log('getPublicEvents'),
        map(res => this.mapArray(res, it => new Event(it)))
      );
  }

  getPermittedUserEvents(userId: string, clientId = null) {
    if (clientId) {
      return this.aFirestore.collection('client_data')
        .doc(clientId)
        .collection('user_events')
        .doc(userId)
        .valueChanges()
        .pipe(
          this.log('getPermittedUserEvents')
        );
    }
    return this.afs
      .collection('user_events')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getPermittedUserEvents')
      );
  }

  getUserEvents(userId?: string) {
    const uid = userId ? userId : this.currentUser.uid;
    return this.afs
      .collection('user_events')
      .doc(uid)
      .valueChanges()
      .pipe(
        this.log('getUserEvents'),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getUserOwnerEvents(userId?: string) {
    const uid = userId ? userId : this.currentUser.uid;
    return this.afs
      .collection('user_owner_events')
      .doc(uid)
      .valueChanges()
      .pipe(
        this.log('getUserOwnerEvents')
      );
  }


  getPermittedUserEventsPromise(userId: string, clientId: string) {
    if (clientId) {
      return firstValueFrom(this.aFirestore.collection('client_data')
        .doc(clientId)
        .collection('user_events')
        .doc(userId)
        .get()
        .pipe(
          this.log('getPermittedUserEventsPromise'),
          map(res => this.mapObject(res, (it) => Object.keys(it.data() || {}))),
          catchError(err => this.firestoreCatchError(err))
        ));
    } else {
      return Promise.resolve([]);
    }
  }

  getInvitedUserEventsPromise(email: string, clientId: string) {
    if (!email || !clientId) {
      return Promise.resolve([]);
    }
    const preparedUserMail = email.replace(/\./g, '_').replace('@', '_');
    return firstValueFrom(this.aFirestore.collection('client_data')
      .doc(clientId)
      .collection('invited_user_events')
      .doc(preparedUserMail)
      .get()
      .pipe(
        this.log('getInvitedUserEventsPromise'),
        map(res => this.mapObject(res, (it) => Object.keys(it.data() || {}))),
        catchError(err => this.firestoreCatchError(err))
      ));
  }

  getUserOwnerEventsByClient(userId: string, clientId: string) {
    return this.aFirestore.collection('client_data')
      .doc(clientId)
      .collection('user_owner_events')
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getUserOwnerEventsByClient')
      );
  }

  getInvitedUserEvents(email: string) {
    if (!email) {
      return of({});
    }
    const preparedUserMail = email.replace(/\./g, '_').replace('@', '_');
    return this.afs
      .collection('invited_user_events')
      .doc(preparedUserMail)
      .valueChanges()
      .pipe(
        this.log('getInvitedUserEvents')
      );
  }

  addInvitedUser(eventId: string, user: IInvitedUser, sectionId: string | null, sendInviteEmail: boolean, clientId?: string) {
    const API_URL = this.BACKEND_BASE + '/eventModeApi/v3/';
    return this.http.post(API_URL +
      `addInvitedUsersWithName?eventId=${eventId}&clientId=${clientId ?? this.auth.client_id$.getValue()}`, [user])
      .toPromise()
      .then(() => {
        if (sendInviteEmail && sectionId) {
          return this.mailTemplateApiService.sendInviteEmail(eventId, sectionId, [user.email]);
        } else {
          return null;
        }
      }).then(() => {
        if (sectionId) {
          const rUser = merge(cloneDeep(user), {
            action: REGISTRATION_ACTION.REGISTER,
            status: Constants.REGISTRATION_STATUS_NEW
          });
          return this.userRegistrationActionWithoutSendEmail(eventId, sectionId, rUser);
        } else {
          return null;
        }
      })
      .catch((e) => this.catchServerError(e));
  }

  addInvitedUsers(eventId: string, invitesList: IInviteUserEmail[] | string[], sectionId: string | null, clientId?: string) {
    const inviteEmail = (invite) => typeof invite === 'string' ? invite : invite.email;
    const inviteRoles = (invite) => typeof invite === 'string' ? null : invite.roles;

    const ifExistsInUsersOnline = (email) => {
      return firstValueFrom(this.getAfs(clientId).collection('conference')
        .doc(eventId)
        .collection('users_online', q => q.where('email', '==', email))
        .get()).then((snap) => snap.docs.length > 0 && snap.docs[0].exists);
    };
    const promise = [];
    for (const invite of invitesList) {
      const email = inviteEmail(invite);
      promise.push(ifExistsInUsersOnline(email).then((existsI) => {
          if (!existsI) {
            const user: IInvitedUser = {
              userId: null,
              email: email,
              fullName: this.utils.extractDefaultDisplayNameFromEmail(email)
            };
            if (inviteRoles(invite)) {
              user['roles'] = (invite as IInviteUserEmail).roles;
            }
            return this.addInvitedUser(eventId, user, sectionId, false, clientId)
              .then(() => Promise.resolve())
              .catch((e) => this.catchServerError(e));
          } else {
            return Promise.resolve();
          }
        })
      );
    }
    return Promise.all(promise);
  }

  verifyGuestCode(eventId: string, code: string): Promise<boolean> {
    return this.http.post<boolean>(this.API_URL + 'verifyGuestCode?eventId=' + eventId + '&code=' + code, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  create(payload: IEventCreateDto) {
    const path = 'events';
    return this.http.post(this.API_URL + path, payload, {responseType: 'text'});
  }

  createEvent(event: Event, managers?: string[], clientId?: string): Promise<any> {
    // todo extract in to additional parameters
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const currentUserUid = this.auth.getAuthUser().uid;
    const eventRef = this.getAfs(clientId).collection<Event>(Event.DB_PATH).doc(this.aFirestore.createId());
    const eventId = eventRef.ref.id;

    if (event.dateMode === EVENT_DATE_MODE.ANYTIME) {
      event.realFinishDate = new Date(Constants.MAX_DATE_MILLIS_01_01_2200);
    } else if (event.endDate && !event.wrapUpPhaseEnd) {
      event.realFinishDate = new Date(event.endDate);
    } else if (event.wrapUpPhaseEnd) {
      event.realFinishDate = new Date(event.wrapUpPhaseEnd);
    } else {
      event.realFinishDate = new Date(Constants.MAX_DATE_MILLIS_01_01_2200);
    }


    return firstValueFrom(this.http.post(this.API_URL + 'generateShortLink', null, {responseType: 'text'}))
      .then((shortLink: string) => event.shortLink = event.shortLink ? event.shortLink : shortLink)
      .then(async () => {
          const batch = this.aFirestore.firestore.batch();
          // todo delete system not needed fields
          event.createDate = currentDate;
          event.ownerKey = currentUserUid;

          delete event['addPMails'];
          delete event['removePMails'];
          delete event['presentersList'];

          const emails = managers ? managers.map(manager => manager.charAt(0) === '*' ? manager.substring(1) : manager) : [];
          await this.addInvitedUsers(eventId, emails, null);

          const managersObj = {};
          const presentersObj = {};
          const conciergesObj = {};

          for (const manager of managers) {
            const isPresenter = manager.charAt(0) !== '*';
            const email = isPresenter ? manager : manager.substring(1);
            const user = await this.getUserCardByEmail(eventId, email);

            const obj = {
              userName: !isEmpty(user.userName) ? user.userName : null,
              fullName: !isEmpty(user.fullName) ? user.fullName : null,
              email: user.email,
              picture: !!user.picture ? user.picture : null,
              department: !!user.department ? user.department : null
            };
            if (isPresenter) {
              managersObj[user.userId] = obj;
              presentersObj[user.userId] = obj;
            } else {
              managersObj[user.userId] = obj;
              conciergesObj[user.userId] = obj;
            }
          }
          event.managers = managersObj;
          event.presenters = presentersObj;
          event.concierges = conciergesObj;
          event.speakers = {};

          batch.set(eventRef.ref, event.toObject());
          const sectionsRef = this.getAfs(clientId).collection('conference')
            .doc(eventId)
            .collection(SectionContent.DB_PATH)
            .doc(this.aFirestore.createId());

          // create root section
          const rootSection = {
            title: 'root',
            expand: true,
            isPublic: true,
            type: 'section',
            invisible: true,
            parentId: null,
            requiredRegistration: event.registrationRequiredMode !== EVENT_REGISTRATION_MODE.NONE
          };
          batch.set(sectionsRef.ref, rootSection);

          // add to owned events
          const ownedEventsRef = this.getAfs(clientId).collection('user_owner_events').doc(currentUserUid);
          const ownedObj = {};
          ownedObj[eventId] = currentTime;
          batch.set(ownedEventsRef.ref, ownedObj);

          // add instant settings
          const isRef = this.getAfs(clientId).collection<InstantSettings>(InstantSettings.DB_PATH).doc(eventId);
          const is = new InstantSettings();
          batch.set(isRef.ref, is.toObject());
          return batch.commit().then(() => {
            return firstValueFrom(this.getAfs(clientId).collection<Event>(Event.DB_PATH)
              .doc(eventId)
              .get()
              .pipe(
                map(res => this.mapObject(res, (it) => new Event({eventId: it.id, ...it.data()})))
              ));
          }).catch((e) => this.throwFirestoreError(e));
        }
      ).catch((e) => this.catchServerError(e));
  }

  deleteEvent(eventId: string): Promise<any> {
    return this.afs.collection(Event.DB_PATH)
      .doc(eventId)
      .set({deleted: 1}, {merge: true}).then(() => {
        return firstValueFrom(this.afs.collection('notification_tasks', ref =>
          ref.where('eventId', '==', eventId))
          .get().pipe(take(1))).then((snap) => {
            const docsObjects = snap.docs;
            let updPromise = Promise.resolve();
            for (const doc of docsObjects) {
              updPromise = updPromise.then(() => {
                const obj = doc.data();
                if (obj['status'] === 'new') {
                  obj['status'] = 'deleted';
                  return doc.ref.set(obj, {merge: true})
                    .catch((err) => this.throwFirestoreError(err));
                } else {
                  return Promise.resolve();
                }
              });
            }
            return updPromise;
          });
      })
      .catch((err) => this.throwFirestoreError(err));
  }

  updateEvent(event: Event): Promise<any> {
    delete event['addPMails'];
    delete event['removePMails'];
    delete event['presentersList'];
    return this.afs
      .collection(Event.DB_PATH)
      .doc(event.eventId)
      .update(event.toObject())
      .then(() => event)
      .catch((err) => this.throwFirestoreError(err));
  }

  updateEventFields(event: Event, params: {[fieldName: string]: any}) {
    return this.afs
      .collection(this.getDBPath())
      .doc(event.eventId)
      .set(params, {merge: true})
      .catch((err) => this.throwFirestoreError(err));
  }

  duplicateEvent(eventId: string, newEventId: string, dateShift: number, inviteUsers: string[], clearUsers: boolean, clientIdTo?: string) {
    const path = `${eventId}/duplicate?newEventId=${newEventId}&dateShift=${dateShift}&invited=${inviteUsers}
                  &clearUsers=${clearUsers}&clientIdTo=${clientIdTo ?? this.auth.client_id$.getValue()}`;
    return this.http.post(this.API_URL + path, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  deleteManager(eventId: string, managerEmail: string) {
    const email = managerEmail.charAt(0) === '*' ? managerEmail.substring(1) : managerEmail;
    return this.getUserCardByEmail(eventId, email)
      .then((user) => {
        if (!user) {
          return null;
        }
        return this.aFirestore.firestore.runTransaction(async transaction => {
          const ref = this.getAfsRef().collection(Event.DB_PATH).doc(eventId);
          const doc = await transaction.get(ref);
          if (!doc.exists) {
            return null;
          }

          const upd = {
            managers: {
              [user.userId]: deleteField()
            },
            presenters: {
              [user.userId]: deleteField()
            },
            concierges: {
              [user.userId]: deleteField()
            }
          };

          transaction.set(ref, upd, {merge: true});
          return Promise.resolve();
        });
      });
  }


  addManager(event: Event, concierge: boolean, emailList: string[]) {
    const eventId = event.eventId;
    return this.addInvitedUsers(eventId, emailList, null)
      .then(async () => {
        const isPresenter = !concierge;
        const managersObj = {};
        const presentersObj = {};
        const conciergesObj = {};

        for (const email of emailList) {
          const user = await this.getUserCardByEmail(eventId, email);
          if (!user) {
            continue;
          }
          const obj = {
            userName: !isEmpty(user.userName) ? user.userName : null,
            fullName: !isEmpty(user.fullName) ? user.fullName : null,
            email: user.email,
            picture: !!user.picture ? user.picture : null,
            department: !!user.department ? user.department : null
          };
          managersObj[user.userId] = obj;
          if (isPresenter) {
            presentersObj[user.userId] = obj;
            conciergesObj[user.userId] = deleteField();
          } else {
            presentersObj[user.userId] = deleteField();
            conciergesObj[user.userId] = obj;
          }
        }

        const upd = {
          managers: managersObj,
          presenters: presentersObj,
          concierges: conciergesObj
        };

        return this.aFirestore.firestore.runTransaction(async transaction => {
          const ref = this.getAfsRef().collection(Event.DB_PATH).doc(event.eventId);
          const doc = await transaction.get(ref);
          if (!doc.exists) {
            return null;
          }
          transaction.set(ref, upd, {merge: true});
          return null;
        });
      }).catch((e) => this.catchServerError(e));
  }

  getUserCard(eventId: string, userId: string): Promise<AppUser> {
    return this.http.post<AppUser>(this.API_URL + `getUserInfo?eventId=${eventId}&userId=${userId}`, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getUserCardByEmail(eventId: string, email: string): Promise<AppUser> {
    return this.http.post<AppUser>(this.API_URL + `getUserInfoByEmail?eventId=${eventId}&email=${email}`, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  sendSupportNotification(id: string): Promise<any> {
    return this.http.post(this.API_URL + 'sendSupportNotification', {id: id})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  changeEventCode(eventId: string) {
    return this.http.post(this.API_URL + 'generateEventCode', null, {responseType: 'text'})
      .toPromise()
      .then(code => {
        if (code) {
          return this.aFirestore.firestore.runTransaction(transaction => {
            const eventRef = this.afs.collection('events').doc(eventId).ref;
            const additionalRef = this.afs.collection('event_additional').doc(eventId).ref;
            return transaction.get(eventRef).then((doc) => {
              const obj = {
                code: code,
                deleted: doc.get('deleted'),
                shortLink: doc.get('shortLink')
              };
              transaction.set(additionalRef, obj, {merge: true});
              return code;
            });
          });
        } else {
          return Promise.reject();
        }
      })
      .catch((e) => this.catchServerError(e));
  }

  generateShortLink() {
    return this.http.post(this.API_URL + 'generateShortLink', null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getSectionTypes(eventId: string): Observable<EventSectionType[]> {
    const path = `conference/${eventId}/${EventSectionType.DB_PATH}`;
    return this.afs.collection<EventSectionType[]>(path)
      .valueChanges()
      .pipe(
        this.log('getSectionTypes'),
        map(res => this.mapArray(res, (it) => {
          it.name = this.utils.i18alt('section.type.' + it.name.toLowerCase(), it.name);
          return new EventSectionType(it);
        })),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getSectionTypesPromise(eventId: string): Promise<EventSectionType[]> {
    const path = `conference/${eventId}/${EventSectionType.DB_PATH}`;
    return firstValueFrom(this.afs.collection<EventSectionType[]>(path)
      .get()
      .pipe(
        this.log('getSectionTypesPromise'),
        map((res: QuerySnapshot<EventSectionType[]>) => this.mapArray(res.docs, (it) => {
          const obj = it.data();
          obj.name = this.utils.i18alt('section.type.' + obj.name.toLowerCase(), obj.name);
          return new EventSectionType(obj);
        }))
      ))
      .catch((e) => this.throwFirestoreError(e));
  }

  addSectionType(eventId: string, sectionType: EventSectionType): Promise<any> { // Example for adding data
    // todo need default ids for default types or not... need to check in duplicate event
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(EventSectionType.DB_PATH)
      .add(sectionType.toObject())
      .catch((e) => this.throwFirestoreError(e));
  }

  updateSectionTypes(eventId: string, types: EventSectionType[], deleteType: {id: any}, clientId?: string): Promise<any> {
    const promises = [];
    if (!isEmpty(deleteType)) {
      promises.push(firstValueFrom(this.getAfs(clientId).collection('conference')
        .doc(eventId)
        .collection(EventSectionType.DB_PATH, ref => ref.where('id', '==', deleteType.id))
        .get())
        .then(value => {
          if (value && value.size) {
            return this.getAfs(clientId).collection('conference')
              .doc(eventId)
              .collection(EventSectionType.DB_PATH)
              .doc(value.docs[0].id).delete();
          }
        }));
    }
    for (const type of types) {
      promises.push(firstValueFrom(this.getAfs(clientId).collection('conference')
        .doc(eventId)
        .collection(EventSectionType.DB_PATH, ref => ref.where('id', '==', type.id))
        .get())
        .then(value => {
          if (value && value.size) {
            return this.getAfs(clientId).collection('conference')
              .doc(eventId)
              .collection(EventSectionType.DB_PATH)
              .doc(value.docs[0].id)
              .set(type.toObject(), {merge: true})
              .catch((err) => this.throwFirestoreError(err));
          } else {
            return this.addSectionType(eventId, type);
          }
        }));
    }
    return Promise.all(promises);
  }

  userRegistrationActionWithoutSendEmail(eventId: string, sectionId: string, content: any) {
    const contentJSON = JSON.stringify(content);
    return this.http.post(this.API_URL + 'userRegistrationActionWithoutSendEmail?eventId=' +
      eventId + '&sectionId=' + sectionId, {value: contentJSON})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  userRegistrationAction(eventId: string, sectionId: string, content: any) {
    const contentJSON = JSON.stringify(content);
    return this.http.post(this.API_URL + 'userRegistrationAction?eventId=' + eventId + '&sectionId=' + sectionId, {value: contentJSON})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  userRegistrationActionTask(eventId: string, content: any) {
    const contentJSON = JSON.stringify(content);
    return this.http.post(this.API_URL + 'userRegistrationActionTask?eventId=' + eventId, {value: contentJSON})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getSignedUrl(path: string) {
    return this.http.post(this.API_URL + 'getSignedUrl?path=' + path, null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  migrateDb() {
    return this.http.post(this.API_URL + 'migrateDb', null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getGuestEventInfo(link: string, clientId: string, guestCode: string): Observable<IGuestEventInfoDto> {
    const path = `${link}/guestInfo`;
    return this.http.get<IGuestEventInfoDto>(this.API_URL + path, {headers: new HttpHeaders({
        'Access-Code': guestCode,
        'Client-Id': clientId
      })});
  }

  getDeletedContents(eventId: string, sectionId: string, hours: number) {
    const path = `${eventId}/section/${sectionId}/content?time=${hours}`;
    return this.http.get(this.API_URL + path);
  }

  restoreContent(eventId: string, sectionId: string, contentId: string, hours: number) {
    const path = `${eventId}/section/${sectionId}/content/${contentId}/restore?time=${hours}`;
    return this.http.post(this.API_URL + path, null);
  }

  restoreSection(eventId: string, sectionId: string, hours: number) {
    const path = `${eventId}/section/${sectionId}/restore?time=${hours}`;
    return this.http.post(this.API_URL + path, null);
  }
}
