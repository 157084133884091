import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/common.service';
import {cloneDeep} from 'lodash';
import {StdComponent} from '../../../../../core/std-component';
import {Constants, TRIPLE} from '../../../../../core/constants';
import {UtilsService} from '../../../../../core/utils.service';
import {SKETCHFAB_PARAMS, TSketchfab} from '../sketchfab-model/sketchfab-model';
import {BehaviorSubject} from 'rxjs';
import {NgxCurrencyInputMode} from 'ngx-currency';

interface IParamData {
  fieldName: string;
  caption: string;
  tooltip: string;
  maxValue: number;
  value: any;
  order: number;
}

@Component({
  selector: 'app-sketchfab-document-editor-dialog',
  templateUrl: './sketchfab-document-editor-dialog.component.html',
  styleUrls: ['./sketchfab-document-editor-dialog.component.scss']
})
export class SketchfabDocumentEditorDialogComponent extends StdComponent implements OnInit {

  readonly inputModel = NgxCurrencyInputMode.Natural;

  editDialogData: any;
  dataHash: string;
  isModify = false;
  sketchfab: TSketchfab;

  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };
  paramsData: IParamData[] = [];

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<SketchfabDocumentEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private common: CommonService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    dialogRef.updateSize('600px');
    this.sketchfab = UtilsService.wrapObjectToProxy(new TSketchfab(cloneDeep(data)), this.dataChangesHandler);
    this.dataHash = UtilsService.md5(UtilsService.jsonSorted(this.sketchfab));
    this.initParamsData();

    this.editDialogData = {
        title: this.common.utils.i18n('text.editor.dialog.edit.sketch.uid')
      , fields: [
        {id: 'sketchUID', type: 'text', required: false, showClearButton: true},
      ]
      , result: {sketchUID: this.sketchfab.uid}
      , withDelete: false
    };

    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  ngOnInit(): void {
    this.dataDetectChanges.pipe(this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.sketchfab));
      });
  }

  onOkClick(): void {
    this.dialogRef.close(this.sketchfab);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  initParamsData() {
    for (const paramName of Object.keys(this.sketchfab.params)) {
      const pv = SKETCHFAB_PARAMS[paramName];
      this.paramsData.push({
        fieldName: paramName,
        caption: pv.caption,
        tooltip: pv.tooltip,
        maxValue: pv.maxValue,
        order: pv.orderInEditDialog,
        value: this.sketchfab.params[paramName]
      });
    }
  }
}

