import {AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/common.service';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {firstValueFrom} from 'rxjs';
import {HtmlEditorDialogComponent} from '../html-editor-dialog/html-editor-dialog.component';

@Component({
  selector: 'app-html-note',
  templateUrl: './html-note.component.html',
  styleUrls: ['./html-note.component.scss']
})
export class HtmlNoteComponent extends AbstractContainerComponent implements OnInit, AfterViewInit {

  @ViewChild('htmlNote', { read: ElementRef }) htmlNoteRef: ElementRef;

  constructor(protected injector: Injector,
              private dialog: MatDialog,
              private common: CommonService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.data$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (this.htmlNoteRef) {
          this.htmlNoteRef.nativeElement.innerHTML = this.common.utils.sanitizeTolerant(value, true);
        }
      });
  }

  onEdit() {
    const dialogRef = this.dialog.open(HtmlEditorDialogComponent, {
      width: '600px',
      height: '600px',
      disableClose: true,
      data: {
        html: this.data
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = result;
        }
        return !!result;
      });
  }

  protected inputFollowMeData(value) {}

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

}
