export interface IEmbeddedFrame {
  provider: EMBEDDED_FRAME_PROVIDER;
  hostName: string;
  clientName: string;
  id: string;
}

export enum EMBEDDED_FRAME_PROVIDER {
  PRESSBOOKS = 'pressbooks',
  LEARNING_SNACKS = 'learning-snacks',
  LEARNING_APPS = 'learning-apps'
}

interface IProviderFieldsModel {
  fieldName: string;
  indexOfMatching: number;
}

interface IProviderTemplate {
  template: RegExp;
  model: IProviderFieldsModel[];
}

export interface IEmbeddedFrameProviderSettings {
  providerName: EMBEDDED_FRAME_PROVIDER;
  icon: string;
  site: string;
  providerTemplates: IProviderTemplate[];
  shortURL: (v: IEmbeddedFrame) => string;
  embeddedURL: (v: IEmbeddedFrame) => string;
}

export interface IEmbeddedFrameProvidersSettings {
  [providerName: string]: IEmbeddedFrameProviderSettings;
}

export const PROVIDER_SETTINGS: IEmbeddedFrameProvidersSettings = {
  [EMBEDDED_FRAME_PROVIDER.PRESSBOOKS]: {
    providerName: EMBEDDED_FRAME_PROVIDER.PRESSBOOKS,
    icon: 'assets/images/social_network/pressbooks.svg',
    site: 'pressbooks.com',
    providerTemplates: [
      {
        template: /([A-Za-z0-9.-]+)(\/[A-Za-z0-9]+\/)(wp-admin\/admin-ajax\.php\?action=h5p_embed).+id=([0-9]+)/,
        model: [
          {fieldName: 'hostName', indexOfMatching: 1},
          {fieldName: 'clientName', indexOfMatching: 2},
          {fieldName: 'id', indexOfMatching: 4}
        ]
      }
    ],
    shortURL: (v: IEmbeddedFrame) => {
      return `${v.hostName}/${v.clientName}/${v.id}`;
    },
    embeddedURL: (v: IEmbeddedFrame) => {
      return `https://${v.hostName}/${v.clientName}/wp-admin/admin-ajax.php?action=h5p_embed&id=${v.id}`;
    }
  },
  [EMBEDDED_FRAME_PROVIDER.LEARNING_SNACKS]: {
    providerName: EMBEDDED_FRAME_PROVIDER.LEARNING_SNACKS,
    icon: 'assets/images/social_network/learning_snacks.png',
    site: 'learningsnacks.de',
    providerTemplates: [
      {
        template: /(learningsnacks.de)(\/[a-zA-Z]+\/)([0-9]+)/,
        model: [
          {fieldName: 'id', indexOfMatching: 3}
        ]
      }
    ],
    shortURL: (v: IEmbeddedFrame) => {
      return `learningsnacks.de/${v.id}`;
    },
    embeddedURL: (v: IEmbeddedFrame) => {
      return `https://www.learningsnacks.de/embed/${v.id}/`;
    }
  },
  [EMBEDDED_FRAME_PROVIDER.LEARNING_APPS]: {
    providerName: EMBEDDED_FRAME_PROVIDER.LEARNING_APPS,
    icon: 'assets/images/social_network/learning_apps.png',
    site: 'learningapps.org',
    providerTemplates: [
      {
        template: /(learningapps.org)\/.+=([0-9]+)/,
        model: [
          {fieldName: 'id', indexOfMatching: 2}
        ]
      },
      {
        template: /(learningapps.org)\/view([0-9]+)/,
        model: [
          {fieldName: 'id', indexOfMatching: 2}
        ]
      },
      {
        template: /(learningapps.org)\/([0-9]+)/,
        model: [
          {fieldName: 'id', indexOfMatching: 2}
        ]
      }
    ],
    shortURL: (v: IEmbeddedFrame) => {
      return `learningapps.org/${v.id}`;
    },
    embeddedURL: (v: IEmbeddedFrame) => {
      return `https://learningapps.org/watch?app=${v.id}`;
    }
  }
};
