import { Component } from '@angular/core';
import { AbstractQuizQuestionParticipantComponent } from '../../shared/participant/abstract-quiz-question-participant-component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AnswersQuestion } from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import { indexOf, orderBy } from 'lodash';

@Component({
  selector: 'app-question-prioritization-participant',
  templateUrl: './question-prioritization-participant.component.html',
  styleUrl: './question-prioritization-participant.component.scss'
})
export class QuestionPrioritizationParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.question.items, event.previousIndex, event.currentIndex);
    this.handleSendAnswer();
  }

  handleSendAnswer() {
    if (!this.acceptAnswers) {
      return;
    }

    this.answers = this.question.items.map((item) => item.id)
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  protected onReceiveQuestionAnswers() {
    if (this.answers && this.answers.length)
      this.question.items = orderBy(this.question.items, item => indexOf(this.answers, item.id), 'asc');
  }

}
