import {Component, Injector, OnInit} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {CommonService} from '../../../../../core/common.service';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import {BehaviorSubject, filter, firstValueFrom, Subject} from 'rxjs';
import {ZoomifyDocumentService} from '../zoomify-document.service';
import {Constants} from '../../../../../core/constants';

interface IZoomifyData {
  id: string;
  srcUrl?: string;
  src: string;
}

interface IZoomifyPosition {
  x: number;
  y: number;
  z: number;
  r: number;
}

const PREVIEW_NAME = '~preview.zif';

@Component({
  selector: 'app-zoomify-document',
  templateUrl: './zoomify-document.component.html',
  styleUrls: ['./zoomify-document.component.scss']
})
export class ZoomifyDocumentComponent extends AbstractContainerComponent implements OnInit {

  private uppy: any;
  private closeUploaderResult$ = new Subject<boolean>();
  zoomifyPosition: IZoomifyPosition;
  previewData: IZoomifyData;
  previewUploaded = false;
  detectChanges$ = new BehaviorSubject<number>(0);
  srcUrl: string;

  constructor(protected injector: Injector,
              private common: CommonService,
              private uploadService: UploadService,
              private zds: ZoomifyDocumentService) {
    super(injector);
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.ZIF, null, async result => {
        this.itemComponentEditorLoading$.next(true);
        this.previewUploaded = true;
        const tmpData = {
          id: PREVIEW_NAME,
          src: result[0].response
        };
        this.previewData = await this.zds.save(this.previewStoragePath(), tmpData)
          .catch(() => this.itemComponentEditorLoading$.next(false));
        const prefix = this.common.utils.generateRandomString(4).toLowerCase();
        this.data = {
          id: `${result[0].id.replace(/\//g, '-').replace('uppy', prefix)}.${result[0].extension}`,
          srcUrl: this.srcUrl,
          src: result[0].response
        };
        this.closeUploaderResult$.next(true);
        this.uploadService.closeModal(this.uppy);
        this.detectChanges$.next(new Date().getTime());
      },
      () => this.closeUploaderResult$.next(false));
  }

  ngOnInit(): void {
    this.data$.pipe(this.takeUntilAlive())
      .subscribe((value: IZoomifyData) => {
        if (!this.previewData) {
          this.previewData = value;
          this.detectChanges$.next(new Date().getTime());
        }
        if (!this.srcUrl && value?.id?.indexOf(';base64,') === -1) {
          this.srcUrl = value?.src;
        }
      });
  }

  onDestroy() {
    super.onDestroy();
    if (this.previewUploaded) {
      this.zds.delete(this.previewStoragePath(), this.previewData);
    }
    this.uppy.close();
  }

  protected previewStoragePath() {
    return `${this.documentPathParams.eventId}/${this.documentPathParams.sectionId}/${this.documentPathParams.containerId}`;
  }

  protected inputFollowMeData(value: IZoomifyPosition) {
    this.zoomifyPosition = value;
    this.detectChanges$.next(new Date().getTime());
  }

  onEdit(): Promise<boolean> {
    this.uploadService.openUploadWindow(this.uppy);
    const uppyDashboards = document.getElementsByClassName('uppy-Dashboard');
    for (const elem of Array.from(uppyDashboards)) {
      (elem as HTMLElement).onkeydown = (ev: KeyboardEvent) => {
        if (ev.code === Constants.ESCAPE) {
          ev.stopPropagation();
          this.uploadService.closeModal(this.uppy);
          this.closeUploaderResult$.next(false);
        }
      };
    }
    return firstValueFrom(this.closeUploaderResult$);
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  zoomifyViewChangeEvent(event) {
    const p: IZoomifyPosition = {x: event.x, y: event.y, z: event.z, r: event.r};
    this.itemComponentEditorLoading$.next(false);
    this.outputFollowMeData.emit(p);
  }
}
