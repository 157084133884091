import {AfterViewInit, Component, ElementRef, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Constants} from '../../core/constants';
import {TimeLineService} from '../../services/time-line.service';
import {CommonService} from '../../core/common.service';
import {take} from 'rxjs';
import * as ui from '../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {StdComponent} from '../../core/std-component';
import {DialogFieldsPanelComponent} from '../dialog-fields-panel/dialog-fields-panel.component';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent extends StdComponent implements OnInit, AfterViewInit {
  result: any;
  validate = true;
  withDelete = false;
  afterView = false;

  @ViewChild('actionPanel') actionPanel: ElementRef;
  @ViewChild('fieldsPanel') fieldsPanel: DialogFieldsPanelComponent;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<EditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
            , public timeLineService: TimeLineService
            , public store: Store<fromRoot.State>
            , public common: CommonService) {
    super(injector);
    this.withDelete = data.withDelete;
    dialogRef.addPanelClass('timeline');
    if (!data.disableConfirmDialog) {
      this.common.listenBackdropClick(dialogRef).pipe(this.takeUntilAlive()).subscribe();
    }
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
  }

  ngAfterViewInit(): void {
    this.afterView = true;
  }

  onDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  setResult(result) {
    this.result = result;
    // set disableClose after all html input values was initialized
    if (this.afterView) {
      // needing for listening backdropClick event
      this.dialogRef.disableClose = true;
    }
  }

  setValidate(validate) {
    this.validate = validate;
  }

  onOkClick(): void {
    if (!this.validate) {return; }
    this.dialogRef.close(this.result);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    this.common.confirm(this.common.i18n('edit_dialog.confirm_dialog.header'),
      this.common.i18n('edit_dialog.confirm_dialog.delete.any')).pipe(take(1)).subscribe(res => {
      if (res) {
        this.dialogRef.close({delete: this.result});
      }
    });
  }
}
