import {Component, ElementRef, Injector, viewChild} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import * as ui from '../../../../../../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../reducers';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {MatTooltip} from '@angular/material/tooltip';
import {filter, interval, take} from 'rxjs';

@Component({
  selector: 'app-question-text-participant',
  templateUrl: './question-text-participant.component.html',
  styleUrls: ['./question-text-participant.component.scss']
})
export class QuestionTextParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  answerIsCorrect = false;
  correctAnswer: string;
  correctTooltip = viewChild<MatTooltip>('tooltip');

  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private store: Store<fromRoot.State>) {
    super(injector, elementRef);
  }

  protected onInit() {
    this.questionsDirectFeedbackChecked$
      .pipe(filter(() => !!this.correctTooltip && !this.question?.tryAgain), this.takeUntilAlive())
      .subscribe(value => {
        if (!value) {
          this.correctAnswer = '';
          this.correctTooltip().hide();
        } else {
          const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
          this.correctAnswer = correctEquality?.map(s => `[ ${s.getAnswerByLanguage(this.languageParams$.getValue())} ]`).join(' ');
          interval(100).pipe(take(1)).subscribe(() => this.correctTooltip().show());
        }
      });
  }

  protected initQuestionAnswersDataSource() {
    const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
    this.answerIsCorrect = this.question.showCorrectAnswers &&
      correctEquality.some(it => it.getAnswerByLanguage(this.languageParams$.getValue()).toLowerCase() === this.answers[0]?.toLowerCase());
  }

  getTextAnswerLength() {
    return this.answers && this.answers[0] ?
      this.answers[0].length : 0;
  }

  editAnswer(edit) {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(edit));
  }

  switchItem(event) {
    if (!this.acceptAnswers) {
      return;
    }
    let sendItem: string;
    sendItem = this.common.utils.sanitized(event.currentTarget.value ? event.currentTarget.value : '');
    sendItem = sendItem.length === 0 ? null : sendItem;
    const answers: string[] = [];
    answers.push(sendItem);
    this.answers = answers;
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  onDestroy() {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(false));
  }
}
