import {Component, ElementRef, Injector} from '@angular/core';
import {Constants, SMILES_RATING_SETS, SMILES_RATING_SETS_NAMES} from '../../../../../../../core/constants';
import {CHART_HEIGHT, CHART_POINT_RADIUS, IAnswer, SHORT_CHART_SMILE_COUNT_LESS} from '../../../quiz-model/quiz';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {isEmpty, max, merge} from 'lodash';

interface IAnswersChartPoint {
  x: number;
  y: number;
  count: number;
}

@Component({
  selector: 'app-question-scale-results',
  templateUrl: './question-scale-results.component.html',
  styleUrls: ['./question-scale-results.component.scss']
})
export class QuestionScaleResultsComponent extends AbstractQuizQuestionResultsComponent {
  readonly SMILES_RATING_SETS = SMILES_RATING_SETS;
  readonly CHART_HEIGHT = CHART_HEIGHT;
  readonly CHART_POINT_RADIUS = CHART_POINT_RADIUS;
  readonly SHORT_CHART_SMILE_COUNT_LESS = SHORT_CHART_SMILE_COUNT_LESS;

  displayedColumns = ['answer'];
  dataSource = [];
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];
    this.question.items.forEach(answer => dataList.push(
      {count: 0, answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex,
        correctAnswer: answer.correctAnswer, progress: '', unprogress: ''}));
    dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.dataSource = dataList;
    this.calcDataSourceFields();
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.getGroupedScaleSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.calcDataSourceFields();
  }

  private calcDataSourceFields() {
    const getPropValue = (qKey, propName: string, def: number = 0) => this.answers && this.answers[propName] ? this.answers[propName] : def;
    let maxLen = 0;
    for (const row of this.dataSource) {
      row.count = getPropValue(this.qKey, row.id);
      maxLen = maxLen < (row.count + '').length ? (row.count + '').length : maxLen;
      const scaleEnd = getPropValue(this.qKey, 'scaleEnd');
      const scaleEnd1 = getPropValue(this.qKey, 'scaleEnd', 1);
      row.progress = (scaleEnd !== 0 ? row.count * 100 / scaleEnd1 : 0) + '%';
      row.unprogress = (scaleEnd !== 0 ? (100 - (row.count * 100 / scaleEnd1)) : 100) + '%';
    }
    this.dataSource.forEach(row => row.minWidth = (maxLen === 1 ? 1 : (1.2 + 0.6 * (maxLen - 2))) + 'em');
  }

  private getGroupedScaleSummaryAnswers(question: EventQuestion, answersRating: {[answerId: string]: {[smile: string]: number}}) {

    const smileCost = (smile: string, smileSet: SMILES_RATING_SETS_NAMES): number => {
      const cost = SMILES_RATING_SETS[smileSet].findIndex(s => s === smile);
      return cost < 0 ? 0 : cost * 10;
    };

    const createChartStyle = (data: {x: number, y: number}[]): string => {
      let style = 'polygon(';
      data.forEach((point, index) => style += `${index > 0 ? ',' : ''}${point.x}% ${point.y}%`);
      return style + ')';
    };

    const answersResult: {[answerId: string]: number} = {};
    const answersChart: {[answerId: string]: IAnswersChartPoint[]} = {};
    const answersChartStyles: {[answerId: string]: string} = {};
    question.items.forEach(a => {
      answersResult[a.id] = 0;
      answersChart[a.id] = [{x: 0, y: 0, count: 0}];
    });
    for (const answerId of Object.keys(answersRating)) {
      if (isEmpty(answersChart[answerId])) {
        continue;
      }
      const answerSummary: {[smile: string]: number} = answersRating[answerId];
      let answerRating = 0;
      let answerCount = 0;
      for (const smile of Object.keys(answerSummary)) {
        answerCount += answerSummary[smile];
        answerRating += smileCost(smile, question.options.smilesRatingSet) * answerSummary[smile];
      }
      answersResult[answerId] = answerCount > 0 ? Math.round(answerRating / answerCount) / 10 : 0;
      const maxY = max(Object.values(answerSummary));
      const stepX = 100 / (SMILES_RATING_SETS[question.options.smilesRatingSet].length - 1);
      let index = 0;
      if (maxY > 0) {
        for (let i = 0; i < SMILES_RATING_SETS[question.options.smilesRatingSet].length; i++) {
          const smile = SMILES_RATING_SETS[question.options.smilesRatingSet][i];
          const yValue = answerSummary[smile] ?? 0;
          const y = (yValue / maxY) * 100;
          answersChart[answerId].push({x: stepX * index, y: y < 1 ? 1 : y, count: yValue});
          index++;
        }
      }
      answersChart[answerId].push({x: 100, y: 0, count: 0});
      answersChartStyles[answerId] = createChartStyle(answersChart[answerId]);
    }
    const maxValue = SMILES_RATING_SETS[question.options.smilesRatingSet].length - 1;
    return merge(answersResult, {scaleEnd: maxValue, answersChartStyles: answersChartStyles, answersChartData: answersChart});
  }

}
