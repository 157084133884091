import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatButton} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import User = firebase.User;
import OAuthProvider = firebase.auth.OAuthProvider;

@Component({
  selector: 'app-auth-confirm-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    TranslateModule
  ],
  templateUrl: './auth-confirm-dialog.component.html',
  styleUrl: './auth-confirm-dialog.component.scss'
})
export class AuthConfirmDialogComponent {
  firebaseUser: User;
  provider: OAuthProvider;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AuthConfirmDialogComponent>) {
    this.firebaseUser = data.firebaseUser;
    this.provider = data.provider;
  }

  async onClose(result: boolean): Promise<void> {
    const credential = await this.firebaseUser.reauthenticateWithPopup(this.provider);
    this.dialogRef.close(credential);
  }
}
