import {Tag} from '../tags-model/Tag';

export enum TAGS_PATHS {
  MAIN = 'app_users',
  TAGS_REFERENCE = 'tags-reference',
  TAGS_DOC_REFERENCE = 'tags-doc-reference',
  CONTENTS_TAGS = 'contents-tags',
}

export const DEFAULT_TAGS_REFERENCE = [
  new Tag({id: '000001', color: '#22A7F0'}),
  new Tag({id: '000002', color: '#44AC42'}),
  new Tag({id: '000003', color: '#FFCE00'}),
  new Tag({id: '000004', color: '#DD1515'}),
  new Tag({id: '000005', color: '#F87832'}),
  new Tag({id: '000006', color: '#ADB5BD'}),
  new Tag({id: '000007', color: '#BE8C6B'}),
];

export const TAGS_COLORS = [
  '#22A7F0', '#44AC42', '#FFCE00',
  '#DD1515', '#F87832', '#ADB5BD',
  '#BE8C6B', '#19A1A1', '#000000',
  '#FFB653', '#E4E4E4', '#CCEAEC',
  '#304ADF', '#B315A3', '#9E903E', '#9D34E6'
];

export const CONTENT_PRIORITY = [null, 1, 2, 3];

export interface IContentTagsParams {
  eventId: string;
  contentId: string;
  itemId?: string;
  tagsIdList: string[];
}

export interface IContentPriorityParams {
  eventId: string;
  contentId: string;
  itemId?: string;
  priority: number;
}

export interface IContentTags {
  tags: string[];
  processed?: boolean;
  priority: number;
}

export interface IItemsTagsMap {
  [itemId: string]: IContentTags;
}

export interface IExtContentTags extends IContentTags {
  items?: IItemsTagsMap;
}

export interface IContentsTagsMap {
  [contentId: string]: IExtContentTags;
}

export interface ITagContentParent {
  contentId: string;
  contentOriginalId: string;
}
