import { Component, ElementRef, Injector, ViewChild } from "@angular/core";
import { AbstractQuizQuestionResultsComponent } from "../../shared/results/abstract-quiz-question-results-component";
import { AnswerQuestion } from "../../../../../../../../../src/app/model/EventQuestion";
import { cloneDeep, isEmpty } from "lodash";
import { Chart } from "chart.js";
import { CORRECT_VALUE_INDEX, END_VALUE_INDEX, START_VALUE_INDEX, getIconPositionInPixelForBarChart } from "../open-number-estimation-utils";
import { createChart} from "./open-number-estimation-chartjs-utils";

export interface UsersInfo {
  totalAnswer: number,
  iconPosition: number,
}

export interface ChartInfo {
  totalAnswer: number,
  details: number[],
  iconPosition: number
}


@Component({
  selector: "app-question-open-number-estimation-results",
  templateUrl: "./question-open-number-estimation-results.component.html",
  styleUrl: "./question-open-number-estimation-results.component.scss",
})
export class QuestionOpenNumberEstimationResultsComponent extends AbstractQuizQuestionResultsComponent {
  usersInfo: UsersInfo[] = [];
  answersQestions: AnswerQuestion[];
  startValue: number;
  endValue: number;
  correctValue: number;
  iconPosition: number;

  isChartCreated = false;
  data = [];

  gradient: any;

  readonly maxBars = 51;
  aggregatedData: ChartInfo[] = [];


  @ViewChild('chartCanvas', { read: ElementRef, static: true }) chartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('lineFill', { read: ElementRef, static: true }) lineFill!: ElementRef<any>;

  chart: Chart | undefined;



  constructor(protected injector: Injector, protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    if (this.question) {
      this.answersQestions = cloneDeep(this.question.items);
      this.initData();
    }
  }

  private initData() {
    if(!this.isChartCreated && !this.endValue){
      this.startValue = parseInt(this.question.items[START_VALUE_INDEX].answer + '');
      this.endValue = parseInt(this.question.items[END_VALUE_INDEX].answer + '');

      if(this.question.items.length - 1 == CORRECT_VALUE_INDEX){
        this.correctValue = parseInt(this.question.items[CORRECT_VALUE_INDEX].answer + '');
        this.correctValue -=  this.startValue;
      }

      this.data = this.fillArrayWithZeros(this.data);
    }
  }

  protected onReceiveQuestionAnswers() {

    if (!isEmpty( this.summaryQuestionAnswers)) {
      const entries: [string, number][] = Object.entries( this.summaryQuestionAnswers) as [string, number][];

      for(const [key, value]  of entries){
        if(value && !this.isChartCreated){

          const index = parseInt(key);

          if(!this.endValue){
            this.initData();
          }

          this.data[index -  this.startValue] = value;

          this.usersInfo.push({
            totalAnswer: value,
            iconPosition: getIconPositionInPixelForBarChart(this.lineFill, this.startValue, this.endValue, index)
          });
        }
      }


      if(!this.isChartCreated){
        this.aggregatedData =  this.aggregateData(this.data, this.maxBars);
        const ctx = this.chartCanvas.nativeElement.getContext('2d');
        this.chart = createChart(this, ctx);
        this.isChartCreated = true;
      }
    }
  }





  private aggregateData(data: number[], maxBars: number): ChartInfo[] {
    const groupSize = Math.ceil(data.length / maxBars);
    const aggregatedData: { totalAnswer: number; details: number[], iconPosition: number }[] = [];

    for (let i = 0; i < data.length; i += groupSize) {
      const group = data.slice(i, i + groupSize);
      const tooltipGroup = Array.from({ length:  groupSize}, (_, idx) => {
        return (idx + i) < data.length ?  this.startValue + (idx + i) : null;
      }).filter(num => num !== null);

      const aggregatedValue = group.reduce((sum, value) => sum + value, 0);
      const iconIndex = tooltipGroup.reduce((sum, value) => sum + value, 0)/tooltipGroup.length;
      aggregatedData.push({
        totalAnswer: aggregatedValue,
        details: tooltipGroup,
        iconPosition: aggregatedValue
          ? getIconPositionInPixelForBarChart(this.lineFill, this.startValue, this.endValue, iconIndex)
          : 0
      });
    }

    return aggregatedData;
  }


   fillArrayWithZeros(arr: number[]): number[] {
    const min = this.startValue;
    const max = this.endValue;

    // Create a new array with zeros where values are missing
    const filledArray = Array.from({ length: max - min + 1}, (_, i) =>
        arr.includes(min + i) ? min + i : 0
    );

    return filledArray;
  }



}




