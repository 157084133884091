import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {UtilsService} from '../core/utils.service';
import {CommonService} from '../core/common.service';
import {LoggerService} from '../core/logger.service';
import {RootLocalStorageService} from '../core/root-local-storage.service';
import {LoadingService} from '../core/loading.service';
import {ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as data from '../actions/data';
import * as fromRoot from '../reducers';
import {AppUser, AppUserResponse} from '../model/AppUser';
import {GoogleAPIService} from '../services/google-api.service';
import {Constants} from '../core/constants';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {BehaviorSubject, combineLatest, filter, firstValueFrom, Observable, of, ReplaySubject, skip, take} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {isEmpty} from 'lodash';
import firebase from 'firebase/compat/app';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {ClientConfig} from '../model/ClientConfig';
import {AuthService, ILoadingState} from '@ninescopesoft/core';
import {AuthConfirmDialogComponent} from './auth-confirm-dialog/auth-confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {GoogleAuthProvider, OAuthProvider} from '@angular/fire/auth';
import User = firebase.User;
import IdTokenResult = firebase.auth.IdTokenResult;
import UserCredential = firebase.auth.UserCredential;

export enum APP_MODE {
  TIMELINE = 'timeline',
  EDUCATION = 'education',
  NOTES = 'notes',
  QUESTION_CARDS = 'question_cards',
  EXAMS = 'exams'
}

@Injectable()
export class LoginService implements OnDestroy {
  private loading = true;
  public signInInProgress = false;
  public getConfigInProgress = true;
  private SCOPES: Array<String>;
  public signedIn = false;
  public signedOut = false;
  claims: any;
  appUser$: BehaviorSubject<AppUser> = this.auth.appUser$;
  public authenticatedUser = new ReplaySubject<User>(1);
  private authUser: User;
  private lastUserResponse: any;
  public idToken: any;
  public accessToken: any;
  public authToken: any;
  public authTokenObserver = new ReplaySubject<any>(1);
  applicationLoading$: BehaviorSubject<ILoadingState> = this.auth.applicationLoading$;

  public alive = true;
  guestLogin = false;
  guestCode: string;
  guestLink: string;
  registrationCode: string;
  inited = false;
  lastUrl: string;
  client_id$: BehaviorSubject<string> = this.auth.client_id$;
  client_id$_: ReplaySubject<string> = new ReplaySubject<string>(1);
  singleClientMode = '';
  clientConfig$: BehaviorSubject<ClientConfig> = new BehaviorSubject<ClientConfig>(null);
  fromUrl:  'main' | 'join' | 'registration'  = 'main';
  resetConfig = false;
  termsAndPrivacy = false;
  requiredVerification = false;
  isMainDomain = true;
  applicationMode: APP_MODE = APP_MODE.TIMELINE;
  urlHelper: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  idTokenResult: BehaviorSubject<IdTokenResult> = new BehaviorSubject<IdTokenResult>(null);
  loginFailedEmail: string;
  passwordFailedEmail: string;

  constructor(private utils: UtilsService
    , private common: CommonService
    , private store: Store<fromRoot.State>
    , private googleApiService: GoogleAPIService
    , private logger: LoggerService
    , private localStorageService: RootLocalStorageService
    , private router: Router
    , private activatedRoute: ActivatedRoute
    , private loadingService: LoadingService
    , private zone: NgZone
    , private afAuth: AngularFireAuth
    , private auth: AuthService
    , private http: HttpClient
    , private aff: AngularFireFunctions
    , private afDB: AngularFireDatabase
    , private dialog: MatDialog) {
    logger.debug('init', 'loginFactory');
    this.isMainDomain = this.common.getEnv().domains.includes(window.location.host);
    this.SCOPES = utils.getEnv().gapi.scopes;
    if (this.common.getCurrentDomain() === Constants.PROVIDER_HKVBS_AZURE) {
      this.common.localStorage.set('currentDomain', Constants.PROVIDER_HKVBS_MICROSOFT);
    }
    this.singleClientMode = utils.getEnv().client_id;
    if (!window.location.pathname.startsWith('/login')) {
      this.lastUrl = window.location.pathname + window.location.search;
    }
    activatedRoute.queryParams.subscribe((val) => {
      if (val && val.provider === 'google') {
        this.common.localStorage.set('allowedProviders', 'gapi');
      }
    });
    router.events.subscribe((val: any) => {
      let url: string = null;
      if (val instanceof NavigationStart) {
        url = val.url;
      }
      if (this.common.getEnv().production && window.location.host === 'go.timeline.click' && url === '/event/vhfu') {
        window.location.href = 'https://rochepartner.timeline.click/event/vhfu';
      }
      if (val instanceof  NavigationEnd && val.url && this.utils.getQueryParamValue(val.url, 'c')) {
        this.router.navigate([], {queryParams: {c: null}, queryParamsHandling: 'merge'});
      }
      if (url && url.startsWith('/go/')) {
        this.fromUrl = 'join';
      }
      if (url && (url === '/terms' || url === '/privacy')) {
        this.termsAndPrivacy = true;
      }
      if (url && url.startsWith('/registration/')) {
        this.fromUrl = 'registration';
      }
      if (!url || (!url.startsWith('/auth') && url !== '/login?provider=google')) {
        // this.saveLastURL(url);
      }
      if (url && url.startsWith('/guest')) {
        const linkObject = this.utils.parseLink(url);
        if (linkObject.link && linkObject.type === 'guest' && linkObject.code) {
          this.guestLogin = true;
          this.guestCode = linkObject.code;
        }
      }
      if (!this.inited && !this.termsAndPrivacy) {
        this.inited = true;
        this.loadBegin();
        this.load();
        let queryParams = null;
        if (url) {
          queryParams = this.utils.getQueryParams(url);
        }
        if (this.isMainDomain && queryParams && queryParams['c']) {
          const cValue = queryParams['c'];
          this.requestClientConfig({
            link: cValue
          });
        } else if (url && this.fromUrl === 'registration') {
          let split = url.split('/');
          split = split.filter(it => it);
          const registrationCode = split.length > 1 ? split[1] : null;
          this.registrationCode = registrationCode;
          const clientObj = this.getClientFromCache();
          this.requestClientConfig({
            obj: clientObj,
            registrationCode: registrationCode
          });
        } else if (this.fromUrl === 'main') {
          const clientObj = this.getClientFromCache();
          if (clientObj) {
            clientObj.cached = true;
          }
          this.requestClientConfig({obj: clientObj});
        }
      }
    });
    this.client_id$
      .pipe(skip(1))
      .subscribe(value => {
        this.client_id$_.next(value);
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  static get isOpenIdAuthRedirect() {
    return window.location.pathname.startsWith('/auth');
  }

  get educationMode(): boolean {
    return this.applicationMode === APP_MODE.EDUCATION;
  }

  get timelineMode(): boolean {
    return this.applicationMode === APP_MODE.TIMELINE;
  }

  getClientFromCache(): ClientConfig {
    const clientObj = this.localStorageService.get('client_obj');
    return clientObj ? JSON.parse(clientObj) as ClientConfig : null;
  }

  clearClientCache() {
    this.localStorageService.remove('client_obj');
  }

  setClientCache(clientConfig: ClientConfig) {
    this.common.localStorage.set('client_obj', JSON.stringify(clientConfig));
  }

  public saveLastURL(url: any) {
    this.lastUrl = url;
  }

  updateLocalClaims(claims, init = false) {
    this.claims = claims;
    if (!isEmpty(claims.client_id)) {
      this.client_id$.next(claims.client_id);
    } else if (!init) {
      this.client_id$.next(null);
    }
  }

  private load() {
    this.auth.afAuth.idTokenResult
      .subscribe((result) => {
        this.idTokenResult.next(result);
      });
    combineLatest([
      this.afAuth.authState,
      this.clientConfig$.pipe(filter(it => !it?.cached))
    ])
      .subscribe(([user, clientConfig]: [firebase.User, ClientConfig]) => {
        if (user && clientConfig && !clientConfig.cached) {
          if (user && !user.isAnonymous && this.guestLogin) {
            this.disableGuestMode();
          }
          return user.getIdTokenResult(true)
            .then(result => {
              this.authTokenObserver.next(result.token);
              this.authToken = result.token;
              this.authUser = user;
              if (!clientConfig.externalUsers && result.signInProvider === 'password') {
                return this.signOut()
                  .then(() => Promise.reject(this.common.i18n('error.login.password.not_allowed')));
              }
            })
            .then(() => {
              this.loadBegin();
              if (!this.signedIn) {
                if (!user.isAnonymous || user.displayName) {
                  return this.updateCurrentUserProfile(user)
                    .then(() => {
                      return this.onFirebaseSignInSuccess(user);
                    });
                }
              } else {
                return this.onFirebaseSignInSuccess(user);
              }
            })
            .then(() => {
              this.loadEnd();
            });
        } else if (!user) {
          this.authTokenObserver.next(null);
          this.authToken = null;
          this.client_id$.next(null);
          this.authUser = null;
          this.authenticatedUser.next(null);
          if (this.resetConfig) {
            this.resetConfig = false;
            // this.common.localStorage.remove('client_obj');
            this.requestClientConfig();
          }
          if (!LoginService.isOpenIdAuthRedirect && (this.signedOut || !this.guestLogin)) {
            this.signedOut = false;
            this.redirectToLoginPage();
          }
          this.loadEnd();
        }
      }, () => {
        this.loadEnd();
      });
  }

  getToken(type: 'idToken' | 'accessToken' = 'idToken') {
    const loginProvider = this.getCurrentProviderId();
    if (loginProvider === Constants.PROVIDER_HKVBS_AZURE) {
      return Promise.resolve(null);
    } else if (loginProvider === Constants.PROVIDER_HKVBS_MICROSOFT) {
      const provider = this.getMicrosoftAuthProvider();
      if (this.utils.validateToken(type === 'idToken' ? this.idToken : this.accessToken)) {
        return Promise.resolve(this.idToken);
      } else {
        return this.authUser.reauthenticateWithPopup(provider)
          .then((result: any) => {
            // {credential: OAuthCredential}
            let accessToken = null;
            let idToken = null;
            if (result && result.credential) {
              accessToken = result.credential.accessToken;
              idToken = result.credential.idToken;
            }
            this.idToken = idToken;
            this.accessToken = accessToken;
            return Promise.resolve(type === 'idToken' ? idToken : accessToken);
          })
          .catch(e => {
            return Promise.resolve(null);
          });
      }
    } else {
      return Promise.resolve(this.getAuthToken());
    }
  }

  updateCurrentUserProfile(firebaseUser: User) {
    this.logger.success('SUCCESS signIn', firebaseUser);
    this.setLoginCookies();
    const loginEmail = (firebaseUser && this.utils.validateEmail(firebaseUser.email)) ? firebaseUser.email : undefined;
    if (loginEmail) {
      this.localStorageService.set('loginEmail', loginEmail);
    }
    this.googleApiService.customParams['login_hint'] = loginEmail;

    if (!firebaseUser || !this.utils.validateEmail(firebaseUser.email)) {
      return Promise.resolve(null);
    }
    const userData: {
      username?: string,
      email?: string,
      picture?: string,
      googleId?: string
    } = {};
    let isGoogleProvider = false;
    let isMicrosoft = false;
    const loginProvider = this.getCurrentProviderId();
    const uid = firebaseUser.uid;
    userData.email = firebaseUser.email;
    userData.picture = firebaseUser.photoURL;
    if (firebaseUser.providerData) {
      for (let i = 0; i < firebaseUser.providerData.length; i++) {
        const providerData = firebaseUser.providerData[i];
        if (loginProvider === 'microsoft') {
          if (providerData.providerId === 'microsoft.com') {
            isMicrosoft = true;
            userData.username = providerData.displayName;
            userData.email = providerData.email;
          }
        } else
        if (providerData.providerId === 'google.com') {
          isGoogleProvider = true;
          userData.username = providerData.displayName;
          userData.email = providerData.email;
          userData.picture = providerData.photoURL;
          userData.googleId = providerData.uid;
        }
      }
    }
    let promise = Promise.resolve(null);
    if (loginProvider === 'microsoft' && !isMicrosoft) {
      promise = promise.then(() => {
        const microsoftAuthProvider = this.getMicrosoftAuthProvider();
        return firebaseUser.linkWithRedirect(microsoftAuthProvider);
      });
    }
    if (!userData.username) {
      userData.username = this.utils.extractDefaultDisplayNameFromEmail(userData.email);
    }
    if (isMicrosoft) {
      const provider = this.getMicrosoftAuthProvider();

      if (firebaseUser.email !== userData.email) {
        promise = promise.then(() =>
          firebaseUser.updateEmail(userData.email)
            .then(() => true)
            .catch(error => {
              if (error.code === 'auth/requires-recent-login') {
                return firstValueFrom(this.dialog.open(AuthConfirmDialogComponent, {
                  data: {
                    firebaseUser: firebaseUser,
                    provider: provider
                  }
                }).afterClosed())
                  .then((credential: UserCredential) => {
                    return credential.user.updateEmail(userData.email)
                      .then(() => true);
                  })
                  .catch((_error) => {
                    this.signOut();
                    throw _error;
                  });
              }
              this.signOut();
              throw error;
            }));
      }
      if (userData.username && firebaseUser.displayName !== userData.username) {
        promise = promise.then(() =>
          firebaseUser.updateProfile({
            displayName: userData.username
          }).then(() => true)
            .catch(error => {
              if (error.code === 'auth/requires-recent-login') {
                return firstValueFrom(this.dialog.open(AuthConfirmDialogComponent, {
                  data: {
                    firebaseUser: firebaseUser,
                    provider: provider
                  }
                }).afterClosed())
                  .then((credential: UserCredential) => {
                    return credential.user.updateProfile({
                      displayName: userData.username
                    }).then(() => true);
                  })
                  .catch((__error) => {
                    this.signOut();
                    throw __error;
                  });
              }
              this.signOut();
              throw error;
            }));
      }
    } else if (isGoogleProvider) {
      promise = promise.then((changed) => {
        const obj = {};
        if (userData.picture && firebaseUser.photoURL !== userData.picture) {
          obj['photoURL'] = userData.picture;
        }
        if (userData.username && firebaseUser.displayName !== userData.username) {
          obj['displayName'] = userData.username;
        }
        if (isEmpty(obj)) {
          return changed;
        }
        return firebaseUser.updateProfile(obj)
          .then(() => true)
          .catch((error) => {
            this.signOut();
            throw error;
          });
      });
      promise = promise.then((changed) => {
        if (firebaseUser.email !== userData.email) {
          return firebaseUser.updateEmail(userData.email)
            .then(() => true)
            .catch((error) => {
              this.signOut();
              throw error;
            });
        }
        return changed;
      });
    }
    promise = promise.then((changed) => {
      if (changed) {
        return this.refreshToken(true);
      }
    });
    return promise;
  }

  navigateToLastUrl() {
    const url = this.lastUrl || 'dashboard';
    return this.router.navigate([url]);
  }

  public applyVerification(): Promise<any> {
    const customAccepted = this.clientConfig$.getValue().customTerms ? this.appUser.acceptCustomTerms : true;
    if (this.appUser.acceptClientTermsAndPrivacy && customAccepted) {
      return this.navigateToLastUrl()
        .then(() => {
          location.reload();
        });
    } else {
      return this.router.navigate(['accept']);
    }
  }

  public onFirebaseSignInSuccess(firebaseUser: User, guest?): Promise<any> {
    return Promise.resolve().then(() => {
      this.loadBegin();
      if (this.guestCode && this.guestLink && !this.authUser.isAnonymous) {
        const client_id = this.clientConfig$.getValue() ? this.clientConfig$.getValue().id : this.common.getEnv().client_id;
        const path = `${this.guestLink}/join?clientId=${client_id}`;
        return firstValueFrom(this.http.post(this.utils.getEnv().BACKEND_BASE + '/eventApi/v3/' + path, null, {
          headers: new HttpHeaders({
            'Access-Code': this.guestCode
          })
        })).catch(() => {
          this.lastUrl = null;
          this.guestLink = null;
          this.guestCode = null;
        });
      }
    }).then(() => {
      return this.loginBackend();
    }).then((userResponse: AppUserResponse) => {
      if (guest) {
        return this.refreshToken(true)
          .then(() => firebaseUser.getIdTokenResult()
            .then((result) => {
              this.updateLocalClaims(result.claims);
            }))
          .then(() => userResponse);
      }
      return userResponse;
    }).then((userResponse: AppUserResponse) => {
      this.logger.debug('User Response: ', userResponse);
      if (!userResponse || userResponse.blocked) {
        this.signOut(true);
      } else {
        const customAccepted = this.clientConfig$.getValue().customTerms ? this.appUser.acceptCustomTerms : true;
        const reload = this.common.localStorage.get('reload', false);
        if (reload) {
          this.common.localStorage.remove('reload');
          this.router.navigate([''])
            .then(() => {
              location.reload();
            });
        } else if (this.authUser && !this.authUser.emailVerified &&
          ((this.claims && this.claims.firebase && this.claims.firebase.sign_in_provider === 'password') || this.authUser.providerId === 'password')) {
          this.router.navigate(['verify']);
        } else if (this.appUser && (!this.appUser.acceptClientTermsAndPrivacy || !customAccepted)) {
          this.router.navigate(['accept']);
        } else {
          this.authenticatedUser.next(this.authUser);
          this.applicationLoading$.next({...this.applicationLoading$.getValue(), user: true});
          if (!this.client_id$.getValue() && !this.singleClientMode) {
            this.redirectTo('go');
          } else if (!this.lastUrl && window.location.pathname === '/login') {
            this.redirectToHomePage();
          } else if (this.lastUrl && this.lastUrl !== '/') {
            this.redirectTo(this.lastUrl);
            this.lastUrl = null;
          } else if (!window.location.pathname || window.location.pathname === '/') {
            this.router.navigate(['dashboard']);
          }
        }
      }
      this.loadEnd();
    }).finally(() => this.signInInProgress = false);
  }

  public signIn(domain?, manual = false, name?, email?, pass?, register?, customDomain?: string) {
    const service = this;
    this.logger.debug('signIn with domain:\'' + domain + '\'');
    const onFail = function () {
      service.logger.debug('FAIL to signIn');
      service.loadEnd();
      service.loading = false;
      service.signInInProgress = false;
      service.gotoLogin().then(() => service.redirectToLoginPage(true));
      service.loginFailedEmail = 'We do not recognize this e-mail';
      service.passwordFailedEmail = 'The password is not correct';
    };


    const logSignInError = function (error) {
      service.logger.error('signIn domain:\'' + domain + '\'', error);
      service.common.showError(error);
    };

    service.googleApiService.customParams = {};
    if (domain && domain !== 'other') {
      service.googleApiService.customParams['hd'] = domain + '.com';
    }
    if (customDomain) {
      service.googleApiService.customParams['hd'] = customDomain;
    }
    const loginEmail = service.localStorageService.get('loginEmail');
    if (loginEmail) {
      service.googleApiService.customParams['login_hint'] = loginEmail;
    }

    const checkPasswordAuth = function(_email, _pass) {
      let promise;
      if (register) {
        promise = service.afAuth.createUserWithEmailAndPassword(_email, _pass);
        service.logger.debug('firebaseService.auth().createUserWithEmailAndPassword()', window.location.hash);
      } else {
        promise = service.afAuth.signInWithEmailAndPassword(_email, _pass);
        service.logger.debug('firebaseService.auth().signInWithEmailAndPassword()', window.location.hash);
      }
      return promise
        .then(result => {
          return (register ? result.user.updateProfile({displayName: name}) : Promise.resolve())
            .then(() => {
              if (!result.user.emailVerified) {
                return service.requestVerificationEmail(result.user)
                  .then(() => {
                    return service.router.navigate(['verify']);
                  });
              }
            })
            .then(() => {
              return result;
            });
        })
        .then(result => {
          const userKey = Constants.getUserKey();
          service.logger.debug('User-Key2', userKey);
          if (userKey) {
            service.localStorageService.set('last-user-key', userKey);
          } else {
            service.localStorageService.remove('last-user-key');
          }
        })
        .catch(function (error) {
          logSignInError(error);
          onFail();
        });
    };

    const checkFirebaseAuth = function() {
      service.logger.debug('firebaseService.auth().getRedirectResult()', window.location.hash);
      const provider = service.getGoogleAuthProvider();
      return service.afAuth.signInWithPopup(provider)
        .then(result => {
          const userKey = Constants.getUserKey();
          service.logger.debug('User-Key2', userKey);
          if (userKey) {
            service.localStorageService.set('last-user-key', userKey);
          } else {
            service.localStorageService.remove('last-user-key');
          }
        })
        .catch(function (error) {
          logSignInError(error);
          onFail();
        });
    };

    const signInWithMicrosoft = function() {
      service.logger.debug('firebaseService.auth().getRedirectResult()', window.location.hash);
      const provider = service.getMicrosoftAuthProvider();
      return service.afAuth.signInWithPopup(provider)
        .then(result => {
          const userKey = Constants.getUserKey();
          service.logger.debug('User-Key2', userKey);
          if (userKey) {
            service.localStorageService.set('last-user-key', userKey);
          } else {
            service.localStorageService.remove('last-user-key');
          }
          if (result && result.credential) {
            // const accessToken = result.credential['accessToken'];
            service.idToken = result.credential['idToken'];
            service.accessToken = result.credential['accessToken'];
          }
        })
        .catch((error) => {
          if (error.code === 'auth/account-exists-with-different-credential') {
            return service.afAuth.fetchSignInMethodsForEmail(error.email)
              .then((providers) => {
                if (!(providers || []).includes('microsoft.com') && error.credential.providerId === 'microsoft.com') {
                  const uid = error.customData._tokenResponse.localId;
                  const rawId = error.customData._tokenResponse.federatedId.replace('http://microsoft.com/', '');
                  const url = service.utils.getEnv().BACKEND_BASE + `/userApi/v3/${uid}/provider?rawId=${rawId}`;
                  service.authToken = error.credential.idToken;
                  return firstValueFrom(service.http.post(url, null))
                    .then(() => service.afAuth.signInWithCredential(error.credential));
                } else {
                  signInWithAzureAuth();
                }
              })
              .catch((_error) => {
                return service.signOut();
              });
          } else {
            logSignInError(error);
            onFail();
          }
        });
    };

    const signInWithAzureAuth = function() {
      if (!service.getAuthUser()) {
        const providerParams = service.utils.getEnv()['hkvbs-azure'];
        const redirect_uri = providerParams.redirect_uri;
        const scopes = providerParams.scopes.join('%20');
        const client_id = providerParams.client_id;
        const API_URL = providerParams.API_URL;
        window.location.href = API_URL +
          '?response_type=code&redirect_uri=' + redirect_uri + '&client_id=' + client_id + '&scope=' + scopes;
      }
    };

    if (domain === 'email') {
      return checkPasswordAuth(email, pass);
    } else
    if (domain === 'other') {
      return checkFirebaseAuth();
    } else if (domain && domain === 'microsoft') {
      return signInWithMicrosoft();
    } else if (domain === 'anonymous') {
      // service.router.navigate(['guest', '']);
    } else {
      return checkFirebaseAuth();
    }

  }

  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  requestVerificationEmail(user?: User) {
    return user ? user.sendEmailVerification() : this.authUser.sendEmailVerification();
  }

  getGoogleAuthProvider(): GoogleAuthProvider {
    const provider = new GoogleAuthProvider();
    this.SCOPES.forEach(function (item: string) {
      provider.addScope(item);
    });
    provider.setCustomParameters(this.googleApiService.customParams);
    return provider;
  }

  getMicrosoftAuthProvider(): OAuthProvider {
    const providerParams = this.utils.getEnv()['microsoft'];

    const provider = new OAuthProvider('microsoft.com');

    const customParams = {
      prompt: 'select_account'
    };
    if (providerParams.tenant_id) {
      customParams['tenant'] = providerParams.tenant_id;
    }
    if (providerParams.redirect_uri) {
      customParams['redirect_uri'] = providerParams.redirect_uri;
    }
    if (!isEmpty(customParams)) {
      provider.setCustomParameters(customParams);
    }

    for (const scope of providerParams.scopes) {
      provider.addScope(scope);
    }

    return provider;
  }

  public loginGuest(name, email, guestCode: string): Promise<any> {
    const client_id = this.clientConfig$.getValue() ? this.clientConfig$.getValue().id : this.common.getEnv().client_id;
    const url = this.utils.getEnv().BACKEND_BASE + '/userApi/v3/login';
    const params: {[key: string]: string} = {};
    params.client_id = client_id;
    return firstValueFrom(this.http.post(url,  params, {headers: new HttpHeaders({
        'Access-Code': guestCode,
        'Client-Id': client_id,
        'Guest-Name': name,
        'Guest-Email': email,
      })}));
  }

  signInEmail(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signInAnonymously(userName: string) {
    if (this.authUser) {
      return Promise.resolve(this.authUser);
    } else {
      this.guestLogin = true;
      return this.afAuth.signInAnonymously()
        .then(result => {
          return result.user.updateProfile({displayName: userName})
            .then(() => {
              return this.refreshToken(true)
                .then(() => this.afAuth.currentUser)
                .then((currentUser) => {
                  return this.onFirebaseSignInSuccess(currentUser, true);
                });
            });
        });
    }
  }

  public setGuestLogin(value: boolean) {
    this.guestLogin = value;
  }

  public loginBackend(client_id?: string): Promise<any> {
    if (!client_id) {
      client_id = this.clientConfig$.getValue() ? this.clientConfig$.getValue().id : this.common.getEnv().client_id;
    }
    let url = this.utils.getEnv().BACKEND_BASE + '/userApi/v3/login';
    const params: {[key: string]: string} = {};
    if (this.guestLogin || (this.getAuthUser() && this.getAuthUser().isAnonymous)) {
      url = this.utils.getEnv().BACKEND_BASE + '/userApi/v3/loginGuest';
      if (this.guestCode) {
        params.registrationCode = this.guestCode;
      }
    } else {
      if (this.registrationCode) {
        params.registrationCode = this.registrationCode;
      }
    }
    if (client_id) {
      params.client_id = client_id;
    }
    return this.http.post(url, params)
      .toPromise()
      .then((resp: AppUserResponse) => {
        return this.authUser.getIdTokenResult(true)
          .then((result) => {
            this.authTokenObserver.next(result.token);
            this.authToken = result.token;
            this.updateLocalClaims(result.claims);

            this.signedIn = true;
            this.setLoginCookies();

            this.setAppUser(resp.user);

            this.lastUserResponse = resp;
            this.loadEnd();
            return this.lastUserResponse;
          });
      })
      .catch((resp) => {
        if (resp && resp.result && resp.result.error && resp.result.error.code) {
          const code = resp.result.error.code;
          if (code === 400 || code === 401) {
            this.signOut(true).then(() => {
              this.common.showError('Failure ' + resp.result.error.code, resp.result.error.message);
              this.redirectToLoginPage();
            });
          } else {
            this.signOut(true).then(() => {
              this.common.showError(resp);
              this.redirectToLoginPage();
            });
          }
        } else {
          this.signOut(true).then(() => {
            this.common.showError(resp);
            this.redirectToLoginPage();
          });
        }
        this.loadingService.loadFinish();
        throw resp;
      });
  }

  private disableGuestMode() {
    this.guestLogin = false;
    this.localStorageService.remove('guest_code');
  }

  public signOut(manual = false) {
    const service = this;
    service.signedIn = false;
    service.appUser = null;
    if (manual) {
      service.localStorageService.remove('loginEmail');
      this.clearClientCache();
    }
    service.removeLoginCookies();
    this.disableGuestMode();
    this.signedOut = true;
    this.store.dispatch(new data.LogoutAction());
    this.afDB.database.goOffline();
    service.logger.debug('firebaseService.auth().signOut() before');
    return service.afAuth.signOut().then(() => {
      service.logger.success('firebaseService.auth().signOut() after');
      service.logger.debug('GAuth.logout()');
      service.googleApiService.signOut();
      service.googleApiService.customParams = {};
      service.store.dispatch(new data.SetCurrentUserAction(service.appUser));
      service.lastUrl = null;
      service.signedIn = false;
      this.guestLink = null;
      this.guestCode = null;
      this.resetConfig = true;
      this.applicationLoading$.next({user: false});
      this.signInInProgress = false;
    }, function (error) {
      service.logger.error('firebaseService.auth().signOut()', error);
      // An error happened.
    });
  }

  public gotoLogin() {
    return this.signOut(false);
  }

  private checkLoginCookies() {
    const loginCookie = this.localStorageService.get('login');
    return loginCookie === 'true';
  }

  private setLoginCookies() {
    this.localStorageService.set('login', 'true');
  }

  private removeLoginCookies() {
    this.localStorageService.remove('login');
  }

  private loadBegin() {
    this.loadingService.loadBegin();
  }

  private loadEnd() {
    this.loadingService.loadEnd();
  }

  public redirectToHomePage(checkLoginUrl = false) {
    if (checkLoginUrl) {
      return this.redirectTo(this.lastUrl);
    } else {
      return this.redirectTo('');
    }
  }

  public redirectToLoginPage(force = false) {
    return this.redirectTo('/login');
  }

  public redirectTo(link) {
    this.logger.debug('redirectTo: ', link, window.location);
    if (window.location.pathname + window.location.search === link) {
      this.logger.debug('redirectTo rejected');
      return;
    }
    if (link == null) {
      link = '';
    }
    return this.zone.run(() => {
        if (link.indexOf('?') > 0) {
          const params = link.substring(link.indexOf('?') + 1);
          const split = params.split('&');
          const queryParams = {};
          for (const it of split) {
            const param = it.split('=');
            if (param[0] !== 'c') {
              queryParams[param[0]] = param[1];
            }
          }
          const extras: NavigationExtras = {
            queryParams: queryParams
          };
          const url = link.substring(0, link.indexOf('?'));
          this.router.navigate([url], extras);
        } else {
          this.router.navigate([link]);
        }
      }
    );
  }

  public isClient(): boolean {
    return this.claims?.client_id === this.client_id$.getValue();
  }

  /**
   * Check user role, if user superadmin will be returned true
   * @param role
   */
  hasRole(role: string | string[]) {
    const roles = this.claims?.roles ? this.claims.roles.split(',') : [];
    if (this.isClient() && roles.includes(Constants.ROLE_SUPERADMIN)) {
      return true;
    }
    if (Array.isArray(role)) {
      return this.isClient() && roles.some(it => role.includes(it));
    } else {
      return this.isClient() && roles.includes(role);
    }
  }

  public isSuperadmin(): boolean {
    return this.claims?.roles?.includes(Constants.ROLE_SUPERADMIN);
  }

  public isAdmin() {
    return this.isClient() && this.claims?.roles?.includes(Constants.ROLE_ADMIN);
  }

  public isModerator() {
    return this.isClient() && this.claims?.roles?.includes(Constants.ROLE_MODERATOR);
  }

  public isTrainer() {
    return this.isClient() && this.claims?.roles?.includes(Constants.ROLE_TRAINER);
  }

  public isSimpleRegistration() {
    return this.isClient() && this.claims?.roles?.includes(Constants.ROLE_SIMPLE_REGISTRATION);
  }

  public isManage() {
    return this.isClient() && this.claims?.roles?.includes(Constants.ROLE_MANAGE);
  }

  public isGuest(): boolean {
    return this.claims?.guest;
  }

  getAuthenticatedUser(): Observable<User> {
    return this.authenticatedUser;
  }

  getAuthenticatedUserPromise(): Promise<any> {
    return Promise.resolve(this.authenticatedUser);
  }

  getAppUser() {
    return this.appUser;
  }

  get appUser() {
    return this.appUser$.getValue();
  }

  set appUser(value: AppUser) {
    this.appUser$.next(value);
  }

  setAppUser(user) {
    const userKey = Constants.getUserKey();
    this.logger.debug('User-Key1', userKey);
    this.appUser = new AppUser(user, userKey);
    this.store.dispatch(new data.SetCurrentUserAction(this.appUser));
  }

  getAuthUser() {
    return this.authUser;
  }

  updateProfilePicture(url) {
    return this.authUser ? this.authUser.updateProfile({photoURL: url}) : Promise.resolve();
  }

  getAuthToken() {
    return this.authToken;
  }

  refreshToken(force?: boolean) {
    const service = this;
    if (this.authUser) {
      return this.authUser.getIdToken(force).then( (token) => {
        service.authTokenObserver.next(token);
        // this.authenticatedUser.next(this.afAuth.currentUser);
        // this.authUser = this.afAuth.currentUser;
        return service.authToken = token;
      });
    }
    return of(null).toPromise();
  }

  isPasswordProvider() {
    return this.authUser?.providerId === 'password';
  }

  getCurrentProviderId() {
    const currentDomain = this.common.localStorage.get('currentDomain', this.common.utils.getEnv().defaultProvider);
    const providers = this.utils.getEnv().providers;
    const provider = providers.find(p => p.value === currentDomain);
    // const defaultProvider = this.getDefaultProvider();
    const defaultProviderId = UtilsService.getDefaultProviderId();
    return provider ? provider.provider : defaultProviderId ? defaultProviderId : null;
  }

  getAzureIdToken() {
    return this.idToken;
  }

  setAzureAccessToken(access_token: string) {
    this.idToken = access_token;
  }

  goToMainDomain(path: string = '') {
    window.location.href = `https://${this.common.utils.getEnv().domains[0]}${path}`;
  }

  get isLocalhost() {
    return window.location.host.includes('localhost');
  }

  requestClientConfig(request?: {
    link?: string,
    id?: string,
    obj?: ClientConfig,
    registrationCode?: string
  }): any | ClientConfig {
    let obj = {};
    if (request) {
      if (request.obj) {
        this.clientConfig$.next(request.obj);
        this.auth.clientConfigFeatures$.next(this.clientConfig$.getValue()?.features);
      }
      obj = {
        id: request.obj ? request.obj.id : request.id,
        link: request.link,
        registrationCode: request.registrationCode
      };
    }
    const callable = this.aff.httpsCallable('getClientConfig');
    return callable({key: 'JzimQq9kJPGYjTwV4oyJUxPB0dfIo4lV', host: window.location.host, ...obj}).pipe(take(1)).toPromise()
      .then((config) => {
        const defaultClient = new ClientConfig({
          id: this.common.getEnv().client_id,
          name: this.common.getEnv().appName,
          defaultClient: true,
          externalUsers: this.common.getEnv().email.externalUsers,
          selfRegistration: this.common.getEnv().email.selfRegistration
        });
        const clientConfig = isEmpty(config) ? defaultClient : new ClientConfig(config);
        if (this.isMainDomain && !this.isLocalhost && !clientConfig.defaultClient && clientConfig.domains) {
          const filtered = clientConfig.domains.filter(it => it !== window.location.host);
          if (!isEmpty(filtered)) {
            const cachedClientConfig = this.getClientFromCache();
            if (cachedClientConfig && cachedClientConfig.id === clientConfig.id) {
              this.clearClientCache();
            }
            window.location.href = 'https://' + filtered[0];
            return null;
          }
        }
        this.setClientCache(clientConfig);
        this.clientConfig$.next(clientConfig);
        this.auth.clientConfigFeatures$.next(clientConfig?.features);
        this.getConfigInProgress = false;
        return clientConfig;
      });
  }

}
