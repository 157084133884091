import {TABLE_COLUMN_TYPE} from '../question-table-editor/table-poll-model/TablePollModel';
import {EventQuestion, IGroupCorrectAnswers} from '../../../../../../../model/EventQuestion';
import {cloneDeep, difference, intersection, isEmpty, union} from 'lodash';

/*
   about question table group correct answers  structure
   question.groupsCorrectAnswers[groupId].correctAnswers[columnId]

   correctAnswers[columnId] by type

   TABLE_COLUMN_TYPE.CHECKBOX:
   columnCorrectAnswers[columnId] = [question_rowId_1, question_rowId_2, ....]

   TABLE_COLUMN_TYPE.DROPDOWN:
     columnCorrectAnswers[columnId] = {
       {[optionId_1]: [question_rowId_1, question_rowId_2, ....]}
       {[optionId_2]: [question_rowId_1, question_rowId_2, ....]}
       ...
     }

 */

export const getGroupCorrectAnswers = (groupsCorrectAnswers: IGroupCorrectAnswers[],
                                       // if group id null use default group
                                       groupId?: string) => {
  let group: IGroupCorrectAnswers;
  if (!groupId) {
    group = groupsCorrectAnswers.find(g => g.defaultGroup);
  } else {
    group = groupsCorrectAnswers.find(g => g.id === groupId);
  }
  return group;
};


export const createColumnGroupCorrectAnswers = (groupsCorrectAnswers: IGroupCorrectAnswers[], columnId: string,
                                                columnType: TABLE_COLUMN_TYPE,
                                                // if group id null use default group
                                                groupId?: string) => {
  let group: IGroupCorrectAnswers;
  if (isEmpty(groupsCorrectAnswers)) {
    group = EventQuestion.createGroupAnswers(true);
    groupsCorrectAnswers = [];
    groupsCorrectAnswers.push(group);
  }
  if (!groupId) {
    group = groupsCorrectAnswers.find(g => g.defaultGroup);
  } else {
    group = groupsCorrectAnswers.find(g => g.id === groupId);
    if (group) {
      group = EventQuestion.createGroupAnswers();
    }
    groupsCorrectAnswers.push(group);
  }
  if (!group.columnsCorrectAnswers) {
    group.columnsCorrectAnswers = {};
  }
  if (!group.columnsCorrectAnswers[columnId]) {
    if (columnType === TABLE_COLUMN_TYPE.CHECKBOX) {
      group.columnsCorrectAnswers[columnId] = [];
    } else {
      group.columnsCorrectAnswers[columnId] = {};
    }
  }
  return group.columnsCorrectAnswers[columnId];
};


export const getColumnGroupCorrectAnswers = (groupsCorrectAnswers: IGroupCorrectAnswers[], columnId: string,
                                             // if group id null use default group
                                             groupId?: string) => {
  let group: IGroupCorrectAnswers;
  if (!groupId) {
    group = groupsCorrectAnswers.find(g => g.defaultGroup);
  } else {
    group = groupsCorrectAnswers.find(g => g.id === groupId);
  }
  return group.columnsCorrectAnswers?.[columnId];
};

export const getOrCreateColumnGroupCorrectAnswers = (groupsCorrectAnswers: IGroupCorrectAnswers[], columnId: string,
                                                     columnType: TABLE_COLUMN_TYPE,
                                                     // if group id null use default group
                                                     groupId?: string) => {
  const group = getColumnGroupCorrectAnswers(groupsCorrectAnswers, columnId, groupId);
  if (!group) {
    return createColumnGroupCorrectAnswers(groupsCorrectAnswers, columnId, columnType, groupId);
  }
  return group;
};

export const getOrCreateColumnGroupCorrectAnswersOption = (groupsCorrectAnswers: IGroupCorrectAnswers[], columnId: string,
                                                           optionId: string,
                                                           columnType: TABLE_COLUMN_TYPE,
                                                           // if group id null use default group
                                                           groupId?: string) => {
  const group = getOrCreateColumnGroupCorrectAnswers(groupsCorrectAnswers, columnId, columnType, groupId);
  if (isEmpty(group[optionId])) {
    group[optionId] = {};
  }
  return group[optionId];
};

export const deleteColumnGroupCorrectAnswers = (groupsCorrectAnswers: IGroupCorrectAnswers[], columnId: string,
                                             // if group id null use default group
                                             groupId?: string) => {
  let group: IGroupCorrectAnswers;
  if (!groupId) {
    group = groupsCorrectAnswers.find(g => g.defaultGroup);
  } else {
    group = groupsCorrectAnswers.find(g => g.id === groupId) ?? EventQuestion.createGroupAnswers();
  }
  delete group.columnsCorrectAnswers?.[columnId];
};

export const mergeColumnGroupCorrectAnswers = (groupsCorrectAnswers: IGroupCorrectAnswers[],
                                               applyGroupsCorrectAnswers: IGroupCorrectAnswers[],
                                               columnId: string,
                                               columnType: TABLE_COLUMN_TYPE,
                                               // if group id null use default group
                                               groupId?: string) => {
  let group: IGroupCorrectAnswers;
  let applyGroup: IGroupCorrectAnswers;
  if (!groupId) {
    group = groupsCorrectAnswers.find(g => g.defaultGroup);
  } else {
    group = groupsCorrectAnswers.find(g => g.id === groupId);
  }
  if (!groupId) {
    applyGroup = applyGroupsCorrectAnswers.find(g => g.defaultGroup);
  } else {
    applyGroup = applyGroupsCorrectAnswers.find(g => g.id === groupId);
  }
  if (!group && !applyGroup) {
    return;
  }
  if (columnType === TABLE_COLUMN_TYPE.CHECKBOX) {
    mergeCheckboxColumnGroupCorrectAnswers(group, applyGroup, columnId);
  } else if (columnType === TABLE_COLUMN_TYPE.DROPDOWN) {
    mergeDropdownColumnGroupCorrectAnswers(group, applyGroup, columnId);
  } else if (columnType === TABLE_COLUMN_TYPE.TEXT) {
    mergeTextColumnGroupCorrectAnswers(group, applyGroup, columnId);
  }
};

const mergeCheckboxColumnGroupCorrectAnswers = (group: IGroupCorrectAnswers,
                                                applyGroup: IGroupCorrectAnswers,
                                                columnId: string) => {
  const correctAnswers = group.columnsCorrectAnswers?.[columnId] ?? [];
  const applyCorrectAnswers = applyGroup.columnsCorrectAnswers?.[columnId] ?? [];
  const crossItems = intersection(correctAnswers, applyCorrectAnswers);
  const newItems = difference(applyCorrectAnswers, correctAnswers);
  if (!group.columnsCorrectAnswers) {
    group.columnsCorrectAnswers = {};
  }
  group.columnsCorrectAnswers[columnId] = union(crossItems, newItems);
};

const mergeDropdownColumnGroupCorrectAnswers = (group: IGroupCorrectAnswers,
                                                applyGroup: IGroupCorrectAnswers,
                                                columnId: string) => {
  const correctAnswers = group.columnsCorrectAnswers?.[columnId] ?? {};
  const applyCorrectAnswers = applyGroup.columnsCorrectAnswers?.[columnId] ?? {};
  const correctAnswersIds = Object.keys(correctAnswers);
  const applyCorrectAnswersIds = Object.keys(applyCorrectAnswers);
  const crossOptionIds = intersection(correctAnswersIds, applyCorrectAnswersIds);
  const newOptionIds = difference(applyCorrectAnswersIds, correctAnswersIds);
  const deletedOptionIds = difference(correctAnswersIds, applyCorrectAnswersIds);
  deletedOptionIds.forEach((id: string) => delete correctAnswers[id]);
  for (const optionId of crossOptionIds) {
    const prev: string[] = correctAnswers[optionId];
    const news: string[] = applyCorrectAnswers[optionId];
    const crossIds = intersection(prev, news);
    const newIds = difference(news, prev);
    correctAnswers[optionId] = union(crossIds, newIds);
  }
  newOptionIds.forEach((id: string) => correctAnswers[id] = applyCorrectAnswers[id]);
  if (!group.columnsCorrectAnswers) {
    group.columnsCorrectAnswers = {};
  }
  group.columnsCorrectAnswers[columnId] = cloneDeep(correctAnswers);
};

const mergeTextColumnGroupCorrectAnswers = (group: IGroupCorrectAnswers,
                                                applyGroup: IGroupCorrectAnswers,
                                                columnId: string) => {
  const correctAnswers = group.columnsCorrectAnswers?.[columnId] ?? {};
  const applyCorrectAnswers = applyGroup.columnsCorrectAnswers?.[columnId] ?? {};
  const correctAnswersIds = Object.keys(correctAnswers);
  const applyCorrectAnswersIds = Object.keys(applyCorrectAnswers);
  const crossOptionIds = intersection(correctAnswersIds, applyCorrectAnswersIds);
  const newOptionIds = difference(applyCorrectAnswersIds, correctAnswersIds);
  const deletedOptionIds = difference(correctAnswersIds, applyCorrectAnswersIds);
  deletedOptionIds.forEach((id: string) => delete correctAnswers[id]);
  for (const optionId of crossOptionIds) {
    const prev: string[] = correctAnswers[optionId].rows ?? [];
    const news: string[] = applyCorrectAnswers[optionId].rows ?? [];
    const crossIds = intersection(prev, news);
    const newIds = difference(news, prev);
    correctAnswers[optionId].rows = union(crossIds, newIds);
    correctAnswers[optionId].option = applyCorrectAnswers[optionId].option;
  }
  newOptionIds.forEach((id: string) => correctAnswers[id] = applyCorrectAnswers[id]);
  if (!group.columnsCorrectAnswers) {
    group.columnsCorrectAnswers = {};
  }
  group.columnsCorrectAnswers[columnId] = cloneDeep(correctAnswers);
};
