import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SectionTimeline } from '../../../model/content/SectionTimeline';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '../../../core/utils.service';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { TimeLineService } from '../../../services/time-line.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { cloneDeep, isEmpty, union } from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

// Emulator
import { TimeLineEmulator } from '../../../time-line-emulator/time-line-emulator';
import { SectionContent } from '../../../model/content/SectionContent';
import { FilteredSectionTimeline } from '../../timeline-section-reference-dialog/time-line-section-reference-component/time-line-section-reference.component';
import { FilteredUser } from '../../../model/AppUser';

interface ISectionNode {
  expandable: boolean;
  level: number;
  section: FilteredSectionTimeline;
}

@Component({
  selector: 'app-export-print-select-section-dialog',
  templateUrl: './export-print-select-section-dialog.component.html',
  styleUrls: ['./export-print-select-section-dialog.component.scss']
})
export class ExportPrintSelectSectionDialogComponent implements OnInit {

  // Emulator
  timeLineEmulator: TimeLineEmulator;

  searchForm: FormGroup;

  isSuperUser: boolean;
  sections: FilteredSectionTimeline[] = [];
  allSectionList: FilteredSectionTimeline[] = [];
  allFilteredSection: FilteredSectionTimeline[] = [];
  selected: SectionTimeline[] = [];
  speakersList: FilteredUser[] = [];

  treeControl = new FlatTreeControl<ISectionNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    (sectionNode: FilteredSectionTimeline, level: number) => {
      const existsectionNode = this.existNodeMap.get(sectionNode.id);
      let sn = existsectionNode ?? {} as ISectionNode;
      const expandable = !isEmpty(sectionNode.items);
      if (sn.expandable !== expandable) {
        sn = {} as ISectionNode;
      }
      sn.expandable = expandable;
      sn.level = level;
      sn.section = sectionNode;
      this.existNodeMap.set((sn as ISectionNode).section.id, sn);
      return sn;
    },
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  existNodeMap = new Map<string, ISectionNode>();
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  mainFilter = (s: SectionTimeline) => !s.isRoot && s.parentId === this.timelineService.rootSection.id;
  hasChild = (_: number, node: any) => node.expandable;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExportPrintSelectSectionDialogComponent>,
    public utils: UtilsService,
    public timelineService: TimeLineService,
    protected formBuilder: FormBuilder
  ) {
    dialogRef.addPanelClass('timeline');
    dialogRef.updateSize('600px');
    dialogRef.disableClose = true;
    this.isSuperUser = data.isSuperUser;
    this.sections = data.sections ? data.sections : [];
    this.selected = data.selected ? data.selected : [];


    // filter start
    this.searchForm = formBuilder.group({
      filter: [''],
      users: [[]]
    });

    this.searchForm.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((search) => {
        this.allSectionList.forEach(s => s.filter = []);
        this.allFilteredSection = this.allSectionList.filter(s => this.mainFilter(s));

        // text filter
        if (search.filter.length > 0) {
          const listIncludesSearchString = this.allSectionList.filter(s => (s.title || '')
            .toLowerCase().includes(search.filter.toLowerCase()));
          let uniqueIds = listIncludesSearchString.map(s => s.id);
          for (const s of listIncludesSearchString) {
            const parentsIds = this.timeLineEmulator.getSectionHierarchicalParents(s);
            const childrenIds = this.timeLineEmulator.childTreeInLineList(s.id).map(p => p.id);
            uniqueIds = union(uniqueIds, parentsIds, childrenIds);
          }
          this.allFilteredSection.forEach(s => s.filter = uniqueIds);
          this.allFilteredSection = this.allFilteredSection
            .filter(s => uniqueIds.includes(s.id))
            .filter(s => this.mainFilter(s));
        }

        // user filter
        if (search.users.length > 0) {
          const mappedUsersId: string[] = search.users.map((user: FilteredUser) => user.userId);
          const listIncludesSearchString = this.allSectionList.filter(s => s.users?.find((user) => mappedUsersId.includes(user.userId)));
          let uniqueIds = listIncludesSearchString.map(s => s.id);
          for (const s of listIncludesSearchString) {
            const parentsIds = this.timeLineEmulator.getSectionHierarchicalParents(s);
            const childrenIds = this.timeLineEmulator.childTreeInLineList(s.id).map(p => p.id);
            uniqueIds = union(uniqueIds, parentsIds, childrenIds);
          }
          this.allFilteredSection.forEach(s => s.filter = uniqueIds);
          this.allFilteredSection = this.allFilteredSection
            .filter(s => uniqueIds.includes(s.id))
            .filter(s => this.mainFilter(s));
        }

        this.dataSource.data = this.allFilteredSection;
      });

  }

  ngOnInit(): void {
    this.handleCreateList();
    this.handleSpeakerList();
  }

  handleSpeakerList(): void {
    this.speakersList = Object.keys(this.timelineService.event.speakers ?? {})
      .map(id => new FilteredUser({ userId: id, ...this.timelineService.event.speakers[id] }));
  }

  handleCreateList(): void {
    // To solve the emulator timeline ( need to list all sections in a unique list )
    const allSections: SectionContent[] = [];
    this.sections.forEach((section) => {
      allSections.push(section);
      const pushChild = (sectionChild: any[]) => {
        for (const child of sectionChild) {
          allSections.push(child);
          if (child.items) {
            pushChild(child.items);
          }
        }
      };
      if (section.items) {
        pushChild(section.items);
      }
    });

    // Emulator with all sections list
    this.timeLineEmulator = new TimeLineEmulator(this.timelineService, null,
      null, this.timelineService.utils, this.timelineService.currentUser, true, null, false);
    this.timeLineEmulator.refreshEmulatorTimeline(this.timelineService.event,
      this.timelineService.currentUser, true, allSections.map(s => new SectionContent(cloneDeep(s))));

    // Structure data
    this.allSectionList = this.timeLineEmulator.planeListContentSortedWithoutFixed()
      .map(p => new FilteredSectionTimeline(new Object({ ...p.sectionContent, items: [] }) as SectionTimeline));
    for (const fs of this.allSectionList) {
      if (!fs.isRoot) {
        const parent = this.allSectionList.find(s => s.id === fs.parentId);
        parent?.pushItem(fs);
      }
    }

    // Datasource
    this.allFilteredSection = this.allSectionList;
    this.dataSource.data = this.allSectionList.filter(s => this.mainFilter(s));
  }

  handleChangeCheck(section: SectionTimeline, isChecked: boolean) {
    const selectParentsByHierarchical = (s: SectionTimeline) => {
      let parent = this.allSectionList.find(it => it.id === s.parentId);
      do {
        const node = this.existNodeMap.get(parent?.id)?.section;
        if (!node) {
          break;
        } else if (node && !this.handleIsChecked(node)) {
          this.selected.push(node);
        }
        parent = this.allSectionList.find(it => it.id === parent.parentId);
      } while (parent && !parent.isRoot);
    };

    if (isChecked) {
      if (!this.handleIsChecked(section)) {
        this.selected.push(section);
      }
      if (section.items.length) {
        for (const currentSection of section.items) {
          if (!this.handleIsChecked(currentSection)) {
            this.handleChangeCheck(currentSection, isChecked);
          }
        }
      }

      if (section.parentId) {
        selectParentsByHierarchical(section);
      }
    }

    if (!isChecked) {
      this.selected = this.selected.filter((currentSection) => currentSection.id !== section.id);
      if (section.items.length) {
        for (const currentSection of section.items) {
          this.handleChangeCheck(currentSection, isChecked);
        }
      }
    }
  }

  handleIsChecked(currentSection: SectionTimeline): boolean {
    return this.selected.some((section) => section.id === currentSection.id);
  }

  handleCheckSpeaker(currentUser: FilteredUser): void {
    let userFilter = this.searchForm.controls.users.value as FilteredUser[];
    if (userFilter.some((user) => currentUser.userId === user.userId)) {
      userFilter = userFilter.filter((user) => currentUser.userId !== user.userId);
      this.searchForm.controls.users.setValue(userFilter);
      return;
    }
    userFilter.push(currentUser);
    this.searchForm.controls.users.setValue(userFilter);
  }

  handleIsCheckSpeaker(currentUser: FilteredUser): boolean {
    const userFilter = this.searchForm.controls.users.value as FilteredUser[];
    return userFilter.some((user) => user.userId === currentUser.userId);
  }

}
