import {AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {MatDialog} from '@angular/material/dialog';
import {filter, firstValueFrom, interval, pairwise, startWith, Subscription, take} from 'rxjs';
import {SketchfabDocumentEditorDialogComponent} from '../sketchfab-document-editor-dialog/sketchfab-document-editor-dialog.component';
import {SketchfabService} from '../sketchfab.service';
import {InternalContentExchangeService, REQUEST_TYPE} from '../../../service/internal-content-exchange.service';
import {ANNOTATION_TYPE} from '../../../../../core/constants';
import {ICameraPosition, SKETCH_STATUS, TSketchfab} from '../sketchfab-model/sketchfab-model';
import {isEmpty} from 'lodash';
import {UtilsService} from '../../../../../core/utils.service';

@Component({
  selector: 'app-sketchfab-document',
  templateUrl: './sketchfab-document.component.html',
  styleUrls: ['./sketchfab-document.component.scss']
})
export class SketchfabDocumentComponent extends AbstractContainerComponent implements OnInit, AfterViewInit {

  @ViewChild('sketchFrame') sketchFrame: ElementRef;

  protected sketchService = new SketchfabService();
  protected followPosition: ICameraPosition;
  private requestGetAnnotationSubscription: Subscription;
  private currentAnimationId: string;

  constructor(protected injector: Injector,
              private dialog: MatDialog,
              private internalExchangeService: InternalContentExchangeService) {
    super(injector);
  }

  ngOnInit(): void {
    this.data$.pipe(startWith(null), pairwise(), this.takeUntilAlive())
      .subscribe(() => {
        this.loadSketch();
      });
    this.sketchService.sketchStatus$.pipe(
      filter(status => status === SKETCH_STATUS.READY),
      take(1),
      this.takeUntilAlive())
      .subscribe(() => {
        this.slidesList.find(sl => sl.id === this.documentPathParams.containerId).loaded$.next(true);
        if (this.followPosition) {
          this.sketchService.setCameraPosition(this.followPosition);
        }
      });
    this.sketchService.cameraPosition$.pipe(
      pairwise(),
      this.takeUntilAlive())
      .subscribe(([prevPosition, nextPosition]) => {
        if (JSON.stringify(prevPosition) !== JSON.stringify(nextPosition)) {
          this.outputFollowMeData.emit(nextPosition);
        }
      });
    interval(3000).pipe(this.takeUntilAlive()).subscribe(() => {
      if (this.sketchService.sketchStatus$.getValue() === SKETCH_STATUS.READY && this.followMeEnable$.getValue()) {
        this.sketchService.getCameraPosition();
      }
    });
    this.internalExchangeService.request$.pipe(filter(r => r?.receiverId === this.documentPathParams.containerId))
      .pipe(this.takeUntilAlive())
      .subscribe(request => {
        switch (request.requestType) {
          case REQUEST_TYPE.GET_ANNOTATION_LIST:
            if (!this.requestGetAnnotationSubscription) {
              this.requestGetAnnotationSubscription = this.sketchService.annotationList$
                .pipe(filter(v => !!v), this.takeUntilAlive())
                .subscribe(annotationList => {
                  this.internalExchangeService.response$.next({
                    senderId: this.documentPathParams.containerId,
                    value: annotationList
                  });
                });
            } else {
              this.internalExchangeService.response$.next({
                senderId: this.documentPathParams.containerId,
                value: this.sketchService.annotationList$.getValue()
              });
            }
            break;
          case REQUEST_TYPE.GOTO_ANNOTATION:
            if (request.task && UtilsService.isDigitId(request.task.annotationId)) {
              this.sketchService.gotoAnnotation({annotation_type: ANNOTATION_TYPE.TEXT, annotation_index: request.task.annotationId});
            } else if (request.task && UtilsService.isStringId(request.task.annotationId)) {
              this.sketchService.gotoAnnotation({annotation_type: ANNOTATION_TYPE.VIDEO, annotation_index: request.task.annotationId},
                {elementId: request.task.elementId, ...request.task.params, continueAnimation: this.currentAnimationId === request.task.elementId});
              this.currentAnimationId = request.task.elementId;
            }
            break;
          case REQUEST_TYPE.STOP_ANIMATION:
            if (request.task && UtilsService.isStringId(request.task.annotationId)) {
              this.sketchService.stopAnimation({annotation_type: ANNOTATION_TYPE.VIDEO, annotation_index: request.task.annotationId});
            }
            break;
        }
      });
  }

  onDestroy() {
    super.onDestroy();
    this.sketchService.destroy();
  }

  ngAfterViewInit() {
    this.loadSketch();
  }

  protected loadSketch() {
    if (!isEmpty(this.data) && this.sketchFrame) {
      this.sketchService.initSketch(this.sketchFrame.nativeElement, new TSketchfab(this.data), this.editorMode);
    }
  }

  protected inputFollowMeData(value) {
    this.followPosition = value;
    this.sketchService.setCameraPosition(value);
  }

  onEdit(): Promise<boolean> {
    const dialogRef = this.dialog.open(SketchfabDocumentEditorDialogComponent, {
      data: this.data
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = (new TSketchfab(result)).toObject();
        }
        return !!result;
      });
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  afterDeleteContainer() {
    this.internalExchangeService.request$.next({
      receiverId: null,
      requestType: REQUEST_TYPE.DELETE_CONTAINER,
      task: {containerId: this.documentPathParams.containerId}
    });
  }
}
