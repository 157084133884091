import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import * as ui from '../../actions/ui';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {REPORT_BUG_TYPE} from '../../core/constants';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {StdComponent} from '../../core/std-component';

@Component({
  selector: 'app-get-support-dialog',
  templateUrl: './get-support-dialog.component.html',
  styleUrls: ['./get-support-dialog.component.scss']
})
export class GetSupportDialogComponent extends StdComponent implements OnInit, OnDestroy {
  readonly REPORT_BUG_TYPE = REPORT_BUG_TYPE;

  reportBugType: REPORT_BUG_TYPE;
  reportText: string;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<GetSupportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: Store<fromRoot.State>) {
    super(injector);
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
  }

  ngOnDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
