import {Component, Injector, OnInit} from '@angular/core';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {difference, isEmpty, merge} from 'lodash';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {AnswerEquality, EventQuestion, TableRowAnswerEquality} from '../../../../../../../model/EventQuestion';
import {ILanguageParams} from '../../../../../../../core/constants';
import {MatTableDataSource} from '@angular/material/table';
import {UtilsService} from '../../../../../../../core/utils.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-question-text-editor',
  templateUrl: './question-text-editor.component.html',
  styleUrls: ['./question-text-editor.component.scss']
})
export class QuestionTextEditorComponent extends AbstractQuizQuestionAnswersEditorComponent implements OnInit {

  textData: TableRowAnswerEquality[] = [];
  textDataSource = new MatTableDataSource<TableRowAnswerEquality>([]);

  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };


  constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.displayedColumns = ['answerValue'];
    const defGroup = this.question.groupsCorrectAnswers.find(q => q.defaultGroup);
    if (defGroup) {
      defGroup.correctEquality.forEach(item => this.textData.push(
        UtilsService.wrapObjectToProxy(new TableRowAnswerEquality(item, this.languageParams), this.dataChangesHandler)));
    }
    this.textData = UtilsService.wrapObjectToProxy(this.textData, this.dataChangesHandler);
    this.textDataSource.data = this.textData;
  }

  ngOnInit(): void {
    this.dataDetectChanges.pipe(this.takeUntilAlive())
      .subscribe(() => {
        const rowIds = this.textDataSource.data.map(r => r.id);
        const defGroup = this.question.groupsCorrectAnswers.find(q => q.defaultGroup);
        const ceIds = defGroup.correctEquality.map(ce => ce.id);
        const addedIds = difference(rowIds, ceIds);
        const deletedIds = difference(ceIds, rowIds);
        addedIds.forEach(addId => {
          const row = this.textDataSource.data.find(r => r.id === addId);
          if (row) {
            defGroup.correctEquality.push(
              UtilsService.wrapObjectToProxy(new AnswerEquality({id: row.id, answerValue: row.answerValue}), this.dataChangesHandler));
          }
        });
        deletedIds.forEach(deleteId => {
          const index = defGroup.correctEquality.findIndex(r => r.id === deleteId);
          if (index > -1) {
            defGroup.correctEquality.splice(index, 1);
          }
        });
        this.textDataSource.data.filter(r => ![...addedIds, ...deletedIds].includes(r.id))
          .forEach(row => {
            const ce = defGroup.correctEquality.find(r => r.id === row.id);
            if (ce) {
              merge(ce, {id: row.id, answerValue: row.answerValue});
            }
          });
      });
  }

  addAnswer() {
    const row = new TableRowAnswerEquality(new AnswerEquality(), this.languageParams);
    this.textData.push(UtilsService.wrapObjectToProxy(row, this.dataChangesHandler));
    this.textDataSource.data = this.textData;
    this.addedId = row.id;
    this.dsSelectedRowId = row.id;
    this.setRowFocus(this.addedId);
  }

  deleteAnswer(rowId) {
    const index = this.textData.findIndex(item => item.id === rowId);
    this.textData.splice(index, 1);
    this.textDataSource.data = this.textData;
    this.dsSelectedRowId = null;
  }

  translateAnswer(rowId) {
    const row = this.textData.find(item => item.id === rowId);
    const text = row.rowCaption;
    this.translating[rowId] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => row.rowCaption = v)
      .finally(() => delete this.translating[rowId]);
  }

  validate(): IValidated {
    if (this.question.useCorrectAnswers) {
      if (isEmpty(this.textDataSource.data)) {
        return {validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required')};
      }
      if (this.textDataSource.data.some(o => isEmptyCaption(o.answerValue))) {
        return {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
      }
    }
    return {validated: true};
  }
}
