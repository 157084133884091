import {Injectable} from '@angular/core';
import {AbstractContent} from '../model/content/AbstractContent';
import {LoginService} from '../login/login.service';
import {SectionContent} from '../model/content/SectionContent';
import {UtilsService} from './utils.service';
import {Constants} from './constants';

export interface IUserId {
  userId: string;
}

export const DRM_MODE_DISABLED = 'disabled';

export enum DRM_MODE {
  /**
   * no protection
   */
  DISABLED = DRM_MODE_DISABLED,
  /**
   * the original slide can not be edited, but copies of it can be edited
   */
  NOT_EDITABLE = 'not.editable',
  /**
   * can be copied normally, but edit is only available to the original author, this implies also edits in copies
   */
  NOT_EDITABLE_COPY = 'not.editable.copy',
  /**
   * No copies allowed
   */
  NOT_COPING = 'not.coping',
  /**
   * CONFIDENTIAL watermark will be added above the slide, to make copy/paste of text harder
   */
  CONFIDENTIAL = 'confidential'
}

export const DRM_MODE_PRIORITY: DRM_MODE[] = [
  DRM_MODE.DISABLED,
  DRM_MODE.NOT_EDITABLE,
  DRM_MODE.NOT_EDITABLE_COPY,
  DRM_MODE.NOT_COPING,
  DRM_MODE.CONFIDENTIAL
];

export interface IDRMRights {
  canEdit?: boolean;
  canCopy?: boolean;
  canDelete?: boolean;
  canCut?: boolean;
}

export enum DRM_MY_KNOWLEDGE_MODE {
  /**
   * no protection
   */
  DISABLED = DRM_MODE_DISABLED,
  /**
   * Can be added to my knowledge but not edited
   */
  NOT_EDITABLE = 'not.editable',
  /**
   * No copies allowed. Cannot be added to my knowledge.
   */
  NOT_COPING = 'not.coping',
}

export enum DRM_PREFIX {
  TIMELINE = 'drm.mode.',
  TIMELINE_TOOLTIP = 'drm.mode.tooltip.',
  MY_KNOWLEDGE =  'drm.mode.my.knowledge.',
  MY_KNOWLEDGE_TOOLTIP =  'drm.mode.my.knowledge.tooltip.'
}

export type TDRMMode = DRM_MODE | DRM_MY_KNOWLEDGE_MODE;

/**
 * Digital Rights Management Service
 */
@Injectable({
  providedIn: 'root'
})
export class DRMService {

  constructor(private loginService: LoginService,
              private utils: UtilsService) {}

  private isSuperUser() {
    return this.loginService.isAdmin() || this.loginService.isSuperadmin();
  }

  /***** TIMELINE DRM *****/

  private userHasEditRights(userId: String, content: AbstractContent) {
    if (this.isSuperUser() || !content?.drmMode || this.loginService.educationMode) {
      return true;
    }
    switch (content.drmMode) {
      case DRM_MODE.DISABLED:
        return true;
      case DRM_MODE.NOT_EDITABLE:
      case DRM_MODE.NOT_EDITABLE_COPY:
      case DRM_MODE.NOT_COPING:
      case DRM_MODE.CONFIDENTIAL:
        return content.creator?.userId === userId;
      default:
        return false;
    }
  }

  private userHasCopyRights(userId: String, content: AbstractContent) {
    if (this.isSuperUser() || !content?.drmMode || this.loginService.educationMode) {
      return true;
    }
    switch (content.drmMode) {
      case DRM_MODE.DISABLED:
      case DRM_MODE.NOT_EDITABLE:
      case DRM_MODE.NOT_EDITABLE_COPY:
        return true;
      case DRM_MODE.CONFIDENTIAL:
      case DRM_MODE.NOT_COPING:
        return content.creator?.userId === userId;
      default:
        return false;
    }
  }

  private userHasDeleteRights(userId: String, content: AbstractContent) {
    if (this.isSuperUser() || !content?.drmMode || this.loginService.educationMode) {
      return true;
    }
    switch (content.drmMode) {
      case DRM_MODE.DISABLED:
        return true;
      case DRM_MODE.NOT_EDITABLE:
      case DRM_MODE.NOT_EDITABLE_COPY:
      case DRM_MODE.CONFIDENTIAL:
      case DRM_MODE.NOT_COPING:
        return content.creator?.userId === userId;
      default:
        return false;
    }
  }

  private userHasCutRights(userId: String, content: AbstractContent) {
      return true;
  }

  checkDRMRights(content: AbstractContent): IDRMRights {
    const userId = this.loginService.appUser?.userId;
    return {
      canEdit: this.userHasEditRights(userId, content),
      canCopy: this.userHasCopyRights(userId, content),
      canDelete: this.userHasDeleteRights(userId, content),
      canCut: this.userHasCutRights(userId, content)
    };
  }

  hasDRMConfidentialRestriction(content: AbstractContent) {
    return content.drmMode === DRM_MODE.CONFIDENTIAL && content.creator?.userId !== this.loginService.appUser?.userId;
  }

  drmListFilter(list: AbstractContent[], user?: IUserId): AbstractContent[] {
    const userId = user?.userId ?? this.loginService.appUser.userId;
    if (list.every(it => it.isTypeSection)) {
      return this.drmListFilterSectionsList(list as SectionContent[], userId);
    } else {
      return this.drmListFilterContentsList(list, userId);
    }
  }

  private drmListFilterSectionsList(list: SectionContent[], userId: string) {

    const exclude = (parentId: string, childrenIds: string[]) => {
      childrenIds.push(parentId);
      list.filter(it => it.parentId === parentId).forEach(it => {
        childrenIds.push(it.id);
        exclude(it.id, childrenIds);
      });
    };

    const sList = list.sort(this.utils.comparator(Constants.ORDERINDEX));
    const excludedIds: string[] = [];
    for (const s of sList) {
      if (!this.userHasCopyRights(userId, s)) {
        exclude(s.id, excludedIds);
      }
    }
    return sList.filter(s => !excludedIds.includes(s.id));
  }

  private drmListFilterContentsList(list: AbstractContent[], userId: string) {
    return list.filter(it => this.userHasCopyRights(userId, it));
  }

  userHasRightsChangeDRMMode(content: AbstractContent) {
    return (!content.id || this.isSuperUser() || content.creator?.userId === this.loginService.appUser?.userId);
  }

  /***** MY KNOWLEDGE DRM *****/

  private userHasMyKnowledgeEditRights(userId: String, content: AbstractContent) {
    if (!content?.drmMyKnowledgeMode) {
      return true;
    }
    switch (content.drmMyKnowledgeMode) {
      case DRM_MY_KNOWLEDGE_MODE.DISABLED:
        return true;
      case DRM_MY_KNOWLEDGE_MODE.NOT_EDITABLE:
      case DRM_MY_KNOWLEDGE_MODE.NOT_COPING:
        return content.creator?.userId === userId;
      default:
        return false;
    }
  }

  private userHasMyKnowledgeCopyRights(userId: String, content: AbstractContent) {
    if (!content?.drmMyKnowledgeMode) {
      return true;
    }
    switch (content.drmMyKnowledgeMode) {
      case DRM_MY_KNOWLEDGE_MODE.DISABLED:
      case DRM_MY_KNOWLEDGE_MODE.NOT_EDITABLE:
        return true;
      case DRM_MY_KNOWLEDGE_MODE.NOT_COPING:
        return content.creator?.userId === userId;
      default:
        return false;
    }
  }

  checkMyKnowledgeDRMRights(content: AbstractContent): Pick<IDRMRights, 'canEdit' | 'canCopy'>  {
    const userId = this.loginService.appUser?.userId;
    return {
      canEdit: this.userHasMyKnowledgeEditRights(userId, content),
      canCopy: this.userHasMyKnowledgeCopyRights(userId, content),
    };
  }

  userHasRightsChangeMyKnowledgeDRMMode(content: AbstractContent) {
    return content.creator?.userId === this.loginService.appUser?.userId;
  }
}
