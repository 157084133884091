import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {DailyRoomInfo} from '@daily-co/daily-js';
import {DailyApiService} from './daily-api.service';
import {RoomRequest} from '../model/daily/RoomRequest';

@Injectable({
  providedIn: 'root'
})
export class DailyDataService {

  constructor(private dailyApiService: DailyApiService) { }

  getToken(eventId: string, roomName: string): Observable<string> {
    return this.dailyApiService.getToken(eventId, roomName);
  }

  getRoom(roomName: string): Observable<DailyRoomInfo> {
    return this.dailyApiService.getRoom(roomName);
  }

  createRoom(roomRequest: RoomRequest): Observable<DailyRoomInfo> {
    return this.dailyApiService.createRoom(roomRequest);
  }

  updateRoom(eventId: string, roomName: string): Observable<DailyRoomInfo> {
    return this.dailyApiService.updateRoom(eventId, roomName);
  }

  deleteRoom(roomName: string): Observable<DailyRoomInfo> {
    return this.dailyApiService.deleteRoom(roomName);
  }

  checkPresence(eventId: string, sectionId: string) {
    return this.dailyApiService.checkPresence(eventId, sectionId);
  }

  watchRoom(eventId: string, sectionId: string, roomId: string) {
    return this.dailyApiService.watchRoom(eventId, sectionId, roomId);
  }
}
