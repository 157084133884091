import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, of, Subject, Subscription, take, takeUntil} from 'rxjs';
import {ExtEvent} from '../model/ExtEvent';
import {AppUser} from '../model/AppUser';
import {Event} from '../model/Event';
import {difference, isEmpty, union} from 'lodash';
import {EventsDataService} from './events-data.service';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {Constants, EVENT_DOP_STATE} from '../core/constants';
import {UsersDataService} from './users-data.service';
import {TimerService} from '../core/timer.service';
import {CommonService} from '../core/common.service';
import {EventManager} from '../model/EventManager';
import {TimeLineService} from './time-line.service';


@Injectable({
  providedIn: 'root'
})
export class EventStartReminderService {

  private _unsubscribe: Subject<any> = new Subject();
  private currentUser: AppUser;
  private _runningEventsSubscription: Subscription;
  private _userEventsSubscription: Subscription;
  private _currentEventsSubscription: Subscription;
  private _timerSubscription: Subscription;
  private _notifyEventMessageSubscription: Subscription;
  private userAttendedEvents = [];
  private userAttendedEventsId = [];
  private eventPresenters = {};
  private eventPresentersAndConcierges = {};
  private runningEvents: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);
  private currentEvent: Event;


  constructor(private eventsDataService: EventsDataService
            , private store: Store<fromRoot.State>
            , private common: CommonService
            , private userDataService: UsersDataService
            , private timeLineService: TimeLineService
            , private timerService: TimerService) {
  }

  public destroy() {
    if (this._runningEventsSubscription) {
      this._runningEventsSubscription.unsubscribe();
      this._runningEventsSubscription = undefined;
    }
    if (this._userEventsSubscription) {
      this._userEventsSubscription.unsubscribe();
      this._userEventsSubscription = undefined;
    }
    if (this._currentEventsSubscription) {
      this._currentEventsSubscription.unsubscribe();
      this._currentEventsSubscription = undefined;
    }
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }
    if (this._notifyEventMessageSubscription) {
      this._notifyEventMessageSubscription.unsubscribe();
      this._notifyEventMessageSubscription = undefined;
    }
    this.userAttendedEvents = [];
    this._unsubscribe.next(true);
    this._unsubscribe.complete();
    this._unsubscribe = new Subject();
  }

  private unsubscribeAll() {
    if (this._runningEventsSubscription) {
      this._runningEventsSubscription.unsubscribe();
    }
    if (this._userEventsSubscription) {
      this._userEventsSubscription.unsubscribe();
    }
    if (this._currentEventsSubscription) {
      this._currentEventsSubscription.unsubscribe();
    }
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    if (this._notifyEventMessageSubscription) {
      this._notifyEventMessageSubscription.unsubscribe();
    }
  }

  startEventReminder(currentUser: AppUser) {
    if ((!this.currentUser && currentUser) || (currentUser && this.currentUser.userId !== currentUser.userId)) {
      this.currentUser = currentUser;
      this.unsubscribeAll();
      this._currentEventsSubscription = this.timeLineService.event$
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(event => {
          this.currentEvent = new Event(event);
        });
      this.getRunningEvents();
      this._runningEventsSubscription = this.runningEvents
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(events => {
          if (events && this.currentUser) {
            this.createCheckStartTimeUserEventsList(events);
          }
        });
      this._timerSubscription = this.timerService.getTimer().pipe(takeUntil(this._unsubscribe))
        .subscribe(() => {
          this.checkEventsStartTimeNotification();
        });
      this._notifyEventMessageSubscription = this.store.select(fromRoot.getNotifyEventMessage)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(object => {
          if (object && object.closeId) {
            const obj = this.userAttendedEvents.find(o => o.eventId === object.closeId);
            if (obj) {
              obj.closed = true;
            }
          }
        });
    } else {
      this.destroy();
    }
  }

/*
  get isEventReminderRunning() {
    return this._userSubscription && !this._userSubscription.closed &&
      this._userEventsSubscription && !this._userEventsSubscription.closed &&
      this._runningEventsSubscription && !this._runningEventsSubscription.closed &&
      this._currentEventsSubscription && !this._currentEventsSubscription.closed &&
      this._timerSubscription && !this._timerSubscription.closed &&
      this._notifyEventMessageSubscription && !this._notifyEventMessageSubscription.closed;
  }
*/

  private getUsersList(val) {
    const ul = {};
    if (!val) {
      return;
    }
    for (let i = 0; i < val.length; i++) {
      const u = new EventManager(val[i]);
      ul[u.userId] = u;
    }
    return ul;
  }

  private get isSuperUser() {
    return this.currentUser.isAdmin || this.currentUser.isModerator;
  }

  private get isTrainer() {
    return this.currentUser.isTrainer;
  }

  private get isSimpleRegistration() {
    return this.currentUser.isSimpleRegistration;
  }

  private getIdArray(obj): any[] {
    return obj ? Object.keys(obj) : [];
  }

  private getRunningEvents() {
    const getUserEvents = (_events, eventIdList, vPublicOrAdminEventList, vPermittedUserEvents) => {
      const vm = this;
      Promise.resolve()
        .then(function () {
        const allPromise = [];
        for (const key of eventIdList) {
          if (vm.isSuperUser) {
            const eventObj = new Event(vPublicOrAdminEventList[key]);
            const presenterList = vm.preparePresenters(_events[key]);
            vm.getPresenterAngConcierges(presenterList);
            allPromise.push(vm.checkEvent(eventObj, vPermittedUserEvents));
          } else {
            allPromise.push(Promise.resolve(_events[key]).then(e => {
              if (e instanceof Event) {
                return e;
              } else {
                return vm.eventsDataService.getEventPromise(key);
              }
            }).then(function (object) {
              const presenterList = vm.preparePresenters(object);
              vm.getPresenterAngConcierges(presenterList);
              if (object && object['eventId'] && (object['dopState'] !== EVENT_DOP_STATE.PUBLIC_BY_LINK ||
                (object['dopState'] === EVENT_DOP_STATE.PUBLIC_BY_LINK &&
                  vm.getIdArray(vPermittedUserEvents).includes(object['eventId'].toString())))) {
                const eventObj = new Event(object);
                return vm.checkEvent(eventObj, vPermittedUserEvents);
              }
            }));
          }
        }
        Promise.all(allPromise).then(value => {
          const filterValue = value.filter(function (el: Event) {
            if (el) {
              return el;
            }
          });
          const ctime = new Date().getTime();
          events = filterValue.sort(function (o1: Event, o2: Event) {
            if (!o1.isPublic && o2.isPublic) {
              return -1;
            } else if (o1.isPublic && !o2.isPublic) {
              return 1;
            } else
            if (o1.sortCondition(ctime) === o2.sortCondition(ctime)) {
              return o1.name.localeCompare(o2.name);
            } else {
              return o1.sortCondition(ctime) < o2.sortCondition(ctime) ? -1 : 1;
            }
          }).slice();
          // vm.notifyOpenedEvents();
          vm.runningEvents.next(events);
        });
      });
    };
    const isGuest = this.currentUser.guest;
    let events: Array<Event> = [];
    this._userEventsSubscription = combineLatest(
      this.isSuperUser ?
        [
          this.eventsDataService.loadEventList(true).pipe(takeUntil(this._unsubscribe)),
          this.eventsDataService.loadPermittedUserEvents(this.currentUser.userId).pipe(takeUntil(this._unsubscribe)),
          this.eventsDataService.loadInvitedUserEvents(this.currentUser.email).pipe(takeUntil(this._unsubscribe)),
          of(null)
        ] :
        ((this.isTrainer || this.isSimpleRegistration) ?
            [
              this.eventsDataService.getPublicEventsObject(true).pipe(takeUntil(this._unsubscribe)),
              this.eventsDataService.loadPermittedUserEvents(this.currentUser.userId).pipe(takeUntil(this._unsubscribe)),
              this.eventsDataService.loadInvitedUserEvents(this.currentUser.email).pipe(takeUntil(this._unsubscribe)),
              this.eventsDataService.loadUserOwnerEvents(this.currentUser.userId).pipe(takeUntil(this._unsubscribe))
            ]
            :
            [
              this.eventsDataService.getPublicEventsObject(true, isGuest).pipe(takeUntil(this._unsubscribe)),
              this.eventsDataService.loadPermittedUserEvents(this.currentUser.userId).pipe(takeUntil(this._unsubscribe)),
              this.eventsDataService.loadInvitedUserEvents(this.currentUser.email).pipe(takeUntil(this._unsubscribe)),
              of(null)
            ]
        )
    ).pipe(takeUntil(this._unsubscribe)
    ).subscribe(([vPublicOrAdminEventList, vPermittedUserEvents, vInvitedUserEvents, vUserOwnerEvents]) => {
      const eventIdList = union(
        this.getIdArray(vPublicOrAdminEventList),
        this.getIdArray(vPermittedUserEvents),
        this.getIdArray(vInvitedUserEvents),
        this.getIdArray(vUserOwnerEvents));
      const _events = {};
      this.addToObject(_events, vPublicOrAdminEventList);
      this.addToObject(_events, vPermittedUserEvents);
      this.addToObject(_events, vInvitedUserEvents);
      this.addToObject(_events, vUserOwnerEvents);
      this.userAttendedEventsId = union(
        this.getIdArray(vPermittedUserEvents),
        this.getIdArray(vInvitedUserEvents)).map(id => id);
      getUserEvents(_events, eventIdList, vPublicOrAdminEventList, vPermittedUserEvents);
    });
  }

  addToObject(dst: any, src: any) {
    if (isEmpty(src)) {
      return dst;
    }
    for (const key of Object.keys(src)) {
      if (isEmpty(dst[key])) {
        dst[key] = src[key];
      }
    }
  }

  preparePresenters(event) {
    const presenterList = {
      [event.eventId]: {
        presenters: null,
        concierges: null
      }
    };
    if (!isEmpty(event.presenters)) {
      presenterList[event.eventId]['presenters'] = Object.keys(event.presenters);
    }
    if (!isEmpty(event.concierges)) {
      presenterList[event.eventId]['concierges'] = Object.keys(event.concierges);
    }
    return presenterList;
  }

  /**
   * Creates a list of events a user must visit.
   * @param eventList all permitted event for user exclude deleted
   */
  private createCheckStartTimeUserEventsList(eventList: any[]) {
    const vm = this;
    const notNeedStatus = [Constants.EVENT_STATUS_FINISHED, Constants.EVENT_STATUS_WRAP_UP_UNTIL,
      Constants.EVENT_STATUS_RUNNING_EVENT_FINISH_IN, Constants.EVENT_STATUS_RUNNING];
    const events: Event[] = (eventList || []).filter(function (event) {
      const tmpEvent = new ExtEvent(vm.common.utils, event);
      const status = tmpEvent.getEventStatus()[0];
      if (notNeedStatus.includes(status) || !tmpEvent.published || !vm.userAttendedEventsId.includes(tmpEvent.eventId)) {
        return false;
      } else {
        return true;
      }
    });
    // new list without not needed eventId
    vm.userAttendedEventsId = events.map(o => o.eventId);
    const allPromise = [];
    // load section instant settings for check event registration on.
    for (let i = 0; i < events.length; i++) {
      allPromise.push(this.eventsDataService.getInstantSettingsPromise(events[i].eventId));
    }
    Promise.all(allPromise).then(function (eventsSettings) {
      const eventsWithRegistration = [];
      // select section with registration on.
      for (let i = 0; i < events.length; i++) {
        if (!isEmpty(eventsSettings[i]) && eventsSettings[i].attendeesRegistration) {
          eventsWithRegistration.push(events[i].eventId);
        }
      }
      // get all root section by event
      const allRootPromise = [];
      const allPresenterPromise = [];
      const allRegSectionPromise = [];
      const notWatchEvent = [];
      for (let i = 0; i < eventsWithRegistration.length; i++) {
        allRootPromise.push(vm.eventsDataService.getRootSectionPromise(eventsWithRegistration[i]));
        const e = events.find(it => it.eventId === eventsWithRegistration[i]);
        allPresenterPromise.push(Promise.resolve((e || {}).managers));
        allRegSectionPromise.push(
          vm.eventsDataService.getUserSectionsRegistration(eventsWithRegistration[i], vm.common.utils.emailToId(vm.currentUser.email))
            .pipe(take(1)).toPromise());
      }
      Promise.all([Promise.all(allRootPromise), Promise.all(allPresenterPromise), Promise.all(allRegSectionPromise)])
        .then(function (promiseResult) {
          const rootSections = promiseResult[0];
          const presenters = promiseResult[1];
          const registeredSection = promiseResult[2].map(list => list.map(id => id));
          for (let i = 0; i < eventsWithRegistration.length; i++) {
            // if user is event presenter than not need check registration.
            if (Object.keys(presenters[i] || {}).includes(vm.currentUser.userId)) {
              continue;
            }
            // if user not registered, not need track this event.
            if (!registeredSection[i].includes(rootSections[i].id)) {
              notWatchEvent.push(eventsWithRegistration[i]);
            }
          }
          // new list without not needed eventId
          vm.userAttendedEventsId = difference(vm.userAttendedEventsId, notWatchEvent);
          let nObj;
          vm.userAttendedEvents = events
            .map(e => new Object({eventId: e.eventId, startDate: e.startDate, name: e.name, published: e.published,
              shortLink: e.shortLink,
              notify: (nObj = vm.userAttendedEvents.find(o => o.eventId === e.eventId)) ? nObj.notify : false}))
            .filter(obj => vm.userAttendedEventsId.includes(obj['eventId']));
          vm.checkEventsStartTimeNotification();
        });
    });
  }

  checkEventsStartTimeNotification() {
    const vm = this;
    const cTime = new Date().getTime();
    for (const obj of this.userAttendedEvents.filter(o => !o.closed)) {
      if (obj.published && !obj.notify && obj.startDate.getTime() -
        Constants.EVENT_BEFORE_START_NOTIFICATION < cTime && cTime < obj.startDate.getTime()) {
        const nEvent = new ExtEvent(this.common.utils, obj);
        if (nEvent && vm.currentEvent && vm.currentEvent.eventId === nEvent.eventId) {
          continue;
        }
        if (nEvent) {
          this.getEventPresenterList(nEvent.eventId).then(function (eventPresenters) {
            vm.eventsDataService.dispatchSetNotifyEventMessage({
              event: new Event(obj),
              eventStatus: nEvent.getEventStatus()
            });
          });
        }
        obj.notify = true;
      }
    }
  }

  notifyOpenedEvents() {
    const vm = this;
    let nf = Promise.resolve();
    for (const obj of this.userAttendedEvents) {
      if (!obj.closed && obj.notify) {
        nf = nf.then(() => {
          const nEvent = new ExtEvent(this.common.utils, obj);
          return this.getEventPresenterList(nEvent.eventId).then(function (eventPresenters) {
            vm.eventsDataService.dispatchSetNotifyEventMessage({
              event: new Event(obj),
              eventStatus: nEvent.getEventStatus()
            });
          });
        });
      }
    }
    nf.catch((err) => this.common.log.error(err));
  }

  private getEventPresenterList(eventId) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      vm.eventsDataService.getSectionsSpeakersPromise(eventId).then(function (sobject) {
        const slist = sobject ? Object.values(sobject) : [];
        const speakerIdList = [];
        (slist || []).forEach(obj => !isEmpty(obj) ? Object.keys(obj).forEach(id => speakerIdList.push(id))  : null);
        const eventPresenters = union(vm.eventPresenters[eventId], speakerIdList);
        resolve(eventPresenters);
      }).catch(err => {
        throw new Error(err);
      });
    });
  }

  private getPresenterAngConcierges(object: {[eventId: string]: {presenters: [], concierges: []}}) {
    for (const eventId of Object.keys(object)) {
      this.eventPresenters[eventId] = object[eventId].presenters;
      this.eventPresentersAndConcierges[eventId] = union(object[eventId].presenters, object[eventId].concierges);
    }
  }

  private checkEvent(eventObj, userEvents) {
    if (this.eventsDataService.eventListFilterByKeyAndDeleted(eventObj)) {
      return this.eventsDataService.hasUserAccessEventV2(eventObj,
        this.currentUser,
        this.eventPresentersAndConcierges[eventObj.eventId],
        userEvents,
        false);
    } else {
      return undefined;
    }
  }

}
