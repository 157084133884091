import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RecordingTimestamp } from '../../../model/recording';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {
  settings: {
    order: 'slide' | `original`
  } = {
    order: 'slide'
  };
  autoPlaying$ = new BehaviorSubject(true);
  sectionHasRecordings$ = new BehaviorSubject(false);
  copy$ = new BehaviorSubject<{
    cut: boolean;
    recording: RecordingTimestamp;
  }>(null);
  videoRecorderOpen = signal(false) ;

  constructor() { }

}
