import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {IEmbeddedFrame, PROVIDER_SETTINGS} from '../embedded-frame-model/embedded-frame-model';
import {CommonService} from '../../../../../core/common.service';
import {EmbeddedFrameEditorDialogComponent} from '../embedded-frame-editor-dialog/embedded-frame-editor-dialog.component';

@Component({
  selector: 'app-embedded-frame',
  templateUrl: './embedded-frame.component.html',
  styleUrls: ['./embedded-frame.component.scss']
})
export class EmbeddedFrameComponent extends AbstractContainerComponent implements OnInit {

  url$ = new BehaviorSubject<any>(null);

  private resizeObserver = new ResizeObserver(entries => {
    if (this.editorMode && this.data?.provider) {
      this.url$.next(this.data?.provider ? this.common.utils.getURL(PROVIDER_SETTINGS[this.data?.provider].embeddedURL(this.data)) : null);
    }
  });

  private mutationObserver = new MutationObserver(() => {
    if (this.editorMode) {
      if (this.frame) {
        this.resizeObserver.observe(this.frame.nativeElement);
      } else {
        this.resizeObserver.disconnect();
      }
    }
  });

  @ViewChild('frame') frame: ElementRef;

  constructor(protected injector: Injector,
              protected common: CommonService,
              private dialog: MatDialog,
              private selfElement: ElementRef) {
    super(injector);
    this.mutationObserver.observe(this.selfElement.nativeElement, {childList: true});
  }

  ngOnInit(): void {
    this.data$.pipe(this.takeUntilAlive())
      .subscribe((value: IEmbeddedFrame) => {
        this.url$.next(value?.provider ? this.common.utils.getURL(PROVIDER_SETTINGS[value?.provider].embeddedURL(value)) : null);
      });
  }

  onDestroy() {
    super.onDestroy();
    this.resizeObserver.disconnect();
    this.mutationObserver.disconnect();
  }

  protected inputFollowMeData(value) {
  }

  onEdit(): Promise<boolean> {
    const dialogRef = this.dialog.open(EmbeddedFrameEditorDialogComponent, {
      width: 'auto',
      height: '280px',
      disableClose: true,
      data: this.data
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = result.data;
        }
        return !!result;
      });
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

}
