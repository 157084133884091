import {Component, OnInit} from '@angular/core';
import {ClientConfig} from '../../model/ClientConfig';
import {LoginService} from '../../login/login.service';
import {CommonService} from '../../core/common.service';
import {AppUser} from '../../model/AppUser';
import {cloneDeep} from 'lodash';
import {UsersDataService} from '../../services/users-data.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as data from '../../actions/data';
import {TermsAndPrivacyDialogComponent} from '../terms-and-privacy-dialog/terms-and-privacy-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {combineLatest, of, take} from 'rxjs';

@Component({
  selector: 'app-client-terms-and-privacy',
  templateUrl: './client-terms-and-privacy.component.html',
  styleUrls: ['./client-terms-and-privacy.component.scss']
})
export class ClientTermsAndPrivacyComponent implements OnInit {

  title = '';
  clientTermsAndPrivacy: string;
  clientName: string;
  currentUser: AppUser;
  customTerms = false;

  constructor(private common: CommonService,
              private loginService: LoginService,
              private userDataService: UsersDataService,
              private store: Store<fromRoot.State>,
              public dialog: MatDialog) {
    combineLatest([
      this.loginService.clientConfig$,
      this.store.select(fromRoot.getCurrentUser)
    ])
      .pipe(take(1))
      .subscribe(([value, user]: [ClientConfig, AppUser]) => {
        this.title = value ? value.name : this.common.utils.getEnv().appName;
        this.clientTermsAndPrivacy = value ? value.clientTermsAndPrivacy : null;
        this.clientName = value ? value.name : '';
        this.customTerms = value ? value.customTerms : false;
        this.currentUser = cloneDeep(user);
      });
  }

  ngOnInit(): void {
  }

  dontAgreeClick() {
    this.loginService.signOut(true);
  }

  onContinue() {
    combineLatest([
      this.currentUser.acceptClientTermsAndPrivacy ? this.userDataService.setAcceptClientTermsAndPrivacy(true) : of(true),
      this.customTerms && this.currentUser.acceptCustomTerms ? this.userDataService.acceptCustomTerms(true) : of(true)
    ])
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(new data.SetCurrentUserAction(this.currentUser));
        this.loginService.setAppUser(this.currentUser);
        window.location.pathname = this.loginService.lastUrl || 'dashboard';
      });
  }

  showClientTermsAndPrivacy() {
    const dialogRef = this.dialog.open(TermsAndPrivacyDialogComponent, {
      panelClass: 'report-bug-get-support-dialog-panel',
      width: '450px',
      disableClose: true,
      data: {
        customText: this.clientTermsAndPrivacy
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
      }
    });
  }
}
