import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Event, IManager, UserEvent} from '../../model/Event';
import {UtilsService} from '../../core/utils.service';
import {auditTime, Subscription} from 'rxjs';
import {ExtEvent} from '../../model/ExtEvent';
import {TimerService} from '../../core/timer.service';
import {Constants, DATE_FORMAT, EVENT_DATE_MODE} from '../../core/constants';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() event!: Event | UserEvent;
  @Input() isPreview = false;
  @Output() clickAction: EventEmitter<void> =  new EventEmitter();
  readonly Constants = Constants;
  readonly EVENT_DATE_MODE = EVENT_DATE_MODE;

  background: string;
  isBackgroundAvailable: boolean;
  backgroundColor: string;
  rate: string;
  managers: IManager[] = [];
  managersTooltip: string;
  registrationStatus: string;
  registrationStatusClass: string;
  colors = [
    'rgb(50 111 112)', 'rgb(0 160 162)',  'rgb(137 200 201)',
    'rgb(253 118 46)', 'rgb(219 130 83)', 'rgb(241 163 121)',
    'rgb(255 222 79)', 'rgb(255 235 149)', 'rgb(255 207 0)',
    'rgb(219 83 83)',  'rgb(255 139 139)', 'rgb(245 57 57)'
  ];
  eventFinishDateStr: string;
  eventStatusSubscription: Subscription;
  status: string[];

  constructor(private utils: UtilsService, private timerService: TimerService) { }

  ngOnInit(): void {
    this.prepareData();
  }

  ngOnDestroy(): void {
    this.eventStatusSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'event' && !changes[propName].isFirstChange()) {
        this.prepareData();
      }
    }
  }

  get currentTime() {
    const ctime = this.utils.getSystemCurrentTime();
    return ctime ? ctime : new Date().getTime();
  }

  prepareData() {
    if (this.event instanceof UserEvent && this.event.customData?.status) {
      this.registrationStatus = this.utils.i18n('user.registration.status.' + this.event.customData.status);
      this.registrationStatusClass = this.getStatusColorClass(this.event.customData?.status);
    }
    this.background = this.event.verticalBanner ? this.event.verticalBanner : '';
    this.isBackgroundAvailable = this.background?.includes('http');
    this.backgroundColor = this.background ? '' : this.getRandomColor();
    // this.rate = ((Math.random() * 50) / 10).toFixed(1);
    const m = Object.keys(this.event.presenters).map(key => {
      return this.event.presenters[key] as IManager;
    });
    if (m.length > 6) {
      this.managers = m.slice(0, 6);
      this.managersTooltip = m.slice(7).map(it => it.fullName || it.userName).join('\n');
      this.managers.push({
        email: '',
        picture: '+' + (m.length - 7),
        fullName: '',
        userName: ''
      });
    } else {
      this.managers = m;
    }

    const extEvent = new ExtEvent(this.utils, this.event);
    this.status = extEvent.getEventStatus(this.currentTime);
    this.eventFinishDateStr = this.utils.formatDate(this.event.endDate, DATE_FORMAT.DD_MM_YYYY);
    if (!this.eventStatusSubscription) {
      this.eventStatusSubscription = this.timerService.getTimer()
        .pipe(auditTime(10000))
        .subscribe(() => {
          const event = new ExtEvent(this.utils, this.event);
          this.status = event.getEventStatus(this.currentTime);
        });
    }
  }

  getRandomColor() {
    const delimiter = 100 / this.colors.length;
    const hash = Number(UtilsService.hash(this.event.eventId, 2));
    const colorIdx = Math.floor(hash / delimiter);
    return this.colors[colorIdx];
  }

  private getStatusColorClass(status) {
    return status.replace(/\./g, '-');
  }

  onClick() {
    this.clickAction.emit();
  }
}
