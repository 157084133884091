export class ChatSettings {
  event = false;
  group = false;
  management = false;
  sectionForVideoConference = false;
  allowSoundAlert = true;


  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  toObject() {
    return {...this};
  }
}
