import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ToasterService} from '../core/toaster.service';
import {catchError, debounceTime, map, Observable, of, switchMap, takeUntil} from 'rxjs';
import {Action} from '@ngrx/store';
import * as data from '../actions/data';
import {EventsDataService} from './events-data.service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {Constants} from '../core/constants';
import {RootLocalStorageService} from '../core/root-local-storage.service';
import {CommonService} from '../core/common.service';

@Injectable()
export class EventsDataEffects {

  constructor(private actions$: Actions
              , private dataService: EventsDataService
              , private toasterService: ToasterService
              , private loginService: LoginService
              , private localStorageService: RootLocalStorageService
              , private logger: LoggerService
              , private commonService: CommonService
  ) { }

  loadUsersOnline$ = createEffect(() => this.actions$.pipe(
    ofType(data.ActionTypes.GET_USER_ONLINE, data.ActionTypes.GET_USER_ONLINE_CANCEL),
    map((action: data.GetUsersOnlineAction | data.GetUsersOnlineCancelAction) => action),
    switchMap(action => action.type === data.ActionTypes.GET_USER_ONLINE_CANCEL ?
      of(new data.GetUsersOnlineSuccessAction([])) : this.dataService.getUsersOnline(action.payload).pipe(
        map((res: any[]) => {
          const value = res.map(it => new Object({type: it.type, data: it.payload.doc.data()}));
          return new data.GetUsersOnlineSuccessAction(value);
        }),
        takeUntil(this.actions$.pipe(ofType(data.ActionTypes.LOGOUT))),
        catchError(err => {
          return of(new data.EventServerFailAction(err));
        }))
    )));

  loadUsersOnlineShort$ = createEffect(() => this.actions$.pipe(
    ofType(data.ActionTypes.GET_USER_ONLINE_SHORT, data.ActionTypes.GET_USER_ONLINE_SHORT_CANCEL),
    debounceTime(300),
    map((action: data.GetUsersOnlineShortAction | data.GetUsersOnlineShortCancelAction) => action),
    switchMap(action => action.type === data.ActionTypes.GET_USER_ONLINE_SHORT_CANCEL ?
      of(new data.GetUsersOnlineShortSuccessAction({})) : this.dataService.loadUsersOnlineShort(action.payload).pipe(
        map(res => {
          const value = new Object({type: res.type, key: res.payload.key, data: res.payload.val()});
          return new data.GetUsersOnlineShortSuccessAction(value);
        }),
        takeUntil(this.actions$.pipe(ofType(data.ActionTypes.LOGOUT))),
        catchError(err => {
          return of(new data.EventServerFailAction(err));
        }))
    )));

  remove$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(data.ActionTypes.REMOVE_EVENT),
    debounceTime(300),
    map((action: data.UpdateAction) => action.payload),
    switchMap(payload => this.dataService.remove(payload)
      .then(res => {
        this.loginService.redirectToHomePage();
        return res;
      })
      .then(res => new data.RemoveEventSuccessAction(payload))
      .catch(err => new data.EventServerFailAction(err))
    )));

  serverFail$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(data.ActionTypes.EVENTS_SERVER_FAIL),
    debounceTime(300),
    map((action: data.UpdateAction) => action.payload),
    switchMap(payload => {
      this.commonService.showProgress.next(false);
      this.logger.error(payload);
      if (payload && payload.result) {
        payload = payload.result;
      }
      if (payload && payload.code !== 'PERMISSION_DENIED' && payload.code !== 'permission-denied') {
        if (payload.error) {
          payload = payload.error;
        }
        if (payload.code === 401) {
          this.loginService.gotoLogin().then(() => {
            this.toasterService.pop('error', 'Failure ' + payload.code, payload.message);
          });
        } else
        if (payload.code === 400 && payload.message === Constants.USER_BLOCKED_SERVER_MESSAGE) {
          this.loginService.gotoLogin().then(() => {
            this.toasterService.pop('error', 'Failure ' + payload.code, payload.message);
            this.loginService.redirectToLoginPage(true);
          });
        } else
        if (payload.code === 400 && payload.message === Constants.EVENT_ACCESS_DENIED) {
          this.toasterService.pop('error', 'Failure ' + payload.code, payload.message);
          this.dataService.offListenConnectionState();
          this.loginService.redirectTo('/dashboard');
          this.localStorageService.remove('lastUrl');
        } else {
          this.toasterService.pop('error', 'Failure ' + payload.code, payload.message);
          this.loginService.redirectTo('/dashboard');
          this.localStorageService.remove('lastUrl');
        }
      } else if (payload && (payload.code === 'PERMISSION_DENIED' || payload.code === 'permission-denied')) {
        if (this.loginService.getAuthUser()) {
          this.toasterService.pop('error', 'Failure ' + payload.code, Constants.PERMISSION_DENIED);
        }
        this.loginService.redirectTo('/dashboard');
        this.localStorageService.remove('lastUrl');
      }
      return of(new data.EmptyAction(''));
    })), {dispatch: false});
}
