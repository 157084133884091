import {Injectable, signal} from '@angular/core';
import {BehaviorSubject, combineLatest, filter, firstValueFrom, map, Observable, of, Subject, Subscription, switchMap} from 'rxjs';
import {ILoadData, LOAD_STATE} from '../../core/constants';
import {CommonService} from '../../core/common.service';
import {Exam} from '../exam-model/exam';
import {ExamDataService} from '../exam/exam-service/exam-data.service';
import {LoginService} from '../../login/login.service';
import {ExamRolesService} from '../exam/exam-roles/exam-roles.service';
import {isEmpty} from 'lodash';

enum LOAD_DATA {
  EXAMS = 'loadExams',
}

@Injectable({
  providedIn: 'root'
})
export class ExamsService {
  private _exams$ = new BehaviorSubject<ILoadData<Exam[]>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  loaded$ = signal(false);
  attendeeMode = signal(false);

  private _unsubscribeAll = new Subject();
  private subscriptions: {
    [key: string]: {
      subscription?: Subscription,
      subject: BehaviorSubject<ILoadData<any>>
    }
  } = {
    [LOAD_DATA.EXAMS]: {subject: this._exams$},
  };
  constructor(private examDataService: ExamDataService,
              public common: CommonService,
              public rolesService: ExamRolesService,
              private loginService: LoginService) { }

  get currentUser() {
    return this.loginService.getAppUser();
  }

  unsubscribeAll() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
    this.attendeeMode.set(false);
    Object.keys(this.subscriptions)
      .forEach(it => {
        this.setSubscription(<LOAD_DATA> it, null);
      });
  }

  async loadData() {
    const loadList: Observable<any>[] = [];
    if (this._exams$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExams();
      loadList.push(this._exams$);
    }
    return loadList.length ? firstValueFrom(combineLatest(loadList)) : Promise.resolve([]);
  }

  async loadUserData() {
    const loadList: Observable<any>[] = [];
    if (this._exams$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadUserExams();
      loadList.push(this._exams$);
    }
    return loadList.length ? firstValueFrom(combineLatest(loadList)) : Promise.resolve([]);
  }

  private setSubscription(name: LOAD_DATA, sub: Subscription) {
    if (this.subscriptions[name]) {
      if (this.subscriptions[name].subscription) {
        this.subscriptions[name].subscription.unsubscribe();
      }
      this.subscriptions[name].subscription = sub;
      if (!sub && this.subscriptions[name].subject.getValue().loaded !== LOAD_STATE.NOT_LOADED) {
        this.subscriptions[name].subject.next({loaded: LOAD_STATE.NOT_LOADED, value: null});
      }
    }
  }

  private loadExams() {
    this._exams$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadExams()
      .subscribe((value: any[]) => {
        this._exams$.next({
          loaded: LOAD_STATE.LOADED,
          value: (value || []).sort(this.common.utils.comparator('createTime', 'desc'))
        });
      });
    this.setSubscription(LOAD_DATA.EXAMS, sub);
  }

  private loadUserExams() {
    this._exams$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadUserExams(this.currentUser.userId)
      .pipe(switchMap((examsIds: string[]) => {
        const list = [];
        for (const examId of (examsIds ?? [])) {
          list.push(this.examDataService.loadExam(examId));
        }
        return combineLatest(!isEmpty(list) ? list : [of([])]);
      }))
      .subscribe((value: any[]) => {
        this._exams$.next({
          loaded: LOAD_STATE.LOADED,
          value: (value || []).sort(this.common.utils.comparator('createTime', 'desc'))
        });
      });
    this.setSubscription(LOAD_DATA.EXAMS, sub);
  }

  get exams$(): Observable<Exam[]> {
    return this._exams$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get exams(): Exam[] {
    const object = this._exams$.getValue();
    return object.loaded === LOAD_STATE.LOADED ? object.value : null;
  }

  getUserExams(userId: string): Promise<string[]> {
    return firstValueFrom(this.examDataService.loadUserExams(userId));
  }
}
