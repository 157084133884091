import { animate, animateChild, query, state, style, transition, trigger } from "@angular/animations";

export const childAnimationAwaiter = trigger('childAnimationAwaiter', [
  transition('* => void', [
    query('@*', [animateChild()], { optional: true })
  ])
]);

export const showHideScaleX = trigger('showHideScaleX', [
  transition(':enter', [
    style({transform: 'scaleX(0)'}),
    animate('.2s', style({transform: 'scaleX(1)'}))
  ]),
  transition(':leave', [
    style({transform: 'scaleX(1)'}),
    animate('.2s', style({transform: 'scaleX(0)'}))
  ]),
]);

export const widthChangeAnim = trigger('widthChangeAnim', [
  state('open', style({ width: '{{openWidth}}' }), { params: { openWidth: 'unset' }}),
  state('closed', style({ width: '{{closedWidth}}' }), { params: { closedWidth: 'unset' }}),
  transition('* => *', [
    animate('.2s')
  ]),
]);
