import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportPrintDialogComponent} from './export-print-dialog/export-print-dialog.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../core/core.module';
import {HttpClient} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ExportPrintSelectSectionDialogComponent } from './export-print-select-section-dialog/export-print-select-section-dialog.component';
import {MatListModule} from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UserAvatarComponent } from '../../user-avatar/user-avatar.component';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    ExportPrintDialogComponent,
    ExportPrintSelectSectionDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatListModule,
    MatTreeModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    UserAvatarComponent
  ],
  exports: [
    ExportPrintDialogComponent
  ]
})
export class ExportPrintDialogModule {
}
