import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {isEmpty} from 'lodash';
import {UtilsService} from '../core/utils.service';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {SECTION_LEARNING_TYPE} from '../core/constants';
import {IRegistrationSettingsMap, RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {IQuestionnaireContentMap} from '../model/content/QuestionnaireContent';
import {EventsDataService} from './events-data.service';
import {IRegistrationQuestionnaireAnswers} from '../model/EventQuestion';
import {RegistrationProcessService} from './registration-process.service';

export interface IUserReg {
  userId: string;
  status: string;
  actions: string[];
  showQuestionnaire: boolean;
  email: string;
  fullName: string;
}

export interface ISelectedSection {
  sectionId: string;
  service: RegistrationProcessService;
  user: IUserReg;
  originId?: string;
}

export interface ISelectedSectionMap {
  [id: string]: ISelectedSection;
}

@Injectable()
export class RegistrationExchangeService {

  public showSectionRegistration = new BehaviorSubject<boolean>(false);
  public openSectionRegistration = new Subject<{section, isRoot, filterStatus}>();
  public openSectionQuestionnaire = new Subject<{open, sectionId, user}>();
  public registrationSelectSectionsList = new BehaviorSubject<ISelectedSectionMap>({}); // objects of type {sectionId, service, user}
  public sectionsContainerMessageExchange = new Subject<{userId, idFrom, listIdTo, select}>();
  public sectionsContainerGroupExchange = new BehaviorSubject<{userId, idFrom, listIdTo, disable, containerId}>(null);
  public sectionsMandatoryMessageExchange = new Subject<{userId, idFrom, idTo, selectType, select}>();
  public sectionsRegistrationModeMessageExchange = new Subject<{userId, idFrom, mode}>();
  public rootApproveMessageExchange = new BehaviorSubject<{}>(null);
  public containerApproveMessageExchange = new BehaviorSubject<{}>(null);
  // "Other" - section is not registered and is not unregistered.
  public sectionsOtherMessageExchange = new BehaviorSubject<{userId, idFrom, idTo, select}>(null);

  public registrationSelectSection: ISelectedSectionMap = {}; // {sectionId, service, user}
  public rootApproveMessages = {}; // {sendUserId: { disable: }}
  public containerApproveMessages = {}; // {sendUserId: {containerId: { disable: }}

  public mediaSwitcher = new Subject<{switch1, switch2}>();
  public refreshPresenterRegistrationDialog = new Subject<SectionTimeline>();
  public setSelfLearningPeopleTableFilter = new Subject<{status: SECTION_LEARNING_TYPE}>();

  public sectionsAvailableUsersActions = {};  // {userId: {sectionId: (actions: [])}
  public sectionsAvailableUsersActionsExchange = new BehaviorSubject<{}>(null);
  public unregisterAllSection = new Subject<boolean>();
  public sectionsGroupMessageExchange = new Subject<{userId, idFrom, listIdTo, select}>();
  public sectionsGroupExchange = new BehaviorSubject<{userId, idFrom, listIdTo, disable, groupNumber}>(null);

  public rootRegistrationSettings$ = new BehaviorSubject<RegistrationSettings>(null);
  public calcTimeByZone = new Subject<string>();
  public alignTimeZoneSelector = new Subject<number>();
  public reloadQuestionnaireAnswers$ = new Subject<{userId: string, unregistered?: boolean}>();
  public reloadRegistrationQuestionnaireAnswers$ = new Subject<{userId: string}>();

  public timelineRegistrationLoaded = new BehaviorSubject<boolean>(false);
  public timelineItemRegistrationLoaded = new BehaviorSubject<string>(null);
  public alignElement = new Subject<string>();
  public removeAlignElement = new BehaviorSubject<string>(null);
  public deleteSectionElementFromAlign = new BehaviorSubject<{[name: string]: {[sectionId: string]: boolean}}>({});
  public changeVisualParams = new BehaviorSubject<{[name: string]: any}>({});

  public registrationSettings$ = new BehaviorSubject<IRegistrationSettingsMap>({});
  public registrationQuestionnaire$ = new BehaviorSubject<IQuestionnaireContentMap>({});
  public registrationQuestionnaireAnswers$ = new BehaviorSubject<IRegistrationQuestionnaireAnswers>({});
  public sectionsRegisteredUsers$ = new BehaviorSubject<any>(null);
  public assistantUsers$ = new BehaviorSubject<any>({});
  public progressBySectionOnDelete$ = new Subject<string>();
  public eventId: string;
  private subscription: Subscription;

  constructor(private utils: UtilsService,
              private eventDataService: EventsDataService) {}

  public resetServiceValues(leave = false) {
    this.registrationSelectSection = {};
    this.sectionsContainerGroupExchange = new BehaviorSubject<{userId, idFrom, listIdTo, disable, containerId}>(null);
    this.mediaSwitcher = new BehaviorSubject<{switch1, switch2}>(null);
    this.sectionsAvailableUsersActions = {};
    this.sectionsAvailableUsersActionsExchange = new BehaviorSubject<{}>(null);
    this.sectionsGroupExchange = new BehaviorSubject<{userId, idFrom, listIdTo, disable, groupNumber}>(null);
    this.rootRegistrationSettings$ = new BehaviorSubject<RegistrationSettings>(null);
    this.timelineRegistrationLoaded = new BehaviorSubject<boolean>(false);
    this.timelineItemRegistrationLoaded = new BehaviorSubject<string>(null);
    this.deleteSectionElementFromAlign = new BehaviorSubject<{[name: string]: {[sectionId: string]: boolean}}>({});
    this.changeVisualParams = new BehaviorSubject<{[name: string]: any}>({});
    this.removeAlignElement = new BehaviorSubject<string>(null);
    this.assistantUsers$ = new BehaviorSubject<any>({});
    this.showSectionRegistration.next(false);
    if (leave) {
      this.eventId = null;
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    }
  }

  registrationSelectSectionAsArray(user): ISelectedSection[] {
    if (isEmpty(this.registrationSelectSection)) {
      return [];
    } else {
      return this.utils.objectValues(this.registrationSelectSection).filter(obj => obj.user.userId === user.userId);
    }
  }

  registrationCancelSelectSection(user) {
    const sections = this.utils.objectValues(this.registrationSelectSection)
      .filter(obj => obj && obj.user.userId === user.userId).map(value => value.sectionId + '=' + value.user.userId);
    for (const key of sections) {
      delete this.registrationSelectSection[key];
    }
    this.registrationSelectSectionsList.next(this.registrationSelectSection);
  }

  getSectionsRegisteredUsers(eventId: string) {
    if (eventId === this.eventId) {
      return;
    }
    this.eventId = eventId;
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
      if (this.sectionsRegisteredUsers$.getValue()) {
        this.sectionsRegisteredUsers$.next(null);
      }
    }
    this.subscription = this.eventDataService.getSectionsRegisteredUsers(this.eventId)
      .subscribe((registeredUsers) => {
        this.sectionsRegisteredUsers$.next(registeredUsers || []);
      });
  }

  getRegisteredUsersBySection(sectionId: string): any {
    const users: any[] = this.sectionsRegisteredUsers$.getValue();
    const res = {};
    for (const user of users) {
      if (user && user.sections) {
        const reduce = Object.keys(user.sections).filter(it => it === sectionId).reduce((d, val) => {
          const obj = {
            sectionId: val, id: user.id, ...user.sections[val]
          };
          const keys = Object.keys(obj).sort();
          const ret = {};
          keys.forEach(key => {
            ret[key] = obj[key];
          });
          return ret;
        }, {});
        if (!isEmpty(reduce)) {
          res[user.id] = reduce;
        }
      }
    }
    return res;
  }

  getRegisteredUsersBySectionAsObject(sectionId: string): any {
    const registered: any[] = Object.values(this.getRegisteredUsersBySection(sectionId));
    return (registered || []).reduce((accum, item) => {
      accum[item.userId] = item;
      return accum;
    }, {});
  }

}
