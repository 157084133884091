import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from '../core/common.service';
import {LoginService} from './login.service';
import {Constants} from '../core/constants';
import {ClientConfig} from '../model/ClientConfig';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {isEmpty} from 'lodash';
import {TermsAndPrivacyDialogComponent} from '../modules/terms-and-privacy-dialog/terms-and-privacy-dialog.component';
import {take} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

declare const TweenLite: any;
declare const Circ: any;

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @Input() readonly = false;
  public options = [];
  public currentDomain;
  width;
  height;
  largeHeader;
  canvas;
  ctx;
  points;
  target;
  animateHeader = true;
  title = '';
  description;
  registration = false;
  loginForm: UntypedFormGroup;
  registrationForm: UntypedFormGroup;
  clientConfig: ClientConfig;
  haveMoreProviders = false;
  showMoreProviders = false;
  showTimelineInternationalIncLink: string;
  clientTermsAndPrivacy: string;
  orgName = this.common.getEnv().organizationName;
  enableForgotPassword = false;
  emailErrors = { email: this.translateService.instant('registration.people.email-validation') };
  passwordErrors = { minlength: this.translateService.instant('registration.people.email-password') };
  resetPasswordFailed: string;
  resetPasswordSuccess: boolean;
  sendingEmailSpinner: boolean;
  imgLoaded = false;

  @ViewChild('divTerms') divTerms;

/*
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }
*/

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public loginService: LoginService,
              private common: CommonService,
              public dialog: MatDialog,
              private translateService: TranslateService
  ) {
    this.showTimelineInternationalIncLink = this.common.utils.getEnv()['showTimelineInternationalIncLink'];
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.registrationForm = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      passwords: new UntypedFormGroup({
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        password_confirm: new UntypedFormControl('', [Validators.required, Validators.minLength(6)])
      }, [this.passwordConfirming]),
    });
    this.loginService.clientConfig$
      .subscribe((value: ClientConfig) => {
        this.clientConfig = value;
        this.title = value ? value.name : this.common.utils.getEnv().appName;
        this.description = this.clientConfig ? this.clientConfig.description : null;
        this.clientTermsAndPrivacy = value ? value.clientTermsAndPrivacy : null;
        const externalUsers = this.clientConfig && this.clientConfig.externalUsers;
        const options = [];
        if (value && value.id) {
          if (!isEmpty(value.providers)) {
            value.providers.forEach((provider) => {
              options.push({value: provider.id, caption: this.setCaption(provider.id), hidden: provider.additional, customDomain: provider.customDomain});
              this.haveMoreProviders = this.haveMoreProviders || provider.additional;
            });
          } else {
            this.common.utils.getEnv().providers.forEach((provider) => {
              if ((!provider.disabled && provider.value !== 'email') || (provider.value === 'email' && externalUsers)) {
                options.push(provider);
              }
            });
          }
          this.options = options;
          this.addAllowedProvider();
          if (this.loginService.fromUrl === 'registration') {
            if (value && value.id) {
              this.registration = true;
              this.currentDomain = 'email';
            } else {
              this.registration = false;
              this.getCurrentDomain();
            }
          }
        }
      });
  }

  ngOnInit(): void {
    this.getCurrentDomain();
    // this.initHeader();
    // this.initAnimation();
  }

  setCaption(id: string): string {
    if (id === 'other') return this.translateService.instant('registration.people.login-with') + ' Google';
    return this.translateService.instant('registration.people.login-with') + ' ' + id.replace(/(^|\s)\S/g, (t) => t.toUpperCase());
  }

  getCurrentDomain() {
    this.currentDomain = this.common.localStorage.get('currentDomain', this.common.utils.getEnv().defaultProvider);
    this.addAllowedProvider();
    if (!this.options.includes(this.currentDomain)) {
      this.currentDomain = this.common.utils.getEnv().defaultProvider;
    }
  }

  addAllowedProvider() {
    const allowProvider = this.common.localStorage.get('allowedProviders', null);
    if (allowProvider != null) {
      const provider = this.common.utils.getEnv().providers.find(it => it.disabled && it.provider === allowProvider);
      if (provider && !this.options.includes(provider)) {
        this.options.push(provider);
      }
    }
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').enabled && c.get('password_confirm').enabled && c.get('password').value !== c.get('password_confirm').value) {
      return {invalid: true};
    }
  }

  get getWelcomeTitle() {
    return this.common.utils.getEnv().appName;
  }

  loginWithGoogle(register = false, provider: string, customDomain?: string) {
    this.currentDomain = provider;
    this.common.localStorage.set('currentDomain', this.currentDomain);
    this.loginForm.disable();
    this.loginService.signInInProgress = true;
    let name = '';
    let email = this.loginForm.controls['email'].value;
    let password = this.loginForm.get('password').value;
    if (this.registration) {
      name = this.registrationForm.controls['name'].value;
      email = this.registrationForm.controls['email'].value;
      password = this.registrationForm.get('passwords').get('password').value;
    }
    this.loginService.signIn(
      this.currentDomain,
      true,
      name,
      email,
      password,
      register,
      customDomain
    ).then(() => {
      this.registration = false;
    }).finally(() => {
      this.loginForm.enable();
    });
  }

  resetPassword() {
    if (!this.loginForm.controls['email'].valid) {
      return;
    }
    this.sendingEmailSpinner = true;
    this.loginService.resetPassword(this.loginForm.controls['email'].value)
      .then(() => {
        this.common.showPopupSuccess(this.common.i18n('home.login.account.password.reset.popup'));
        this.sendingEmailSpinner = false;
        this.resetPasswordSuccess = true;
      }).catch(e => {
        this.sendingEmailSpinner = false;
        this.loginService.loginFailedEmail = this.translateService.instant('home.login.forgot.password.fail');
        this.common.log.error(e);
        this.common.showPopupError(e);
    });
  }

  media() {
    return document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH;
  }

  initHeader() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.target = {x: this.width / 2, y: this.height / (this.media() ? 3 : 6)};

    this.largeHeader = document.getElementById('canvas-wrapper');
    this.largeHeader.style.height = this.height + 'px';

    this.canvas = document.getElementById('lines-canvas');
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.ctx = this.canvas.getContext('2d');

    // create points
    this.points = [];
    for (let x = 0; x < this.width; x = x + this.width / 20) {
      for (let y = 0; y < this.height; y = y + this.height / 20) {
        const px = x + Math.random() * this.width / 20;
        const py = y + Math.random() * this.height / 20;
        const p = {x: px, originX: px, y: py, originY: py };
        this.points.push(p);
      }
    }

    // for each point find the 5 closest points
    for (let i = 0; i < this.points.length; i++) {
      const closest = [];
      const p1 = this.points[i];
      for (let j = 0; j < this.points.length; j++) {
        const p2 = this.points[j];
        if (!(p1 === p2)) {
          let placed = false;
          for (let k = 0; k < 5; k++) {
            if (!placed) {
              if (closest[k] === undefined) {
                closest[k] = p2;
                placed = true;
              }
            }
          }

          for (let k = 0; k < 5; k++) {
            if (!placed) {
              if (this.getDistance(p1, p2) < this.getDistance(p1, closest[k])) {
                closest[k] = p2;
                placed = true;
              }
            }
          }
        }
      }
      p1.closest = closest;
    }

    // assign a circle to each point
    for (let i = 0; i < this.points.length; i++) {
      this.points[i].circle =
        new Circle(this.points[i], 2 + Math.random() * 2, 'rgba(255, 255, 255, 0.8)', this.ctx, this.points[i].active);
    }
  }

  // Util
  getDistance(p1, p2) {
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
  }


  initAnimation() {
    this.animate();
    for (let i = 0; i < this.points.length; i++) {
      this.shiftPoint(this.points[i]);
    }
  }

  animate() {
    const _this = this;
    const _animate = () => {
      if (_this.animateHeader) {
        _this.ctx.clearRect(0, 0, _this.width, _this.height);
        for (let i = 0; i < _this.points.length; i++) {
          // detect points in range
          if (Math.abs(_this.getDistance(_this.target, _this.points[i])) < 4000) {
            _this.points[i].active = 0.3;
            _this.points[i].circle.active = 0.6;
          } else if (Math.abs(_this.getDistance(_this.target, _this.points[i])) < 20000) {
            _this.points[i].active = 0.1;
            _this.points[i].circle.active = 0.3;
          } else if (Math.abs(_this.getDistance(_this.target, _this.points[i])) < 40000) {
            _this.points[i].active = 0.02;
            _this.points[i].circle.active = 0.1;
          } else {
            _this.points[i].active = 0;
            _this.points[i].circle.active = 0;
          }

          _this.drawLines(_this.points[i]);
          _this.points[i].circle.draw();
        }
      }
      requestAnimationFrame(_animate);
    };
    _animate();
  }

  shiftPoint(p) {
    const vm = this;
    TweenLite.to(p, 1 + Math.random(), {x: p.originX - 50 + Math.random() * 100,
      y: p.originY - 50 + Math.random() * 100, ease: Circ.easeInOut,
      onComplete: function() {
        vm.shiftPoint(p);
      }});
  }

  // Canvas manipulation
  drawLines(p) {
    if (!p.active) {return; }
    for (let i = 0; i < p.closest.length; i++) {
      this.ctx.beginPath();
      this.ctx.moveTo(p.x, p.y);
      this.ctx.lineTo(p.closest[i].x, p.closest[i].y);
      this.ctx.strokeStyle = 'rgba(255, 255, 255,' + p.active + ')';
      this.ctx.stroke();
    }
  }

  resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.largeHeader.style.height = this.height + 'px';
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.target = {x: this.width / 2, y: this.height / (this.media() ? 3 : 6)};
    if (this.divTerms && this.divTerms.nativeElement.clientWidth) {
      this.divTerms.nativeElement.style.left = 'calc(50% - ' + (this.divTerms.nativeElement.clientWidth / 2) + 'px)';
    }
  }

  ngAfterViewInit(): void {
    if (this.divTerms && this.divTerms.nativeElement.clientWidth) {
      this.divTerms.nativeElement.style.left = 'calc(50% - ' + (this.divTerms.nativeElement.clientWidth / 2) + 'px)';
    }
  }

  isEmail(email: string) {
    if (!email) {
      return false;
    }
    return this.common.utils.validateEmail(email);
  }

  showClientTermsAndPrivacy() {
    const dialogRef = this.dialog.open(TermsAndPrivacyDialogComponent, {
      panelClass: 'report-bug-get-support-dialog-panel',
      width: '450px',
      disableClose: true,
      data: {
        customText: this.clientTermsAndPrivacy
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
      }
    });
  }
}

export class Circle {
  pos;
  radius;
  color;
  ctx;
  active;

  constructor (pos, radius, color, ctx, active) {
    this.pos = pos;
    this.radius = radius;
    this.color = color;
    this.ctx = ctx;
    this.active = active;
  }

    draw() {
      if (!this.active) {return; }
      this.ctx.beginPath();
      this.ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
      this.ctx.fillStyle = 'rgba(255, 2555, 255,' + this.active + ')';
      this.ctx.fill();
    }
}


