import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {UserApiService} from './user-api.service';
import {Group} from '../model/Group';
import {AppUserUID} from '../model/AppUserUID';
import {BehaviorSubject, map, Observable, Subscription} from 'rxjs';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppUser} from '../model/AppUser';
import {EventModeApiService} from './event-mode-api.service';
import {EventApiService} from './event-api.service';
import {StorageDataService} from './storage-data.service';

@Injectable()
export class UsersDataService {

  userEvents: BehaviorSubject<{[eventId: string]: number}> = new BehaviorSubject<{[eventId: string]: number}>({});
  userOwnerEvents: BehaviorSubject<{[eventId: string]: number}> = new BehaviorSubject<{[eventId: string]: number}>({});

  subscriptions: {[key: string]: Subscription} = {};

  constructor(private afDB: AngularFireDatabase
    , private utils: UtilsService
    , private loginService: LoginService
    , private store: Store<fromRoot.State>
    , private userApi: UserApiService
    , private eventApiService: EventApiService
    , private eventModeApiService: EventModeApiService
    , private http: HttpClient
    , private storageDataService: StorageDataService
  ) {
    let userEvents$: Subscription;
    let userOwnerEvents$: Subscription;
    this.loginService.getAuthenticatedUser()
      .subscribe(user => {
        if (userEvents$ && !userEvents$.closed) {
          userEvents$.unsubscribe();
        }
        if (userOwnerEvents$ && !userOwnerEvents$.closed) {
          userOwnerEvents$.unsubscribe();
        }
        if (user) {
          userEvents$ = this.eventApiService.getUserEvents()
            .subscribe((events: {[eventId: string]: number}) => {
              this.userEvents.next(events);
            });
          userOwnerEvents$ = this.eventApiService.getUserOwnerEvents()
            .subscribe((events: {[eventId: string]: number}) => {
              this.userOwnerEvents.next(events);
            });
        }
      });
  }

  setRoleToUser(userId: string, roleId: string, set: boolean) {
    return this.userApi.setRoleToUser(userId, roleId, set);
  }

  setUserGuest(userId: string, blocked: boolean, client_id?: string) {
    return this.userApi.setUserGuest(userId, blocked, client_id);
  }

  setUserBlocked(userId: string, blocked: boolean, client_id?: string) {
    return this.userApi.setUserBlocked(userId, blocked, client_id);
  }

  setUserClientId(userId: string, client_id: string) {
    return this.userApi.setUserClientId(userId, client_id);
  }

  setAcceptTermsAndPrivacy(userId: string, acceptTermsAndPrivacy: boolean) {
    return this.userApi.setAcceptTermsAndPrivacy(userId, acceptTermsAndPrivacy);
  }

  setAcceptClientTermsAndPrivacy(acceptClientTermsAndPrivacy: boolean, userId?: string) {
    return this.userApi.setAcceptClientTermsAndPrivacy(acceptClientTermsAndPrivacy, userId);
  }

  acceptCustomTerms(value: boolean, userId?: string) {
    return this.userApi.acceptCustomTerms(value, userId);
  }

  setAcceptClientTermsAndPrivacyByClientId(userId: string, clientId: string, acceptClientTermsAndPrivacy: boolean) {
    return this.userApi.setAcceptClientTermsAndPrivacyByClientId(userId, clientId, acceptClientTermsAndPrivacy);
  }

  updateUserCard(userId: string, description: string, socialNetwork: string, hideEmail: boolean, department: string) {
    return this.userApi.updateUserCard(userId, description, socialNetwork, hideEmail, department);
  }

  updateProfilePicture(url: string) {
    return this.userApi.updateProfilePicture(url);
  }

  getUserById(userId: string): Observable<AppUser> {
    return this.userApi.getUserById(userId);
  }

  getUserByEmail(email: string): Observable<AppUser> {
    return this.userApi.getUserByEmail(email);
  }

  getSuperusers(): Observable<AppUser[]> {
    return this.userApi.getSuperusers();
  }

  addSuperuser(email: string): Promise<any> {
    return this.userApi.addSuperuser(email);
  }

  removeSuperuser(email: string) {
    return this.userApi.removeSuperuser(email);
  }

  getUsers(): Observable<AppUser[]> {
    return this.userApi.getUsers();
  }

  getUsersPromise() {
    return this.userApi.getUsersPromise();
  }

  getClientUser(userId: string, clientId: string): Promise<AppUser> {
    return this.userApi.getClientUser(userId, clientId);
  }

  createClientUser(userId: string, clientId: string): Promise<AppUser> {
    return this.userApi.createClientUser(userId, clientId);
  }

  deleteClientUser(userId: string, clientId: string) {
    return this.userApi.deleteClientUser(userId, clientId);
  }

  deleteAndClearClientEventUser(userId: string, clientId: string, eventId: string) {
    return this.userApi.deleteAndClearClientEventUser(userId, clientId, eventId);
  }

  getUser(key: string, options: {takeOne?: boolean, root?: boolean} = {takeOne: false, root: false}) {
    return this.userApi.getUser(key, options);
  }

  getGroups(): Observable<Group[]> {
    return this.userApi.getGroups();
  }

  getGroupUsers(groupId) {
    return this.userApi.getGroupUsers(groupId);
  }

  getGroup(key: string): Observable<Group> {
    return this.userApi.getGroup(key);
  }

  addGroup(group: Group) {
    return this.userApi.addGroup(group);
  }

  saveGroup(group: Group) {
    return this.userApi.saveGroup(group);
  }

  deleteGroup(groupKey: string) {
    return this.userApi.deleteGroup(groupKey);
  }

  addUserToGroup(groupId: string, user: any) {
    return this.userApi.addUserToGroup(groupId, user);
  }

  deleteUserFromGroup(groupId: string, userId: string) {
    return this.userApi.deleteUserFromGroup(groupId, userId);
  }

  getUserUUID(userId: string): Observable<AppUserUID> {
    return this.userApi.getUserUUID(userId);
  }

  setUUIDState(state) {
    return this.userApi.setUUIDState(state);
  }

  generateUUID() {
    return this.userApi.generateUUID();
  }

  addUserToClient(email: string, name: string, picture: string) {
    return this.userApi.addUserToClient(email, name, picture);
  }

  activateSubscription(state: boolean) {
    return this.userApi.activateSubscription(state);
  }

  setGeolocation(country: string, countryCode: string) {
    return this.userApi.setGeolocation(country, countryCode);
  }

  setBrowserLanguage(language: string) {
    return this.userApi.setUserInterfaceLanguage(null, language);
  }

  setUserInterfaceLanguage(language: string) {
    return this.userApi.setUserInterfaceLanguage(language);
  }

  getAzureIdToken() {
    const idToken = this.loginService.getAzureIdToken();
    if (!idToken) {
      return this.userApi.getAzureAccessToken().then(azureAccessToken => {
        this.loginService.setAzureAccessToken(azureAccessToken);
        return azureAccessToken;
      });
    } else {
      const jwt = this.utils.parseJwt(idToken);
      const currentTime = new Date().getTime();
      if (jwt && jwt.exp && ((jwt.exp - (10 * 60))  * 1000) < currentTime) {
        return this.userApi.getAzureAccessToken().then(azureIdToken => {
          this.loginService.setAzureAccessToken(azureIdToken);
          return azureIdToken;
        });
      } else {
        return Promise.resolve(idToken);
      }
    }
  }

  getAzureAccessTokenPicture() {
    return Promise.resolve(this.loginService.getToken('accessToken'));
  }

  requestAzureProfilePicture() {
    return this.getAzureAccessTokenPicture()
      .then(token => {
        return this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }),
          responseType: 'blob'
        })
          .toPromise()
          .then((rawData: Blob) => {
            if (rawData) {
              return new Promise(resolve => {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                  const base64 = reader.result as string;
                  resolve(base64);
                });
                reader.readAsDataURL(rawData);
              });
            } else {
              return Promise.resolve(null);
            }
          })
          .catch(error => {
            if (error.status === 404) {
              return Promise.resolve(null);
            }
          });
      });
  }

  getVerificationInfo(userId: string) {
    return this.userApi.getVerificationInfo(userId);
  }

  verifyUser(userId: string, value: boolean) {
    return this.userApi.verifyUser(userId, value);
  }

  uploadProfilePicture(base64) {
    const uid = this.loginService.getAuthUser().uid;
    if (base64) {
      return this.storageDataService.uploadAnyImageToStorage('profile/' + uid + '/', 'profilePhoto.jpg', base64, 'image/jpg')
        .then(snapshot => {
          if (snapshot) {
            return snapshot.ref.getDownloadURL()
              .then(url => {
                return this.loginService.updateProfilePicture(url)
                  .then(r => {
                    return this.loginService.refreshToken(true)
                      .then((t) => {
                        return this.updateProfilePicture(url)
                          .then(() => {
                            return Promise.resolve(url);
                          });
                      });
                  });
              });
          }
        });
    } else {
      return this.updateProfilePicture(null)
        .then(() => {
          return Promise.resolve(null);
        });
    }
  }

  resetPassword(userId: string) {
    return this.userApi.resetPassword(userId);
  }

  getUsersByPacketSize(packetSize: number, startAt: any) {
    return this.userApi.getUsersByPacketSize(packetSize, startAt)
      .pipe(map(snap => snap.docs));
  }
}
