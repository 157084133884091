import {FirestoreReference} from './FirestoreReference';

export class EventLocation extends FirestoreReference {
  public static readonly DB_PATH = 'locations';

  caption: string;
  type: string;
  parentKey: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }
    if (obj.caption) {
      this.caption = obj.caption;
    }
    if (obj.type) {
      this.type = obj.type;
    }
    if (obj.parentKey) {
      this.parentKey = obj.parentKey;
    }

  }
}
