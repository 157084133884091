import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {catchError, firstValueFrom, map, Observable, take} from 'rxjs';
import {MailTemplate} from '../model/MailTemplate';
import {UtilsService} from '../core/utils.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {HttpClient} from '@angular/common/http';
import {isEmpty} from 'lodash';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateApiService extends StdApiService {
  protected API_URL: string;
  secretKey = '1234567890';

  constructor(private http: HttpClient,
              protected logger: LoggerService,
              protected store: Store<fromRoot.State>,
              protected auth: LoginService,
              protected utils: UtilsService,
              protected aFirestore: AngularFirestore,
              protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/notificationApi/v3/';
  }

  addEmailTemplate(eventId: string, template: MailTemplate) {
    const path = eventId ? `conference/${eventId}/` + MailTemplate.DB_PATH : MailTemplate.DB_PATH;
    return this.afs.collection<MailTemplate>(path).add(template.toObject());
  }

  saveEmailTemplate(eventId: string, template: MailTemplate) {
    if (eventId) {
      return this.afs
        .collection('conference')
        .doc(eventId)
        .collection(MailTemplate.DB_PATH)
        .doc(template._key)
        .set(template.toObject());
    }
    return this.afs
      .collection(MailTemplate.DB_PATH)
      .doc(template._key)
      .set(template.toObject());
  }

  deleteEmailTemplate(eventId: string, key: string) {
    if (eventId) {
      return this.afs
        .collection('conference')
        .doc(eventId)
        .collection(MailTemplate.DB_PATH)
        .doc(key)
        .delete();
    }
    return this.afs
      .collection(MailTemplate.DB_PATH)
      .doc(key)
      .delete();
  }

  testEmailTemplate(eventId: string, sectionId: string, templateId: string) {
    let url = this.API_URL + 'testEmailTemplate?eventId=' + eventId;
    if (sectionId) {
      url = url + '&sectionId=' + sectionId;
    }
    if (templateId) {
      url = url + '&templateId=' + templateId;
    }
    return this.http.post(url, null).toPromise();
  }

  previewEmailTemplate(eventId: string, sectionId: string, template: MailTemplate) {
    let url = this.API_URL + 'previewEmailTemplate?';
    if (eventId) {
      url = url + '&eventId=' + eventId;
    }
    if (sectionId) {
      url = url + '&sectionId=' + sectionId;
    }
    return this.http.post(url, template).toPromise();
  }

  getDefaultTemplates(): Observable<MailTemplate[]> {
    const path = `mail_template`;
    return this.afs.collection<MailTemplate>(path)
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        map(res => this.mapArray(res, (it) => new MailTemplate(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getTemplates(eventId: string): Observable<MailTemplate[]> {
    const path = `conference/${eventId}/mail_template`;
    return this.afs.collection<MailTemplate>(path)
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        map(res => this.mapArray(res, (it) => new MailTemplate(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getTemplatesBySection(eventId: string, sectionId: string): Observable<MailTemplate[]> {
    const path = `conference/${eventId}/mail_template`;
    return this.afs.collection<MailTemplate>(path, ref => ref.where('sectionId', '==', sectionId))
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        map(res => this.mapArray(res, (it) => new MailTemplate(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  sendInviteEmail(eventId: string, sectionId: string, emails: Array<string>) {
    const url = this.API_URL + 'sendInviteEmail?eventId=' + eventId + '&sectionId=' + sectionId;
    return this.http.post(url, {value: emails})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  sendEmailByTemplate(eventId: string, sectionId: string, templateId: string, emails: Array<string>) {
    let url = this.API_URL + 'sendEmailByTemplate?eventId=' + eventId;
    if (sectionId) {
      url = url + '&sectionId=' + sectionId;
    }
    if (templateId) {
      url = url + '&templateId=' + templateId;
    }
    return this.http.post(url, {value: emails})
      .toPromise();
  }

  copyEmailTemplates(eventId: string, sectionIdFrom: string, sectionIdTo: string) {
    return firstValueFrom(this.getTemplatesBySection(eventId, sectionIdFrom))
      .then((list) => {
        if (!isEmpty(list)) {
          let p = Promise.resolve(null);
          for (const v of list) {
            p = p.then(() => {
              delete v._key;
              v.sectionId = sectionIdTo;
              const t = new MailTemplate(v);
              return this.addEmailTemplate(eventId, t);
            });
          }
          return p;
        } else {
          return Promise.resolve();
        }
      });
  }

  deleteAllEmailTemplates(eventId: string, sectionId: string) {
    return firstValueFrom(this.getTemplatesBySection(eventId, sectionId))
      .then((list) => {
        if (!isEmpty(list)) {
          let p = Promise.resolve(null);
          for (const v of list) {
            p = p.then(() => {
              return this.deleteEmailTemplate(eventId, v._key);
            });
          }
          return p;
        } else {
          return Promise.resolve();
        }
      });
  }

}
