import {EXAM_LAUNCH_TYPE, TExamUser, USER_EXAM_STATE} from '../exam-constants/exam-constants';

export interface IUserExamActionHistory {
  actionExecutor: TExamUser;
  action: string;
  actionTime: number;
  payload: any;
}

export class ExamUser {
  examId: string;
  userId: string;
  email: string;
  fullName: string;
  picture: string;
  inviteDate: number;
  state: USER_EXAM_STATE;
  examScore: number;
  examProgress: number[]; // [approved contents count, total exam quiz count]
  startTimeMilliseconds: number;
  examDurationMinutes: number;
  extendedDurationMinutes: number;
  usedDurationSeconds: number;
  finalPoints: any;
  grade: any;
  actionHistory: IUserExamActionHistory[];
  examLaunchType: EXAM_LAUNCH_TYPE;

  constructor(obj?) {
    for (const name of Object.keys(obj ?? {})) {
      if (this.classFieldsNames().includes(name)) {
        this[name] = obj[name];
      }
    }
  }

  protected classFieldsNames(): string[] {
    return [
      'userId',
      'email',
      'fullName',
      'picture',
      'inviteDate',
      'examId',
      'state',
      'examScore',
      'examProgress',
      'startTimeMilliseconds',
      'examDurationMinutes',
      'extendedDurationMinutes',
      'usedDurationSeconds',
      'finalPoints',
      'grade',
      'actionHistory',
      'examLaunchType'
    ];
  }
}
