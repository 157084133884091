import {Component, OnInit} from '@angular/core';
import {LoginService} from '../login.service';
import {ToasterService} from '../../core/toaster.service';
import {SessionStorageService} from '../../core/session-storage.service';
import {DATE_FORMAT} from '../../core/constants';
import {CommonService} from '../../core/common.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  TIMER_IN_SECONDS = 5 * 60 * 1000;
  resend = false;
  counter = 0;
  timeLeft = '';
  email = '';

  constructor(private loginService: LoginService,
              private toasterService: ToasterService,
              private common: CommonService,
              private sessionStorageService: SessionStorageService) {
    this.loginService.requiredVerification = true;
  }

  ngOnInit(): void {
    const verificationTime = this.sessionStorageService.get('verificationTime', 0);
    const currentTime = new Date().getTime();
    if (!verificationTime || ((verificationTime - currentTime) <= 0)) {
      this.resend = true;
    } else {
      this.runTimer(Math.trunc((verificationTime - currentTime) /  1000));
    }
    this.loginService.getAuthenticatedUser()
      .subscribe(user => {
        this.email = user ? user.email : '';
        if (user && user.emailVerified) {
          this.loginService.requiredVerification = false;
        }
      });
  }

  verify(notify = true) {
    return this.loginService.getAuthUser().reload()
      .then(() => {
        if (this.loginService.getAuthUser().emailVerified) {
          return this.loginService.applyVerification()
            .then(() => true);
        } else if (notify) {
          this.toasterService.pop('warning', null, 'Email is not verified.');
        }
      });
  }

  request() {
    this.loginService.requestVerificationEmail()
      .then(() => {
        this.resend = false;
        this.toasterService.pop('success', null, 'Verification Email was sent.');
        const currentTime = new Date().getTime();
        const newTime = currentTime + this.TIMER_IN_SECONDS;
        this.sessionStorageService.set('verificationTime', newTime);
        this.runTimer(Math.trunc((newTime - currentTime) / 1000));
      });
  }

  clearTimer() {
    this.sessionStorageService.remove('verificationTime');
  }

  get tLeft() {
    return {
      minutes: this.timeLeft
    };
  }

  runTimer(counter: number) {
    if (!counter) {
      return;
    }
    const intervalId = setInterval(() => {
      counter = counter - 1;
      this.timeLeft = this.common.utils.formatDate(counter * 1000, DATE_FORMAT.mm_ss);
      if (counter % 15 === 0) {
        this.verify(false)
          .then(value => {
            if (value) {
              this.resend = true;
              this.timeLeft = '';
              clearInterval(intervalId);
              this.clearTimer();
            }
          });
      }
      if (counter === 0) {
        this.resend = true;
        this.timeLeft = '';
        clearInterval(intervalId);
        this.clearTimer();
      }
    }, 1000);
  }

  logout() {
    this.loginService.fromUrl = 'main';
    this.loginService.gotoLogin();
  }
}
