import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
  standalone: true
})
export class ObjectKeysPipe implements PipeTransform {

  transform(value: any, filterByTrueValue?: boolean): unknown {
    if (filterByTrueValue) {
      return Object.keys(value ?? {}).filter(key => !!value[key]);
    } else {
      return Object.keys(value ?? {});
    }
  }

}
