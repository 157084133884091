import {Component, Injector} from '@angular/core';
import {ILanguageParams} from '../../../../../../../core/constants';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {IValidated, QUESTION_TYPE} from '../../shared/quiz-quiestion-types';
import {isEmpty} from 'lodash';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';


@Component({
  selector: 'app-question-check-choice-v2-editor',
  templateUrl: './question-check-choice-v2-editor.component.html',
  styleUrls: ['./question-check-choice-v2-editor.component.scss']
})
export class QuestionCheckChoiceV2EditorComponent extends AbstractQuizQuestionAnswersEditorComponent  {

  constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.changeUseCorrect(this.question.useCorrectAnswers);
  }

  changeCorrectAnswerCheckBox(event, row) {
    if (this.question.storypoint === QUESTION_TYPE.CHOICE_V2 && this.question.useCorrectAnswers) {
      const checked = event.checked;
      if (checked) {
        for (const r of this.dataSource.data) {
          if (r.correctAnswer && r.id !== row.id) {
            r.correctAnswer = false;
          }
        }
      }
    }
  }

  changeUseCorrect(value) {
    this.displayedColumns = value ? ['correctAnswer', 'answer'] : ['answer'];
  }

  validate(): IValidated {
    if (isEmpty(this.dataSource.data)) {
      return {validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required')};
    }
    if (this.dataSource.data.some(o => isEmptyCaption(o.answer))) {
      return {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
    }
    if (this.question.useCorrectAnswers) {
      if (this.dataSource.data.every(r => !r.correctAnswer)) {
        return {validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.correct.answer.is.required')};
      }
    }
    return {validated: true};
  }

}
