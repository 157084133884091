import {AppUser} from '../../model/AppUser';
import {ExamSection} from '../exam-model/exam-section';
import {ContentContainer} from '../../model/content/ContentContainer';
import {IManager} from '../../model/Event';
import {EXAM_ROLES} from '../exam/exam-roles/exam-roles';

/**
 * Exam collection and documents structure
 *
 * [examId]
 *     // collections
 *    'exam-contents-map' --
 *        [exam-contents-map] - document with short tree of sections and contents
 *                           [sectionId]: {
 *                             [contentId1]: items[{type, ....}]
  *                            [contentId2]: items[{type, ....}]
 *                           }
 *
 *    'exam-users' --
 *         [userId] - documents of exam users
 *                  {email1, .....}
 *                  {email2, .....}
 *
 *    'sections' --
 *         [sectionId] -- documents of sections
 *                   {title1,....} (
 *
 *                   // collections
 *                   'addition-contents' -- [id] - document with additional information data for the section
 *
 *                   'contents' - documents of contents
 *                             [contentId1]: {name, ....}
 *                             [contentId2]: {name, ....}
 *
 *                   'contents-assessments-statistics' --
 *                       [contents-assessments-statistics-document] - document with assessment statistics by section contents
 *                              [contentId1]: {approved: number, reviewed: number, assessed: number, autoAssessed: number}
 *                              [contentId2]: {approved: number, reviewed: number, assessed: number, autoAssessed: number}
 *
 *                   'users-assessments-statistics' --
 *                       [userId] - documents of users assessment statistics by contents
 *                          [contentId1]: {score: number, approved: boolean, reviewed: boolean, assessed: boolean, autoAssessed: boolean}
 *                          [contentId2]: {score: number, approved: boolean, reviewed: boolean, assessed: boolean, autoAssessed: boolean}
 *
 *                  )
 *
 *   'general-assessments-statistics' -- collection with documents of assessment statistics
 *
 *        [sections-assessments-statistics-document] - document of sections assessment statistics
 *           [sectionId1]: {approved: number, reviewed: number, assessed: number, autoAssessed: number}
 *           [sectionId2]: {approved: number, reviewed: number, assessed: number, autoAssessed: number}
 *
 *        [exam-assessments-statistics-document] - document of exam assessment statistics
 *              {approved: number, reviewed: number, assessed: number, autoAssessed: number}
 *
 *   'users-answers-map' --
 *        [userId] - documents with information about what content and what questions the user answered
 *                [contentId]: {
 *                  [quizId]: {
 *                    [questionId1]: boolean
 *                    [questionId1]: boolean
 *                  }
 *                }
 *
 *   'users-assessments' --
 *        [userId] - document of users
 *            {email,.....} (

 *               // collections
 *               'contents-assessments'
 *                   [contentId] - documents of users assessments score by contents by questions
 *
 *               'contents-assessments-history'
 *                   [contentId] - documents of users assessments status and assessments history by content
 *            )
 *
 */

export enum EXAMS_PATHS {
  MAIN = 'exams',
  EXAM_SECTIONS = 'sections',
  EXAM_CONTENTS = 'contents',
  ADDITION_CONTENTS = 'addition-contents',
  EXAM_USERS = 'exam-users',
  USERS_ASSESSMENTS = 'users-assessments',
  CONTENTS_ASSESSMENT = 'contents-assessments',
  CONTENTS_ASSESSMENT_HISTORY = 'contents-assessments-history',
  USERS_ASSESSMENTS_STATISTICS = 'users-assessments-statistics',
  CONTENTS_ASSESSMENTS_STATISTICS = 'contents-assessments-statistics',
  EXAM_GENERAL_ASSESSMENTS_STATISTICS = 'general-assessments-statistics',
  CONTENTS_ASSESSMENTS_STATISTICS_DOCUMENT = 'contents-assessments-statistics-document',
  SECTIONS_ASSESSMENTS_STATISTICS_DOCUMENT = 'sections-assessments-statistics-document',
  EXAM_ASSESSMENTS_STATISTICS_DOCUMENT = 'exam-assessments-statistics-document',
  EXAM_LAUNCH_CODES = 'exam-launch-codes',
  EXAM_LAUNCH_CODES_DOCUMENT = 'exam-launch-codes-document'
}

export type TExamUser = Pick<AppUser, 'userId' | 'email' | 'fullName' | 'picture'>;

export enum SAVE_TO_POSITION {
  ABOVE = 'above',
  BELOW = 'below',
  WITHIN = 'within'
}

export enum EXAM_CONTENT_STATUS {
  APPROVED = 'approved',
  REVIEWED = 'reviewed',
  DONE = 'done',
  DRAFT = 'draft'
}

export enum EXAM_ASSESSMENT_STATUS {
  AUTO_ASSESSED = 'autoAssessed',
  ASSESSED = 'assessed',
  APPROVED = 'approved',
  REVIEWED = 'reviewed',
}

export const EXAM_ASSESSMENT_STATUS_COLOR = {
  [EXAM_ASSESSMENT_STATUS.ASSESSED]: '#FD8E3A',
  [EXAM_ASSESSMENT_STATUS.REVIEWED]: '#49BEFF',
  [EXAM_ASSESSMENT_STATUS.APPROVED]: '#16A34A',
  [EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED]: '#E55E91',
};

export const EXAM_ASSESSMENT_STATUS_ORDER = [
  EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED,
  EXAM_ASSESSMENT_STATUS.ASSESSED,
  EXAM_ASSESSMENT_STATUS.REVIEWED,
  EXAM_ASSESSMENT_STATUS.APPROVED
];

export const EXAM_CONTENT_STATUS_COLOR = {
  [EXAM_CONTENT_STATUS.DONE]: '#FD8E3A',
  [EXAM_CONTENT_STATUS.REVIEWED]: '#49BEFF',
  [EXAM_CONTENT_STATUS.APPROVED]: '#36F289',
  [EXAM_CONTENT_STATUS.DRAFT]: '#868E96',
};

export const EXAM_PROGRESS_STATUS_BACKGROUND_COLOR = {
  [EXAM_CONTENT_STATUS.DONE]: '#462B26',
  [EXAM_CONTENT_STATUS.REVIEWED]: '#2E3852',
  [EXAM_CONTENT_STATUS.APPROVED]: '#1D3F32',
  [EXAM_CONTENT_STATUS.DRAFT]: 'white',
};

export interface IContentMoveAction {
  indexFrom: number;
  indexTo: number;
}

export enum EXAM_CLIPBOARD_TYPE {
  COPY = 'copy',
  CUT = 'cut'
}

export enum SECTION_ANSWERS_STATUS {
  NOT_STARTED = 'not-started',
  STARTED = 'ti-progress-check',
  COMPLETE = 'ti-check'
}

export type TClipboardContent = ExamSection | ContentContainer;

export interface IExamClipboard {
  clipboardType: EXAM_CLIPBOARD_TYPE;
  content: TClipboardContent;
}

export const PERCENT_ROUND_TO = 10;

interface IManagerExt extends IManager {
  roles: EXAM_ROLES[];
}

export interface IExamManagers {
  [userId: string]: IManagerExt;
}

export interface IQuizQuestionAnswerShort {
  [questionId: string]: boolean;
}

export interface IAnswersMapItem {
  [quizId: string]: IQuizQuestionAnswerShort;
}

export interface IExamUserAnswersMap {
  [contentId: string]: IAnswersMapItem;
}


export interface IQuizShort {
  questionId: string;
  type: number;
}

export interface IContainerMapItem {
  id: string;
  type: string;
  questions?: IQuizShort[];
}

export interface IContentContainerMapItem {
  items: IContainerMapItem[];
}

export interface IContentContainerMap {
  [contentId: string]: IContentContainerMapItem;
}

export interface IExamContentsMap {
  [sectionId: string]: IContentContainerMap;
}

export enum EXAM_ASSESSMENT_WORK_MODE {
  OVERVIEW = 'overview',
  ATTENDEE = 'attendee',
  SINGLE_ATTENDEE = 'single-attendee'
}

export const EXAM_ASSESSMENT_ANONYMOUS_DISABLED = 'examAssessmentAnonymousDisabled';
export const EXAM_ASSESSMENT_TAB_SIZE = 'examAssessmentTabSize';

export interface IScoring {
  [containerId: string]: {
    [questionId: string]: number
  };
}

export interface IScoringHistory {
  id: string;
  description: ASSESSMENT_STATUS_DESCRIPTION_TEMPLATE | string;
  inspector: AppUser;
  createTime: number;
  status: string;
  assessmentFrom?: any[];
  assessmentTo?: any[];
}

export const ASSESSMENT_STATUS_DESCRIPTION_TEMPLATE_PREFIX = 'exams.exam.content.assessment.card.change.status.';

export enum ASSESSMENT_STATUS_DESCRIPTION_TEMPLATE {
  CHANGE_STATUS = 'description.template1',
  CHANGE_STATUS_AND_ASSESSMENT = 'description.template2',
}

export interface IExplanation {
  author: TExamUser;
  text: string;
  createTime: number;
}

export interface IAssessmentStatistics {
  score: number;
  autoAssessed: boolean;
  assessed: boolean;
  reviewed: boolean;
  approved: boolean;
  changeTime: boolean;
  explanation: IExplanation;
}

export interface IAssessmentStatisticsByContent {
  [contentId: string]: IAssessmentStatistics;
}

export interface IAssessmentUserStatisticsByContent {
  [userId: string]: IAssessmentStatisticsByContent;
}

export interface IAssessmentContentStatistics {
  autoAssessed: number | any;
  assessed: number | any;
  reviewed: number | any;
  approved: number | any;
}

export interface IAssessmentContentStatisticsMap {
  [contentId: string]: IAssessmentContentStatistics;
}

export enum EXAM_STATUS {
  READY_TO_CREATE = 'ready-to-create',
  CREATING = 'creating',
  CREATION_APPROVED = 'creation-approved',
  READY_TO_RUN = 'ready-to-run',
}

export enum EXAM_STATE {
  ARCHIVED = 'archived',
  CLOSED = 'closed'
}

export enum EXAM_STATUS_GROUPS {
  CREATION = 'creation',
  RUNNING = 'running',
  ASSESSMENT = 'assessment',
  RESULTS = 'results'
}

export const EXAM_STATUS_GROUPS_ITEMS = {
  [EXAM_STATUS_GROUPS.CREATION]: {
    // Exam status field value
    items: [EXAM_STATUS.READY_TO_CREATE, EXAM_STATUS.CREATING, EXAM_STATUS.CREATION_APPROVED],
    name: 'exams.exam.status.group.creation'
  },
  [EXAM_STATUS_GROUPS.RUNNING]: {
    // combined filter by counters field name in Exam class and Exam status field value
    items: [
      'countUsersExamAvailable', 'countUsersExamReadyToStart',
      'countUsersExamRunning', 'countUsersExamPaused',
      'countUsersExamEnded', 'countUsersExamInterrupted', EXAM_STATUS.READY_TO_RUN],
    name: 'exams.exam.status.group.run'
  },
  [EXAM_STATUS_GROUPS.ASSESSMENT]: {
    // counters field name in Exam class
    items: ['countUsersExamAssessment'],
    name: 'exams.exam.status.group.assessment'
  },
  [EXAM_STATUS_GROUPS.RESULTS]: {
    // counters field name in Exam class
    items: ['countUsersExamResults'],
    name: 'exams.exam.status.group.result'
  }
};

export interface IEventLink {
  eventId: string;
  shortLink: string;
  eventName: string;
  sectionId: string;
  sectionTitle: string;
}

export const EXAM_STATUSES_ALLOW_EDIT_CONTENTS = [EXAM_STATUS.READY_TO_CREATE, EXAM_STATUS.CREATING];
export const EXAM_STATUSES_ALLOW_ASSESSING = [EXAM_STATUS.READY_TO_RUN];

export enum USER_EXAM_STATE {
  NOT_STARTED = 'not.started',
  EXAM_AVAILABLE = 'exam.available',
  READY_TO_START = 'ready.to.start',
  STARTED = 'started',
  PAUSED = 'paused',
  ENDED = 'ended',
  ASSESSMENT = 'assessment',
  RESULTS = 'results',
  INTERRUPTED = 'interrupted'
}

export const USER_EXAM_STATE_ICON = {
  [USER_EXAM_STATE.NOT_STARTED]: 'ti-line-dashed',
  [USER_EXAM_STATE.EXAM_AVAILABLE]: 'ti-clipboard-check',
  [USER_EXAM_STATE.READY_TO_START]: 'ti-thumb-up',
  [USER_EXAM_STATE.STARTED]: 'ti-alarm',
  [USER_EXAM_STATE.PAUSED]: 'ti-clock-pause',
  [USER_EXAM_STATE.ENDED]: 'ti-bell-school',
  [USER_EXAM_STATE.ASSESSMENT]: 'ti-clipboard-check',
  [USER_EXAM_STATE.RESULTS]: 'ti-clipboard-list',
  [USER_EXAM_STATE.INTERRUPTED]: 'ti-clock-stop',
};

export enum USER_EXAM_ACTION {
  EXAM_AVAILABLE = 'exam.available',
  READY_TO_START = 'ready.to.start',
  START = 'start',
  END = 'end',
  PAUSE = 'pause',
  RESET = 'reset',
  ASSESSMENT = 'assessment',
  RESULTS = 'results',
  UPDATE_POINTS = 'update_points',
  INCREASE_DURATION = 'increase.duration',
  DELETE_EXAM_USER = 'delete.exam.user',
  INTERRUPT = 'interrupted',
  CHANGE_EXAM_LAUNCH_TYPE = 'change.exam.launch.type'
}

export enum EXAM_MANAGEMENT_MODE {
  RUN = 'run',
  ASSESSMENT = 'assessment',
  RESULTS = 'results'
}

export enum EXAM_ASSESSMENT_MODE {
  SIMPLE = 'simple', // auto assessed, approved
  WORKFLOW = 'workflow', // auto assessed, assessed, approved
  EXTENDED = 'extended' // // auto assessed, assessed, reviewed, approved
}

export const EXAM_ASSESSMENT_MODE_STATUSES = {
  [EXAM_ASSESSMENT_MODE.SIMPLE]: [
    EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED, EXAM_ASSESSMENT_STATUS.APPROVED],
  [EXAM_ASSESSMENT_MODE.WORKFLOW]: [
    EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED, EXAM_ASSESSMENT_STATUS.ASSESSED, EXAM_ASSESSMENT_STATUS.APPROVED],
  [EXAM_ASSESSMENT_MODE.EXTENDED]: [
    EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED, EXAM_ASSESSMENT_STATUS.ASSESSED,
    EXAM_ASSESSMENT_STATUS.REVIEWED, EXAM_ASSESSMENT_STATUS.APPROVED
  ]
};

export enum EXAM_LAUNCH_TYPE {
  SELF_START = 'self.start',
  START_BY_PRESENTER = 'start.by.presenter',
  START_BY_CODE = 'start.by.code'
}
