import {Injectable} from '@angular/core';
import {BehaviorSubject, filter, map, Observable, Subject, takeUntil} from 'rxjs';
import {EventLocation} from '../model/EventLocation';
import {ReferenceApiService} from './reference-api.service';
import {EventType} from '../model/EventType';
import {EventSupportType} from '../model/EventSupportType';
import {MailTemplateApiService} from './mail-template-api.service';
import {MailTemplate} from '../model/MailTemplate';
import {IcsTemplateApiService} from './ics-template-api.service';
import {IcsTemplate} from '../model/IcsTemplate';
import {LoginService} from '../login/login.service';
import {ILoadData, LOAD_STATE} from '../core/constants';
import {Tag} from '@ninescopesoft/core';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  protected _unsubscribeAll: Subject<any> = new Subject();
  private _locations = new BehaviorSubject<ILoadData<EventLocation[]>>({loaded: LOAD_STATE.NOT_LOADED, value: []});
  private _tags = new BehaviorSubject<ILoadData<Tag[]>>({loaded: LOAD_STATE.NOT_LOADED, value: []});
  private _eventTypes = new BehaviorSubject<ILoadData<EventType[]>>({loaded: LOAD_STATE.NOT_LOADED, value: []});

  constructor(
    protected referenceApiService: ReferenceApiService,
    protected mailTemplateApiService: MailTemplateApiService,
    protected icsTemplateApiService: IcsTemplateApiService,
    protected loginService: LoginService
  ) {
    this.loginService.getAuthenticatedUser()
      .subscribe(authUser => {
        if (!authUser) {
          this.destroy();
        }
      });
  }

  destroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._eventTypes.next({loaded: LOAD_STATE.NOT_LOADED, value: []});
    this._locations.next({loaded: LOAD_STATE.NOT_LOADED, value: []});
    this._tags.next({loaded: LOAD_STATE.NOT_LOADED, value: []});
  }

  initLocations() {
    if (this._locations.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._locations.getValue().loaded = LOAD_STATE.LOADING;
      this.referenceApiService.getLocations()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(locations => {
          this._locations.next({loaded: LOAD_STATE.LOADED, value: locations});
        });
    }
  }

  /* locations */
  get locations$(): Observable<EventLocation[]> {
    this.initLocations();
    return this._locations.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  /* Available after manual call initLocations() or first request locations$*/
  get locations(): EventLocation[] {
    return this._locations.getValue().value;
  }

  addLocation(location: EventLocation) {
    return this.referenceApiService.addLocation(location);
  }

  saveLocation(location: EventLocation) {
    return this.referenceApiService.saveLocation(location);
  }

  deleteLocation(key: string) {
    return this.referenceApiService.deleteLocation(key);
  }

  /* tags */
  initTags() {
    if (this._tags.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._tags.getValue().loaded = LOAD_STATE.LOADING;
      this.referenceApiService.getTags()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(tags => {
          this._tags.next({loaded: LOAD_STATE.LOADED, value: tags});
        });
    }
  }

  get tags$(): Observable<Tag[]> {
    this.initTags();
    return this._tags.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  /* Available after manual call initTags() or first request tags$*/
  get tags(): Tag[] {
    return this._tags.getValue().value;
  }

  addTag(tag: Tag) {
    return this.referenceApiService.addTag(tag);
  }

  saveTag(tag: Tag) {
    return this.referenceApiService.saveTag(tag);
  }

  deleteTag(key: string) {
    return this.referenceApiService.deleteTag(key);
  }

  /* event support types */
  getSupportTypes(): Observable<EventSupportType[]> {
    return this.referenceApiService.getSupportTypes();
  }

  addSupportType(eventSupportType: EventSupportType) {
    return this.referenceApiService.addSupportType(eventSupportType);
  }

  saveSupportType(eventSupportType: EventSupportType) {
    return this.referenceApiService.saveSupportType(eventSupportType);
  }

  deleteSupportType(key: string) {
    return this.referenceApiService.deleteSupportType(key);
  }

  /* mail templates */
  getEmailDefaultTemplates(): Observable<MailTemplate[]> {
    return this.mailTemplateApiService.getDefaultTemplates();
  }

  getEmailTemplates(eventId: string): Observable<MailTemplate[]> {
    return this.mailTemplateApiService.getTemplates(eventId);
  }

  getEmailTemplatesBySection(eventId: string, sectionId: string): Observable<MailTemplate[]> {
    return this.mailTemplateApiService.getTemplatesBySection(eventId, sectionId);
  }

  addEmailTemplate(eventId: string, template: MailTemplate) {
    return this.mailTemplateApiService.addEmailTemplate(eventId, template);
  }

  saveEmailTemplate(eventId: string, template: MailTemplate) {
    return this.mailTemplateApiService.saveEmailTemplate(eventId, template);
  }

  deleteEmailTemplate(eventId: string, key: string) {
    return this.mailTemplateApiService.deleteEmailTemplate(eventId, key);
  }

  /* ics templates */
  getICSDefaultTemplates(): Observable<IcsTemplate> {
    return this.icsTemplateApiService.getDefault();
  }

  getICSTemplatesBySection(eventId: string, sectionId: string): Observable<IcsTemplate[]> {
    return this.icsTemplateApiService.get(eventId, sectionId);
  }

  saveICSTemplate(eventId: string, template: IcsTemplate): Promise<any> {
    return this.icsTemplateApiService.save(eventId, template);
  }

  deleteICSTemplate(eventId: string, key: string) {
    return this.icsTemplateApiService.delete(eventId, key);
  }

  testICSTemplate(eventId: string, template: IcsTemplate): Promise<any> {
    return this.icsTemplateApiService.test(eventId, template);
  }

}
