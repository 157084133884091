import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DOCUMENT} from '@angular/common';
import {FONT_SIZE_VALUES} from '../text-editor-config/config';
import {ToasterService} from '../../../core/toaster.service';
import {TranslateApiService} from '../../../services/translate-api.service';
import {HOTSPOT_DATA_ATTRIBUTES, ILanguageParams, TEXT_MARK_TAGS} from '../../../core/constants';
import {CommonService} from '../../../core/common.service';
import * as DOMPurify from 'dompurify';
import {UtilsService} from '../../../core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class TextEditorService {

  savedSelection: Range | null;
  selectedText: string;
  selectedHTML: string;

  constructor(private toasterService: ToasterService,
              private translateApiService: TranslateApiService,
              private common: CommonService,
              @Inject(DOCUMENT) private _document: any) {
  }

  /**
   * Executed command from editor header buttons exclude toggleEditorMode
   * @param command string from triggerCommand
   */
  executeCommand(command: string) {
    const commands = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre'];
    if (commands.includes(command)) {
      this._document.execCommand('formatBlock', false, command);
    }

    this._document.execCommand(command, false, null);
  }

  /**
   * Create URL link
   * @param url string from UI prompt
   * @param node
   */
  createLink(url: string, node) {
    if (node) {
      this.selectedText = node['textContent'];
      node.remove();
    }
    if (!url.includes('http')) {
      this._document.execCommand('createlink', false, url);
    } else {
      const target = !url.includes(window.location.host) ? `target="_blank"` : '';
      const newUrl = `<a href="${url}" ${target}>${this.selectedText ? this.selectedText : url}</a>`;
      this.insertHtml(newUrl);
    }
  }

  parseTextLinks(text, count?: (value: number) => void): string {
    let ind = 0;
    let replaceCount = 0;
    const result = (text || '')
      .replace(/(&amp;)/gmi, '&')
      .replace(/(&nbsp;)/gmi, ' ')
      .replace(/([^\S]|^)(http[s]?:\/\/(www\.)?|(ftp:\/\/)(www\.)?|www\.)([0-9A-Za-z-.@:%_+~#=\/?&]+)/gmi,
        (match) => {
        replaceCount++;
        ind++;
        const url = match.trim();
        const target = !url.includes(window.location.host) ? ` target="_blank"` : '';
        const newUrl = `<a href="${url}"${target}>${url}</a>`;
        return ' ' + newUrl + ' ';
      }
    );
    if (typeof count === 'function') {
      count(replaceCount);
    }
    return result;
  }

  /**
   * insert color either font or background
   *
   * @param color color to be inserted
   * @param where where the color has to be inserted either text/background
   */
  insertColor(color: string, where: string): void {
    const restored = this.restoreSelection();
    if (restored) {
      if (where === 'textColor') {
        this._document.execCommand('foreColor', false, color);
      } else {
        this._document.execCommand('hiliteColor', false, color);
      }
    }
  }

  /**
   * Set font name
   * @param fontName string
   */
  setFontName(fontName: string) {
    this._document.execCommand('fontName', false, fontName);
  }

  private clearElementsFontSize(element: Element) {
    const clear = (elem: Element) => {
      for (let i = 0; i < elem.children.length; i++) {
        const el = elem.children.item(i);
        // el.removeAttribute('style');
        (el as HTMLElement).style.fontSize = '';
        clear(el);
      }
    };
    clear(element);
  }

  getSelectionHtml() {
    let html = '';
    if (typeof window.getSelection !== 'undefined') {
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const container = document.createElement('span');
        for (let i = 0, len = sel.rangeCount; i < len; ++i) {
          container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        this.clearElementsFontSize(container);
        html = container.outerHTML;
      }
    } else if (typeof document.getSelection() !== 'undefined') {
      if (document.getSelection().type === 'Text') {
        html = document.getSelection().toString();
      }
    }
    return html;
  }

  setFontSize(fontCode: string, textDocument: HTMLElement) {
    if (this.selectedText) {
      const selectedInnerHtml = this.selectedHTML.replace(/^<span>/, '').replace(/<\/span>$/, '');
      const element = document.createElement('font');
      element.innerHTML = selectedInnerHtml;
      element.setAttribute('style', `font-size: ${FONT_SIZE_VALUES[fontCode]}`);
      this.pasteHtmlAtCaret(element.outerHTML);
      textDocument.dispatchEvent(new Event('input', {bubbles: true}));
    }
  }

  markSelectedTextAsHotspot(audioId: string, containerId: string, textAnnotationId: string, videoAnnotationId: string,
                            audioOptions: any, animationOptions: any, orderNumber: string, text: string,
                            textDocument: HTMLElement, restoreSelection = false) {
    const selectedHTML = this.selectedText !== text ? text : this.selectedText;
    if (restoreSelection) {
      this.restoreSelection();
    }
    if (!this.selectedText && !text) {
      return;
    }
    const selectedElement = document.createElement('div');
    selectedElement.innerHTML = selectedHTML;
    const annotationList = selectedElement.getElementsByTagName(TEXT_MARK_TAGS.HOTSPOT);
    let element: HTMLElement;
    if (annotationList.length) {
      element = Array.from(annotationList)[0] as HTMLElement;
    } else {
      element = document.createElement(TEXT_MARK_TAGS.HOTSPOT);
      element.innerHTML = selectedHTML.replace(/^<span>/, '').replace(/<\/span>$/, '');
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.ELEMENT_ID}`, UtilsService.createId(5));
    }
    if (audioId) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_ID}`, audioId);
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_ID}`);
    }
    if (audioOptions) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_LOOP}`, audioOptions.loop);
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_SPEED}`, audioOptions.speed);
      if (audioOptions.startTime) {
        element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_START_TIME}`, audioOptions.startTime);
      } else {
        element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_START_TIME}`);
      }
      if (audioOptions.endTime) {
        element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_END_TIME}`, audioOptions.endTime);
      } else {
        element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_END_TIME}`);
      }
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_SPEED}`);
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_SPEED}`);
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_START_TIME}`);
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.AUDIO_OPTIONS_END_TIME}`);
    }
    if (containerId) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.CONTAINER_ID}`, containerId);
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.CONTAINER_ID}`);
    }
    if (textAnnotationId !== null && textAnnotationId !== undefined) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.TEXT_ANNOTATION_ID}`, textAnnotationId);
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.TEXT_ANNOTATION_ID}`);
    }
    if (videoAnnotationId !== null && videoAnnotationId !== undefined) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_ANNOTATION_ID}`, videoAnnotationId);
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_ANNOTATION_ID}`);
    }
    if (orderNumber !== null && orderNumber !== undefined) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.ORDER_NUMBER}`, orderNumber);
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.ORDER_NUMBER}`);
    }
    if (animationOptions) {
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_LOOP}`, animationOptions.loop);
      element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_SPEED}`, animationOptions.speed);
      if (animationOptions.startTime) {
        element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_START_TIME}`, animationOptions.startTime);
      } else {
        element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_START_TIME}`);
      }
      if (animationOptions.endTime) {
        element.setAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_END_TIME}`, animationOptions.endTime);
      } else {
        element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_END_TIME}`);
      }
    } else {
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_LOOP}`);
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_SPEED}`);
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_START_TIME}`);
      element.removeAttribute(`data-${HOTSPOT_DATA_ATTRIBUTES.VIDEO_OPTIONS_END_TIME}`);
    }
    element.setAttribute('contenteditable', 'false');
    this.pasteHtmlAtCaret(` ${element.outerHTML} `);
    textDocument.dispatchEvent(new Event('input', {bubbles: true}));
  }

  private clearHotspotItemAttributes(markItem: HTMLElement) {
    Object.values(HOTSPOT_DATA_ATTRIBUTES).forEach(attr => markItem.removeAttribute(`data-${attr}`));
    markItem.removeAttribute('contenteditable');
  }

  clearAnnotationMarkByContainerId(containerId: string, textDocument: HTMLElement) {
    let documentInnerHTML = textDocument.innerHTML;
    const markList = textDocument.getElementsByTagName(TEXT_MARK_TAGS.HOTSPOT);
    for (const markItem of Array.from(markList) as HTMLElement[]) {
      const dataContainerId = markItem.dataset.container_id;
      if (dataContainerId && containerId === dataContainerId) {
        const replaceHTML = markItem.outerHTML;
        this.clearHotspotItemAttributes(markItem);
        documentInnerHTML = documentInnerHTML.replace(replaceHTML, markItem.innerHTML);
      }
    }
    textDocument.innerHTML = documentInnerHTML;
    textDocument.dispatchEvent(new Event('input', {bubbles: true}));
    return textDocument.innerHTML;
  }

  clearSelectedHotspots(textDocument: HTMLElement, clearAll = false) {
    const element = document.createElement('span');
    element.innerHTML = !clearAll ? this.selectedHTML : textDocument.innerHTML;
    const markList = element.getElementsByTagName(TEXT_MARK_TAGS.HOTSPOT);
    let documentInnerHTML = textDocument.innerHTML;
    for (const markItem of Array.from(markList) as HTMLElement[]) {
      const replaceHTML = markItem.outerHTML;
      this.clearHotspotItemAttributes(markItem);
      documentInnerHTML = documentInnerHTML.replace(replaceHTML, markItem.innerHTML);
    }
    textDocument.innerHTML = documentInnerHTML;
    textDocument.dispatchEvent(new Event('input', {bubbles: true}));
  }

  pasteHtmlAtCaret(html) {
    let sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        const el = document.createElement('div');
        el.innerHTML = html;
        const frag = document.createDocumentFragment();
        let node;
        let lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    }
  }

  /**
   * Create raw HTML
   * @param html HTML string
   */
  private insertHtml(html: string): void {

    const isHTMLInserted = this._document.execCommand('insertHTML', false, html);

    if (!isHTMLInserted) {
      this.toasterService.pop('warning', 'Input field is not selected or incorrect data is inserted');
    }
  }

  public insertText(html: string): void {
    const isHTMLInserted = this._document.execCommand('insertText', false, html);

    if (!isHTMLInserted) {
      this.toasterService.pop('warning', 'Input field is not selected or incorrect data is inserted');
    }
  }

  /**
   * save selection when the editor is focussed out
   */
  saveSelection(): any {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        this.savedSelection = sel.getRangeAt(0);
        this.selectedText = sel.toString();
        this.selectedHTML = this.getSelectionHtml();
      }
    } else if (this._document.getSelection && this._document.createRange) {
      this.savedSelection = document.createRange();
    } else {
      this.savedSelection = null;
    }
  }

  /**
   * restore selection when the editor is focussed in
   *
   * saved selection when the editor is focussed out
   */
  restoreSelection(): boolean {
    if (this.savedSelection) {
      if (window.getSelection) {
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(this.savedSelection);
        return true;
      } else if (this._document.getSelection /*&& this.savedSelection.select*/) {
        // this.savedSelection.select();
        return true;
      }
    } else {
      return false;
    }
  }

  setDefaultParagraphSeparator(separator: string) {
    this._document.execCommand('defaultParagraphSeparator', false, separator);
  }


  getTranslateHint(languageParams: ILanguageParams) {
    return this.translateApiService.getTranslateHint(languageParams.defaultLanguage, languageParams.currentLanguage);
  }

  getRestoreTranslateHint(languageParams: ILanguageParams) {
    return this.translateApiService.getRestoreTranslateHint(languageParams.defaultLanguage);
  }

  /**
   * translate selected text. if none selected - translate all document text.
   * @param textDocument
   * @param languageParams
   */
  translateSelectedText(textDocument: HTMLElement, languageParams: ILanguageParams) {
    const text = this.savedSelection?.toString() ? this.getSelectionHtml() : textDocument.innerHTML;
    return this.translateApiService.translateHTML(text, languageParams.defaultLanguage, languageParams.currentLanguage)
      .then(t => {
        if (this.savedSelection?.toString()) {
          this.insertHtml(this.common.utils.sanitizeTolerant(t, true));
        } else {
          textDocument.innerHTML = this.common.utils.sanitizeTolerant(t, true);
        }
        textDocument.dispatchEvent(new Event('input', {bubbles: true}));
      });
  }

  /**
   * remove tag span from HTML text.
   * <p> text editor not use tag span.
   * <p>tag span can be randomly added by execCommand.
   * <p>reproduce only on Chrome browsers.
   */
  sanitizeText(text: string) {
    DOMPurify.addHook('beforeSanitizeAttributes', (currentNode, hookEvent, config) => {
      switch (currentNode?.tagName?.toUpperCase()) {
        case 'B':
        case 'I':
        case 'U':
          currentNode.removeAttribute('style');
          return currentNode;
        default:
          return currentNode;
      }
    });
    const sanitizedText = !text ? '' : DOMPurify.sanitize(text, {
      ADD_TAGS: [TEXT_MARK_TAGS.HOTSPOT],
      ADD_ATTR: ['contenteditable'],
      FORBID_TAGS: ['span']});
    DOMPurify.removeHook('beforeSanitizeAttributes');
    return sanitizedText;
  }

  /**
   * return html1 == html2;
   * @param html1
   * @param html2
   */
  compareHTML(html1: string, html2: string) {
    if (!html1 && !html2) {
      return true;
    } else if ((!html1 && html2) || (html1 && !html2)) {
      return false;
    } else {
      const sorted1 = [...html1.replace(/\n/g, '').replace(/\r/g, '').replace(/ /g, '')].sort((a, b) => a.localeCompare(b)).join('');
      const sorted2 = [...html2.replace(/\n/g, '').replace(/\r/g, '').replace(/ /g, '')].sort((a, b) => a.localeCompare(b)).join('');
      return sorted1 === sorted2;
    }
  }

  selectElement(element: HTMLElement) {
    if (document.createRange) {
      const rng = document.createRange();
      rng.selectNode(element);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(rng);
      this.saveSelection();
    }
  }
}

export class TextCaretPosition {
  private readonly isContentEditable;
  private readonly target;
  private position = 0;

  constructor(target) {
    this.isContentEditable = target && target.contentEditable;
    this.target = target;
  }

  savePosition() {
    if (this.isContentEditable) {
      const selection = window.getSelection();
      if (selection.rangeCount) {
        const range = window.getSelection().getRangeAt(0);
        const prefix = range.cloneRange();
        prefix.selectNodeContents(this.target);
        prefix.setEnd(range.endContainer, range.endOffset);
        this.position = prefix.toString().length;
      }
    }
  }

  restorePosition() {
    const setCaret = (pos, parent) => {
      for (const node of parent.childNodes) {
        if (node.nodeType === Node.TEXT_NODE) {
          if (node.length >= pos) {
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(node, pos);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return -1;
          } else {
            pos = pos - node.length;
          }
        } else {
          pos = setCaret(pos, node);
          if (pos < 0) {
            return pos;
          }
        }
      }
      return pos;
    };
    setCaret(this.position, this.target);
  }
}
