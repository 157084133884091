import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TimeSelectDialogComponent} from './time-select-dialog/time-select-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnDestroy {

  protected _unsubscribeAll: Subject<any> = new Subject();

  @Input() set value(value: Date | number) {
    let val = value;
    if (value && value instanceof Date) {
      val = value.getTime();
    }
    this.setTimeValue(val);
  }
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() forcedValue: Subject<{value}>;

  @Output() change = new EventEmitter<number>();

  hours = 0;
  minutes = 0;
  dateTime: number;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    if (this.forcedValue) {
      this.forcedValue.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
        this.setTimeValue(value ? value.value : value);
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  setTimeValue(value) {
    if (value) {
      this.dateTime = value;
      const cd = new Date(this.dateTime);
      this.hours = cd.getHours();
      this.minutes = cd.getMinutes();
    } else {
      this.hours = 0;
      this.minutes = 0;
      this.dateTime = null;
    }
  }

  showTimePicker() {
    const dialogRef = this.dialog.open(TimeSelectDialogComponent, {
      width: '280px',
      data: {
        time: this.dateTime
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setTimeValue(result.time);
        this.change.emit(result.time);
      }
    });
  }
}
