import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

export interface ILogRecord {
  time: number;
  collection: string;
  reads: number;
}

@Injectable()
export class LoggerService {

  dbLog: ILogRecord[] = [];

  enabled = true;
  noop = () => {};

  constructor() {
    // You can do a check on your environment or some other flag to
    // enable/disable logging
    // if ((<any>'<%= ENV %>') === 'prod') this.enabled = false;
    if (environment.production) {
      this.enabled = false;
    }
  }

  get debug() {
    if (this.enabled) {return console.debug.bind(console); }
    return this.noop;
  }

  get error() {
    return console.error.bind(console);
  }

  get log() {
    if (this.enabled) {return console.log.bind(console); }
    return this.noop;
  }

  get info() {
    if (this.enabled) {return console.info.bind(console); }
    return this.noop;
  }

  get warn() {
    if (this.enabled) {return console.warn.bind(console); }
    return this.noop;
  }

  get success() {
    if (this.enabled) {return console.info.bind(console); }
    return this.noop;
  }

  public comparator(field, direction?: 'asc' | 'desc') {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field] < b[field]) {return -1; }
        if (a[field] > b[field]) {return 1; }
      } else if (direction === 'desc') {
        if (a[field] > b[field]) {return -1; }
        if (a[field] < b[field]) {return 1; }
      }
      return 0;
    };
  }

  clearDBLog() {
    this.dbLog = [];
  }

  printDBLog() {
    const dbLog = this.dbLog.concat([]);
    let counter = 0;
    let totalReads = 0;
    const collections: string[] = [];
    const duplicates: string[] = [];
    const duplicatesResult: any[] = [];
    for (const iLogRecord of dbLog) {
      counter++;
      console.log(`Record ${counter} of ${dbLog.length}`, iLogRecord);
      totalReads += iLogRecord.reads;
      if (!collections.includes(iLogRecord.collection)) {
        collections.push(iLogRecord.collection);
      } else {
        duplicates.push(iLogRecord.collection);
        duplicatesResult.push(iLogRecord.collection + '-' + iLogRecord.reads);
      }
    }
    console.log(`Total reads: ${totalReads}`);
    duplicates.sort();
    duplicatesResult.sort();
    console.log(`Duplicates`, duplicates);
    console.log(`duplicatesResult`, duplicatesResult);
    dbLog.sort(this.comparator('reads', 'desc'));
    for (const iLogRecord of dbLog) {
      let count = 1;
      const filter = duplicates.filter(it => it === iLogRecord.collection);
      if (filter && filter.length) {
        count = filter.length + 1;
      }
      console.log(`${iLogRecord.collection}(${count}) - ${iLogRecord.reads}`);
    }
  }
}
