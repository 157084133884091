import {ServiceTasksComponent} from '../service-tasks.component';
import {QueryFn} from '@angular/fire/compat/firestore';
import {firstValueFrom} from 'rxjs';
import {isEmpty} from 'lodash';

export const informationAboutModulesLostRecordingSnippets = async (thiz: ServiceTasksComponent) => {
  const request = (collection: string, size: number, startAt) => {
    let queryFn: QueryFn = q => q.where('contentId', '==', 'new').limit(size);
    if (startAt) {
      queryFn = q => q.where('contentId', '==', 'new').limit(size).startAfter(startAt);
    }
    return thiz.afs.collectionGroup(collection, queryFn).get();
  };

  if (!await thiz.commonService.confirmation(
    'Run: Information about module with lost recording snippets')) {
    return;
  }

  console.log('Start process');
  const taskLog = {};
  const chunkSize = 500;

  console.log('query contents');
  let contentsCount = 0;
  let contents_ = await firstValueFrom(request('timestamps', chunkSize, null));
  while (contents_.docs.length > 0) {
    contentsCount += contents_.docs.length;
    console.log('loaded', contentsCount);
    for (const doc of contents_.docs) {
      const snp = doc.data();
      if (snp.contentId === 'new') {
        const moduleId = snp.gcpPath.split('/')[2];
        if (isEmpty(taskLog[moduleId])) {
          taskLog[moduleId] = [];
        }
        console.log('info: ', doc.ref.path);
        taskLog[moduleId].push({content_path: doc.ref.path});
      }
    }
    contents_ = await firstValueFrom(request('timestamps', chunkSize, contents_.docs[contents_.docs.length - 1]));
  }
  console.log('~~~~~~~~ task result ~~~~~~~~');
  console.log(taskLog);
};
