import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {CommonService} from '../../../core/common.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})

export class DateTimePickerComponent implements OnInit, OnDestroy {

  private _dateValue: Date = null;
  private _hours = 0;
  private _minutes = 0;
  protected _unsubscribeAll: Subject<any> = new Subject();

  @Input() set value(value: Date | number) {
    this.setValue(value);
  }
  @Input() placeholder;
  @Input() floatLabel;
  @Input() disabled;
  @Input() alwaysShowClear;
  @Input() hideClearButton;
  @Input() buttonPosition: 'before' | 'after' = 'after';
  @Input() forcedValue: Subject<{value}>;
  @Input() errorText;
  @Input() hideTime;
  @Input() hideDate;
  @Input() matDatepickerFilter;
  @Input() required = false;
  @Input() slim = false;
  @Input() extendedFormantDate = false;
  @Input() hideUnderline = false;
  @Input() textAlign: 'left' | 'right' | 'center';
  @Output() change = new EventEmitter<number>();

  @ViewChild('inputDateElement') inputDateElement;

  constructor(private common: CommonService) {}

  ngOnInit() {
    if (this.forcedValue) {
      this.forcedValue.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
        this.setValue(value ? value.value : value);
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  get dateValue(): Date {
    if (this.inputDateElement && !this._dateValue) {
      this.inputDateElement.nativeElement.value = '';
    } else {
      if (this.extendedFormantDate && this.inputDateElement) {
        this.inputDateElement.nativeElement.value = this.formatDate(this._dateValue);
      }
    }
    return this._dateValue;
  }

  set dateValue(value: Date) {
    this._dateValue = value;
    if (!value) {
      this._hours = 0;
      this._minutes = 0;
    }
    this._dateValue = value ? new Date(value.getFullYear(), value.getMonth(), value.getDate(), this._hours, this._minutes) : null;
    this.change.emit(this._dateValue ? this._dateValue.getTime() : null);
  }

  changeTime(value: number) {
    this._dateValue = value ? new Date(value) : null;
    this._hours = this._dateValue ? this._dateValue.getHours() : 0;
    this._minutes = this._dateValue ? this._dateValue.getMinutes() : 0;
    this.change.emit(value);
  }

  setValue(value: Date | number) {
    if (typeof value === 'number') {
      this._dateValue = new Date(value);
    } else {
      this._dateValue = value;
    }
    if (this._dateValue) {
      this._hours = this._dateValue.getHours();
      this._minutes = this._dateValue.getMinutes();
    } else {
      this._hours = 0;
      this._minutes = 0;
    }
  }

  private formatDate(value): string {
    if (!value || value.length === 0) {
      return '';
    }
    const datePipe = new DatePipe('en-US');
    return this.common.utils.langMonthsReplacer(datePipe.transform(new Date(value), 'dd - MMMM - y'));
  }

}
