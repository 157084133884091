import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as ui from '../../actions/ui';
import {DomSanitizer} from '@angular/platform-browser';
import {AdvancedSettings} from '../../model/event-mode/InstantSettings';
import {Event} from '../../model/Event';
import {UsersDataService} from '../../services/users-data.service';
import {UtilsService} from '../../core/utils.service';
import {LoginService} from '../../login/login.service';
import {CommonService} from '../../core/common.service';
import {Constants} from '../../core/constants';

@Component({
  selector: 'app-main-menu-files-dialog',
  templateUrl: './main-menu-files-dialog.component.html',
  styleUrls: ['./main-menu-files-dialog.component.scss']
})
export class MainMenuFilesDialogComponent implements OnInit, OnDestroy {

  frameUrl;
  advancedSettings: AdvancedSettings;
  currentEvent: Event;
  loadingCounter = 0;
  needSignOut = false;
  isHKVBS = false;

  constructor(public dialogRef: MatDialogRef<MainMenuFilesDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , private store: Store<fromRoot.State>
    , private commonService: CommonService
    , private userDataService: UsersDataService
    , private loginService: LoginService
    , private utils: UtilsService
    , private sanitizer: DomSanitizer) {
    this.currentEvent = data.currentEvent;
    this.advancedSettings = data.instantSettings.advancedSettings;
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    if (this.advancedSettings.useAuthenticationCode) {
      this.isHKVBS = this.loginService.getCurrentProviderId() === Constants.PROVIDER_HKVBS_AZURE ||
                     this.loginService.getCurrentProviderId() === Constants.PROVIDER_HKVBS_MICROSOFT;
      if (this.isHKVBS) {
        const url = !this.advancedSettings.linkToFileStore ?
          this.utils.getEnv()['linkToFileStore'] : this.advancedSettings.linkToFileStore;
        this.loginService.getToken().then(token => {
          if (!token) {
            this.userDataService.getAzureIdToken().then(_token => {
              if (_token && this.currentEvent) {
                const _class = this.currentEvent.name.split(' ')[0];
                this.frameUrl = this.getURL(`${url}&class=${_class}&token=${_token}`);
              } else if (!_token && this.currentEvent) {
                this.needSignOut = true;
              }
            });
          } else {
            const _class = this.currentEvent.name.split(' ')[0];
            this.frameUrl = this.getURL(`${url}&class=${_class}&token=${token}`);
          }
        });
      } else {
        this.needSignOut = true;
      }
    } else {
      const url = !this.advancedSettings.linkToFileStore ? this.utils.getEnv()['linkToFileStore'] : this.advancedSettings.linkToFileStore;
      this.frameUrl = this.getURL(url);
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  load() {
    this.loadingCounter++;
    this.commonService.showProgress.next(true);
    if (this.loadingCounter >= 2) {
      this.commonService.showProgress.next(false);
    }
  }

  getURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  errorOnLoad() {
    this.commonService.showProgress.next(false);
  }

  signOut() {
    this.dialogRef.close();
    this.loginService.signOut(true);
  }
}
