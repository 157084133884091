import {FirestoreReference} from './FirestoreReference';

export class EventSupportType extends FirestoreReference {
  public static readonly DB_PATH = 'support_types';

  caption: string;
  deleted = 0;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }
    if (obj.caption) {
      this.caption = obj.caption;
    }
    if (obj.deleted) {
      this.deleted = obj.deleted;
    }
  }
}
