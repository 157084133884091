import {StdComponent} from '../../../../../../../core/std-component';
import {Constants, DATE_FORMAT, ILanguageParams} from '../../../../../../../core/constants';
import {CommonService} from '../../../../../../../core/common.service';
import {TranslateApiService} from '../../../../../../../services/translate-api.service';
import {Component, effect, ElementRef, EventEmitter, Injector, OnInit, Signal, TemplateRef, WritableSignal} from '@angular/core';
import {IQuizContentAnswersExtended, Quiz} from '../../../quiz-model/quiz';
import {setQuizElementProperties} from '../../../quiz-question-sheet/quiz-question-sheet-lib';
import {cloneDeep, isEmpty} from 'lodash';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {IDocumentPathParams} from '../../../../../../../services/event-mode-api.service';
import {AnswersQuestion, IQContentAnswersLastChange} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {TUser} from '../../../../../shared/container-interface';
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, Subject} from 'rxjs';
import {EventsDataService} from '../../../../../../../services/events-data.service';
import {QUESTION_TYPE, QUESTION_TYPES_COMPONENTS} from '../quiz-quiestion-types';
import {UtilsService} from '../../../../../../../core/utils.service';
import {QuizService} from '../../../quiz.service';
import {APP_MODE} from '../../../../../../../login/login.service';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-abstract-quiz-question-participant',
  template: ''
})
export abstract class AbstractQuizQuestionParticipantComponent extends StdComponent implements OnInit {
  readonly Constants = Constants;
  readonly QUESTION_TYPE = QUESTION_TYPE;

  protected common: CommonService;
  protected translateApiService: TranslateApiService;
  protected dataService: EventsDataService;
  protected quizService: QuizService;

  // required input variables. initialize order has the meaning.
  editorMode: boolean;
  currentUserId: string;
  qKey: string;
  currentQuestionId: Signal<string>;
  acceptAnswers: boolean;
  anonymousAnswers: boolean;
  documentPathParams: IDocumentPathParams;
  qContentAnswersLastChange: IQContentAnswersLastChange;
  currentUserProperties: TUser;
  preliminaryAnswers$ = new Subject<any[]>();
  contentLocation: APP_MODE;
  // changes when the user has checked the answers manually.
  // resets when the user starts changing the answers.
  set questionsDirectFeedbackChecked(value: boolean) {
    this.questionsDirectFeedbackChecked$.next(value);
  }
  get questionsDirectFeedbackChecked() {
    return this.questionsDirectFeedbackChecked$.getValue();
  }
  // called by the user when he starts answering again and resets all answers.
  // used if enabled question options tryAgain and directFeedback
  // subject param is questionId.
  resetQuestionAnswers$: Subject<string>;
  // // signal that the answers were read from the database.
  userQuestionsAnswersLoaded: WritableSignal<boolean>;

  set quiz(value: Quiz) {
    this.quiz$.next(value);
    if (value) {
      setQuizElementProperties(value, this.elementRef);
    }
  }
  set question(value: EventQuestion) {
    this.question$.next(value);
  }
  set answersByQuestions(value: IQuizContentAnswersExtended) {
    this.answersByQuestions$.next(value);
  }
  set languageParams(value: ILanguageParams) {
    this.languageParams$.next(value);
  }
  outputCustomToolbarTemplate = new EventEmitter<TemplateRef<any>>();

  // local variables
  quiz$ = new BehaviorSubject<Quiz>(null);
  protected question$ = new BehaviorSubject<EventQuestion>(null);
  protected languageParams$ = new BehaviorSubject<ILanguageParams>(null);
  protected languageParams$$ = toSignal(this.languageParams$);
  private answersByQuestions$ = new BehaviorSubject<IQuizContentAnswersExtended>(null);
  private prevAnswers: any;
  private errorOnSendAnswer = false;
  answers$ = new BehaviorSubject<any>(null);
  lastChange: string;
  properties: any;
  sendTime = 0;
  submit = new BehaviorSubject<boolean>(false);
  questionsDirectFeedbackChecked$ = new BehaviorSubject<boolean>(false);

  protected answerChange$ = new BehaviorSubject<AnswersQuestion>(null);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector);
    this.common = injector.get(CommonService);
    this.translateApiService = injector.get(TranslateApiService);
    this.dataService = injector.get(EventsDataService);
    this.quizService = injector.get(QuizService);

    effect(() => {
      const receiveSendTime = (val: IQuizContentAnswersExtended) =>
        !isEmpty(val?.sendTime) ? (val.sendTime[this.qKey]?.[this.currentUserId] ?? 0) : 0;

      const receiveLastChange = (val: IQuizContentAnswersExtended) =>
        val?.lastChange ? this.common.utils.formatDate(val.lastChange[this.qKey]?.[this.currentUserId], DATE_FORMAT.DD_MM_YYYY_HH_mm) : '';

      const receiveProperties = (val: IQuizContentAnswersExtended) => val?.properties?.[this.qKey]?.[this.currentUserId];

      if (this.userQuestionsAnswersLoaded()) {
        this.answersByQuestions$.pipe(
          filter(v => !receiveSendTime(v) || !(receiveSendTime(v) < this.sendTime)), // don't accept by time old answers
          this.takeUntilAlive())
          .subscribe(value => {
            // emit only first value on init component or new answers (e.g. sent from other device)
            if (!receiveSendTime(value) || !this.sendTime || receiveSendTime(value) > this.sendTime) {
              this.answers$.next(!isEmpty(value?.answers) ?
                (!isEmpty(value.answers[this.qKey]?.[this.currentUserId]) ? value.answers[this.qKey][this.currentUserId] : null) : null);
            }
            this.lastChange = receiveLastChange(value);
            this.properties = receiveProperties(value);
            this.onReceiveQuestionAnswers();
          });
      }
    });
  }

  get quiz(): Quiz {
    return this.quiz$.getValue();
  }

  get question(): EventQuestion {
    return this.question$.getValue();
  }

  get languageParams(): ILanguageParams {
    return this.languageParams$.getValue();
  }

  get answers() {
    return cloneDeep(this.answers$.getValue() ?? []);
  }

  set answers(value: any) {
    if (!this.errorOnSendAnswer) {
      this.prevAnswers = cloneDeep(this.answers$.getValue() ?? []);
    }
    this.answers$.next(value);
  }

  protected onInit() {}

  protected initQuestionAnswersDataSource() {}

  protected onReceiveQuestionAnswers() {}

  ngOnInit() {
    this.onInit();
    combineLatest([this.question$, this.languageParams$]).pipe(this.takeUntilAlive())
      .subscribe(([question, languageParams]) => {
        if (question && languageParams) {
          this.initQuestionAnswersDataSource();
        }
    });
    if (!this.editorMode) {
      combineLatest([this.answerChange$, this.submit])
        .pipe(
          // emit new answer if not submitted or after submitted if prev answer not equal curren answer
          // (protection against high frequency of answering questions)
          filter(([aw, sb]) => aw !== null && !sb),
          distinctUntilChanged((prev, next) => UtilsService.jsonSorted(prev[0]) === UtilsService.jsonSorted(next[0])),
          debounceTime(QUESTION_TYPES_COMPONENTS[this.question.storypoint].sendAnswersDebounceTime ?? 0),
          // condition for stop send answer after catch error
          filter(([aw]) => !!aw.questionId),
          this.takeUntilAlive())
        .subscribe(([obj]) => {
          this.sendAnswers(obj);
        });
      if (this.resetQuestionAnswers$) {
        this.resetQuestionAnswers$.pipe(
          filter(id => id === this.qKey && this.question.tryAgain && this.question.directFeedback),
          this.takeUntilAlive())
          .subscribe(() => {
            this.answers = null;
            this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
          });
      }
    }
  }

  private sendAnswers(obj) {
    if (!this.acceptAnswers) {
      return;
    }
    const aObj = cloneDeep(obj.answers);
    this.answers$.next(aObj);
    this.preliminaryAnswers$.next(aObj);
    this.sendTime = new Date().getTime();
    this.submit.next(true);
    this.common.blockedMoveByContents.set(true);
    this.dataService.sendQuizQuestionAnswer(this.documentPathParams, this.currentUserId,
      obj.questionId, this.question.storypoint,
      QUESTION_TYPES_COMPONENTS[this.question.storypoint].questionAnswersSummaryMethodName(this.question),
      QUESTION_TYPES_COMPONENTS[this.question.storypoint].questionAnswersSummarySaveType(this.question),
      QUESTION_TYPES_COMPONENTS[this.question.storypoint].directFeedbackCheck,
      obj.answers, this.anonymousAnswers, this.currentUserProperties, this.sendTime, obj.properties, this.contentLocation)
      .then(() => {
        this.errorOnSendAnswer = false;
      })
      .catch(e => {
        this.errorOnSendAnswer = true;
        this.submit.next(false);
        this.answers$.next(this.prevAnswers);
        this.preliminaryAnswers$.next(this.prevAnswers);
        this.answerChange$.next(new AnswersQuestion(null, null, null, null));
        this.common.showPopupError(e.error);
      })
      .finally(() => {
        this.common.blockedMoveByContents.set(false);
        this.submit.next(false);
        this.lastChange = this.common.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm);
      });
  }
}
