// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1em;
  box-sizing: border-box;
}
.container div {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 1em 2em 2em;
  min-width: 50%;
}
.container div button:not(.warn-white) {
  margin-left: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/login/email-verification/email-verification.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,sBAAA;AACF;AAAE;EACE,qBAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,oBAAA;EACA,cAAA;AAEJ;AADI;EACE,gBAAA;AAGN","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  padding: 1em;\n  box-sizing: border-box;\n  div {\n    display: inline-block;\n    box-sizing: border-box;\n    border-radius: 10px;\n    background-color: white;\n    color: black;\n    padding: 1em 2em 2em;\n    min-width: 50%;\n    button:not(.warn-white) {\n      margin-left: 1em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
