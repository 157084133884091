import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {BehaviorSubject} from 'rxjs';
import {EventQuestion, IGapAnswerData, IGapResultDataMap} from '../../../../../../../model/EventQuestion';
import {ILanguageParams} from '../../../../../../../core/constants';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-question-text-gap-filling-results',
  templateUrl: './question-text-gap-filling-results.component.html',
  styleUrls: ['./question-text-gap-filling-results.component.scss']
})
export class QuestionTextGapFillingResultsComponent extends AbstractQuizQuestionResultsComponent {

  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapResultData$ = new BehaviorSubject<IGapResultDataMap>({});
  gapTaskText$ = new BehaviorSubject<string>(null);
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    this.gapAnswersData$
      .next(this.question.showCorrectAnswers || this.editorMode ?
        (this.question.correctEquality || []).map(v => new Object({id: v.id, answerId: v.answerId,
          answerValue: this.question.items.find(a => a.id === v.answerId)?.getAnswerByLanguage(this.languageParams)}) as IGapAnswerData)
        : []);
    this.gapResultData$
      .next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.groupTextGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers, this.languageParams);
    this.gapResultData$.next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
  }

  private groupTextGapFillingSummaryAnswers(question: EventQuestion,
                                                    qContentAnswers: {[answerEquality: string]: number},
                                                    languageParams: ILanguageParams) {
    const correctAnswers = (question.correctEquality || [])
      .map(o => `${o.id}=${question.items.find(a => a.id === o.answerId)?.getAnswerByLanguage(languageParams)}`);
    const result: {[gapId: string]: {correctCount: number, errorCount: number, correctPercent: number, errorPercent: number, answers: {value: string, count: number, correct: boolean}[]}} =
      (question.correctEquality || []).reduce((acc, item) => {
        acc[item.id] = {correctCount: 0, errorCount: 0, correctPercent: 0, errorPercent: 0, answers: []};
        return acc;
      }, {});
    for (const answerEquality of Object.keys(qContentAnswers || {})) {
      const count = qContentAnswers[answerEquality] ?? 0;
      if (!count) {
        continue;
      }
      const av = answerEquality.split('=');
      if (isEmpty(av[1])) {
        continue;
      }

      let answerIdx = result[av[0]].answers.findIndex(ca => ca.value === av[1]);

      if (answerIdx === -1) {
        result[av[0]].answers.push({value: av[1], count: 0, correct: false});
        answerIdx = result[av[0]].answers.length - 1;
      }

      const correctList = correctAnswers.filter(o => o.split('=')[0] === av[0]);
      if (!isEmpty(correctList) && correctList.some(cr => cr.split('=')[1].toLowerCase().trim() === av[1].toLowerCase().trim())) {
        result[av[0]].correctCount += count;
        result[av[0]].answers[answerIdx].correct = true;
        result[av[0]].answers[answerIdx].count ++;
      } else {
        result[av[0]].errorCount += count;
        result[av[0]].answers[answerIdx].correct = false;
        result[av[0]].answers[answerIdx].count ++;
      }
    }
    for (const r of Object.values(result)) {
      const maxCount = r.correctCount + r.errorCount;
      if (r.correctCount > 0) {
        r.correctPercent = 100 / (maxCount / r.correctCount);
      }
      if (r.errorCount > 0) {
        r.errorPercent = 100 / (maxCount / r.errorCount);
      }

      r.answers = r.answers.sort((a, b) => b.count - a.count);
    }
    return result;
  }

}
