import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {
  AnswerEquality,
  AnswerQuestion,
  EventQuestion,
  IGapAnswerData,
  IGapGroup,
  IGapGroupMatching,
  TableRowAnswerQuestion
} from '../../../../../../../model/EventQuestion';
import {ILanguageParams} from '../../../../../../../core/constants';
import {isEmpty} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {
  AbstractQuizQuestionAnswerMatchingEditorComponent
} from '../../shared/editor/abstract-quiz-question-answer-matching-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';

interface IQuestionSelectGapOptions {
  multipleAnswersForOneMatching: boolean;
}

@Component({
  selector: 'app-question-select-gap-filling-editor',
  templateUrl: './question-select-gap-filling-editor.component.html',
  styleUrls: ['./question-select-gap-filling-editor.component.scss']
})
export class QuestionSelectGapFillingEditorComponent extends AbstractQuizQuestionAnswerMatchingEditorComponent  {

  gapFillingText$ = new BehaviorSubject<string>(null); // text for update text in groups
  gapFillingText: string; // text for default group
  gapFillingGroupAnswersCaption: string;
  gapGroups$ = new BehaviorSubject<IGapGroup[]>([]);
  gapGroupsMatching$ = new BehaviorSubject<IGapGroupMatching[]>([]);

  private _selectedGapId: string;

  constructor(protected injector: Injector,
              public changeDetector: ChangeDetectorRef) {
    super(injector);
  }

  get questionOptions(): IQuestionSelectGapOptions {
    return super.questionOptions;
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.gapFillingText = this.question.getTaskTextByLanguage(this.languageParams);
    this.gapFillingText$.next(this.gapFillingText);
    if (!isEmpty(this.dataSourceMatching.data)) {
      this.reloadGapAnswers(this.dataSourceMatching.data[0].id);
    }
    this.mapToGapGroup();
    this.mapToGapGroupData();
  }

  get selectedGapId(): string {
    return this._selectedGapId;
  }

  set selectedGapId(value: string) {
    this._selectedGapId = value;
    this.setGapFillingGroupAnswersCaption();
  }

  reloadGapAnswers(gapId) {
    this.selectedGapId = gapId;
    this.dataSource.data = this.answerData.filter(a => a.matching.includes(gapId));
  }

  setGapFillingGroupAnswersCaption() {
    const g = this.matchingData.find(o => o.id === this.selectedGapId);
    this.gapFillingGroupAnswersCaption = !g?.getAnswerByLanguage(this.languageParams) ?
      this.common.i18n('action.tooltip.add.gap.filling.group.answers') :
      this.common.i18n('action.tooltip.add.gap.filling.group.name.answers', {name: g.getAnswerByLanguage(this.languageParams)});
  }

  private mapToGapGroup() {
    this.gapGroups$
      .next((this.dataSourceMatching.data || []).map(v => new Object({id: v.id,
        name: v.getAnswerByLanguage(this.languageParams)}) as IGapGroup));
  }

  private mapToGapGroupData() {
    this.gapGroupsMatching$
      .next((this.answerData || [])
        .map(v => new Object({id: v.id,
          name: v.getAnswerByLanguage(this.languageParams), matchingId: v.matching[0]}) as IGapGroupMatching));
  }

  addMatching() {
    super.addMatching();
    this.mapToGapGroup();
  }

  deleteMatching(rowId) {
    super.deleteMatching(rowId);
    if (this.selectedGapId === rowId) {
      this.selectedGapId = null;
    }
    this.mapToGapGroup();
  }

  gapGroupRowDisabled(rowId) {
    return (this.question.correctEquality || []).some(o => this.answerData.find(a => a.matching[0] === rowId && a.id === o.answerId));
  }

  deleteGapValues(gapId) {
    while (this.answerData.filter(item => item.matching[0] === gapId).length) {
      const index = this.answerData.findIndex(it => it.matching[0] === gapId);
      if (index > -1) {
        this.answerData.splice(index, 1);
      }
    }
    this.dataSource.data = [];
    this.mapToGapGroupData();
  }

  gapValuesChange() {
    this.mapToGapGroup();
    this.mapToGapGroupData();
  }

  cdr() {
    this.changeDetector.detectChanges();
  }

  addGapAnswer(gapId: string) {
    const row = new TableRowAnswerQuestion(new AnswerQuestion({matching: [gapId]}), this.languageParams);
    row.orderIndex = this.genAnswerOrderIndex();
    this.answerData.push(row);
    this.dataSource.data = this.answerData.filter(a => a.matching.includes(gapId));
    this.addedId = row.id;
    this.dsSelectedRowId = row.id;
    this.mapToGapGroup();
    this.mapToGapGroupData();
    this.setRowFocus(this.addedId);
  }

  deleteGapAnswer(rowId) {
    const index = this.answerData.findIndex(item => item.id === rowId);
    this.answerData.splice(index, 1);
    this.dataSource.data = this.answerData.filter(a => a.matching.includes(this.selectedGapId));
    this.dsSelectedRowId = null;
    this.mapToGapGroupData();
  }

  gapRowAnswerDisabled(rowId) {
    return !!(this.question.groupsCorrectAnswers || []).find(g => !!(g.correctEquality || []).find(o => o.answerId === rowId));
  }

  onGapFillingTextChange(value: string) {
    this.question.taskText = value;
    this.gapFillingText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  onGroupGapAnswersDataChange(value: IGapAnswerData[], groupId: string | 'default') {
    const group = this.question.groupsCorrectAnswers.find(g => groupId === 'default' ? g.defaultGroup : g.id === groupId);
    group.correctEquality = (value || []).map(o => new AnswerEquality({id: o.id, answerId: o.answerId}));
  }

  validate(): IValidated {
    return !isEmpty(this.matchingData) && !this.matchingData.some(o => isEmptyCaption(o.answerMatching)) &&
    !isEmpty(this.answerData) && !this.answerData.some(o => isEmptyCaption(o.answer)) &&
    !this.question.groupsCorrectAnswers.some(g => g.correctEquality.some(o => !o.answerId)) ?
      {validated: true} : {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }
}
