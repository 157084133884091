import {Injectable} from '@angular/core';
import {Constants, EVENT_DATE_MODE, MANAGE_TIME_ROW_TYPE, SECTION_EVENT_PHASE} from '../core/constants';
import {ROW_TIME_TYPE, RowTask, TimePoint, TimePointMap, TimeValue} from '../model/event-mode/RowDatesObject';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {IPlaneContentMap, PlaneContent} from '../model/content/PlaneContent';
import {Event} from '../model/Event';
import {UtilsService} from '../core/utils.service';
import {EventsDataService} from './events-data.service';
import {Subject} from 'rxjs';
import {TimeLineEmulator} from '../time-line-emulator/time-line-emulator';

interface Range {
  firstIndex: number;
  lastIndex: number;
}

export interface ManageTimeError {
  text: string;
  errorTimePoints: TimePointMap;
  warningTimePoints: TimePointMap;
  forceMarkError: boolean;
  noError: boolean;
}

export interface ICheckTimePoint {
  timeValue: number;
  timePoint: TimePoint;
  rowTimeType: ROW_TIME_TYPE;
}

@Injectable({
  providedIn: 'root'
})
export class ManageTimeService {

  private _testManageTimeMap: TimePointMap;
  private _lockTestManageTimeMap = false;
  private _errorTimePoints: TimePointMap;
  private _forceMarkError = false;
  private _warningTimePoints: TimePointMap;
  private readonly defaultErrorText = 'Incorrect data';
  private _errorText;
  private _resetLastTP: TimePoint;

  private _mainList: SectionTimeline[];
  private _fullListContents: IPlaneContentMap;
  private _fullListContentsSorted: PlaneContent[];
  private _event: Event;
  private _childTreeInLineListCache = {};
  private _rootSectionId;
  private _cycle;
  private _roundTime5minutes;
  private _fixedRangeList: Range[] = [];
  private _runCheckTimePoint = new Subject<ICheckTimePoint>();
  private _getCheckResultTimePoint = new Subject<ManageTimeError>();
  private _resetErrorValues = new Subject();
  private _executeManageTimeTask = new Subject<RowTask[]>();
  private _executeManageTimeTaskResult = new Subject();
  private _emulateCutPasteWithChangeTime$ = new Subject<TimeLineEmulator>();
  private _checkManageTimeErrorResult$ = new Subject<ManageTimeError>();

  constructor(private utils: UtilsService, private eventDataService: EventsDataService) { }

  get testManageTimeMap(): TimePointMap {
    return this._testManageTimeMap;
  }

  get runCheckTimePoint(): Subject<ICheckTimePoint> {
    return this._runCheckTimePoint;
  }

  get getCheckResultTimePoint(): Subject<ManageTimeError> {
    return this._getCheckResultTimePoint;
  }

  get resetErrorValues(): Subject<any> {
    return this._resetErrorValues;
  }

  get executeManageTimeTask(): Subject<RowTask[]> {
    return this._executeManageTimeTask;
  }

  get executeManageTimeTaskResult(): Subject<any> {
    return this._executeManageTimeTaskResult;
  }

  get emulateCutPasteWithChangeTime$(): Subject<TimeLineEmulator> {
    return this._emulateCutPasteWithChangeTime$;
  }

  get checkManageTimeErrorResult$(): Subject<ManageTimeError> {
    return this._checkManageTimeErrorResult$;
  }

// create new self time point
  public createTestTimePoint(point: TimePoint, value, timeType: ROW_TIME_TYPE): TimePoint {
    const newPoint = cloneDeep(point);
    switch (timeType) {
      case ROW_TIME_TYPE.START:
        newPoint.start.value = value;
        newPoint.start.fixed = !!value;
        break;
      case ROW_TIME_TYPE.DURATION:
        newPoint.duration.value = value * Constants.ONE_MINUTE_MS;
        newPoint.duration.fixed = !!value;
        break;
      case ROW_TIME_TYPE.END:
        newPoint.end.value = value;
        newPoint.end.fixed = !!value;
        break;
    }
    // reset not fixed values.
    newPoint.start.value = newPoint.start.fixed ? newPoint.start.value : null;
    newPoint.duration.value = newPoint.duration.fixed ? newPoint.duration.value : null;
    newPoint.end.value = newPoint.end.fixed ? newPoint.end.value : null;
    return newPoint;
  }

  // check correct self fields
  private checkSelfFixedField(point: TimePoint): boolean {
    if ((!point.end.fixed && !point.start.fixed && !point.duration.fixed) ||
      (point.end.fixed && !point.start.fixed && !point.duration.fixed) ||
      (!point.end.fixed && point.start.fixed && !point.duration.fixed)) {
      return true;
    } else {
      return this.roundUpToMinutes(Math.abs(point.endValue - point.startValue)) === this.roundUpToMinutes(point.durationValue);
    }
  }

  checkCorrectTimeMapPoints(sectionList: PlaneContent[], realManageTimeMap: TimePointMap, checkAll = false) {
    this._lockTestManageTimeMap = true;
    let allError = {};
    for (const section of sectionList) {
      if (section.sectionContent.getSectionTimeIsNotActive()) {
        continue;
      }
      const point = this._testManageTimeMap[section.id];
      const check = this.checkCorrectTimeMap(point, realManageTimeMap);
      if (!check.noError) {
        if (!checkAll) {
          return check;
        } else {
          allError = merge(allError, check.errorTimePoints, check.warningTimePoints);
        }
      }
    }
    if (checkAll && !isEmpty(allError)) {
      return {noError: false, errorTimePoints: allError};
    }
    this._lockTestManageTimeMap = false;
    this._testManageTimeMap = {};
    this._errorTimePoints = {};
    this._warningTimePoints = {};
    this._forceMarkError = false;
    this._errorText = null;
    return {noError: true, text: null, errorTimePoints: {}, warningTimePoints: {}};
  }

  checkCorrectTimeMap(srcTestPoint: TimePoint, realManageTimeMap: TimePointMap): ManageTimeError {
    const returnResult = (result: ManageTimeError) => {
      if (!this._lockTestManageTimeMap) {
        this._testManageTimeMap = {};
      }
      this._errorTimePoints = {};
      this._warningTimePoints = {};
      this._forceMarkError = false;
      this._errorText = null;
      return result;
    };
    const getErrorText = () => this._errorText ? this._errorText : this.defaultErrorText;

    this._errorTimePoints = {};
    this._warningTimePoints = {};
    this._errorText = null;
    if (srcTestPoint) {
      const testPoint = this._testManageTimeMap[srcTestPoint.sectionId];
      if (!this.checkSelfFixedField(testPoint)) {
        this._errorTimePoints[testPoint.sectionId] = testPoint;
        return returnResult({
          noError: false, text: 'Wrong fixed values', forceMarkError: this._forceMarkError,
          errorTimePoints: this._errorTimePoints, warningTimePoints: this._warningTimePoints
        });
      }
      if (!this.checkTimeMap(testPoint, realManageTimeMap)) {
        return returnResult({
          noError: false, text: getErrorText(), forceMarkError: this._forceMarkError,
          errorTimePoints: this._errorTimePoints, warningTimePoints: this._warningTimePoints
        });
      }
    }
    return returnResult({noError: true, text: null, errorTimePoints: {}, warningTimePoints: {}, forceMarkError: false});
  }

  private checkTimeMap(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    // check time point in event date range
    if (!this.checkTimePointInEventPhaseRange(testPoint, realManageTimeMap)) {
      return false;
    }
    // check event time point start date
    if (!this.checkEventTimePointStartDate(testPoint)) {
      return false;
    }
    // check main event start date more or equals prep end date if prep exists
    if (!this.checkMainEventTimePointStartDate(testPoint, realManageTimeMap)) {
      return false;
    }
    // check main event end date less or equals wrap-up start date if wrap-up exists
    if (!this.checkMainEventTimePointEndDate(testPoint, realManageTimeMap)) {
      return false;
    }
    // check that prep end date less or equals main event start date if prep exists
    if (!this.checkPrepTimePointEndDate(testPoint, realManageTimeMap)) {
      return false;
    }
    // check that wrap start date more or equals main event end date if wrap exists
    if (!this.checkWrapTimePointStartDate(testPoint, realManageTimeMap)) {
      return false;
    }
    // check that all event phase date do not intersect with each other
    if (!this.checkEventPhaseDateIntersect(testPoint, realManageTimeMap)) {
      return false;
    }
    // check that all fixed date above testPoint level is less testPoint start date
    if (!this.checkAboveFixedDateLevelIsLess(testPoint)) {
      return false;
    }
    // check that all fixed start date above testPoint is less or equals testPoint start date
    if (!this.checkAboveFixedStartDateIsLessOrEquals(testPoint)) {
      return false;
    }
    if (!this.checkAboveLevelFixedDurationAndTestPointStartDateIsLessOrEquals(testPoint)) {
      return false;
    }
    // check that all fixed date above testPoint level is less testPoint start date by sum duration
    if (!this.checkAboveFixedDateLevelIsLessBySumDuration(testPoint)) {
      return false;
    }
    // check that all fixed date below testPoint level is more testPoint start date
    if (!this.checkBelowFixedDateLevelIsMore(testPoint)) {
      return false;
    }
    // check that all fixed start date below testPoint is more testPoint or equal end date
    if (!this.checkBelowFixedStartDateIsMoreEndDate(testPoint)) {
      return false;
    }
    if (!this.checkLevelFirstBelowFixedStartDateIsMoreAllEndDateBetweenTestPoint(testPoint)) {
      return false;
    }
    // check that sum children with fixed duration less testPoint duration.
    if (!this.checkSumChildFixedDuration(testPoint)) {
      return false;
    }
    // check that testPoint date less fixed event end time if event fixed duration or end time
    if (!this.checkDateLessEventFixedEnd(testPoint)) {
      return false;
    }
    // check that sum fixed duration more parent duration
    if (!this.checkSumFixedDurationMoreParent(testPoint)) {
      return false;
    }
    // check that above point with fixed duration have start date lest then testPoint
    if (!this.checkAbovePointFixedDurationHaveLessStartDate(testPoint)) {
      return false;
    }
    // check that above point with fixed duration have end date less or equal testPoint start date
    if (!this.checkAbovePointFixedDurationHaveLessEndDate(testPoint)) {
      return false;
    }
    // check that above point with fixed duration have equals start date, duration, end date
    if (!this.checkAbovePointFixedDurationHaveEqualsTestPoint(testPoint)) {
      return false;
    }
    // check that above first point with fixed start + sum child fixed duration less than start test point
    if (!this.checkAboveFirstPointWithFixedStartPlusSumFixedChildDurationLessTestPointStart(testPoint)) {
      return false;
    }
    // check that parent point with fixed duration or fixed end more then testPoint end
    if (!this.checkParentFixedEndMoreChildFixedEnd(testPoint)) {
      return false;
    }
    // check that testPoint start in range parent point with fixed start + fixed duration
    if (!this.checkParentFixedStartAndFixedDuration(testPoint)) {
      return false;
    }
    // check all child fixed end date less than testPoint fixed end date
    if (!this.checkChildsFixedEndLessTestFixedEnd(testPoint)) {
      return false;
    }
    // check that level above points with fixed start date and duration have end date more then start testPoint
    if (!this.checkAbovePointWithFixedStartDateAndDurationHaveMoreEndDate(testPoint)) {
      return false;
    }
    // check that level above points with fixed duration have end date less or equals testPoint start date
    // if testPoint fixed end and duration
    if (!this.checkAbovePointWithFixedDurationLessOrEqualsTestPointStart(testPoint)) {
      return false;
    }
    // check that level below points end date have more then testPoint end date
    if (!this.checkBelowPointEndDateMoreTestPointEndDate(testPoint)) {
      return false;
    }
    // check that level below points between testPoint all end date more prev date
    if (!this.checkBelowPointBetweenTestPointMoreAllPrevEndDate(testPoint)) {
      return false;
    }
    // check all above less level sections with fixed end date less or equals testPoint fixed start + fixed duration.
    if (!this.checkAllAboveLessLevelSectionFixedEndLessOrEqualsTestPointStartPlusDuration(testPoint)) {
      return false;
    }

    if (!this.checkParentStartEqualOrLessTestPointFixedStart(testPoint)) {
      return false;
    }
    // check level for duplicate fixed start or end and duration
    if (!this.checkLevelForDuplicateFixedStartOrEndAndDuration(testPoint)) {
      return false;
    }
    // check that all timePoints in event ranges
    for (const sectionId of Object.keys(this._testManageTimeMap)) {
      const point = this._testManageTimeMap[sectionId];
      if (!this.testAnyTimePointInEventPhaseRange(point)) {
        return false;
      }
    }
    // check that all duration is not less 0 or null
    for (const sectionId of Object.keys(this._testManageTimeMap)) {
      const point = this._testManageTimeMap[sectionId];
      if (point.duration.value !== null && point.duration.value !== undefined && point.duration.value < 0) {
        this._warningTimePoints[point.sectionId] = point;
      } else if (point.startValue !== null && point.endValue !== null && point.duration.value === null) {
        this._warningTimePoints[point.sectionId] = point;
      }
    }
    if (!isEmpty(this._warningTimePoints)) {
      this._errorText = this.utils.i18n('manage.time.check.error.incorrect.data');
      return false;
    }
    return true;
  }
  // check event time point start date
  private checkEventTimePointStartDate(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      // check that all fixed dates more or equal event point start date
      for (const sectionId of Object.keys(this._testManageTimeMap)) {
        if (sectionId.toString().includes('-')) {
          continue;
        }
        const tp = this._testManageTimeMap[sectionId];
        if ((tp.start.fixed && tp.start.value < testPoint.start.value) || (tp.end.fixed && tp.end.value < testPoint.start.value)) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
      if (!testPoint.end.value) {
        return true;
      } else {
        // check that all fixed dates less or equal event point end date and
        // that all fixed start dates + fixed duration less or equal event point end date
        for (const sectionId of Object.keys(this._testManageTimeMap)) {
          if (sectionId.toString().includes('-')) {
            continue;
          }
          const tp = this._testManageTimeMap[sectionId];
          if ((tp.start.fixed && tp.start.value > testPoint.end.value) || (tp.end.fixed && tp.end.value > testPoint.end.value)) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
            this._errorTimePoints[tp.sectionId] = tp;
            return false;
          }
          if (tp.start.fixed && tp.start.value && tp.duration.fixed && tp.duration &&
            tp.start.value + tp.duration.value > testPoint.end.value) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
            this._errorTimePoints[tp.sectionId] = tp;
            return false;
          }
        }
      }
    }
    return true;
  }

  // check main event start date more or equals prep end date if prep exists
  checkMainEventTimePointStartDate(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    if (testPoint.sectionId.toString().includes('-') && !testPoint.sectionEventPhase) {
      const eventId = testPoint.sectionId.toString().split('-')[0];
      const realPrepTP: TimePoint = realManageTimeMap[eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP];
      if (!isEmpty(realPrepTP) && testPoint.startValue && realPrepTP.endValue && realPrepTP.endValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.start.time.can.be.more.prep.end.time');
        this._errorTimePoints[realPrepTP.sectionId] = realPrepTP;
        this._forceMarkError = true;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // check main event end date less or equals wrap-up start date if wrap-up exists
  checkMainEventTimePointEndDate(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    if (testPoint.sectionId.toString().includes('-') && !testPoint.sectionEventPhase) {
      const eventId = testPoint.sectionId.toString().split('-')[0];
      const realWrapTP: TimePoint = realManageTimeMap[eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP];
      if (!isEmpty(realWrapTP) && testPoint.endValue && realWrapTP.startValue && realWrapTP.startValue < testPoint.endValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.end.time.can.be.less.wrap.start.time');
        this._errorTimePoints[realWrapTP.sectionId] = realWrapTP;
        this._forceMarkError = true;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // check that prep end date less or equals main event start date if prep exists
  checkPrepTimePointEndDate(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    if (testPoint.sectionId.toString().includes('-') && testPoint.sectionEventPhase === SECTION_EVENT_PHASE.PREP) {
      const eventId = testPoint.sectionId.toString().split('-')[0];
      const realStartTP: TimePoint = realManageTimeMap[eventId + '-' + MANAGE_TIME_ROW_TYPE.START];
      if (!isEmpty(realStartTP) && testPoint.endValue && realStartTP.startValue && realStartTP.startValue < testPoint.endValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.prep.end.time.can.be.less.main.event.start.time');
        this._errorTimePoints[realStartTP.sectionId] = realStartTP;
        this._forceMarkError = true;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // check that wrap start date more or equals main event end date if wrap exists
  checkWrapTimePointStartDate(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    if (testPoint.sectionId.toString().includes('-') && testPoint.sectionEventPhase === SECTION_EVENT_PHASE.WRAP_UP) {
      const eventId = testPoint.sectionId.toString().split('-')[0];
      const realStartTP: TimePoint = realManageTimeMap[eventId + '-' + MANAGE_TIME_ROW_TYPE.START];
      if (!isEmpty(realStartTP) && testPoint.startValue && realStartTP.endValue && realStartTP.endValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.wrap.start.time.can.be.more.main.event.end.time');
        this._errorTimePoints[realStartTP.sectionId] = realStartTP;
        this._forceMarkError = true;
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  // check that all event phase date do not intersect with each other
  checkEventPhaseDateIntersect(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    if (!testPoint.sectionId.toString().includes('-')) {
      const rMapArray: TimePoint[] = this.utils.objectValues(realManageTimeMap);
      const tMapArray: TimePoint[] = this.utils.objectValues(this._testManageTimeMap);
      const realPrepTP: TimePoint = (testPoint.sectionEventPhase === SECTION_EVENT_PHASE.PREP ? tMapArray : rMapArray)
        .find(p => p.sectionId.includes('-' + MANAGE_TIME_ROW_TYPE.PREP));
      const realStartTP: TimePoint = (!testPoint.sectionEventPhase ? tMapArray : rMapArray)
        .find(p => p.sectionId.includes('-' + MANAGE_TIME_ROW_TYPE.START));
      const realWrapTP: TimePoint = (testPoint.sectionEventPhase === SECTION_EVENT_PHASE.WRAP_UP ? tMapArray : rMapArray)
        .find(p => p.sectionId.includes('-' + MANAGE_TIME_ROW_TYPE.WRAP_UP));
      if (!isEmpty(realPrepTP) && realPrepTP.endValue && realPrepTP.endValue > realStartTP.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.start.time.can.be.more.prep.end.time');
        this._errorTimePoints[realStartTP.sectionId] = realStartTP;
        this._forceMarkError = true;
        return false;
      }
      if (!isEmpty(realWrapTP) && realWrapTP.startValue && realWrapTP.startValue < realStartTP.endValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.end.time.can.be.less.wrap.start.time');
        this._errorTimePoints[realStartTP.sectionId] = realStartTP;
        this._forceMarkError = true;
        return false;
      }
      if (!isEmpty(realStartTP) && realStartTP.startValue && realPrepTP.endValue && realStartTP.startValue < realPrepTP.endValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.prep.end.time.can.be.less.main.event.start.time');
        this._errorTimePoints[realStartTP.sectionId] = realStartTP;
        this._forceMarkError = true;
        return false;
      }
      if (!isEmpty(realStartTP) && realStartTP.endValue && realWrapTP.startValue && realStartTP.endValue > realWrapTP.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.event.wrap.start.time.can.be.more.main.event.end.time');
        this._errorTimePoints[realStartTP.sectionId] = realStartTP;
        this._forceMarkError = true;
        return false;
      }
    }
    return true;
  }

  // check that all fixed date above testPoint level is less testPoint start date
  private checkAboveFixedDateLevelIsLess(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const parentId = this._mainList[sectionIndex].parentId;
      const levelIdList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (s.parentId === parentId && index < sectionIndex) {
          levelIdList.push(s.id);
        }
      });
      levelIdList.reverse();
      for (const id of levelIdList) {
        const tp = this._testManageTimeMap[id];
        if (testPoint.start.value && ((tp.start.fixed && tp.start.value > testPoint.start.value) ||
          (tp.end.fixed && tp.end.value > testPoint.start.value))) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }

  // check that all above fixed start date above testPoint is less or equals testPoint start date
  private checkAboveFixedStartDateIsLessOrEquals(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const idList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (index < sectionIndex) {
          idList.push(s.id);
        }
      });
      idList.reverse();
      for (const id of idList) {
        const tp = this._testManageTimeMap[id];
        if (tp.start.fixed && (testPoint.start.fixed || (testPoint.end.fixed && testPoint.duration.fixed)) &&
          tp.startValue > testPoint.startValue) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }

  private checkAboveLevelFixedDurationAndTestPointStartDateIsLessOrEquals(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const parentId = this._mainList[sectionIndex].parentId;
      const idList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (s.parentId === parentId && index < sectionIndex && !s.sectionTimeIsNotActive) {
          idList.push(s.id);
        }
      });
      idList.reverse();
      for (const id of idList) {
        const tp = this._testManageTimeMap[id];
        if (testPoint.start.fixed && tp.duration.fixed && tp.endValue > testPoint.startValue) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }

  // check level for duplicate fixed start or end and duration
  private checkLevelForDuplicateFixedStartOrEndAndDuration(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const parentId = this._mainList[sectionIndex].parentId;
      const idList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (s.parentId === parentId && !s.sectionTimeIsNotActive && !!s.duration && s.id !== testPoint.sectionId) {
          idList.push(s.id);
        }
      });
      for (const id of idList) {
        const tp = this._testManageTimeMap[id];
        if (tp.durationValue && tp.durationValue > 0 &&
          tp.startValue === testPoint.startValue && tp.durationValue === testPoint.durationValue && tp.endValue === testPoint.endValue) {
          this._errorText = this.utils.i18n('manage.time.check.error.dates.and.duration.is.equals');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }

  // check that all fixed date above testPoint level is less testPoint start date by sum duration
  private checkAboveFixedDateLevelIsLessBySumDuration(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const parentId = this._mainList[sectionIndex].parentId;
      const levelIdList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (s.parentId === parentId && index < sectionIndex) {
          levelIdList.push(s.id);
        }
      });
      let duration = 0;
      levelIdList.reverse();
      for (const id of levelIdList) {
        const tp = this._testManageTimeMap[id];
        if (!tp.start.fixed && !tp.end.fixed && tp.duration.fixed) {
          duration += tp.duration.value;
        }
        if (testPoint.start.value && ((tp.start.fixed && tp.start.value > testPoint.start.value - duration) ||
          (tp.end.fixed && tp.end.value > testPoint.start.value - duration))) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }
  // check that all fixed date below testPoint level is more testPoint start date
  private checkBelowFixedDateLevelIsMore(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const parentId = this._mainList[sectionIndex].parentId;
      const levelIdList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (s.parentId === parentId && index > sectionIndex) {
          levelIdList.push(s.id);
        }
      });
      for (const id of levelIdList) {
        const tp = this._testManageTimeMap[id];
        if (testPoint.start.fixed && testPoint.start.value && ((tp.start.fixed && tp.start.value < testPoint.start.value) ||
          (tp.end.fixed && tp.end.value < testPoint.start.value))) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }
  // check that all fixed start date below testPoint is more testPoint or equal end date
  private checkBelowFixedStartDateIsMoreEndDate(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const parentId = this._mainList[sectionIndex].parentId;
      const levelIdList: string[] = [];
      this._mainList.forEach((s, index) => {
        if (s.parentId === parentId && index > sectionIndex) {
          levelIdList.push(s.id);
        }
      });
      for (const id of levelIdList) {
        const tp = this._testManageTimeMap[id];
        if ((testPoint.start.fixed || testPoint.end.fixed) && tp.start.fixed && tp.start.value < testPoint.end.value) {
          this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
          this._errorTimePoints[tp.sectionId] = tp;
          return false;
        }
      }
    }
    return true;
  }

  private checkLevelFirstBelowFixedStartDateIsMoreAllEndDateBetweenTestPoint(testPoint: TimePoint) {
    const sectionIndex = this._mainList.findIndex(s => s.id === testPoint.sectionId);
    if (sectionIndex > -1) {
      const levelIdList: string[] = [];
      const parentId = this._mainList[sectionIndex].parentId;
      this._mainList.sort(this.utils.comparator(Constants.ORDERINDEX)).forEach((s, index) => {
        if (s.parentId === parentId && index >= sectionIndex) {
          levelIdList.push(s.id);
        }
      });
      const dates = [];
      for (const id of levelIdList) {
        const tp = this._testManageTimeMap[id];
        if (tp.start.fixed) {
          if (dates.some(d => d > tp.startValue)) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
            this._errorTimePoints[tp.sectionId] = tp;
            return false;
          } else {
            // testPoint is "ok" - continue check
            if (tp.sectionId === testPoint.sectionId) {
              dates.push(tp.endValue);
            } else {
              // point with fixed start date is "ok" and is below testPoint
              return true;
            }
          }
        } else {
          dates.push(tp.endValue);
        }
      }
    }
    return true;
  }

  // check that sum children with fixed duration less testPoint duration
  private checkSumChildFixedDuration(testPoint: TimePoint) {
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    if (section && !isEmpty(section.items)) {
      const sTree = this.childTreeInLineList(section.id).filter(o => o.id !== section.id);
      const excludeIdList = [];
      let sumDuration = 0;
      const tpList: TimePointMap = {};
      for (const obj of sTree) {
        const p = this._testManageTimeMap[obj['id']];
        if (!p) {
          continue;
        }
        const sc = this._mainList.find(s => s.id === p.sectionId);
        if (p.duration.fixed && !excludeIdList.includes(p.sectionId)) {
          tpList[p.sectionId] = p;
          sumDuration = sumDuration + p.durationValue;
          if (!isEmpty(sc.items)) {
            this.childTreeInLineList(sc.id).forEach(o => excludeIdList.push(o.id));
          }
        }
      }
      if (testPoint.durationValue < sumDuration) {
        this._errorText = this.utils.i18n('manage.time.check.error.sum.of.children.greater.value');
        this._errorTimePoints = tpList;
        return false;
      }
    }
    return true;
  }
  // check that testPoint date less fixed event end time if event fixed duration or end time
  private checkDateLessEventFixedEnd(testPoint: TimePoint) {
    const mainPoint = Object.keys(this._testManageTimeMap)
      .filter( key => key.includes('-'))
      .map(id => this._testManageTimeMap[id]).filter(p => p.sectionEventPhase === testPoint.sectionEventPhase) [0];
    if (mainPoint.sectionId === testPoint.sectionId) {
      return true;
    }
    if (mainPoint && mainPoint.start.fixed && (testPoint.endValue < mainPoint.startValue || testPoint.startValue < mainPoint.startValue)) {
      this._errorText = this.utils.i18n('manage.time.check.error.time.less.main.time');
      this._errorTimePoints[mainPoint.sectionId] = mainPoint;
      return false;
    }
    if (mainPoint && mainPoint.start.fixed && (mainPoint.duration.fixed || mainPoint.end.fixed) &&
      (testPoint.startValue > mainPoint.endValue || testPoint.endValue > mainPoint.endValue ||
        testPoint.durationValue > mainPoint.durationValue)) {
      this._errorText = this.utils.i18n('manage.time.check.error.time.more.main.time');
      this._errorTimePoints[mainPoint.sectionId] = mainPoint;
      return false;
    }
    return true;
  }
  // check that sum fixed duration more parent duration
  private checkSumFixedDurationMoreParent(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    let parent;
    if (section.parentId && (parent = this._mainList.find(s => s.id === section.parentId))) {
      const sTree = this.childTreeInLineList(parent.id).filter(o => o.id !== parent.id);
      const excludeIdList = [];
      let sumDuration = 0;
      const tpList: TimePointMap = {};
      for (const obj of sTree) {
        const p = this._testManageTimeMap[obj['id']];
        if (!p) {
          continue;
        }
        const sc = this._mainList.find(s => s.id === p.sectionId);
        if (p.duration.fixed && !excludeIdList.includes(p.sectionId)) {
          tpList[p.sectionId] = p;
          sumDuration = sumDuration + p.durationValue;
          if (!isEmpty(sc.items)) {
            this.childTreeInLineList(sc.id).forEach(o => excludeIdList.push(o.id));
          }
        }
      }
      const parentPoint = this._testManageTimeMap[parent.id];
      if (!parent.getSectionTimeIsNotActive() && parentPoint.durationValue < sumDuration) {
        this._errorText = this.utils.i18n('manage.time.check.error.sum.of.children.greater.parent.value');
        this._errorTimePoints[parentPoint.sectionId] = parentPoint;
        return false;
      }
    }
    return true;
  }
  // check that above point with fixed duration have start date lest then testPoint
  private checkAbovePointFixedDurationHaveLessStartDate(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList.filter(s => s.parentId === section.parentId  && s.orderIndex < section.orderIndex).reverse();
    for (const s of levelList) {
      if (s.getSectionTimeIsNotActive()) {
        continue;
      }
      const point = this._testManageTimeMap[s.id];
      if (point.duration.fixed && testPoint.startValue && point.startValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.date.is.less.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }
  // check that above point with fixed duration have end date less or equal testPoint start date
  private checkAbovePointFixedDurationHaveLessEndDate(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList.filter(s => s.parentId === section.parentId && s.orderIndex < section.orderIndex).reverse();
    for (const s of levelList) {
      if (s.getSectionTimeIsNotActive()) {
        continue;
      }
      const point = this._testManageTimeMap[s.id];
      if (point.duration.fixed && (point.start.fixed || point.end.fixed) && testPoint.startValue && point.endValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.date.is.less.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }
  // check that above point with fixed duration have equals start date, duration, end date
  private checkAbovePointFixedDurationHaveEqualsTestPoint(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList.filter(s => s.parentId === section.parentId  && s.orderIndex < section.orderIndex).reverse();
    for (const s of levelList) {
      if (s.getSectionTimeIsNotActive()) {
        continue;
      }
      const point = this._testManageTimeMap[s.id];
      if (point.duration.fixed && testPoint.startValue === point.startValue && testPoint.durationValue === point.durationValue &&
        testPoint.endValue === point.endValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.dates.and.duration.is.equals');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }

  // check that above first point with fixed start + sum child fixed duration less than start test point
  private checkAboveFirstPointWithFixedStartPlusSumFixedChildDurationLessTestPointStart(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-') || !testPoint.start.fixed) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList
      .filter(s => s.parentId === section.parentId  && s.orderIndex < section.orderIndex && s.plannedTimeFixed).reverse();
    if (!isEmpty(levelList) && !isEmpty(levelList[0].items)) {
      const lsTP = this._testManageTimeMap[levelList[0].id];
      if (lsTP) {
        const points: TimePoint[] = [];
        const sumFixedDuration = levelList[0].items.reduce((accum, it) => {
          const point = this._testManageTimeMap[it.id];
          if (point && point.durationValue && point.duration.fixed) {
            points.push(point);
            accum = accum + point.durationValue;
          }
          return accum;
        }, 0);
        if (testPoint.start.fixed && lsTP.startValue + this.roundUpToMinutes(sumFixedDuration) > testPoint.startValue) {
          this._errorText = this.utils.i18n('manage.time.check.error.sum.of.children.greater.value');
          points.forEach(p => this._errorTimePoints[p.sectionId] = p);
          return false;
        }
      }
    }
    return true;
  }

  // check that parent point with fixed duration or fixed end more then testPoint end
  private checkParentFixedEndMoreChildFixedEnd(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    let parentId = this._fullListContents[testPoint.sectionId].sectionContent.parentId;
    const parents = [];
    while (parentId !== this._rootSectionId) {
      parents.push(parentId);
      parentId = this._fullListContents[parentId].sectionContent.parentId;
    }
    for (let i = 0; i < parents.length; i++) {
      const point = this.testManageTimeMap[parents[i]];
      if (!point || (!point.duration.fixed && !point.end.fixed)) {
        continue;
      }
      if ((point.duration.fixed && point.start.fixed && point.startValue + point.durationValue !== point.endValue) ||
        (((point.start.fixed && point.duration.fixed) || point.end.fixed) && point.endValue < testPoint.endValue)) {
        this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }

  // check that testPoint start in range parent point with fixed start + fixed duration
  private checkParentFixedStartAndFixedDuration(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-') || !testPoint.start.fixed) {
      return true;
    }
    const parentId = this._fullListContents[testPoint.sectionId].sectionContent.parentId;
    const parentPoint = this.testManageTimeMap[parentId];
    if (parentPoint && ((parentPoint.start.fixed && parentPoint.duration.fixed) || (parentPoint.start.fixed && parentPoint.end.fixed))) {
      if (testPoint.startValue > testPoint.endValue && testPoint.startValue > parentPoint.endValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
        this._errorTimePoints[parentPoint.sectionId] = parentPoint;
        return false;
      }
    }
    return true;
  }

  // check all child fixed end date less than testPoint fixed end date
  private checkChildsFixedEndLessTestFixedEnd(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    if (!testPoint.end.fixed) {
      return true;
    }
    const sTree = this.childTreeInLineList(testPoint.sectionId).map(o => o.sectionContent);
    for (let i = 0; i < sTree.length; i++) {
      const sp = this._testManageTimeMap[sTree[i].id] ;
      if (testPoint.endValue && sp && sp.end.fixed && sp.endValue > testPoint.endValue) {
        this._errorTimePoints[sp.sectionId] = sp;
      }
    }
    if (!isEmpty(this._errorTimePoints)) {
      this._errorText = this.utils.i18n('manage.time.check.error.date.is.less.than.acceptable');
      return false;
    }
    return true;
  }

  private checkParentStartEqualOrLessTestPointFixedStart(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    if (!testPoint.start.fixed) {
      return true;
    }
    let parentId = this._fullListContents[testPoint.sectionId].sectionContent.parentId;
    const parents = [];
    while (parentId !== this._rootSectionId) {
      parents.push(parentId);
      parentId = this._fullListContents[parentId].sectionContent.parentId;
    }
    for (let i = 0; i < parents.length; i++) {
      const s = this._fullListContents[parents[i]].sectionContent;
      const point = this.testManageTimeMap[parents[i]];
      if (!point || s.getSectionTimeIsNotActive()) {
        continue;
      }
      if (point.startValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.time.is.less.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }

  // check all above less level sections with fixed end date less or equals testPoint fixed start + fixed duration.
  private checkAllAboveLessLevelSectionFixedEndLessOrEqualsTestPointStartPlusDuration(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const testSection = this._mainList.find(s => s.id === testPoint.sectionId);
    const plSorted = Object.keys(this._fullListContents)
      .map(k => this._fullListContents[k])
      .sort(this.utils.comparator(Constants.ORDERINDEX));
    const testSectionLevel = this.contentLevel(testSection.id);
    const aboveSections = this._mainList.filter(s => s.orderIndex < testSection.orderIndex &&
      this.contentLevel(s.id) > testSectionLevel);
    for (let i = 0; i < aboveSections.length; i++) {
      const sp = this._testManageTimeMap[aboveSections[i].id];
      if (sp.end.fixed && testPoint.start.fixed && testPoint.duration.fixed &&
        testPoint.startValue + testPoint.durationValue < sp.endValue) {
        this._errorTimePoints[sp.sectionId] = sp;
      }
    }
    if (!isEmpty(this._errorTimePoints)) {
      this._errorText = this.utils.i18n('manage.time.check.error.date.is.less.than.acceptable');
      return false;
    }
    return true;
  }

  // check that level above points with fixed start date and duration have end date more then start testPoint
  private checkAbovePointWithFixedStartDateAndDurationHaveMoreEndDate(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList.filter(s => s.parentId === section.parentId  && s.orderIndex < section.orderIndex).reverse();
    for (const s of levelList) {
      const point = this._testManageTimeMap[s.id];
      if (point.duration.fixed && point.start.fixed && testPoint.startValue && point.endValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.date.is.less.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }

  // check that level above points with fixed duration have end date less or equals testPoint start date if testPoint fixed end and duration
  private checkAbovePointWithFixedDurationLessOrEqualsTestPointStart(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-') || !(testPoint.end.fixed && testPoint.duration.fixed)) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList.filter(s => s.parentId === section.parentId  && s.orderIndex < section.orderIndex).reverse();
    for (const s of levelList) {
      const point = this._testManageTimeMap[s.id];
      if (point.endValue > testPoint.startValue) {
        this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }

  // check that level below points end date have more then testPoint end date
  private checkBelowPointEndDateMoreTestPointEndDate(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    const levelList = this._mainList.filter(s => s.parentId === section.parentId  && s.orderIndex > section.orderIndex);
    for (const s of levelList) {
      const point = this._testManageTimeMap[s.id];
      if (((point.duration.fixed && point.start.fixed) || point.end.fixed) && (testPoint.endValue > point.end.value)) {
        this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }

  // check that level below points between testPoint all end date more prev date
  private checkBelowPointBetweenTestPointMoreAllPrevEndDate(testPoint: TimePoint) {
    if (testPoint.sectionId.toString().includes('-')) {
      return true;
    }
    const section = this._mainList.find(s => s.id === testPoint.sectionId);
    let levelList = this._mainList.filter(s => s.parentId === section.parentId  && s.orderIndex > section.orderIndex);
    if (isEmpty(levelList)) {
      return true;
    }
    const firstFixedEndIndex = levelList.findIndex(o => o.endTimeFixed);
    if (firstFixedEndIndex === -1) {
      return true;
    }
    levelList = levelList.slice(0, firstFixedEndIndex + 1);
    const sEndList: {sectionId: string, endTime: number}[] = [];
    for (const s of levelList) {
      const sp = this._testManageTimeMap[s.id];
      if (sp && sp.endValue) {
        sEndList.push({sectionId: s.id, endTime: sp.endValue});
      }
    }
    let errSectionId = null;
    if (!isEmpty(sEndList)) {
      const testEndList = sEndList.reduce((result, current, index) => {
        if (index === 0) {
          result = current.endTime;
        } else if (index > 0 && result > 0 && result > current.endTime) {
          errSectionId = current.sectionId;
          result = -1;
        }
        return result;
      }, -1);
      if (testEndList < 0) {
        const point = this._testManageTimeMap[errSectionId];
        this._errorText = this.utils.i18n('manage.time.check.error.time.is.more.than.acceptable');
        this._errorTimePoints[point.sectionId] = point;
        return false;
      }
    }
    return true;
  }


  // check time point in event date range
  private checkTimePointInEventPhaseRange(testPoint: TimePoint, realManageTimeMap: TimePointMap) {
    if (testPoint.sectionId.includes('-')) {
      return true;
    }
    if (this._event.dateMode === EVENT_DATE_MODE.SPECIFIC_DATE_TIME) {
      switch (testPoint.sectionEventPhase) {
        case SECTION_EVENT_PHASE.PREP:
          if (this._event.prepPhaseStartFixed && this._event.prepPhaseStart > testPoint.startValue) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.out.of.main.time.range');
            const point = realManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          if ((this._event.prepPhaseEndFixed || (this._event.prepPhaseStartFixed && this._event.prepPhaseDurationFixed)) &&
            this._event.wrapUpPhaseEnd < testPoint.endValue) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.out.of.main.time.range');
            const point = realManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          break;
        case SECTION_EVENT_PHASE.WRAP_UP:
          if (this._event.wrapUpPhaseStartFixed && this._event.wrapUpPhaseStart > testPoint.startValue) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.out.of.main.time.range');
            const point = realManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          if ((this._event.wrapUpPhaseEndFixed || (this._event.wrapUpPhaseStartFixed && this._event.wrapUpPhaseDurationFixed)) &&
            this._event.wrapUpPhaseEnd < testPoint.endValue) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.out.of.main.time.range');
            const point = realManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          break;
        default:
          if (testPoint.startValue < this._event.startDate.getTime()) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.less.main.time');
            const point = realManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.START];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          if ((this._event.endDateFixed || this._event.durationFixed) && testPoint.endValue > this._event.endDate) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.more.main.time');
            const point = realManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.START];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          break;
      }
    }
    return true;
  }

  // test any time point in event date range
  private testAnyTimePointInEventPhaseRange(testPoint: TimePoint) {
    if (testPoint.sectionId.includes('-')) {
      return true;
    }
    if (this._event.dateMode === EVENT_DATE_MODE.SPECIFIC_DATE_TIME) {
      let eventTP: TimePoint;
      switch (testPoint.sectionEventPhase) {
        case SECTION_EVENT_PHASE.PREP:
          eventTP = this._testManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP];
          if (((eventTP.start.fixed && eventTP.duration.fixed || (eventTP.duration.fixed && eventTP.end.fixed) ||
            (eventTP.start.fixed && eventTP.end.fixed))) &&
            (eventTP.startValue > testPoint.startValue || eventTP.endValue < testPoint.startValue ||
              eventTP.startValue > testPoint.endValue || eventTP.endValue < testPoint.endValue)) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.out.of.main.time.range');
            const point = this._testManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          break;
        case SECTION_EVENT_PHASE.WRAP_UP:
          eventTP = this._testManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP];
          if (((eventTP.start.fixed && eventTP.duration.fixed || (eventTP.duration.fixed && eventTP.end.fixed) ||
            (eventTP.start.fixed && eventTP.end.fixed))) &&
            (eventTP.startValue > testPoint.startValue || eventTP.endValue < testPoint.startValue ||
              eventTP.startValue > testPoint.endValue || eventTP.endValue < testPoint.endValue)) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.out.of.main.time.range');
            const point = this._testManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          break;
        default:
          eventTP = this._testManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.START];
          if (
            (eventTP.startValue > testPoint.startValue || eventTP.startValue > testPoint.endValue ||
              ((eventTP.duration.fixed || eventTP.end.fixed) && (eventTP.endValue < testPoint.startValue ||
                eventTP.endValue < testPoint.endValue)))) {
            this._errorText = this.utils.i18n('manage.time.check.error.time.less.main.time');
            const point = this._testManageTimeMap[this._event.eventId + '-' + MANAGE_TIME_ROW_TYPE.START];
            this._errorTimePoints[point.sectionId] = point;
            return false;
          }
          break;
      }
    }
    return true;
  }

  private getTimePoint(value: Event | SectionTimeline, rootSectionId: string, eventTimeType?: MANAGE_TIME_ROW_TYPE): TimePoint {
    const durationMS = (v: number, alterValue?: number) => v || v === 0 ? v * Constants.ONE_MINUTE_MS :
      (alterValue || alterValue === 0 ? alterValue * Constants.ONE_MINUTE_MS : null);
    const toNumber = (v: Date | number, alterValue?: Date | number) => v ? (v instanceof Date ? (v as Date).getTime() : v as number) :
      (alterValue ? (alterValue instanceof Date ? (alterValue as Date).getTime() : alterValue as number) : null);
    if (value instanceof Event) {
      switch (eventTimeType) {
        case MANAGE_TIME_ROW_TYPE.START:
          return (new TimePoint(value.eventId + '-' + MANAGE_TIME_ROW_TYPE.START, MANAGE_TIME_ROW_TYPE.START, null,
            new TimeValue(toNumber(value.startDate), true),
            new TimeValue(value.durationFixed ? durationMS(value.duration) : null, value.durationFixed),
            new TimeValue(value.endDateFixed ? toNumber(value.endDate) : null, value.endDateFixed)))
            .calcUndefinedByFixed();
        case MANAGE_TIME_ROW_TYPE.PREP:
          return (new TimePoint(value.eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP,  SECTION_EVENT_PHASE.PREP, SECTION_EVENT_PHASE.PREP,
            new TimeValue(value.prepPhaseStartFixed ? toNumber(value.prepPhaseStart) : null, value.prepPhaseStartFixed),
            new TimeValue(value.prepPhaseDurationFixed ? durationMS(value.prepPhaseDuration) : null, value.prepPhaseDurationFixed),
            new TimeValue(value.prepPhaseEndFixed ? toNumber(value.prepPhaseEnd) : null, value.prepPhaseEndFixed)))
            .calcUndefinedByFixed();
        case MANAGE_TIME_ROW_TYPE.WRAP_UP:
          return (new TimePoint(value.eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP,
            SECTION_EVENT_PHASE.WRAP_UP, SECTION_EVENT_PHASE.WRAP_UP,
            new TimeValue(value.wrapUpPhaseStartFixed ? toNumber(value.wrapUpPhaseStart) : null, value.wrapUpPhaseStartFixed),
            new TimeValue(value.wrapUpPhaseDurationFixed ? durationMS(value.wrapUpPhaseDuration) : null, value.wrapUpPhaseDurationFixed),
            new TimeValue(value.wrapUpPhaseEndFixed ? toNumber(value.wrapUpPhaseEnd) : null , value.wrapUpPhaseEndFixed)))
            .calcUndefinedByFixed();
      }
    } else {
      if (!value.getSectionTimeIsNotActive()) {
        return (new TimePoint(value.id, value['title'], value.sectionEventPhase,
          new TimeValue(value.plannedTimeFixed ? toNumber(value.plannedTimeFixedValue, value.plannedTime) : null, value.plannedTimeFixed),
          new TimeValue(value.durationFixed ? durationMS(value.durationFixedValue, value.duration) : null, value.durationFixed),
          new TimeValue(value.endTimeFixed ? toNumber(value.endTimeFixedValue, value.endTime) : null, value.endTimeFixed)))
          .calcUndefinedByFixed();
      } else if (value.getSectionTimeIsNotActive() && value.parentId === rootSectionId) {
        return new TimePoint(value.id, value['title'], value.sectionEventPhase,
          new TimeValue(null, false),
          new TimeValue(0, true),
          new TimeValue(null, false));
      }
    }
  }

  private roundUpToMinutes(value: number) {
    if (this._roundTime5minutes) {
      if (value === null || value === undefined) {
        return null;
      }
      const dvM = Math.ceil(value /  Constants.ONE_MINUTE_MS);
      const sVal = dvM.toString();
      if (sVal.endsWith('5') || sVal.endsWith('0')) {
        return (+sVal) * Constants.ONE_MINUTE_MS;
      } else {
        return ((Math.floor(dvM / 5) * 5)) * Constants.ONE_MINUTE_MS;
      }
    } else {
      return Math.ceil(value / Constants.ONE_MINUTE_MS) * Constants.ONE_MINUTE_MS;
    }
  }

  private childTreeInLineList(sectionId: string): PlaneContent[] {
    const getContentIndex = (sId): number => {
      const sortedPlaneList = this._fullListContentsSorted;
      return sortedPlaneList.findIndex(elem => sId === elem['id']);
    };

    const getNextContentId = (sId): string => {
      const parent = this._fullListContents[sId] && this._fullListContents[sId]['trueParentItem'] ?
        this._fullListContents[this._fullListContents[sId]['trueParentItem']['id']] : null;
      if (!parent) {
        return null;
      }
      const childList = parent.items;
      const myIndex = childList.findIndex(elem => sId === elem['id']);
      if (parent.items.length === 1 || myIndex === parent.items.length - 1) {
        return sId;
      } else {
        return parent.items[myIndex + 1]['id'];
      }
    };

    const tree = (sId: string): PlaneContent[] => {
      const ret: any[] = [];
      const level = this.contentLevel(sId);
      const myIndex = getContentIndex(sId);
      if (myIndex < 0) {
        return ret;
      }
      const nextId = getNextContentId(sId);
      const sortedPlaneList = this._fullListContentsSorted;
      for (let i = myIndex; i < sortedPlaneList.length; i++) {
        if (i === myIndex ||
          (this.contentLevel(sortedPlaneList[i]['id']) > level && sortedPlaneList[i]['id'] !== nextId)) {
          ret.push(sortedPlaneList[i]);
        } else {
          break;
        }
      }
      return ret;
    };


    if (this._childTreeInLineListCache[sectionId]) {
      return this._childTreeInLineListCache[sectionId];
    }
    const line = tree(sectionId);
    this._childTreeInLineListCache[sectionId] = line;
    return line;
  }

  private contentLevel(itemId: string) {
    let level = 0;
    const sortedPlaneList = this._fullListContentsSorted;
    const myIndex = sortedPlaneList.findIndex(function (elem) {
      if (itemId === elem['id']) {
        return true;
      }
    });
    let parentId = sortedPlaneList[myIndex] && sortedPlaneList[myIndex]['parentItem'] ? sortedPlaneList[myIndex]['parentItem']['id'] : null;
    for (let i = myIndex; i > 0; i--) {
      if (sortedPlaneList[i]['type'] === Constants.CONTENT_TYPE_TIMELINE &&
        parentId === sortedPlaneList[i]['id']) {
        level++;
        parentId = sortedPlaneList[i]['parentItem'] ? sortedPlaneList[i]['parentItem']['id'] : null;
      }
    }
    return level;
  }


  public calcTestMangeTimeMap(planeFullListContent: IPlaneContentMap,
                              planeListContentSorted: PlaneContent[], event: Event, rootSectionId: string,
                              roundTime5minutes: boolean, withoutPoint: boolean, testTimePoint: TimePoint) {
    this._testManageTimeMap = {};
    switch (testTimePoint.sectionEventPhase) {
      case SECTION_EVENT_PHASE.PREP:
        this.calcMangeTimeMap(this._testManageTimeMap, planeFullListContent, planeListContentSorted, event ,
          MANAGE_TIME_ROW_TYPE.PREP, roundTime5minutes , rootSectionId, withoutPoint ? null : testTimePoint);
        break;
      case SECTION_EVENT_PHASE.WRAP_UP:
        this.calcMangeTimeMap(this._testManageTimeMap, planeFullListContent, planeListContentSorted, event ,
          MANAGE_TIME_ROW_TYPE.WRAP_UP, roundTime5minutes , rootSectionId, withoutPoint ? null : testTimePoint);
        break;
      default:
        this.calcMangeTimeMap(this._testManageTimeMap, planeFullListContent, planeListContentSorted, event ,
          MANAGE_TIME_ROW_TYPE.START, roundTime5minutes , rootSectionId, withoutPoint ? null : testTimePoint);
        break;
    }
  }

  public calcTestEventMangeTimeMap(planeFullListContent: {[key: string]: PlaneContent},
                                   planeListContentSorted: PlaneContent[], event: Event, rootSectionId: string, round5Min: boolean) {
    this._testManageTimeMap = {};
    this._mainList = [];
    this.calcMangeTimeMap(this._testManageTimeMap, planeFullListContent,
      planeListContentSorted, event, MANAGE_TIME_ROW_TYPE.PREP, round5Min, rootSectionId);
    this.calcMangeTimeMap(this._testManageTimeMap, planeFullListContent,
      planeListContentSorted, event, MANAGE_TIME_ROW_TYPE.START, round5Min, rootSectionId);
    this.calcMangeTimeMap(this._testManageTimeMap, planeFullListContent,
      planeListContentSorted, event, MANAGE_TIME_ROW_TYPE.WRAP_UP, round5Min, rootSectionId);
    this._mainList = planeListContentSorted.filter(o => !o.isRoot && planeFullListContent[o.parentId] &&
      !planeFullListContent[o.parentId].container &&
      (!o.sectionContent.getSectionTimeIsNotActive() ||
        (o.sectionContent.getSectionTimeIsNotActive() && o.parentId === rootSectionId)) && !o.sectionContent.fixedSectionType)
      .map(s => s.sectionContent);
  }

  /**
   * @param manageTimeMap
   * @param fullListContents
   * @param sectionList - sorted by "orderIndex" list of objects of type PlaneContent without fixed section
   * @param event - current event
   * @param eventPhase
   * @param roundTime5minutes - event instant settings manageTimeRound5Min
   * @param rootSectionId
   * @param testTimePoint
   */
  public calcMangeTimeMap(manageTimeMap: TimePointMap, fullListContents: IPlaneContentMap,
                          sectionList: PlaneContent[], event: Event,
                          eventPhase: MANAGE_TIME_ROW_TYPE, roundTime5minutes: boolean,
                          rootSectionId: string, testTimePoint?: TimePoint) {
    // exclude section with parent as container and root section
    const prepWrap = [MANAGE_TIME_ROW_TYPE.PREP, MANAGE_TIME_ROW_TYPE.WRAP_UP].includes(eventPhase);
    const phase = eventPhase === MANAGE_TIME_ROW_TYPE.PREP ? SECTION_EVENT_PHASE.PREP :
      (eventPhase === MANAGE_TIME_ROW_TYPE.WRAP_UP ? SECTION_EVENT_PHASE.WRAP_UP : null);
    this._mainList = sectionList.filter(o => !o.isRoot && fullListContents[o.parentId] &&
      !fullListContents[o.parentId].container &&
      (!o.sectionContent.getSectionTimeIsNotActive() ||
        (o.sectionContent.getSectionTimeIsNotActive() && o.parentId === rootSectionId)) &&
      !o.sectionContent.fixedSectionType &&
      (prepWrap ? o.sectionContent.sectionEventPhase === phase : !o.sectionContent.sectionEventPhase))
      .map(s => s.sectionContent);
    this._fullListContents = fullListContents;
    this._fullListContentsSorted = Object.values(fullListContents).sort(this.utils.comparator(Constants.ORDERINDEX));
    this._event = event;
    this._childTreeInLineListCache = {};
    this._rootSectionId = rootSectionId;
    this._roundTime5minutes = roundTime5minutes;
    let mainTP;
    if (testTimePoint && testTimePoint.sectionId.toString().includes('-')) {
      mainTP = testTimePoint.calcUndefinedByFixed();
    } else {
      mainTP = this.getTimePoint(event, rootSectionId, eventPhase);
    }
    manageTimeMap[mainTP.sectionId] = mainTP;
    this._mainList.forEach(s => {
      if (testTimePoint && s.id === testTimePoint.sectionId) {
        manageTimeMap[s.id] = testTimePoint.calcUndefinedByFixed();
      } else {
        manageTimeMap[s.id] = this.getTimePoint(s, rootSectionId);
      }
    });
    this._cycle = 0;
    this._fixedRangeList = [];
    this._resetLastTP = null;
    this.analyzeData(mainTP, manageTimeMap);
    this.analyzePhaseFixedEndTime(phase, manageTimeMap);
  }

  private analyzeData(mainTimePoint: TimePoint, manageTimeMap: TimePointMap) {
    this.testAndSetPrepStartDate(mainTimePoint);
    this.testAndSetWrapUpStartDate(mainTimePoint);
    this.firstStep(mainTimePoint, manageTimeMap);
    this.analyzePoints(manageTimeMap, 0, this._mainList.length - 1);
    this.collapseParentsAndCalculate(manageTimeMap, 0, this._mainList.length - 1);
    if (this._resetLastTP) {
      this._resetLastTP.start.value = null;
      this._resetLastTP.end.value = null;
    }
    this.analyzePoints(manageTimeMap, 0, this._mainList.length - 1);
    this.analyzeFixedDurationRanges(manageTimeMap);
    this.finalizeData(manageTimeMap, 0, this._mainList.length - 1);
    this.analyzePoints(manageTimeMap, 0, this._mainList.length - 1);
    this.checkEndEventPhase(this._rootSectionId, mainTimePoint, manageTimeMap);
    mainTimePoint.durationValue = this.calcChildDuration(this._rootSectionId, manageTimeMap, mainTimePoint);
  }

  private analyzePoints(manageTimeMap: TimePointMap, firstIndex: number, lastIndex: number) {
    let oldCycle = -1;
    while (oldCycle !== this._cycle) {
      oldCycle = this._cycle;
      for (let i = firstIndex; i <= lastIndex; i++) {
        this.analyzePoint(this._mainList[i], manageTimeMap, i);
      }
    }
  }

  private firstStep(mainTimePoint: TimePoint, manageTimeMap: TimePointMap) {
    if (isEmpty(this._mainList)) {
      return;
    }
    const firstTP = manageTimeMap[this._mainList[0].id];
    if (!firstTP.startValue) {
      firstTP.startValue = mainTimePoint.startValue;
    }
    const s = this._mainList.filter(o => !o.getSectionTimeIsNotActive()).reverse()[0];
    if (s) {
      const lastTP = manageTimeMap[s.id];
      if (!lastTP.endValue && mainTimePoint.endValue) {
        const parents = [];
        let parentId = s.parentId;
        while (parentId !== this._rootSectionId) {
          const parent = this._fullListContents[parentId];
          parents.push(parent.id);
          parentId = parent.parentId;
        }
        if (!isEmpty(parents) && !lastTP.durationValue &&
          parents.every(id => !manageTimeMap[id] || (!manageTimeMap[id].end.fixed && !manageTimeMap[id].duration.fixed))) {
          lastTP.endValue = mainTimePoint.endValue;
        } else
        if (!isEmpty(parents) && (lastTP.duration.fixed && !lastTP.endValue && !lastTP.startValue) &&
          parents.every(id => !manageTimeMap[id] || (!manageTimeMap[id].end.fixed && !manageTimeMap[id].duration.fixed))) {
          lastTP.endValue = mainTimePoint.endValue;
          this._resetLastTP = lastTP;
        } else
        if (isEmpty(parents) && (!lastTP.durationValue && !lastTP.endValue && !lastTP.startValue)) {
          lastTP.endValue = mainTimePoint.endValue;
        } else
        if (isEmpty(parents) && (lastTP.duration.fixed && !lastTP.endValue && !lastTP.startValue)) {
          lastTP.endValue = mainTimePoint.endValue;
          this._resetLastTP = lastTP;
        } else
        if (isEmpty(parents) && (!lastTP.duration.fixed && !lastTP.end.fixed && lastTP.start.fixed)) {
          lastTP.endValue = mainTimePoint.endValue;
        }
      }
    }
  }

  private getCalculatedChild(section: SectionTimeline, outList: SectionTimeline[]) {
    if (section && !section.getSectionTimeIsNotActive() && (isEmpty(section.items) || section.container ||
      section.items.every(itm => itm.getSectionTimeIsNotActive()))) {
      outList.push(section);
    } else if (section && !section.getSectionTimeIsNotActive() && !isEmpty(section.items)) {
      for (let i = 0; i < section.items.length - 1; i++) {
        const s = section.items[i];
        this.getCalculatedChild(this._fullListContents[s.id].sectionContent , outList);
      }
    }
  }

  private analyzePoint(section: SectionTimeline, manageTimeMap: TimePointMap, index: number) {
    if (!section) {
      return;
    }
    const tpSelf = manageTimeMap[section.id];
    let sSelfParent: SectionTimeline;
    let tpSelfParent: TimePoint = null;
    if (!isEmpty(sSelfParent = this._mainList.find(s => s.id === section.parentId))) {
      tpSelfParent = manageTimeMap[section.parentId];
    }

    let sLevelPrev: SectionTimeline = null;
    let tpLevelPrev: TimePoint = null;
    const sLevelPrevList =
      this._mainList.filter(s => s.parentId === section.parentId && s.orderIndex < section.orderIndex);
    if (!isEmpty(sLevelPrevList)) {
      sLevelPrev = sLevelPrevList[sLevelPrevList.length - 1];
      tpLevelPrev = manageTimeMap[sLevelPrev.id];
    }

    let sLevelNext: SectionTimeline = null;
    let tpLevelNext: TimePoint = null;
    const sLevelNextList =
      this._mainList.filter(s => s.parentId === section.parentId && s.orderIndex > section.orderIndex);
    if (!isEmpty(sLevelNextList)) {
      sLevelNext = sLevelNextList[0];
      tpLevelNext = manageTimeMap[sLevelNext.id];
    }


    let sPrev: SectionTimeline = null;
    let tpPrev: TimePoint = null;
    if (index > 0) {
      sPrev = this._mainList[index - 1];
      tpPrev = manageTimeMap[sPrev.id];
    }
    let sNext: SectionTimeline = null;
    let tpNext: TimePoint = null;
    if (index !== this._mainList.length - 1) {
      sNext = this._mainList[index + 1];
      tpNext = manageTimeMap[sNext.id];
    }
    let lastChild: SectionTimeline = null;
    let tpLastChild: TimePoint = null;
    const sTree = this.childTreeInLineList(section.id).filter(o => o.id !== section.id &&
      !o.sectionContent.getSectionTimeIsNotActive() && this._fullListContents[o.parentId] && !this._fullListContents[o.parentId].container);
    if (!section.container && !section.getSectionTimeIsNotActive() && sTree.length > 0) {
      const onlyCalculateSections = [];
      for (let i = 0; i < sTree.length; i++) {
        this.getCalculatedChild(sTree[i].sectionContent, onlyCalculateSections);
      }
      const lch = onlyCalculateSections.sort(this.utils.comparator(Constants.ORDERINDEX)).reverse()[0];
      if (lch) {
        lastChild = this._fullListContents[lch.id].sectionContent;
        tpLastChild = manageTimeMap[lastChild.id];
      }
    }

    if (sLevelPrev && tpLevelPrev && tpLevelPrev.endValue &&
      !tpSelf.start.fixed && !tpSelf.end.fixed && sPrev.parentId !== section.parentId) {
      tpSelf.startValue = tpLevelPrev.endValue;
    }

    if (sLevelNext && tpLevelNext && tpLevelNext.start.fixed && !tpSelf.end.fixed && !tpSelf.duration.fixed && !tpSelf.endValue) {
      tpSelf.endValue = tpLevelNext.startValue;
    }

    if (sPrev && sPrev.id !== section.parentId && !tpSelf.startValue && tpPrev.endValue) {
      tpSelf.startValue = tpPrev.endValue;
      this._cycle++;
    } else if (sPrev && sPrev.id !== section.parentId &&
      (!tpPrev.duration.fixed || (tpPrev.duration.fixed && !tpPrev.start.fixed)) &&
      tpSelf.startValue && !tpPrev.endValue) {
      const sPrevParent = this._mainList.find(s => s.id === sPrev.parentId);
      if (sPrevParent && sPrevParent.id !== this._rootSectionId && sPrev.parentId !== section.parentId) {
        const tpPrevParent = manageTimeMap[sPrevParent.id];
        if (tpPrevParent && tpPrevParent.endValue) {
          tpPrev.endValue = tpPrevParent.endValue;
          if (tpPrev.durationValue) {
            const dr = this.roundUpToMinutes(tpPrev.durationValue);
            tpPrev.end.value = tpPrev.start.value + dr;
          }
          this._cycle++;
        }
      } else {
        tpPrev.endValue = tpSelf.startValue;
        if (tpPrev.durationValue) {
          const dr = this.roundUpToMinutes(tpPrev.durationValue);
          tpPrev.end.value = tpPrev.start.value + dr;
        }
        this._cycle++;
      }
    } else if (sPrev && sPrev.id === section.parentId && !tpSelf.startValue && tpPrev.startValue) {
      tpSelf.startValue = tpPrev.startValue;
      this._cycle++;
    }
    if (tpSelf && sPrev && sNext && section.parentId === sPrev.parentId && section.parentId === sNext.parentId &&
      !tpSelf.startValue && tpPrev.endValue) {
      tpSelf.startValue = tpPrev.endValue;
      this._cycle++;
    }
    if (tpSelf && sPrev && sNext && section.parentId === sPrev.parentId && section.parentId === sNext.parentId &&
      !tpSelf.endValue && tpNext.startValue) {
      tpSelf.endValue = tpNext.startValue;
      this._cycle++;
    }
    if (sNext && sNext.parentId !== section.id && !tpNext.startValue && tpSelf.endValue) {
      if (sSelfParent && tpSelfParent &&
        (sNext.parentId === sSelfParent.parentId || (sNext.parentId !== section.parentId && sNext.orderIndex > section.orderIndex)) &&
        tpSelfParent.endValue && tpSelf.endValue &&
        !tpNext.startValue && tpSelfParent.endValue > tpSelf.endValue) {
        tpNext.startValue = tpSelfParent.endValue;
      } else {
        tpNext.startValue = tpSelf.endValue;
      }
      this._cycle++;
    } else if (sNext && sNext.parentId === section.id && !tpNext.startValue && tpSelf.startValue) {
      tpNext.startValue = tpSelf.startValue;
      this._cycle++;
    } else if (sNext && sNext.parentId === section.id && tpNext.startValue && !tpSelf.startValue) {
      tpSelf.startValue = tpNext.startValue;
      this._cycle++;
    }
    if (tpLastChild && lastChild &&
      !tpLastChild.start.fixed && !tpLastChild.duration.fixed && !tpLastChild.end.fixed && tpSelf.endValue && !tpLastChild.endValue) {
      const endTp = this.timePointFixedParent(tpLastChild, manageTimeMap, tpSelf);
      tpLastChild.endValue = endTp.endValue;
      if (tpLastChild.durationValue) {
        const dr = this.roundUpToMinutes(tpLastChild.durationValue);
        tpLastChild.end.value = tpLastChild.start.value + dr;
      }
      this._cycle++;
    } else if (tpLastChild && lastChild && tpLastChild.endValue && !tpSelf.endValue) {
      tpSelf.endValue = tpLastChild.endValue;
      this._cycle++;
    }
  }

  private timePointFixedParent(tp: TimePoint, manageTimeMap: TimePointMap, defTimePoint: TimePoint) {
    const cSection = this._fullListContents[tp.sectionId].sectionContent;
    let parent = this._fullListContents[cSection.parentId];
    const parentsIdList = [cSection.parentId];
    while (parent && !parent.sectionContent.isRoot) {
      parent = this._fullListContents[parent.parentId];
      if (parent && !parent.sectionContent.isRoot) {
        parentsIdList.push(parent.id);
      }
    }
    for (const id of parentsIdList) {
      const ptp: TimePoint = manageTimeMap[id];
      if (ptp && (ptp.end.fixed || (ptp.start.fixed && ptp.duration.fixed) ||
        (!!ptp.start.value && !ptp.start.fixed && ptp.duration.fixed))) {
        return ptp;
      }
    }
    return defTimePoint;
  }

  private collapseParentsAndCalculate(manageTimeMap: TimePointMap, firstIndex: number, lastIndex: number) {
    let fIndex = -1;
    const excludeIdList: string[] = [];
    let rangeNotHaveStartEnd = true;
    for (let i = firstIndex; i <= lastIndex; i++) {
      if (!this._mainList[i] || excludeIdList.includes(this._mainList[i].id)) {
        continue;
      }
      if (this._mainList[i].items.length > 0 && !this._mainList[i].container &&
        !this._mainList[i].items.every(s => s.getSectionTimeIsNotActive())) {
        if (manageTimeMap[this._mainList[i].id].duration.fixed ||
          (manageTimeMap[this._mainList[i].id].start.fixed && manageTimeMap[this._mainList[i].id].end.fixed)) {
          const sTree = this.childTreeInLineList(this._mainList[i].id).filter(obj => obj.id !== this._mainList[i].id);
          sTree.forEach(obj => excludeIdList.push(obj.id));
          if (!this._mainList[i].container && !this._mainList[i].items.every(s => s.getSectionTimeIsNotActive())) {
            this._fixedRangeList.push({firstIndex: i + 1, lastIndex: i + sTree.length});
          }
        }
        continue;
      }
      const tp = manageTimeMap[this._mainList[i].id];
      if (tp.startValue && fIndex < 0) {
        rangeNotHaveStartEnd = false;
        fIndex = i;
      }
      if (tp.endValue && fIndex >= 0) {
        this.calculateRange(fIndex, i, manageTimeMap, excludeIdList);
        rangeNotHaveStartEnd = false;
        fIndex = -1;
      }
    }
    if (rangeNotHaveStartEnd && lastIndex > 0) {
      const sp = this._fullListContents[this._mainList[firstIndex].parentId];
      if (sp) {
        const parentTP: TimePoint = manageTimeMap[sp.id];
        if (parentTP && parentTP.durationValue) {
          this.calculateRange(firstIndex, lastIndex, manageTimeMap, excludeIdList, parentTP.durationValue);
        }
      }
    }
  }

  private calculateRange(firstIndex: number, lastIndex: number, manageTimeMap: TimePointMap, excludeIdList: string[],
                         mainDuration?: number) {
    const vm = this;
    const rangeList = this._mainList.slice(firstIndex, lastIndex + 1);
    const sumFixedDuration = rangeList.reduce(function (value, s) {
      if ((manageTimeMap[s.id].duration.fixed || (manageTimeMap[s.id].start.fixed && manageTimeMap[s.id].end.fixed)) &&
        !excludeIdList.includes(s.id)) {
        return value + manageTimeMap[s.id].duration.value;
      } else {
        return value;
      }
    }, 0);
    let count = rangeList.reduce(function (value, s) {
      if ((!s.container && s.items.length > 0 && !s.items.every(sc => sc.getSectionTimeIsNotActive())) ||
        manageTimeMap[s.id].duration.fixed || (manageTimeMap[s.id].start.fixed && manageTimeMap[s.id].end.fixed) ||
        excludeIdList.includes(s.id)) {
        return value;
      } else {
        return value + 1;
      }
    }, 0);
    let duration = !mainDuration ? (manageTimeMap[this._mainList[lastIndex].id].endValue -
      manageTimeMap[this._mainList[firstIndex].id].startValue - sumFixedDuration) : (mainDuration - sumFixedDuration);
    for (let i = firstIndex; i <= lastIndex; i++) {
      const s = this._mainList[i];
      if (!s) {
        continue;
      }
      const tp = manageTimeMap[s.id];
      if ((!s.container && s.items.length > 0 && !s.items.every(sc => sc.getSectionTimeIsNotActive())) ||
        manageTimeMap[s.id].duration.fixed || (manageTimeMap[s.id].start.fixed && manageTimeMap[s.id].end.fixed) ||
        excludeIdList.includes(s.id)) {
        continue;
      }
      tp.durationValue = this.roundUpToMinutes(Math.ceil(duration / count));
      duration = duration - tp.durationValue;
      count--;
    }
  }

  private finalizeData(manageTimeMap: TimePointMap, firstIndex: number, lastIndex: number) {
    const range = this._mainList.slice(firstIndex, lastIndex + 1).reverse();
    for (let i = 0; i < range.length; i++) {
      const section = range[i];
      if ((!section.container && section.items.length > 0 &&
        !section.items.every(sc => sc.getSectionTimeIsNotActive())) && !manageTimeMap[section.id].durationValue) {
        manageTimeMap[section.id].durationValue = this.calcChildDuration(section.id, manageTimeMap, manageTimeMap[section.id]);
      }
    }
    for (let i = firstIndex; i <= lastIndex; i++) {
      const section = this._mainList[i];
      if (!manageTimeMap[section.id].durationValue) {
        manageTimeMap[section.id].durationValue = 0;
      }
    }
    this.checkParents(manageTimeMap, firstIndex, lastIndex);
  }

  private calcChildDuration(parentId, manageTimeMap: TimePointMap, mainTimePoint: TimePoint) {
    const parentPoint = manageTimeMap[mainTimePoint.sectionId];
    if (parentPoint && parentPoint.start.fixed && parentPoint.end.fixed) {
      return parentPoint.durationValue;
    } else
    if (parentPoint && parentPoint.startValue && parentPoint.endValue) {
      return parentPoint.endValue - parentPoint.startValue;
    } else {
      const childList = this._mainList.filter(s => s.parentId === parentId && !s.getSectionTimeIsNotActive());
      if (!isEmpty(childList)) {
        const sp = manageTimeMap[childList[0].id];
        const lp = manageTimeMap[childList[childList.length - 1].id];
        if (sp.startValue && lp.endValue) {
          return lp.endValue - sp.startValue;
        } else {
          return childList.reduce(function (value, s) {
            return value + manageTimeMap[s.id].durationValue;
          }, 0);
        }
      } else {
        return 0;
      }
    }
  }

  private checkParents(manageTimeMap: TimePointMap, firstIndex: number, lastIndex: number) {
    for (let i = firstIndex; i <= lastIndex; i++) {
      const section = this._mainList[i];
      if (section && !section.container && !section.getSectionTimeIsNotActive() && section.items.length > 0) {
        const lastItem = section.items.filter(s => !s.getSectionTimeIsNotActive()).reverse()[0];
        if (lastItem) {
          const tpLast = manageTimeMap[lastItem.id];
          const tpSelf = manageTimeMap[section.id];
          if ((tpLast && tpLast.endValue && !tpSelf.endValue) || (tpLast && tpLast.endValue > tpSelf.endValue)) {
            if (!tpSelf.end.fixed) {
              if (tpSelf.startValue) {
                if (!tpSelf.duration.fixed) {
                  tpSelf.duration.value = null;
                }
              }
              if (tpSelf.start.fixed && tpSelf.duration.fixed) {
                continue;
              }
              tpSelf.endValue = tpLast.endValue;
            }
          }
        }
      }
    }
  }

  private checkEndEventPhase(rootSectionId, mainTimePoint: TimePoint,  manageTimeMap: TimePointMap) {
    const list = this._mainList.filter(s => s.id !== rootSectionId && !s.getSectionTimeIsNotActive()).reverse();
    let maxDate = 0;
    for (const section of list) {
      const tp = manageTimeMap[section.id];
      if (tp.endValue > maxDate) {
        maxDate = tp.endValue;
      }
    }
    if (mainTimePoint.startValue && mainTimePoint.duration.fixed) {
      mainTimePoint.endValue = mainTimePoint.startValue + mainTimePoint.durationValue;
    } else {
      mainTimePoint.endValue = maxDate;
    }
  }

  private analyzeFixedDurationRanges(manageTimeMap: TimePointMap) {
    while (!isEmpty(this._fixedRangeList)) {
      const range = this._fixedRangeList[0];
      this.analyzePoints(manageTimeMap, range.firstIndex, range.lastIndex);
      this.collapseParentsAndCalculate(manageTimeMap, range.firstIndex, range.lastIndex);
      this.analyzePoints(manageTimeMap, range.firstIndex, range.lastIndex);
      this.checkParents(manageTimeMap, range.firstIndex, range.lastIndex);
      this.analyzePoints(manageTimeMap, range.firstIndex, range.lastIndex);
      this._fixedRangeList.splice(0, 1);
    }
  }

  testAndSetPrepStartDate(mainTimePoint: TimePoint) {
    if (mainTimePoint.sectionEventPhase === SECTION_EVENT_PHASE.PREP && !isEmpty(this._mainList) &&
      !mainTimePoint.startValue  && !mainTimePoint.endValue &&
      this._mainList.every(s => !s.plannedTimeFixed) && this._mainList.every(s => !s.endTimeFixed) &&
      this._mainList.some(s => s.durationFixed)) {
      mainTimePoint.startValue = this.utils.clearTimeInDate(this._event.startDate.getTime());
    }
  }

  testAndSetWrapUpStartDate(mainTimePoint: TimePoint) {
    if (mainTimePoint.sectionEventPhase === SECTION_EVENT_PHASE.WRAP_UP && !isEmpty(this._mainList) &&
      !mainTimePoint.startValue  && !mainTimePoint.endValue &&
      this._mainList.every(s => !s.plannedTimeFixed) && this._mainList.every(s => !s.endTimeFixed) &&
      this._mainList.some(s => s.durationFixed)) {
      mainTimePoint.startValue = this._event.endDate;
    }
  }

  analyzePhaseFixedEndTime(phase: SECTION_EVENT_PHASE, manageTimeMap: TimePointMap) {
    if (isEmpty(this._mainList)) {
      return;
    }
    const lastS = this._mainList[this._mainList.length - 1];
    const list = [lastS];
    let parentId = lastS.parentId;
    while (!!parentId && parentId !== this._rootSectionId) {
      const ps = this._mainList.find(o => o.id === parentId);
      if (ps.id !== this._rootSectionId) {
        list.push(ps);
      }
      parentId = ps.parentId;
    }
    if (list.every(s => !s.endTimeFixed)) {
      const tp = manageTimeMap[list[list.length - 1].id];
      let endTime = null;
      switch (phase) {
        case SECTION_EVENT_PHASE.PREP:
          endTime = this._event.prepPhaseEndFixed ? this._event.prepPhaseEnd : null;
          break;
        case SECTION_EVENT_PHASE.WRAP_UP:
          endTime = this._event.wrapUpPhaseEndFixed ?  this._event.wrapUpPhaseEnd : null;
          break;
        default:
          endTime = this._event.endDate ? this._event.endDate : null;
          break;
      }
      if (tp.start.fixed && tp.duration.fixed) {
        return;
      }
      if (tp.endValue && tp.startValue && tp.durationValue &&
        (this.roundUpToMinutes(tp.startValue + tp.durationValue) === this.roundUpToMinutes(endTime))) {
        tp.endValue = endTime;
        this.analyzePoints(manageTimeMap, 0, this._mainList.length - 1);
      }
    }
  }

  updateMangeTimeObjects(eventId, manageTimeMap: TimePointMap, fullListContents: IPlaneContentMap) {
    const task: RowTask[] = [];
    for (const key of Object.keys(manageTimeMap)) {
      const point = manageTimeMap[key];
      const uSectionId = point.sectionId.toString().split('-');
      const sectionId = uSectionId[0];
      const pointEventPhase: MANAGE_TIME_ROW_TYPE = uSectionId[1] ? uSectionId[1] : null;
      const section = fullListContents[sectionId] ? fullListContents[sectionId].sectionContent : null;
      if (section && section.getSectionTimeIsNotActive()) {
        continue;
      }
      switch (pointEventPhase) {
        case MANAGE_TIME_ROW_TYPE.START:
          if (!point.start.fixed) {
            task.push({sectionId: sectionId, fieldName: 'startDate', value: point.startValue, type: 'event'});
          }
          if (!point.duration.fixed) {
            task.push({sectionId: sectionId, fieldName: 'duration', value: point.durationDataBaseValue, type: 'event'});
          }
          if (!point.end.fixed) {
            task.push({sectionId: sectionId, fieldName: 'endDate', value: point.endValue, type: 'event'});
          }
          break;
        case MANAGE_TIME_ROW_TYPE.PREP:
          if (!point.start.fixed) {
            task.push({sectionId: sectionId, fieldName: 'prepPhaseStart', value: point.startValue, type: 'event'});
          }
          if (!point.duration.fixed) {
            task.push({sectionId: sectionId, fieldName: 'prepPhaseDuration', value: point.durationDataBaseValue, type: 'event'});
          }
          if (!point.end.fixed) {
            task.push({sectionId: sectionId, fieldName: 'prepPhaseEnd', value: point.endValue, type: 'event'});
          }
          break;
        case MANAGE_TIME_ROW_TYPE.WRAP_UP:
          if (!point.start.fixed) {
            task.push({sectionId: sectionId, fieldName: 'wrapUpPhaseStart', value: point.startValue, type: 'event'});
          }
          if (!point.duration.fixed) {
            task.push({sectionId: sectionId, fieldName: 'wrapUpPhaseDuration', value: point.durationDataBaseValue, type: 'event'});
          }
          if (!point.end.fixed) {
            task.push({sectionId: sectionId, fieldName: 'wrapUpPhaseEnd', value: point.endValue, type: 'event'});
          }
          break;
        case MANAGE_TIME_ROW_TYPE.END:
          break;
        case MANAGE_TIME_ROW_TYPE.DAY:
          break;
        case MANAGE_TIME_ROW_TYPE.TOOLS:
          break;
        default:
          if (!point.start.fixed) {
            task.push({sectionId: sectionId, fieldName: 'plannedTime', value: point.startValue, type: 'section'});
          } else if (point.start.fixed && section.plannedTimeFixedValue) {
            task.push({sectionId: sectionId, fieldName: 'plannedTime', value: section.plannedTimeFixedValue, type: 'section'});
          }
          if (!point.duration.fixed) {
            task.push({sectionId: sectionId, fieldName: 'duration', value: point.durationDataBaseValue, type: 'section'});
          } else if (point.duration.fixed && section.durationFixedValue) {
            task.push({sectionId: sectionId, fieldName: 'duration', value: section.durationFixedValue, type: 'section'});
          }
          if (!point.end.fixed) {
            task.push({sectionId: sectionId, fieldName: 'endTime', value: point.endValue, type: 'section'});
          } else if (point.end.fixed && section.endTimeFixedValue) {
            task.push({sectionId: sectionId, fieldName: 'endTime', value: section.endTimeFixedValue, type: 'section'});
          }
          break;
      }
    }
    return this.eventDataService.updateManageTimeSections(eventId, task);
  }
}
