import { Component, ElementRef, Injector } from '@angular/core';
import { AbstractQuizQuestionResultsComponent } from '../../shared/results/abstract-quiz-question-results-component';
import { isEmpty, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

interface AnswerSummary {
  answerId: string;
}

@Component({
  selector: 'app-question-prioritization-results',
  templateUrl: './question-prioritization-results.component.html',
  styleUrl: './question-prioritization-results.component.scss',
})
export class QuestionPrioritizationResultsComponent extends AbstractQuizQuestionResultsComponent {

  answers$ = new BehaviorSubject<AnswerSummary[][]>([]);
  weightedTotalById: { [answerId: string]: number } = {};
  tiedPlaces: { [position: number]: string[] } = {};

  constructor(
    protected injector: Injector,
    protected elementRef: ElementRef,
  ) {
    super(injector, elementRef);
  }

  protected onReceiveQuestionAnswers() {
    this.summaryQuestionAnswers$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (isEmpty(value)) {
          this.answers$.next([]);
        } else {
          this.answers$.next(value);
        }
        this.handleRules();
      });
  }

  handleRules(): void {
    // Initialize counting of occurrences for each id by position
    const occurrencesByPosition: { [answerId: string]: number[] } = {};

    const answers = this.answers$.value;

    this.question.items.forEach(item => {
      occurrencesByPosition[item.id] = Array(answers.length).fill(0);
    });

    answers.forEach((position, index) => {
      Object.keys(position).forEach(answerId => {
        if (occurrencesByPosition[answerId]) {
          occurrencesByPosition[answerId][index] += position[answerId];  // Count occurrences per position
        }
      });
    });

    // 'Occurrences by Position:' occurrencesByPosition

    // Calculate the weighted sum for each answerId
    Object.keys(occurrencesByPosition).forEach(answerId => {
      const weightedTotal = occurrencesByPosition[answerId].reduce((acc, val, index) => {
        return acc + (val * (index + 1));
      }, 0);
      this.weightedTotalById[answerId] = weightedTotal;
    });

    // Sort the answerIds based on the weighted total in ascending order
    const sortedQuestionItems = orderBy(
      this.question.items,
      item => this.weightedTotalById[item.id],  // Sort based on the weighted total from weightedTotalById
      'asc'  // Sort in ascending order (smallest total first)
    );

    this.question.items = sortedQuestionItems;

    // Get Tied Places

    let lastWeightedValue = null;
    let currentPlacement = 1;
    let currentTiedItems: string[] = [];

    sortedQuestionItems.forEach((item, index) => {
      const currentWeightedValue = this.weightedTotalById[item.id];

      // If this is the same as the last item's weighted value, it's a tie
      if (currentWeightedValue === lastWeightedValue) {
        currentTiedItems.push(item.id);
        this.tiedPlaces[currentPlacement] = [...currentTiedItems];
      } else {
        // Reset the tied items for the next group
        currentTiedItems = [item.id];
        currentPlacement = index + 1; // Adjust placement based on the index (1-based)
      }

      // Update lastWeightedValue to be the current item's value for the next comparison
      lastWeightedValue = currentWeightedValue;
    });

  }

  isTied(answerId: string): boolean {
    return Object.values(this.tiedPlaces).some(tiedItems => tiedItems.includes(answerId));
  }

  getPlacement(answerId: string, index: number): number {
    // Check if this answerId is tied
    if (this.tiedPlaces[index] && this.tiedPlaces[index].includes(answerId)) {
      return index; // Return the previous position if tied
    }
    return index + 1; // Otherwise return normal placement
  }

}
