export class AppUserUID {
  uuid: string;
  isPublic: boolean;
  userId: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.uuid) {
      this.uuid = obj.uuid;
    }
    if (obj.isPublic) {
      this.isPublic = obj.isPublic;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
  }
}
