import {Injectable} from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {StorageDataService} from '../../../../services/storage-data.service';
import {CommonService} from '../../../../core/common.service';
import {IDocumentPathParams} from '../../../../services/event-mode-api.service';
import {ITaskFile, TASK_INSIDE_COLLECTIONS, TaskObject} from './task-model/task';
import {ITEM_MARKER} from '../../shared/container-interface';
import {EventsDataService} from '../../../../services/events-data.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService  extends AbstractContainerService {

  constructor(private storageDataService: StorageDataService,
              private dataService: EventsDataService,
              private common: CommonService) {
    super();
  }

  async save(storagePath: string, taskObject: TaskObject, documentPathParams?: IDocumentPathParams): Promise<any> {
    const deletedIds: string[] = [];
    const data: ITaskFile[] = taskObject.files ?? [];
    const path = `${storagePath}/task`;
    for (const it of data) {
      if (it.id.startsWith(ITEM_MARKER.NEW)) {
        const fileName = this.common.utils.addTimeToFileName(it.id.replace(ITEM_MARKER.NEW, ''));
        await this.storageDataService.uploadAnyObjectToStorageByDataPath(path, fileName, it.src, it.metaType)
          .then(snapshot => snapshot.ref.getDownloadURL()
            .catch(e => this.common.log.error(e))
            .then(url => it.src = url))
          .catch(e => this.common.log.error(e));
      } else if (it.id.startsWith(ITEM_MARKER.DELETED)) {
        const fileName = this.common.utils.extractFileNameFromUrl(it.src);
        await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
          .catch(e => this.common.log.error(e));
      }
      it.id = it.id.replace(ITEM_MARKER.NEW, '');
      if (it.id.startsWith(ITEM_MARKER.DELETED)) {
        deletedIds.push(it.id);
      }
    }
    for (const id of deletedIds) {
      const index = data.findIndex(it => it.id === id);
      data.splice(index, 1);
    }
    return Promise.resolve(taskObject);
  }

  async delete(storagePath: string, taskObject: TaskObject, documentPathParams?: IDocumentPathParams): Promise<boolean> {
    const data = taskObject.files ?? [];
    const path = `${storagePath}/task`;
    for (const it of (data || [])) {
      const fileName = this.common.utils.extractFileNameFromUrl(it.src);
      await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
        .catch(e => this.common.log.error(e));
    }
    await this.dataService.deleteContainerDocumentWithInsideCollectionsWithStoreData(documentPathParams, TASK_INSIDE_COLLECTIONS)
      .catch(e => this.dataService.common.log.error(e));
    return Promise.resolve(true);
  }

  async relocateTo(oldDocumentPathParams: IDocumentPathParams, newDocumentPathParams: IDocumentPathParams): Promise<boolean> {
    return await this.dataService.relocateContainerDocumentInsideCollections(
      oldDocumentPathParams,
      newDocumentPathParams,
      TASK_INSIDE_COLLECTIONS.map(cl => cl.collectionName))
      .catch(e => this.dataService.common.log.error(e));
  }

}
