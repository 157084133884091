import {Injectable, Injector} from '@angular/core';
import {EventsDataService} from '../../../services/events-data.service';
import {CONTAINER_ITEM_TYPE, ContentContainer, ContentContainerItem} from '../../../model/content/ContentContainer';
import {cloneDeep, isEmpty} from 'lodash';
import {COMPONENTS, ITEM_MARKER} from '../shared/container-interface';
import {CommonService} from '../../../core/common.service';
import {
  EventModeApiService,
  IDocumentPathParams,
  IExtDocumentPathParams,
  IRelocateParams
} from '../../../services/event-mode-api.service';
import {TagsDataService} from '../../tags-reference/tags-service/tags-data.service';
import {APP_MODE, LoginService} from '../../../login/login.service';
import {CONTENT_PATH_TYPE} from '../../../model/content/AbstractContent';
import {TimeLineService} from '../../../services/time-line.service';
import {CHANGE_LOG_VALUE_TYPE} from '../../../services/std-api-service';

@Injectable({
  providedIn: 'root'
})
export class ContentContainerService {

  constructor(private injector: Injector,
              private dataService: EventsDataService,
              private apiService: EventModeApiService,
              private tagsDataService: TagsDataService,
              private loginService: LoginService,
              private timeLineService: TimeLineService,
              private common: CommonService) { }

  private getItemService(type: CONTAINER_ITEM_TYPE) {
    const componentService: any = COMPONENTS[type]?.componentService;
    if (componentService) {
      return this.injector.get(componentService);
    }
  }

  private buildStoragePath(eventId: string, contentId: string, containerId: string) {
    return `${eventId}/${contentId}/${containerId}`;
  }

  add(content: ContentContainer, saveAsDraft?: boolean): Promise<string> {
    const cc = new ContentContainer(content);
    let _saveAsDraft = saveAsDraft;
    if (this.loginService.educationMode && !_saveAsDraft) {
      _saveAsDraft = true;
      cc.dirty = true;
    }
    const items = cloneDeep(cc.items);
    delete cc.items;
    return this.dataService.addContentContainer(cc.eventId, cc, _saveAsDraft).then(async id => {
      cc.id = id;
      cc.items = items;
      // add items
      const deletedIds: string[] = [];
      for (const item of (cc.items || [])) {
        if (item.id.startsWith(ITEM_MARKER.DELETED)) {
          deletedIds.push(item.id);
          continue;
        }
        item.id = item.id.replace(ITEM_MARKER.NEW, '');
        const service = this.getItemService(item.type);
        if (service) {
          await service.save(this.buildStoragePath(cc.eventId, cc.id, item.id), item.data).then(result => item.data = result);
        }
      }
      cc.items = cc.items.filter(it => !deletedIds.includes(it.id));
      cc.itemsTypes = cc.getItemsTypes();
      const updateTime = new Date().getTime();
      return this.dataService.editContentContainer(cc.eventId, cc.id, cc, _saveAsDraft, updateTime)
        .then(async () => {
          if (this.loginService.educationMode && _saveAsDraft) {
            const info = !content.id ? {draftWithoutOriginal: true} : {sourceIsDraft: true};
            await this.apiService.saveChangeLogValue(content.eventId, {
              sourceId: cc.id,
              sourceType: content.type,
              changeType: !content.id ? CHANGE_LOG_VALUE_TYPE.ADD : CHANGE_LOG_VALUE_TYPE.EDITED,
              parentId: content.parentId,
              title: content.title,
              ...info
            });
          }
          return cc.id;
        })
        .catch(e => this.common.log.error(e));
    }).catch(e => this.common.log.error(e));
  }

  async update(content: ContentContainer): Promise<string> {
    const cc = new ContentContainer(content);
    const saveAsDraft = this.loginService.educationMode && cc.dirty;
    if (saveAsDraft && !(await this.isExistsDraft(cc))) {
      return this.add(cc, true);
    }
    const items = cloneDeep(cc.items);
    delete cc.items;
    const updateTime = new Date().getTime();
    return this.dataService.editContentContainer(cc.eventId, cc.id, cc, saveAsDraft, updateTime).then(async () => {
      cc.items = items;
      // update items
      const deletedIds: string[] = [];
      for (const item of (cc.items || [])) {
        const service = this.getItemService(item.type);
        if (service) {
          if (item.id.startsWith(ITEM_MARKER.NEW)) {
            const path = this.buildStoragePath(cc.eventId, cc.id, item.id.replace(ITEM_MARKER.NEW, ''));
            await service.save(path, item.data)
              .then(result => item.data = result);
          } else if (item.id.startsWith(ITEM_MARKER.DELETED)) {
            const documentPathParams = {
              eventId: content.eventId,
              sectionId: content.parentId,
              contentId: content.id,
              containerId: item.id.replace(ITEM_MARKER.DELETED, '')
            };
            const path = this.buildStoragePath(cc.eventId, cc.id, item.id.replace(ITEM_MARKER.DELETED, ''));
            // todo: add delete item tags like as #147
            await service.delete(path, item.data, documentPathParams);
          } else {
            const documentPathParams: IDocumentPathParams = {
              eventId: content.eventId,
              sectionId: content.parentId,
              contentId: content.id,
              containerId: item.id
            };
            const path = this.buildStoragePath(cc.eventId, cc.id, item.id);
            await service.save(path, item.data, documentPathParams).then(result => item.data = result);
          }
        }
        item.id = item.id.replace(ITEM_MARKER.NEW, '');
        if (item.id.startsWith(ITEM_MARKER.DELETED)) {
          deletedIds.push(item.id);
        }
      }
      cc.items = cc.items.filter(it => !deletedIds.includes(it.id));
      cc.itemsTypes = cc.getItemsTypes();
      return this.dataService.editContentContainer(cc.eventId, cc.id, cc, saveAsDraft, updateTime)
        .then(async () => {
          if (this.loginService.educationMode && saveAsDraft) {
            await this.apiService.saveChangeLogValue(content.eventId, {
              sourceId: cc.id,
              sourceType: content.type,
              changeType: CHANGE_LOG_VALUE_TYPE.EDITED,
              parentId: content.parentId,
              title: content.title,
              sourceIsDraft: true
            });
          }
          return cc.id;
        })
        .catch(e => this.common.log.error(e));
    }).catch(e => this.common.log.error(e));
  }

  async checkDeleteConstraint(content: ContentContainer): Promise<boolean> {
    let canBeDeleted = true;
    for (const item of (content.items || [])) {
      const componentService: any = COMPONENTS[item.type]?.componentService;
      if (componentService) {
        const service = this.injector.get(componentService);
        if (service) {
          const documentPathParams: IDocumentPathParams = {
            eventId: content.eventId,
            sectionId: content.parentId,
            contentId: content.id,
            containerId: item.id
          };
          canBeDeleted = await service.checkDeletedConstraint(documentPathParams);
          if (!canBeDeleted) {
            return Promise.resolve(false);
          }
        }
      }
    }
    return Promise.resolve(true);
  }

  // delete items
  async deleteItems(content: ContentContainer) {
    for (const item of (content.items || [])) {
      const componentService: any = COMPONENTS[item.type]?.componentService;
      if (componentService) {
        const service = this.injector.get(componentService);
        if (service) {
          const documentPathParams: IDocumentPathParams = {
            eventId: content.eventId,
            sectionId: content.parentId,
            contentId: content.id,
            containerId: item.id
          };
          await service.delete(this.buildStoragePath(content.eventId, content.id, item.id), item.data, documentPathParams);
        }
      }
    }
  }

  async delete(content: ContentContainer, withoutCheckDraft?: boolean): Promise<string> {
    if (this.loginService.educationMode) {
      const deleteDraft = this.loginService.educationMode && content.dirty;
      if (deleteDraft) {
        const isExistsOriginal = await this.isExistsOriginal(content);
        await this.deleteItems(content);
        return this.dataService.deleteContentContainer(content.eventId, content.parentId, content.id, true)
          .then(async () => {
            const info = !isExistsOriginal ? {draftWithoutOriginal: true} : {sourceIsDraft: true};
            await this.apiService.saveChangeLogValue(content.eventId, {
              sourceId: content.id,
              sourceType: content.type,
              changeType: CHANGE_LOG_VALUE_TYPE.DELETE,
              parentId: content.parentId,
              title: content.title,
              ...info
            });
            return content.id;
          });
      } else {
        const existsDraft = !withoutCheckDraft ? (await this.isExistsDraft(content)) : null;
        if (existsDraft) {
          await this.delete(existsDraft);
        }
        await this.deleteItems(content);
        return this.dataService.deleteContentContainer(content.eventId, content.parentId, content.id, false)
          .then(async () => {
            await this.apiService.saveChangeLogValue(content.eventId, {
              sourceId: content.id,
              sourceType: content.type,
              changeType: CHANGE_LOG_VALUE_TYPE.DELETE,
              parentId: content.parentId,
              title: content.title
            });
            return content.id;
          });
      }
    } else {
      await this.deleteItems(content);
      return (this.loginService.applicationMode === APP_MODE.NOTES ? this.tagsDataService.deleteContentTags(content) : Promise.resolve())
        .then(() => this.dataService.deleteContentContainer(content.eventId, content.parentId, content.id, false))
        .then(() => content.id);
    }
  }

  relocateTo(content: ContentContainer, params: IRelocateParams) {
    return this.dataService.relocateContentContainer(content, params).then(async () => {
      if (content.parentId !== params.parentId && !content.dirty) {
        // relocate items collections
        for (const item of (content.items || [])) {
          const oldDocumentPathParams: IDocumentPathParams = {
            eventId: content.eventId,
            sectionId: content.parentId,
            contentId: content.id,
            containerId: item.id
          };
          const newDocumentPathParams: IDocumentPathParams = {
            eventId: params.eventId,
            sectionId: params.parentId,
            contentId: content.id,
            containerId: item.id
          };
          const service = this.getItemService(item.type);
          if (service) {
            if (typeof service.relocateTo !== 'undefined') {
              await service.relocateTo(oldDocumentPathParams, newDocumentPathParams);
            }
          }
        }
      }
      if (this.loginService.educationMode) {
        if (!content.dirty) {
          const draftContent = await this.isExistsDraft(content);
          if (draftContent) {
            return this.dataService.relocateContentContainer(draftContent, params);
          }
        } else {
          const sourceContent = await this.dataService.isExistsContent(content, CONTENT_PATH_TYPE.DEFAULT);
          if (sourceContent) {
            return this.dataService.relocateContentContainer(sourceContent, params);
          }
        }
      }
      return content.id;
    });
  }

  protected async loadDocumentItemsMetadata(content: ContentContainer) {
    const rItems = cloneDeep(content.items);
    const params: IExtDocumentPathParams = {
      clientId: this.loginService.client_id$.getValue(),
      eventId: content.eventId,
      sectionId: content.parentId,
      contentId: content.id,
      location: this.apiService.getDBPath(),
      containerId: null,
      dirty: content.dirty
    };

    for (const item of rItems) {
      if (COMPONENTS[item.type]?.urlToBASE64Scheme) {
        params.containerId = item.id;
        const itemObject = await this.dataService.getEncodedBase64ContentContainerItem(params, COMPONENTS[item.type].urlToBASE64Scheme);
        const loadedItem = new ContentContainerItem(itemObject);
        item.data = loadedItem.data;
      }
    }
    return rItems;
  }

  isExistsDraft(content: ContentContainer) {
    return this.dataService.isExistsContent(content, CONTENT_PATH_TYPE.DRAFT);
  }

  isExistsOriginal(content: ContentContainer) {
    return this.dataService.isExistsContent(content, CONTENT_PATH_TYPE.DEFAULT);
  }

  /**
   * return clone deep input content with direct links or with loaded links as base64 data.
   * @param content
   */
  async prepareContentMetadata(content: ContentContainer) {
    if (!this.loginService.educationMode) {
      return cloneDeep(content);
    }
    if (this.loginService.educationMode && !content.releaseVersion) {
      return cloneDeep(content);
    } else if (this.loginService.educationMode && content.releaseVersion && !content.dirty) {
      const existDraft = !isEmpty((this.timeLineService.sectionContentsObjects.dirtyList || [])
        .find(cn => cn.id === content.id));
      if (!existDraft) {
        const clContent = cloneDeep(content);
        clContent.items = await this.loadDocumentItemsMetadata(clContent);
        delete clContent.releaseVersion;
        clContent.dirty = true;
        return clContent;
      } else {
        return cloneDeep(content);
      }
    } else {
      return cloneDeep(content);
    }
  }
}
