import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CDK_DRAG_CONFIG, CdkDragDrop} from '@angular/cdk/drag-drop';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {ITEM_MARKER} from '../../../shared/container-interface';
import {
  BACKGROUND_POSITION,
  BACKGROUND_SIZE,
  IGallerySettings,
  IPicture,
  IPictureGallery,
  IPictureGalleryDialogData
} from '../picture-gallery-model/picture-gallery-model';
import {Constants, ILanguageParams, TRIPLE} from '../../../../../core/constants';
import {BehaviorSubject, filter, take} from 'rxjs';
import {CommonService} from '../../../../../core/common.service';
import {COUNTRY_LANGUAGE} from '../../../../../core/language-constants';
import {StdComponent} from '../../../../../core/std-component';
import {UtilsService} from '../../../../../core/utils.service';

const dragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000000000
};

@Component({
  selector: 'app-picture-gallery-editor-dialog',
  templateUrl: './picture-gallery-editor-dialog.component.html',
  styleUrls: ['./picture-gallery-editor-dialog.component.scss'],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: dragConfig }]
})
export class PictureGalleryEditorDialogComponent extends StdComponent implements OnInit {
  readonly ITEM_MARKER = ITEM_MARKER;
  POSITION = BACKGROUND_POSITION;
  SIZE = BACKGROUND_SIZE;

  allPictures: IPicture[] = [];
  defaultPictures: IPicture[] = [];
  pictures: IPicture[] = [];
  settings: IGallerySettings = {
    backgroundPosition: BACKGROUND_POSITION.CENTER,
    backgroundSize: BACKGROUND_SIZE.CONTAIN
  };
  startDrag = false;
  picturesLength = 0;
  languageParams: ILanguageParams;
  showPrimaryPicture = false;
  private uppy: any;
  picturesTitleDefault: string;
  picturesTitleCurrent: string;
  dataHash: string;
  isModify = false;
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<PictureGalleryEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IPictureGalleryDialogData,
              private common: CommonService,
              private uploadService: UploadService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    dialogRef.updateSize('680px');
    this.allPictures = UtilsService.wrapObjectToProxy(cloneDeep(data.pictures || []), this.dataChangesHandler);
    this.settings = UtilsService.wrapObjectToProxy(cloneDeep(data.settings ?? this.settings), this.dataChangesHandler);
    this.dataHash = this.getGalleryHash(this.allPictures, this.settings);
    this.data.languageParams$
      .pipe(filter(p => !isEmpty(p)), take(1))
      .subscribe(params => {
        this.languageParams = cloneDeep(params);
        this.refreshPictures();
        this.showPrimaryPicture = this.languageParams?.usedMultilingualContent &&
          this.languageParams?.defaultLanguage !== this.languageParams?.currentLanguage;
      });
    this.setPicturesLength();
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get saveDisabled() {
    if (!this.languageParams?.usedMultilingualContent) {
      return false;
    }
    const pictureList = this.allPictures.filter(p => !p.id.startsWith(ITEM_MARKER.DELETED));
    const langList = pictureList.reduce((acc, item) => {
      const l = item.lang ?? this.languageParams.defaultLanguage;
      if (!acc[l]) {
        acc[l] = 1;
      } else {
        acc[l]++;
      }
      return acc;
    }, {});
    const defCount = langList[this.languageParams.defaultLanguage] ?? 0;
    return !Object.keys(langList).filter(lang => lang !== this.languageParams.defaultLanguage)
      .every(lang => langList[lang] === defCount);
  }

  private refreshPictures() {
    this.defaultPictures = this.allPictures.filter(p => !p.lang || p.lang === this.languageParams.defaultLanguage);
    if (this.languageParams.usedMultilingualContent && this.languageParams.defaultLanguage !== this.languageParams.currentLanguage) {
      this.pictures = this.allPictures.filter(p => p.lang === this.languageParams.currentLanguage);
    } else {
      this.pictures = this.defaultPictures;
    }
  }

  ngOnInit(): void {
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.IMAGE, null, result => {
      this.addImageToDataSource(result);
      this.uploadService.closeModal(this.uppy);
    }, null, true);
    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.dataHash !== this.getGalleryHash(this.allPictures, this.settings);
      });
  }

  onOkClick(): void {
    const gallery: IPictureGallery = {
      pictures: this.allPictures,
      settings: this.settings
    };
    this.dialogRef.close(gallery);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDestroy() {
    this.uppy.close();
  }

  private setPicturesLength() {
    this.picturesLength = (this.pictures || []).filter(p => !p.id.startsWith(ITEM_MARKER.DELETED)).length;
  }

  getAllListConnections(id) {
    const connections = [];
    for (const item of this.pictures) {
      if (item.id !== id) {
        connections.push(item.id);
      }
    }
    return connections;
  }

  startDragImg() {
    this.startDrag = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    this.startDrag = false;
    // event.previousContainer.id = html element id
    const imgDropIndexId = event.previousContainer.id;
    // event.container.id = html element id
    const imgReceiveIndexId = event.container.id;
    if (imgReceiveIndexId === imgDropIndexId) {
      return;
    }
    const imgDropIndex = this.allPictures.findIndex(p => p.id === imgDropIndexId);
    const imgReceiveIndex = this.allPictures.findIndex(p => p.id === imgReceiveIndexId);
    const movedItem = this.allPictures[imgDropIndex];
    this.allPictures.splice(imgDropIndex, 1);
    this.allPictures.splice(imgReceiveIndex, 0, movedItem);
    this.refreshPictures();
  }

  removeImage(id) {
    const index = this.allPictures.findIndex(obj => obj['id'] === id);
    if (index > -1) {
      if (this.allPictures[index].id.startsWith(ITEM_MARKER.NEW)) {
        this.allPictures.splice(index, 1);
      } else {
        this.allPictures[index].id = `${ITEM_MARKER.DELETED}${this.allPictures[index].id}`;
      }
    }
    this.refreshPictures();
    this.setPicturesLength();
  }

  uploadImage() {
    this.uploadService.openUploadWindow(this.uppy);
  }

  addImageToDataSource(list) {
    let changed = false;
    const langObject = () => this.languageParams.usedMultilingualContent ? {lang: this.languageParams.currentLanguage} : {};
    for (const item of list) {
      const prefix = this.common.utils.generateRandomString(4).toLowerCase();
      const id = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}.${item.extension}`;
      if (!this.allPictures.find(p => p.id.includes(id))) {
        this.allPictures.push(merge({
          id: `${ITEM_MARKER.NEW}${id}`,
          src: item.response,
        }, langObject()));
        changed = true;
      }
    }
    if (this.languageParams.usedMultilingualContent && changed &&
      this.languageParams.defaultLanguage === this.languageParams.currentLanguage) {
      this.allPictures.filter(p => !p.lang).forEach(p => p.lang = this.languageParams.currentLanguage);
    }
    this.refreshPictures();
    if (!this.languageParams.usedMultilingualContent && changed) {
      const pIds = this.pictures.map(p => p.id);
      this.allPictures.filter(p => !pIds.includes(p.id)).forEach(p => p.id = `${ITEM_MARKER.DELETED}${p.id}`);
      this.allPictures.filter(p => pIds.includes(p.id)).forEach(p => delete p.lang);
    }
    this.setPicturesLength();
  }

  onLanguageChange(lang) {
    this.languageParams.currentLanguage = lang;
    this.refreshPictures();
    this.showPrimaryPicture = this.languageParams?.usedMultilingualContent &&
      this.languageParams?.defaultLanguage !== this.languageParams?.currentLanguage;
    this.setPicturesLength();
    const dl = Object.values(COUNTRY_LANGUAGE).find(o => o.languageISOCode === this.languageParams.defaultLanguage);
    const cl = Object.values(COUNTRY_LANGUAGE).find(o => o.languageISOCode === this.languageParams.currentLanguage);
    this.picturesTitleDefault = this.common.i18n('gallery.pictures.default.language.title',
      {language: this.common.i18n(dl.name)});
    this.picturesTitleCurrent = this.common.i18n('gallery.pictures.current.language.title',
      {language: this.common.i18n(cl.name)});
  }

  private getGalleryHash(pictures, settings) {
    const p = UtilsService.jsonSorted(pictures);
    const s = UtilsService.jsonSorted(settings);
    return UtilsService.md5(p + s);
  }
}
