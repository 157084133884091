import {Component, ElementRef, Inject, Injector, OnInit, signal} from '@angular/core';
import {Exam} from '../../exam-model/exam';
import {StdComponent} from '../../../core/std-component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../core/common.service';
import {QuestionCardsService} from '../../../question-cards/question-card-service/question-cards.service';
import {Constants} from '../../../core/constants';
import {ExamsService} from '../../exams-service/exams.service';

const DIALOG_HEIGHT = '80vh';

@Component({
  selector: 'app-exams-reference',
  templateUrl: './exams-reference.component.html',
  styleUrl: './exams-reference.component.scss'
})
export class ExamsReferenceComponent extends StdComponent implements OnInit {

  loaded = signal(false);
  selectedExam: Exam;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<ExamsReferenceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public common: CommonService,
              public examsService: ExamsService,
              public dialog: MatDialog,
              private elementRef: ElementRef) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.updateSize('80%', DIALOG_HEIGHT);
    this.elementRef.nativeElement.style.setProperty('--max-height', DIALOG_HEIGHT);
    dialogRef.disableClose = true;

    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE) {
          this.onNoClick();
        }
      });
  }

  ngOnInit() {
    this.examsService.loadData()
      .then(() => this.loaded.set(true));
  }

  onOkClick() {
    this.dialogRef.close({exam: this.selectedExam});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDestroy() {
    this.examsService.unsubscribeAll();
  }

}
