import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../core/common.service';
import {UsersDataService} from '../../services/users-data.service';
import {TranslateModule} from '@ngx-translate/core';
import {ClientConfig} from '../../model/ClientConfig';
import {MatDialog} from '@angular/material/dialog';
import {Constants} from '../../core/constants';
import {LoginService} from '../../login/login.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatTooltip} from '@angular/material/tooltip';
import {AsyncPipe, NgIf} from '@angular/common';
import {UserAvatarComponent} from '../../user-avatar/user-avatar.component';
import {TimeLineService} from '../../services/time-line.service';
import {UserAccountDialogComponent} from '../user-account-dialog/user-account-dialog.component';
import {cloneDeep} from 'lodash';

@Component({
  standalone: true,
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  imports: [
    MatTooltip,
    TranslateModule,
    NgIf,
    UserAvatarComponent,
    AsyncPipe
  ],
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  @Input() clientConfig: ClientConfig;
  isAzure = false;
  @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger;
  isMobile$ = this.common.isMobile$();

  constructor(private common: CommonService,
              public dialog: MatDialog,
              public loginService: LoginService,
              public userDataService: UsersDataService,
              public timelineService: TimeLineService) {
    const loginProvider = this.loginService.getCurrentProviderId();
    this.isAzure = loginProvider === Constants.PROVIDER_HKVBS_MICROSOFT;
  }

  ngOnInit(): void {
  }

  get currentUser() {
    return this.loginService.getAppUser();
  }

  openMenu() {
    const dialogRef = this.dialog.open(UserAccountDialogComponent, {
      width: '400px',
      data: {
        appUser: cloneDeep(this.loginService.getAppUser()),
        clientConfig: this.clientConfig
      }
    });
  }
}
