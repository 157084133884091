import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import * as ui from '../../../../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EMBEDDED_FRAME_PROVIDER, IEmbeddedFrame, PROVIDER_SETTINGS} from '../embedded-frame-model/embedded-frame-model';
import {cloneDeep} from 'lodash';
import {CommonService} from '../../../../../core/common.service';
import {UtilsService} from '../../../../../core/utils.service';
import {StdComponent} from '../../../../../core/std-component';
import {Constants, TRIPLE} from '../../../../../core/constants';

@Component({
  selector: 'app-embedded-frame-editor-dialog',
  templateUrl: './embedded-frame-editor-dialog.component.html',
  styleUrls: ['./embedded-frame-editor-dialog.component.scss']
})
export class EmbeddedFrameEditorDialogComponent extends StdComponent implements OnInit, OnDestroy {

  readonly EMBEDDED_FRAME_PROVIDER = EMBEDDED_FRAME_PROVIDER;
  readonly PROVIDER_SETTINGS = PROVIDER_SETTINGS;

  embeddedData: IEmbeddedFrame;
  private _embeddedDataString: string;
  dataHash: string;
  isModify = false;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<EmbeddedFrameEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IEmbeddedFrame,
              private common: CommonService,
              private store: Store<fromRoot.State>) {
    super(injector);
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    dialogRef.addPanelClass('timeline');
    this.embeddedData = cloneDeep(data);
    this._embeddedDataString = PROVIDER_SETTINGS[this.embeddedData?.provider]?.shortURL(this.embeddedData);
    this.dataHash = UtilsService.md5(this.embeddedDataString);
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get embeddedDataString(): string {
    return this._embeddedDataString;
  }

  set embeddedDataString(value: string) {
    this._embeddedDataString = value;
    this.isModify = this.dataHash !== UtilsService.md5(value);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  change() {
    if (!this.embeddedDataString) {
      this.embeddedData = null;
      return;
    }
    if (!this.embeddedData?.provider) {
      for (const provider of Object.values(PROVIDER_SETTINGS)) {
        const template = provider.providerTemplates.find(t => this.embeddedDataString.match(t.template));
        if (template) {
          const mr = this.embeddedDataString.match(template.template);
          if (mr) {
            this.embeddedData = new Object({}) as IEmbeddedFrame;
            this.embeddedData.provider = provider.providerName;
            for (let index = 0; index < template.model.length; index++) {
              const m = template.model[index];
              this.embeddedData[m.fieldName] = mr[m.indexOfMatching].replace(/\//g, '');
            }
            this.embeddedDataString = provider.shortURL(this.embeddedData);
            return;
          }
        }
      }
    }
  }

  onOkClick(): void {
    this.dialogRef.close({data: this.embeddedData});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openEmbeddedFrame(provide: EMBEDDED_FRAME_PROVIDER, openUrl: boolean) {
    const url = openUrl ?
      PROVIDER_SETTINGS[provide].embeddedURL(this.embeddedData) :
      `https://${PROVIDER_SETTINGS[provide].site}`;
    window.open(url, '_blank');
  }
}
