import {IDocumentPathParams} from '../../../services/event-mode-api.service';

export abstract class AbstractContainerService {
  /**
   * @param storagePath - path to data in storage (file, image and etc.).
   * @param data - document data of custom type
   * @param documentPathParams - path params to document (e.g. preload document for analyze prev data)
   * @param saveAsTemplate
   * @param draft
   * @return data - modified input data or input data as is if not need modify
   */
  abstract save(storagePath: string, data, documentPathParams?: IDocumentPathParams,
                saveAsTemplate?: boolean, draft?: boolean): Promise<any>;

  /**
   * @param storagePath - path to data in storage (file, image and etc.).
   * @param data - document data of custom type
   * @param documentPathParams - path params to document for delete extended data inside document (collections, documents, other garbage)
   * @param isDeleteTemplate
   * @param draft
   */
  abstract delete(storagePath: string, data, documentPathParams?: IDocumentPathParams,
                  isDeleteTemplate?: boolean, draft?: boolean): Promise<boolean>;

  /**
   * relocate collections of container when main content relocate to another path
   * @param oldDocumentPathParams
   * @param newDocumentPathParams
   * @param draft
   */
  relocateTo(oldDocumentPathParams: IDocumentPathParams, newDocumentPathParams: IDocumentPathParams, draft?: boolean): Promise<boolean> {
    return Promise.resolve(true);
  }

  /**
   * call before delete for check can be container delete.
   * @param documentPathParams
   */
  checkDeletedConstraint(documentPathParams: IDocumentPathParams): Promise<boolean> {
    return Promise.resolve(true);
  }
}
