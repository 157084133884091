import {Component, Injector} from '@angular/core';
import {isEmpty} from 'lodash';
import {
  AbstractQuizQuestionAnswerMatchingEditorComponent
} from '../../shared/editor/abstract-quiz-question-answer-matching-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {IQuestionMatchingOptions} from '../question-matching.module';

@Component({
  selector: 'app-question-matching-editor',
  templateUrl: './question-matching-editor.component.html',
  styleUrls: ['./question-matching-editor.component.scss']
})
export class QuestionMatchingEditorComponent  extends AbstractQuizQuestionAnswerMatchingEditorComponent  {

  constructor(protected injector: Injector) {
    super(injector);
    this.displayedColumns = ['answer', 'answer_matching'];
  }

  get questionOptions(): IQuestionMatchingOptions {
    return super.questionOptions;
  }

  validate(): IValidated {
    if (isEmpty(this.dataSource.data) || isEmpty(this.dataSourceMatching.data)) {
      return {validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required')};
    }
    if (this.dataSource.data.some(o => isEmptyCaption(o.answer)) ||
         this.dataSourceMatching.data.some(o => isEmptyCaption(o.answerMatching))) {
      return {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
    }
    const correct = this.question.items.every(q => {
      const qUsed = (this.question.groupsCorrectAnswers || [])
        .filter(g => !isEmpty(g.items.filter(it => it.id === q.id))).length;
      return (this.question.groupsCorrectAnswers || []).length === qUsed;
    });
    if (!correct) {
      return {validated: false, warning: this.common.i18n('question.editor.warning.not.all.matches.are.filled.in')};
    }
    if (this.questionOptions.simpleMatching) {
      for (const g of this.question.groupsCorrectAnswers || []) {
        const arr: string[] = [];
        for (const a of g.items) {
          if (a.matching.every(matchingId => !arr.find(id => id === matchingId))) {
            a.matching.forEach(matchingId => arr.push(matchingId));
          } else {
            return {validated: false, warning: this.common.i18n('question.editor.warning.answers.cannot.be.duplicated')};
          }
        }
      }
    }
    return {validated: true};
  }
}
