import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import { AuthService, User, UsersService } from '@ninescopesoft/core';
import {isEmpty} from 'lodash';
import * as ui from '../../actions/ui';
import {CommonService} from '../../core/common.service';
import {Constants} from '../../core/constants';
import {StdComponent} from '../../core/std-component';
import {UPLOAD_TYPE, UploadService} from '../../core/upload.service';
import {CreateEventService} from '../../create-event/create-event.service';
import * as fromRoot from '../../reducers';
import {RootLocalStorageService} from '../../core/root-local-storage.service';
import {ToasterService} from '../../core/toaster.service';

@Component({
  selector: 'app-event-create',
  templateUrl: './event-create.component.html',
  styleUrls: ['./event-create.component.scss']
})
export class EventCreateComponent extends StdComponent implements OnInit {

  uppy: any;

  eventName: string;
  eventDescription: string;
  eventState: 'public' | 'private' | 'draft' | 'link' = 'link';
  mode: 'event' | 'event_registration' | 'registration' = 'event';
  companyName;

  private _everybody_at_with_the_link_i18: {editDialog: string, eventFilterStatus: string, viewEventVisibility};
  private _on_dashboard_i18: {editDialog: string, eventFilterStatus: string, viewEventVisibility};

  users: User[] = [];
  eventStartDate = new Date().getTime();
  eventEndDate = new Date().getTime() + Constants.ONE_HOUR;

  start = false;
  end = false;
  eventLink: string;
  shortLink: string;
  logoLoaded = true;
  logoSrc;
  logoUploadResponse;

  get eventPreview() {
    return {
      eventId: '',
      name: this.eventName,
      description: this.eventDescription,
      state: this.eventState,
      presenters: this.users.map(user => ({
        email: user.email,
        picture: user.photoURL,
        fullName: user.displayName
      })),
      startDate: this.eventStartDate,
      endDate: this.eventEndDate,
      mode: this.mode,
      verticalBanner: this.logoSrc,
      dateMode: 0,
      defaultLanguage: Constants.TIMELINE_DEFAULT_LANGUAGE
    };
  }

  constructor(protected override injector: Injector,
              public dialogRef: MatDialogRef<EventCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public router: Router,
              public usersService: UsersService,
              private auth: AuthService,
              private translate: TranslateService,
              private common: CommonService,
              private createEventService: CreateEventService,
              private uploadService: UploadService,
              private store: Store<fromRoot.State>,
              public dialog: MatDialog,
              private toasterService: ToasterService,
              private localStorageService: RootLocalStorageService
              ) {
    super(injector);
    dialogRef.addPanelClass(['timeline', 'create-event-dialog']);
    dialogRef.updatePosition({ left: '24%' });
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this.common.setBlurBackground(true);
    const mnth = Constants.ONE_DAY * 30;
    this.eventEndDate = this.eventStartDate + mnth;
    this.eventName = this.common.i18n('event.create.dialog.new.event');
    this.companyName = common.getEnv().companyName;
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.SINGLE_IMAGE, null, (result) => {
      if (!isEmpty(result)) {
        this.logoUploadResponse = result[0];
        this.logoSrc = result[0].response;
      }
      this.uploadService.closeModal(this.uppy);
    });
    this.common.listenBackdropClick(dialogRef).pipe(this.takeUntilAlive()).subscribe();
  }

  private i18DomainCaptions(domain) {
    this._everybody_at_with_the_link_i18 = {editDialog: '', viewEventVisibility: '', eventFilterStatus: ''};
    this._everybody_at_with_the_link_i18.editDialog = this.common.i18n('edit_dialog.checkbox.event.public.by.link', {domain: domain});
    this._everybody_at_with_the_link_i18.eventFilterStatus = this.common.i18n('event.filter.status.public.by.link', {domain: domain});
    this._everybody_at_with_the_link_i18.viewEventVisibility = this.common.i18n('view_event.visibility.public.by.link', {domain: domain});
    this._on_dashboard_i18 = {editDialog: '', viewEventVisibility: '', eventFilterStatus: ''};
    this._on_dashboard_i18.editDialog = this.common.i18n('edit_dialog.checkbox.event.public.on.dashboard', {domain: domain});
    this._on_dashboard_i18.eventFilterStatus = this.common.i18n('event.filter.status.public', {domain: domain});
    this._on_dashboard_i18.viewEventVisibility = this.common.i18n('view_event.visibility.public', {domain: domain});
  }

  get everybody_at_with_the_link_i18() {
    return this._everybody_at_with_the_link_i18;
  }

  get on_dashboard_i18() {
    return this._on_dashboard_i18;
  }

  ngOnInit(): void {
    this.i18DomainCaptions(this.companyName);
    this.users = [new User(this.auth.user)];
  }

  eventNameChanged() {
    this.eventName = this.eventName.trim();
  }

  override onDestroy() {
    this.common.setBlurBackground(false);
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    this.uppy.close();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onStartClick() {
    this.start = true;
    const base64 = this.logoUploadResponse?.response ?? null;
    const presenters = this.users.map(u => u.email);
    this.createEventService.create(
      this.eventName,
      this.eventDescription,
      this.eventState,
      presenters,
      [],
      this.eventStartDate,
      this.eventEndDate,
      this.mode,
      base64,
      Constants.TIMELINE_DEFAULT_LANGUAGE,
      !!this.common.getEnv().features?.hideSelfLearningUsersDetailDefaultValue
    ).subscribe((shortLink) => {
      this.shortLink = shortLink;
      this.eventLink = `${window.location.origin}/event/${shortLink}`;
      this.end = true;
    });
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.eventLink);
    this.toasterService.pop('success', null, this.common.utils.i18n('link.copied.to.clipboard'));
  }

  goToEvent() {
    this.router.navigate(['event', this.shortLink])
      .then(() => this.onCancelClick());
    this.dialogRef.close();
  }

  uploadImage() {
    const src = !!this.logoSrc;
    if (!src) {
      this.uploadService.openUploadWindow(this.uppy);
    } else {
      this.logoSrc = null;
    }
    this.dialogRef.disableClose = true;
  }

  openAdvancedMode() {
    const stateAccordingEnum = {
      draft: 0,
      private: 1,
      public: 2,
      link: 3
    };

    const modeAccordingEnum = {
      event: 0,
      event_registration: 1,
      registration: 2
    };

    this.localStorageService.set('newEventParams', JSON.stringify({
      eventName: this.eventName,
      eventDescription: this.eventDescription,
      eventState: stateAccordingEnum[this.eventState],
      users: this.users.map(user => ({
        userId: user.id,
        email: user.email,
        picture: user.photoURL,
        fullName: user.displayName
      })),
      logo: this.logoUploadResponse?.response,
      eventStartDate: this.eventStartDate,
      eventEndDate: this.eventEndDate,
      mode: modeAccordingEnum[this.mode]
    }));

    this.router.navigate(['event', 'new', 'registration'])
      .then(() => this.onCancelClick());
  }

}
