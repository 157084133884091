import {environment} from '../environments/environment';
import {ApplicationConfig, ErrorHandler, importProvidersFrom} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {TranslationLoaderService} from './core/translation-loader-service';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireFunctionsModule, REGION} from '@angular/fire/compat/functions';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAnalyticsModule, CONFIG} from '@angular/fire/compat/analytics';
import {AngularFirePerformanceModule, INSTRUMENTATION_ENABLED, PerformanceMonitoringService} from '@angular/fire/compat/performance';
import {EffectsModule} from '@ngrx/effects';
import {EventsDataEffects} from './services/events-data-effects.service';
import {UsersDataEffects} from './services/users-data-effects.service';
import {DataEffects} from './effects/data';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from './core/core.module';
import {CoreModule as RootCoreModule} from '@ninescopesoft/core';
import {DirectivesModule} from './directives/directives.module';
import {AppRoutingModule} from './app-routing.module';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IconsModule} from './modules/icons/icons.module';
import {EventComponentsModule} from './event-components/event-components.module';
import {GetSupportDialogModule} from './modules/get-support-dialog/get-support-dialog.module';
import {TermsAndPrivacyModule} from './modules/terms-and-privacy-dialog/terms-and-privacy.module';
import {MainMenuFilesModule} from './modules/main-menu-files-dialog/main-menu-files.module';
import {ExportPrintDialogModule} from './modules/export-print-dialog/export-print-dialog.module';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ClientTermsAndPrivacyModule} from './modules/client-terms-and-privacy/client-terms-and-privacy.module';
import {EventCreateModule} from './event-create/event-create.module';
import {MtxColorpickerModule} from '@ng-matero/extensions/colorpicker';
import {NgOptimizedImage} from '@angular/common';
import {AuthHttpInterceptor} from './services/auth-http-interceptor';
import {GlobalErrorHandler} from './core/global-error-handler.service';
import {LoginService} from './login/login.service';
import {CanActivateAuthGuard} from './login/can-activate.authguard';
import {LoginGuard} from './login/login.guard';
import {UsersDataService} from './services/users-data.service';
import {EventsDataService} from './services/events-data.service';
import {GoogleAPIService} from './services/google-api.service';
import {EventApiService} from './services/event-api.service';
import {EventModeApiService} from './services/event-mode-api.service';
import {UserApiService} from './services/user-api.service';
import {ContentService} from './services/content.service';
import {RegistrationProcessService} from './services/registration-process.service';
import {RegistrationExchangeService} from './services/registration-exchange.service';
import {SelfLearningService} from './services/self-learning.service';
import {RecordTimestampService} from './services/record-timestamp.service';
import {provideEnvironmentNgxMask} from 'ngx-mask';

export function getAppConfig(): ApplicationConfig {
  return {
    providers: [
      importProvidersFrom(
        BrowserModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (httpClient: HttpClient) => new TranslationLoaderService(httpClient),
            deps: [HttpClient]
          }
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,
        EffectsModule.forRoot([
          EventsDataEffects,
          UsersDataEffects,
          DataEffects
        ]),
        FormsModule,
        CoreModule,
        RootCoreModule,
        DirectivesModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
          metaReducers, runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false,
          }
        }),
        BrowserAnimationsModule,
        IconsModule,
        GetSupportDialogModule,
        TermsAndPrivacyModule,
        MainMenuFilesModule,
        ExportPrintDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatTableModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatMenuModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatAutocompleteModule,
        ClientTermsAndPrivacyModule,
        EventComponentsModule,
        EventCreateModule,
        MtxColorpickerModule,
        NgOptimizedImage
      ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHttpInterceptor,
        multi: true,
      }, {
        provide: ErrorHandler, useClass: GlobalErrorHandler
      },
      {
        provide: REGION,
        useValue: 'europe-west6'
      },
      {
        provide: CONFIG,
        useValue: {
          send_page_view: false,
          allow_ad_personalization_signals: false,
          anonymize_ip: true
        }
        // provide: DEBUG_MODE,
        // useValue: true
      },
      PerformanceMonitoringService,
      {
        provide: INSTRUMENTATION_ENABLED,
        useValue: true
      },
      LoginService,
      CanActivateAuthGuard,
      LoginGuard,
      UsersDataService,
      EventsDataService,
      GoogleAPIService,
      EventApiService,
      EventModeApiService,
      UserApiService,
      ContentService,
      RegistrationProcessService,
      RegistrationExchangeService,
      SelfLearningService,
      RecordTimestampService,
      provideEnvironmentNgxMask(),
      {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
      provideHttpClient(withInterceptorsFromDi())
    ]
  };
}
