import { Component, Injector } from '@angular/core';
import { AbstractQuizQuestionAnswersEditorComponent } from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import { ILanguageParams } from '../../../../../../../../../src/app/core/constants';
import { EventQuestion } from '../../../../../../../../../src/app/model/EventQuestion';
import { IValidated } from '../../shared/quiz-quiestion-types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { START_VALUE_INDEX, END_VALUE_INDEX, CORRECT_VALUE_INDEX } from '../open-number-estimation-utils';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-question-open-number-estimation-editor',
  templateUrl: './question-open-number-estimation-editor.component.html',
  styleUrl: './question-open-number-estimation-editor.component.scss'
})
export class QuestionOpenNumberEstimationEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  form: FormGroup;
  isCheckedControl = new FormControl(false);
  definedCorrectAnswer: boolean = false;
  invalidValue: boolean = false;
  correctValue: number;

  readonly dataSourceSize = 3;


  constructor(
    protected injector: Injector,
    private fb: FormBuilder,
    private snackBar: MatSnackBar) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.question.useCorrectAnswers = true;


    this.form = this.fb.group({
      startValue: [
        this.dataSource.data[START_VALUE_INDEX]?.answer,
        [Validators.required]
      ],
      endValue: [
        this.dataSource.data[END_VALUE_INDEX]?.answer ,
        [Validators.required]
      ],
      correctValue: [
        this.dataSource.data[CORRECT_VALUE_INDEX]?.answer,
      ],
    });



    this.form.get('startValue')?.valueChanges.subscribe(value => {
      this.dataSource.data[START_VALUE_INDEX].answer = value;
    });

    this.form.get('endValue')?.valueChanges.subscribe(value => {
      this.dataSource.data[END_VALUE_INDEX].answer = value;
    });

    this.form.get('correctValue')?.valueChanges.subscribe(value => {
      if(this.dataSource.data.length === this.dataSourceSize){
        this.dataSource.data[CORRECT_VALUE_INDEX].answer = value;
      }
    });

    if(this.dataSource.data.length == 0){
      this.addAnswer();
      this.addAnswer();
    }

    this.definedCorrectAnswer = this.dataSource.data.length === this.dataSourceSize;

    this.isCheckedControl.setValue(this.definedCorrectAnswer);
  }


  validate(): IValidated {
    if(this.form){
      const startValue = this.form.get('startValue')?.value;
      const endValue = this.form.get('endValue')?.value;
      const correctValue = this.form.get('correctValue')?.value;

      if(startValue && (endValue || endValue === 0) && startValue >= endValue){
        return { validated: false };
      }

      if (correctValue && this.dataSource.data.length === this.dataSourceSize){
        if(correctValue <= startValue || correctValue >= endValue ) {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: 'snack-bar-error'
          });
          this.invalidValue = true;
          return { validated: false, warning: ''};
        }
      }

      this.invalidValue = false;

      return { validated: true };
    }

    return { validated: false };
  }




  defineCorrectAnswer():void {
    this.definedCorrectAnswer = !this.definedCorrectAnswer;

    if(this.definedCorrectAnswer){
      if(this.dataSource.data.length < this.dataSourceSize){
        this.addAnswer();
        this.dataSource.data[this.dataSourceSize - 1].correctAnswer = true;
      }

    }else{
      if(this.dataSource.data.length === this.dataSourceSize){
        this.deleteAnswer(this.dataSource.data[this.dataSourceSize - 1].id);
      }
    }
  }

}
