import {ChangeDetectorRef, Component, Inject, Injector, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UtilsService} from '../../core/utils.service';
import {StdComponent} from '../../core/std-component';
import {Constants, DEPENDENCY, ILanguageParams, UNION_BY} from '../../core/constants';
import {EventsDataService} from '../../services/events-data.service';
import {DependencyQuestionnaire} from '../../model/content/DependencyQuestionnaire';
import {EventQuestion} from '../../model/EventQuestion';
import {TimeLineService} from '../../services/time-line.service';
import {QUESTION_TYPES_COMPONENTS} from '../content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';
import {isEmpty} from 'lodash';

interface IQuestionnaireItem {contentId: string; questionnaireId: string; caption: string; questions: IQuestionItem[]; }
interface IQuestionItem {questionId: string; caption: string; answers: IAnswerItem[]; }
interface IAnswerItem {answerId: string; caption: string; }

@Component({
  selector: 'app-content-dependency-settings-dialog',
  templateUrl: './content-dependency-settings-dialog.component.html',
  styleUrls: ['./content-dependency-settings-dialog.component.scss']
})

export class ContentDependencySettingsDialogComponent extends StdComponent implements OnInit {
  readonly Constants = Constants;
  readonly UNION_BY = UNION_BY;
  readonly DEPENDENCY = DEPENDENCY;

  dependencyType: DEPENDENCY;
  questionnaireNameList: IQuestionnaireItem[] = [];
  questionNameList: IQuestionItem[] = [];
  answerNameList: IAnswerItem[] = [];
  dependencyContentItem: IQuestionnaireItem;
  dependencyContentId: string;
  dependencyQuestionnaireId: string;
  dependencyQuestionId: string;
  dependencyAnswerIdList: string[] = [];
  dependencyAnswersUnionBy: UNION_BY = UNION_BY.AND;
  eventId;
  languageParams: ILanguageParams = {
    defaultLanguage: this.timelineService.DEFAULT_LANGUAGE,
    currentLanguage: this.timelineService.DEFAULT_LANGUAGE,
    usedMultilingualContent: false,
    usedLanguages: []
  };

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<ContentDependencySettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private utils: UtilsService,
              private eventDataService: EventsDataService,
              private timelineService: TimeLineService,
              public changeDetector: ChangeDetectorRef) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.eventId = data.eventId;
    if (this.eventId) {
      this.loadQuestionnaireContents(this.eventId, new DependencyQuestionnaire(data.dependencyQuestionnaire));
    }
  }

  ngOnInit() {

  }

  onOkClick(): void {
    const dep = new DependencyQuestionnaire({
      dependencyType: this.dependencyType,
      contentId: this.dependencyContentId,
      questionnaireId: this.dependencyQuestionnaireId,
      questionId: this.dependencyQuestionId,
      answerIdList: this.dependencyAnswerIdList,
      answersUnionBy: this.dependencyAnswersUnionBy
  });
    this.dialogRef.close(dep);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  deleteDependency() {
    this.dialogRef.close({});
  }

  setUnion(item): string {
    return item === UNION_BY.OR ?
      this.utils.i18n('edit_dialog.question_dialog.input.dependency.union.or') :
      this.utils.i18n('edit_dialog.question_dialog.input.dependency.union.and');
  }

  loadQuestionnaireContents(eventId, dependency: DependencyQuestionnaire) {
    const checkDoubleTitle = (title: string) => this.questionnaireNameList.find(o => o.caption === title);
    const sections = this.timelineService.planeListContentSortedWithoutFixedAsSectionArray().map(s => s.id);
    let doubleTitleCounter = 0;
    this.eventDataService.loadQuestionnaireContents(eventId).then((list) => {
      (list || []).filter(cq => sections.includes(cq.parentId)).forEach(q => {
        const questions: IQuestionItem[] = [];
        for (const question of (Object.values(q.questions) as EventQuestion[]).sort(this.utils.comparator(Constants.ORDERINDEX))) {
          if (!QUESTION_TYPES_COMPONENTS[question.storypoint].canUseInDependency) {
            continue;
          }
          const answers: IAnswerItem[] = [];
          for (const answer of question.items) {
            answers.push({answerId: answer.id, caption: answer.getAnswerByLanguage(this.languageParams)});
          }
          questions.push({questionId: question.id, caption: question.getCaptionByLanguage(this.languageParams), answers: answers});
        }
        if (!isEmpty(questions)) {
          const title = this.utils.i18n(q.title);
          this.questionnaireNameList.push({
            contentId: q.contentId ?? q.id,
            questionnaireId: q.id,
            caption: !checkDoubleTitle(title) ? title : `${title} (${++doubleTitleCounter})`,
            questions: questions
          });
        }
      });
      if (dependency) {
        this.dependencyContentItem = this.questionnaireNameList
          .find(o => o.contentId === (dependency.contentId ?? o.questionnaireId) && o.questionnaireId === dependency.questionnaireId);
        if (this.dependencyContentItem) {
          this.dependencyContentId = this.dependencyContentItem.contentId;
          this.dependencyQuestionnaireId = this.dependencyContentItem.questionnaireId;
          this.reloadQuestions(this.dependencyContentItem);
          this.reloadAnswers(dependency.questionId);
          this.dependencyType = dependency.dependencyType;
          this.dependencyQuestionId = dependency.questionId;
          this.dependencyAnswerIdList = dependency.answerIdList;
          this.dependencyAnswersUnionBy = dependency.answersUnionBy;
        }
      }
    });
  }

  reloadQuestions(item) {
    this.dependencyContentId = item?.contentId;
    this.dependencyQuestionnaireId = item?.questionnaireId;
    this.questionNameList = item ? item.questions : [];
    this.answerNameList = [];
    this.dependencyQuestionId = null;
    this.dependencyAnswerIdList = null;
  }

  reloadAnswers(dependencyQuestionId) {
    const obj = this.questionNameList.find(o => o.questionId === dependencyQuestionId);
    this.answerNameList = obj ? obj.answers : [];
    this.dependencyAnswerIdList = null;
  }
}
