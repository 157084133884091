import {EventEmitter, Injectable, OnChanges, OnDestroy, OnInit} from '@angular/core';

@Injectable()
export class NotificationsService implements OnInit, OnChanges, OnDestroy {
  public closeDelay = 0;

  public onLoad: EventEmitter<any> = new EventEmitter();
  public onShow: EventEmitter<any> = new EventEmitter();
  public onClose: EventEmitter<any> = new EventEmitter();
  public onError: EventEmitter<any> = new EventEmitter();
  public onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  private instances: Notification[] = [];

  public checkCompatibility () {
    return !!('Notification' in window);
  }

  public isPermissionGranted (permission) {
    return permission === 'granted';
  }

  public requestPermission (callback) {
    return Notification.requestPermission(callback);
  }

  public show (title: string, body: string, tag: string, icon: string, time?: number) {
    if (!this.checkCompatibility()) {
      return console.log('Notification API not available in this browser.');
    }

    return this.requestPermission((permission) => {
      if (this.isPermissionGranted(permission)) {
        const notificationOptions: NotificationOptions = {
          body: body,
          tag: tag,
          icon: icon
        };
        this.create(title, notificationOptions);
        if (time) {
          this.vibrate(time);
        }
      }
    });
  }

  public create (title: string, notificationOptions: NotificationOptions) {
    const notification = new Notification(title, notificationOptions);

    this.instances.push(notification);
    this.attachEventHandlers(notification);
    this.close(notification);

    return notification;
  }

  public close (notification): void {
    if (this.closeDelay) {
      setTimeout(() => {
        notification.close();
      }, this.closeDelay);
    } else {
      notification.close();
    }
  }

  public closeAll (): void {
    this.instances.map(notification => this.close(notification));
    this.instances = [];
  }

  attachEventHandlers (notification): void {
    notification.onshow = () => {
      this.onShow.emit({ notification });
    };

    notification.onclick = (event) => {
      this.onClick.emit({ event, notification });
    };

    notification.onerror = () => {
      this.onError.emit({ notification });
    };

    notification.onclose = () => {
      this.onClose.emit({ notification });
    };
  }

  public ngOnInit (): void {
    this.onLoad.emit({});
  }

  public ngOnDestroy (): void {
    this.closeAll();
  }

  public ngOnChanges(): void {
  }

  public vibrate(time) {
    if ('vibrate' in navigator) {
      navigator.vibrate(time);
    }
  }
}
