import * as dataModel from '../model/data';
import * as data from '../actions/data';
import {clone, cloneDeep, isEmpty} from 'lodash';
import {StdAction} from '../actions/util';
import {ConferenceUser} from '../model/event-mode/ConferenceUser';

export function reducer(state = dataModel.defaults, action: StdAction): dataModel.Data {
  const stateCopy = state;
  const {type, payload} = action;
  switch (type) {
    case data.ActionTypes.SET_CURRENT_USER:
      return {...stateCopy, currentUser: payload};
    case data.ActionTypes.GET_USER_ONLINE_SUCCESS:
      const usersOnline = clone(stateCopy.usersOnline);
      for (const it of payload) {
        if (it.type === 'added') {
          const obj = it.data;
          usersOnline.push(new ConferenceUser(obj));
        } else
        if (it.type === 'modified') {
          const obj = it.data;
          const changedUser = new ConferenceUser(obj);
          const index = usersOnline.findIndex(function (item) {
            if (item.userId === changedUser.userId) {
              return true;
            }
          });
          if (index > -1) {
            usersOnline[index] = changedUser;
          }
        } else
        if (it.type === 'removed') {
          const obj = it.data;
          const removedUserId = obj['userId'];
          const index = usersOnline.findIndex(function (item) {
            if (item.userId === removedUserId) {
              return true;
            }
          });
          if (index > -1) {
            usersOnline.splice(index, 1);
          }
        }
      }
      return {...stateCopy, usersOnline: usersOnline};
    case data.ActionTypes.GET_USER_ONLINE_SHORT_SUCCESS:
      const usersOnlineShort = cloneDeep(stateCopy.usersOnlineShort);
      if (payload.type === 'child_added') {
        const userId = payload.key;
        const obj = payload.data;
        usersOnlineShort[userId] = {
          userId: userId,
          pingTime: obj.pingTime,
          createTime: obj.createTime};
      } else
      if (payload.type === 'child_changed') {
        const userId = payload.key;
        if (!isEmpty(usersOnlineShort[userId])) {
          const obj = payload.data;
          usersOnlineShort[userId].createTime = obj.createTime;
          usersOnlineShort[userId].pingTime = obj.pingTime;
        }
      } else
      if (payload.type === 'child_removed') {
        const userId = payload.key;
        delete usersOnlineShort[userId];
      }
      return {...stateCopy, usersOnlineShort: usersOnlineShort};
    case data.ActionTypes.SET_CURRENT_CONTENT:
      return {...stateCopy, currentContent: payload};
    case data.ActionTypes.NOTIFY_EVENT_MESSAGE:
      return {...stateCopy, notifyEventMessage: payload};
    default:
      return state;
  }
}

export const getCurrentUser = (state: dataModel.Data) => state.currentUser;
export const getUsersOnline = (state: dataModel.Data) => state.usersOnline;
export const getCurrentContent = (state: dataModel.Data) => state.currentContent;
export const getNotifyEventMessage = (state: dataModel.Data) => state.notifyEventMessage;
export const getUsersOnlineShort = (state: dataModel.Data) => state.usersOnlineShort;
