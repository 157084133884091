import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {catchError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModulabApiService extends StdApiService {
  protected API_URL: string;

  constructor(
    private http: HttpClient,
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    protected aFirestore: AngularFirestore,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/api/v3/modulab/';
  }

  importXML(task: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    const path = `import?eid=${task.eventId}&sid=${task.parentId}&startIndex=${task.beginOrderIndexSectionId}&endIndex=${task.endOrderIndexSectionId}&fileName=${task.fileName}`;
    return this.http.post(this.API_URL + path, task.base64, {headers: headers})
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  exportXML(param: any) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const path = `export?eid=${param.eventId}`;
    return this.http.get(this.API_URL + path, {
      responseType: 'blob',
      headers: headers
    }).pipe(
      catchError(err => this.catchServerErrorObservable(err))
    );
  }
}
