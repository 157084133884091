// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --mdc-dialog__actions-padding-top: 10px;
}

.mat-mdc-dialog-container {
  padding: 24px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/date-time-picker/time-picker/time-select-dialog/time-select-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[":host {\n  --mdc-dialog__actions-padding-top: 10px;\n}\n\n.mat-mdc-dialog-container {\n  padding: 24px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
