import {Injectable} from '@angular/core';
import {UtilsService} from '../core/utils.service';
import {EventsDataService} from './events-data.service';
import {RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {Constants, REGISTRATION_ACTION} from '../core/constants';
import {clone, isEmpty, merge} from 'lodash';
import {QuestionnaireContent} from '../model/content/QuestionnaireContent';
import {RegistrationUserCard} from '../model/event-mode/RegistrationUserCard';
import {BehaviorSubject, Subscription} from 'rxjs';
import {EventQuestion} from '../model/EventQuestion';
import {TextMacrosParserService} from './text-macros-parser.service';

@Injectable()
export class RegistrationProcessService {

  private readonly UNSET = {};
  /**
   * Constants of error
   */
  private readonly ACTION_NOT_FOUND = 'Action not found.';
  private readonly USER_IS_NULL = 'User is null.';
  private readonly SECTION_IS_NULL = 'Section is null.';
  private readonly PROC_TREE_FOR_STATUS_NOT_FOUND = 'Proc tree for status not found.';

  /**
   * Statuses not decrease free places.
   */
  private readonly STATUS_NOT_DECREASE_FREE_PLACES = {
    [Constants.REGISTRATION_STATUS_NOT_REGISTERED]: true,
    [Constants.REGISTRATION_STATUS_NEW]: true,
    [Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION]: true
  };

  private readonly PARAM_ACTION_REGISTER = REGISTRATION_ACTION.REGISTER;
  private readonly PARAM_ACTION_UNREGISTER = REGISTRATION_ACTION.UNREGISTER;
  private readonly PARAM_ACTION_DELETE_UNREGISTER = REGISTRATION_ACTION.DELETE_UNREGISTER;

  /**
   * Change type
   */
  private readonly CHANGE_TYPE_CHANGE_STATUS = 'change.status';

  private _registrationSettings: RegistrationSettings;
  private _registrationQuestionnaire: QuestionnaireContent;
  private _registrationQuestionnaireAnswers;
  private _isAttendee = true;
  private _registeredUsersCount = 0;
  private _usersOnWaitingListCount = 0;
  private _allRegistrationUsers = [];
  private _sectionRegisteredUsers$ = new BehaviorSubject({});
  private _sectionsRegisteredUsers$ = new BehaviorSubject([]);
  private _registrationSettingsList$ = new BehaviorSubject([]);
  private _currentEventId;
  mainRootRegistrationSettings: RegistrationSettings;
  mainRootRegistrationComplete = false;
  private _assistantUsers = {};
  private _ownerId: string;

  /**
   * Proc tree actions
   */
  private readonly ACTION_ADD_NOT_REGISTERED = Constants.ACTION_ADD_TO_NOT_REGISTERED;
  private readonly ACTION_REGISTER_NOW1 = 'action.register.now.1';
  private readonly ACTION_REGISTER_NOW1A = 'action.register.now.1a';
  private readonly ACTION_REGISTER_NOW1B = 'action.register.now.1b';
  private readonly ACTION_REGISTER_NOW1C = 'action.register.now.1c';
  private readonly ACTION_REGISTER_NOW2 = 'action.register.now.2';
  private readonly ACTION_REGISTER_NOW2A = 'action.register.now.2a';
  private readonly ACTION_REGISTER_NOW3 = 'action.register.now.3';
  private readonly ACTION_REGISTER_NOW4 = 'action.register.now.4';
  private readonly ACTION_REGISTER_NOW5 = 'action.register.now.5';
  private readonly ACTION_REGISTER_NOW6 = 'action.register.now.6';
  private readonly ACTION_REGISTER_NOW7 = 'action.register.now.7';
  private readonly ACTION_UNREGISTER_WITH_DELETE_REGINFO = Constants.ACTION_UNREGISTERED_WITH_DELETE_REGINFO;
  private readonly ACTION_FORCE_APPROVE = 'action.force.approve';
  private readonly ACTION_FORCE_ON_WAITING_LIST = 'action.force.on.waiting.list';
  private readonly ACTION_FORCE_WAITING_FOR_APPROVAL = 'action.force.waiting.for.approval';
  private readonly ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL = 'action.force.waiting.for.event.approval';
  private readonly ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE = 'action.force.register.continue.incomplete';
  private readonly ACTION_FORCE_REGISTRATION_CONFIRMED = 'action.force.registration.confirmed';
  private readonly ACTION_FORCE_REGISTRATION_COMPLETED = 'action.force.registration.completed';
  private readonly ACTION_UNREGISTER = Constants.ACTION_UNREGISTER;
  private readonly ACTION_CONTINUE_REGISTRATION = 'action.continue.registration';
  private readonly ACTION_CONTINUE_REGISTRATION1 = 'action.continue.registration.1';
  private readonly ACTION_CONTINUE_REGISTRATION2 = 'action.continue.registration.2';
  private readonly ACTION_APPROVE_REGISTRATION = 'action.approve.registration';
  private readonly ACTION_APPROVE_REGISTRATION1 = 'action.approve.registration.1';
  private readonly ACTION_APPROVE_REGISTRATION2 = 'action.approve.registration.2';
  private readonly ACTION_FORCE_UNREGISTER = 'action.force.unregister';
  private readonly ACTION_REGISTRATION_CLOSE = Constants.ACTION_REGISTRATION_CLOSE;
  private readonly ACTION_COMPLETE_REGISTRATION_FINISHED = Constants.ACTION_FINISH_REGISTRATION;
  private readonly ACTION_COMPLETE_REGISTRATION_FINISHED1 = 'action.complete.registration.finished.1';
  private readonly ACTION_COMPLETE_REGISTRATION_FINISHED2 = 'action.complete.registration.finished.2';
  private readonly ACTION_COMPLETE_REGISTRATION_FINISHED_FORCE = 'action.complete.registration.finished.force';
  private readonly ACTION_COMPLETE_SPECIAL_REGISTRATION = Constants.ACTION_COMPLETE_SPECIAL_REGISTRATION;
  private readonly ACTION_NOT_ATTENDING = Constants.ACTION_NOT_ATTENDING;

  public registrationInstructionInnerHTML: string;

  /**
   * Statuses on which the questionnaire can be shown.
   */
  private readonly STATUS_CAN_SHOW_QUESTIONNAIRE =
    [Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
     Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION,
     Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE];
  private readonly ACTIONS_NEED_CONFIRM = [this.ACTION_FORCE_REGISTRATION_COMPLETED, this.ACTION_FORCE_APPROVE];
  /**
   * Registration process tree. key = user status, value = available action
   */
  private readonly PROC_TREE;
  private readonly PROC_TREE_ATTENDEE;

  private subscriptions: {[key: string]: Subscription} = {};

  constructor(private utils: UtilsService
            , private eventDataService: EventsDataService
            , public textMacrosParserService: TextMacrosParserService) {
    this.PROC_TREE = {
      [Constants.REGISTRATION_STATUS_NEW]: [
        {[this.ACTION_ADD_NOT_REGISTERED]: {
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_NEW, changeType: this.UNSET}, internal: true}},
        {[this.ACTION_REGISTER_NOW2]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: true,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL},
            alter_action: this.ACTION_REGISTER_NOW2A}},
        {[this.ACTION_REGISTER_NOW2A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.requiredApprove, conditionEquals2: true,
            condition3: this.keepWaitingList, conditionEquals3: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, internal: true}},
        {[this.ACTION_REGISTER_NOW1]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.eventRequiredApprove, conditionEquals4: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true},
            alter_action: this.ACTION_REGISTER_NOW1A}},
        {[this.ACTION_REGISTER_NOW3]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.keepWaitingList, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST} , internal: true}},
        {[this.ACTION_REGISTER_NOW4]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}}},
        {[this.ACTION_REGISTER_NOW5]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]: {
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_NOT_REGISTERED]: [
        {[this.ACTION_REGISTER_NOW2]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL},
            alter_action: this.ACTION_REGISTER_NOW2A}},
        {[this.ACTION_REGISTER_NOW2A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.requiredApprove, conditionEquals2: true,
            condition3: this.keepWaitingList, conditionEquals3: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, internal: true}},
        {[this.ACTION_REGISTER_NOW1]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.eventRequiredApprove, conditionEquals4: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true},
            alter_action: this.ACTION_REGISTER_NOW1A}},
        {[this.ACTION_REGISTER_NOW1A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.keepWaitingList, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST} , internal: true}},
        {[this.ACTION_REGISTER_NOW4]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}}},
        {[this.ACTION_REGISTER_NOW7]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]: {
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_NOT_ATTENDING]: [
        {[this.ACTION_REGISTER_NOW2]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL},
            alter_action: this.ACTION_REGISTER_NOW2A}},
        {[this.ACTION_REGISTER_NOW2A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.requiredApprove, conditionEquals2: true,
            condition3: this.keepWaitingList, conditionEquals3: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, internal: true}},
        {[this.ACTION_REGISTER_NOW1]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.eventRequiredApprove, conditionEquals4: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true},
            alter_action: this.ACTION_REGISTER_NOW1A}},
        {[this.ACTION_REGISTER_NOW1A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.keepWaitingList, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST} , internal: true}},
        {[this.ACTION_REGISTER_NOW4]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}}},
        {[this.ACTION_REGISTER_NOW7]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]: {
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL]: [
        {[this.ACTION_APPROVE_REGISTRATION]: {
            // if there is a questionnaire, we will offer to fill it in any case
            condition1: this.hasQuestionnaire, conditionEquals1: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}}},
        {[this.ACTION_APPROVE_REGISTRATION1]: {
            condition1: this.hasQuestionnaire, conditionEquals1: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL]: [
        {[this.ACTION_APPROVE_REGISTRATION]: {
            // if there is a questionnaire, we will offer to fill it in any case
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_ON_WAITING_LIST]: [
        {[this.ACTION_APPROVE_REGISTRATION]: {
            // if there is a questionnaire, we will offer to fill it in any case
            condition1: this.hasQuestionnaire, conditionEquals1: true,
            condition2: this.eventRequiredApprove, conditionEquals2: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}}},
        {[this.ACTION_APPROVE_REGISTRATION1]: {
            condition1: this.hasQuestionnaire, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_APPROVE_REGISTRATION2]: {
            condition1: this.hasQuestionnaire, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL, setRegDate: true}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED]: [
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED1]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED_FORCE]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE]: [
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED1]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED2]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED, setRegDate: true}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_APPROVE]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, useDefault: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_COMPLETED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_SPECIAL_REGISTRATION]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ],
      [Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}},
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_FORCE_ON_WAITING_LIST]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_APPROVAL]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_WAITING_FOR_EVENT_APPROVAL]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}, force: true}},
        {[this.ACTION_FORCE_REGISTER_CONTINUE_INCOMPLETE]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE}, force: true}},
        {[this.ACTION_FORCE_REGISTRATION_CONFIRMED]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED}, force: true}},
        {[this.ACTION_FORCE_UNREGISTER]: {task: this.unregister, force: true}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED]:
            { task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER, setRegDate: true,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED}, force: true}},
        {[this.ACTION_NOT_ATTENDING]:
            { task: this.updateStatusWithDeleteAnswers, params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_NOT_ATTENDING}, force: true}}
      ]
    };

    this.PROC_TREE_ATTENDEE = {
      // Only the user who has just entered or was added the event can have the status of NEW.
      [Constants.REGISTRATION_STATUS_NEW]: [
        {[this.ACTION_REGISTRATION_CLOSE]: {condition0: this.closeByDate, conditionEquals0: true}},
        // if assistant unregistered dependent users
        {[this.ACTION_ADD_NOT_REGISTERED]: {
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_NEW}, internal: true}},
        // if assistant delete users from dependent list
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_REGISTER_NOW1]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.keepWaitingList, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}}},
        {[this.ACTION_REGISTER_NOW1A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: true,
            condition3: this.hasQuestionnaire, conditionEquals3: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW1B]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.userAllAnswered, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW1C]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.eventRequiredApprove, conditionEquals4: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW2]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: true,
            condition3: this.eventRequiredApprove, conditionEquals3: false,
            condition5: this.hasQuestionnaire, conditionEquals5: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW3]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.hasQuestionnaire, conditionEquals4: false,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_REGISTER_NOW4]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.hasQuestionnaire, conditionEquals4: true,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_REGISTER_NOW5]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.userAllAnswered, conditionEquals4: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE,
              return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_REGISTER_NOW6]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.userAllAnswered, conditionEquals4: true,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            condition6: this.hasQuestionnaire, conditionEquals6: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_UNREGISTER]: {internal: true}}
      ],
      // The user can have a NOT_REGISTERED status only if it was registered, and then refused to register
      [Constants.REGISTRATION_STATUS_NOT_REGISTERED]: [
        {[this.ACTION_REGISTRATION_CLOSE]: {condition0: this.closeByDate, conditionEquals0: true}},
        // if assistant delete users from dependent list
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_REGISTER_NOW1]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.keepWaitingList, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}}},
        {[this.ACTION_REGISTER_NOW1A]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.eventRequiredApprove, conditionEquals2: true,
            condition3: this.hasQuestionnaire, conditionEquals3: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW1B]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.userAllAnswered, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW1C]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.eventRequiredApprove, conditionEquals4: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW2]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: true,
            condition3: this.eventRequiredApprove, conditionEquals3: false,
            condition5: this.hasQuestionnaire, conditionEquals5: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW3]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.hasQuestionnaire, conditionEquals4: false,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
                setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_REGISTER_NOW4]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.hasQuestionnaire, conditionEquals4: true,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
                setRegDate: true, return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_REGISTER_NOW5]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.userAllAnswered, conditionEquals4: false,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE,
                return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_REGISTER_NOW6]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            condition4: this.userAllAnswered, conditionEquals4: true,
            condition5: this.eventRequiredApprove, conditionEquals5: false,
            condition6: this.hasQuestionnaire, conditionEquals6: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_UNREGISTER]: {internal: true}}
      ],
      [Constants.REGISTRATION_STATUS_NOT_ATTENDING]: [
        {[this.ACTION_REGISTRATION_CLOSE]: {condition0: this.closeByDate, conditionEquals0: true}},
        // if assistant delete users from dependent list
        {[this.ACTION_UNREGISTER_WITH_DELETE_REGINFO]: {task: this.unregisterWithDelete, internal: true}},
        {[this.ACTION_REGISTER_NOW1]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: true,
            condition2: this.keepWaitingList, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_ON_WAITING_LIST}}},
        {[this.ACTION_REGISTER_NOW2]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL}}},
        {[this.ACTION_REGISTER_NOW3]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.hasQuestionnaire, conditionEquals4: false,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_REGISTER_NOW4]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: false,
            condition4: this.hasQuestionnaire, conditionEquals4: true,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_REGISTER_NOW5]: {
            condition0: this.closeByDate, conditionEquals0: false,
            condition1: this.notEnoughFreePlaces, conditionEquals1: false,
            condition2: this.requiredApprove, conditionEquals2: false,
            condition3: this.requiredAllAnswers, conditionEquals3: true,
            task: this.updateStatus,
            params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE,
              return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_UNREGISTER]: {internal: true}}
      ],
      [Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}],
      [Constants.REGISTRATION_STATUS_WAITING_FOR_APPROVAL]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}],
      [Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}],
      [Constants.REGISTRATION_STATUS_ON_WAITING_LIST]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}],
      [Constants.REGISTRATION_STATUS_REGISTRATION_CONFIRMED]: [
        {[this.ACTION_CONTINUE_REGISTRATION]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: false,
            task: this.showQuestionnaire}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED1]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
                setRegDate: true, return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED2]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: false,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
                setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}
      ],
      [Constants.REGISTRATION_STATUS_REGISTRATION_CONTINUE_INCOMPLETE]: [
        {[this.ACTION_CONTINUE_REGISTRATION]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: false,
            task: this.showQuestionnaire}},
        {[this.ACTION_CONTINUE_REGISTRATION1]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.userAllAnswered, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_CONTINUE_REGISTRATION2]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.userAllAnswered, conditionEquals3: true,
            condition4: this.eventRequiredApprove, conditionEquals4: true,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_WAITING_FOR_EVENT_APPROVAL}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED]: {
            condition1: this.requiredAllAnswers, conditionEquals1: true,
            condition2: this.userAllAnswered, conditionEquals2: true,
            condition3: this.eventRequiredApprove, conditionEquals3: false,
            task: this.updateStatus, params: {action: this.PARAM_ACTION_REGISTER,
              status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
              setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED1]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: true,
            condition3: this.eventRequiredApprove, conditionEquals3: false,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
                setRegDate: true, return_result: Constants.SHOW_QUESTIONNAIRE}}},
        {[this.ACTION_COMPLETE_REGISTRATION_FINISHED2]: {
            condition1: this.requiredAllAnswers, conditionEquals1: false,
            condition2: this.hasQuestionnaire, conditionEquals2: false,
            condition3: this.eventRequiredApprove, conditionEquals3: false,
            task: this.updateStatus,
              params: {action: this.PARAM_ACTION_REGISTER,
                status: Constants.REGISTRATION_STATUS_REGISTRATION_COMPLETED,
                setRegDate: true, return_result: Constants.OPEN_TIMELINE}}},
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}
      ],
      [Constants.REGISTRATION_STATUS_SPECIAL_REGISTRATION]: [
        {[this.ACTION_UNREGISTER]: {task: this.unregister}}]
    };
  }

  public resetServiceValues() {
    this._currentEventId = null;
    this._registeredUsersCount = 0;
    this._usersOnWaitingListCount = 0;
    this._registrationSettings = null;
    this._isAttendee = true;
    this._allRegistrationUsers = [];
    this._registrationQuestionnaire = null;
    this._registrationQuestionnaireAnswers = null;
    this._assistantUsers = {};
    this._ownerId = null;
    this.mainRootRegistrationSettings = null;
    this.mainRootRegistrationComplete = false;
    this._sectionRegisteredUsers$.next({});
  }

  resetByEvent() {
    if (this.subscriptions['getSectionsRegisteredUsers']) {
      this.subscriptions['getSectionsRegisteredUsers'].unsubscribe();
      this.subscriptions['getSectionsRegisteredUsers'] = null;
    }
  }

  /**
   * Public methods
   */

  set registrationSettings(value: RegistrationSettings) {
    this._registrationSettings = value;
    const text = this.textMacrosParserService.parseText(value?.registrationInstruction ? value.registrationInstruction : '');
    this.registrationInstructionInnerHTML = this.utils.convertTextToRichText(text);
  }

  get registrationSettings(): RegistrationSettings {
    return this._registrationSettings ?? new RegistrationSettings();
  }

  set registrationQuestionnaire(value: QuestionnaireContent) {
    this._registrationQuestionnaire = value;
  }

  get registrationQuestionnaire(): QuestionnaireContent {
    return this._registrationQuestionnaire;
  }

  set registrationQuestionnaireAnswers(value) {
    this._registrationQuestionnaireAnswers = value;
  }

  get registrationQuestionnaireAnswers() {
    return this._registrationQuestionnaireAnswers;
  }

  setRegistrationUsers(value: {}) {
    if (!value) {
      this._allRegistrationUsers = [];
      this._registeredUsersCount = 0;
      this._usersOnWaitingListCount = 0;
    }
    this._allRegistrationUsers = this.utils.objectValues(value).map( obj => new RegistrationUserCard(obj));
    this._registeredUsersCount = this.utils.objectValues(value).map( obj => new RegistrationUserCard(obj))
      .filter(user => !this.STATUS_NOT_DECREASE_FREE_PLACES[user.status]).length;
    this._usersOnWaitingListCount = this.utils.objectValues(value).map( obj => new RegistrationUserCard(obj))
      .filter(user => Constants.REGISTRATION_STATUS_ON_WAITING_LIST === user.status).length;
    this._sectionRegisteredUsers$.next(value);
  }

  setRegistrationUsersCounters(registered: number, waiting: number) {
    this._registeredUsersCount = registered ?? 0;
    this._usersOnWaitingListCount = waiting ?? 0;
  }

  getSpaceValues(registrationCounter: {}, maxPlaces: number) {
    const capitalizeStatus = (status: string) => {
      return  status.split('.').map((it, idx) => {
        if (idx > 0) {
          it = it[0].toUpperCase() + it.substring(1);
        }
        return it;
      }).join('');
    };

    const statuses = Object.keys(Constants.REGISTRATION_STATUS)
      .map((s) => capitalizeStatus(s));

    let count = 0;
    for (const status of statuses) {
      count += registrationCounter[status] ?? 0;
    }

    const registered = count;
    const waitingField = capitalizeStatus(Constants.REGISTRATION_STATUS_ON_WAITING_LIST);
    const waitingCount = registrationCounter[waitingField] ?? 0;
    let placesLeft = maxPlaces ? (maxPlaces - registered) : 0;
    if (placesLeft < 0) {
      placesLeft = 0;
    }
    const waitingList = placesLeft <= 0 && waitingCount > 0 ? waitingCount : 0;
    return {
      registered,
      placesLeft,
      waitingList
    };
  }

  get allRegistrationUsers() {
    return this._allRegistrationUsers;
  }

  /**
   * sectionsRegisteredUsers$ should be filled
   */
  getRegisteredUsersBySection(sectionId: string): any {
    const users: any[] = this._sectionsRegisteredUsers$.getValue();
    const res = {};
    for (const user of users) {
      if (user && user.sections) {
        const reduce = Object.keys(user.sections).filter(it => it === sectionId).reduce((d, val) => {
          return {
            sectionId: val, ...user.sections[val]
          };
        }, {});
        if (!isEmpty(reduce)) {
          res[user.id] = reduce;
        }
      }
    }
    return res;
  }

  get sectionRegisteredUsers$(): BehaviorSubject<{}> {
    return this._sectionRegisteredUsers$;
  }

  get sectionsRegisteredUsers$(): BehaviorSubject<any[]> {
    return this._sectionsRegisteredUsers$;
  }

  loadSectionsRegisteredUsers() {
    if (this.subscriptions['getSectionsRegisteredUsers']) {
      return;
    }
    this.subscriptions['getSectionsRegisteredUsers'] =
      this.eventDataService.getSectionsRegisteredUsers(this.currentEventId)
        .subscribe((registeredUsers) => {
          this.sectionsRegisteredUsers$.next(registeredUsers);
        });
  }

  get registrationSettingsList$(): BehaviorSubject<any[]> {
    return this._registrationSettingsList$;
  }

  set ownerId(value: string) {
    this._ownerId = value;
  }

  get ownerId(): string {
    return this._ownerId;
  }

  get currentEventId() {
    return this._currentEventId;
  }

  set currentEventId(value) {
    this._currentEventId = value;
  }

  /**
   *
   * @param user - must have property userId or email.
   * @returns {null}
   */
  getRegistrationUsersCard(user): RegistrationUserCard {
    if (!this._allRegistrationUsers || this._allRegistrationUsers.length === 0) {return null; }
    if (user && !user.email) {
      return null;
    }
    if (!user || !user.email) {throw new Error('Error: UserId is null'); }
    let card = this._allRegistrationUsers.find(value => value.email === user.email);
    if (card) {
      card = new RegistrationUserCard(card);
      card.fullName = user.fullName;
      card.picture = user.picture ? user.picture : Constants.DEFAULT_USER_LOGO;
      card.isAssistant = !!this._assistantUsers[card.userId];
    }
    return card;
  }

  getDefaultUserCardFromUser(user) {
    if (!user) {throw new Error('Error: UserId is null'); }
    return new RegistrationUserCard({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      status: Constants.REGISTRATION_STATUS_NEW,
      fullName: user.fullName,
      picture: user.picture ? user.picture : Constants.DEFAULT_USER_LOGO,
      assistantId: user.assistantId,
      isAssistant: user.email ? !!this._assistantUsers[this.utils.emailToId(user.email)] : false});
  }

  getDependentUserList(user) {
    if (!user || !user.userId) {
      throw this.USER_IS_NULL;
    }
    const list = this._allRegistrationUsers.filter( value => value.assistantId === user.userId);
    return list ? list : [];
  }

  get registeredUsersCount(): number {
    return this._registeredUsersCount;
  }

  get usersOnWaitingListCount(): number {
    return this._usersOnWaitingListCount;
  }

  showQuestionnaireOnStatus(status) {
    return this._registrationQuestionnaire && !this._registrationQuestionnaire.draft &&
      !isEmpty(this._registrationQuestionnaire.questions) && this.STATUS_CAN_SHOW_QUESTIONNAIRE.indexOf(status) > -1;
  }

  set isAttendee(value: boolean) {
    this._isAttendee = value;
  }

  get assistantUsers(): {} {
    return this._assistantUsers;
  }

  set assistantUsers(value: {}) {
    this._assistantUsers = value ? value : {};
  }

  executeAction(sectionId, currentUser, actionName, param = {}) {
    return new Promise<any>((resolve, reject) => {
      if (!sectionId || !currentUser || !actionName || !currentUser.status || !currentUser.userId) {
        throw this.rejectError('executeAction', sectionId, currentUser, {}, currentUser ? currentUser.status : {}, actionName);
      }
      const action = this.canActionExecute(currentUser, actionName);
      if (!action) {
        throw this.rejectErrorStr('executeAction[' + actionName + ']', this.ACTION_NOT_FOUND);
      }
      const name = Object.keys(action)[0];
      const doTask = action[name]['task'];
      const params = merge(action[name]['params'], param);
      const internal = action[name]['internal'];
      if (doTask) {
        doTask(this, sectionId, currentUser, params, internal).then(function (result) {
          resolve({sectionId: sectionId, result: result});
        }).catch(function (error) {
          reject(error);
        });
      } else {
        resolve({sectionId: sectionId, result: Constants.SUCCESS});
      }
    });
  }

  emulateExecuteAction(sectionId, currentUser, actionName) {
    return new Promise<any>((resolve, reject) => {
      if (!sectionId || !currentUser || !actionName || !currentUser.status || !currentUser.userId) {
        throw this.rejectError('executeAction', sectionId, currentUser, {}, currentUser ? currentUser.status : {}, actionName);
      }
      const action = this.canActionExecute(currentUser, actionName);
      if (!action) {
        throw this.rejectErrorStr('executeAction[' + actionName + ']', this.ACTION_NOT_FOUND);
      }
      const name = Object.keys(action)[0];
      const doTask = action[name]['task'];
      let params = action[name]['params'];
      const internal = action[name]['internal'];
      if (isEmpty(params)) {
        params = {};
      }
      params['emulate'] = true;
      if (doTask) {
        doTask(this, sectionId, currentUser, params, internal).then(function (result) {
          resolve({sectionId: sectionId, result: result.resolve_result, task: result.task, join_to_container: result.join_to_container});
        }).catch(function (error) {
          reject(error);
        });
      } else {
        resolve({sectionId: sectionId, result: Constants.SUCCESS});
      }
    });
  }

  isActionAvailable(currentUser, action): boolean {
    const actions = this.getAvailableStatusAction(currentUser, 'all');
    return actions.indexOf(action) > -1;
  }

  actionNeedConfirm(actionName): boolean {
    return this.ACTIONS_NEED_CONFIRM.indexOf(actionName) > -1;
  }

  /**
   * For show user menu. (users action logical process)
   * @param currentUser
   * @returns {string[]}
   */
  getAvailableUserActions(currentUser) {
    return this.getAvailableStatusAction(currentUser);
  }

  /**
   * For show custom menu. (force action no logical process)
   * @param currentUser
   * @returns {string[]}
   */
  getForcePresenterActions(currentUser) {
    return this.getAvailableStatusAction(currentUser, 'force');
  }

  /**
   * For show top menu.
   * @param currentUser
   * @returns {string[]}
   */
  getDefaultPresenterActions(currentUser) {
    return this.getAvailableStatusAction(currentUser, 'useDefault');
  }

  get registrationDateRestriction() {
    return this._registrationSettings && (this._registrationSettings.dateFrom || this._registrationSettings.dateTo);
  }


  get registrationMaxAttendeesRestriction() {
    return this._registrationSettings && this._registrationSettings.maxAttendees && this._registrationSettings.maxAttendees > 0;
  }

  /**
   * Private methods
   */

  private getAvailableStatusAction(currentUser, actionType?: 'internal'|'force'|'useDefault'|'all', alterAction?): string[] {
    if (!currentUser) {
      throw this.rejectErrorStr('getAvailableStatusAction', this.USER_IS_NULL);
    }
    const status = currentUser['status'];
    const statusActions = this._isAttendee ? this.PROC_TREE_ATTENDEE[status] : this.PROC_TREE[status];
    if (!statusActions) {
      throw this.rejectErrorStr('getAvailableStatusAction',  this.PROC_TREE_FOR_STATUS_NOT_FOUND);
    }
    const resultActionsName = [];
    for (const action of statusActions) {
      const actionName = Object.keys(action)[0];
      const actionValue = action[actionName];
      const internal = actionValue['internal'];
      const force = actionValue['force'];
      const useDefault = actionValue['useDefault'];
      const alterActionName = actionValue['alter_action'];
      if (alterAction && alterAction !== actionName) {
        continue;
      }
      if (actionType !== 'all') {
        if (!actionType && (internal === true || force === true || useDefault === true)) {
          continue;
        } else if (actionType === 'internal' && (force === true || useDefault === true)) {
          continue;
        } else if (actionType === 'force' && force !== true) {
          continue;
        } else if (actionType === 'useDefault' && useDefault !== true) {
          continue;
        }
      }
      const actionConditions = this.getActionCondition(actionValue);
      if (!actionConditions) {
        resultActionsName.push(actionName);
      } else
      if (this.checkConditions(currentUser, actionConditions)) {
        resultActionsName.push(actionName);
      } else
      if (alterActionName && this.canActionExecute(currentUser, alterActionName)) {
        resultActionsName.push(actionName);
      }
    }
    return resultActionsName;
  }

  private getActionCondition(actionValue): any[] {
    const result = [];
    const rcondition = [];
    const requals = [];
    const conditionsName = Object.keys(actionValue).filter(
      value => value.indexOf('condition') > -1 && value.indexOf('conditionEquals') === -1);
    if (!conditionsName || conditionsName.length === 0) {return null; }
    for (const cname of conditionsName) {
      const cequals = cname.replace('condition', 'conditionEquals');
      rcondition.push(actionValue[cname]);
      requals.push(actionValue[cequals]);
    }
    result.push(rcondition);
    result.push(requals);
    return result;
  }

  private checkConditions(currentUser, actionConditions: any[]): boolean {
    const result = true;
    const rcondition: any[] = actionConditions[0];
    const requals: any[] = actionConditions[1];
    for (let i = 0; i < rcondition.length; i++) {
      const value = rcondition[i](this, currentUser);
      if (typeof requals[i] === 'boolean') {
        if (!!value !== requals[i] ) {
          return false;
        }
      } else
      if (rcondition[i](this, currentUser) !== requals[i]) {
        return false;
      }
    }
    return result;
  }

  private canActionExecute(currentUser, actionName): string {
    if (!currentUser) {
      throw this.rejectErrorStr('canActionExecute', this.USER_IS_NULL);
    }
    const status = currentUser['status'];
    const statusActions: any[] = this._isAttendee ? this.PROC_TREE_ATTENDEE[status] : this.PROC_TREE[status];
    if (!statusActions) {
      throw this.rejectErrorStr('canActionExecute',  this.PROC_TREE_FOR_STATUS_NOT_FOUND);
    }
    const resultActionsName = [];
    let action = statusActions.find(obj => Object.keys(obj)[0] === actionName);
    if (!action) {
      throw this.rejectErrorStr('canActionExecute[' + actionName + ']', this.ACTION_NOT_FOUND);
    }
    const actionValue = action[actionName];
    const alterActionName = actionValue['alter_action'];
    const actionConditions = this.getActionCondition(actionValue);
    if (!actionConditions) {
      return action;
    } else
    if (this.checkConditions(currentUser, actionConditions)) {
      return action;
    } else
    if (alterActionName && (action = this.canActionExecute(currentUser, alterActionName))) {
      return action;
    } else {
      return undefined;
    }
  }

  private notEnoughFreePlaces = function(self) {
    return self._registrationSettings && self.registrationMaxAttendeesRestriction &&
      self._registeredUsersCount >= self._registrationSettings.maxAttendees;
  };

  private requiredApprove = function(self) {
    return self._registrationSettings && self._registrationSettings.requiredApprove === true;
  };

  private eventRequiredApprove = function(self) {
    return self.mainRootRegistrationSettings &&
      self.mainRootRegistrationSettings.requiredApprove === true && !self.mainRootRegistrationComplete;
  };

  private requiredAllAnswers = function(self) {
    return self._registrationQuestionnaire && !self._registrationQuestionnaire.draft && self._registrationSettings &&
      self._registrationSettings.requiredAllAnswers === true;
  };

  private keepWaitingList = function(self) {
    return self._registrationSettings && self._registrationSettings.keepWaitingList === true;
  };

  private hasQuestionnaire = function(self) {
    return self._registrationQuestionnaire && !self._registrationQuestionnaire.draft &&
      self._registrationQuestionnaire.questions &&
      Object.keys(self._registrationQuestionnaire.questions).length > 0;
  };

  private closeByDate = function(self) {
    const curDate = self.utils.todayTime();
    return self._registrationSettings && self.registrationDateRestriction && (
      (self._registrationSettings.dateFrom && curDate < self._registrationSettings.dateFrom) ||
      (self._registrationSettings.dateTo && curDate > self._registrationSettings.dateTo)
    );
  };

  /**
   * Update data, go to status. If the call is internal, the regDate is not filled.
   * @param self
   * @param sectionId
   * @param currentUser
   * @param params
   * @param internal
   * @returns {Promise<any>}
   */
  private updateStatus = function(self, sectionId, currentUser, params, internal) {
    return new Promise<any>((resolve, reject) => {
      if (!sectionId || !currentUser || !params || !params.status || !params.action) {
        reject(self.rejectError('updateStatus', sectionId, currentUser, params, params.status, params.action));
        return;
      }
      const userObj = new RegistrationUserCard(currentUser);
      const regDateObj = params['setRegDate'] === true ? {regDate: self.utils.now()} : {};
      const changeType = params['changeType'] ? params['changeType'] : self.CHANGE_TYPE_CHANGE_STATUS;
      const emulate = params['emulate'];
      delete params['emulate'];
      const task = merge(userObj, params, {change_type: changeType}, regDateObj);
      delete task['return_result'];
      delete task['setRegDate'];
      delete task['changeType'];
      if (!emulate) {
        self.eventDataService.userRegistrationAction(self._currentEventId, sectionId, task).then(function (r) {
          if (Constants.REGISTER_STATUS[params.status]) {
            self.joinContainerSectionIfParentContainer(sectionId, currentUser.email).then(function (rr) {
              resolve(params && params.return_result ? params.return_result : self.SUCCESS);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve(params && params.return_result ? params.return_result : self.SUCCESS);
          }
        }).catch(function (error) {
          reject(error);
        });
      } else {
        if (Constants.REGISTER_STATUS[params.status]) {
          self.checkForJoinContainerSectionIfParentContainer(sectionId).then(function (needJoin) {
            resolve({resolve_result: params && params.return_result ? params.return_result : self.SUCCESS,
              task: task, join_to_container: needJoin});
          }).catch(function (error) {
            reject(error);
          });
        } else {
          resolve({resolve_result: params && params.return_result ? params.return_result : self.SUCCESS,
            task: task, join_to_container: Constants.SKIP});
        }
      }
    });
  };

  /**
   * Update data, go to status. If the call is internal, the regDate is not filled. Delete user answers!!!.
   * @param self
   * @param sectionId
   * @param currentUser
   * @param params
   * @param internal
   * @returns {Promise<any>}
   */
  private updateStatusWithDeleteAnswers = function(self, sectionId, currentUser, params, internal) {
    return new Promise<any>((resolve, reject) => {
      if (!sectionId || !currentUser || !params || !params.status || !params.action) {
        reject(self.rejectError('updateStatus', sectionId, currentUser, params, params.status, params.action));
        return;
      }
      const userObj = new RegistrationUserCard(currentUser);
      const regDateObj = params['setRegDate'] === true ? {regDate:  self.utils.now()} : {};
      const changeType = params['changeType'] ? params['changeType'] : self.CHANGE_TYPE_CHANGE_STATUS;
      const task = merge(userObj, params, {change_type: changeType}, regDateObj);
      delete task['return_result'];
      delete task['setRegDate'];
      delete task['changeType'];
      self.eventDataService.userRegistrationAction(self._currentEventId, sectionId, task).then(function (r) {
        self.deleteUserRegistrationAnswers(sectionId, currentUser).then( function (rt) {
          resolve({resolve_result: params && params.return_result ? params.return_result : self.SUCCESS});
        }).catch(function (error) {
          reject(error);
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  };

  /**
   * Smart method. If the user is registered as an assistant,
   * registration information is not deleted. Only the status changes.
   * If the user deletes himself - the registration information is deleted.
   */
  private unregister = function(self, sectionId, currentUser, params?) {
    return new Promise<any>((resolve, reject) => {
      if (!currentUser) {
        throw self.rejectErrorStr(self.PARAM_ACTION_UNREGISTER, self.USER_IS_NULL);
      }
      if (!sectionId) {
        throw self.rejectErrorStr(self.PARAM_ACTION_UNREGISTER, self.SECTION_IS_NULL);
      }
      const userObj = new RegistrationUserCard(currentUser);
      const actionTask = {action: self.PARAM_ACTION_UNREGISTER, regDate: 0, status: Constants.REGISTRATION_STATUS_NOT_REGISTERED};
      const task = merge(userObj, actionTask, {change_type: self.CHANGE_TYPE_CHANGE_STATUS});
      if (isEmpty(params)) {
        self.eventDataService.userRegistrationAction(self._currentEventId, sectionId, task).then(function (r) {
          self.deleteUserRegistrationAnswers(sectionId, currentUser).then( function (rt) {
            self.leaveContainerSectionIfParentContainer(sectionId, currentUser.email).then(function (rz) {
              resolve(Constants.SUCCESS);
            }).catch(function (error) {
              reject(error);
            });
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
          reject(error);
        });
      } else {
        self.deleteUserRegistrationAnswers(sectionId, currentUser).then( function (rt) {
          self.leaveContainerSectionIfParentContainer(sectionId, currentUser.email).then(function (rz) {
            resolve({resolve_result: Constants.SUCCESS, task: task});
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
          reject(error);
        });
      }
    });
  };

  /**
   * Complete deletion of all registration information.
   */
  private unregisterWithDelete = function (self, sectionId, currentUser) {
    return new Promise<any>((resolve, reject) => {
      if (!currentUser) {
        throw self.rejectErrorStr('unregisterWithDelete', self.USER_IS_NULL);
      }
      if (!sectionId) {
        throw self.rejectErrorStr('unregisterWithDelete', self.SECTION_IS_NULL);
      }
      const userObj = new RegistrationUserCard(currentUser);
      const actionTask = {
        action: self.PARAM_ACTION_DELETE_UNREGISTER,
        status: Constants.REGISTRATION_STATUS_NOT_REGISTERED
      };
      const task = merge(userObj, actionTask);
      self.checkHasUserOtherSectionRegistrationAndDelete(currentUser, task).then(function (result) {
        resolve(result);
      }).catch(function (error) {
        reject(error);
      });
    });
  };

  private showQuestionnaire = function(self) {
    return Promise.resolve(Constants.SHOW_QUESTIONNAIRE);
  };

  private userAllAnswered = function(self, currentUser) {
    if (!currentUser || !self._registrationQuestionnaire) {return true; }
    const questions = self._registrationQuestionnaire.getStrictQuestions();
    if (isEmpty(questions)) {
      return true;
    }
    const uId = currentUser.userId;
    const ret: number[] = [Constants.QSTATUS_NOT_ANSWERED, 0];
    if (questions) {
      const qIdList = self.applyQuestionnaireDependency(Object.keys(questions), self._registrationQuestionnaire, uId);
      for (const qk of qIdList) {
        if (questions[qk]) {
          const answers = self.registrationQuestionnaireAnswers && self.registrationQuestionnaireAnswers[qk] ?
            self.registrationQuestionnaireAnswers[qk] : {};
          if (answers) {
            if (answers[uId]) {
              ret[0]++;
            }
          }
        }
      }
      ret[1] = ret[0] * 100 / qIdList.length;
      ret[0] = ret[0] === Constants.QSTATUS_NOT_ANSWERED ?
        Constants.QSTATUS_NOT_ANSWERED : (ret[0] < qIdList.length ?
          Constants.QSTATUS_PARTLY_ANSWERED : Constants.QSTATUS_ANSWERED);
    }
    return ret[0] === Constants.QSTATUS_ANSWERED;
  };

  private applyQuestionnaireDependency(questionsIdLis: string[], qContent: QuestionnaireContent, currentUserId): string[] {
    const result: string[] = [];
    const userId = currentUserId;
    const questions = qContent.questions;
    const answers = this._registrationQuestionnaireAnswers;
    questionsIdLis.forEach(qKey => {
      const cq = questions[qKey];
      const question = new EventQuestion(cq);
      const dependency = question.getDependency();
      if (dependency) {
        if (dependency.dependencyConditionShowIf) {
          const dependedQuestion = new EventQuestion(questions[dependency.questionId]);
          if (answers && answers[dependedQuestion.id] && answers[dependedQuestion.id][userId] &&
               dependency.dependencyResult(answers[dependedQuestion.id][userId])) {
            result.push(question.id);
          }
        } else
        if (dependency.dependencyConditionHideIf) {
          const dependedQuestion = new EventQuestion(questions[dependency.questionId]);
          if (answers && answers[dependedQuestion.id] && answers[dependedQuestion.id][userId] &&
               !dependency.dependencyResult(answers[dependedQuestion.id][userId])) {
            result.push(question.id);
          }
        }
      } else {
        result.push(question.id);
      }
    });
    return result
      .map(id => qContent.questions[id])
      .filter(o => !!o)
      .sort(this.utils.comparator(Constants.ORDERINDEX))
      .map(q => q.id);
  }

  private rejectError(methodWhereCalledRejectError, sectionId, user, params, status, action): string {
    const error = 'RegistrationProcessService.' + methodWhereCalledRejectError + ': ';
    if (!sectionId) {return error + this.SECTION_IS_NULL; }
    if (!user) {return error + this.USER_IS_NULL; }
    if (!params) {return error + 'Params is null'; }
    if (!status) {return error + 'Status is null'; }
    if (!action) {return error + 'Action is null'; }
    return undefined;
  }

  private rejectErrorStr(methodWhereCalledRejectError, errText): string {
    const error = 'RegistrationProcessService.' + methodWhereCalledRejectError + ': ';
    return error + errText;
  }

  private deleteUserRegistrationAnswers(sectionId, currentUser) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!currentUser) {
        throw this.rejectErrorStr('deleteUserRegistrationAnswers', this.USER_IS_NULL);
      }
      if (!sectionId) {
        throw this.rejectErrorStr('deleteUserRegistrationAnswers', this.SECTION_IS_NULL);
      }
      if (!this._registrationQuestionnaire) {
        resolve(Constants.SUCCESS);
        return Constants.SUCCESS;
      }
      const userId = currentUser['userId'];
      this.eventDataService.getRegistrationQuestionnaireAnswersByUser(userId, this._currentEventId, sectionId)
        .then(function (questions) {
        if (questions) {
          if (!questions) {
            resolve(Constants.SUCCESS);
            return Constants.SUCCESS;
          }
          const questionList = [];
          delete questions['userId'];
          for (const qk of Object.keys(questions)) {
             questionList.push(qk);
          }
          vm.eventDataService.deleteUserRegistrationAnswers(userId, vm._currentEventId, sectionId, questionList)
            .then((r) => {
              resolve(Constants.SUCCESS);
            }).catch(function (error) {
              reject(error);
            });
        } else {
          resolve(Constants.SUCCESS);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  private checkHasUserOtherSectionRegistrationAndDelete(user, task) {
    const removeRegistration = function (self, currentUser, currentSectionId, currentTask) {
      return new Promise<any>((resolve, reject) => {
        self.eventDataService.userRegistrationAction(self._currentEventId, currentSectionId, currentTask).then(function (r) {
          const unsetAssistantTask = clone(task);
          delete unsetAssistantTask['status'];
          unsetAssistantTask.action = 'unset.assistant';
          self.eventDataService.userRegistrationAction(self._currentEventId, currentSectionId, unsetAssistantTask).then(function (ra) {
            self.deleteUserRegistrationAnswers(currentSectionId, currentUser).then(function (rt) {
              self.leaveContainerSectionIfParentContainer(currentSectionId, currentUser.email).then(function (rz) {
                resolve(self.SUCCESS);
              }).catch(function (error) {
                reject(error);
              });
            }).catch(function (error) {
              reject(error);
            });
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
          reject(error);
        });
      });
    };
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      this.eventDataService.getUserRegisteredSectionIdsPromise(this._currentEventId, user.userId)
        .then(function (sectionList) {
          if (!sectionList) {
            resolve(Constants.SUCCESS);
          } else {
            const allPromise = [];
            for (const sectionId of sectionList) {
              allPromise.push(removeRegistration(vm, user, sectionId, task));
            }
            Promise.all(allPromise).then(function (r) {
              resolve(Constants.SUCCESS);
            }).catch(function (error) {
              reject(error);
            });
          }
        });
    });
  }

  joinContainerSectionIfParentContainer(sectionId, email) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      this.eventDataService.getSectionPromise(this._currentEventId, sectionId).then(function (cObj) {
        if (cObj) {
          const parentId = cObj['parentId'];
          if (parentId) {
            vm.eventDataService.getSectionPromise(vm._currentEventId, parentId).then(function (pObj) {
              if (pObj && pObj['container']) {
                vm.eventDataService.saveUserSection(vm._currentEventId, sectionId, {email: email, joinTime: vm.utils.now()})
                  .then(function (r) {
                    resolve(Constants.SUCCESS);
                  }).catch(function (error) {
                  reject(error);
                });
              } else {
                resolve(Constants.SUCCESS);
              }
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve(Constants.SUCCESS);
          }
        } else {
          resolve(Constants.SUCCESS);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  checkForJoinContainerSectionIfParentContainer(sectionId) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      this.eventDataService.getSectionPromise(this._currentEventId, sectionId).then(function (cObj) {
        if (cObj) {
          const parentId = cObj['parentId'];
          if (parentId) {
            vm.eventDataService.getSectionPromise(vm._currentEventId, parentId).then(function (pObj) {
              if (pObj && pObj['container']) {
                resolve(Constants.JOIN_TO_CONTAINER);
              } else {
                resolve(Constants.SKIP);
              }
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve(Constants.SKIP);
          }
        } else {
          resolve(Constants.SKIP);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  leaveContainerSectionIfParentContainer(sectionId, email) {
    return new Promise<any>((resolve, reject) => {
      this.eventDataService.saveUserSection(this._currentEventId, sectionId, {email: email, joinTime: {}}).then( function (r) {
        resolve(Constants.SUCCESS);
      }) .catch(function (error) {
        reject(error);
      });
    });
  }

  checkUserAllAnswered(currentUser, registrationQuestionnaire, registrationQuestionnaireAnswers) {
    if (!currentUser || !registrationQuestionnaire) {return true; }
    const questions = registrationQuestionnaire.getStrictQuestions();
    if (isEmpty(questions)) {
      return true;
    }
    const uId = currentUser.userId;
    const ret: number[] = [Constants.QSTATUS_NOT_ANSWERED, 0];
    if (questions) {
      const qIdList = this.applyQuestionnaireDependency(Object.keys(questions), registrationQuestionnaire, uId);
      for (const qk of qIdList) {
        if (questions[qk]) {
          const answers = registrationQuestionnaireAnswers && registrationQuestionnaireAnswers[qk] ?
            registrationQuestionnaireAnswers[qk] : {};
          if (answers) {
            if (answers[uId]) {
              ret[0]++;
            }
          }
        }
      }
      ret[1] = ret[0] * 100 / qIdList.length;
      ret[0] = ret[0] === Constants.QSTATUS_NOT_ANSWERED ?
        Constants.QSTATUS_NOT_ANSWERED : (ret[0] < qIdList.length ?
          Constants.QSTATUS_PARTLY_ANSWERED : Constants.QSTATUS_ANSWERED);
    }
    return ret[0] === Constants.QSTATUS_ANSWERED;
  }

}

