import {Injectable} from '@angular/core';
import {SectionContent} from '../model/content/SectionContent';
import {clone, isEmpty} from 'lodash';
import {Constants, DATE_FORMAT, ILanguageParams} from '../core/constants';
import {AppUser} from '../model/AppUser';
import {Event} from '../model/Event';
import {UtilsService} from '../core/utils.service';
import {RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {IRegSectionStatuses} from './events-data.service';

interface IDictionary {
  [macros: string]: string;
}

interface ISectionDictionaryList {
  [sectionId: string]: IDictionary;
}

@Injectable({
  providedIn: 'root'
})
export class TextMacrosParserService {

  private eventDictionary: IDictionary = {};
  private userDictionary: IDictionary = {};
  private sectionDictionaryList: ISectionDictionaryList = {};
  private registrationSettingsDictionary: IDictionary = {};
  private sectionUserStatusDictionaryList: ISectionDictionaryList = {};
  private staticSectionUserStatusDictionary: IDictionary = {};

  constructor(private utils: UtilsService) {
    this.staticSectionUserStatusDictionary['${user_status}'] = 'n/a';
  }

  initUserParserDictionary(user: AppUser) {
    this.userDictionary = {};
    if (!user) {
      return;
    }
    this.userDictionary['${user_name}'] = user.fullName;
  }

  initEventParserDictionary(event: Event) {
    this.eventDictionary = {};
    if (!event) {
      return;
    }
    this.eventDictionary['${event_name}'] = event.name;
    this.eventDictionary['${event_start}'] = this.utils.formatDate(event.startDate, DATE_FORMAT.DD_MM_YYYY_HH_mm);
    this.eventDictionary['${event_link}'] = event.shortLink;
    this.eventDictionary['${event_startdate}'] = this.utils.formatDate(event.startDate, DATE_FORMAT.DD_MM_YYYY);
    this.eventDictionary['${event_starttime}'] = this.utils.formatDate(event.startDate, DATE_FORMAT.HH_mm);
    this.eventDictionary['${event_duration}'] =
      this.utils.millisTo_YY_MM_DD_hh_mm(event.duration * Constants.ONE_MINUTE_MS);
    this.eventDictionary['${event_description}'] = event.description;
  }

  initEventRegistrationSettings(settings: RegistrationSettings) {
    this.registrationSettingsDictionary = {};
    if (!settings) {
      return;
    }
    this.registrationSettingsDictionary['${event_registration_instruction}'] = settings.registrationInstruction;
  }

  private sectionUserStatusDictionary(section: IRegSectionStatuses) {
    if (!section) {
      return;
    }
    if (isEmpty(this.sectionUserStatusDictionaryList[section.sectionId])) {
      this.sectionUserStatusDictionaryList[section.sectionId] = {};
    }
    const sectionDictionary = this.sectionUserStatusDictionaryList[section.sectionId];
    sectionDictionary['${user_status}'] = this.utils.i18n('user.registration.status.' + section.status);
  }

  initUserStatusParserDictionaryList(list: IRegSectionStatuses[]) {
    if (isEmpty(this.sectionUserStatusDictionaryList)) {
      this.sectionUserStatusDictionaryList = {};
    }
    const alive = [];
    for (const s of (list || [])) {
      alive.push(s.sectionId);
      this.sectionUserStatusDictionary(s);
    }
    const dead = Object.keys(this.sectionUserStatusDictionaryList).filter(id => !alive.includes(id));
    (dead || []).forEach(id => delete this.sectionUserStatusDictionaryList[id]);
  }

  initSectionParserDictionaryList(list: SectionContent[], languageParams: ILanguageParams) {
    if (isEmpty(this.sectionDictionaryList)) {
      this.sectionDictionaryList = {};
    }
    const alive = [];
    for (const s of (list || [])) {
      alive.push(s.id);
      this.initSectionDictionary(s, languageParams);
    }
    const dead = Object.keys(this.sectionDictionaryList).filter(id => !alive.includes(id));
    (dead || []).forEach(id => delete this.sectionDictionaryList[id]);
  }

  initSectionDictionary(section: SectionContent, languageParams: ILanguageParams) {
    if (!section) {
      return;
    }
    if (isEmpty(this.sectionDictionaryList[section.id])) {
      this.sectionDictionaryList[section.id] = {};
    }
    const sectionDictionary = this.sectionDictionaryList[section.id];
    sectionDictionary['${section_name}'] = section.getTitleByLanguage(languageParams);
    sectionDictionary['${section_start}'] = this.utils.formatDate(section.plannedTime, DATE_FORMAT.DD_MM_YYYY_HH_mm);
    sectionDictionary['${section_startdate}'] = this.utils.formatDate(section.plannedTime, DATE_FORMAT.DD_MM_YYYY);
    sectionDictionary['${section_starttime}'] = this.utils.formatDate(section.plannedTime, DATE_FORMAT.HH_mm);
    sectionDictionary['${section_duration}'] =
      this.utils.millisTo_YY_MM_DD_hh_mm(section.duration * Constants.ONE_MINUTE_MS);
    sectionDictionary['${section_subtitle}'] = section.getSubtitleByLanguage(languageParams);
    sectionDictionary['${section_description}'] = section.getDescriptionByLanguage(languageParams);
  }

  removeSectionFromDictionaryList(sectionId) {
    delete this.sectionDictionaryList[sectionId];
  }

  private replace(text: string, templatesValues: IDictionary): string {
    if (isEmpty(templatesValues)) {
      return text;
    }
    let tmp = text;
    let result = '';
    for (const template of Object.keys(templatesValues || {})) {
      result = '';
      const p = (tmp || '').split(template);
      for (let i = 0; i < p.length; i++) {
        result = result + p[i] + (i < p.length - 1 ? templatesValues[template] : '');
      }
      tmp = result;
    }
    return result;
  }

  private replaceSectionMacros(sectionDictionary: IDictionary) {
    sectionDictionary['${section_name}'] = this.replace(sectionDictionary['${section_name}'], this.userDictionary);
    sectionDictionary['${section_name}'] = this.replace(sectionDictionary['${section_name}'], this.eventDictionary);
    sectionDictionary['${section_name}'] = this.replace(sectionDictionary['${section_name}'], sectionDictionary);

    sectionDictionary['${section_subtitle}'] = this.replace(sectionDictionary['${section_subtitle}'], this.userDictionary);
    sectionDictionary['${section_subtitle}'] = this.replace(sectionDictionary['${section_subtitle}'], this.eventDictionary);
    sectionDictionary['${section_subtitle}'] = this.replace(sectionDictionary['${section_subtitle}'], sectionDictionary);

    sectionDictionary['${section_description}'] = this.replace(sectionDictionary['${section_description}'], this.userDictionary);
    sectionDictionary['${section_description}'] = this.replace(sectionDictionary['${section_description}'], this.eventDictionary);
    sectionDictionary['${section_description}'] = this.replace(sectionDictionary['${section_description}'], sectionDictionary);
  }

  parse(sectionId: string, text: string, templatesValues?: IDictionary) {
    let result = clone(text ? text : '');
    if (!result) {
      return result;
    }
    result = this.replace(result, this.userDictionary);
    result = this.replace(result, this.eventDictionary);
    result = this.replace(result, this.registrationSettingsDictionary);
    if (this.sectionUserStatusDictionaryList[sectionId]) {
      result = this.replace(result, this.sectionUserStatusDictionaryList[sectionId]);
    } else {
      result = this.replace(result, this.staticSectionUserStatusDictionary);
    }
    if (this.sectionDictionaryList[sectionId]) {
      result = this.replace(result, this.sectionDictionaryList[sectionId]);
      this.replaceSectionMacros(this.sectionDictionaryList[sectionId]);
    }
    if (templatesValues) {
      result = this.replace(result, templatesValues);
    }
    return result;
  }

  parseText(text: string, templatesValues?: IDictionary) {
    let result = clone(text ? text : '');
    if (!result) {
      return result;
    }
    result = this.replace(result, this.userDictionary);
    result = this.replace(result, this.eventDictionary);
    result = this.replace(result, this.registrationSettingsDictionary);
    if (templatesValues) {
      result = this.replace(result, templatesValues);
    }
    return result;
  }

  resetServiceValues() {
    this.eventDictionary = {};
    this.userDictionary = {};
    this.sectionDictionaryList = {};
    this.registrationSettingsDictionary = {};
    this.sectionUserStatusDictionaryList = {};
  }
}
