import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {Constants} from '../../../../../../../core/constants';
import {IAnswer} from '../../../quiz-model/quiz';
import {QUESTION_TYPE} from '../../shared/quiz-quiestion-types';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-question-check-choice-results',
  templateUrl: './question-check-choice-results.component.html',
  styleUrls: ['./question-check-choice-results.component.scss']
})
export class QuestionCheckChoiceResultsComponent extends AbstractQuizQuestionResultsComponent {

  answers;
  dataSource = [];
  displayedColumns = ['answer'];
  correctAnswersEmpty = true;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];
    if (this.question.storypoint === QUESTION_TYPE.CHOICE && !this.question.useCorrectAnswers) {
      this.question.items.forEach(answer => dataList.push(
        {answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex}));
    } else {
      const correctAnswers = this.question.reduceGroupsCorrectAnswersItemsCorrectAnswer();
      this.correctAnswersEmpty = isEmpty(correctAnswers);
      this.question.items.forEach(answer => dataList.push(
        {count: 0, answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex,
          correctAnswer: correctAnswers?.[answer.id], progress: '', unprogress: ''}));
    }
    let diff = this.dataSource.length !== dataList.length;
    if (!diff) {
      diff = this.dataSource.some(item => {
        const ind = dataList.findIndex(elem => {
          if (item['id'] === elem['id'] && item['answer'] === elem['answer'] &&
            item['correctAnswer'] === elem['correctAnswer'] && item['orderIndex'] === elem['orderIndex']) {
            return true;
          }
        });
        return ind === -1;
      });
    }
    if (diff) {
      dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
      this.dataSource = dataList;
    }
    this.calcDataSourceFields();
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.common.utils.getGroupedAnswers(this.summaryQuestionAnswers);
    this.calcDataSourceFields();
  }

  private calcDataSourceFields() {
    const getPropValue = (qKey, propName: string, def: number = 0) => this.answers && this.answers[propName] ? this.answers[propName] : def;
    let maxLen = 0;
    for (const row of this.dataSource) {
      row.count = getPropValue(this.qKey, row.id);
      maxLen = maxLen < (row.count + '').length ? (row.count + '').length : maxLen;
      const scaleEnd = getPropValue(this.qKey, 'scaleEnd');
      const scaleEnd1 = getPropValue(this.qKey, 'scaleEnd', 1);
      row.progress = (scaleEnd !== 0 ? row.count * 100 / scaleEnd1 : 0) + '%';
      row.unprogress = (scaleEnd !== 0 ? (100 - (row.count * 100 / scaleEnd1)) : 100) + '%';
    }
    this.dataSource.forEach(row => row.minWidth = (maxLen === 1 ? 1 : (1.2 + 0.6 * (maxLen - 2))) + 'em');
  }
}
