import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {
  BASE_TABLE_GRID_COLUMNS,
  ITableGridColumn,
  TABLE_COLUMN_TYPE,
  TABLE_COLUMNS_WIDTH,
  TableColumn
} from '../question-table-editor/table-poll-model/TablePollModel';
import {Constants} from '../../../../../../../core/constants';
import {MatTableDataSource} from '@angular/material/table';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../reducers';
import * as ui from '../../../../../../../actions/ui';
import {isEmpty, merge} from 'lodash';
import {UploadFilesUtils} from './question-table-upload-files-utils';
import {BehaviorSubject, debounceTime, take} from 'rxjs';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UploadService} from '../../../../../../../core/upload.service';
import {StorageDataService} from '../../../../../../../services/storage-data.service';
import {
  QuestionTableFilesAnswersPreviewComponent
} from '../question-table-files-answers-preview/question-table-files-answers-preview.component';
import {getColumnGroupCorrectAnswers} from '../question-table-shared/question-table-utils';

const GRID_COLUMNS: ITableGridColumn[] = [
  ...BASE_TABLE_GRID_COLUMNS
];

@Component({
  selector: 'app-question-table-participant',
  templateUrl: './question-table-participant.component.html',
  styleUrl: './question-table-participant.component.scss'
})
export class QuestionTableParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  readonly TABLE_COLUMN_TYPE = TABLE_COLUMN_TYPE;

  displayedColumns = [];
  dataSource = new MatTableDataSource([]);
  columns: TableColumn[] = [];
  gridColumns: ITableGridColumn[] = [];
  uploadFilesUtils: UploadFilesUtils;
  horizontalScroll$ = new BehaviorSubject<number>(null);
  horizontalScrollBlock = false;
  questionCorrectAnswers = {};


  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private store: Store<fromRoot.State>,
              private uploadService: UploadService,
              private storageDataService: StorageDataService,
              private dialog: MatDialog) {
    super(injector, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.editorMode) {
      this.uploadFilesUtils = new UploadFilesUtils(this.common, this.storageDataService, this.uploadService,
        this.question, this.currentUserId, this.documentPathParams);
      this.horizontalScroll$.pipe(debounceTime(50), this.takeUntilAlive())
        .subscribe(() => {
          this.horizontalScrollBlock = false;
        });
    }
  }

  onDestroy() {
    this.uploadFilesUtils?.destroy();
  }

  initQuestionAnswersDataSource() {
    this.columns = (this.question.options?.tableColumns ?? []).map(c => new TableColumn(c));
    this.gridColumns = this.columns.filter(cl => !cl.isMainColumn)
      .sort(this.common.utils.comparator(Constants.ORDERINDEX))
      .map((cl, idx) => new Object({
        name: `${cl.type.inputType}_${idx}`,
        width: TABLE_COLUMNS_WIDTH[cl.type.inputType],
        id: cl.id,
        type: cl.type.inputType,
        columnName: cl.type.columnName,
        isCorrectAnswerEnabled: cl.type.isCorrectAnswerEnabled,
        selectedTypes: cl.type['selectedTypes'],
        options: cl.type['options']?.sort(this.common.utils.comparator(Constants.ORDERINDEX))
      }) as ITableGridColumn);
    this.reduceColumnsCorrectAnswers();
    this.displayedColumns = [...GRID_COLUMNS.map(cl => cl.name), ...this.gridColumns.map(cl => cl.name)];
    const columnsCSS = [...GRID_COLUMNS.map(cl => cl.width), ...this.gridColumns.map(cl => cl.width)].join(' ');
    this.elementRef.nativeElement.style.setProperty('--table-grid-template-columns', columnsCSS);
    this.dataSource.data = (this.columns.find(c => c.isMainColumn).tableRows ?? [])
      .sort(this.common.utils.comparator(Constants.ORDERINDEX));
  }

  reduceColumnsCorrectAnswers() {
    if (!this.question.useCorrectAnswers || (this.question.useCorrectAnswers && !this.question.showCorrectAnswers) ||
         isEmpty(this.question.groupsCorrectAnswers)) {
      this.questionCorrectAnswers = {};
      return;
    }
    for (const column of this.gridColumns) {
      if (column.isCorrectAnswerEnabled) {
        if (column.type === TABLE_COLUMN_TYPE.CHECKBOX) {
          const columnCorrectAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
          this.questionCorrectAnswers[column.id] = columnCorrectAnswers.reduce((acc, id) => merge(acc, {[id]: true}), {});
        } else if (column.type === TABLE_COLUMN_TYPE.DROPDOWN) {
          const result = {};
          const columnCorrectAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
          for (const optionId of Object.keys(columnCorrectAnswers)) {
            const rowIds = columnCorrectAnswers[optionId];
            for (const rowId of rowIds) {
              if (!result[rowId]) {
                result[rowId] = [];
              }
              result[rowId].push(optionId);
              result[rowId].sort();
            }
          }
          this.questionCorrectAnswers[column.id] = result;
        } else if (column.type === TABLE_COLUMN_TYPE.TEXT) {
          const result = {};
          const columnCorrectAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
          for (const optionId of Object.keys(columnCorrectAnswers)) {
            const optionText = this.common.utils.valueToArray(columnCorrectAnswers[optionId].option);
            const rowIds = columnCorrectAnswers[optionId].rows ?? [];
            for (const rowId of rowIds) {
              if (!result[rowId]) {
                result[rowId] = [];
              }
              result[rowId].push(...optionText);
            }
          }
          this.questionCorrectAnswers[column.id] = result;
        }
      }
    }
  }

  sendAnswer(value, type: TABLE_COLUMN_TYPE, rowId: string, columnId: string, options?: any) {
    if (!this.acceptAnswers) {
      return;
    }
    const answers = {};
    switch (type) {
      case TABLE_COLUMN_TYPE.CHECKBOX:
        answers[rowId] = {[columnId]: !!value};
        break;
      case TABLE_COLUMN_TYPE.DROPDOWN:
        answers[rowId] = {
          [columnId]: options.map(o => o.id).reduce((acc, id) => {
            return merge(acc, {[id]: value.includes(id)});
          }, {})
        };
        break;
      case TABLE_COLUMN_TYPE.TEXT:
        const text = this.common.utils.sanitizeTolerant(value.currentTarget.value ? value.currentTarget.value : '');
        answers[rowId] = {[columnId]: text};
        break;
      case TABLE_COLUMN_TYPE.FILE:
        answers[rowId] = {[columnId]: {file: value ? JSON.stringify(value) : null}};
        break;
      default:
        return;
    }
    this.answers = merge(isEmpty(this.answers) ? {} : this.answers, answers);
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  editTextAnswer(edit) {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(edit));
  }

  uploadFile(rowId, columnId) {
    this.uploadFilesUtils.uploadResult$.pipe(take(1), this.takeUntilAlive())
      .subscribe(fileData => {
        if (fileData) {
          this.sendAnswer(fileData, TABLE_COLUMN_TYPE.FILE, rowId, columnId);
        }
      });
    const column = this.gridColumns.find(cl => cl.id === columnId);
    this.uploadFilesUtils.upload(column.selectedTypes);
  }

  deleteUploadedFile(rowId, columnId) {
    if (!this.answers?.[rowId]?.[columnId]?.file) {
      return;
    }
    this.uploadFilesUtils.deleteResult$.pipe(take(1), this.takeUntilAlive())
      .subscribe(result => {
        if (result) {
          this.sendAnswer(null, TABLE_COLUMN_TYPE.FILE, rowId, columnId);
        }
      });
    this.uploadFilesUtils.deleteUploadFile(JSON.parse(this.answers[rowId][columnId].file));
  }

  replaceUploadedFile(rowId, columnId) {
    if (!this.answers?.[rowId]?.[columnId]?.file) {
      return;
    }
    this.uploadFilesUtils.uploadResult$.pipe(take(1), this.takeUntilAlive())
      .subscribe(fileData => {
        if (fileData) {
          this.sendAnswer(fileData, TABLE_COLUMN_TYPE.FILE, rowId, columnId);
        }
      });
    const column = this.gridColumns.find(cl => cl.id === columnId);
    this.uploadFilesUtils.replaceUploadFile(JSON.parse(this.answers[rowId][columnId].file), column.selectedTypes);
  }

  viewUploadedFile(menuButton, rowId, columnId) {
    if (!this.answers?.[rowId]?.[columnId]?.file) {
      return;
    }
    const buttonElement = menuButton._elementRef.nativeElement;
    const buttonRect = buttonElement.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {
      top: `${buttonRect.bottom}px`,
      left: `${buttonRect.left - 160}px`
    };
    dialogConfig.maxWidth = 'initial';
    dialogConfig.disableClose = false;
    dialogConfig.panelClass = ['timeline', 'questions-participants-detail'];

    const dialogRef = this.dialog.open(QuestionTableFilesAnswersPreviewComponent, dialogConfig);
    const componentInstance: any = dialogRef.componentInstance;
    componentInstance.documentPathParams = this.documentPathParams;
    componentInstance.qContent = this.question;
    componentInstance.userId = this.currentUserId;
    componentInstance.questionId = this.qKey;
    componentInstance.answers = {rowId: {[columnId]: this.answers[rowId][columnId]}};
  }

  onWheelContentScroller(event, table) {
    const element = table._elementRef.nativeElement;
    const toLeft  = event.deltaY < 0 && element.scrollLeft > 0;
    const toRight = event.deltaY > 0 && element.scrollLeft < element.scrollWidth - element.clientWidth;
    if (toLeft || toRight) {
      event.preventDefault();
      event.stopPropagation();
      let deltaLeft = event?.deltaY ?? 0;
      deltaLeft = Math.abs(deltaLeft) < 100 ? (100 * (deltaLeft < 0 ? -1 : 1)) : deltaLeft;
      if (!this.horizontalScrollBlock || this.horizontalScroll$.getValue() !== deltaLeft) {
        element.scrollBy({left: deltaLeft * 3,  behavior: 'smooth'});
        this.horizontalScrollBlock = true;
      }
      this.horizontalScroll$.next(deltaLeft);
    }
  }

}
