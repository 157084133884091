import { Injectable } from '@angular/core';
import {LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  public get = this.sessionStorageGet;
  public set = this.sessionStorageSet;
  public remove = this.sessionStorageRemove;

  constructor(private logger: LoggerService) {
  }

  private checkSessionStorage() {
    try {
      return 'sessionStorage' in window && window['sessionStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  private sessionStorageGet(key, defaultValue?) {
    if (!this.checkSessionStorage()) {
      return defaultValue ? defaultValue : undefined;
    }
    let value = sessionStorage.getItem(key);
    if (!value) {
      value = defaultValue ? defaultValue : undefined;
    }
    const obj = {};
    obj[key] = value;
    this.logger.debug('sessionStorageGet', obj);
    return value;
  }

  private sessionStorageSet(key, value) {
    if (!this.checkSessionStorage()) {
      return;
    }
    const obj = {};
    obj[key] = value;
    this.logger.debug('sessionStorageSet', obj);
    sessionStorage.setItem(key, value);
  }

  private sessionStorageRemove(key) {
    if (!this.checkSessionStorage()) {
      return;
    }
    this.logger.debug('sessionStorageRemove', key);
    sessionStorage.removeItem(key);
  }
}
