import {Component, Injector} from '@angular/core';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {Constants} from '../../../../../../../core/constants';
import {IQuestionUploadFilesOptions} from '../upload-file-model/UploadFile';
import {formatSize} from '../../shared/lib/question-upload-files-utils';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-question-upload-files-editor',
  templateUrl: './question-upload-files-editor.component.html',
  styleUrl: './question-upload-files-editor.component.scss'
})
export class QuestionUploadFilesEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  readonly formatSize = formatSize;

  isMediaPanelOpen = false;
  isDocumentPanelOpen = false;
  DEFAULT_NO_OF_FILES = 3;
  FT_IMAGE_SIZE = Constants.FT_IMAGE_SIZE;
  FT_VIDEO_SIZE = Constants.FT_VIDEO_SIZE;
  FT_AUDIO_SIZE = Constants.FT_AUDIO_SIZE;
  FT_DOC_SIZE = Constants.FT_DOC_SIZE;
  FT_OTHER_SIZE = Constants.FT_OTHER_SIZE;

  images = {
    jpg: false,
    jpeg: false,
    png: false,
    gif: false,
    svg: false,
    webp: false,
    heic: false,
  };

  audios = {
    m4a: false,
    mp3: false,
    aac: false,
  };

  videos = {
    mp4: false
  };

  documentTypes = {
    pdf: false,
    docx: false,
    xlsx: false,
    pptx: false
  };

  others = {
    csv: false,
    zip: false
  };

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (!this.questionOptions.numberOfFiles) {
      this.questionOptions.numberOfFiles = this.DEFAULT_NO_OF_FILES;
    }
    this.isMediaPanelOpen = false;
    this.isDocumentPanelOpen = false;
    this.initializeCheckboxes();
  }

  initializeCheckboxes() {
    // Initialize images
    Object.keys(this.images).forEach(key => {
      this.images[key] = this.questionOptions.selectedTypes?.images?.includes(key);
    });

    // Initialize audios
    Object.keys(this.audios).forEach(key => {
      this.audios[key] = this.questionOptions.selectedTypes?.audios?.includes(key);
    });

    // Initialize videos
    Object.keys(this.videos).forEach(key => {
      this.videos[key] = this.questionOptions.selectedTypes?.videos?.includes(key);
    });

    // Initialize documents
    Object.keys(this.documentTypes).forEach(key => {
      this.documentTypes[key] = this.questionOptions.selectedTypes?.documents?.includes(key);
    });

    Object.keys(this.others).forEach(key => {
      this.others[key] = this.questionOptions.selectedTypes?.others?.includes(key);
    });
  }

  trackByKey(index, row) {
    return row.key;
  }

  get questionOptions(): IQuestionUploadFilesOptions {
    return super.questionOptions;
  }

  incrementLimit() {
    this.questionOptions.numberOfFiles++;
  }

  decrementLimit() {
    if (this.questionOptions.numberOfFiles > 1) {
      this.questionOptions.numberOfFiles--;
    }
  }

  selectAllMedia(event: Event) {
    event.stopPropagation();
    const allMediaTypes = { ...this.images, ...this.audios, ...this.videos };
    const selectAll = !Object.values(allMediaTypes).every(v => v);
    Object.keys(this.images).forEach(key => this.images[key] = selectAll);
    Object.keys(this.audios).forEach(key => this.audios[key] = selectAll);
    Object.keys(this.videos).forEach(key => this.videos[key] = selectAll);
    this.updateSelectedTypes();
  }

  selectAllDocuments(event: Event) {
    event.stopPropagation();
    const allDocTypes = { ...this.documentTypes, ...this.others};
    const selectAll = !Object.values(allDocTypes).every(v => v);
    Object.keys(this.documentTypes).forEach(key => this.documentTypes[key] = selectAll);
    Object.keys(this.others).forEach(key => this.others[key] = selectAll);
    this.updateSelectedTypes();
  }

  updateSelectedTypes() {
    if (!this.questionOptions.selectedTypes) {
      this.questionOptions.selectedTypes = {
        images: [],
        audios: [],
        videos: [],
        documents: [],
        others: [],
      };
      this.questionOptions.selectedTypes = this.setObjectChangesHandler(this.questionOptions.selectedTypes);
    }
    this.questionOptions.selectedTypes.images = Object.keys(this.images).filter(key => this.images[key]);
    this.questionOptions.selectedTypes.audios = Object.keys(this.audios).filter(key => this.audios[key]);
    this.questionOptions.selectedTypes.videos = Object.keys(this.videos).filter(key => this.videos[key]);
    this.questionOptions.selectedTypes.documents = Object.keys(this.documentTypes).filter(key => this.documentTypes[key]);
    this.questionOptions.selectedTypes.others = Object.keys(this.others).filter(key => this.others[key]);
  }

  getSelectedMediaTypes(): string {
    const selectedTypes = [
      ...Object.keys(this.images).filter(key => this.images[key]),
      ...Object.keys(this.audios).filter(key => this.audios[key]),
      ...Object.keys(this.videos).filter(key => this.videos[key])
    ];

    return selectedTypes.length > 0 ? this.common.i18n('questionnaire.upload.file.editor.files.selected',
      {count: selectedTypes.length}) : this.common.i18n('questionnaire.upload.file.editor.files.selected.none');
  }

  getSelectedDocumentTypes(): string {
    const selectedTypes = [
      ...Object.keys(this.documentTypes).filter(key => this.documentTypes[key]),
      ...Object.keys(this.others).filter(key => this.others[key])
    ];
    return selectedTypes.length > 0 ? this.common.i18n('questionnaire.upload.file.editor.files.selected',
      {count: selectedTypes.length}) : this.common.i18n('questionnaire.upload.file.editor.files.selected.none');
  }

  onCheckboxChange() {
    this.updateSelectedTypes();
  }

  onMediaPanelOpened() {
    this.isMediaPanelOpen = true;
  }

  onMediaPanelClosed() {
    this.isMediaPanelOpen = false;
  }

  onDocumentPanelOpened() {
    this.isDocumentPanelOpen = true;
  }

  onDocumentPanelClosed() {
    this.isDocumentPanelOpen = false;
  }

  validate(): IValidated {
    if (isEmpty(this.questionOptions.selectedTypes) || (
      isEmpty(this.questionOptions.selectedTypes.images) &&
      isEmpty(this.questionOptions.selectedTypes.audios) &&
      isEmpty(this.questionOptions.selectedTypes.videos) &&
      isEmpty(this.questionOptions.selectedTypes.documents) &&
      isEmpty(this.questionOptions.selectedTypes.others))
    ) {
      return {validated: false, warning: this.common.i18n('questionnaire.upload.file.editor.no.file.error')};
    }
    return {validated: true};
  }
}
