import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AppUser} from '../../model/AppUser';
import * as fromRoot from '../../reducers';
import {select, Store} from '@ngrx/store';
import {Constants} from '../../core/constants';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonService} from '../../core/common.service';
import {take} from 'rxjs';
import {clone, isEmpty} from 'lodash';
import {UsersDataService} from '../../services/users-data.service';
import {LoginService} from '../../login/login.service';
import * as ui from '../../actions/ui';
import {StdComponent} from '../../core/std-component';
import {EditDialogComponent} from '../../dialog-components/edit-dialog/edit-dialog.component';
import {TimeLineService} from '../../services/time-line.service';

@Component({
  selector: 'app-user-card-dialog',
  templateUrl: './user-card-dialog.component.html',
  styleUrls: ['./user-card-dialog.component.scss'],
})
export class UserCardDialogComponent extends StdComponent implements OnInit {
  readonly Constants = Constants;

  appUser: AppUser;
  currentUser: AppUser;
  readOnlyFoce = false;
  userSocialNetList: any[] = [];
  socNetList = Constants.SOCIAL_NETWORK_LIST;
  userProfile = false;
  eventId = null;
  receiveEmailNotifications = false;
  isAzure = false;
  isPasswordProvider = this.loginService.isPasswordProvider();

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<UserCardDialogComponent>,
              private store: Store<fromRoot.State>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private common: CommonService,
              private sanitizer: DomSanitizer,
              private userDataService: UsersDataService,
              private timeLineService: TimeLineService,
              private loginService: LoginService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    if (!isEmpty(data)) {
      this.appUser = new AppUser(clone(data.appUser));
      this.receiveEmailNotifications = !this.appUser.unsubscribe;
      const snt = JSON.parse(this.appUser.socialNetwork);
      if (snt) {
        this.userSocialNetList = snt;
      }
      this.eventId = data.eventId;
      this.currentUser = data.currentUser;
    } else {
    }
    const loginProvider = this.loginService.getCurrentProviderId();
    this.isAzure = loginProvider === Constants.PROVIDER_HKVBS_MICROSOFT;
    this.common.listenBackdropClick(dialogRef).pipe(this.takeUntilAlive()).subscribe();
  }

  get readOnly(): boolean {
    return !(this.currentUser && (this.currentUser.isAdmin
      || this.currentUser.userId === this.appUser.userId)) || !this.appUser || !this.appUser.userId;
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    if (isEmpty(this.data)) {
      this.userProfile = true;
      this.store.pipe(select(fromRoot.getCurrentUser), this.takeUntilAlive())
        .pipe(this.takeUntilAlive())
        .subscribe(val => {
          this.appUser = val ? new AppUser(clone(val)) : null;
          if (this.appUser) {
            this.currentUser = this.appUser;
            if (this.appUser.socialNetwork) {
              const snt = JSON.parse(this.appUser.socialNetwork);
              if (snt) {
                this.userSocialNetList = snt;
              }
            }
          }
        });
    }
    this.timeLineService.conferenceUser$
      .pipe(this.takeUntilAlive())
      .subscribe(user => {
        this.readOnlyFoce = user ? user.isViewer : false;
      });
  }

  onDestroy(): void {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onOkClick(): void {
    this.appUser.socialNetwork = JSON.stringify(this.userSocialNetList);
    if (this.userProfile) {
      this.userDataService.updateUserCard(this.appUser.userId, this.appUser.description,
        this.appUser.socialNetwork, this.appUser.hideEmail, this.appUser.department);
    } else {
      if (this.appUser.unsubscribe) {
        this.appUser.unsubscribe = this.receiveEmailNotifications ? 0 : this.appUser.unsubscribe;
      } else {
        this.appUser.unsubscribe = this.receiveEmailNotifications ? this.appUser.unsubscribe : 1;
      }
      this.dialogRef.close(this.appUser);
    }
  }

  onNoClick(): void {
    if (this.userProfile) {
      this.loginService.redirectTo('/dashboard');
    } else {
      this.dialogRef.close();
    }
  }

  clickAddNet(netName) {
    const contact = '';
    const dialogData = {title: this.common.utils.i18n('common.add_social_network_dialog.caption')
      , fields: [
        { id: 'contact', type: 'text',
          required: true,
          placeholder: this.common.utils.i18n('common.edit_social_network.contact'),
          hinttext: this.common.utils.i18n('reducere_e.g.') + ', ' + Constants.SOCIAL_NETWORK_TABLE[netName].template
        }
      ]
      , result: {contact: contact, userNetworkName: netName}};
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userSocialNetList.push({
          id: (new Date()).getTime().toString(),
          contact: result.contact,
          userNetworkName: result.userNetworkName});
      }
    });
  }

  clickEditNet(net) {
    const contact = net.contact;
    const dialogData = {
      title: this.common.utils.i18n('common.edit_social_network_dialog.caption')
      , fields: [
        {id: 'contact', type: 'text', placeholder: this.common.utils.i18n('common.edit_social_network.contact')}
      ]
      , result: {contact: contact, userNetworkName: net.userNetworkName, id: net.id}
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userSocialNetList.forEach((netVal, index) => {
          if (netVal.id === result.id) {
            this.userSocialNetList[index] = result;
          }
        });
      }
    });
  }

  onDeleteNet(net) {
    this.common.confirm(
      this.common.utils.i18n('edit_dialog.confirm_dialog.header'),
      this.common.utils.i18n('edit_dialog.confirm_dialog.body.social.network')).pipe(
      take(1)
    ).subscribe(result => {
        if (result) {
          let delIndex = -1;
          this.userSocialNetList.forEach((netVal, index) => {
            if (netVal.id === net.id) {
              delIndex = index;
            }
          });
          if (delIndex > -1) {
            this.userSocialNetList.splice(delIndex, 1);
          }
        }
      });
  }

  getUrl(usernet) {
    if (usernet.userNetworkName !== Constants.SKYPE) {
      return usernet.contact;
    }
    let contact = '';
    this.userSocialNetList.forEach(netVal => {
      if (netVal.userNetworkName === usernet.userNetworkName) {
        contact = netVal.contact;
      }
    });
    return Constants.SOCIAL_NETWORK_TABLE[usernet.userNetworkName].external_link
      .replace('profile_name', contact);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(['https', 'http'].some(pre => url.startsWith(pre)) ? url : `${document.location.protocol}//${url}`);
  }

  departamentPlaceholder() {
    return this.readOnly || this.readOnlyFoce ? '' :
        this.common.utils.i18n('function.department');
  }

  media() {
    return document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH;
  }

  reloadProfilePicture() {
    this.userDataService.requestAzureProfilePicture()
      .then(base64 => {
        this.appUser.picture = base64 as any;
      });
  }
}
