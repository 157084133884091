import * as uiModel from '../model/ui';
import * as ui from '../actions/ui';
import {clone, merge} from 'lodash';
import {StdAction} from '../actions/util';

export function reducer(state = uiModel.defaults, action: StdAction): uiModel.UI {
  const stateCopy = clone(state);

  switch (action.type) {
    case ui.ActionTypes.SET_MENU_OPEN:
      return merge({}, state, {menuOpen: action.payload});
    case ui.ActionTypes.SET_CURRENT_PAGE:
      return merge({}, state, {currentPage: action.payload});
    case ui.ActionTypes.SET_LOADING:
      stateCopy.loading = action.payload;
      return stateCopy;
    case ui.ActionTypes.SET_PRESENTATION_MODE:
      return merge({}, state, {presentationMode: action.payload});
    case ui.ActionTypes.SET_PRESENTATION_MODE_KEY_ACTION:
      return merge({}, state, {presentationModeKeyAction: action.payload});
    case ui.ActionTypes.SET_MODAL_DIALOG_OPEN:
      return merge({}, state, {modalDialogOpen: action.payload});
    case ui.ActionTypes.SET_EDITOR_MODE:
      return merge({}, state, {editorMode: action.payload});
    case ui.ActionTypes.SET_OPEN_MANAGE_TIME:
      return merge({}, state, {manageTimeOpen: action.payload});
    case ui.ActionTypes.SET_EVENT_SETTINGS_OPEN_PAGE:
      return merge({}, state, {eventSettingsOpenPage: action.payload});
    case ui.ActionTypes.SET_USER_INPUT_ANSWER_ON_TEXT_QUESTION:
      return merge({}, state, {userInputAnswerOnTextQuestion: action.payload});
    case ui.ActionTypes.SET_MEETING_VIEW_MODE:
      return merge({}, state, {meetingViewMode: action.payload});
    case ui.ActionTypes.SET_FULL_SCREEN_MODE:
      return merge({}, state, {fullScreenMode: action.payload});
    default:
      return state;
  }
}

export const getMenuOpen = (state: uiModel.UI) => state.menuOpen;
export const getCurrentPage = (state: uiModel.UI) => state.currentPage;
export const getLoading = (state: uiModel.UI) => state.loading;
export const getPresentationMode = (state: uiModel.UI) => state.presentationMode;
export const getPresentationModeKeyAction = (state: uiModel.UI) => state.presentationModeKeyAction;
export const getModalDialogOpen = (state: uiModel.UI) => state.modalDialogOpen;
export const getManageTimeOpen = (state: uiModel.UI) => state.manageTimeOpen;
export const getEventSettingsOpenPage = (state: uiModel.UI) => state.eventSettingsOpenPage;
export const getUserInputAnswerOnTextQuestion = (state: uiModel.UI) => state.userInputAnswerOnTextQuestion;
export const getMeetingViewMode = (state: uiModel.UI) => state.meetingViewMode;
export const getFullScreenMode = (state: uiModel.UI) => state.fullScreenMode;
export const getEditorMode = (state: uiModel.UI) => state.editorMode;
