import {AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {EventsDataService} from '../../services/events-data.service';
import {BehaviorSubject, delay, fromEvent, map, Subscription} from 'rxjs';
import {Event, UserEvent} from '../../model/Event';
import {autoUnsubscribe} from '../../core/autoUnsubscribe';
import {CommonService} from '../../core/common.service';
import {LoginService} from '../../login/login.service';
import {DashboardToolbarComponent, MainToolbarTab, TEventTag} from '../dashboard-toolbar/dashboard-toolbar.component';
import {InjectorService} from '@ninescopesoft/core';
import {Constants, EVENT_DATE_MODE} from '../../core/constants';
import {ExtEvent} from '../../model/ExtEvent';
import {isEmpty} from 'lodash';
import {DebugService} from '../../services/debug.service';

type TEvent = Event | UserEvent;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  readonly defaultScrollSettings = {
    pageLength: 20,
    pageStep: 10,
    buffer: 450
  };
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  pageLength = this.defaultScrollSettings.pageLength;
  subscriptions: {[key: string]: Subscription} = {};
  myEvents: UserEvent[] = [];
  pastEvents: UserEvent[] = [];
  publicEvents: Event[] = [];
  allEvents: Event[] = [];
  events: TEvent[] = [];
  filtered$: BehaviorSubject<TEvent[]> = new BehaviorSubject<TEvent[]>([]);

  injectMenu$ = this.injectorService.mainToolbar$.pipe(autoUnsubscribe());
  toolbar: ViewContainerRef;
  toolbarComponent: ComponentRef<DashboardToolbarComponent>;
  toolbarCurrentTab: MainToolbarTab;
  filter: string;
  eventListRef: HTMLElement;
  noEvents = false;
  tagsFilter: TEventTag[] = [];
  isDesktop$ = this.common.isMobile$().pipe(map(res => !res));

  constructor(private loginService: LoginService,
              private eventsDataService: EventsDataService,
              private debug: DebugService,
              private injectorService: InjectorService,
              private common: CommonService) {
  }

  ngOnInit(): void {
    this.injectMenu$
      .pipe(delay(0))
      .subscribe((value) => {
        if (value) {
          this.toolbar = value;
          value.clear();
          this.unsubscribe('onChange');
          this.toolbarComponent = value.createComponent(DashboardToolbarComponent);
          this.subscriptions['onChange'] = this.toolbarComponent.instance.onChange
            .subscribe((change) => {
              this.toolbarCurrentTab = change;
              this.loadEvents(change);
              this.eventListRef.scrollTop = 0;
            });
          this.unsubscribe('onSearch');
          this.subscriptions['onSearch'] = this.toolbarComponent.instance.onSearch
            .subscribe((change) => {
              this.pageLength = this.defaultScrollSettings.pageLength;
              this.eventListRef.scrollTop = 0;
              this.filter = change;
              this.applyFilter();
            });
          this.unsubscribe('onTagFilter');
          this.subscriptions['onTagFilter'] = this.toolbarComponent.instance.onTagsFilter
            .subscribe((change) => {
              this.pageLength = this.defaultScrollSettings.pageLength;
              this.eventListRef.scrollTop = 0;
              this.tagsFilter = change;
              this.applyFilter();
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.toolbar.clear();
    Object.keys(this.subscriptions).forEach((key) => {
      this.unsubscribe(key);
    });
  }

  ngAfterViewInit(): void {
    this.eventListRef = document.getElementById('eventList');
    if (this.eventListRef) {
      this.subscriptions['onScroll'] = fromEvent(this.eventListRef, 'scroll')
        .subscribe((e) => {
          const target = e.target as HTMLDivElement;
          const tableViewHeight = target.offsetHeight;
          const tableScrollHeight = target.scrollHeight;
          const scrollLocation = target.scrollTop;
          const buffer = this.defaultScrollSettings.buffer;
          const limit = tableScrollHeight - tableViewHeight - buffer;
          if (scrollLocation > limit) {
            this.pageLength += this.defaultScrollSettings.pageStep;
            this.applyFilter();
          }
        });
    }
  }

  unsubscribe(key: string) {
    if (this.subscriptions[key]) {
      this.subscriptions[key].unsubscribe();
      this.subscriptions[key] = null;
    }
  }

  get currentTime() {
    const ctime = this.common.utils.getSystemCurrentTime();
    return ctime ? ctime : new Date().getTime();
  }

  loadEvents(mode: string) {
    const applyData = (events) => {
      this.noEvents = !events.length;
      this.events = [].concat(events);
      this.applyFilter();
      this.loading$.next(false);
    };
    const sortEvents = (events) => {
      events = (events || []).sort(this.common.utils.comparator('startDate'));

      const permanent = events.filter(it => it.dateMode === EVENT_DATE_MODE.ANYTIME);
      const other = events.filter(it => it.dateMode !== EVENT_DATE_MODE.ANYTIME);

      const running = other.filter(event => {
        const extEvent = new ExtEvent(this.common.utils, event);
        const status = extEvent.getEventStatus(this.currentTime);
        return status[0] === Constants.EVENT_STATUS_RUNNING;
      }).sort(this.common.utils.comparator('startDate', 'desc'));

      const runningIds = running.map(it => it.eventId);

      const statusesSoon = [
        Constants.EVENT_STATUS_EVENT_STARTS_ON,
        Constants.EVENT_STATUS_EVENT_STARTS_IN,
        Constants.EVENT_STATUS_EVENT_OPEN_IN,
        Constants.EVENT_STATUS_EVENT_OPEN_TO,
        Constants.EVENT_STATUS_EVENT_OPEN_ON
      ];
      const soon = other.filter(event => {
        const extEvent = new ExtEvent(this.common.utils, event);
        const status = extEvent.getEventStatus(this.currentTime);
        return statusesSoon.includes(status[0]);
      });
      const soonIds = soon.map(it => it.eventId);
      const availableUntil = other
        .filter(it => !runningIds.includes(it.eventId) && !soonIds.includes(it.eventId))
        .filter(it => it.dateMode === EVENT_DATE_MODE.PUBLISHED_WITH_TIME_RANGE)
        .sort(this.common.utils.comparator('startDate', 'desc'));
      const past = other
        .filter(it => !runningIds.includes(it.eventId) && !soonIds.includes(it.eventId))
        .filter(it => it.dateMode !== EVENT_DATE_MODE.PUBLISHED_WITH_TIME_RANGE)
        .sort(this.common.utils.comparator('endDate', 'desc'));

      const sorted = running.concat(soon).concat(availableUntil).concat(permanent).concat(past);
      if (events?.length !== sorted?.length) {
        this.debug.postCustomError(`Dashboard. Wrong sort results. before=${events?.length}, after=${sorted?.length}`);
      }
      return sorted;
    };
    this.loading$.next(true);
    if (mode === 'my') {
      if (this.subscriptions['myEvents']) {
        applyData(this.myEvents);
      } else {
        this.subscriptions['myEvents'] = this.eventsDataService.getMyEvents()
          .subscribe((events) => {
            this.myEvents = sortEvents(events);
            if (this.toolbarCurrentTab === 'my') {
              applyData(this.myEvents);
            }
          });
      }
    } else if (mode === 'public') {
      if (this.subscriptions['publicEvents']) {
        applyData(this.publicEvents);
      } else {
        this.subscriptions['publicEvents'] = this.eventsDataService.getPublicEvents()
          .subscribe((events) => {
            this.publicEvents = sortEvents(events);
            if (this.toolbarCurrentTab === 'public') {
              applyData(this.publicEvents);
            }
          });
      }
    } else if (mode === 'past') {
      if (this.subscriptions['pastEvents']) {
        applyData(this.pastEvents);
      } else {
        this.subscriptions['pastEvents'] = this.eventsDataService.getMyEvents(true)
          .subscribe((events) => {
            this.pastEvents = sortEvents(events);
            if (this.toolbarCurrentTab === 'past') {
              applyData(this.pastEvents);
            }
          });
      }
    } else if (mode === 'all') {
      if (this.subscriptions['allEvents']) {
        applyData(this.allEvents);
      } else {
        this.subscriptions['allEvents'] = this.eventsDataService.getEvents()
          .subscribe((events) => {
            this.allEvents = sortEvents(events);
              if (this.toolbarCurrentTab === 'all') {
                applyData(this.allEvents);
              }
          });
      }
    } else {
      applyData([]);
    }
  }

  applyFilter() {
    const find = (e: TEvent, search: string) => {
      if (!e) {
        return false;
      }
      const names = Object.values(e.managers || {}).map(m => m.userName).join(' ').toLowerCase();
      const emails = Object.values(e.managers || {}).map(m => m.email).join(' ').toLowerCase();
      const sh = search?.toLowerCase();
      return e.name?.toLowerCase().includes(sh) || names.includes(sh) || emails.includes(sh);
    };
    const filtered = this.events
      .filter(it => this.filter ? find(it, this.filter) : true)
      .filter(it => {
        if (this.toolbarCurrentTab !== 'all') {
          return true;
        }
        const eventTags = Object.keys(it.tags || {});
        const filterTags = this.tagsFilter.map(el => el.id);
        return isEmpty(filterTags) ? true : eventTags.filter(id => filterTags.includes(id)).length;
      })
      .slice(0, this.pageLength);
    this.filtered$.next(filtered);
  }

  onClick(event: Event) {

  }

  leaveAction(event: Event) {
    this.common.showProgress.next(true);
    const uid = this.loginService.getAuthUser().uid;
    const email = this.loginService.getAuthUser().email;
    return this.eventsDataService.removeUserFromConference(event.eventId, uid, null, email)
      .catch((e) => this.common.log.error(e))
      .finally(() => this.common.showProgress.next(false));
  }

  goToExploreTab() {
    this.toolbarComponent.instance.openTab$.next('public');
  }

}
