import {Component, Injector, OnInit} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, firstValueFrom} from 'rxjs';
import {HighlightTableEditorDialogComponent} from '../highlight-table-editor-dialog/highlight-table-editor-dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import {THighlightTableRow} from '../highlight-table-model/highlight-table-model';
import {LANGUAGE} from '../../../../../core/language-constants';
import {IMultilingual} from '../../../shared/container-interface';
import {ILanguageParams} from '../../../../../core/constants';
import {cloneDeep, isEmpty} from 'lodash';
import {TranslateApiService} from '../../../../../services/translate-api.service';

@Component({
  selector: 'app-highlight-table',
  templateUrl: './highlight-table.component.html',
  styleUrls: ['./highlight-table.component.scss']
})
export class HighlightTableComponent extends AbstractContainerComponent implements OnInit {

  dataSource = new MatTableDataSource<THighlightTableRow>();
  languageParams$ = new BehaviorSubject<ILanguageParams>(null);


  constructor(protected injector: Injector,
              private dialog: MatDialog,
              private translateApiService: TranslateApiService) {
    super(injector);
  }

  ngOnInit(): void {
    combineLatest([this.currentLanguage$, this.data$, this.usedMultilingualContent$])
      .pipe(this.takeUntilAlive())
      .subscribe(([currentLanguage, value, usedMultilingualContent]: [LANGUAGE, any, IMultilingual]) => {
        this.languageParams$.next({
          defaultLanguage: this.defaultLanguage$.getValue(),
          currentLanguage: currentLanguage,
          usedMultilingualContent: usedMultilingualContent.multilingual,
          usedLanguages: usedMultilingualContent.usedLanguages
        });
        if (!isEmpty(this.languageParams$.getValue())) {
          this.dataSource.data = (value || []).map(o => new THighlightTableRow(o, this.languageParams$.getValue()));
        }
        this.emmitCurrentLanguageTranslatedState();
      });
  }

  onEdit() {
    const dialogRef = this.dialog.open(HighlightTableEditorDialogComponent, {
      data: {
        data: this.data,
        languageParams$: this.languageParams$
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = result;
          this.dataSource.data = (result || []).map(o => new THighlightTableRow(o, this.languageParams$.getValue()));
        }
        return !isEmpty(result);
      });
  }

  protected inputFollowMeData(value) {}

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  async translate() {
    const lp = this.languageParams$.getValue();
    const data = (cloneDeep(this.data || [])).map(o => new THighlightTableRow(o, lp));
    for (const row of data) {
      row.rowTitle = await this.translateApiService.translateSimpleString(row.rowTitle, lp.defaultLanguage, lp.currentLanguage);
      row.rowDescription = await this.translateApiService.translateSimpleString(row.rowDescription, lp.defaultLanguage, lp.currentLanguage);
    }
    this.data = data.map(o => o.simplify());
    this.dataSource.data = (data || []).map(o => new THighlightTableRow(o, this.languageParams$.getValue()));
    return Promise.resolve();
  }
}
