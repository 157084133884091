import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {BehaviorSubject} from 'rxjs';
import {EventQuestion, IGapAnswerData, IGapGroup, IGapGroupMatching, IGapResultDataMap} from '../../../../../../../model/EventQuestion';

@Component({
  selector: 'app-question-select-gap-filling-results',
  templateUrl: './question-select-gap-filling-results.component.html',
  styleUrls: ['./question-select-gap-filling-results.component.scss']
})
export class QuestionSelectGapFillingResultsComponent extends AbstractQuizQuestionResultsComponent {

  gapGroups$ = new BehaviorSubject<IGapGroup[]>([]);
  gapGroupsMatching$ = new BehaviorSubject<IGapGroupMatching[]>([]);
  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapResultData$ = new BehaviorSubject<IGapResultDataMap>({});
  gapTaskText$ = new BehaviorSubject<string>(null);
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    this.gapGroups$.next((this.question.matchingItems || [])
      .map(v => new Object({id: v.id, name: v.getAnswerByLanguage(this.languageParams)}) as IGapGroup));
    this.gapGroupsMatching$
      .next((this.question.items || [])
        .map(v => new Object({id: v.id, name: v.getAnswerByLanguage(this.languageParams),
          matchingId: v.matching[0]}) as IGapGroupMatching));
    this.gapAnswersData$
      .next(this.question.showCorrectAnswers  || this.editorMode ?
        this.question.groupsCorrectAnswers?.find(g => g.defaultGroup)
          ?.correctEquality?.map(v => new Object({id: v.id, answerId: v.answerId}) as IGapAnswerData) : []);
    this.answers = this.groupSelectGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$
      .next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.groupSelectGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$.next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
  }

  private groupSelectGapFillingSummaryAnswers(question: EventQuestion, qContentAnswers: {[answerEquality: string]: number}) {
    const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
    const correctAnswers = correctEquality.map(o => `${o.id}=${o.answerId}`);
    const result: {[gapId: string]: {correctCount: number, errorCount: number, correctPercent: number, errorPercent: number}} =
      correctEquality.reduce((acc, item) => {
        acc[item.id] = {correctCount: 0, errorCount: 0, correctPercent: 0, errorPercent: 0};
        return acc;
      }, {});
    for (const answerEquality of Object.keys(qContentAnswers || {})) {
      const count = qContentAnswers[answerEquality] ?? 0;
      if (!count) {
        continue;
      }
      const arr = answerEquality.split('=');
      const groupId = arr[0];
      const answers: string[] = [];
      arr.filter(id => id !== groupId).forEach(id => answers.push(`${groupId}=${id}`));
      if (result[groupId]) {
        if (answers.every(a => correctAnswers.includes(a))) {
          result[groupId].correctCount += count;
        } else {
          result[groupId].errorCount += count;
        }
      }
    }
    for (const r of Object.values(result)) {
      const maxCount = r.correctCount + r.errorCount;
      if (r.correctCount > 0) {
        r.correctPercent = 100 / (maxCount / r.correctCount);
      }
      if (r.errorCount > 0) {
        r.errorPercent = 100 / (maxCount / r.errorCount);
      }
    }
    return result;
  }

}
