import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {filter, from, map, mergeMap, Observable, take, tap} from 'rxjs';
import {CanActivateAuthGuard} from '../login/can-activate.authguard';
import {LoginService} from '../login/login.service';
import {Constants} from '../core/constants';
import firebase from 'firebase/compat/app';
import IdTokenResult = firebase.auth.IdTokenResult;

@Injectable({
  providedIn: 'root'
})
export class CanActivateSuperadminGuard  {

  constructor(private _authGuard: CanActivateAuthGuard,
              private router: Router,
              private loginService: LoginService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._authGuard.canActivate(next, state)
      .pipe(
        filter(auth => !!auth),
        mergeMap(auth => {
          return from(this.loginService.getAuthUser().getIdTokenResult())
            .pipe(
              take(1),
              tap((id_token: IdTokenResult) => {
                if (!id_token.claims || !id_token.claims.roles || !id_token.claims.roles.includes(Constants.ROLE_SUPERADMIN)) {
                  this.router.navigate(['dashboard']);
                }
              }),
              map(it => !!it)
            );
        })
      );
  }
}
