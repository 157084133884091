import {Component, ElementRef, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {StdComponent} from '../../core/std-component';
import {MatButton, MatIconAnchor, MatIconButton, MatMiniFabButton} from '@angular/material/button';
import {MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatCheckbox} from '@angular/material/checkbox';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatOption, MatSelect, MatSelectTrigger} from '@angular/material/select';
import {Constants, ILanguage, TRIPLE} from '../../core/constants';
import {LoginService} from '../../login/login.service';
import {clone, cloneDeep, isEmpty} from 'lodash';
import {AppUser} from '../../model/AppUser';
import {UserAvatarComponent} from '../../user-avatar/user-avatar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountriesService} from '../../core/countries.service';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {CommonService} from '../../core/common.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {EditDialogComponent} from '../../dialog-components/edit-dialog/edit-dialog.component';
import {BehaviorSubject, filter, take} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {UsersDataService} from '../../services/users-data.service';
import * as data from '../../actions/data';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {UtilsService} from '../../core/utils.service';
import {LoadingProgressComponent} from '../../components/loading-progress/loading-progress.component';
import {MatInput} from '@angular/material/input';
import * as ui from '../../actions/ui';

const DIALOG_HEIGHT = '650px';
const DIALOG_WIDTH = '840px';

@Component({
  selector: 'app-user-account-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatIconButton,
    MatSuffix,
    MatButton,
    MatCheckbox,
    TranslateModule,
    MatSelect,
    MatOption,
    UserAvatarComponent,
    FormsModule,
    NgIf,
    NgForOf,
    CdkTextareaAutosize,
    MatMenu,
    MatMenuTrigger,
    MatIconAnchor,
    ReactiveFormsModule,
    MatSelectTrigger,
    AsyncPipe,
    LoadingProgressComponent,
    MatFormField,
    MatInput,
    MatMiniFabButton
  ],
  templateUrl: './user-account-dialog.component.html',
  styleUrl: './user-account-dialog.component.scss'
})
export class UserAccountDialogComponent extends StdComponent implements OnInit {

  readonly Constants = Constants;
  appUser: AppUser;
  clientConfig;
  receiveEmailNotifications: boolean;
  userLanguage: any;
  userCountry: any;
  userSocialNetList: any[] = [];
  socNetList = Object.keys(Constants.SOCIAL_NETWORK_ICONS);
  SOCIAL_NETWORK_ICONS = Constants.SOCIAL_NETWORK_ICONS;
  isAzure = false;
  isEditMode = false;
  countryList: any[] = [];
  languageList: ILanguage[] = [];
  browserLanguage;
  browserLanguageCanBeUsed;
  private dataHash: string;
  isModified = false;
  isLangModified = false;
  isCountryModified = false;
  isPasswordProvider = this.loginService.isPasswordProvider();
  progress$ = new BehaviorSubject(false);
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  contentChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(public dialogRef: MatDialogRef<UserAccountDialogComponent>,
              private store: Store<fromRoot.State>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public injector: Injector,
              public loginService: LoginService,
              public countriesService: CountriesService,
              private userDataService: UsersDataService,
              private translate: TranslateService,
              public dialog: MatDialog,
              public common: CommonService,
              private sanitizer: DomSanitizer,
              private elementRef: ElementRef
  ) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.addPanelClass('questions-participants-detail');
    dialogRef.updateSize(DIALOG_WIDTH);
    dialogRef.disableClose = true;
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (!this.progress$.getValue()) {
          if (event.key === Constants.ESCAPE && this.isModified) {
            const closeType = await this.common.confirmationSaveChanged();
            if (closeType === TRIPLE.YES) {
              this.onOkClick();
            } else if (closeType === TRIPLE.OTHER) {
              return this.onNoClick();
            }
          } else if (event.key === Constants.ESCAPE && !this.isModified) {
            this.onNoClick();
          }
        }
      });

    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
      this.isModified = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.appUser));
    });

    this.elementRef.nativeElement.style.setProperty('--mdc-dialog__height', DIALOG_HEIGHT);
    this.countryList = this.countriesService.getList().map(it => ({name: it.name, value: it.code}));
    this.languageList = this.common.utils.getLanguageList();
    this.browserLanguage = this.common.utils.getBrowserLanguage();
    this.browserLanguageCanBeUsed = this.common.utils.checkBrowserLanguage(this.browserLanguage);
    if (!isEmpty(data)) {
      this.clientConfig = data.clientConfig;
      this.appUser = new Proxy(cloneDeep(data.appUser), this.contentChangesHandler);
      this.dataHash = UtilsService.md5(UtilsService.jsonSorted(this.appUser));
      this.receiveEmailNotifications = !this.appUser.unsubscribe;
      const snt = JSON.parse(this.appUser.socialNetwork);
      if (snt) {
        this.userSocialNetList = snt;
      }
      this.userLanguage = this.appUser.language ? this.appUser.language : 'default';
      this.userCountry = this.appUser.countryCode ? this.countryList.find(obj => obj.value === this.appUser.countryCode) : '';
    }
    if (this.browserLanguageCanBeUsed && this.browserLanguage !== this.clientConfig.language) {
      this.languageList.splice(0, 0, {
        name: this.common.i18n('language.default.browser',
          {
            name: this.common.i18n(
              this.languageList.find(l => l.value === this.browserLanguage).name)
          }),
        value: 'default'
      });
    }
    if (!this.browserLanguageCanBeUsed) {
      const defClientLanguage = this.languageList.find(l => l.value === this.clientConfig.language);
      if (defClientLanguage) {
        this.languageList.splice(0, 0, {
          name: this.common.i18n('language.default.timeline',
            {
              name: this.common.i18n(defClientLanguage.name)
            }),
          value: 'default'
        });
      }
    }

    const loginProvider = this.loginService.getCurrentProviderId();
    this.isAzure = loginProvider === Constants.PROVIDER_HKVBS_MICROSOFT;

  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    const toolbar = document.getElementById('main-toolbar');
    if (toolbar) {
      toolbar.classList.add('menu-blur');
    }
    const container = document.getElementById('main-container');
    if (container) {
      container.classList.add('menu-blur');
    }
  }

  getSocialIconClass(networkName: string): string {
    return `ti s-24 ${this.SOCIAL_NETWORK_ICONS[networkName] || ''}`;
  }

  signOut() {
    this.dialogRef.close();
    this.loginService.signOut(true);
  }

  get readOnly(): boolean {
    return !this.appUser || !this.appUser.userId;
  }

  clickAddNet(netName) {
    const contact = '';
    const dialogData = {title: this.common.utils.i18n('common.add_social_network_dialog.caption')
      , fields: [
        { id: 'contact', type: 'text',
          required: true,
          placeholder: this.common.utils.i18n('common.edit_social_network.contact'),
          hinttext: this.common.utils.i18n('reducere_e.g.') + ', ' + Constants.SOCIAL_NETWORK_TABLE[netName].template
        }
      ]
      , result: {contact: contact, userNetworkName: netName}};
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userSocialNetList.push({
          id: (new Date()).getTime().toString(),
          contact: result.contact,
          userNetworkName: result.userNetworkName});
        this.appUser.socialNetwork = JSON.stringify(this.userSocialNetList);
      }
    });
  }

  clickEditNet(net) {
    const contact = net.contact;
    const dialogData = {
      title: this.common.utils.i18n('common.edit_social_network_dialog.caption')
      , fields: [
        {id: 'contact', type: 'text', placeholder: this.common.utils.i18n('common.edit_social_network.contact')}
      ]
      , result: {contact: contact, userNetworkName: net.userNetworkName, id: net.id}
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userSocialNetList.forEach((netVal, index) => {
          if (netVal.id === result.id) {
            this.userSocialNetList[index] = result;
          }
        });
        this.appUser.socialNetwork = JSON.stringify(this.userSocialNetList);
      }
    });
  }

  onDeleteNet(net) {
    this.common.confirm(
      this.common.utils.i18n('edit_dialog.confirm_dialog.header'),
      this.common.utils.i18n('edit_dialog.confirm_dialog.body.social.network')).pipe(
      take(1)
    ).subscribe(result => {
      if (result) {
        let delIndex = -1;
        this.userSocialNetList.forEach((netVal, index) => {
          if (netVal.id === net.id) {
            delIndex = index;
          }
        });
        if (delIndex > -1) {
          this.userSocialNetList.splice(delIndex, 1);
          this.appUser.socialNetwork = JSON.stringify(this.userSocialNetList);
        }
      }
    });
  }

  getUrl(usernet) {
    if (usernet.userNetworkName !== Constants.SKYPE) {
      return usernet.contact;
    }
    let contact = '';
    this.userSocialNetList.forEach(netVal => {
      if (netVal.userNetworkName === usernet.userNetworkName) {
        contact = netVal.contact;
      }
    });
    return Constants.SOCIAL_NETWORK_TABLE[usernet.userNetworkName].external_link
      .replace('profile_name', contact);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(['https', 'http'].some(pre => url.startsWith(pre)) ? url : `${document.location.protocol}//${url}`);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  async onOkClick() {
    try {
      this.progress$.next(true);

      this.appUser.socialNetwork = JSON.stringify(this.userSocialNetList);
      await this.userDataService.updateUserCard(this.appUser.userId,
        this.appUser.description,
        this.appUser.socialNetwork,
        this.appUser.hideEmail,
        this.appUser.department);

      await this.setUserInterfaceLanguage(this.userLanguage);

      await this.updateUserGeolocation(this.userCountry);

      if (this.isPasswordProvider && this.appUser.fullName !== this.data.appUser) {
        await this.userDataService.updateUserName(this.appUser.fullName);
      }

      this.progress$.next(false);
      this.dialogRef.close();
    } catch (error) {
      this.common.log.error(error);
      this.progress$.next(false);
    }
  }

  private async setUserInterfaceLanguage(userLanguage: string): Promise<void> {
    if (userLanguage === 'default') {
      this.appUser.language = null;
      await this.userDataService.setUserInterfaceLanguage('default');
      const targetLanguage = this.browserLanguageCanBeUsed
        ? this.browserLanguage
        : this.clientConfig.language;
      this.translate.use(targetLanguage);
    } else {
      this.appUser.language = userLanguage;
      await this.userDataService.setUserInterfaceLanguage(userLanguage);
      this.translate.use(userLanguage);
    }
  }

  private async updateUserGeolocation(country: any): Promise<void> {
    const response = await this.userDataService.setGeolocation(country.name, country.value);

    const updatedUser = cloneDeep(this.appUser);
    updatedUser.country = country.name;
    updatedUser.countryCode = country.value;

    this.loginService.setAppUser(updatedUser);
    this.store.dispatch(new data.SetCurrentUserAction(updatedUser));
  }

  editMode() {
    this.isEditMode = !this.isEditMode;
  }

  onDestroy() {
    super.onDestroy();
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    const toolbar = document.getElementById('main-toolbar');
    if (toolbar) {
      toolbar.classList.remove('menu-blur');
    }
    const container = document.getElementById('main-container');
    if (container) {
      container.classList.remove('menu-blur');
    }
  }

  changeLanguage(event: any) {
    if (event.isUserInput) {
      this.isLangModified = event.source.value !== this.appUser.language;
    }
  }

  changeCountry(event: any) {
    if (event.isUserInput) {
      this.isCountryModified = event.source.value.value !== this.appUser.countryCode;
    }
  }
}
