import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, delay, filter, firstValueFrom, of, skip, Subject, Subscription, switchMap, take} from 'rxjs';
import {IMeeting, IMeetingParams} from '../model/meeting/Meeting';
import {NAVIGATION_TO, TimeLineService} from './time-line.service';
import {MeetingApiService} from './meeting-api.service';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {
  Constants,
  FOLLOW_ME_MODE,
  MEETING_STATE,
  MEETING_VIEW_MODE,
  VIRTUAL_CONFERENCE_EVENT,
  VIRTUAL_CONFERENCE_TYPE
} from '../core/constants';
import {Event} from '../model/Event';
import {SectionContent} from '../model/content/SectionContent';
import {ConferenceUser} from '../model/event-mode/ConferenceUser';
import * as ui from '../actions/ui';
import {CommonService} from '../core/common.service';
import {EventsDataService} from './events-data.service';
import {LoginService} from '../login/login.service';
import {isEmpty} from 'lodash';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {VirtualConferenceSettings} from '../model/event-mode/InstantSettings';
import {DailyCoService} from '../modules/meeting-frame/services/daily-co.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FollowMeService, IFollowMeMeetingViewMode, IFollowMeMeetingViewModeMap} from './follow-me.service';
import { DAILY_CO_ACTION } from '../modules/meeting-frame/services/daily-co.constants';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  isCurrentSectionNoConference$ = new BehaviorSubject<boolean>(false);

  private _changeCurrentMeeting = new BehaviorSubject<IMeetingParams>(null);
  private _meetingDialogOpened = new BehaviorSubject<boolean>(false);
  private _closeMeetingFrame = new Subject<boolean>();
  private _stopInstantGroupMeetings = new Subject<boolean>();
  private featureLine$ = new BehaviorSubject<string>(null);

  private currentEvent: Event;
  eventMeeting: IMeeting;
  eventMeetingParams: IMeetingParams = {};
  sectionMeeting: IMeeting;
  sectionMeetingParams: IMeetingParams = {};
  joinedSection$ = new BehaviorSubject<SectionContent>(null);
  _currentMeetingId;
  meetingCloseManually = false;
  subscribeFutureLine: Subscription;
  conferenceUser: ConferenceUser;

  planeListSubscription: Subscription;
  subscriptions: {[key: string]: Subscription} = {};
  meetingState$ = new BehaviorSubject<string>(null);
  joinTimer: Subscription;
  meetingViewMode = new BehaviorSubject<MEETING_VIEW_MODE>(MEETING_VIEW_MODE.INTERNAL);
  joinedSectionChanged = false;
  disablePlayButton = new BehaviorSubject<boolean>(false);

  constructor(private common: CommonService,
              private loginService: LoginService,
              private timelineService: TimeLineService,
              private followMeService: FollowMeService,
              private meetingApiService: MeetingApiService,
              private store: Store<fromRoot.State>,
              private dataService: EventsDataService,
              private dailyCoService: DailyCoService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

    this.loginService.authenticatedUser
      .pipe(
        switchMap((user) => {
          return user ? this.timelineService.eventDataLoaded$ : of(null);
        }),
        switchMap((loaded) => loaded ? of (loaded).pipe(delay(3000)) : of(loaded)),
        switchMap((loaded) => {
          return loaded && this.timelineService.destination === NAVIGATION_TO.EVENT ? this.timelineService.event$ : of(null);
        })
      )
      .subscribe((event) => {
        if (!event) {
          this.switchOff();
          return;
        }
        let inited = false;
        if (!this.currentEvent || this.currentEvent.eventId !== event.eventId) {
          this.currentEvent = event;
          this.clearPlaneListSubscription();
          this.planeListSubscription = this.timelineService.planeList
            .subscribe(planeList => {
              if (!isEmpty(planeList)) {
                this.eventMeeting = this.timelineService.rootSection.meeting;
                if (!inited) {
                  inited = true;
                  this.initFutureLineSubscription();
                  this.init();
                }
              } else {
                // clear
                this.eventMeeting = null;
                this.sectionMeeting = null;
                this.meetingState = MEETING_STATE.STOP;
              }
            });
        }
      });

    this.timelineService.conferenceUser$
      .subscribe(user => {
        this.conferenceUser = user;
      });

    this.dailyCoService.dailyCoAction$
      .subscribe(ev => {
        if (ev !== DAILY_CO_ACTION.KICK_USER) {
          this.joinMeeting(null);
        }
        this.meetingDialogOpened.next(false);
      });
  }

  get meetingState() {
    return this.meetingState$.getValue();
  }

  set meetingState(value: string) {
    this.meetingState$.next(value);
  }

  get isCurrentSectionNoConference() {
    return this.isCurrentSectionNoConference$.getValue();
  }

  set isCurrentSectionNoConference(value: boolean) {
    this.isCurrentSectionNoConference$.next(value);
  }

  switchOff() {
    this.currentEvent = null;
    this.sectionMeeting = null;
    this.isCurrentSectionNoConference = false;
    this.meetingState = MEETING_STATE.STOP;
    this.disablePlayButton.next(false);
    this.clearPlaneListSubscription();
    this.switchOffAll();
    return null;
  }

  get joinedSection(): SectionContent {
    return this.joinedSection$.getValue();
  }

  set joinedSection(value: SectionContent) {
    this.joinedSection$.next(value);
  }

  clearPlaneListSubscription() {
    if (this.planeListSubscription) {
      this.planeListSubscription.unsubscribe();
      this.planeListSubscription = null;
    }
  }

  switchOffAll() {
    this.meetingDialogOpened.next(false);
    this.closeMeetingFrame.next(true);
    this.currentMeetingId = null;
    this.joinedSection = null;
    if (this.subscribeFutureLine) {
      this.subscribeFutureLine.unsubscribe();
      this.subscribeFutureLine = null;
    }
    this.common.utils.unsubscribeAll(this.subscriptions);
  }

  isMainEventNoConference() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE;
  }

  isVirtualConferenceIntegratedLiveStreaming() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings
      .mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING;
  }

  isVirtualConferenceExternalLiveStreaming() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings
      .mainEventOption === VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING;
  }

  isVirtualConferenceRecordedVideoStreaming() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.RECORDED_VIDEO_STREAMING;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.RECORDED_VIDEO_STREAMING;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings
      .mainEventOption === VIRTUAL_CONFERENCE_EVENT.RECORDED_VIDEO_STREAMING;
  }

  isVirtualConferenceIntegratedLiveConference() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings
      .mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE;
  }

  getMainEventOption() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.mainEventOption;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.mainEventOption;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.mainEventOption;
  }

  getDefaultSize() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.defaultSize;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.defaultSize;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.defaultSize;
  }

  getAllowOpeningInSeparateTab() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.allowOpeningInSeparateTab;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.allowOpeningInSeparateTab;
    }
    return this.timelineService.instantSettings?.virtualConferenceSettings?.allowOpeningInSeparateTab;
  }

  getAllowManualSizeChange() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.allowManualSizeChange;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.allowManualSizeChange;
    }
    return this.timelineService.instantSettings?.virtualConferenceSettings?.allowManualSizeChange;
  }

  getVirtualConferenceSettings(): VirtualConferenceSettings {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings;
  }

  getLinkToLiveStreaming() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.linkToLiveStreaming;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.linkToLiveStreaming;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.linkToLiveStreaming;
  }

  getLiveConferenceIframeHTML() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.embedVariables;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.embedVariables;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.embedVariables;
  }

  getAutoplay() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.autoplay;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.autoplay;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.autoplay;
  }

  getPositionSynchronization() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.positionSynchronization;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId]
        .sectionContent.virtualConferenceSettings.positionSynchronization;
    }
    return this.timelineService.instantSettings.virtualConferenceSettings.positionSynchronization;
  }

  getJoinedSectionId() {
    if (this.joinedSection && !this.joinedSection.isRoot && (this.joinedSection.specificVideoConferences ||
      (this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
        this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences))) {
      return this.joinedSection.id;
    }
    return this.timelineService.rootSection ? this.timelineService.rootSection.id  : null;
  }

  get currentUser() {
    return this.timelineService.currentUser;
  }

  get isPresenter() {
    return this.timelineService.isPresenter;
  }

  init() {
    this.common.utils.unsubscribeAll(this.subscriptions);
    this.subscriptions['eventInstantSettings'] = this.timelineService.instantSettings$.subscribe((value) => {
      if (value && this.eventMeeting && this.eventMeeting.meetingId === this.getCurrentMeeting() &&
        this.meetingState === MEETING_STATE.PLAY && this.isMainEventNoConference()) {
        if (this.isPresenter) {
          this.startStopEventMeeting(true);
        } else {
          this.meetingDialogOpened.next(false);
          this.closeMeetingFrame.next(true);
          this.currentMeetingId = null;
        }
      }
      if (value && this.sectionMeeting && this.sectionMeeting.meetingId === this.getCurrentMeeting() &&
        this.meetingState === MEETING_STATE.PLAY &&
        (this.sectionMeeting.meetingId === this.timelineService.rootSection.id && this.isMainEventNoConference())) {
        if (this.isPresenter) {
          this.startStopEventMeeting(true);
        } else {
          this.meetingDialogOpened.next(false);
          this.closeMeetingFrame.next(true);
          this.currentMeetingId = null;
        }
      }
    });
    let firstValue = true;
    this.subscriptions['customAction'] = this.dataService.getCustomActionValue(this.currentEvent.eventId, 'meetingState')
      .subscribe(state => {
        /**Verify if event conference is enabled */
        const hasEventConference = this.timelineService.instantSettings.canShowVirtualConferences;
        if (state && this.meetingState !== state.value && hasEventConference) {
          if (state.value === MEETING_STATE.STOP && this.meetingState === MEETING_STATE.PLAY) {
            const sectionId = this.timelineService.selectedSection?.id ?? null;
            this.router.navigate([], {queryParams: {sid: sectionId}, relativeTo: this.activatedRoute});
          }
          this.meetingState = !isEmpty(state.value) ? state.value : MEETING_STATE.STOP;
          if (this.meetingState === MEETING_STATE.PLAY) {
            let section = this.timelineService.getSectionContent(this.timelineService.featureLineContentId);

            // if the user has joined a parallel section that is a child of the feature line, dont do anything
            if (section && !section.isRoot && section.container && this.joinedSection?.parentId === section.id) {
              return;
            }

            // try to join to main event video conference if featureline doesnt have one
            if (section && !section.isRoot && (!section?.specificVideoConferences)) {
              section = null;
            }

            this.joinMeeting(section);
          }
          if (this.meetingState === MEETING_STATE.STOP && !firstValue) {
            this.meetingDialogOpened.next(false);
            this.closeMeetingFrame.next(true);
            if (!this.currentMeetingId) { this.currentMeetingId = this.eventMeeting?.meetingId; }
            if (this.currentMeetingId && this.joinedSection?.isRoot) {
              this.joinedSection = null;
            }
            this.currentMeetingId = null;
          }
        } else if (!state && this.meetingState !== MEETING_STATE.STOP && !firstValue) {
          this.meetingState = MEETING_STATE.STOP;
          this.meetingDialogOpened.next(false);
          this.closeMeetingFrame.next(true);
          this.currentMeetingId = null;
        }
        firstValue = false;
      });

    this.subscriptions['joinSection$'] = this.timelineService.joinSectionSubject
      .pipe(skip(1))
      .subscribe(object => {
        if (object && object.section.parentId === this.timelineService.featureLineContentId) {
          if (object.join) {
            if (this.meetingState !== MEETING_STATE.STOP) {
              this.joinMeeting(object.section as SectionTimeline);
            }
          } else if (!object.join && this.isSectionContainer(object.section as SectionTimeline)) {
            this.meetingDialogOpened.next(false);
            this.joinedSection = null;
            this.sectionMeeting = null;
            this.currentMeetingId = null;
          }
        } else if (object && object.section && !object.join && this.meetingDialogOpened.getValue()) {
          this.meetingDialogOpened.next(false);
          this.joinedSection = null;
          this.sectionMeeting = null;
          this.currentMeetingId = null;
        }
      });
    this.subscriptions['contentChanged'] = this.followMeService.contentChanged$
      .subscribe(content => {
        if (content && content['type'] === Constants.CONTENT_TYPE_QUESTIONNAIRE &&
          this.timelineService.instantSettings.canShowVirtualConferences &&
          this.timelineService.instantSettings.virtualConferenceSettings.autoSizeControl &&
          this.isMeetingRunning() && this.getMeetingType() !== VIRTUAL_CONFERENCE_TYPE.DAILY_CO) {
          this.store.dispatch(new ui.SetMeetingViewMode(MEETING_VIEW_MODE.INTERNAL));
        }
      });
    const getViewModeObject = (lst: IFollowMeMeetingViewModeMap) => {
      const sId = this.dailyCoService.ownerStreamSectionId ?
        this.dailyCoService.ownerStreamSectionId : this.timelineService.rootSection.id;
      return lst ? lst[sId] : null;
    };
    this.subscriptions['followMeMeetingViewMode'] = this.dataService.getFollowMeCurrentMeetingViewMode(this.currentEvent.eventId)
      .subscribe(list => {
        const selSection = this.timelineService.selectedSection;
        const isParentContainer = this.timelineService.parentIsContainer(selSection);
        const followMeEnabled = this.timelineService.eventInstantSettings.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME;
        if (!this.isPresenter && !this.timelineService.isSpeakerAnySection()) {
          if ((!isParentContainer && followMeEnabled) ||
            (isParentContainer && (followMeEnabled ||
              (selSection.followMeSettings && selSection.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME)))) {
            const obj: IFollowMeMeetingViewMode = getViewModeObject(list);
            if (obj) {
              this.store.dispatch(new ui.SetMeetingViewMode(obj.viewMode));
              this.dailyCoService.meetingViewModeOption$.next(obj.viewModeOption);
            }
          }
        } else if (!isEmpty(list) && !this.isPresenter &&
          this.timelineService.isSpeakerAnySection() && this.followMeService.followMePresenter &&
          this.timelineService.currentUser.userId === this.followMeService.followMePresenter.userId &&
          this.timelineService.eventInstantSettings.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME) {
          const obj: IFollowMeMeetingViewMode = getViewModeObject(list);
          if (obj) {
            this.store.dispatch(new ui.SetMeetingViewMode(obj.viewMode));
            this.dailyCoService.meetingViewModeOption$.next(obj.viewModeOption);
          }
        } else if (this.isPresenter || this.timelineService.isSpeakerAnySection()) {
          if (!isParentContainer && this.followMeService.followMePresenter &&
            this.timelineService.currentUser.userId !== this.followMeService.followMePresenter.userId &&
            followMeEnabled &&
            (this.timelineService.followMeEventPGSParticipant.getValue() || this.timelineService.followMeEventAsPresenter.getValue())) {
            const obj: IFollowMeMeetingViewMode = getViewModeObject(list);
            if (obj) {
              this.store.dispatch(new ui.SetMeetingViewMode(obj.viewMode));
              this.dailyCoService.meetingViewModeOption$.next(obj.viewModeOption);
            }
          } else
          if (isParentContainer &&
            ((this.followMeService.followMePresenter || !this.followMeService.followMeSpeaker) ||
              (!this.followMeService.followMePresenter || this.followMeService.followMeSpeaker)) &&
            ((!this.followMeService.followMePresenter ||
                this.timelineService.currentUser.userId !== this.followMeService.followMePresenter.userId) ||
              (!this.followMeService.followMeSpeaker ||
                this.timelineService.currentUser.userId !== this.followMeService.followMeSpeaker.userId)) &&
            (followMeEnabled ||
              (selSection.followMeSettings && selSection.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME)) &&
            (this.timelineService.followMeEventPGSParticipant.getValue() || this.timelineService.followMeEventAsPresenter.getValue() ||
             this.timelineService.followMeSectionPGSParticipant.getValue() || this.timelineService.followMeSectionAsPresenter.getValue())) {
            const obj: IFollowMeMeetingViewMode = getViewModeObject(list);
            if (obj) {
              this.store.dispatch(new ui.SetMeetingViewMode(obj.viewMode));
              this.dailyCoService.meetingViewModeOption$.next(obj.viewModeOption);
            }
          }
        }
      });
  }

  get changeCurrentMeeting(): BehaviorSubject<IMeetingParams> {
    return this._changeCurrentMeeting;
  }

  get meetingDialogOpened(): BehaviorSubject<boolean> {
    return this._meetingDialogOpened;
  }

  get closeMeetingFrame(): Subject<boolean> {
    return this._closeMeetingFrame;
  }

  get stopInstantGroupMeetings(): Subject<boolean> {
    return this._stopInstantGroupMeetings;
  }

  public resetServiceValues() {
    this._changeCurrentMeeting = new BehaviorSubject<IMeetingParams>(null);
    this._meetingDialogOpened = new BehaviorSubject<boolean>(false);
  }

  get currentMeetingId() {
    return this._currentMeetingId;
  }

  set currentMeetingId(value) {
    this._currentMeetingId = value;
    this.meetingCloseManually = false;
  }

  async leaveMeetingIfSectionIsNoConference() {
    const featureLine = this.featureLine$.getValue();
    this.isCurrentSectionNoConference = this.timelineService?.sections
      .find((section) => section.id === featureLine &&
        section.specificVideoConferences)?.virtualConferenceSettings?.mainEventOption === VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE;
    if (this.isCurrentSectionNoConference) {
      await this.leaveMeeting();
    }
  }

  /**
   * If the previous section was a conference and is not the root, leave the meeting.
   * @param previousSectionId The ID of the current section.
   */
  async leaveMeetingIfPreviousSectionWasConferenceAndIsNotRoot(previousSectionId: string) {
    const section = this.timelineService?.sections.find((section) => section.id === previousSectionId);
    if (section?.specificVideoConferences && section?.virtualConferenceSettings?.mainEventOption !== VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE && (section && !section.isRoot)) {
      await this.leaveMeeting();
    }
  }


  initFutureLineSubscription() {
    if (this.subscribeFutureLine) {
      this.subscribeFutureLine.unsubscribe();
      this.subscribeFutureLine = null;
    }
    this.subscribeFutureLine = this.timelineService.featureLineContentId$
      .pipe(skip(0))
      .subscribe(async sectionId => {
        await this.leaveMeetingIfPreviousSectionWasConferenceAndIsNotRoot(this.featureLine$.getValue());
        this.featureLine$.next(sectionId);
        await this.leaveMeetingIfSectionIsNoConference();
        this.sectionMeeting = null;
        let section = this.timelineService.getSection(sectionId);
        const instantGroup = !!(this.isSectionContainer(section) ?? null);
        const userJoinedSection = instantGroup ? this.timelineService.conferenceUserJoinedSections[section.id] : null;
        const joinedInstantGroup = !!(this.isSectionContainer(this.joinedSection ?? userJoinedSection) ?? null);
        if (instantGroup !== joinedInstantGroup) {
          this.leaveMeeting();
        }
        if (instantGroup) {
          section = this.timelineService.userRoom(section, this.conferenceUser) as SectionTimeline;
          const igSection = this.getSectionContainer(section);
          if (section && ((this.meetingState === MEETING_STATE.PLAY &&
               (section.specificVideoConferences || igSection.specificVideoConferences)) ||
            ((!this.joinedSection || (this.joinedSection && section.id !== this.joinedSection.id)) &&
              ((section.specificVideoConferences && section.autostartVideoConferencesForParticipantsOnCurrentTime) ||
                !section.specificVideoConferences && igSection.specificVideoConferences &&
                  igSection.autostartVideoConferencesForParticipantsOnCurrentTime)))) {
            this.joinMeeting(section, false);
          } else if (!igSection) {
            this.leaveMeeting();
          }
        } else {
          this.isCurrentSectionNoConference = section?.specificVideoConferences &&
            section?.virtualConferenceSettings?.mainEventOption === VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE;
          if (this.isCurrentSectionNoConference) {
            this.leaveMeeting();
          }

          const jSection = this.getSectionWithConferenceByHierarchy(section);
          if (section && ((this.meetingState === MEETING_STATE.PLAY && !jSection.specificVideoConferences) ||
            ((!this.joinedSection || (this.joinedSection && jSection.id !== this.joinedSection.id)) &&
              jSection.specificVideoConferences &&
              (jSection.autostartVideoConferencesForParticipantsOnCurrentTime &&
                jSection.virtualConferenceSettings.mainEventOption !== VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE)))
          ) {
            this.joinMeeting(section, false);
          } else if (jSection && !jSection.specificVideoConferences &&
            this.joinedSection && this.joinedSection.specificVideoConferences) {
            this.leaveMeeting();
          } else if (this.joinedSection?.isRoot && jSection?.specificVideoConferences) {
            this.leaveMeeting();
          }
        }
      });
  }

  isSectionContainer(currentSection?: SectionContent) {
    return currentSection && (currentSection.container ||
      (this.timelineService.planeListContent[currentSection.parentId] &&
        this.timelineService.planeListContent[currentSection.parentId].sectionContent.container));
  }

  getSectionContainer(currentSection?: SectionContent) {
    return currentSection &&
      this.timelineService.planeListContent[currentSection.parentId] &&
        this.timelineService.planeListContent[currentSection.parentId].sectionContent.container ?
      this.timelineService.planeListContent[currentSection.parentId].sectionContent : null;
  }

  getMeetingType() {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.virtualConferenceSettings.typeOfConference;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.virtualConferenceSettings.typeOfConference;
    }
    return this.timelineService.instantSettings?.virtualConferenceSettings?.typeOfConference;
  }

  getJoinedSectionZoomMeeting(): IMeeting {
    if (this.joinedSection && !this.joinedSection.isRoot && this.joinedSection.specificVideoConferences) {
      return this.joinedSection.meeting;
    }
    if (this.joinedSection && !this.joinedSection.isRoot && this.timelineService.planeListContent[this.joinedSection.parentId] &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.container &&
      this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.specificVideoConferences) {
      return this.timelineService.planeListContent[this.joinedSection.parentId].sectionContent.meeting;
    }
    return null;
  }

  getSectionWithConferenceByHierarchy(section: SectionContent | string) {
    if (typeof section === 'string') {
      section = this.timelineService.getSection(section);
    }
    if (!section) {
      return this.timelineService.rootSection;
    }
    if (section.specificVideoConferences) {
      return section;
    }
    const hSectionList = this.timelineService.getSectionParentsByHierarchy(section)
      .map(id => this.timelineService.getSectionContent(id)).filter(o => !isEmpty(o));
    if (hSectionList.some(s => s.container)) {
      return section;
    }
    if (hSectionList.some(s => s.specificVideoConferences)) {
      return hSectionList.find(s => s.specificVideoConferences);
    }
    return this.timelineService.rootSection;
  }

  async joinMeeting(section: SectionTimeline | SectionContent, preview?: boolean) {
    if (section && section.container && !preview) {
      return;
    }

    if (section?.specificVideoConferences && section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.NO_CONFERENCE) {
      return;
    }

    if (this.joinedSection?.isRoot && !section?.specificVideoConferences) {
      return;
    }

    if (section && !section.isRoot && !section?.specificVideoConferences) {
      return;
    }

    if (this.joinTimer && !this.joinTimer.closed) {
      this.joinTimer.unsubscribe();
    }
    const rjs = this.getSectionWithConferenceByHierarchy(section);
    const videoConferenceOn = this.joinedSection?.isRoot ?
      this.timelineService.instantSettings?.canShowVirtualConferences : this.joinedSection?.specificVideoConferences;
    if (this.joinedSection && rjs && this.joinedSection.id !== rjs.id && videoConferenceOn) {
      await this.leaveMeeting();
    }
    this.joinedSectionChanged = this.joinedSection !== rjs;
    this.joinedSection = rjs;
    if (rjs && !rjs.isRoot && this.isVirtualConferenceExternalLiveStreaming()) {
      this.store.dispatch(new ui.SetMeetingViewMode(MEETING_VIEW_MODE.HIDDEN));
      this.meetingDialogOpened.next(false);
    } else {
      if (!this.meetingDialogOpened.getValue() && this.getMeetingType() !== VIRTUAL_CONFERENCE_TYPE.DAILY_CO) {
        this.store.dispatch(new ui.SetMeetingViewMode(this.getDefaultSize()));
      }
    }
    if (this.isPresenter && preview) {
      this.meetingState = MEETING_STATE.PLAY;
    }
    if (this.isVirtualConferenceIntegratedLiveConference()) {
      if (this.getMeetingType() === VIRTUAL_CONFERENCE_TYPE.DAILY_CO) {
        if (this.joinedSection) {
          this.sectionMeeting = {
            meetingId: this.joinedSection.id,
            state: null,
            url: this.joinedSection.id
          };
        } else {
          this.sectionMeeting = null;
        }
        this.joinDailyCoMeeting();
      }
    } else if (!this.canJoinMainVirtualConference()) {
      return;
    } else if (this.isVirtualConferenceIntegratedLiveStreaming()) {
      const isAllowedVideoType = [VIRTUAL_CONFERENCE_EVENT.RECORDED_VIDEO_STREAMING, VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING]
        .includes(this.timelineService.instantSettings.virtualConferenceSettings.mainEventOption);
      if (this.joinedSection?.isRoot && isAllowedVideoType &&
        !this.timelineService.instantSettings.virtualConferenceSettings?.linkToLiveStreaming) {
        return;
      }
      this.currentMeetingId = this.eventMeeting ? this.eventMeeting.meetingId : null;
      this.showEventMeetingDialog(this.joinedSection);
    } else if (this.isVirtualConferenceExternalLiveStreaming() || this.isVirtualConferenceRecordedVideoStreaming()) {
      this.currentMeetingId = this.eventMeeting ? this.eventMeeting.meetingId : null;
      if (rjs && !rjs.isRoot && this.isVirtualConferenceExternalLiveStreaming()) {
        this.changeCurrentMeeting.next({provider: VIRTUAL_CONFERENCE_TYPE.EXTERNAL_LIVE_STREAMING});
        if (!this.meetingDialogOpened.getValue()) {
          this.meetingDialogOpened.next(true);
        }
        return;
      }
      this.store.dispatch(new ui.SetMeetingViewMode(this.getDefaultSize()));
      this.showEventMeetingDialog(this.joinedSection);
    }
  }

  canJoinMainVirtualConference() {
    return [
        VIRTUAL_CONFERENCE_EVENT.RECORDED_VIDEO_STREAMING,
        VIRTUAL_CONFERENCE_TYPE.EXTERNAL_LIVE_STREAMING,
        VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING]
        .includes(this.timelineService.instantSettings.virtualConferenceSettings?.mainEventOption)
      && this.timelineService.instantSettings.virtualConferenceSettings?.linkToLiveStreaming;
  }

  async leaveMeeting() {
    const meetingIsAlive = this.meetingDialogOpened.getValue();
    this.meetingDialogOpened.next(false);
    this.currentMeetingId = null;
    this.joinedSection = null;
    this.sectionMeeting = null;
    if (meetingIsAlive) {
      const qParams = Object.assign({}, this.activatedRoute.snapshot.queryParams);
      delete qParams.cid;
      const sid = qParams.sid;
      this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: qParams});
    }
    if (this.dailyCoService.callFrame && this.dailyCoService.ownerStreamSectionId) {
      return await firstValueFrom(combineLatest(
        [this.dailyCoService.destroyWatcher$.pipe(filter(v => !v)),
          this.dailyCoService.ownerStreamSectionChange$.pipe(filter(v => !v))]))
        .then(([d, s]) => !d && !s);
    } else {
      return Promise.resolve(true);
    }
  }

  startStopEventMeeting(manual?: boolean, preview?: boolean, previewState?: MEETING_STATE) {
    if (!manual && isEmpty(this.eventMeeting)) {
      return;
    }
    let state;
    if (preview) {
      state = previewState;
    } else if (manual) {
      state = this.meetingState == null || this.meetingState === MEETING_STATE.STOP ? MEETING_STATE.PLAY : MEETING_STATE.STOP;
    } else {
      state = this.meetingState;
    }

    this.timelineService.userInitiatorEventMeeting = state === MEETING_STATE.PLAY ? this.timelineService.currentUser : null;

    if (this.isVirtualConferenceIntegratedLiveStreaming() ||
        this.isVirtualConferenceExternalLiveStreaming() ||
        this.isVirtualConferenceRecordedVideoStreaming()) {
      if (state === MEETING_STATE.PLAY) {
        if (this.timelineService.instantSettings.virtualConferenceSettings.linkToLiveStreaming &&
          this.timelineService.instantSettings.virtualConferenceSettings.embedVariables) {
          this.common.showProgress.next(true);
          this.eventMeeting = {meetingId: this.timelineService.rootSection.id, topic: null, state: MEETING_STATE.PLAY, url: null};
          if (!preview) {
            return this.dataService.updateSectionContent(this.timelineService.event.eventId, this.timelineService.rootSection.id,
              {meeting: {meetingId: this.timelineService.rootSection.id, topic: null, url: null}})
              .then(() =>
                this.dataService.setCustomActionLongValue(this.timelineService.event.eventId, 'meetingState', MEETING_STATE.PLAY))
              .catch((err) => {
                this.common.log.error(err);
                this.closeMeetingFrame.next(true);
                this.common.showProgress.next(false);
                this.dataService.setCustomActionLongValue(this.timelineService.event.eventId, 'meetingState', MEETING_STATE.STOP);
              });
          } else {
            this.joinMeeting(this.timelineService.rootSection as SectionTimeline, preview);
            return Promise.resolve();
          }
        }
      }
      if (state === MEETING_STATE.STOP) {
        if (!preview) {
          return this.dataService.setCustomActionLongValue(this.timelineService.event.eventId, 'meetingState', MEETING_STATE.STOP)
          .then(() => {
              this.joinedSection = null;
              this.closeMeetingFrame.next(true);
              this.store.dispatch(new ui.SetMeetingViewMode(MEETING_VIEW_MODE.INTERNAL));
            });
        } else {
          this.meetingState = MEETING_STATE.STOP;
          this.joinedSection = null;
          this.closeMeetingFrame.next(true);
          this.store.dispatch(new ui.SetMeetingViewMode(MEETING_VIEW_MODE.INTERNAL));
          return Promise.resolve();
        }
      }
    } else if (this.isVirtualConferenceIntegratedLiveConference()) {
      if (state === MEETING_STATE.PLAY) {
        return this.dataService.setCustomActionLongValue(this.timelineService.event.eventId, 'meetingState', MEETING_STATE.PLAY)
          .finally(() => this.common.showProgress.next(false));
      } else
      if (state === MEETING_STATE.STOP) {
        return this.dataService.setCustomActionLongValue(this.currentEvent.eventId, 'meetingState', MEETING_STATE.STOP)
          .then(async () => {
            if (this.dailyCoService.streamingRunning$.getValue()) {
              await this.dataService.saveStreamingStatus(this.dailyCoService.eventId, this.dailyCoService.ownerStreamSectionId, false);
            }
            this.joinedSection = null;
            this.closeMeetingFrame.next(true);
            this.store.dispatch(new ui.SetMeetingViewMode(MEETING_VIEW_MODE.INTERNAL));
            this.leaveMeeting();
            // remove meetings by rooms
            await this.dailyCoService.deleteAllBreakoutRooms(this.dailyCoService.ownerStreamSectionId);
            return Promise.resolve();
          });
      }
    }
  }

  showEventMeetingDialog(section?: SectionContent) {
    if (this.getSectionWithConferenceByHierarchy(section).isRoot && this.eventMeeting && this.meetingState !== MEETING_STATE.PLAY) {
      this.meetingDialogOpened.next(false);
      return;
    }
    if (this.isVirtualConferenceIntegratedLiveConference()) {
      const url = !isEmpty(this.getJoinedSectionZoomMeeting()) ? this.getJoinedSectionZoomMeeting().url : this.eventMeeting.url;
      let externalUrlSubscription = of(url);
      if (this.isPresenter && this.currentMeetingId && this.currentEvent.eventId) {
        externalUrlSubscription = this.meetingApiService.getPresenterUrl(this.currentEvent.eventId, this.currentMeetingId);
      }
      externalUrlSubscription.pipe(take(1)).subscribe(extUrl => {
        if (extUrl) {
          this.eventMeetingParams.externalUrl = extUrl;
          if (!this.eventMeetingParams.provider) {
            this.eventMeetingParams.provider = this.getMeetingType();
          }
          this.changeCurrentMeeting.next(this.eventMeetingParams);
          if (!this.meetingDialogOpened.getValue()) {
            this.meetingDialogOpened.next(true);
          }
        } else {
          if (this.isPresenter) {
            this.startStopEventMeeting();
            this.meetingDialogOpened.next(false);
          }
        }
      });
    }
    if (this.isVirtualConferenceIntegratedLiveStreaming()) {
      this.changeCurrentMeeting.next({provider: VIRTUAL_CONFERENCE_TYPE.INTEGRATED_LIVE_STREAMING});
      if (!this.meetingDialogOpened.getValue()) {
        this.meetingDialogOpened.next(true);
      }
      this.common.showProgress.next(false);
    }
    if (this.isVirtualConferenceExternalLiveStreaming()) {
      this.changeCurrentMeeting.next({provider: VIRTUAL_CONFERENCE_TYPE.EXTERNAL_LIVE_STREAMING});
      if (!this.meetingDialogOpened.getValue()) {
        this.meetingDialogOpened.next(true);
      }
      this.common.showProgress.next(false);
    }
    if (this.isVirtualConferenceRecordedVideoStreaming()) {
      this.changeCurrentMeeting.next({provider: VIRTUAL_CONFERENCE_TYPE.RECORDED_STREAMING});
      if (!this.meetingDialogOpened.getValue()) {
        this.meetingDialogOpened.next(true);
      }
      this.common.showProgress.next(false);
    }
  }

  showSectionMeetingDialog() {
    if (this.sectionMeeting?.url) {
      this.sectionMeetingParams.externalUrl = this.sectionMeeting.url;
      this.sectionMeetingParams.password = this.sectionMeeting.password;
      if (!this.sectionMeetingParams.provider) {
        this.sectionMeetingParams.provider = this.getMeetingType();
      }
      this.changeCurrentMeeting.next(this.sectionMeetingParams);
      this.meetingDialogOpened.next(true);
    }
  }

  joinDailyCoMeeting() {
    if (isEmpty(this.sectionMeeting) || (this.currentMeetingId === this.sectionMeeting.meetingId)) {
      return;
    }
    this.common.showProgress.next(true);
    this.currentMeetingId = this.sectionMeeting.meetingId;

    this.sectionMeetingParams.provider = VIRTUAL_CONFERENCE_TYPE.DAILY_CO;
    this.sectionMeetingParams.meetingInfoUrl = this.sectionMeeting.meetingId;
    this.showSectionMeetingDialog();
    this.common.showProgress.next(false);
  }

  openInternalMeetingWindow() {
    if (!this.isVirtualConferenceIntegratedLiveConference()) {
      this.showEventMeetingDialog();
    }
  }

  getCurrentMeeting() {
    if (this.eventMeeting && this.meetingState === MEETING_STATE.PLAY &&
      (!this.timelineService.featureLineContentId ||
        (this.timelineService.featureLineContentId &&
          (!this.timelineService.planeListContent[this.timelineService.featureLineContentId].sectionContent.container)))) {
      return this.eventMeeting.meetingId;
    } else if (this.sectionMeeting && this.timelineService.featureLineContentId &&
      this.timelineService.planeListContent[this.timelineService.featureLineContentId].sectionContent.container &&
      this.timelineService.userRoom(
        this.timelineService.planeListContent[this.timelineService.featureLineContentId].sectionContent, this.conferenceUser)) {
      return this.sectionMeeting.meetingId;
    } else if (this.sectionMeeting && (this.getMeetingType() === VIRTUAL_CONFERENCE_TYPE.DAILY_CO)) {
      return this.sectionMeeting.meetingId;
    } else {
      return null;
    }
  }

  isMeetingRunning() {
    return this.currentMeetingId && this.meetingState === MEETING_STATE.PLAY;
  }

  /** constants for meeting view mode which are full page */
  isMeetingViewModePage(mode: MEETING_VIEW_MODE) {
    return [MEETING_VIEW_MODE.BREAKOUT_ROOMS, MEETING_VIEW_MODE.CONFERENCE_GRID].includes(mode);
  }

  isMeetingViewModeFree(mode: MEETING_VIEW_MODE) {
    return [MEETING_VIEW_MODE.FREE, MEETING_VIEW_MODE.SMALL].includes(mode);
  }

  isMeetingViewMode(mode: MEETING_VIEW_MODE) {
    return [MEETING_VIEW_MODE.SMALL, MEETING_VIEW_MODE.FREE,
      MEETING_VIEW_MODE.CONFERENCE_GRID, MEETING_VIEW_MODE.BREAKOUT_ROOMS].includes(mode);
  }
}
