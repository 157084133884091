import {DEPENDENCY, UNION_BY} from '../../core/constants';
import {isEmpty} from 'lodash';

/**
 * defines sectionId when load contents list
 * @link EventsDataService.loadSectionContents
 * @link EventsDataService.loadDependencyQuestionnaireSectionsId
 */
export interface IDependencyQuestionnaire {sectionId?: string; contentId: string; questionnaireId: string; questionId: string; }
export interface IDependencyAnswers {[questionnaireId: string]: {[questionId: string]: string; }; }

export class DependencyQuestionnaire {
  dependencyType: DEPENDENCY;
  contentId: string;
  questionnaireId: string = null;
  questionId: string = null;
  answerIdList = [];
  answersUnionBy: UNION_BY = UNION_BY.AND;

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.questionnaireId) {
      this.questionnaireId = obj.questionnaireId;
    }
    if (obj.contentId) {
      this.contentId = obj.contentId;
    }
    if (obj.dependencyType) {
      this.dependencyType = obj.dependencyType;
    }
    if (obj.questionId) {
      this.questionId = obj.questionId;
    }
    if (obj.answerIdList) {
      this.answerIdList = obj.answerIdList;
    }
    if (obj.hasOwnProperty('answersUnionBy')) {
      this.answersUnionBy = obj.answersUnionBy;
    }
  }

  private get answersUnionByAnd() {
    return this.answersUnionBy === UNION_BY.AND;
  }

  private get answersUnionByOr() {
    return this.answersUnionBy === UNION_BY.OR;
  }

  // todo ask Pavel why array!?!?!?!?!
  // dependencyResult(userAnswers: string[]): boolean {
  dependencyResult(userAnswers: any): boolean {
    let result = false;
    if (isEmpty(userAnswers)) {
      result = false;
    } else
    if (this.answersUnionByAnd) {
      // all dependency includes in answers by "and" condition
      result = this.answerIdList.every(id => userAnswers.indexOf(id) > -1);
    } else
    if (this.answersUnionByOr) {
      result = userAnswers.some(id => this.answerIdList.indexOf(id) > -1);
    }
    return result;
  }

  get dependencyConditionShowIf() {
    return this.dependencyType === DEPENDENCY.SHOW_IF;
  }

  get dependencyConditionHideIf() {
    return this.dependencyType === DEPENDENCY.HIDE_IF;
  }

  toObject() {
    return {...this};
  }
}
