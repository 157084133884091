import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TimeLineService} from '../../services/time-line.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {CommonService} from '../../core/common.service';
import * as ui from '../../actions/ui';

interface IFiles {src: string; name: string; }

@Component({
  selector: 'app-text-view-dialog',
  templateUrl: './text-view-dialog.component.html',
  styleUrls: ['./text-view-dialog.component.scss']
})
export class TextViewDialogComponent implements OnInit, OnDestroy {

  text: string;
  files: IFiles[];

  constructor(public dialogRef: MatDialogRef<TextViewDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
    , public timeLineService: TimeLineService
    , public store: Store<fromRoot.State>
    , public common: CommonService) {
    this.text = data.text;
    this.files = data.files;
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
  }

  ngOnDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onOkClick() {
    this.dialogRef.close();
  }

}
