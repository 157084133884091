import {type} from './util';
import {Action} from '@ngrx/store';
import {Page} from '../model/Page';
import {MEETING_VIEW_MODE, PAGES} from '../core/constants';

export const ActionTypes = {
  SET_MENU_OPEN: type('[UI] Set Menu Open')
  , SET_CURRENT_PAGE: type('[UI] Set Current Page')
  , SET_LOADING: type('[UI] Set Loading')
  , SET_EXPAND_SECTION_TREE: type('[UI] Set Expand Section Tree')
  , SET_PRESENTATION_MODE: type('[UI] Set Presentation Mode')
  , SET_PRESENTATION_MODE_KEY_ACTION: type('[UI] Set Presentation Mode Key Action')
  , SET_MODAL_DIALOG_OPEN: type('[UI] Set Modal Dialog Open')
  , SET_EDITOR_MODE: type('[UI] Set Editor Mode')
  , SET_OPEN_MANAGE_TIME: type('[UI] Set Open Manage Time')
  , SET_EVENT_SETTINGS_OPEN_PAGE: type('[UI] Set Event Settings Open Page')
  , SET_USER_INPUT_ANSWER_ON_TEXT_QUESTION: type('[UI] Set User Input Answer On Text Question')
  , SET_MEETING_VIEW_MODE: type('[UI] Set Meeting View Mode')
  , SET_FULL_SCREEN_MODE: type('[UI] Set Full Screen Mode')
};

export class SetMenuOpenAction implements Action {
  type = ActionTypes.SET_MENU_OPEN;

  constructor(public payload: boolean) {
  }
}

export class SetCurrentPageAction implements Action {
  type = ActionTypes.SET_CURRENT_PAGE;

  constructor(public payload: Page) {
  }
}

export class SetLoadingAction implements Action {
  type = ActionTypes.SET_LOADING;

  constructor(public payload: boolean) {
  }
}

export class SetPresentationModeAction implements Action {
  type = ActionTypes.SET_PRESENTATION_MODE;

  constructor(public payload: boolean) {
  }
}

export class SetPresentationModeKeyAction implements Action {
  type = ActionTypes.SET_PRESENTATION_MODE_KEY_ACTION;

  constructor(public payload: string) {
  }
}

export class SetEventSettingsOpenPage implements Action {
  type = ActionTypes.SET_EVENT_SETTINGS_OPEN_PAGE;

  constructor(public payload: PAGES) {
  }
}

export class SetOpenManageTime implements Action {
  type = ActionTypes.SET_OPEN_MANAGE_TIME;

  constructor(public payload: boolean) {
  }
}

export class SetModalDialogOpen implements Action {
  type = ActionTypes.SET_MODAL_DIALOG_OPEN;

  constructor(public payload: boolean) {
  }
}

export class SetEditorMode implements Action {
  type = ActionTypes.SET_EDITOR_MODE;

  constructor(public payload: boolean) {
  }
}

export class SetUserInputAnswerOnTextQuestion implements Action {
  type = ActionTypes.SET_USER_INPUT_ANSWER_ON_TEXT_QUESTION;

  constructor(public payload: boolean) {
  }
}

export class SetMeetingViewMode implements Action {
  type = ActionTypes.SET_MEETING_VIEW_MODE;

  constructor(public payload: MEETING_VIEW_MODE) {
  }
}

export class SetFullScreenMode implements Action {
  type = ActionTypes.SET_FULL_SCREEN_MODE;

  constructor(public payload: boolean) {
  }
}


export type Actions
  = SetMenuOpenAction
  | SetCurrentPageAction
  | SetLoadingAction
  | SetPresentationModeAction
  | SetPresentationModeKeyAction
  | SetModalDialogOpen
  | SetOpenManageTime
  | SetEventSettingsOpenPage
  | SetUserInputAnswerOnTextQuestion
  | SetMeetingViewMode
  | SetFullScreenMode
  | SetEditorMode
  ;
