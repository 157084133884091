export class EventSectionType {
  public static readonly DB_PATH = 'section_type';

  id: string;
  icon: string;
  name: string;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.icon) {
      this.icon = obj.icon;
    }
    if (obj.name) {
      this.name = obj.name;
    }
  }

  toObject() {
    return {...this};
  }
}
