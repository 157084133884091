import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {APP_MODE, LoginService} from '../login/login.service';
import {StdApiService} from './std-api-service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoggerService} from '../core/logger.service';
import {UtilsService} from '../core/utils.service';
import {firstValueFrom, take} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StorageApiService extends StdApiService {
  protected API_URL: string;

  constructor(protected store: Store<fromRoot.State>,
              protected logger: LoggerService,
              protected auth: LoginService,
              protected utils: UtilsService,
              protected aFirestore: AngularFirestore,
              protected storage: AngularFireStorage,
              protected aff: AngularFireFunctions,
              private http: HttpClient
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/storageApi/v3/';
  }

  uploadImageByPath(path: string, base64: string) {
    const reference = this.aFirestore.firestore.app.storage().ref(`${this.clientStoragePath}/${path}`);
    base64 = base64
      .replace('data:image/png;base64,', '')
      .replace('data:image/jpeg;base64,', '');
    return reference.putString(base64, 'base64', {contentType: 'image/png'});
  }

  async uploadRecording(eventId: string, fileName: string, blob: Blob, contentType: string, appMode: APP_MODE) {
    let segment = null;
    switch (appMode) {
      case APP_MODE.TIMELINE:
        segment = 'conference';
        break;
      case APP_MODE.EDUCATION:
        segment = 'modules';
        break;
      default:
        return null;
    }
    const path = `${this.clientStoragePath}/${segment}/${eventId}/recordings/${fileName}`;
    const reference = this.aFirestore.firestore.app.storage().ref(path);
    await reference.put(blob, {contentType});
    return path.replace(`${this.clientStoragePath}`, ``);
  }

  uploadAnyImageToStorage(path: string, imageName: string, base64: string, contentType: string = 'image/png') {
    const reference = this.storage.storage.ref(`${this.clientStoragePath}/${path}/${imageName}`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: contentType});
  }

  uploadAnyImageToStorageClient(clientId: string, path: string, imageName: string, base64: string, contentType: string = 'image/png') {
    const clientPath = 'client_data/' + clientId;
    const reference = this.storage.storage.ref(`${clientPath}/${path}/${imageName}`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: contentType});
  }

  /**
   * upload image object to storage by path;
   * @param path - path to itemName;
   * @param imageName - name or other id
   * @param base64
   * @param contentType - base64 content type, default 'png'
   */
  uploadAnyObjectToStorageByDataPath(path: string, imageName: string, base64: string, contentType: string = 'image/png') {
    const reference = this.storage.storage.ref(`${this.clientStorageDataPath}/${path}/${imageName}`);
    const index = base64.indexOf(';base64,');
    base64 = base64.substr(index + ';base64,'.length);
    return reference.putString(base64, 'base64', {contentType: contentType});
  }

  deleteObjectFromStorage(path: string) {
    return this.aFirestore.firestore.app.storage().ref(path).delete()
      .catch((e) => {
        if (e.code === 'storage/object-not-found') {
          return Promise.resolve();
        } else {
          return Promise.reject(e);
        }
      });
  }

  deleteObjectFromClientStorage(path: string) {
    return this.aFirestore.firestore.app.storage().ref(`${this.clientStoragePath}/${path}`).delete()
      .catch((e) => {
        if (e.code === 'storage/object-not-found') {
          return Promise.resolve();
        } else {
          return Promise.reject(e);
        }
      });
  }

  /**
   * delete object from storage by path;
   * @param path - path to itemName;
   */
  deleteObjectFromStorageByDataPath(path: string) {
    return this.aFirestore.firestore.app.storage().ref(`${this.clientStorageDataPath}/${path}`).delete()
      .catch((e) => {
        if (e.code === 'storage/object-not-found') {
          return Promise.resolve();
        } else {
          return Promise.reject(e);
        }
      });
  }

  /**
   * recursively deletes all files and folders;
   */
  deleteContainerUsersFolderObjectsFromStorageByDataPath(eventId: string, contentId: string, containerId: string) {
    return firstValueFrom(this.http.post(this.API_URL + 'deleteContentContainerFolderObjectsFromStorage',
      {
        value: {
          eventId: eventId,
          contentId: contentId,
          containerId: containerId,
          contentDBPathType: this.dataPath,
          usersObjectsPath: 'users_answers'
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  /**
   * copy object inside storage between different paths
   * @param pathFrom
   * @param pathTo
   */
  copyStorageDataObject(pathFrom: string, pathTo: string) {
    const callable = this.aff.httpsCallable('copyStorageObject');
    const obj = {
      pathFrom: `${this.clientStoragePath}/${pathFrom}`,
      pathTo: `${this.clientStorageDataPath}/${pathTo}`
    };
    return callable(obj).pipe(take(1)).toPromise();
  }

  /**
   * copy object
   * @param pathFrom
   * @param pathTo
   */
  copyStorageObject(pathFrom: string, pathTo: string) {
    const callable = this.aff.httpsCallable('copyStorageObject');
    const obj = {
      pathFrom: `${this.clientStoragePath}/${pathFrom}`,
      pathTo: `${this.clientStoragePath}/${pathTo}`
    };
    return callable(obj).pipe(take(1)).toPromise();
  }

  /**
   * Return url to storage object
   * @param path = path to object
   * @returns url
   */
  getStorageDataURL(path: string): Promise<string> {
    const reference = this.angularFireStorage.storage.ref(`${this.clientStorageDataPath}/${path}`);
    return reference.getDownloadURL();
  }

  getClientStorageDataLocation(path: string) {
    return `${this.clientStorageDataPath}/${path}`;
  }

}
