import {AbstractContent} from './AbstractContent';

export class ClipboardContent extends AbstractContent {
  type = 'clipboard';
  imageUrl: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.imageUrl) {
      this.imageUrl = obj.imageUrl;
    }
  }

  mergeContent(src: ClipboardContent) {
    super.mergeContent(src);

    if (this.imageUrl !== src.imageUrl) {
      this.imageUrl = src.imageUrl;
    }
  }
}
