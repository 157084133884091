import { ElementRef } from "@angular/core";

export const START_VALUE_INDEX: number = 0;
export const END_VALUE_INDEX: number = 1;
export const CORRECT_VALUE_INDEX: number = 2;

export function getIconPositionInPixelForBarChart(element: ElementRef, min: number, max: number, value: number): number {
  let iconPosition = 0;
  if(element){
    const sliderNativeEl = element.nativeElement;
    const sliderWidth = sliderNativeEl.offsetWidth;

   const barSize = (sliderWidth / (max - min + 1));

   iconPosition =  (barSize * (value-min)) + (barSize / 2);
  }

  return iconPosition;
}
