import {AfterViewInit, Component, ElementRef, Injector, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {isEmpty} from 'lodash';
import {IUploadFileResultData} from '../upload-file-model/UploadFile';
import {getExtension} from '../../shared/lib/question-upload-files-utils';
import {BehaviorSubject} from 'rxjs';
import {StorageDataService} from '../../../../../../../services/storage-data.service';
import {ANONYMOUS_ANSWERS_MODE} from '../../../quiz-model/quiz';
import {IMAGE_LOADER, ImageLoaderConfig} from '@angular/common';

@Component({
  selector: 'app-question-upload-files-results',
  templateUrl: './question-upload-files-results.component.html',
  styleUrl: './question-upload-files-results.component.scss'
})
export class QuestionUploadFilesResultsComponent extends AbstractQuizQuestionResultsComponent implements AfterViewInit {
  getExtension = getExtension;
  totalFilesUploaded = 0;
  answers$ = new BehaviorSubject<any[]>([]);
  dataObject: IUploadFileResultData[] = [];
  intersectionObserver: IntersectionObserver;
  showUserInfo = true;
  readonly imageExtensions = this.Constants.FILE_TYPES[this.Constants.FT_IMAGE].extension.filter(ext => ext !== 'HEIC');
  @ViewChildren('contentWrapper', { read: ElementRef }) contentsWrappersElementsRef: QueryList<ElementRef>;
  @ViewChild('contentContainer') contentContainerElementsRef: ElementRef;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private storageDataService: StorageDataService) {
    super(injector, elementRef);
  }

  ngAfterViewInit(): void {
    const quiz = this.quiz$.getValue();
    const anonymousAnsMode = quiz.anonymousAnswersMode;
    this.showUserInfo = anonymousAnsMode === ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS;
    if (this.contentContainerElementsRef) {
      const mainWrapper = this.contentContainerElementsRef.nativeElement;
      if (mainWrapper) {
        this.intersectionObserver = new IntersectionObserver(this.intersectionHandle.bind(this), {root: mainWrapper, threshold: 0});
        if (this.contentsWrappersElementsRef.length > 0) {
          this.observeLazyElements();
        }
        this.contentsWrappersElementsRef.changes.pipe(this.takeUntilAlive())
          .subscribe(() => {
            this.observeLazyElements();
          });
      }
    }
  }

  private observeLazyElements(): void {
    for (const it of this.contentsWrappersElementsRef) {
      if ((it.nativeElement as HTMLElement).classList.contains('lazy')) {
        this.intersectionObserver.observe(it.nativeElement);
      }
    }
  }

  private intersectionHandle(entries: IntersectionObserverEntry[]) {
    for (const entry of entries.filter(it => it.isIntersecting && (it.target as HTMLElement).classList.contains('lazy'))) {
      const element = entry.target as HTMLElement;
      const params: any = element.dataset;
      const userId = params.userid;
      const fileId = params.fileid;
      this.getFileUrl(userId, fileId, (url) => {
        const idx = this.dataObject.findIndex(obj => obj.userId === userId && obj.file.id === fileId);
        if (idx !== -1) {
          this.dataObject[idx].imageUrl = url;
        }
        this.intersectionObserver.unobserve(element);
      });
    }
  }

  private buildData(object) {
    this.dataObject = [];
    this.totalFilesUploaded = 0;
    for (const list of !isEmpty(object) ? object : []) {
      for (const file of list.f) {
        const dataObj: IUploadFileResultData = {
          userId: list.u,
          picture: list.p,
          fullName: list.n,
          file: file,
          isImage: this.imageExtensions.includes(file.name.split('.').pop().toUpperCase()),
          imageUrl: null
        };
        this.dataObject.push(dataObj);
        this.totalFilesUploaded++;
      }
    }
  }

  downloadFile(userId: string, fileId: string) {
    this.getFileUrl(userId, fileId, (url) => {
      window.open(url, '_blank');
    });
  }

  private getFileUrl(userId: string, fileId: string, success) {
    const eventId = this.documentPathParams.eventId;
    const contentId = this.documentPathParams.contentId;
    const containerId = this.documentPathParams.containerId;
    const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${userId}/${fileId}`;

    this.storageDataService.getStorageDataURL(path)
      .then(url => {
        if (url) {
          success(url);
        }
      }).catch((err) => {
        this.common.log.error(err);
      });
  }

  protected onReceiveQuestionAnswers() {
    this.summaryQuestionAnswers$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (isEmpty(value) || !Array.isArray(value)) {
          this.buildData([]);
        } else if (Array.isArray(value)) {
          this.buildData(value);
        }
      });
  }

  onDestroy() {
    super.onDestroy();
    this.intersectionObserver?.disconnect();
  }
}
