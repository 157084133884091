import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {WelcomeScreen} from '../../model/content/WelcomeScreen';
import {InstantSettings} from '../../model/event-mode/InstantSettings';
import {EventsDataService} from '../../services/events-data.service';
import * as fromRoot from '../../reducers';
import {Store} from '@ngrx/store';
import * as ui from '../../actions/ui';
import {LoginService} from '../../login/login.service';
import {ContentService} from '../../services/content.service';
import {CommonService} from '../../core/common.service';
import {StdComponent} from '../../core/std-component';
import {TimeLineService} from '../../services/time-line.service';
import {ShiftEventParams} from '../create-event.service';
import {BANNER_TYPE, FOLLOW_ME_MODE} from '../../core/constants';
import {cloneDeep, isEmpty} from 'lodash';

@Component({
  selector: 'app-duplicate-event-dialog',
  templateUrl: './duplicate-event-dialog.component.html',
  styleUrls: ['./duplicate-event-dialog.component.scss']
})
export class DuplicateEventDialogComponent extends StdComponent implements OnInit, OnDestroy {
  private readonly IMG_TYPE_LOGO = 1;
  private readonly IMG_TYPE_H_BANNER = 2;
  private readonly IMG_TYPE_V_BANNER = 3;
  private readonly IMG_TYPE_BACKGROUND = 4;
  private readonly IMG_TYPE_MOBILE_BACKGROUND = 5;
  public alive = true;
  DUPLICATE_PROC_START = 40;
  start = false;
  end = false;
  endWithError = false;
  progressPercent = 0;
  progressHint = '';
  result: any;
  showProgress = false;
  errorTitle;

  createTime = new Date().getTime();

  newEventId;
  // map of url. {key = logo|welcom_s|welcom_sm|clipboard|questionnaire: value = url|url|url|{id: url}| id: {qId: url}}
  urlMap = {
    logo: null,
    hBanner: null,
    vBanner: null,
    background: null,
    mobileBackground: null,
    clipboard: {},
    questionnaire: {}
  };

  event; // It's not Event from model, this is Event object with more fields for save on server side.
  srcEvent;
  instantSettings: InstantSettings;
  welcomeScreen: WelcomeScreen;
  sectionTypes;
  currentUserId;
  inviteUsers: string[];
  // event image
  needCopyEventLogo = false;
  needCopyEventHBanner = false;
  needCopyEventVBanner = false;
  needCopyEventBackground = false;
  needCopyEventMobileBackground = false;
  shiftEventParams: ShiftEventParams;
  silentMode = false;
  clearUsers = false;
  clientId: string;


  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<DuplicateEventDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private common: CommonService,
              private eventsDataService: EventsDataService,
              private timelineService: TimeLineService,
              private store: Store<fromRoot.State>,
              private contentService: ContentService,
              private loginService: LoginService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.clientId = data.clientId;
    this.event = cloneDeep(data.event);
    this.srcEvent = data.srcEvent;
    if (data.instantSettings.followMeSettings) {
      data.instantSettings.followMeSettings.followMeMode = FOLLOW_ME_MODE.DONT_FOLLOW;
    }
    this.instantSettings = data.instantSettings;
    this.welcomeScreen = data.welcomeScreen;
    this.sectionTypes = data.sectionTypes;
    this.currentUserId = data.currentUserId;
    this.inviteUsers = data.inviteUsers ? data.inviteUsers : [];
    this.silentMode = !!data.silentMode;
    this.clearUsers = !!data.clearUsers;
    if (this.event.logo) {
      this.needCopyEventLogo = this.common.utils.isFBUrl(this.event.logo, this.srcEvent.eventId);
    }
    if (this.event.verticalBanner) {
      this.needCopyEventVBanner = this.common.utils.isFBUrl(this.event.verticalBanner, this.srcEvent.eventId);
    }
    if (this.event.horizontalBanner) {
      this.needCopyEventHBanner = this.common.utils.isFBUrl(this.event.horizontalBanner, this.srcEvent.eventId);
    }
    if (this.event.background) {
      this.needCopyEventBackground = this.common.utils.isFBUrl(this.event.background, this.srcEvent.eventId);
    }
    if (this.event.mobileBackground) {
      this.needCopyEventMobileBackground = this.common.utils.isFBUrl(this.event.mobileBackground, this.srcEvent.eventId);
    }
    this.shiftEventParams = data.shiftEventParams;
  }

  ngOnInit() {
    if (this.silentMode) {
      this.startServerDuplicateEvent();
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  goToNewDuplicateEvent() {
    this.store.dispatch(new ui.SetMenuOpenAction(false));
    this.loginService.redirectTo('/event/' + this.event.shortLink);
    this.dialogRef.close();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  setProgress(percent, hint, info?) {
    this.progressPercent = Math.floor(percent);
    this.progressHint = this.common.utils.i18n(hint) + (!info ? '' : ' ' + info) ;
  }

  createEventHead() {
    return new Promise<any>((resolve, reject) => {
      this.setProgress(0, 'duplicate.hint.event');
      const eventLogo = this.event.logo;
      const vEventBanner = this.event.verticalBanner;
      const hEventBanner = this.event.horizontalBanner;
      this.event.logo = null;
      this.event.verticalBanner = null;
      this.event.horizontalBanner = null;
      this.event.meeting = null;
      this.event.hkvbsIntegrationId = null;
      this.event.readonlyHKVBS = null;
      this.event.guest = false;
      this.eventsDataService.createEvent(this.event, this.event['addPMails'], this.clientId).then(saveResult => {
        this.setProgress(5, 'duplicate.hint.interaction.settings');
        this.eventsDataService
          .updateSectionTypes(saveResult.eventId, this.sectionTypes, null, this.clientId)
          .then(() => {
            this.eventsDataService.updateEventInstantSettings(saveResult.eventId, this.instantSettings, this.clientId).then( () => {
              if (eventLogo || this.welcomeScreen || vEventBanner || hEventBanner) {
                this.setProgress(10, 'duplicate.hint.event.logo');
                this.eventsDataService.addEventLogo(saveResult.eventId, eventLogo, this.clientId).then(logoUrl => {
                  saveResult.logo = logoUrl.imageUrl;
                  this.eventsDataService.addEventBanner(saveResult.eventId, vEventBanner, BANNER_TYPE.VERTICAL, this.clientId)
                    .then(vUrl => {
                    saveResult.verticalBanner = vUrl.imageUrl;
                    this.eventsDataService.addEventBanner(saveResult.eventId, hEventBanner, BANNER_TYPE.HORIZONTAL, this.clientId)
                      .then(hUrl => {
                      saveResult.horizontalBanner = hUrl.imageUrl;
                      if (this.welcomeScreen) {
                        this.setProgress(15, 'duplicate.hint.event.welcome.screen');
                        this.eventsDataService.editWelcomeScreen(saveResult.eventId, new WelcomeScreen(this.welcomeScreen), this.clientId)
                          .then(() => {
                          resolve(saveResult.eventId);
                        }).catch(error => {
                          this.throwError(error);
                        });
                      } else {
                        resolve(saveResult.eventId);
                      }
                    });
                  });
                });
              } else {
                resolve(saveResult.eventId);
              }
            }).catch(error => {
              this.throwError(error);
            });
          }).catch(error => {
          this.throwError(error);
        });
      }).catch(error => {
        this.throwError(error);
      });
    });
  }

  imageCopy(eventIdFrom, eventIdTo, pathFrom, pathTo, percent, hint, info?, clientIdTo?: string) {
    return new Promise<any>((resolve, reject) => {
      this.eventsDataService.imageRewriteTo(eventIdFrom, eventIdTo, pathFrom, pathTo, clientIdTo).then(r => {
        this.setProgress(percent, hint, info);
        resolve(r);
      }).catch(error => {
        this.throwError(error);
      });
    });
  }

  getImageUrl(newEventId, newTimeLineId, questionId, percent, hint, imgType: number, urlMapContentObj?, info?) {
    return new Promise<any>((resolve, reject) => {
      if (imgType === this.IMG_TYPE_LOGO) {
        this.eventsDataService.getDownloadUrl(newEventId, `logo/${newEventId}.png`, this.clientId).then(url => {
          this.setProgress(percent, hint);
          this.urlMap.logo = url ? url : null;
          resolve(url);
        }).catch(error => {
          this.common.log.debug('Error getEventDownloadURL: ' + JSON.stringify(error));
          this.urlMap.logo = null;
          resolve(null);
        });
      } else
      if (imgType === this.IMG_TYPE_H_BANNER) {
        this.eventsDataService.getDownloadUrl(newEventId, `horizontalBanner/horizontalBanner.png`, this.clientId).then(url => {
          this.setProgress(percent, hint);
          this.urlMap.hBanner = url ? url : null;
          resolve(url);
        }).catch(error => {
          this.common.log.debug('Error getBannerDownloadURL: ' + JSON.stringify(error));
          this.urlMap.hBanner = null;
          resolve(null);
        });
      } else
      if (imgType === this.IMG_TYPE_V_BANNER) {
        this.eventsDataService
          .getDownloadUrl(newEventId, `verticalBanner/verticalBanner.png`, this.clientId).then(url => {
          this.setProgress(percent, hint);
          this.urlMap.vBanner = url ? url : null;
          resolve(url);
        }).catch(error => {
          this.common.log.debug('Error getBannerDownloadURL: ' + JSON.stringify(error));
          this.urlMap.vBanner = null;
          resolve(null);
        });
      } else
      if (imgType === this.IMG_TYPE_BACKGROUND) {
        this.eventsDataService
          .getDownloadUrl(newEventId, `background/background.png`, this.clientId).then(url => {
          this.setProgress(percent, hint);
          this.urlMap.background = url ? url : null;
          resolve(url);
        }).catch(error => {
          this.common.log.debug('Error getBackgroundDownloadURL: ' + JSON.stringify(error));
          this.urlMap.background = null;
          resolve(null);
        });
      } else
      if (imgType === this.IMG_TYPE_MOBILE_BACKGROUND) {
        this.eventsDataService
          .getDownloadUrl(newEventId, `mobileBackground/mobileBackground.png`, this.clientId).then(url => {
          this.setProgress(percent, hint);
          this.urlMap.mobileBackground = url ? url : null;
          resolve(url);
        }).catch(error => {
          this.common.log.debug('Error getMobileBackgroundDownloadURL: ' + JSON.stringify(error));
          this.urlMap.mobileBackground = null;
          resolve(null);
        });
      }
    });
  }

  updateImageUrl(newEventId, urlMapContentObj, questionId, percent, hint, imgType: number, info?) {
    return new Promise<any>((resolve, reject) => {
      if (imgType === this.IMG_TYPE_LOGO) {
        this.eventsDataService.updateEventLogoUrl(newEventId, this.urlMap.logo, this.clientId).then(r => {
          this.setProgress(percent, hint);
          resolve(r);
        }).catch(error => {
          this.throwError(error);
        });
      } else
      if (imgType === this.IMG_TYPE_H_BANNER) {
        this.eventsDataService.updateEventHorizontalBanner(newEventId, this.urlMap.hBanner, this.clientId).then(r => {
          this.setProgress(percent, hint);
          resolve(r);
        }).catch(error => {
          this.throwError(error);
        });
      } else
      if (imgType === this.IMG_TYPE_V_BANNER) {
        this.eventsDataService.updateEventVerticalBanner(newEventId, this.urlMap.vBanner, this.clientId).then(r => {
          this.setProgress(percent, hint);
          resolve(r);
        }).catch(error => {
          this.throwError(error);
        });
      } else
      if (imgType === this.IMG_TYPE_BACKGROUND) {
        this.eventsDataService.updateEventBackground(newEventId, this.urlMap.background, this.clientId).then(r => {
          this.setProgress(percent, hint);
          resolve(r);
        }).catch(error => {
          this.throwError(error);
        });
      } else
      if (imgType === this.IMG_TYPE_MOBILE_BACKGROUND) {
        this.eventsDataService.updateEventMobileBackground(newEventId, this.urlMap.mobileBackground, this.clientId).then(r => {
          this.setProgress(percent, hint);
          resolve(r);
        }).catch(error => {
          this.throwError(error);
        });
      }
    });
  }

  copyEventImages() {
    return new Promise<any>((resolve, reject) => {
      const allImagesPromise = [];
      this.setProgress(20, 'duplicate.hint.event.copy.event.images');
      if (this.needCopyEventLogo) {
        allImagesPromise.push(this.imageCopy(this.srcEvent.eventId, this.newEventId,
          'logo/' + this.srcEvent.eventId + '.png', 'logo/' + this.newEventId + '.png',
          20, 'duplicate.hint.event.copy.event.logo', null, this.clientId));
      }
      if (this.needCopyEventHBanner) {
        allImagesPromise.push(this.imageCopy(this.srcEvent.eventId, this.newEventId,
          'horizontalBanner/horizontalBanner.png', 'horizontalBanner/horizontalBanner.png',
          20, 'duplicate.hint.event.copy.event.banner', null, this.clientId));
      }
      if (this.needCopyEventVBanner) {
        allImagesPromise.push(this.imageCopy(this.srcEvent.eventId, this.newEventId,
          'verticalBanner/verticalBanner.png', 'verticalBanner/verticalBanner.png',
          20, 'duplicate.hint.event.copy.event.banner', null, this.clientId));
      }
      if (this.needCopyEventBackground) {
        allImagesPromise.push(this.imageCopy(this.srcEvent.eventId, this.newEventId,
          'background/background.png', 'background/background.png',
          20, 'duplicate.hint.event.copy.event.background', null, this.clientId));
      }
      if (this.needCopyEventMobileBackground) {
        allImagesPromise.push(this.imageCopy(this.srcEvent.eventId, this.newEventId,
          'mobileBackground/mobileBackground.png', 'mobileBackground/mobileBackground.png',
          20, 'duplicate.hint.event.copy.event.mobile.background', null, this.clientId));
      }
      if (allImagesPromise.length > 0) {
        Promise.all(allImagesPromise).then(result => {
          resolve(result);
        }).catch(error => {
          this.throwError(error);
        });
      } else {
        resolve(true);
      }
    });
  }

  getNewImagesUrl() {
    return new Promise<any>((resolve, reject) => {
      const allImagesPromise = [];
      this.setProgress(25, 'duplicate.hint.event.copy.get.images.url');
      if (this.needCopyEventLogo) {
        allImagesPromise.push(this.getImageUrl(this.newEventId, null, null,
          25, 'duplicate.hint.event.copy.get.images.url.logo', this.IMG_TYPE_LOGO));
      }
      if (this.needCopyEventHBanner) {
        allImagesPromise.push(this.getImageUrl(this.newEventId, null, null,
          25, 'duplicate.hint.event.copy.get.images.url.banner', this.IMG_TYPE_H_BANNER));
      }
      if (this.needCopyEventVBanner) {
        allImagesPromise.push(this.getImageUrl(this.newEventId, null, null,
          25, 'duplicate.hint.event.copy.get.images.url.banner', this.IMG_TYPE_V_BANNER));
      }
      if (this.needCopyEventBackground) {
        allImagesPromise.push(this.getImageUrl(this.newEventId, null, null,
          25, 'duplicate.hint.event.copy.get.images.url.background', this.IMG_TYPE_BACKGROUND));
      }
      if (this.needCopyEventMobileBackground) {
        allImagesPromise.push(this.getImageUrl(this.newEventId, null, null,
          25, 'duplicate.hint.event.copy.get.images.url.mobile.background', this.IMG_TYPE_MOBILE_BACKGROUND));
      }
      if (allImagesPromise.length > 0) {
        Promise.all(allImagesPromise).then(result => {
          resolve(result);
        }).catch(error => {
          this.throwError(error);
        });
      } else {
        resolve(true);
      }
    });
  }

  updateNewImageUrl() {
    return new Promise<any>((resolve, reject) => {
      const allImagesPromise = [];
      this.setProgress(30, 'duplicate.hint.event.copy.update.images.url');
      if (this.needCopyEventLogo && this.srcEvent.logo !== this.urlMap.logo) {
        allImagesPromise.push(this.updateImageUrl(this.newEventId, null, null,
          30, 'duplicate.hint.event.copy.update.images.url.logo', this.IMG_TYPE_LOGO));
      }
      if (this.needCopyEventHBanner && this.srcEvent.horizontalBanner !== this.urlMap.hBanner) {
        allImagesPromise.push(this.updateImageUrl(this.newEventId, null, null,
          30, 'duplicate.hint.event.copy.update.banner', this.IMG_TYPE_H_BANNER));
      }
      if (this.needCopyEventVBanner && this.srcEvent.verticalBanner !== this.urlMap.vBanner) {
        allImagesPromise.push(this.updateImageUrl(this.newEventId, null, null,
          30, 'duplicate.hint.event.copy.update.banner', this.IMG_TYPE_V_BANNER));
      }
      if (this.needCopyEventBackground && this.srcEvent.background !== this.urlMap.background) {
        allImagesPromise.push(this.updateImageUrl(this.newEventId, null, null,
          30, 'duplicate.hint.event.copy.update.background', this.IMG_TYPE_BACKGROUND));
      }
      if (this.needCopyEventMobileBackground && this.srcEvent.mobileBackground !== this.urlMap.mobileBackground) {
        allImagesPromise.push(this.updateImageUrl(this.newEventId, null, null,
          30, 'duplicate.hint.event.copy.update.mobile.background', this.IMG_TYPE_MOBILE_BACKGROUND));
      }
      if (allImagesPromise.length > 0) {
        Promise.all(allImagesPromise).then(result => {
          resolve(result);
        }).catch(error => {
          this.throwError(error);
        });
      } else {
        resolve(true);
      }
    });
  }

  throwError(error) {
    this.end = true;
    this.endWithError = true;
    this.showProgress = false;
    this.errorTitle = error;
    throw error;
  }

  startServerDuplicateEvent() {
    this.start = true;
    this.showProgress = true;
    let procCount = this.DUPLICATE_PROC_START;
    this.start = true;
    this.showProgress = true;
    let shift = 0;
    if (this.shiftEventParams && this.shiftEventParams.useShift) {
      this.timelineService.shiftEventDatesBeforeSave(this.event, this.shiftEventParams);
      shift = new Date(this.shiftEventParams.newStartDate).getTime() - new Date(this.shiftEventParams.oldStartDate).getTime();
    }
    this.createEventHead().then(newEventId => {
      this.newEventId = newEventId;
      this.copyEventImages().then(() => {
        this.getNewImagesUrl().then(() => {
          this.updateNewImageUrl().then(() => {
            const TaskStatusSubscriptions = this.eventsDataService
              .getDuplicateEventTaskStatus(this.srcEvent.eventId, this.newEventId, this.currentUserId)
              .pipe(this.takeUntilAlive()).subscribe(tasksStatus => {
                if (!isEmpty(tasksStatus)) {
                  const taskStatus = tasksStatus.reverse()[0];
                  if (taskStatus['status'] !== 'error' && taskStatus['procName']) {
                    this.setProgress(procCount, taskStatus['procName']);
                    procCount = procCount + 5;
                    if (taskStatus['status'] === 'end') {
                      this.setProgress(100, taskStatus['procName']);
                      TaskStatusSubscriptions.unsubscribe();
                      this.end = true;
                      this.showProgress = false;
                      if (this.silentMode) {
                        this.goToNewDuplicateEvent();
                      }
                    }
                  } else if (taskStatus['status'] === 'error') {
                    TaskStatusSubscriptions.unsubscribe();
                    this.throwError('method:' + taskStatus['procName'] + ': ' + taskStatus['info']);
                  }
                }
              });
            this.setProgress(35, 'duplicate.hint.event.create.content');
            this.eventsDataService.duplicateEvent(this.srcEvent.eventId, this.newEventId,
              shift, this.inviteUsers, this.clearUsers, this.clientId)
              .catch(err => {
                this.throwError(err);
              });
          }).catch(error => {
            this.throwError(error);
          });
        }).catch(error => {
          this.throwError(error);
        });
      }).catch(error => {
        this.throwError(error);
      });
    });
  }
}
