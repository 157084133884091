import {isEmpty} from 'lodash';
import {IStorageCollectionPath} from '../../../../../services/event-mode-api.service';

export const TASK_INSIDE_COLLECTIONS: IStorageCollectionPath[] = [{
  collectionName: 'users_answers',
  storageDataFiledName: 'files',
  pathSuffix: 'users_answers',
  pathDataFieldNameSuffix: 'userId'
}];

export enum USER_TASK_STATUS {
  NEW = 'user.task.status.new',
  IN_PROGRESS = 'user.task.status.in.progress', // set when press start button
  DONE = 'user.task.status.done', // set when press submit button and answers set is readonly
  FEEDBACK = 'user.task.status.feedback', // status can set only speaker
  APPROVED = 'user.task.status.approved', // status can set only speaker
}

export interface ITaskFile {
  id: string;
  src: string;
  name: string;
  metaType: string;
}

export interface ITaskSpeaker {
  userId: string;
  fullName: string;
  picture: string;
  email: string;
  department: string;
}

export interface ITaskResultUserRow {
  checkbox: boolean;
  userId: string;
  fullName: string;
  picture: string;
  email: string;
  userStatus: string;
  draft: boolean;
  textAnswer: string;
  filesAnswer: string;
  files: ITaskFile[];
  feedback_edit: string;
  feedback: string;
  points: string;
  points_edit: string;
  grade: string;
  grade_edit: string;
}

export interface IResultUserRow {
  checkbox: boolean;
  userId: string;
  fullName: string;
  picture: string;
  email: string;
  userStatus: string;
  draft: boolean;
  textAnswer: string;
  filesAnswer: string;
  files: ITaskFile[];
  feedback_edit: string;
  feedback: string;
  points: string;
  points_edit: string;
  grade: string;
  grade_edit: string;
}

export class TaskObject {
  title: string;
  description: string;
  directLink: string;
  files: ITaskFile[] = [];
  collectResultAsTextLink: boolean;
  collectResultAsFile: boolean;
  maximalPoints: number;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    if (obj.title) {
      this.title = obj.title;
    }
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.directLink) {
      this.directLink = obj.directLink;
    }
    if (obj.collectResultAsTextLink) {
      this.collectResultAsTextLink = obj.collectResultAsTextLink;
    }
    if (obj.collectResultAsFile) {
      this.collectResultAsFile = obj.collectResultAsFile;
    }
    if (obj.maximalPoints) {
      this.maximalPoints = obj.maximalPoints;
    }
    if (!isEmpty(obj.files)) {
      this.files = obj.files;
    }
  }

  getAliveFiles() {
    return this.files.filter(f => !f.id.startsWith('-'));
  }

  toObject() {
    return {...this};
  }
}

export class UserTaskObject {
  userId: string;
  email: string;
  fullName: string;
  picture: string;
  department: string;
  userStatus: USER_TASK_STATUS = USER_TASK_STATUS.NEW;
  textAnswer: string;
  files: ITaskFile[] = [];
  feedback: string;
  points: string;
  grade: string;
  speaker: ITaskSpeaker;
  constructor(obj: any) {
    if (!obj) {
      return;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
    if (obj.email) {
      this.email = obj.email;
    }
    if (obj.fullName) {
      this.fullName = obj.fullName;
    }
    if (obj.picture) {
      this.picture = obj.picture;
    }
    if (obj.department) {
      this.department = obj.department;
    }
    if (obj.userStatus) {
      this.userStatus = obj.userStatus;
    }
    if (obj.textAnswer) {
      this.textAnswer = obj.textAnswer;
    }
    if (!isEmpty(obj.files)) {
      this.files = obj.files;
    }
    if (obj.feedback) {
      this.feedback = obj.feedback;
    }
    if (obj.points) {
      this.points = obj.points;
    }
    if (obj.grade) {
      this.grade = obj.grade;
    }
    if (obj.speaker) {
      this.speaker = obj.speaker;
    }
  }

  getAliveFiles() {
    return this.files.filter(f => !f.id.startsWith('-'));
  }

  getFilesNameList() {
    if (isEmpty(this.files)) {
      return null;
    }
    let result = '';
    this.getAliveFiles().forEach(uf => result = result.length > 0 ? (result + ',' + uf.name) : uf.name);
    return result;
  }

  toObject() {
    return {...this};
  }
}
