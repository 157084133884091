import {Component, HostListener, Injector, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {StdComponent} from '../../core/std-component';
import {Constants} from '../../core/constants';
import {UtilsService} from '../../core/utils.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends StdComponent implements OnInit {
  public title: string;
  public message: string;
  public messageList: string[];
  public showDontShowAgainOption = false;
  public dontShowAgainValue = false;
  public okButtonCaption = 'common.ok';
  public cancelButtonCaption = 'common.cancel';
  public showCloseButton = false;
  public cancelAsClose = false;
  public dontAutoClose = false;
  public cancelAsFalse = false;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === Constants.ESCAPE) {
      this.dialogRef.close();
    }
  }

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              public utils: UtilsService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    if (!this.dontAutoClose) {
      this.utils.closeCommonModalDialog.pipe(this.takeUntilAlive()).subscribe(value => {
        this.dialogRef.close(Constants.EXTERNAL_CLOSE_DIALOG_OK);
      });
    }
  }


}
