// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.picture-content {
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  border-radius: 16px;
}
.picture-content:hover .picture-nav-toolbar {
  display: flex;
}

.picture-nav-toolbar {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
}
.picture-nav-toolbar .picture-nav-button {
  border-radius: 50%;
  background-color: rgba(235, 235, 235, 0.6705882353);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.picture-nav-toolbar .picture-nav-button mat-icon {
  font-size: 35px;
  height: auto;
  width: auto;
}
.picture-nav-toolbar .picture-nav-button mat-icon.prev {
  transform: rotate(180deg);
}
.picture-nav-toolbar .picture-nav-button.disable {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/content-container/components/picture-gallery/picture-gallery/picture-gallery.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACI;EACE,aAAA;AACN;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,WAAA;AADF;AAEE;EACE,kBAAA;EACA,mDAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;AAAJ;AACI;EACE,eAAA;EACA,YAAA;EACA,WAAA;AACN;AAAM;EACE,yBAAA;AAER;AACI;EACE,oBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AACN","sourcesContent":[".picture-content {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  box-sizing: border-box;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  display: flex;\n  align-items: center;\n  border-radius: 16px;\n  &:hover {\n    .picture-nav-toolbar {\n      display: flex;\n    }\n  }\n}\n\n.picture-nav-toolbar {\n  display: none;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 5px;\n  width: 100%;\n  .picture-nav-button {\n    border-radius: 50%;\n    background-color: #ebebebab;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 40px;\n    height: 40px;\n    mat-icon {\n      font-size: 35px;\n      height: auto;\n      width: auto;\n      &.prev {\n        transform: rotate(180deg);\n      }\n    }\n    &.disable {\n      pointer-events: none;\n      cursor: default;\n      opacity: 0.6;\n      color: gray;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
