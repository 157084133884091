import {Component, Inject, Injector, OnInit} from '@angular/core';
import {StdComponent} from '../../../../../core/std-component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import {CommonService} from '../../../../../core/common.service';
import {ITEM_MARKER} from '../../../shared/container-interface';
import {formatSize, getExtension, isImage} from '../../quiz/quiz-components/question-upload-files/question-upload-files-utils';
import {IPresenterFiles, IUploadedFiles} from '../upload-files-model/upload-files-model';
import {UtilsService} from '../../../../../core/utils.service';
import {cloneDeep} from 'lodash';
import {BehaviorSubject, filter, take} from 'rxjs';
import {Constants} from '../../../../../core/constants';

@Component({
  selector: 'app-upload-files-editor-dialog',
  templateUrl: './upload-files-editor-dialog.component.html',
  styleUrl: './upload-files-editor-dialog.component.scss'
})
export class UploadFilesEditorDialogComponent extends StdComponent implements OnInit {
  readonly ITEM_MARKER = ITEM_MARKER;
  readonly DEFAULT_LARGE_SIZE_LIMIT = Constants.FT_VIDEO_SIZE;
  readonly DEFAULT_SIZE_LIMIT = Constants.FT_AUDIO_SIZE;
  readonly isImage = isImage;
  uppy: any;
  replacementUppy: any;
  allFiles: IPresenterFiles[] = [];
  isModify = false;
  dataHash: string;
  idxToDelete: number;
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(protected injector: Injector,
              private uploadService: UploadService,
              private common: CommonService,
              public dialogRef: MatDialogRef<UploadFilesEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IUploadedFiles) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    dialogRef.updateSize('680px');
    this.allFiles = UtilsService.wrapObjectToProxy(cloneDeep(data.files || []), this.dataChangesHandler);
    this.dataHash = this.getGalleryHash(this.allFiles);
  }

  ngOnInit(): void {
    const maxNumberOfFiles = 10;
    const selectedTypes = this.getSelectedTypes();
    const baseSettings = {
      restrictions: {
        allowedFileTypes: selectedTypes,
        maxFileSize: this.DEFAULT_LARGE_SIZE_LIMIT,
        dashboardNote: this.getDashboardNote(maxNumberOfFiles)
      },
      onBeforeFileAdded: (currentFile, files) => {
        const validationResult = this.validateFileSize(currentFile);
        if (validationResult !== true) {
          this.uppy.info(validationResult.message, 'error', 5000);
          return false;
        }
        return true;
      }
    };
    const mainSettings = {
      ...baseSettings,
      restrictions: {
        ...baseSettings.restrictions,
        maxNumberOfFiles: maxNumberOfFiles
      }
    };

    const replacementSettings = {
      ...baseSettings,
      restrictions: {
        ...baseSettings.restrictions,
        maxNumberOfFiles: 1,
        dashboardNote: this.getDashboardNote(1)
      }
    };
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM, mainSettings, (result) => {
      this.addFileToSource(result);
      this.uploadService.closeModal(this.uppy);
    });
    this.uppy.on('dashboard:modal-open', () => {
      this.setUppyId();
    });

    this.replacementUppy = this.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM, replacementSettings, (result) => {
      this.addFileToSourceInPlaceOf(result[0], this.idxToDelete);
      this.deleteFile(this.idxToDelete);
      this.uploadService.closeModal(this.replacementUppy);
    }, (cancel) => {
      this.undoDeleteUploadedFile();
      this.uploadService.closeModal(this.replacementUppy);
    });
    this.replacementUppy.on('dashboard:modal-open', () => {
      this.setUppyId();
    });
    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.dataHash !== this.getGalleryHash(this.allFiles);
      });
  }

  private getDashboardNote(maxNumberOfFiles: number) {
    return this.common.utils.i18n('questionnaire.upload.file.participants.upload.file.info',
      {maxNoOfFiles: maxNumberOfFiles, fileTypes: this.getSupportedFileType()});
  }

  private setUppyId() {
    const dashboardEl = document.querySelectorAll('.uppy-Dashboard');
    if (dashboardEl) {
      dashboardEl.forEach((element, index) => {
        if (!element.id) {
          element.id = `uppy-Dashboard-${index + 1}`;
        }
      });
    }
  }

  private getSupportedFileType() {
    return Object.entries(Constants.FILE_TYPES)
      .map(([category, data]) => {
        const extensions = data.extension
          .map(ext => `.${ext.toLowerCase()}`)
          .join(', ');
        return `${category}: ${extensions}`;
      })
      .join('\n');
  }

  private getSelectedTypes(): string[] {
    return Object.values(Constants.FILE_TYPES)
      .flatMap(type => type.extension)
      .map(ext => `.${ext.toLowerCase()}`);
  }

  validateFileSize(file) {
    const fileType = file.extension;
    const sizeLimit = this.getSizeLimitForExtension(file.extension) || this.DEFAULT_SIZE_LIMIT;
    if (file.size > sizeLimit) {
      return {
        message: this.common.i18n('questionnaire.upload.file.participants.upload.file.type.size.error',
          {fileSizeLimit: formatSize(sizeLimit), fileType: fileType}),
      };
    }
    return true;
  }

  getSizeLimitForExtension(extension: string): number {
    const uppercaseExtension = extension.toUpperCase().replace('.', '');
    for (const fileType of Object.values(Constants.FILE_TYPES)) {
      if (fileType.extension.includes(uppercaseExtension)) {
        return fileType.sizeLimit;
      }
    }
    return null;
  }

  private addFileToSource(list) {
    for (const item of list) {
      const prefix = this.common.utils.generateRandomString(4).toLowerCase();
      const id = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}.${item.extension}`;
      if (!this.allFiles.find(p => p.id.includes(id))) {
        this.allFiles.push({
          id: `${ITEM_MARKER.NEW}${id}`,
          name: item.name,
          src: item.response,
          size: formatSize(item.size),
          extension: getExtension(item.name),
          metaType: item.meta.type
        });
      }
    }
  }

  private addFileToSourceInPlaceOf(item, index) {
    const prefix = this.common.utils.generateRandomString(4).toLowerCase();
    const id = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}.${item.extension}`;
    if (!this.allFiles.find(p => p.id.includes(id))) {
      this.allFiles.splice(index + 1, 0, {
        id: `${ITEM_MARKER.NEW}${id}`,
        name: item.name,
        src: item.response,
        size: formatSize(item.size),
        extension: getExtension(item.name),
        metaType: item.meta.type
      });
    }
  }

  replaceUploadFile(itemId: string) {
    const index = this.allFiles.findIndex(it => it.id === itemId);
    if (index > -1) {
      this.idxToDelete = index;
    }
    this.uploadService.openUploadWindow(this.replacementUppy);
  }

  private undoDeleteUploadedFile() {
    if (this.idxToDelete > -1) {
      this.allFiles[this.idxToDelete].id = this.allFiles[this.idxToDelete].id.replace(ITEM_MARKER.DELETED, '');
      this.idxToDelete = -1;
    }
  }

  deleteUploadFile(id) {
    this.common.confirm(this.common.i18n('questionnaire.upload.file.participants.operation.remove.confirm.header'),
      this.common.i18n('questionnaire.upload.file.participants.operation.remove.confirm.message'), false,
      this.common.i18n('action.delete'))
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          const index = this.allFiles.findIndex(it => it.id === id);
          this.deleteFile(index);
        }
      });
  }

  private deleteFile(index: number) {
    if (index > -1) {
      if (this.allFiles[index].id.startsWith(ITEM_MARKER.NEW)) {
        this.allFiles.splice(index, 1);
      } else {
        this.allFiles[index].id = `${ITEM_MARKER.DELETED}${this.allFiles[index].id}`;
      }
    }
  }

  upload() {
    this.uploadService.openUploadWindow(this.uppy);
  }

  onOkClick(): void {
    const uploadedFiles: IUploadedFiles = {
      files: this.allFiles
    };
    this.dialogRef.close(uploadedFiles);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDestroy() {
    this.uppy.close();
    this.replacementUppy.close();
  }

  private getGalleryHash(files) {
    const f = UtilsService.jsonSorted(files);
    return UtilsService.md5(f);
  }

}
