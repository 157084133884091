import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EventComponent} from './event/event.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../core/core.module';
import {HttpClient} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../directives/directives.module';



@NgModule({
  declarations: [
    EventComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    DirectivesModule
  ],
  exports: [
    EventComponent
  ]
})
export class EventComponentsModule { }
