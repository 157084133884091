import {Injectable} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {COLUMN_INPUT_TYPES, TABLE_COLUMN_TYPE, TableColumn} from '../question-table-editor/table-poll-model/TablePollModel';
import {ILanguageParams, TCaption} from '../../../../../../../core/constants';
import {UtilsService} from '../../../../../../../core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionTableFormUtilsService {
  constructor() {}

  static createInstance(): QuestionTableFormUtilsService {
    return new QuestionTableFormUtilsService();
  }

  createColumnFormGroup(cmpCtx: any, column: TableColumn): FormGroup {
    return cmpCtx.fb.group({
      id: [column.id],
      isMainColumn: [column.isMainColumn],
      type: [column.type, Validators.required],
      tableRows: cmpCtx.fb.array([]),
      orderIndex: [column.orderIndex]
    });
  }

  getPlaceHolder(columnType: TABLE_COLUMN_TYPE) {
    const inputType = COLUMN_INPUT_TYPES.find(input => input.name === columnType);
    return inputType.label;
  }

  getAnswerByLanguage(thisCtx: any, languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(thisCtx, 'answer', languageParams);
  }

  setAnswerByLanguage(thisCtx: any, value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(thisCtx, 'answer', value, languageParams);
  }
}
