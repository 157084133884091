import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Injector} from '@angular/core';
import {WordCloudTemplate} from '../../../../../../../model/content/WordCloudTemplate';
import {debounceTime, Subject, Subscription} from 'rxjs';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {WordCloudService} from '../../../../../../../core/word-cloud.service';
import {cloneDeep, delay, isEmpty} from 'lodash';

@Component({
  selector: 'app-question-word-cloud-results',
  templateUrl: './question-word-cloud-results.component.html',
  styleUrls: ['./question-word-cloud-results.component.scss']
})
export class QuestionWordCloudResultsComponent extends AbstractQuizQuestionResultsComponent implements AfterViewChecked {
  wordCloudTemplate: WordCloudTemplate;
  wordCloudCanvas1;
  wordCloudCanvas2;
  cloudContainer;
  canvasSwitcher = 1;
  resizeSubject = new Subject();
  resizeSubscription: Subscription;
  firstDrawn = true;

  isFullscreen: boolean;

  tagCloudContainer;
  tags: string[] = [];
  tagWordCloud;
  canvas3DWordCloudReady = false;

  private elementObserver = new MutationObserver(() => {
    this.checkReady3DCanvas();
  });

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeSubject.next(true);
  }

  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private wordCloudService: WordCloudService,
              private cdr: ChangeDetectorRef) {
    super(injector, elementRef);
    this.elementObserver.observe(this.elementRef.nativeElement, {childList: true, subtree: true});
  }

  private checkReady3DCanvas() {
    const elements: HTMLCollection = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('canvas');
    this.canvas3DWordCloudReady = !isEmpty(Array.from(elements).find(el => el.id === 'tagWordCloud_' + this.qKey)) &&
      this.qKey === this.currentQKey$.getValue();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeResize();
    if (this.currentQKey$) {
      this.currentQKey$.pipe(this.takeUntilAlive())
        .subscribe(() => {
          this.checkReady3DCanvas();
        });
    }
  }

  onDestroy() {
    this.elementObserver.disconnect();
  }

  protected initQuestionAnswersDataSource() {
    if (this.question &&
      this.wordCloudCanvas1 && this.wordCloudCanvas2 && this.wordCloudTemplate && !this.wordCloudTemplate.use3DWordCloud) {
      this.wordCloudService.drawWordCloud(
        this.canvasSwitcher === 1 ? this.wordCloudCanvas1 : this.wordCloudCanvas2,
        this.wordCloudTemplate, null, null, this.summaryQuestionAnswers);
      this.cdr.markForCheck();
    } else if (this.wordCloudTemplate && this.wordCloudTemplate.use3DWordCloud && this.canvas3DWordCloudReady) {
      this.wordCloudService.drawTagWordCloud('tagWordCloud_' + this.qKey, this.wordCloudTemplate.options3DWordCloud);
      this.updateTagAnswers(this.summaryQuestionAnswers);
      this.cdr.markForCheck();
    }
  }

  protected onReceiveQuestionAnswers() {
    if (this.wordCloudTemplate && this.wordCloudTemplate.compare(this.question.options.wordCloudTemplate) &&
      (this.cloudContainer || this.tagWordCloud)) {
      this.wordCloudTemplate = new WordCloudTemplate({...this.question.options.wordCloudTemplate, url: this.question.files[0]});
      if (this.wordCloudTemplate && this.wordCloudTemplate.use3DWordCloud && this.canvas3DWordCloudReady) {
        this.wordCloudService.drawTagWordCloud('tagWordCloud_' + this.qKey, this.wordCloudTemplate.options3DWordCloud);
        this.updateTagAnswers(this.summaryQuestionAnswers);
        this.cdr.markForCheck();
      } else if (this.wordCloudTemplate && !this.wordCloudTemplate.use3DWordCloud) {
        this.resizeSubject.next(true);
      }
    } else {
      const oldTemplate = cloneDeep(this.wordCloudTemplate);
      this.wordCloudTemplate = new WordCloudTemplate({...this.question.options.wordCloudTemplate, url: this.question.files[0]});
      if (this.tagWordCloud && this.wordCloudTemplate && this.wordCloudTemplate.use3DWordCloud && this.canvas3DWordCloudReady &&
        this.wordCloudTemplate.compare(oldTemplate)) {
        this.wordCloudService.drawTagWordCloud('tagWordCloud_' + this.qKey, this.wordCloudTemplate.options3DWordCloud);
        this.updateTagAnswers(this.summaryQuestionAnswers);
        this.cdr.markForCheck();
      } else if (this.tagWordCloud && this.tagCloudContainer && this.wordCloudTemplate.use3DWordCloud && this.canvas3DWordCloudReady) {
        this.updateTagAnswers(this.summaryQuestionAnswers);
      } else if (this.wordCloudTemplate && !this.wordCloudTemplate.use3DWordCloud && this.wordCloudCanvas1 && this.wordCloudCanvas2) {
        this.wordCloudService.drawWordCloud(
          this.canvasSwitcher === 1 ? this.wordCloudCanvas1 : this.wordCloudCanvas2,
          this.wordCloudTemplate, null, null, this.summaryQuestionAnswers);
      }
    }
  }

  private subscribeResize() {
    this.resizeSubscription = this.resizeSubject.pipe(debounceTime(800), this.takeUntilAlive()).subscribe(() => {
      if (this.question) {
        if (this.wordCloudTemplate && !this.wordCloudTemplate.use3DWordCloud && this.wordCloudCanvas2 && this.wordCloudCanvas1) {
          this.firstDrawn = false;
          this.wordCloudService.drawWordCloud(this.canvasSwitcher === 1 ? this.wordCloudCanvas2 : this.wordCloudCanvas1,
            this.wordCloudTemplate, null, null, this.summaryQuestionAnswers)
            .then(() => {
              this.canvasSwitcher = this.canvasSwitcher === 1 ? 2 : 1;
              this.cdr.markForCheck();
            });
        }
        if (this.tagWordCloud && this.tagCloudContainer && this.wordCloudTemplate.use3DWordCloud && this.canvas3DWordCloudReady) {
          this.tagWordCloud.height = this.tagCloudContainer.clientHeight;
          this.tagWordCloud.width = this.tagCloudContainer.clientWidth;
          this.wordCloudService.drawTagWordCloud('tagWordCloud_' + this.qKey, this.wordCloudTemplate.options3DWordCloud);
          this.updateTagAnswers(this.summaryQuestionAnswers);
          this.cdr.markForCheck();
        }
      }
    });
  }

  updateTagAnswers(answers) {
    const baseWords = this.wordCloudService
      .prepareTagWordList(answers, this.wordCloudTemplate.singleWordMode);
    const dw = [];
    for (const value of this.tags) {
      if (baseWords.findIndex(w => w === value) === -1) {
        dw.push(value);
      }
    }
    for (const value of dw) {
      const ind = this.tags.findIndex(w => w === value);
      this.tags.splice(ind, 1);
    }
    for (const value of baseWords) {
      const ind = this.tags.findIndex(w => w === value);
      if (ind === -1) {
        this.tags.push(value);
      }
    }
    if (this.tagWordCloud && this.canvas3DWordCloudReady) {
      this.wordCloudService.updateTagWordCloud('tagWordCloud_' + this.qKey);
      delay(() => this.wordCloudService.updateTagWordCloud('tagWordCloud_' + this.qKey), 100);
    }
  }

  trackByWord(index, item) {
    if (!item) {
      return null;
    }
    return item;
  }

  ngAfterViewChecked(): void {
    if (this.question && this.wordCloudTemplate && !this.wordCloudTemplate.use3DWordCloud) {
      if (!this.wordCloudCanvas1) {
        this.wordCloudCanvas1 = document.getElementById((!this.isFullscreen ? 'canvas_1_' : 'canvas_1_fullscreen_') + this.qKey);
      }
      if (!this.wordCloudCanvas2) {
        this.wordCloudCanvas2 = document.getElementById((!this.isFullscreen ? 'canvas_2_' : 'canvas_2_fullscreen_') + this.qKey);
      }
      if (!this.cloudContainer) {
        this.cloudContainer =
          document.getElementById((!this.isFullscreen ? 'cloud-container_' : 'cloud-container_fullscreen_') + this.qKey);
      }
      if (this.wordCloudCanvas1 && this.wordCloudCanvas2 && this.cloudContainer) {
        if (this.firstDrawn) {
          this.resizeSubject.next(true);
        }
      }
    }
    if (!this.tagCloudContainer && !this.tagWordCloud && this.question && this.wordCloudTemplate && this.wordCloudTemplate.use3DWordCloud) {
      this.tagWordCloud = document.getElementById('tagWordCloud_' + this.qKey);
      this.tagCloudContainer = document.getElementById('tag_cloud-container_' + this.qKey);
      if (this.tagCloudContainer && this.tagWordCloud) {
        this.tagWordCloud.height = this.tagCloudContainer.clientHeight;
        this.tagWordCloud.width = this.tagCloudContainer.clientWidth;
        this.updateTagAnswers(this.summaryQuestionAnswers);
        this.wordCloudService.drawTagWordCloud('tagWordCloud_' + this.qKey, this.wordCloudTemplate.options3DWordCloud);
        delay(() =>
          this.wordCloudService.drawTagWordCloud('tagWordCloud_' + this.qKey, this.wordCloudTemplate.options3DWordCloud), 100);
        this.cdr.detectChanges();
      }
    }
  }

}
