export class FirestoreObject {

  constructor(obj: any) {
    if (!obj) {
      return;
    }
  }

  toObject() {
    return {...this};
  }
}
