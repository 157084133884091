import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {BehaviorSubject} from 'rxjs';
import {IGapAnswerData, TableRowAnswerQuestion} from '../../../../../../../model/EventQuestion';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {isEmpty} from 'lodash';
import {Constants} from '../../../../../../../core/constants';

@Component({
  selector: 'app-question-checkbox-gap-filling-participant',
  templateUrl: './question-checkbox-gap-filling-participant.component.html',
  styleUrls: ['./question-checkbox-gap-filling-participant.component.scss']
})
export class QuestionCheckboxGapFillingParticipantComponent  extends AbstractQuizQuestionParticipantComponent {

  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  correctGapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapTaskText$ = new BehaviorSubject<string>(null);
  rowsAnswersQuestion: TableRowAnswerQuestion[] = [];

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    (this.question.items || []).forEach(item => this.rowsAnswersQuestion.push(new TableRowAnswerQuestion(item, this.languageParams)));
    this.rowsAnswersQuestion.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    const compatibleRelations = (this.question.correctEquality || []).map(ce => new Object({id: ce.id, answerId: ce.answerId}) as any);
    this.gapAnswersData$
      .next(((this.question.relations || compatibleRelations)
        .map(eq => {
          const answerValue = !isEmpty((this.answers || []).find(aId => aId === eq.id));
          return new Object({...eq, answerValue: answerValue}) as IGapAnswerData;
        })));
    const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
    this.correctGapAnswersData$.next(this.question.showCorrectAnswers ?
      correctEquality.map(v => new Object({id: v.id, answerId: v.answerId, answerValue: v.answerValue}) as IGapAnswerData)
      : []);
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }


  protected onReceiveQuestionAnswers() {
    this.gapAnswersData$
      .next(((this.question.relations || [])
        .map(eq => {
          const answerValue = !isEmpty((this.answers || []).find(aId => aId === eq.id));
          return new Object({...eq, answerValue: answerValue}) as IGapAnswerData;
        })));
  }

  changeCheckboxGap(value: IGapAnswerData[]) {
    this.answers = (value || []).filter(v => !!v.answerValue).map(o => o.id);
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

}
