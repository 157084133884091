import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import * as ui from '../../../../../actions/ui';
import {ITaskFile, TaskObject} from '../task-model/task';
import {Constants, TRIPLE} from '../../../../../core/constants';
import {CommonService} from '../../../../../core/common.service';
import {ITEM_MARKER} from '../../../shared/container-interface';
import {BehaviorSubject, filter} from 'rxjs';
import {StdComponent} from '../../../../../core/std-component';
import {UtilsService} from '../../../../../core/utils.service';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'app-task-document-editor-dialog',
  templateUrl: './task-document-editor-dialog.component.html',
  styleUrls: ['./task-document-editor-dialog.component.scss']
})
export class TaskDocumentEditorDialogComponent extends StdComponent implements OnInit, OnDestroy {
  readonly Constants = Constants;
  readonly ITEM_MARKER = ITEM_MARKER;

  content: TaskObject;
  isPresenter: boolean;
  eventId;
  uppy: any;
  dataHash: string;
  isModify = false;
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<TaskDocumentEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<fromRoot.State>,
              private uploadService: UploadService,
              private common: CommonService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.ANY_DOCUMENT, null, (result) => {
      this.addImageToDataSource(result);
      this.uploadService.closeModal(this.uppy);
    });
    this.content = UtilsService.wrapObjectToProxy(cloneDeep(data.content), this.dataChangesHandler);
    this.dataHash = UtilsService.md5(UtilsService.jsonSorted(this.content));
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  set contentDirectLink(value: string) {
    this.content.directLink = this.common.utils.sanitizeTolerant(value);
  }

  get contentDirectLink() {
    return this.content.directLink;
  }

  ngOnInit() {
    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.content));
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    this.uppy.close();
  }

  uploadImage() {
    this.uploadService.openUploadWindow(this.uppy);
  }

  addImageToDataSource(list) {
    for (const item of list) {
      const prefix = this.common.utils.generateRandomString(4).toLowerCase();
      const id = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}.${item.extension}`;
      if (!this.content.files.find(it => it.id.includes(id))) {
        const obj: ITaskFile = {
          id: `${ITEM_MARKER.NEW}${id}`,
          src: item.response,
          name: item.name,
          metaType: item.meta.type
        };
        this.content.files.push(obj);
      }
    }
  }

  deleteUploadFile(item) {
    const index = this.content.files.findIndex(it => it.id === item.id);
    if (index > -1) {
      if (this.content.files[index].id.startsWith(ITEM_MARKER.NEW)) {
        this.content.files.splice(index, 1);
      } else {
        this.content.files[index].id = `${ITEM_MARKER.DELETED}${this.content.files[index].id}`;
      }
    }
  }

  set currentContentMaximalPoints(value) {
    const val = value ? (value.toString().replace(/[^0-9]*/g, '')) : null;
    this.content.maximalPoints = val ? Number(val) : null;
  }

  get currentContentMaximalPoints() {
    if (typeof this.content.maximalPoints !== 'number') {
      return null;
    } else {
      return this.content.maximalPoints;
    }
  }

  onOkClick(): void {
    this.dialogRef.close(this.content);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
