import {Injectable} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DailyRoomInfo} from '@daily-co/daily-js';
import {IRequestSection, StdApiService} from './std-api-service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {RoomRequest} from '../model/daily/RoomRequest';

@Injectable({
  providedIn: 'root'
})
export class DailyApiService extends StdApiService {
  protected API_URL: string;

  constructor(
    private http: HttpClient,
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    protected aFirestore: AngularFirestore,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/dailyApi/v3/';
  }

  getToken(eventId: string, roomName: string): Observable<string> {
    return this.http.post(this.API_URL + `token/${roomName}?eventId=${eventId}`, null, {responseType: 'text'})
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  getRoom(roomName: string): Observable<DailyRoomInfo> {
    return this.http.get<DailyRoomInfo>(this.API_URL + `rooms/${roomName}`)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  createRoom(roomRequest: RoomRequest): Observable<DailyRoomInfo> {
    return this.http.post<DailyRoomInfo>(this.API_URL + `rooms`, roomRequest)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  updateRoom(eventId: string, roomName: string): Observable<DailyRoomInfo> {
    return this.http.post<DailyRoomInfo>(this.API_URL + `rooms/${roomName}?eventId=${eventId}`, null)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  deleteRoom(roomName: string): Observable<any> {
    return this.http.delete(this.API_URL + `rooms/${roomName}`)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  checkPresence(eventId: string, sectionId: string) {
    return this.http.post(this.API_URL + `presence/${eventId}?sid=${sectionId}`, null)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  watchRoom(eventId: string, sectionId: string, roomId: string) {
    const body: IRequestSection = {
      eventId: eventId,
      sectionId: sectionId
    };
    return this.http.post(this.API_URL + `rooms/${roomId}/watch`, body)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

}
