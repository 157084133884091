import {Injectable} from '@angular/core';
import {TagsDataService} from './tags-data.service';
import {BehaviorSubject, combineLatest, filter, firstValueFrom, map, Observable, Subject, Subscription, takeUntil} from 'rxjs';
import {ILoadData, LOAD_STATE} from '../../../core/constants';
import {Tag} from '../tags-model/Tag';
import {IContentsTagsMap} from '../tags-constants/tags-constants';
import {FeaturesService} from '../../../core/features.service';
import {APP_MODE, LoginService} from '../../../login/login.service';
import {InstantSettings} from '../../../model/event-mode/InstantSettings';

enum LOAD_DATA {
  NOTES_TAGS_REFERENCE = 'loadNotesTagsReference',
  CONTENTS_TAGS = 'loadContentsTags'
}

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private _tagsReference$ = new BehaviorSubject<ILoadData<Tag[]>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _contentsTags$ = new BehaviorSubject<ILoadData<IContentsTagsMap>>({loaded: LOAD_STATE.NOT_LOADED, value: null});

  private subscriptions: {
    [key: string]: {
      subscription?: Subscription,
      subject: BehaviorSubject<ILoadData<any>>
    }
  } = {
    [LOAD_DATA.NOTES_TAGS_REFERENCE]: {subject: this._tagsReference$},
    [LOAD_DATA.CONTENTS_TAGS]: {subject: this._contentsTags$},
  };
  private _unsubscribeAll = new Subject();

  constructor(private tagsDataService: TagsDataService,
              private featuresService: FeaturesService,
              private loginService: LoginService) { }

  isTagsServiceEnabled(instantSettings?: InstantSettings) {
    return (instantSettings?.myKnowledge && this.featuresService.isMyKnowledgeEnabled()) ||
      this.loginService.applicationMode === APP_MODE.NOTES;
  }

  /****** PUBLIC ******/
  unsubscribeAll() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
    Object.keys(this.subscriptions)
      .forEach(it => {
        this.setSubscription(<LOAD_DATA> it, null);
      });
  }

  loadData(eventId?: string) {
    const loadList = [];
    if (this._tagsReference$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadTagsReference();
      loadList.push(this.tagsReference$);
    }
    if (this._contentsTags$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadContentsTags(eventId);
      loadList.push(this.contentsTags$);
    }
    return loadList.length ? firstValueFrom(combineLatest(loadList)) : Promise.resolve([]);
  }

  get tagsReference$(): Observable<Tag[]> {
    return this._tagsReference$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  /* ~~~~~~ PUBLIC CONTENTS TAGS ~~~~~~ */
  get contentsTags$(): Observable<IContentsTagsMap> {
    return this._contentsTags$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get contentsTags(): IContentsTagsMap {
    const object = this._contentsTags$.getValue();
    return object.loaded === LOAD_STATE.LOADED ? object.value : null;
  }

  /****** PRIVATE ******/
  private setSubscription(name: LOAD_DATA, sub: Subscription) {
    if (this.subscriptions[name]) {
      if (this.subscriptions[name].subscription) {
        this.subscriptions[name].subscription.unsubscribe();
      }
      this.subscriptions[name].subscription = sub;
      if (!sub && this.subscriptions[name].subject.getValue().loaded !== LOAD_STATE.NOT_LOADED) {
        this.subscriptions[name].subject.next({loaded: LOAD_STATE.NOT_LOADED, value: null});
      }
    }
  }

  /* ~~~~~~ PRIVATE TAGS REFERENCE ~~~~~~ */
  private loadTagsReference() {
    const sub = this.tagsDataService.loadTagsReference().pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this._tagsReference$.next({loaded: LOAD_STATE.LOADED, value: value});
      });
    this.setSubscription(LOAD_DATA.NOTES_TAGS_REFERENCE, sub);
  }

  /* ~~~~~~ PRIVATE CONTENTS TAGS ~~~~~~ */
  private loadContentsTags(eventId?: string) {
    const sub = this.tagsDataService.loadContentsTags(eventId).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this._contentsTags$.next({loaded: LOAD_STATE.LOADED, value: value});
      });
    this.setSubscription(LOAD_DATA.CONTENTS_TAGS, sub);
  }
}
