import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SectionTimeline} from '../../../../model/content/SectionTimeline';
import {TimeLineService} from '../../../../services/time-line.service';
import {BehaviorSubject, Subject, take} from 'rxjs';
import {isEmpty} from 'lodash';
import {SectionContent} from '../../../../model/content/SectionContent';
import {StdComponent} from '../../../../core/std-component';
import {EditDurationDialogComponent} from '../../../../components/edit-duration-dialog/edit-duration-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ISelectedMode, IWarningDuration} from '../time-line-section-reference.component';
import {MatCheckbox} from '@angular/material/checkbox';

export enum MANUAL_SELECT_MODE {
  UNSELECT = 0,
  SQUARE = 1,
  CHECK = 2
}

export interface IOnSelect {
  section: SectionTimeline;
  selectMode: MANUAL_SELECT_MODE;
}

@Component({
  selector: 'app-reference-item',
  templateUrl: './reference-item.component.html',
  styleUrls: ['./reference-item.component.scss']
})
export class ReferenceItemComponent extends StdComponent implements OnInit {

  readonly MANUAL_SELECT_MODE = MANUAL_SELECT_MODE;

  @Input() section: SectionTimeline;
  @Input() mainSection: SectionContent;
  @Input() allSectionList: SectionTimeline[] = [];
  @Input() selectList$: BehaviorSubject<{[sectionId: string]: SectionContent | SectionTimeline}>;
  @Input() durationParentWarning$: BehaviorSubject<IWarningDuration>;
  @Input() durationSelfWarning$: BehaviorSubject<IWarningDuration>;
  @Input() manualSelected$: Subject<ISelectedMode>;
  @Output() onSelect = new EventEmitter<IOnSelect>();
  @Output() onChangeDuration = new EventEmitter<SectionTimeline>();

  selected = false;
  manualSelected: MANUAL_SELECT_MODE = MANUAL_SELECT_MODE.UNSELECT;
  parentWarningId: string;
  selfWarningId: string;
  inSearchResult$ = new BehaviorSubject<boolean>(false);
  parents: SectionContent[];

  @ViewChild('checkbox') checkbox: MatCheckbox;

  constructor(protected injector: Injector,
              public dialog: MatDialog,
              public timeLineService: TimeLineService) {
    super(injector);
  }

  ngOnInit(): void {
  }

  init() {
    this.selectList$.pipe(this.takeUntilAlive()).subscribe(value => {
      if (!this.section.items.length) {
        this.selected = !isEmpty(value[this.section.id]);
        this.manualSelected = this.selected ? 2 : 0;
      } else {
        const count = this.section.items.filter(it => !!value[it.id]).length;
        const selectedMode = count >= 0 && count < this.section.items.length ? MANUAL_SELECT_MODE.SQUARE :
          MANUAL_SELECT_MODE.CHECK;
        switch (this.manualSelected) {
          case MANUAL_SELECT_MODE.UNSELECT:
            this.selected = !isEmpty(value[this.section.id]);
            if (this.selected) {
              this.manualSelected = selectedMode;
            }
            break;
          case MANUAL_SELECT_MODE.CHECK:
            this.selected = !isEmpty(value[this.section.id]);
            if (!this.selected) {
              this.manualSelected = MANUAL_SELECT_MODE.UNSELECT;
            } else {
              this.manualSelected = selectedMode;
            }
            break;
          case MANUAL_SELECT_MODE.SQUARE:
            if (isEmpty(value[this.section.id])) {
              this.selected = false;
              this.manualSelected = 0;
            } else {
              this.manualSelected = selectedMode;
            }
            break;
        }
      }
    });
    this.durationParentWarning$.pipe(this.takeUntilAlive()).subscribe(value => {
      this.parentWarningId = value[this.section.id];
    });
    this.durationSelfWarning$.pipe(this.takeUntilAlive()).subscribe(value => {
      this.selfWarningId = value[this.section.id];
    });
    this.manualSelected$.pipe(this.takeUntilAlive()).subscribe(value => {
      if (value[this.section.id]) {
        this.manualSelected = value[this.section.id];
        this.selected = true;
      }
    });
  }

  selectSection(section: SectionTimeline) {
    if (!this.parents) {
      this.parents = this.getParents(section, this.allSectionList);
    }
    if (!this.section.items.length || this.section.items.every(s => !this.selectList$.getValue()[s.id])) {
      this.manualSelected = this.selected ? MANUAL_SELECT_MODE.CHECK : MANUAL_SELECT_MODE.UNSELECT;
      this.onSelect.emit({section: section, selectMode: this.manualSelected});
      this.checkParentsAndNotify(section);
      return;
    }
    if (this.selected && this.manualSelected === MANUAL_SELECT_MODE.UNSELECT) {
      this.manualSelected = MANUAL_SELECT_MODE.SQUARE;
    } else if (!this.selected && this.manualSelected === MANUAL_SELECT_MODE.SQUARE) {
      this.checkbox.checked = true;
      this.selected = true;
      this.manualSelected = MANUAL_SELECT_MODE.CHECK;
    } else if (!this.selected && this.manualSelected === MANUAL_SELECT_MODE.CHECK) {
      this.manualSelected = MANUAL_SELECT_MODE.UNSELECT;
    }
    this.onSelect.emit({section: section, selectMode: this.manualSelected});
    this.checkParentsAndNotify(section);
  }

  editDuration() {
    const dialogRef = this.dialog.open(EditDurationDialogComponent, {
      panelClass: 'manage-time-select-time-dialog',
      width: 'auto',
      data: {
        simpleMode: true,
        duration: !this.section.duration ? 0 : this.section.duration
      }
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe (result => {
      if (result) {
        this.section.duration = result.duration;
        this.onChangeDuration.emit(this.section);
      }
    });
  }

  protected getParents(section: SectionContent, list: SectionContent[]) {
    const result: SectionContent[] = [];
    if (!section.parentId) {
      return result;
    }
    let parent = list.find(s => s.id === section.parentId);
    while (parent && !parent.isRoot) {
      result.push(parent);
      parent = list.find(s => s.id === parent.parentId);
    }
    return result.sort(this.timeLineService.utils.comparator('orderIndex', 'desc'));
  }

  protected checkParentsAndNotify(section: SectionContent) {
    if (section.parentId === this.timeLineService.rootSection.id ||
      this.parents.every(s => !this.selectList$.getValue()[s.id]) ||
      this.parents.every(s => !!this.selectList$.getValue()[s.id])) {
      return true;
    }
    const selectedList = this.selectList$.getValue();
    const notify = [];
    for (let i = 0; i < this.parents.length - 1; i++) {
      const pId = this.parents[i].id;
      const topId = this.parents[i + 1].id;
      if (!selectedList[pId] && selectedList[topId]) {
        notify.push(this.parents[i]);
      }
    }
    notify.forEach(s => this.onSelect.emit({section: s, selectMode: MANUAL_SELECT_MODE.SQUARE}));
  }

}
