import {computed, Injectable, signal} from '@angular/core';
import {Event} from '../model/Event';
import {Constants} from '../core/constants';
import {LANGUAGE} from '../core/language-constants';
import {BehaviorSubject} from 'rxjs';
import {toObservable} from '@angular/core/rxjs-interop';

interface IDefaultLanguageParams {
  defaultLanguage: LANGUAGE;
  currentLanguage: LANGUAGE;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  event = signal<Event>(null);
  event$ = toObservable(this.event);

  DEFAULT_LANGUAGE = computed<LANGUAGE>(() => (this.event()?.defaultLanguage ?? Constants.TIMELINE_DEFAULT_LANGUAGE) as LANGUAGE);

  DEFAULT_LANGUAGE_PARAMS = computed<IDefaultLanguageParams>(() => new Object({
    defaultLanguage: this.DEFAULT_LANGUAGE(),
    currentLanguage: this.DEFAULT_LANGUAGE()
  }) as IDefaultLanguageParams);

  readonly userInterfaceLanguage$ = new BehaviorSubject<string>(null);

}
