import {AfterViewInit, Component, ElementRef, EventEmitter, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {TextEditorDialogComponent} from '../text-editor-dialog/text-editor-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  interval,
  Observable,
  Subject,
  Subscription,
  take,
  takeUntil
} from 'rxjs';
import {CommonService} from '../../../../../core/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isEmpty, merge} from 'lodash';
import {IMultilingual, ISlide} from '../../../shared/container-interface';
import {
  ANNOTATION_TYPE,
  ATTR_IS_PLAYING,
  Constants,
  HOTSPOT_DATA_ATTRIBUTES,
  IHotspotElementData,
  ILanguageParams,
  MEDIA_LOOP,
  TEXT_MARK_TAGS
} from '../../../../../core/constants';
import {LANGUAGE} from '../../../../../core/language-constants';
import {TranslateApiService} from '../../../../../services/translate-api.service';
import {TextEditorService} from '../../../../../components/text-editor/text-editor-service/text-editor.service';
import {
  FOLLOW_ME_HOTSPOT_STATUS,
  IContainerAnnotationsMap,
  IFollowMeData,
  ITextNodeFile,
  TextNode,
  TText
} from '../text-note-model/text-note-model';
import {InternalContentExchangeService, REQUEST_TYPE} from '../../../service/internal-content-exchange.service';
import {CONTAINER_ITEM_TYPE} from '../../../../../model/content/ContentContainer';
import {TextNoteService} from '../text-note.service';
import {UtilsService} from '../../../../../core/utils.service';

const EXTERNAL_TYPES_LIST = {
  [CONTAINER_ITEM_TYPE.SKETCHFAB]: {
    dataField: 'uid'
  },
  [CONTAINER_ITEM_TYPE.VIDEO]: {
    dataField: 'url'
  }
};

@Component({
  selector: 'app-text-note',
  templateUrl: './text-note.component.html',
  styleUrls: ['./text-note.component.scss']
})
export class TextNoteComponent extends AbstractContainerComponent implements OnInit, AfterViewInit {
  set followMeData(value: any) {
    super.followMeData = value;
  }

  languageParams$ = new BehaviorSubject<ILanguageParams>(null);
  audioFiles: ITextNodeFile[] = [];

  externalAnnotations: IContainerAnnotationsMap = {};
  externalAnnotations$ = new BehaviorSubject<IContainerAnnotationsMap>({});

  stopAll$ = new Subject<string>();
  followMeInputData$ = new BehaviorSubject<IFollowMeData>(null);

  hotspotsList: THotspot[] = [];
  hotspotsListSubscription: Subscription;
  loaded = false;
  loaded$ = new BehaviorSubject(false);
  editorModeInitialized$ = new BehaviorSubject<boolean>(false);

  @ViewChild('textNote', { read: ElementRef }) textNoteRef: ElementRef;

  constructor(protected injector: Injector,
              private dialog: MatDialog,
              private common: CommonService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private textEditorService: TextEditorService,
              private translateApiService: TranslateApiService,
              private internalExchangeService: InternalContentExchangeService) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.editorMode) {
      this.loaded = true;
      this.slidesList$.pipe(this.takeUntilAlive())
        .subscribe(list => {
          this.externalAnnotations = {};
          for (const sl of list.filter(it => !!it.containerItem?.type && !isEmpty(it.containerItem?.data))) {
            const supportedObject = EXTERNAL_TYPES_LIST[sl.containerItem.type];
            if (supportedObject && !isEmpty(sl.containerItem.data) &&
              (!isEmpty(sl.containerItem.data[supportedObject.dataField]) || typeof sl.containerItem.data === 'string')) {
              if (isEmpty(this.externalAnnotations[sl.containerItem.type])) {
                this.externalAnnotations[sl.containerItem.type] = [];
              }
              if (!this.externalAnnotations[sl.containerItem.type].find(a => a.containerId === sl.id)) {
                this.externalAnnotations[sl.containerItem.type].push({containerId: sl.id, title: sl.id});
              }
            }
          }
          this.editorModeInitialized$.next(true);
        });
      this.internalExchangeService.request$
        .pipe(
          filter(v => !v.receiverId || v.receiverId === this.documentPathParams.containerId),
          this.takeUntilAlive())
        .subscribe(request => {
          switch (request.requestType) {
            case REQUEST_TYPE.DELETE_CONTAINER:
              if (request.task?.containerId) {
                const containerId = TextNoteService.prepareId(request.task.containerId);
                const textDocument = this.textNoteRef.nativeElement;
                const cleanText = this.textEditorService.clearAnnotationMarkByContainerId(containerId, textDocument);
                this.data = {
                  text: this.setLanguageText(TextNode.getText(this.data), cleanText),
                  files: this.data?.files ?? []
                };
                TextNoteService.deleteNotUsedAudio(this.data);
                this.data$.next(this.data);
              }
              break;
          }
        });
    } else {
      this.loaded$.pipe(debounceTime(10), this.takeUntilAlive())
        .subscribe(value => {
          this.loaded = value;
        });
      this.internalExchangeService.request$
        .pipe(
          filter(v => v.receiverId === this.documentPathParams.containerId),
          this.takeUntilAlive())
        .subscribe(request => {
          switch (request.requestType) {
            case REQUEST_TYPE.STOP_HOTSPOTS:
              this.hotspotsList.forEach(it => it.stop());
              break;
          }
        });
      this.stopAll$.pipe(this.takeUntilAlive()).subscribe(excludeId => {
        this.hotspotsList.filter(it => excludeId ? (it.id !== excludeId) : true).forEach(it => it.stop());
        if (excludeId) {
          this.slidesList$.getValue()
            .filter(sl => sl.id !== this.documentPathParams.containerId)
            .forEach(sl => this.internalExchangeService.request$.next({receiverId: sl.id, requestType: REQUEST_TYPE.STOP_HOTSPOTS}));
        }
      });
      this.followMeInputData$.pipe(
        distinctUntilChanged((prev, curr) => UtilsService.jsonSorted(prev) === UtilsService.jsonSorted(curr)),
        this.takeUntilAlive())
        .subscribe(data => {
          if (!data) {
            this.stopAll$.next(null);
          } else if (data.elementId) {
            const element = this.hotspotsList.find(it => it.id === data.elementId);
            if (element) {
              element.onClick(data.status === FOLLOW_ME_HOTSPOT_STATUS.STOP);
            }
          }
        });
    }
  }

  onDestroy() {
    this.hotspotsList.forEach(it => it.destroy());
  }

  private getLanguageText(value: TText, language: string) {
    const otherSingleLanguage = (obj) => Object.keys(obj || {}).length === 1 ? obj[Object.keys(obj)[0]] : null;

    if (!value || typeof value === 'string') {
      return value as string;
    } else {
      return this.usedMultilingualContent ?
        (value[language] ?? (value[this.defaultLanguage$.getValue()] ??
          (value[Constants.TIMELINE_DEFAULT_LANGUAGE] ?? value[LANGUAGE.EN]))) :
        (value[this.defaultLanguage$.getValue()] ??
          (value[Constants.TIMELINE_DEFAULT_LANGUAGE] ?? otherSingleLanguage(value)));
    }
  }

  private setLanguageText(data: TText, value: string): TText {
    if (this.usedMultilingualContent) {
      if (!data || typeof data === 'string') {
        return this.defaultLanguage$.getValue() !== this.currentLanguage$.getValue() ?
          {
            [this.defaultLanguage$.getValue()]: data as string,
            [this.currentLanguage$.getValue()]: value
          }
          :
          {
            [this.defaultLanguage$.getValue()]: value as string
          };
      } else {
        return merge(data, {[this.currentLanguage$.getValue()]: value});
      }
    } else {
      return value;
    }
  }

  ngAfterViewInit() {
    combineLatest([this.currentLanguage$, this.data$, this.usedMultilingualContent$])
      .pipe(this.takeUntilAlive())
      .subscribe(([currentLanguage, value, usedMultilingualContent]: [LANGUAGE, TextNode, IMultilingual]) => {
        this.audioFiles = value?.files ?? [];
        if (this.textNoteRef) {
          const sanitizedHTML = this.textEditorService.sanitizeText(this.getLanguageText(TextNode.getText(value), currentLanguage));
          this.textNoteRef.nativeElement.innerHTML = this.common.utils.convertStringToHTML(sanitizedHTML);
          this.addListeners();
        }
        this.languageParams$.next({
          defaultLanguage: this.defaultLanguage$.getValue(),
          currentLanguage: currentLanguage,
          usedMultilingualContent: usedMultilingualContent.multilingual,
          usedLanguages: usedMultilingualContent.usedLanguages
        });
        this.emmitCurrentLanguageTranslatedState();
      });
  }

  private addListeners() {
    if (!this.editorMode) {
      this.setLinksNavigateTo();
      this.createHotspotList();
      this.waitLoadedHotspotsList();
    } else {
      // in editor mode don't wait while hotspot params loaded.
      // this information need for check correct hotspot data annotation.
      // if data is not correct mark this element in text.
      this.createHotspotList();
    }
  }

  private setLinksNavigateTo() {
    const setNavigate = (element: HTMLElement) => {
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children.item(i);
        if (child.tagName.toLowerCase() === 'a') {
          (child as HTMLElement).onclick = (event) => {
            const url = (event.target as HTMLElement).getAttribute('href');
            const params = this.common.utils.parseInternalLink(url);
            if (params) {
              event.stopPropagation();
              event.preventDefault();
              this.navigateTo(params);
            }
          };
        } else {
          setNavigate(child as HTMLElement);
        }
      }
    };
    setNavigate(this.textNoteRef.nativeElement as HTMLElement);
  }

  private navigateTo(params) {
    this.router.navigate([], {queryParams: params, relativeTo: this.activatedRoute})
      .then(() => {
        interval(200).pipe(take(1)).subscribe(() => {
          this.router.navigate([], {queryParams: params, relativeTo: this.activatedRoute});
        });
      });
  }

  private getAnnotationValues(unsubscribe: Subject<boolean>) {
    const subscriptions: Observable<any>[] = [];
    this.externalAnnotations$.next(!isEmpty(this.externalAnnotations) ? null : {});
    for (const aType of Object.keys(this.externalAnnotations)) {
      for (const aValue of this.externalAnnotations[aType]) {
        subscriptions.push(this.internalExchangeService.response$
          .pipe(filter(v => v.senderId === aValue.containerId), takeUntil(unsubscribe)));
      }
    }
    combineLatest(subscriptions).pipe(takeUntil(unsubscribe))
      .subscribe(value => {
        for (const aType of Object.keys(this.externalAnnotations)) {
          for (const aValue of this.externalAnnotations[aType]) {
            const obj = (value || []).find(o => o.senderId === aValue.containerId);
            if (!isEmpty(obj)) {
              aValue.values = obj.value;
            }
          }
        }
        this.externalAnnotations$.next(this.externalAnnotations);
      });
    for (const aType of Object.keys(this.externalAnnotations)) {
      for (const aValue of this.externalAnnotations[aType]) {
        this.internalExchangeService.request$.next({receiverId: aValue.containerId, requestType: REQUEST_TYPE.GET_ANNOTATION_LIST});
      }
    }
  }

  async onEdit() {
    await firstValueFrom(this.editorModeInitialized$.pipe(filter(v => !!v)));
    let unsubscribe = new Subject<boolean>();
    this.getAnnotationValues(unsubscribe);
    const dialogRef = this.dialog.open(TextEditorDialogComponent, {
      data: {
        text: this.getLanguageText(TextNode.getText(this.data), this.currentLanguage$.getValue()),
        originalText: this.getLanguageText(TextNode.getText(this.data), this.defaultLanguage$.getValue()),
        files: this.data?.files ?? [],
        externalAnnotations$: this.externalAnnotations$,
        languageParams$: this.languageParams$
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = {
            text: this.setLanguageText(TextNode.getText(this.data), result.text),
            files: result.files
          };
          TextNoteService.deleteNotUsedAudio(this.data);
        }
        unsubscribe.next(true);
        unsubscribe.complete();
        unsubscribe = null;
        return !!result;
      });
  }

  protected inputFollowMeData(value: IFollowMeData) {
    this.followMeInputData$.next(value);
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  translate() {
    const lp = this.languageParams$.getValue();
    const defText = this.getLanguageText(TextNode.getText(this.data), lp.defaultLanguage);
    return this.translateApiService.translateHTML(defText, lp.defaultLanguage, lp.currentLanguage)
      .then(tText => this.data = {
        text: this.setLanguageText(TextNode.getText(this.data), tText),
        files: this.data.files
      });
  }

  private createHotspotList() {
    const textElement: HTMLElement = this.textNoteRef.nativeElement;
    if (this.hotspotsListSubscription) {
      this.hotspotsListSubscription.unsubscribe();
    }
    if (textElement) {
      this.hotspotsList.forEach(it => it.destroy());
      this.hotspotsList = [];
      const list = textElement.getElementsByTagName(TEXT_MARK_TAGS.HOTSPOT);
      for (const item of Array.from(list) as HTMLElement[]) {
        this.hotspotsList.push(new THotspot(item, this.audioFiles, this.slidesList, this.stopAll$,
          this.internalExchangeService, this.outputFollowMeData, this.common, this.editorMode));
      }
    }
  }

  private waitLoadedHotspotsList() {
    this.loaded$.next(isEmpty(this.hotspotsList));
    this.hotspotsListSubscription = combineLatest(this.hotspotsList.map(it => it.ready$))
      .pipe(this.takeUntilAlive())
      .subscribe(list => {
        this.loaded$.next(list.every(it => !!it));
      });
  }
}

class THotspot {
  private hotspotData: IHotspotElementData = {};
  private audioElement: HTMLAudioElement;
  private audioDuration: number;
  private track: ITextNodeFile;
  private audioSubscription: Subscription;
  private unsubscribe = new Subject<boolean>();
  ready$ = new BehaviorSubject<boolean>(false);

  constructor(private hotspotElement: HTMLElement,
              private audioFiles: ITextNodeFile[],
              private slideList: ISlide[],
              private stopAll$: Subject<string>,
              private internalExchangeService: InternalContentExchangeService,
              private outputFollowMeData: EventEmitter<any>,
              private common: CommonService,
              private editorMode: boolean) {
    this.prepare();
  }

  get id() {
    return this.hotspotData.element_id;
  }

  private get audioId() {
    return this.hotspotData.audio_id;
  }

  private get containerId() {
    return this.hotspotData.container_id;
  }

  private get textId() {
    return this.hotspotData.text_annotation_id;
  }

  private get videId() {
    return this.hotspotData.video_annotation_id;
  }

  private get ready() {
    return this.ready$.getValue();
  }

  destroy() {
    this.stopAudio();
    this.audioUnsubscribe();
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this.unsubscribe = null;
  }

  private prepare() {
    this.hotspotElement.onclick = this.onClick.bind(this);
    for (const attr of Object.values(HOTSPOT_DATA_ATTRIBUTES)) {
      this.hotspotData[attr] = this.hotspotElement.dataset[attr];
    }
    if (this.audioId) {
      this.track = this.audioFiles.find(f => f.trackId === this.audioId);
      if (this.track) {
        this.audioElement = document.createElement('audio');
        this.audioElement.currentTime = 0;
        this.audioElement.onloadedmetadata = (ev) => {
          this.audioDuration = ev.target['duration'];
        };
        this.audioElement.onloadeddata = () => {
          this.ready$.next(true);
        };
        this.audioElement.src = this.track.src;
        this.audioElement.load();
      } else {
        this.ready$.next(true);
      }
    } else {
      this.ready$.next(true);
    }
    if (this.editorMode) {
      this.loadHotspotOwnerParams();
    }
  }

  private loadHotspotOwnerParams() {
    const prepIndex = (v) => (typeof v === 'number' ? v + 1 : v).toString();

    const slide = this.slideList.find(sl => sl.id === this.containerId);
    if (slide) {
      this.hotspotElement.setAttribute('data-loading', 'true');
      slide.loaded$.pipe(filter(v => !!v), take(1), takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.internalExchangeService
            .response$.pipe(filter(v => v.senderId === this.containerId), takeUntil(this.unsubscribe))
            .subscribe(response => {
              if (response) {
                this.hotspotElement.removeAttribute('data-incorrect');
                const rv = response.value;
                if (this.textId && !isEmpty(rv) &&
                  !rv.find(it => !isEmpty(it) && it.annotation_type === ANNOTATION_TYPE.TEXT &&
                    prepIndex(it.annotation_index) === this.textId)) {
                  this.hotspotElement.setAttribute('data-incorrect', 'true');
                }
                if (this.videId && !isEmpty(rv) &&
                  !rv.find(it => !isEmpty(it) && it.annotation_type === ANNOTATION_TYPE.VIDEO &&
                    prepIndex(it.annotation_index) === this.videId)) {
                  this.hotspotElement.setAttribute('data-incorrect', 'true');
                }
                this.hotspotElement.removeAttribute('data-loading');
              }
            });
          this.internalExchangeService.request$.next({
            receiverId: this.containerId,
            requestType: REQUEST_TYPE.GET_ANNOTATION_LIST
          });
        });
    }
  }

  private get isPlaying() {
    return this.hotspotElement.getAttribute(ATTR_IS_PLAYING.AUDIO) || this.hotspotElement.getAttribute(ATTR_IS_PLAYING.VIDEO);
  }

  private get hasMediaData() {
    return this.hotspotData.audio_id || this.hotspotData.video_annotation_id;
  }

  onClick(stop?: boolean) {
    if (typeof stop === 'boolean' && stop) {
      this.stop();
      this.gotoTextAnnotation();
      return;
    }
    if (this.hasMediaData) {
      if (!this.isPlaying) {
        this.stopAll$.next(this.id);
        this.playAudio();
        this.gotoAnnotationPlayStopVideoAnimation();
        this.outputFollowMeData.next({elementId: this.hotspotData.element_id, status: FOLLOW_ME_HOTSPOT_STATUS.PLAY});
      } else {
        this.stopAudio();
        this.stopVideoAnimation();
        this.outputFollowMeData.next({elementId: this.hotspotData.element_id, status: FOLLOW_ME_HOTSPOT_STATUS.STOP});
      }
    } else {
      this.stopAll$.next(this.id);
      this.gotoTextAnnotation();
      this.outputFollowMeData.next({elementId: this.hotspotData.element_id, status: FOLLOW_ME_HOTSPOT_STATUS.GOTO});
    }
  }

  stop() {
    this.stopAudio();
    this.stopVideoAnimation();
  }

  /* ~~~~~~~~~  AUDIO ~~~~~~~~~ */

  private playAudio() {
    if (this.ready && this.audioElement) {
      if (this.audioElement.paused || this.audioElement.ended) {
        this.playAudioInterval(this.audioElement,
          this.hotspotData.audio_options_start_time,
          this.hotspotData.audio_options_end_time,
          this.hotspotData.audio_options_loop,
          this.hotspotData.audio_options_speed)
          .then(() => this.setAudioPlayStopAttribute(true))
          .catch(e => {
            this.setAudioPlayStopAttribute(false);
            this.common.log.error(e);
          });
      } else {
        this.stopAudio();
      }
    }
  }

  private stopAudio() {
    if (this.ready && this.audioElement) {
      this.setAudioPlayStopAttribute(false);
      this.audioUnsubscribe();
      this.audioElement.pause();
      this.audioElement.currentTime = 0;
    }
  }

  private setAudioPlayStopAttribute(play: boolean) {
    if (play) {
      this.hotspotElement.setAttribute(ATTR_IS_PLAYING.AUDIO, 'true');
    } else {
      this.hotspotElement.removeAttribute(ATTR_IS_PLAYING.AUDIO);
    }
  }

  private playAudioInterval(player: HTMLAudioElement, start, end, loop, speed) {
    player.playbackRate = Number(speed ?? 1);
    const s = start?.toString().length ? Number(start) : 0;
    const e = end?.toString().length ? Number(end) : this.audioDuration;
    const duration = ((e * 1000) - (s * 1000)) / player.playbackRate;
    player.currentTime = Number(s);
    if (loop === MEDIA_LOOP.ONE) {
      if (e) {
        this.audioSubscription = interval(duration).pipe(take(1), takeUntil(this.unsubscribe))
          .subscribe(() => {
             this.stopAudio();
          });
      }
    } else {
      this.audioSubscription = interval(duration).pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          player.currentTime = Number(s);
        });
    }
    return player.play();
  }

  private audioUnsubscribe() {
    if (this.audioSubscription) {
      this.audioSubscription.unsubscribe();
      this.audioSubscription = null;
    }
  }

  /* ~~~~~~~~~  VIDEO/ANIMATION ~~~~~~~~~ */
  private get videoAnnotationOptions() {
    const propList = Object.keys(this.hotspotData).filter(prop => prop.includes('video') && prop.includes('options'));
    return propList.reduce((acc, prop) => {
      acc[prop] = this.hotspotData[prop];
      return acc;
    }, {});
  }

  private gotoTextAnnotation() {
    if (this.hotspotData.text_annotation_id) {
      const textAnnotationId = this.hotspotData.text_annotation_id;
      this.internalExchangeService.request$.next({
        receiverId: this.hotspotData.container_id,
        requestType: REQUEST_TYPE.GOTO_ANNOTATION,
        task: {annotationId: UtilsService.isDigitId(textAnnotationId) ? Number(textAnnotationId) - 1 : textAnnotationId}
      });
    }
  }

  private gotoAnnotationPlayStopVideoAnimation() {
    this.gotoTextAnnotation();
    if (this.hotspotData.video_annotation_id) {
      const playing = this.hotspotElement.getAttribute(ATTR_IS_PLAYING.VIDEO);
      if (!playing) {
        const videoAnnotationId = this.hotspotData.video_annotation_id;
        this.internalExchangeService.request$.next({
          receiverId: this.hotspotData.container_id,
          requestType: REQUEST_TYPE.GOTO_ANNOTATION,
          task: {
            annotationId: UtilsService.isDigitId(videoAnnotationId) ? Number(videoAnnotationId) - 1 : videoAnnotationId,
            params: this.videoAnnotationOptions,
            elementId: this.hotspotData.element_id
          }
        });
        this.hotspotElement.setAttribute(ATTR_IS_PLAYING.VIDEO, 'true');
      } else {
        this.stopVideoAnimation();
      }
    }
  }

  private stopVideoAnimation() {
    this.hotspotElement.removeAttribute(ATTR_IS_PLAYING.VIDEO);
    this.internalExchangeService.request$.next({
      receiverId: this.hotspotData.container_id,
      requestType: REQUEST_TYPE.STOP_ANIMATION,
      task: {annotationId: this.hotspotData.video_annotation_id}
    });
  }
}
