import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.scss']
})
export class LoadingProgressComponent implements OnInit {

  @Input() progressItemWidth: number | string;
  @Input() progressSize: number | string;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    const elementRef = this.elementRef.nativeElement as HTMLElement;
    if (this.progressItemWidth) {
      if (typeof this.progressItemWidth === 'string') {
        elementRef.style.setProperty('--progress-item-width', this.progressItemWidth);
      } else {
        const itemWidth = `${(this.progressItemWidth > 1 ? this.progressItemWidth / 10 : this.progressItemWidth)}em`;
        elementRef.style.setProperty('--progress-item-width', itemWidth);
      }
    }
    if (this.progressSize) {
      if (typeof this.progressSize === 'string') {
        elementRef.style.setProperty('--progress-height', this.progressSize);
        elementRef.style.setProperty('--progress-width', this.progressSize);
      } else {
        const size = `${this.progressSize}px`;
        elementRef.style.setProperty('--progress-height', size);
        elementRef.style.setProperty('--progress-width', size);
      }
    }
  }

}
