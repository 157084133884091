import { Injectable } from '@angular/core';

@Injectable()
export class QuestionTableSelectStateService {

  openStates: boolean[][] = [];

  constructor() {
    this.openStates = [];
  }

  resetSelectState() {
    this.openStates = [];
  }

  /**
   * Initializes open states based on the number of selects.
   * @param colIndex
   * @param length The number of mat-select dropdowns
   */
  initializeOpenStates(colIndex: number, length: number) {
    this.openStates.push(new Array(length).fill(false));
  }

  /**
   * Updates the open state when a mat-select is opened
   * @param colIndex
   * @param rowIndex
   */
  onSelectOpened(colIndex: number, rowIndex: number) {
    if (colIndex === undefined || rowIndex === undefined) {
      return false;
    }
    this.openStates[colIndex][rowIndex] = true;
  }

  /**
   * Updates the open state when a mat-select is closed
   * @param colIndex
   * @param rowIndex
   */
  onSelectClosed(colIndex: number, rowIndex: number) {
    this.openStates[colIndex][rowIndex] = false;
  }

  /**
   * Get the open state of a specific select
   * @returns true if the select is open, false if it's closed
   * @param colIndex
   * @param rowIndex
   */
  getOpenState(colIndex: number, rowIndex: number): boolean {
    return  this.openStates[colIndex][rowIndex];
  }
}
