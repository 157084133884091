import {Component, effect, ElementRef, Injector, OnDestroy, signal, TemplateRef, viewChild} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../reducers';
import * as ui from '../../../../../../../actions/ui';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {cloneDeep, isEmpty} from 'lodash';
import {auditTime, filter, interval, Subject, take} from 'rxjs';
import {MatTooltip} from '@angular/material/tooltip';
import {APP_MODE} from '../../../../../../../login/login.service';

interface ToolbarActionButton {
  mode: `edit` | `save`;
  show: boolean;
}

@Component({
  selector: 'app-question-open-text-participant',
  templateUrl: './question-open-text-participant.component.html',
  styleUrls: ['./question-open-text-participant.component.scss']
})
export class QuestionOpenTextParticipantComponent extends AbstractQuizQuestionParticipantComponent implements OnDestroy {

  toolbarTemplate = viewChild<TemplateRef<any>>('toolbarTemplate');
  correctTooltip = viewChild<MatTooltip>('tooltip');
  submitting = signal<boolean>(false);

  answerForm: FormGroup;
  defaultColor = '#1677FF';
  currentAnswerIdx = 0;
  allAnswers: any[] = [];
  totalAnswers = 1;
  addDisabled = false;
  disabled = false;
  canDelete = false;
  listOfIncorrectAnswers: string[] = [];
  private _toolbarActionButton: ToolbarActionButton = {mode: `save`, show: false};
  correctAnswer: string;
  userTyping$ = new Subject();

  constructor(protected injector: Injector,
    protected elementRef: ElementRef,
    private store: Store<fromRoot.State>,
    protected formBuilder: FormBuilder
  ) {
    super(injector, elementRef);
    this.answerForm = this.formBuilder.group({
      answer: ['', Validators.compose([Validators.required])],
      color: [this.defaultColor, Validators.required]
    });
    effect(() => {
      if (this.currentQuestionId() === this.qKey && this.toolbarTemplate()) {
        this.outputCustomToolbarTemplate.emit(this.toolbarTemplate());
      }
    });
  }

  get toolbarActionButton(): ToolbarActionButton {
    return this._toolbarActionButton;
  }

  set toolbarActionButton(value: ToolbarActionButton) {
    this._toolbarActionButton = value;
    this.disabled = value.mode !== `save`;
  }

  protected onInit() {
    // need for blocked global key events
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(true));
    this.submit.pipe(filter(v => this.submitting() && !v), this.takeUntilAlive())
      .subscribe(() => {
        this.submitting.set(false);
        this.toolbarActionButton = {mode: `edit`, show: this.question.options.allowEditAndSend};
      });
    this.question$.pipe(filter(() =>
        this.questionsDirectFeedbackChecked$.getValue() && !!this.correctTooltip && !this.question?.tryAgain),
      this.takeUntilAlive())
      .subscribe(() => {
        const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
        this.correctAnswer = correctEquality?.map(s => `${s.getAnswerByLanguage(this.languageParams$.getValue())}`).join(' | ');
        interval(100).pipe(take(1)).subscribe(() => {
          this.correctTooltip().show();
        });
      });
    this.questionsDirectFeedbackChecked$
      .pipe(filter((v) => !v), this.takeUntilAlive())
      .subscribe(() => {
          this.correctAnswer = '';
          this.correctTooltip().hide();
      });
    this.userTyping$.pipe(filter(() => !this.disabled), this.takeUntilAlive(), auditTime(750)).subscribe(() => this.setTyping());
  }

  onDestroy() {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(false));
  }

  protected initQuestionAnswersDataSource() {
    if (this.question.showCorrectAnswers) {
      const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
      const correctList: string[] = correctEquality.map(it => this.common.utils.valueToArray(it.answerValue)
        .map(v => this.common.utils.htmlToText(v).trim().toLowerCase()))
        .reduce((acc, it) => {
          it.forEach(v => acc.push(v));
          return acc;
        }, []);
      const answers = this.answers.a ?? [];
      this.listOfIncorrectAnswers = [];
      if (isEmpty(answers)) {
        return;
      }
      for (const answer of answers) {
        if (!correctList.includes(this.common.utils.htmlToText(answer.a).trim().toLowerCase())) {
          this.listOfIncorrectAnswers.push(answer.a);
        }
      }
    } else {
      this.listOfIncorrectAnswers = [];
    }
  }

  protected onReceiveQuestionAnswers() {
    if (!isEmpty(this.answers?.a)) {
      this.allAnswers = cloneDeep(this.answers?.a);
    } else {
      this.allAnswers = [{
        a: '',
        c: this.defaultColor,
        new: true
      }];
    }
    this.onChangeAnswers();
  }

  private onChangeAnswers() {
    const val = this.allAnswers[this.currentAnswerIdx];
    this.totalAnswers = this.allAnswers.length;
    this.addDisabled = this.allAnswers.some(v => v.new);
    this.canDelete = (this.allAnswers.length > 1 && val.new) ||
      this.allAnswers.some((v, idx) => !v.new && idx !== this.currentAnswerIdx);
    this.answerForm = new FormGroup({ answer: new FormControl(val.a), color: new FormControl(val.c) });
    if (val.new) {
      this.toolbarActionButton = {mode: `save`, show: true};
    } else {
      this.toolbarActionButton = {mode: `edit`, show: this.question.options.allowEditAndSend};
    }
  }

  onAdd() {
    this.allAnswers = [...this.allAnswers, { a: '', c: this.defaultColor, new: true}];
    this.currentAnswerIdx = this.allAnswers.length - 1;
    this.onChangeAnswers();
  }

  onDelete() {
    if (this.allAnswers.length === 1 && this.allAnswers[0].new) {
      this.allAnswers = [{
        a: '',
        c: this.defaultColor,
        new: true
      }];
      return;
    }
    if (this.allAnswers[this.currentAnswerIdx].new) {
      this.allAnswers = this.allAnswers.filter((v, idx) => idx !== this.currentAnswerIdx);
      this.currentAnswerIdx = this.currentAnswerIdx - 1;
      this.onChangeAnswers();
      return;
    }
    const newArr = this.allAnswers.filter((v, idx) => idx !== this.currentAnswerIdx);
    this.answers = { a: newArr.filter(v => v.a) };
    this.submitting.set(true);
    if (this.currentAnswerIdx) {
      this.currentAnswerIdx = this.currentAnswerIdx - 1;
    }
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  onNext() {
    this.currentAnswerIdx = this.currentAnswerIdx + 1;
    this.onChangeAnswers();
  }

  onPrevious() {
    this.currentAnswerIdx = this.currentAnswerIdx - 1;
    this.onChangeAnswers();
  }

  sendAnswer() {
    if (!this.acceptAnswers) {
      return;
    }
    this.toolbarActionButton = { ...this.toolbarActionButton, show: false };
    const formModel = cloneDeep(this.answerForm.value);
    const answers = cloneDeep(this.allAnswers);
    answers[this.currentAnswerIdx] = { a: formModel.answer, c: formModel.color, d: new Date().toISOString()};
    this.answers = { a: answers };
    this.submitting.set(true);
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  edit() {
    this.toolbarActionButton = { ...this.toolbarActionButton, mode: `save`};
  }

  save() {
    this.sendAnswer();
  }

  async setTyping() {
    if (this.contentLocation === APP_MODE.EXAMS) {
      return;
    }
    await this.quizService.setTyping(this.documentPathParams, this.question.id);
  }
}
