import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {cloneDeep, isEmpty} from 'lodash';
import {CommonService} from '../../../../../core/common.service';
import {StdComponent} from '../../../../../core/std-component';
import {Constants, TRIPLE} from '../../../../../core/constants';
import {UtilsService} from '../../../../../core/utils.service';

@Component({
  selector: 'app-easyzoom-document-editor-dialog',
  templateUrl: './easyzoom-document-editor-dialog.component.html',
  styleUrls: ['./easyzoom-document-editor-dialog.component.scss']
})
export class EasyzoomDocumentEditorDialogComponent extends StdComponent implements OnInit {

  editDialogData: any;
  private _easyZoomUrl: string;
  dataHash: string;
  isModify = false;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<EasyzoomDocumentEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private common: CommonService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this._easyZoomUrl = cloneDeep(data);
    this.dataHash = UtilsService.md5(this._easyZoomUrl);
    this.editDialogData = {
      title: this.common.utils.i18n('text.editor.dialog.edit.easy.zoom.url')
      , fields: [
        {id: 'easyZoomUrl', type: 'text', required: false, showClearButton: true},
      ]
      , result: {easyZoomUrl: isEmpty(this.easyZoomUrl) ? null : this.easyZoomUrl}
      , withDelete: false
    };
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get easyZoomUrl(): string {
    return this._easyZoomUrl;
  }

  set easyZoomUrl(value: string) {
    this._easyZoomUrl = value;
    this.isModify = this.dataHash !== UtilsService.md5(value);
  }

  ngOnInit(): void {
  }

  onOkClick(): void {
    this.dialogRef.close(this.easyZoomUrl);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
