import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {AnswerEquality, EventQuestion, IGapAnswerData} from '../../../../../../../model/EventQuestion';
import {ILanguageParams} from '../../../../../../../core/constants';
import {isEmpty} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';

@Component({
  selector: 'app-question-text-gap-filling-editor',
  templateUrl: './question-text-gap-filling-editor.component.html',
  styleUrls: ['./question-text-gap-filling-editor.component.scss']
})
export class QuestionTextGapFillingEditorComponent extends AbstractQuizQuestionAnswersEditorComponent  {

  gapFillingText$ = new BehaviorSubject<string>(null);
  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);

  constructor(protected injector: Injector,
              public changeDetector: ChangeDetectorRef) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.gapFillingText$.next(this.question.getTaskTextByLanguage(this.languageParams));
    this.mapToGapAnswersData();
  }

  private mapToGapAnswersData() {
    this.gapAnswersData$
      .next((this.question.correctEquality || []).map(v => new Object({id: v.id, answerId: v.answerId,
        answerValue: this.question.items.find(a => a.id === v.answerId)?.getAnswerByLanguage(this.languageParams)}) as IGapAnswerData));
  }

  onGapFillingTextChange(value: string) {
    this.question.taskText = value;
  }

  onGapAnswersDataChange(value: IGapAnswerData[]) {
    this.question.correctEquality = (value || []).map(o => new AnswerEquality({id: o.id, answerId: o.answerId}));
  }

  isUsedInGap(answerId: string) {
    return !!(this.question.correctEquality || []).find(o => o.answerId === answerId);
  }

  validate(): IValidated {
    return !isEmpty(this.dataSource.data) && !this.dataSource.data.some(o => isEmptyCaption(o.answer)) &&
    !this.question.correctEquality.some(o => !o.answerId) ?
      {validated: true} : {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }
}
