import { Injectable } from '@angular/core';
import {LoggerService} from './logger.service';
import {Store} from '@ngrx/store';
import * as ui from '../actions/ui';
import * as fromRoot from '../reducers';
import {BehaviorSubject, Subject} from 'rxjs';


@Injectable()
export class LoadingService {
  private loadCount = 0;
  private loading = false;
  _queue = {};
  loadingQueue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  loadingComplete: Subject<boolean> = new Subject<boolean>();

  constructor(private logger: LoggerService
              , private store: Store<fromRoot.State>
  ) { }

  public startLoading(key: string) {
    this._queue[key] = true;
  }

  public endLoading(key: string) {
    if (this._queue[key]) {
      delete this._queue[key];
      if (Object.keys(this._queue).length === 0) {
        this.loadingComplete.next(true);
      }
    }
  }

  public finishLoading() {
    this._queue = {};
    this.loadingComplete.next(true);
  }

  public loadBegin() {
    this.loadCount++;
    this.logger.debug('loadBegin', this.loadCount);
    const oldValue = this.loading;
    this.loading = true;
    if (oldValue !== this.loading) {
      this.onChange();
    }
  }

  public loadEnd() {
    this.loadCount--;
    this.logger.debug('loadEnd', this.loadCount);
    if (this.loadCount < 0) this.loadCount = 0;
    if (this.loadCount === 0) {
      this.loading = false;
      this.onChange();
    }
  }

  public loadFinish() {
    this.logger.debug('loadFinish', this.loadCount);
    this.loadCount = 0;
    this.loading = false;
    this.onChange();
  }

  public onChange() {
    this.logger.debug('SetLoadingAction', this.loading);
    this.store.dispatch(new ui.SetLoadingAction(this.loading));
  }

}
