import {firstValueFrom} from 'rxjs';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import {AngularFireDatabase} from '@angular/fire/compat/database';

export function updateOnlineStatistics(afs: AngularFirestore, afDB: AngularFireDatabase, clientId: string) {
  return firstValueFrom(afs.collection('client_data').doc(clientId)
    .collection('events')
    .get())
    .then(async (snapshot: QuerySnapshot<any>) => {
      let eventsLength = 0;
      let counter = 0;
      const events = snapshot.docs.filter(d => !d.data().deleted);
      eventsLength = events.length;
      for (const event of events) {
        console.log('process ', ++counter, ' of ', eventsLength);
        await firstValueFrom(afs
          .collection('client_data').doc(clientId)
          .collection('conference').doc(event.id)
          .collection('users_online').get())
          .then((usersSnapshot: QuerySnapshot<any>) => {
            const ref = afDB.database
              .ref(`/client_data/${clientId}/events_activity/${event.id}/participants`);
            return ref.set(usersSnapshot.size);
          });
      }
    });
}

export function updateRegisteredStatistics(afs: AngularFirestore, afDB: AngularFireDatabase, clientId: string) {
  return firstValueFrom(afs.collection('client_data').doc(clientId)
    .collection('events')
    .get())
    .then(async (snapshot: QuerySnapshot<any>) => {
      let eventsLength = 0;
      let counter = 0;
      const events = snapshot.docs.filter(d => !d.data().deleted);
      eventsLength = events.length;
      for (const event of events) {
        console.log('process ', ++counter, ' of ', eventsLength);
        await firstValueFrom(afs
          .collection('client_data').doc(clientId)
          .collection('conference').doc(event.id)
          .collection('user_registration').get())
          .then((usersSnapshot: QuerySnapshot<any>) => {
            let registered = 0;
            for (const doc of usersSnapshot.docs) {
              const sections = doc.get('sections');
              if (sections) {
                const hasRegistration = Object.values(sections).some((it: any) => it.status === 'registration.completed');
                if (hasRegistration) {
                  registered++;
                }
              }
            }
            const ref = afDB.database
              .ref(`/client_data/${clientId}/events_activity/${event.id}/registered`);
            return ref.set(registered);
          });
      }
    });
}
