import {CommonModule} from '@angular/common';
import {Component, Input, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-form-input',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, TranslateModule],
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() innerLabel: string;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() customErrors: { [key: string]: string };
  @Input() errorTriggered: string;
  @Input() forceErrorLabel: string;
  @Input() disable: boolean;
  @Input() reducedSize: boolean;

  private defaultErrors: { [key: string]: string } = {
    required: this.translateService.instant('form.error.required'),
  };

  content: string;
  touched: boolean;
  onChange = (content: string): void => {};
  onTouched = (): void => {};

  get errors(): string[] {
    if ((this.touched || this.control.touched) && this.control.errors) {
      return Object.keys(this.control.errors);
    }
  }

  get errorLabels(): string[] {
    const formErrors = this.errors?.length
      ? this.errors.map(
        (error) => this.customErrors?.[error] || this.defaultErrors?.[error]
      )
      : [];

      if (this.forceErrorLabel) {
        formErrors.push(this.forceErrorLabel);
      }

      if (this.errorTriggered) {
      return [this.errorTriggered, ...formErrors];
    }
    return formErrors;
  }

  constructor(@Self() @Optional() private control: NgControl,  private translateService: TranslateService) {
    this.control.valueAccessor = this;
  }

  writeValue(content: string): void {
    this.errorTriggered = null;
    this.content = content;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  changeValue(value: string): void {
    this.markAsTouched();
    this.onChange(value);
    this.errorTriggered = null;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
