import {ILanguageParams, TCaption} from '../../../../../../../../core/constants';
import {UtilsService} from '../../../../../../../../core/utils.service';

export interface ITablePoll {
  tableColumns: TableColumn[];
}

export class TableColumn {
  id: string = this.genId();
  type: IColumnType | ISelectMenuType | IUploadFileType;
  tableRows: Partial<ExtTableRow>[];
  isMainColumn: boolean;
  orderIndex: number;

  constructor(obj?: any) {
    for (const name of Object.keys(obj ?? {})) {
      if (this.classFieldsNames().includes(name)) {
        this[name] = obj[name];
      }
    }
  }

  protected classFieldsNames(): string[] {
    return [
      'id',
      'type',
      'tableRows',
      'isMainColumn',
      'orderIndex'
    ];
  }

  genId(): string {
    return 'COL' + UtilsService.createId();
  }

  getColumnNameByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this.type, 'columnName', languageParams);
  }

  setColumnNameByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this.type, 'columnName', value, languageParams);
  }

  toObject() {
    return UtilsService.classToObject(this);
  }
}

export class TableRowBase {
  id: string = this.genId();
  orderIndex: number;
  answer: TCaption = null; // possible answer options. set when created. similar to the answers field in the AnswerQuestion class

  constructor(obj?: any) {
    for (const name of Object.keys(obj ?? {})) {
      if (this.classFieldsNames().includes(name)) {
        this[name] = obj[name];
      }
    }
  }

  protected classFieldsNames(): string[] {
    return [
      'id',
      'orderIndex',
      'answer',
    ];
  }

  genId(): string {
    return 'ROW' + UtilsService.createId();
  }

  getAnswerByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'answer', languageParams);
  }

  setAnswerByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'answer', value, languageParams);
  }

  toObject() {
    return UtilsService.classToObject(this);
  }

}

export class ExtTableRow extends TableRowBase {
   constructor(object, public languageParams: ILanguageParams) {
      super(object);
    }
    get rowCaption(): string | undefined {
      return this.getAnswerByLanguage(this.languageParams);
    }
    set rowCaption(value: string | undefined) {
      this.setAnswerByLanguage(value, this.languageParams);
    }
}

export interface IColumnType {
  columnName: TCaption;
  inputType: TABLE_COLUMN_TYPE;
  isCorrectAnswerEnabled?: boolean;
}

export interface ISelectMenuType extends IColumnType {
  options: ISelectMenuOptions[];
}

export interface ISelectMenuOptions {
  id: string;
  option: TCaption;
  orderIndex: number;
}

export interface IUploadFileType extends IColumnType  {
  selectedTypes: IUploadSelectedFileTypes;
}

export interface IUploadSelectedFileTypes {
    images: string[];
    audios: string[];
    videos: string[];
    documentTypes: string[];
    others: string[];
}

export enum TABLE_COLUMN_TYPE {
  DROPDOWN = 'dropdown',
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  FILE = 'file'
}

export interface ITableGridColumn {
  name: string;
  width: string;
  id?: string;
  columnName?: TCaption;
  type?: TABLE_COLUMN_TYPE;
  isCorrectAnswerEnabled?: boolean;
  selectedTypes?: IUploadSelectedFileTypes;
  options?: ISelectMenuOptions[];
}

export const TABLE_COLUMNS_WIDTH = {
  [TABLE_COLUMN_TYPE.DROPDOWN]: 'minmax(300px, 1fr)',
  [TABLE_COLUMN_TYPE.CHECKBOX]: 'minmax(200px, 1fr)',
  [TABLE_COLUMN_TYPE.TEXT]: 'minmax(300px, 1fr)',
  [TABLE_COLUMN_TYPE.FILE]: 'minmax(200px, 1fr)'
};

export const BASE_TABLE_GRID_COLUMNS: ITableGridColumn[] = [
   {name: 'answer', width: 'minmax(400px, 1fr)'},
];

export interface IColumnInputType {
  name: TABLE_COLUMN_TYPE;
  label: string;
  icon: string;
}

export const COLUMN_INPUT_TYPES: IColumnInputType[] = [
  {
    label: 'questionnaire.table.editor.new.interaction.opentext',
    name: TABLE_COLUMN_TYPE.TEXT,
    icon: 'ti ti-forms'
  },
  {
    label: 'questionnaire.table.editor.new.interaction.select.menu',
    name: TABLE_COLUMN_TYPE.DROPDOWN,
    icon: 'ti ti-select'
  },
  {
    label: 'questionnaire.table.editor.new.interaction.upload.file',
    name: TABLE_COLUMN_TYPE.FILE,
    icon: 'ti ti-upload'
  },
  {
    label: 'questionnaire.table.editor.new.interaction.checkbox',
    name: TABLE_COLUMN_TYPE.CHECKBOX,
    icon: 'ti ti-checkbox'
  },
];

export interface IOption {
  id: string;
  option: TCaption;
  correctAnswers: string[];
  orderIndex: number;
}

export interface ICorrectTextOption {
  id?: string; // not need save to database
  option: TCaption;
  rows: string[];
  orderIndex: number;
}
