import {Component, Inject, OnInit} from '@angular/core';
import {IPrintParams} from '../../../event-mode/event-mode/event-mode.component';
import {UtilsService} from '../../../core/utils.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SectionTimeline} from '../../../model/content/SectionTimeline';
import {ExportPrintSelectSectionDialogComponent} from '../export-print-select-section-dialog/export-print-select-section-dialog.component';
import {TimeLineService} from '../../../services/time-line.service';
import {BehaviorSubject, finalize, take} from 'rxjs';
import {ModulabApiService} from '../../../services/modulab-api.service';
import {CommonService} from '../../../core/common.service';

@Component({
  selector: 'app-export-print-dialog',
  templateUrl: './export-print-dialog.component.html',
  styleUrls: ['./export-print-dialog.component.scss']
})
export class ExportPrintDialogComponent implements OnInit {

  isSuperUser: boolean;
  showProgress = new BehaviorSubject<boolean>(false);
  sections: SectionTimeline[] = [];
  printType = 0;
  contentType = 0;
  event: any;
  result: IPrintParams = {
    sections: [],

    slides: true,
    slidesFirst: false,
    slidePage: false,

    polls: true,
    myAnswers: true,
    summarizeAnswers: true,
    correctAnswers: true,

    pdf: true,
    notes: true,
    social: true,
    feedbackRating: false
  };

  constructor(public dialogRef: MatDialogRef<ExportPrintDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public dialog: MatDialog
    , public utils: UtilsService
    , public common: CommonService
    , public timelineService: TimeLineService
    , private modulabApiService: ModulabApiService
  ) {
    dialogRef.addPanelClass('timeline');
    this.isSuperUser = data.isSuperUser;
    this.event = data.event;
    const sections = this.timelineService.getPlaneListContentAsSectionArray()
      .filter(it => !it.isRoot && this.timelineService.sectionLevel(it) <= 1 && !it.fixedSectionType);
    this.sections = sections ? sections : [];
  }

  ngOnInit() {
  }

  selectSections(e) {
    if (e.value === 0) {
      this.result.sections = [];
    }
  }

  showSelectSectionsDialog() {
    const selected = this.result.sections && this.result.sections.length ? this.result.sections : [];
    const dialogRef = this.dialog.open(ExportPrintSelectSectionDialogComponent, {
      disableClose: true,
      data: {
        isSuperUser: this.isSuperUser,
        sections: this.sections,
        selected: selected
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.result.sections = result;
      }
    });
  }

  exportXML() {
    const payload = {
      eventId: this.event?.eventId
    };
    this.showProgress.next(true);

    this.modulabApiService.exportXML(payload)
      .pipe(
        finalize(() => this.showProgress.next(false)),
      )
      .subscribe(data => {
      const blob = new Blob([data], {type: 'application/zip'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'export' + this.event?.eventId + '_file.zip';
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }
}
