import {Quiz} from '../../../quiz-model/quiz';
import {EventQuestion, IGroupCorrectAnswers} from '../../../../../../../model/EventQuestion';
import {QUESTION_TYPE} from '../quiz-quiestion-types';
import {cloneDeep, isEmpty} from 'lodash';
import {UtilsService} from '../../../../../../../core/utils.service';

const genGroupId = () => 'GR' + UtilsService.createId();

const createCheckChoiceGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  if (!question.useCorrectAnswers) {
    return null;
  }
  const group: IGroupCorrectAnswers = {id: genGroupId(), items: [], defaultGroup: true};
  if (!isEmpty(question.items) && !question.items.every(it => !it.correctAnswer)) {
    for (const answer of question.items) {
      if (answer.correctAnswer) {
        group.items.push({id: answer.id, correctAnswer: true} as any);
      }
    }
    return [group];
  }
  return null;
};

const createMatchingGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  const group: IGroupCorrectAnswers = {id: genGroupId(), items: [], defaultGroup: true};
  if (!isEmpty(question.items) && !question.items.every(it => isEmpty(it.matching))) {
    for (const answer of question.items) {
      group.items.push({id: answer.id, matching: cloneDeep(answer.matching)} as any);
    }
    return [group];
  }
  return null;
};

const createTextGapGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  const group: IGroupCorrectAnswers = {id: genGroupId(), correctEquality: [], defaultGroup: true};
  if (!isEmpty(question.correctEquality)) {
    group.correctEquality = question.correctEquality
      .map(ce => new Object({
        id: ce.id,
        answerId: ce.answerId,
        answerValue: question.items.find(a => a.id === ce.answerId).answer
      }) as any);
    return [group];
  }
  return null;
};

const createSelectGapGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  const group: IGroupCorrectAnswers = {id: genGroupId(), correctEquality: [], defaultGroup: true};
  if (!isEmpty(question.correctEquality)) {
    group.correctEquality = cloneDeep(question.correctEquality);
    return [group];
  }
  return null;
};

const createCheckboxGapGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  const group: IGroupCorrectAnswers = {id: genGroupId(), correctEquality: [], defaultGroup: true};
  if (!isEmpty(question.correctEquality)) {
    question.relations = question.correctEquality.map(ce => new Object({id: ce.id, answerId: ce.answerId}) as any);
    group.correctEquality = cloneDeep(question.correctEquality);
    return [group];
  }
  return null;
};

const createMapGapGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  const group: IGroupCorrectAnswers = {id: genGroupId(), correctEquality: [], defaultGroup: true};
  if (!isEmpty(question.correctEquality)) {
    question.relations = question.correctEquality.map(ce => new Object({id: ce.id, mapPosition: ce.mapPosition ?? null}) as any);
    group.correctEquality = cloneDeep(question.correctEquality);
    return [group];
  }
  return null;
};

const createTextGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  if (!question.useCorrectAnswers) {
    return null;
  }
  const group: IGroupCorrectAnswers = {id: genGroupId(), correctEquality: [], defaultGroup: true};
  if (!isEmpty(question.items)) {
    group.correctEquality = question.items.map(it => new Object({id: it.id, answerValue: it.answer}) as any);
    return [group];
  }
  return null;
};

const createSequenceGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  const group: IGroupCorrectAnswers = {id: genGroupId(), correctEquality: [], defaultGroup: true};
  if (!isEmpty(question.items)) {
    group.items = question.items.map(a => new Object({id: a.id}) as any);
    return [group];
  }
  return null;
};

export const createCompatibleQuestionGroup = (question: EventQuestion): IGroupCorrectAnswers[] => {
  if (!question || question.questionFormatVersion) {
    return null;
  }
  const questionType: QUESTION_TYPE = question.storypoint;
  switch (questionType) {
    case QUESTION_TYPE.CHECK:
    case QUESTION_TYPE.CHOICE:
    case QUESTION_TYPE.CHECK_V2:
    case QUESTION_TYPE.CHOICE_V2:
      return createCheckChoiceGroup(question);
    case QUESTION_TYPE.MATCHING:
      return createMatchingGroup(question);
    case QUESTION_TYPE.TEXT_GAP_FILLING:
      return createTextGapGroup(question);
    case QUESTION_TYPE.SELECT_GAP_FILLING:
      return createSelectGapGroup(question);
    case QUESTION_TYPE.CHECKBOX_GAP_FILLING:
      return createCheckboxGapGroup(question);
    case QUESTION_TYPE.MATCHING_MAP:
      return createMapGapGroup(question);
    case QUESTION_TYPE.TEXT:
      return createTextGroup(question);
    case QUESTION_TYPE.SEQUENCE:
      return createSequenceGroup(question);
  }
  return null;
};

export const createCompatibleQuizQuestionGroupsFormat = (quiz: Quiz) => {
  for (const qId of Object.keys(quiz.questions)) {
    const groups = createCompatibleQuestionGroup(quiz.questions[qId]);
    if (!isEmpty(groups)) {
      quiz.questions[qId].groupsCorrectAnswers = groups;
    }
  }
};

