export interface ILoginProvider {
  id: string;
  title: string;
  customDomain: string;
  additional: boolean;
}

export class ClientConfig {

  id: string;
  links: string[];
  domains: string[];
  providers: ILoginProvider[];
  name: string;
  email: string;
  description: string;
  contact: string;
  logo: string;
  logo_inverted: string;
  background: string;
  defaultClient: boolean;
  externalUsers: boolean;
  selfRegistration: boolean;
  registrationCode: string;
  domainsAllowed: string;
  domainsAllowedOnly: boolean;
  cached = false;
  language: string;
  contactTitle: string;
  contactLink: string;
  clientTermsAndPrivacy: string;
  usefulLinks: {title: string; link: string}[];
  features: {[name: string]: any};
  customTerms = false;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
  }

  toObject() {
    return {...this};
  }
}
