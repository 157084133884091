import {Page} from './Page';
import {MEETING_VIEW_MODE, PAGES} from '../core/constants';

export interface UI {
  menuOpen: boolean;
  currentPage: Page;
  loading: boolean;
  presentationMode: boolean;
  presentationModeKeyAction: string;
  modalDialogOpen: boolean;
  manageTimeOpen: boolean;
  eventSettingsOpenPage: PAGES;
  userInputAnswerOnTextQuestion: boolean;
  meetingViewMode: MEETING_VIEW_MODE;
  fullScreenMode: boolean;
  editorMode: boolean;
}

export const defaults: UI = {
  menuOpen: false
  , currentPage: null
  , loading: false
  , presentationMode: true
  , presentationModeKeyAction: null
  , modalDialogOpen: false
  , manageTimeOpen: false
  , eventSettingsOpenPage: null
  , userInputAnswerOnTextQuestion: false
  , meetingViewMode: null
  , fullScreenMode: false
  , editorMode: false
};
