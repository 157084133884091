import {Component, inject, Injector} from '@angular/core';
import {Constants, ILanguageParams} from '../../../../../../../core/constants';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {pairwise, startWith} from 'rxjs/operators';
import {UploadFileSettingsDialogComponent} from './new-interaction/upload-file-settings-dialog/upload-file-settings-dialog.component';
import {CheckboxSettingsDialogComponent} from './new-interaction/checkbox-settings-dialog/checkbox-settings-dialog.component';
import {SelectMenuSettingsDialogComponent} from './new-interaction/select-menu-settings-dialog/select-menu-settings-dialog.component';
import {
  COLUMN_INPUT_TYPES,
  ExtTableRow,
  IColumnType, ISelectMenuType,
  ITablePoll,
  IUploadFileType,
  TABLE_COLUMN_TYPE,
  TableColumn,
  TableRowBase
} from './table-poll-model/TablePollModel';
import {QuestionTableFormUtilsService} from '../question-table-shared/question-table-form-utils.service';
import {OpenTextDialogComponent} from './new-interaction/open-text-dialog/open-text-dialog.component';
import {BehaviorSubject, debounceTime, filter, firstValueFrom} from 'rxjs';
import {cloneDeep, isEmpty} from 'lodash';
import {
  getColumnGroupCorrectAnswers,
  getGroupCorrectAnswers,
  mergeColumnGroupCorrectAnswers
} from '../question-table-shared/question-table-utils';


@Component({
  selector: 'app-question-table-editor',
  templateUrl: './question-table-editor.component.html',
  styleUrl: './question-table-editor.component.scss'
})
export class QuestionTableEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  readonly COLUMN_INPUT_TYPES = COLUMN_INPUT_TYPES;
  readonly TABLE_COLUMN_TYPE = TABLE_COLUMN_TYPE;
  readonly column_zero_name: string = 'Rows';
  readonly column_zero_type: string = TABLE_COLUMN_TYPE.TEXT;
  public readonly fb = inject(FormBuilder);
  protected formUtilsService = inject(QuestionTableFormUtilsService);
  private horizontalScroll$ = new BehaviorSubject<number>(null);
  private horizontalScrollBlock = false;

  hoveredColumn: number | null = null; // Track the hovered column
  hoveredRow: number | null = null; // Track the hovered column
  gridForm!: FormGroup;
  questions: { id: string, answer: object, orderIndex: number }[] = [];
  currentRowIndex: number | null = null; // Track the current row index
  currentColIndex: number | null = null; // Track the current row index
  selectedRow: FormControl;

  constructor(protected injector: Injector) {
    super(injector);
  }

  get questionOptions(): ITablePoll {
    return super.questionOptions;
  }

  get grid(): FormArray {
    return this.gridForm.get('grid') as FormArray;
  }

  getColumn(index: number): FormGroup {
    return this.grid.at(index) as FormGroup;
  }

  getRowsForColumn(columnIndex: number): FormArray {
    return this.getColumn(columnIndex).get('tableRows') as FormArray;
  }

  initializeData(isEditMode) {
    this.gridForm = this.fb.group({
      grid: this.fb.array([])
    });
    if (isEditMode) {
      const tableColumns = this.questionOptions.tableColumns;
      tableColumns.forEach((column, colIdx) => {
        const columnGroup = this.formUtilsService.createColumnFormGroup(this, column);
        // Add rows dynamically to this column
        column.tableRows?.forEach(row => {
          (columnGroup.get('tableRows') as FormArray).push(this.createRow(row));
        });
        this.grid.push(columnGroup);
      });
    } else {
      this.addColumn(new TableColumn({
        isMainColumn: true,
        type: {
          columnName: this.column_zero_name,
          inputType: this.column_zero_type
        },
        tableRows: [new TableRowBase()]
      }), false);
    }
  }

  addColumn(column: TableColumn, isCreateMode: boolean = true): void {
    column.orderIndex = this.genAnswerOrderIndex();
    const columnGroup = this.formUtilsService.createColumnFormGroup(this, column);
    if (isCreateMode) {
      this.grid.value[0].tableRows?.forEach(row => {
        (columnGroup.get('tableRows') as FormArray).push(this.createRow(null));
      });
    } else {
      // Add rows dynamically to this column
      column.tableRows?.forEach(row => {
        (columnGroup.get('tableRows') as FormArray).push(this.createRow(row));
      });
    }
    this.grid.push(columnGroup);
  }

  // Helper function to create a row form group
  createRow(row: Partial<ExtTableRow>): FormGroup {
    const rowQuestion = new ExtTableRow(new TableRowBase(row), this.languageParams);
    if (!row?.orderIndex) {
      rowQuestion.orderIndex = this.genAnswerOrderIndex();
    }
    return this.fb.group({
      formControlAnswerValue: [rowQuestion.getAnswerByLanguage(this.languageParams), Validators.required],
      id: [rowQuestion.id || ''],
      orderIndex: [rowQuestion.orderIndex || ''],
      answer: [rowQuestion.answer]
    });
  }

  private getUseCorrectAnswers() {
    return (this.questionOptions?.tableColumns ?? []).some(cl => cl.type.isCorrectAnswerEnabled);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.horizontalScroll$.pipe(debounceTime(50), this.takeUntilAlive())
      .subscribe(() => {
        this.horizontalScrollBlock = false;
      });
    this.languageParams$.pipe(filter(() => !!this.gridForm), this.takeUntilAlive())
      .subscribe(() => this.updateValuesByLanguage());
    this.initializeData(!!this.questionOptions?.tableColumns?.length);
    this.grid.valueChanges.pipe(this.takeUntilAlive()).subscribe(() => {
      this.updateQuestionOptions();
    });
    // Subscribe to individual FormControl changes inside FormArray
    this.handleFormValueChanges();
  }

  private handleFormValueChanges() {
    this.grid.controls.forEach((colControl, index) => {
      const tableRows = colControl.get('tableRows') as FormArray;
      // Store subscriptions to avoid duplicatesx
      const subscriptions = new WeakMap();
      tableRows.controls.forEach((rowControl, itemIndex) => {
        if (!subscriptions.has(rowControl)) {
          //  Add valueChanges subscription for each row
          const sub1 = rowControl.valueChanges.pipe(startWith(rowControl.value), pairwise(), this.takeUntilAlive())
            .subscribe(([prev, curr]) => {
              this.formUtilsService.setAnswerByLanguage(curr, curr.formControlAnswerValue, this.languageParams);
            });
          subscriptions.set(rowControl, sub1);
        }
      });
      // Detect new rows added dynamically
      tableRows.valueChanges.pipe(this.takeUntilAlive())
        .subscribe(() => {
          tableRows.controls.forEach((rowControl, itemIndex) => {
            if (!subscriptions.has(rowControl)) {
              const sub2 = rowControl.valueChanges.pipe(startWith(rowControl.value), pairwise()).subscribe(([prev, curr]) => {
                this.formUtilsService.setAnswerByLanguage(curr, curr.formControlAnswerValue, this.languageParams);
              });
              subscriptions.set(rowControl, sub2);
            }
          });
        });
    });
  }

  updateValuesByLanguage() {
    const rowsForColumZero: FormArray =  this.getRowsForColumn(0);
    rowsForColumZero.controls.forEach((rowControl, itemIndex) => {
      const rowValueCopy = { ...rowControl.value }; // Shallow copy
      const answerTranslated = this.formUtilsService.getAnswerByLanguage(rowValueCopy, this.languageParams);
      rowControl.patchValue({
        formControlAnswerValue: answerTranslated,
        // Preserve existing answer
        answer: rowControl.value.answer});
    });
    this.grid.controls.filter(c => !c.value.isMainColumn).forEach(c => c.patchValue(cloneDeep(c.value)));
  }

  validate(): IValidated {
    if (this.grid.controls.length < 2) {
      return { validated: false,
        warning: this.common.i18n('questionnaire.table.editor.settings.select.menu.error.mgs.minimum.column.size') };
    }
    return { validated: true };
  }

  openNewInteractionDialog(colIndex: number,  type: TABLE_COLUMN_TYPE): void {
    this.questions = this.grid.value.filter(col => col.isMainColumn).flatMap((col: TableColumn) => {
      return col.tableRows?.map((row: ExtTableRow) => {
        return{ id: row.id, answer: row.answer, orderIndex: row.orderIndex};
      }).sort(this.common.utils.comparator(Constants.ORDERINDEX));
    });
    switch (type) {
      case TABLE_COLUMN_TYPE.FILE:
        return this.openFieUploadDialog(colIndex, TABLE_COLUMN_TYPE.FILE);
      case TABLE_COLUMN_TYPE.CHECKBOX:
        return this.openCheckboxDialog(colIndex, TABLE_COLUMN_TYPE.CHECKBOX);
      case TABLE_COLUMN_TYPE.DROPDOWN:
        return this.openSelectMenuDialog(colIndex, TABLE_COLUMN_TYPE.DROPDOWN);
      case TABLE_COLUMN_TYPE.TEXT:
        return this.openTextDialog(colIndex, type);
    }
  }

  private openTextDialog(colIndex: number, type: string): void {
    const dialogRef = this.dialog.open(OpenTextDialogComponent, {
      data: {
        callerCtx: this,
        formGroup: colIndex > 0 ? this.getColumn(colIndex) : null,
        questions: this.questions,
        groupsCorrectAnswers: this.question.groupsCorrectAnswers ?? []
      },
      minWidth: 'min-content',
      panelClass: 'timeline'
    });
    firstValueFrom(dialogRef.afterClosed()).then((data: {id, columnType, groupsCorrectAnswers}) => {
      if (data && data.columnType.columnName) {
        const typeValue = {
          columnName: data.columnType.columnName,
          inputType: data.columnType.inputType ?? type,
          isCorrectAnswerEnabled: data.columnType.isCorrectAnswerEnabled
        };
        mergeColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, data.groupsCorrectAnswers, data.id, TABLE_COLUMN_TYPE.TEXT);
        if (colIndex > 0) {
          const columnGroup = this.getColumn(colIndex);
          columnGroup.patchValue({type: typeValue});
        } else {
          this.addColumn(new TableColumn({id: data.id, type: typeValue}));
        }
      }
    });
  }

  openSelectMenuDialog(colIndex: number, type: string): void {
    const dialogRef = this.dialog.open(SelectMenuSettingsDialogComponent, {
      data: {
        callerCtx: this,
        formGroup: colIndex > 0 ? this.getColumn(colIndex) : null,
        questions: this.questions,
        groupsCorrectAnswers: this.question.groupsCorrectAnswers ?? []
      },
      minWidth: 'min-content',
      panelClass: 'timeline'
    });
    firstValueFrom(dialogRef.afterClosed()).then((data: {id, columnType, groupsCorrectAnswers}) => {
      if (data && data.columnType.columnName) {
        const typeValue = {
          columnName: data.columnType.columnName,
          inputType: data.columnType.inputType ?? type,
          options: data.columnType.options,
          isCorrectAnswerEnabled: data.columnType.isCorrectAnswerEnabled
        };
        mergeColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, data.groupsCorrectAnswers, data.id, TABLE_COLUMN_TYPE.DROPDOWN);
        if (colIndex > 0) {
          const columnGroup = this.getColumn(colIndex);
          columnGroup.patchValue({type: typeValue});
        } else {
          this.addColumn(new TableColumn({id: data.id, type: typeValue}));
        }
      }
    });
  }

  openFieUploadDialog(colIndex: number, type: string): void {
    const dialogRef = this.dialog.open(UploadFileSettingsDialogComponent, {
      data: {
        callerCtx: this,
        formGroup: colIndex > 0  ? this.getColumn(colIndex) : null,
      },
      minWidth: 'min-content',
      panelClass: 'timeline'
    });
    firstValueFrom(dialogRef.afterClosed()).then((data: IUploadFileType) => {
      if (data && data.columnName) {
        const typeValue = {
          columnName: data.columnName,
          inputType: data.inputType ?? type,
          selectedTypes: data.selectedTypes
        };
        if (colIndex > 0) {
          const columnGroup = this.getColumn(colIndex);
          columnGroup.patchValue({type: typeValue});
        } else {
          this.addColumn(new TableColumn({type: typeValue}));
        }
      }
    });
  }

  openCheckboxDialog(colIndex: number, type: string): void {
    const dialogRef = this.dialog.open(CheckboxSettingsDialogComponent, {
      data: {
        callerCtx: this,
        formGroup: colIndex > 0  ? this.getColumn(colIndex) : null,
        questions: this.questions,
        groupsCorrectAnswers: this.question.groupsCorrectAnswers ?? []
      },
      minWidth: 'min-content',
      panelClass: 'timeline'
    });
    firstValueFrom(dialogRef.afterClosed()).then((data: {id, columnType, groupsCorrectAnswers}) => {
      if (data && data.columnType.columnName) {
        const typeValue = {
          columnName: data.columnType.columnName,
          inputType: data.columnType.inputType ?? type,
          isCorrectAnswerEnabled: data.columnType.isCorrectAnswerEnabled
        };
        mergeColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, data.groupsCorrectAnswers, data.id, TABLE_COLUMN_TYPE.CHECKBOX);
        if (colIndex > 0) {
          const columnGroup = this.getColumn(colIndex);
          columnGroup.patchValue({type: typeValue});
        } else {
          this.addColumn(new TableColumn({id: data.id, type: typeValue}));
        }
      }
    });
  }

  openSettings(colIndex: number, columnType: IColumnType): void {
    this.openNewInteractionDialog(colIndex, columnType.inputType);
    return;
  }

  updateQuestionOptions() {
    this.question.useCorrectAnswers = this.getUseCorrectAnswers();
    this.questionOptions.tableColumns = (this.grid.value as TableColumn[])
      .map(obj => {
        // remove temporary auxiliary fields in class constructor.
        // convert to object for save to firebase without error.
        // firebase can save only not typed objects
        const tcl = (new TableColumn(obj)).toObject();
        tcl.tableRows = obj.tableRows.map(r => (new TableRowBase(r).toObject()));
        return tcl;
      });
  }

  removeColumn(index: number, columnId: string): void {
    this.grid.removeAt(index);
    const group = getGroupCorrectAnswers(this.question.groupsCorrectAnswers);
    if (!isEmpty(group?.columnsCorrectAnswers?.[columnId])) {
      delete group.columnsCorrectAnswers[columnId];
    }
  }

  addRow(row?: ExtTableRow): void {
    this.grid.controls.forEach(columnGroup => {
      (columnGroup.get('tableRows') as FormArray).push(this.createRow(row));
    });
  }

  removeRow(rowIndex: number, deleteRowId: string): void {
    for (const ctrl of this.grid.controls) {
      (ctrl.get('tableRows') as FormArray).removeAt(rowIndex);
      const column = <TableColumn>ctrl.value;
      if (!column.isMainColumn) {
        const typeValue = column.type;
        if (typeValue.inputType === TABLE_COLUMN_TYPE.CHECKBOX) {
          let correctAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
          if (!isEmpty(correctAnswers)) {
            correctAnswers = correctAnswers.filter(id => id !== deleteRowId) ?? [];
            const group = getGroupCorrectAnswers(this.question.groupsCorrectAnswers);
            if (!isEmpty(correctAnswers)) {
              group.columnsCorrectAnswers[column.id] = correctAnswers;
            } else {
              delete group.columnsCorrectAnswers?.[column.id];
            }
          }
        } else if (typeValue.inputType === TABLE_COLUMN_TYPE.DROPDOWN) {
          const correctAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
          if (!isEmpty(correctAnswers)) {
            (typeValue as ISelectMenuType).options.forEach(opt => {
              correctAnswers[opt.id] = correctAnswers[opt.id]?.filter(id => id !== deleteRowId) ?? [];
              if (isEmpty(correctAnswers[opt.id])) {
                delete correctAnswers[opt.id];
              }
            });
            const group = getGroupCorrectAnswers(this.question.groupsCorrectAnswers);
            if (!isEmpty(correctAnswers)) {
              group.columnsCorrectAnswers[column.id] = correctAnswers;
            } else {
              delete group.columnsCorrectAnswers?.[column.id];
            }
          }
        } else if (typeValue.inputType === TABLE_COLUMN_TYPE.TEXT) {
          const correctAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
          if (!isEmpty(correctAnswers)) {
            const columnCorrectAnswers = getColumnGroupCorrectAnswers(this.question.groupsCorrectAnswers, column.id);
            for (const optionId of Object.keys(columnCorrectAnswers)) {
              const rowIds = columnCorrectAnswers[optionId].rows ?? [];
              if (rowIds.includes(deleteRowId)) {
                columnCorrectAnswers[optionId].rows.splice(rowIds.indexOf(deleteRowId), 1);
              }
            }
            const group = getGroupCorrectAnswers(this.question.groupsCorrectAnswers);
            if (!isEmpty(correctAnswers)) {
              group.columnsCorrectAnswers[column.id] = correctAnswers;
            } else {
              delete group.columnsCorrectAnswers?.[column.id];
            }
          }
        }
      }
    }
  }

  onMenuOpened(rowIndex) {
    this.currentRowIndex = rowIndex;
    this.hoveredRow = rowIndex;
  }

  onDeleteColMenuOpened(colIndex) {
    this.currentColIndex = colIndex;
    this.hoveredColumn = colIndex;
  }

  onMenuClosed() {
    this.currentRowIndex = undefined;

    this.hoveredColumn = null;
    this.hoveredRow = null;
 }

  onMouseLeaveColumn() {
    this.hoveredColumn = null;
  }

  onMouseLeave() {
    this.hoveredRow = null;
  }

  translateFormControlValue(rowCtrl: FormControl) {
    const row = rowCtrl.value;
    const text = row.formControlAnswerValue;
    this.translating[this.dsSelectedRowId] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(translatedText => {
        this.formUtilsService.setAnswerByLanguage(row, translatedText, this.languageParams);
        rowCtrl.patchValue({
          formControlAnswerValue: translatedText,
          answer: row.answer // Preserve existing answer
        }, { emitEvent: false });
      })
      .finally(() => delete this.translating[this.dsSelectedRowId]);
  }

  onWheelContentScroller(event, element: HTMLElement) {
    const toLeft  = event.deltaY < 0 && element.scrollLeft > 0;
    const toRight = event.deltaY > 0 && element.scrollLeft < element.scrollWidth - element.clientWidth;
    if (toLeft || toRight) {
      event.preventDefault();
      event.stopPropagation();
      let deltaLeft = event?.deltaY ?? 0;
      deltaLeft = Math.abs(deltaLeft) < 100 ? (100 * (deltaLeft < 0 ? -1 : 1)) : deltaLeft;
      if (!this.horizontalScrollBlock || this.horizontalScroll$.getValue() !== deltaLeft) {
        element.scrollBy({left: deltaLeft * 3,  behavior: 'smooth'});
        this.horizontalScrollBlock = true;
      }
      this.horizontalScroll$.next(deltaLeft);
    }
  }

}
