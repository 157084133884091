import {ServiceTasksComponent} from '../service-tasks.component';
import {Constants, DATE_FORMAT, EVENT_DATE_MODE} from '../../../core/constants';
import {IWriteCache} from '../st-intercace';
import {Event} from '../../../model/Event';
import {isEmpty} from 'lodash';

export const checkAndFixEventRealFinishDatePermanent = async (thiz: ServiceTasksComponent, onlyCheck: boolean) => {
  let clientIds = [];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Fixed event realFinishDate'))) {
    return;
  }

  const task = (clientId) => {
    let eventsLength = 0;
    let counter = 0;
    const log = [];
    const skipLog = [];

    console.log('clientId: ', clientId);
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start ~~~~~');

    return thiz.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs.filter(e => e.get('dateMode') === EVENT_DATE_MODE.ANYTIME);
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log('check: ', ++counter, 'of', eventsLength);
          if (event.deleted) {
            skipLog.push({
              eventId: event.eventId,
              shortLink: event.shortLink,
              realFinishDate: event.realFinishDate,
              startDate: thiz.utils.formatDate(event.startDate.getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm),
              duration: event.duration,
              endDate: thiz.utils.formatDate(event.endDate, DATE_FORMAT.DD_MM_YYYY_HH_mm),
              name: event.name,
              dateMode: event.dateMode
            });
            continue;
          }
          if (!event.realFinishDate || event.realFinishDate.getTime() < 2524608000000) {
            log.push({
              eventId: event.eventId,
              shortLink: event.shortLink,
              realFinishDate: event.realFinishDate,
              startDate: thiz.utils.formatDate(event.startDate.getTime(), DATE_FORMAT.DD_MM_YY_HH_mm_ss),
              duration: event.duration,
              endDate: thiz.utils.formatDate(event.endDate, DATE_FORMAT.DD_MM_YY_HH_mm_ss),
              name: event.name,
              dateMode: event.dateMode,
              newDuration: Math.trunc((event.endDate - event.startDate.getTime()) / Constants.ONE_MINUTE_MS),
              wrapUpPhaseEnd: event.wrapUpPhaseEnd
            });
            if (!onlyCheck) {
              const ref = thiz.afs.collection('client_data').doc(clientId)
                .collection('events').doc(doc.id)
                .ref;
              const obj = {
                realFinishDate: new Date(Constants.MAX_DATE_MILLIS_01_01_2200)
              };
              cache.push({ref: ref, obj: obj});
            }
          }
        }
        console.log('~~~~~ log ~~~~~');
        console.log(log);
        console.log('~~~~~ skip ~~~~~');
        console.log(skipLog);
  });
  };

  const cache: IWriteCache[] = [];
  for (const id of clientIds) {
    await task(id);
  }
  if (!onlyCheck) {
    console.log('~~~~~ fixed ~~~~~');
    thiz.commitCache(cache);
  }
};

