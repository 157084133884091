import {Component, Inject, OnInit} from '@angular/core';
import {IPrintParams} from '../../../event-mode/event-mode/event-mode.component';
import {UtilsService} from '../../../core/utils.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SectionTimeline} from '../../../model/content/SectionTimeline';
import {ExportPrintSelectSectionDialogComponent} from '../export-print-select-section-dialog/export-print-select-section-dialog.component';
import {TimeLineService} from '../../../services/time-line.service';
import {take} from 'rxjs';

@Component({
  selector: 'app-export-print-dialog',
  templateUrl: './export-print-dialog.component.html',
  styleUrls: ['./export-print-dialog.component.scss']
})
export class ExportPrintDialogComponent implements OnInit {

  isSuperUser: boolean;
  sections: SectionTimeline[] = [];
  printType = 0;
  contentType = 0;
  result: IPrintParams = {
    sections: [],

    slides: true,
    slidesFirst: false,
    slidePage: false,

    polls: true,
    myAnswers: true,
    summarizeAnswers: true,
    correctAnswers: true,

    pdf: true,
    notes: true,
    social: true,
    feedbackRating: false
  };

  constructor(public dialogRef: MatDialogRef<ExportPrintDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public dialog: MatDialog
    , public utils: UtilsService
    , public timelineService: TimeLineService
  ) {
    dialogRef.addPanelClass('timeline');
    this.isSuperUser = data.isSuperUser;
    const sections = this.timelineService.getPlaneListContentAsSectionArray()
      .filter(it => !it.isRoot && this.timelineService.sectionLevel(it) <= 1 && !it.fixedSectionType);
    this.sections = sections ? sections : [];
  }

  ngOnInit() {
  }

  selectSections(e) {
    if (e.value === 0) {
      this.result.sections = [];
    }
  }

  showSelectSectionsDialog() {
    const selected = this.result.sections && this.result.sections.length ? this.result.sections : [];
    const dialogRef = this.dialog.open(ExportPrintSelectSectionDialogComponent, {
      disableClose: true,
      data: {
        isSuperUser: this.isSuperUser,
        sections: this.sections,
        selected: selected
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.result.sections = result;
      }
    });
  }

}
