import {FirestoreReference} from './FirestoreReference';

export class Group extends FirestoreReference {
  public static readonly DB_PATH = 'groups';
  public static readonly DB_PATH_USERS = 'groups_users';

  name: string;

  description: string;

  logo: string;

  readonlyHKVBS: number;

  hkvbsIntegrationId: string;

  constructor(obj?: any) {
    super(obj);
    if (!obj) {
      return;
    }
    if (obj.name) {
      this.name = obj.name;
    }
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.logo) {
      this.logo = obj.logo;
    }
    if (obj.readonlyHKVBS) {
      this.readonlyHKVBS = obj.readonlyHKVBS;
    }
    if (obj.hkvbsIntegrationId) {
      this.hkvbsIntegrationId = obj.hkvbsIntegrationId;
    }
  }
}
