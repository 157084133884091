import {Constants} from '../../core/constants';
import {SectionTimeline} from './SectionTimeline';

export interface IPlaneContentMap {
  [key: string]: PlaneContent;
}

export class PlaneContent {
  public readonly id: string;
  private readonly _items: SectionTimeline[];
  public readonly title: string;
  inSlot: boolean;
  inSelfLearning: boolean;
  prevSectionId: string;
  isRegistered = true;
  public treeItemsProperties = {
    inclinedLineOnExpand: false,
    hideTopBadgeLineAlways: false,
    hideTopBadgeLineOnPrevExpand: false,
    hideBottomBadgeLineAlways: false,
    hideBottomBadgeLineOnMinimized: false,
    hideBottomBadgeLineOnExpand: false,
    level: 0
  };

  constructor(private readonly content: SectionTimeline, private readonly _parentItem: SectionTimeline) {
    this.id = content.id;
    this.title = content.title;
    this._items = content['items'] ? content['items'].filter(item => !item.displayOnly) : [];
  }

  getId() {
    return this.content.getId();
  }

  restoreDefaultTreeItemsProperties() {
    this.treeItemsProperties = {
      inclinedLineOnExpand: false,
      hideTopBadgeLineAlways: false,
      hideTopBadgeLineOnPrevExpand: false,
      hideBottomBadgeLineAlways: false,
      hideBottomBadgeLineOnMinimized: false,
      hideBottomBadgeLineOnExpand: false,
      level: 0
    };
  }

  get items(): SectionTimeline[] {
    return this._items;
  }

  get sectionTimeIsNotActive() {
    return this.content.sectionTimeIsNotActive;
  }

  get orderIndex() {
    return this.content.orderIndex;
  }

  get headSection() {
    return this.trueParentItem && !this.trueParentItem.parentId && this.trueParentItemItems[0].id === this.id;
  }

  get headLevelSection() {
    return this.trueParentItem && !this.trueParentItem.parentId;
  }

  get singleHeadSection() {
    return this.trueParentItem && !this.trueParentItem.parentId && this.trueParentItemItems[0].id === this.id &&
      this.trueParentItemItems.length === 1;
  }

  get isRoot() {
    return this.content['isRoot'];
  }

  get sectionContent() {
    return this.content;
  }

  get isTypeSection(): boolean {
    return this.type === Constants.CONTENT_TYPE_TIMELINE || this.type === Constants.CONTENT_TYPE_SECTION;
  }

  get type() {
    return this.content.type;
  }

  get container() {
    return this.content['container'];
  }

  get invisible() {
    return this.content.invisible;
  }

  get description() {
    return this.content['description'];
  }

  get isPublic() {
    return this.content.isPublic;
  }

  get draft() {
    return this.content.draft;
  }

  get parentItem() {
    return !this.invisible && this._parentItem && this._parentItem.invisible ? null : this._parentItem;
  }

  get trueParentItem() {
    return this._parentItem;
  }

  get trueParentItemItems() {
    return this._parentItem && this._parentItem['items'] ?
      this._parentItem['items'].filter(item => !item.displayOnly) : [];
  }

  get parentId() {
    return this.content.parentId;
  }

  get headLevel() {
    return !this.parentItem || this.parentItem.invisible;
  }

  get requiredRegistration() {
    return this.content['requiredRegistration'];
  }

  get plannedTime() {
    return this.content.plannedTime;
  }

  get duration() {
    return this.content.duration;
  }

  get durationTime() {
    return (this.content as SectionTimeline).durationTime();
  }

  get secondParentId() {
    return this.content.secondParentId;
  }

  get noChild() {
    return this._items.length === 0;
  }

  get isSelfLearningSection() {
    return this.content.isSelfLearningSection;
  }

  get freeSlotType() {
    return this.content.freeSlotType;
  }

  get markedAsDone() {
    return this.content.markedAsDone;
  }

  isAttached() {
    return this.sectionContent.isAttached();
  }
}
