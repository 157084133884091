import {Injectable} from '@angular/core';
import {LoggerService} from './logger.service';

@Injectable()
export class RootLocalStorageService {
  public get = this.localStorageGet;
  public set = this.localStorageSet;
  public remove = this.localStorageRemove;

  constructor(private logger: LoggerService) {
  }

  private checkLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  private localStorageGet(key, defaultValue?) {
    if (!this.checkLocalStorage()) {
      return defaultValue ? defaultValue : undefined;
    }
    let value = localStorage.getItem(key);
    if (!value) {
      value = defaultValue ? defaultValue : undefined;
    }
    const obj = {};
    obj[key] = value;
    this.logger.debug('localStorageGet', obj);
    return value;
  }

  private localStorageSet(key, value) {
    if (!this.checkLocalStorage()) {
      return;
    }
    const obj = {};
    obj[key] = value;
    this.logger.debug('localStorageSet', obj);
    localStorage.setItem(key, value);
  }

  private localStorageRemove(key) {
    if (!this.checkLocalStorage()) {
      return;
    }
    this.logger.debug('localStorageRemove', key);
    localStorage.removeItem(key);
  }
}
