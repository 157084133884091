import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {LoginService} from '../login/login.service';
import {LoggerService} from '../core/logger.service';
import {UtilsService} from '../core/utils.service';
import {Group} from '../model/Group';
import {catchError, firstValueFrom, map, Observable, take} from 'rxjs';
import {Action, AngularFirestore, DocumentSnapshot, QuerySnapshot} from '@angular/fire/compat/firestore';
import {AppUser, UserColors} from '../model/AppUser';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {HttpClient} from '@angular/common/http';
import {AppUserUID} from '../model/AppUserUID';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {deleteField} from '@angular/fire/firestore';

@Injectable()
export class UserApiService extends StdApiService {
  protected API_URL: string;
  protected API_URL_V3: string;

  constructor(
    private http: HttpClient,
    protected store: Store<fromRoot.State>,
    protected aFirestore: AngularFirestore,
    protected utils: UtilsService,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/userApi/v3/';
    this.API_URL_V3 = this.BACKEND_BASE + '/api/v3/users';
  }

  public isExists(path: string, id: string) {
    const documentSnapshotObservable = firstValueFrom(this.afs.collection(path).doc(id).get());
    return documentSnapshotObservable.then((value => value.exists));
  }

  getSuperusers(): Observable<AppUser[]> {
    return this.aFirestore.collection(AppUser.DB_PATH, ref => ref.where('roles', 'array-contains', '1000'))
      .valueChanges({idField: 'userId'})
      .pipe(
        map(res => this.mapArray(res, it => new AppUser(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  addSuperuser(email: string): Promise<any> {
    return this.http.post(this.API_URL + 'addSuperuser', {value: email})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  removeSuperuser(email: string) {
    return this.http.post(this.API_URL + 'removeSuperuser', {value: email})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getUsers(): Observable<AppUser[]> {
    return this.aFirestore.collection<AppUser>(AppUser.DB_PATH)
      .get()
      .pipe(
        this.log('getUsers'),
        map((changes: QuerySnapshot<AppUser>) => this.mapArray(changes.docs, it => new AppUser({userId: it.id, ...it.data()})))
      );
  }

  public getUsersPromise() {
    return firstValueFrom(this.afs.collection<AppUser>(AppUser.DB_PATH)
      .get()
      .pipe(
        this.log('getUsers'),
        map((changes: QuerySnapshot<AppUser>) => this.mapArray(changes.docs, it => new AppUser({userId: it.id, ...it.data()})))
      ));
  }

  getClientUser(userId: string, clientId: string): Promise<AppUser> {
    return firstValueFrom(this.aFirestore.doc(`client_data/${clientId}/app_users/${userId}`)
      .get()
      .pipe(
        this.log('getClientUser'),
        map((res: DocumentSnapshot<AppUser>) => res.exists ? this.mapObject(res, it => new AppUser({userId: it.id, ...it.data()})) : null)
      ));
  }

  createClientUser(userId: string, clientId: string): Promise<AppUser> {
    return this.http.post<AppUser>(this.API_URL + `${userId}/create?clientId=${clientId}`, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  deleteClientUser(userId: string, clientId: string) {
    return this.http.post<AppUser>(this.API_URL + `${userId}/delete?clientId=${clientId}`, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  deleteAndClearClientEventUser(userId: string, clientId: string, eventId: string) {
    const path = `deleteAndClearUserFromClientEvent?userId=${userId}&clientId=${clientId}&eventId=${eventId}`;
    return this.http.post<AppUser>(this.API_URL + path, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getUser(key: string, options: {takeOne?: boolean, root?: boolean} = {takeOne: false, root: false}) {
    if (options.root) {
      if (options.takeOne) {
        return this.aFirestore
          .collection(AppUser.DB_PATH)
          .doc<AppUser>(key)
          .snapshotChanges()
          .pipe(
            this.log('getUser'),
            map((res: Action<DocumentSnapshot<AppUser>>) => new AppUser({userId: res.payload.id, ...res.payload.data()})),
            take(1)
          );
      }
      return this.aFirestore
        .collection(AppUser.DB_PATH)
        .doc<AppUser>(key)
        .snapshotChanges()
        .pipe(
          this.log('getUser'),
          map((res: Action<DocumentSnapshot<AppUser>>) => new AppUser({userId: res.payload.id, ...res.payload.data()}))
        );
    } else {
      if (options.takeOne) {
        return this.afs
          .collection(AppUser.DB_PATH)
          .doc<AppUser>(key)
          .snapshotChanges()
          .pipe(
            this.log('getUser'),
            map((res: Action<DocumentSnapshot<AppUser>>) => new AppUser({userId: res.payload.id, ...res.payload.data()})),
            take(1)
          );
      }
      return this.afs
        .collection(AppUser.DB_PATH)
        .doc<AppUser>(key)
        .snapshotChanges()
        .pipe(
          this.log('getUser'),
          map((res: Action<DocumentSnapshot<AppUser>>) =>
            res.payload.exists ? new AppUser({userId: res.payload.id, ...res.payload.data()}) : null)
        );
    }
  }

  public getAdmins() {
    this.api(() => {
      return this.gapi.client.drive.files.get({
        'fileId': '0B8NOPM-Y2k3haGtERkxaZTAwekU'
      }).then(function(resp) {
        if (resp && resp.result) {
          this.logger.debug('Name: ' + resp.result.name);
          this.logger.debug('Kind: ' + resp.result.kind);
          this.logger.debug('MIME type: ' + resp.result.mimeType);
        } else {
          this.logger.debug('No Response');
        }
      }).catch(error => {
        this.logger.error('gapi.client.drive.files.get Error: ' + JSON.stringify(error));
      });
    });
    return this.api(() => this.gapi.client.userApi.getAdmins());
  }

  setRoleToUser(userId: string, roleId: string, set: boolean) {
    const obj = {
      client_id: this.auth.client_id$.getValue(),
      user_id: userId,
      role: roleId
    };
    if (set) {
      return this.http.post<AppUser>(this.API_URL + 'setUserRole', obj)
        .toPromise()
        .catch((e) => this.catchServerError(e));
    } else {
      return this.http.post<AppUser>(this.API_URL + 'deleteUserRole', obj)
        .toPromise()
        .catch((e) => this.catchServerError(e));
    }
  }

  setUserGuest(userId: string, guest: boolean, client_id?: string) {
    if (!client_id) {
      client_id = this.auth.client_id$.getValue();
    } else if (client_id && client_id === 'null') {
      client_id = null;
    }
    const obj = {
      client_id: client_id,
      guest: guest
    };
    return this.http.post<AppUser>(this.API_URL + `${userId}/guest`, obj);
  }

  setUserBlocked(userId: string, blocked: boolean, client_id?: string) {
    if (!client_id) {
      client_id = this.auth.client_id$.getValue();
    } else if (client_id && client_id === 'null') {
      client_id = null;
    }
    const obj = {
      client_id: client_id,
      user_id: userId,
      blocked: blocked
    };
    return this.http.post<AppUser>(this.API_URL + 'setUserBlocked', obj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  setUserClientId(userId: string, client_id: string) {
    const obj = {
      user_id: userId,
      role: client_id
    };
    return this.http.post<AppUser>(this.API_URL + 'setUserClientId', obj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  setAcceptTermsAndPrivacy(userId: string, acceptTermsAndPrivacy: boolean) {
    const path = `setAcceptTermsAndPrivacy?userId=${userId}`;
    return this.http.post(this.API_URL + path, {value: acceptTermsAndPrivacy})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  setAcceptClientTermsAndPrivacy(acceptClientTermsAndPrivacy: boolean, userId?: string) {
    if (!userId) {
      userId = this.auth.getAuthUser().uid;
    }
    const path = `setAcceptClientTermsAndPrivacy?userId=${userId}`;
    return this.http.post(this.API_URL + path, {value: acceptClientTermsAndPrivacy})
      .pipe(catchError(err => this.catchServerErrorObservable(err)));
  }

  acceptCustomTerms(value: boolean, userId?: string) {
    if (!userId) {
      userId = this.auth.getAuthUser().uid;
    }
    const path = `acceptCustomTerms?userId=${userId}&value=${value}`;
    return this.http.post(this.API_URL + path, null)
      .pipe(catchError(err => this.catchServerErrorObservable(err)));
  }

  setAcceptClientTermsAndPrivacyByClientId(userId: string, clientId: string, acceptClientTermsAndPrivacy: boolean) {
    const path = `setAcceptClientTermsAndPrivacy?userId=${userId}&clientId=${clientId}`;
    return this.http.post(this.API_URL + path, {value: acceptClientTermsAndPrivacy})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  updateUserCard(userId: string, description: string, socialNetwork: string, hideEmail: boolean, department: string) {
    const obj = {
      userId: userId,
      description: description ? description : null,
      socialNetwork: socialNetwork,
      hideEmail: hideEmail,
      department: department ? department : null
    };
    return this.http.post(this.API_URL + 'updateUserCard', obj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  updateUserName(name: string)  {
    const userId = this.auth.getAuthUser().uid;
    const path = `/${userId}/name`;
    return this.http.patch(this.API_URL_V3 + path, {name: name});
  }

  updateProfilePicture(url: string) {
    return firstValueFrom(this.http.post(this.API_URL + `picture`, {value: url}))
      .catch((e) => this.catchServerError(e));
  }

  getUserById(userId: string): Observable<AppUser> {
    return this.http.get<AppUser>(this.API_URL_V3 + `/${userId}`);
  }

  getUserByEmail(email: string): Observable<AppUser> {
    return this.http.get<AppUser>(this.API_URL_V3 + `?email=${email}`);
  }

  activateSubscription(state: boolean) {
    return this.http.post<AppUser>(this.API_URL + 'activateSubscription', this.booleanObject(state))
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  setGeolocation(country: string, countryCode: string) {
    return firstValueFrom(this.http.post(this.API_URL + 'country', {country: country, countryCode: countryCode}))
      .catch((e) => this.catchServerError(e));
  }

  setUserInterfaceLanguage(language?: string, languageBrowser?: string) {
    const body: {language?: string, languageBrowser?: string} = {};
    if (language) {
      body.language = language;
    }
    if (languageBrowser) {
      body.languageBrowser = languageBrowser;
    }
    return firstValueFrom(this.http.post<AppUser>(this.API_URL + 'language', body))
      .catch((e) => this.catchServerError(e));
  }

  /* user groups */
  getGroups(): Observable<Group[]> {
    return this.afs.collection<Group>(Group.DB_PATH)
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        this.log('getGroups'),
        map(res => this.mapArray(res, (it) => new Group(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getGroup(key: string): Observable<Group> {
    return this.afs
      .collection(Group.DB_PATH)
      .doc<Group>(key)
      .get()
      .pipe(
        this.log('getGroup'),
        map(res => this.mapObject(res, (it) => new Group({_key: it.id, ...it.data()}))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  addGroup(group: Group) {
    return this.afs.collection<Group>(Group.DB_PATH)
      .add(group.toObject())
      .catch((err) => this.throwFirestoreError(err));
  }

  saveGroup(group: Group) {
    return this.afs
      .collection(Group.DB_PATH)
      .doc<Group>(group._key)
      .update(group.toObject())
      .catch((err) => this.throwFirestoreError(err));
  }

  deleteGroup(groupKey: string) {
    return this.afs
      .collection(Group.DB_PATH)
      .doc<Group>(groupKey)
      .delete();
  }

  getGroupUsers(groupId: string) {
    return this.afs
      .collection(Group.DB_PATH_USERS)
      .doc(groupId)
      .valueChanges()
      .pipe(
        this.log('getGroupUsers'),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  addUserToGroup(groupId: string, userObj: any) {
    return this.isExists(Group.DB_PATH_USERS, groupId)
      .then(isExists => {
        if (isExists) {
          return this.afs
            .collection(Group.DB_PATH_USERS)
            .doc(groupId)
            .update(userObj)
            .catch((err) => this.throwFirestoreError(err));
        } else {
          return this.afs.collection(Group.DB_PATH_USERS)
            .doc(groupId)
            .set(userObj)
            .catch((err) => this.throwFirestoreError(err));
        }
      });
  }

  deleteUserFromGroup(groupId: string, userId: string) {
    const updateObj = {};
    updateObj[userId] = deleteField();
    return this.afs
      .collection(Group.DB_PATH_USERS)
      .doc<Group>(groupId)
      .update(updateObj)
      .catch((err) => this.throwFirestoreError(err));
  }

  /* other */

  getUserUUID(userId: string): Observable<AppUserUID> {
    return this.afs.collection('user_uuid')
      .doc<AppUserUID>(userId)
      .valueChanges()
      .pipe(
        this.log('getUserUUID'),
        map(res => this.mapObject(res, it => new AppUserUID({userId: userId, ...it})))
      );
  }

  setUUIDState(state) {
    return this.http.post(this.API_URL + 'setUUIDState', {value: state})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  generateUUID() {
    return this.http.post(this.API_URL + 'generateUUID', null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  addUserToClient(email: string, name: string, picture: string) {
    const obj = {
      email: email,
      name: name,
      picture: picture ? picture : null
    };
    return this.http.post(this.API_URL + 'addUserToClient', obj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getAzureAccessToken() {
    return this.http.post(this.API_URL + 'getAzureAccessToken?provider=hkvbs-azure', null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getAzureAccessTokenPicture() {
    return this.http.post(this.API_URL + 'getAzureAccessTokenPicture?provider=hkvbs-azure', null, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  approveUser(clientId: string, userId: string, value: boolean) {
    const path = `approveUser?clientId=${clientId}&userId=${userId}&value=${value}`;
    return this.http.post<AppUser>(this.API_URL + path, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getVerificationInfo(userId: string) {
    const path = `${userId}/verify`;
    return this.http.get<boolean>(this.API_URL + path)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  verifyUser(userId: string, value: boolean) {
    const path = `${userId}/verify?value=${value}`;
    return this.http.patch<AppUser>(this.API_URL + path, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  resetPassword(userId: string) {
    const path = `${userId}/reset`;
    return this.http.patch(this.API_URL + path, null, {
      responseType: 'text'
    });
  }

  getUsersByPacketSize(packetSize: number, startAt: any) {
    let queryFn = q => q.orderBy('email').limit(packetSize);
    if (startAt) {
      queryFn = q => q.orderBy('email').limit(packetSize).startAfter(startAt);
    }
    return this.afs
      .collection('app_users', queryFn)
      .get();
  }

  getUserColors(): Observable<UserColors[]> {
    const userId = this.auth.getAuthUser().uid;
    return this.afs
      .collection('app_users').doc(userId)
      .collection('user_colors')
      .get()
      .pipe(
        map(res => res.docs.map(doc => new UserColors({id: doc.id, ...doc.data()}))),
        this.log('getUserColors')
      );
  }

  addUserColor(color: string, palette: string[] = []) {
    const userId = this.auth.getAuthUser().uid;
    return this.afs
      .collection('app_users').doc(userId)
      .collection('user_colors')
      .add({
        color,
        palette
      });
  }

  updateUserColors(colorId: string, colorObj: UserColors) {
    const userId = this.auth.getAuthUser().uid;
    return this.afs
      .collection('app_users').doc(userId)
      .collection('user_colors').doc(colorId)
      .update(colorObj);
  }

  deleteUserColor(colorId: string) {
    const userId = this.auth.getAuthUser().uid;
    return this.afs
      .collection('app_users').doc(userId)
      .collection('user_colors').doc(colorId)
      .delete();
  }
}
