import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, catchError} from 'rxjs';
import { CommonService } from '../core/common.service';
import { Constants } from '../core/constants';
import { StdApiService } from './std-api-service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { LoggerService } from '../core/logger.service';
import { LoginService } from '../login/login.service';
import { UtilsService } from '../core/utils.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';

export enum TEXT_AI_OPTION_LIST {
    CORRECT_GRAMMAR = 'correct_grammar',
    TEXT_SUMMARIZE = 'text_summarize',
    TEXT_EXPAND = 'text_expand',
    CREATE_LYRICS= 'create_lyrics',
    GROUP_BY_COLOR = 'group_by_color'
}

@Injectable({
  providedIn: 'root',
})
export class AITextService extends StdApiService {

  constructor(
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    protected aFirestore: AngularFirestore,
    protected storage: AngularFireStorage,
    private functions: AngularFireFunctions,
    private common: CommonService
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
  }

  correctGrammar(textContent: string) {
    return this.useAIForText(Constants.AI_TEXT_FUNCTION_CORRECT_GRAMMAR, textContent);
  }

  shortenText(textContent: string) {
    return this.useAIForText(Constants.AI_TEXT_FUNCTION_SHORTEN_TEXT, textContent);
  }

  expandText(textContent: string) {
    return this.useAIForText(Constants.AI_TEXT_FUNCTION_EXPAND_TEXT, textContent);
  }

  createQuestionnaire(textContent: string, options?: any) {
    return this.useAIForText(Constants.AI_TEXT_FUNCTION_CREATE_QUESTIONNAIRE, textContent, options);
  }

  verifyQuestionnaire(textContent: string, verifyContent: string, verifyAnswer: string[]) {
    return this.useAIForTest(Constants.AI_TEXT_FUNCTION_VERIFY_QUESTION, textContent, verifyContent, verifyAnswer);
  }

  createLyrics(textContent: string) {
    return this.useAIForText(Constants.AI_TEXT_FUNCTION_CREATE_LYRICS, textContent);
  }

  useAIForTest(functionName: string, textContent: string, verifyContent: string, verifyAnswer: string[] | string): Observable<any> {
    if (textContent.trim().length === 0 || verifyContent.trim().length === 0) { return; }
    const callable = this.functions.httpsCallable('useAIForText');
    return callable({ text: textContent, verifyContent: verifyContent, verifyAnswer: verifyAnswer, functionName: functionName })
      .pipe(
        catchError((error) => {
          this.common.showPopupError(this.common.i18n('text.editor.ai.text.error'));
          return this.catchServerErrorObservable(error);
        })
      );
  }

  useAIForText(functionName: string, textContent: string, options?: any): Observable<any> {
    if (textContent.trim().length === 0) { return; }
    const callable = this.functions.httpsCallable('useAIForText');
    return callable({ text: textContent, functionName: functionName, options: options })
      .pipe(
        catchError((error) => {
          this.common.showPopupError(this.common.i18n('text.editor.ai.text.error'));
          return this.catchServerErrorObservable(error);
        })
      );
  }
}
