import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {APP_MODE, LoginService} from './login/login.service';
import * as ui from './actions/ui';
import * as fromRoot from './reducers';
import {Store} from '@ngrx/store';
import {Title} from '@angular/platform-browser';
import {Constants, FOLLOW_ME_MODE} from './core/constants';
import {CommonService} from './core/common.service';
import {TimeLineService} from './services/time-line.service';
import {AppUser, AppUserResponse} from './model/AppUser';
import {MatDialog} from '@angular/material/dialog';
import {Event} from './model/Event';
import {BehaviorSubject, combineLatest, filter, firstValueFrom, map, of, Subject, Subscription, switchMap, take, takeUntil} from 'rxjs';
import {EventsDataService} from './services/events-data.service';
import * as data from './actions/data';
import {UsersDataService} from './services/users-data.service';
import {cloneDeep, isEmpty} from 'lodash';
import {TokenRefreshService} from './services/token-refresh.service';
import {TimerService} from './core/timer.service';
import {EventStartReminderService} from './services/event-start-reminder.service';
import {InstantSettings} from './model/event-mode/InstantSettings';
import {registerLocaleData} from '@angular/common';
import localeCh from '@angular/common/locales/de-CH';
import localeChExtra from '@angular/common/locales/extra/de-CH';
import {AnalyticsService} from './services/analytics.service';
import {VersionControlService} from './services/version-control.service';
import {ClientConfig} from './model/ClientConfig';
import {EnvironmentService} from '@ninescopesoft/core';
import {GeolocationService} from './core/geolocation.service';
import {LANGUAGE} from './core/language-constants';
import {PaperJsService} from './core/paper-js.service';
import { RecordingService } from './modules/meeting-frame/services/recording.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly Constants = Constants;
  readonly APP_MODE = APP_MODE;
  public auth: any;
  loading: boolean;
  timelineLoaded$ = new BehaviorSubject<boolean | null>(null);
  presentationMode = false;
  modalDialogOpen = false;
  manageTimeOpen = false;
  userEditAnswerOnTextQuestion = false;
  timelineProgress = false;
  menuOpened = false;
  currentUser: AppUser;
  currentEvent: Event;
  instantSettings: InstantSettings;
  instantSettingsSubscribe: Subscription;
  hiddenMenuItems = {};
  timelineAboutURL;
  menuItemFocused = 0; // now max 15
  openAllEventsSubject = new Subject();
  protected _unsubscribeAll: Subject<any> = new Subject();
  protected _unsubscribeAuthState: Subject<any> = new Subject();
  showTermsAndPrivacy = false;
  canShowMainMenuFiles = false;
  isAzure = false;
  disableMainBackground = false;
  profileUpdated = false;
  client_name = '';
  clientConfig: ClientConfig;
  isManage: false | 'module' | 'paths' | 'curriculums' | 'settings' = false;
  inModule = false;
  inManageViewId: string;
  logo: string;

  @ViewChild('hidden_div') el: ElementRef;
  @ViewChild('hidden_div_defColor') elDefColor: ElementRef;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((this.instantSettings && this.instantSettings.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME &&
      !this.isPresenter && !this.isSpeaker) || this.isManage) {
      return;
    }
    if (this.presentationMode && !this.modalDialogOpen && !this.manageTimeOpen && !this.userEditAnswerOnTextQuestion &&
      !event.altKey && event.ctrlKey && !event.shiftKey && !event.metaKey && !this.recordingService.videoRecorderOpen()) {
      switch (event.code) {
        case Constants.LEFT_ARROW:
        case Constants.RIGHT_ARROW:
          this.store.dispatch(new ui.SetPresentationModeKeyAction('Ctrl' + event.code + ',' + (new Date()).getTime()));
          break;
        default:
          break;
      }
    } else
      if (this.presentationMode && !this.modalDialogOpen && !this.manageTimeOpen && !this.userEditAnswerOnTextQuestion &&
        !event.altKey && !event.ctrlKey && !event.shiftKey && !event.metaKey && !this.recordingService.videoRecorderOpen()) {
        switch (event.code) {
          case Constants.LEFT_ARROW:
          case Constants.RIGHT_ARROW:
          case Constants.ESCAPE:
            this.store.dispatch(new ui.SetPresentationModeKeyAction(event.code + ',' + (new Date()).getTime()));
            break;
          default:
            break;
        }
      }
  }
  @HostListener('click') onApplicationClick() {
    if (!this.common.userApplicationInteraction.getValue()) {
      this.common.userApplicationInteraction.next(true);
    }
  }

  constructor (private router: Router,
    private titleService: Title,
    public loginService: LoginService,
    private translate: TranslateService,
    private store: Store<fromRoot.State>,
    public common: CommonService,
    public timelineService: TimeLineService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public eventDataService: EventsDataService,
    public userDataService: UsersDataService,
    public tokenRefreshService: TokenRefreshService,
    public timerService: TimerService,
    public eventStartReminderService: EventStartReminderService,
    private versionControlService: VersionControlService,
    private analytics: AnalyticsService,
    private geolocationService: GeolocationService,
    private environmentService: EnvironmentService,
    private paper: PaperJsService,
    private recordingService: RecordingService) {
    this.environmentService.setBackendBase(this.common.utils.getEnv().BACKEND_BASE);
    this.environmentService.setCompanyName(this.common.utils.getEnv().companyName);
    if (!this.common.utils.isRoche() && !this.common.utils.isP30()) {
      registerLocaleData(localeCh, 'de-CH', localeChExtra);
    }
    analytics.logEvent('custom_application');
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    let lang = this.common.utils.getEnv().lang;
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (!lang) {
      lang = 'en';
    }
    translate.use(lang);
    const clientConfig = this.loginService.getClientFromCache();
    if (clientConfig) {
      clientConfig.cached = true;
      this.loginService.clientConfig$.next(clientConfig);
    }
    this.initBackground();
    this.paper.initialize(null);
    this.timelineService.setPermitRocheVideo(this.common.utils.getEnv()['permitRocheVideo']);
    this.timelineService.setCompanyName(this.common.utils.getEnv().companyName);
    this.hiddenMenuItems = this.common.utils.getEnv().hiddenMenuItems ? this.common.utils.getEnv().hiddenMenuItems : {};
    this.timelineAboutURL = this.common.utils.getEnv()['timelineAboutURL'];
    this.showTermsAndPrivacy = this.common.utils.getEnv()['showTermsAndPrivacy'];

    this.tokenRefreshService.init();
    this.versionControlService.init();
    this.store.select(fromRoot.getMenuOpen)
      .subscribe(open => {
        this.menuOpened = open;
      });
    this.store.select(fromRoot.getLoading)
      .subscribe(loading => {
        this.loading = loading;
        this.cdr.markForCheck();
      });
    this.store.select(fromRoot.getPresentationMode)
      .subscribe(expand => {
        this.presentationMode = expand;
      });
    this.store.select(fromRoot.getModalDialogOpen)
      .subscribe(open => {
        this.modalDialogOpen = open;
      });
    this.store.select(fromRoot.getManageTimeOpen)
      .subscribe(open => {
        this.manageTimeOpen = open;
      });
    this.store.select(fromRoot.getUserInputAnswerOnTextQuestion)
      .subscribe(edit => {
        this.userEditAnswerOnTextQuestion = edit;
      });
    this.setAppName();
    this.common.showProgress
      .subscribe(progress => {
        if (this.timelineProgress !== progress) {
          this.timelineProgress = progress;
          this.cdr.detectChanges();
        }
      });
    this.common.disableMainBackground
      .subscribe((value) => {
        if (this.disableMainBackground !== value) {
          this.disableMainBackground = value;
          this.cdr.detectChanges();
        }
      });
    this.versionControlService.requiredReload$
      .subscribe(value => {
        if (value) {
          const text = this.versionControlService.appVersion && this.versionControlService.appVersion.text;
          const titleMessage = this.common.i18n(text ? text : 'version.control.confirm.title');
          const reloadMessage = this.common.i18n('common.reload');
          this.common.confirm(
            titleMessage,
            null,
            null,
            null,
            reloadMessage,
            null,
            true,
            true)
            .pipe(take(1))
            .subscribe(result => {
              location.reload();
            });
        }
      });
    combineLatest([
      this.router.events.pipe(filter((ev) => ev instanceof NavigationStart)),
      this.loginService.clientConfig$.pipe(filter(it => !!it))
    ])
      .subscribe(([ev, config]: [NavigationStart, ClientConfig]) => {
        const route = ev.url;
        const routeName = route.split('/')[1];
        switch (routeName) {
          case 'curriculums': this.titleService.setTitle(`Cur`);
            break;
          case 'paths': this.titleService.setTitle(`Script`);
            break;
          case 'modules': this.titleService.setTitle(`Mod`);
            break;
          case 'module': if (!this.titleService.getTitle().includes('Mod')) { this.titleService.setTitle(`Mod`); }
            break;
          case 'dashboard': this.titleService.setTitle(`Dashboard | ${config.name}`);
            break;
          case 'event':
            const eventName = this.timelineService.event?.name;
            const child = route.split('/')[3] ? `| ${route.split('/')[3]}` : '';
            const title = `${eventName ? eventName : config.name}`;
            this.titleService.setTitle(`${title} ${eventName ? child : ''}`);
            break;
          default: this.titleService.setTitle(config.name);
        }
      });
    this.router.events
      .pipe(
        filter(it => (it instanceof NavigationStart) || (it instanceof NavigationEnd)),
        filter(it => {
          const split = (<RouterEvent>it).url.split('/');
          if (split[1] === 'module' || split[1] === 'modules') {
            this.loginService.applicationMode = APP_MODE.EDUCATION;
          } else if (split[2] === 'notes') {
            this.loginService.applicationMode = APP_MODE.NOTES;
          } else if (split[1] === 'question-cards') {
            this.loginService.applicationMode = APP_MODE.QUESTION_CARDS;
          } else {
            this.loginService.applicationMode = APP_MODE.TIMELINE;
          }
          return this.loginService.educationMode;
        })
      )
      .subscribe(() => {
      });
    this.router.events
      .pipe(filter(it => it instanceof NavigationEnd))
      .subscribe((value) => {
        let url = (<RouterEvent>value).url;
        if (url && url.includes('?')) {
          url = url.split('?')[0];
        }
        if (this.loginService.urlHelper.getValue() !== url) {
          this.loginService.urlHelper.next(url);
        }
        if (url.includes('curriculums')) {
          this.isManage = 'curriculums';
        } else if (url.includes('paths')) {
          this.isManage = 'paths';
        } else if (url.includes('modules')) {
          this.isManage = 'module';
        } else if (!url.includes('event') && url.includes('settings')) {
          this.isManage = 'settings';
        } else {
          this.isManage = false;
        }
        this.inModule = url.includes('module/');
        this.inManageViewId = !url.includes('event') ? url.split('/')[2] : null;
      });
  }

  ngOnInit(): void {
    this.timelineService.timelineLoaded
      .subscribe(loaded => {
        this.timelineLoaded$.next(loaded);
        this.cdr.detectChanges();
      });
    this.loginService.getAuthenticatedUser()
      .subscribe(authUser => {
        const appUser = this.loginService.getAppUser();
        if (authUser && appUser && !appUser.languageBrowser) {
          this.userDataService.setBrowserLanguage(this.common.utils.getBrowserLanguage());
        }
        if (authUser && appUser && !appUser.country) {
          firstValueFrom(this.geolocationService.getGeolocation())
            .then((location: { country: string, countryCode: string; }) =>
              this.userDataService.setGeolocation(location.country, location.countryCode)
                .then((response: AppUserResponse) => this.loginService.setAppUser(response.user))).catch(() => { });
        }
        if (!authUser) {
          this._unsubscribeAuthState.next(true);
          this._unsubscribeAuthState.complete();
          this._unsubscribeAuthState = new Subject();
          this.timerService.destroy();
        } else if (!this.timerService.isTimerRunning) {
          this.timerService.createTimer();
        }
      });
    let requestPicture = false;
    const loginProvider = this.loginService.getCurrentProviderId();
    this.isAzure = loginProvider === Constants.PROVIDER_HKVBS_MICROSOFT;
    this.translate.onLangChange.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.timelineService.userInterfaceLanguage$.next(value.lang);
        this.timelineService.selfLearningService.userInterfaceLanguage = value.lang as LANGUAGE;
      });
    this.store.select(fromRoot.getCurrentUser)
      .pipe(
        map(user => !user || user instanceof AppUser ? user : new AppUser(user)),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(user => {
        // todo user coming twice... second time as object without dispatch after merge applications
        if ((this.currentUser && !user && !this.isLogged()) || (this.currentUser && !user && this.isLogged())) {
          this.offListeners();
          if (!this.isLogged()) {
            this.eventDataService.offListenConnectionState();
          }
        }
        this.currentUser = cloneDeep(user);
        if (this.currentUser && this.currentUser.language && this.currentUser.language !== this.translate.currentLang) {
          this.translate.use(this.currentUser.language);
        }
        this.initBackground(this.currentEvent);
        if (user && this.isAzure && !user.guest && !requestPicture) {
          requestPicture = true;
          this.updateProfilePicture();
        }
        this.timelineService.currentUser = this.currentUser;
      });
    let client_id = null;
    this.loginService.clientConfig$
      .subscribe(config => {
        if (config && client_id !== config.id) {
          if (client_id) {
            this.eventStartReminderService.startEventReminder(null);
          }
          client_id = config.id;
          this.clientConfig = config;
          Constants.TIMELINE_DEFAULT_LANGUAGE = config.language;
          this.environmentService.setLanguage(config.language);
        }
        if (isEmpty(client_id)) {
          this.client_name = '';
        } else {
          this.client_name = config ? config.name : client_id;
        }
        this.logo = config?.logo ? config.logo : null;
        const browserLanguage = this.common.utils.getBrowserLanguage();
        const browserLanguageCanBeUsed = this.common.utils.checkBrowserLanguage(browserLanguage);
        if (!this.timelineService.userInterfaceLanguage && config && config.language) {
          this.translate.use(browserLanguageCanBeUsed ? browserLanguage : config.language);
        }
        this.initBackground(this.currentEvent);
      });
    this.timelineService.eventDataLoaded$
      .pipe(
        switchMap(it => it ? this.timelineService.event$ : of(null)),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(val => {
        const event = val;
        if (val === null || (!!val && !val.shortLink)) {
          this.currentEvent = null;
          this.initInstantSettings(null);
          this.initBackground(null);
        } else if (!!event && event.shortLink) {
          this.initInstantSettings(event.eventId);
          this.currentEvent = new Event(event);
          this.initBackground(event);
          this.resetMenuItemsFocused();
        }
      });
    this.openAllEventsSubject.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        if (value) {
          this.loginService.redirectTo('/dashboard');
        }
      });
  }

  // todo how it work with re-login
  offListeners() {
    this.timerService.destroy();
    this.eventStartReminderService.destroy();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
  }

  isLoginPage() {
    const link = window.location.href.match(/.+\/(login)$/);
    return link && link.length === 2 && link[1] === 'login';
  }

  isVerifyPage() {
    const link = window.location.href.match(/.+\/(verify)$/);
    return link && link.length === 2 && link[1] === 'verify';
  }

  isAcceptPage() {
    const link = window.location.href.match(/.+\/(accept)$/);
    return link && link.length === 2 && link[1] === 'accept';
  }

  isGuestPage() {
    const link = window.location.href.match(/.+\/(guest).+/);
    return link && link.length === 2 && link[1] === 'guest';
  }

  public isLogged() {
    return this.loginService.signedIn;
  }

  updateProfilePicture() {
    if (!this.currentUser.lastProfileUpdate && !this.profileUpdated) {
      this.userDataService.requestAzureProfilePicture()
        .then(base64 => {
          this.userDataService.uploadProfilePicture(base64)
            .then(url => {
              this.currentUser.picture = url;
              this.profileUpdated = true;
              this.store.dispatch(new data.SetCurrentUserAction(this.currentUser));
            });
        });
    }
  }

  closeMenu() {
    this.setMenuOpen(false);
  }

  closePlusMenuAndMainMenu() {
    this.closeMenu();
  }

  setAppName() {
    const suffix = this.common.utils.getEnv().name;
    const value = this.common.utils.getEnv().appName;
    const title = value + (suffix ? (' ' + suffix) : '');
    this.titleService.setTitle(title);
  }

  setMenuOpen(open: boolean) {
    this.menuOpened = open;
    this.store.dispatch(new ui.SetMenuOpenAction(open));
  }

  media() {
    return document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH;
  }

  ngAfterViewInit(): void {
    if (this.el) {
      const styles = getComputedStyle(this.el.nativeElement);
      if (styles) {
        Constants.PROGRESS_COLOR = styles.getPropertyValue('color');
      }
    }
    if (this.elDefColor) {
      const styles = getComputedStyle(this.elDefColor.nativeElement);
      if (styles) {
        Constants.WORD_CLOUD_DEF_COLOR = styles.getPropertyValue('color');
      }
    }
  }

  get isPresenter() {
    return this.currentUser && this.currentEvent && this.timelineService.isPresenter;
  }

  get isSpeaker() {
    return this.currentUser && this.currentEvent && this.timelineService.event?.isSpeaker(this.loginService.getAppUser().userId);
  }

  resetMenuItemsFocused() {
    if (this.currentEvent && (this.menuItemFocused === 3 || this.menuItemFocused === 4) || this.menuItemFocused === 5) {
      this.menuItemFocused = 0;
    }
  }

  initInstantSettings(eventId) {
    if (eventId) {
      if (this.instantSettingsSubscribe) {
        this.instantSettingsSubscribe.unsubscribe();
        this.instantSettingsSubscribe = null;
        this.instantSettings = null;
      }
      this.instantSettingsSubscribe = this.timelineService.instantSettings$
        .pipe(takeUntil(this._unsubscribeAuthState))
        .subscribe(instantSettings => {
          this.instantSettings = instantSettings ? instantSettings : new InstantSettings();
          this.canShowMainMenuFiles = this.instantSettings.advancedSettings.externalEventFileStore &&
            this.isAzure && (this.currentUser && !this.currentUser.guest);
        });
    }
  }

  initBackground(event?: Event) {
    if (this.media()) {
      if (event?.background && event?.mobileBackground) {
        document.body.style.backgroundImage = 'url("' + event.mobileBackground + '")';
      } else if (event && event.background && !event.mobileBackground) {
        document.body.style.backgroundImage = 'url("' + event.background + '")';
      } else if (this.loginService.clientConfig$.getValue() && this.loginService.clientConfig$.getValue().background) {
        const bg = this.loginService.clientConfig$.getValue().background;
        document.body.style.backgroundImage = 'url("' + bg + '")';
      } else {
        document.body.style.backgroundImage = 'url(assets/images/main_mobile_background.jpg)';
      }
    } else {
      // show event background if login to event and background exists
      if (event?.background) {
        document.body.style.backgroundImage = 'url("' + event.background + '")';
      } else if (this.loginService.clientConfig$.getValue() && this.loginService.clientConfig$.getValue().background) {
        const bg = this.loginService.clientConfig$.getValue().background;
        document.body.style.backgroundImage = 'url("' + bg + '")';
      } else {
        document.body.style.backgroundImage = 'url(assets/images/main_background.jpg)';
      }
    }
  }
}
