import {Component, ElementRef, Injector, signal} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {IAnswer} from '../../../quiz-model/quiz';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {QUESTION_TYPE} from '../../shared/quiz-quiestion-types';
import {Constants} from '../../../../../../../core/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize, take, takeLast, tap, timer } from 'rxjs';

@Component({
  selector: 'app-question-check-choice-v2-participant',
  templateUrl: './question-check-choice-v2-participant.component.html',
  styleUrls: ['./question-check-choice-v2-participant.component.scss']
})
export class QuestionCheckChoiceV2ParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  displayedColumns = ['check', 'answer', 'correct'];
  dataSource: IAnswer[] = [];
  hideAnswer = signal(false);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
                super(injector, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setAnswersOrder();
  }

  initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];
    if (this.question.items) {
      if (this.question.storypoint === QUESTION_TYPE.CHOICE_V2 && !this.question.useCorrectAnswers) {
        this.question.items.forEach(answer => dataList.push(
          {answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex}));
      } else {
        this.question.items.forEach(answer => dataList.push(
          {check: '', answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id,
            orderIndex: answer.orderIndex, correctAnswer: answer.correctAnswer}));
      }
      let diff = this.dataSource.length !== dataList.length;
      if (!diff) {
        diff = this.dataSource.some(item => {
          const ind = dataList.findIndex(elem => {
            if (item.id === elem.id && item.answer === elem.answer && /*item.answerMatchingId === elem.answerMatchingId &&*/
              item.correctAnswer === elem.correctAnswer && item.orderIndex === elem.orderIndex) {
              return true;
            }
          });
          return ind === -1;
        });
      }
      if (diff) {
        dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
        this.dataSource = dataList;
      }
    }
  }

  switchItem(event, item) {
    if (!this.acceptAnswers) {
      return;
    }
    let answers: string[] = [];
    if (this.answers) {
      answers = this.answers;
      if (this.question.storypoint === QUESTION_TYPE.CHOICE_V2) {
        answers.splice(0, answers.length);
      }
      const ind = answers.indexOf(item);
      if (ind >= 0) {
        answers.splice(ind, 1);
        this.answers = answers;
      } else {
        answers.push(item);
        this.answers = answers;
      }
    } else {
      answers.push(item);
      this.answers = answers;
    }
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  private shuffleAnswers(arr: IAnswer[]): IAnswer[] {
    return arr.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
  }

  private setAnswersOrder(): void {
    if (this.question.shuffleAnswers) {
      this.hideAnswer.set(true);
      this.resetQuestionAnswers$
      .pipe(this.takeUntilAlive())
      .subscribe(() => this.dataSource = this.shuffleAnswers(this.dataSource));

      /** Shuffle only if the user hasn’t answered the question yet. Consider only second emission from answers$, as the first one comes from the BehaviorSubject initialization */
      this.answers$.pipe(take(2), takeLast(1), this.takeUntilAlive()).subscribe((answers) => {
        if (!answers?.length) this.dataSource = this.shuffleAnswers(this.dataSource);
        this.hideAnswer.set(false);
      });

      /** Show answer automatically after 3 seconds */
      timer(3000).subscribe(() => this.hideAnswer.set(false));
    }
  }
}
