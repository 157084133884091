import {
  Constants,
  EVENT_DOP_STATE,
  EVENT_REGISTRATION_MODE,
  EVENT_STATE,
  EVENT_TIMELINE_MODE, IEventEntityLink,
  IReleaseVersion
} from '../core/constants';
import {IMeeting} from './meeting/Meeting';
import {UtilsService} from '../core/utils.service';
import {COUNTRY_LANGUAGE, LANGUAGE} from '../core/language-constants';
import {Tag} from '@ninescopesoft/core';

export interface IManager {
  email: string;
  fullName: string;
  picture: string;
  userName: string;
}

export interface IManagerMap {
  [userId: string]: IManager;
}

export interface ICountryStatistics {
  countryName: string;
  countryTotal: number;
  languages: { [language: string]: number };
}

export interface ICountryStatisticsMap {
  [countryCode: string]: ICountryStatistics;
}

export interface ITag {
  name: string;
  color: string;
}

export class Event {
  public static readonly DB_PATH = 'events';
  public static readonly DB_MODULE_PATH = 'modules';
  public static readonly DB_TIMELINE_PATH = 'conference';
  public static readonly DB_MODULE_TIMELINE_PATH = 'modules';
  public static readonly DB_NOTES_PATH = 'app_users';
  public static readonly DB_QUESTION_CARDS = 'question-cards';
  public static readonly DB_EXAMS = 'exams';
  public static readonly COLORS = [
    'rgb(50 111 112)', 'rgb(0 160 162)',  'rgb(137 200 201)',
    'rgb(253 118 46)', 'rgb(219 130 83)', 'rgb(241 163 121)',
    'rgb(255 222 79)', 'rgb(255 235 149)', 'rgb(255 207 0)',
    'rgb(219 83 83)',  'rgb(255 139 139)', 'rgb(245 57 57)'
  ];


  eventId: string = null;
  state: 'public' | 'private' | 'draft' | 'link' = 'link';
  name: string = null;
  shortLink: string = null;
  createDate: Date = null;
  startDate: Date = null;
  realFinishDate: Date = null;
  endDate: number; // event end date
  description: string;
  deleted = 0;
  duration: number;
  eventTypeId: number = null;
  listUsersDialogMode: number  = Constants.LIST_USERS_MODE_ALLUSERS;
  logo?: string = null;
  managers: IManagerMap = {};
  presenters: IManagerMap = {};
  concierges: IManagerMap = {};
  speakers: IManagerMap = {};
  moods = [];
  isPublic = true;
  published = false;
  dopState: number;
  // Event creator id.
  ownerKey;
  readonlyHKVBS: number;
  hkvbsIntegrationId: string;
  template = false;
  prepPhaseStart: number;
  prepPhaseDuration: number;
  prepPhaseEnd: number;
  wrapUpPhaseStart: number;
  wrapUpPhaseDuration: number;
  wrapUpPhaseEnd: number;
  startDateFixed = true;
  durationFixed: boolean;
  endDateFixed: boolean;
  prepPhaseStartFixed: boolean;
  prepPhaseDurationFixed: boolean;
  prepPhaseEndFixed: boolean;
  wrapUpPhaseStartFixed: boolean;
  wrapUpPhaseDurationFixed: boolean;
  wrapUpPhaseEndFixed: boolean;
  registrationRequiredMode: EVENT_REGISTRATION_MODE = EVENT_REGISTRATION_MODE.NONE;
  forwardMode = false;
  forwardUrl: string;
  dateMode = 0;
  timelineMode: EVENT_TIMELINE_MODE = EVENT_TIMELINE_MODE.NO;
  supportMode = '0';
  locations = [];
  languages = [];
  defaultLanguage = Constants.TIMELINE_DEFAULT_LANGUAGE;
  locationInformation: string;
  locationLink: string;
  verticalBanner: string;
  horizontalBanner: string;
  background: string;
  mobileBackground: string;
  useWelcomeScreen: boolean;
  meeting: IMeeting;
  guest = false;
  guestName: boolean;
  guestEmail: boolean;
  disableManageTime: boolean;
  slotMode = false;
  hideSelfLearningUsersDetail: boolean;
  classCode: string;
  classCodeAutoupdate: boolean;
  // education fields
  lessonDuration: number;

  entityLink: IEventEntityLink;
  releaseVersion: IReleaseVersion;
  tags: {[key: string]: Tag} = {};
  enabledEventApi: boolean;

  public static getRandomColor(eventId): string {
    const delimiter = 100 / Event.COLORS.length;
    const hash = Number(UtilsService.hash(eventId, 2));
    const colorIdx = Math.floor(hash / delimiter);
    return Event.COLORS[colorIdx];
  }

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.eventId) {
      this.eventId = obj.eventId;
    }
    if (obj.state) {
      this.state = obj.state;
    }
    if (obj.name) {
      this.name = obj.name;
    }
    if (obj.shortLink) {
      this.shortLink = obj.shortLink;
    }
    if (obj.createDate) {
      this.createDate = (typeof obj.createDate.toDate === 'function') ? obj.createDate.toDate() : new Date(obj.createDate);
    }
    if (obj.startDate) {
      this.startDate = (typeof obj.startDate.toDate === 'function') ? obj.startDate.toDate() : new Date(obj.startDate);
    }
    if (obj.deleted) {
      this.deleted = obj.deleted;
    }
    if (obj.duration) {
      this.duration = obj.duration;
    }
    if (obj.eventTypeId) {
      this.eventTypeId = obj.eventTypeId;
    }
    if (obj.logo) {
      this.logo = obj.logo;
    }
    if (obj.realFinishDate) {
      const isTimestamp = typeof obj.realFinishDate.toDate === 'function';
      this.realFinishDate = isTimestamp ? obj.realFinishDate.toDate() : new Date(obj.realFinishDate);
    }
    if (obj.managers) {
      this.managers = obj.managers;
    }
    if (obj.presenters) {
      this.presenters = obj.presenters;
    }
    if (obj.concierges) {
      this.concierges = obj.concierges;
    }
    if (obj.speakers) {
      this.speakers = obj.speakers;
    }
    if (obj.moods) {
      this.moods = obj.moods;
    }
    if (obj.hasOwnProperty('isPublic')) {
      this.isPublic = obj.isPublic;
    }
    if (obj.listUsersDialogMode >= 0) {
      this.listUsersDialogMode = obj.listUsersDialogMode;
    }
    if (obj.published) {
      this.published = obj.published;
    }
    if (obj.ownerKey) {
      this.ownerKey = obj.ownerKey;
    }
    if (obj.readonlyHKVBS) {
      this.readonlyHKVBS = obj.readonlyHKVBS;
    }
    if (obj.hkvbsIntegrationId) {
      this.hkvbsIntegrationId = obj.hkvbsIntegrationId;
    }
    if (obj.template) {
      this.template = obj.template;
    }
    if (obj.endDate) {
      this.endDate = obj.endDate;
    }

    if (obj.prepPhaseStart) {
      this.prepPhaseStart = obj.prepPhaseStart;
    }
    if (obj.prepPhaseDuration) {
      this.prepPhaseDuration = obj.prepPhaseDuration;
    }
    if (obj.prepPhaseEnd) {
      this.prepPhaseEnd = obj.prepPhaseEnd;
    }
    if (obj.wrapUpPhaseStart) {
      this.wrapUpPhaseStart = obj.wrapUpPhaseStart;
    }
    if (obj.wrapUpPhaseDuration) {
      this.wrapUpPhaseDuration = obj.wrapUpPhaseDuration;
    }
    if (obj.wrapUpPhaseEnd) {
      this.wrapUpPhaseEnd = obj.wrapUpPhaseEnd;
    }
    if (obj.description) {
      this.description = obj.description;
    }

    if (obj.startDateFixed) {
      this.startDateFixed = obj.startDateFixed;
    }
    if (obj.durationFixed) {
      this.durationFixed = obj.durationFixed;
    }
    if (obj.endDateFixed) {
      this.endDateFixed = obj.endDateFixed;
    }
    if (obj.prepPhaseStartFixed) {
      this.prepPhaseStartFixed = obj.prepPhaseStartFixed;
    }
    if (obj.prepPhaseDurationFixed) {
      this.prepPhaseDurationFixed = obj.prepPhaseDurationFixed;
    }
    if (obj.prepPhaseEndFixed) {
      this.prepPhaseEndFixed = obj.prepPhaseEndFixed;
    }
    if (obj.wrapUpPhaseStartFixed) {
      this.wrapUpPhaseStartFixed = obj.wrapUpPhaseStartFixed;
    }
    if (obj.wrapUpPhaseDurationFixed) {
      this.wrapUpPhaseDurationFixed = obj.wrapUpPhaseDurationFixed;
    }
    if (obj.wrapUpPhaseEndFixed) {
      this.wrapUpPhaseEndFixed = obj.wrapUpPhaseEndFixed;
    }
    if (obj.dopState) {
      this.dopState = obj.dopState;
    }
    if (obj.hasOwnProperty('registrationRequiredMode')) {
      this.registrationRequiredMode = obj.registrationRequiredMode;
    }
    if (obj.hasOwnProperty('forwardMode')) {
      this.forwardMode = obj.forwardMode;
    }
    if (obj.forwardUrl) {
      this.forwardUrl = obj.forwardUrl;
    }
    if (obj.hasOwnProperty('dateMode')) {
      this.dateMode = obj.dateMode;
    }
    if (obj.hasOwnProperty('timelineMode')) {
      this.timelineMode = obj.timelineMode;
    }
    if (obj.hasOwnProperty('supportMode')) {
      this.supportMode = obj.supportMode;
    }
    if (obj.locations) {
      this.locations = obj.locations;
    }
    if (obj.languages) {
      this.languages = obj.languages;
    }
    if (obj.locationInformation) {
      this.locationInformation = obj.locationInformation;
    }
    if (obj.locationLink) {
      this.locationLink = obj.locationLink;
    }
    if (obj.verticalBanner) {
      this.verticalBanner = obj.verticalBanner;
    }
    if (obj.horizontalBanner) {
      this.horizontalBanner = obj.horizontalBanner;
    }
    if (obj.background) {
      this.background = obj.background;
    }
    if (obj.mobileBackground) {
      this.mobileBackground = obj.mobileBackground;
    }
    if (obj.hasOwnProperty('useWelcomeScreen')) {
      this.useWelcomeScreen = obj.useWelcomeScreen;
    }
    if (obj.meeting) {
      this.meeting = obj.meeting;
    }
    if (obj.hasOwnProperty('guest')) {
      this.guest = obj.guest;
    }
    if (obj.hasOwnProperty('guestName')) {
      this.guestName = obj.guestName;
    }
    if (obj.hasOwnProperty('guestEmail')) {
      this.guestEmail = obj.guestEmail;
    }
    if (obj.hasOwnProperty('slotMode')) {
      this.slotMode = obj.slotMode;
    }
    if (obj.hasOwnProperty('hideSelfLearningUsersDetail')) {
      this.hideSelfLearningUsersDetail = obj.hideSelfLearningUsersDetail;
    }
    if (obj.hasOwnProperty('classCode')) {
      this.classCode = obj.classCode;
    }
    if (obj.hasOwnProperty('classCodeAutoupdate')) {
      this.classCodeAutoupdate = obj.classCodeAutoupdate;
    }
    if (obj.hasOwnProperty('lessonDuration')) {
      this.lessonDuration = obj.lessonDuration;
    }
    if (obj.hasOwnProperty('disableManageTime')) {
      this.disableManageTime = obj.disableManageTime;
    }
    if (obj.hasOwnProperty('defaultLanguage')) {
      this.defaultLanguage = obj.defaultLanguage;
    }
    if (this.eventId && !obj.defaultLanguage) {
      this.defaultLanguage = LANGUAGE.EN;
    }
    if (obj.hasOwnProperty('entityLink')) {
      this.entityLink = obj.entityLink;
    }
    if (obj.hasOwnProperty('releaseVersion')) {
      this.releaseVersion = obj.releaseVersion;
    }
    if (obj.hasOwnProperty('tags')) {
      this.tags = obj.tags;
    }
    if (obj.hasOwnProperty('enabledEventApi')) {
      this.enabledEventApi = obj.enabledEventApi;
    }
  }

  getColor() {
    const delimiter = 100 / Event.COLORS.length;
    const hash = Number(UtilsService.hash(this.eventId, 2));
    const colorIdx = Math.floor(hash / delimiter);
    return Event.COLORS[colorIdx];
  }

  /**
   * Condition for sort event on dashboard and menu:
   * Starting time is closer to current time.
   * @param {Event} event
   * @param {number} ctime - current time
   * @returns {number}
   */
  sortCondition(ctime: number) {
    return Math.abs(ctime - this.startDate.getTime());
  }

  getStartDate(): number {
    return (this.startDate instanceof Date) ? this.startDate.getTime() : this.startDate as number;
  }

  getEventSate() {
    if (!this.published) {
      return EVENT_STATE.DRAFT;
    } else if (this.published && !this.isPublic) {
      return EVENT_STATE.PRIVATE;
    } else if (this.published && this.isPublic && this.dopState !== EVENT_DOP_STATE.PUBLIC_BY_LINK) {
      return EVENT_STATE.PUBLIC_ON_DASHBOARD;
    } else if (this.published && this.isPublic && this.dopState === EVENT_DOP_STATE.PUBLIC_BY_LINK) {
      return EVENT_STATE.PUBLIC_BY_LINK;
    }
  }

  setEventState(state: EVENT_STATE) {
    if (state === EVENT_STATE.DRAFT) {
      this.dopState = 0;
      this.isPublic = false;
      this.published = false;
    } else if (state === EVENT_STATE.PRIVATE) {
      this.dopState = 0;
      this.isPublic = false;
      this.published = true;
    } else if (state === EVENT_STATE.PUBLIC_ON_DASHBOARD) {
      this.dopState = 0;
      this.isPublic = true;
      this.published = true;
    } else if (state === EVENT_STATE.PUBLIC_BY_LINK) {
      this.dopState = EVENT_DOP_STATE.PUBLIC_BY_LINK;
      this.isPublic = true;
      this.published = true;
    }
  }

  isManager(userId: string) {
    return !!(this.managers && this.managers[userId]);
  }

  isPresenter(userId: string) {
    return !!(this.presenters && this.presenters[userId]);
  }

  isConcierge(userId: string) {
    return !!(this.concierges && this.concierges[userId]);
  }

  isSpeaker(userId: string) {
    return !!(this.speakers && this.speakers[userId]);
  }

  isOwner(userId: string) {
    return this.ownerKey === userId;
  }

  getMultilingual() {
    const languageList = Object.values(COUNTRY_LANGUAGE).map(l => l.languageISOCode);
    const multilingual = (this.languages || []).filter(l => languageList.includes(l)).length > 1;
    const usedLanguages = (this.languages || []).filter(l => languageList.includes(l));
    return {multilingual, usedLanguages};
  }

  toObject() {
    const obj = {...this};
    delete obj.eventId;
    return obj;
  }
}

export class UserEvent extends Event {
  customData: {[key: string]: any} = {};

  constructor(obj: any) {
    super(obj);

    if (obj.customData) {
      this.customData = obj.customData;
    }
  }
}

export interface IEventCreateDto {
  name: string;
  description: string;
  state: string;
  presenters: string[];
  concierges: string[];
  start: number;
  end: number;
  mode: string;
  image: string;
  defaultLanguage: string;
  hideSelfLearning: boolean;
}

export interface IGuestEventInfoDto {
  id: string;
  title: string;
  description: string;
  logo: string;
  guestName: boolean;
  guestEmail: boolean;
}

