import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import * as ui from '../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {Constants, DATE_FORMAT, EVENT_DATE_MODE, EVENT_DOP_STATE} from '../../core/constants';
import {ExtEvent} from '../../model/ExtEvent';
import {auditTime, BehaviorSubject, Subscription} from 'rxjs';
import {EventsDataService} from '../../services/events-data.service';
import {StdComponent} from '../../core/std-component';
import {TimeLineService} from '../../services/time-line.service';
import {EventType} from '../../model/EventType';
import {CommonService} from '../../core/common.service';
import {TimerService} from '../../core/timer.service';
import {clone, merge} from 'lodash';
import {Event, IManager} from '../../model/Event';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent extends StdComponent implements OnInit, OnDestroy {
  readonly Constants = Constants;
  readonly EVENT_DOP_STATE = EVENT_DOP_STATE;
  readonly EVENT_DATE_MODE = EVENT_DATE_MODE;

  @Input() set currentEvent(value: ExtEvent) {
    this.event = value instanceof Event ? new ExtEvent(this.common.utils, value) : value;
    this.eventFinishDateStr = this.common.utils.formatDate(this.event.endDate, DATE_FORMAT.DD_MM_YYYY);
    const mergeManagers = merge(clone(this.event.presenters), clone(this.event.speakers));
    this.presenters.next(Object.keys(mergeManagers || {}).map(uId => mergeManagers[uId]));
  }
  @Input() eventStatus = [];
  @Input() asNotify = false;
  @Input() currentUser;
  @Input() eventTypes: Array<EventType> = [];

  presenters = new BehaviorSubject<IManager[]>([]);
  public eventStatusSubscription: Subscription;
  event: ExtEvent;
  eventFinishDateStr: string;

  constructor(protected injector: Injector,
              private store: Store<fromRoot.State>,
              private common: CommonService,
              public timeLineService: TimeLineService,
              private eventsDataService: EventsDataService,
              public timerService: TimerService) {
    super(injector);
  }

  ngOnInit() {
    if (this.asNotify) {
      this.eventStatusSubscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.eventStatusSubscription) {
      this.eventStatusSubscription.unsubscribe();
    }
  }

  get hasFullAccess() {
    return this.currentUser && (
      (this.currentUser.isAdmin || this.currentUser.isModerator) || this.event.managers[this.currentUser.userId]
    );
  }

  generateDirectImageLink(link: String) {
    if ((link !== undefined) && (link.indexOf('drive.google.com/file/d/') !== -1)) {
      link = link.replace('https://drive.google.com/file/d/', '');
      link = link.replace('http://drive.google.com/file/d/', '');
      link = link.replace('www.drive.google.com/file/d/', '');
      link = link.replace('drive.google.com/file/d/', '');
      link = link.replace('/view?usp=sharing', '');
      link = link.replace('/view', '');
      link = link.replace('/', '');
      link = 'https://www.googledrive.com/host/' + link;
    } else if ((link !== undefined) && (link.indexOf('//drive.google.com/a') !== -1)) {
      // if it's a file from folder which is available only to domain, let's take only its ID
      const searchStr = 'file/d/';
      let fileID = link.substring(link.indexOf(searchStr) + searchStr.length);
      fileID = fileID.substring(0, fileID.indexOf('/'));
      link = 'https://www.googledrive.com/host/' + fileID;
    }

    return link;
  }

  closeMenu() {
    this.store.dispatch(new ui.SetMenuOpenAction(false));
    if (this.asNotify) {
      this.eventsDataService.dispatchSetNotifyEventMessage({closeId: this.event.eventId});
    }
  }

  closeEventCard(event) {
    this.eventsDataService.dispatchSetNotifyEventMessage({closeId: this.event.eventId});
    event.cancelBubble = true;
  }

  eventStatusSubscribe() {
    if (this.eventStatusSubscription) {
      this.eventStatusSubscription.unsubscribe();
    }
    if (!this.eventStatusSubscription || this.eventStatusSubscription.closed) {
      this.eventStatusSubscription = this.timerService.getTimer()
        .pipe(auditTime(5000), this.takeUntilAlive())
        .subscribe(() => {
          this.eventStatus = this.event.getEventStatus();
        });
    }
  }

  getEventType(id) {
    return this.eventTypes.find(item => item.id === id);
  }

  getEventTypeName(id) {
    const eventType = this.getEventType(id);
    if (eventType) {
      let i18n = this.common.utils.i18n('event_type.' + eventType.name);
      if (i18n === ('event_type.' + eventType.name)) {
        i18n = eventType.name;
      }
      return i18n;
    }
    return '';
  }

  getEventTypeLogo(id) {
    const eventType = this.getEventType(id);
    if (eventType) {
      return eventType.icon;
    }
    return Constants.EVENT_TYPE_DEFAULT_ICON;
  }
}
