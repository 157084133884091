import {Injectable} from '@angular/core';
import {AbstractContent} from '../model/content/AbstractContent';
import {Constants, ILanguageParams} from './constants';
import {cloneDeep} from 'lodash';
import {SectionContent} from '../model/content/SectionContent';
import {BehaviorSubject, Subject} from 'rxjs';
import {RootLocalStorageService} from './root-local-storage.service';
import {APP_MODE, LoginService} from '../login/login.service';
import {IDocumentPathParams, IExtendedDocumentPathParams} from '../services/event-mode-api.service';
import {CONTAINER_ITEM_TYPE, ContentContainerItem} from '../model/content/ContentContainer';
import {Event} from '../model/Event';
import {UtilsService} from './utils.service';

const CLIPBOARD_HISTORY_STORAGE_PATH = 'clipboard_history';
const MAX_CLIPBOARD_HISTORY = 7;

const CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY_STORAGE_PATH = 'clipboard_items_history';
const MAX_CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY = 10;

export enum CLIPBOARD_HISTORY_LOCATION {
  CONFERENCE = 'conference',
  EDUCATION = 'education'
}

interface IClipboardHistory {
  [clientId: string]: {
    [location: string]: any[];
  };
}

export interface IClipboardContentContainerItem extends IExtendedDocumentPathParams {
  type: CONTAINER_ITEM_TYPE;
  shortTitle: string;
  eventShortName: string;
  url: string;
  historyOrderIndex: number;
  provider: string;
  pinned?: boolean;
}

export type TSimplifiedContent = Pick<AbstractContent, 'id' | 'eventId' | 'type' | 'parentId' | 'orderIndex' | 'title'>;

export interface IExtTSimplifiedContent extends TSimplifiedContent {
  dirty: boolean;
}

export interface ISimplifiedContent extends IExtTSimplifiedContent {
  iconCSS: string;
}

export type TContent = AbstractContent | SectionContent;

@Injectable()
export class ClipboardService {
  private _content: TContent;
  private _copyType: string;
  private _moveSectionList: any[];
  private _moveSectionListId: string[];
  private _daySection: SectionContent;
  private clientConferenceHistory: TSimplifiedContent[] = [];
  private clientEducationHistory: TSimplifiedContent[] = [];
  copySectionListResult$ = new Subject<{newId: string, oldId: string, parentId: string}[]>();
  clipboardSectionsContentsHistory$ = new BehaviorSubject<ISimplifiedContent[]>([]);
  clipboardSectionHistory$ = new BehaviorSubject<TSimplifiedContent[]>([]);
  clipboardModulesContentsHistory$ = new BehaviorSubject<ISimplifiedContent[]>([]);
  clipboardModuleHistory$ = new BehaviorSubject<TSimplifiedContent[]>([]);
  clipboardHistoryObject: IClipboardHistory = {};
  private _clientId: string;
  clipboardContentContainerItems$ = new BehaviorSubject<IClipboardContentContainerItem[]>([]) ;
  content$ = new BehaviorSubject<TContent>(null);
  hasAttachedSections = false;

  constructor(private loginService: LoginService,
              private utils: UtilsService,
              private localStorageService: RootLocalStorageService) { }

  get content(): AbstractContent | SectionContent {
    return this._content;
  }

  get isTypeSection() {
    return this._content &&
      (this._content.type === Constants.CONTENT_TYPE_SECTION || this._content.type === Constants.CONTENT_TYPE_TIMELINE);
  }

  get copyType() {
    return this._copyType;
  }

  get isCutType() {
    return this._content && this._copyType === Constants.CONTENT_CLIPBOARD_CUT;
  }

  get isCopyType() {
    return this._content && this._copyType === Constants.CONTENT_CLIPBOARD_COPY;
  }

  get moveSectionList(): any[] {
    return this._moveSectionList;
  }

  set copyContent(object: {content: AbstractContent, copyType: string, moveSectionList?: any[]}) {
    if (!object) {
      this._content = null;
      this._copyType = null;
      this._moveSectionList = null;
      this._moveSectionListId = null;
      this._daySection = null;
      this.hasAttachedSections = false;
    } else {
      this._content = cloneDeep(object.content);
      this.hasAttachedSections = object.content.isTypeSection &&
        ((object.content as SectionContent).isAttached() ||
        (object.moveSectionList || []).some(s => s.isAttached()));
      this.sanitizeContent(this._content);
      this._copyType = object.copyType;
      this._moveSectionList = (object.moveSectionList || []).map(o => this.sanitizeContent(cloneDeep(o)));
      this.moveSectionListId = object.moveSectionList;
      this.saveClipboardHistory();
    }
    this.content$.next(this._content);
  }

  private sanitizeContent(object: any) {
    if (object) {
      delete object['dependencyQuestionnaire'];
      delete object['shortcuts'];
      if (object.type === Constants.CONTENT_TYPE_TIMELINE && object instanceof AbstractContent) {
        object.type = Constants.CONTENT_TYPE_SECTION;
      }
    }
    return object;
  }

  private set moveSectionListId(value: any[]) {
    if (!value) {
      this._moveSectionListId = null;
      return;
    }
    this._moveSectionListId = [];
    for (const obj of value) {
      this._moveSectionListId.push(obj.id);
    }
  }

  /**
   * Get true if value in copy section child list
   * @param value - Id.
   */
  inMoveList(value) {
    if (!this._moveSectionListId || this._moveSectionListId.length === 0 || !value) {
      return false;
    }
    return this._moveSectionListId.includes(value);
  }

  get daySection(): SectionContent {
    return this._daySection;
  }

  set daySection(value: SectionContent) {
    this._daySection = value;
  }

  private emitClipboardHistory(clipboardHistoryObject: IClipboardHistory) {
    this.clientConferenceHistory = (clipboardHistoryObject[this._clientId] || {})[CLIPBOARD_HISTORY_LOCATION.CONFERENCE] ?? [];
    this.clientEducationHistory = (clipboardHistoryObject[this._clientId] || {})[CLIPBOARD_HISTORY_LOCATION.EDUCATION] ?? [];
    this.clipboardSectionHistory$.next((this.clientConferenceHistory || [])
      .filter(o => o.type === Constants.CONTENT_TYPE_SECTION));
    this.clipboardSectionsContentsHistory$.next((this.clientConferenceHistory || [])
      .filter(o => o.type !== Constants.CONTENT_TYPE_SECTION)
      .map(o => new Object({iconCSS: 'material-icons-outlined', ...o}) as ISimplifiedContent));
    this.clipboardModuleHistory$.next((this.clientEducationHistory || [])
      .filter(o => o.type === Constants.CONTENT_TYPE_SECTION));
    this.clipboardModulesContentsHistory$.next((this.clientEducationHistory || [])
      .filter(o => o.type !== Constants.CONTENT_TYPE_SECTION)
      .map(o => new Object({iconCSS: 'material-icons', ...o}) as ISimplifiedContent));
  }

  reloadClipboardHistory(clientId: string) {
    this._clientId = clientId;
    this.clipboardHistoryObject = {};
    if (!clientId) {
      this.emitClipboardHistory({});
    }
    this.clipboardHistoryObject = JSON.parse(this.localStorageService.get(CLIPBOARD_HISTORY_STORAGE_PATH) ?? '{}');
    this.emitClipboardHistory(this.clipboardHistoryObject);
  }

  refreshClipboardHistory() {
    if (!this._clientId) {
      return;
    }
    this.reloadClipboardHistory(this._clientId);
  }

  private get currentLocation() {
    return !this.loginService.educationMode ? CLIPBOARD_HISTORY_LOCATION.CONFERENCE : CLIPBOARD_HISTORY_LOCATION.EDUCATION;
  }

  private saveLocalHistory(history, location) {
    if (!this.clipboardHistoryObject[this._clientId]) {
      this.clipboardHistoryObject[this._clientId] = {};
    }
    this.clipboardHistoryObject[this._clientId][location] = history;
    this.emitClipboardHistory(this.clipboardHistoryObject);
    this.localStorageService.set(CLIPBOARD_HISTORY_STORAGE_PATH, JSON.stringify(this.clipboardHistoryObject));
  }

  private simplify(content: AbstractContent): IExtTSimplifiedContent {
    return {
      id: content.id,
      eventId: content.eventId,
      parentId: content.parentId,
      type: content.type,
      orderIndex: content.orderIndex,
      title: content.title,
      dirty: content['dirty']
    };
  }

  private saveClipboardHistory() {
    if (!this._clientId || !this._content || this.isCutType ||
         (this._content && this._content['freeSlotType']) || this.hasAttachedSections) {
      return;
    }
    const c = this.simplify(this._content);
    c.orderIndex = new Date().getTime();
    const location = this.getContentLocation(c.id);
    const history = location === CLIPBOARD_HISTORY_LOCATION.CONFERENCE ? this.clientConferenceHistory : this.clientEducationHistory;
    const index = history.findIndex(it => it.id === c.id);
    if (index > -1) {
      history.splice(index, 1);
    }
    history.splice(0, 0, c);
    if (history.length > MAX_CLIPBOARD_HISTORY) {
      history.splice(history.length - 1, 1);
    }
    this.saveLocalHistory(history, location);
  }

  removeFromClipboardHistory(id: string) {
    if (!id) {
      return;
    }
    const location = this.getContentLocation(id);
    const history = location === CLIPBOARD_HISTORY_LOCATION.CONFERENCE ? this.clientConferenceHistory : this.clientEducationHistory;
    const index = history.findIndex(it => it.id === id);
    if (index > -1) {
      history.splice(index, 1);
    }
    this.saveLocalHistory(history, location);
  }

  clearClipboardHistory() {
    this.emitClipboardHistory({});
    delete this.clipboardHistoryObject[this._clientId];
    this.localStorageService.set(CLIPBOARD_HISTORY_STORAGE_PATH, JSON.stringify(this.clipboardHistoryObject));
  }

  getContentLocation(id: string) {
    for (const location of [CLIPBOARD_HISTORY_LOCATION.CONFERENCE, CLIPBOARD_HISTORY_LOCATION.EDUCATION]) {
      const history = (this.clipboardHistoryObject[this._clientId] || {})[location] || [];
      if (history.find(o => o.id === id)) {
        return location;
      }
    }
    return this.currentLocation;
  }

  reloadClipboardContentContainerHistory() {
    const storageObject = this.localStorageService.get(CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY_STORAGE_PATH);
    const history = JSON.parse(storageObject ?? '[]');
    this.clipboardContentContainerItems$.next(history
      .sort((a, b) =>
        (a.pinned ? Number.MAX_VALUE - a.historyOrderIndex : a.historyOrderIndex) <
        (b.pinned ? Number.MAX_VALUE - b.historyOrderIndex : b.historyOrderIndex) ? 1 : -1));
  }

  copyContentContainerItemToClipboard(item: ContentContainerItem, docPath: IDocumentPathParams,
                                      contentTitle: string, eventShortName: string, languageParams: ILanguageParams, dirty: boolean) {
    const currentLocation = () => {
      switch (this.loginService.applicationMode) {
        case APP_MODE.EDUCATION:
          return Event.DB_MODULE_TIMELINE_PATH;
        case APP_MODE.TIMELINE:
          return Event.DB_TIMELINE_PATH;
      }
    };

    const shortTitle = () => {
      let text: string;
      switch (item.type) {
        case CONTAINER_ITEM_TYPE.TEXT:
          text = this.utils.htmlToText(UtilsService.getByLanguage(item, 'data', languageParams) );
          return (text ? `${text.substring(0, 100)}... (©${contentTitle})` : contentTitle).trim();
        case CONTAINER_ITEM_TYPE.QUIZ:
          text = UtilsService.getByLanguage(Object.values(item.data.questions || {})
            .sort(this.utils.comparator(Constants.ORDERINDEX))[0], 'caption', languageParams);
          return (text ? `${text} (©${contentTitle})` : contentTitle).trim();
        case CONTAINER_ITEM_TYPE.TASK:
          return (item.data.title ? `${item.data.title} (©${contentTitle})` : contentTitle).trim();
        default:
          return contentTitle.trim();
      }
    };

    const setProvide = () => {
      switch (item.type) {
        case CONTAINER_ITEM_TYPE.EMBEDDED_FRAME:
          return item.data.provider;
        default:
          return null;
      }
    };

    const newObject: IClipboardContentContainerItem = {
      clientId: this.loginService.client_id$.getValue(),
      location: currentLocation(),
      eventId: docPath.eventId,
      sectionId: docPath.sectionId,
      contentId: docPath.contentId,
      containerId: docPath.containerId,
      type: item.type,
      provider: setProvide(),
      shortTitle: shortTitle(),
      eventShortName: eventShortName,
      url: window.location.href,
      dirty: dirty,
      historyOrderIndex: new Date().getTime()
    };
    let history = this.clipboardContentContainerItems$.getValue();
    const existObject = history
      .find(o => o.type === newObject.type && o.containerId === newObject.containerId &&
        o.contentId === newObject.contentId && !!o.dirty === !!newObject.dirty);
    if (existObject) {
      existObject.historyOrderIndex = new Date().getTime();
    } else {
      history.push(newObject);
      history = history.sort((a, b) => a.historyOrderIndex < b.historyOrderIndex ? 1 : -1);
      const historyNotPinned = history.filter(it => !it.pinned);
      if (historyNotPinned.length > MAX_CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY) {
        const last = historyNotPinned[historyNotPinned.length - 1];
        history = history.filter(it => it.historyOrderIndex !== last.historyOrderIndex);
      }
    }
    this.localStorageService.set(CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY_STORAGE_PATH, JSON.stringify(history));
    this.reloadClipboardContentContainerHistory();
  }

  removeContentContainerItemToClipboard(item: IClipboardContentContainerItem) {
    const history = this.clipboardContentContainerItems$.getValue().filter(o => o.historyOrderIndex !== item.historyOrderIndex);
    this.localStorageService.set(CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY_STORAGE_PATH, JSON.stringify(history));
    this.reloadClipboardContentContainerHistory();
  }

  clearContentContainerClipboardHistory() {
    const history = this.clipboardContentContainerItems$.getValue().filter(o => o.pinned);
    this.localStorageService.set(CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY_STORAGE_PATH, JSON.stringify(history));
    this.reloadClipboardContentContainerHistory();
  }

  pinInListContentContainerClipboardHistory(item: IClipboardContentContainerItem) {
    const history = this.clipboardContentContainerItems$.getValue();
    const historyItem = history.find(o => o.historyOrderIndex === item.historyOrderIndex);
    historyItem.pinned = !item.pinned;
    this.localStorageService.set(CLIPBOARD_CONTENT_CONTAINER_ITEMS_HISTORY_STORAGE_PATH, JSON.stringify(history));
    this.reloadClipboardContentContainerHistory();
  }
}
