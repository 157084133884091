import {AbstractContent} from './AbstractContent';

export class SlideContent extends AbstractContent {
  type = 'slide';
  slide: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.slide) {
      this.slide = obj.slide;
    }
  }

  mergeContent(src: SlideContent) {
    super.mergeContent(src);

    if (this.slide !== src.slide) {
      this.slide = src.slide;
    }
  }
}
