import {Injectable} from '@angular/core';
import {AbstractMergeService} from '../../service/abstract-merge-service';
import {ILanguageParams} from '../../../../core/constants';
import {ISlideComponent} from '../../time-line-content-container-detail/time-line-content-container-detail.component';
import {TextNode} from './text-note-model/text-note-model';

const TEXT_SPLITTER = `<div><br></div>`;

@Injectable({
  providedIn: 'root'
})
export class TextNoteMergeServiceService extends AbstractMergeService {
  constructor() {
    super();
  }

  public merge(mergeTo: ISlideComponent, mergeWith: ISlideComponent, languageParams: ILanguageParams) {
    let dataTo = TextNode.getText(mergeTo.containerItem.data);
    const dataWith = TextNode.getText(mergeWith.containerItem.data);
    if (!dataWith) {
      return mergeTo;
    }
    const isMultilingual = languageParams.usedMultilingualContent;
    if (typeof dataWith === 'string' && typeof dataTo === 'string') {
      if (isMultilingual) {
        dataTo = {[languageParams.defaultLanguage]: `${dataTo}${TEXT_SPLITTER}${dataWith}`};
      } else {
        dataTo = `${dataTo}${TEXT_SPLITTER}${dataWith}`;
      }
    } else if (typeof dataWith === 'string' && typeof dataTo !== 'string') {
      const defLang = languageParams.defaultLanguage;
      dataTo[defLang] = `${dataTo[defLang] ?? ''}${dataTo[defLang] ? TEXT_SPLITTER : ''}${dataWith}`;
    } else if (typeof dataTo === 'string' && typeof dataWith !== 'string') {
      const defLang = languageParams.defaultLanguage;
      const defText = dataTo;
      dataTo = {[defLang]: defText};
      for (const lang of Object.keys(dataWith)) {
        const withLangText = dataWith[lang];
        dataTo[lang] = `${dataTo[lang] ?? defText}${TEXT_SPLITTER}${withLangText}`;
      }
    } else if (typeof dataWith !== 'string' && typeof dataTo !== 'string') {
      const defLang = languageParams.defaultLanguage;
      const defText = dataTo[defLang];
      for (const lang of Object.keys(dataWith)) {
        const withLangText = dataWith[lang];
        dataTo[lang] = `${dataTo[lang] ?? defText}${TEXT_SPLITTER}${withLangText}`;
      }
    }
    mergeTo.componentRef.instance.componentRef.instance.value = dataTo;
    mergeTo.componentRef.instance.componentRef.instance.valueChange.emit(dataTo);
  }
}
