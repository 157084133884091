import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-notification-snackbar',
  templateUrl: './notification-snackbar.component.html',
  standalone: true,
  imports: [
    MatIcon,
    NgIf
  ],
  styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              public snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>) { }

  get getIcon() {
    switch (this.data.type) {
      case 'success': return 'done';
      case 'error': return 'error';
      case 'warning': return 'report_problem';
      case 'info': return 'announcement';
      default: return '';
    }
  }
}
