import {Injectable} from '@angular/core';
import {CommonService} from '../core/common.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Subject, Subscription} from 'rxjs';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {LoginService} from '../login/login.service';

interface IApplicationVersion {
  version: string;
  text?: string;
}

@Injectable({
  providedIn: 'root'
})
export class VersionControlService {

  subscriptionToken: Subscription;
  requiredReload$: Subject<boolean> = new Subject<boolean>();
  appVersion: IApplicationVersion;

  constructor(private common: CommonService
            , protected auth: LoginService
            , private afs: AngularFirestore
            , private afDB: AngularFireDatabase) { }

  init() {
    this.auth.getAuthenticatedUser().subscribe(authUser => {
      if (authUser && authUser.uid) {
        this.unsubscribe();
        this.subscriptionToken = this.afs.collection('versions')
          .doc<IApplicationVersion>('application')
          .valueChanges()
          .subscribe((doc) => {
            if (doc && doc.version) {
              this.appVersion = doc;
              const res = this.common.utils.versionCompare(doc.version, this.common.getEnv().version);
              if (res > 0) {
                this.requiredReload$.next(true);
              }
              const userId = this.auth.getAuthUser().uid;
              const path = `client_data/${this.auth.client_id$.getValue()}/user_log/${userId}`;
              this.afDB.object(path).set({date: new Date().getTime(), version: this.common.getEnv().version});
            }
          });
      } else {
        this.unsubscribe();
      }
    });
  }

  unsubscribe () {
    if (this.subscriptionToken) {
      this.subscriptionToken.unsubscribe();
      this.subscriptionToken = null;
    }
  }
}
