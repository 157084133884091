import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {EventsDataService} from '../../services/events-data.service';
import {BehaviorSubject, first, firstValueFrom, take} from 'rxjs';
import {Constants, DATE_FORMAT, EVENT_DATE_MODE, LOAD_STATE} from '../../core/constants';
import {UtilsService} from '../../core/utils.service';
import {ContentService} from '../../services/content.service';
import {LoginService} from '../../login/login.service';
import {AppUser} from '../../model/AppUser';
import {Event, IManager} from '../../model/Event';
import {cloneDeep, isEmpty, isEqual, max, union} from 'lodash';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {UsersDataService} from '../../services/users-data.service';
import {AngularFirestore, DocumentReference, QueryFn, SetOptions} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {
  AnswerEquality,
  EventQuestion,
  IGroupCorrectAnswers,
  IQuizQuestionsGroupsCorrectAnswersMap,
  TGroupAnswerQuestion
} from '../../model/EventQuestion';
import {StdComponent} from '../../core/std-component';
import firebase from 'firebase/compat/app';
import {EventApiService} from '../../services/event-api.service';
import {SectionContent} from '../../model/content/SectionContent';
import {UserApiService} from '../../services/user-api.service';
import {CommonService} from '../../core/common.service';
import {ClientApiService} from '../../services/client-api.service';
import {ClientConfig} from '../../model/ClientConfig';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Module as EduModule} from '@ninescopesoft/core';
import {EventModeApiService, IDocumentPathParams} from '../../services/event-mode-api.service';
import {CONTAINER_ITEM_TYPE, ContentContainer} from '../../model/content/ContentContainer';
import {FeaturesService} from '../../core/features.service';
import {QUESTION_FORMAT_VERSION2, Quiz} from '../../modules/content-container/components/quiz/quiz-model/quiz';
import {ConferenceUser} from '../../model/event-mode/ConferenceUser';
import {StorageDataService} from '../../services/storage-data.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {MatDialog} from '@angular/material/dialog';
import {EditDialogComponent} from '../../dialog-components/edit-dialog/edit-dialog.component';
import {StatisticsApiServiceService} from '../../services/statistics-api-service.service';
import {TimeLineService} from '../../services/time-line.service';
import {InstantSettings} from '../../model/event-mode/InstantSettings';
import {QUESTION_TYPE} from '../../modules/content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';
import {IWriteCache} from './st-intercace';
import {
  createEventsLastActivityStatistics,
  createEventsSelfLearningModulesActivityStatistics,
  createEventsSelfLearningModulesStatistics
} from './tasks/001-update-statistics';
import {updateOnlineStatistics, updateRegisteredStatistics} from './tasks/002-online-statistics';
import {updateQuestionnaireFontSize} from './tasks/003-update-question-font-size';
import {jsonContentExport, jsonExport, jsonImport} from './tools/json-export-import';
import {UploadService} from '../../core/upload.service';
import {informationAboutEventsWithLostSections} from './tasks/004-info-events-with-lost-sections';
import {informationAboutEventsWithQuizContainsQuestionsTypeFileUpload} from './tasks/005-info-events-with-questions-type-file-upload';
import {checkAndFixEventRealFinishDatePermanent} from './tasks/006-check-realfinishdate';
import {informationAboutModulesLostRecordingSnippets} from './tasks/007-info-modules-with-lost-recording-snippet';
import {ExamApiService} from '../../exams/exam/exam-service/exam-api.service';
import {clearExamUsersStatistics} from './tools/clear-exam-user-statistics';

interface IObject {
  id: string;
  name: string;
}

interface IChild {
  sectionId: string;
  parentId: string;
  orderIndex: number;
  level?: number;
  title: string;
}

interface IIndexTest {
  sectionId: string;
  parentId: string;
  title: string;
  minIndex: number;
  maxIndex: number;
  level: number;
  isLost: boolean;
  childrenOutOfIndex: IChild[];
}

@Component({
  selector: 'app-service-tasks',
  templateUrl: './service-tasks.component.html',
  styleUrls: ['./service-tasks.component.scss']
})

export class ServiceTasksComponent extends StdComponent implements OnInit {
  currentUser: AppUser;

  batch: firebase.firestore.WriteBatch;
  batchCounter = 0;
  logEven = [];
  logEvenId = {};
  contentCounter = 0;
  load = false;
  progress: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentClientId;
  status: string[];
  status1: string[];
  status2: string[];
  status3: string[];
  clientIdsList: string[];
  clientList: ClientConfig[];
  prevChoice: any;
  uppy;
  isProduction = this.commonService.getEnv().production;
  @ViewChild('date') el: ElementRef;

  constructor(protected injector: Injector,
              public eventDataService: EventsDataService,
              public commonService: CommonService,
              public utils: UtilsService,
              public contentService: ContentService,
              public loginService: LoginService,
              public dataService: EventsDataService,
              public userDataService: UsersDataService,
              public eventApiService: EventApiService,
              public clientApiService: ClientApiService,
              public featuresService: FeaturesService,
              public store: Store<fromRoot.State>,
              public afs: AngularFirestore,
              public aff: AngularFireFunctions,
              public afDB: AngularFireDatabase,
              public angularFireStorage: AngularFireStorage,
              public http: HttpClient,
              public userApi: UserApiService,
              public eventModeApi: EventModeApiService,
              public dialog: MatDialog,
              public storageDataService: StorageDataService,
              public statisticsApiServiceService: StatisticsApiServiceService,
              public timeLineService: TimeLineService,
              public uploadService: UploadService,
              public examApiService: ExamApiService) {
    super(injector);
    this.currentClientId = this.loginService.client_id$.getValue();
  }

  ngOnInit(): void {
    firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then(snapshot => {
        this.clientIdsList = snapshot.docs.map(d => d.id);
        this.clientList = snapshot.docs.map(d => new ClientConfig(Object.assign({id: d.id}, d.data())));
      });
  }

  onDestroy() {
    this.uppy?.close();
  }

  confirmationBeforeRun(title: string, prevChoice?: any) {
    const fields = this.clientList.map(v => new Object({
      id: v.id,
      type: 'checkbox',
      placeholder: `${v.id} (${v.name})`
    }));
    const results = this.clientList.reduce((acc, v) => Object.assign(acc, {[v.id]: prevChoice?.[v.id] ?? false}), {});
    const dialogData = {title: `Select client for task [${title}]`
      , fields: fields
      , result: results
      , disableConfirmDialog: true
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      disableClose: true,
      width: '600px',
      data: dialogData
    });
    dialogRef.afterOpened().pipe(take(1))
      .subscribe(() => {
        const chBoxList: HTMLCollection = dialogRef.componentRef.location.nativeElement.getElementsByTagName('mat-checkbox');
        for (const chBox of Array.from(chBoxList)) {
          (chBox as HTMLElement).style.marginBottom = '5px';
        }
        if (dialogRef.componentInstance.actionPanel) {
          const selectAll = document.createElement('button');
          selectAll.innerHTML = 'Select all';
          selectAll.className = 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base';
          selectAll.setAttribute('mat-button', '');
          selectAll.setAttribute('timeline', '');
          selectAll.style.marginRight = 'auto';
          selectAll.style.marginLeft = '0';
          selectAll.style.order = '1';
          selectAll.onclick = (ev: any) => {
            for (const client of this.clientList) {
              const value = dialogRef.componentRef.instance.fieldsPanel.result[client.id];
              dialogRef.componentRef.instance.fieldsPanel.result[client.id] = !value;
            }
          };
          dialogRef.componentInstance.actionPanel.nativeElement.appendChild(selectAll);
        }
      });
    return firstValueFrom(dialogRef.afterClosed()).then(dialogResult => {
      return isEmpty(dialogResult) ? null : Object.keys(dialogResult).filter(id => !!dialogResult[id]);
    });

  }

  commitCache(cache: IWriteCache[], options: SetOptions = { merge: true }) {
    const COMMIT_COUNT = 250;
    this.batch = this.afs.firestore.batch();
    let promise = Promise.resolve();
    let counter = 0;
    let commitCounter = 0;
    for (const task of cache) {
      promise = promise.then(() => {
        counter++;
        commitCounter++;
        this.batch.set(task.ref, task.obj, task.options ? task.options : options);
        if (counter >= COMMIT_COUNT) {
          return this.batch.commit().then(() => {
            console.log('Committed ' + commitCounter + ' of ' + cache.length);
            this.progress.next('Committed ' + commitCounter + ' of ' + cache.length);
            counter = 0;
            this.batch = this.afs.firestore.batch();
            return new Promise(resolve => setTimeout(resolve, 1000));
          });
        }
        return Promise.resolve();
      });
    }
    promise = promise.then(() => {
      if (counter > 0) {
        return this.batch.commit().then(() => {
          counter = 0;
          console.log('Committed ' + cache.length + ' of ' + cache.length);
          this.progress.next('Committed ' + cache.length + ' of ' + cache.length);
          this.batch = this.afs.firestore.batch();
        });
      }
      return Promise.resolve();
    });
    promise.then(() => {
      console.log('Commit finished.');
      this.progress.next('Commit finished.');
    });
    return promise;
  }

  commitCacheSafe(cache: IWriteCache[], options: SetOptions = { merge: true }) {
    const COMMIT_COUNT = 100;
    this.batch = this.afs.firestore.batch();
    let promise = Promise.resolve();
    let counter = 0;
    let commitCounter = 0;
    for (const task of cache) {
      promise = promise.then(() => {
        counter++;
        commitCounter++;
        this.batch.set(task.ref, task.obj, task.options ? task.options : options);
        if (counter >= COMMIT_COUNT) {
          return this.batch.commit().then(() => {
            console.log('Committed ' + commitCounter + ' of ' + cache.length);
            this.progress.next('Committed ' + commitCounter + ' of ' + cache.length);
            counter = 0;
            this.batch = this.afs.firestore.batch();
            return new Promise(resolve => setTimeout(resolve, 30000));
          });
        }
        return Promise.resolve();
      });
    }
    promise = promise.then(() => {
      if (counter > 0) {
        return this.batch.commit().then(() => {
          counter = 0;
          console.log('Committed ' + cache.length + ' of ' + cache.length);
          this.progress.next('Committed ' + cache.length + ' of ' + cache.length);
          this.batch = this.afs.firestore.batch();
        });
      }
      return Promise.resolve();
    });
    promise.then(() => {
      console.log('Commit finished.');
      this.progress.next('Commit finished.');
    });
    return promise;
  }

  printUserVersionList() {
    const offset = new Date().getTimezoneOffset();
    const valStart = this.el.nativeElement.valueAsNumber + offset * 60 * 1000;
    const valEnd = valStart + (24 * 60 * 60 * 1000);
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId', clientId);
    this.afDB.list(`client_data/${clientId}/user_log`, q => q.orderByChild('date').startAt(valStart).endAt(valEnd))
      .snapshotChanges()
      .pipe(first())
      .toPromise()
      .then((values) => {
        const filtered = values
          .map(it => {
            const val: any = new Object(it.payload.val());
            return { id: it.key, dateValue: this.utils.formatDate(val.date, DATE_FORMAT.DD_MM_YYYY_HH_mm), ...val };
          })
          .sort(this.utils.comparator('date'));
        console.log('filtered', filtered);
      });
  }

  getUser(id: string, logs = true) {
    const clientId = this.loginService.client_id$.getValue();
    return firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('app_users').doc(id)
      .get())
      .then((user) => {
        if (logs) {
          console.log(id, user.data());
        }
        return user.data();
      });
  }

  requestUsers(clientId: string, size: number, startAt) {
    let queryFn = q => q.orderBy('email').limit(size);
    if (startAt) {
      queryFn = q => q.orderBy('email').limit(size).startAfter(startAt);
    }
    if (clientId) {
      return this.afs
        .collection('client_data').doc(clientId)
        .collection('app_users', queryFn)
        .get()
        .toPromise();
    }
    return this.afs
      .collection('app_users', queryFn)
      .get()
      .toPromise();
  }

  async addIndexToUser(reset = false) {
    const compareArrays = (a1: string[], a2: string[]): boolean => {
      return a1 && a1.length === a2.length && a1.every((v, i) => a2.includes(v));
    };
    const splitBySeparator = (value: string) => {
      return value.split(/[\s,.@_]+/);
    };
    const createIndex = (list: string[], value: string) => {
      if (value) {
        for (let i = 0; i < value.length; i++) {
          const substring = value.slice(0, i + 1);
          if (!list.includes(substring)) {
            list.push(substring);
          }
        }
      }
    };

    const updateClient = async (clientId) => {
      let updateCount = 0;
      const chunkSize = 250;
      let loading = true;
      let users_ = await this.requestUsers(clientId, chunkSize, null);
      while (loading) {
        const users = users_.docs;
        for (const doc of users) {
          const user = <AppUser>doc.data();
          const newIndex = [];
          if (user.fullName) {
            const keywords = splitBySeparator(user.fullName.toLowerCase());

            for (let i = 0; i < keywords.length; i++) {
              const splice = keywords.slice(i, keywords.length);
              const join = splice.join(' ');
              createIndex(newIndex, join);
            }
          }
          if (user.email) {
            createIndex(newIndex, user.email.toLowerCase());
          }
          if (reset) {
            newIndex.length = 0;
          }
          if (!compareArrays(user.searchIndex, newIndex)) {
            updateCount++;
            cache.push({ ref: doc.ref, obj: { searchIndex: newIndex } });
          }
        }
        console.log(updateCount);
        await this.commitCache(cache).then(() => {
          cache.length = 0;
        });
        if (users_.size === chunkSize) {
          users_ = await this.requestUsers(clientId, chunkSize, users_.docs[users_.docs.length - 1]);
        } else {
          loading = false;
        }
      }
    };

    const cache: IWriteCache[] = [];

    console.log(`Main started`);
    await updateClient(null);
    console.log(`Main finished`);

    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    console.log('Finish!');
    this.progress.next('All tasks finished');

    return;
  }

  async recreateAndUpdateMSUsersLogoUrl() {
    const signAndUpdateUserLogo = (user: AppUser) => {
      return this.eventDataService.getSignedUrl(`client_data/${clientId}/profile/${user.userId}/profilePhoto.jpg`).then(newUrl => {
        console.log('update user ', 'userId: ' + user.userId, 'user name: ' + user.userName, 'url from: ' + user.picture, 'to :' + newUrl);
        // update user, event, modules
        const userRef = this.afs.collection('client_data').doc(clientId).collection('app_users').doc(user.userId).ref;
        cache.push({ ref: userRef, obj: { picture: newUrl } });

        for (const event of eventList) {
          if ((event.presenters || {})[user.userId]) {
            const pObj = { presenters: { [user.userId]: { picture: newUrl } } };
            const mObj = { managers: { [user.userId]: { picture: newUrl } } };
            const ref = this.afs.collection('client_data').doc(clientId).collection('events').doc(event.eventId).ref;
            cache.push({ ref: ref, obj: pObj });
            cache.push({ ref: ref, obj: mObj });
          }
          if ((event.concierges || {})[user.userId]) {
            const cObj = { concierges: { [user.userId]: { picture: newUrl } } };
            const mObj = { managers: { [user.userId]: { picture: newUrl } } };
            const ref = this.afs.collection('client_data').doc(clientId).collection('events').doc(event.eventId).ref;
            cache.push({ ref: ref, obj: cObj });
            cache.push({ ref: ref, obj: mObj });
          }
          if ((event.speakers || {})[user.userId]) {
            const sObj = { speakers: { [user.userId]: { picture: newUrl } } };
            const ref = this.afs.collection('client_data').doc(clientId).collection('events').doc(event.eventId).ref;
            cache.push({ ref: ref, obj: sObj });
          }
        }

        for (const module of modulesList) {
          if ((module.managers || []).find(u => u.id === user.userId)) {
            const index = module.managers.findIndex(u => u.id === user.userId);
            module.managers[index].photoURL = newUrl;
            const ref = this.afs.collection('client_data').doc(clientId).collection('modules').doc(module.id).ref;
            cache.push({ ref: ref, obj: { managers: module.managers } });
          }
          if ((module.owners || []).find(u => u.id === user.userId)) {
            const index = module.owners.findIndex(u => u.id === user.userId);
            module.owners[index].photoURL = newUrl;
            const ref = this.afs.collection('client_data').doc(clientId).collection('modules').doc(module.id).ref;
            cache.push({ ref: ref, obj: { owners: module.owners } });
          }
          if ((module.viewers || []).find(u => u.id === user.userId)) {
            const index = module.viewers.findIndex(u => u.id === user.userId);
            module.viewers[index].photoURL = newUrl;
            const ref = this.afs.collection('client_data').doc(clientId).collection('modules').doc(module.id).ref;
            cache.push({ ref: ref, obj: { viewers: module.viewers } });
          }
        }

        return Promise.resolve();
      }).catch(err => {
        throw new Error('Error get profile url for user: ' + user.userId + ' error:' + err);
      });
    };

    const cache: IWriteCache[] = [];
    const clientId = this.loginService.client_id$.getValue();
    const clientUsers: AppUser[] = [];
    const eventList: Event[] = [];
    const modulesList: EduModule[] = [];
    if (!await this.commonService.confirmation('Run: Recreate and update microsoft users logo url')) {
      return;
    }
    console.log('Load client ' + clientId + ' users...');
    return this.afs
      .collection('client_data').doc(clientId)
      .collection('app_users')
      .get().toPromise().then(clientUsersSnapshot => {
        console.log('Loaded ' + clientUsersSnapshot.docs.length + ' users.');
        for (const userDoc of clientUsersSnapshot.docs) {
          const appUser = new AppUser({ userId: userDoc.id, ...userDoc.data() });
          if (appUser?.picture?.includes('profilePhoto.jpg')) {
            clientUsers.push(appUser);
          }
        }
        console.log('Loaded ' + clientUsers.length + ' microsoft users.');
        console.log('Load client ' + clientId + ' events...');
        return this.afs
          .collection('client_data').doc(clientId)
          .collection('events')
          .get()
          .toPromise()
          .then(eventsSnapshot => {
            for (const eventDoc of eventsSnapshot.docs) {
              eventList.push(new Event({ ...eventDoc.data(), eventId: eventDoc.id }));
            }
            console.log('Loaded ' + eventsSnapshot.docs.length + ' events.');
            console.log('Load client ' + clientId + ' modules...');
            return this.afs
              .collection('client_data').doc(clientId)
              .collection('modules')
              .get()
              .toPromise()
              .then(async modulesSnapshot => {
                for (const moduleDoc of modulesSnapshot.docs) {
                  modulesList.push(new Object({ ...moduleDoc.data(), id: moduleDoc.id }) as EduModule);
                }
                console.log('Loaded ' + modulesSnapshot.docs.length + ' modules.');
                console.log('Start update logo.');
                let count = 0;
                for (const user of clientUsers) {
                  await signAndUpdateUserLogo(user);
                  console.log('Updated ' + (++count) + ' of ' + clientUsers.length);
                }
                return this.commitCache(cache)
                  .then(() => console.log('End update.'));
              });
          });
      });
  }

  async createUserEvents() {

    const createByClient = (clientId: string) => {
      const eventIds: { [key: string]: string[]; } = {};
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('user_events')
        .get())
        .then((ue_snapshot) => {
          for (const user_events of ue_snapshot.docs) {
            const data = user_events.data();
            if (!eventIds[user_events.id]) {
              eventIds[user_events.id] = [];
            }
            eventIds[user_events.id] = Object.keys(data);
          }
          console.log('user_events', eventIds);
        })
        .then(() => {
          return firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection('events')
            .get())
            .then(async (events) => {
              Object.keys(eventIds).forEach(userId => {
                const userEvents = eventIds[userId];
                events.forEach(event => {
                  if (userEvents.includes(event.id)) {
                    const ref = this.afs.collection('client_data').doc(clientId)
                      .collection('app_users').doc(userId)
                      .collection('events').doc(event.id)
                      .ref;
                    cache.push({ ref: ref, obj: event.data() });
                  }
                });
              });
              events.forEach(event => {
                if (event.data().ownerKey) {
                  const ref = this.afs.collection('client_data').doc(clientId)
                    .collection('app_users').doc(event.data().ownerKey)
                    .collection('events').doc(event.id)
                    .ref;
                  cache.push({ ref: ref, obj: event.data() });
                }
              });
            });
        })
        .then(async () => {
          await this.commitCache(cache).then(() => {
            cache.length = 0;
          });
          console.log('Finished!!!');
        });
    };

    const cache: IWriteCache[] = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await createByClient(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async updateEventState() {
    const updateEventState = (clientId: string) => {
      firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get())
        .then((snapshot) => {
          for (const doc of snapshot.docs) {
            const event = doc.data() as Event;
            if (!event.name) {
              console.log('Empty event', doc.id, doc.data());
              continue;
            }
            const obj = {};
            if (event.dopState === 0 && !event.isPublic && !event.published) {
              obj['state'] = 'draft';
            } else if (event.dopState === 0 && !event.isPublic && event.published) {
              obj['state'] = 'private';
            } else if (event.dopState === 0 && event.isPublic && event.published) {
              obj['state'] = 'public';
            } else if (event.dopState === 1 && event.isPublic && event.published) {
              obj['state'] = 'link';
            }
            if (!isEmpty(obj)) {
              const ref = this.afs.collection('client_data').doc(clientId)
                .collection('events').doc(doc.id)
                .ref;
              cache.push({ ref: ref, obj: obj });
            }
          }
        })
        .then(() => this.commitCache(cache).then(() => cache.length = 0))
        .then(() => console.log('Finished!!!'));
    };

    const cache: IWriteCache[] = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateEventState(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');

  }

  async checkEmptyEvents() {
    const updateEventState = (clientId: string) => {
      firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get())
        .then(async (snapshot) => {
          for (const doc of snapshot.docs) {
            const event = doc.data() as Event;
            if (event.deleted === 0 && (!event.name || !event.shortLink)) {
              const size = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('conference').doc(doc.id)
                .collection('timeline')
                .get())
                .then((sections) => {
                  return sections.size;
                });
              results.push({
                id: doc.id,
                link: event.shortLink,
                name: event.name,
                date: event.startDate,
                sections: size,
                end: new Date(event.endDate)
              });
            }
          }
        })
        .then(() => {
          console.log('Results', clientId, results);
        })
        .then(() => console.log('Finished', clientId));
    };

    const results = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const client_id of clients) {
      console.log(`Client ${client_id} started`);
      await updateEventState(client_id);
      console.log(`Client ${client_id} finished`);
    }

    console.log('Finish!');
  }

  async checkAndFixEventRealFinishDateDate(onlyCheck: boolean) {
    if (!await this.commonService.confirmation('Run: Fixed event realFinishDate')) {
      return;
    }
    const clientId = this.loginService.client_id$.getValue();
    console.log('clientId: ', clientId);
    let eventsLength = 0;
    let counter = 0;
    const log = [];
    const skipLog = [];
    const cache: IWriteCache[] = [];
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('~~~~~ start ~~~~~');
    this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          console.log('check: ', ++counter, 'of', eventsLength);
          if (event.deleted || !event.startDate || !event.endDate || event.dateMode === EVENT_DATE_MODE.ANYTIME) {
            skipLog.push({
              eventId: event.eventId,
              shortLink: event.shortLink,
              startDate: this.utils.formatDate(event.startDate.getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm),
              duration: event.duration,
              endDate: this.utils.formatDate(event.endDate, DATE_FORMAT.DD_MM_YYYY_HH_mm),
              name: event.name,
              dateMode: event.dateMode,
              realFinishDate: event.realFinishDate
            });
            continue;
          }
          if (event.durationFixed && event.endDateFixed && event.startDate.getTime() +
            (event.duration ?? 0) * Constants.ONE_MINUTE_MS < event.endDate) {
            log.push({
              eventId: event.eventId,
              shortLink: event.shortLink,
              startDate: this.utils.formatDate(event.startDate.getTime(), DATE_FORMAT.DD_MM_YY_HH_mm_ss),
              duration: event.duration,
              endDate: this.utils.formatDate(event.endDate, DATE_FORMAT.DD_MM_YY_HH_mm_ss),
              name: event.name,
              dateMode: event.dateMode,
              realFinishDate: event.realFinishDate,
              newDuration: Math.trunc((event.endDate - event.startDate.getTime()) / Constants.ONE_MINUTE_MS),
              wrapUpPhaseEnd: event.wrapUpPhaseEnd
            });
            if (!onlyCheck) {
              const ref = this.afs.collection('client_data').doc(clientId)
                .collection('events').doc(doc.id)
                .ref;
              const obj = {
                realFinishDate: new Date(event.endDate),
                duration: Math.trunc((event.endDate - event.startDate.getTime()) / Constants.ONE_MINUTE_MS),
                durationFixed: false
              };
              cache.push({ ref: ref, obj: obj });
            }
          }
        }
        console.log('~~~~~ log ~~~~~');
        console.log(log);
        console.log('~~~~~ skip ~~~~~');
        console.log(skipLog);
        if (!onlyCheck) {
          console.log('~~~~~ fixed ~~~~~');
          this.commitCache(cache);
        }
      });
  }

  async createSummaryByUserSectionRegistrationAllClients() {
    if (!await this.commonService.confirmation('Run create registration summary')) {
      return;
    }
    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await this.createSummaryByUserSectionRegistration(clientId);
      console.log(`Client ${clientId} finished`);
      console.log(`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~`);
    }
    console.log('All clients finished');
  }

  private async createSummaryByUserSectionRegistration(clientIdValue: string) {
    const summary = (eventId, sectionId): Promise<any> => {
      return firstValueFrom(this.afs
        .collection('client_data').doc(clientId)
        .collection('conference').doc(eventId)
        .collection('timeline').doc(sectionId)
        .collection('registered_users').get())
        .then(rs => {
          const sm: { [status: string]: number; } = {};
          for (const ud of rs.docs) {
            const u: { status: string; } = ud.data() as { status: string; };
            if ([Constants.REGISTRATION_STATUS_NEW, Constants.REGISTRATION_STATUS_NOT_REGISTERED].includes(u.status)) {
              continue;
            }
            const status = u.status
              .split('.')
              .map((v, idx) => idx > 0 ? (v[0].toUpperCase() + v.substring(1)) : v)
              .join('');
            sm[status] = !sm[status] ? 1 : sm[status] + 1;
          }
          const ref = this.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(eventId)
            .collection('timeline_counters').doc(sectionId).ref;
          return !isEmpty(sm) ? cache.push({ ref: ref, obj: sm }) : true;
        });
    };
    if (!clientIdValue) {
      return;
    }
    const clientId = clientIdValue;
    let eventsLength = 0;
    let counter = 0;
    const cache: IWriteCache[] = [];
    const queryFn = q => q.where('deleted', '==', 0);
    let eventPromise = Promise.resolve();
    console.log('Client ' + clientId + ' loading');
    return this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get()
      .toPromise()
      .then((snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        for (const doc of events) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          eventPromise = eventPromise.then(() => this.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.eventId)
            .collection('timeline')
            .get()
            .toPromise()
            .then((snapshotSections) => {
              console.log('process: ', ++counter, ' of ', eventsLength);
              let sp = Promise.resolve();
              for (const sd of snapshotSections.docs) {
                const sId = sd.id;
                sp = sp.then(() => summary(event.eventId, sId));
              }
              return sp;
            })
          );
        }
        return eventPromise;
      }).then(() => {
        console.log('~~~~~ commit ~~~~~');
        return this.commitCache(cache);
      });
  }

  deleteOldEvents(commit = false) {
    const clientId = 'P10_000001';
    let eventsLength = 0;
    let counter = 0;
    const cache: IWriteCache[] = [];
    const queryFn = q => q.where('deleted', '==', 0);
    console.log('Start');
    const list: string[] = [];
    return firstValueFrom(this.afs.collection('client_data').doc(clientId)
      .collection('events', queryFn)
      .get())
      .then((snapshot) => {
        const events = snapshot.docs;
        eventsLength = events.length;
        console.log('Total events:', eventsLength);
        const endDate = new Date(2020, 11, 31);
        for (const doc of events.filter(it => it.data().dateMode !== 2)) {
          const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
          const ref = this.afs.collection('client_data').doc(clientId)
            .collection('events').doc(doc.id).ref;
          const msg = event.shortLink + ';' + event.name + ';' + this.utils.formatDate(event.realFinishDate, DATE_FORMAT.DD_MM_YYYY);
          if (event.realFinishDate < endDate) {
            counter++;
            list.push(msg);
            console.log('Event ' + counter, msg);
            cache.push({ ref: ref, obj: { deleted: 3 } });
          } else if (event.endDate < endDate.getTime() && !event.wrapUpPhaseEnd) {
            counter++;
            list.push(msg);
            console.log('Event ' + counter, msg);
            cache.push({ ref: ref, obj: { deleted: 3 } });
          } else if (event.wrapUpPhaseEnd < endDate.getTime() && event.wrapUpPhaseEnd) {
            counter++;
            list.push(msg);
            console.log('Event ' + counter, msg);
            cache.push({ ref: ref, obj: { deleted: 3 } });
          }
        }
      }).then(() => {
        console.log('~~~~~ commit ~~~~~', cache.length, counter);
        this.utils.downloadCsvReport(list.join('\n'), 'old_events.csv', 'text/csv;encoding:utf-8');
        if (commit) {
          return this.commitCache(cache);
        }
      });
  }

  async updateSpeakerEmail() {

    const updateClient = async (clientId) => {
      let updateCount = 0;
      const users = {};
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (snapshot) => {
          for (const doc of snapshot.docs.filter(it => !isEmpty(it.data().speakers))) {
            if (doc.exists) {
              const event = doc.data() as Event;
              for (const speakerId of Object.keys(event.speakers)) {
                if (!users[speakerId]) {
                  users[speakerId] = await this.getUser(speakerId, false);
                }
                if (users[speakerId] && event.speakers[speakerId].email !== users[speakerId].email) {
                  const obj = {
                    speakers: {
                      [speakerId]: { email: users[speakerId].email }
                    }
                  };
                  console.log(`Email was changed eventId=${doc.id}, speakerId=${speakerId}, oldEmail=${event.speakers[speakerId].email}, newEmail=${users[speakerId].email}`);
                  cache.push({ ref: doc.ref, obj: obj });
                  updateCount++;
                }
              }
            }
          }
          console.log('Updated ' + updateCount);
        });
    };

    const cache: IWriteCache[] = [];

    const clients = await this.afs
      .collection('client_data')
      .get()
      .toPromise()
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('Finish!');
    this.progress.next('All tasks finished');

    return;
  }

  async checkOldFields() {
    const fields = ['moods', 'messages', 'instantSettings'];
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', q => q.where('deleted', '==', 0)).get())
        .then(async (snapshot) => {
          const events = snapshot.docs.map(it => new Event({ eventId: it.id, ...it.data() }));
          // .filter(it => it.eventId === '0PYugHyJWdqq2pPRMatK');
          for (const event of events) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(event.eventId)
              .collection('timeline').get())
              .then((querySnapshot) => {
                for (const doc of querySnapshot.docs) {
                  for (const field of fields) {
                    if (doc.data().hasOwnProperty(field)) {
                      const logObj = {
                        date: this.utils.formatDate(event.startDate, DATE_FORMAT.DD_MM_YYYY_HH_mm),
                        value: doc.data()[field]
                      };
                      console.log('eid=' + event.eventId + ' sid=' + doc.id + ' field=' + field, logObj);
                      const obj = {
                        [field]: firebase.firestore.FieldValue.delete()
                      };
                      cache.push({ ref: doc.ref, obj: obj });
                    }
                  }
                }
              });
          }
        });
    };

    const cache: IWriteCache[] = [];

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    await this.commitCache(cache);

    console.log('Finish!');
    this.progress.next('All tasks finished');
  }

  async rebuildQuizSummary() {
    const rebuild = (clientId) => {
      let eventsLength = 0;
      let counter = 0;
      let deletedCounter = 0;
      console.log(`Client ${clientId} started`);
      this.status1.push('Start process clientId ' + clientId + ' ' +
        this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
      return this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          const events = snapshot.docs;
          let secPromise = Promise.resolve();
          eventsLength = events.length;
          for (const doc of events) {
            const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
            if (event.deleted) {
              deletedCounter++;
              continue;
            }
            secPromise = secPromise.then(() => {
              return this.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(event.eventId)
                .collection('timeline')
                .get()
                .toPromise()
                .then((snapshotSections) => {
                  const sections: SectionContent[] = snapshotSections.docs.map(d => new SectionContent({ id: d.id, ...d.data() }));
                  let contentsPromise = Promise.resolve();
                  for (const section of sections) {
                    contentsPromise = contentsPromise.then(() => {
                      return this.afs
                        .collection('client_data').doc(clientId)
                        .collection('conference').doc(event.eventId)
                        .collection('timeline')
                        .doc(section.id)
                        .collection('contents', q =>
                          q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
                            .where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ))
                        .get().toPromise().then(async contentsSnap => {
                          const contentsDocs = contentsSnap.docs;
                          let docCounter = 0;
                          for (const contentDoc of contentsDocs) {
                            // const content = new ContentContainer({id: contentDoc.id, ...contentDoc.data()});
                            const quizItems = contentDoc.data().items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                            for (const item of quizItems) {
                              const path: IDocumentPathParams = {
                                eventId: event.eventId,
                                sectionId: section.id,
                                contentId: contentDoc.id,
                                containerId: item.id
                              };
                              const hasAnswers = await firstValueFrom(this.afs
                                .collection('client_data').doc(clientId)
                                .collection('conference').doc(event.eventId)
                                .collection('timeline').doc(section.id)
                                .collection('contents').doc(contentDoc.id)
                                .collection('containers').doc(item.id)
                                .collection('answers', q => q.limit(1)).get());
                              if (hasAnswers.docs.length) {
                                await this.eventDataService.buildQuizSummary(clientId, path)
                                  .catch((e) => {
                                    errorList.push({ error: e, path: path });
                                    console.log('%cError rebuild for path%c', 'color: red',
                                      'color: blue', path, 'color: black', e);
                                  });
                              }
                            }
                            console.log('processed eventId', event.eventId,
                              'sectionId', section.id, ++docCounter, 'of', contentsDocs.length);
                          }
                          return Promise.resolve();
                        });
                    });
                  }
                  return contentsPromise;
                });
            }).then(() => {
              console.log('processed ' + (++counter) + ' of ' + (eventsLength - deletedCounter));
              return Promise.resolve();
            });
          }
          return secPromise;
        }).then(() => {
          console.log('End process clientId', clientId);
          this.status1.push('End process clientId ' + clientId + ' ' +
            this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
          return Promise.resolve(true);
        });
    };

    if (!await this.commonService.confirmation('Run: Rebuild quiz answers summary')) {
      return;
    }
    const errorList: { error, path }[] = [];
    const clients: ClientConfig[] = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => {
          const obj = it.data();
          obj['id'] = it.id;
          return new ClientConfig(obj);
        });
      });
    this.status1 = [];
    console.log('Start process.');
    this.status1.push('Start process ' + clients.length + ' clients');
    for (const client of clients) {
      await rebuild(client.id);
    }
    console.log('End process.');
    this.status1.push('End process ' + clients.length + ' clients');
    if (errorList.length > 0) {
      console.log('%cProcess end with error%c', 'color: red', 'color: black', errorList);
    }
  }

  async checkDuplicateUsers() {
    const updateClient = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('app_users').get())
        .then((userSnapshot) => {
          const emails = {};
          for (const userDoc of userSnapshot.docs) {
            if (userDoc.get('email') == null) {
              continue;
            }
            if (!emails[userDoc.get('email')]) {
              emails[userDoc.get('email')] = userDoc.id;
            } else {
              console.log('Duplicate user', emails[userDoc.get('email')], userDoc.id, userDoc.get('email'));
            }
          }
        });
    };

    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => it.id);
      });

    console.log('Clients', clients);
    for (const clientId of clients) {
      console.log(`Client ${clientId} started`);
      await updateClient(clientId);
      console.log(`Client ${clientId} finished`);
    }

    console.log('Finish!');
    this.progress.next('All tasks finished');
  }

  async createEventsStatisticsByUsersCountry() {

    const getUser = (clientId: string, userId: string) => {
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('app_users').doc(userId)
        .get())
        .then((user) => {
          return user.data() ? new AppUser({ userId: userId, ...user.data() }) : null;
        });
    };


    const rebuild = (client: ClientConfig) => {
      const clientId = client.id;
      let eventsLength = 0;
      let counter = 0;
      const appUsers: { [userId: string]: AppUser } = {};
      console.log(`Client ${clientId} started`);
      this.status2.push('Start process clientId ' + clientId + ' ' +
        this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
      return this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get()
        .toPromise()
        .then((snapshot) => {
          const events = snapshot.docs.filter(d => !d.data().deleted);
          let secPromise = Promise.resolve();
          eventsLength = events.length;
          for (const doc of events) {
            const event = new Event({ eventId: doc.id, ...(doc.data() as any) });
            secPromise = secPromise.then(() => {
              console.log('process ', ++counter, ' of ', eventsLength);
              let skipCounter = 0;
              return firstValueFrom(this.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(event.eventId)
                .collection('users_online').get())
                .then(async snapshotSections => {
                  const users: ConferenceUser[] = snapshotSections.docs.map(d => new ConferenceUser({ id: d.id, ...d.data() }));
                  const statistics: {
                    [countryCode: string]: {
                      countryName: string,
                      countryTotal: number,
                      languages: {
                        [language: string]: number
                      }
                    }
                  } = {};
                  console.log('create statistics: ', event.eventId, event.shortLink, 'users online count: ', users.length);
                  for (const user of users) {
                    let countryCode = user.countryCode;
                    let countryName = user.country;
                    let language = user.language;
                    if (!countryCode) {
                      let appUser = appUsers[user.userId];
                      if (!appUser) {
                        appUser = await getUser(clientId, user.userId);
                        if (appUser) {
                          appUsers[user.userId] = appUser;
                        }
                        if (!appUser || !appUser.countryCode) {
                          skipCounter++;
                          continue;
                        }
                      }
                      if (!appUser.countryCode) {
                        skipCounter++;
                        continue;
                      }
                      countryCode = appUser.countryCode;
                      countryName = appUser.country;
                      language = appUser.language;
                    }
                    if (!language) {
                      language = client.language;
                    }
                    const country = statistics[countryCode];
                    if (!country) {
                      statistics[countryCode] = { countryName: countryName, countryTotal: 1, languages: { [language]: 1 } };
                    } else {
                      country.countryTotal += 1;
                      if (!country.languages[language]) {
                        country.languages[language] = 1;
                      } else {
                        country.languages[language] += 1;
                      }
                    }
                  }
                  console.log('save statistics: ', event.eventId, event.shortLink, 'users online count: ', users.length,
                    'skip count: ', skipCounter, 'statistics: ', statistics);
                  return this.afs.collection('client_data').doc(clientId)
                    .collection('conference').doc(event.eventId)
                    .collection('statistics')
                    .doc('country_statistics').set(statistics, { merge: false });
                });
            });
          }
          return secPromise;
        }).then(() => {
          console.log('End process clientId', clientId);
          this.status2.push('End process clientId ' + clientId + ' ' +
            this.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
          return Promise.resolve(true);
        });
    };

    if (!await this.commonService.confirmation('Run: Create events statistics by users country')) {
      return;
    }
    const clients: ClientConfig[] = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => {
          const obj = it.data();
          obj['id'] = it.id;
          return new ClientConfig(obj);
        });
      });
    this.status2 = [];
    console.log('Start process.');
    this.status2.push('Start process ' + clients.length + ' clients');
    for (const client of clients) {
      await rebuild(client);
    }
    console.log('End process.');
    this.status2.push('End process ' + clients.length + ' clients');
  }

  async uglifyAppUsers() {
    if (this.commonService.getEnv().production) {
      this.commonService.showPopupError('Production environment detected');
      return;
    }
    if (!await this.commonService.confirmation('Run: Uglify AppUsers')) {
      return;
    }

    const cache: IWriteCache[] = [];

    let counter = 0;
    const usedStrings = [];

    const firstRef = this.afs.collection('app_users', q => q.orderBy('email').limit(1000));

    await firstValueFrom(firstRef.get())
      .then(async (firstSnapshot) => {
        const generateRandomString = (length: number) => {
          let randomString = this.commonService.utils.generateRandomString(length);
          while (usedStrings.includes(randomString)) {
            randomString = this.commonService.utils.generateRandomString(length);
          }
          usedStrings.push(randomString);
          return randomString;
        };
        const processSnapshot = (snapshot) => {
          for (const doc of snapshot.docs) {
            const email = doc.get('email') as string;
            if (email?.endsWith('@roche.com')) {
              const randomString1 = generateRandomString(6);
              const randomString2 = generateRandomString(10);
              const obj = {
                email: randomString1 + '.' + randomString2 + '@roche.com',
                fullName: randomString1 + ' ' + randomString2,
                userName: randomString1 + ' ' + randomString2
              };
              counter++;
              console.log(`Update(${counter}) email=${email}, obj=${JSON.stringify(obj)}`);
              cache.push({ ref: doc.ref, obj: obj });
            }
          }
        };

        processSnapshot(firstSnapshot);

        let lastDoc = firstSnapshot.docs[firstSnapshot.docs.length - 1];

        let hasNext = firstSnapshot.docs.length >= 1000;
        while (hasNext) {
          const queryFn = q => q.orderBy('email').startAfter(lastDoc).limit(1000);
          const nextRef = this.afs.collection('app_users', queryFn);
          const nextSnapshot = await firstValueFrom(nextRef.get());
          if (!nextSnapshot.empty) {
            processSnapshot(nextSnapshot);
            lastDoc = nextSnapshot.docs[nextSnapshot.docs.length - 1];
          } else {
            hasNext = false;
          }
        }
      });

    await this.commitCacheSafe(cache);
    console.log('All tasks finished');
  }

  async fixSlotDates() {
    const fixClient = async (clientId: string) => {
      return firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          for (const eventDoc of eventsSnapshot.docs) {
            const sections = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then((tlSnapshot) => {
                return tlSnapshot.docs.map(it => new Object({id: it.id, ...it.data()}) as SectionContent)
                  .filter(it => !it.education || !it.parentId)
                  .filter(it => !it.fixedSectionType);
              });
            if (sections.length <= 1) {
              continue;
            }
            const rootSection = sections.find(it => !it.parentId);
            const firstLevel = sections.filter(it => it.parentId === rootSection.id).sort(this.utils.comparator(Constants.ORDERINDEX));
            const wrongList = [];
            for (let i = 0; i < firstLevel.length; i++) {
              const section = firstLevel[i];
              if ((i + 1) >= firstLevel.length || !section.freeSlotType) {
                continue;
              }
              const nextSection = firstLevel[i + 1];
              if (section.plannedTime > nextSection.plannedTime && nextSection.freeSlotType) {
                wrongList.push(section);
              }
            }
            const lastSection = firstLevel[firstLevel.length - 1];
            if (wrongList.length) {
              console.log(`%cEvent id=${eventDoc.id}, link=${eventDoc.get('shortLink')}, idx=${lastSection.orderIndex}, date=${new Date(lastSection.plannedTime)}`, 'color: blue');
            }
            let orderIndex = lastSection.orderIndex + 10000000;
            const sorted = wrongList.sort(this.utils.comparator('plannedTime'));
            for (const section of sorted as SectionContent[]) {
              if (lastSection.plannedTime < section.plannedTime) {
                const hasChild = sections.find(it => it.parentId === section.id);
                if (hasChild) {
                  console.log(`%cSection(CHILD) id=${section.id}, idx=${section.orderIndex}, date=${new Date(section.plannedTime)}, title=${section.title}`, 'color: red');
                  // continue;
                }
                console.log(`Section id=${section.id}, idx=${section.orderIndex}, new=${orderIndex}, date=${new Date(section.plannedTime)}, title=${section.title}`);
                const ref = this.afs.collection('client_data').doc(clientId)
                  .collection('conference').doc(eventDoc.id)
                  .collection('timeline').doc(section.id).ref;
                cache.push({ref: ref, obj: {orderIndex: orderIndex}});
                orderIndex += 10000000;
              }
            }
          }
        });
    };

    const cache: IWriteCache[] = [];
    // const clients = await firstValueFrom(this.afs
    //   .collection('client_data')
    //   .get())
    //   .then((snapshot) => {
    //     return snapshot.docs.map(it => it.id);
    //   });
    const clients = [this.loginService.client_id$.getValue()];
    for (const clientId of clients) {
      await fixClient(clientId);
    }

    await this.commitCache(cache);
    console.log('End process.');
  }

  async createStatisticsByModulesWithoutPicturesSrc() {

    const runCreateStatisticsByModulesWithoutPicturesSrc = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules', ref => ref.where('deleted', '==', 0)).get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs; // .filter(d => d.id === '4Ihh0KS2qz2AXptArPPL');
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process ${++counter} of ${size}`);
                const allSection = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
                const sections = allSection.filter(s => !s.isRoot);
                if (sections.length > 0) {
                  console.log(`module id ${eventDoc.id} sections count ${sections.length}`);
                  let si = 0;
                  for (const s of sections) {
                    console.log(`process event id ${eventDoc.id} section ${++si} of ${sections.length}`);
                    const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                      .collection('modules').doc(eventDoc.id)
                      .collection('timeline').doc(s.id)
                      .collection('contents', ref => ref.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER))
                      .get())
                      .then(snap => (snap.docs || []));
                    for (const ccDoc of contents) {
                      const cc = new ContentContainer({id: ccDoc.id, ...ccDoc.data()});
                      const images = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.IMAGE)
                        .filter(gl => !!gl.data.pictures?.filter(p => !p.src).length);
                      const files = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.TASK)
                        .filter(gl => !!gl.data.files?.filter(p => !p.src).length);
                      const pdfs = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.PDF)
                        .filter(gl => !gl.data.src);
                      const quizzes = cc.items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ)
                        .map(qd => new Object({id: qd.id, quiz: new Quiz(qd.data)}) as { id: string, quiz: Quiz })
                        .filter(q => !!(Object.values(q.quiz.questions) as EventQuestion[])
                          .filter(ques => ques.storypoint === Constants.QTYPE_MATCHING_MAP && !ques.files[0]).length);
                      if (!isEmpty(images)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'picture without src'
                          }]);
                      }
                      if (!isEmpty(files)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'files without src'
                          }]);
                      }
                      if (!isEmpty(quizzes)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'question type matching map without imanage'
                          }]);
                      }
                      if (!isEmpty(pdfs)) {
                        log[clientId][eventDoc.id] = union(log[clientId][eventDoc.id] ?? [],
                          [{
                            a_moduleId: eventDoc.id, b_sectionId: cc.parentId,
                            c_contentId: cc.id, warning: 'pdf without src'
                          }]);
                      }
                    }
                  }
                }
              });
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Create statistics by modules without pictures src')) {
      return;
    }
    const log = {};
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
        // .filter(cl => cl.id === this.currentClientId);
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      log[clientId] = {};
      await runCreateStatisticsByModulesWithoutPicturesSrc(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('~~~~ log ~~~~');
    console.log(log);
    const clientCSV = {};
    for (const clientId of Object.keys(log)) {
      const clientData = log[clientId];
      const list: string[] = ['module;section;content;message;link'];
      for (const moduleId of Object.keys(clientData)) {
        const rowData: {a_moduleId, b_sectionId, c_contentId, warning}[] = clientData[moduleId];
        for (const row of rowData) {
          const link = `module/${row.a_moduleId}?sid=${row.b_sectionId}&cid=${row.c_contentId}`;
          list.push(`${row.a_moduleId};${row.b_sectionId};${row.c_contentId};${row.warning};${link}`);
        }
      }
      clientCSV[clientId] = list;
    }
    Object.keys(clientCSV)
      .forEach(clId => this.utils.downloadCsvReport(clientCSV[clId].join('\n'),
        `${clients.find(c => c.id === clId).name}.csv`, 'text/csv;encoding:utf-8'));
    console.log('End process.');
  }

  async createDailyWebhook() {
    const url = this.utils.getEnv().BACKEND_BASE + '/dailyApi/v3/api/token';
    const dailyToken = await firstValueFrom(this.http.get(url, {
      responseType: 'text'
    }));
    const projectId = this.utils.getEnv().firebase['projectId'];
    const result = await firstValueFrom(this.http.post('https://daily.co/api/v1/webhooks', {
      url: `https://europe-west6-${projectId}.cloudfunctions.net/onRecordingDownloadReady`,
      eventTypes: ['recording.ready-to-download']
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dailyToken
      }),
      responseType: 'json'
    }));
    console.log('result', result);

  }

  async getDailyWebhooks() {
    const url = this.utils.getEnv().BACKEND_BASE + '/dailyApi/v3/api/token';
    const dailyToken = await firstValueFrom(this.http.get(url, {
      responseType: 'text'
    }));
    const result = await firstValueFrom(this.http.get('https://daily.co/api/v1/webhooks', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dailyToken
      }),
      responseType: 'json'
    }));
    console.log('Webhooks', result);
  }

  async deleteDailyWebhook(uuid: string) {
    const url = this.utils.getEnv().BACKEND_BASE + '/dailyApi/v3/api/token';
    const dailyToken = await firstValueFrom(this.http.get(url, {
      responseType: 'text'
    }));
    const result = await firstValueFrom(this.http.delete(`https://daily.co/api/v1/webhooks/${uuid}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + dailyToken
      }),
      responseType: 'json'
    }));
    console.log('result', result);
  }

  async convertOldQuestionOptionsToNewFormat() {

    const convertQuestion = (question, type: 'quiz' | 'questionnaire') => {
      const options = {};
      const files = [];
      let matchingFieldChanged = false;
      if (!isEmpty(question.items) && question.items.some(o => o.hasOwnProperty('matchingId'))) {
        for (const item of question.items) {
          if (!!item.matchingId) {
            item.matching = [item.matchingId];
            delete item.matchingId;
            matchingFieldChanged = true;
          }
        }
      }
      if (question.hasOwnProperty('simpleMatching')) {
        options['simpleMatching'] = question.simpleMatching;
        delete question.simpleMatching;
      }
      if (question.hasOwnProperty('smilesRatingSet')) {
        options['smilesRatingSet'] = question.smilesRatingSet;
        delete question.smilesRatingSet;
      }
      if (question.hasOwnProperty('wordCloudTemplate')) {
        if (question.wordCloudTemplate?.url) {
          files.push(question.wordCloudTemplate.url);
          delete question.wordCloudTemplate.url;
        }
        options['wordCloudTemplate'] = cloneDeep(question.wordCloudTemplate);
        delete question.wordCloudTemplate;
      }
      if (type === 'quiz' && question.hasOwnProperty('imageUrl')) {
        files.push(question.imageUrl);
        delete question.imageUrl;
      }
      if (matchingFieldChanged || !isEmpty(options) || !isEmpty(files)) {
        if (!isEmpty(options)) {
          question['options'] = options;
        }
        if (!isEmpty(files)) {
          question['files'] = files;
        }
        return true;
      }
      return false;
    };

    const runConferenceConvertQuestionOptions = async (clientId) => {
      return await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs
            .filter(d => !d.data().deleted);
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process event ${++counter} of ${size}`);
                let sectionCounter = 0;
                const sectionCount = snapshot.docs.length;
                for (const s of snapshot.docs) {
                  console.log(`process event ${eventDoc.id} sections ${++sectionCounter} of ${sectionCount}`);
                  const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                    .collection('conference').doc(eventDoc.id)
                    .collection('timeline').doc(s.id)
                    .collection('contents', ref => ref.where('itemsTypes', 'array-contains', CONTAINER_ITEM_TYPE.QUIZ))
                    .get())
                    .then(snap => snap.docs);
                  const contentContainersDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
                      d.data().itemsTypes?.includes(CONTAINER_ITEM_TYPE.QUIZ))
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of contentContainersDocs) {
                    const quizItems = content['items'].filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                    let needUpdate = false;
                    for (const quizItem of quizItems) {
                      const questions = quizItem.data.questions;
                      for (const questionId of Object.keys(questions)) {
                        const question = questions[questionId];
                        if (convertQuestion(question, 'quiz')) {
                          needUpdate = true;
                        }
                      }
                    }
                    if (needUpdate) {
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                  const questionnaireDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_QUESTIONNAIRE)
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of questionnaireDocs) {
                    const questions = content['questions'];
                    let needUpdate = false;
                    for (const questionId of Object.keys(questions)) {
                      const question = questions[questionId];
                      if (convertQuestion(question, 'questionnaire')) {
                        needUpdate = true;
                      }
                    }
                    if (needUpdate) {
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                }
              });
          }
        });
    };

    const runModulesConvertQuestionOptions = async (clientId) => {
      return await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('modules').get())
        .then(async (eventsSnapshot) => {
          const eventDocs = eventsSnapshot.docs
            .filter(d => !d.data().deleted);
          const size = eventDocs.length;
          let counter = 0;
          for (const eventDoc of eventDocs) {
            await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('modules').doc(eventDoc.id)
              .collection('timeline').get())
              .then(async (snapshot) => {
                console.log(`process module ${++counter} of ${size}`);
                for (const s of snapshot.docs) {
                  const contents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                    .collection('modules').doc(eventDoc.id)
                    .collection('timeline').doc(s.id)
                    .collection('contents')
                    .get())
                    .then(snap => snap.docs);
                  const contentContainersDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
                      d.data().itemsTypes?.includes(CONTAINER_ITEM_TYPE.QUIZ))
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of contentContainersDocs) {
                    const quizItems = content['items'].filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                    let needUpdate = false;
                    for (const quizItem of quizItems) {
                      const questions = quizItem.data.questions;
                      for (const questionId of Object.keys(questions)) {
                        const question = questions[questionId];
                        if (convertQuestion(question, 'quiz')) {
                          needUpdate = true;
                        }
                      }
                    }
                    if (needUpdate) {
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                  const questionnaireDocs = contents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_QUESTIONNAIRE)
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of questionnaireDocs) {
                    const questions = content['questions'];
                    let needUpdate = false;
                    for (const questionId of Object.keys(questions)) {
                      const question = questions[questionId];
                      if (convertQuestion(question, 'questionnaire')) {
                        needUpdate = true;
                      }
                    }
                    if (needUpdate) {
                      logOldQuestionnaire.push({clientId,
                        eventId: eventDoc.id, shortLink: eventDoc.data()['shortLink'], sectionId: s.id, contentId: content['id']});
                      const ref = contents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }

                  const draftContents = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                    .collection('modules').doc(eventDoc.id)
                    .collection('timeline').doc(s.id)
                    .collection('draft_contents')
                    .get())
                    .then(snap => snap.docs);
                  const draftContentContainersDocs = draftContents
                    .filter(d => d.data().type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
                      d.data().itemsTypes?.includes(CONTAINER_ITEM_TYPE.QUIZ))
                    .map(cd => new Object({id: cd.id, ...cd.data()}));
                  for (const content of draftContentContainersDocs) {
                    const quizItems = content['items'].filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
                    let needUpdate = false;
                    for (const quizItem of quizItems) {
                      const questions = quizItem.data.questions;
                      for (const questionId of Object.keys(questions)) {
                        const question = questions[questionId];
                        if (convertQuestion(question, 'quiz')) {
                          needUpdate = true;
                        }
                      }
                    }
                    if (needUpdate) {
                      const ref = draftContents.find(d => d.id === content['id']).ref;
                      delete content['id'];
                      cache.push({ref: ref, obj: content});
                    }
                  }
                }
              });
          }
        });
    };

    let clientIds: string[];
    const logOldQuestionnaire = [];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Convert old question options to new format.'))) {
      return;
    }
    const cache: IWriteCache[] = [];
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runConferenceConvertQuestionOptions(clientId);
      await runModulesConvertQuestionOptions(clientId);
      console.log(`Client ${clientId} finished`);
    }
    if (!isEmpty(logOldQuestionnaire)) {
      console.log(logOldQuestionnaire);
    }
    this.commitCacheSafe(cache).then(() => console.log('End process.'));
  }

  async fillEventAdditional() {

    const runFillEventAdditional = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('event_additional').get())
        .then(async (eventsSnapshot) => {
          const event_additionals = eventsSnapshot.docs;
          let counter = 0;
          const size = event_additionals.length;
          for (const additionalDoc of event_additionals) {
            if (!additionalDoc.get('shortLink') || !additionalDoc.get('deleted')) {
              const eventDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('events').doc(additionalDoc.id)
                .get());
              if (eventDoc.exists) {
                cache.push({
                  ref: additionalDoc.ref,
                  obj: {
                    shortLink: eventDoc.get('shortLink'),
                    deleted: eventDoc.get('deleted')
                  }
                });
              }
            }
            console.log('processed ' + (++counter) + ' of ' + size);
          }
        });
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events', q => q.where('guest', '==', true)).get())
        .then(async (eventsSnapshot) => {
          const events = eventsSnapshot.docs;
          let counter = 0;
          const size = events.length;
          for (const eventDoc of events) {
            if (!eventDoc.data().hasOwnProperty('guestName') && !eventDoc.data().hasOwnProperty('guestEmail')) {
              cache.push({
                ref: eventDoc.ref,
                obj: {
                  guestName: true,
                  guestEmail: true
                }
              });
            }
            console.log('processed ' + (++counter) + ' of ' + size);
          }
        });
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const events = eventsSnapshot.docs;
          let counter = 0;
          const size = events.length;
          for (const eventDoc of events) {
            if (eventDoc.data().hasOwnProperty('guestMode')) {
              cache.push({
                ref: eventDoc.ref,
                obj: {
                  guestMode: firebase.firestore.FieldValue.delete()
                }
              });
            }
            console.log('processed ' + (++counter) + ' of ' + size);
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Fill Event Additional.')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await runFillEventAdditional(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async createNewEmailTemplate() {

    const createNewEmailTemplate = async (clientId) => {
      await this.afs.collection('client_data').doc(clientId)
        .collection('mail_template').doc('R1EWOFxBhiw4VAk53LOX')
        .set({
          body: 'Hi ${user_name}!<br/><br/>Your Timeline password has been reset by the administrator. <br/><br/>Login Link: ${application_link}<br/>Your E-Mail: ${email}<br/>Your new password: ${password}<br/><br/>Best regards, The Timeline Team',
          name: 'New Password',
          subject: 'Your New Timeline Password',
          type: 'reset_password'
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Create New Email Template.')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });
    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await createNewEmailTemplate(clientId);
      console.log(`Client ${clientId} finished`);
    }
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async blockGmailUsers(save = false) {
    if (!await this.commonService.confirmation('Run: Block Gmail Users')) {
      return;
    }

    const csvLog = ['type;id;email;name'];

    let counter = 0;

    const cid = this.loginService.client_id$.getValue();
    const firstRef = this.afs.collection('client_data').doc(cid)
      .collection('app_users', q => q.orderBy('email').limit(1000));

    await firstValueFrom(firstRef.get())
      .then(async (firstSnapshot) => {
        const processSnapshot = (snapshot) => {
          for (const doc of snapshot.docs) {
            const email = doc.get('email') as string;
            let name = doc.get('fullName') as string;
            if (!name) {
              name = doc.get('userName') as string;
            }
            const blocked = doc.get('blocked') as boolean;
            const guest = doc.get('guest') as boolean;
            const uid = doc.id;
            if (blocked) {
              continue;
            }
            if (guest) {
              console.log(`Guest(${counter}). email=${email}, uid=${uid}, name=${name}`);
              csvLog.push(`guest;${uid};${email};${name}`);
            }
            if (email?.endsWith('@gmail.com')) {
              counter++;
              console.log(`Gmail(${counter}). email=${email}, uid=${uid}, name=${name}`);
              csvLog.push(`gmail;${uid};${email};${name}`);
/*
              if (save) {
                await this.dataService.setUserBlocked(uid, true)
                  .catch((e) => this.commonService.log.error(e));
              }
*/
            }
          }
        };

        processSnapshot(firstSnapshot);

        let lastDoc = firstSnapshot.docs[firstSnapshot.docs.length - 1];

        let hasNext = firstSnapshot.docs.length >= 1000;
        while (hasNext) {
          const queryFn = q => q.orderBy('email').startAfter(lastDoc).limit(1000);
          const nextRef = this.afs.collection('client_data').doc(cid)
            .collection('app_users', queryFn);
          const nextSnapshot = await firstValueFrom(nextRef.get());
          if (!nextSnapshot.empty) {
            processSnapshot(nextSnapshot);
            lastDoc = nextSnapshot.docs[nextSnapshot.docs.length - 1];
          } else {
            hasNext = false;
          }
        }
      });
    this.utils.downloadCsvReport(csvLog.join('\n'), 'gmail.csv', 'text/csv;encoding:utf-8');
    console.log('All tasks finished');
  }

  async fixSectionSpeakers() {
    const runFixSectionSpeakers = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events').get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs;
          let counter = 0;
          const eventsLength = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
/*
            if (isEmpty(eventDoc.data())) {
              console.log(`isEmpty. id=${eventDoc.id} process ${++counter} of ${eventsLength}.`, eventDoc.id, eventDoc.data());
              await eventDoc.ref.delete();
              continue;
            }
*/
            let speakersDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('event_sections_speakers').doc(eventDoc.id).get());

            if (speakersDoc.exists && speakersDoc.get('updateTrigger')) {
              await speakersDoc.ref.update({updateTrigger: firebase.firestore.FieldValue.delete()});
              speakersDoc = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
                .collection('event_sections_speakers').doc(eventDoc.id).get());
            }
            const speakers = speakersDoc.exists ? speakersDoc.data() : {};

            const timeline = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection('conference').doc(eventDoc.id)
              .collection('timeline').get());

            const obj: {
              [sectionId: string]: {
                [userId: string]: boolean
              }
            } = {};

            let withIssue = false;
            const speakersObj = {};

            for (const sectionDoc of timeline.docs) {
              const section = sectionDoc.data() as SectionContent;
              if (!isEmpty(section.users)) {
                for (const user of section.users) {
                  if (user.speaker) {
                    if (!obj[sectionDoc.id]) {
                      obj[sectionDoc.id] = {};
                    }
                    obj[sectionDoc.id][user.userId] = true;
                    speakersObj[user.userId] = true;
                    const event_speakers = eventDoc.get('speakers');
                    if (!withIssue) {
                      withIssue = !event_speakers || !event_speakers[user.userId];
                    }
                  }
                }
              }
            }

            for (const sectionId of Object.keys(speakers)) {
              if (isEmpty(speakers[sectionId])) {
                delete speakers[sectionId];
              }
            }


            if (!isEqual(speakers, obj)) {
              console.log(`id=${eventDoc.id} process ${++counter} of ${eventsLength}. obj=${Object.keys(obj).length}, speakers=${Object.keys(speakers).length}`, obj, speakers);
              cache.push({ref: speakersDoc.ref, obj, options: {merge: false}});
            } else if (withIssue) {
              console.log(`wrong event speakers list. id=${eventDoc.id} process ${++counter} of ${eventsLength}. event=${Object.keys(eventDoc.get('speakers')).length}, document=${Object.keys(speakersObj).length}, obj=${Object.keys(obj).length}, speakers=${Object.keys(speakers).length}`, eventDoc.get('speakers'), obj, speakers);
              cache.push({ref: speakersDoc.ref, obj: {updateTrigger: {}}});
            } else {
              console.log(`id=${eventDoc.id} process ${++counter} of ${eventsLength}`);
            }
          }
        });
    };

    if (!await this.commonService.confirmation(
      'Run: Fix Section Speakers')) {
      return;
    }
    const cache: IWriteCache[] = [];
    const clients = await firstValueFrom(this.afs
      .collection('client_data')
      .get())
      .then((snapshot) => {
        return snapshot.docs.map(it => new ClientConfig(Object.assign({id: it.id}, it.data())));
      });

    for (const client of clients) {
      const clientId = client.id;
      console.log(`Client ${clientId} started`);
      await runFixSectionSpeakers(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('Finish!');
    this.commitCache(cache)
      .then(() => console.log('End process.'));
  }

  async clientEngagedSessionsStatistics() {
    const to0d = (d: number) => d <= 9 ? `0${d}` : `${d}`;

    const runClientEngagedSessionsStatistics = async (clientId) => {
      let eventsLength = 0;
      let counter = 0;
      const clientSessions: {
        [dateTimeKey: string]: {
          dateTime: number,
          count: number | any
        }
      } = {};
      let clientSessionsTotal = 0;
      let clientSessionsTotalDate = 0;
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('events')
        .get())
        .then(async snapshot => {
          const events = snapshot.docs; // .filter(d => !d.data().deleted);
          eventsLength = events.length;
          for (const event of events) {
            console.log('process ', ++counter, ' of ', eventsLength);
            await firstValueFrom(this.afDB.list(`client_data/${clientId}/conference/${event.id}/users/ping`).valueChanges())
              .then(async usersPings => {
                for (const pings of (usersPings ?? [])) {
                  for (const pingTime of Object.values(pings)) {
                    const pingDateTime = new Date(pingTime);
                    const dateTime = new Date(pingDateTime.getFullYear(), pingDateTime.getMonth(), pingDateTime.getDate(), 0).getTime();
                    const dateTimeKey = `${pingDateTime.getFullYear()}${to0d(pingDateTime.getMonth())}${to0d(pingDateTime.getDate())}`;
                    if (isEmpty(clientSessions[dateTimeKey])) {
                      clientSessions[dateTimeKey] = {
                        dateTime: dateTime,
                        count: 1
                      };
                    } else {
                      clientSessions[dateTimeKey].count = clientSessions[dateTimeKey].count + 1;
                    }
                    clientSessionsTotal++;
                    clientSessionsTotalDate = max([clientSessionsTotalDate, dateTime]);
                  }
                }
              });
          }
          Object.values(clientSessions).forEach(it => {
            it.count = firebase.database.ServerValue.increment(it.count);
          });
          await this.afDB.database.ref(`client_data/${clientId}/engaged_sessions`).update(clientSessions);
          await this.afDB.database.ref(`client_data/${clientId}/engaged_sessions_total`).update({
            dateTime: clientSessionsTotalDate,
            count: firebase.database.ServerValue.increment(clientSessionsTotal)
          });
          return;
        });
    };

    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Create client engaged sessions statistics.'))) {
      return;
    }
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runClientEngagedSessionsStatistics(clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async updateUserStatistics() {
    if (!await this.commonService.confirmation('Run: Update User Statistics.')) {
      return;
    }

    this.statisticsApiServiceService.updateUsersByCreatedAt()
      .subscribe({
        next: () => {
          console.log('finished!');
        },
        error: (err) => {
          console.error('error', err);
        }
      });
  }

  async updateEventParticipantsStatistics() {
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Create users online statistics.'))) {
      return;
    }
    console.log('Start process');
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await updateOnlineStatistics(this.afs, this.afDB, clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async updateEventRegisteredStatistics() {
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Create users registered statistics.'))) {
      return;
    }
    console.log('Start process');
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await updateRegisteredStatistics(this.afs, this.afDB, clientId);
      console.log(`Client ${clientId} finished`);
    }
    console.log('End process.');
  }

  async fillFullName(fix = false) {
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun('Run: Fill user full name.'))) {
      return;
    }
    const runClientUsersUpdate = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection('app_users', q => q.orderBy('fullName').limit(1000))
        .get())
        .then((users) => {
          let counter = 0;
          const total = users.docs.length;
          for (const doc of users.docs) {
            counter ++;
            if (doc.get('email') && (!doc.get('fullName') || !doc.get('userName'))) {
              const obj: {
                userName?: string,
                fullName?: string
              } = {};
              if (!doc.get('fullName')) {
                obj.fullName = this.utils.extractDefaultDisplayNameFromEmail(doc.get('email'));
              }
              if (!doc.get('userName')) {
                obj.userName = this.utils.extractDefaultDisplayNameFromEmail(doc.get('email'));
              }
              if (fix) {
                cache.push({ref: doc.ref, obj});
              }
              console.log(`User ${counter} of ${total} id=${doc.id}, ${doc.get('email')}, name=${doc.get('userName')}, full=${doc.get('fullName')}, fixed=${this.utils.extractDefaultDisplayNameFromEmail(doc.get('email'))}`, obj);
            } else {
              console.log(`User ${counter} of ${total}`);
            }
          }
        });

    };
    const cache: IWriteCache[] = [];
    for (const clientId of clientIds) {
      console.log(`Client ${clientId} started`);
      await runClientUsersUpdate(clientId);
      console.log(`Client ${clientId} finished`);
    }
    await this.commitCache(cache).finally(() => console.log('End process.'));
  }

  getRadioGroupValue() {
    return (document.querySelector('input[name = sectionsType]:checked') as HTMLInputElement)?.value as any;
  }

  async checkTimelineOrderIndex(sectionsCheckType: 'timeline' | 'education' | 'modules',
                                fixType: 'check-only' | 'fix-lost' | 'fix-order', eventId: string) {
    let confirmationTitle;
    switch (fixType) {
      case 'check-only':
        confirmationTitle = `Run: Check sections of type ${sectionsCheckType}`;
        break;
      case 'fix-lost':
        confirmationTitle = `Run: Fix lost sections of type ${sectionsCheckType}`;
        break;
      case 'fix-order':
        confirmationTitle = `Run: Fix order index of type ${sectionsCheckType}`;
        break;
    }

    const childrenCheckOutOfRange = (section: SectionContent,
                                     sectionsList: SectionContent[], rootChildrenTreeIds: string[],  testsOutOfRange: IIndexTest[]) => {
      const childrenTree = this.timeLineService.childTreeInLineListDeprecated(section.id).filter(s => s.id !== section.id);
      const childrenTreeIds = childrenTree.map(s => s.id);
      const childrenTreeMaxIndex = max([section.orderIndex, ...childrenTree.map(s => s.orderIndex)]);
      const children = this.utils.getSectionChildrenTree(section, sectionsList).filter(s => s.id !== section.id);
      const warning: IChild[] = children.filter(s => !childrenTreeIds.includes(s.id))
        .map(s => new Object({sectionId: s.id, title: s.title, orderIndex: s.orderIndex, parentId: s.parentId,
          level: this.timeLineService.contentLevel(s.id) + 1}) as IChild);
      if (!isEmpty(warning)) {
        testsOutOfRange.push({
          sectionId: section.id, parentId: section.parentId, title: section.title,
          minIndex: section.orderIndex, maxIndex: childrenTreeMaxIndex,
          level: this.timeLineService.contentLevel(section.id) + 1,
          isLost: !rootChildrenTreeIds.includes(section.id),
          childrenOutOfIndex: warning.sort(this.utils.comparator(Constants.ORDERINDEX))
        });
      }
    };

    const checkLostSections = (section: SectionContent, rootChildrenTreeIds: string[], lostSections: IChild[]) => {
      if (!rootChildrenTreeIds.includes(section.id)) {
        lostSections.push({sectionId: section.id, parentId: section.parentId, title: section.title, orderIndex: section.orderIndex});
      }
    };

    const fixLostSections = async (lostSections: IChild[], sectionsList: SectionContent[],
                                   clientId: string, eventId: string, rootId: string,
                                   sectionsDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) => {
      const maxOrderIndex = max(sectionsList.map(s => s.orderIndex)) + 10000000;
      let parentForLost = sectionsList.find(s => s.title === 'Lost sections');
      if (!parentForLost) {
        const sectionId = this.afs.createId();
        const section = new SectionContent({
          eventId: eventId,
          title: 'Lost sections',
          orderIndex: maxOrderIndex,
          parentId: rootId,
          status: Constants.SECTION_STATUS_DRAFT,
          sectionTimeIsNotActive: true,
          education: sectionsCheckType !== 'timeline'
        });
        parentForLost = await this.afs.collection('client_data').doc(clientId)
          .collection(sectionsCheckType === 'modules' ? 'modules' : 'conference')
          .doc(eventId).collection('timeline').doc(sectionId).set(section.toObject())
          .then(() => firstValueFrom(this.afs.collection('client_data').doc(clientId)
            .collection(sectionsCheckType === 'modules' ? 'modules' : 'conference')
            .doc(eventId).collection('timeline').doc(sectionId).get())
            .then(snap => new SectionContent({id: snap.id, ...snap.data()})));
      }
      if (parentForLost) {
        for (const ls of lostSections) {
          if (!sectionsList.find(s => s.id === ls.parentId)) {
            const doc = sectionsDocs.find(d => d.id === ls.sectionId);
            if (doc) {
              cache.push({ref: doc.ref, obj: {parentId: parentForLost.id}});
            }
          }
        }
      }
    };

    const addToSort = (section: SectionContent, sectionsList: SectionContent[], sortedList: SectionContent[]) => {
      const children = sectionsList.filter(s => s.parentId === section.id)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      if (section.isRoot && children.filter(it => it.title !== 'Lost sections').every(it => it.freeSlotType)) {
        const mainLostSection = children.find(it => it.title === 'Lost sections');
        if (mainLostSection) {
          mainLostSection.plannedTime = max(children.filter(it => it.title !== 'Lost sections').map(it => it.plannedTime)) + 10000000;
        }
        children.sort(this.utils.comparator('plannedTime'));
      }
      sortedList.push(section);
      for (const chl of children) {
        addToSort(chl, sectionsList, sortedList);
      }
    };

    const fixOrderIndex = (sectionsList: SectionContent[],
                           sectionsDocs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) => {
      const sortedList: SectionContent[] = [];
      const root = sectionsList.find(s => s.isRoot);
      addToSort(root, sectionsList, sortedList);
      let orderIndex = 100000000;
      for (const s of sortedList.filter(s => !s.isRoot)) {
        const doc = sectionsDocs.find(d => d.id === s.id);
        if (doc) {
          cache.push({ref: doc.ref, obj: {orderIndex: orderIndex}});
          orderIndex += 100000000;
        }
      }
    };

    const runCheckOrderIndex = async (clientId) => {
      await firstValueFrom(this.afs.collection('client_data').doc(clientId)
        .collection(sectionsCheckType === 'modules' ? 'modules' : 'events').get())
        .then(async (eventsSnapshot) => {
          const eventsDocs = eventsSnapshot.docs.filter(d => !d.get('deleted') && (eventId ? d.id === eventId : true));
          let ecounter = 0;
          const brokenEvents: Event[] = [];
          const crashedEventsId: string[] = [];
          const size = eventsDocs.length;
          for (const eventDoc of eventsDocs) {
            this.timeLineService.resetServiceValues();
            const testsOutOfRange: IIndexTest[] = [];
            const lostSections: IChild[] = [];
            if (!eventDoc.get('shortLink')) {
              crashedEventsId.push(eventDoc.id);
              console.log(`processed skip event ${eventDoc.id} ~~~~~ CRASHED EVENT ~~~~~ ` + (++ecounter) + ' of ' + size);
              continue;
            }
            const event = new Event({eventId: eventDoc.id, ...eventDoc.data()});
            console.log(`processed event ${event.eventId} ~~~~~ ${event.shortLink} ` + (++ecounter) + ' of ' + size);
            const sectionsDocs = await firstValueFrom(this.afs.collection('client_data').doc(clientId)
              .collection(sectionsCheckType === 'modules' ? 'modules' : 'conference').doc(event.eventId)
              .collection('timeline', q => q.where('type', '==', Constants.CONTENT_TYPE_SECTION)).get())
              .then(sectionsSnapshot => sectionsSnapshot.docs);
            const sections = sectionsDocs.map(d => new SectionContent({id: d.id, ...d.data()}))
              .filter(s => ((sectionsCheckType === 'timeline' ? !s.education : !!s.education) && !s.fixedSectionType) || !s.parentId)
              .sort(this.utils.comparator(Constants.ORDERINDEX));

            event.managers[this.loginService.getAppUser().userId] = this.loginService.getAppUser() as IManager;
            this.timeLineService._event$.next({loaded: LOAD_STATE.LOADED, value: event});
            const root = sections.find(s => s.isRoot);
            this.timeLineService._rootSection$$.next({loaded: LOAD_STATE.LOADED, value: root});
            this.timeLineService._sections$.next({loaded: LOAD_STATE.LOADED, value: sections});
            this.timeLineService.refreshTimeline();

            const rootChildrenTreeIds = this.utils.getSectionChildrenTree(root, sections).map(s => s.id);
            for (const s of sections.filter(s => !s.isRoot)) {
              childrenCheckOutOfRange(s, sections, rootChildrenTreeIds, testsOutOfRange);
              checkLostSections(s, rootChildrenTreeIds, lostSections);
            }

            this.timeLineService.resetServiceValues();
            if (!isEmpty(testsOutOfRange) || !isEmpty(lostSections)) {
              brokenEvents.push(event);
              console.log(`%c~~~~~ check result ${event.eventId} ~~~~~ ${event.shortLink} ~~~~~ broken`, 'color: brown');
              console.log('rootId: ', root.id);
              console.log('~~~~~ all sections ~~~~~');
              console.log(testsOutOfRange);
              console.log('~~~~~ lost out of range sections ~~~~~');
              console.log(testsOutOfRange.filter(o => o.isLost));
              console.log('~~~~~ lost sections ~~~~~');
              console.log(lostSections);
              console.log('~~~~~ alive out of range sections ~~~~~');
              console.log(testsOutOfRange.filter(o => !o.isLost));

              if (fixType === 'fix-lost' && !isEmpty(lostSections)) {
                console.log('~~~~~ fix lost sections ~~~~~');
                await fixLostSections(lostSections, sections, clientId, event.eventId, root.id, sectionsDocs);
              }
              if (fixType === 'fix-order' && !isEmpty(testsOutOfRange)) {
                console.log('~~~~~ fix sections order index ~~~~~');
                fixOrderIndex(sections, sectionsDocs);
              }
            } else {
              console.log(`~~~~~ check result ${event.eventId} ~~~~~ ${event.shortLink} ~~~~~ Ok`);
            }
          }
          if (!isEmpty(brokenEvents)) {
            console.log(`%c~~~~~ broken events ~~~~~`, 'color: red');
            console.log(brokenEvents);
          }
          if (!isEmpty(crashedEventsId)) {
            console.log(`%c~~~~~ crashed events id ~~~~~`, 'color: red');
            console.log(crashedEventsId);
          }
        });
    };

    let cache: IWriteCache[] = [];
    let clientIds: string[];
    if (isEmpty(clientIds = await this.confirmationBeforeRun(confirmationTitle, this.prevChoice))) {
      return;
    }
    this.prevChoice = clientIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    for (const clientId of clientIds) {
      console.log(`%cClient ${clientId} started`, 'color: blueviolet');
      cache = [];
      await runCheckOrderIndex(clientId);
      console.log(`%cClient ${clientId} finished`, 'color: blue');
      if (fixType !== 'check-only') {
        await this.commitCache(cache)
          .then(() => console.log(`%ccommit finished for ${clientId}`, 'color: orange'));
      }
    }
  }

  createEventsLastActivityStatistics() {
    createEventsLastActivityStatistics(this);
  }

  createEventsSelfLearningModulesStatistics() {
    createEventsSelfLearningModulesStatistics(this);
  }

  createEventsSelfLearningModulesActivityStatistics() {
    createEventsSelfLearningModulesActivityStatistics(this);
  }

  updateQuestionFontSize() {
    updateQuestionnaireFontSize(this);
  }

  getRadioGroupValueByName(groupName) {
    return (document.querySelector(`input[name = ${groupName}]:checked`) as HTMLInputElement)?.value as any;
  }

  jsonExportImport(location: 'timeline' | 'modules', action: 'export' | 'import',  eventId: string) {
    switch (action) {
      case 'export':
        return jsonExport(this, location, eventId);
      case 'import':
        return jsonImport(this, location, eventId);
    }
  }

  jsonContentExportImport(location: 'timeline' | 'modules', action: 'export' | 'import',
                          eventId: string, sectionId: string, contentId: string) {
    switch (action) {
      case 'export':
        return jsonContentExport(this, location, eventId, sectionId, contentId);
    }
  }

  eventsWithLostSections() {
    informationAboutEventsWithLostSections(this);
  }

  eventsWithQuizContainsQuestionsTypeFileUpload() {
    informationAboutEventsWithQuizContainsQuestionsTypeFileUpload(this);
  }

  checkAndFixEventRealFinishDatePermanentTask(mode) {
    void checkAndFixEventRealFinishDatePermanent(this, mode);
  }

  informationAboutModulesLostRecordingSnippets() {
    informationAboutModulesLostRecordingSnippets(this);
  }

  clearExamUsersStatistics(examId: string) {
    clearExamUsersStatistics(this, examId);
  }
}
