import {Injectable} from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {ITEM_MARKER} from '../../shared/container-interface';
import {CommonService} from '../../../../core/common.service';
import {StorageDataService} from '../../../../services/storage-data.service';
import {IPictureGallery} from './picture-gallery-model/picture-gallery-model';

@Injectable({
  providedIn: 'root'
})
export class PictureGalleryService extends AbstractContainerService {

  constructor(private storageDataService: StorageDataService,
              private common: CommonService) {
    super();
  }

  private getMimeType(data: string) {
    const m = data.substring(0, 256).match(/data:(.+);/);
    if (m && m.length === 2) {
      return m[1];
    }
    return 'image/png';
  }

  async save(path: string, gallery: IPictureGallery): Promise<IPictureGallery> {
    const deletedIds: string[] = [];
    const data = gallery.pictures ?? [];
    for (const it of data) {
      if (it.id.startsWith(ITEM_MARKER.NEW)) {
        const mimeType = this.getMimeType(it.src);
        const fileName = this.common.utils.addTimeToFileName(it.id.replace(ITEM_MARKER.NEW, ''));
        await this.storageDataService.uploadAnyObjectToStorageByDataPath(path, fileName, it.src, mimeType)
          .then(snapshot => snapshot.ref.getDownloadURL()
            .catch(e => this.common.log.error(e))
            .then(url => it.src = url))
          .catch(e => this.common.log.error(e));
      } else if (it.id.startsWith(ITEM_MARKER.DELETED)) {
        const fileName = this.common.utils.extractFileNameFromUrl(it.src);
        await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
          .catch(e => this.common.log.error(e));
      }
      it.id = it.id.replace(ITEM_MARKER.NEW, '');
      if (it.id.startsWith(ITEM_MARKER.DELETED)) {
        deletedIds.push(it.id);
      }
    }
    for (const id of deletedIds) {
      const index = data.findIndex(it => it.id === id);
      data.splice(index, 1);
    }
    return Promise.resolve(gallery);
  }

  async delete(path: string, gallery: IPictureGallery): Promise<boolean> {
    const data = gallery.pictures ?? [];
    for (const it of (data || [])) {
      const fileName = this.common.utils.extractFileNameFromUrl(it.src);
      await this.storageDataService.deleteObjectFromStorageByDataPath(path, fileName)
        .catch(e => this.common.log.error(e));
    }
    return Promise.resolve(true);
  }
}
