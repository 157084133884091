import {AbstractContent} from './AbstractContent';
import {Constants} from '../../core/constants';


export class BreakContent extends AbstractContent {
  type = 'break';
  subtype = null;
  description: string;

  constructor(obj: any, subtype?) {
    super(obj);
    if (!obj) {
      return;
    }
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.subtype) {
      this.subtype = obj.subtype;
    }
    if (subtype) {
      this.subtype = subtype;
    }
  }

  mergeContent(src: BreakContent) {
    super.mergeContent(src);

    if (this.description !== src.description) {
      this.description = src.description;
    }
    if (this.subtype !== src.subtype) {
      this.subtype = src.subtype;
    }
    if (this.subtype === Constants.CONTENT_SUBTYPE_QUESTION) {
      this.creator = src.creator;
      this.userId = src.userId;
    }
  }
}
