import {AppUser} from './AppUser';
import {AbstractContent} from './content/AbstractContent';
import {ConferenceUser} from './event-mode/ConferenceUser';

export interface Data {
  currentUser: AppUser;
  /* EventMode */
  usersOnline: ConferenceUser[];
  currentContent: AbstractContent;
  notifyEventMessage: any;
  usersOnlineShort: {[userId: string]: {userId: string, pingTime: number, createTime: number}};
}

export const defaults: Data = {
  currentUser: null
  , usersOnline: []
  , currentContent: null
  , notifyEventMessage: null
  , usersOnlineShort: {}
};
