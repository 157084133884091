export class EventManager {
  userId: string = null;
  email: string = null;
  userName: string = null;
  fullName: string = null;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
    if (obj.email) {
      this.email = obj.email;
    }
    if (obj.userName) {
      this.userName = obj.userName;
    }
    if (obj.fullName) {
      this.fullName = obj.fullName;
    }
  }
}
