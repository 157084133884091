import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {LoginService} from '../login.service';
import {Router} from '@angular/router';
import {CommonService} from '../../core/common.service';
import {RootLocalStorageService} from '../../core/root-local-storage.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {catchError, distinctUntilChanged, filter, map, NEVER, Subject, switchMap, takeUntil} from 'rxjs';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {EventApiService} from '../../services/event-api.service';
import {HttpStatusCode} from '@angular/common/http';
import {IGuestEventInfoDto} from '../../model/Event';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {AsyncPipe, NgIf, NgStyle} from '@angular/common';
import {FormInputComponent} from '../../components/form-input/form-input.component';
import {MatButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';

interface FormModel {
  name: FormControl<string>;
  email: FormControl<string>;
}

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinner,
    NgIf,
    NgStyle,
    FormInputComponent,
    MatButton,
    AsyncPipe,
    MatDivider
  ],
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit, OnDestroy {

  alive$ = new Subject();
  loginForm: FormGroup<FormModel> = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('')
  });
  nameControl = false;
  emailControl = false;
  nameErrors = {
    maxlength: this.translateService.instant('form.error.maxlength', { requiredLength: 24 }),
    pattern: this.translateService.instant('form.error.alphabet')
  };
  emailErrors = {
    email: this.translateService.instant('registration.people.email-validation')
  };
  eventInfo: IGuestEventInfoDto;
  imgLoaded = false;
  imgLoadedError = false;
  showTimelineInternationalIncLink = this.common.utils.getEnv()['showTimelineInternationalIncLink'];
  orgName = this.common.getEnv().organizationName;
  showProgress$ = this.common.showProgress;

  constructor(public loginService: LoginService,
              private afAuth: AngularFireAuth,
              private localStorageService: RootLocalStorageService,
              private common: CommonService,
              private router: Router,
              private translateService: TranslateService,
              private eventApi: EventApiService
  ) {
    if (this.loginService.getAuthUser()) {
      this.navigateTo();
    } else {
      this.loginService.setGuestLogin(true);
      this.navigateTo(true);
    }
    document.body.style.backgroundImage = 'url(assets/images/main_background.jpg)';
  }

  ngOnInit() {
    this.loginService.clientConfig$
      .pipe(
        filter(config => !!config),
        distinctUntilChanged((first, second) => first.id === second.id),
        map(config => config.id),
        switchMap(id => {
          const fullUrl = new URL(this.router.url, window.location.origin);
          const currentUrl = fullUrl.pathname;

          const linkObject = this.common.utils.parseLink(currentUrl);
          return this.eventApi
            .getGuestEventInfo(linkObject.link, id, linkObject.code)
            .pipe(
              catchError(e => {
                this.common.showProgress.next(false);
                this.common.showError(e);
                this.loginService.redirectToLoginPage();
                return NEVER;
              })
            );
        }))
      .pipe(
        takeUntil(this.alive$)
      )
      .subscribe(info => {
        if (!info) {
          this.loginService.redirectToLoginPage();
          return;
        }
        this.eventInfo = info;
        this.nameControl = info.guestName;
        this.emailControl = info.guestEmail;
        if (this.nameControl) {
          this.loginForm.controls['name'].setValidators([Validators.required, Validators.maxLength(24), Validators.pattern('^[a-zA-Z0-9_ ]*$')]);
          this.loginForm.controls['name'].updateValueAndValidity();
        }
        if (this.emailControl) {
          this.loginForm.controls['email'].setValidators([Validators.required, Validators.email]);
          this.loginForm.controls['email'].updateValueAndValidity();
        }
      });

    this.afAuth.authState
      .pipe(
        takeUntil(this.alive$)
      )
      .subscribe(user => {
        if (user?.displayName) {
          this.loginForm.controls['name'].setValue(user.displayName);
        }
      });
  }

  ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

  signIn() {
    this.loginForm.disable();
    this.common.showProgress.next(true);
    const value = this.loginForm.get('name').value;
    const email = this.loginForm.get('email').value;

    if (this.emailControl) {
      const fullUrl = new URL(this.router.url, window.location.origin);
      const currentUrl = fullUrl.pathname;

      const linkObject = this.common.utils.parseLink(currentUrl);
      this.loginService.loginGuest(value, email, linkObject.code)
        .then(value => {
          if (value && value.password && !value.blocked) {
            return this.loginService.signInEmail(email, value.password)
              .then(() => {
                this.navigateTo();
              });
          }
        })
        .finally(() => {
          this.common.showProgress.next(false);
        })
        .catch((e) => {
          this.loginForm.enable();
          this.common.showProgress.next(false);
          if (e.status === HttpStatusCode.Conflict) {
            this.common.showPopupError(e.error);
            this.loginService.redirectToLoginPage();
          }
        });
    } else {
      this.loginService.signInAnonymously(value)
        .then(result => {
          this.common.showProgress.next(false);
          if (result) {
            this.navigateTo();
          }
        })
        .catch((e) => {
          this.loginForm.enable();
          this.common.showProgress.next(false);
        });
    }
  }

  navigateTo(save?) {
    const currentUrl = this.router.url;
    const linkObject = this.common.utils.parseLink(currentUrl);
    if (linkObject.link && linkObject.type === 'guest' && linkObject.code) {
      const route = ['event', linkObject.link];
      this.localStorageService.set('guest_code', linkObject.code);
      this.loginService.guestLink = linkObject.link;
      if (save) {
        this.loginService.saveLastURL('/' + route.join('/'));
      }
    } else {
      this.localStorageService.remove('guest_code');
      this.loginService.guestLink = null;
      if (save) {
        this.loginService.saveLastURL('/dashboard');
      }
    }
  }
}
