import {DependencyQuestion} from './content/DependencyQuestion';
import {DEPENDENCY, ILanguageParams, TCaption} from '../core/constants';
import {UtilsService} from '../core/utils.service';
import {cloneDeep} from 'lodash';
import {
  QUESTION_TYPES_COMPONENTS,
  QUESTION_VIEW_MODE
} from '../modules/content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';
import {IQuestionDocumentPathParams} from '../services/event-mode-api.service';
import {EventsDataService} from '../services/events-data.service';
import {
  createCompatibleQuestionGroup
} from '../modules/content-container/components/quiz/quiz-components/shared/lib/quiz-question-compatibility-lib';
import {APP_MODE} from '../login/login.service';

export interface IRegistrationQuestionnaireAnswers {
  [userId: string]: {[sectionId: string]: {[questionId: string]: {answers: any}}};
}

export interface ICheckFeedback {checkResult: boolean; feedbackMessage: string; }

export interface ISimpleQuestion {
  questionId: string;
  caption: string;
  optional: number;
  storypoint: number;
  items: AnswerQuestion[];
}

export interface IGapGroup {
  id: string;
  name: string;
}

export interface IGapGroupMatching {
  id: string;
  matchingId: string;
  name: string;
}

export interface IGapAnswerData {
  id: string;
  answerId?: string;
  answerValue?: any;
  answerCaption?: any;
}

export interface IGapResultData {
  correctCount: number;
  errorCount: number;
  correctPercent: number;
  errorPercent: number;
  answers: {value: string, count: number, correct: boolean}[];
}

export interface IGapResultDataMap {
  [gapId: string]: IGapResultData;
}

export type TGroupAnswerQuestion = Pick<AnswerQuestion, 'id' | 'correctAnswer' | 'matching'>;

export interface IGroupCorrectAnswers {
  // group id
  id: string;
  // list of correct answers for question of types Check/Choice/Matching
  items?: TGroupAnswerQuestion[];
  // list of correct answers for question of types Gap Filling....
  correctEquality?: AnswerEquality[];
  points?: number;
  defaultGroup?: boolean;
  // field for any format
  columnsCorrectAnswers?: any;
  // If the structure of the group changes, the condition in the method must be modified.
  // @link QuizService.saveLazyFields
}

export interface IQuizQuestionsGroupsCorrectAnswersMap {
  [questionId: string]: IGroupCorrectAnswers[];
}

export class EventQuestion {
  id: string = this.genId();
  eventId: string = null;
  timelineId: string = null;
  storypoint: number = null;
  orderIndex = 0;
  caption: TCaption = null;
  liveResults = true;
  items: AnswerQuestion[] = [];
  matchingItems: AnswerQuestionMatching[];
  // @deprecated
  // todo: in new format moved to groups. must be delete here.
  //  but for now we have contents in a very old format, this field remains to support such content.
  correctEquality: AnswerEquality[];
  // for some question type is duplicate correctEquality from groups correct answers, but without correct answers values.
  // stores only link between elements and answer id or element id and his position on the form.
  relations: AnswerRelation[];
  taskText: TCaption;
  answers = {}; // internal system fields, user for create question result data by users answers not save to DB.
  optional: boolean = null; // if true not used when check answers for all questionnaire questions
  dependency: DependencyQuestion = new DependencyQuestion();
  directFeedback: boolean;
  tryAgain: boolean;
  shuffleAnswers: boolean;
  correctFeedbackMessage: string;
  incorrectFeedbackMessage: string;
  showCorrectAnswers: boolean;
  useCorrectAnswers: boolean;
  viewMode: QUESTION_VIEW_MODE;
  options: any = {};
  files: string[] = [];
  // exam fields
  points: number;
  // for not template quiz question this is lazy loading field saved in quiz container documents.
  groupsCorrectAnswers: IGroupCorrectAnswers[];
  // field to distinguish between questions in the old and new format with groups of correct answers.
  // in new format field equal v2, old form field is empty
  questionFormatVersion: string;

  public static genQuestionId(increment: number = 0): string {
    return 'Q' + ((new Date()).getTime() + increment).toString();
  }

  public static createGroupAnswers(defaultGroup = false) {
    const groupId = 'GR' + UtilsService.createId();
    const group: IGroupCorrectAnswers = {id: groupId};
    if (defaultGroup) {
      group.defaultGroup = true;
    }
    return group;
  }

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('id')) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('eventId')) {
      this.eventId = obj.eventId;
    }
    if (obj.hasOwnProperty('timelineId')) {
      this.timelineId = obj.timelineId;
    }
    if (obj.storypoint != null) {
      this.storypoint = obj.storypoint;
    }
    if (obj.hasOwnProperty('liveResults')) {
      this.liveResults = obj.liveResults;
    }
    if (obj.hasOwnProperty('caption')) {
      this.caption = obj.caption;
    }
    if (obj.hasOwnProperty('items')) {
      this.items = (obj.items || []).map(o => new AnswerQuestion(o));
    }
    if (obj.hasOwnProperty('matchingItems')) {
      this.matchingItems = (obj.matchingItems || []).map(o => new AnswerQuestionMatching(o));
    }
    if (obj.hasOwnProperty('answers')) {
      this.answers = obj.answers;
    }
    if (obj.hasOwnProperty('orderIndex')) {
      this.orderIndex = obj.orderIndex;
    }
    if (obj.hasOwnProperty('optional')) {
      this.optional = obj.optional;
    }
    if (obj.hasOwnProperty('dependency')) {
      this.dependency = obj.dependency;
    }
    if (obj.hasOwnProperty('directFeedback')) {
      this.directFeedback = obj.directFeedback;
    }
    if (obj.hasOwnProperty('correctFeedbackMessage')) {
      this.correctFeedbackMessage = obj.correctFeedbackMessage;
    }
    if (obj.hasOwnProperty('incorrectFeedbackMessage')) {
      this.incorrectFeedbackMessage = obj.incorrectFeedbackMessage;
    }
    if (obj.hasOwnProperty('showCorrectAnswers')) {
      this.showCorrectAnswers = obj.showCorrectAnswers;
    }
    if (obj.hasOwnProperty('useCorrectAnswers')) {
      this.useCorrectAnswers = obj.useCorrectAnswers;
    }
    if (obj.hasOwnProperty('correctEquality')) {
      this.correctEquality = (obj.correctEquality || []).map(o => new AnswerEquality(o));
    }
    if (obj.hasOwnProperty('taskText')) {
      this.taskText = obj.taskText;
    }
    if (obj.hasOwnProperty('viewMode')) {
      this.viewMode = obj.viewMode;
    }
    if (obj.hasOwnProperty('options')) {
      this.options = obj.options;
    }
    if (obj.hasOwnProperty('files')) {
      this.files = obj.files;
    }
    if (obj.hasOwnProperty('tryAgain')) {
      this.tryAgain = obj.tryAgain;
    }
    if (obj.hasOwnProperty('shuffleAnswers')) {
      this.shuffleAnswers = obj.shuffleAnswers;
    }
    if (obj.hasOwnProperty('groupsCorrectAnswers')) {
      this.groupsCorrectAnswers = obj.groupsCorrectAnswers;
    }
    if (obj.hasOwnProperty('relations')) {
      this.relations = obj.relations;
    }
    if (obj.hasOwnProperty('questionFormatVersion')) {
      this.questionFormatVersion = obj.questionFormatVersion;
    }
    if (obj.hasOwnProperty('points')) {
      this.points = obj.points;
    }
  }

  private genId(prefix: string = 'Q'): string {
    return prefix + UtilsService.createId();
  }

  isTypified() {
    return Number.isInteger(this.storypoint);
  }

  setRequiredFromExternal(eventId: string, timelineId: string, orderIndex?): EventQuestion {
    this.eventId = eventId;
    this.timelineId = timelineId;
    if (orderIndex) {
      this.orderIndex = orderIndex;
    }
    return this;
  }

  getCaptionByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'caption', languageParams);
  }

  setCaptionByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'caption', value, languageParams);
  }

  getTaskTextByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'taskText', languageParams);
  }

  setTaskTextByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'taskText', value, languageParams);
  }

  /**
   * Get dependency by different conditions.
   * Use this for get true dependency object.
   * @returns {DependencyQuestion} - object or undefined
   */
  getDependency(): DependencyQuestion {
    return this.dependency && this.dependency.dependencyType !== DEPENDENCY.NONE &&
      this.dependency.questionId && this.dependency.answerIdList && this.dependency.answerIdList.length > 0 ?
        new DependencyQuestion(this.dependency) : undefined;
  }

  /**
   * User answers.
   * @param userId
   * @returns {string[]} - array of answers id. always not null.
   */
  getUserAnswers(userId): string[] {
    let result: string[] = [];
    const obj = this.answers?.[userId];
    if (obj) {
      result = this.objectValues(obj);
    }
    return result;
  }

  private objectValues(object) {
    return Object.keys(object).map(function (key) {
      return object[key];
    });

  }

  /**
   * return feedback message
   */
  checkCorrectUserAnswers(userId, documentPathParams: IQuestionDocumentPathParams, location: APP_MODE,
                                dataService: EventsDataService): Promise<ICheckFeedback> {
    return this.checkFeedback(userId, null, documentPathParams, location, dataService);
  }

  /**
   * return feedback message
   */
  checkCorrectAnswers(userAnswers: any, documentPathParams: IQuestionDocumentPathParams, location: APP_MODE,
                            dataService: EventsDataService): Promise<ICheckFeedback> {
    return this.checkFeedback(null, userAnswers, documentPathParams, location, dataService);
  }

  private async checkFeedback(userId, userAnswers, documentPathParams: IQuestionDocumentPathParams, location: APP_MODE,
                              dataService: EventsDataService): Promise<ICheckFeedback> {
    const correctFeedback = () => this.correctFeedbackMessage ? this.correctFeedbackMessage :
      'edit_dialog.question_dialog.input.direct.feedback.correct.message';
    const incorrectFeedback = () => this.incorrectFeedbackMessage ? this.incorrectFeedbackMessage :
      'edit_dialog.question_dialog.input.direct.feedback.incorrect.message';
    const answers = userId ? this.getUserAnswers(userId) : userAnswers;
    if (QUESTION_TYPES_COMPONENTS[this.storypoint].directFeedbackCheck) {
      const checkMethodName = QUESTION_TYPES_COMPONENTS[this.storypoint].directFeedbackCheck;
      const chk = await dataService.checkQuestionCorrectAnswers(answers, documentPathParams, checkMethodName, location,
        this.questionFormatVersion, {[this.id]: createCompatibleQuestionGroup(this)});
      return chk !== null ? {checkResult: chk, feedbackMessage: chk ? correctFeedback() : incorrectFeedback()} : null;
    }
  }

  addGroupAnswers(defaultGroup = false) {
    if (!this.groupsCorrectAnswers) {
      this.groupsCorrectAnswers = [];
    }
    const groupId = this.genId('GR');
    const group: IGroupCorrectAnswers = {id: groupId, items: [], correctEquality: []};
    if (defaultGroup) {
      group.defaultGroup = true;
    }
    this.groupsCorrectAnswers.push(group);
    return groupId;
  }

  deleteGroupAnswers(groupId: string) {
    const ind = (this.groupsCorrectAnswers || []).findIndex(g => g.id === groupId);
    if (ind > -1) {
      this.groupsCorrectAnswers.splice(ind, 1);
    }
  }

  reduceGroupsCorrectAnswersCorrectEquality() {
    return this.groupsCorrectAnswers?.reduce((acc, g) => {
      acc.push(...g.correctEquality.map(o => new AnswerEquality(cloneDeep(o))));
      return acc;
    }, []) ?? [];
  }

  reduceGroupsCorrectAnswersItemsCorrectAnswer() {
    return this.groupsCorrectAnswers?.reduce((acc, g) => {
      g.items.forEach(it => acc[it.id] = it.correctAnswer);
      return acc;
    }, {}) ?? {};
  }

  reduceGroupsCorrectAnswersItems() {
    return this.groupsCorrectAnswers?.reduce((acc, g) => {
      acc.push(...g.items.map(o => cloneDeep(o)));
      return acc;
    }, []) ?? [];
  }

  toObject() {
    delete this.answers;
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || object[key] === null || typeof object[key] === 'function')) {
            delete object[key];
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = {...this};
    checkUnsupportedValue(obj);
    return obj;
  }

}

export class AnswerQuestion {
  id: string = this.genId();
  answer: TCaption = null;
  orderIndex = 0;
  // @deprecated
  // todo: in new format moved to groups. must be delete here.
  //  but for now we have contents in a very old format, this field remains to support such content.
  correctAnswer: boolean = null;
  // used in Combobox(select) gap filling question type.
  // link to matching group from field matchingItems
  // can be used to create similar cases.
  matching: string[] = [];

  public static genAnswerId(increment: number = 0): string {
    return 'AQ' + ((new Date()).getTime() + increment).toString();
  }

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('answer')) {
      this.answer = obj.answer;
    }
    if (obj.hasOwnProperty('orderIndex')) {
      this.orderIndex = obj.orderIndex;
    }
    if (obj.hasOwnProperty('correctAnswer')) {
      this.correctAnswer = obj.correctAnswer;
    }
    if (obj.hasOwnProperty('matching')) {
      this.matching = obj.matching;
    }
  }

  genId(): string {
    return 'AQ' + UtilsService.createId();
  }

  getAnswerByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'answer', languageParams);
  }

  setAnswerByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'answer', value, languageParams);
  }

  toObject() {
    return {...this};
  }
}

export interface IMapPosition {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

export class AnswerQuestionMatching {
  id: string = this.genId();
  answerMatching: TCaption;
  orderIndex = 0;

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.answerMatching) {
      this.answerMatching = obj.answerMatching;
    }
    if (obj.orderIndex) {
      this.orderIndex = obj.orderIndex;
    }
  }

  genId(): string {
    return 'AQM' + UtilsService.createId();
  }

  getAnswerByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'answerMatching', languageParams);
  }

  setAnswerByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'answerMatching', value, languageParams);
  }

  toObject() {
    return {...this};
  }
}

export class AnswerEquality {
  id: string = this.genId();
  answerId: string;
  answerValue: any;
  mapPosition: IMapPosition; // used for set position on image map if question type of "MATCHING_MAP"

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.answerId) {
      this.answerId = obj.answerId;
    }
    if (obj.answerValue) {
      this.answerValue = obj.answerValue;
    }
    if (obj.mapPosition) {
      this.mapPosition = obj.mapPosition;
    }
  }

  getAnswerByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'answerValue', languageParams);
  }

  setAnswerByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'answerValue', value, languageParams);
  }

  private genId(): string {
    return 'AEQ' + UtilsService.createId();
  }

  toObject() {
    return {...this};
  }

}

export class AnswerRelation {
  id: string = this.genId();
  answerId: string;
  // used for set position on image map if question type of "MATCHING_MAP"
  // can be used to create similar cases
  mapPosition: IMapPosition;

  constructor (obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.answerId) {
      this.answerId = obj.answerId;
    }
    if (obj.mapPosition) {
      this.mapPosition = obj.mapPosition;
    }
  }

  private genId(): string {
    return 'ARL' + UtilsService.createId();
  }

  toObject() {
    return {...this};
  }

}

export class TableRowAnswerQuestion extends AnswerQuestion {
  constructor(object, public languageParams: ILanguageParams) {
    super(object);
  }

  get rowCaption() {
    return this.getAnswerByLanguage(this.languageParams);
  }

  set rowCaption(value: string) {
    this.setAnswerByLanguage(value, this.languageParams);
  }
}

export class TableRowAnswerQuestionMatching extends AnswerQuestionMatching {
  constructor(object, public languageParams: ILanguageParams) {
    super(object);
  }

  get rowCaption() {
    return this.getAnswerByLanguage(this.languageParams);
  }

  set rowCaption(value: string) {
    this.setAnswerByLanguage(value, this.languageParams);
  }
}

export class TableRowAnswerEquality extends AnswerEquality {
  constructor(object, public languageParams: ILanguageParams) {
    super(object);
  }

  get rowCaption() {
    return this.getAnswerByLanguage(this.languageParams);
  }

  set rowCaption(value: string) {
    this.setAnswerByLanguage(value, this.languageParams);
  }
}
