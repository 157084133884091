import {Component, Injector} from '@angular/core';
import {AbstractQuizQuestionEditorComponent} from '../../shared/editor/abstract-quiz-question-editor-component';
import {WordCloudTemplate} from '../../../../../../../model/content/WordCloudTemplate';
import {WordCloudService} from '../../../../../../../core/word-cloud.service';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {ILanguageParams} from '../../../../../../../core/constants';

@Component({
  selector: 'app-question-word-cloud-editor',
  templateUrl: './question-word-cloud-editor.component.html',
  styleUrls: ['./question-word-cloud-editor.component.scss']
})
export class QuestionWordCloudEditorComponent extends AbstractQuizQuestionEditorComponent {

  wordCloudTemplate: WordCloudTemplate;

  constructor(protected injector: Injector,
              private wordCloudService: WordCloudService) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    if (!this.question.options.wordCloudTemplate) {
      this.wordCloudTemplate = new WordCloudTemplate();
      this.wordCloudTemplate.options = this.wordCloudService.defaultDisplayTemplateOptions;
    } else {
      this.wordCloudTemplate = new WordCloudTemplate({...this.question.options.wordCloudTemplate, url: this.question.files[0]});
    }
  }

  onChangeQuestionWordCloudTemplate(template: WordCloudTemplate) {
    if (template.url) {
      this.question.files[0] = template.url;
    } else {
      this.question.files = [];
    }
    this.question.options.wordCloudTemplate = new WordCloudTemplate(template.toObject());
  }
}
