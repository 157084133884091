import {CommonModule} from '@angular/common';
import {Component, inject, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ISettings} from '../../isettings';
import {takeUntil} from 'rxjs/operators';
import {LanguageSelectorModule} from '../../../../../../../../../components/language-selector/language-selector.module';
import {BaseSettingsComponent} from '../base-settings';
import {PipeModule} from '../../../../../../../../../pipes/pipe.module';
import {LoadingProgressComponent} from '../../../../../../../../../components/loading-progress/loading-progress.component';
import {IColumnType, TABLE_COLUMN_TYPE, TableColumn, TableRowBase} from '../../table-poll-model/TablePollModel';
import {cloneDeep} from 'lodash';
import {IGroupCorrectAnswers} from '../../../../../../../../../model/EventQuestion';
import {
  deleteColumnGroupCorrectAnswers,
  getColumnGroupCorrectAnswers,
  getOrCreateColumnGroupCorrectAnswers
} from '../../../question-table-shared/question-table-utils';

@Component({
  selector: 'app-checkbox-settings-dialog',
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule, MatInputModule,
    CommonModule, MatIconModule, MatDialogModule, MatButtonModule,
    MatExpansionModule, TranslateModule, MatCheckboxModule,
    MatSlideToggleModule, LanguageSelectorModule, PipeModule, LoadingProgressComponent
  ],
  templateUrl: './checkbox-settings-dialog.component.html',
  styleUrl: './checkbox-settings-dialog.component.scss'
})
export class CheckboxSettingsDialogComponent extends BaseSettingsComponent implements OnInit, ISettings {

  form: FormGroup;
  callerCtx: any;
  column: TableColumn;
  columnType: IColumnType;
  groupsCorrectAnswers: IGroupCorrectAnswers[];
  showError = false;

  private readonly data = inject<{
    callerCtx: any,
    formGroup: FormGroup;
    groupsCorrectAnswers: IGroupCorrectAnswers[];
    questions: {id: string, answer: any, orderIndex: number}[]}>(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<CheckboxSettingsDialogComponent>);

  constructor() {
    super();
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.init(this.data.callerCtx);
    this.callerCtx = this.data.callerCtx;
    const formGroup = this.data.formGroup;

    this.column = new TableColumn(cloneDeep(formGroup?.value ?? {
      type: {
        inputType: TABLE_COLUMN_TYPE.CHECKBOX
      },
      tableRows: [new TableRowBase()]
    }));
    this.columnType = this.column.type;
    this.groupsCorrectAnswers = cloneDeep(this.data.groupsCorrectAnswers) ?? [];
    this.form = this.fb.group({
      columnName: [this.column.getColumnNameByLanguage(this.callerCtx.languageParams),
        [Validators.required, Validators.maxLength(256)]],
      isCorrectAnswerEnabled: [!!this.columnType.isCorrectAnswerEnabled],
      questions: this.fb.array(
        this.data.questions.map(q => new FormControl(q))
      ),
      correctAnswers: this.fb.array(this.data.questions
        .map(q => new FormControl(getColumnGroupCorrectAnswers(this.groupsCorrectAnswers, this.column.id)?.includes(q.id))))
    });
    if (!this.form.value.isCorrectAnswerEnabled) {
      this.correctAnswers.disable();
    } else {
      this.correctAnswers.enable();
    }
    this.isCorrectAnswerEnabled.valueChanges
      .pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
      if (value) {
        this.correctAnswers.enable();
      } else {
        this.correctAnswers.disable();
      }
      this.columnType.isCorrectAnswerEnabled = value;
    });
    this.columnName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.column.setColumnNameByLanguage(value, this.callerCtx.languageParams);
    });
    this.correctAnswers.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean[]) => {
      const correctAnswers: string[] = getOrCreateColumnGroupCorrectAnswers(this.groupsCorrectAnswers,
        this.column.id, TABLE_COLUMN_TYPE.CHECKBOX);
      (value ?? []).forEach((v, index) => {
        if (v && !correctAnswers.includes(this.data.questions[index].id)) {
          correctAnswers.push(this.data.questions[index].id);
        } else if (!v && correctAnswers.includes(this.data.questions[index].id)) {
          const ind = correctAnswers.findIndex(id => id === this.data.questions[index].id);
          if (ind > -1) {
            correctAnswers.splice(ind, 1);
          }
        }
      });
    });
    this.languageChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.patchValue({...this.form.value, columnName: this.column.getColumnNameByLanguage(this.callerCtx.languageParams)});
      this.questions.controls.forEach(c => c.patchValue(cloneDeep(c.value)));
    });
  }

  get columnName(): FormControl {
    return this.form.get('columnName') as FormControl;
  }
  get isCorrectAnswerEnabled(): FormControl {
    return this.form.get('isCorrectAnswerEnabled') as FormControl;
  }

  get questions(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  get correctAnswers(): FormArray {
    return this.form.get('correctAnswers') as FormArray;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onDone(event): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      const errorMsgs = this.checkTheRequiredFieldsAndReturnErrorMsgs();
      if (errorMsgs.length) {
        const targetRect = event.target.getBoundingClientRect();
        const topPosition = targetRect.top - (targetRect.top * 0.4);
        // this.toasterService.openCustomSnackbarComponent(topPosition, errorMsgs, CustomSnackbarComponent);
      }
    } else {
      this.showError = false;
      if (!this.columnType.isCorrectAnswerEnabled) {
        deleteColumnGroupCorrectAnswers(this.groupsCorrectAnswers, this.column.id);
      }
      this.dialogRef.close({id: this.column.id, columnType: this.columnType, groupsCorrectAnswers: this.groupsCorrectAnswers});
    }
  }

  checkTheRequiredFieldsAndReturnErrorMsgs(): string[] {
    const errorMsgs: any[] = [];
    if (this.columnName.invalid) {
      this.columnName.markAsTouched();
      this.showError = true;
      errorMsgs.push(this.common.i18n('questionnaire.table.editor.settings.error.mgs.column.name.empty'));
    } else {
      this.showError = false;
    }
    return errorMsgs;
  }
}
