import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RootLocalStorageService} from './root-local-storage.service';
import {LoadingService} from './loading.service';
import {LoggerService} from './logger.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {SafePipe} from '../pipes/safe.pipe';
import {NotificationsService} from './notifications.service';
import {ClipboardService} from './clipboard.service';
import {UploadService} from './upload.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    LoggerService
    , LoadingService
    , NotificationsService
    , RootLocalStorageService
    , SafePipe
    , ClipboardService
    , UploadService
  ],
  declarations: []
})
export class CoreModule { }
