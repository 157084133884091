// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-wrapper {
  width: 100%;
  height: 100%;
  min-height: 0;
  border: 0;
}
.frame-wrapper .frame {
  width: 100%;
  height: 100%;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/content-container/components/embedded-frame/embedded-frame/embedded-frame.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,SAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,SAAA;AAEJ","sourcesContent":[".frame-wrapper {\n  width: 100%;\n  height: 100%;\n  min-height: 0;\n  border: 0;\n  .frame {\n    width: 100%;\n    height: 100%;\n    border: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
