import {Component, ElementRef, HostBinding, Injector, OnInit, ViewChild} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {BehaviorSubject, combineLatest, firstValueFrom, Subscription, take} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {EventsDataService} from '../../../../../services/events-data.service';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import {MatDialog} from '@angular/material/dialog';
import * as ui from '../../../../../actions/ui';
import {cloneDeep, isEmpty} from 'lodash';
import {
  IResultUserRow,
  ITaskFile,
  ITaskResultUserRow,
  ITaskSpeaker,
  TaskObject,
  USER_TASK_STATUS,
  UserTaskObject
} from '../task-model/task';
import {Constants} from '../../../../../core/constants';
import {TaskDocumentEditorDialogComponent} from '../task-document-editor-dialog/task-document-editor-dialog.component';
import {ITEM_MARKER} from '../../../shared/container-interface';
import {LoginService} from '../../../../../login/login.service';
import {TextViewDialogComponent} from '../../../../../dialog-components/text-view-dialog/text-view-dialog.component';
import {EditDialogComponent} from '../../../../../dialog-components/edit-dialog/edit-dialog.component';


@Component({
  selector: 'app-task-document',
  templateUrl: './task-document.component.html',
  styleUrls: ['./task-document.component.scss']
})
export class TaskDocumentComponent extends AbstractContainerComponent implements OnInit {
  readonly Constants = Constants;
  readonly USER_TASK_STATUS = USER_TASK_STATUS;
  readonly ITEM_MARKER = ITEM_MARKER;

  @HostBinding('class.show-user-results') showUserResults = false;

  subtypeIcon;
  isPresenterContent = false;
  taskDocument: TaskObject;
  userTaskDocument: UserTaskObject;
  userTaskDocument$ = new BehaviorSubject<UserTaskObject>(null);
  uppy: any;
  userTaskAnswersSubscription: Subscription;
  allUsersTaskAnswersSubscription: Subscription;
  displayedColumns: string[] = ['checkbox', 'picture', 'fullName', 'userStatus',
    'textAnswer', 'filesAnswer', 'feedback_edit', 'feedback',
    'points_edit', 'points', 'grade_edit', 'grade'];
  resultSet = new BehaviorSubject<ITaskResultUserRow[]>([]);
  dataSource = new MatTableDataSource<ITaskResultUserRow>([]);
  dataObject: { [userId: string]: ITaskResultUserRow } = {};
  filter: any = {
    statusFilter: Constants.ALL_STATUS,
    userName: ''
  };
  isPresenter$ = new BehaviorSubject(false);
  isNew = true;

  private elementObserver = new MutationObserver(() => {
    if ((this.sort && !this.dataSource.sort) || (!this.sort && this.dataSource.sort)) {
      this.dataSource.sort = this.sort;
    }
    if ((this.paginator && !this.dataSource.paginator) || (!this.paginator && this.dataSource.paginator)) {
      this.dataSource.paginator = this.paginator;
    }
  });


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(protected injector: Injector,
              private store: Store<fromRoot.State>,
              private dataService: EventsDataService,
              private uploadService: UploadService,
              private loginService: LoginService,
              private elementRef: ElementRef,
              private dialog: MatDialog) {
    super(injector);
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.ANY_DOCUMENT, null, (result) => {
      this.addImageToDataSource(result);
      this.uploadService.closeModal(this.uppy);
    });
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this.elementObserver.observe(this.elementRef.nativeElement, {childList: true});
  }

  protected inputFollowMeData(value) {
  }

  onEdit(): Promise<boolean> {
    const dialogRef = this.dialog.open(TaskDocumentEditorDialogComponent, {
      width: '600px',
      height: '700px',
      disableClose: true,
      data: {
        content: new TaskObject(cloneDeep(this.taskDocument))
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = new TaskObject(result);
        }
        return !!result;
      });
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  ngOnInit() {
    this.resultSet
      .pipe(this.takeUntilAlive())
      .subscribe(values => {
        this.dataSource.data = values;
      });
    combineLatest([this.isEventManager$, this.managerUseParticipantMode$])
      .pipe(this.takeUntilAlive())
      .subscribe(([isPresenter, useParticipantMode]) => {
        this.isPresenter$.next(isPresenter && !useParticipantMode);
        if (!this.isPresenter$.getValue()) {
          this.presenterSubscriptionsOff();
          this.initUserTaskAnswers();
        } else {
          this.dataSource.filterPredicate = this.filterData;
          this.dataSource.filter = JSON.stringify(this.filter);
          this.userSubscriptionsOff();
          this.initAllUsersAnswers();
        }
      });
    this.data$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        this.isNew = isEmpty(value);
        this.taskDocument = new TaskObject(value);
      });
  }

  onDestroy(): void {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    this.elementObserver.disconnect();
    this.uppy.close();
  }

  private userSubscriptionsOff() {
    if (this.userTaskAnswersSubscription) {
      this.userTaskAnswersSubscription.unsubscribe();
    }
  }

  private presenterSubscriptionsOff() {
    if (this.allUsersTaskAnswersSubscription) {
      this.allUsersTaskAnswersSubscription.unsubscribe();
    }
  }

  private initUserTaskAnswers() {
    this.userSubscriptionsOff();
    this.userTaskAnswersSubscription = this.dataService.getTaskUserAnswers(this.documentPathParams)
      .pipe(this.takeUntilAlive())
      .subscribe(value => {
        this.userTaskDocument = new UserTaskObject(value);
        this.userTaskDocument$.next(this.userTaskDocument); // for change detection
      });
  }

  private initAllUsersAnswers() {
    this.presenterSubscriptionsOff();
    this.allUsersTaskAnswersSubscription = this.dataService.loadAllUsersTaskAnswers(this.documentPathParams)
      .pipe(this.takeUntilAlive())
      .subscribe((object) => {
        this.buildData(object);
      });
  }

  uploadImage() {
    this.uploadService.openUploadWindow(this.uppy);
  }

  private addImageToDataSource(list) {
    for (const item of list) {
      const id = `${item.id.replace(/\//g, '-')}.${item.extension}`;
      if (!this.userTaskDocument.files.find(it => it.id.includes(id))) {
        const obj: ITaskFile = {
          id: `${ITEM_MARKER.NEW}${id}`,
          src: item.response,
          name: item.name,
          metaType: item.meta.type
        };
        this.userTaskDocument.files.push(obj);
      }
    }
  }

  deleteUploadFile(item) {
    const index = this.userTaskDocument.files.findIndex(it => it.id === item.id);
    if (index > -1) {
      if (this.userTaskDocument.files[index].id.startsWith(ITEM_MARKER.NEW)) {
        this.userTaskDocument.files.splice(index, 1);
      } else {
        this.userTaskDocument.files[index].id = `${ITEM_MARKER.DELETED}${this.userTaskDocument.files[index].id}`;
      }
    }
  }


  start() {
    this.dataService.common.confirm(this.dataService.common.i18n('confirm_dialog.start.title'),
      this.dataService.common.i18n('confirm_dialog.start.body'))
      .pipe(take(1))
      .subscribe(res => {
      if (res) {
        const user = this.loginService.getAppUser();
        const userTaskObject = new UserTaskObject({
          userId: user.userId,
          email: user.email,
          fullName: user.fullName,
          picture: user.picture,
          department: user.department,
          userStatus: USER_TASK_STATUS.IN_PROGRESS
        });
        this.dataService.common.showProgress.next(true);
        this.dataService.userSubmitTask(this.documentPathParams, userTaskObject).then(() => {
          this.dataService.common.showProgress.next(false);
        }).catch((err) => {
          this.dataService.common.showProgress.next(false);
          this.dataService.common.log.error(err);
        });
      }
    });
  }

  submit() {
    this.dataService.common.confirm(this.dataService.common.i18n('confirm_dialog.submit.title'),
      this.dataService.common.i18n('confirm_dialog.submit.body')).pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        this.userTaskDocument.userStatus = USER_TASK_STATUS.DONE;
        this.dataService.common.showProgress.next(true);
        this.dataService.userSubmitTask(this.documentPathParams, this.userTaskDocument).then(() => {
          this.dataService.common.showProgress.next(false);
        }).catch((err) => {
          this.dataService.common.showProgress.next(false);
          this.dataService.common.log.error(err);
        });
      }
    });
  }

  onStatusFilter(status) {
    this.filter.statusFilter = status;
    this.dataSource.filter = JSON.stringify(this.filter);
  }

  searchFilter(filterValue: string) {
    this.filter.userName = filterValue;
    this.dataSource.filter = JSON.stringify(this.filter);
  }

  moveToStatus(status: USER_TASK_STATUS) {
    this.dataService.common.confirm(this.dataService.common.i18n('confirm_dialog.change.status.title'),
      this.dataService.common.i18n('confirm_dialog.change.status.body', {status: this.dataService.common.i18n(status)})).pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        this.dataService.common.showProgress.next(true);
        const allPromise = [];
        for (const row of this.dataSource.data.filter(r => r.checkbox)) {
          allPromise.push(this.dataService.userSubmitTask(this.documentPathParams, {userId: row.userId, userStatus: status}));
        }
        Promise.all(allPromise).then(() => {
          this.onCheckAll({target: {checked: false}});
          this.dataService.common.showProgress.next(false);
        }).catch((err) => {
          this.dataService.common.log.error(err);
        });
      }
    });
  }

  onCheckAll(event) {
    Object.keys(this.dataObject).forEach(id => this.dataObject[id].checkbox = event.target.checked);
    this.resultSet.next(Object.values(this.dataObject));
  }

  isAllChecked() {
    return !isEmpty(this.resultSet.getValue()) ? this.resultSet.getValue().every(o => o.checkbox) : false;
  }

  isSomeChecked() {
    return !isEmpty(this.resultSet.getValue()) ? this.resultSet.getValue().some(o => o.checkbox) : false;
  }

  onRowCheckboxChange(row: IResultUserRow) {
    this.dataObject[row.userId].checkbox = !this.dataObject[row.userId].checkbox;
    this.resultSet.next(Object.values(this.dataObject));
  }

  filterData(data: IResultUserRow, filter: string) {
    const obj = JSON.parse(filter);
    const statusFilter = () => {
      if (obj.statusFilter === Constants.ALL_STATUS) {return true; }
      if (obj.statusFilter === USER_TASK_STATUS.IN_PROGRESS &&
        data.userStatus === USER_TASK_STATUS.IN_PROGRESS) {return true; }
      if (obj.statusFilter === USER_TASK_STATUS.DONE &&
        data.userStatus === USER_TASK_STATUS.DONE) {return true; }
      if (obj.statusFilter === USER_TASK_STATUS.FEEDBACK &&
        data.userStatus === USER_TASK_STATUS.FEEDBACK) {return true; }
      if (obj.statusFilter === USER_TASK_STATUS.APPROVED &&
        data.userStatus === USER_TASK_STATUS.APPROVED) {return true; }
      return false;
    };

    const searchFilter = () => {
      if (!obj.userName) {return true; }
      if (data.fullName.toLocaleLowerCase().includes(obj.userName.trim().toLowerCase())) {return true; }
      return false;
    };

    return statusFilter() && searchFilter();
  }

  showTextAnswer(row: IResultUserRow, type: 'text' | 'file' ) {
    let dialogData;
    dialogData = type === 'text' ? {text: row.textAnswer, } : {files: row.files};
    const dialogRef = this.dialog.open(TextViewDialogComponent, {
      width: '350px',
      height: type === 'text' ? '450px' : 'auto'
      , disableClose: true
      , data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  onEditFeedbackClick(row: IResultUserRow) {
    const dialogData = {title: this.dataService.common.utils.i18n('users.task.add.feedback')
      , fields: [
        {id: 'value', type: 'text', floatLabel: 'never'},
      ]
      , result: {value: row.feedback}
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      maxHeight: '270px'
      , data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataService.common.showProgress.next(true);
        this.dataService.userSubmitTask(this.documentPathParams, {
          userId: row.userId,
          feedback: result.value,
          userStatus: USER_TASK_STATUS.FEEDBACK,
          speaker: this.getSpeakerObject()
        }).then(() => {
          this.dataService.common.showProgress.next(false);
        }).catch((err) => {
          this.dataService.common.log.error(err);
          this.dataService.common.showProgress.next(false);
        });
      }
    });
  }

  onEditPointClick(row: IResultUserRow) {
    const dialogData = {title: this.dataService.common.utils.i18n('users.task.set.points') +
        (this.taskDocument.maximalPoints ? ('. ' +
          this.dataService.common.utils.i18n('maximal.points') + ' ' + this.taskDocument.maximalPoints) : '')
      , fields: [
        {id: 'value', type: 'text', floatLabel: 'never'},
      ]
      , result: {value: row.points}
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      maxHeight: '270px'
      , data: dialogData
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        if (this.taskDocument.maximalPoints && result.value > this.taskDocument.maximalPoints) {
          this.dataService.common.showPopupWarning(this.dataService.common.utils.i18n('users.task.set.points.warning'));
          return;
        }
        this.dataService.common.showProgress.next(true);
        this.dataService.userSubmitTask(this.documentPathParams, {
          userId: row.userId,
          points: result.value,
          speaker: this.getSpeakerObject()
        }).then(() => {
          this.dataService.common.showProgress.next(false);
        }).catch((err) => {
          this.dataService.common.log.error(err);
          this.dataService.common.showProgress.next(false);
        });
      }
    });
  }

  onEditGradeClick(row: IResultUserRow) {
    const dialogData = {title: this.dataService.common.utils.i18n('users.task.set.grade')
      , fields: [
        {id: 'value', type: 'text', floatLabel: 'never'},
      ]
      , result: {value: row.grade}
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '350px',
      maxHeight: '270px'
      , data: dialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataService.common.showProgress.next(true);
        this.dataService.userSubmitTask(this.documentPathParams, {
          userId: row.userId,
          grade: result.value,
          speaker: this.getSpeakerObject()
        }).then(() => {
          this.dataService.common.showProgress.next(false);
        }).catch((err) => {
          this.dataService.common.log.error(err);
          this.dataService.common.showProgress.next(false);
        });
      }
    });
  }

  private getSpeakerObject(): ITaskSpeaker {
    const cUser = this.loginService.getAppUser();
    return {
      department: cUser.department ?? null,
      fullName: cUser.userName ?? null,
      email: cUser.email,
      picture: cUser.picture ?? null,
      userId: cUser.userId
    };
  }

  private buildData(object) {
    Object.keys(this.dataObject).forEach(id => this.dataObject[id].draft = true);
    for (const userId of Object.keys(!isEmpty(object) ? object : {})) {
      const uto = new UserTaskObject(object[userId]);
      this.dataObject[userId] = {
        checkbox: this.dataObject[userId] ? this.dataObject[userId].checkbox : false,
        userId: userId,
        picture: uto.picture,
        email: uto.email,
        fullName: uto.fullName,
        userStatus: uto.userStatus,
        draft: false,
        textAnswer: uto.textAnswer,
        filesAnswer: uto.getFilesNameList(),
        files: uto.files,
        feedback_edit: 'insert_comment',
        feedback: uto.feedback,
        points: uto.points,
        points_edit: 'insert_comment',
        grade: uto.grade,
        grade_edit: 'insert_comment',
      };
    }
    Object.keys(this.dataObject).forEach(id => this.dataObject[id].draft ? (delete this.dataObject[id]) : null);
    this.resultSet.next(Object.values(this.dataObject));
  }

  onShowUserResults(value) {
    this.showUserResults = value;
  }
}
