import { Component, computed, ElementRef, Injector } from '@angular/core';
import { isEmpty, cloneDeep } from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';
import { AbstractQuizQuestionResultsComponent } from '../../shared/results/abstract-quiz-question-results-component';
import { TimeLineService } from '../../../../../../../../../src/app/services/time-line.service';
import { AppUser } from '../../../../../../../../../src/app/model/AppUser';
import { AnswerQuestion } from '../../../../../../../../../src/app/model/EventQuestion';
import { TCaption } from '../../../../../../../../../src/app/core/constants';
import { SequenceUtils } from '../sequence-utils';
import { QuizService } from '../../../quiz.service';
import {QUESTION_VIEW_MODE} from '../../shared/quiz-quiestion-types';




export interface IAnswerSequence {
  id?: string;
  answerText: TCaption;
  currPosition: number;
  correctPosition: number;
  correctAnswer?: boolean;
  wrongAnswer?: boolean;
}

export interface IAnswerSequenceCounter {
  answerSequence: IAnswerSequence;
  countWrongAnswer: number;
  countCorrectAnswer: number;
}

@Component({
  selector: 'app-question-sequence-results',
  templateUrl: './question-sequence-results.component.html',
  styleUrl: './question-sequence-results.component.scss'
})
export class QuestionSequenceResultsComponent extends AbstractQuizQuestionResultsComponent {

  public QUIZ_VIEW_MODE = QUESTION_VIEW_MODE;

  correctAnswerSequence: AnswerQuestion[];
  answersSequenceCounter: IAnswerSequenceCounter[] = [];

  totalAnswerResult: number;
  currentUser: AppUser;

  private dialogRef: MatDialogRef<ResultDialogComponent> | null = null;




  constructor(protected injector: Injector,
    protected elementRef: ElementRef,
    private dialog: MatDialog,
    private timelineService: TimeLineService,
    private quizService: QuizService) {
    super(injector, elementRef);

    this.currentUser = this.timelineService.currentUser;
  }


  protected initQuestionAnswersDataSource() {
    if (this.question  && !this.correctAnswerSequence?.length) {
      this.correctAnswerSequence = cloneDeep(this.question.items);

      this.correctAnswerSequence.forEach((answer, idx) => {
        const answerSequence = SequenceUtils.answerQuestionToIAnswerSequence(this.correctAnswerSequence, answer, idx);

        this.answersSequenceCounter.push({
          answerSequence: answerSequence,
          countWrongAnswer: 0,
          countCorrectAnswer: 0
        });
      });
    }
  }


  protected onReceiveQuestionAnswers() {

    this.summaryQuestionAnswers$.pipe(this.takeUntilAlive())
      .subscribe((value: any[]) => {
        if (!isEmpty(value)) {

          if (!this.correctAnswerSequence) {
            this.initQuestionAnswersDataSource();
          }

          const answerCount: number[] =  Object.values(value[0]);
          this.totalAnswerResult = answerCount.reduce((prev, curr) => prev + curr, 0);

          this.correctAnswerSequence?.forEach((val, idx) => {
            this.answersSequenceCounter[idx].countCorrectAnswer  = value[idx][val.id] || 0;
            this.answersSequenceCounter[idx].countWrongAnswer = this.totalAnswerResult - this.answersSequenceCounter[idx].countCorrectAnswer;
          });
        }
      });
  }


  openDialog(event: MouseEvent, rowData: any): void {

    const targetElement = event.target as HTMLElement;

    // Find the wrong and correct answer elements
    const wrongAnswerElem = this.findSiblingWithClass(targetElement, 'column-wrong');
    const correctAnswerElem = this.findSiblingWithClass(targetElement, 'column-right');
    const rectForColumnRight = correctAnswerElem.getBoundingClientRect();

    // Apply the active border class if elements are found
    wrongAnswerElem?.classList.add('column-wrong-border-active');
    correctAnswerElem?.classList.add('column-right-border-active');

    // Close the previous dialog if it's open
    if (this.dialogRef) {
      this.dialogRef.close();
    }

    const DIALOG_WIDTH_PLUS_MARGIN = 312; // dialog + margin
    // Only open dialog if none is open
    if (!this.dialogRef) {
      rowData.totalAnswerResult = this.totalAnswerResult;
      this.dialogRef = this.dialog.open(ResultDialogComponent, {
        data: { rowData, viewMode: this.question.viewMode },
        position: {
          top: `${rectForColumnRight.bottom + 5}px`,
          left: `${rectForColumnRight.right - DIALOG_WIDTH_PLUS_MARGIN}px`,
        },
        // panelClass: 'hover-dialog',
        hasBackdrop: false // Disable backdrop to make it behave like a tooltip
      });

      // Reset dialogRef after it closes
      this.dialogRef.afterClosed().subscribe(() => {
        this.closeDialog();
        wrongAnswerElem?.classList.remove('column-wrong-border-active');
        correctAnswerElem?.classList.remove('column-right-border-active');
      });
    }
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }

  // Helper function to find the element with the class or its sibling
  private findSiblingWithClass(element: HTMLElement, className: string): HTMLElement | null {
    return element.classList.contains(className)
      ? element
      : element.previousElementSibling?.classList.contains(className)
        ? element.previousElementSibling as HTMLElement
        : element.nextElementSibling?.classList.contains(className)
          ? element.nextElementSibling as HTMLElement
          : null;
  }

}
