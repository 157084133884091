import {isEmpty} from 'lodash';
import {ANNOTATION_TYPE, MEDIA_LOOP} from '../../../../../core/constants';
import {IContentComponent} from '../../../shared/container-interface';

export interface IMediaOptions {
  speed: number;
  startTime: number;
  endTime: number;
  loop: MEDIA_LOOP;
}

export interface IText {
  [language: string]: string;
}

export type TText = IText | string;

export interface ITextNodeFile {
  id: string;
  trackId: string; // never change after create, need for set html element id in text
  src: string;
  title: string;
  mimeType: string; // fill if is possible
  lang?: string; // not need if not multilingual mode
}

export interface IAnnotationValue {
  annotation_index: any;
  name?: string;
  description?: string;
  annotation_type?: ANNOTATION_TYPE;
  options?: any;
}

export interface IContainerAnnotation {
  containerId: string;
  title?: string;
  values?: IAnnotationValue[];
}

export interface IContainerAnnotationsMap {
  [externalType: string]: IContainerAnnotation[];
}

export type TAnnotationType = Pick<IContentComponent, 'title' | 'svgIcon' | 'iconClass'>;

export interface IAnnotationType extends TAnnotationType {
  type: string;
}

export interface IExtAnnotationValue extends IAnnotationValue {
  containerId: string;
  type: string;
  orderNumber: string;
}

export type TExtAnnotation = IExtAnnotationValue | IContainerAnnotation;


export enum FOLLOW_ME_HOTSPOT_STATUS {
  PLAY = 'play',
  STOP = 'stop',
  GOTO = 'goto'
}

export interface IFollowMeData {
  elementId: string;
  status: FOLLOW_ME_HOTSPOT_STATUS;
}

export class TextNode {
  text: TText;
  files: [];

  public static getText(value): TText {
    if (isEmpty(value) || typeof value === 'string') {
      return value;
    } else if (typeof value === 'object') {
      return value.hasOwnProperty('text') ? value.text : value;
    }
  }
}
