import {createSelector} from 'reselect';
import {ActionReducer, ActionReducerMap} from '@ngrx/store';
import {LoggerOptions, storeLogger} from 'ngrx-store-logger';

import {environment} from '../../environments/environment';

import * as dataModel from '../model/data';
import {defaults} from '../model/data';
import * as uiModel from '../model/ui';
import * as fromData from './data';
import * as fromUi from './ui';
import {localStorageSync} from 'ngrx-store-localstorage';
import {ActionTypes} from '../actions/data';
import {cloneDeep} from 'lodash';

export interface State {
  data: dataModel.Data;
  ui: uiModel.UI;
}

export const reducers: ActionReducerMap<State> = {
  data: fromData.reducer
  , ui: fromUi.reducer
};

export function clearEventMode(reducer: ActionReducer<State>): any {
  return function (state, action) {
    if (action.type === ActionTypes.CLEAR_EVENT_MODE) {
      const defaultState = cloneDeep(defaults);
      state.data.usersOnline = defaultState.usersOnline;
      state.data.currentContent = defaultState.currentContent;
      state.data.usersOnlineShort = defaultState.usersOnlineShort;
      return reducer(state, action);
    }
    return reducer(state, action);
  };
}

export function logger(reducer: ActionReducer<State>): any {
  const loggerOptions: LoggerOptions = {
    collapsed: true
  };
  return storeLogger(loggerOptions)(reducer);
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: [], rehydrate: true})(reducer);
}

const developmentMetaReducer = [localStorageSyncReducer, clearEventMode, logger];
const productionMetaReducer = [localStorageSyncReducer, clearEventMode];

export const metaReducers = environment.production ? productionMetaReducer : developmentMetaReducer;

/* Data */
export const getDataState = (state: State) => state.data;
export const getCurrentUser = createSelector(getDataState, fromData.getCurrentUser);
export const getUsersOnline = createSelector(getDataState, fromData.getUsersOnline);
export const getCurrentContent = createSelector(getDataState, fromData.getCurrentContent);
export const getNotifyEventMessage = createSelector(getDataState, fromData.getNotifyEventMessage);
export const getUsersOnlineShort = createSelector(getDataState, fromData.getUsersOnlineShort);

/* UI */
export const getUIState = (state: State) => state.ui;
export const getMenuOpen = createSelector(getUIState, fromUi.getMenuOpen);
export const getCurrentPage = createSelector(getUIState, fromUi.getCurrentPage);
export const getLoading = createSelector(getUIState, fromUi.getLoading);
export const getPresentationMode = createSelector(getUIState, fromUi.getPresentationMode);
export const getPresentationModeKeyAction = createSelector(getUIState, fromUi.getPresentationModeKeyAction);
export const getModalDialogOpen = createSelector(getUIState, fromUi.getModalDialogOpen);
export const getManageTimeOpen = createSelector(getUIState, fromUi.getManageTimeOpen);
export const getEventSettingsOpenPage = createSelector(getUIState, fromUi.getEventSettingsOpenPage);
export const getUserInputAnswerOnTextQuestion = createSelector(getUIState, fromUi.getUserInputAnswerOnTextQuestion);
export const getMeetingViewMode = createSelector(getUIState, fromUi.getMeetingViewMode);
export const getFullScreenMode = createSelector(getUIState, fromUi.getFullScreenMode);
export const getEditorMode = createSelector(getUIState, fromUi.getEditorMode);
