import {Injectable} from '@angular/core';
import {AbstractContent, CONTENT_PATH_TYPE} from '../model/content/AbstractContent';
import {EventsDataService} from './events-data.service';
import {
  ANONYMOUS_OPTION,
  Constants,
  COPY_TYPE,
  DIRECTION_COPY_CONTENT,
  EMULATE_RESULT,
  FIXED_SECTION_TYPE,
  FIXED_SECTION_TYPE_ORDER_INDEX,
  ILanguageParams,
  LINK_STATE,
  SECTION_EVENT_PHASE,
  SELF_LEARNING_MODE,
  VIRTUAL_CONFERENCE_EVENT,
  VIRTUAL_CONFERENCE_TYPE
} from '../core/constants';
import {clone, cloneDeep, isEmpty, merge, union} from 'lodash';
import {CommonService} from '../core/common.service';
import {BreakContent} from '../model/content/BreakContent';
import {TextContent} from '../model/content/TextContent';
import {LinkContent} from '../model/content/LinkContent';
import {UtilsService} from '../core/utils.service';
import {ClipboardContent} from '../model/content/ClipboardContent';
import {SlideContent} from '../model/content/SlideContent';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {
  AnswerQuestion,
  AnswerQuestionMatching,
  EventQuestion,
  TableRowAnswerQuestion,
  TableRowAnswerQuestionMatching
} from '../model/EventQuestion';
import {IQuestions, QuestionnaireContent} from '../model/content/QuestionnaireContent';
import {SectionContent, SectionUser} from '../model/content/SectionContent';
import {ToasterService} from '../core/toaster.service';
import {PlaneContent} from '../model/content/PlaneContent';
import {RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {TimeLineService} from './time-line.service';
import {BehaviorSubject, debounceTime, firstValueFrom, Subject, take, takeUntil} from 'rxjs';
import {ModularContent, Module} from '../model/content/ModularContent';
import {InstantSettings} from '../model/event-mode/InstantSettings';
import {EditSectionSettingDialogComponent} from '../modules/edit-section-setting-dialog/edit-section-setting-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AppUser} from '../model/AppUser';
import {CLIPBOARD_HISTORY_LOCATION, ClipboardService} from '../core/clipboard.service';
import {RowTask, TimePoint} from '../model/event-mode/RowDatesObject';
import {TaskDocument} from '../model/content/TaskDocument';
import {
  IMember,
  InstantGroupSettingsDialogComponent
} from '../modules/instant-group-settings-dialog/instant-group-settings-dialog.component';
import {MeetingApiService} from './meeting-api.service';
import {TimeLineEmulator} from '../time-line-emulator/time-line-emulator';
import {ManageTimeService} from './manage-time.service';
import {LoginService} from '../login/login.service';
import {Event} from '../model/Event';
import {IcsTemplateApiService} from './ics-template-api.service';
import {MailTemplateApiService} from './mail-template-api.service';
import {DailyDataService} from './daily-data.service';
import {EducationService} from './education.service';
import {DailyCoService} from '../modules/meeting-frame/services/daily-co.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CONTAINER_ITEM_TYPE, ContentContainer, ContentContainerItem} from '../model/content/ContentContainer';
import {ContentContainerService} from '../modules/content-container/service/content-container.service';
import {MeetingService} from './meeting.service';
import {QUESTION_TYPE} from '../modules/content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';
import {Quiz} from '../modules/content-container/components/quiz/quiz-model/quiz';
import {DRMService} from '../core/drm.service';
import {TranslateApiService} from './translate-api.service';
import {LANGUAGE} from '../core/language-constants';
import {EventModeApiService} from './event-mode-api.service';
import {CHANGE_LOG_VALUE_TYPE} from './std-api-service';

export interface SectionEventPhaseChanger {
  change: boolean;
  phase: SECTION_EVENT_PHASE | null;
}

export interface ICopySectionResult {
   newId: string;
   oldId: string;
   parentId: string;
}

export interface IAbstractContent extends AbstractContent {
  isRoot?: boolean;
  sectionEventPhase?: SECTION_EVENT_PHASE;
  education?: boolean;
  items?: SectionTimeline[];
}

export interface Question {
  question: string;
  options: string[];
  correctAnswer: string;
}

@Injectable()
export class ContentService {

  private _addNewContentSubject = new Subject<AbstractContent>();

  constructor(public dataService: EventsDataService
            , private eventModeApi: EventModeApiService
            , private common: CommonService
            , private toasterService: ToasterService
            , public timeLineService: TimeLineService
            , private manageTimeService: ManageTimeService
            , private loginService: LoginService
            , private meetingApiService: MeetingApiService
            , private _clipboard: ClipboardService
            , private icsTemplateApiService: IcsTemplateApiService
            , private mailTemplateApiService: MailTemplateApiService
            , private utils: UtilsService
            , private educationService: EducationService
            , private dailyDataService: DailyDataService
            , private dailyCoService: DailyCoService
            , private router: Router
            , private activatedRoute: ActivatedRoute
            , public drmService: DRMService
            , public contentContainerService: ContentContainerService
            , public meetingService: MeetingService
            , public translateApiService: TranslateApiService
            , public timelineService: TimeLineService
) { }

  static getIconClassByType(name: string) {
    switch (name) {
      case Constants.CONTENT_SUBTYPE_QUESTION: return Constants.CONTENT_SUBTYPE_CLASS_QUESTION_ICON;
      case Constants.CONTENT_SUBTYPE_SUMMARISE: return Constants.CONTENT_SUBTYPE_CLASS_SUMMARISE_ICON;
      case Constants.CONTENT_SUBTYPE_TASK: return Constants.CONTENT_SUBTYPE_CLASS_TASK_ICON;
      case Constants.CONTENT_SUBTYPE_BREAK: return Constants.CONTENT_SUBTYPE_CLASS_BREAK_ICON;
      case Constants.CONTENT_SUBTYPE_QUESTIONNAIRE: return Constants.CONTENT_SUBTYPE_CLASS_QUESTIONNAIRE_ICON;
      default: return '';
    }
  }

/*
  private get clearTimeObjectAsObjects() {
    return {plannedTime: {}, duration: {}, endTime: {},
      plannedTimeFixed: false, durationFixed: false, endTimeFixed: false, sectionTimeIsNotActive: false,
      plannedTimeFixedValue: {}, durationFixedValue: {}, endTimeFixedValue: {}};
  }
*/

  get addNewContentSubject(): Subject<AbstractContent> {
    return this._addNewContentSubject;
  }

  public get clearTimeObjectAsNull() {
    return {plannedTime: null, duration: null, endTime: null,
      plannedTimeFixed: false, durationFixed: false, endTimeFixed: false,
      plannedTimeFixedValue: null, durationFixedValue: null, endTimeFixedValue: null};
  }

  private getResetSectionTimeRowTask(sectionId, task: RowTask[]) {
    task.push({sectionId: sectionId, fieldName: 'plannedTime', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'duration', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'endTime', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'plannedTimeFixed', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'durationFixed', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'endTimeFixed', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'plannedTimeFixedValue', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'durationFixedValue', value: null, type: 'section'});
    task.push({sectionId: sectionId, fieldName: 'endTimeFixedValue', value: null, type: 'section'});
  }

  get clipboard(): ClipboardService {
    return this._clipboard;
  }

  showPopMessageSuccess() {
    this.toasterService.pop('success',
      null,
      this.utils.i18n('common.dialog.success'));
  }

  showPopMessageError(errMsg) {
    this.toasterService.pop('error',
      null, errMsg);
  }

  throwError(error) {
    this.common.log.error(error);
    throw error;
  }

  async addContentContainer(event, parentSection: SectionContent, questionnaireData: any, isSelfLearningSection: boolean) {
    const cc = new ContentContainer({});
    cc.description = '';
    cc.title = this.common.i18n('timeline.section.title.knowledgecheck');
    cc.isPublic = true;
    cc.singleMode = true;
    cc.draft = false;
    cc.parentId = !parentSection.isAttached() ? parentSection.id : parentSection.originId;
    cc.eventId = event.eventId;
    cc.orderIndex = event.orderIndex;
    cc.isPresenterContent = true;
    if (isSelfLearningSection) {
      cc.selfLearningMode = SELF_LEARNING_MODE.ONE_TIME_VIEW;
    }

    const ccItem = new ContentContainerItem();
    ccItem.type = CONTAINER_ITEM_TYPE.QUIZ;
    ccItem.setRect(0, 576, 0, 1024, 1, 12);

    const quiz = new Quiz();
    quiz.anyUserCanShowResult = false;
    quiz.showResult = false;

    const questions: Question[] = questionnaireData.questions;
    questions.forEach((question: Question, index: number) => {
      const eventQuestion = this.createQuestion(index, question.question, question.options, parseInt(question.correctAnswer));
      quiz.questions[eventQuestion.id] = eventQuestion;
    });

    const multilingual = this.timeLineService.event.getMultilingual();
    if (multilingual.multilingual) {
      ccItem.data = await this.translate(quiz, multilingual.usedLanguages);
      cc.items.push(ccItem);
      cc.itemsTypes.push(CONTAINER_ITEM_TYPE.QUIZ);
      this.addContent(cc).then((cId) => {
        this.router.navigate([], {queryParams: {cid: cId}, relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
        return Promise.resolve();
      });
    } else {
      ccItem.data = quiz;
      cc.items.push(ccItem);
      cc.itemsTypes.push(CONTAINER_ITEM_TYPE.QUIZ);

      this.addContent(cc).then((cId) => {
        this.router.navigate([], {queryParams: {cid: cId}, relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
        return Promise.resolve();
      });
    }
  }

  async translate(quiz: Quiz, currentLangs: LANGUAGE[]): Promise<Quiz> {
    const languageParams = this.timelineService.getEventLanguageParams();
    const defLParams: ILanguageParams = {
      defaultLanguage: languageParams.defaultLanguage,
      currentLanguage: languageParams.defaultLanguage,
      usedMultilingualContent: true,
      usedLanguages: []
    };
    const defContent: Quiz = cloneDeep(quiz);
    for (const currentLanguage of currentLangs) {
      if (languageParams.defaultLanguage === currentLanguage) {
        continue;
      }
      const currLParams: ILanguageParams = {
        defaultLanguage: languageParams.defaultLanguage,
        currentLanguage: currentLanguage,
        usedMultilingualContent: true,
        usedLanguages: []
      };
      for (const question of Object.values(defContent.questions || {})) {
        let caption = question.getCaptionByLanguage(defLParams);
        caption = await this.translateApiService.translateSimpleString(caption, currLParams.defaultLanguage, currLParams.currentLanguage);
        question.setCaptionByLanguage(caption, currLParams);

        const answerData: TableRowAnswerQuestion[] = [];
        (question.items || []).forEach(item => answerData.push(new TableRowAnswerQuestion(item, defLParams)));
        for (const row of answerData) {
          let text = row.rowCaption;
          text = await this.translateApiService.translateSimpleString(text, currLParams.defaultLanguage, currLParams.currentLanguage);
          row.languageParams = currLParams;
          row.rowCaption = text;
        }
        question.items = [];
        for (const row of answerData) {
          const answer = new AnswerQuestion(row);
          question.items.push(answer);
        }

        const matchingData: TableRowAnswerQuestionMatching[] = [];
        (question.matchingItems || []).forEach(item => matchingData.push(new TableRowAnswerQuestionMatching(item, defLParams)));
        for (const row of matchingData) {
          let text = row.rowCaption;
          text = await this.translateApiService.translateSimpleString(text, currLParams.defaultLanguage, currLParams.currentLanguage);
          row.languageParams = currLParams;
          row.rowCaption = text;
        }
        question.matchingItems = [];
        for (const row of matchingData) {
          const matching = new AnswerQuestionMatching(row);
          question.matchingItems.push(matching);
        }
      }
    }
    return Promise.resolve(defContent);
  }

  createQuestion(orderIndex: number, questionStr: string, ans: string[], correctAnswerIdx: number,
                 directFeedback: boolean = false): EventQuestion {
    const question = new EventQuestion();
    question.orderIndex = (orderIndex * 100);
    question.useCorrectAnswers = true;
    question.storypoint = QUESTION_TYPE.CHOICE;
    question.caption = questionStr;
    question.directFeedback = directFeedback;

    const createAnswer = (answer: string, orderIndex: number, isCorrect: boolean) => {
      const answerOption = new AnswerQuestion();
      answerOption.answer = answer;
      answerOption.orderIndex = orderIndex;
      answerOption.correctAnswer = isCorrect;
      return answerOption;
    };

    let index = 100;
    for (let i = 0; i < ans.length; i++) {
      const answerOption = createAnswer(ans[i], index, i === correctAnswerIdx);
      question.items.push(answerOption);
      index += 100;
    }
    return question;
  }

  addContent(object: AbstractContent, eventId?, type?, subtype?) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      let c_type = object ? object['type'] : null;
      let c_subtype = object ? object['subtype'] : null;
      let c_eventId = object ? object['eventId'] : null;
      if (!c_type) {c_type = type; }
      if (!c_subtype) {c_subtype = subtype; }
      if (!c_eventId) {c_eventId = eventId; }
      const prepErr: boolean = !c_type || !c_eventId;
      if (!prepErr && (c_type === Constants.CONTENT_TYPE_CONTENT_CONTAINER)) {
        this.contentContainerService.add(object as ContentContainer)
          .then(r => resolve(r))
          .catch(e => {
            vm.common.log.error('Error addContentContainer: ', e);
            reject(e);
          });
      } else  if (!prepErr && (c_type === Constants.CONTENT_TYPE_SECTION)) {
        this.dataService.addSectionContent(c_eventId, new SectionContent(object)).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_TASK_DOCUMENT) {
        const objectItems = cloneDeep((object as TaskDocument).uploadFileLinks);
        (object as TaskDocument).uploadFileLinks = null;
        this.dataService.addTaskDocumentContent(c_eventId, object).then(function (c) {
          object.id = c;
          (object as TaskDocument).uploadFileLinks = objectItems;
          vm.editContent(object).then(function (r) {
            resolve(r);
          }).catch(function (e) {
            reject(e);
          });
        }).catch(function (e) {
          vm.common.log.error('Error addModularContent: ', e);
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_MODULAR) {
        const objectItems = cloneDeep((object as ModularContent).items);
        (object as ModularContent).items = null;
        this.dataService.addModularContent(c_eventId, object as ModularContent).then(function (c) {
          const id = c;
          (object as ModularContent).id = id;
          (object as ModularContent).items = objectItems;
          (object as ModularContent).items.forEach(item => (item as Module).parentId = id);
          vm.editContent(object).then(function (r) {
            resolve(r);
          }).catch(function (e) {
            reject(e);
          });
        }).catch(function (e) {
          vm.common.log.error('Error addModularContent: ', e);
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
        this.dataService.addQuestionnaireContent(c_eventId, object).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (!prepErr && (c_type === Constants.CONTENT_TYPE_BREAK || c_subtype === Constants.CONTENT_TYPE_BREAK ||
        c_subtype === Constants.CONTENT_SUBTYPE_TASK || c_subtype === Constants.CONTENT_SUBTYPE_QUESTION ||
        c_subtype === Constants.CONTENT_SUBTYPE_SUMMARISE)) {
        const break_object = cloneDeep(object);
        break_object['type'] = Constants.CONTENT_TYPE_BREAK;
        break_object['subtype'] = c_subtype;
        this.dataService.addBreakContent(c_eventId, break_object).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (prepErr) {
        reject(new Error('Content type or eventId is undefined'));
      } else {
        reject(new Error('Undefined object'));
      }
    });
  }

  editContent(object: {}, eventId?, timelineId?, type?, subtype?) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      let c_type = object ? object['type'] : undefined;
      let c_subtype = object ? object['subtype'] : undefined;
      let c_eventId = object ? object['eventId'] : undefined;
      let c_timelineId = object ? object['id'] : undefined;
      if (!c_type) {c_type = type; }
      if (!c_subtype) {c_subtype = subtype; }
      if (!c_eventId) {c_eventId = eventId; }
      if (!c_timelineId) {c_timelineId = timelineId; }
      const prepErr: boolean = !c_type || !c_eventId || !c_timelineId;
      this.deleteNotSavedFields(object);
      if (!prepErr && c_type === Constants.CONTENT_TYPE_TASK_DOCUMENT) {
        this.editTaskDocumentContent(c_eventId, c_timelineId, object as TaskDocument).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          vm.common.log.error('Error editModularContent: ', c_eventId, c_timelineId, e);
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_MODULAR) {
        this.editModularContent(c_eventId, c_timelineId, object as ModularContent).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          vm.common.log.error('Error editModularContent: ', c_eventId, c_timelineId, e);
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
        this.contentContainerService.update(object as ContentContainer)
          .then(r => resolve(r))
          .catch(e => reject(e));
      } else
      if (!prepErr && (c_type === Constants.CONTENT_TYPE_BREAK || c_subtype === Constants.CONTENT_TYPE_BREAK ||
        c_subtype === Constants.CONTENT_SUBTYPE_TASK || c_subtype === Constants.CONTENT_SUBTYPE_QUESTION ||
        c_subtype === Constants.CONTENT_SUBTYPE_SUMMARISE)) {
        const break_object = cloneDeep(object);
        break_object['type'] = Constants.CONTENT_TYPE_BREAK;
        break_object['subtype'] = c_subtype;
        this.dataService.editBreakContent(c_eventId, c_timelineId, object).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (prepErr) {
        reject(new Error('Content type or eventId, or contentId is undefined'));
      } else {
        reject(new Error('Undefined object'));
      }
    });
  }

  /**
   * Delete content.
   * @param object - content object or null if know other parameters
   * @param eventId
   * @param parentId
   * @param timelineId
   * @param type
   * @param subtype
   * @returns {Promise<any>}
   */
  deleteContent(object, eventId?, parentId?, timelineId?, type?, subtype?) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      let c_type = object ? object['type'] : undefined;
      let c_subtype = object ? object['subtype'] : undefined;
      let c_eventId = object ? object['eventId'] : undefined;
      let c_timelineId = object ? object['id'] : undefined;
      let c_parentId = object ? object['parentId'] : undefined;
      if (!c_type) {c_type = type; }
      if (!c_subtype) {c_subtype = subtype; }
      if (!c_eventId) {c_eventId = eventId; }
      if (!c_timelineId) {c_timelineId = timelineId; }
      if (!c_parentId) {c_parentId = parentId; }
      const prepErr: boolean = !c_type || !c_eventId || !c_timelineId ||
        (!c_parentId && c_type !== Constants.CONTENT_TYPE_TIMELINE && c_type !== Constants.CONTENT_TYPE_SECTION);
      if (!prepErr && (c_type === Constants.CONTENT_TYPE_TIMELINE || c_type === Constants.CONTENT_TYPE_SECTION)) {
        this.dataService.deleteSectionContent(c_eventId, c_timelineId, object?.title).then(function (c) {
          vm.deleteSectionRegistrationInformation(c_eventId, c_timelineId).then(function (rd) {
            if (c_timelineId === vm.timeLineService.featureLineContentId) {
              vm.dataService.setFeatureLine(c_eventId, null)
                .then(() => resolve(c))
                .catch(function (e) {
                reject(e);
              });
            } else {
              resolve(c);
            }
          }).catch(function (e) {
            reject(e);
          });
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
        this.dataService.deleteQuestionnaireContentExt(c_eventId, object).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_MODULAR) {
        this.deleteModularContent(c_eventId, c_parentId, c_timelineId, new ModularContent(object)).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
        this.contentContainerService.delete(object as ContentContainer)
          .then(r => resolve(r))
          .catch(e => reject(e));
      } else
      if (!prepErr && c_type === Constants.CONTENT_TYPE_TASK_DOCUMENT) {
        this.deleteTaskDocumentContent(c_eventId, c_parentId, c_timelineId, new TaskDocument(object)).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (!prepErr && (c_type === Constants.CONTENT_TYPE_BREAK || c_subtype === Constants.CONTENT_TYPE_BREAK ||
        c_subtype === Constants.CONTENT_SUBTYPE_TASK || c_subtype === Constants.CONTENT_SUBTYPE_QUESTION ||
        c_subtype === Constants.CONTENT_SUBTYPE_SUMMARISE)) {
        this.dataService.deleteBreakContent(c_eventId, c_parentId, c_timelineId).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      } else
      if (prepErr) {
        reject(new Error('Content type or event id, or content id, or parent id is undefined'));
      } else {
        reject(new Error('Undefined object'));
      }
    });
  }

  deleteNotSavedFields(object) {
    delete object['relevancy'];
  }

  /**
   * Apply object of changed fields on source content.
   * @param applyObject
   * @param content
   * @returns {any}
   */
  applyResultToContent(applyObject, content) {
    const result = cloneDeep(content);
    delete result['relevancy'];
    if (content.type === Constants.CONTENT_TYPE_MODULAR && (content as ModularContent).isItemTypeImage(0)) {
      (content as ModularContent).setItemBodyConverterVer(0, applyObject['image']);
      result['imageUrl'] = {};
    }
    if (content.type === Constants.CONTENT_TYPE_MODULAR && (content as ModularContent).isItemTypeText(0)) {
      (content as ModularContent).setItemBodyConverterVer(0, applyObject['moduleContent']);
    }
    if (content.type === Constants.CONTENT_TYPE_MODULAR) {
      result['draft'] = applyObject['draft'];
      result['title'] = applyObject['title'];
      result['description'] = applyObject['description'];
    }
    if (!applyObject || content.type === Constants.CONTENT_TYPE_MODULAR) {return result; }
    for (const field of Object.keys(applyObject)) {
      if (field !== 'image') {
        result[field] = applyObject[field];
      } else
      if (field === 'image') {
        result['imageUrl'] = applyObject[field];
      }
    }
    return result;
  }

  /**
   * Possible fields from contentA that can be in fields contentB.
   * @param contentA
   * @param contentB
   * @returns {any}
   */
  applyPossibleContentsFieldsAtoB(contentA, contentB) {
    const typeA = contentA['type'];
    const typeB = contentB['type'];
    if (typeB === Constants.CONTENT_TYPE_TEXT || typeB === Constants.CONTENT_TYPE_LINK) {
      contentB['title'] = {};
    }
    if (typeB !== Constants.CONTENT_TYPE_BREAK) {
      contentB['subtype'] = {};
      if (typeB === Constants.CONTENT_TYPE_MODULAR) {
        contentB['description'] = {};
      }
    }
    if (typeB !== Constants.CONTENT_TYPE_TEXT && typeB !== Constants.CONTENT_TYPE_MODULAR) {
      contentB['content'] = {};
    }
    if (typeA === Constants.CONTENT_TYPE_TEXT && typeB === Constants.CONTENT_TYPE_MODULAR) {
      (contentB as ModularContent).setItemBodyConverterVer(0, contentA['content']);
      contentB['content'] = {};
    }
    if (typeA === Constants.CONTENT_TYPE_BREAK && typeB === Constants.CONTENT_TYPE_MODULAR) {
      (contentB as ModularContent).setItemBodyConverterVer(0, contentA['description']);
    }
    if (typeA === Constants.CONTENT_TYPE_CLIPBOARD && typeB === Constants.CONTENT_TYPE_MODULAR) {
      (contentB as ModularContent).setItemBodyConverterVer(0, contentA['imageUrl']);
    }
    if (typeA === Constants.CONTENT_TYPE_TEXT && typeB === Constants.CONTENT_TYPE_BREAK) {
      (contentB as BreakContent).description = (contentA as TextContent).content;
    } else
    if (typeA === Constants.CONTENT_TYPE_BREAK && typeB === Constants.CONTENT_TYPE_TEXT) {
      (contentB as TextContent).content = (contentA as BreakContent).description;
    } else
    if (typeA === Constants.CONTENT_TYPE_LINK && typeB === Constants.CONTENT_TYPE_TEXT) {
      (contentB as TextContent).content = (contentA as LinkContent).url;
      contentB['url'] = {};
    } else
    if (typeA === Constants.CONTENT_TYPE_LINK && typeB === Constants.CONTENT_TYPE_BREAK) {
      (contentB as BreakContent).description = (contentA as LinkContent).url;
      contentB['url'] = {};
    }
    return contentB;
  }

  /**
   * Prepare data for edit content dialog.
   * @param {AbstractContent} anyContent
   * @param {boolean} isPresenter
   * @param currentUser
   * @param convertCount
   * @param {string} dialogWidth - For TextContent this parameter is '80vw' and is set inside the method.
   *                               Other contents use value dialogWidth.
   */
  prepareEditDialogData(anyContent: AbstractContent, isPresenter: boolean, currentUser,
                        convertCount, dialogWidth = '526px'): {dialogData, dialogWidth, anyContent} {
    let editDialogWidth = dialogWidth;
    let value = '';
    let content = '';
    let draft = false;
    let dialogData = {};
    const draftField = this.utils.draftField;
    draftField.hidden = !isPresenter;

    if  (anyContent.type === Constants.CONTENT_TYPE_TEXT) {
      editDialogWidth = '80vw';
      draft = anyContent.draft;
      content = (anyContent as TextContent).content;
      dialogData = {title: this.utils.i18n('title.edit.content.text'), srcType: anyContent.type
        , convertCount: convertCount
        , fields: [{id: 'content', type: 'textarea', placeholder: this.utils.i18n('common.content'), required: true}
          , draftField]
        , result: {content: content, draft: draft, id: anyContent.id}};
    } else if  (anyContent.type === Constants.CONTENT_TYPE_LINK) {
      content = (anyContent as LinkContent).url;
      draft = anyContent.draft;
      dialogData = {title: this.utils.i18n('title.edit.content.link'), srcType: anyContent.type
        , convertCount: convertCount
        , fields: [{id: 'url', type: 'text', placeholder: this.utils.i18n('common.url'), required: true}
          , draftField]
        , result: {url: content, draft: draft, id: anyContent.id}};
    } else if  (anyContent.type === Constants.CONTENT_TYPE_CLIPBOARD) {
      value = (anyContent as ClipboardContent).title;
      content = (anyContent as ClipboardContent).imageUrl;
      draft = anyContent.draft;
      dialogData = {title: this.utils.i18n('title.edit.content.clipboard'), srcType: anyContent.type
        , convertCount: convertCount
        , fields: [{id: 'title', type: 'text', placeholder: this.utils.i18n('common.title'), required: false}
          , draftField]
        , image: {id: 'src', type: 'image', placeholder: this.utils.i18n('common.src'), required: true}
        , result: {title: value, image: content, draft: draft, id: anyContent.id}};
    } else if  (anyContent.type === Constants.CONTENT_TYPE_SLIDE) {
      content = (anyContent as SlideContent).slide;
      draft = anyContent.draft;
      dialogData = {title: this.utils.i18n('title.edit.content.slide'), srcType: anyContent.type
        , convertCount: convertCount
        , fields: [{id: 'slide', type: 'text', placeholder: this.utils.i18n('common.slide.link'), required: true}
          , draftField]
        , result: {slide: content, draft: draft, id: anyContent.id}};
    } else if  (anyContent.type === Constants.CONTENT_TYPE_TIMELINE) {
      value = (anyContent as SectionTimeline).title;
      content = (anyContent as SectionTimeline).location;
      dialogData = {
        title: this.utils.i18n('title.edit.content.section')
        , fields: [{id: 'title', type: 'textarea', placeholder: this.utils.i18n('common.title'), required: false},
          {id: 'location', type: 'text', placeholder: this.utils.i18n('common.location'), required: false}]
        , result: {title: value, location: content, draft: draft, id: anyContent.id}
      };
    } else if  (anyContent.type === Constants.CONTENT_TYPE_BREAK) {
      const description = anyContent['description'];
      const title = anyContent['title'];
      const showAnonymously = anyContent.userId === this.loginService.getAppUser().userId &&
        anyContent['subtype'] === Constants.CONTENT_SUBTYPE_QUESTION &&
          this.timeLineService.eventInstantSettings.easyContentSettings.anonymously === ANONYMOUS_OPTION.ANONYMOUS_ON_CHOICE;
      const anonymousField = showAnonymously ? [this.utils.anonymouslyField] : [];
      const anonymousResult = showAnonymously ? {anonymously: false} : {};
      draft = anyContent.draft;
      dialogData = {title: this.utils.i18n('action.edit_' + anyContent['subtype'] + '.content')
        , srcType: anyContent.type, srcSubtype: anyContent['subtype']
        , convertCount: convertCount
        , fields: union([this.utils.titleFieldNoRequired, this.utils.descriptionField, draftField], anonymousField),
        result: merge({title: title, description: description, draft: draft, id: anyContent.id, subtype: anyContent['subtype']},
          anonymousResult)
      };
    } else if  (anyContent.type === Constants.CONTENT_TYPE_MODULAR) {
      const description = anyContent['description'];
      const title = anyContent['title'];
      draft = anyContent.draft;
      const moduleContent = (anyContent as ModularContent).getItemBodyConverterVer(0);
      const isModuleBodyTypeImage = (anyContent as ModularContent).isItemTypeImage(0);
      dialogData = {title: this.utils.i18n('title.edit.content.modular')
        , srcType: anyContent.type
        , convertCount: convertCount
        , fields: isModuleBodyTypeImage ?
          [{id: 'title', type: 'text', placeholder: this.utils.i18n('common.title'), required: false},
            this.utils.subtitleFieldNoRequired, draftField] :
          [{id: 'title', type: 'text', placeholder: this.utils.i18n('common.title'), required: false},
            this.utils.subtitleFieldNoRequired,
          {id: 'moduleContent', type: 'textarea', placeholder: this.utils.i18n('common.content'), required: true},
          draftField],
        result: isModuleBodyTypeImage ?
          {title: title, description: description, draft: draft, id: anyContent.id,
            image: moduleContent} :
          {title: title, description: description, draft: draft, id: anyContent.id,
            moduleContent: moduleContent}
      };
      if (isModuleBodyTypeImage) {
        dialogData['image'] = {id: 'modularContent', type: 'image', placeholder: this.utils.i18n('common.src'), required: true};
      }
    }
    dialogData['parentSectionId'] = anyContent.parentId;
    dialogData['secondParentSectionId'] = anyContent.secondParentId;
    dialogData['isPresenter'] = isPresenter;
    dialogData['currentUser'] = currentUser;
    return {dialogData: dialogData, dialogWidth: editDialogWidth, anyContent: anyContent};
  }

  /**
   * Update content base on params from dialog result.
   * @param result - edit dialog result.
   * @param anyContent - content after convert srcContent into another type content.
   * @param srcContent - updated content.
   */
  updateContentFromDialogResult(result, anyContent, srcContent) {
    if (result.id === srcContent.id) {
      const updateDataObj = this.applyResultToContent(result, anyContent);
      return this.editContent(updateDataObj);
    } else {
      anyContent = this.applyResultToContent(result, anyContent);
      this.deleteContent(srcContent).then(() => this.addContent(anyContent, srcContent.eventId));
    }
  }

  /**
   * Create copy of any content.
   * @param abstractContent
   * @param newEventId
   * @param newParentId
   * @param newOrderIndex
   * @param sectionTypeEducation - change field SectionContent.education
   * @param copyPinLine
   */
  createCopyContent(abstractContent: IAbstractContent, newEventId, newParentId,
                    newOrderIndex, sectionTypeEducation: boolean, copyPinLine: boolean): Promise<string | ICopySectionResult> {
    const isTypeSection = (obj: AbstractContent) =>
      obj.type === Constants.CONTENT_TYPE_SECTION || obj.type === Constants.CONTENT_TYPE_TIMELINE;
    const direction = (id: string) => {
      if (this.clipboard.getContentLocation(id) === CLIPBOARD_HISTORY_LOCATION.CONFERENCE && this.loginService.educationMode) {
        return DIRECTION_COPY_CONTENT.TIMELINE_TO_MODULE;
      } else if (this.clipboard.getContentLocation(id) === CLIPBOARD_HISTORY_LOCATION.EDUCATION && !this.loginService.educationMode) {
        return DIRECTION_COPY_CONTENT.MODULE_TO_TIMELINE;
      } else {
        return DIRECTION_COPY_CONTENT.NO_CHANGE;
      }
    };

    const anyContent = cloneDeep(abstractContent);
    if (isTypeSection(anyContent)) {
      anyContent.education = sectionTypeEducation;
    } else {
      const newParent = this.timelineService.getSection(newParentId);
      if (newParent?.isAttached()) {
        newParentId = newParent.originId;
      }
    }

    return this.dataService.copyContent(anyContent, newEventId, newParentId, newOrderIndex,
                                        copyPinLine, direction(this.clipboard.content?.id))
      .then(newId => !isTypeSection(anyContent) ? newId :
        (new Object({newId: newId, oldId: anyContent.id, parentId: newParentId})) as ICopySectionResult)
      .then(async result => {
        await this.eventModeApi.saveChangeLogValue(newEventId, {
          sourceId: !isTypeSection(anyContent) ? result : result['newId'],
          sourceType: !isTypeSection(anyContent) ? anyContent['type'] : Constants.CONTENT_TYPE_SECTION,
          changeType: CHANGE_LOG_VALUE_TYPE.ADD,
          title: anyContent.title
        });
        return result;
      });
  }

  private prepareContent(anyContent: AbstractContent, newEventId, newParentId, newOrderIndex, isRegistrationQuestionnaire: boolean) {
    const urlMapFrom = {clipboardId: null, questions: [], templates: []};
    let content = cloneDeep(anyContent);
    delete content['id'];
    content['eventId'] = newEventId;
    content['parentId'] = newParentId;
    delete content['userId'];
    content['anonymously'] = false;
    content[Constants.ORDERINDEX] = newOrderIndex;
    if (content['type'] === Constants.CONTENT_TYPE_CLIPBOARD) {
      (content as ClipboardContent).imageUrl = null;
      urlMapFrom.clipboardId = anyContent.id;
    }
    if (content['type'] === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
      content = JSON.parse(JSON.stringify(content));
      this.prepareQuestionnaireContent(content as QuestionnaireContent,
        urlMapFrom.questions, urlMapFrom.templates, isRegistrationQuestionnaire);
    }
    if (content['type'] === Constants.CONTENT_TYPE_TIMELINE || content['type'] === Constants.CONTENT_TYPE_SECTION) {
      content = JSON.parse(JSON.stringify(new SectionContent(content)));
      if (anyContent.eventId !== newEventId) {
        content['users'] = null;
      }
      if ((content as SectionContent).virtualConferenceSettings?.dailyCoSettings?.permanentRooms) {
        const rooms = (content as SectionContent).virtualConferenceSettings?.dailyCoSettings?.permanentRooms;
        (rooms || []).forEach((r, index) => r.roomId = 'ROOM-' + (new Date().getTime() + ((index + 1) * 123)));
      }
    }
    return {content: content, urlMapFrom: urlMapFrom};
  }

  private prepareQuestionnaireContent(content: QuestionnaireContent, questions: any[], templates: any[],
                                      isRegistrationQuestionnaire: boolean) {
    const qKeyList = Object.keys(content.questions);
    const dependencyAnswersMap: {oldAnswerId: string, newAnswerId: string}[] = [];
    const dependencyQuestionMap: {oldQuestionId: string, newQuestionId: string}[] = [];
    let idInc = 0;
    qKeyList.forEach(qKey => {
      const question: EventQuestion = new EventQuestion(content.questions[qKey]);
      const oldId = question.id;
      idInc = idInc + 100;
      question.id = EventQuestion.genQuestionId(idInc);
      dependencyQuestionMap.push({oldQuestionId: oldId, newQuestionId: question.id});
      question.answers = null;
      question['lastchange'] = null;
      question.eventId = null;
      question.timelineId = null;
      if (isRegistrationQuestionnaire) {
        if (!isEmpty(question.dependency)) {
          question.dependency.id = question.id;
        }
        question.timelineId = content.parentId;
        const items = question['items'] as AnswerQuestion[];
        for (const it of (items || [])) {
          const oldItId = it.id;
          idInc = idInc + 100;
          it.id = AnswerQuestion.genAnswerId(idInc);
          dependencyAnswersMap.push({oldAnswerId: oldItId, newAnswerId: it.id});
        }
        delete content.questions[qKey];
        content.questions[question.id] = question;
      }
      if (!isRegistrationQuestionnaire) {
        if (this.utils.isFBUrl(question.files[0], content.eventId)) {
          question.files = [];
          questions.push(qKey);
        }
        if (question.options.wordCloudTemplate && question.files[0]) {
          templates.push(qKey);
        }
      }
    });
    if (isRegistrationQuestionnaire && !isEmpty(dependencyAnswersMap)) {
      for (const qKey of Object.keys(content.questions)) {
        const question = content.questions[qKey];
        if (!isEmpty(question.dependency) && !isEmpty(question.dependency.answerIdList)) {
          const qObj = dependencyQuestionMap.find(o => o.oldQuestionId === question.dependency.questionId);
          if (!isEmpty(qObj)) {
            question.dependency.questionId = qObj.newQuestionId;
            for (let i = 0; i < question.dependency.answerIdList.length; i++) {
              const oldAId = question.dependency.answerIdList[i];
              const obj = dependencyAnswersMap.find(o => o.oldAnswerId === oldAId);
              if (!isEmpty(obj)) {
                question.dependency.answerIdList[i] = obj.newAnswerId;
              }
            }
          }
        }
      }
    }
  }

  public storageObjectCopy(eventIdFrom, eventIdTo, pathFrom, pathTo, newContentId, type: COPY_TYPE , imageId, objectName?: string) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (type) {
        this.dataService.imageRewriteTo(eventIdFrom, eventIdTo, pathFrom, pathTo).then(function (r) {
          if (type === COPY_TYPE.CLIPBOARD) {
            vm.dataService.getClipboardDownloadURL(eventIdTo, newContentId).then(function (url) {
              resolve(url);
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          } else if (type === COPY_TYPE.QUESTION) {
            vm.dataService.getQuestionDownloadURL(eventIdTo, newContentId, imageId).then(function (url) {
              resolve({url: url, questionId: imageId});
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          } else if (type === COPY_TYPE.QUESTION_TEMPLATE) {
            vm.dataService.getQuestionTemplateDownloadURL(eventIdTo, newContentId, imageId).then(function (url) {
              resolve({url: url, questionId: imageId});
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          } else if (type === COPY_TYPE.IMAGE) {
            vm.dataService.getModuleImageDownloadURL(eventIdTo, newContentId, imageId).then(function (url) {
              resolve({url: url, imageId: imageId});
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          } else if (type === COPY_TYPE.PDF) {
            vm.dataService.getModulePDFDownloadURL(eventIdTo, newContentId, imageId).then(function (url) {
              resolve({url: url, imageId: imageId});
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          } else if (type === COPY_TYPE.ZIF) {
            vm.dataService.getModuleZIFDownloadURL(eventIdTo, newContentId, imageId).then(function (url) {
              resolve({url: url, imageId: imageId});
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          } else if (type === COPY_TYPE.TASK_FILE) {
            vm.dataService.getTaskDocumentPresenterDownloadURL(eventIdTo, newContentId, objectName).then(function (url) {
              resolve({url: url, imageId: imageId});
            }).catch((e) => {
              vm.common.log.error(e);
              reject(e);
            });
          }
        }).catch(function (e) {
          vm.common.log.error(e);
          reject(e);
        });
      } else {
        vm.common.log.error('Copy object type is undefined');
        reject('Copy object type is undefined');
      }
    });
  }

  private sectionListCopyPaste(eventId, moveList: any[], parentId, nextOrderIndex, increment, copyType, newEventId,
                               parentSectionTypeEducation: boolean,
                               sectionEventPhaseChanger: SectionEventPhaseChanger,
                               pasteType: EMULATE_RESULT, notClearPlannedTimeOnCutPaste = false) {
    return new Promise<any>((resolve, reject) => {
      const parentIsContainer = this.timeLineService.planeFullListContentWithoutFixed[parentId].container;
      const allSectionPromise = [];
      for (let i = 0; i < moveList.length; i++) {
        nextOrderIndex = nextOrderIndex + increment;
        if (copyType === Constants.CONTENT_CLIPBOARD_CUT) {
          if (eventId === newEventId) {
            const pasteParam = i > 0 && pasteType === EMULATE_RESULT.WITH_START_WITHOUT_DURATION_WITHOUT_END ?
              EMULATE_RESULT.WITHOUT_DURATION : pasteType;
            // clear plannedTime, duration, endTime for all section if parent is container and
            // clear plannedTime, endTime if parent is not container.
            const clearTimeObject = parentIsContainer ?
                this.clearTimeObjectAsNull : this.getPasteClearTimeLineObject(pasteParam, moveList[i].sectionContent);
            let params = sectionEventPhaseChanger.change ? {sectionEventPhase: sectionEventPhaseChanger.phase} : {};
            params = merge(params, !notClearPlannedTimeOnCutPaste ? clearTimeObject : {});
            params['education'] = parentSectionTypeEducation;
            allSectionPromise.push(this.dataService.updateContentPosition(eventId, moveList[i].sectionContent,
              i === 0 ? parentId : moveList[i].parentId, nextOrderIndex, params));
          }
        } else if (copyType === Constants.CONTENT_CLIPBOARD_COPY) {
          // all section copy insert into one parent because we don't know new section id
          const sectionObj = moveList[i] instanceof SectionContent ? moveList[i] : (moveList[i] as PlaneContent).sectionContent;
          const params = sectionEventPhaseChanger.change ? {
            sectionEventPhase: isEmpty(sectionEventPhaseChanger.phase) ? null : sectionEventPhaseChanger.phase} : {};
          // clear plannedTime, duration, endTime for all section if parent is container and
          // clear plannedTime, endTime if parent is not container.
          const clearTimeObject = parentIsContainer ?
            this.clearTimeObjectAsNull : this.getPasteClearTimeLineObject(pasteType, moveList[i].sectionContent);
          const mergedContent = merge(cloneDeep(sectionObj), params, !notClearPlannedTimeOnCutPaste ? clearTimeObject : {});
          allSectionPromise.push(this.createCopyContent(mergedContent,
            newEventId, i === 0 ? parentId : moveList[i].parentId, nextOrderIndex, parentSectionTypeEducation, false));
        }
      }
      Promise.all(allSectionPromise).then(sectionList => {
        if (copyType === Constants.CONTENT_CLIPBOARD_CUT) {
            resolve(true);
        } else if (copyType === Constants.CONTENT_CLIPBOARD_COPY) {
          const updatePromise = [];
          for (const obj of sectionList) {
            if (obj.parentId === obj.oldId) {
              continue;
            }
            const oldParentId = obj.parentId;
            const parentObj = sectionList.find(item => item.oldId === oldParentId);
            // after all section copy correct parentId
            const newParentId = !parentObj ? obj.parentId : parentObj.newId;
            updatePromise.push(this.dataService.updateSectionContent(newEventId, obj.newId, {parentId: newParentId}));
          }
          Promise.all(updatePromise).then(() => {
            // const allCopySection = [];
            let promise = Promise.resolve();
            for (const obj of sectionList) {
              promise = promise.then(() => this.copySectionContents(eventId, obj, newEventId));
            }
            promise.then(() => {
              this.clipboard.copySectionListResult$.next(sectionList);
              resolve('success');
            });
          });
        }
      });
    });
  }

  public getPasteClearTimeLineObject(emulateResultWithout: EMULATE_RESULT, section: SectionContent) {
    switch (emulateResultWithout) {
      case EMULATE_RESULT.AS_IS:
        return {
          plannedTime: section.plannedTime,
          plannedTimeFixed: section.plannedTimeFixed,
          plannedTimeFixedValue: section.plannedTimeFixedValue,
          duration: section.duration,
          durationFixed: section.durationFixed,
          durationFixedValue: section.durationFixedValue,
          endTime: section.endTime,
          endTimeFixed: section.endTimeFixed,
          endTimeFixedValue: section.endTimeFixedValue
        };
      case EMULATE_RESULT.AS_IS_WITH_FIXED_START_BASE_ON_PREV_SECTION:
        const day = new Date(this._clipboard.daySection.plannedDay());
        const pDate = new Date(section.plannedTime);
        const newPTime = new Date(day.getFullYear(), day.getMonth(), day.getDate(), pDate.getHours(), pDate.getMinutes()).getTime();
        return {
          plannedTime: newPTime,
          plannedTimeFixed: true,
          plannedTimeFixedValue: newPTime,
          duration: section.duration,
          durationFixed: section.durationFixed,
          durationFixedValue: section.durationFixedValue,
          endTime: newPTime + section.durationTime(),
          endTimeFixed: section.endTimeFixed,
          endTimeFixedValue: newPTime + section.durationTime()
        };
      case EMULATE_RESULT.WITHOUT_START_END_WITH_EXTEND:
      case EMULATE_RESULT.WITHOUT_START_END:
        return {
          plannedTime: null,
          plannedTimeFixed: false,
          plannedTimeFixedValue: null,
          endTime: null,
          endTimeFixed: false,
          endTimeFixedValue: null};
      case EMULATE_RESULT.WITHOUT_DURATION:
        return {
          plannedTime: null,
          plannedTimeFixed: false,
          plannedTimeFixedValue: null,
          duration: null,
          durationFixed: false,
          durationFixedValue: null,
          endTime: null,
          endTimeFixed: false,
          endTimeFixedValue: null,
        };
      case EMULATE_RESULT.WITHOUT_DURATION_WITH_START_END:
        return {
          plannedTime: section.plannedTime,
          plannedTimeFixed: section.plannedTimeFixed,
          plannedTimeFixedValue: section.plannedTimeFixedValue,
          duration: null,
          durationFixed: false,
          durationFixedValue: null,
          endTime: section.endTime,
          endTimeFixed: section.endTimeFixed,
          endTimeFixedValue: section.endTimeFixedValue
        };
      case EMULATE_RESULT.WITH_START_WITHOUT_DURATION_WITHOUT_END:
        return {
          plannedTime: section.plannedTime,
          plannedTimeFixed: true,
          plannedTimeFixedValue: section.plannedTimeFixedValue,
          duration: null,
          durationFixed: false,
          durationFixedValue: null,
          endTime: null,
          endTimeFixed: false,
          endTimeFixedValue: null
        };
      case EMULATE_RESULT.WITH_FIXED_START_AND_DURATION_BASE_ON_PREV_SECTION:
        const pTime = this._clipboard.daySection.plannedTime - section.durationTime();
        const pTimeFixed = pTime >= this.utils.getDateDay(this._clipboard.daySection.plannedTime);
        return {
          plannedTime: pTimeFixed ? this._clipboard.daySection.plannedTime - section.durationTime() :
            this._clipboard.daySection.plannedTime,
          plannedTimeFixed: true,
          plannedTimeFixedValue: pTimeFixed ? this._clipboard.daySection.plannedTime - section.durationTime() :
            this._clipboard.daySection.plannedTime,
          duration: pTimeFixed ? section.duration : 0,
          durationFixed: true,
          durationFixedValue: pTimeFixed ? section.durationFixedValue : 0,
          endTime: null,
          endTimeFixed: false,
          endTimeFixedValue: null
        };
      case EMULATE_RESULT.WITH_FIXED_START_BASE_ON_PREV_SECTION:
        return {
          plannedTime: this._clipboard.daySection.plannedTime,
          plannedTimeFixed: true,
          plannedTimeFixedValue: this._clipboard.daySection.plannedTime,
          duration: null,
          durationFixed: false,
          durationFixedValue: null,
          endTime: null,
          endTimeFixed: false,
          endTimeFixedValue: null
        };
    }
  }
  /**
   * Copy section contents. sectionObj - object created in method sectionCopyPaste or created manual.
   * @param eventId
   * @param params
   * @param newEventId
   * @returns {Promise<any>}
   */
  private copySectionContents(eventId, params: { newId, oldId }, newEventId) {
    const loadFromEducation = this.clipboard.getContentLocation(this.clipboard.content?.id) !== CLIPBOARD_HISTORY_LOCATION.CONFERENCE;
    return this.dataService.loadSectionContentsByLocation(eventId, params.oldId, loadFromEducation)
      .then(contentList => {
        const allContents = [];
        const list = this.drmService.drmListFilter(contentList);
        for (const obj of list) {
          const content: any = this.dataService.createTypedContent(obj);
          if (content) {
            allContents.push(this.createCopyContent(content, newEventId, params.newId, content[Constants.ORDERINDEX], false, false));
          }
        }
        Promise.all(allContents).then(idList => idList).catch(error => {
          this.common.showProgress.next(false);
          this.common.log.error(error);
          throw error;
        });
      });
  }

  createEditSectionSettingsDialogData(currentUser, section: SectionTimeline | SectionContent, isFeatureLineContent, sectionTypeList,
                              isPresenter, isAdminOrModerator, requiredEventRegistration,
                                      registrationSettings: RegistrationSettings) {
    return {
      isFeatureLine: isFeatureLineContent, currentUser: currentUser, section: section,
      isPresenter: isPresenter, isAdminOrModerator: isAdminOrModerator, sectionTypeList: sectionTypeList,
      requiredEventRegistration: requiredEventRegistration, registrationSettings: registrationSettings};
  }

  deleteSection(eventId: string, section: SectionTimeline | SectionContent, deleteType: 'this'|'subcontents'|'suball') {
    switch (deleteType) {
      case 'this':
        return this.deleteChildSectionWithContents(eventId, section, true);
      case 'subcontents':
        return this.deleteChildContents(eventId, section);
      case 'suball':
        return this.deleteChildSectionWithContents(eventId, section, false);
    }
  }

  private deleteSectionRegistrationInformation(eventId, sectionId) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      this.dataService.getRegistrationQuestionnairePromise(eventId, sectionId)
        .then(function (vQ) {
          const allPromise = [];
          if (vQ) {
            const sQ = new QuestionnaireContent(vQ);
            allPromise.push(vm.dataService.deleteRegistrationQuestionnaireExt(eventId, sQ));
          }
          allPromise.push(vm.dataService.deleteRegistrationSettings(eventId, sectionId));
          allPromise.push(vm.dataService.deleteRegistrationUsers(eventId, sectionId));
          allPromise.push(vm.dataService.deleteSectionRegistrationSummary(eventId, sectionId));
          Promise.all(allPromise).then(function () {
            resolve('success');
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
        reject(error);
      });
    });
  }

  private deleteChildContents(eventId, section: SectionTimeline | SectionContent) {
    const vm = this;
    return new Promise<any>(async (resolve, reject) => {
      const children = await this.dataService.getSectionContentsPromise(eventId, section.id, CONTENT_PATH_TYPE.DEFAULT);
      const draftChildren = await this.dataService.getSectionContentsPromise(eventId, section.id, CONTENT_PATH_TYPE.DRAFT);
      Promise.resolve(union(children, draftChildren)).then(childs => {
        if (childs && !isEmpty(childs)) {
          const idList = Object.keys(childs);
          const allPromise = [];
          for (const id of idList) {
            allPromise.push(vm.deleteContent(childs[id]));
          }
          allPromise.push(this.dataService.deleteSectionCollectionsData(eventId, section.id));
          Promise.all(allPromise).then(function () {
            resolve('success');
          }).catch(function (error) {
            reject(error);
          });
        } else {
          this.dataService.deleteSectionCollectionsData(eventId, section.id).then(() => {
            resolve('success');
          }).catch(function (error) {
            reject(error);
          });
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  private async getShortcutsSectionsList(eventId, sectionsList: SectionTimeline[] | SectionContent[]) {
    const list = [];
    let timelineSections = this.timelineService.timelineSections;
    if (isEmpty(timelineSections)) {
      timelineSections = await this.dataService.getSectionsPromise(eventId, false);
    }
    for (const section of  sectionsList) {
      if (section.shortcuts) {
        section.shortcuts.forEach(id => {
          const s = timelineSections.find(o => o.id === id);
          if (s) {
            list.push(...this.utils.getSectionChildrenTree(s, timelineSections)
              .map(o => new PlaneContent(o as SectionTimeline, null)));
          }
        });
      }
    }
    return list;
  }

  private deleteChildSectionWithContents(eventId: string, section: SectionTimeline | SectionContent, withSelf) {
    return new Promise<any>(async (resolve, reject) => {
      const list = this.timeLineService.childTreeInLineList(section.id);
      if (section.education && this.timeLineService.event.slotMode &&
           this.timeLineService.timelineSlotMode.getValue() && !section.deleted) {
        const allPromise = [];
        list.filter(o => (!withSelf ? o.id !== section.id : o.id))
          .forEach(s => allPromise.push(this.dataService.updateSectionContent(section.eventId, s.id, {deleted: true})));
        Promise.all(allPromise).then(() => {
          resolve('success');
        }).catch(error => {
          reject(error);
        });
      } else if (list && !isEmpty(list) || (list.length === 1 && list[0].id === section.id)) {
        if (section.education && this.timeLineService.event.slotMode) {
          list.push(...(await this.getShortcutsSectionsList(eventId, list.map(p => p.sectionContent))));
        }
        const allPromise = [];
        for (const obj of list) {
          allPromise.push(this.deleteChildContents(eventId, obj.sectionContent));
        }
        Promise.all(allPromise).then(() => {
          const allPromiseSection = [];
          for (const obj of list) {
            if (withSelf) {
              allPromiseSection.push(this.deleteContent(obj.sectionContent));
            } else if (obj['id'] !== section.id) {
              allPromiseSection.push(this.deleteContent(obj.sectionContent));
            }
          }
          Promise.all(allPromiseSection).then(() => {
            resolve('success');
          }).catch(error => {
            reject(error);
          });
        }).catch((error) => {
          reject(error);
        });
      } else {
        resolve('success');
      }
    });
  }

  copyRegistrationSettings(eventId, sectionObj: {newId, oldId}, newEventId) {
    return new Promise<any>((resolve, reject) => {
          this.dataService.getRegistrationSettingsPromise(eventId, sectionObj.oldId)
            .then(vRegistrationSettings => {
              // if registration settings null maybe there is a questionnaire.
              if (vRegistrationSettings) {
                this.dataService.getSectionPromise(eventId, sectionObj.oldId).then(oldSectionObject => {
                  const oldSection = new SectionContent(oldSectionObject);
                  this.dataService.getSectionPromise(eventId, sectionObj.newId).then(newSectionObject => {
                    const newSection = new SectionContent(newSectionObject);
                    const newRegSettings = new RegistrationSettings(vRegistrationSettings);
                    if (newRegSettings.emailAutoInviteDate && newSection.plannedTime && oldSection.plannedTime) {
                      const delta = newSection.plannedTime - oldSection.plannedTime;
                      newRegSettings.emailAutoInviteDate = newRegSettings.emailAutoInviteDate + delta;
                    }
                    if (newRegSettings.emailRemainderDate && newSection.plannedTime && oldSection.plannedTime) {
                      const delta = newSection.plannedTime - oldSection.plannedTime;
                      newRegSettings.emailRemainderDate = newRegSettings.emailRemainderDate + delta;
                    }
                    this.dataService.editRegistrationSettings(newEventId, sectionObj.newId, newRegSettings).then(() => {
                      this.copyRegistrationQuestionnaire(eventId, sectionObj, newEventId).then(() => {
                        resolve('success');
                      }).catch(function (error) {
                        reject(error);
                      });
                    }).catch(function (error) {
                      reject(error);
                    });
                  }).catch(function (error) {
                    reject(error);
                  });
                }).catch(function (error) {
                  reject(error);
                });
              } else {
                this.copyRegistrationQuestionnaire(eventId, sectionObj, newEventId).then(() => {
                  resolve('success');
                }).catch(function (error) {
                  reject(error);
                });
              }
            }).catch(function (error) {
            reject(error);
          });
    });
  }

  private copyRegistrationQuestionnaire(eventId, sectionObj: {newId, oldId}, newEventId) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      this.dataService.getRegistrationQuestionnairePromise(eventId, sectionObj.oldId)
        .then(function (vRegistrationQuestionnaire) {
        if (vRegistrationQuestionnaire) {
          const newRegQuestionnaire = new QuestionnaireContent(vRegistrationQuestionnaire);
          const preparedContent = vm.prepareContent(newRegQuestionnaire, newEventId, sectionObj.newId, 0, true);
          preparedContent.content.id = sectionObj.newId;
          vm.dataService.editRegistrationQuestionnaire(newEventId, sectionObj.newId,
            preparedContent.content.title, preparedContent.content).then(function () {
            resolve('success');
          }).catch(function (error) {
            reject(error);
          });
        } else {
          resolve('success');
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  }

  editModularContent(c_eventId, c_timelineId, object: ModularContent) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      const allPromise = [];
      for (const moduleItem of object.getItems()) {
        if (Constants.MODULE_TYPES_FILE[moduleItem.type] && moduleItem.moduleStatus.get() === Constants.MODULE_STATUS_DELETE) {
          moduleItem['toJSON'] = function() {
            return undefined;
          };
          try {
            if (Constants.MODULE_TYPES_FILE[moduleItem.type]) {
              const imageDeleteData: {}[] = JSON.parse(moduleItem.getModuleContent());
              for (const imgObj of imageDeleteData) {
                allPromise.push(vm.deleteModuleStorageItem(c_eventId, c_timelineId, imgObj, moduleItem.type));
              }
            }
            allPromise.push(vm.dataService.deleteModularContentModuleSvgFromStorageAndMetadata(c_eventId, object.parentId, object.id,
              moduleItem.id.replace('-', '')));
          } catch (e) {
            vm.common.log.error('editModularContent', 'eventId', c_eventId, 'timelineId', c_timelineId, e);
          }
        } else
        if (Constants.MODULE_TYPES_FILE[moduleItem.type] && moduleItem.moduleStatus.get() !== Constants.MODULE_STATUS_DELETE) {
          try {
            const imageData: {}[] = JSON.parse(moduleItem.getModuleContent());
            for (const imgObj of imageData) {
              if (imgObj['upload'] === Constants.NEED_UPLOAD) {
                allPromise.push(vm.uploadModuleStorageItem(c_eventId, c_timelineId, imgObj, moduleItem.type));
              } else if (imgObj['upload'] === Constants.DELETED) {
                allPromise.push(vm.deleteModuleStorageItem(c_eventId, c_timelineId, imgObj,
                  imgObj['type'] ? imgObj['type'] : moduleItem.type)
                  .then((delImgResult) => {return vm.dataService.
                    deleteImageSvgFromStorageAndMetadata(c_eventId, object.parentId, object.id, imgObj['id'], 'general')
                      .then(() => {return vm.dataService.
                      deleteImageSvgFromStorageAndMetadata(c_eventId, object.parentId, object.id, imgObj['id'], 'personal')
                        .then(() => Promise.resolve(delImgResult));
                    });
                }));
              } else if (imgObj['upload'] === Constants.NEED_RELOAD) {
                allPromise.push(vm.reloadModuleItemImage(c_eventId, c_timelineId, imgObj));
              } else if (imgObj['upload'] === Constants.NEED_REUPLOAD) {
                allPromise.push(vm.reuploadModuleItemImage(c_eventId, c_timelineId, imgObj, moduleItem.type));
              }
            }
          } catch (e) {
            vm.common.log.error('editModularContent', 'eventId', c_eventId, 'timelineId', c_timelineId, e);
            moduleItem.moduleContent = [];
          }
        }
      }
      Promise.all(allPromise).then(function (list) {
        object.getItems().forEach( (moduleItem, mIndex) => {
          if (Constants.MODULE_TYPES_FILE[moduleItem.type] && moduleItem.moduleStatus.get() !== Constants.MODULE_STATUS_DELETE) {
            let imageData: {}[] = [];
            try {
              imageData = JSON.parse(moduleItem.getModuleContent());
              for (const obj of list.filter(o => !isEmpty(o))) {
                const index = imageData.findIndex(o => o['id'] === obj['id']);
                if (index > -1) {
                  if (obj['upload'] === Constants.UPLOADED) {
                    imageData[index] = obj;
                  } else if (obj['upload'] === Constants.DELETED) {
                    imageData.splice(index, 1);
                  }
                }
              }
            } catch (e) {
              vm.common.log.error('editModularContent', 'eventId', c_eventId, 'timelineId', c_timelineId, e);
              imageData = [];
            }
            try {
              moduleItem.moduleContent = JSON.stringify(imageData);
            } catch (e) {
              vm.common.log.error('editModularContent', 'eventId', c_eventId, 'timelineId', c_timelineId, e);
              moduleItem.moduleContent = [];
            }
          } else if (moduleItem.moduleStatus.get() === Constants.MODULE_STATUS_DELETE) {
            object.getItems().splice(mIndex, 1);
          }
        });
        vm.dataService.editModularContent(c_eventId, c_timelineId, object, true).then(function (c) {
          resolve(c);
        }).catch(function (e) {
          reject(e);
        });
      });
    });
  }

  private uploadModuleStorageItem(c_eventId, c_timelineId, item, moduleType) {
    return new Promise<any>((resolve, reject) => {
      if (moduleType === Constants.MODULE_TYPE_IMAGE || moduleType === Constants.MODULE_TYPE_PECHA_KUCHA) {
        this.dataService.uploadImageToStorage(c_eventId, c_timelineId, item['id'], item['src']).then(function (snapshot) {
          if (!snapshot) {
            reject('Error upload image ' + item['id']);
          } else {
            snapshot.ref.getDownloadURL().then(url => {
              resolve({id: item['id'], src: url, upload: 1});
            }).catch(function (e) {
              reject(e);
            });
          }
        }).catch(function (e) {
          reject(e);
        });
      } else if (moduleType === Constants.MODULE_TYPE_PDF) {
        this.dataService.uploadPdfToStorage(c_eventId, c_timelineId, item['id'], item['src']).then(function (snapshot) {
          if (!snapshot) {
            reject('Error upload image ' + item['id']);
          } else {
            snapshot.ref.getDownloadURL().then(url => {
              resolve({id: item['id'], src: url, upload: 1});
            }).catch(function (e) {
              reject(e);
            });
          }
        }).catch(function (e) {
          reject(e);
        });
      } else if (moduleType === Constants.MODULE_TYPE_ZIF) {
        this.dataService.uploadZifToStorage(c_eventId, c_timelineId, item['id'], item['src']).then(function (snapshot) {
          if (!snapshot) {
            reject('Error upload image ' + item['id']);
          } else {
            snapshot.ref.getDownloadURL().then(url => {
              resolve({id: item['id'], src: url, upload: 1});
            }).catch(function (e) {
              reject(e);
            });
          }
        }).catch(function (e) {
          reject(e);
        });
      } else {
        resolve(null);
      }
    });
  }

  private deleteModuleStorageItem(c_eventId, c_timelineId, item, moduleType) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      if (moduleType === Constants.MODULE_TYPE_IMAGE || moduleType === Constants.MODULE_TYPE_PECHA_KUCHA) {
        this.dataService.deleteImageFromStorage(c_eventId, c_timelineId, item['id']).then(function (snapshot) {
          // todo: add remove svg metadata
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        }).catch(function (e) {
          vm.common.log.error(e);
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        });
      } else if (moduleType === Constants.MODULE_TYPE_PDF) {
        this.dataService.deletePdfFromStorage(c_eventId, c_timelineId, item['id']).then(function (snapshot) {
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        }).catch(function (e) {
          vm.common.log.error(e);
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        });
      } else if (moduleType === Constants.MODULE_TYPE_ZIF) {
        this.dataService.deleteZifFromStorage(c_eventId, c_timelineId, item['id']).then(function (snapshot) {
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        }).catch(function (e) {
          vm.common.log.error(e);
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        });
      } else {
        resolve(null);
      }
    });
  }

  deleteModularContent(c_eventId, c_parentId, c_timelineId, object: ModularContent) {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      const allPromise = [];
      for (const moduleItem of object.getItems()) {
        if (Constants.MODULE_TYPES_FILE[moduleItem.type]) {
          let imageData: {}[] = [];
          try {
            imageData = JSON.parse(moduleItem.getModuleContent());
          } catch (e) {
            vm.common.log.error('deleteModularContent. Bad content body format.', 'eventId', c_eventId, 'timelineId', c_timelineId, e);
            imageData = [];
          }
          for (const item of imageData) {
            allPromise.push(vm.deleteModuleStorageItem(c_eventId, c_timelineId, item, moduleItem.type));
          }
        }
      }
      Promise.all(allPromise).then(function (r) {
        vm.dataService.deleteModularContentAllSvgFromStorageAndMetadata(c_eventId, c_parentId, c_timelineId).then(() => {
          vm.dataService.deleteModularContent(c_eventId, c_parentId, c_timelineId, object.dirty).then(function (c) {
            resolve(c);
          }).catch(function (e) {
            reject(e);
          });
        }).catch(function (e) {
          reject(e);
        });
      });
    });
  }

  deleteTaskDocumentContent(c_eventId, c_parentId, c_timelineId, object: TaskDocument) {
    return new Promise<any>((resolve, reject) => {
      const allPromise = [];
      if (!isEmpty(object.uploadFileLinks)) {
        for (const item of object.uploadFileLinks) {
          allPromise.push(this.dataService.deletePresenterAnyDocumentFromStorage(object.eventId, object.id, item.id + '-' + item.name));
        }
      }
      allPromise.push(this.dataService.deleteAllUserAnyDocumentFromStorage(c_eventId, c_timelineId));
      Promise.all(allPromise).then(() => {
        this.dataService.deleteTaskDocumentContent(c_eventId, c_parentId, c_timelineId, object.dirty).then(() => {
          return resolve(c_timelineId);
        });
      });
    });
  }

  private reloadModuleItemImage(c_eventId, c_timelineId, item) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      this.storageObjectCopy(c_eventId, c_eventId,
        'clipboard/' + c_timelineId + '.png',
        'image/' + c_timelineId + '/' + item['id'] + '.png', c_timelineId, COPY_TYPE.IMAGE, item['id'])
        .then(function (copyResult) {
          vm.dataService.deleteImage(c_eventId, c_timelineId).then(function (snapshot) {
            resolve({id: item['id'], src: copyResult.url, upload: Constants.UPLOADED});
          }).catch(function (e) {
            vm.common.log.error(e);
            resolve({id: item['id'], src: null, upload: Constants.DELETED});
          });
        }).catch(function (e) {
        vm.common.log.error(e);
        resolve({id: item['id'], src: null, upload: Constants.DELETED});
      });
    });
  }

  private reuploadModuleItemImage(c_eventId, c_timelineId, item, moduleType) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      vm.dataService.deleteImage(c_eventId, c_timelineId).then(function (snapshot) {
        vm.uploadModuleStorageItem(c_eventId, c_timelineId, item, moduleType).then(function (result) {
          resolve(result);
        }).catch(function (e) {
          vm.common.log.error(e);
          resolve({id: item['id'], src: null, upload: Constants.DELETED});
        });
      }).catch(function (e) {
        vm.common.log.error(e);
        resolve({id: item['id'], src: null, upload: Constants.DELETED});
      });
    });
  }

  editTaskDocumentContent(c_eventId, c_timelineId, object: TaskDocument) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      const allPromise = [];
      if (!isEmpty(object.uploadFileLinks)) {
        for (const item of object.uploadFileLinks) {
          if (item.upload === LINK_STATE.NEED_UPLOAD) {
            allPromise.push(vm.dataService.uploadPresenterAnyDocumentToStorage(c_eventId, c_timelineId,
              item.id + '-' + item.name, item.src, item.metaType).then((snapshot) => {
              return snapshot.ref.getDownloadURL().then(url => {
                return {id: item['id'], src: url, upload: LINK_STATE.UPLOADED};
              });
            }));
          }
          if (item.upload === LINK_STATE.DELETED) {
            allPromise.push(vm.dataService.deletePresenterAnyDocumentFromStorage(c_eventId, c_timelineId, item.id + '-' + item.name)
              .then(() => {
                return {id: item['id'], src: null, upload: LINK_STATE.DELETED};
              }));
          }
        }
      }
      Promise.all(allPromise).then((result) => {
        for (const item of result) {
          const index = object.uploadFileLinks.findIndex(it => it.id === item.id);
          if (index > -1) {
            const obj = object.uploadFileLinks[index];
            if (obj.upload === LINK_STATE.NEED_UPLOAD && item.upload === LINK_STATE.UPLOADED) {
              obj.upload = LINK_STATE.UPLOADED;
              obj.src = item.src;
            } else if (item.upload === LINK_STATE.DELETED) {
              object.uploadFileLinks.splice(index, 1);
            }
          }
        }
        this.dataService.editTaskDocumentContent(c_eventId, c_timelineId, object).then(() => {
          return resolve(object.id);
        });
      });
    });
  }

  /**
   * Add questionnaire content from returned object from edit dialogs.
   * @param eventId
   * @param contentObject
   */
  addQuestionnaireContent(eventId, contentObject) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      const questionsUrlList = [];
      const wordCloudUrlList = [];
      const questionList = {};
      Object.keys(contentObject.content.questions)
        .forEach(qKey => questionList[qKey] = {
          imageUrl: contentObject.content.questions[qKey].imageUrl,
          wordCloudTemplate: clone(contentObject.content.questions[qKey].wordCloudTemplate)
        });
      Object.keys(questionList).forEach(qKey => {
        contentObject.content.questions[qKey].imageUrl = null;
        contentObject.content.questions[qKey].wordCloudTemplate = null;
      });
      this.dataService.addQuestionnaireContent(eventId, contentObject.content).then(function (c) {
        const contentId = c;
        contentObject.content.id = contentId;
        contentObject.content.eventId = eventId;
        const questionKeyList = Object.keys(questionList);
        for (let i = 0; i < questionKeyList.length; i++) {
          const qKey = questionKeyList[i];
          questionsUrlList.push(
            vm.dataService.addQuestionImage(eventId, contentId, qKey, questionList[qKey].imageUrl)
          );
          wordCloudUrlList.push(
            vm.dataService.addQuestionWorCloud(eventId, contentId, qKey, questionList[qKey].wordCloudTemplate)
          );
        }
        Promise.all([Promise.all(questionsUrlList), Promise.all(wordCloudUrlList)]).then(urlList => {
          const questionsUrlListV = urlList[0];
          const wordCloudUrlListV = urlList[1];
          for (let i = 0; i < questionsUrlListV.length; i++) {
            const qUrl = questionsUrlListV[i];
            contentObject.content.questions[qUrl.qKey].imageUrl = qUrl.imageUrl;
          }
          for (let i = 0; i < wordCloudUrlListV.length; i++) {
            const template = wordCloudUrlListV[i];
            contentObject.content.questions[template.qKey].wordCloudTemplate = template.template;
          }
          vm.dataService
            .editQuestionnaireContent(
              eventId, contentId, contentObject.content.title, contentObject.content, contentObject.content.isPublic).then(function (r) {
              resolve(contentId);
            }).catch(function (e) {
            vm.common.log.error(e);
            resolve(null);
          });
        });
      }).catch(function (e) {
        vm.common.log.error(e);
        resolve(null);
      });
    });
  }

  /**
   * Update questionnaire content from returned object from edit dialogs.
   * @param eventId
   * @param contentObject
   * @param projectorContentList
   * @param oldQuestions
   */
  updateQuestionnaireContent(eventId, contentObject, projectorContentList, oldQuestions: IQuestions) {
    const vm = this;
    const questionsUrlList = [];
    const wordCloudUrlList = [];
    const questionKeyList = Object.keys(contentObject.content.questions);
    return this.deleteOldQuestionImages(eventId, contentObject).then(function (r) {
      for (let i = 0; i < questionKeyList.length; i++) {
        const qKey = questionKeyList[i];
        const checkFbStorage = contentObject.oldQuestionsValues[qKey] && contentObject.oldQuestionsValues[qKey].imageUrl &&
          vm.utils.isFBUrl(contentObject.oldQuestionsValues[qKey].imageUrl, eventId);
        questionsUrlList.push(
          vm.dataService.patchQuestionImage(
            eventId, contentObject.content.id, qKey, contentObject.content.questions[qKey].imageUrl, checkFbStorage)
        );
        wordCloudUrlList.push(
          vm.dataService.patchQuestionWordCloudTemplate(
            eventId, contentObject.content.id, qKey, contentObject.content.questions[qKey].wordCloudTemplate,
            contentObject.oldQuestionsWordCloudTemplate[qKey] && !contentObject.newQuestionsWordCloudTemplate[qKey],
            !oldQuestions[qKey])
        );
      }
      return Promise.all([Promise.all(questionsUrlList), Promise.all(wordCloudUrlList)]).then(urlList => {
        const questionsUrlListV = urlList[0];
        const wordCloudUrlListV = urlList[1];
        for (let i = 0; i < questionsUrlListV.length; i++) {
          const qUrl = questionsUrlListV[i];
          contentObject.content.questions[qUrl.qKey].imageUrl = qUrl.imageUrl;
        }
        for (let i = 0; i < wordCloudUrlListV.length; i++) {
          const template = wordCloudUrlListV[i];
          contentObject.content.questions[template.qKey].wordCloudTemplate = template.template;
        }
        vm.deleteNotSavedFields(contentObject.content);
        return vm.dataService
          .editQuestionnaireContent(
            eventId, contentObject.content.id, contentObject.content.title, contentObject.content, contentObject.content.isPublic)
          .then(function (c) {
            return Promise.resolve(contentObject.content.id);
          });
      });
    }).catch(function (e) {
      vm.common.log.error(e);
    });
  }

  updateQuestionnaireContentSimple(eventId, content: QuestionnaireContent) {
    return this.dataService
      .editQuestionnaireContent(eventId, content.id, content.title, content, content.isPublic);
  }

  updateRegistrationQuestionnaireContent(eventId, contentObject) {
    return this.dataService.editRegistrationQuestionnaire(
        eventId, contentObject.content.id, contentObject.content.title, contentObject.content);
  }

  updateRegistrationQuestionnaireContentSimple(eventId, content: QuestionnaireContent) {
    return this.dataService.editRegistrationQuestionnaire(eventId, content.id, content.title, content);

  }

  private deleteOldQuestionImages(eventId, contentObject) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      const allPromise = [];
      // remove image of deleted question if this image was saved in FB storage.
      // oldQuestionsValues and newQuestionsValues is objects of {id, imageUrl} by question where id is question id
      for (const oldQ of this.utils.objectValues(contentObject.oldQuestionsValues)) {
        const newQ = contentObject.newQuestionsValues[oldQ['id']];
        if (!newQ && oldQ['imageUrl'] && vm.utils.isFBUrl(oldQ['imageUrl'], eventId)) {
          allPromise.push(new Promise<any>((resolveD, rejectD) => {
            vm.dataService.deleteQuestionImage(eventId, contentObject.content.id, oldQ['id']).then(function (r) {
              delete contentObject.oldQuestionsValues[oldQ['id']];
              resolveD(true);
            }).catch(function (e) {
              vm.common.log.error('error deleteQuestionImage, content image will be not delete or not exist.', e);
              resolveD(true);
            });
          }));
        }
      }
      for (const oldQ of this.utils.objectValues(contentObject.oldQuestionsWordCloudTemplate)) {
        const newQ = contentObject.newQuestionsWordCloudTemplate[oldQ['id']];
        if (!newQ && oldQ.template && oldQ.template.url) {
          allPromise.push(new Promise<any>((resolveD, rejectD) => {
            vm.dataService.deleteQuestionWordCloudTemplate(eventId, contentObject.content.id, oldQ['id']).then(function (r) {
              delete contentObject.oldQuestionsWordCloudTemplate[oldQ['id']];
              resolveD(true);
            }).catch(function (e) {
              vm.common.log.error('error deleteQuestionTemplate, content image will be not delete or not exist.', e);
              resolveD(true);
            });
          }));
        }
      }
      Promise.all(allPromise).then(function () {
        resolve(true);
      }).catch(function (e) {
        vm.common.log.error('error deleteQuestionImage, content image will be not delete or not exist.', e);
        reject(e);
      });
    });
  }

  createFixedSections(eventId, eventInstantSettings: InstantSettings, rootSection?: SectionContent) {
    return new Promise((resolve, reject) => {
      if (!eventId || !eventInstantSettings.modeEasy() || (!this.timeLineService.rootSection && !rootSection) ||
        !isEmpty(this.timeLineService.fixedSections)) {
        return resolve(null);
      }
      const obj = {
        expand: true,
        sectionTypeId: null,
        isPublic: true,
        parentId: rootSection ? rootSection.id : this.timeLineService.rootSection.id,
        eventId: eventId,
      };
      const sectionContent = new SectionContent(obj);
      sectionContent.title = 'easy.section.new';
      sectionContent.id = FIXED_SECTION_TYPE.EASY_NEW;
      sectionContent.fixedSectionType = FIXED_SECTION_TYPE.EASY_NEW;
      sectionContent.orderIndex = FIXED_SECTION_TYPE_ORDER_INDEX.EASY_NEW_ORDER_INDEX;
      const vm = this;
      this.addContent(sectionContent).then(function () {
        sectionContent.title = 'easy.section.down';
        sectionContent.id = FIXED_SECTION_TYPE.EASY_DONE;
        sectionContent.fixedSectionType = FIXED_SECTION_TYPE.EASY_DONE;
        sectionContent.orderIndex = FIXED_SECTION_TYPE_ORDER_INDEX.EASY_DONE_ORDER_INDEX;
        vm.addContent(sectionContent).then(function () {
          sectionContent.title = 'easy.section.suggested';
          sectionContent.id = FIXED_SECTION_TYPE.EASY_SUGGESTED;
          sectionContent.fixedSectionType = FIXED_SECTION_TYPE.EASY_SUGGESTED;
          sectionContent.orderIndex = FIXED_SECTION_TYPE_ORDER_INDEX.EASY_SUGGESTED_ORDER_INDEX;
          vm.addContent(sectionContent).then(function () {
            resolve(null);
          });
        });
      });
    });
  }

  contentMoveToNewOrCoverDown(content: AbstractContent, moveType: FIXED_SECTION_TYPE, disableToastr = false) {
    return new Promise((resolve, reject) => {
      const parent = this.utils.objectValues(this.timeLineService.fixedSections)
        .find(o => o.fixedSectionType === moveType);
      if (parent) {
        this.dataService.updateContentPosition(content.eventId, content, parent.id, null, {}, true)
          .then(() => {
            resolve(true);
            if (!disableToastr) {
              this.toasterService.pop('success', null, this.utils.i18n('easy.content.move.success'));
            }
          }).catch(e => {
          this.common.log.error(e);
          reject(e);
        });
      } else {
        resolve(true);
      }
    });
  }


  /* ~~~~~~~~~~~~~~~~~~~~~~~~ Move from TimeLineSection ~~~~~~~~~~~~~~~~~~~~~~~~ */


  editSection(content: SectionTimeline | SectionContent, currentUser: AppUser, isPresenter: boolean, sectionTypeList: any[],
              dialog: MatDialog, isMedia: boolean, openInstantGroupDialog: boolean, params?) {
    if (content.id) {
      const access = this.timeLineService.currentUser.isAdmin || this.timeLineService.hasAccess(content);
      return (access ? this.dataService.getRegistrationSettingsPromise(content.eventId, content.id) : Promise.resolve())
        .then(settings => {
        return this.editSectionSettings(content, currentUser, isPresenter, sectionTypeList,
          dialog, isMedia, new RegistrationSettings(settings), openInstantGroupDialog, params);
      });
    } else {
      return this.editSectionSettings(content, currentUser, isPresenter, sectionTypeList,
        dialog, isMedia, null, openInstantGroupDialog, params);
    }
  }

  private editSectionSettings(content: SectionTimeline | SectionContent, currentUser: AppUser, isPresenter: boolean, sectionTypeList,
                              dialog: MatDialog, isMedia: boolean, registrationSettings: RegistrationSettings,
                              openInstantGroupDialog: boolean, params?): Promise<string> {
    return new Promise<string>((solve) => {
      const saveAttendeeList = (list: SectionUser[], sectionId) => {
        let up = Promise.resolve();
        for (const user of list || []) {
          up = up.then(() => {
            const isDeleted = user.userId.startsWith('*');
            const userId = user.userId.replace('*', '');
            return this.dataService.setSectionUser(content.eventId, sectionId, userId, !isDeleted).then(() => {
              if (isDeleted && content.id) {
                return this.dataService.saveUserSection(content.eventId, sectionId, {userId: userId});
              } else {
                return Promise.resolve();
              }
            });
          });
        }
        return up.then(() => Promise.resolve(sectionId));
      };
      const add = (result, editedData) => {
        // editedData not included content data so do merges.
        return new Promise<string>((resolve, reject) => {
          const addedData = merge(content, editedData);
          const attendeeList = (editedData.users || []).filter(it => !it.speaker);
          addedData.users = (addedData.users || []).filter(it => it.speaker);
          this.dataService.addSectionContent(this.timeLineService.timelineEvent.eventId, addedData)
            .then(id => {
              if (id) {
                saveAttendeeList(attendeeList, id).then(() => {
                  this.dataService.editRegistrationSettings(vm.timeLineService.timelineEvent.eventId, id,
                    new RegistrationSettings({
                      maxAttendees: result.registrationSettings ? result.registrationSettings.maxAttendees : null,
                      mandatoryForEventRegistration: result.registrationSettings ?
                        result.registrationSettings.mandatoryForEventRegistration : null
                    }))
                    .then(() => resolve(id))
                    .catch(error => {
                      this.common.log.error(error);
                      resolve(null);
                    });
                });
              }
            }).catch(error => {
            this.common.log.error(error);
            resolve(null);
          });
        });
      };
      const edit = (result, editedData) => {
        return new Promise<string>((resolve) => {
          this.common.showProgress.next(true);
          const attendeeList = (editedData.users || []).filter(it => !it.speaker);
          editedData.users = (editedData.users || []).filter(it => it.speaker);
          const access = this.timeLineService.currentUser.isAdmin || this.timeLineService.hasAccess(content);
          if (access) {
            this.dataService.updateSectionContent(content.eventId, editedData.getId(), editedData)
              .then(() => saveAttendeeList(attendeeList, editedData.getId()))
              .then(() => {
                registrationSettings.maxAttendees = result.registrationSettings.maxAttendees ?
                  result.registrationSettings.maxAttendees : null;
                registrationSettings.mandatoryForEventRegistration =
                  result.registrationSettings.mandatoryForEventRegistration ?
                    result.registrationSettings.mandatoryForEventRegistration : null;
                this.dataService.editRegistrationSettings(content.eventId, editedData.getId(), registrationSettings)
                  .then(() => {
                    if (content.container !== editedData.container && editedData.container && !this.timeLineService.event.slotMode) {
                      // if section container is true reset all child's time fields
                      const childList = this.timeLineService.planeFullListContent[content.id].items;
                      if (!isEmpty(childList)) {
                        const task = [];
                        childList.forEach(s => this.getResetSectionTimeRowTask(s.id, task));
                        this.dataService.updateManageTimeSections(content.eventId, task).then(() => {
                          this.common.showProgress.next(false);
                          resolve(content.id);
                        }).catch(error => {
                          this.common.log.error(error);
                          this.cancelCutContent();
                          this.common.showProgress.next(false);
                          resolve(content.id);
                        });
                      } else {
                        this.common.showProgress.next(false);
                        resolve(content.id);
                      }
                    } else {
                      this.common.showProgress.next(false);
                      resolve(content.id);
                    }
                  });
              }).catch(error => {
              this.common.log.error(error);
              this.cancelCutContent();
              this.common.showProgress.next(false);
              resolve(content.id);
            });
          } else if (this.timeLineService.conferenceUser.isEditor || this.timeLineService.conferenceUser.isTranslator) {
            this.dataService.updateSectionContent(content.eventId, editedData.getId(), editedData, true).then(() => {
                this.common.showProgress.next(false);
                resolve(content.id);
              });
          }
        });
      };
      const waitAfterEdit = (sectionId) => {
        this.timeLineService.planeListSubject.pipe(take(1)).subscribe(() => {
          const s = this.timeLineService.planeListContent[sectionId];
          if (!isEmpty(s) && this.timeLineService.selectedContent?.id === sectionId) {
            // if edited section active refresh section display data
            this.dataService.dispatchSetCurrentContent(cloneDeep(s.sectionContent));
          }
        });
      };
      const updateTypeOfConference = (section: SectionContent) => {
        if (!isEmpty(section.meeting) && section.specificVideoConferences && section.virtualConferenceSettings &&
          section.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO) {
          return this.dataService.updateSectionContent(section.eventId, section.getId(), {meeting: {}});
        } else {
          return Promise.resolve();
        }
      };
      const vm = this;
      let dialogData = this.createEditSectionSettingsDialogData(
        currentUser, content, (content.id && content.id === this.timeLineService.featureLineContentId),
        sectionTypeList, isPresenter, currentUser.isAdmin || currentUser.isModerator,
        this.timeLineService.eventInstantSettings.attendeesRegistration, registrationSettings);
      dialogData = Object.assign(dialogData, params);
      let dialogRef;
      if (dialog) {
        if (!openInstantGroupDialog) {
          dialogRef = dialog.open(EditSectionSettingDialogComponent, {
            width: isMedia ? 'unset' : '630px',
            minWidth: isMedia ? 'unset' : '630px',
            maxWidth: isMedia ? '100%' : '',
            data: dialogData
          });
        } else {
          // dialog for InstantGroup
          dialogRef = dialog.open(InstantGroupSettingsDialogComponent, {
            width: '80%',
            height: '80%',
            disableClose: true,
            data: dialogData
          });
        }
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
          if (result) {
            const editedData = result.section;
            // if content.id not null this is edit action.
            if (content.id) {
              if (this.timeLineService.selectedSection && this.timeLineService.selectedSection.id === content.id) {
                waitAfterEdit(content.getId());
              }
              const refresh = this.timeLineService.selectedSection && this.timeLineService.selectedSection.id === editedData.id &&
                dialogData.section.container !== editedData.container;
              edit(result, editedData).then(async (sectionId) => {
                if (sectionId) {
                  const section = new SectionContent(editedData);
                  updateTypeOfConference(section).then(async () => {
                    if (section.specificVideoConferences && section.virtualConferenceSettings &&
                      section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE &&
                      section.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO &&
                      section.virtualConferenceSettings.dailyCoSettings &&
                      section.virtualConferenceSettings.dailyCoSettings.liveStreamingOn) {
                      this.dailyDataService.updateRoom(this.timeLineService.timelineEvent.eventId, section.getId());
                    }
                    if (!isEmpty(result.updatedMeetingRooms)) {
                      for (const r of (result.updatedMeetingRooms.updated || [])) {
                        await this.dataService.addUpdateMeetingRoomInDB(section.eventId, section.id, r);
                      }
                      for (const r of (result.updatedMeetingRooms.deleted || [])) {
                        await this.dailyCoService.deleteMeetingRoomParticipants(section.id, r.roomId);
                      }
                    }
                  });
                }
                if (refresh) {
                  this.timeLineService.refreshSectionSubject.next(editedData);
                  this.timeLineService.changeSectionSubject.next(editedData);
                }
                if (sectionId && editedData.isInstantGroupSection()) {
                  const childSections = !openInstantGroupDialog ?
                    (this.timeLineService.planeListContent[sectionId].sectionContent.items || [])
                      .map(obj => new Object({section: cloneDeep(obj), members: []})) :
                    result.childSections;
                  if (!isEmpty(childSections)) {
                    this.mergeInstantGroupSections(sectionId, childSections, currentUser);
                  }
                }
                return solve(sectionId);
              });
            } else {
              // if content.id null this is add action.
              add(result, editedData).then((sectionId) => {
                if (sectionId) {
                  const section = new SectionContent({id: sectionId, eventId: this.timeLineService.timelineEvent.eventId, ...editedData});
                  updateTypeOfConference(section).then(async () => {
                    if (section.specificVideoConferences && section.virtualConferenceSettings &&
                      section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE &&
                      section.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO &&
                      section.virtualConferenceSettings.dailyCoSettings &&
                      section.virtualConferenceSettings.dailyCoSettings.liveStreamingOn) {
                      this.dailyDataService.updateRoom(this.timeLineService.timelineEvent.eventId, section.id);
                    }
                    if (!isEmpty(result.updatedMeetingRooms)) {
                      for (const r of (result.updatedMeetingRooms.updated || [])) {
                        await this.dataService.addUpdateMeetingRoomInDB(section.eventId, section.id, r);
                      }
                      for (const r of (result.updatedMeetingRooms.deleted || [])) {
                        await this.dailyCoService.deleteMeetingRoomParticipants(section.id, r.roomId);
                      }
                    }
                  });
                }
                if (sectionId && editedData.isInstantGroupSection()) {
                  if (!isEmpty(result.childSections)) {
                    this.mergeInstantGroupSections(sectionId, result.childSections, currentUser);
                  }
                }
                return solve(sectionId);
              });
            }
          }
        });
      } else {
        if (dialogData) {
          const editedData = dialogData.section;
          // if content.id not null this is edit action.
          if (content.id) {
            return edit(dialogData, editedData).then(() => solve(content.id));
          } else {
            // if content.id null this is add action.
            return add(dialogData, editedData).then(id => solve(id));
          }
        }
      }
    });
  }

  mergeInstantGroupSections(parentId, childSections: [{section: any, members: []}], currentUser) {
    const toCreateMeetings = [];
    const toDeleteMeetings = [];
    const unsubscribeSubject = (subject) => {
      subject.next();
      subject.complete();
      subject = null;
    };
    const cSubj = new BehaviorSubject<{sectionId, nextIndex, waitDelete?}>(null);
    const _unsubscribeSubj = new Subject();
    const waitForAction = (waitSectionId, sectionObject, members, index, waitDelete) => {
      const _unsubscribe = new Subject();
      this.common.showProgress.next(true);
      this.common.blockedCloseProgress = true;
      this.timeLineService.planeList.pipe(takeUntil(_unsubscribe)).subscribe(list => {
        if ((!waitDelete && !isEmpty(list[waitSectionId])) || (waitDelete && isEmpty(list[waitSectionId]))) {
          if (sectionObject.id.startsWith('?')) {
            sectionObject.parentId = parentId;
            unsubscribeSubject(_unsubscribe);
            const childList = this.timeLineService.childTreeInLineList(parentId).filter(o => o.id !== parentId);
            this.addSection(list[isEmpty(childList) ? parentId : childList[childList.length - 1].id].sectionContent,
              isEmpty(childList), null, currentUser,
              true, [], null, false,
              list[parentId].sectionContent.sectionEventPhase, false, sectionObject)
              .then((sectionId: string) => {
                toCreateMeetings.push(sectionId);
                this.mergeInstantGroupSectionsMembers(sectionId, members).then(() => {
                  this.common.blockedCloseProgress = false;
                  this.common.showProgress.next(false);
                  cSubj.next({sectionId: sectionId, nextIndex: index + 1});
                }).catch((e) => {
                  this.common.log.error(e);
                  this.common.blockedCloseProgress = false;
                  this.common.showProgress.next(false);
                  unsubscribeSubject(_unsubscribe);
                  unsubscribeSubject(_unsubscribeSubj);
                });
            }).catch((e) => {
              this.common.log.error(e);
              this.common.blockedCloseProgress = false;
              this.common.showProgress.next(false);
              unsubscribeSubject(_unsubscribe);
              unsubscribeSubject(_unsubscribeSubj);
            });
          } else if (!sectionObject['delete']) {
            unsubscribeSubject(_unsubscribe);
            this.editSection(sectionObject, currentUser, true, [], null, false, false)
              .then(() => {
                if (isEmpty(sectionObject.meeting)) {
                  toCreateMeetings.push(sectionObject.id);
                }
                this.mergeInstantGroupSectionsMembers(sectionObject.id, members).then(() => {
                  this.common.blockedCloseProgress = false;
                  this.common.showProgress.next(false);
                  cSubj.next({sectionId: sectionObject.id, nextIndex: index + 1});
                }).catch((e) => {
                  this.common.log.error(e);
                  this.common.blockedCloseProgress = false;
                  this.common.showProgress.next(false);
                  unsubscribeSubject(_unsubscribe);
                  unsubscribeSubject(_unsubscribeSubj);
                });
              }).catch((e) => {
              this.common.log.error(e);
              this.common.blockedCloseProgress = false;
              this.common.showProgress.next(false);
              unsubscribeSubject(_unsubscribe);
              unsubscribeSubject(_unsubscribeSubj);
            });
          } else if (sectionObject['delete']) {
            unsubscribeSubject(_unsubscribe);
            this.deleteContent(sectionObject).then(() => {
              if (sectionObject.meeting && sectionObject.meeting.meetingId) {
                toDeleteMeetings.push({[sectionObject.id] : sectionObject.meeting.meetingId});
              }
              this.common.blockedCloseProgress = false;
              this.common.showProgress.next(false);
              cSubj.next({sectionId: sectionObject.id, nextIndex: index + 1, waitDelete: true});
            }).catch((e) => {
              this.common.log.error(e);
              this.common.blockedCloseProgress = false;
              this.common.showProgress.next(false);
              unsubscribeSubject(_unsubscribe);
              unsubscribeSubject(_unsubscribeSubj);
            });
          }
         }
      });
    };
    cSubj.pipe(takeUntil(_unsubscribeSubj)).subscribe(object => {
      this.common.blockedCloseProgress = false;
      this.common.showProgress.next(false);
      if (!object || object && object.nextIndex < childSections.length) {
        waitForAction(!object ? parentId : object.sectionId,
          childSections[!object ? 0 : object.nextIndex].section,
          childSections[!object ? 0 : object.nextIndex].members,
          !object ? 0 : object.nextIndex,
          !object ? false : object.waitDelete);
      } else {
        unsubscribeSubject(_unsubscribeSubj);
        this.common.blockedCloseProgress = false;
        this.common.showProgress.next(false);
        if (!this.utils.getEnv().meeting.doNotCreateMeetingForInstantGroup) {
          let promises = Promise.resolve(null);
          for (const sectionId of toCreateMeetings) {
            promises = promises
              .then(() => {
                const guid = this.common.utils.generateRandomString(24);
                const url = this.common.utils.getEnv().meeting.jitsi.domain + '/' + guid;
                const pass = this.common.utils.generateRandomNumberString(8);
                const meeting = {
                  meetingId: guid,
                  url: url,
                  password: pass
                };
                return this.dataService.updateSectionContent(this.timeLineService.timelineEvent.eventId, sectionId, {
                  meeting: meeting
                });
              });
          }
          for (const meetingObject of toDeleteMeetings) {
            for (const sectionId of Object.keys(meetingObject)) {
              promises = promises.then(() => {
                return this.dataService.updateSectionContent(this.timeLineService.timelineEvent.eventId, sectionId, {meeting: null});
              });
            }
          }
        }
      }
    });
  }

  mergeInstantGroupSectionsMembers(sectionId, members: IMember[]) {
    let m = Promise.resolve(null);
    for (const member of members) {
      m = m.then(() => {
        return this.dataService.saveUserSection(
          this.timeLineService.timelineEvent.eventId, sectionId,
          {email: member['email'],  joinTime: !member['delete'] && !member['isMemberInOtherSection'] ? this.utils.now() : null})
          .then((value) => {
            if (!value && !member['delete'] && !member['isMemberInOtherSection']) {
              return this.dataService.joinInvitedToSection(
                this.timeLineService.timelineEvent.eventId, sectionId, {email: member['email'], joinTime: this.utils.now()});
            } else {
              return Promise.resolve();
            }
          });
      })
        .then(() => {
          return this.dataService.setSectionUser(
            this.timeLineService.timelineEvent.eventId, sectionId, member.userId, !member['delete']
          );
        });
    }
    return m.then(() => Promise.resolve());
  }

  public cutContent(content: AbstractContent, copyType) {
    const cutContentId = () => this._clipboard.content ? this._clipboard.content.id : null;
    const moveList = this.timeLineService.childTreeInLineList(content.id).map(o => cloneDeep(o));
    this._clipboard.copyContent = cutContentId() === content.id  && this._clipboard.copyType === copyType ? null :
      {content: cloneDeep(content), copyType: copyType, moveSectionList: moveList};
  }

  public cancelCutContent() {
    this._clipboard.copyContent = null;
  }

  pasteTimeline(contentBelow: IAbstractContent, below: boolean, pasteType: EMULATE_RESULT, notClearPlannedTimeOnCutPaste = false) {
    const vm = this;
    if (this._clipboard.content && this._clipboard.isTypeSection) {
      if (this._clipboard.isCutType && this._clipboard.content['sectionEventPhase'] === contentBelow['sectionEventPhase'] &&
        this.checkPasteObjectIntoItself(contentBelow, below) === Constants.BREAK_PROCESS) {
        return new Promise<any>((resolve, reject) => {
          this._clipboard.copyContent = null;
          resolve(Constants.BREAK_PROCESS);
        });
      }
      const parentId = contentBelow.isTypeSection ?
        (below ? contentBelow.parentId : contentBelow.id) : contentBelow.parentId;
      let orderIndex = null;
      if (contentBelow.isTypeSection) {
        orderIndex = below ?
          this.timeLineService.getOrderIndex(contentBelow.id, true, this._clipboard.content.id) :
          this.timeLineService.getOrderIndex(contentBelow.id);
      } else {
        orderIndex = this.timeLineService.getOrderIndex(contentBelow.id);
      }
      if (this._clipboard.isTypeSection && this._clipboard.isCutType) {
        return this.pasteSection(contentBelow, this._clipboard.content as SectionContent,
          parentId, orderIndex !== null, below, Constants.CONTENT_CLIPBOARD_CUT, pasteType, notClearPlannedTimeOnCutPaste);
      } else
      if (this._clipboard.isTypeSection && this._clipboard.isCopyType) {
        return this.pasteSection(contentBelow, this._clipboard.content as SectionContent,
          parentId, orderIndex !== null, below, Constants.CONTENT_CLIPBOARD_COPY, pasteType);
      }
    } else {
      if (this._clipboard.content && this._clipboard.isCutType) {
        if (this._clipboard.content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
          return this.contentContainerService.relocateTo(this._clipboard.content as ContentContainer,
            {
              eventId: this.timeLineService.timelineEvent.eventId,
              parentId: contentBelow.id,
              orderIndex: null
            })
            .catch(error => this.throwError(error));
        } else {
          return this.dataService.updateContentPosition(this.timeLineService.timelineEvent.eventId,
            this._clipboard.content, contentBelow.id, null, {}, true)
            .then(() => {
            return this.showPopMessageSuccess();
          }).catch(error => {
            vm.throwError(error);
          });
        }
      } else
      if (this._clipboard.content && this._clipboard.isCopyType) {
        this.common.showProgress.next(true);
        // only the presenter can copy the section, therefore we put down the sign "isPresenterContent = true"
        this._clipboard.content.isPresenterContent = true;
        return this.createCopyContent(this._clipboard.content,
          this.timeLineService.timelineEvent.eventId, contentBelow.id, null, contentBelow.education, false)
          .then(() => {
            this._clipboard.copyContent = null;
            this.common.showProgress.next(false);
            return this.showPopMessageSuccess();
        }).catch(error => {
            this.common.showProgress.next(false);
            this.throwError(error);
        });
      } else {
        return Constants.BREAK_PROCESS;
      }
    }
  }

  public checkPasteObjectIntoItself(contentBelow: AbstractContent, below) {
    let afterContent = null;
    if (contentBelow.isTypeSection) {
      if (!below && (!contentBelow['items'] || contentBelow['items'].length === 0)) {
        return;
      }
      afterContent = below ?
        this.getAfterPasteContent(contentBelow.id, true) :
        this.getAfterPasteContent(contentBelow.id, false);
    } else {
      afterContent = this.getAfterPasteContent(contentBelow.id);
    }
    if (afterContent && afterContent.id === this._clipboard.content.id &&
      (contentBelow.parentId === this._clipboard.content.parentId ||
        this._clipboard.content.parentId === contentBelow.id)) {
      const dayCopy = this.utils.getDateDay((this._clipboard.content as SectionContent).plannedTime);
      const srcDay = this.timeLineService.planeListContent[this._clipboard.content.id] ?
        this.utils.getDateDay(this.timeLineService.planeListContent[this._clipboard.content.id].sectionContent.plannedTime) : dayCopy;
      if (!dayCopy || (!!dayCopy && dayCopy === srcDay)) {
        return Constants.BREAK_PROCESS;
      }
    }
  }

  public getMoveToDay(contentBelow) {
    let cs = contentBelow;
    while (!cs.plannedTime && !cs.isRoot) {
      const csp = this.timeLineService.planeListContent[cs.parentId];
      if (csp) {
        cs = csp.sectionContent;
      }
    }
    return !cs.isRoot ? this.utils.getDateDay(cs.plannedTime) : null;
  }
  public updateMoveSectionList(contentBelow, list: SectionTimeline[]) {
    const toDay = this.getMoveToDay(contentBelow);
    const fwd = list.find(s => !s.sectionTimeIsNotActive && !!s.plannedTime);
    if (fwd) {
      const deltaDay = toDay - fwd.plannedDay();
      if (deltaDay === 0) {
        return;
      }
      list.filter(s => !s.sectionTimeIsNotActive)
        .forEach(s => {
          const section = this.timeLineService.getSectionContent(s.id);
          if (section && section.plannedTime === s.plannedTime) {
            if (s.plannedTime !== null && s.plannedTime !== undefined) {
              s.plannedTime += deltaDay;
            } else {
              s.plannedTime = null;
            }
            if (s.plannedTimeFixed) {
              s.plannedTimeFixedValue += deltaDay;
            } else {
              s.plannedTimeFixedValue = null;
            }
            if (s.endTime !== null && s.endTime !== undefined) {
              s.endTime += deltaDay;
            } else {
              s.endTime = null;
            }
            if (s.endTimeFixed) {
              s.endTimeFixedValue += deltaDay;
            } else {
              s.endTimeFixedValue = null;
            }
          }
        });
    }
  }

  private pasteSection(contentBelow: IAbstractContent, pasteSection: SectionContent,
                       parentId: string, calcIncrement: boolean, below: boolean,
               copyType, pasteType: EMULATE_RESULT, notClearPlannedTimeOnCutPaste = false) {
    const drmFilterIds = this.drmService
      .drmListFilter(this._clipboard.moveSectionList.map(o => o instanceof SectionTimeline ? o : o['sectionContent']))
      .map(o => o.id);
    const moveList = this._clipboard.moveSectionList.filter(o => drmFilterIds.includes(o.id)).map(o => cloneDeep(o));
    this.updateMoveSectionList(contentBelow, moveList.map(o => o instanceof SectionTimeline ? o : o['sectionContent']));
    let startIndex = null;
    let changeOnlyParent = false;
    let startCalcIncrementId = contentBelow.id;
    const sectionEventPhaseChanger: SectionEventPhaseChanger = {
      change: pasteSection.sectionEventPhase !== contentBelow.sectionEventPhase,
      phase: contentBelow.sectionEventPhase ? contentBelow.sectionEventPhase : null};
    if (!calcIncrement || (contentBelow.isTypeSection && below)) {
      const listTreebelow = this.timeLineService.childTreeInLineList(contentBelow.id);
      // check pasteSection is last child or single child in contentBelow childs tree.
      // if it's true that pasteSection need change only parent.
      if (pasteSection.eventId === this.timeLineService.timelineEvent.eventId && copyType === Constants.CONTENT_CLIPBOARD_CUT &&
        contentBelow.isTypeSection && contentBelow.items.length > 0 &&
        ((contentBelow.items.length === 1 && pasteSection.id === contentBelow.items[0]['id']) ||
          pasteSection.id === contentBelow.items[contentBelow.items.length - 1]['id'])) {
        changeOnlyParent = true;
      } else {
        // get last section in child tree of contentBelow
        startIndex = listTreebelow[listTreebelow.length - 1][Constants.ORDERINDEX];
        startCalcIncrementId = listTreebelow[listTreebelow.length - 1]['id'];
      }
    }
    if (!changeOnlyParent) {
      const nextOrderIndex = (calcIncrement && !contentBelow.isTypeSection) ||
      (calcIncrement && contentBelow.isTypeSection && !below) ? contentBelow.orderIndex : startIndex;
      // calcIncrement=false only if pasting to very last section of timeline
      const increment = calcIncrement ?
        this.timeLineService.getOrderIndexIncrement(startCalcIncrementId, moveList.length) :
        Constants.ORDER_INDEX_INCREMENT;
      this.common.showProgress.next(true);
      const isEducationCopy = this.loginService.educationMode ? true :
        (!contentBelow.isRoot ? contentBelow.education : this.timeLineService.timelineSlotMode.getValue());
      return this.sectionListCopyPaste(pasteSection.eventId, moveList, parentId, nextOrderIndex, increment, copyType,
        this.timeLineService.timelineEvent.eventId, isEducationCopy, sectionEventPhaseChanger, pasteType, notClearPlannedTimeOnCutPaste)
        .then(async () => {
          // waiting for information about all sections to arrive
          return await firstValueFrom(this.timeLineService.sections$.pipe(debounceTime(moveList.length * 100)))
            .then(() => {
              this.common.showProgress.next(false);
              this.showPopMessageSuccess();
            }).catch(err => {
              this.common.showProgress.next(false);
              this.throwError(err);
            });
        })
        .catch(err => {
          this.common.showProgress.next(false);
          this.throwError(err);
        });
    } else {
      // call only for current event. not call when copy/paste between events.
      // call if paste into very end of sections tree.
      return new Promise<any>((resolve, reject) => {
        if (copyType === Constants.CONTENT_CLIPBOARD_CUT) {
          const allPromise = [];
          for (let i = 0; i < moveList.length; i++) {
            const section = moveList[i].sectionContent;
            const pasteParam = i > 0 && pasteType === EMULATE_RESULT.WITH_START_WITHOUT_DURATION_WITHOUT_END ?
              EMULATE_RESULT.WITHOUT_DURATION : pasteType;
            const parentIsContainer = this.timeLineService.planeFullListContentWithoutFixed[pasteSection.id].container;
            const clearTimeObject = parentIsContainer ?
              this.clearTimeObjectAsNull : this.getPasteClearTimeLineObject(pasteParam, section);
            let params = sectionEventPhaseChanger.change ? {sectionEventPhase: sectionEventPhaseChanger.phase} : {};
            params = merge(params, !notClearPlannedTimeOnCutPaste ? clearTimeObject : {});
            params['education'] = contentBelow.education;
            allPromise.push(this.dataService.updateContentPosition(this.timeLineService.timelineEvent.eventId,
              section, i === 0 ? parentId : section.parentId, section.orderIndex, params));
          }
          return Promise.all(allPromise).then(r => {
            if (r.every(res => res !== Constants.BREAK_PROCESS)) {
              this.showPopMessageSuccess();
              resolve(r);
            } else {
              this.common.showProgress.next(false);
              throw new Error('unregistered type content');
            }
          }).catch(error => {
            this.common.showProgress.next(false);
            this.throwError(error);
          });
        }
      });
    }
  }


  addSection(parentSection, addAsWithin, sectionTypeId, currentUser: AppUser, isPresenter: boolean, sectionTypeList: any[],
             dialog: MatDialog, isMedia: boolean, sectionEventPhase: SECTION_EVENT_PHASE, isInstantGroup: boolean, object?) {
    const getSrcPlaneById = (id): PlaneContent => this.timeLineService.planeFullListContent[id];
    /**
     * Before - max from all right childs.(within mode)
     * @param item
     * @returns {any}
     */
    const getBeforeOrderIndexWithin = (item) => {
      if (!item) {return null; }
      if (!getSrcPlaneById(item.id) || !getSrcPlaneById(item.id).items) { return item; }
      const itemsList = getSrcPlaneById(item.id).items;
      itemsList.sort(this.utils.comparator(Constants.ORDERINDEX));
      return itemsList.length === 0 ? item : itemsList[itemsList.length - 1];
    };

    /**
     * New logic. Content add below after selected section at current level.
     * Before - max from all right childs.
     * @param item
     * @returns {any}
     */
    const getBeforeOrderIndex = (item) => !item ? null : getSrcPlaneById(item.id);

    const getStartIndex = (item, within: boolean) => {
      let startObj = within ? getBeforeOrderIndexWithin(item) : getBeforeOrderIndex(item);
      while (startObj['items'] && startObj['items'].length > 0) {
        startObj = startObj['items'][startObj['items'].length - 1];
        startObj = getBeforeOrderIndex(startObj);
      }
      return startObj;
    };

    /**
     * Before + 1 in global sorted contents.
     * @param item
     * @returns {any}
     */
    const getAfterOrderIndex = (item) => {
      if (!item) {return null; }
      const sortedPlaneList = this.timeLineService.getPlaneFullListContentWithoutFixedAsSortedArray();
      sortedPlaneList.sort(this.utils.comparator(Constants.ORDERINDEX));
      const myIndex = sortedPlaneList.findIndex(elem => item.id === elem['id']);
      if (myIndex === -1 || myIndex === sortedPlaneList.length - 1) {
        return null;
      } else {
        const afterObj = sortedPlaneList[myIndex + 1];
        return afterObj[Constants.ORDERINDEX];
      }
    };

    const getOrderIndex = (item, within: boolean) => {
      if (item.isRoot && within) {
        const before = item[Constants.ORDERINDEX];
        const planeListContentSorted = this.timeLineService.getPlaneListContentSortedWithoutFixed();
        const after = planeListContentSorted[1] ? planeListContentSorted[1][Constants.ORDERINDEX] : null;
        if (!after) {
          return null;
        }
        return before + ((after - before) / 2);
      } else {
        const beforeObj = getStartIndex(item, within);
        const before = beforeObj[Constants.ORDERINDEX];
        const after = getAfterOrderIndex(beforeObj);
        if (!after) {
          return null;
        } else {
          return before + ((after - before) / 2);
        }
      }
    };

    const getOrderIndexIfAddAsWithin = (item) => {
      const incrementOrderIndex =
        this.timeLineService.getOrderIndexIncrement(item.id, 1);
      return incrementOrderIndex ? (item.orderIndex + incrementOrderIndex) : null;
    };
    const obj = {
      expand: true,
      sectionTypeId: sectionTypeId,
      isPublic: true,
      parentId: addAsWithin ? parentSection.id : parentSection.parentId,
      orderIndex: addAsWithin ? getOrderIndexIfAddAsWithin(parentSection) : getOrderIndex(parentSection, addAsWithin),
      sectionEventPhase: !sectionEventPhase ? parentSection.sectionEventPhase : sectionEventPhase
    };
    if (object && object.hasOwnProperty('title')) {
      obj['title'] = object['title'];
    }
    if (object && object.hasOwnProperty('users')) {
      obj['users'] = object['users'];
    }
    if (object && object.hasOwnProperty('isPublic')) {
      obj['isPublic'] = object['isPublic'];
    }
    if (object && object.hasOwnProperty('freeSlotType')) {
      obj['freeSlotType'] = object['freeSlotType'];
    }
    if (object && object.hasOwnProperty('duration') &&
        object.hasOwnProperty('durationFixed') && object.hasOwnProperty('durationFixedValue')) {
      obj['duration'] = object['duration'];
      obj['durationFixed'] = object['durationFixed'];
      obj['durationFixedValue'] = object['durationFixedValue'];
    }
    if (isInstantGroup) {
      obj['isInstantGroup'] = true;
      obj['container'] = true;
      obj['title'] = this.common.utils.i18n('instant.group');
    }
    if (object && object.hasOwnProperty('isSelfLearning')) {
      obj['isSelfLearningSection'] = object['isSelfLearning'];
    }
    if (obj.sectionEventPhase && (!object || (object && !object.hasOwnProperty('sectionTimeIsNotActive')))) {
      obj['sectionTimeIsNotActive'] = true;
    } else if (object && object.hasOwnProperty('sectionTimeIsNotActive')) {
      obj['sectionTimeIsNotActive'] = object['sectionTimeIsNotActive'];
    }
    if (object && object.hasOwnProperty('virtualConferenceSettings')) {
      obj['virtualConferenceSettings'] = object['virtualConferenceSettings'];
    }
    const params = object?.params;
    const sectionContent = new SectionContent(obj);
    return this.editSection(sectionContent as SectionTimeline, currentUser, isPresenter,
      sectionTypeList, dialog, isMedia, isInstantGroup, params);
  }

  private async checkUserHasDRMRightsToDeleteSection(section: SectionTimeline) {
    const list = this.timeLineService.childTreeInLineList(section.id);
    for (const obj of list) {
      if (!this.drmService.checkDRMRights(obj.sectionContent).canDelete) {
        return false;
      }
      const hasRights = await this.dataService.checkUserHasDRMRightsToDeleteSectionContents(section);
      if (!hasRights) {
        return false;
      }
    }
    return true;
  }

  async deleteSectionWithConfirm(section: SectionTimeline, deleteType: 'this'|'subcontents'|'suball') {
    const vm = this;
    let dialogBody;
    switch (deleteType) {
      case 'this':
        dialogBody = this.common.i18n(!section.isAttached() ?
          'edit_dialog.confirm_remove_section_dialog.body.all' :
          'edit_dialog.confirm_detach_section_dialog.body.all');
        break;
      case 'subcontents':
        dialogBody = this.common.i18n('edit_dialog.confirm_remove_section_dialog.body.allsubcontens');
        break;
      case 'suball':
        dialogBody = this.common.i18n('edit_dialog.confirm_remove_section_dialog.body.allsub');
        break;
    }
    const checkDRM = await this.checkUserHasDRMRightsToDeleteSection(section);
    if (!checkDRM) {
      this.common.showPopupError(this.common.i18n('drm.mode.error.not.has.rights'));
      return;
    }
    this.common.confirm(this.common.i18n('edit_dialog.confirm_dialog.header'), dialogBody).pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        this.common.showProgress.next(true);
        this.timeLineService.updateMangeTimeObjectsBlocked = true;
        // get params for navigate after delete section.
        const parent = this.timeLineService.planeListContent[section.parentId];
        const parentItems = parent.items;
        const indexInParent = parentItems.findIndex(it => it.id === section.id);
        const items = parentItems.filter(it => it.id !== section.id);
        const navigateId = items.length > 0 ? parentItems[indexInParent === 0 ? indexInParent + 1 : indexInParent - 1]?.id : parent.id;
        this.deleteSection(this.timeLineService.timelineEvent.eventId, section, deleteType).then(() => {
          if (this.loginService.educationMode) {
            this.educationService.calculateEducationDurationFieldsValuesAfterDelete(section.id, section.parentId);
          }
          // navigate after delete section.
          const onlyMarkAsDelete = section.education && this.timeLineService.event.slotMode &&
            this.timeLineService.timelineSlotMode.getValue() && !section.deleted;
          const selectedId = this.timeLineService.selectedSection?.id;
          if (selectedId === section.id && deleteType === 'this' && !onlyMarkAsDelete) {
            this.router.navigate([], {queryParams: {sid: navigateId}, relativeTo: this.activatedRoute});
          }
          vm.timeLineService.updateMangeTimeObjectsBlocked = false;
          vm.common.showProgress.next(false);
        }).catch(function (error) {
          vm.timeLineService.updateMangeTimeObjectsBlocked = false;
          vm.common.showProgress.next(false);
          vm.throwError(error);
        });
      }
    });
  }

  pasteContent(contentBelowAbove: IAbstractContent, childContentListSrc: any[], above) {
    if (!contentBelowAbove) {return Promise.resolve(); }
    let orderIndex = this.getOrderIndexForPasteInsert(contentBelowAbove.id, childContentListSrc, above);
    if ((orderIndex === null || orderIndex === undefined) && !isEmpty(childContentListSrc)) {
      orderIndex = childContentListSrc
        .sort(this.utils.comparator(Constants.ORDERINDEX))[childContentListSrc.length - 1].orderIndex + Constants.ORDER_INDEX_INCREMENT;
    }
    if (this._clipboard.content && this._clipboard.isCutType) {
      if (this._clipboard.content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
        return this.contentContainerService.relocateTo(this._clipboard.content as ContentContainer,
          {
            eventId: this.timeLineService.timelineEvent.eventId,
            parentId: (contentBelowAbove.secondParentId ? contentBelowAbove.secondParentId : contentBelowAbove.parentId),
            orderIndex: orderIndex
          })
          .catch(error => this.throwError(error));
      } else {
        const moveToNewPath = this._clipboard.content.parentId !== (contentBelowAbove.secondParentId ?
          contentBelowAbove.secondParentId : contentBelowAbove.parentId);
        const params = this._clipboard.content.isTypeSection ? {education: contentBelowAbove.education} : {};
        return this.dataService.updateContentPosition(this.timeLineService.timelineEvent.eventId,
          this._clipboard.content, (contentBelowAbove.secondParentId ?
            contentBelowAbove.secondParentId : contentBelowAbove.parentId), orderIndex, params, moveToNewPath)
          .then(() => {
          this._clipboard.copyContent = null;
          return this.showPopMessageSuccess();
        }).catch(error => this.throwError(error));
      }
    } else
    if (this._clipboard.content && this._clipboard.isCopyType) {
      this.common.showProgress.next(true);
      // only the presenter can copy the section, therefore we put down the sign "isPresenterContent = true"
      this._clipboard.content.isPresenterContent = true;
      return this.createCopyContent(this._clipboard.content, this.timeLineService.timelineEvent.eventId,
        (contentBelowAbove.secondParentId ?
          contentBelowAbove.secondParentId : contentBelowAbove.parentId), orderIndex, contentBelowAbove.education, false)
        .then(() => {
          this._clipboard.copyContent = null;
          this.common.showProgress.next(false);
          return this.showPopMessageSuccess();
        }).catch(error => {
        this._clipboard.removeFromClipboardHistory(this._clipboard.content?.id);
        this.common.showProgress.next(false);
        this.throwError(error);
      });
    }
  }

  getOrderIndexForPasteInsert(contentId, childContentListSrc: any[], above): number {
    const childContentSortedList: any[] = childContentListSrc.sort(this.utils.comparator(Constants.ORDERINDEX));
    const contentBelowIndex = childContentSortedList.findIndex(function (item) {
      return item.id === contentId;
    });
    if (contentBelowIndex === -1) {
      // maybe content is section, try know about it
      const sObj = this.timeLineService.planeListContent[contentId];
      if (!sObj) {return; }
      if (isEmpty(childContentListSrc)) {return; }
      const last = childContentListSrc[childContentListSrc.length - 1];
      return last.orderIndex + Constants.ORDER_INDEX_INCREMENT;
    }
    // if insert below orderIndex initialize as null server side set this params, else get value less than the very first orderIndex
    let orderIndex = !above ? null : childContentSortedList[0][Constants.ORDERINDEX] / 2;
    if (!above && contentBelowIndex !== childContentSortedList.length - 1) {
      orderIndex = childContentSortedList[contentBelowIndex][Constants.ORDERINDEX] +
        ((childContentSortedList[contentBelowIndex + 1][Constants.ORDERINDEX] -
          childContentSortedList[contentBelowIndex][Constants.ORDERINDEX]) / 2);
    } else
    if (above && contentBelowIndex !== 0) {
      orderIndex = childContentSortedList[contentBelowIndex - 1][Constants.ORDERINDEX] +
        ((childContentSortedList[contentBelowIndex][Constants.ORDERINDEX] -
          childContentSortedList[contentBelowIndex - 1][Constants.ORDERINDEX]) / 2);
    }
    return orderIndex;
  }

  emulatePasteProcess(contentBelow: IAbstractContent, below, outputCheckError?: (value) => any): EMULATE_RESULT  {
    if (this.loginService.educationMode) {
      return EMULATE_RESULT.AS_IS;
    }
    const hasEventTP = (object) => Object.keys(object).some(key => key.includes('-'));
    const needFixedDayStartTime = () => !!this._clipboard.daySection;
    let checkError;
    try {
      if (!this._clipboard.isTypeSection || this.timeLineService.timelineSlotMode.getValue()) {
        return EMULATE_RESULT.AS_IS;
      }
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, contentBelow, below);
      if (!needFixedDayStartTime()) {
        if (!emulator.runEmulator(EMULATE_RESULT.AS_IS)) {
          return EMULATE_RESULT.STOP;
        }
      } else {
        if (!emulator.runEmulator(EMULATE_RESULT.AS_IS_WITH_FIXED_START_BASE_ON_PREV_SECTION)) {
          return EMULATE_RESULT.STOP;
        }
        checkError = this.manageTimeService.checkCorrectTimeMapPoints(
          this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
          this.manageTimeService.testManageTimeMap);
        if (outputCheckError) {
          outputCheckError(checkError);
        }
        if (checkError.noError) {
          return EMULATE_RESULT.AS_IS_WITH_FIXED_START_BASE_ON_PREV_SECTION;
        }
        if (!emulator.runEmulator(EMULATE_RESULT.WITH_FIXED_START_AND_DURATION_BASE_ON_PREV_SECTION)) {
          return EMULATE_RESULT.STOP;
        }
      }
      checkError = this.manageTimeService.checkCorrectTimeMapPoints(
        this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
        this.manageTimeService.testManageTimeMap);
      if (outputCheckError) {
        outputCheckError(checkError);
      }
      if (!needFixedDayStartTime()) {
        if (!checkError.noError) {
          if (!emulator.runEmulator(EMULATE_RESULT.WITHOUT_START_END)) {
            return EMULATE_RESULT.STOP;
          }
          checkError = this.manageTimeService.checkCorrectTimeMapPoints(
            this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
            this.manageTimeService.testManageTimeMap);
          if (outputCheckError) {
            outputCheckError(checkError);
          }

          if (!checkError.noError) {
            if (this._clipboard.isCutType || (this._clipboard.isCopyType && !hasEventTP(checkError.errorTimePoints))) {
              if (!emulator.runEmulator(EMULATE_RESULT.WITHOUT_DURATION)) {
                return EMULATE_RESULT.STOP;
              }
              checkError = this.manageTimeService.checkCorrectTimeMapPoints(
                this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
                this.manageTimeService.testManageTimeMap);
              if (outputCheckError) {
                outputCheckError(checkError);
              }
              return checkError.noError ? EMULATE_RESULT.WITHOUT_DURATION : EMULATE_RESULT.STOP;
            } else {
              // try extend event
              const id = Object.keys(checkError.errorTimePoints)[0];
              const eventTP: TimePoint = checkError.errorTimePoints[id];
              const event = cloneDeep(this.timeLineService.timelineEvent);
              if (!contentBelow.sectionEventPhase) {
                event.durationFixed = false;
                event.endDateFixed = false;
              } else if (contentBelow.sectionEventPhase === SECTION_EVENT_PHASE.PREP) {
                event.prepPhaseDurationFixed = false;
                event.prepPhaseEndFixed = false;
              } else if (contentBelow.sectionEventPhase === SECTION_EVENT_PHASE.WRAP_UP) {
                event.wrapUpPhaseDurationFixed = false;
                event.wrapUpPhaseEndFixed = false;
              }
              if (!emulator.runEmulator(EMULATE_RESULT.WITHOUT_START_END, event)) {
                return EMULATE_RESULT.STOP;
              }
              checkError = this.manageTimeService.checkCorrectTimeMapPoints(
                this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
                this.manageTimeService.testManageTimeMap);
              if (outputCheckError) {
                outputCheckError(checkError);
              }
              if (!checkError.noError) {
                return EMULATE_RESULT.WITHOUT_DURATION;
              } else {
                return EMULATE_RESULT.WITHOUT_START_END_WITH_EXTEND;
              }
            }
          } else {
            return EMULATE_RESULT.WITHOUT_START_END;
          }
        } else {
          return EMULATE_RESULT.AS_IS;
        }
      } else {
        if (!checkError.noError) {
          if (!emulator.runEmulator(EMULATE_RESULT.WITH_FIXED_START_BASE_ON_PREV_SECTION)) {
            return EMULATE_RESULT.STOP;
          }
          checkError = this.manageTimeService.checkCorrectTimeMapPoints(this._clipboard.moveSectionList,
            this.manageTimeService.testManageTimeMap);
          if (outputCheckError) {
            outputCheckError(checkError);
          }
          return checkError.noError ? EMULATE_RESULT.WITH_FIXED_START_BASE_ON_PREV_SECTION : EMULATE_RESULT.STOP;
        } else {
          return EMULATE_RESULT.WITH_FIXED_START_AND_DURATION_BASE_ON_PREV_SECTION;
        }
      }
    } catch (e) {
      this._clipboard.copyContent = null;
      this.common.log.error(e);
      return null;
    }
  }

  emulateMoveSectionTo(contentBelow, below, outputCheckError?: (value) => any): EMULATE_RESULT  {
    try {
      if (!this._clipboard.isTypeSection) {
        return EMULATE_RESULT.AS_IS;
      }
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, contentBelow, below);
      if (!emulator.runEmulator(EMULATE_RESULT.AS_IS)) {
        return EMULATE_RESULT.STOP;
      }
      let checkError = this.manageTimeService.checkCorrectTimeMapPoints(
        this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
        this.manageTimeService.testManageTimeMap);
      if (outputCheckError) {
        outputCheckError(checkError);
      }

      if (!checkError.noError) {
        if (!emulator.runEmulator(EMULATE_RESULT.WITH_START_WITHOUT_DURATION_WITHOUT_END)) {
          return EMULATE_RESULT.STOP;
        }
        checkError = this.manageTimeService.checkCorrectTimeMapPoints(
          this._clipboard.isCutType ? this._clipboard.moveSectionList : emulator.testCopyList,
          this.manageTimeService.testManageTimeMap);
        if (outputCheckError) {
          outputCheckError(checkError);
        }
        if (!checkError.noError) {
          return EMULATE_RESULT.STOP;
        } else {
          return EMULATE_RESULT.WITH_START_WITHOUT_DURATION_WITHOUT_END;
        }
      } else {
        return EMULATE_RESULT.AS_IS;
      }
    } catch (e) {
      this._clipboard.copyContent = null;
      this.common.log.error(e);
      return null;
    }
  }

  emulateChangeProcess(sectionList: PlaneContent[], event: Event, outError: (value) => any): EMULATE_RESULT  {
    try {
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, null, null);
      const checkList = sectionList.map(o => cloneDeep(o));
      emulator.runChangeEmulator(EMULATE_RESULT.AS_IS, checkList, event);
      let checkError = this.manageTimeService.checkCorrectTimeMapPoints(checkList, this.manageTimeService.testManageTimeMap);
      if (!checkError.noError) {
        emulator.runChangeEmulator(EMULATE_RESULT.WITHOUT_DURATION_WITH_START_END, checkList, event);
        checkError = this.manageTimeService.checkCorrectTimeMapPoints(checkList, this.manageTimeService.testManageTimeMap);
        if (!checkError.noError) {
          outError(checkError);
          return EMULATE_RESULT.STOP;
        } else {
          checkList.forEach(o => {
            const index = sectionList.findIndex(s => s.id === o.id);
            if (index > -1) {
              sectionList[index] = cloneDeep(o);
            }
          });
          return EMULATE_RESULT.WITHOUT_DURATION_WITH_START_END;
        }
      } else {
        checkList.forEach(o => {
          const index = sectionList.findIndex(s => s.id === o.id);
          if (index > -1) {
            sectionList[index] = cloneDeep(o);
          }
        });
        return EMULATE_RESULT.WITHOUT_START_END;
      }
    } catch (e) {
      this.common.log.error(e);
      return null;
    }
  }

  emulateListChangeProcess(sectionList: PlaneContent[], event: Event, outError: (value) => any): EMULATE_RESULT  {
    try {
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, null, null);
      emulator.runChangeEmulator(EMULATE_RESULT.AS_IS, sectionList, event);
      const checkError = this.manageTimeService.checkCorrectTimeMapPoints(sectionList, this.manageTimeService.testManageTimeMap);
      if (!checkError.noError) {
          outError(checkError);
          return EMULATE_RESULT.STOP;
      } else {
        return EMULATE_RESULT.AS_IS;
      }
    } catch (e) {
      this.common.log.error(e);
      return null;
    }
  }

  emulateListChangeStartTimeProcess(sectionList: PlaneContent[], event: Event): TimeLineEmulator {
    try {
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, null, null);
      emulator.runChangeEmulator(EMULATE_RESULT.AS_IS, sectionList, event);
      return emulator;
    } catch (e) {
      this.common.log.error(e);
      return null;
    }
  }

  emulatePasteWithChangedStartTimeAsIs(contentBelow: SectionContent, below): TimeLineEmulator {
    try {
      if (!this._clipboard.isTypeSection) {
        return null;
      }
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, contentBelow, below);
      if (!emulator.runEmulator(EMULATE_RESULT.AS_IS)) {
        return null;
      }
      return emulator;
    } catch (e) {
      this._clipboard.copyContent = null;
      this.common.log.error(e);
      return null;
    }
  }

  emulateAnyListChangeDurationProcess(sectionList: PlaneContent[], event: Event, outError: (value) => any): EMULATE_RESULT  {
    try {
      const emulator = new TimeLineEmulator(this.timeLineService, this.manageTimeService,
        this, this.utils, this.loginService.getAppUser(), true, null, null);
      emulator.runChangeEmulator(EMULATE_RESULT.AS_IS, sectionList, event);
      const checkError = this.manageTimeService.checkCorrectTimeMapPoints(sectionList, this.manageTimeService.testManageTimeMap, true);
      if (!checkError.noError) {
        outError(checkError);
        return EMULATE_RESULT.STOP;
      } else {
        return EMULATE_RESULT.AS_IS;
      }
    } catch (e) {
      this.common.log.error(e);
      return null;
    }
  }

  /**
   * Returning content that will follow after inserting the current content.
   * @param {number} itemId
   * @param {{}} timeLineWithParentList
   * @param {boolean} below
   * @param {number} excludeId
   * @returns {null}
   */
  private getAfterPasteContent(sectionId: string, below?: boolean, excludeId?: string) {

    const getAfterContent = (itemId, offset = 0) => {
      if (!itemId) {return null; }
      const sortedPlaneList = this.timeLineService.getPlaneFullListContentWithoutFixedAsSortedArray();
      const myIndex = sortedPlaneList.findIndex(elem => itemId === elem['id']);
      if (myIndex === -1 || myIndex === sortedPlaneList.length - 1) {
        return null;
      } else {
        let afterObj = sortedPlaneList[myIndex + 1];
        if (offset > 0 && sortedPlaneList[myIndex + 1 + offset]) {
          afterObj = sortedPlaneList[myIndex + 1 + offset];
        } else
        if (offset > 0 && !sortedPlaneList[myIndex + 1 + offset]) {
          afterObj = null;
        }
        return afterObj;
      }
    };

    if (!sectionId) {return null; }
    let localItemId = sectionId;
    let localItemIdBefore = sectionId;
    if (below) {
      const objList = this.timeLineService.childTreeInLineList(sectionId);
      if (objList.length > 0) {
        if (objList.length > 1 && excludeId && objList[objList.length - 1].id === excludeId) {
          localItemIdBefore = objList[objList.length - 2].id;
          localItemId = objList[objList.length - 1].id;
        } else {
          localItemIdBefore = objList[objList.length - 1].id;
          localItemId = objList[objList.length - 1].id;
        }
      }
    }
    return getAfterContent(localItemId, excludeId && excludeId.length > 0 ? 1 : 0);
  }
}
