import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    // Total seconds by rounding to nearest integer value
    const totalSeconds = Math.floor(value);

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Format minutes and seconds to always be two digits
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');

    if (hours > 0) {
      // Format hours to always be two digits and return hh:mm:ss format
      const hoursString = hours.toString().padStart(2, '0');
      return `${hoursString}:${minutesString}:${secondsString}`;
    } else {
      // Return mm:ss format
      return `${minutesString}:${secondsString}`;
    }
  }

}
