import {Injectable} from '@angular/core';
import {catchError, firstValueFrom, map, Observable, tap} from 'rxjs';
import {EventLocation} from '../model/EventLocation';
import {AngularFirestore, QuerySnapshot} from '@angular/fire/compat/firestore';
import {StdApiService} from './std-api-service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {CommonService} from '../core/common.service';
import {EventSupportType} from '../model/EventSupportType';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Tag} from '@ninescopesoft/core';
import {Timestamp} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ReferenceApiService extends StdApiService {

  constructor(
    protected aFirestore: AngularFirestore,
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected common: CommonService,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, common.utils, aFirestore, storage);
  }

  /* locations */
  getLocations(): Observable<EventLocation[]> {
    return this.afs.collection<EventLocation>(EventLocation.DB_PATH)
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        this.log('getLocations'),
        map(res => this.mapArray(res, (it) => new EventLocation(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getLocationsPromise(): Promise<EventLocation[]> {
    return firstValueFrom(this.afs.collection<EventLocation>(EventLocation.DB_PATH)
      .get()
      .pipe(
        this.log('getLocationsPromise'),
        map((res: QuerySnapshot<EventLocation>) => this.mapArray(res.docs, (it) => new EventLocation({_key: it.id, ...it.data()})))
      ))
      .catch((err) => this.throwFirestoreError(err));
  }

  addLocation(location: EventLocation) {
    return this.afs.collection<EventLocation>(EventLocation.DB_PATH).add(location.toObject());
  }

  saveLocation(location: EventLocation) {
    return this.afs
      .collection(EventLocation.DB_PATH)
      .doc(location._key)
      .update(location.toObject());
  }

  deleteLocation(key: string) {
    return this.afs
      .collection(EventLocation.DB_PATH)
      .doc(key)
      .delete();
  }

  /* tags */
  getTags(): Observable<Tag[]> {
    return this.afs.collection<Tag>('event_tags')
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('getTags'),
        map(res => this.mapArray(res, (it) => it as Tag)),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  getTagsPromise(): Promise<Tag[]> {
    return firstValueFrom(this.afs.collection<Tag>('event_tags')
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        this.log('getTagsPromise'),
        tap((res: Tag[]) => res),
      ))
      .catch((err) => this.common.log.error(err));
  }

  addTag(tag: Tag) {
    if (!tag) {
      return Promise.resolve();
    }
    if (!tag.id) {
      tag.id = this.aFirestore.createId();
    }
    return this.afs.collection<Tag>('event_tags').doc(tag.id).set(tag);
  }

  saveTag(tag: Tag) {
    if (!tag) {
      return Promise.resolve();
    }
    tag.updatedAt = Timestamp.now();
    return this.afs
      .collection('event_tags')
      .doc<Tag>(tag.id)
      .update(tag);
  }

  deleteTag(key: string) {
    return this.afs
      .collection('event_tags')
      .doc<Tag>(key)
      .delete();
  }

  /* support types */
  getSupportTypes(): Observable<EventSupportType[]> {
    return this.afs.collection<EventSupportType>(EventSupportType.DB_PATH)
      .valueChanges(this.REFERENCE_ID)
      .pipe(
        this.log('getSupportTypes'),
        map(res => this.mapArray(res, (it) => new EventSupportType(it))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  addSupportType(eventSupportType: EventSupportType) {
    return this.afs.collection<EventSupportType>(EventSupportType.DB_PATH).add(eventSupportType.toObject());
  }

  saveSupportType(eventSupportType: EventSupportType) {
    return this.afs
      .collection(EventSupportType.DB_PATH)
      .doc<EventSupportType>(eventSupportType._key)
      .update(eventSupportType.toObject());
  }

  deleteSupportType(key: string) {
    return this.afs
      .collection(EventSupportType.DB_PATH)
      .doc<EventSupportType>(key)
      .delete();
  }
}
