import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeWhile} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {GoogleAPIService} from '../services/google-api.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {CommonService} from '../core/common.service';
import {LoadingService} from '../core/loading.service';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {LoginService} from '../login/login.service';
import {Constants} from '../core/constants';
import {HttpClient} from '@angular/common/http';
import {SpinnerComponent} from '../components/spinner/spinner.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  standalone: true,
  imports: [
    SpinnerComponent
  ],
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  public alive = true;

  loading: boolean;

  protected gapi: any;

  constructor(private activatedRoute: ActivatedRoute
    , private common: CommonService
    , private store: Store<fromRoot.State>
    , protected googleApiService: GoogleAPIService
    , private loginService: LoginService
    , private loadingService: LoadingService
    , private http: HttpClient
    , private afAuth: AngularFireAuth) {
    this.store.select(fromRoot.getLoading).pipe(
      takeWhile(() => this.alive))
      .subscribe(loading => {
        this.loading = loading;
      });
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe(params => {
        const currentDomain = this.common.localStorage.get('currentDomain', this.common.utils.getEnv().defaultProvider);
        const provider = params['provider'];
        if (provider === 'hkvbs') {
          this.loginBackendHKVBS(currentDomain === provider ? provider : currentDomain);
        }
      });
  }

  ngOnInit() {
    this.gapi = window['gapi'];
  }

  ngOnDestroy() {
    this.alive = false;
  }

  loginBackendHKVBS(provider) {
    const vm = this;
    const onError = function (error) {
      vm.loadingService.loadFinish();
      vm.common.showError(error);
      vm.loginService.redirectToLoginPage();
    };
    this.activatedRoute.queryParamMap.pipe(
      takeWhile(() => this.alive))
      .subscribe(queryParam => {
        this.loadingService.loadBegin();
        const code = queryParam.get('code');
        const state = queryParam.get('session_state');
        const url = this.common.utils.getEnv().BACKEND_BASE + '/authApi/v3/auth';
        Promise.resolve().then(() => {
          const env = this.common.utils.getEnv();
          const api_key = this.common.utils.getEnv().api_key;
          const redirect_uri =
            provider === 'hkvbs' ? env.hkvbs.redirect_uri : env['hkvbs-azure'].redirect_uri;
          let provider_id = this.loginService.getCurrentProviderId();
          if (provider_id === Constants.PROVIDER_HKVBS_MICROSOFT) {
            provider_id = Constants.PROVIDER_HKVBS_AZURE;
          }
          // const redirect_uri = this.common.utils.getEnv().BACKEND_BASE.replace('8080', '4200') + '/auth/hkvbs';
          const res = this.http
            .post(url, {authCode: code, provider: provider_id, redirect_uri: redirect_uri}, {responseType: 'text', params: {key: api_key}})
            .toPromise();
          res.then(result => {
            if (!result) {
              throw new Error(Constants.ERROR_BACKEND_GET_TOKEN);
            }
            this.afAuth.signInWithCustomToken(result).then(u => {
/*
              u.user.getIdTokenResult().then(r => {
                console.log('getIdTokenResult', r);
              });
*/
              this.loadingService.loadFinish();
            }).catch(onError);
          }, onError).catch(onError);
        }, onError).catch(onError);
      });
  }

}
