import {ICaption, ICreator, ILanguageParams, SIZE_OPTION, TCaption} from '../../core/constants';
import {UtilsService} from '../../core/utils.service';
import {
  EXAM_ASSESSMENT_STATUS,
  EXAM_ASSESSMENT_STATUS_COLOR,
  EXAM_CONTENT_STATUS,
  EXAM_CONTENT_STATUS_COLOR,
  EXAM_PROGRESS_STATUS_BACKGROUND_COLOR,
  IAssessmentContentStatistics,
  IAssessmentContentStatisticsMap,
  IExamContentsMap,
  IExamManagers
} from '../exam-constants/exam-constants';
import {ExamService} from '../exam/exam-service/exam.service';
import {CONTAINER_ITEM_TYPE} from '../../model/content/ContentContainer';

export class ExamSection {
  id: string;
  examId: string;
  createTime = new Date().getTime();
  updateTime: number;
  creator: ICreator;
  parentId: string;
  orderIndex = 0;
  title: ICaption;
  description: ICaption;
  enabledLanguages: string[];
  users: IExamManagers;
  permissions: string[]; // users id list access to sections
  maxPoints = 0;
  contentsCount = 0;
  totalPoints = 0;
  countDone = 0;
  pointsDone = 0;
  countReviewed = 0;
  pointsReviewed = 0;
  countApproved = 0;
  pointsApproved = 0;
  coverImage: string;
  skipMainPage: boolean;
  defaultSizeAdditionContent: SIZE_OPTION = SIZE_OPTION.MEDIUM;

  constructor(obj?) {
    for (const name of Object.keys(obj ?? {})) {
      if (this.classFieldsNames().includes(name)) {
        this[name] = obj[name];
      }
    }
  }

  protected classFieldsNames(): string[] {
    return [
      'id',
      'examId',
      'createTime',
      'updateTime',
      'creator',
      'parentId',
      'orderIndex',
      'title',
      'description',
      'enabledLanguages',
      'coverImage',
      'skipMainPage',
      'defaultSizeAdditionContent',
      'users',
      'permissions',
      'maxPoints',
      'contentsCount',
      'totalPoints',
      'countDone',
      'pointsDone',
      'countReviewed',
      'pointsReviewed',
      'countApproved',
      'pointsApproved'
    ];
  }

  getTitleByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'title', languageParams);
  }

  setTitleByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'title', value, languageParams);
  }

  getDescriptionByLanguage(languageParams: ILanguageParams) {
    return UtilsService.getByLanguage(this, 'description', languageParams);
  }

  setDescriptionByLanguage(value: TCaption, languageParams: ILanguageParams) {
    UtilsService.setByLanguage(this, 'description', value, languageParams);
  }

  toObject() {
    const checkUnsupportedValue = (object) => {
      Object.keys(object)
        .forEach(key => {
          if ((object[key] === undefined || typeof object[key] === 'function')) {
            delete object[key];
          } else if (object[key] === null) {
            object[key] = null;
          } else if (typeof object[key] === 'object') {
            if (typeof object[key]['toObject'] !== 'undefined') {
              object[key] = object[key].toObject();
            } else {
              checkUnsupportedValue(object[key]);
            }
          }
        });
    };
    const obj = {...this};
    delete obj.id;
    if (obj.parentId === obj.examId) {
      delete obj.parentId;
    }
    checkUnsupportedValue(obj);
    obj.permissions = Object.keys(this.users || {});
    return obj;
  }
}

export class ExamSectionTreeItem extends ExamSection {
  items: ExamSectionTreeItem[] = [];
  backgroundAutoAssessed: string;
  backgroundDone: string; // or assessed
  backgroundReviewed: string;
  backgroundApproved: string;
  borderColorDone: string;
  borderColorReviewed: string;
  borderColorApproved: string;
  backgroundColorAutoAssessed100: string;
  backgroundColorDone100: string; // or assessed
  backgroundColorReviewed100: string;
  backgroundColorApproved100: string;
  progressAutoAssessed = 0;
  progressDone = 0; // or assessed
  progressReviewed = 0;
  progressApproved = 0;

  constructor(obj) {
    super(obj);
    this.backgroundDone = this.htmlPercentBackground(this.pointsDone,
      EXAM_CONTENT_STATUS_COLOR[EXAM_CONTENT_STATUS.DONE], EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.DONE],
      (progress) => this.progressDone = progress);
    this.backgroundReviewed = this.htmlPercentBackground(this.pointsReviewed,
      EXAM_CONTENT_STATUS_COLOR[EXAM_CONTENT_STATUS.REVIEWED], EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.REVIEWED],
      (progress) => this.progressReviewed = progress);
    this.backgroundApproved = this.htmlPercentBackground(this.pointsApproved,
      EXAM_CONTENT_STATUS_COLOR[EXAM_CONTENT_STATUS.APPROVED], EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.APPROVED],
      (progress) => this.progressApproved = progress);
    this.borderColorDone = EXAM_CONTENT_STATUS_COLOR[EXAM_CONTENT_STATUS.DONE];
    this.borderColorReviewed = EXAM_CONTENT_STATUS_COLOR[EXAM_CONTENT_STATUS.REVIEWED];
    this.borderColorApproved = EXAM_CONTENT_STATUS_COLOR[EXAM_CONTENT_STATUS.APPROVED];
    this.backgroundColorDone100 = EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.DONE];
    this.backgroundColorReviewed100 = EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.REVIEWED];
    this.backgroundColorApproved100 = EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.APPROVED];
  }

  protected htmlPercentBackground(points: number, color: string, background: string, progressFn: (progress) => void) {
    return ExamService.htmlPercentBackground(points, this.maxPoints, color, background, progressFn);
  }

  calculateAssessmentStatistics(attendeesCount: number,
                                assessmentStatistics: IAssessmentContentStatistics | IAssessmentContentStatisticsMap,
                                examContentsMap: IExamContentsMap,
                                isExamSection: boolean, isInfoSection: boolean) {
    const defaultValues = {autoAssessed: 0, assessed: 0, reviewed: 0, approved: 0};
    let contentsCount = 0;
    // count of contents with contain quiz items
    if (!isExamSection) {
      contentsCount = Object.values(examContentsMap?.[this.id] || {})
        .filter(obj => (obj.items || []).some(it => it.type === CONTAINER_ITEM_TYPE.QUIZ)).length;
    } else {
      contentsCount = Object.keys(examContentsMap ?? {})
        .reduce((acc, sectionId) => acc + Object.values(examContentsMap?.[sectionId] || {})
          .filter(obj => (obj.items || []).some(it => it.type === CONTAINER_ITEM_TYPE.QUIZ)).length, 0);
    }
    const sectionStatistics: IAssessmentContentStatistics =
      !isInfoSection ? ((!isExamSection ? assessmentStatistics?.[this.id] : assessmentStatistics) ?? defaultValues) : defaultValues;

    this.backgroundAutoAssessed = ExamService.htmlPercentBackground(sectionStatistics.autoAssessed, contentsCount * attendeesCount,
      EXAM_ASSESSMENT_STATUS_COLOR[EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED],
      EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.DONE], (progress) => this.progressAutoAssessed = progress);
    this.backgroundColorAutoAssessed100 = EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.DONE];

    this.backgroundDone = ExamService.htmlPercentBackground(sectionStatistics.assessed, contentsCount * attendeesCount,
      EXAM_ASSESSMENT_STATUS_COLOR[EXAM_ASSESSMENT_STATUS.ASSESSED],
      EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.DONE], (progress) => this.progressDone = progress);

    this.backgroundReviewed = ExamService.htmlPercentBackground(sectionStatistics.reviewed, contentsCount * attendeesCount,
      EXAM_ASSESSMENT_STATUS_COLOR[EXAM_ASSESSMENT_STATUS.REVIEWED],
      EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.REVIEWED], (progress) => this.progressReviewed = progress);

    this.backgroundApproved = ExamService.htmlPercentBackground(sectionStatistics.approved, contentsCount * attendeesCount,
      EXAM_ASSESSMENT_STATUS_COLOR[EXAM_ASSESSMENT_STATUS.APPROVED],
      EXAM_PROGRESS_STATUS_BACKGROUND_COLOR[EXAM_CONTENT_STATUS.APPROVED], (progress) => this.progressApproved = progress);
  }
}
