import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {AnswerEquality, EventQuestion, IGapAnswerData} from '../../../../../../../model/EventQuestion';
import {ILanguageParams} from '../../../../../../../core/constants';
import {isEmpty} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';

@Component({
  selector: 'app-question-text-gap-filling-editor',
  templateUrl: './question-text-gap-filling-editor.component.html',
  styleUrls: ['./question-text-gap-filling-editor.component.scss']
})
export class QuestionTextGapFillingEditorComponent extends AbstractQuizQuestionAnswersEditorComponent  {

  gapFillingText$ = new BehaviorSubject<string>(null); // text for update text in groups
  gapFillingText: string; // text for default group

  constructor(protected injector: Injector,
              public changeDetector: ChangeDetectorRef) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.gapFillingText = this.question.getTaskTextByLanguage(this.languageParams);
    this.gapFillingText$.next(this.gapFillingText);
  }

  onGapFillingTextChange(value: string) {
    this.question.taskText = value;
    this.gapFillingText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  onGroupGapAnswersDataChange(value: IGapAnswerData[], groupId: string | 'default') {
    const group = this.question.groupsCorrectAnswers.find(g => groupId === 'default' ? g.defaultGroup : g.id === groupId);
    group.correctEquality = (value || []).map(o => new AnswerEquality({id: o.id, answerId: o.answerId,
      answerValue: this.question.items.find(it => it.id === o.answerId)?.answer ?? null}));
  }

  gapRowAnswerDisabled(rowId: string) {
    return !!(this.question.groupsCorrectAnswers || []).find(g => !!(g.correctEquality || []).find(o => o.answerId === rowId));
  }

  validate(): IValidated {
    return !isEmpty(this.dataSource.data) && !this.dataSource.data.some(o => isEmptyCaption(o.answer)) &&
    !this.question.groupsCorrectAnswers.some(g => g.correctEquality.some(o => !o.answerId)) ?
      {validated: true} : {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }
}
