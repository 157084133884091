import {Injectable} from '@angular/core';
import {AbstractFinalizeService} from '../../service/abstract-finalize-service';
import {ContentContainer} from '../../../../model/content/ContentContainer';
import {FEEDBACK_DIALOG_TYPE} from './quiz-model/quiz';
import {isEmpty} from 'lodash';
import {CommonService} from '../../../../core/common.service';
import {firstValueFrom, interval} from 'rxjs';

interface IFeedback {
  quizId: string;
  message: string;
  type: FEEDBACK_DIALOG_TYPE;
}

@Injectable({
  providedIn: 'root'
})
export class QuizFinalizeServiceService extends AbstractFinalizeService {

  constructor(private common: CommonService) {
    super();
  }

  protected registeredFeedbackList: IFeedback[] = [];

  registerFeedback(quizId: string, message: string, type: FEEDBACK_DIALOG_TYPE) {
    this.registeredFeedbackList.push({quizId: quizId, message: message, type: type});
  }

  async finalize(content: ContentContainer) {
    await firstValueFrom(interval(10));
    if (!isEmpty(this.registeredFeedbackList) && window.location.href.includes('/event/')) {
      const feedbackType = this.registeredFeedbackList.some(o => o.type === FEEDBACK_DIALOG_TYPE.TRY_AGAIN) ?
        FEEDBACK_DIALOG_TYPE.TRY_AGAIN : FEEDBACK_DIALOG_TYPE.CONTINUE;
      const feedbackObj = this.registeredFeedbackList.find(o => o.type === feedbackType);
      const feedbackMessage = this.common.i18n(feedbackObj.message);
      this.registeredFeedbackList = [];
      this.common.disableMainBackground.next(true);
      firstValueFrom(this.common.confirm(feedbackMessage, null, false,
        feedbackType === FEEDBACK_DIALOG_TYPE.TRY_AGAIN ? this.common.i18n('feedback.dialog.try.again') : null,
        feedbackType === FEEDBACK_DIALOG_TYPE.TRY_AGAIN ?
          this.common.i18n('feedback.dialog.continue.anyway') : this.common.i18n('feedback.dialog.continue'),
        feedbackType === FEEDBACK_DIALOG_TYPE.CONTINUE, feedbackType === FEEDBACK_DIALOG_TYPE.CONTINUE))
        .then(async res => {
          if (res) {
            // goto content
            await firstValueFrom(interval(97))
              .then(() => this.common.gotoDocumentByLink({sectionId: content.parentId, contentId: content.id}));
            // try again goto content if selected section is not parent content section
            await firstValueFrom(interval(997))
              .then(() => this.common.gotoDocumentByLink({sectionId: content.parentId, contentId: content.id}));
          }
          this.common.disableMainBackground.next(false);
        });
    }
    this.registeredFeedbackList = [];
  }
}
