import {Component, HostListener, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Constants} from '../../../../core/constants';
import {StdComponent} from '../../../../core/std-component';

@Component({
  selector: 'app-time-select-dialog',
  templateUrl: './time-select-dialog.component.html',
  styleUrls: ['./time-select-dialog.component.scss']
})
export class TimeSelectDialogComponent extends StdComponent implements OnInit {
  hours = 0;
  minutes = 0;
  dateTime: number;

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === Constants.ESCAPE) {
      this.onNoClick();
    } else if (event.code === Constants.ENTER) {
      this.onOkClick();
    }
  }

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<TimeSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    if (data.time) {
      this.dateTime = data.time;
    } else {
      this.dateTime = new Date().getTime();
    }
    const cd = new Date(this.dateTime);
    this.hours = cd.getHours();
    this.minutes = cd.getMinutes();
  }

  ngOnInit() {

  }

  onOkClick(): void {
    const cd = new Date(this.dateTime);
    const rd = new Date(cd.getFullYear(), cd.getMonth(), cd.getDate(), this.hours, this.minutes).getTime();
    this.dialogRef.close({time: rd});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
