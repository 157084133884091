// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import {version} from './appVersion';

export const environment = {
  production: false
  , name: 'DEV'
  , appName: 'Roche Timeline'
  , organizationName: 'Timeline International Inc.'
  , lang: 'en'
  , version: version
  , debug: {
    analytics: true,
    trace: true,
    console: true,
    crashReport: true
  }
  , domains: ['timeline-p10-dev.firebaseapp.com', 'p10-dev.timeline.click', 'localhost:4200']
  , firebase: {
    apiKey: 'AIzaSyBls-J0CsnWuv36bM8YnFoBOhiW7AWVtLg',
    // authDomain: 'timeline-p10-dev.firebaseapp.com',
    authDomain: 'p10-dev.timeline.click',
    databaseURL: 'https://timeline-p10-dev-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'timeline-p10-dev',
    storageBucket: 'timeline-p10-dev.appspot.com',
    // messagingSenderId: '577476569080',
    appId: '1:577476569080:web:19b435bcb060bd918d580d',
    measurementId: 'G-BG6KDZN216'
  }
  // , BACKEND_BASE: 'http://localhost:8080'
  // , BACKEND_BASE: 'https://timeline-p10-dev.appspot.com'
  , BACKEND_BASE: 'https://api.p10-dev.timeline.click'
  , providers: [
      {provider: 'gapi', caption: 'Roche', value: 'roche', disabled: false},
      {provider: 'gapi', caption: 'Genentech', value: 'gene', disabled: false},
      {provider: 'gapi', caption: 'Google/Guest', value: 'other', disabled: false},
      {provider: 'email', caption: 'Email', value: 'email', disabled: true},
      {provider: 'microsoft', caption: 'Microsoft', value: 'microsoft', disabled: false},
    ]
  , defaultProvider: 'other'
  , gapi: {
    client_id: '577476569080-n7t81elrsacfc7ptlnqn1jaac55r9ulu.apps.googleusercontent.com',
    apiKey: 'AIzaSyBls-J0CsnWuv36bM8YnFoBOhiW7AWVtLg',
    scopes: [
      'openid'
      , 'profile'
      , 'email'
    ],
    calendar: {
      enabled: true,
      DISCOVERY_DOCS: [
        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'
      ],
      scopes: [
        'https://www.googleapis.com/auth/calendar.events'
      ]
    },
    geolocation: {
      apiKey: 'AIzaSyAJ2CpceUaTFKoN_AVJqR1fK7brxvE-Uko'
    }
  },
  email: {
    externalUsers: false,
    selfRegistration: false
  },
  hkvbs: {
    API_URL: 'https://auth.hkvbs.ch/connect/authorize',
    client_id: 'timeline_dev',
    // redirect_uri: 'http://localhost:4200/auth/hkvbs',
    redirect_uri: 'https://itc-backend-dev.firebaseapp.com/auth/hkvbs',
    scopes: [
      'openid',
      'email',
      'pirouetteInfo',
      'profile',
      'roles',
      'eDirectory'
    ]
  },
  'hkvbs-azure': {
    API_URL: '',
    client_id: '',
    redirect_uri: '',
    scopes: []
  },
  microsoft: {
    API_URL: '',
    client_id: '15ff4777-2531-406d-bff1-00f5a5133094',
    tenant_id: '',
    redirect_uri: '',
    scopes: []
  },
  api_key: 'AIzaSyCtRfwfpDaor9lg6Za10Nn2iYDqfvdGHiM',
  hiddenMenuItems: {
    'my-time': false
  },
  meeting: {
    event: 'jitsi',
    jitsi: {
      domain: 'meet.timeline.click'
    },
    zoom: {
      meetingClientName: 'env10'
    },
    zoom_sdk: {
      password: 'P93UG8'
    },
    daily: {
      api: 'https://timelinedev.daily.co/'
    },
    doNotCreateMeetingForInstantGroup: false
  },
  features: {
    enableSlotMode: true,
    enableDebug: false,
    hideSelfLearningUsersDetailDefaultValue: false,
    importSlots: true,
    importSlotsUrl: '',
    timelineDaysRange: 31,
    timelineDaysPage: 14,
    myKnowledge: false,
    recordings: false
  },
  companyName: 'Roche',
  permitRocheVideo: true,
  timelineAboutURL: 'https://itc-backend-dev.firebaseapp.com/',
  showTermsAndPrivacy: true,
  enableSocialPresentation: true,
  client_id: '0010'
};
