import {StdComponent} from '../core/std-component';
import {MonoTypeOperatorFunction, tap} from 'rxjs';

export class StdDataService {

  constructor() { }

  takeUntilAlive<T>(component: StdComponent): MonoTypeOperatorFunction<T> {
    if (!component) {
      return tap();
    }
    return component.takeUntilAlive();
  }

  toMap(list: any[]) {
    return list.reduce((accum, item) => {
      accum[item.id] = item;
      return accum;
    }, {});
  }
}
