import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/common.service';
import {IVideoData, VIDEO_TYPES_CLASSES} from '../video-stream/video-stream.component';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {Constants, ILanguageParams, TRIPLE} from '../../../../../core/constants';
import {filter, take} from 'rxjs';
import {UtilsService} from '../../../../../core/utils.service';
import {StdComponent} from '../../../../../core/std-component';

const DEFAULT_VALUES = {
  synchronizePosition: false,
  autoplay: false
};

@Component({
  selector: 'app-video-stream-editor-dialog',
  templateUrl: './video-stream-editor-dialog.component.html',
  styleUrls: ['./video-stream-editor-dialog.component.scss']
})
export class VideoStreamEditorDialogComponent extends StdComponent implements OnInit {

  videoData: IVideoData;
  editDialogData: any;
  showExtendedOptions = false;
  languageParams: ILanguageParams;
  saveDisabled = false;
  dataHash: string;
  isModify = false;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<VideoStreamEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private common: CommonService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.videoData = cloneDeep(data?.videoData ?? DEFAULT_VALUES);
    this.dataHash = UtilsService.md5(UtilsService.jsonSorted(this.videoData));
    this.showExtendedOptions = !!VIDEO_TYPES_CLASSES[this.videoData.type];
    this.data.languageParams$
      .pipe(filter(p => !isEmpty(p)), take(1))
      .subscribe(params => {
        this.languageParams = cloneDeep(params);
        this.setVideoDialogData();
      });
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  setVideoDialogData() {
    this.editDialogData = {
      fields: [
        {
          id: 'url', type: 'text', required: false,
          hinttext: this.common.utils.i18n('text.editor.edit.videos.hint'),
          showClearButton: true
        },
      ]
      , result: {url: UtilsService.getByLanguage(this.videoData, 'url', this.languageParams)}
      , withDelete: false
    };
  }

  ngOnInit(): void {

  }

  dataChange(value: IVideoData) {
    const urlObject = (url) => typeof url === 'string' ? {[this.languageParams.defaultLanguage]: url} : url;
    let videoData = value?.url ? cloneDeep(value) : {type: null, url: null};
    const usedML = this.languageParams?.usedMultilingualContent;
    const langDif = this.languageParams?.defaultLanguage !== this.languageParams?.currentLanguage;
    this.saveDisabled = true;
    if (!usedML) {
      this.videoData =  merge(this.videoData, videoData);
      this.saveDisabled = !this.videoData.url || this.videoData.url === '' || !this.isVideoSiteValid(videoData.url);
    } else {
      videoData = merge(videoData, {url: {[this.languageParams.currentLanguage]: videoData.url}});
      if (!langDif) {
        this.videoData = merge(this.videoData, videoData);
      } else {
        this.videoData.url = merge(urlObject(this.videoData.url), videoData.url);
      }
      const urls = Object.values(this.videoData.url || {});
      const allUrlsNull = urls.every(url => !url || url === '');
      this.saveDisabled = allUrlsNull || !urls.filter(url => !!url)
        .every(url => this.isVideoSiteValid(url));
    }
    this.showExtendedOptions = !!VIDEO_TYPES_CLASSES[this.videoData.type];
    this.checkModify();
  }

  checkModify() {
    this.isModify = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.videoData));
  }

  private isVideoSiteValid(url: string) {
    const videoSite = this.common.utils.getVideoSiteAndVideoIdFromUrl(url, true);
    return videoSite && videoSite.site === this.videoData.type;
  }

  onOkClick(): void {
    this.dialogRef.close(this.videoData ?? {});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onLanguageChange(lang) {
    this.languageParams.currentLanguage = lang;
    this.setVideoDialogData();
  }
}
