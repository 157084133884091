import {Component, EventEmitter, Injector, signal} from '@angular/core';
import {StdComponent} from '../../../core/std-component';
import {BehaviorSubject, Subject} from 'rxjs';
import {IDocumentPathParams} from '../../../services/event-mode-api.service';
import {IMultilingual, IOptionsHash, ISlide, ISlideAction, TUser} from './container-interface';
import {LANGUAGE} from '../../../core/language-constants';
import {IContainerItemOptions} from '../../../model/content/ContentContainer';
import {APP_MODE} from '../../../login/login.service';

@Component({
  template: ''
})
export abstract class AbstractContainerComponent extends StdComponent {

  /**
   * used to get data from outside
   * @param value
   */
  set value(value: any) {
    this.data$.next(value);
  }

  /**
   * used to send data out
   */
  valueChange = new EventEmitter<any>();

  /**
   * calculated hash of data before edit component data. can be compared with hash after edit content and know is content modified
   */
  dataHash: string;

  /**
   * calculated hash of options without data before edit component data.
   */
  optionsHash: IOptionsHash;

  /**
   * component wrapper resizing event in the editor
   * (used e.g. for hide custom component toolbar on resize component).
   */
  isStartResize$: BehaviorSubject<boolean>;

  /**
   * follow me input
   * @param value
   */
  set followMeData(value: any) {
    this.inputFollowMeData(value);
  }

  /**
   * At now time user can manage business processes (e.g. add content, delete content and etc.).
   */
  isEventManager$: BehaviorSubject<boolean>;

  /**
   * opportunity to use participant mode for managers.
   */
  managerUseParticipantMode$: BehaviorSubject<boolean>;

  /**
   * FollowMe status enable/disable.
   * use for manipulate FollowMe custom data inside container
   */
  followMeEnable$: BehaviorSubject<boolean>;

  /**
   * is current user now FollowMe manager(presenter/speaker)
   */
  isFollowMeManager$: BehaviorSubject<boolean>;

  /**
   * Path params for this container to custom data that is used only inside this container.
   */
  documentPathParams: IDocumentPathParams;

  /**
   * Open editor mode.
   */
  editorMode: boolean;

  /**
   * Current userId
   */
  currentUserId: string;

  /**
   * Current user abstract key for anonymous action (e.g. send anonymous answer)
   */
  currentUserKey: string;

  /**
   * Current user properties {name, picture, email} {e.g. display on the form}
   */
  currentUserProperties: TUser;
  /**
   * follow me output data.
   * use this emitter to send "follow me" commands(data) to the outside
   * if the component is planning to support "follow me" mode
   */
  outputFollowMeData = new EventEmitter<any>();

  /**
   * subject for working with data inside the component
   */
  data$ = new BehaviorSubject<any>(null);

  /**
   * is used multilingual content
   */
  usedMultilingualContent$ = new BehaviorSubject<IMultilingual>({multilingual: false, usedLanguages: []});

  /**
   * content in readonly mode
   */
  readonly: boolean;

  protected get usedMultilingualContent(): boolean {
    return this.usedMultilingualContent$.getValue().multilingual;
  }

  protected get usedLanguages(): string[] {
    return this.usedMultilingualContent$.getValue().usedLanguages;
  }
  /**
   * default language
   */
  defaultLanguage$ = new BehaviorSubject<LANGUAGE>(null);
  /**
   * current language
   */
  currentLanguage$ = new BehaviorSubject<LANGUAGE>(null);

  /**
   * output state of translated to current language
   */
  translatedToCurrentLanguageState = new EventEmitter<boolean>();

  /**
   * method for determining language translations
   */
  getTranslatedLanguages: (data) => string[];

  /**
   * used for receive any actions from external call
   */
  incomingAction$ = new Subject<string>();

  /**
   * used for receive any actions to external call
   */
  outgoingAction$ = new Subject<ISlideAction>();

  /**
   * list of all content slides
   */
  slidesList$: BehaviorSubject<ISlide[]>;
  /**
   * use if need blocked save document button while editor load component data
   */
  itemComponentEditorLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * scale
   */
  scale$: BehaviorSubject<number>;

  /**
   * property of main content. if dirty = true content is a draft.
   */
  dirty: boolean;

  /**
   * Flag to show the content in learn mode or not.
   */
  learnMode = false;

  /**
   * the place where the content was created (TIMELINE, MODULES, ..... )
   */
  contentLocation: APP_MODE;

  /**
   * Parent container item options.
   */
  containerItemOptions = signal<IContainerItemOptions>(null);

  protected constructor(protected injector: Injector) {
    super(injector);
  }

  /**
   * data setter for working with data inside the component and send data out
   * @param value
   */
  protected set data(value: any) {
    this.data$.next(value);
    this.valueChange.emit(this.data$.getValue());
  }

  /**
   * data getter for working with data inside the component
   */
  protected get data() {
    return this.data$.getValue();
  }

  onDestroy(): void {
    super.onDestroy();
  }

  protected get slidesList(): ISlide[] {
    return this.slidesList$.getValue();
  }

  /**
   * the method is used to call the component's data editor.
   * return close editor result (e.g. ok = true, cancel = false)
   */
  abstract onEdit(): Promise<boolean>;

  /**
   * use this method to receive "follow me" commands(data)
   * from the outside for their subsequent processing
   * if the component is planning to support the "follow me" mode
   * @param value
   */
  protected abstract inputFollowMeData(value);

  /**
   * external call on select next element from data.
   * return false if data is not iterable object or achieved last element.
   */
  abstract onNext(): boolean;

  /**
   * external call on select prev element from data.
   * return false if data is not iterable object or achieved first element.
   */
  abstract onPrev(): boolean;

  /**
   * event before delete/clean up container.
   * use for check can be container delete or not.
   * if method return true container will be deleted.
   */
  beforeDeleteConstraint(): Promise<boolean> {
    return Promise.resolve(true);
  }

  /**
   * event after positive confirmation delete/clean up container.
   */
  afterDeleteContainer() {}

  /**
   * method output state of translated to current language
   */
  protected emmitCurrentLanguageTranslatedState() {
    const multilingual = this.usedMultilingualContent;
    if (this.editorMode && multilingual && typeof this.getTranslatedLanguages !== 'undefined') {
      const languages = this.getTranslatedLanguages(this.data);
      if (!languages?.length) {
        return this.translatedToCurrentLanguageState.emit(true);
      }
      const translated = languages.map(o => o === 'default' ? this.defaultLanguage$.getValue() : o)
        .includes(this.currentLanguage$.getValue());
      this.translatedToCurrentLanguageState.emit(translated);
    } else {
      this.translatedToCurrentLanguageState.emit(true);
    }
  }

  translate(): Promise<any> {
    return Promise.resolve();
  }
}
