import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class HKVBSApiService extends StdApiService {

  constructor(
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    protected aFirestore: AngularFirestore,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
  }

  public synchronize() {
    return this.api(() => this.gapi.client.hkvbsApi.synchronize());
  }

}
