import {Constants, CONTENT_SPECIFICATION, ICreator, IEventEntityLink, SELF_LEARNING_MODE} from '../../core/constants';
import {ContentSocialSettings} from './SocialSettings';
import {DRM_MODE, DRM_MY_KNOWLEDGE_MODE} from '../../core/drm.service';

export interface IContentEntityLink extends IEventEntityLink {
  sectionId?: string;
  contentId?: string;
}

export enum CONTENT_PATH_TYPE {
  DEFAULT = 'contents',
  DRAFT = 'draft_contents'
}

export abstract class AbstractContent {
  id: string;
  eventId: string;
  isPublic: boolean;
  title; // TCaption;
  userId: string;
  creator: ICreator;
  type: string;
  invisible: false;
  parentId: string;
  orderIndex = 0;
  draft: boolean;
  createTime: number;
  updateTime: number;
  isPresenterContent: boolean;
  secondParentId: string;
  specification: CONTENT_SPECIFICATION;
  minimalTimeSpentOnContent: number;
  selfLearningMode: SELF_LEARNING_MODE;
  anonymously: boolean;
  socialSettings: ContentSocialSettings;
  entityLink: IContentEntityLink;
  drmMode: DRM_MODE;
  drmMyKnowledgeMode: DRM_MY_KNOWLEDGE_MODE;
  systemData: any;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('isPublic')) {
      this.isPublic = obj.isPublic;
    }
    if (obj.hasOwnProperty('title')) {
      this.title = obj.title;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
    if (obj.hasOwnProperty('creator')) {
      this.creator = obj.creator;
    }
    if (obj.invisible) {
      this.invisible = obj.invisible;
    }
    if (obj.parentId) {
      this.parentId = obj.parentId;
    }
    if (obj.hasOwnProperty('orderIndex') && obj.orderIndex > 0) {
      this.orderIndex = obj.orderIndex;
    }
    if (obj.hasOwnProperty('draft')) {
      this.draft = obj.draft;
    }
    if (obj.eventId) {
      this.eventId = obj.eventId;
    }
    if (obj.createTime) {
      this.createTime = obj.createTime;
    }
    if (obj.updateTime) {
      this.updateTime = obj.updateTime;
    }
    if (obj.isPresenterContent) {
      this.isPresenterContent = obj.isPresenterContent;
    }
    if (obj.secondParentId) {
      this.secondParentId = obj.secondParentId;
    }
    if (obj.specification) {
      this.specification = obj.specification;
    }
    if (obj.hasOwnProperty('minimalTimeSpentOnContent')) {
      this.minimalTimeSpentOnContent = obj.minimalTimeSpentOnContent;
    }
    if (obj.selfLearningMode) {
      this.selfLearningMode = obj.selfLearningMode;
    }
    if (obj.hasOwnProperty('anonymously')) {
      this.anonymously = obj.anonymously;
    }
    if (obj.hasOwnProperty('socialSettings')) {
      this.socialSettings = new ContentSocialSettings(obj.socialSettings);
    }
    if (obj.hasOwnProperty('entityLink')) {
      this.entityLink = obj.entityLink;
    }
    if (obj.hasOwnProperty('drmMode')) {
      this.drmMode = obj.drmMode;
    }
    if (obj.hasOwnProperty('drmMyKnowledgeMode')) {
      this.drmMyKnowledgeMode = obj.drmMyKnowledgeMode;
    }
    if (obj.hasOwnProperty('systemData')) {
      this.systemData = obj.systemData;
    }
  }

  public mergeContent(src: AbstractContent) {
    if (this.isPublic !== src.isPublic) {
      this.isPublic = src.isPublic;
    }
    if (this.title !== src.title) {
      this.title = src.title;
    }
    if (this.parentId !== src.parentId) {
      this.parentId = src.parentId;
    }
    if (this.orderIndex !== src.orderIndex) {
      this.orderIndex = src.orderIndex;
    }
    if (this.draft !== src.draft) {
      this.draft = src.draft;
    }
    if (this.createTime !== src.createTime) {
      this.createTime = src.createTime;
    }
    if (this.updateTime !== src.updateTime) {
      this.updateTime = src.updateTime;
    }
    if (this.isPresenterContent !== src.isPresenterContent) {
      this.isPresenterContent = src.isPresenterContent;
    }
    if (this.secondParentId !== src.secondParentId) {
      this.secondParentId = src.secondParentId;
    }
    if (this.specification !== src.specification) {
      this.specification = src.specification;
    }
    if (this.minimalTimeSpentOnContent !== src.minimalTimeSpentOnContent) {
      this.minimalTimeSpentOnContent = src.minimalTimeSpentOnContent;
    }
    if (this.selfLearningMode !== src.selfLearningMode) {
      this.selfLearningMode = src.selfLearningMode;
    }
    if (this.anonymously !== src.anonymously) {
      this.anonymously = src.anonymously;
    }
    if (this.drmMode !== src.drmMode) {
      this.drmMode = src.drmMode;
    }
    if (this.drmMyKnowledgeMode !== src.drmMyKnowledgeMode) {
      this.drmMyKnowledgeMode = src.drmMyKnowledgeMode;
    }
    if (src.type === Constants.CONTENT_TYPE_MODULAR) {
      this.socialSettings = new ContentSocialSettings(src.socialSettings);
    }
    if (src.hasOwnProperty('entityLink') || (this.entityLink && !src.hasOwnProperty('entityLink'))) {
      this.entityLink = src.entityLink;
    }
    if (this.systemData !== src.systemData) {
      this.systemData = src.systemData;
    }
  }

  get isTypeSection(): boolean {
    return this.type === Constants.CONTENT_TYPE_TIMELINE || this.type === Constants.CONTENT_TYPE_SECTION;
  }

  toObject() {
    const obj = {...this};
    Object.keys(obj).forEach(key => obj[key] === undefined && (obj[key] = null));
    Object.keys(obj).forEach(key => typeof obj[key] === 'function' && delete obj[key]);

    delete obj.id;

    if (obj.socialSettings) {
      obj.socialSettings = obj.socialSettings instanceof ContentSocialSettings ? obj.socialSettings.toObject() : obj.socialSettings;
    }
    return obj;
  }
}
