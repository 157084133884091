// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  border-radius: 50%;
}
:host img {
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/user-avatar/user-avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[":host {\n  border-radius: 50%;\n  img {\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
