import {FirestoreReference} from './FirestoreReference';

export enum MailTemplateType {
  CUSTOM = 'custom',
  INVITATION = 'invitation',
  REMINDER = 'reminder',
  STATUS_CHANGE = 'status_change',
  STATUSES_CHANGE = 'statuses_change',
  FREE_SPACE = 'free_space',
  RESET_PASSWORD = 'reset_password'
}

export class MailTemplate extends FirestoreReference {
  public static readonly DB_PATH = 'mail_template';

  name: string;

  type: MailTemplateType = MailTemplateType.CUSTOM;

  subject: string;

  body: string;

  sectionId: string;

  parentKey: string;

  constructor(obj?: any) {
    super(obj);
    if (!obj) {
      return;
    }
    if (obj.name) {
      this.name = obj.name;
    }
    if (obj.type) {
      this.type = obj.type;
    }
    if (obj.subject) {
      this.subject = obj.subject;
    }
    if (obj.body) {
      this.body = obj.body;
    }
    if (obj.sectionId) {
      this.sectionId = obj.sectionId;
    }
    if (obj.parentKey) {
      this.parentKey = obj.parentKey;
    }

  }
}
