import {Injectable} from '@angular/core';
import {TimePoint, TimePointMap} from '../model/event-mode/RowDatesObject';
import {IPlaneContentMap, PlaneContent} from '../model/content/PlaneContent';
import {AutopilotSettings} from '../model/event-mode/InstantSettings';
import {AUTOPILOT_SAMPLE, BEFORE_CHANGE_TICKING, CLOCK_TICKING, Constants, COUNTDOWN_TIMER} from '../core/constants';
import {cloneDeep, isEmpty} from 'lodash';
import {BehaviorSubject, interval, Subject, take} from 'rxjs';
import {SectionContent} from '../model/content/SectionContent';

export interface IAutopilotSectionObject {
  timePoint: TimePoint; title: string; orderIndex: number;
  section: SectionContent; parentIsContainer: boolean;
}
export interface IAutopilotMap {[sectionId: number]: IAutopilotSectionObject; }
export interface ICountdownValue {counterValue: number; maxValue: number; playTick: boolean; }
export interface IAutopilotValue {currentTimeSectionId: string; countdownValue: ICountdownValue; lastActiveSectionId: string; }
export interface IAutopilotPrestartMap {[sectionId: number]: number; }

@Injectable({
  providedIn: 'root'
})
export class AutopilotService {
  private _autopilotSections: IAutopilotMap = {};
  private _autopilotSectionsSorted: IAutopilotSectionObject[] = [];
  private _autopilotPrestartTimePoll: IAutopilotPrestartMap = {};
  private _autopilotSettings: AutopilotSettings = new AutopilotSettings();
  private _countdownSubject = new Subject<ICountdownValue>();
  private _unsetCurrentTimeSectionIdSubject = new Subject<boolean>();
  private _playSoundSubject = new Subject<AUTOPILOT_SAMPLE>();
  private manageTimeMap: TimePointMap;
  private _planeFullListContent: IPlaneContentMap;
  private isPresenter: boolean;
  private projectorMode: boolean;
  private eventId: string;
  private gongBuffer = undefined;
  private gongBufferSource = undefined;
  private inflictedBuffer = undefined;
  private inflictedBufferSource = undefined;
  private clockTickBuffer = undefined;
  private clockTickBufferSource = undefined;
  private _tickSynchronize = 0;
  private _isDBCurrentTimeSection = false;
  private playBeforeCountdownSections = {};
  private _changeLastActiveSectionId$ = new BehaviorSubject<IAutopilotValue>(null);
  private _feedbackPopUp = false;
  private _feedbackPopUpSubject = new BehaviorSubject<string>(null);
  autopilotChangeState$ = new Subject();

  constructor() { }

  get autopilotSettings(): AutopilotSettings {
    return this._autopilotSettings;
  }

  set autopilotSettings(value: AutopilotSettings) {
    this._autopilotSettings = value;
  }

  get countdownSubject(): Subject<ICountdownValue> {
    return this._countdownSubject;
  }

  get unsetCurrentTimeSectionIdSubject(): Subject<boolean> {
    return this._unsetCurrentTimeSectionIdSubject;
  }

  get tickSynchronize(): number {
    return this._tickSynchronize;
  }

  set tickSynchronize(value: number) {
    this._tickSynchronize = value;
  }

  get isDBCurrentTimeSection(): boolean {
    return this._isDBCurrentTimeSection;
  }

  get playSoundSubject(): Subject<AUTOPILOT_SAMPLE> {
    return this._playSoundSubject;
  }

  get changeLastActiveSectionId$(): BehaviorSubject<IAutopilotValue> {
    return this._changeLastActiveSectionId$;
  }

  get autopilotPrestartTimePoll(): IAutopilotPrestartMap {
    return this._autopilotPrestartTimePoll;
  }

  get feedbackPopUp(): boolean {
    return this._feedbackPopUp;
  }

  set feedbackPopUp(value: boolean) {
    this._feedbackPopUp = value;
  }

  get feedbackPopUpSubject(): BehaviorSubject<string> {
    return this._feedbackPopUpSubject;
  }

  public resetServiceValues() {
    this._autopilotSections = {};
    this._autopilotSectionsSorted = [];
    this._autopilotSettings = new AutopilotSettings();
    this.manageTimeMap = undefined;
    this.isPresenter = undefined;
    this.eventId = undefined;
    this._tickSynchronize = 0;
    this.gongBuffer = undefined;
    this.clockTickBuffer = undefined;
    this.playBeforeCountdownSections = {};
    this._changeLastActiveSectionId$ = new BehaviorSubject<IAutopilotValue>(null);
    this._autopilotPrestartTimePoll = {};
    this._feedbackPopUp = false;
    this._feedbackPopUpSubject = new BehaviorSubject(null);
  }

  private comparator(field, direction?) {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field] < b[field]) {return -1; }
        if (a[field] > b[field]) {return 1; }
      } else if (direction === 'desc') {
        if (a[field] > b[field]) {return -1; }
        if (a[field] < b[field]) {return 1; }
      }
      return 0;
    };
  }

  rebuildAutopilotMap(planeListContentSorted: PlaneContent[], planeFullListContent: IPlaneContentMap,
                      manageTimeMap: TimePointMap, eventId: string, isPresenter: boolean,
                      autopilotStatusControl: boolean, timelineService) {
    const getRoomTimePoint = (containerTimePoint: TimePoint, roomSectionId: string) => {
      const tp = cloneDeep(containerTimePoint);
      tp.sectionId = roomSectionId;
      const s = planeFullListContent[roomSectionId].sectionContent;
      tp.title = s.title;
      return tp;
    };

    const level = (sectionId: string) => {
      let lvl = 0;
      const pc = planeFullListContent[sectionId];
      if (pc) {
        let parent = planeFullListContent[pc.sectionContent?.parentId];
        if (parent) {
          lvl = 1;
          while (parent && !parent.sectionContent.isRoot) {
            lvl++;
            parent = planeFullListContent[parent.sectionContent?.parentId];
          }
        }
      }
      return lvl;
    };

    this.manageTimeMap = manageTimeMap;
    this.isPresenter = isPresenter;
    this._planeFullListContent = planeFullListContent;
    this.eventId = eventId;
    let list: PlaneContent[] = [];
    if (!autopilotStatusControl) {
      list = planeListContentSorted
        .filter(o =>
          !o.sectionContent.sectionTimeIsNotActive && !o.isRoot &&
          planeFullListContent[o.parentId] && !o.sectionContent.excludeFromAutopilot &&
          o.sectionContent.status === Constants.SECTION_STATUS_OPEN && (!planeFullListContent[o.parentId].container ||
          (planeFullListContent[o.parentId].container &&
            planeFullListContent[o.parentId].sectionContent.status === Constants.SECTION_STATUS_OPEN &&
            !planeFullListContent[o.parentId].sectionContent.sectionTimeIsNotActive &&
            !planeFullListContent[o.parentId].sectionContent.excludeFromAutopilot &&
            !!timelineService.userRoom(planeFullListContent[o.parentId].sectionContent, timelineService.conferenceUser) &&
            timelineService.userRoom(planeFullListContent[o.parentId].sectionContent, timelineService.conferenceUser).id === o.id)));
    } else {
      list = planeListContentSorted
        .filter(o =>
          !o.sectionContent.sectionTimeIsNotActive && !o.isRoot &&
          planeFullListContent[o.parentId] && !planeFullListContent[o.parentId].container && !o.sectionContent.excludeFromAutopilot);
    }
    if (this._autopilotSettings?.automaticTransitionLevel) {
      list = list.filter(pc => level(pc.id) <= this._autopilotSettings.automaticTransitionLevel);
    }
    const updateKeys = Object.keys(this._autopilotSections).reduce(function (rval, cval) {
      rval[cval] = true;
      return rval;
    }, {});
    for (const plc of list) {
      if (this._autopilotSections[plc.id]) {
        delete updateKeys[plc.id];
      }
      this._autopilotSections[plc.id] = {
        title: plc.title,
        orderIndex: plc.sectionContent.orderIndex,
        timePoint: !planeFullListContent[planeFullListContent[plc.id].parentId].container ?
          manageTimeMap[plc.id] : getRoomTimePoint(manageTimeMap[planeFullListContent[plc.id].parentId], plc.id),
        section: planeFullListContent[plc.id].sectionContent,
        parentIsContainer: planeFullListContent[planeFullListContent[plc.id].parentId].container
      };
    }
    Object.keys(updateKeys).forEach(key => delete this._autopilotSections[key]);
    this._autopilotSectionsSorted = Object.keys(this._autopilotSections).map(id => this._autopilotSections[id])
      .sort(this.comparator(Constants.ORDERINDEX));
  }

  /**
   * @param timerTime
   * @param dbCurrentSectionId - value from database
   */
  getAutopilotValue(timerTime: number, dbCurrentSectionId: string, timelineService): IAutopilotValue {
    const result: IAutopilotValue = {currentTimeSectionId: null, countdownValue: null, lastActiveSectionId: null};
    if (isEmpty(this._autopilotSectionsSorted) && !dbCurrentSectionId) {
      return result;
    }
    if (this.isPresenter && dbCurrentSectionId && this.checkEnterIntoDbCurrentSection(timerTime, dbCurrentSectionId)) {
      this._unsetCurrentTimeSectionIdSubject.next(true);
      this._isDBCurrentTimeSection = false;
    } else if (!!dbCurrentSectionId) {
      result.currentTimeSectionId = dbCurrentSectionId;
      this._isDBCurrentTimeSection = true;
      return result;
    } else {
      this._isDBCurrentTimeSection = false;
    }
    let timeList = this._autopilotSectionsSorted.filter(o =>
      o.timePoint.startValue <= timerTime && timerTime < o.timePoint.endValue);
    this._autopilotSectionsSorted.forEach(o => {
      if (o.section.specificVideoConferences && o.section.autopilotPrestartTime &&
        o.timePoint.startValue - timerTime > 0 &&
        o.timePoint.startValue - timerTime <= o.section.autopilotPrestartTime) {
        this._autopilotPrestartTimePoll[o.section.id] = o.timePoint.startValue - timerTime;
      } else if (o.section.specificVideoConferences && o.section.autopilotPrestartTime &&
        this._autopilotPrestartTimePoll[o.section.id] && o.timePoint.startValue - timerTime <= 0) {
        delete this._autopilotPrestartTimePoll[o.section.id];
      }
    });
    let currentTP: TimePoint;
    while (!isEmpty(timeList)) {
      currentTP = timeList[0].timePoint;
      timeList = timeList.filter(o => o.timePoint.sectionId !== currentTP.sectionId &&
        o.timePoint.startValue <= timerTime && timerTime < o.timePoint.endValue &&
        timelineService.hasUserAccessRightsToSection(this._planeFullListContent[o.timePoint.sectionId].sectionContent));
    }
    if (currentTP) {
      const currentSection = this._autopilotSections[currentTP.sectionId];
      let clockTicking;
      if (currentSection && !currentSection.parentIsContainer && currentSection.section &&
           currentSection.section.autopilotEnableCountDown) {
        clockTicking = currentSection.section.autopilotClockTicking;
      } else if (currentSection && currentSection.parentIsContainer && currentSection.section &&
           currentSection.section.autopilotEnableCountDown) {
        clockTicking = currentSection.section.autopilotClockTicking;
      } else if (currentSection && currentSection.parentIsContainer && currentSection.section &&
           !currentSection.section.autopilotEnableCountDown &&
          timelineService.getSectionContent(currentSection.section.parentId).autopilotEnableCountDown) {
        clockTicking = timelineService.getSectionContent(currentSection.section.parentId).autopilotClockTicking;
      } else {
        clockTicking = this._autopilotSettings.clockTicking;
      }
      result.currentTimeSectionId = !currentSection.parentIsContainer ? currentTP.sectionId : currentSection.section.parentId;
      const duration = clockTicking !== CLOCK_TICKING.FULL ? Math.min(clockTicking, currentTP.durationValue)  : currentTP.durationValue;
      if (this._feedbackPopUp && currentTP.endValue - timerTime <= Constants.ONE_MINUTE_MS &&
        currentTP.endValue - timerTime >= Constants.ONE_MINUTE_MS - 2000) {
        this.feedbackPopUpSubject.next(currentTP.sectionId);
      }
      if (currentTP.endValue - duration <= timerTime && timerTime <= currentTP.endValue) {
        if (this._autopilotSettings.playBeforeCountdown && clockTicking !== CLOCK_TICKING.FULL &&
          clockTicking !== CLOCK_TICKING.NO_CLOCK_TICKING &&
          !this.playBeforeCountdownSections[currentTP.sectionId]) {
          this.playBeforeCountdownSections[currentTP.sectionId] = true;
          this.playSoundSubject.next(AUTOPILOT_SAMPLE.BEFORE_COUNTDOWN);
        }
        const cValue = duration - (currentTP.endValue - timerTime);
        const timeForChange = currentTP.endValue - timerTime;
        if (cValue >= 0) {
          result.countdownValue = {
            maxValue: duration,
            counterValue: this.autopilotEnableChartMode ? (cValue < 1000 ? 0 : cValue) : cValue,
            playTick: this._autopilotSettings.beforeChangeTicking !== BEFORE_CHANGE_TICKING.NO &&
              timeForChange <= this._autopilotSettings.beforeChangeTicking +
               (!this.autopilotEnableChartMode ? 1000 : 0) && timeForChange >= 0
          };
        }
      }
    }
    // find last active section
    const lastActiveList = this._autopilotSectionsSorted.filter(o => o.timePoint.endValue < timerTime && !o.parentIsContainer);
    if (!isEmpty(lastActiveList)) {
      result.lastActiveSectionId = lastActiveList[lastActiveList.length - 1].timePoint.sectionId;
    }
    this.autopilotChangeState$.next(true);
    return result;
  }

  private checkEnterIntoDbCurrentSection(timerTime, sectionId) {
    const sectionTP = this.manageTimeMap[sectionId];
    if (!sectionTP) {
      return false;
    }
    // timeout 3 seconds after resetting the current time before re-setting this section as current time.
    return timerTime >= sectionTP.startValue && timerTime <= (sectionTP.startValue + Constants.ONE_SECOND_INTERVAL * 3) &&
      timerTime <= sectionTP.endValue;
  }

  playGong() {
    if (this.isSoundGongOff()) {
      return;
    }
    const vm = this;
    const loadSound = () => {
      const audioURL = AUTOPILOT_SAMPLE.GONG;
      const request = new XMLHttpRequest();
      request.open('GET', audioURL, true);
      request.responseType = 'arraybuffer';
      request.onload = function () {
        context.decodeAudioData(request.response, function (buffer) {
          vm.gongBuffer = buffer;
          playSound(vm.gongBuffer);
        });
      };
      request.send();
    };
    const playSound = (buffer) => {
      vm.gongBufferSource = context.createBufferSource();
      vm.gongBufferSource.buffer = buffer;
      vm.gongBufferSource.connect(context.destination);
      vm.gongBufferSource.start(0);
    };
    let context;
    try {
      context = new (window['AudioContext'] || window['webkitAudioContext'])();
      if (!context) {
        return;
      }
    } catch (e) {
      console.log('Your browser doesn\'t support Web Audio API', e);
    }
    if (!this.gongBuffer) {
      loadSound();
    } else {
      playSound(this.gongBuffer);
    }
  }

  playBeforeCountdown() {
    if (this.isSoundRemindOff()) {
      return;
    }
    const vm = this;
    const loadSound = () => {
      const audioURL = AUTOPILOT_SAMPLE.BEFORE_COUNTDOWN;
      const request = new XMLHttpRequest();
      request.open('GET', audioURL, true);
      request.responseType = 'arraybuffer';
      request.onload = function () {
        context.decodeAudioData(request.response, function (buffer) {
          vm.inflictedBuffer = buffer;
          playSound(vm.inflictedBuffer);
        });
      };
      request.send();
    };
    const playSound = (buffer) => {
      vm.inflictedBufferSource = context.createBufferSource();
      vm.inflictedBufferSource.buffer = buffer;
      vm.inflictedBufferSource.connect(context.destination);
      vm.inflictedBufferSource.start(0);
    };
    let context;
    try {
      context = new (window['AudioContext'] || window['webkitAudioContext'])();
      if (!context) {
        return;
      }
    } catch (e) {
      console.log('Your browser doesn\'t support Web Audio API', e);
    }
    if (!this.inflictedBuffer) {
      loadSound();
    } else {
      playSound(this.inflictedBuffer);
    }
  }

  clockTick(mute) {
    if (this.isSoundTickingOff()) {
      return;
    }
    const vm = this;
    const loadSound = () => {
      const audioURL = AUTOPILOT_SAMPLE.TICK;
      const request = new XMLHttpRequest();
      request.open('GET', audioURL, true);
      request.responseType = 'arraybuffer';
      request.onload = function () {
        context.decodeAudioData(request.response, function (buffer) {
          vm.clockTickBuffer = buffer;
          playSound(vm.clockTickBuffer);
        });
      };
      request.send();
    };
    const playSound = (buffer) => {
      vm.clockTickBufferSource = context.createBufferSource();
      vm.clockTickBufferSource.buffer = buffer;
      vm.clockTickBufferSource.connect(context.destination);
      vm.clockTickBufferSource.start(0);
      if (mute) {
        vm.clockTickBufferSource.stop(0);
      }
    };
    let context;
    try {
      context = new (window['AudioContext'] || window['webkitAudioContext'])();
      if (!context) {
        return;
      }
    } catch (e) {
      console.log('Your browser doesn\'t support Web Audio API', e);
    }
    if (!this.clockTickBuffer) {
      loadSound();
    } else {
      playSound(this.clockTickBuffer);
    }
  }

  private isSoundTickingOff() {
    if (this._autopilotSettings.countdownTimer === COUNTDOWN_TIMER.NO) {
      return true;
    }
    if (!this._autopilotSettings.tickingBeforeCountdownEnd) {
      return true;
    } else if (this.projectorMode && this._autopilotSettings.soundAvailabilityProjectors) {
      return false;
    } else if (this.isPresenter && this._autopilotSettings.soundAvailabilityPresenters) {
      return false;
    } else if (!this.isPresenter && this._autopilotSettings.soundAvailabilityAudience) {
      return false;
    }
    return true;
  }

  private isSoundGongOff() {
    if (this._autopilotSettings.countdownTimer === COUNTDOWN_TIMER.NO) {
      return true;
    }
    if (!this._autopilotSettings.playGong) {
      return true;
    } else if (this.projectorMode && this._autopilotSettings.soundAvailabilityProjectors) {
      return false;
    } else if (this.isPresenter && this._autopilotSettings.soundAvailabilityPresenters) {
      return false;
    } else if (!this.isPresenter && this._autopilotSettings.soundAvailabilityAudience) {
      return false;
    }
    return true;
  }

  private isSoundRemindOff() {
    if (this._autopilotSettings.countdownTimer === COUNTDOWN_TIMER.NO) {
      return true;
    }
    if (!this._autopilotSettings.playBeforeCountdown) {
      return true;
    } else if (this.projectorMode && this._autopilotSettings.soundAvailabilityProjectors) {
      return false;
    } else if (this.isPresenter && this._autopilotSettings.soundAvailabilityPresenters) {
      return false;
    } else if (!this.isPresenter && this._autopilotSettings.soundAvailabilityAudience) {
      return false;
    }
    return true;
  }

  playSample(sample: AUTOPILOT_SAMPLE) {
    const loadSound = () => {
      const audioURL = sample;
      const request = new XMLHttpRequest();
      request.open('GET', audioURL, true);
      request.responseType = 'arraybuffer';
      request.onload = function () {
        context.decodeAudioData(request.response, function (buffer) {
          playSound(buffer);
        });
      };
      request.send();
    };
    const playSound = (buffer) => {
      const bufferSource = context.createBufferSource();
      bufferSource.buffer = buffer;
      bufferSource.connect(context.destination);
      bufferSource.start(0);
    };
    let context;
    try {
      context = new (window['AudioContext'] || window['webkitAudioContext'])();
      if (!context) {
        console.log('Your browser doesn\'t support Web Audio API');
      }
    } catch (e) {
      console.log('Your browser doesn\'t support Web Audio API', e);
    }
    if (sample === AUTOPILOT_SAMPLE.TICK) {
      interval(1000).pipe(take(4)).subscribe(() => loadSound());
    } else {
      loadSound();
    }
  }

  private get autopilotEnableChartMode() {
    return this._autopilotSettings && this._autopilotSettings.autopilotEnable &&
      this._autopilotSettings.countdownTimer === COUNTDOWN_TIMER.VISUAL &&
      ((this.isPresenter && this._autopilotSettings.countdownAvailabilityPresenters) ||
        (!this.isPresenter && this._autopilotSettings.countdownAvailabilityAudience) ||
        (this.projectorMode && this._autopilotSettings.countdownAvailabilityProjectors));
  }


}
