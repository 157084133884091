import {Component, effect, Injector, ViewChild} from '@angular/core';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {isEmpty} from 'lodash';
import {ILanguageParams} from '../../../../../../../core/constants';
import {EventQuestion, TableRowAnswerQuestion, TGroupAnswerQuestion} from '../../../../../../../model/EventQuestion';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-question-sequence-editor',
  templateUrl: './question-sequence-editor.component.html',
  styleUrl: './question-sequence-editor.component.scss'
})
export class QuestionSequenceEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  displayedColumns: string[] = ['sequenceNumber', 'answer'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(protected injector: Injector) {
    super(injector);
    this.dataSource.sortingDataAccessor = (row: TableRowAnswerQuestion, columnName: string) => {
      switch (columnName) {
        case 'orderIndex':
          const index = this.question?.groupsCorrectAnswers?.find(g => g.defaultGroup)?.items?.findIndex(it => it.id === row.id) ?? -1;
          return index > -1 ? index : row['orderIndex'];
        default:
          return row[columnName];
      }
    };
    effect(() => {
      this.dataSource.sort = this.sort;
    });
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    // This type of question, according to the technical specifications,
    // does not have a standard form and can only work in the correct answers mode.
    // set useCorrectAnswers = true for new question
    if (isEmpty(this.question.items)) {
      this.question.useCorrectAnswers = true;
    }
  }

  protected sortedData() {
    return this.dataSource['_renderData'].getValue() as TableRowAnswerQuestion[];
  }

  protected defaultGroupData() {
    return this.question?.groupsCorrectAnswers?.find(g => g.defaultGroup)?.items ?? [];
  }

  protected mixAnswersData() {
    const mixedIds = this.answerData.map(a => a.id).sort(() => Math.random() - 0.5);
    this.answerData.forEach(row => row.orderIndex = (mixedIds.indexOf(row.id) + 1) * 100);
    this.dataSource.data = this.answerData;
  }

  addAnswer() {
    super.addAnswer();
    this.setCorrectOrderToGroupAndMixValues();
  }

  deleteAnswer(rowId) {
    super.deleteAnswer(rowId);
    this.setCorrectOrderToGroupAndMixValues();
  }

  onClickUpDown(rowId, move: number) {
    const index = this.defaultGroupData().findIndex(item => item.id === rowId);
    const temp = this.defaultGroupData()[index];
    this.defaultGroupData()[index] = this.defaultGroupData()[index + move];
    this.defaultGroupData()[index + move] = temp;
    this.mixAnswersData();
  }

  isFirst(rowId) {
    return !rowId || !this.sortedData().findIndex(item => item.id === rowId) || isEmpty(this.answerData);
  }

  isLast(rowId) {
    return !rowId || (this.answerData.length - 1) === this.sortedData().findIndex(item => item.id === rowId) ||
      isEmpty(this.answerData);
  }

  protected setCorrectOrderToGroupAndMixValues() {
    const correctIdsOrder = this.sortedData().map(a => new Object({id: a.id}) as TGroupAnswerQuestion);
    this.defaultGroupData().splice(0);
    this.defaultGroupData().push(...correctIdsOrder);

  }

  validate(): IValidated {
    if (isEmpty(this.dataSource.data)) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required') };
    }
    if (this.dataSource.data.some(o => isEmptyCaption(o.answer))) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty') };
    }
    return { validated: true };
  }
}
