import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Injector } from '@angular/core';
import { cloneDeep} from 'lodash';
import { AbstractQuizQuestionParticipantComponent } from '../../shared/participant/abstract-quiz-question-participant-component';
import { AnswerQuestion } from '../../../../../../../model/EventQuestion';
import { AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import { IAnswerSequence } from '../sequence-utils';




@Component({
  selector: 'app-question-sequence-participant',
  templateUrl: './question-sequence-participant.component.html',
  styleUrl: './question-sequence-participant.component.scss'
})
export class QuestionSequenceParticipantComponent extends AbstractQuizQuestionParticipantComponent {


  correctSequence: AnswerQuestion[];
  participantAnswers: AnswerQuestion[];
  checkParticipantAnswers: IAnswerSequence[] = [];

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);

    this.question$.asObservable()
        .pipe(this.takeUntilAlive())
        .subscribe(data => {
      if (data && !this.participantAnswers) {
        this.correctSequence = cloneDeep(data.items);
        this.participantAnswers = cloneDeep(data.items);
        // Sort the list randomly
        this.participantAnswers.sort(() => Math.random() - 0.5);
      }
    });
  }



  protected onReceiveQuestionAnswers() {
    if (this.answers && this.answers.length && this.participantAnswers) {

      this.participantAnswers = this.answers.map((answerId, idx) => {
        const index = this.correctSequence.findIndex(obj => obj.id === answerId);
        return this.correctSequence[index];
      });
    }
    this.checkParticipantAnswers = (this.answers || []).map((item, idx) => this.answerIdToIAnswerSequence(item, idx));
  }

  drop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex !== event.currentIndex){
      moveItemInArray(this.participantAnswers, event.previousIndex, event.currentIndex);
      this.sendAnswer();
    }
  }


  sendAnswer() {
    const answers: string[] = this.participantAnswers.map(item => item.id);
    this.answerChange$.next(new AnswersQuestion(this.qKey, answers, this.question.timelineId));
  }


  private answerIdToIAnswerSequence(answerId: string, currIdx: number): IAnswerSequence | null {
    const correctIdx = this.correctSequence?.findIndex(obj => obj.id === answerId);
    if (correctIdx === -1) {
      return null;
    }
    return this.answerQuestionToIAnswerSequence(this.correctSequence[correctIdx], correctIdx, currIdx);
  }

  private answerQuestionToIAnswerSequence(correctSequence: AnswerQuestion, correctIdx: number, currIdx: number): IAnswerSequence {
    return {
      id: correctSequence.id,
      answerText: correctSequence.answer,
      currPosition: currIdx,
      correctPosition: correctIdx,
      correctAnswer: currIdx === correctIdx,
      wrongAnswer: currIdx !== correctIdx
    };
  }

}
