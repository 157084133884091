import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, debounceTime, filter, pairwise, skip, Subject, Subscription, take, takeUntil} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {EventsDataService} from './events-data.service';
import {TimeLineService} from './time-line.service';
import {CommonService} from '../core/common.service';
import {SectionContent} from '../model/content/SectionContent';
import {cloneDeep, delay, isEmpty} from 'lodash';
import {
  Constants,
  FIXED_SECTION_TYPE,
  FOLLOW_ME_ACTION_TYPE,
  FOLLOW_ME_MODE,
  FOLLOW_ME_USER_PARAMS,
  MEETING_VIEW_MODE
} from '../core/constants';
import {AppUser} from '../model/AppUser';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {DailyCoService} from '../modules/meeting-frame/services/daily-co.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractContent} from '../model/content/AbstractContent';
import {IManagerMap} from '../model/Event';
import {FollowMeSettings} from '../model/event-mode/InstantSettings';

export interface IFollowMeContainerData {
  [containerId: string]: any;
}

export interface IFollowMeAction {
  userId: string;
  sectionId: string;
  contentId: string | null;
  moduleId?: string | null;
  actionType: FOLLOW_ME_ACTION_TYPE;
  viewMode?: string;
  actionId?: string | null;
  actionTime: number;
  anyObject?: any;
  containerData?: IFollowMeContainerData;
  force?: boolean;
}

export interface IFollowMeModulePage {
  userId: string;
  contentId: string;
  moduleId: string;
  viewMode: string;
  pageIndex: number;
}

export interface IFollowMeModuleCameraPosition {
  userId: string;
  contentId: string;
  moduleId: string;
  position: any;
}

export interface IExtFullScreenMode {
  fullscreen: boolean;
  sectionId: string;
  contentId: string;
  moduleId: string;
}

export interface IFollowMeQAAction {
  openDialog: boolean;
  qaSectionId: FIXED_SECTION_TYPE;
  secondParentId: string;
  contentId: string;
  force?: boolean;
}

export interface IFollowMeScrollTo {
  sectionId: string;
  contentId: string;
  elementId: string;
}

export interface IFollowMeZoomTo {
  sectionId: string;
  contentId: string;
  moduleId: string;
  imageId: string;
  scale: number;
  percentLeft: number;
  percentTop: number;
}

export interface IFollowMeMeetingSpeaker {
  sectionId: string;
  userId: string;
}

export interface IFollowMeConferenceGrid {
  selectedUser: string;
  segment: string;
}

export interface IFollowMePoolAction {
  selectedContentId: string;
  selectedContainerId: string;
  selectedQuestionId: string;
  selectedAnswerId: string;
}

export interface IFollowMeMeetingViewMode {
  sectionId: string;
  userId: string;
  viewMode: MEETING_VIEW_MODE;
  viewModeOption: MEETING_VIEW_MODE.FREE | MEETING_VIEW_MODE.SMALL;
}

export interface IFollowMeMeetingViewModeMap {
  [sectionId: string]: IFollowMeMeetingViewMode;
}

export interface IFollowMeCommand {
  command: string;
  time?: number;
  sectionId?: string;
}

export enum WORK_MODE {
  MAIN = 'main',
  PARALLEL = 'parallel'
}

export type TFollowMeWorkMode = WORK_MODE.MAIN | WORK_MODE.PARALLEL | null;

@Injectable({
  providedIn: 'root'
})
export class FollowMeService {
  private _unsubscribe: Subject<any> = new Subject();
  private _internalFollowMePresenterSubject = new Subject<IFollowMeAction>();
  private _externalFollowMeUserSubject = new BehaviorSubject<IFollowMeAction>(null);
  private _onSelectSection = new Subject<SectionContent>();
  private _emitSetCurrentContentSubscriptions: {[id: string]: Subscription} = {};
  private _followMeContentModulePage = new Subject<IFollowMeModulePage>();
  private _followMeContentContainerData$ = new Subject<IFollowMeContainerData>();
  private _followMeContentModuleCameraPosition = new BehaviorSubject<IFollowMeModuleCameraPosition>(null);
  private _followMeContentModuleZoomPosition = new BehaviorSubject<IFollowMeModuleCameraPosition>(null);
  public _followMePresenter: AppUser;
  private _followMePresenterSubject = new BehaviorSubject<AppUser>(null);
  private _followMeSpeaker: AppUser;
  private _followMeSpeakerSubject = new BehaviorSubject<AppUser>(null);
  private _internalFollowMeSpeakerSubject = new Subject<IFollowMeAction>();
  private _mainSpeakerCurrentSection: SectionTimeline;
  private _mainSpeakerCurrentSectionSubject = new BehaviorSubject<SectionContent>(null);
  private _featureLineUserSectionSubject = new BehaviorSubject<SectionContent>(null);
  private _mainUserCurrentSectionSubject = new BehaviorSubject<SectionContent>(null);
  private _mainUserCurrentSection: SectionContent;
  private _extFullScreenMode$ = new Subject<IExtFullScreenMode>();
  private _externalFollowMeQAModeAction$ = new Subject<IFollowMeQAAction>();
  private _timelineSectionContentsList: Subscription;
  private _followMeScrollTo$ = new Subject<IFollowMeScrollTo>();
  private _followMeZoomTo$ = new Subject<IFollowMeZoomTo>();
  private _initFollowMeQAMode: Subscription;
  public currentMeetingViewMode: MEETING_VIEW_MODE = MEETING_VIEW_MODE.CONFERENCE_GRID;
  contentChanged$ = new BehaviorSubject<AbstractContent>(null);
  followMeManager$ = new BehaviorSubject<AppUser>(null);

  constructor(private common: CommonService
            , private timeLineService: TimeLineService
            , private store: Store<fromRoot.State>
            , private dataService: EventsDataService
            , private dailyCoService: DailyCoService
            , private router: Router
            , private activatedRoute: ActivatedRoute
  ) { }

  init() {
    const conferenceUser = this.timeLineService.conferenceUser;
    const eventFollowMe = this.timeLineService.instantSettings?.followMeSettings;
    const dataLoaded = !!conferenceUser && !!eventFollowMe;

    if (dataLoaded && eventFollowMe.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME &&
      this.followMePresenter && conferenceUser.userId !== this.followMePresenter.userId) {
      this.timeLineService.followMeEventPGSParticipant
        .next(conferenceUser.getEventFollowMeUserParam() === FOLLOW_ME_USER_PARAMS.FOLLOW_AS_PARTICIPANT);
      this.timeLineService.followMeEventAsPresenter
        .next(conferenceUser.getEventFollowMeUserParam() === FOLLOW_ME_USER_PARAMS.FOLLOW_AS_PRESENTER);
    } else {
      this.timeLineService.followMeEventPGSParticipant.next(false);
      this.timeLineService.followMeEventAsPresenter.next(false);
    }
    if (dataLoaded && this.mainSpeakerCurrentSection && this.mainSpeakerCurrentSection.followMeSettings &&
      this.mainSpeakerCurrentSection.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME &&
      this.followMeSpeaker && conferenceUser.userId !== this.followMeSpeaker.userId) {
      this.timeLineService.followMeSectionPGSParticipant.next({
        [this.mainSpeakerCurrentSection.id]:
        conferenceUser.getSectionFollowMeUserParam(this.mainSpeakerCurrentSection.id) === FOLLOW_ME_USER_PARAMS.FOLLOW_AS_PARTICIPANT});
      this.timeLineService.followMeSectionAsPresenter.next({
        [this.mainSpeakerCurrentSection.id]:
        conferenceUser.getSectionFollowMeUserParam(this.mainSpeakerCurrentSection.id) === FOLLOW_ME_USER_PARAMS.FOLLOW_AS_PRESENTER});
    } else {
      this.timeLineService.followMeSectionPGSParticipant.next(null);
      this.timeLineService.followMeSectionAsPresenter.next(null);
    }
  }

  isEventMeetingAction(action: IFollowMeAction) {
    return this.dailyCoService.ownerStreamSectionId === this.timeLineService.rootSection.id &&
      action && action.contentId &&
      (action.contentId.includes(Constants.CONTENT_TYPE_SCREEN_SHARING) ||
        action.contentId.includes(Constants.CONTENT_TYPE_MEETING));
  }

  isFollowMeActionPresenterHasRights(action: IFollowMeAction) {
    if (this.timeLineService.getFeatureLineSectionContent()?.container &&
        this.timeLineService.getSection(action?.sectionId)?.parentId === this.timeLineService.featureLineContentId$.getValue()) {
      return true;
    }
    if (this.timeLineService.getFeatureLineSectionContent()?.container &&
      this.timeLineService.getSection(action?.sectionId)?.parentId !== this.timeLineService.featureLineContentId$.getValue()) {
      return false;
    }
    if (action && action.sectionId && this.followMePresenter &&
         action.sectionId !== this.timeLineService.featureLineContentId$.getValue()) {
      return this.timeLineService.hasUserPresenterAccess(this.followMePresenter) ||
        this.timeLineService.hasUserSpeakerAccessRightsToSection(
          this.timeLineService.getSection(this.timeLineService.featureLineContentId$.getValue()), this.followMePresenter);
    } else if (action && action.sectionId && this.followMePresenter &&
      action.sectionId && !this.timeLineService.featureLineContentId$.getValue() &&
      this.timeLineService.event.isManager(this.followMePresenter.userId)) {
      return true;
    }
    return true;
  }

  canEmitAction(action: IFollowMeAction) {
    if (isEmpty(action) || this.timeLineService.timelineSlotMode.getValue()) {
      return false;
    }
    if (action.force || this.isEventMeetingAction(action)) {
      return true;
    }
    if (this.timeLineService.isPresenter && this.followMePresenter &&
      this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
      return true;
    }
    // filtering FollowMe actions from presenters who do not have rights to CurrentTime to avoid navigation
    if (!this.isFollowMeActionPresenterHasRights(action)) {
      return false;
    }
    if (!this.timeLineService.isPresenter && !this.timeLineService.isSpeakerAnySection()) {
      return true;
    }
    if (this.timeLineService.isPresenter && this.followMePresenter &&
      this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
      this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
      return true;
    }
    const fLineSection = this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId);
    const speakerMode = this.timeLineService.getSectionWithSpeakersSelfOrParent(this.timeLineService.featureLineContentId);
    const accessToSpeakerMode = this.timeLineService.hasAccess(speakerMode) || this.timeLineService.isSpeakerAnySection();
    if (fLineSection && !fLineSection.container) {
      if (!!speakerMode && (accessToSpeakerMode || (!accessToSpeakerMode && this.timeLineService.isSpeakerAnySection())) &&
        this.followMePresenter && this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      } else
      if (!speakerMode && ((!this.timeLineService.isPresenter &&
        this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId]) ||
        (!this.timeLineService.isPresenter && !this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId] &&
          this.timeLineService.isSpeakerAnySection())) && this.followMePresenter &&
        this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      }
    } else if (!fLineSection) {
      const actionSection = this.timeLineService.getSectionContent(action.sectionId);
      if (actionSection) {
        const spMode = this.timeLineService.getSectionWithSpeakersSelfOrParent(actionSection.id);
        const accessTospMode = this.timeLineService.hasAccess(spMode);
        if (!!spMode && (accessTospMode || (!accessTospMode && this.timeLineService.isSpeakerAnySection())) &&
          this.followMePresenter &&
          this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
          this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
          return true;
        } else
        if (!spMode && ((!this.timeLineService.isPresenter &&
          this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId]) ||
          (!this.timeLineService.isPresenter && !this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId] &&
            this.timeLineService.isSpeakerAnySection())) && this.followMePresenter &&
          this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
          this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
          return true;
        }
      }
    } else {
      if (action.sectionId && !!speakerMode && accessToSpeakerMode &&
        this.followMeSpeaker && this.timeLineService.currentUser.userId !== this.followMeSpeaker.userId &&
        this.timeLineService.conferenceUser.getSectionFollowMeUserParam(action.sectionId) !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      } else if (action.sectionId && !speakerMode && fLineSection && fLineSection.container &&
        this.followMePresenter && this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      } else if (action.sectionId && !!speakerMode && fLineSection && fLineSection.container &&
        this.followMeSpeaker && this.timeLineService.currentUser.userId !== this.followMeSpeaker.userId &&
        this.timeLineService.timelineEvent.isManager(this.timeLineService.currentUser.userId) &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      }

    }
  }

  canEmitQAAction(action: IFollowMeQAAction) {
    if (isEmpty(action) || this.timeLineService.timelineSlotMode.getValue()) {
      return false;
    }
    if (action.force) {
      return true;
    }
    if (this.timeLineService.isPresenter && this.followMePresenter &&
      this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
      return true;
    }
    if (!this.timeLineService.isPresenter && !this.timeLineService.isSpeakerAnySection()) {
      return true;
    }
    if (this.timeLineService.isPresenter && this.followMePresenter && this.followMePresenter &&
      this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
      this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
      return true;
    }
    const fLineSection = this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId);
    const speakerMode = this.timeLineService.getSectionWithSpeakersSelfOrParent(this.timeLineService.featureLineContentId);
    if (fLineSection && !fLineSection.container) {
      if (!!speakerMode && (speakerMode.userAccessSpeaker(this.timeLineService.currentUser.userId) ||
        (!speakerMode.userAccessSpeaker(this.timeLineService.currentUser.userId) && this.timeLineService.isSpeakerAnySection())) &&
        this.followMePresenter && this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      } else
      if (!speakerMode && ((!this.timeLineService.isPresenter &&
        this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId]) ||
        (!this.timeLineService.isPresenter && !this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId] &&
          this.timeLineService.isSpeakerAnySection())) && this.followMePresenter &&
        this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      }
    } else if (!fLineSection) {
      const actionSection = this.timeLineService.getSectionContent(action.secondParentId);
      if (actionSection) {
        const spMode = this.timeLineService.getSectionWithSpeakersSelfOrParent(actionSection.id);
        if (!!spMode && (spMode.userAccessSpeaker(this.timeLineService.currentUser.userId) ||
          (!spMode.userAccessSpeaker(this.timeLineService.currentUser.userId) && this.timeLineService.isSpeakerAnySection())) &&
          this.followMePresenter &&
          this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
          this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
          return true;
        } else
        if (!spMode && ((!this.timeLineService.isPresenter &&
          this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId]) ||
          (!this.timeLineService.isPresenter && !this.timeLineService.timelineSpeakers[this.timeLineService.currentUser.userId] &&
            this.timeLineService.isSpeakerAnySection())) && this.followMePresenter &&
          this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
          this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
          return true;
        }
      }
    } else {
      if (!!speakerMode && speakerMode.userAccessSpeaker(this.timeLineService.currentUser.userId) &&
        this.followMeSpeaker && this.timeLineService.currentUser.userId !== this.followMeSpeaker.userId &&
        this.timeLineService.conferenceUser
          .getSectionFollowMeUserParam(speakerMode.id) !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      } else if (!speakerMode && fLineSection && fLineSection.container &&
        this.followMePresenter && this.timeLineService.currentUser.userId !== this.followMePresenter.userId &&
        this.timeLineService.conferenceUser.getEventFollowMeUserParam() !== FOLLOW_ME_USER_PARAMS.DONT_FOLLOW_ANYBODY) {
        return true;
      }
    }
  }

  canSendAction(action: {secondParentId?: string, sectionId?: string}) {
    if (!action.sectionId && this.timeLineService.sections.length === 1) {
      return this.followMePresenter.userId === this.timeLineService.currentUser.userId;
    }
    const fLineSection = this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId);
    const speakerMode = this.timeLineService.getSectionWithSpeakersSelfOrParent(this.timeLineService.featureLineContentId);
    if (fLineSection && !fLineSection.container) {
      if (!!speakerMode && this.timeLineService.hasAccess(speakerMode) &&
        this.followMePresenter && this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
        return true;
      } else
      if (!speakerMode && this.timeLineService.isPresenter && this.followMePresenter &&
        this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
        return true;
      }
    } else if (!fLineSection) {
      const actionSection = this.timeLineService.getSectionContent(action.secondParentId ? action.secondParentId : action.sectionId);
      if (actionSection) {
        const spMode = this.timeLineService.getSectionWithSpeakersSelfOrParent(actionSection.id);
        if (!!spMode && this.timeLineService.hasAccess(spMode) &&
          this.followMePresenter && this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
          return true;
        } else
        if (!spMode && this.timeLineService.isPresenter && this.followMePresenter &&
          this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
          return true;
        }
      }
    } else {
      if (!!speakerMode && this.timeLineService.hasAccess(speakerMode) &&
        this.followMeSpeaker && this.timeLineService.currentUser.userId === this.followMeSpeaker.userId) {
        return true;
      } else if (!speakerMode && fLineSection && fLineSection.container &&
        this.followMePresenter && this.timeLineService.currentUser.userId === this.followMePresenter.userId) {
        return true;
      }
    }
  }

  initFollowMeService() {
    this.externalFollowMeUserSubject
      .pipe(takeUntil(this._unsubscribe), skip(1))
      .subscribe(action => {
        if (this.canEmitAction(action)) {
          switch (action.actionType) {
            case FOLLOW_ME_ACTION_TYPE.SECTION_ID:
              this.unsubscribeEmitSetCurrentContentSubscriptions();
              this.emitSetCurrentSection(action);
              break;
            case FOLLOW_ME_ACTION_TYPE.CONTENT_ID:
              this.emitSetCurrentContent(action);
              break;
            case FOLLOW_ME_ACTION_TYPE.IMAGE_INDEX:
              this.emitSetCurrentContentImage(action);
              break;
            case FOLLOW_ME_ACTION_TYPE.CONTAINER_DATA_CHANGE:
              this.emitSetContainerContentDataChange(action);
              break;
            case FOLLOW_ME_ACTION_TYPE.PAGE_NUMBER:
              this.emitSetCurrentContentPageNumber(action);
              break;
            case FOLLOW_ME_ACTION_TYPE.CAMERA_POSITION:
              this.emitSetCurrentContentCameraPosition(action);
              break;
            case FOLLOW_ME_ACTION_TYPE.ZOOM_POSITION:
              this.emitSetCurrentContentZoomPosition(action);
              break;
            default:
              this.common.log.error(action);
              break;
          }
        }
      });
    this.externalFollowMeQAModeAction$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(action => {
        if (this.canEmitQAAction(action) || this.isActionCloseDialog(action)) {
          this.analyzeQAAction(action);
        }
      });
    this.timeLineService.instantSettings$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(settings => {
        if (settings && settings.followMeSettings.followMeMode === FOLLOW_ME_MODE.DONT_FOLLOW) {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
        }
      });
    this.initFollowMeQAMode();
  }

  private isActionCloseDialog(action: IFollowMeQAAction) {
    return this.timeLineService.showQADialog.getValue() && !action.openDialog &&
      !action.contentId && !action.secondParentId && !action.qaSectionId;
  }

  destroy() {
    this.unsubscribeEmitSetCurrentContentSubscriptions();
    this._unsubscribe.next(true);
    this._unsubscribe.complete();
    this._unsubscribe = new Subject();
    this._externalFollowMeUserSubject = new BehaviorSubject<IFollowMeAction>(null);
    this._followMeContentModuleCameraPosition = new BehaviorSubject<IFollowMeModuleCameraPosition>(null);
    this._followMeContentModuleZoomPosition = new BehaviorSubject<IFollowMeModuleCameraPosition>(null);
    this._followMePresenter = null;
    this._followMePresenterSubject = new BehaviorSubject<AppUser>(null);
    this._followMeSpeaker = null;
    this._followMeSpeakerSubject = new BehaviorSubject<AppUser>(null);
    this._mainSpeakerCurrentSection = null;
    this._mainSpeakerCurrentSectionSubject = new BehaviorSubject<SectionContent>(null);
    this._featureLineUserSectionSubject = new BehaviorSubject<SectionContent>(null);
    this._mainUserCurrentSectionSubject = new BehaviorSubject<SectionContent>(null);
    this._mainUserCurrentSection = null;
    this._timelineSectionContentsList = null;
    this._initFollowMeQAMode = null;
    this.currentMeetingViewMode = MEETING_VIEW_MODE.CONFERENCE_GRID;
    this.contentChanged$ = new BehaviorSubject<AbstractContent>(null);
  }

  get followMePresenter(): AppUser {
    return this._followMePresenter;
  }

  set followMePresenter(value: AppUser) {
    if (value && this._followMePresenter && this._followMePresenter.userId === value.userId) {
      return;
    }
    this._followMePresenter = value;
    this.timeLineService.followMePresenterUser = value;
    this._followMePresenterSubject.next(value);
    this.init();
  }

  get followMePresenterSubject(): BehaviorSubject<AppUser> {
    return this._followMePresenterSubject;
  }

  get followMeSpeaker(): AppUser {
    return this._followMeSpeaker;
  }

  set followMeSpeaker(value: AppUser) {
    this._followMeSpeaker = value;
    this.timeLineService.followMeSpeakerUser = value;
    this._followMeSpeakerSubject.next(value);
    this.init();
  }

  get followMeSpeakerSubject(): BehaviorSubject<AppUser> {
    return this._followMeSpeakerSubject;
  }

  get mainSpeakerCurrentSection(): SectionTimeline {
    return this._mainSpeakerCurrentSection;
  }

  set mainSpeakerCurrentSection(value: SectionTimeline) {
    this._mainSpeakerCurrentSection = value;
    this.init();
  }

  get mainSpeakerCurrentSectionSubject(): BehaviorSubject<SectionContent> {
    return this._mainSpeakerCurrentSectionSubject;
  }

  get mainUserCurrentSectionSubject(): BehaviorSubject<SectionContent> {
    return this._mainUserCurrentSectionSubject;
  }

  get mainUserCurrentSection(): SectionContent {
    return this._mainUserCurrentSection;
  }

  set mainUserCurrentSection(value: SectionContent) {
    this._mainUserCurrentSection = value;
  }

  get internalFollowMePresenterSubject(): Subject<IFollowMeAction> {
    return this._internalFollowMePresenterSubject;
  }

  get internalFollowMeSpeakerSubject(): Subject<IFollowMeAction> {
    return this._internalFollowMeSpeakerSubject;
  }

  get externalFollowMeUserSubject(): BehaviorSubject<IFollowMeAction> {
    return this._externalFollowMeUserSubject;
  }

  get onSelectSection(): Subject<SectionContent> {
    return this._onSelectSection;
  }

  get followMeContentModulePage(): Subject<IFollowMeModulePage> {
    return this._followMeContentModulePage;
  }

  get followMeContentContainerData$(): Subject<IFollowMeContainerData> {
    return this._followMeContentContainerData$;
  }

  get followMeContentModuleCameraPosition(): Subject<IFollowMeModuleCameraPosition> {
    return this._followMeContentModuleCameraPosition;
  }

  get followMeContentModuleZoomPosition(): BehaviorSubject<IFollowMeModuleCameraPosition> {
    return this._followMeContentModuleZoomPosition;
  }

  get featureLineUserSectionSubject(): BehaviorSubject<SectionContent> {
    return this._featureLineUserSectionSubject;
  }

  get extFullScreenMode$(): Subject<IExtFullScreenMode> {
    return this._extFullScreenMode$;
  }

  get externalFollowMeQAModeAction$(): Subject<IFollowMeQAAction> {
    return this._externalFollowMeQAModeAction$;
  }

  get followMeScrollTo$(): Subject<IFollowMeScrollTo> {
    return this._followMeScrollTo$;
  }

  get followMeZoomTo$(): Subject<IFollowMeZoomTo> {
    return this._followMeZoomTo$;
  }

  private getSection(sectionId) {
    return sectionId && this.timeLineService.planeListContent[sectionId] ?
      this.timeLineService.planeListContent[sectionId].sectionContent : null;
  }

  private subjectUnsubscribe(subj: Subject<any>) {
    subj.next(true);
    subj.complete();
    subj = null;
  }

  private unsubscribeEmitSetCurrentContentSubscriptions() {
    if (!isEmpty(this._emitSetCurrentContentSubscriptions)) {
      Object.keys(this._emitSetCurrentContentSubscriptions)
        .forEach(id => this._emitSetCurrentContentSubscriptions[id].unsubscribe());
    }
  }

  private emitSetCurrentSection(action: IFollowMeAction | {[actionId: string]: string}) {
    const navigateToSection = (s: SectionContent) => {
      this.router.navigate([], {queryParams: {sid: s.id}, relativeTo: this.activatedRoute});
    };
    const section = this.getSection(action.actionId);
    if (!section) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      return;
    }
    const availableSection = this.timeLineService.maxTopHierarchicalParentIsRegisteredTimeline(section.id);
    if (availableSection.requiredRegistration &&
      !this.timeLineService.doNotCheckRegistrationForCurrentUser(this.timeLineService.currentUser) &&
      !this.timeLineService.getSectionHierarchicalSpeaker(section).find(userId => userId === this.timeLineService.currentUser.userId) &&
      !this.timeLineService.completeUserSectionRegistration.find(id => id === section.getId())) {
      return;
    }
    const parent = this.timeLineService.getSectionContent(availableSection.parentId);
    if (availableSection && availableSection.container) {
      const jSection = this.timeLineService.userRoom(availableSection, this.timeLineService.conferenceUser);
      if (jSection) {
        navigateToSection(jSection);
      }
    } else if (availableSection && parent && parent.container) {
      const jSection = this.timeLineService.userRoom(parent, this.timeLineService.conferenceUser);
      if (jSection && jSection.id === availableSection.id) {
        navigateToSection(jSection);
      } else {
        navigateToSection(parent);
      }
    } else {
      navigateToSection(availableSection);
    }
    this.timeLineService.mobileModeSubject.next({showSectionTree: false});
  }

  public emitSetCurrentContent(action: IFollowMeAction) {
    const sendSubject = () => {
      this._emitSetCurrentContentSubscriptions['list'] = this.timeLineService.sectionChildContentsListSubject
          .pipe(takeUntil(this._unsubscribe), filter(o => o?.sectionId === action.sectionId), take(1))
          .subscribe(object => {
            if (action.contentId.includes(Constants.CONTENT_TYPE_MEETING) && !!this.dailyCoService.meetingContent) {
              this.router.navigate([], {
                queryParams: {cid: 'meeting'},
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'merge'});
              return;
            } else if (action.contentId.includes(Constants.CONTENT_TYPE_SCREEN_SHARING) && !!this.dailyCoService.screenSharingContent &&
               !!this.dailyCoService.dailyCoLoaded.getValue()) {
              this.router.navigate([], {
                queryParams: {cid: 'screensharing'},
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'merge'
              });
              if (this.dailyCoService.screenSharingContent && !(this.dailyCoService.screenSharingContent).stream &&
                (this.dailyCoService.screenSharingContent).streamUrl && this.dailyCoService.player) {
                let unsub = new Subject();
                this.dailyCoService.playM3U8$.pipe(takeUntil(unsub)).subscribe(() => {
                  const elPlayerScreenShare = <HTMLVideoElement>document.getElementById('amazonPlayerScreenShare');
                  if (elPlayerScreenShare) {
                    unsub.next(true);
                    unsub.complete();
                    unsub = null;
                  }
                  if (this.dailyCoService.screenSharingOn$.getValue() && elPlayerScreenShare &&
                        this.dailyCoService.streamingRunning$.getValue()) {
                    this.dailyCoService.playVideo(this.dailyCoService.streamingViewerUrl$.getValue(), elPlayerScreenShare,
                      this.dailyCoService.streamingRunning$.getValue());
                  }
                });
              }
              return;
            } else if (action.contentId.includes(Constants.CONTENT_TYPE_LIVE_STREAMING)) {
              this.router.navigate([], {
                queryParams: {cid: 'live-streaming'},
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'merge'});
              return;
            } else if (action.contentId.includes(Constants.CONTENT_TYPE_SCREEN_SHARING) && !this.dailyCoService.screenSharingContent) {
              this.dailyCoService.followMeScreenSharingContentActive = true;
              return;
            } else if (!isEmpty(object) && !isEmpty(object.list)) {
              const content = object.list.find(o => o.id === action.actionId);
              if (content && this.timeLineService.isContentVisibility(content)) {
                this.router.navigate([], {queryParams: {cid: content.id}, relativeTo: this.activatedRoute, queryParamsHandling: 'merge'})
                  .then(() => this.contentChanged$.next(content));
              }
            }
          });
    };
    const selectedSection = this.timeLineService.selectedSection;
    if (selectedSection && selectedSection.id === action.sectionId) {
      if (this.timeLineService.hasSectionAccessRights(this.timeLineService.currentUser, selectedSection)) {
        this.timeLineService.mobileModeSubject.next({showSectionTree: false});
        sendSubject();
      }
    } else if (selectedSection && selectedSection.id !== action.sectionId) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      this._emitSetCurrentContentSubscriptions['s'] = this.timeLineService.changeSectionSubject
        .pipe(takeUntil(this._unsubscribe), filter(v => v?.id === action.sectionId), take(1))
        .subscribe(value => {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
          if (value.id === action.sectionId) {
            sendSubject();
          }
        });
      this.emitSetCurrentSection({actionId: action.sectionId});
    }
  }

  private emitSetCurrentContentImage(action: IFollowMeAction) {
    const sendAction = () => {
      this.followMeContentModulePage.next({
        userId: action.userId,
        contentId: action.contentId,
        moduleId: action.moduleId,
        viewMode: null,
        pageIndex: Number(action.actionId)
      });
    };
    const sendSubject = () => {
      this._emitSetCurrentContentSubscriptions['list'] = this.timeLineService.sectionChildContentsListSubject
        .pipe(takeUntil(this._unsubscribe), filter(o => o?.sectionId === action.sectionId), take(1))
        .subscribe(object => {
          if (!isEmpty(object) && !isEmpty(object.list)) {
            const content = object.list.find(o => o.id === action.contentId);
            if (content) {
              this.dataService.dispatchSetCurrentContent(cloneDeep(content));
              delay(() => sendAction(), 200);
            }
          }
        });
    };
    const selectedSection = this.timeLineService.selectedSection;
    if (selectedSection && selectedSection.id === action.sectionId) {
      if (this.timeLineService.hasSectionAccessRights(this.timeLineService.currentUser, selectedSection)) {
        this.timeLineService.mobileModeSubject.next({showSectionTree: false});
        sendSubject();
      }
    } else if (selectedSection && selectedSection.id !== action.sectionId) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      this._emitSetCurrentContentSubscriptions['s'] = this.timeLineService.changeSectionSubject
        .pipe(takeUntil(this._unsubscribe), filter(v => v?.id === action.sectionId), take(1))
        .subscribe(value => {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
          if (value.id === action.sectionId) {
            sendSubject();
          }
        });
      this.emitSetCurrentSection({actionId: action.sectionId});
    }
  }

  private emitSetCurrentContentPageNumber(action: IFollowMeAction) {
    const sendSubject = () => {
      this._emitSetCurrentContentSubscriptions['list'] = this.timeLineService.sectionChildContentsListSubject
          .pipe(takeUntil(this._unsubscribe), filter(o => o?.sectionId === action.sectionId), take(1))
          .subscribe(object => {
            if (!isEmpty(object) && !isEmpty(object.list)) {
              const content = object.list.find(o => o.id === action.contentId);
              if (content) {
                this.dataService.dispatchSetCurrentContent(cloneDeep(content));
                this.followMeContentModulePage.next({
                  userId: action.userId,
                  contentId: action.contentId,
                  moduleId: action.moduleId,
                  viewMode: action.viewMode,
                  pageIndex: Number(action.actionId)
                });
              }
            }
          });
    };
    const selectedSection = this.timeLineService.selectedSection;
    if (selectedSection && selectedSection.id === action.sectionId) {
      if (this.timeLineService.hasSectionAccessRights(this.timeLineService.currentUser, selectedSection)) {
        this.timeLineService.mobileModeSubject.next({showSectionTree: false});
        sendSubject();
      }
    } else if (selectedSection && selectedSection.id !== action.sectionId) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      this._emitSetCurrentContentSubscriptions['s'] = this.timeLineService.changeSectionSubject
        .pipe(takeUntil(this._unsubscribe), filter(v => v?.id === action.sectionId), take(1))
        .subscribe(value => {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
          if (value.id === action.sectionId) {
            sendSubject();
          }
        });
      this.emitSetCurrentSection({actionId: action.sectionId});
    }
  }

  private emitSetCurrentContentCameraPosition(action: IFollowMeAction) {
    const sendSubject = () => {
      this._emitSetCurrentContentSubscriptions['list'] = this.timeLineService.sectionChildContentsListSubject
        .pipe(takeUntil(this._unsubscribe), filter(o => o?.sectionId === action.sectionId), take(1))
        .subscribe(object => {
          if (!isEmpty(object) && !isEmpty(object.list)) {
            const content = object.list.find(o => o.id === action.contentId);
            if (content) {
              this.dataService.dispatchSetCurrentContent(cloneDeep(content));
              this.followMeContentModuleCameraPosition.next({
                userId: action.userId,
                contentId: action.contentId,
                moduleId: action.moduleId,
                position: action.anyObject
              });
            }
          }
        });
    };
    const selectedSection = this.timeLineService.selectedSection;
    if (selectedSection && selectedSection.id === action.sectionId) {
      if (this.timeLineService.hasSectionAccessRights(this.timeLineService.currentUser, selectedSection)) {
        this.timeLineService.mobileModeSubject.next({showSectionTree: false});
        sendSubject();
      }
    } else if (selectedSection && selectedSection.id !== action.sectionId) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      this._emitSetCurrentContentSubscriptions['s'] = this.timeLineService.changeSectionSubject
        .pipe(takeUntil(this._unsubscribe), filter(v => v?.id === action.sectionId), take(1))
        .subscribe(value => {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
          if (value.id === action.sectionId) {
            sendSubject();
          }
        });
      this.emitSetCurrentSection({actionId: action.sectionId});
    }
  }

  private emitSetCurrentContentZoomPosition(action: IFollowMeAction) {
    const sendSubject = () => {
      this._emitSetCurrentContentSubscriptions['list'] = this.timeLineService.sectionChildContentsListSubject
        .pipe(takeUntil(this._unsubscribe), filter(o => o?.sectionId === action.sectionId), take(1))
        .subscribe(object => {
          if (!isEmpty(object) && !isEmpty(object.list)) {
            const content = object.list.find(o => o.id === action.contentId);
            if (content) {
              this.dataService.dispatchSetCurrentContent(cloneDeep(content));
              this.followMeContentModuleZoomPosition.next({
                userId: action.userId,
                contentId: action.contentId,
                moduleId: action.moduleId,
                position: action.anyObject
              });
            }
            this.unsubscribeEmitSetCurrentContentSubscriptions();
          }
        });
    };
    const selectedSection = this.timeLineService.selectedSection;
    if (selectedSection && selectedSection.id === action.sectionId) {
      if (this.timeLineService.hasSectionAccessRights(this.timeLineService.currentUser, selectedSection)) {
        this.timeLineService.mobileModeSubject.next({showSectionTree: false});
        sendSubject();
      }
    } else if (selectedSection && selectedSection.id !== action.sectionId) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      this._emitSetCurrentContentSubscriptions['s'] = this.timeLineService.changeSectionSubject
        .pipe(takeUntil(this._unsubscribe), filter(v => v?.id === action.sectionId), take(1))
        .subscribe(value => {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
          if (value.id === action.sectionId) {
            sendSubject();
          }
        });
      this.emitSetCurrentSection({actionId: action.sectionId});
    }
  }

  private emitSetContainerContentDataChange(action: IFollowMeAction) {
    const sendAction = () => {
      this.followMeContentContainerData$.next(action.containerData);
    };
    const sendSubject = () => {
      this._emitSetCurrentContentSubscriptions['list'] = this.timeLineService.sectionChildContentsListSubject
        .pipe(takeUntil(this._unsubscribe), filter(o => o?.sectionId === action.sectionId), take(1))
        .subscribe(object => {
          if (!isEmpty(object) && !isEmpty(object.list)) {
            const content = object.list.find(o => o.id === action.contentId);
            if (content && this.timeLineService.selectedContent?.id !== content.id) {
              this.dataService.dispatchSetCurrentContent(cloneDeep(content));
              delay(() => sendAction(), 200);
            } else if (content && this.timeLineService.selectedContent?.id === content.id) {
              sendAction();
            }
          }
        });
    };
    const selectedSection = this.timeLineService.selectedSection;
    if (selectedSection && selectedSection.id === action.sectionId) {
      if (this.timeLineService.hasSectionAccessRights(this.timeLineService.currentUser, selectedSection)) {
        this.timeLineService.mobileModeSubject.next({showSectionTree: false});
        sendSubject();
      }
    } else if (selectedSection && selectedSection.id !== action.sectionId) {
      this.unsubscribeEmitSetCurrentContentSubscriptions();
      this._emitSetCurrentContentSubscriptions['s'] = this.timeLineService.changeSectionSubject
        .pipe(takeUntil(this._unsubscribe), filter(v => v?.id === action.sectionId), take(1))
        .subscribe(value => {
          this.unsubscribeEmitSetCurrentContentSubscriptions();
          if (value.id === action.sectionId) {
            sendSubject();
          }
        });
      this.emitSetCurrentSection({actionId: action.sectionId});
    }
  }

  restrictParticipantsOnPage() {
    const instantSettings = this.timeLineService.instantSettings;
    if (!this.timeLineService.currentUser || !instantSettings) {
      return false;
    }
    let room;
    const isFollowMeRestricted = instantSettings.followMeSettings &&
      instantSettings.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME &&
      instantSettings.followMeSettings.restrictParticipantsOnPage;
    const userId = this.timeLineService.currentUser.userId;
    return ((!this.timeLineService.isPresenter && !this.timeLineService.isSpeakerAnySection()) ||
      ((this.followMePresenter && this.followMePresenter.userId !== userId && this.timeLineService.followMeEventPGSParticipant.getValue() &&
        !this.timeLineService.parentIsContainer(this.timeLineService.selectedSection)) ||
        (this.followMeSpeaker && this.followMeSpeaker.userId !== userId &&
          this.timeLineService.parentIsContainer(this.timeLineService.selectedSection) &&
          (this.timeLineService.followMeSectionPGSParticipant.getValue() &&
            this.timeLineService.followMeSectionPGSParticipant.getValue()[this.timeLineService.selectedSection.id])))) &&
      (
        (!this.timeLineService.isSpeakerMode && isFollowMeRestricted) ||

        (this.timeLineService.isSpeakerMode && this.mainSpeakerCurrentSection &&
          !(this.mainSpeakerCurrentSection.container || this.timeLineService.parentIsContainer(this.mainSpeakerCurrentSection)) &&
          isFollowMeRestricted) ||

        (this.timeLineService.isSpeakerMode && this.mainSpeakerCurrentSection &&
          (this.mainSpeakerCurrentSection.container || this.timeLineService.parentIsContainer(this.mainSpeakerCurrentSection)) &&
          this.mainSpeakerCurrentSection.followMeSettings &&
          this.mainSpeakerCurrentSection.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME &&
          this.mainSpeakerCurrentSection.followMeSettings.restrictParticipantsOnPage) ||

        (this.timeLineService.isSpeakerMode && !this.mainSpeakerCurrentSection &&
          this.timeLineService.featureLineContentId && this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId) &&
          this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId).container &&
          (room = this.timeLineService.userRoom(this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId),
            this.timeLineService.conferenceUser)) && room.followMeSettings &&
           room.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME && room.followMeSettings.restrictParticipantsOnPage) ||

        (this.timeLineService.isSpeakerMode && !this.mainSpeakerCurrentSection &&
          this.timeLineService.featureLineContentId && this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId) &&
          !this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId).container &&
          isFollowMeRestricted));
  }

  isFollowMeEnable() {
    let room;
    if (this.timeLineService.timelineSlotMode.getValue()) {
      return false;
    }
    const instantSettings = this.timeLineService.instantSettings;
    const isFollowMeMode = instantSettings?.followMeSettings?.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME;
    return (!this.timeLineService.isSpeakerMode && isFollowMeMode) ||

      (this.timeLineService.isSpeakerMode && this.mainSpeakerCurrentSection &&
        !(this.mainSpeakerCurrentSection.container || this.timeLineService.parentIsContainer(this.mainSpeakerCurrentSection)) &&
        isFollowMeMode) ||

      (this.timeLineService.isSpeakerMode && this.mainSpeakerCurrentSection &&
        (this.mainSpeakerCurrentSection.container || this.timeLineService.parentIsContainer(this.mainSpeakerCurrentSection)) &&
        this.mainSpeakerCurrentSection.followMeSettings &&
        this.mainSpeakerCurrentSection.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME) ||

      (this.timeLineService.isSpeakerMode && !this.mainSpeakerCurrentSection &&
        this.timeLineService.featureLineContentId &&
        this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId).container &&
        (room = this.timeLineService.userRoom(this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId),
          this.timeLineService.conferenceUser)) && room.followMeSettings &&
        room.followMeSettings.followMeMode === FOLLOW_ME_MODE.FOLLOW_ME) ||

      (this.timeLineService.isSpeakerMode && !this.mainSpeakerCurrentSection &&
        this.timeLineService.featureLineContentId &&
        !this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId).container &&
        isFollowMeMode);
  }

  restrictAccessToSocialPresentation() {
    if (!this.timeLineService.currentUser) {
      return false;
    }
    const userId = this.timeLineService.currentUser.userId;
    return (!this.timeLineService.isPresenter && !this.timeLineService.isSpeakerAnySection())
      ||
      (this.followMePresenter && this.followMePresenter.userId !== userId &&
        !this.timeLineService.parentIsContainer(this.timeLineService.selectedSection) &&
        (
          // if manager or speaker but "don't follow any body" is false
          ((this.timeLineService.timelineEvent.isManager(userId) ||
          this.timeLineService.hasSpeakerAccessRightsToSection(this.timeLineService.selectedSection)) &&
          (this.timeLineService.followMeEventAsPresenter.getValue() ||
            this.timeLineService.followMeEventPGSParticipant.getValue())) ||
          // if not speaker and not presenter
          (!this.timeLineService.hasSpeakerAccessRightsToSection(this.timeLineService.selectedSection) &&
            !this.timeLineService.timelineEvent.isManager(userId))
        )
      )
      ||
      (this.followMeSpeaker && this.followMeSpeaker.userId !== userId &&
        this.timeLineService.parentIsContainer(this.timeLineService.selectedSection) &&
        (
          ((this.timeLineService.timelineEvent.isManager(userId) ||
              this.timeLineService.hasSpeakerAccessRightsToSection(this.timeLineService.selectedSection)) &&
          ((this.timeLineService.followMeSectionAsPresenter.getValue() &&
             this.timeLineService.followMeSectionAsPresenter.getValue()[this.timeLineService.selectedSection.id]) ||
           (this.timeLineService.followMeSectionPGSParticipant.getValue() &&
             this.timeLineService.followMeSectionPGSParticipant.getValue()[this.timeLineService.selectedSection.id]))) ||

          (!this.timeLineService.hasSpeakerAccessRightsToSection(this.timeLineService.selectedSection) &&
            !this.timeLineService.timelineEvent.isManager(userId))
        )
      );
  }

  restrictAccessToSocialPresentationAsParticipant() {
    if (!this.timeLineService.currentUser) {
      return false;
    }
    const userId = this.timeLineService.currentUser.userId;
    return (!this.timeLineService.isPresenter && !this.timeLineService.isSpeakerAnySection())
      ||
      (this.followMePresenter && this.followMePresenter.userId !== userId &&
        !this.timeLineService.parentIsContainer(this.timeLineService.selectedSection) &&
        this.timeLineService.followMeEventPGSParticipant.getValue())
      ||
      (this.followMeSpeaker && this.followMeSpeaker.userId !== userId &&
        this.timeLineService.parentIsContainer(this.timeLineService.selectedSection) &&
        (this.timeLineService.followMeSectionPGSParticipant.getValue() &&
          this.timeLineService.followMeSectionPGSParticipant.getValue()[this.timeLineService.selectedSection.id])
      );
  }

  private getFollowMeParams(): {settings: FollowMeSettings, workMode: TFollowMeWorkMode, sectionId?: string} {
    const fLineSection = this.timeLineService.getSectionContent(this.timeLineService.featureLineContentId);
    if (fLineSection?.container) {
      const userRoom = this.timeLineService.userRoom(fLineSection, this.timeLineService.conferenceUser);
      if (userRoom) {
        return {settings: userRoom.followMeSettings, workMode: WORK_MODE.PARALLEL, sectionId: userRoom.id};
      }
    }
    return {settings: this.timeLineService.instantSettings.followMeSettings, workMode: WORK_MODE.MAIN};
  }

  // -------- QA Follow Me Internal Listeners -------------
  initFollowMeQAMode() {
    const qaDialogSubscribe = (subscribe: boolean) => {
      if (!subscribe) {
        if (this._initFollowMeQAMode) {
          this._initFollowMeQAMode.unsubscribe();
          this._initFollowMeQAMode = null;
        }
        return;
      }
      this._initFollowMeQAMode = combineLatest([
        this.timeLineService.showQADialog.pipe(pairwise(), takeUntil(this._unsubscribe)),
        this.timeLineService.selectedQASection.pipe(takeUntil(this._unsubscribe)),
        this.timeLineService.qaContent.pipe(takeUntil(this._unsubscribe))])
        .pipe(takeUntil(this._unsubscribe), debounceTime(5))
        .subscribe(([[vDialogOpenPrev, vDialogOpen], vSelQaSection, vQAContent]) => {
          const followMeParams = this.getFollowMeParams();
          if (!this.isFollowMeEnable() || (vDialogOpenPrev === null && !vDialogOpen) || !followMeParams?.settings?.followQa) {
            return;
          }
          const isFollowMeManager = followMeParams.workMode === WORK_MODE.MAIN ?
            this.followMePresenter?.userId === this.timeLineService.currentUser.userId :
            this.followMeSpeaker?.userId === this.timeLineService.currentUser.userId;
          const action: IFollowMeQAAction = {
            openDialog: vDialogOpen,
            qaSectionId: vSelQaSection ? vSelQaSection.fixedSectionType : null,
            secondParentId: vSelQaSection ? this.timeLineService.qaSecondParentId : null,
            contentId: vQAContent ? vQAContent.id : null};
          if (isFollowMeManager && followMeParams.workMode === WORK_MODE.MAIN) {
            this.dataService.setFollowMeQAAction(this.timeLineService.timelineEvent.eventId, action)
              .catch((e) => this.common.log.error(e));
          } else if (isFollowMeManager && followMeParams.workMode === WORK_MODE.PARALLEL) {
            this.dataService.setFollowMeSpeakerQAAction(this.timeLineService.timelineEvent.eventId, followMeParams.sectionId, action)
              .catch((e) => this.common.log.error(e));
          }
        });
    };

    combineLatest([
      this.timeLineService.eventManagers$.pipe(takeUntil(this._unsubscribe)),
      this.timeLineService.eventSpeakers$.pipe(takeUntil(this._unsubscribe))])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(([em, es]: [IManagerMap, IManagerMap]) => {
        qaDialogSubscribe(
          (!!em && !!em[this.timeLineService.currentUser?.userId]) || (!!es && !!es[this.timeLineService.currentUser?.userId]));
      });
  }

  analyzeQAAction(action: IFollowMeQAAction) {
    if (this._timelineSectionContentsList) {
      this._timelineSectionContentsList.unsubscribe();
    }
    if (action.contentId) {
      this._timelineSectionContentsList = this.timeLineService.timelineSectionContentsList
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(list => {
          if (!isEmpty(list)) {
            const qaCn = list.find(cn => cn.id === action.contentId);
            this.timeLineService.qaContent.next(qaCn);
          }
        });
    } else if (!action.contentId && this.timeLineService.qaContent.getValue()) {
      this.timeLineService.qaContent.next(null);
    }
    const selectedQASection = this.timeLineService.getSectionContent(action.qaSectionId);
    if (action.secondParentId && action.secondParentId !== this.timeLineService.rootSection.id &&
      !this.timeLineService.sectionsWithSpecificQAList.find(s => s.id === action.secondParentId)) {
      this.timeLineService.qaSecondParentId = null;
      this.timeLineService.selectedQASection.next(null);
      this.timeLineService.showQADialog.next(false);
      return;
    }
    if (action.openDialog) {
      const sectionChanged = this.timeLineService.qaSecondParentId !== action.secondParentId;
      if (selectedQASection.fixedSectionType !== FIXED_SECTION_TYPE.EASY_SUGGESTED) {
        this.timeLineService.qaSecondParentId = action.secondParentId;
        this.timeLineService.selectedQASection.next(selectedQASection);
      } else
      if (selectedQASection.fixedSectionType === FIXED_SECTION_TYPE.EASY_SUGGESTED && sectionChanged) {
        this.timeLineService.qaSecondParentId = action.secondParentId;
        this.timeLineService.selectedQASection.next(selectedQASection);
      }
    }
    this.timeLineService.showQADialog.next(action.openDialog);
  }

  /** If followMe and restrict participant on page enabled and you restricted for actions */
  hasFollowMeRestrict() {
    return this.isFollowMeEnable() && this.restrictParticipantsOnPage();
  }

  /** If followMe enabled and you have rights to control current section */
  hasFollowMeRight() {
    return this.isFollowMeEnable() &&  this.canSendAction({sectionId: this.timeLineService.selectedSection?.id});
  }

  setFollowMeCurrentMeetingViewMode(viewMode: MEETING_VIEW_MODE, viewModeOption: MEETING_VIEW_MODE.FREE | MEETING_VIEW_MODE.SMALL) {
    if (!viewMode) {
      return Promise.resolve();
    }
    if (!this.timeLineService.parentIsContainer(this.timeLineService.getSectionContent(this.dailyCoService.ownerStreamSectionId))) {
      return this.dataService.setFollowMeCurrentMeetingViewMode(this.timeLineService.timelineEvent.eventId, {
        sectionId: this.dailyCoService.ownerStreamSectionId,
        userId: this.timeLineService.currentUser.userId,
        viewMode: viewMode,
        viewModeOption: viewModeOption
      });
    } else {
      return this.dataService.setFollowMeCurrentMeetingViewModeSpeaker(
        this.timeLineService.timelineEvent.eventId, this.dailyCoService.ownerStreamSectionId, {
          sectionId: this.dailyCoService.ownerStreamSectionId,
          userId: this.timeLineService.currentUser.userId,
          viewMode: viewMode,
          viewModeOption: viewModeOption
        });
    }
  }
}
