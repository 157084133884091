import {AbstractContent} from './AbstractContent';
import {EventQuestion} from '../EventQuestion';

export interface IQuestions {[id: string]: EventQuestion; }

export interface IQuestionnaireContentMap {
  [sectionId: string]: QuestionnaireContent;
}

export class QuestionnaireContent extends AbstractContent {
  type = 'questionnaire';

  questions: IQuestions = {};
  questionsCount: number;
  notes = {};
  acceptAnswers: boolean;
  anonymousAnswers: boolean;
  showResult: boolean;
  // version control system for modules
  releaseVersion: string;
  dirty: boolean;


  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.hasOwnProperty('questions')) {
      this.questions = Object.values(obj.questions || {}).reduce((acc, item: EventQuestion) => {
        acc[item.id] = new EventQuestion(item);
        return acc;
      }, {}) as IQuestions;
    }

    this.questionsCount = obj.questions ? Object.keys(obj.questions).length : 0;
    if (obj.notes) {
      this.notes = obj.notes;
    }
    if (obj.hasOwnProperty('acceptAnswers')) {
      this.acceptAnswers = obj.acceptAnswers;
    }
    if (obj.hasOwnProperty('anonymousAnswers')) {
      this.anonymousAnswers = obj.anonymousAnswers;
    }
    if (obj.hasOwnProperty('showResult')) {
      this.showResult = obj.showResult;
    }
    if (obj.hasOwnProperty('releaseVersion')) {
      this.releaseVersion = obj.releaseVersion;
    }
    if (obj.hasOwnProperty('dirty')) {
      this.dirty = obj.dirty;
    }
  }

  mergeContent(src: QuestionnaireContent) {
    super.mergeContent(src);
    if (this.questionsCount !== src.questionsCount) {
      this.questionsCount = src.questionsCount;
    }
    if (this.notes !== src.notes) {
      this.notes = src.notes;
    }
    if (this.acceptAnswers !== src.acceptAnswers) {
      this.acceptAnswers = src.acceptAnswers;
    }
    if (this.anonymousAnswers !== src.anonymousAnswers) {
      this.anonymousAnswers = src.anonymousAnswers;
    }
    if (this.showResult !== src.showResult) {
      this.showResult = src.showResult;
    }
    if (this.releaseVersion !== src.releaseVersion) {
      this.releaseVersion = src.releaseVersion;
    }
    if (this.dirty !== src.dirty) {
      this.dirty = src.dirty;
    }
    this.questions = Object.values(src.questions || {}).reduce((acc, item: EventQuestion) => {
      acc[item.id] = new EventQuestion(item);
      return acc;
    }, {}) as IQuestions;

  }

  /**
   * Get questions with property "optional" = false
   */
  getStrictQuestions() {
    const result = {};
    if (this.questions) {
      for (const qKey of Object.keys(this.questions)) {
        const qObj: EventQuestion = this.questions[qKey];
        if (!qObj.optional) {
          result[qKey] = qObj;
        }
      }
    }
    return result;
  }

  toObject() {
    const obj = super.toObject();
    Object.keys(obj).forEach(key => obj[key] === undefined && (obj[key] = null));
    for (const key of Object.keys(obj.questions)) {
      if (obj.questions[key] && obj.questions[key] instanceof EventQuestion) {
        obj.questions[key] = obj.questions[key].toObject();
      } else {
        const q = new EventQuestion(obj.questions[key]);
        obj.questions[key] = q.toObject();
      }
    }
    return obj;
  }
}
