import {TEXT_AI_OPTION_LIST} from '../../../../services/ai-text.service';
import {Injectable, signal} from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {IDocumentPathParams, IExtendedDocumentPathParams} from '../../../../services/event-mode-api.service';
import {QUESTION_FORMAT_VERSION2, Quiz, QUIZ_QUESTION_INSIDE_COLLECTIONS, WORD_CLOUD_TEMPLATE_PREFIX} from './quiz-model/quiz';
import {Constants, ILanguageParams, TEMPLATE_TYPE} from '../../../../core/constants';
import {StorageDataService} from '../../../../services/storage-data.service';
import {EventsDataService} from '../../../../services/events-data.service';
import {WordCloudTemplate} from '../../../../model/content/WordCloudTemplate';
import {clone, cloneDeep, isEmpty} from 'lodash';
import {CONTAINER_ITEM_TYPE, ContentContainer, ContentContainerItem} from '../../../../model/content/ContentContainer';
import {filter, firstValueFrom, map, Observable, Subject} from 'rxjs';
import {CommonService} from '../../../../core/common.service';
import {
  IExtQuizTemplate,
  IQuizTemplate,
  QUESTION_TYPE,
  QUESTIONS_TYPES_INCLUDE_FILES,
  TEMPLATE_STORAGE_PATH
} from './quiz-components/shared/quiz-quiestion-types';
import {COMPONENTS} from '../../shared/container-interface';
import {LoginService} from '../../../../login/login.service';
import {EventQuestion, IQuizQuestionsGroupsCorrectAnswersMap} from '../../../../model/EventQuestion';
import {FollowMeService} from '../../../../services/follow-me.service';
import {TimeLineService} from '../../../../services/time-line.service';
import {UtilsService} from '../../../../core/utils.service';
import {
  createCompatibleQuestionGroup,
  createCompatibleQuizQuestionGroupsFormat
} from './quiz-components/shared/lib/quiz-question-compatibility-lib';

interface IImageMetadata {
  mimeType: string;
  fileName: string;
}

interface AnswerStatus {
  isCorrect: boolean;
  questionId: string | null;
}

interface QuestionProperties {
  properties?: any;
  questionId: string;
}

const BASE64_SUFFIX = ';base64,';
export const PRESENTER_BACKGROUND_COLOR = '#F6F8F9';

@Injectable({
  providedIn: 'root'
})
export class QuizService extends AbstractContainerService {
  // ~~~~~~ subjects for used only in MyKnowledge ~~~~~~
  public checkAnswer$ = new Subject<any>();
  public qProperties$ = new Subject<QuestionProperties>();
  public isAnswerCorrect$ = new Subject<AnswerStatus>();
  public isAnswerSelected$ = new Subject<{ isAnswerSelected: boolean, questionId: string }>();
  public submittingAnswers$ = new Subject<boolean>();
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  /**
   * subject for exchange messages within the questionnaire from QuizQuestionSheetComponent to every question type component
   * record params [subjectId -> contentId.containerId.questionId]: any string.
   *
   * method for generate subjectId
   * @link quiz-question-ai-lib.getCurrentSubjectId(...)
   */
  public aiGenerated$ = new Subject<Record<string, string>>();
  /**
   * subject for exchange messages within the questionnaire from question type component to QuizQuestionSheetComponent
   * record params [subjectId -> contentId.containerId.questionId]: action.
   *
   * for manual generate subjectId use algorithm from
   * @link quiz-question-ai-lib.getCurrentSubjectId(...)
   *
   * can use this.subjectId in results form if form inherited from base QuestionResults form
   */
  public aiAction$ = new Subject<Record<string, TEXT_AI_OPTION_LIST>>();

  public static getQuizBackgroundStyle = (container: ContentContainerItem, isPresenter: boolean) => {
    return container.getBackgroundStyleFromOptions(isPresenter ? PRESENTER_BACKGROUND_COLOR : null);
  }


  constructor(private storageDataService: StorageDataService,
              private dataService: EventsDataService,
              private common: CommonService,
              private loginService: LoginService,
              private followMeService: FollowMeService,
              private timelineService: TimeLineService) {
    super();
  }

  async save(storagePath: string, data: Quiz, documentPathParams: IDocumentPathParams,
             saveAsTemplate: boolean, draft: boolean): Promise<any> {
    let srcQuiz: Quiz;
    const obj = await this.dataService.getContentDocument({...documentPathParams, dirty: draft});
    if (!isEmpty(obj)) {
      let qObj;
      srcQuiz = (qObj = (obj.items || []).find(it => it.id === documentPathParams.containerId)) ? new Quiz(qObj.data) : null;
      if (!isEmpty(srcQuiz?.questions)) {
        // find deleted word cloud questions and delete template from storage
        const prevWCloudQuestions = Object.values(srcQuiz.questions || {})
          .filter(q => q.storypoint === QUESTION_TYPE.WORD_CLOUD &&
            !q.options.wordCloudTemplate?.use3DWordCloud && q.files[0]);
        const currWCloudQuestions = Object.values(data.questions || {})
          .filter(q => q.storypoint === QUESTION_TYPE.WORD_CLOUD &&
            !q.options.wordCloudTemplate?.use3DWordCloud && q.files[0]);
        const deletedWCloudQuestions = prevWCloudQuestions.filter(q => !currWCloudQuestions.find(cq => cq.id === q.id));
        for (const question of deletedWCloudQuestions) {
          if (question.files[0].includes(`${WORD_CLOUD_TEMPLATE_PREFIX}`)) {
            const name = this.common.utils.extractFileNameFromUrl(question.files[0]);
            await this.storageDataService.deleteObjectFromStorageByDataPath(`${storagePath}/${question.id}`, name)
              .catch(e => this.dataService.common.log.error(e));
          }
        }
        // find deleted map of matching questions and delete template from storage
        const prevMapQuestions = Object.values(srcQuiz.questions || {})
          .filter(q => q.storypoint === QUESTION_TYPE.MATCHING_MAP);
        const currMapQuestions = Object.values(data.questions || {})
          .filter(q => q.storypoint === QUESTION_TYPE.MATCHING_MAP);
        const deletedMapQuestions = prevMapQuestions.filter(q => !currMapQuestions.find(cq => cq.id === q.id) ||
          (currMapQuestions.find(cq => cq.storypoint === QUESTION_TYPE.MATCHING_MAP &&
              cq.id === q.id && !!cq.files[0]) &&
            q.storypoint !== QUESTION_TYPE.MATCHING_MAP && !q.files[0]) ||
          (currMapQuestions.find(cq => cq.id === q.id &&
            q.storypoint === QUESTION_TYPE.MATCHING_MAP && cq.storypoint === QUESTION_TYPE.MATCHING_MAP &&
            this.common.utils.isFBUrl(q.files[0], documentPathParams.eventId) &&
            (isEmpty(cq.files) || cq.files[0].includes(BASE64_SUFFIX)))));
        for (const question of deletedMapQuestions) {
          const name = this.common.utils.extractFileNameFromUrl(question.files[0]);
          if (name) {
            await this.storageDataService.deleteObjectFromStorageByDataPath(`${storagePath}/${question.id}`, name)
              .catch(e => this.dataService.common.log.error(e));
          }
        }
      }
    }
    const wordCloud2DQuestionsList = Object.values(data.questions || {})
      .filter(q => q.storypoint === QUESTION_TYPE.WORD_CLOUD &&
        !q.options.wordCloudTemplate?.use3DWordCloud && q.files[0]);
    const uploadMethod = !saveAsTemplate ? 'uploadAnyObjectToStorageByDataPath' : 'uploadAnyImageToStorage';
    for (const question of wordCloud2DQuestionsList) {
      if (question.options.wordCloudTemplate.id.startsWith(Constants.WORD_CLOUD_ID_DEFAULT_PREFIX)) {
        const defTemplateId = question.options.wordCloudTemplate.id.split('-')[1];
        const pathFrom = defTemplateId + '.png';
        const pathTo = `${storagePath}/${question.id}/${WORD_CLOUD_TEMPLATE_PREFIX}${question.id}-${new Date().getTime()}.png`;
      await this.storageDataService.copyStorageDataObject(`word_cloud_templates/templates/${pathFrom}`, pathTo)
        .then(() => this.storageDataService.getStorageDataURL(pathTo)
          .then(async url => {
            const tem = new WordCloudTemplate(question.options.wordCloudTemplate);
            tem.id = WordCloudTemplate.genId();
            question.files[0] = url;
            question.options.wordCloudTemplate = tem;
            if (srcQuiz?.questions && srcQuiz.questions[question.id] && srcQuiz.questions[question.id].files[0]) {
              const fileName = this.common.utils.extractFileNameFromUrl(srcQuiz.questions[question.id].files[0]);
              await this.storageDataService.deleteObjectFromStorageByDataPath(`${storagePath}/${question.id}`, fileName)
                .catch(e => this.dataService.common.log.error(e));
            }
            return true;
          }).catch(e => this.dataService.throwFirestoreError(e))
        ).catch(e => this.dataService.throwFirestoreError(e));
      } else if (question.files[0]?.includes(BASE64_SUFFIX)) {
        const name = `${WORD_CLOUD_TEMPLATE_PREFIX}${question.id}-${new Date().getTime()}.png`;
        const path = `${storagePath}/${question.id}`;
        question.files[0] = await this.storageDataService[uploadMethod](path, name, question.files[0])
          .then(snapshot => snapshot.ref.getDownloadURL())
          .catch(e => {
            question.files = [];
            this.common.log.error(e);
          });
      }
    }
    const matchingMapQuestionsList = Object.values(data.questions || {})
      .filter(q => q.storypoint === QUESTION_TYPE.MATCHING_MAP && q?.files[0]?.includes(BASE64_SUFFIX));
    for (const question of matchingMapQuestionsList) {
        const path = `${storagePath}/${question.id}`;
        const metadataParams = this.getImageMetadataParams(question.files[0]);
      await this.storageDataService[uploadMethod](path, `${this.common.utils.addTimeToFileName(metadataParams.fileName)}`,
          question.files[0], metadataParams.mimeType)
        .then(snapshot => snapshot.ref.getDownloadURL()
          .catch(e => this.common.log.error(e))
          .then(url => question.files[0] = url))
        .catch(e => this.common.log.error(e));
    }
    if (!saveAsTemplate) {
      await this.saveLazyFields(data, documentPathParams, draft);
    }
    return Promise.resolve(data);
  }

  async delete(storagePath: string, data: Quiz, documentPathParams: IDocumentPathParams,
               isDeleteTemplate: boolean, draft: boolean): Promise<boolean> {
    const wordCloudQuestionsList = Object.values(data.questions || {}).filter(q => !isEmpty(q.files) && !!q.files[0]);
    const deleteMethod = !isDeleteTemplate ? 'deleteObjectFromStorageByDataPath' : 'deleteObjectFromClientStorage';
    for (const question of wordCloudQuestionsList) {
      if (question.files[0]?.includes(`${WORD_CLOUD_TEMPLATE_PREFIX}`)) {
        const name = this.common.utils.extractFileNameFromUrl(question.files[0]);
        await this.storageDataService[deleteMethod](`${storagePath}/${question.id}`, name)
          .catch(e => this.dataService.common.log.error(e));
      }
    }
    const matchingMapQuestionsList = Object.values(data.questions || {})
      .filter(q => q.storypoint === QUESTION_TYPE.MATCHING_MAP && !isEmpty(q.files) && !!q.files[0]);
    for (const question of matchingMapQuestionsList) {
      const name = this.common.utils.extractFileNameFromUrl(question.files[0]);
      if (name) {
        await this.storageDataService[deleteMethod](`${storagePath}/${question.id}`, name)
          .catch(e => this.dataService.common.log.error(e));
      }
    }
    if (documentPathParams && !isDeleteTemplate) {
      await this.dataService.deleteContainerDocumentWithInsideCollections(documentPathParams, QUIZ_QUESTION_INSIDE_COLLECTIONS, draft)
        .then(() => this.storageDataService.deleteContainerUsersFolderObjectsFromStorageByDataPath(
          documentPathParams.eventId, documentPathParams.contentId, documentPathParams.containerId))
        .catch(e => this.dataService.common.log.error(e));
    }
    return Promise.resolve(true);
  }

  async relocateTo(oldDocumentPathParams: IDocumentPathParams,
                   newDocumentPathParams: IDocumentPathParams, draft: boolean): Promise<boolean> {
    return await this.dataService.relocateContainerDocumentInsideCollections(
      oldDocumentPathParams,
      newDocumentPathParams,
      QUIZ_QUESTION_INSIDE_COLLECTIONS, draft)
      .catch(e => this.dataService.common.log.error(e));
  }

  async checkDeletedConstraint(documentPathParams: IDocumentPathParams): Promise<boolean> {
    return this.dataService.getQuizConstraint(documentPathParams)
      .then(constraint => {
        if (!isEmpty(constraint)) {
          if (!Object.keys(constraint[documentPathParams.containerId] || {})
            .every(qId => isEmpty(constraint[documentPathParams.containerId][qId]?.contents))) {
            this.dataService.common.showPopupError(this.dataService.common.i18n('edit_dialog.can_not_remove_questionnaire_dialog.body'));
            return false;
          }
        }
        return true;
      });
  }

  async getContentContainerAllQuizUserAnswers(content: ContentContainer, userId: string, containSomeAnswers?: (value: boolean) => void) {
    let someAnswers = false;
    for (const item of content.items) {
      if (item.type === CONTAINER_ITEM_TYPE.QUIZ) {
        const documentPathParams: IDocumentPathParams = {
          eventId: content.eventId,
          sectionId: content.parentId,
          contentId: content.id,
          containerId: item.id
        };
        const answers = await firstValueFrom(this.dataService.getQuizQuestionsAnswersByUser(documentPathParams,
          userId, this.loginService.applicationMode));
        const questions = (item.data as Quiz).questions || {};
        for (const qKey of Object.keys(questions)) {
          if (!someAnswers && !isEmpty(((answers || {})[qKey] || {}).answers)) {
            someAnswers = true;
          }
          questions[qKey].answers = {[answers.userId]: ((answers || {})[qKey] || {}).answers};
        }
      }
    }
    if (containSomeAnswers) {
      containSomeAnswers(someAnswers);
    }
    return content;
  }

  protected getImageMetadataParams(data: string): IImageMetadata {
    const getMimeType = (d: string) => {
      const m = d.match(/data:(.+)/);
      if (m && m.length === 2) {
        return m[1];
      }
      return 'image/png';
    };

    const metadata = data.substring(0, data.indexOf(BASE64_SUFFIX));
    const params = metadata.split(';');
    const mimeTypeParam = params.find(p => p.includes('mimeType'));
    const mimeType = mimeTypeParam ? mimeTypeParam.split('=')[1] : getMimeType(metadata);
    const fileNameParam = params.find(p => p.includes('fileName'));
    const fileName = fileNameParam ? fileNameParam.split('=')[1] :
      `${this.common.utils.generateRandomString(10)}-${new Date().getTime()}.${mimeType.split('/')[1]}`;
    return {mimeType, fileName};
  }

  prepareImageMetadata(imageBase64: string, fileName: string) {
    const fileNameParts = fileName.split('.');
    const newFileName = `${this.common.utils.generateRandomString(16)}.${fileNameParts[fileNameParts.length - 1]}`;
    const mimeType = imageBase64.substring(imageBase64.indexOf('data:') + 'data:'.length, imageBase64.indexOf(BASE64_SUFFIX));
    const metadata = imageBase64.substring(0, imageBase64.indexOf(BASE64_SUFFIX));
    const extMetadata = `${metadata};mimeType=${mimeType};fileName=${newFileName}`;
    return imageBase64.replace(metadata, extMetadata);
  }

  protected getQuizStoragePath(templateType: TEMPLATE_TYPE, params: {userId?: string, templateId?: string}) {
    const path = clone(TEMPLATE_STORAGE_PATH[templateType]);
    return Object.keys(params).reduce((acc, pName) => {
      acc = acc.replace(`{${pName}}`, params[pName]);
      return acc;
    }, path);
  }

  async saveTemplate(template: IQuizTemplate, quiz: Quiz, documentPathParams: IDocumentPathParams, dirty: boolean) {
    let qz = (new Quiz(cloneDeep(quiz))).toObject();
    if (!template.id) {
      // exists template always contain files in base64. this FOR need only for save template from exist quiz saved in db
      for (const qId of Object.keys(qz.questions)) {
        const question = qz.questions[qId];
        question.questionFormatVersion = QUESTION_FORMAT_VERSION2;
        if (QUESTIONS_TYPES_INCLUDE_FILES.includes(question.storypoint) && !isEmpty(question.files) &&
          question.files.filter(v => !!v).some(f => !f.includes(BASE64_SUFFIX))) {
          const params: IExtendedDocumentPathParams = {
            clientId: this.loginService.client_id$.getValue(),
            location: this.dataService.getDBPath(),
            dirty: dirty,
            ...documentPathParams
          };
          const urlToBASE64Scheme = COMPONENTS[CONTAINER_ITEM_TYPE.QUIZ].urlToBASE64Scheme;
          const quizObject: any = await this.dataService.getEncodedBase64ContentContainerItem(params, urlToBASE64Scheme, null);
          if (quizObject) {
            const dbq = quizObject.data.questions[qId];
            if (dbq && !isEmpty(dbq.files) && !isEmpty(dbq.files.filter(v => !!v))) {
              const names = [];
              question.files.filter(v => !!v).forEach(f => names.push(this.common.utils.extractFileNameFromUrl(f)));
              question.files = dbq.files.filter(v => !!v);
              question.files.forEach((f, ind) => question.files[ind] = this.prepareImageMetadata(f, names[ind]));
            } else {
              question.files = [];
            }
          } else {
            question.files = [];
          }
        } else if (!QUESTIONS_TYPES_INCLUDE_FILES.includes(question.storypoint) && !isEmpty(question.files)) {
          question.files = [];
        }
      }
    }
    const templateId = this.dataService.generateNewDocumentId();
    const userId = this.loginService.getAppUser().userId;
    const path = this.getQuizStoragePath(template.type, {userId: userId, templateId: templateId});
    qz = await this.save(path, qz, documentPathParams, true, false);
    const tem = cloneDeep(template);
    delete tem.id;
    return this.dataService.saveQuizTemplate(templateId, template.type, {...tem, template: qz})
      .then(async () => {
        if (template.id) {
          // load and delete exist template after save edited version with new id
          const srcTemplate = await this.dataService.loadQuizTemplate(template.id, template.type);
          if (srcTemplate) {
            await this.deleteTemplate(template.id, template.type, srcTemplate.template);
          }
        }
        return true;
      });
  }

  async deleteTemplate(templateId: string, templateType: TEMPLATE_TYPE, quiz: Quiz) {
    return this.dataService.deleteQuizTemplate(templateId, templateType)
      .then(() => {
        const userId = this.loginService.getAppUser().userId;
        const path = this.getQuizStoragePath(templateType, {userId: userId, templateId: templateId});
        return this.delete(path, quiz, null, true, false);
      });
  }

  async createQuizFromTemplate(eventId: string, templateId: string, templateType: TEMPLATE_TYPE, quiz: Quiz) {
    const qz = cloneDeep(quiz);
    for (const question of Object.values(qz.questions)) {
      if (question.questionFormatVersion !== QUESTION_FORMAT_VERSION2) {
        const group = createCompatibleQuestionGroup(question);
        question.groupsCorrectAnswers = group ?? [];
      }
      if (!isEmpty(question.files)) {
        for (let i = 0; i < question.files.length; i++) {
          const file = question.files[i];
          if (file) {
            const name = this.common.utils.extractFileNameFromUrl(file);
            const userId = this.loginService.getAppUser().userId;
            const path = this.getQuizStoragePath(templateType, {userId: userId, templateId: templateId});
            const base64: any = await this.dataService.getEncodedBase64Path(eventId, `${path}/${question.id}/${name}`);
            question.files[i] = !isEmpty(base64.data) ? this.prepareImageMetadata(base64.data, name) : null;
          }
        }
      }
    }
    return new Quiz(qz);
  }

  loadTemplatesList(templateType: TEMPLATE_TYPE, languageParams: ILanguageParams): Observable<IExtQuizTemplate[]> {
    return this.dataService.loadQuizTemplatesList(templateType)
      .pipe(map((list: IQuizTemplate[]) => {
        list.forEach((t: IExtQuizTemplate) => {
          let annotation = `${t.title} ${t.description ?? ''}`;
          const quiz = t.template as Quiz;
          Object.values(quiz?.questions ?? {})
            .map(q => new EventQuestion(q))
            .forEach((q: EventQuestion) => {
              annotation += ' ' + q.getCaptionByLanguage(languageParams);
            });
          t.findAnnotation = annotation;
        });
        return list as IExtQuizTemplate[];
      }));
  }

  getQuestionFollowMePoolAction(documentPathParams: IDocumentPathParams, questionId: string) {
    return this.dataService.getFollowMePoolAction(documentPathParams.eventId)
      .pipe(filter(action => this.followMeService.isFollowMeEnable() && action &&
        action.selectedContentId === documentPathParams.contentId &&
        action.selectedContainerId === documentPathParams.containerId &&
        action.selectedQuestionId === questionId));
  }

  async setQuestionFollowMePoolAction(documentPathParams: IDocumentPathParams, questionId: string, answer: any) {
    if (this.followMeService.hasFollowMeRight()) {
      return this.dataService.setFollowMePoolAction(this.timelineService.event.eventId, {
        selectedContentId: documentPathParams.contentId,
        selectedContainerId: documentPathParams.containerId,
        selectedQuestionId: questionId,
        selectedAnswerId: answer
      });
    }
  }

  loadLazyFields(quiz: Quiz, documentPathParams: IDocumentPathParams, draft: boolean) {
    return firstValueFrom(this.dataService.loadQuizQuestionsGroupsCorrectAnswers(documentPathParams, draft))
      .then((groups: IQuizQuestionsGroupsCorrectAnswersMap) => {
        const qz = cloneDeep(quiz);
        if (!isEmpty(groups)) {
          for (const qId of Object.keys(qz.questions)) {
            if (!isEmpty(groups[qId])) {
              qz.questions[qId].groupsCorrectAnswers = groups[qId];
            }
          }
        } else {
          createCompatibleQuizQuestionGroupsFormat(qz);
        }
        return qz;
      });
  }

  saveLazyFields(quiz: Quiz, documentPathParams: IDocumentPathParams, draft: boolean) {
    const groups: IQuizQuestionsGroupsCorrectAnswersMap = {};
    // if all the answers do not have groups then we do not save because
    // perhaps they were not loaded, and only the document itself is saved.
    if (Object.values(quiz.questions).every(q => isEmpty(q.groupsCorrectAnswers))) {
      return Promise.resolve();
    }
    for (const qId of Object.keys(quiz.questions)) {
      if (!isEmpty(quiz.questions[qId].groupsCorrectAnswers) &&
        quiz.questions[qId].groupsCorrectAnswers
          .some(g => !isEmpty(g.items) || !isEmpty(g.correctEquality) || !isEmpty(g.columnsCorrectAnswers))) {
        groups[qId] = quiz.questions[qId].groupsCorrectAnswers.map(g => UtilsService.classToObject(g));
      } else {
        // document this group will be deleted on save groups
        groups[qId] = null;
      }
      delete quiz.questions[qId].groupsCorrectAnswers;
      quiz.questions[qId].questionFormatVersion = QUESTION_FORMAT_VERSION2;
    }
    return this.dataService.saveQuizQuestionsGroupsCorrectAnswers(groups, documentPathParams, draft);
  }

  async loadEncodedAndPreparedBase64File(file: string) {
    if (file?.includes(BASE64_SUFFIX) || !file) {
      return file;
    }
    const name = this.common.utils.extractFileNameFromUrl(file);
    const params = this.common.utils.splitFileNameUrl(file);
    const clientIdIndex = params.indexOf(this.loginService.client_id$.getValue());
    if (clientIdIndex > -1) {
      const path = params.filter((p, index) => index > clientIdIndex).join('/');
      const base64: any = await this.dataService.getEncodedBase64Path(params[clientIdIndex + 2], `${path}`);
      return !isEmpty(base64.data) ? this.prepareImageMetadata(base64.data, name) : null;
    }
    return null;
  }

  async setTyping(documentPath: IDocumentPathParams, questionId) {
    await this.dataService.setTypingQuestion(documentPath, questionId, this.loginService.getAppUser());
  }

}
