import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {customIcons} from './icons-list';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class IconsModule {

  constructor(
    domSanitizer: DomSanitizer,
    iconRegistry: MatIconRegistry
  ) {
    for (const icon of customIcons) {
      iconRegistry.addSvgIcon(
        icon.name,
        domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    }
  }
}
