import { Pipe, PipeTransform } from '@angular/core';
import { interval, map, of, timer } from 'rxjs';

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNow implements PipeTransform {

  transform(value: Date | number): any {
    return interval(1000).pipe(map(() => this.calculate(value)));
  }

  calculate(value: Date | number) {
    const now = new Date();

    const date = new Date(value);

    const timeDifferenceInSeconds = (now.getTime() - date.getTime()) / 1000;

    const minutes = Math.floor(timeDifferenceInSeconds / 60);
    const seconds = Math.floor(timeDifferenceInSeconds % 60);

    return `${minutes}''${seconds.toString().padStart(2, `0`)}'`;
  }

}
