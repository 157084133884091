import {Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {User, UsersService} from '@ninescopesoft/core';
import {filter, firstValueFrom, interval, Observable, startWith, switchMap, take} from 'rxjs';
import {CommonService} from '../../core/common.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {UntypedFormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {StdComponent} from '../../core/std-component';

@Component({
  selector: 'app-users-select-list',
  templateUrl: './users-select-list.component.html',
  styleUrls: ['./users-select-list.component.scss']
})
export class UsersSelectListComponent extends StdComponent implements OnInit {

  @Input() users: User[] = [];
  @Output() usersChange = new EventEmitter<User[]>();
  @Input() disabled: boolean;

  private _showFindUser = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userControl = new UntypedFormControl();
  userOptions: Observable<User[]>;

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  constructor(protected override injector: Injector,
              private common: CommonService,
              public usersService: UsersService) {
    super(injector);
  }

  ngOnInit(): void {
    this.userOptions = this.userControl.valueChanges
      .pipe(
        startWith(''),
        filter(it => typeof it === 'string'),
        switchMap((value: string): Observable<User[]> => this.usersService.search(value)),
        this.takeUntilAlive()
      );
    this.userControl.disable();
  }

  get showFindUser(): boolean {
    return this._showFindUser;
  }

  set showFindUser(value: boolean) {
    this._showFindUser = value;
    if (value) {
      this.userControl.enable();
    } else {
      this.userControl.disable();
    }
  }

  addUser(event: MatAutocompleteSelectedEvent): void {
    const u = <User>event.option.value;
    if (!this.users.find(o => o.id === u.id)) {
      this.users.push(u);
      this.usersChange.emit(this.users);
    }
    this.clearInput();
  }

  async removeUser(id: string) {
    if (await firstValueFrom(this.common.confirm(this.common.i18n('edit_dialog.confirm_dialog.delete.reg.user.body'), ''))) {
      this.users = this.users.filter(m => m.id !== id);
      this.usersChange.emit(this.users);
    }
  }

  showUserList() {
    this.showFindUser = true;
    interval(150).pipe(take(1)).subscribe(() => {
      this.userInput.nativeElement.focus();
      this.userInput.nativeElement.select();
    });
  }

  clearInput() {
    this.userInput.nativeElement.value = '';
    this.userControl.setValue('');
    this.showFindUser = false;
  }
}
