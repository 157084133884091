import {Component, Injector, OnInit} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {EasyzoomDocumentEditorDialogComponent} from '../easyzoom-document-editor-dialog/easyzoom-document-editor-dialog.component';
import {CommonService} from '../../../../../core/common.service';

@Component({
  selector: 'app-easyzoom-document',
  templateUrl: './easyzoom-document.component.html',
  styleUrls: ['./easyzoom-document.component.scss']
})
export class EasyzoomDocumentComponent extends AbstractContainerComponent implements OnInit {

  easyzoomUrl$ = new BehaviorSubject<any>(null);

  constructor(protected injector: Injector,
              private dialog: MatDialog,
              private common: CommonService) {
    super(injector);
  }

  ngOnInit(): void {
    this.data$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        this.easyzoomUrl$.next(this.common.utils.getURL(value));
      });
  }

  protected inputFollowMeData(value) {
  }

  onEdit(): Promise<boolean> {
    const dialogRef = this.dialog.open(EasyzoomDocumentEditorDialogComponent, {
      width: '500px',
      height: '250px',
      disableClose: true,
      data: this.data
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = result;
        }
        return !!result;
      });
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }


}
