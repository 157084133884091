import {Component, Injector} from '@angular/core';
import {UPLOAD_TYPE} from '../../../../../../../core/upload.service';
import {cloneDeep, difference, isEmpty, isEqual, uniq, uniqWith} from 'lodash';
import {firstValueFrom} from 'rxjs';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {MatchingMapEditorDialogComponent} from '../matching-map/matching-map-editor-dialog/matching-map-editor-dialog.component';
import {AnswerRelation} from '../../../../../../../model/EventQuestion';

@Component({
  selector: 'app-question-map-mathing-editor',
  templateUrl: './question-map-mathing-editor.component.html',
  styleUrls: ['./question-map-mathing-editor.component.scss']
})
export class QuestionMapMathingEditorComponent extends AbstractQuizQuestionAnswersEditorComponent  {

  loadedImage = true;
  uppy: any;

  constructor(protected injector: Injector) {
    super(injector);
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.SINGLE_IMAGE, null, (result) => {
      this.question.files[0] = !isEmpty(result) ? result[0].response : null;
      if (this.question.files[0]) {
        const prefix = this.common.utils.generateRandomString(4).toLowerCase();
        const fileName = `${result[0].id.replace(/\//g, '-').replace('uppy', prefix)}.${result[0].extension}`;
        const mimeType = result[0].type;
        const metadata = this.question.files[0].substring(0, this.question.files[0].indexOf(';base64,'));
        const extMetadata = `${metadata};mimeType=${mimeType};fileName=${fileName}`;
        this.question.files[0] = this.question.files[0].replace(metadata, extMetadata);
      }
      this.uploadService.closeModal(this.uppy);
    });
  }

  uploadImage() {
    if (!this.question.files[0]) {
      this.uploadService.openUploadWindow(this.uppy);
    } else {
      this.question.files = [];
    }
  }

  editMatchingMap(groupId: string | 'default') {
    const index = this.question.groupsCorrectAnswers.findIndex(g => groupId === 'default' ? g.defaultGroup : g.id === groupId);
    const dialogRef = this.dialog.open(MatchingMapEditorDialogComponent, {
      data: {
        answerData: this.answerData,
        correctEquality: this.question.groupsCorrectAnswers[index].correctEquality,
        imageUrl: this.question.files[0],
        groupId: groupId
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.question.groupsCorrectAnswers[index].correctEquality = result.correctEquality;
          if (groupId === 'default') {
            this.groupsCorrectAnswers$.getValue().forEach(g => this.applyDefaultTo(g.id));
            this.question.relations = uniqWith(this.question.groupsCorrectAnswers.filter(q => q.defaultGroup).reduce((acc, g) => {
              acc.push(...g.correctEquality.filter(ce => !isEmpty(ce.mapPosition))
                .map(ce => new AnswerRelation({id: ce.id, mapPosition: ce.mapPosition})));
              return acc;
            }, []), isEqual);
          }
        }
        return !!result;
      });
  }

  validate(): IValidated {
    return !isEmpty(this.dataSource.data) && !this.dataSource.data.some(o => isEmptyCaption(o.answer)) ? {validated: true} :
      {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }

  onDestroy() {
    super.onDestroy();
    this.uppy.close();
  }

  addGroupAnswers(): string {
    const groupId = super.addGroupAnswers();
    this.applyDefaultTo(groupId);
    return groupId;
  }

  protected applyDefaultTo(groupId: string) {
    const defGroup = this.question.groupsCorrectAnswers.find(g => g.defaultGroup);
    const selfGroup = this.question.groupsCorrectAnswers.find(g => g.id === groupId);
    const defIds = uniq(defGroup.correctEquality.map(ce => ce.id));
    const selfIds = uniq(selfGroup.correctEquality.map(ce => ce.id));
    const addedIds = difference(defIds, selfIds);
    const deleteIds = difference(selfIds, defIds);
    addedIds.forEach(addId => {
      const ce = cloneDeep(defGroup.correctEquality.find(o => o.id === addId));
      if (ce) {
        ce.answerId = null;
        selfGroup.correctEquality.push(ce);
      }
    });
    deleteIds.forEach(deleteId => {
      const count = selfGroup.correctEquality.filter(o => o.id === deleteId).length;
      for (let i = 0; i < count; i++) {
        const index = selfGroup.correctEquality.findIndex(o => o.id === deleteId);
        if (index > -1) {
          selfGroup.correctEquality.splice(index, 1);
        }
      }
    });
    defIds.forEach(defId => {
      const defce = defGroup.correctEquality.find(o => o.id === defId);
      const sce = selfGroup.correctEquality.find(o => o.id === defId);
      if (defce && sce) {
        sce.mapPosition = cloneDeep(defce.mapPosition);
      }
    });
  }
}
