import {UPLOAD_TYPE, UploadService} from '../../../../../../../core/upload.service';
import {BehaviorSubject, Subject, take} from 'rxjs';

import {StorageDataService} from '../../../../../../../services/storage-data.service';
import {formatSize, getExtension} from '../../shared/lib/question-upload-files-utils';
import {IUploadFile, IUploadFileParticipantData} from '../../question-upload-files/upload-file-model/UploadFile';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {IDocumentPathParams} from '../../../../../../../services/event-mode-api.service';
import {Constants} from '../../../../../../../core/constants';
import {CommonService} from '../../../../../../../core/common.service';
import {IUploadSelectedFileTypes} from '../question-table-editor/table-poll-model/TablePollModel';


export class UploadFilesUtils {

  readonly DEFAULT_SIZE_LIMIT = Constants.FT_AUDIO_SIZE;
  readonly DEFAULT_LARGE_SIZE_LIMIT = Constants.FT_VIDEO_SIZE;
  readonly imageExtensions = Constants.FILE_TYPES[Constants.FT_IMAGE].extension.filter(ext => ext !== 'HEIC');

  uppy: any;
  replacementUppy: any;
  maxNumberOfFiles: number;
  itemToDelete: any;
  fileData: IUploadFileParticipantData[] = [];
  fileData$ = new BehaviorSubject<IUploadFileParticipantData[]>([]);
  answers;
  uploadResult$ = new Subject();
  deleteResult$ = new Subject();

  constructor(protected common: CommonService,
              private storageDataService: StorageDataService,
              private uploadService: UploadService,
              public question: EventQuestion,
              public currentUserId: string,
              public documentPathParams: IDocumentPathParams) {
  }

  destroy() {
    this.uppy?.close();
    this.replacementUppy?.close();
  }

  private getDashboardNote(maxNumberOfFiles: number, selectedTypes: any) {
    return this.common.utils.i18n('questionnaire.upload.file.participants.upload.file.info',
      {maxNoOfFiles: maxNumberOfFiles, fileTypes: this.formatSelectedTypes(selectedTypes)});
  }

  getUploadFileParticipantDetailsData(item: IUploadFile) {
    const dataObj: IUploadFileParticipantData = {
      id: `${item.id}`,
      name: item.name,
      size: formatSize(item.size),
      extension: getExtension(item.name),
      metaType: item.metaType,
      isImage: this.imageExtensions.includes(item.name.split('.').pop().toUpperCase()),
      imageUrlLoaded: false,
      imageSrc: null,
      draft: false
    };
    if (dataObj.isImage) {
      this.getTheUpdatedFileUrl(dataObj);
    }
    return dataObj;
  }

  private getTheUpdatedFileUrl(file: IUploadFileParticipantData) {
    if (file.isImage && !file.imageUrlLoaded) {
      try {
        this.getFileUrl(file.id, (url) => {
          file.imageSrc = url;
          file.imageUrlLoaded = true;
        });
      } catch (error) {
        this.common.log.error(error);
        file.imageUrlLoaded = false;
      }
    }
  }

  initConfigForUploadType(selectedTypes, replacement: boolean) {
    this.maxNumberOfFiles = 1;
    const baseSettings = {
      restrictions: {
        allowedFileTypes: this.getSelectedTypes(selectedTypes),
        maxFileSize: this.DEFAULT_LARGE_SIZE_LIMIT,
        dashboardNote: this.getDashboardNote(this.maxNumberOfFiles, selectedTypes)
      },
      onBeforeFileAdded: (currentFile, files) => {
        const validationResult = this.validateFileSize(currentFile);
        if (validationResult !== true) {
          this.uppy.info(validationResult.message, 'error', 5000);
          return false;
        }
        return true;
      }
    };
    const mainSettings = {
      ...baseSettings,
      restrictions: {
        ...baseSettings.restrictions,
        maxNumberOfFiles: this.maxNumberOfFiles
      }
    };
    const replacementSettings = {
      ...baseSettings,
      restrictions: {
        ...baseSettings.restrictions,
        maxNumberOfFiles: 1,
        dashboardNote: this.getDashboardNote(this.maxNumberOfFiles, selectedTypes)
      }
    };
    if (!replacement) {
      this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM, mainSettings, (result) => {
        this.uploadFiles(result).then(() => {
          this.uploadService.closeModal(this.uppy);
          this.uppy.close();
        });
      }, () => {
        this.uploadResult$.next(null);
      });
      this.uppy.on('dashboard:modal-open', () => {
        this.setUppyId();
      });
    }
    if (replacement) {
      this.replacementUppy = this.uploadService.createFileUploader(UPLOAD_TYPE.CUSTOM, replacementSettings, (result) => {
        this.uploadFiles(result).then(() => {
          this.deleteFile(this.itemToDelete).then(() => {
            this.uploadService.closeModal(this.replacementUppy);
            this.replacementUppy.close();
          });
        });
      }, () => {
        this.uploadResult$.next(null);
        this.undoDeleteUploadedFile();
      });
      this.replacementUppy.on('dashboard:modal-open', () => {
        this.setUppyId();
      });
    }
  }

  private setUppyId() {
    const dashboardEl = document.querySelectorAll('.uppy-Dashboard');
    if (dashboardEl) {
      dashboardEl.forEach((element, index) => {
        if (!element.id) {
          element.id = `uppy-Dashboard-${index + 1}`;
        }
      });
    }
  }

  private getFileUrl(fileId: string, success) {
    const currUserId = this.currentUserId;
    const eventId = this.documentPathParams.eventId;
    const contentId = this.documentPathParams.contentId;
    const containerId = this.documentPathParams.containerId;
    const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${currUserId}/${fileId}`;
    this.storageDataService.getStorageDataURL(path)
      .then(url => {
        if (url) {
          success(url);
        }
      }).catch((err) => {
      this.common.log.error(err);
      throw err;
    });
  }

  formatSelectedTypes(selectedTypes: IUploadSelectedFileTypes): string {
    const categories = {
      Images: selectedTypes?.images || [],
      Audio: selectedTypes?.audios || [],
      Videos: selectedTypes?.videos || [],
      Documents: selectedTypes?.documentTypes || [],
      Others: selectedTypes?.others || []
    };

    return Object.entries(categories)
      .filter(([_, types]) => types.length > 0)
      .map(([category, types]) => `${category}: ${types.join(', ')}`)
      .join('\n');
  }

  private getSelectedTypes(selectedTypes: any): string[] {
    return Object.values(selectedTypes).flat()
      .map(ext => `.${ext}`);
  }

  get questionOptions(): any {
    return this.question?.options;
  }

  validateFileSize(file) {
    const fileType = file.extension;
    const sizeLimit = this.getSizeLimitForExtension(file.extension) || this.DEFAULT_SIZE_LIMIT;
    if (file.size > sizeLimit) {
      return {
        message: this.common.i18n('questionnaire.upload.file.participants.upload.file.type.size.error',
          {fileSizeLimit: formatSize(sizeLimit), fileType: fileType}),
      };
    }
    return true;
  }

  getSizeLimitForExtension(extension: string): number {
    const uppercaseExtension = extension.toUpperCase().replace('.', '');
    for (const fileType of Object.values(Constants.FILE_TYPES)) {
      if (fileType.extension.includes(uppercaseExtension)) {
        return fileType.sizeLimit;
      }
    }
    return null;
  }

  private async uploadFiles(list) {
    this.common.showProgress.next(true);
    const answers = [];
    for (const item of list) {
      const prefix = this.common.utils.generateRandomString(4).toLowerCase();
      const id = `${item.id.replace(/\//g, '-').replace('uppy', prefix)}.${item.extension}`;
      if (!answers.find(it => it.id.includes(id))) {
        const curUserId = this.currentUserId;
        const eventId = this.documentPathParams.eventId;
        const contentId = this.documentPathParams.contentId;
        const containerId = this.documentPathParams.containerId;
        const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${curUserId}`;
        await this.storageDataService.uploadAnyObjectToStorageByDataPath(path, id, item.response, item.meta.type)
          .then(() => {
            const obj: IUploadFile = {
              id: `${id}`,
              name: item.name,
              size: item.size,
              metaType: item.meta.type
            };
            this.uploadResult$.next(obj);
            answers.push(obj);
            const dataObj: IUploadFileParticipantData = this.getUploadFileParticipantData(id, item);
            this.fileData.push(dataObj);
          }).finally(() => {
            this.common.showProgress.next(false);
          }).catch(e => {
            this.uploadResult$.next(null);
            throw e;
          });
      }
    }
    this.answers = {f: answers};
    this.fileData$.next(this.fileData);
  }

  private getUploadFileParticipantData(id: string, item: any) {
    const dataObj: IUploadFileParticipantData = {
      id: `${id}`,
      name: item.name,
      size: formatSize(item.size),
      extension: getExtension(item.name),
      metaType: item.meta.type,
      isImage: this.imageExtensions.includes(item.name.split('.').pop().toUpperCase()),
      imageUrlLoaded: false,
      imageSrc: null,
      draft: false
    };
    if (dataObj.isImage) {
      dataObj.imageUrlLoaded = true;
      dataObj.imageSrc = item.response;
    }
    return dataObj;
  }

  deleteUploadFile(fileDetails: IUploadFile) {
    this.common.confirm(this.common.i18n('questionnaire.upload.file.participants.operation.remove.confirm.header'),
      this.common.i18n('questionnaire.upload.file.participants.operation.remove.confirm.message'), false,
      this.common.i18n('action.delete'))
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
            this.deleteFile(fileDetails);
        }
      });
  }

  private async deleteFile(item: IUploadFile) {
    this.common.showProgress.next(true);
    if (item.id) {
      const curUserId = this.currentUserId;
      const eventId = this.documentPathParams.eventId;
      const contentId = this.documentPathParams.contentId;
      const containerId = this.documentPathParams.containerId;
      const path = `${eventId}/${contentId}/${containerId}/${this.question.id}/users_answers/${curUserId}`;
      await this.storageDataService.deleteObjectFromStorageByDataPath(path, item.id)
        .then(() => {
          this.deleteResult$.next(true);
        }).finally(() => {
          this.common.showProgress.next(false);
        }).catch(e => {
          this.deleteResult$.next(false);
          throw e;
        });
      this.common.showProgress.next(false);
    }
  }

  replaceUploadFile(uploadedFile: IUploadFile, selectedTypes) {
    if (uploadedFile) {
      this.itemToDelete = uploadedFile;
    }
    this.initConfigForUploadType(selectedTypes, true);
    this.uploadService.openUploadWindow(this.replacementUppy);
  }

  private undoDeleteUploadedFile() {
    this.itemToDelete = null;
  }

  upload(selectedTypes) {
    this.initConfigForUploadType(selectedTypes, false);
    this.uploadService.openUploadWindow(this.uppy);
  }
}
