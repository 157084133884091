import {Injectable} from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {EventsDataService} from '../../../../services/events-data.service';
import {IDocumentPathParams} from '../../../../services/event-mode-api.service';
import {APP_MODE, LoginService} from '../../../../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class VideoStreamService extends AbstractContainerService {

  constructor(private dataService: EventsDataService,
              private loginService: LoginService) {
    super();
  }

  save(path: string, data): Promise<any> {
    return Promise.resolve(data);
  }

  delete(path: string, data, documentPathParams: IDocumentPathParams): Promise<boolean> {
    return (this.loginService.applicationMode === APP_MODE.TIMELINE ?
      this.dataService.setFollowMePlayerState(documentPathParams, null) : Promise.resolve())
      .then(() => true)
      .catch(e => this.dataService.common.log.error(e));
  }


}
