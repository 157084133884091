import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {LoginService} from './login.service';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {map} from 'rxjs';

@Injectable()
export class LoginGuard  {
  constructor(private store: Store<fromRoot.State>
    , private loginService: LoginService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.loginService.authenticatedUser.pipe(map(user => {
      const gotoLogin = user == null;
      if (!gotoLogin) {
        // this.loginService.redirectToHomePage(true);
      }
      return gotoLogin;
    }));
  }
}
