import {ChangeDetectorRef, Component, Inject, Injector, OnInit} from '@angular/core';
import * as ui from '../../actions/ui';
import {Constants, IG_USER_LIST_PAGE, SECTION_USER_LIST_PAGE} from '../../core/constants';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {EventsDataService} from '../../services/events-data.service';
import {CommonService} from '../../core/common.service';
import {TimeLineService} from '../../services/time-line.service';
import {clone, cloneDeep, isEmpty, merge, union} from 'lodash';
import {Event} from '../../model/Event';
import {SectionContent, SectionUser} from '../../model/content/SectionContent';
import {RegistrationSettings} from '../../model/event-mode/RegistrationSettings';
import {ConferenceUser} from '../../model/event-mode/ConferenceUser';
import {BehaviorSubject, combineLatest, debounceTime, interval, sample, take} from 'rxjs';
import {StdComponent} from '../../core/std-component';
import {VirtualConferenceSettings} from '../../model/event-mode/InstantSettings';

export interface IMember {
  userId: string; picture: string; userName: string; email: string;
  last_activity: number; joined_event: number; invited: number;
  checkbox: boolean; delete?: boolean; isMemberInOtherSection?: boolean;
  online_device?: string; online_status?: number;
}

interface IData {
  [userId: string]: IMember;
}

interface IChildSections {
  section: SectionContent; membersSubject: BehaviorSubject<IMember[]>; members: IMember[];
}

@Component({
  selector: 'app-instant-group-settings-dialog',
  templateUrl: './instant-group-settings-dialog.component.html',
  styleUrls: ['./instant-group-settings-dialog.component.scss']
})
export class InstantGroupSettingsDialogComponent extends StdComponent implements OnInit {
  readonly Constants = Constants;

  MIN_GROUP_COUNT = 2;
  MAX_GROUP_COUNT = 20;

  displayedColumns = ['checkbox', 'picture', 'userName'];
  currentEvent: Event;
  section: SectionContent;
  registrationSettings: RegistrationSettings;
  groupCount = 0;
  private _childSections: IChildSections[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  initData: IData = {};
  pageLength = IG_USER_LIST_PAGE.INIT_PAGE_LENGTH;
  paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.changeDetector);
  checkedall = false;
  allUnCheck = true;
  selectCount = 0;
  searchValue = '';
  loadComplete;
  loadSubject = new BehaviorSubject<number>(null);
  eventUsers: ConferenceUser[] = [];
  rangeOfUsers: string;
  loaded = false;
  unassignedOnly = true;
  sortType: number = Constants.USORT_ONLINE_STATUS;
  sectionByUserData = {};


  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<InstantGroupSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<fromRoot.State>,
              public eventDataService: EventsDataService,
              private common: CommonService,
              public dialog: MatDialog,
              public timeLineService: TimeLineService,
              public changeDetector: ChangeDetectorRef) {
    super(injector);
    this.dialogRef.addPanelClass('timeline');
    this.currentEvent = this.timeLineService.timelineEvent;
    this.section = cloneDeep(data.section);
    this.registrationSettings = data.registrationSettings ? data.registrationSettings : new RegistrationSettings();
    if (!this.section.id) {
      this.loadComplete = [0];
      this.section.restrictAccessMembers = true;
    } else {
      this.loadComplete = [0, 0];
    }
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(event => {
        if (event.key === Constants.ESCAPE) {
          this.onNoClick();
        }
      });
  }
  get childSections(): IChildSections[] {
    return (this._childSections || []).filter(chs => !chs.section['delete']);
  }

  set childSections(value: IChildSections[]) {
    this._childSections = value;
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this.paginator.pageSize = this.pageLength;
    this.paginator.ngOnInit();
    this.dataSource.paginator = this.paginator;
    this.loadSubject.pipe(this.takeUntilAlive())
      .subscribe(index => {
        if (index !== null) {
          this.loadComplete[index] = 1;
          if (this.loadComplete.every(v => v === 1) && !this.loaded) {
            this.fillDefaultSectionList();
            this.calcRangeOfUsers();
            // this.dataSource.data = this.reinitData(this.eventUsers);
            this.loaded = true;
            this.eventDataService.getUsersDataPromise(this.currentEvent.eventId).then(value => {
              const sectionByUserData = {};
              for (const user of (value || [])) {
                if (!isEmpty(user.sections)) {
                  for (const sectionId of user.sections) {
                    if (isEmpty(sectionByUserData[sectionId])) {
                      sectionByUserData[sectionId] = [];
                    }
                    if (!sectionByUserData[sectionId].includes(user.userId)) {
                      sectionByUserData[sectionId].push(user.userId);
                    }
                  }
                }
              }
              this.sectionByUserData = sectionByUserData;
              this.fillMembers();
              this.dataSource.data = this.reinitData(this.eventUsers);
            }).then(() => {
              this.eventDataService.getUsersOnlineDevices(this.currentEvent.eventId)
                .pipe(take(1)).toPromise().then((devicesList) => {
                this.reloadUsersOnlineDevices(devicesList);
                this.eventDataService.loadUsersOnlinePingList(this.currentEvent.eventId)
                  .then(userPingList => {
                    this.reloadUserOnlineStatus(userPingList);
                  });
              });
            });
          }
        }
      });
    let usersRequested = false;
    this.store.select(fromRoot.getUsersOnline).pipe(this.takeUntilAlive())
      .pipe(debounceTime(100), this.takeUntilAlive())
      .subscribe(uOnline => {
        if (isEmpty(uOnline) && !usersRequested) {
          usersRequested = true;
          this.eventDataService.dispatchUsersOnline(this.currentEvent.eventId);
        } else if (!isEmpty(uOnline)) {
          if (!this.loaded) {
            this.eventUsers = union(cloneDeep(uOnline));
            this.loadSubject.next(0);
          } else {
            this.dataSource.data = this.reinitData(cloneDeep(uOnline));
          }
        }
    });
    if (this.section.id) {
      this.timeLineService.planeList
        .pipe(this.takeUntilAlive())
        .subscribe(list => {
          if (!this.loaded) {
            const childs = this.timeLineService.getPlaneListContentAsSectionArray()
              .filter(s => s.parentId === this.section.id);
            childs.forEach(s => this.pushSavedChildSection(s));
            this.groupCount = childs.length;
            this.loadSubject.next(1);
          }
        });
    }

    combineLatest([
      this.eventDataService.loadUsersOnlinePing(this.currentEvent.eventId).pipe(
        sample(interval(Constants.GET_PING_LIST_INTERVAL_GRID)), this.takeUntilAlive()
      ),
      this.eventDataService.getUsersOnlineDevices(this.currentEvent.eventId)
    ]).pipe(
      sample(interval(500)), this.takeUntilAlive()
    )
      .subscribe(([userPingList, devicesList]) => {
        this.reloadUsersOnlineDevices(devicesList);
        this.reloadUserOnlineStatus(userPingList);
    });
  }

  onOkClick(): void {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    this.setSectionResultValues();
    this.dialogRef.close({
      section: this.section,
      registrationSettings: this.registrationSettings,
      childSections: this._childSections.map(obj => new Object(
        {section: cloneDeep(obj.section), members: cloneDeep(obj.members.sort((a , b) => a.delete && !b.delete ? -1 : 1))}))
    });
  }

  onNoClick(): void {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    this.dialogRef.close();
  }

  setSectionResultValues() {
    this.mergeSectionsUsers();
    if (this.section.id && !this.section.sectionTypeId) {
      this.section = merge(this.section, {sectionTypeId: null});
    }
    if (!this.section.id) {
      this.section.sectionTimeIsNotActive = true;
    }
    if (this.section.specificVideoConferences) {
      if (isEmpty(this.section.virtualConferenceSettings)) {
        this.section.virtualConferenceSettings = VirtualConferenceSettings.getDefaultSectionVirtualConferenceSettings(true);
      }
    }
  }

  mergeSectionsUsers() {
    for (const section of this.childSections) {
      const s = section.section;
      s.isPublic = !this.section.restrictAccessMembers;
      const speakers = isEmpty(section.section.users) ? [] : section.section.users
        .filter(u => u.speaker)
        .map(o => new SectionUser(o));
      const m = !isEmpty(section.members) ? section.members : [];
      const su = !isEmpty(section.section.users) ? section.section.users.filter(u => !u.speaker) : [];
      su.forEach((u, index) => {
        if (!m.find(o => o.userId === u.userId)) {
          su.splice(index, 1);
        }
      });
      m.forEach(u => {
        const index = su.findIndex(o => o.userId === u.userId);
        if (index === -1) {
          su.push(new SectionUser({userId: u.userId, attendee: true, speaker: false}));
        } else if (u.delete && index > -1) {
          su.splice(index, 1);
        }
      });
      m.forEach(u => u.isMemberInOtherSection = this.isMemberInOtherSection(u.userId, s.id));
      section.section.users = union(speakers, su);
    }
  }

  groupCountInc(event) {
    if (this.groupCount < this.MAX_GROUP_COUNT) {
      this.groupCount++;
      this.pushNewChildSection(this.groupCount);
    } else {
      event.cancelBubble = true;
    }
  }

  groupCountDec(event) {
    const del = () => {
      this.childSections[this.groupCount - 1].membersSubject.complete();
      this.childSections.splice(this.groupCount - 1, 1);
      this.groupCount--;
    };
    if (this.groupCount > this.MIN_GROUP_COUNT) {
      if (this.childSections[this.groupCount - 1].members.length > 0) {
        this.common.confirm(this.common.i18n('instant.group.delete.new.group.with.members',
          {name: this.childSections[this.groupCount - 1].section.title}),
          this.common.i18n('instant.group.delete.group.confirmation'))
          .pipe(take(1)).subscribe(result => {
          if (result) {
            del();
          }
        });
      } else {
        del();
      }
    } else {
      event.cancelBubble = true;
    }
    this.dataSource.data = this.getResultSet();
    this.calcRangeOfUsers();
  }

  pushNewChildSection(id) {
    const list = this._childSections.filter(o => !o.section['delete'])
      .filter(o => o.section.title === this.common.utils.i18n('common.group') + ' ' + id);
    if (!isEmpty(list)) {
      id = id + '-' + list.length;
    }
    this._childSections.push({
      section: new SectionContent({id: '?' + id, title: this.common.utils.i18n('common.group') + ' ' + id, isPublic: true}),
      members: [],
      membersSubject: new BehaviorSubject([])
    });
    this.calcRangeOfUsers();
  }

  pushSavedChildSection(section: SectionContent) {
    if (isEmpty(this.childSections) || this.childSections.every(o => o.section.id !== section.id)) {
      this._childSections.push({
        section: new SectionContent(section),
        members: [],
        membersSubject: new BehaviorSubject([])
      });
    } else {
      const obj = this.childSections.find(o => o.section.id === section.id);
      if (obj) {
        obj.section = section;
      }
    }
    this.childSections.sort(function(a, b) {
      return a.section.orderIndex >= b.section.orderIndex ? 1 : -1;
    });
    this.calcRangeOfUsers();
  }

  fillMembers() {
    for (const section of this.childSections) {
      const members = [];
      for (const userId of (this.sectionByUserData[section.section.id] || [])) {
        const appUser = this.eventUsers.find(u => u.userId === userId);
        if (appUser) {
          const iMember = !!members.find(it => it.userId === appUser.userId);
          if (!iMember) {
            members.push({
              userId: appUser.userId, userName: appUser.fullName, email: appUser.email, picture: appUser.picture,
              last_activity: appUser.lastActivity, joined_event: appUser.joinedToEvent, invited: appUser.invitedToEvent,
              checkbox: false});
          }
        }
      }
      section.members = members;
    }
    this.childSections.forEach(o => o.membersSubject.next(o.members));
  }

  fillDefaultSectionList() {
    if (this.groupCount > 0 || this.section.id) {
      return;
    }
    const usersCount = this.eventUsers.length;
    this.groupCount = this.MIN_GROUP_COUNT;
    if (usersCount <= 6) {
      this.groupCount = 2;
    } else if (usersCount > 6 && usersCount <= 12) {
      this.groupCount = 3;
    } else if (usersCount > 12) {
      this.groupCount = 5;
    }
    for (let i = 1; i <= this.groupCount; i++) {
      this.pushNewChildSection(i);
    }
  }

  private reinitData(uOnline, uInvited = []) {
    const invitedList: ConferenceUser[] = Object.keys(uInvited || {})
      .map(k => new ConferenceUser(uInvited[k]))
      .filter(u => !!u.userId);
    this.eventUsers = union(cloneDeep(uOnline));
    for (const user of this.eventUsers) {
      const checkBoxValue = this.initData[user.userId] ? this.initData[user.userId]['checkbox'] : false;
      const online_device = this.initData[user.userId] ? this.initData[user.userId].online_device : null;
      const online_status = this.initData[user.userId] ? this.initData[user.userId].online_status : null;
      this.initData[user.userId] = {
        checkbox: checkBoxValue,
        userId: user.userId,
        picture: user.picture,
        userName: user.fullName ? user.fullName : user.email,
        last_activity: user.lastActivity,
        joined_event: user.joinedToEvent,
        invited: user.invitedToEvent,
        email: user.email,
        online_device: online_device,
        online_status: online_status
      };
    }
    return this.getResultSet();
  }

  isInMembers(userId) {
    return this.childSections.find(s => s.members.findIndex(m => m.userId === userId && !m.delete) > -1);
  }

  private getResultSet(): any [] {
    const res = [];
    for (const rowId of Object.keys(this.initData)) {
      const rowData = this.initData[rowId];
      if (this.unassignedOnly && this.isInMembers(rowData.userId)) {
        continue;
      }
      if (!this.searchFilter(this.initData[rowId])) {
        continue;
      }
      res.push(rowData);
    }
    return this.sortResultSet(res);
  }

  onscroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const buffer = SECTION_USER_LIST_PAGE.BUFFER;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.pageLength += SECTION_USER_LIST_PAGE.PAGE_STEP;
      this.paginator._changePageSize(this.pageLength);
    }
  }


  onCheckAll(event) {
    this.dataSource.data.forEach(row => row.checkbox = event.checked);
    this.onRowCheckboxChange();
  }

  onRowCheckboxChange() {
    this.allUnCheck = true;
    this.selectCount = this.dataSource.data.filter(row => row.checkbox).length;
    for (const row of this.dataSource.data) {
      if (row.checkbox) {
        this.allUnCheck = false;
        break;
      }
    }
  }

  get isAllChecked() {
    if (this.dataSource.data.length === 0) {return; }
    return this.dataSource.data.every(row => row.checkbox);
  }

  private searchFilter(row): boolean {
    if (this.searchValue.length > 0) {
      if (row.userName && row.userName.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  getSelectedRows() {
    const result: IMember[] = [];
    for (const row of this.dataSource.data) {
      if (row.checkbox) {
        result.push(row);
        row.checkbox = false;
      }
    }
    this.onRowCheckboxChange();
    return result;
  }

  addRandomUsers() {
    const allSelectedUsers = this.getSelectedRows().slice().sort(function (a, b) {
      return Math.random() - 0.5;
    });
    if (isEmpty(allSelectedUsers) || isEmpty(this.childSections)) {
      return;
    }
    for (const user of allSelectedUsers) {
      if (this.isInMembers(user.userId)) {
        continue;
      }
      const list: IChildSections[] = this.childSections.map(obj =>
        new Object({
          section: cloneDeep(obj.section),
          membersSubject: obj.membersSubject,
          members: obj.members}
          ) as IChildSections);
      list.sort((a, b) =>
        a.members.filter(m => !m.delete).length >= b.members.filter(m => !m.delete).length ? 1 : -1);
      const mu = list[0].members.find(m => m.userId === user.userId);
      if (!mu) {
        list[0].members.push(clone(user));
      } else if (mu.delete) {
        mu.delete = false;
      }
      this.childSections.forEach(it => it.members = list.find(o => o.section.id === it.section.id).members);
    }
    this.dataSource.data = this.getResultSet();
    this.childSections.forEach(o => o.membersSubject.next(o.members));
  }

  resetAllMembers() {
    this.childSections.forEach(o => {
      if (!o.section.id.startsWith('?') && o.members.length > 0) {
        o.members.forEach(m => m.delete = true);
      } else if (o.section.id.startsWith('?')) {
        o.members = [];
      }
      o.membersSubject.next(o.members);
    });
    this.dataSource.data = this.getResultSet();
  }

  isMemberInOtherSection(userId, addToSectionId) {
    return this.childSections.filter(o => o.section.id !== addToSectionId)
      .some(m => m.members.find(u => u.userId === userId && !u.delete));
  }

  addSelectedMembers(sectionId) {
    const allSelectedUsers = this.getSelectedRows();
    if (isEmpty(allSelectedUsers)) {
      return;
    }
    const section = this.childSections.find(o => o.section.id === sectionId);
    if (section) {
      for (const user of allSelectedUsers) {
        const m = section.members.find(o => o.userId === user.userId);
        if (!m) {
          section.members.push(clone(user));
        } else if (m.delete) {
          m.delete = false;
        }
      }
    }
    this.dataSource.data = this.getResultSet();
    section.membersSubject.next(section.members);
  }

  removeMembers(object) {
    const sectionId = object.sectionId;
    const usersIdList = object.usersIdList;
    const section = this.childSections.find(o => o.section.id === sectionId);
    if (section) {
      for (const userId of usersIdList) {
        const m = section.members.find(o => o.userId === userId);
        if (m) {
          m.delete = true;
        }
      }
    }
    this.dataSource.data = this.getResultSet();
    section.membersSubject.next(section.members);
  }

  deleteSection(sectionId) {
    const index = this._childSections.findIndex(o => o.section.id === sectionId);
    if (index > -1) {
      const obj = this._childSections[index];
      if (obj.section.id.startsWith('?')) {
        this._childSections.splice(index, 1);
        this.groupCount = this._childSections.filter(o => !o.section['delete']).length;
      } else {
        obj.section = new SectionContent({
          id: obj.section.id,
          eventId: obj.section.eventId,
          type: obj.section.type,
          title: obj.section.title
        });
        obj.section['delete'] = true;
        this.groupCount = this._childSections.filter(o => !o.section['delete']).length;
      }
    }
    this.dataSource.data = this.getResultSet();
    this.calcRangeOfUsers();
  }

  calcRangeOfUsers() {
    const min = Math.trunc(this.eventUsers.length / this.childSections.length);
    const max = min + 1;
    if (this.eventUsers.length / this.childSections.length > min) {
      this.rangeOfUsers = min + '-' + max + ' ' + this.common.utils.i18n('instant.group.people.group');
    } else {
      this.rangeOfUsers = min + ' ' + this.common.utils.i18n('instant.group.people.group');
    }
  }

  unassignedOnlyChange() {
    this.dataSource.data = this.getResultSet();
  }

  applyFilter(searchValue: string) {
    this.searchValue = searchValue;
    this.dataSource.data = this.getResultSet();
  }

  onSort(sortType) {
    this.sortType = sortType;
    this.dataSource.data = this.getResultSet();
  }

  sortResultSet(list) {
    if (this.sortType === Constants.QSORT_NONE) {
      return;
    }
    if (this.sortType === Constants.USORT_ONLINE_STATUS) {
      list.sort(this.common.utils.comparator('online_status', 'desc'));
    }
    if (this.sortType && this.sortType === Constants.QSORT_USER_NAME) {
      list.sort(this.common.utils.comparator('userName'));
    }
    if (this.sortType && this.sortType === Constants.USORT_LAST_ACTIVITY) {
      list.sort(this.common.utils.comparator('last_activity', 'desc'));
    }
    if (this.sortType && this.sortType === Constants.USORT_JOINED_EVENT) {
      list.sort(this.common.utils.comparator('joined_event', 'desc'));
    }
    if (this.sortType && this.sortType === Constants.USORT_INVITED) {
      list.sort(this.common.utils.comparator('invited', 'desc'));
    }
    return list;
  }

  reloadUserOnlineStatus(userPingList) {
    if (userPingList) {
      let needUpdate = false;
      Object.keys(userPingList).forEach(uId => {
        if (this.initData[uId]) {
          const devices = userPingList[uId];
          const user = this.eventUsers.find(u => u.userId === uId);
          if (user) {
            const newOnlineDevice = user.onlineInfo(devices);
            if (this.initData[uId]['online_device'] !== newOnlineDevice) {
              needUpdate = true;
              this.initData[uId]['online_device'] = newOnlineDevice;
              this.initData[uId]['online_status'] =
                newOnlineDevice.length > 0 ? Constants.ONLINE_OPACITY : Constants.OFFLINE_OPACITY;
            }
          }
        }
      });
      if (needUpdate) {
        this.dataSource.data = this.getResultSet();
      }
    }
  }

  reloadUsersOnlineDevices(devicesList) {
    const onlineDevices = !isEmpty(devicesList) ? devicesList : {};
    this.eventUsers.forEach(u => u.devices = onlineDevices[u.userId] ? onlineDevices[u.userId].devices : {});
  }
}
