import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';
import {catchError, map, Observable} from 'rxjs';
import {IMeeting, Meeting} from '../model/meeting/Meeting';
import {JoinMeetingInfo} from '../model/meeting/JoinMeetingInfo';
import {DomSanitizer} from '@angular/platform-browser';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class MeetingApiService extends StdApiService {
  protected API_URL: string;

  constructor(
    private http: HttpClient,
    private afDB: AngularFireDatabase,
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    private sanitizer: DomSanitizer,
    protected aFirestore: AngularFirestore,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/meetingApi/v3/';
  }

  createMeeting(eventId: string): Observable<Meeting> {
    return this.http.post<Meeting>(this.API_URL + 'createMeeting/' + eventId, null)
      .pipe(
        map(res => this.mapObject(res, it => new Meeting(it)))
        , catchError(err => this.catchServerErrorObservable(err))
      );
  }

  stopMeeting(eventId: string, meetingId: number | string): Observable<boolean> {
    return this.http.post<boolean>(this.API_URL + 'stopMeeting/' + eventId + '/' + meetingId, null)
      .pipe(
        map(res => this.mapBoolean(res))
        , catchError(err => this.catchServerErrorObservable(err))
      );
  }

  deleteMeeting(eventId: string, meetingId: number |  string): Promise<boolean> {
    return this.http.post<boolean>(this.API_URL + 'deleteMeeting/' + eventId + '/' + meetingId, null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  joinZoomMeetingAsPresenter(eventId: string, meetingId: number | string): Observable<JoinMeetingInfo> {
    return this.http.post<JoinMeetingInfo>(this.API_URL + 'joinMeetingAsPresenter/' + eventId + '/' + meetingId, null)
      .pipe(
        map(res => this.mapObject(res, it => new JoinMeetingInfo(it)))
        , catchError(err => this.catchServerErrorObservable(err))
      );
  }

  saveMeetingPing(eventId: string, meetingId: number | string) {
    const currentTime = new Date().getTime();
    const path = `meetings/${eventId}/${meetingId}`;
    return this.afDB.object(path).set(currentTime);
  }

  joinZoomMeetingAsAttendee(eventId: string, meetingId: number | string): Observable<JoinMeetingInfo> {
    return this.http.post<JoinMeetingInfo>(this.API_URL + 'joinMeetingAsAttendee/' + eventId + '/' + meetingId, null)
      .pipe(
        map(res => this.mapObject(res, it => new JoinMeetingInfo(it)))
        , catchError(err => this.catchServerErrorObservable(err))
      );
  }

  parseMeetingInfo(info: JoinMeetingInfo) {
    if (!this.utils.getEnv().meeting.zoom.meetingClientName) {
      throw new Error('Meeting client name not found.');
    }
    const params = info.meetingId + '/' + info.signature
      + '?clientName=' + this.utils.urlParamEscape(this.utils.getEnv().meeting.zoom.meetingClientName)
      + '&userName=' + this.utils.urlParamEscape(info.userName)
      + '&userEmail=' + this.utils.urlParamEscape(info.userEmail)
      + '&c=' + this.utils.urlParamEscape(info.c);
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://timeline-zoom.web.app/join/' + params);
  }

  getPresenterUrl(eventId: string, meetingId: number | string): Observable<string> {
    return this.http.get<string>(this.API_URL + 'presenterUrl/' + eventId + '/' + meetingId)
      .pipe(
        map(res => this.mapString(res))
        , catchError(err => this.catchServerErrorObservable(err))
      );
  }

  getMeeting(eventId: string, meetingId: number | string): Observable<IMeeting> {
    return this.http.get<IMeeting>(this.API_URL + `${meetingId}?eventId=${eventId}`)
      .pipe(
        catchError(err => this.catchServerErrorObservable(err))
      );
  }

  createSectionMeeting(eventId: string, sectionId: string): Observable<Meeting> {
    return this.http.post<Meeting>(this.API_URL + 'createSectionMeeting/' + eventId + '/' + sectionId, null)
      .pipe(
        map(res => this.mapObject(res, it => new Meeting(it)))
        , catchError(err => this.catchServerErrorObservable(err))
      );
  }

}
