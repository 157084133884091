import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {LoginService} from '../login/login.service';
import {CommonService} from '../core/common.service';
import {TimerService} from '../core/timer.service';
import {Subscription} from 'rxjs';
import {deleteField} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService {

  subscriptionToken: Subscription;

  constructor(
    private afs: AngularFirestore,
    private loginService: LoginService,
    private timerService: TimerService,
    private commonService: CommonService
  ) {
  }

  init() {
    this.loginService.getAuthenticatedUser().subscribe(authUser => {
      if (authUser && authUser.uid) {
        if (this.subscriptionToken) {
          this.subscriptionToken.unsubscribe();
          this.subscriptionToken = null;
        }
        this.subscriptionToken = this.afs
          .collection('token_refresh').doc<any>(authUser.uid)
          .valueChanges()
          .subscribe(value => {
            if (value && value.expiration) {
              const refreshTime = value.expiration;
              const currentTime = new Date().getTime();
              if ((refreshTime - currentTime) < 0) {
                this.refreshToken().then(() => {
                  const obj = {
                    expiration: deleteField()
                  };
                  this.afs.collection('token_refresh')
                    .doc<any>(authUser.uid)
                    .set(obj, {merge: true})
                    .catch(() => {});
                });
              }
            }
          });
      } else {
        if (this.subscriptionToken) {
          this.subscriptionToken.unsubscribe();
          this.subscriptionToken = null;
        }
      }
    });
  }

  refreshToken() {
    return this.loginService.refreshToken(true).then(token => {
      this.loginService.loginBackend().then(() => {
        this.commonService.log.info('token was refreshed');
      });
    });
  }

}
