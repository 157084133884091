import {ElementRef, inject, Injectable, NgZone} from '@angular/core';
import {PaperScope, Tool} from 'paper';

@Injectable({
  providedIn: 'root'
})
export class PaperJsService {
  private ngZone = inject(NgZone);

  drawScope;
  drawTool;

  constructor() {
  }

  initialize(canvasElement: ElementRef | null) {
    if (!canvasElement) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      if (!this.drawScope) {
        this.drawScope = new PaperScope();
      }
      this.drawScope.setup(canvasElement.nativeElement);
      if (!this.drawTool) {
        this.drawTool = new Tool();
      }
    });
  }

  destroy() {
    this.drawScope.remove();
    this.drawScope = null;
    this.drawTool = null;
  }
}
