import { Injectable } from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {UtilsService} from '../core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private analytics: AngularFireAnalytics
            , private utils: UtilsService) { }

  logEvent(tag: string, params?: {[key: string]: any}) {
    if (this.utils.getEnv().debug.analytics) {
      try {
        const currentDate = new Date();
        const obj = params ? Object.assign({date: currentDate}, params) : {date: currentDate};
        return this.analytics.logEvent(tag, obj);
      } catch (e) {
        if (this.utils.getEnv().debug.console) {
          return Promise.reject(e);
        }
        return Promise.reject();
      }
    }
    return Promise.resolve();
  }
}
