import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  constructor(private http: HttpClient,
              private common: CommonService) {  }

  getGeolocation(raw= false) {
    const apiKey = this.common.getEnv().gapi.geolocation.apiKey;
    return this.http.post('https://www.googleapis.com/geolocation/v1/geolocate?key=' + apiKey, null)
      .pipe(switchMap((coords: {location: {lat: number, lng: number}}) => {
        const lat = coords.location.lat;
        const lng = coords.location.lng;
        return this.http.post(`https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${lat},${lng}&sensor=false&language=en`, null);
      }),
        map((location: {results: []}) => {
          if (raw) {
            return location;
          }
          const addresses: {address_components: []} = location.results[location.results.length - 1];
          const addr: {long_name: string, short_name: string} = addresses.address_components[addresses.address_components.length - 1];
          return {country: addr.long_name, countryCode: addr.short_name};
        }));
  }
}
