import {Constants} from '../../../../../../core/constants';

const imageExtensions = Constants.FILE_TYPES[Constants.FT_IMAGE].extension.filter(ext => ext !== 'HEIC');

export function formatSize(bytes: number, decimals: number = 2): string {
  if (!bytes) {
    return '-';
  }
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getExtension(filePath: string): string {
  const lastDotIndex = filePath.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return ''; // No extension found
  }
  return filePath.slice(lastDotIndex + 1).toLowerCase();
}

export function isImage(name): boolean {
  return imageExtensions.includes(name.split('.').pop().toUpperCase());
}
