import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {LoggerService} from '../../../core/logger.service';
import {APP_MODE, LoginService} from '../../../login/login.service';
import {UtilsService} from '../../../core/utils.service';
import {AngularFirestore, QueryFn} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {StdApiService} from '../../../services/std-api-service';
import {catchError, firstValueFrom, map, Observable} from 'rxjs';
import {ExamSection} from '../../exam-model/exam-section';
import {
  EXAM_ASSESSMENT_STATUS,
  EXAM_ASSESSMENT_STATUS_ORDER,
  EXAM_CLIPBOARD_TYPE,
  EXAM_CONTENT_STATUS,
  EXAMS_PATHS,
  IAssessmentContentStatistics,
  IAssessmentContentStatisticsMap,
  IAssessmentStatistics,
  IExplanation,
  USER_EXAM_ACTION,
  USER_EXAM_STATE
} from '../../exam-constants/exam-constants';
import {ContentContainer} from '../../../model/content/ContentContainer';
import {ContentContainerService} from '../../../modules/content-container/service/content-container.service';
import {QuestionCard} from '../../../question-cards/question-cards-model/question-card';
import {Exam} from '../../exam-model/exam';
import {cloneDeep, isEmpty, omit, pick} from 'lodash';
import {CONTENT_PATH_TYPE} from '../../../model/content/AbstractContent';
import {HttpClient} from '@angular/common/http';
import {StorageDataService} from '../../../services/storage-data.service';
import {ExamUser} from '../../exam-model/exam-user';
import {AppUser} from '../../../model/AppUser';
import {arrayUnion, increment} from '@angular/fire/firestore';
import {CommonService} from '../../../core/common.service';

const BASE64_SUFFIX = ';base64,';

@Injectable({
  providedIn: 'root'
})
export class ExamApiService extends StdApiService {
  protected API_URL: string;

  constructor(private http: HttpClient,
              protected store: Store<fromRoot.State>,
              protected logger: LoggerService,
              protected auth: LoginService,
              protected utils: UtilsService,
              protected aFirestore: AngularFirestore,
              protected storage: AngularFireStorage,
              protected contentContainerService: ContentContainerService,
              protected storageDataService: StorageDataService,
              protected common: CommonService) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/examApi/v3/';
  }

  generateNewDocumentId() {
    return this.aFirestore.createId();
  }

  getContentContainerDataService() {
    return this.contentContainerService.dataService;
  }

  private examReferenceCollectionRef(queryFn?: QueryFn) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}`, queryFn);
  }

  private examCoverStoragePath(examId: string) {
    return `${examId}/cover`;
  }

  private examSectionCoverStoragePath(examId: string, sectionId: string) {
    return `${examId}/${sectionId}/cover`;
  }

  private examSectionsReferenceCollectionRef(examId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}`);
  }

  private examSectionContentsReferenceCollectionRef(examId: string, sectionId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${sectionId}/${EXAMS_PATHS.EXAM_CONTENTS}`);
  }

  private examSectionAdditionContentsReferenceCollectionRef(examId: string, sectionId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${sectionId}/${EXAMS_PATHS.ADDITION_CONTENTS}`);
  }

  private examSectionUsersAssessmentsStatisticsCollectionRef(examId: string, sectionId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${sectionId}/${EXAMS_PATHS.USERS_ASSESSMENTS_STATISTICS}`);
  }

  private examSectionContentsAssessmentsStatisticsCollectionRef(examId: string, sectionId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${sectionId}/${EXAMS_PATHS.CONTENTS_ASSESSMENTS_STATISTICS}`);
  }

  private examExamGeneralAssessmentsStatisticsCollectionRef(examId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_GENERAL_ASSESSMENTS_STATISTICS}`);
  }

  private examContentsMapDocumentReference(examId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/exam-contents-map`).doc('exam-contents-map');
  }

  private examUserAnswersMapDocumentReference(examId: string, userId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/users-answers-map`).doc(userId);
  }

  private examUsersCollectionRef(examId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_USERS}`);
  }

  private appUserExamsCollectionRef(userId: string) {
    return this.afs.collection(`app_users/${userId}/${EXAMS_PATHS.MAIN}`);
  }

  private examUsersAssessmentsCollectionRef(examId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}`);
  }

  private examUserContentsAssessmentsCollectionRef(examId: string, userId: string) {
    return this.afs
      .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}/${userId}/${EXAMS_PATHS.CONTENTS_ASSESSMENT}`);
  }

  private examUserContentsAssessmentsHistoryCollectionRef(examId: string, userId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}/${userId}/${EXAMS_PATHS.CONTENTS_ASSESSMENT_HISTORY}`);
  }

  private examLaunchCodesDocumentRef(examId: string) {
    return this.afs.collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_LAUNCH_CODES}`)
      .doc(EXAMS_PATHS.EXAM_LAUNCH_CODES_DOCUMENT);
  }

  getExam(examId: string) {
    return firstValueFrom(this.examReferenceCollectionRef().doc(examId).get())
      .then(d => d.exists ? new Exam({examId: d.id, ...d.data()}) : null);
  }

  loadExam(examId: string) {
    return this.examReferenceCollectionRef().doc(examId)
      .valueChanges()
      .pipe(
        this.log('loadExam'),
        map(obj => obj ? new Exam({examId, ...obj}) : null),
        catchError(err => this.firestoreCatchError(err, 'loadExam'))
      );
  }

  loadExams(): Observable<QuestionCard[]> {
    const isAdmin = this.auth.isAdmin() || this.auth.isSuperadmin();
    if (isAdmin) {
      return this.examReferenceCollectionRef()
        .valueChanges({idField: 'examId'})
        .pipe(
          this.log('loadExams'),
          map(res => this.mapArray(res || [], (it) => new Exam(it))),
          catchError(err => this.firestoreCatchError(err, 'loadExams'))
        );
    } else if (this.auth.isManage()) {
      const userId = this.auth.getAppUser().userId;
      const examQueryFn: QueryFn = q => q.where('combinedUsers', 'array-contains', userId);
      return this.examReferenceCollectionRef(examQueryFn).valueChanges({idField: 'examId'})
        .pipe(
          this.log('loadExams'),
          map(res => this.mapArray(res || [], (it) => new Exam(it))),
          catchError(err => this.firestoreCatchError(err, 'loadExams'))
        );
    }
  }

  async saveExam(exam: Exam) {
    let prevExam: Exam;
    let deleteCover = false;
    // check exam has cover before save
    if (exam.examId) {
      prevExam = await this.getExam(exam.examId);
      deleteCover = !!prevExam.coverImage && !exam.coverImage;
    }
    const examId = exam.examId ?? this.generateNewDocumentId();
    const obj = cloneDeep(typeof exam.toObject !== 'undefined' ? exam.toObject() : exam);
    // check mime type
    const mimeType = this.utils.getMimeType(exam.coverImage);
    let coverImage;
    // if mimi type true then need upload image
    if (mimeType) {
      // save base64 to tmp variable for upload after save document
      coverImage = exam.coverImage;
      // remove base64 string. after upload, this field will be assigned the value url
      obj.coverImage = null;
    }
    // save exam object
    delete obj.examId;
    return this.examReferenceCollectionRef()
      .doc(examId)
      .set(obj, {merge: false})
      .then(async () => {
        if (deleteCover) {
          await this.storageDataService.deleteObjectFromStorageByDataPath(this.examCoverStoragePath(examId), examId);
        }
        // prepare and upload exam cover
        if (coverImage && mimeType) {
          return this.storageDataService.uploadAnyObjectToStorageByDataPath(
            this.examCoverStoragePath(examId), examId, coverImage, mimeType)
            .then(snapshot => snapshot.ref.getDownloadURL()
              // save exam object with url
              .then(url => this.examReferenceCollectionRef()
                .doc(examId)
                .set({coverImage: url}, {merge: true})
                .then(() => examId)));
        }
        return examId;
      });
  }

  async deleteExam(examId: string) {
    const exam = await firstValueFrom(this.loadExam(examId));
    return this.examReferenceCollectionRef()
      .doc(examId)
      .delete()
      .then(async () => {
        if (exam.coverImage) {
          await this.storageDataService.deleteObjectFromStorageByDataPath(this.examCoverStoragePath(examId), examId);
        }
        return examId;
      });
  }

  loadExamContentsMap(examId: string) {
    return this.examContentsMapDocumentReference(examId)
      .valueChanges()
      .pipe(
        this.log('loadExamContentsMap'),
        catchError(err => this.firestoreCatchError(err, 'loadExamContentsMap'))
      );
  }

  getExamSection(examId: string, sectionId: string) {
    return firstValueFrom(this.examSectionsReferenceCollectionRef(examId).doc(sectionId).get())
      .then(d => d.exists ? new ExamSection({id: d.id, ...d.data()}) : null);
  }

  loadExamSections(examId: string) {
    return this.examSectionsReferenceCollectionRef(examId)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('loadExamSections'),
        map(res => this.mapArray(res, (it) => new ExamSection(it))),
        catchError(err => this.firestoreCatchError(err, 'loadExamSections'))
      );
  }

  loadExamSectionContents(examId: string, sectionId: string) {
    return this.examSectionContentsReferenceCollectionRef(examId, sectionId)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('loadExamSectionContents'),
        map(res => this.mapArray(res, (it) => new ContentContainer(it))),
        catchError(err => this.firestoreCatchError(err, 'loadExamSectionContents'))
      );
  }

  loadExamSectionAttendeesStatisticsByContents(examId: string, sectionId: string) {
    return this.examSectionUsersAssessmentsStatisticsCollectionRef(examId, sectionId)
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('loadExamSectionAttendeesStatisticsByContents'),
        map((res: any[]) => {
          return (res ?? []).reduce((acc, it) => {
            const userId = it.userId;
            delete it.userId;
            acc[userId] = it;
            return acc;
          }, {});
        }),
        catchError(err => this.firestoreCatchError(err, 'loadExamSectionAttendeesStatisticsByContents'))
      );
  }

  loadExamSectionAttendeeStatisticsByContents(examId: string, sectionId: string, userId: string) {
    return this.examSectionUsersAssessmentsStatisticsCollectionRef(examId, sectionId)
      .doc(userId)
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('loadExamSectionAttendeeStatisticsByContents'),
        map((res: any[]) => {
          return (res ? [res] : []).reduce((acc, it: any) => {
            const uId = it.userId;
            delete it.userId;
            acc[uId] = it;
            return acc;
          }, {});
        }),
        catchError(err => this.firestoreCatchError(err, 'loadExamSectionAttendeeStatisticsByContents'))
      );
  }

  loadExamSectionContentsStatistics(examId: string, sectionId: string) {
    return this.examSectionContentsAssessmentsStatisticsCollectionRef(examId, sectionId)
      .doc(EXAMS_PATHS.CONTENTS_ASSESSMENTS_STATISTICS_DOCUMENT)
      .valueChanges()
      .pipe(
        this.log('loadExamSectionContentsStatistics'),
        catchError(err => this.firestoreCatchError(err, 'loadExamSectionContentsStatistics'))
      );
  }

  loadExamSectionsAssessmentStatistics(examId: string) {
    return this.examExamGeneralAssessmentsStatisticsCollectionRef(examId)
      .doc(EXAMS_PATHS.SECTIONS_ASSESSMENTS_STATISTICS_DOCUMENT)
      .valueChanges()
      .pipe(
        this.log('loadExamSectionsStatistics'),
        catchError(err => this.firestoreCatchError(err, 'loadExamSectionsStatistics'))
      );
  }

  loadExamAssessmentStatistics(examId: string) {
    return this.examExamGeneralAssessmentsStatisticsCollectionRef(examId)
      .doc(EXAMS_PATHS.EXAM_ASSESSMENTS_STATISTICS_DOCUMENT)
      .valueChanges()
      .pipe(
        this.log('loadExamAssessmentStatistics'),
        catchError(err => this.firestoreCatchError(err, 'loadExamAssessmentStatistics'))
      );
  }

  protected getImageMetadataParams(data: string): {mimeType, fileExtension} {
    const getMimeType = (d: string) => {
      if (!data) {
        return null;
      }
      const m = d.match(/data:(.+)/);
      if (m && m.length === 2) {
        return m[1];
      }
      return null;
    };

    if (!data) {
      return null;
    }
    const metadata = data.substring(0, data.indexOf(BASE64_SUFFIX));
    const params = metadata.split(';');
    const mimeTypeParam = params.find(p => p.includes('mimeType'));
    const mimeType = mimeTypeParam ? mimeTypeParam.split('=')[1] : getMimeType(metadata);
    if (!mimeType) {
      return null;
    }
    const fileExtensionParam = params.find(p => p.includes('fileExtension'));
    const fileExtension = fileExtensionParam ? fileExtensionParam.split('=')[1] : '';
    return {mimeType, fileExtension};
  }

  async saveExamSection(section: ExamSection) {
    let prevSection: ExamSection;
    let deleteCover = false;
    // check section has cover before save
    if (section.id) {
      prevSection = await this.getExamSection(section.examId, section.id);
      deleteCover = (!!prevSection.coverImage && !section.coverImage) ||
        (!!prevSection.coverImage && section.coverImage?.includes(BASE64_SUFFIX));
    }
    const sectionId = section.id ?? this.generateNewDocumentId();
    const obj = cloneDeep(typeof section.toObject !== 'undefined' ? section.toObject() : section);
    // check mime type
    const mimeTypeParams = this.getImageMetadataParams(section.coverImage);
    let coverImage;
    // if mimi type true then need upload image
    if (mimeTypeParams) {
      // save base64 to tmp variable for upload after save document
      coverImage = section.coverImage;
      // remove base64 string. after upload, this field will be assigned the value url
      obj.coverImage = prevSection.coverImage ?? null;
    }
    // save section object
    delete obj.id;
    if (obj.parentId === obj.examId) {
      delete obj.parentId;
    }
    return this.examSectionsReferenceCollectionRef(section.examId)
      .doc(sectionId)
      .set(obj, {merge: false})
      .then(async () => {
        // prepare and upload section cover
        if (coverImage && mimeTypeParams) {
          const fileName = `${sectionId}-${new Date().getTime()}.${mimeTypeParams.fileExtension}`;
          return this.storageDataService.uploadAnyObjectToStorageByDataPath(
            this.examSectionCoverStoragePath(section.examId, sectionId), fileName, coverImage, mimeTypeParams.mimeType)
            .then(snapshot => snapshot.ref.getDownloadURL()
              // save section object with url
              .then(url => this.examSectionsReferenceCollectionRef(section.examId)
                .doc(sectionId)
                .set({coverImage: url}, {merge: true})
                .then(async () => {
                  if (deleteCover) {
                    // delete prev cover
                    const prevFileName = this.utils.extractFileNameFromUrl(prevSection.coverImage);
                    await this.storageDataService.deleteObjectFromStorageByDataPath(
                      this.examSectionCoverStoragePath(section.examId, sectionId), prevFileName);
                  }
                  return sectionId;
                })));
        } else if (deleteCover) {
          // delete prev cover
          const prevFileName = this.utils.extractFileNameFromUrl(prevSection.coverImage);
          await this.storageDataService.deleteObjectFromStorageByDataPath(
            this.examSectionCoverStoragePath(section.examId, sectionId), prevFileName);
          return sectionId;
        }
        return sectionId;
      });
  }

  deleteExamSection(section: ExamSection) {
    return this.examSectionsReferenceCollectionRef(section.examId)
      .doc(section.id)
      .delete()
      .then(async () => {
        if (section.coverImage) {
          const fileName = this.utils.extractFileNameFromUrl(section.coverImage);
          await this.storageDataService.deleteObjectFromStorageByDataPath(
            this.examSectionCoverStoragePath(section.examId, section.id), fileName);
        }
        return section.id;
      });
  }

  saveContent(content: ContentContainer) {
    if (!content.status) {
      content.status = EXAM_CONTENT_STATUS.DRAFT;
    }
    return !content.id ? this.contentContainerService.add(content) : this.contentContainerService.update(content);
  }

  updateOrderIndex(content: ContentContainer, orderIndex: number) {
    return this.contentContainerService.updateContentFields(content, {orderIndex: orderIndex}, CONTENT_PATH_TYPE.DEFAULT);
  }

  updatePoints(content: ContentContainer) {
    return this.contentContainerService.simpleUpdateContent(content, CONTENT_PATH_TYPE.DEFAULT);
  }

  updateStatus(content: ContentContainer, status: string) {
    return this.contentContainerService.updateContentFields(content, {status: status}, CONTENT_PATH_TYPE.DEFAULT);
  }

  deleteContent(content: ContentContainer) {
    return this.contentContainerService.delete(content);
  }

  pasteContent(examId: string, sectionId: string, content: ContentContainer, orderIndex: number, type: EXAM_CLIPBOARD_TYPE) {
    return firstValueFrom(this.http.post(this.API_URL + 'pasteExamContent',
      {
        value: {
          dstExamId: examId,
          dstSectionId: sectionId,
          srcExamId: content.eventId,
          srcSectionId: content.parentId,
          srcContentId: content.id,
          orderIndex: orderIndex,
          pasteType: type
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  pasteSection(examId: string, parentId: string, section: ExamSection, orderIndex: number, type: EXAM_CLIPBOARD_TYPE) {
    return firstValueFrom(this.http.post(this.API_URL + 'pasteExamSection',
      {
        value: {
          dstExamId: examId,
          dstParentId: parentId,
          srcExamId: section.examId,
          srcSectionId: section.id,
          orderIndex: orderIndex,
          pasteType: type
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  loadExamSectionAdditionContents(section: ExamSection): Observable<ContentContainer[]> {
    return this.examSectionAdditionContentsReferenceCollectionRef(section.examId, section.id)
      .valueChanges({idField: 'id'})
      .pipe(
        this.log('loadExamSectionAdditionContents'),
        map(res => this.mapArray(res, (it) => new ContentContainer(it))),
        catchError(err => this.firestoreCatchError(err, 'loadExamSectionAdditionContents'))
      );
  }

  saveSectionAdditionContent(content: ContentContainer, section: ExamSection) {
    if (!section.id) {
      throw Error('Section id is null. Save section before save content.');
    }
    if (!content.parentId) {
      content.parentId = section.id;
    }
    return !content.id ? this.contentContainerService.add(content) : this.contentContainerService.update(content);
  }

  deleteSectionAdditionContent(content: ContentContainer) {
    return this.contentContainerService.delete(content);
  }

  userStartPauseExam(exam: Exam, state: USER_EXAM_STATE) {
    return firstValueFrom(this.http.post(this.API_URL + 'userChangeExamState',
      {
        value: {
          examId: exam.examId,
          state: state
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  getUserExamTaskRunningTime(exam: Exam) {
    return firstValueFrom(this.http.post<number>(this.API_URL + 'getUserExamTaskRunningTime',
      {
        value: {
          examId: exam.examId,
        }
      }))
      .catch((e) => this.catchServerError(e));
  }

  loadUserExamAnswersMap(examId: string, userId: string) {
    return this.examUserAnswersMapDocumentReference(examId, userId)
      .valueChanges()
      .pipe(
        this.log('loadUserExamAnswersMap'),
        catchError(err => this.firestoreCatchError(err, 'loadUserExamAnswersMap'))
      );
  }

  loadExamLaunchCodes(examId: string) {
    return this.examLaunchCodesDocumentRef(examId)
      .valueChanges()
      .pipe(
        this.log('loadExamStartCodes'),
        catchError(err => this.firestoreCatchError(err, 'loadExamStartCodes'))
      );
  }

  saveExamLaunchCode(examId: string, entityId: string, code: string) {
    return firstValueFrom(this.http.post(this.API_URL + 'saveExamLaunchCode',
      {
        value: {
          examId: examId,
          entityId: entityId,
          code: code
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  launchExamByCodeCode(examId: string, code: string) {
    return firstValueFrom(this.http.post(this.API_URL + 'launchExamByCodeCode',
      {
        value: {
          examId: examId,
          code: code
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }

  loadingAndApplyLazyFields(content: ContentContainer) {
    return this.contentContainerService.loadingLazyFields(cloneDeep(content));
  }

  loadExamUsers(examId: string): Observable<ExamUser[]> {
    return this.examUsersCollectionRef(examId)
      .valueChanges({idField: 'userId'})
      .pipe(
        this.log('loadExamUsers'),
        map(res => this.mapArray(res, (it) => new ExamUser({...it, examId}))),
        catchError(err => this.firestoreCatchError(err, 'loadExamUsers'))
      );
  }

  async addUsersToExam(exam: Exam, users: ExamUser[]) {
    const addUser = (u: ExamUser) => {
      const examUserDocRef = this.examUsersCollectionRef(exam.examId).doc(u.userId).ref;
      const appUserExamDocRef = this.appUserExamsCollectionRef(u.userId).doc(exam.examId).ref;
      return this.aFirestore.firestore.runTransaction(async transaction => {
        try {
          await transaction.get(examUserDocRef);
          transaction.set(appUserExamDocRef, shortExam);
          transaction.set(examUserDocRef, omit(u, ['userId', 'examId']));
        } catch (e) {
          this.common.log.error(e);
          throw new Error(e);
        }
        return Promise.resolve();
      });
    };
    const inviteDate = new Date().getTime();
    const shortExam = {userInviteDate: inviteDate};
    for (const user of users) {
      if (!user.inviteDate) {
        user.inviteDate = inviteDate;
      }
      await addUser(user);
    }
  }

  async deleteUsersFromExam(examId: string, users: ExamUser[]) {
    const deleteUser = (u: ExamUser) => {
      const examUserDocRef = this.examUsersCollectionRef(examId).doc(u.userId).ref;
      const appUserExamDocRef = this.appUserExamsCollectionRef(u.userId).doc(examId).ref;
      return this.aFirestore.firestore.runTransaction(async transaction => {
        try {
          await transaction.get(examUserDocRef);
          transaction.delete(appUserExamDocRef);
          transaction.delete(examUserDocRef);
        } catch (e) {
          this.common.log.error(e);
          throw new Error(e);
        }
        return Promise.resolve();
      });
    };

    for (const user of users) {
      await deleteUser(user);
    }
  }

  loadUserExams(userId: string): Observable<string[]> {
    return this.appUserExamsCollectionRef(userId)
      .valueChanges({idField: 'examId'})
      .pipe(
        this.log('loadUserExams'),
        map(res => this.mapArray(res, (it) => it.examId)),
        catchError(err => this.firestoreCatchError(err, 'loadUserExams'))
      );
  }

  getExamUser(examId: string, userId: string) {
    return this.examUsersCollectionRef(examId)
      .doc(userId)
      .valueChanges()
      .pipe(
        this.log('getExamUser'),
        map(res => !isEmpty(res) ? new ExamUser({...res, userId, examId}) : null),
        catchError(err => this.firestoreCatchError(err, 'getExamUser'))
      );
  }

  loadUserContentAssessments(examId: string, contentId: string, userId: string) {
    return this.examUserContentsAssessmentsCollectionRef(examId, userId).doc(contentId).valueChanges();
  }

  saveUserContentAssessmentsExplanation(content: ContentContainer, userId: string, explanation: IExplanation) {
    return this.examSectionUsersAssessmentsStatisticsCollectionRef(content.eventId, content.parentId)
      .doc(userId)
      .set({[content.id]: {explanation: explanation}}, {merge: true});
  }

  loadUserContentAssessmentsHistory(examId: string, contentId: string, userId: string) {
    return this.examUserContentsAssessmentsHistoryCollectionRef(examId, userId).doc(contentId).valueChanges();
  }

  loadExamContent(examId: string, sectionId: string, contentId: string): Observable<ContentContainer> {
    return this.examSectionContentsReferenceCollectionRef(examId, sectionId)
      .doc(contentId)
      .get()
      .pipe(
        this.log('loadExamContent'),
        map(res => new ContentContainer({id: res.id, ...res.data()})),
        catchError(err => this.firestoreCatchError(err, 'loadExamContent'))
      );
  }

  loadQuizQuestionsAnswersByUser(userId, documentPathParams) {
    return this.contentContainerService.dataService.getQuizQuestionsAnswersByUser(documentPathParams, userId, APP_MODE.EXAMS);
  }

  loadContentQuizzesAnswersGroups(content: ContentContainer) {
    return this.contentContainerService.loadingLazyFields(content);
  }

  getContentDocumentPathParams(content: ContentContainer) {
    return this.contentContainerService.getContentDocumentPathParams(content);
  }

  setContentAssessment(content: ContentContainer, assessmentObject: any, user: ExamUser, inspector: AppUser,
                       status: EXAM_ASSESSMENT_STATUS, statusChangeDescription: string,
                       assessmentChangeDescription: string, assessmentFrom: any[], assessmentTo: any[]) {

    const isStatusSet = (currentStatus: EXAM_ASSESSMENT_STATUS, checkStatus: EXAM_ASSESSMENT_STATUS) =>
      EXAM_ASSESSMENT_STATUS_ORDER.indexOf(checkStatus) <= EXAM_ASSESSMENT_STATUS_ORDER.indexOf(currentStatus);

    // previous value, current value - values can only increase or not change
    const setIncrement = (p: boolean, c: boolean) => !!p !== !!c && !!p === false && !!c === true ? increment(1) : increment(0);

    const assessmentUserRef = this.examUsersAssessmentsCollectionRef(content.eventId)
      .doc(user.userId).ref;
    const userContentAssessmentRef = this.examUserContentsAssessmentsCollectionRef(content.eventId, user.userId)
      .doc(content.id).ref;
    const userContentAssessmentHistoryRef = this.examUserContentsAssessmentsHistoryCollectionRef(content.eventId, user.userId)
      .doc(content.id).ref;
    const userStatisticsRef = this.examSectionUsersAssessmentsStatisticsCollectionRef(content.eventId, content.parentId)
      .doc(user.userId).ref;
    const contentsStatisticsRef = this.examSectionContentsAssessmentsStatisticsCollectionRef(content.eventId, content.parentId)
      .doc(EXAMS_PATHS.CONTENTS_ASSESSMENTS_STATISTICS_DOCUMENT).ref;
    const sectionsStatisticsRef = this.examExamGeneralAssessmentsStatisticsCollectionRef(content.eventId)
      .doc(EXAMS_PATHS.SECTIONS_ASSESSMENTS_STATISTICS_DOCUMENT).ref;
    const examStatisticsRef = this.examExamGeneralAssessmentsStatisticsCollectionRef(content.eventId)
      .doc(EXAMS_PATHS.EXAM_ASSESSMENTS_STATISTICS_DOCUMENT).ref;
    return this.aFirestore.firestore.runTransaction(async transaction => {
      await transaction.get(assessmentUserRef);
      const contentStatisticsCurrent: IAssessmentStatistics = (await transaction.get(userStatisticsRef))
        .get(content.id);
      const history: any[] = [];
      const historyValue: any = {
        status: status,
        inspector: pick(inspector, ['userId', 'fullName', 'email' , 'picture']),
        createTime: new Date().getTime()
      };
      if (statusChangeDescription) {
        history.push({...cloneDeep(historyValue),
          id: this.common.utils.generateRandomString(4),
          description: statusChangeDescription});
      }
      if (!isEmpty(assessmentFrom) && !isEmpty(assessmentTo) && assessmentChangeDescription) {
        for (let i = 0; i < assessmentFrom.length; i++) {
          history.push({...cloneDeep(historyValue),
            id: this.common.utils.generateRandomString(4),
            description: assessmentChangeDescription, assessmentFrom: assessmentFrom[i], assessmentTo: assessmentTo[i]});
        }
      }
      let scoreSum = 0;
      Object.keys(assessmentObject).forEach(quizId => {
        const questionIds = Object.keys(assessmentObject[quizId]);
        questionIds.forEach(questionId => scoreSum += (assessmentObject[quizId][questionId] ?? 0));
      });
      const userStatistics = {
        [content.id]: {
          score: scoreSum,
          autoAssessed: isStatusSet(status, EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED),
          assessed: isStatusSet(status, EXAM_ASSESSMENT_STATUS.ASSESSED),
          reviewed: isStatusSet(status, EXAM_ASSESSMENT_STATUS.REVIEWED),
          approved: isStatusSet(status, EXAM_ASSESSMENT_STATUS.APPROVED),
          changeTime: new Date().getTime()
        }
      };
      const incrementObject = {
        autoAssessed: setIncrement(contentStatisticsCurrent?.autoAssessed, userStatistics[content.id].autoAssessed),
        assessed: setIncrement(contentStatisticsCurrent?.assessed, userStatistics[content.id].assessed),
        reviewed: setIncrement(contentStatisticsCurrent?.reviewed, userStatistics[content.id].reviewed),
        approved: setIncrement(contentStatisticsCurrent?.approved, userStatistics[content.id].approved),
      };
      const contentStatistics: IAssessmentContentStatisticsMap = {[content.id]: incrementObject};
      const sectionStatistics: IAssessmentContentStatisticsMap = {[content.parentId]: incrementObject};
      const examStatistics: IAssessmentContentStatistics = incrementObject;
      try {
        transaction.set(userContentAssessmentRef, assessmentObject);
        for (const hItem of history) {
          const statusHistory = {
            status: status,
            history: arrayUnion(hItem)
          };
          transaction.set(userContentAssessmentHistoryRef, statusHistory, {merge: true});
        }
        transaction.set(userStatisticsRef, userStatistics, {merge: true});
        transaction.set(contentsStatisticsRef, contentStatistics, {merge: true});
        transaction.set(sectionsStatisticsRef, sectionStatistics, {merge: true});
        transaction.set(examStatisticsRef, examStatistics, {merge: true});
        transaction.set(assessmentUserRef, pick(user, ['email', 'fullName', 'picture']));
      } catch (e) {
        this.common.log.error(e);
        throw new Error(e);
      }
      return Promise.resolve();
    });
  }

  saveContentAssessmentMessageHistory(content: ContentContainer,
                                      message: any, user: ExamUser, inspector: AppUser) {
    const userContentAssessmentHistoryRef = this.examUserContentsAssessmentsHistoryCollectionRef(content.eventId, user.userId)
      .doc(content.id).ref;
    return this.aFirestore.firestore.runTransaction(async transaction => {
      try {
        const historyValue: any = {
          inspector: pick(inspector, ['userId', 'fullName', 'email' , 'picture']),
          createTime: new Date().getTime()
        };
        const history = {...cloneDeep(historyValue),
          id: this.common.utils.generateRandomString(4),
          description: message
        };
        const statusHistory = {
          history: arrayUnion(history)
        };
        transaction.set(userContentAssessmentHistoryRef, statusHistory, {merge: true});
      } catch (e) {
        this.common.log.error(e);
        throw new Error(e);
      }
      return Promise.resolve();
    });
  }

  async getUserExamsCount(userId: string) {
    return this.appUserExamsCollectionRef(userId).ref.get().then(snap => snap.docs.length);
  }

  increaseExamDurationForUser(examId: string, userId: string, duration: number) {
    return firstValueFrom(this.http.post<number>(this.API_URL + 'increaseExamDurationForUser',
      {
        value: {
          examId: examId,
          userId: userId,
          duration: duration
        }
      }))
      .catch((e) => this.catchServerError(e));
  }

  executeExamActionForUser(examId: string, userId: string, action: USER_EXAM_ACTION, payload?: any) {
    return firstValueFrom(this.http.post(this.API_URL + 'executeExamActionForUser',
      {
        value: {
          examId: examId,
          userId: userId,
          action: action,
          payload: payload
        }
      },
      {responseType: 'text'}))
      .catch((e) => this.catchServerError(e));
  }
}
