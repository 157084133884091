import {ServiceTasksComponent} from '../service-tasks.component';
import {firstValueFrom} from 'rxjs';
import {EXAMS_PATHS} from '../../../exams/exam-constants/exam-constants';
import {omit, pick} from 'lodash';

const getAfs = (thiz: ServiceTasksComponent) => {
  return thiz.afs.collection('client_data').doc(thiz.loginService.client_id$.getValue());
};

export const clearExamUsersStatistics = async (thiz: ServiceTasksComponent, examId: string) => {
  console.log('cleaning started: ' + examId);
  // remove statical fields
  const examUsers = await firstValueFrom(thiz.examApiService.loadExamUsers(examId));
  for (const u of examUsers) {
    await getAfs(thiz).collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_USERS}`)
      .doc(u.userId)
      .set(pick(u, ['picture', 'fullName', 'email', 'examId', 'inviteDate']), {merge: false});
  }

  // remove document EXAM_ASSESSMENTS_STATISTICS_DOCUMENT
  await getAfs(thiz).collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_GENERAL_ASSESSMENTS_STATISTICS}`)
    .doc(EXAMS_PATHS.EXAM_ASSESSMENTS_STATISTICS_DOCUMENT).delete();
  // remove document SECTIONS_ASSESSMENTS_STATISTICS_DOCUMENT
  await getAfs(thiz).collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_GENERAL_ASSESSMENTS_STATISTICS}`)
    .doc(EXAMS_PATHS.SECTIONS_ASSESSMENTS_STATISTICS_DOCUMENT).delete();

  const examUsersAssessments = await firstValueFrom(getAfs(thiz)
    .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}`).valueChanges({idField: 'userId'}));
  for (const u of examUsersAssessments) {
    // remove all documents
    const caList = await firstValueFrom(getAfs(thiz)
      .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}/${u.userId}/${EXAMS_PATHS.CONTENTS_ASSESSMENT}`)
      .valueChanges({idField: 'id'}));
    for (const d of caList) {
      await getAfs(thiz)
        .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}/${u.userId}/${EXAMS_PATHS.CONTENTS_ASSESSMENT}`)
        .doc(d.id).delete();
    }
    // remove all documents
    const cahList = await firstValueFrom(getAfs(thiz)
      .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}/${u.userId}/${EXAMS_PATHS.CONTENTS_ASSESSMENT_HISTORY}`)
      .valueChanges({idField: 'id'}));
    for (const d of cahList) {
      await getAfs(thiz)
        .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}/${u.userId}/${EXAMS_PATHS.CONTENTS_ASSESSMENT_HISTORY}`)
        .doc(d.id).delete();
    }
    // remove user u
    await getAfs(thiz)
      .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.USERS_ASSESSMENTS}`)
      .doc(u.userId).delete();
  }

  const sections = await firstValueFrom(thiz.examApiService.loadExamSections(examId));
  for (const s of sections) {
    // remove all documents
    const ds = await firstValueFrom(getAfs(thiz)
      .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${s.id}/${EXAMS_PATHS.CONTENTS_ASSESSMENTS_STATISTICS}`)
      .valueChanges({idField: 'id'}));
    for (const d of ds) {
      await getAfs(thiz)
        .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${s.id}/${EXAMS_PATHS.CONTENTS_ASSESSMENTS_STATISTICS}`)
        .doc(d.id).delete();
    }
    // remove all documents
    const uds = await firstValueFrom(getAfs(thiz)
      .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${s.id}/${EXAMS_PATHS.USERS_ASSESSMENTS_STATISTICS}`)
      .valueChanges({idField: 'userId'}));
    for (const u of uds) {
      await getAfs(thiz)
        .collection(`${EXAMS_PATHS.MAIN}/${examId}/${EXAMS_PATHS.EXAM_SECTIONS}/${s.id}/${EXAMS_PATHS.USERS_ASSESSMENTS_STATISTICS}`)
        .doc(u.userId).delete();
    }
  }
  // reset exam assessment statistical fields
  const exam = await thiz.examApiService.getExam(examId);
  await getAfs(thiz)
    .collection(`${EXAMS_PATHS.MAIN}`)
    .doc(examId).set(omit(exam, [
      'countUsersExamAvailable',
      'countUsersExamReadyToStart',
      'countUsersExamResults',
      'countUsersExamAssessment',
      'countUsersExamEnded',
      'countUsersExamRunning',
      'countUsersExamPaused',
      'countUsersExamInterrupted']
    ), {merge: false});
  console.log('finished: ' + examId);
};
