import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {Constants} from '../../../../../../../core/constants';
import {IAnswer} from '../../../quiz-model/quiz';
import {QUESTION_TYPE, QUESTION_VIEW_MODE} from '../../shared/quiz-quiestion-types';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-question-check-choice-v2-results',
  templateUrl: './question-check-choice-v2-results.component.html',
  styleUrls: ['./question-check-choice-v2-results.component.scss']
})
export class QuestionCheckChoiceV2ResultsComponent extends AbstractQuizQuestionResultsComponent {

  readonly QUESTION_VIEW_MODE = QUESTION_VIEW_MODE;

  answers;
  dataSource = [];
  displayedColumns = ['check', 'answer', 'correct'];
  correctAnswersEmpty = true;
  totalAnswers = 0;
  viewMode = QUESTION_VIEW_MODE.PERCENTAGE;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.question$.pipe(this.takeUntilAlive()).subscribe(question => this.viewMode = question.viewMode ?? QUESTION_VIEW_MODE.PERCENTAGE);
  }

  protected initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];

    if (this.question.storypoint === QUESTION_TYPE.CHOICE_V2 && !this.question.useCorrectAnswers) {
      this.question.items.forEach(answer => dataList.push(
        {answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex}));
    } else {
      const correctAnswers = this.question.reduceGroupsCorrectAnswersItemsCorrectAnswer();
      this.correctAnswersEmpty = isEmpty(correctAnswers);
      this.question.items.forEach(answer => dataList.push(
        {count: 0, answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex,
          correctAnswer: correctAnswers?.[answer.id], progress: '', unprogress: ''}));
    }
    let diff = this.dataSource.length !== dataList.length;
    if (!diff) {
      diff = this.dataSource.some(item => {
        const ind = dataList.findIndex(elem => {
          if (item['id'] === elem['id'] && item['answer'] === elem['answer'] &&
            item['correctAnswer'] === elem['correctAnswer'] && item['orderIndex'] === elem['orderIndex']) {
            return true;
          }
        });
        return ind === -1;
      });
    }
    if (diff) {
      dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
      this.dataSource = dataList;
    }
    this.calcDataSourceFields();
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.common.utils.getGroupedAnswers(this.summaryQuestionAnswers);
    this.calcDataSourceFields();
  }

  private calcDataSourceFields() {
    const getPropValue = (propName: string, def: number = 0) => this.answers && this.answers[propName] ? this.answers[propName] : def;
    for (const row of this.dataSource) {
      row.count = getPropValue(row.id);
    }
    this.totalAnswers = this.dataSource.reduce((prev, curr) => prev + curr.count, 0);
    this.dataSource.forEach(row => row.percent = this.totalAnswers ? `${Math.round((row.count / this.totalAnswers) * 100)}%` : '0%');
  }
}
