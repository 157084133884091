import { Pipe, PipeTransform } from '@angular/core';
import {ILanguageParams, TCaption} from '../core/constants';
import {UtilsService} from '../core/utils.service';
import {isEmpty} from 'lodash';

@Pipe({
  name: 'translateToLanguage'
})
export class TranslateToLanguage implements PipeTransform {
  /**
   * usage:
   * for simple fields e.g content type of text, html - [value]="content.data | translateToLanguage : languageParams"
   * for complex field e.g section.title, video.url - [value]="section | translateToLanguage : languageParams : title"
   * for array of objects field e.g content type of image - [value]="pictures | translateToLanguage: languageParams: 'src' : 0"
   */
  transform(value: TCaption | any, languageParams: ILanguageParams, fieldName?: string, index?: number): string | any[] {
    if (isEmpty(value)) {
      return '';
    }
    if (Array.isArray(value) && fieldName && typeof index === 'number') {
      return UtilsService.getTranslateToLanguageFromArray(value, languageParams)[index]?.[fieldName];
    }
    if (!fieldName) {
      return UtilsService.getTranslateToLanguage(value, languageParams);
    }
    if (fieldName && !Array.isArray(value)) {
      if (!languageParams.usedMultilingualContent && typeof value[fieldName] === 'string') {
        return value[fieldName];
      }
      if (!languageParams.usedMultilingualContent && typeof value[fieldName] === 'object') {
        return UtilsService.getByLanguage(value, fieldName, languageParams);
      }
      if (languageParams.usedMultilingualContent && typeof value[fieldName] === 'object') {
        return UtilsService.getByLanguage(value, fieldName, languageParams);
      }
      return UtilsService.getByMultilingual(value, fieldName, languageParams);
    }
    return '';
  }

}
