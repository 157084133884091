import {AbstractQuizQuestionAnswersEditorComponent} from './abstract-quiz-question-answers-editor-component';
import {MatTableDataSource} from '@angular/material/table';
import {AnswerQuestionMatching, EventQuestion, TableRowAnswerQuestionMatching} from '../../../../../../../model/EventQuestion';
import {clone} from 'lodash';
import {Constants, ILanguageParams} from '../../../../../../../core/constants';

export class AbstractQuizQuestionAnswerMatchingEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  dataSourceMatching = new MatTableDataSource<TableRowAnswerQuestionMatching>();
  matchingData: TableRowAnswerQuestionMatching[] = [];
  addedMatchingId;
  dsmSelectedRowId: string;
  displayedColumnsMatching = ['matching'];

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    (question.matchingItems || []).forEach(item => this.matchingData.push(new TableRowAnswerQuestionMatching(item, this.languageParams)));
    this.matchingData.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.matchingData = this.setObjectChangesHandler(this.matchingData);
    this.dataSourceMatching.data = this.matchingData;
  }

  isFirstMatching(rowId) {
    return !rowId || 0 === this.matchingData.findIndex(item => item.id === rowId);
  }

  isLastMatching(rowId) {
    return !rowId || (this.matchingData.length - 1) === this.matchingData.findIndex(item => item.id === rowId);
  }

  addMatching() {
    const row = new TableRowAnswerQuestionMatching(new AnswerQuestionMatching(), this.languageParams);
    row.orderIndex = this.genAnswerOrderIndex();
    this.matchingData.push(row);
    this.dataSourceMatching.data = this.matchingData;
    this.addedMatchingId = row.id;
    this.dsmSelectedRowId = row.id;
    this.setRowFocus(this.addedMatchingId);
  }

  deleteMatching(rowId) {
    const index = this.matchingData.findIndex(item => item.id === rowId);
    this.matchingData.splice(index, 1);
    this.dataSourceMatching.data = this.matchingData;
    this.dsmSelectedRowId = null;
  }

  onClickUpDownMatching(rowId, move: number) {
    const index = this.matchingData.findIndex(item => item.id === rowId);
    const temp = clone(this.matchingData[index]);
    this.matchingData[index] = clone(this.matchingData[index + move]);
    this.matchingData[index + move] = temp;
    this.matchingData.forEach((item, itemIndex) => item.orderIndex = (itemIndex + 1) * 100 );
    this.dataSourceMatching.data = this.matchingData;
  }

  translateAnswerMatching(rowId) {
    const row = this.matchingData.find(item => item.id === rowId);
    const text = row.rowCaption;
    this.translating[rowId] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => row.rowCaption = v)
      .finally(() => delete this.translating[rowId]);
  }

  applyQuestionData(): EventQuestion {
    super.applyQuestionData();
    const matchingItems = [];
    for (let i = 0; i < this.matchingData.length; i++) {
      matchingItems.push(new AnswerQuestionMatching(this.matchingData[i]));
    }
    this.question.matchingItems = matchingItems;
    return this.question;
  }

}
