import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {Subject, takeUntil} from 'rxjs';
import * as ui from '../actions/ui';
import {CommonService} from '../core/common.service';
import {LoginService} from '../login/login.service';
import {Page} from '../model/Page';
import {InjectorService} from '@ninescopesoft/core';
import {ClientConfig} from '../model/ClientConfig';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {UserMenuComponent} from './user-menu/user-menu.component';

@Component({
  standalone: true,
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  imports: [
    MainMenuComponent,
    UserMenuComponent
  ],
  styleUrls: ['./toolbar-menu.component.scss']
})
export class ToolbarMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  protected _unsubscribeAll: Subject<any> = new Subject();
  clientConfig: ClientConfig;
  @ViewChild('customToolbar', {read: ViewContainerRef}) customToolbar: ViewContainerRef;

  constructor(private store: Store<fromRoot.State>
    , private loginService: LoginService
    , private common: CommonService
    , private injectorService: InjectorService) {
    this.loginService.clientConfig$
      .subscribe(config => {
        this.clientConfig = config;
      });

    this.loginService.urlHelper
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(url => {
        this.store.dispatch(new ui.SetCurrentPageAction(new Page({
          url: url
          , name: this.getCaptionByUrl(url)
        })));
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.injectorService.mainToolbar$.next(this.customToolbar);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = null;
  }

  private getCaptionByUrl(linkUrl: string) {
    const def = 'Home';
    let url = linkUrl;
    if (!url) {return def; }
    if (url.startsWith('/%') || url.startsWith('/#')) {
      url = url.substring(url.indexOf('/', 1), url.length);
    }
    if (url.endsWith('/new')) {
      return this.common.i18n('main.menu.item.create_new');
    }
    if (url.startsWith('/')) {
      url = url.substring(1, url.length);
    }
    if (url.indexOf('/') >= 0) {
      url = url.substring(0, url.indexOf('/'));
    }
    const item = url.toLowerCase();
    let res = this.common.i18n('main.menu.item.' + item);
    if (!res || res.startsWith('main.menu.item.')) {
      res = '';
    }
    return res;
  }
}
