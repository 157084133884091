import {Component, Injector, OnInit} from '@angular/core';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {MatDialog} from '@angular/material/dialog';
import {PictureGalleryEditorDialogComponent} from '../picture-gallery-editor-dialog/picture-gallery-editor-dialog.component';
import {BehaviorSubject, combineLatest, firstValueFrom, take} from 'rxjs';
import {IMultilingual, ITEM_MARKER} from '../../../shared/container-interface';
import {
  DEFAULT_SETTINGS,
  IFollowMeData,
  IGallerySettings,
  IPicture,
  IPictureGallery
} from '../picture-gallery-model/picture-gallery-model';
import {Constants, ILanguageParams} from '../../../../../core/constants';
import {isEmpty} from 'lodash';
import {LANGUAGE} from '../../../../../core/language-constants';

@Component({
  selector: 'app-picture-gallery',
  templateUrl: './picture-gallery.component.html',
  styleUrls: ['./picture-gallery.component.scss']
})
export class PictureGalleryComponent extends AbstractContainerComponent implements OnInit {

  index = 0;
  src$ = new BehaviorSubject<string>(null);
  dataLength = 0;
  settings: IGallerySettings;
  languageParams$ = new BehaviorSubject<ILanguageParams>(null);

  constructor(protected injector: Injector,
              private dialog: MatDialog) {
    super(injector);
  }

  private get languageParams() {
    return this.languageParams$.getValue();
  }

  /**
   * default language always property lang is null
   * @param pictures
   * @private
   */
  private getLanguagePictures(pictures: IPicture[]) {
    const otherSingleLanguage = (obj: IPicture[]) => {
      const langs = obj.reduce((acc, it) => {
        if (!acc.includes(it.lang)) {
          acc.push(it.lang);
        }
        return acc;
      }, []);
      return langs.length === 1 ? obj.filter(p => p.lang === langs[0]) : [];
    };
    if (!this.languageParams.usedMultilingualContent) {
      const simpleList = (pictures || []).filter(p => !p.lang);
      const defLangList = (pictures || []).filter(p => p.lang === this.languageParams.defaultLanguage);
      const mainLangList = (pictures || []).filter(p => p.lang === Constants.TIMELINE_DEFAULT_LANGUAGE);
      const otherLanguage = otherSingleLanguage(pictures || []);
      if (!isEmpty(simpleList)) {
        return simpleList;
      } else if (!isEmpty(defLangList)) {
        return defLangList;
      } else if (!isEmpty(mainLangList)) {
        return mainLangList;
      } else if (!isEmpty(otherLanguage)) {
        return otherLanguage;
      }
      return [];
    } else {
      const simpleList = (pictures || []).filter(p => !p.lang);
      const langList = (pictures || []).filter(p => p.lang === this.languageParams.currentLanguage);
      const defLangList = (pictures || []).filter(p => p.lang === this.languageParams.defaultLanguage);
      const mainLangList = (pictures || []).filter(p => p.lang === Constants.TIMELINE_DEFAULT_LANGUAGE);
      if (!isEmpty(langList)) {
        return langList;
      } else if (!isEmpty(defLangList)) {
        return defLangList;
      } else if (!isEmpty(simpleList)) {
        return simpleList;
      } else if (!isEmpty(mainLangList)) {
        return mainLangList;
      }
      return [];
    }
  }

  private getPictures(): IPicture[] {
    // todo: remove after some time
    if (Array.isArray(this.data)) {
      this.data = {
        pictures: this.data,
        settings: DEFAULT_SETTINGS
      };
    }
    const all = (((this.data as IPictureGallery)?.pictures || []) as IPicture[]).filter(p => !p.id.startsWith(ITEM_MARKER.DELETED));
    const d = this.getLanguagePictures(all);
    this.dataLength = d.length;
    return d;
  }

  ngOnInit(): void {
    combineLatest([this.currentLanguage$, this.data$, this.usedMultilingualContent$])
      .pipe(this.takeUntilAlive())
      .subscribe(([currentLanguage, value, usedMultilingualContent]: [LANGUAGE, any, IMultilingual]) => {
        this.languageParams$.next({
          defaultLanguage: this.defaultLanguage$.getValue(),
          currentLanguage: currentLanguage,
          usedMultilingualContent: usedMultilingualContent.multilingual,
          usedLanguages: usedMultilingualContent.usedLanguages
        });
        const pictures = this.getPictures();
        this.settings = value?.settings ?? DEFAULT_SETTINGS;
        this.index = pictures[this.index] ? this.index : 0;
        this.src$.next(pictures[this.index]?.src);
        this.emmitCurrentLanguageTranslatedState();
      });
  }

  next() {
    if (this.index + 1 <= this.getPictures().length - 1) {
      this.index++;
      this.src$.next(this.getPictures()[this.index].src);
      this.outputFollowMeData.emit({index: this.index});
    }
  }

  prev() {
    if (this.index - 1 >= 0) {
      this.index--;
      this.src$.next(this.getPictures()[this.index].src);
      this.outputFollowMeData.emit({index: this.index});
    }
  }

  onEdit() {
    const dialogRef = this.dialog.open(PictureGalleryEditorDialogComponent, {
      data: {
        pictures: this.data?.pictures,
        settings: this.data?.settings,
        languageParams$: this.languageParams$
      }
    });
    return firstValueFrom(dialogRef.afterClosed().pipe(take(1)))
      .then(result => {
        if (result) {
          this.index = 0;
          this.data = result;
        }
        return !!result;
      });
  }

  protected inputFollowMeData(value: IFollowMeData) {
    this.index = value?.index ?? 0;
    if (this.getPictures()[this.index]) {
      this.src$.next(this.getPictures()[this.index].src);
    }
  }

  onNext(): boolean {
    if (this.index + 1 > this.getPictures().length - 1) {
      return false;
    }
    this.next();
    return true;
  }

  onPrev(): boolean {
    if (this.index - 1 < 0) {
      return false;
    }
    this.prev();
    return true;
  }
}
