import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import * as data from '../actions/data';
import {CommonService} from '../core/common.service';
import {Event, ICountryStatisticsMap, IEventCreateDto, UserEvent} from '../model/Event';
import {EventApiService} from './event-api.service';
import {CHANGE_LOG_VALUE_TYPE, IChangeLogValue} from './std-api-service';
import {
  EventModeApiService,
  ICopyTask,
  IDocumentPathParams,
  IExtCopyTask,
  IExtDocumentPathParams,
  IInvitedUser,
  IInviteUserEmail,
  IPinData,
  IPlayBackAction,
  IQuizConstraint,
  IRelocateParams,
  ISectionUpdateTask,
  IStorageCollectionPath,
  ISVGMetadata,
  PLACE_TYPE,
  REF_METADATA
} from './event-mode-api.service';
import {AbstractContent, CONTENT_PATH_TYPE, IContentEntityLink} from '../model/content/AbstractContent';
import {AppUser} from '../model/AppUser';
import {
  BANNER_TYPE,
  Constants,
  CONTENT_FROM_AUDIENCE_MODE,
  DIRECTION_COPY_CONTENT,
  FOLLOW_ME_MODE,
  FOLLOW_ME_USER_PARAMS,
  ISectionUserMeetingState,
  IUserMediaDevicesState,
  LINK_STATE,
  SECTION_EVENT_PHASE,
  TEMPLATE_TYPE,
  USER_TASK_FIELDS
} from '../core/constants';
import {UtilsService} from '../core/utils.service';
import {catchError, combineLatest, firstValueFrom, from, map, Observable, of, switchMap, take} from 'rxjs';
import {SectionContent} from '../model/content/SectionContent';
import {SlideContent} from '../model/content/SlideContent';
import {LinkContent} from '../model/content/LinkContent';
import {CompetitionContent} from '../model/content/CompetitionContent';
import {ClipboardContent} from '../model/content/ClipboardContent';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {SeparatorContent} from '../model/content/SeparatorContent';
import {BreakContent} from '../model/content/BreakContent';
import {IQuestionnaireContentMap, QuestionnaireContent} from '../model/content/QuestionnaireContent';
import {TextContent} from '../model/content/TextContent';
import {ExtEvent} from '../model/ExtEvent';
import {ModularContent} from '../model/content/ModularContent';
import {WordCloudTemplate} from '../model/content/WordCloudTemplate';
import {cloneDeep, isEmpty, merge, union} from 'lodash';
import {RowTask} from '../model/event-mode/RowDatesObject';
import {IDependencyQuestionnaire} from '../model/content/DependencyQuestionnaire';
import {StdDataService} from './std-data-service';
import {EventSectionType} from '../model/EventSectionType';
import {WelcomeScreen} from '../model/content/WelcomeScreen';
import {RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {InstantSettings, VirtualConferenceSettings} from '../model/event-mode/InstantSettings';
import {ConferenceUser, ConferenceUserRole} from '../model/event-mode/ConferenceUser';
import {ITaskDocumentSpeaker, TaskDocument, UserTaskDocumentObject} from '../model/content/TaskDocument';
import {ChatMessage} from '../model/content/ChatMessage';
import {DocumentChange, QueryFn} from '@angular/fire/compat/firestore';
import {
  IFollowMeAction,
  IFollowMeCommand,
  IFollowMeConferenceGrid,
  IFollowMeMeetingSpeaker,
  IFollowMeMeetingViewMode,
  IFollowMeMeetingViewModeMap,
  IFollowMePoolAction,
  IFollowMeQAAction,
  IFollowMeScrollTo,
  IFollowMeZoomTo
} from './follow-me.service';
import {PINS_MODE} from '../model/content/SocialSettings';
import {IFeedbackValue, ISectionFeedbackUserValue} from './feedback.service';
import {ConferenceRoomInvite} from '../event-mode/event-mode/conference/models/conference-participant';
import {QAMessage} from '../model/content/QAMessage';
import {IRoom} from '../modules/meeting-frame/services/daily-co.constants';
import {WhoCanHear} from '../model/content/meetingSettings';
import {ContentContainer} from '../model/content/ContentContainer';
import {IURLToBASE64Scheme, TUser} from '../modules/content-container/shared/container-interface';
import {QueueModel} from '../model/content/queue-model';
import {PlaneContent} from '../model/content/PlaneContent';
import {IRegistrationQuestionnaireAnswers} from '../model/EventQuestion';
import {IUpdateChangesParams} from './content-version-control.service';
import {RecordingStartRequest, UserAvatar} from '../model/recording';
import {IQuizTemplate, SUMMARY_SAVE_TYPE} from '../modules/content-container/components/quiz/quiz-components/shared/quiz-quiestion-types';
import {APP_MODE} from '../login/login.service';
import {RecordTimestampService} from './record-timestamp.service';

export interface ISectionChildContents {
  list: AbstractContent[];
  dirtyList: AbstractContent[];
  cleanList: AbstractContent[];
  views: {};
  dependency: IDependencyQuestionnaire[];
  relevancySummary: {};
  userLikes: {};
  sectionId: string;
}

export interface IRegSectionStatuses {
  sectionId: string;
  status: string;
}

export interface IStreamingStatus {
  streamStatus: boolean;
  screenSharingStatus: boolean;
  screenSharingUserId: string;
}

export interface ISectionContentsObject {
  list: [];
  dirtyList: [];
  cleanList: [];
  views;
  dependency;
  relevancySummary;
  userLikes;
}

@Injectable()
export class EventsDataService extends StdDataService {

  constructor(private afDB: AngularFireDatabase
    , private store: Store<fromRoot.State>
    , private eventApi: EventApiService
    , private eventModeApi: EventModeApiService
    , public utils: UtilsService
    , public common: CommonService
    , private recordingTimestampService: RecordTimestampService) {
    super();
  }

  throwFirestoreError(err) {
    this.eventModeApi.throwFirestoreError(err);
  }

  getDBPath() {
    return this.eventModeApi.getDBPath();
  }

  getPublicEvents() {
    return this.eventApi.getNewPublicEvents();
  }

  getMyEvents(past = false): Observable<UserEvent[]> {
    return this.eventApi.getMyEvents(past);
  }

  generateNewDocumentId() {
    return this.eventModeApi.generateNewDocumentId();
  }

  setMaxTimelineSectionsOrderIndex(value: number) {
    this.eventModeApi.setMaxTimelineSectionsOrderIndex(value);
  }

  getEvent(eventId: string): Observable<Event> {
    return  eventId.length > 4 ? this.eventApi.getEvent(eventId) : this.eventApi.getEventByLink(eventId);
  }

  getEventPromise(eventId: string, fromPath?: string): Promise<Event> {
    return  eventId.length > 4 ? this.eventApi.getEventPromise(eventId, fromPath) : this.eventApi.getEventByLinkPromise(eventId, fromPath);
  }

  getEventByLink(link: string): Observable<Event> {
    return this.eventApi.getEventByLink(link);
  }

  getEventByLinkPromise(link: string): Promise<Event> {
    return this.eventApi.getEventByLinkPromise(link);
  }

  getSectionTypes(eventId: string): Observable<EventSectionType[]> {
    return this.eventApi.getSectionTypes(eventId);
  }

  getSectionTypesPromise(eventId: string): Promise<EventSectionType[]> {
    return this.eventApi.getSectionTypesPromise(eventId);
  }

  addSectionType(eventId: string, sectionType: EventSectionType): Promise<any> {
    return this.eventApi.addSectionType(eventId, sectionType);
  }

  updateSectionTypes(eventId: string, types: EventSectionType[], deleteType: {id: any}, clientId?: string) {
    return this.eventApi.updateSectionTypes(eventId, (types || []).map(o => new EventSectionType(o)), deleteType, clientId);
  }

  dispatchUsersOnline(eventId: string) {
    this.store.dispatch(new data.GetUsersOnlineAction(eventId));
  }

  dispatchUsersOnlineShort(eventId: string) {
    this.store.dispatch(new data.GetUsersOnlineShortAction(eventId));
  }

  /**
   * @param {AbstractContent} content
   * @param eventId - Can be null. Not null if need increment view counter in FB for this content.
   */
  dispatchSetCurrentContent(content: AbstractContent) {
    this.store.dispatch(new data.SetCurrentContent(content));
  }

  dispatchSetNotifyEventMessage(object: any) {
    this.store.dispatch(new data.SetNotifyEventMessage(object));
  }

  updateUserOnlineStatus(link: string, oldLink: string, userKey: string, online: boolean, connectionInf: any) {
    return this.eventModeApi.updateUserOnlineStatus(link, oldLink, userKey, online, connectionInf);
  }

  sendUserOnlinePing(eventId: number, userId: string, userKey: string) {
    this.eventModeApi.sendUserOnlinePing(eventId, userId, userKey);
  }

  getEventIdByLink(link: string) {
    return this.eventApi.getEventIdByLink(link);
  }

  getFeatureLineContentId(eventId: string) {
    return this.eventModeApi.getFutureLine(eventId);
  }

  getFeatureLineContentIdPromise(eventId: string) {
    return this.eventModeApi.getFutureLinePromise(eventId);
  }

  getInstantSettings(eventId: string): Observable<InstantSettings> {
    return this.eventModeApi.getInstantSettings(eventId);
  }

  getInstantSettingsPromise(eventId: string): Promise<InstantSettings> {
    return this.eventModeApi.getInstantSettingsPromise(eventId);
  }

  saveUserSection(eventId: string, timelineId: string, params: any): any {
    return this.eventModeApi.saveUserSection(eventId, timelineId, params);
  }

  saveUserFollowMeSettings(eventId: string, param: FOLLOW_ME_USER_PARAMS, sectionId: string) {
    return this.eventModeApi.saveUserFollowMeSettings(eventId, param, sectionId);
  }
  /**
   * Restrict user access to section
   */
  setSectionUser(eventId: string, sectionId: string, userId: string, value: boolean): Promise<any> {
    return this.eventModeApi.setSectionUser(eventId, sectionId, userId, value);
  }

  allowReceiveICS(eventId: string, value: boolean): Promise<any> {
    return this.eventModeApi.allowReceiveICS(eventId, value);
  }

  getUserData(eventId: string): Observable<any> {
    return this.eventModeApi.getUserData(eventId);
  }

  getUsersDataPromise(eventId: string): Promise<any[]> {
    return this.eventModeApi.getUsersDataPromise(eventId);
  }

  getSectionUsersDataPromise(eventId: string, sectionId: string): Promise<any[]> {
    return this.eventModeApi.getSectionUsersDataPromise(eventId, sectionId);
  }

  joinInvitedToSection(eventId: string, timelineId: string, params: any): any {
    return this.eventModeApi.joinInvitedToSection(eventId, timelineId, params);
  }

  setFeatureLine(eventId: string, timelineId: string) {
    return this.eventModeApi.setFeatureLine(eventId, timelineId);
  }

  setFeatureLineChangeTimer(eventId: string, changeTimerValue?: number) {
    return this.eventModeApi.setFeatureLineChangeTimer(eventId, changeTimerValue);
  }

  getFutureLineChangeTimer(eventId: string) {
    return this.eventModeApi.getFutureLineChangeTimer(eventId);
  }

  addSectionContent(eventId: string, content: SectionContent) {
    return this.eventModeApi.addSectionContent(eventId, content);
  }

  /**
   * Don't check exists root section.
   * @param {number} eventId
   * @param content
   * @returns {Promise<any> | any}
   */
  addSimpleSectionContent(eventId: string, content: any): Promise<string> {
    return this.eventModeApi.addSimpleSectionContent(eventId, content);
  }

  updateSectionContent(eventId: string, timelineId: string, content: any, restricted?: boolean): Promise<any> {
    return this.eventModeApi.updateSectionContent(eventId, timelineId, content, restricted);
  }

  /**
   * Update sections fields with values, update modules fields with values
   * @param eventId
   * @param sectionValues
   * @param moduleValues
   */
  updateSectionAndModulesFieldsValues(eventId: string, sectionValues: {sectionId: string, fieldName: string, value: any}[],
                                      moduleValues: {moduleId: string, fieldName: string, value: any}[]) {
    return this.eventModeApi.updateSectionAndModulesFieldsValues(eventId, sectionValues, moduleValues);
  }

  updateSectionVirtualConferenceSettings(eventId: string, timelineId: string, settings: VirtualConferenceSettings): Promise<any> {
    return this.eventModeApi.updateSectionVirtualConferenceSettings(eventId, timelineId, settings);
  }

  incContentLike(eventId: string, parentId: string, timelineId: string, increment: number, oldValue: number,
                 notCalcSummaryIncrement: boolean): any {
    return this.eventModeApi.incContentLike(eventId, parentId, timelineId, increment, oldValue, notCalcSummaryIncrement);
  }

  deleteSectionContent(eventId: string, timelineId: string, title: string): Promise<any> {
    return this.eventModeApi.deleteSectionContent(eventId, timelineId, title);
  }

  deleteSimpleSection(eventId: string, timelineId: string): any {
    return this.eventModeApi.deleteSimpleSection(eventId, timelineId);
  }

  addBreakContent(eventId: string, content: any) {
    return this.eventModeApi.addBreakContent(eventId, content);
  }

  editBreakContent(eventId: string, timelineId: string, content: any): any {
    return this.eventModeApi.editBreakContent(eventId, timelineId, content);
  }

  deleteBreakContent(eventId: string, parentId: string, timelineId: string): any {
    return this.eventModeApi.deleteBreakContent(eventId, parentId, timelineId);
  }

  editQuestionnaireContentSimple(eventId: string, timelineId: string, content: any): any {
    return this.eventModeApi.editQuestionnaireContentSimple(eventId, timelineId, content);
  }

  updateQuestionImageUrl(eventId: string, timelineId: string, parentId: string, questionId: string, imageUrl: string): any {
    return this.eventModeApi.updateQuestionImageUrl(eventId, timelineId, parentId, questionId, imageUrl);
  }

  updateQuestionTemplateUrl(eventId: string, timelineId: string, parentId: string, questionId: string, imageUrl: string): any {
    return this.eventModeApi.updateQuestionTemplateUrl(eventId, timelineId, parentId, questionId, imageUrl);
  }

  addQuestionnaireContent(eventId: string, content: any) {
    return this.eventModeApi.addQuestionnaireContent(eventId, new QuestionnaireContent(content));
  }

  editQuestionnaireContent(eventId: string, timelineId: string, title: string, content: any, isPublic: boolean): any {
    return this.eventModeApi.editQuestionnaireContent(eventId, timelineId, title, new QuestionnaireContent(content), isPublic, true);
  }

  editRegistrationQuestionnaire(eventId: string, timelineId: string, title: string, content: any): any {
    return this.eventModeApi.editRegistrationQuestionnaire(eventId, timelineId, title, content);
  }

  deleteQuestionnaireContent(eventId: string, parentId: string, timelineId: string, deleteDraft: boolean): any {
    return this.eventModeApi.deleteQuestionnaireContent(eventId, parentId, timelineId, deleteDraft);
  }

  deleteRegistrationQuestionnaire(eventId: string, sectionId: string): any {
    return this.eventModeApi.deleteRegistrationQuestionnaire(eventId, sectionId);
  }

  deleteRegistrationUsers(eventId: string, sectionId: string): any {
    return this.eventModeApi.deleteRegistrationUsers(eventId, sectionId);
  }

  deleteSectionRegistrationSummary(eventId: string, sectionId: string): any {
    return this.eventModeApi.deleteSectionRegistrationSummary(eventId, sectionId);
  }

  editWelcomeScreen(eventId: string, content: WelcomeScreen, clientId?: string): Promise<any> {
    return this.eventModeApi.editWelcomeScreen(eventId, content, clientId);
  }

  addModularContent(eventId: string, content: ModularContent): Promise<string> {
    return this.eventModeApi.addModularContent(eventId, content);
  }

  editModularContent(eventId: string, timelineId: string, content: any, fullUpdateDocument = false): any {
    return this.eventModeApi.editContent(eventId, timelineId, content, fullUpdateDocument);
  }

  deleteModularContent(eventId: string, parentId: string, timelineId: string, deleteDraft: boolean): any {
    return this.eventModeApi.deleteModularContent(eventId, parentId, timelineId, deleteDraft);
  }

  editAnyContent(eventId: string, timelineId: string, content: any): Promise<any> {
    return this.eventModeApi.editContent(eventId, timelineId, content);
  }

  simpleEditSection(eventId: string, sectionId: string, content: any): Promise<any> {
    return this.eventModeApi.simpleEditSection(eventId, sectionId, content);
  }

  addTaskDocumentContent(eventId: string, content: any) {
    return this.eventModeApi.addTaskDocumentContent(eventId, content);
  }

  editTaskDocumentContent(eventId: string, timelineId: string, content: any): any {
    return this.eventModeApi.editContent(eventId, timelineId, content);
  }

  deleteTaskDocumentContent(eventId: string, parentId: string, timelineId: string, deleteDraft: boolean): any {
    return this.eventModeApi.deleteTaskDocumentContent(eventId, parentId, timelineId, deleteDraft);
  }

  updateEventInstantSettings(eventId: string, instantSettings: any, clientId?: string): Promise<void> {
    return this.eventModeApi.updateEventInstantSettings(eventId, instantSettings, clientId);
  }

  loadUserInformation(eventId: string, userId: string, takeOne = false): Observable<ConferenceUser> {
    return this.eventModeApi.loadUserInformation(eventId, userId, takeOne);
  }

  getWelcomeScreen(eventId: string): Observable<WelcomeScreen> {
    return this.eventModeApi.getWelcomeScreen(eventId);
  }

  getWelcomeScreenPromise(eventId: string): Promise<WelcomeScreen> {
    return this.eventModeApi.getWelcomeScreenPromise(eventId);
  }

  getEventAdditional(eventId: string): Promise<any> {
    return this.eventModeApi.getEventAdditional(eventId);
  }

  private checkEventPresenter(eventId: string, userId: string): any {
    return this.eventModeApi.checkEventPresenter(eventId, userId);
  }

  checkPastEventPromise(eventId: string, userId: string): Promise<any> {
    return this.eventModeApi.checkPastEventPromise(eventId, userId);
  }

  getUsersOnline(eventId: string) {
    return this.eventModeApi.getUsersOnline(eventId);
  }

  getUsersOnlineChange(eventId: string) {
    return this.eventModeApi.getUsersOnlineChange(eventId);
  }

  getUsersOnlinePromise(eventId: string): Promise<ConferenceUser[]> {
    return this.eventModeApi.getUsersOnlinePromise(eventId);
  }

  getEventUsersRolePromise(eventId: string, userRole: ConferenceUserRole): Promise<ConferenceUser[]> {
    return this.eventModeApi.getEventUsersRolePromise(eventId, userRole);
  }

  isViewerUserPromise(eventId: string, email: string): Promise<ConferenceUser> {
    return this.eventModeApi.isViewerUserPromise(eventId, email);
  }

  getUserOnlineByUserId(eventId: string, userId: string): Promise<ConferenceUser> {
    return this.eventModeApi.getUserOnlineByUserId(eventId, userId);
  }

  loadUsersOnlineShort(eventId: string) {
    return this.eventModeApi.loadUsersOnlineShort(eventId);
  }

  loadUsersOnlinePingList(eventId: string) {
    return this.eventModeApi.loadUsersOnlinePingList(eventId);
  }

  getSectionContents(eventId: string, section: SectionContent | SectionTimeline, secondParentId: string,
                     user: AppUser, isPresenter: boolean): Observable<any[]> {
    return combineLatest([
      this.eventModeApi.getSectionContents(eventId, section, secondParentId, user, isPresenter)
        .pipe(map(obj => obj.map(value => this.createTypedContent(value)))),
      this.eventModeApi.getSectionDraftContents(eventId, section)
        .pipe(map(obj => obj.map(value => this.createTypedContent(value))))])
      .pipe(switchMap(([list, draft]) => {
        return of(union(list, draft));
      }));
  }

  getSectionContent(eventId: string, sectionId: string, contentId: string): Observable<any> {
    return this.eventModeApi.getSectionContent(eventId, sectionId, contentId)
      .pipe(map(obj => {
        const content = this.createTypedContent(obj);
        return typeof content === 'string' ? null : content;
      }));
  }

  getSectionContentPromise(eventId: string, sectionId: string, contentId: string): Promise<any> {
    return this.eventModeApi.getSectionContentPromise(eventId, sectionId, contentId)
      .then(obj => {
        const content = this.createTypedContent(obj);
        return typeof content === 'string' ? null : content;
      });
  }

  getSectionsByParent(eventId: string, sectionId: string) {
    return this.eventModeApi.getSectionsByParent(eventId, sectionId);
  }

  loadUsersOnlinePing(eventId: string) {
    return this.eventModeApi.loadUsersOnlinePing(eventId);
  }

  getUsersOnlineDevices(eventId: string) {
    return this.eventModeApi.getUsersOnlineDevices(eventId)
      .pipe(
        map(res => this.toMap(res))
      );
  }

  getEvents(hideFinished = false, onlyGuest = false): Observable<Event[]> {
    return this.eventApi.getEvents(hideFinished, onlyGuest)
      .pipe(map(list => list.filter(it => !it.template)));
  }

  loadEventList(hideFinished = false, onlyGuest = false, template = false) {
    return this.eventApi.getEvents(hideFinished, onlyGuest)
      .pipe(
        map(list => {
          return list
            .filter(it => onlyGuest ? !it.dopState : it)
            .filter(it => template ? it.template : it)
            .reduce((obj, item) => {
              obj[item.eventId] = item;
              return obj;
            }, {});
        }));
  }

  listenConnectionState(eventId: string, currentUser: AppUser) {
    return this.eventModeApi.listenConnectionState(eventId, currentUser);
  }

  offListenConnectionState() {
    return this.eventModeApi.offListenConnectionState();
  }

  uploadImageToStorage(eventId: string, timelineId: string, imageId: string, base64: string) {
    return this.eventModeApi.uploadImageToStorage(eventId, timelineId, imageId, base64);
  }

  uploadPdfToStorage(eventId: string, timelineId: string, imageId: string, base64: string) {
    return this.eventModeApi.uploadPdfToStorage(eventId, timelineId, imageId, base64);
  }

  uploadZifToStorage(eventId: string, timelineId: string, imageId: string, base64: string) {
    return this.eventModeApi.uploadZifToStorage(eventId, timelineId, imageId, base64);
  }

  deleteImageFromStorage(eventId: string, timelineId: string, imageId: string) {
    return this.eventModeApi.deleteImageFromStorage(eventId, timelineId, imageId);
  }

  deletePdfFromStorage(eventId: string, timelineId: string, imageId: string) {
    return this.eventModeApi.deletePdfFromStorage(eventId, timelineId, imageId);
  }

  deleteZifFromStorage(eventId: string, timelineId: string, imageId: string) {
    return this.eventModeApi.deleteZifFromStorage(eventId, timelineId, imageId);
  }

  uploadPresenterAnyDocumentToStorage(eventId: string, timelineId: string, documentName: string, base64: string, metaType: string) {
    return this.eventModeApi.uploadPresenterAnyDocumentToStorage(eventId, timelineId, documentName, base64, metaType);
  }

  deletePresenterAnyDocumentFromStorage(eventId: string, timelineId: string, documentName: string) {
    return this.eventModeApi.deletePresenterAnyDocumentFromStorage(eventId, timelineId, documentName);
  }

  uploadUserAnyDocumentToStorage(eventId: string, timelineId: string, userId: string,
                                 documentName: string, base64: string, metaType: string) {
    return this.eventModeApi.uploadUserAnyDocumentToStorage(eventId, timelineId, userId, documentName, base64, metaType);
  }

  deleteUserAnyDocumentFromStorage(eventId: string, timelineId: string, userId: string, documentName: string) {
    return this.eventModeApi.deleteUserAnyDocumentFromStorage(eventId, timelineId, userId, documentName);
  }

  saveSvgMetadataAndUploadToStorage(eventId: string, sectionId: string, contentId: string, moduleId: string, ownerId: string,
                                    svgId: string, base64: string,
                                    orderIndex: number, placeType: PLACE_TYPE,
                                    reference: REF_METADATA) {
    return this.eventModeApi.saveSvgMetadataAndUploadToStorage(eventId, sectionId, contentId, moduleId, ownerId,
      svgId, base64, orderIndex, placeType, reference);
  }

  uploadSvgToStorage(eventId: string, sectionId: string, contentId: string, ownerId: string,
                     svgId: string, base64: string, reference: REF_METADATA) {
    return this.eventModeApi.uploadSvgToStorage(eventId, contentId, ownerId, svgId, base64).then(() =>
      this.eventModeApi.refreshSvgMetadataForClient(eventId, sectionId, contentId, svgId , reference));
  }

  savePinSvgMetadata(eventId: string, sectionId: string, contentId: string, moduleId: string, ownerId: string,
                     pinXY: string, picture: string, placeType: PLACE_TYPE,
                     pinData: {pinMode: PINS_MODE, pinText?: string, pinNumber?: number, reference: REF_METADATA,
                       userId?: string, userName?: string}) {
    return this.eventModeApi.savePinSvgMetadata(eventId, sectionId, contentId, moduleId, ownerId, pinXY, picture, placeType, pinData);
  }

  editPinSvgMetadata(eventId: string, sectionId: string, contentId: string, moduleId: string, ownerId: string,
                     pinXY: string, picture: string, placeType: PLACE_TYPE,
                     pinData: IPinData) {
    return this.eventModeApi.editPinSvgMetadata(eventId, sectionId, contentId, moduleId, ownerId, pinXY, picture, placeType, pinData);
  }

  deletePinSvgMetadata(eventId: string, sectionId: string, contentId: string, pinId: string,
                       referece: REF_METADATA) {
    return this.eventModeApi.deletePinSvgMetadata(eventId, sectionId, contentId, pinId, referece);
  }

  deleteSvgLine(eventId: string, sectionId: string, contentId: string, ownerId: string, svgId: string, referece: REF_METADATA) {
    return this.eventModeApi.deleteSvgLine(eventId, sectionId, contentId, ownerId, svgId, referece);
  }

  getSvgFromStorage(eventId: string, timelineId: string, ownerId: string, svgId: string) {
    return this.eventModeApi.getSvgFromStorage(eventId, timelineId, ownerId, svgId);
  }

  deleteImageSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string, ownerId: string,
                                       type: 'general' | 'personal') {
    return this.eventModeApi.deleteImageSvgFromStorageAndMetadata(eventId, sectionId, contentId, ownerId, type);
  }

  deleteModularContentAllSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string) {
    return this.eventModeApi.deleteModularContentAllSvgFromStorageAndMetadata(eventId, sectionId, contentId);
  }

  deleteModularContentAllGlobalSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string) {
    return this.eventModeApi.deleteModularContentAllGlobalSvgFromStorageAndMetadata(eventId, sectionId, contentId);
  }

  deleteModularContentModuleSvgFromStorageAndMetadata(eventId: string, sectionId: string, contentId: string, moduleId: string) {
    return this.eventModeApi.deleteModularContentModuleSvgFromStorageAndMetadata(eventId, sectionId, contentId, moduleId);
  }

  getImageSvgMetadata(eventId: string, sectionId: string, contentId: string, ownerId: string,
                      reference: REF_METADATA): Observable<DocumentChange<ISVGMetadata>[]> {
    return this.eventModeApi.getSvgMetadata(eventId, sectionId, contentId, ownerId, reference);
  }

  getPinSvgMetadata(eventId: string, sectionId: string, contentId: string, ownerId: string,
                    reference: REF_METADATA): Observable<DocumentChange<ISVGMetadata>[]> {
    return this.eventModeApi.getSvgMetadata(eventId, sectionId, contentId, ownerId, reference);
  }

  public getAllSvgMetadataPromise(eventId: string, sectionId: string, contentId: string,
                                  reference: REF_METADATA): Promise<ISVGMetadata[]> {
    return this.eventModeApi.getAllSvgMetadataPromise(eventId, sectionId, contentId, reference);
  }

  public saveSvgMetadata(eventId: string,  sectionId: string, contentId: string, svgId: string, svgMetadata: ISVGMetadata,
                         reference: REF_METADATA) {
    return this.eventModeApi.saveSvgMetadata(eventId,  sectionId, contentId, svgId, svgMetadata, reference);
  }

  getClipboardDownloadURL(eventId: string, timelineId: string): any {
    return this.eventModeApi.getClipboardDownloadURL(eventId, timelineId);
  }

  deleteImage(eventId: string, timelineId: string) {
    return this.eventModeApi.deleteImage(eventId, timelineId);
  }

  uploadQuestionImage(eventId: string, timelineId: string, questionId: string, base64: string) {
    return this.eventModeApi.uploadQuestionImage(eventId, timelineId, questionId, base64);
  }

  deleteQuestionImage(eventId: string, timelineId: string, questionId: string): any {
    return this.eventModeApi.deleteQuestionImage(eventId, timelineId, questionId);
  }

  deleteQuestionWordCloudTemplate(eventId: string, timelineId: string, questionId: string): any {
    return this.eventModeApi.deleteQuestionWordCloudTemplate(eventId, timelineId, questionId);
  }

  getQuestionDownloadURL(eventId: string, timelineId: string, questionId: string): Promise<any> {
    return this.eventModeApi.getQuestionDownloadURL(eventId, timelineId, questionId);
  }

  getQuestionTemplateDownloadURL(eventId: string, timelineId: string, questionId: string): Promise<any> {
    return this.eventModeApi.getQuestionTemplateDownloadURL(eventId, timelineId, questionId);
  }

  getModuleImageDownloadURL(eventId: string, timelineId: string, imageId: string): Promise<any> {
    return this.eventModeApi.getModuleImageDownloadURL(eventId, timelineId, imageId);
  }

  getModulePDFDownloadURL(eventId: string, timelineId: string, imageId: string): Promise<any> {
    return this.eventModeApi.getModulePDFDownloadURL(eventId, timelineId, imageId);
  }

  getModuleZIFDownloadURL(eventId: string, timelineId: string, imageId: string): Promise<any> {
    return this.eventModeApi.getModuleZIFDownloadURL(eventId, timelineId, imageId);
  }

  getTaskDocumentPresenterDownloadURL(eventId: number, timelineId: number, objectName: string): Promise<any> {
    return this.eventModeApi.getTaskDocumentPresenterDownloadURL(eventId, timelineId, objectName);
  }

  uploadEventLogo(eventId: string, base64: string, clientId?: string) {
    return this.eventModeApi.uploadEventLogo(eventId, base64, clientId);
  }

  uploadEventBanner(eventId: number, base64: string, bannerType: BANNER_TYPE, clientId?: string) {
    return this.eventModeApi.uploadEventBanner(eventId, base64, bannerType, clientId);
  }

  /**
   * Use only in duplicate event, not use in other any case!!!!
   * @param eventId
   * @param logoUrl
   */
  updateEventLogoUrl(eventId: string, logoUrl: string, clientId?: string) {
    return this.eventModeApi.updateEventLogoUrl(eventId, logoUrl, clientId);
  }

  updateEventVerticalBanner(eventId: string, url: string, clientId?: string) {
    return this.eventModeApi.updateEventVerticalBanner(eventId, url, clientId);
  }

  updateEventBackground(eventId: string, url: string, clientId?: string) {
    return this.eventModeApi.updateEventBackground(eventId, url, clientId);
  }

  updateEventMobileBackground(eventId: string, url: string, clientId?: string) {
    return this.eventModeApi.updateEventMobileBackground(eventId, url, clientId);
  }

  updateEventHorizontalBanner(eventId: string, url: string, clientId?: string) {
    return this.eventModeApi.updateEventHorizontalBanner(eventId, url, clientId);
  }

  deleteEventLogo(eventId: string): any {
    return this.eventModeApi.deleteEventLogo(eventId);
  }

  deleteEventBanner(eventId: string, bannerType: BANNER_TYPE): any {
    return this.eventModeApi.deleteEventBanner(eventId, bannerType);
  }

  getEventDownloadURL(eventId: string): any {
    return this.eventModeApi.getEventDownloadURL(eventId);
  }

  getEventBannerDownloadURL(eventId: number, bannerType: BANNER_TYPE): any {
    return this.eventModeApi.getEventBannerDownloadURL(eventId, bannerType);
  }

  uploadWelcomeScreen(eventId: string, base64: string, mobile: boolean) {
    return this.eventModeApi.uploadWelcomeScreen(eventId, base64, mobile);
  }

  deleteWelcomeScreen(eventId: string, mobile: boolean): any {
    return this.eventModeApi.deleteWelcomeScreen(eventId, mobile);
  }

  getWelcomeScreenDownloadURL(eventId: string, mobile: boolean): any {
    return this.eventModeApi.getWelcomeScreenDownloadURL(eventId, mobile);
  }

  getWordCloudTemplateURL(path: string): any {
    return this.eventModeApi.getWordCloudTemplateURL(path);
  }

  getQuestionnaireAnswers(eventId: string, sectionId: string, timelineId: string) {
    return this.eventModeApi.getQuestionnaireAnswers(eventId, sectionId, timelineId);
  }

  getQuestionnaireAnswersPromise(eventId: string, sectionId: string, timelineId: string) {
    return this.eventModeApi.getQuestionnaireAnswersPromise(eventId, sectionId, timelineId);
  }

  getQuestionnaireAnswersByUser(userId: string, eventId: string, sectionId: string,
                                timelineId: string, anonymousMode: boolean): Promise<any> {
    return this.eventModeApi.getQuestionnaireAnswersByUser(userId, eventId, sectionId, timelineId, anonymousMode);
  }

  sendAnswerQ(userKey: string, eventId: string, parentId: string, timelineId: string, questionId: string,
              answer: string[], anonymousAnswers:  boolean): any {
    return this.eventModeApi.sendAnswerQ(userKey, eventId, parentId, timelineId, questionId, answer,
      anonymousAnswers);
  }

  clearDependentAnswersQ(userKey: string, eventId: string, sectionId: string, timelineId: string, questionsIdList: string[],
                         anonymousAnswers: boolean): Promise<any> {
    return this.eventModeApi.clearDependentAnswersQ(userKey, eventId, sectionId, timelineId, questionsIdList,
      anonymousAnswers);
  }

  getRegistrationQuestionnaireAnswers(eventId: string, sectionId: string) {
    return this.eventModeApi.getRegistrationQuestionnaireAnswers(eventId, sectionId);
  }

  getRegistrationQuestionnaireAnswersByUser(userId: string, eventId: string, sectionId: string) {
    return this.eventModeApi.getRegistrationQuestionnaireAnswersByUser(userId, eventId, sectionId);
  }

  sendRegistrationAnswerQ(userKey: string, eventId: string, sectionId: string, questionId: string,
              answer: string[], anonymousAnswers:  boolean, logChanges: boolean): any {
    return this.eventModeApi.sendRegistrationAnswerQ(userKey, eventId, sectionId, questionId, answer, anonymousAnswers, logChanges);
  }

  leaveNote(userKey: string, eventId: string, parentId: string, timelineId: string, note: string): Promise<void> {
    return this.eventModeApi.leaveNote(userKey, eventId, parentId, timelineId, note);
  }

  leaveRegistrationNote(userKey: string, eventId: string, sectionId: string, note: string): Promise<void> {
    return this.eventModeApi.leaveRegistrationNote(userKey, eventId, sectionId, note);
  }

  leaveTimeLineValues(eventId: string, parentId: string, timelineId: string, questionId: string, values: any): any {
    return this.eventModeApi.leaveTimeLineValues(eventId, parentId, timelineId, questionId, values);
  }

  leaveRegistrationValues(eventId: string, sectionId: string, questionId: string, values: any): any {
    return this.eventModeApi.leaveRegistrationValues(eventId, sectionId, questionId, values);
  }

  saveUserInformationTime(eventId: string, fieldName: string): any {
    return this.eventModeApi.saveUserInformationTime(eventId, fieldName);
  }

  saveUserOnlineStateData(eventId: string): any {
    return this.eventModeApi.saveUserOnlineStateData(eventId);
  }

  changeConferenceUserRole(eventId: string, userId: string, role: ConferenceUserRole, action: 'add' | 'remove' = 'add'): Promise<any> {
    return this.eventModeApi.changeConferenceUserRole(eventId, userId, role, action);
  }

  saveUserInformationField(eventId: string, userId: string, fieldName: string, fieldValue: any): Promise<any> {
    return this.eventModeApi.saveUserInformationField(eventId, userId, fieldName, fieldValue);
  }

  saveUserMeetingState(eventId: string, userId: string, sectionId: string, state: ISectionUserMeetingState): Promise<any> {
    return this.eventModeApi.saveUserMeetingState(eventId, userId, sectionId, state);
  }

  saveUserMediaDevicesState(eventId: string, userId: string, state: IUserMediaDevicesState): Promise<any> {
    return this.eventModeApi.saveUserMediaDevicesState(eventId, userId, state);
  }

  getEventChatMessages(eventId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.eventModeApi.getEventChatMessages(eventId);
  }

  getEventGroupChatMessages(eventId: string, sectionId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.eventModeApi.getEventGroupChatMessages(eventId, sectionId);
  }

  getEventManagerChatMessages(eventId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.eventModeApi.getEventManagerChatMessages(eventId);
  }

  getSectionChatMessages(eventId: string, sectionId: string): Observable<DocumentChange<ChatMessage>[]> {
    return this.eventModeApi.getSectionChatMessages(eventId, sectionId);
  }

  sendChatMessage(eventId: string, type: 'event' | 'group' | 'manager' | 'section', sectionId: string, message: string) {
    return this.eventModeApi.sendChatMessage(eventId, type, sectionId, message);
  }

  deleteChatMessage(eventId: string, type: 'event' | 'group' | 'manager' | 'section', sectionId: string, message) {
    return this.eventModeApi.deleteChatMessage(eventId, type, sectionId, message);
  }

  // todo Deprecated?
  leavePersonalNote(eventId: string, parentId: string, timelineId: string, message: string): any {
    return this.eventModeApi.leavePersonalNote(eventId, parentId, timelineId, message);
  }

  addInvitedUser(eventId: string, user: IInvitedUser, sectionId: string | null, sendInviteEmail: boolean) {
    return this.eventApi.addInvitedUser(eventId, user, sectionId, sendInviteEmail);
  }

  addInvitedUsers(eventId: string, emails: IInviteUserEmail[] | string[], sectionId: string | null) {
    return this.eventApi.addInvitedUsers(eventId, emails, sectionId);
  }

  removeUserFromConference(eventId: string, userId: string, fbUserId: string, email: string) {
    return this.eventModeApi.removeUserFromConference(eventId, userId, fbUserId, email);
  }

  create(payload: IEventCreateDto) {
    return this.eventApi.create(payload);
  }

  createEvent(payload: Event, managers?: string[], clientId?: string) {
    return this.eventApi.createEvent(payload, managers, clientId);
  }

  remove(payload) {
    return this.eventApi.deleteEvent(payload);
  }

  update(event: Event) {
    return this.eventApi.updateEvent(event);
  }

  updateEventFields(event: Event, params: {[fieldName: string]: any}) {
    return this.eventApi.updateEventFields(event, params);
  }

  deleteInvitedUser(eventId: string, email: string) {
    return this.eventApi.deleteInvitedUser(eventId, email);
  }

  verifyGuestCode(eventId: string, code: string): Promise<boolean> {
    return this.eventApi.verifyGuestCode(eventId, code);
  }

  deleteManager(eventId: string, managerEmail: string) {
    return this.eventApi.deleteManager(eventId, managerEmail);
  }

  addManager(event: Event, concierge: boolean, emailList: string[]) {
    return this.eventApi.addManager(event, concierge, emailList);
  }

  getUserCard(eventId: string, userId: string): Promise<AppUser> {
    return this.eventApi.getUserCard(eventId, userId);
  }

  getUserCardByEmail(eventId: string, email: string): Promise<AppUser> {
    return this.eventApi.getUserCardByEmail(eventId, email);
  }

  sendSupportNotification(id: string) {
    return this.eventApi.sendSupportNotification(id);
  }

  changeEventCode(eventId: string) {
    return this.eventApi.changeEventCode(eventId);
  }

  addRecordingContentChange(
    eventId: string,
    ownerStreamSectionId: string,
    sectionId: string,
    contentId: string,
    recordingStart: number,
    userAvatar: UserAvatar
  ) {
    return this.recordingTimestampService
      .addRecordingContentChange(eventId, ownerStreamSectionId, sectionId || null, contentId || null, recordingStart, userAvatar);
  }

  addRecordingMetadata(eventId: string, ownerStreamSectionId: string, metadataType: string, recordingStart: number, extraPropsObj = {}) {
    return this.recordingTimestampService.addRecordingMetadata(eventId, ownerStreamSectionId, metadataType, recordingStart, extraPropsObj);
  }

  setRecordingStart(ownerStreamSectionId: string, model: RecordingStartRequest) {
    return this.recordingTimestampService.setRecordingStart(ownerStreamSectionId, model);
  }

  generateShortLink() {
    return this.eventApi.generateShortLink().then(result => {
      return result ? result : null;
    });
  }

  hasUserAccessEventV2(eventObj: Event, appUser: AppUser, eventsPresenterList: any[], userEvents: {}, pastEvent): Promise<any> {
    if (!eventObj || !appUser) {
      return null;
    }
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      // if "past mode" load only finished "Past events I attended"
        let checkContinue = false;
        if (pastEvent) {
          const extEventObj = new ExtEvent(this.utils, eventObj);
          checkContinue = (userEvents[eventObj.eventId] && extEventObj.statusFinish) || appUser.isAdmin || appUser.isModerator ||
            ((appUser.isTrainer || appUser.isSimpleRegistration) && appUser.userId === eventObj.ownerKey);
        } else {
          checkContinue = true;
        }
        if (checkContinue) {
          // always access to event if user is Presenter, Admin, Moderator or event is public
            if ((eventsPresenterList && eventsPresenterList.findIndex(userId => userId === appUser.userId) >= 0) ||
              eventObj.isPublic || appUser.isAdmin || appUser.isModerator ||
              ((appUser.isTrainer || appUser.isSimpleRegistration) && appUser.userId === eventObj.ownerKey)) {
              if (eventObj.published) {
                resolve(eventObj);
              } else
              if ((eventsPresenterList && eventsPresenterList.findIndex(userId => userId === appUser.userId) >= 0) ||
                  appUser.isAdmin || appUser.isModerator || ((appUser.isTrainer || appUser.isSimpleRegistration) &&
                  appUser.userId === eventObj.ownerKey)) {
                resolve(eventObj);
              } else {
                resolve(null);
              }
            } else {
              // common user can't access event on work mode draft or archive
              if (eventObj.published) {
                resolve(eventObj);
              } else {
                resolve(null);
              }
            }
        } else {
          resolve(null);
        }
    });
  }

  /* check priority 0 */
  private isPastEvent(eventId, userId) {
    return this.checkPastEventPromise(eventId, userId);
  }

  /* check priority 1 */
  private isEventPresenter(eventId, userId) {
    return this.checkEventPresenter(eventId, userId);
  }

  /* check priority 2 */
  private isEventOnlineUser(eventId, userId) {
    return this.loadUserInformation(eventId, userId, true).toPromise();
  }

  isEventUser(eventId, userId) {
    return this.loadUserInformation(eventId, userId, true).pipe(take(1)).toPromise();
  }

  eventListFilterByKeyAndDeleted(obj: any, pastEvent = false): boolean {
    if (!obj || !obj.eventId) {return false; }
    if (pastEvent) {
      const extEventObj = new ExtEvent(this.utils, obj);
      return obj.eventId.toString().length > Constants.MAX_TEST_EVENT_ID_LENGTH &&
        (obj.deleted === 0) && extEventObj.statusFinish === true;
    } else {
      return obj.eventId.toString().length > Constants.MAX_TEST_EVENT_ID_LENGTH && (obj.deleted === 0);
    }
  }

  /**
   * Return promise of type {qKey: questionId, imageUrl: imageUrl}
   * @param eventId
   * @param contentId
   * @param questionId
   * @param imageUrl
   * @param checkFbStorage
   * @returns {Promise<any>}
   */
  patchQuestionImage(eventId, contentId, questionId, imageUrl, checkFbStorage): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (checkFbStorage) {
      // try get URL image from FB storage, because we don't know was it in storage before edit or not.
      this.getQuestionDownloadURL(eventId, contentId, questionId).then(function (c) {
        // image at the FB storage and url not change - return this url.
        if (imageUrl && vm.utils.isFBUrl(imageUrl, eventId)) {
          resolve({qKey: questionId, imageUrl: imageUrl});
        } else {
          // delete image from FB storage.
          vm.deleteQuestionImage(eventId, contentId, questionId).then(function (r) {
            // simple image. url in internet space
            if (!imageUrl || (imageUrl && imageUrl.indexOf('data:image/') === -1 && imageUrl.indexOf(';base64,') === -1)) {
              resolve({qKey: questionId, imageUrl: imageUrl});
            } else
            // upload image into FB storage.
            if (imageUrl.indexOf('data:image/') > -1 && imageUrl.indexOf(';base64,') > -1) {
              vm.uploadQuestionImage(eventId, contentId, questionId, imageUrl).then(function (snapshot) {
                if (!snapshot) { return resolve({qKey: questionId, imageUrl: null}); }
                snapshot.ref.getDownloadURL().then(url => {
                  resolve({qKey: questionId, imageUrl: url});
                });
              }).catch(function (e) {
                vm.common.log.error('error uploadQuestionImage, content will be not updated.', e);
                reject(e);
              });
            } else {
              resolve({qKey: questionId, imageUrl: null});
            }
          }).catch(function (e) {
            vm.common.log.error('error deleteQuestionImage, content will be not updated.', e);
            reject(e);
          });
        }
      }).catch(function (e) {
        // if all ok this not happend, but stay if upssss happend!!!
        if (!imageUrl || (imageUrl && imageUrl.indexOf('data:image/') === -1 && imageUrl.indexOf(';base64,') === -1)) {
          resolve({qKey: questionId, imageUrl: imageUrl});
        } else
        // upload image into FB storage.
        if (imageUrl.indexOf('data:image/') > -1 && imageUrl.indexOf(';base64,') > -1) {
          vm.uploadQuestionImage(eventId, contentId, questionId, imageUrl).then(function (snapshot) {
            if (!snapshot) { return resolve({qKey: questionId, imageUrl: null}); }
            snapshot.ref.getDownloadURL().then(url => {
              resolve({qKey: questionId, imageUrl: url});
            });
          }).catch(function (er) {
            vm.common.log.error('error uploadQuestionImage, content will be not updated.', er);
            reject(er);
          });
        } else {
          resolve({qKey: questionId, imageUrl: null});
        }
      });
    } else {
        if (!imageUrl || (imageUrl && imageUrl.indexOf('data:image/') === -1 && imageUrl.indexOf(';base64,') === -1)) {
          resolve({qKey: questionId, imageUrl: imageUrl});
        } else
        // upload image into FB storage.
        if (imageUrl.indexOf('data:image/') > -1 && imageUrl.indexOf(';base64,') > -1) {
          vm.uploadQuestionImage(eventId, contentId, questionId, imageUrl).then(function (snapshot) {
            if (!snapshot) { return resolve({qKey: questionId, imageUrl: null}); }
            snapshot.ref.getDownloadURL().then(url => {
              resolve({qKey: questionId, imageUrl: url});
            });
          }).catch(function (er) {
            vm.common.log.error('error uploadQuestionImage, content will be not updated.', er);
            reject(er);
          });
        } else {
          resolve({qKey: questionId, imageUrl: null});
        }
    }
    });
  }

  /**
   * Return promise of type {qKey: questionId, imageUrl: imageUrl}
   * @param eventId
   * @param contentId
   * @param questionId
   * @param template
   * @param needDelete
   * @param isNew
   * @returns {Promise<any>}
   */
  patchQuestionWordCloudTemplate(eventId, contentId, questionId, template: WordCloudTemplate, needDelete, isNew): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!template) {
        resolve({qKey: questionId, template: null});
      } else if (needDelete) {
        vm.deleteQuestionWordCloudTemplate(eventId, contentId, questionId).then(function () {
          resolve({qKey: questionId, template: null});
        }).catch(function (e) {
          resolve({qKey: questionId, template: null});
        });
      } else if (this.utils.isFBUrl(template.url, eventId) || !template.url) {
        resolve({qKey: questionId, template: template});
      } else {
        if (isNew) {
          vm.addQuestionWorCloud(eventId, contentId, questionId, template).then(function (obj) {
            resolve(obj);
          }).catch(function (e) {
            vm.common.log.error('error patchQuestionWordCloudTemplate.addQuestionWorCloud, content will be not updated.', e);
            reject(e);
          });
        } else {
          // if object not exist in store method throw exception,
          // ignore this exception and continue try add question word cloud.
          vm.deleteQuestionWordCloudTemplate(eventId, contentId, questionId).then(function () {
            vm.addQuestionWorCloud(eventId, contentId, questionId, template).then(function (obj) {
              resolve(obj);
            }).catch(function (e) {
              vm.common.log.error('error patchQuestionWordCloudTemplate.addQuestionWorCloud, content will be not updated.', e);
              reject(e);
            });
          }).catch(function (e) {
            vm.addQuestionWorCloud(eventId, contentId, questionId, template).then(function (obj) {
              resolve(obj);
            }).catch(function (ex) {
              vm.common.log.error('error patchQuestionWordCloudTemplate.addQuestionWorCloud, content will be not updated.', ex);
              reject(e);
            });
          });
        }
      }
    });
  }

  deleteQuestionnaireContentExt(eventId, content) {
    return new Promise<any>((resolve, reject) => {
      const questionList = content.questions ? content.questions : {};
      const vm = this;
      this.deleteQuestionnaireAnswers(eventId, content.parentId, content.id).then(() => {
        this.deleteQuestionnaireContent(eventId, content.parentId, content.id, content.dirty).then(function (result) {
          const questionKeyList = Object.keys(questionList);
          const allQuestionsImagePromise = [];
          for (let i = 0; i < questionKeyList.length; i++) {
            const qKey = questionKeyList[i];
            if (questionList[qKey].imageUrl && vm.utils.isFBUrl(questionList[qKey].imageUrl, eventId)) {
              allQuestionsImagePromise.push(vm.deleteQuestionImage(eventId, content.id, qKey));
            }
            if (questionList[qKey].wordCloudTemplate) {
              allQuestionsImagePromise.push(vm.deleteQuestionWordCloudTemplate(eventId, content.id, qKey));
            }
          }
          if (allQuestionsImagePromise.length === 0) {
            resolve(result);
          } else {
            Promise.all(allQuestionsImagePromise).then(function (r) {
              resolve(result);
            }).catch(function (err) {
              vm.common.log.error('error delete question image.', err);
              resolve(err);
            });
          }
        }).catch(function (error) {
          vm.common.log.error('error delete question content.', error);
          resolve(error);
        });
      }).catch(function (error) {
        vm.common.log.error('error delete questionnaire answers.', error);
        resolve(error);
      });
    });
  }

  deleteRegistrationQuestionnaireExt(eventId, content) {
    return new Promise<any>((resolve, reject) => {
      const questionList = content.questions ? content.questions : {};
      const vm = this;
      this.deleteRegistrationQuestionnaire(eventId, content.id).then(function (result) {
        const questionKeyList = Object.keys(questionList);
        const allQuestionsImagePromise = [];
        for (let i = 0; i < questionKeyList.length; i++) {
          const qKey = questionKeyList[i];
          if (questionList[qKey].imageUrl && vm.utils.isFBUrl(questionList[qKey].imageUrl, eventId)) {
            allQuestionsImagePromise.push(vm.deleteQuestionImage(eventId, content.id, qKey));
          }
          if (questionList[qKey].wordCloudTemplate) {
            allQuestionsImagePromise.push(vm.deleteQuestionWordCloudTemplate(eventId, content.id, qKey));
          }
        }
        if (allQuestionsImagePromise.length === 0) {
          resolve(result);
        } else {
          Promise.all(allQuestionsImagePromise).then(function (r) {
            resolve(result);
          }).catch(function (err) {
            vm.common.log.error('error delete question image.', err);
            resolve(err);
          });
        }
      }).catch(function (error) {
        vm.common.log.error('error delete question content.', error);
        resolve(error);
      });
    });
  }

  addQuestionImage(eventId, contentId, questionId, imageUrl): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!imageUrl || (imageUrl && imageUrl.indexOf('data:image/') === -1 && imageUrl.indexOf(';base64,') === -1)) {
        resolve({qKey: questionId, imageUrl: imageUrl});
      } else
      // upload image into FB storage.
      if (imageUrl.indexOf('data:image/') > -1 && imageUrl.indexOf(';base64,') > -1) {
        vm.uploadQuestionImage(eventId, contentId, questionId, imageUrl).then(function (snapshot) {
          if (!snapshot) { return resolve({qKey: questionId, imageUrl: null}); }
          snapshot.ref.getDownloadURL().then(url => {
            resolve({qKey: questionId, imageUrl: url});
          });
        }).catch(function (er) {
          vm.common.log.error('error uploadQuestionImage, content will be not updated.', er);
          reject(er);
        });
      } else {
        resolve({qKey: questionId, imageUrl: null});
      }
    });
  }

  addQuestionWorCloud(eventId, contentId, questionId, wordCloudTemplate: WordCloudTemplate): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!wordCloudTemplate) {
        resolve({qKey: questionId, template: null});
      } else if (wordCloudTemplate.id.startsWith(Constants.WORD_CLOUD_ID_DEFAULT_PREFIX)) {
        const defTemplateId = wordCloudTemplate.id.split('-')[1];
        vm.copyWordCloudTemplateToContent(eventId, defTemplateId + '.png', `question/${contentId}/word_cloud_template/${questionId}.png`)
          .then(function (r) {
              vm.getWordCloudURL(eventId, contentId, questionId).then(function (url) {
                const tem = new WordCloudTemplate(wordCloudTemplate);
                tem.id = WordCloudTemplate.genId();
                tem.url = url;
                resolve({qKey: questionId, template: tem});
              });
          }).catch(function (e) {
          vm.common.log.error(e);
          reject(e);
        });
      } else if (wordCloudTemplate.url && wordCloudTemplate.url.indexOf('data:image/png;base64,') > -1) {
        vm.uploadQuestionWordCloudTemplate(eventId, contentId, questionId, wordCloudTemplate.url).then(function (snapshot) {
          if (!snapshot) { return resolve({qKey: questionId, template: null}); }
          snapshot.ref.getDownloadURL().then(url => {
            const tem = new WordCloudTemplate(wordCloudTemplate);
            tem.id = WordCloudTemplate.genId();
            tem.url = url;
            resolve({qKey: questionId, template: tem});
          });
        }).catch(function (e) {
          vm.common.log.error('error uploadQuestionWordCloudTemplate, content will be not updated.', e);
          reject(e);
        });
      }
    });
  }


  /**
   * Return promise of type {imageUrl: imageUrl}
   * @param eventId
   * @param imageUrl
   * @param checkFbStorage
   * @returns {Promise<any>}
   */
  patchEventLogo(eventId, imageUrl, checkFbStorage): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (checkFbStorage) {
        // try get URL image from FB storage, because we don't know was it in storage before edit or not.
        this.getEventDownloadURL(eventId).then(function (c) {
          // image at the FB storage and url not change - return this url.
          if (imageUrl && vm.utils.isFBUrl(imageUrl, eventId)) {
            resolve({imageUrl: imageUrl});
          } else {
            // delete image from FB storage.
            vm.deleteEventLogo(eventId).then(function (r) {
              // simple image. url in internet space
              if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
                resolve({imageUrl: imageUrl});
              } else
              // upload image into FB storage.
              if (vm.utils.isClipboardUrl(imageUrl)) {
                vm.uploadEventLogo(eventId, imageUrl).then(function (snapshot) {
                  if (!snapshot) { return resolve({imageUrl: null}); }
                  snapshot.ref.getDownloadURL().then(url => {
                    resolve({imageUrl: url});
                  });
                }).catch(function (e) {
                  vm.common.log.error('error uploadEventLogo, content will be not updated.', e);
                  reject(e);
                });
              } else {
                resolve({imageUrl: null});
              }
            }).catch(function (e) {
              vm.common.log.error('error uploadEventLogo, content will be not updated.', e);
              reject(e);
            });
          }
        }).catch(function (e) {
          // if all ok this not happend, but stay if upssss happend!!!
          if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
            resolve({imageUrl: imageUrl});
          } else
          // upload image into FB storage.
          if (vm.utils.isClipboardUrl(imageUrl)) {
            vm.uploadEventLogo(eventId, imageUrl).then(function (snapshot) {
              if (!snapshot) { return resolve({imageUrl: null}); }
              snapshot.ref.getDownloadURL().then(url => {
                resolve({imageUrl: url});
              });
            }).catch(function (er) {
              vm.common.log.error('error uploadEventLogo, content will be not updated.', er);
              reject(er);
            });
          } else {
            resolve({imageUrl: null});
          }
        });
      } else {
        if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
          resolve({imageUrl: imageUrl});
        } else
        // upload image into FB storage.
        if (vm.utils.isClipboardUrl(imageUrl)) {
          vm.uploadEventLogo(eventId, imageUrl).then(function (snapshot) {
            if (!snapshot) { return resolve({imageUrl: null}); }
            snapshot.ref.getDownloadURL().then(url => {
              resolve({imageUrl: url});
            });
          }).catch(function (er) {
            vm.common.log.error('error uploadEventLogo, content will be not updated.', er);
            reject(er);
          });
        } else {
          resolve({imageUrl: null});
        }
      }
    });
  }

  addEventLogo(eventId, imageUrl, clientId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
        resolve({imageUrl: imageUrl});
      } else
      // upload image into FB storage.
      if (vm.utils.isClipboardUrl(imageUrl)) {
        vm.uploadEventLogo(eventId, imageUrl, clientId).then(function (snapshot) {
          if (!snapshot) { return resolve({imageUrl: null}); }
          snapshot.ref.getDownloadURL().then(url => {
            resolve({imageUrl: url});
          });
        }).catch(function (er) {
          vm.common.log.error('error uploadEventLogo, content will be not updated.', er);
          reject(er);
        });
      } else {
        resolve({imageUrl: null});
      }
    });
  }

  /**
   * Return promise of type {imageUrl: imageUrl}
   * @param eventId
   * @param imageUrl
   * @param checkFbStorage
   * @param bannerType
   * @returns {Promise<any>}
   */
  patchEventBanner(eventId, imageUrl, checkFbStorage, bannerType: BANNER_TYPE): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (checkFbStorage) {
        // try get URL image from FB storage, because we don't know was it in storage before edit or not.
        this.getEventBannerDownloadURL(eventId, bannerType).then(function (c) {
          // image at the FB storage and url not change - return this url.
          if (imageUrl && vm.utils.isFBUrl(imageUrl, eventId)) {
            resolve({imageUrl: imageUrl});
          } else {
            // delete image from FB storage.
            vm.deleteEventBanner(eventId, bannerType).then(function (r) {
              // simple image. url in internet space
              if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
                resolve({imageUrl: imageUrl});
              } else
              // upload image into FB storage.
              if (vm.utils.isClipboardUrl(imageUrl)) {
                vm.uploadEventBanner(eventId, imageUrl, bannerType).then(function (snapshot) {
                  if (!snapshot) { return resolve({imageUrl: null}); }
                  snapshot.ref.getDownloadURL().then(url => {
                    resolve({imageUrl: url});
                  });
                }).catch(function (e) {
                  vm.common.log.error('error uploadEventLogo, content will be not updated.', e);
                  reject(e);
                });
              } else {
                resolve({imageUrl: null});
              }
            }).catch(function (e) {
              vm.common.log.error('error uploadEventLogo, content will be not updated.', e);
              reject(e);
            });
          }
        }).catch(function (e) {
          // if all ok this not happend, but stay if upssss happend!!!
          if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
            resolve({imageUrl: imageUrl});
          } else
          // upload image into FB storage.
          if (vm.utils.isClipboardUrl(imageUrl)) {
            vm.uploadEventBanner(eventId, imageUrl, bannerType).then(function (snapshot) {
              if (!snapshot) { return resolve({imageUrl: null}); }
              snapshot.ref.getDownloadURL().then(url => {
                resolve({imageUrl: url});
              });
            }).catch(function (er) {
              vm.common.log.error('error uploadEventLogo, content will be not updated.', er);
              reject(er);
            });
          } else {
            resolve({imageUrl: null});
          }
        });
      } else {
        if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
          resolve({imageUrl: imageUrl});
        } else
        // upload image into FB storage.
        if (vm.utils.isClipboardUrl(imageUrl)) {
          vm.uploadEventBanner(eventId, imageUrl, bannerType).then(function (snapshot) {
            if (!snapshot) { return resolve({imageUrl: null}); }
            snapshot.ref.getDownloadURL().then(url => {
              resolve({imageUrl: url});
            });
          }).catch(function (er) {
            vm.common.log.error('error uploadEventLogo, content will be not updated.', er);
            reject(er);
          });
        } else {
          resolve({imageUrl: null});
        }
      }
    });
  }

  addEventBanner(eventId, imageUrl, bannerType: BANNER_TYPE, clientId?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
        resolve({imageUrl: !imageUrl ? null : imageUrl});
      } else
      // upload image into FB storage.
      if (vm.utils.isClipboardUrl(imageUrl)) {
        vm.uploadEventBanner(eventId, imageUrl, bannerType, clientId).then(function (snapshot) {
          if (!snapshot) { return resolve({imageUrl: null}); }
          snapshot.ref.getDownloadURL().then(url => {
            resolve({imageUrl: url});
          });
        }).catch(function (er) {
          vm.common.log.error('error uploadEventLogo, content will be not updated.', er);
          reject(er);
        });
      } else {
        resolve({imageUrl: null});
      }
    });
  }

  /**
   * Return promise of type {imageUrl: imageUrl}
   * @param eventId
   * @param imageUrl
   * @param checkFbStorage
   * @param mobile - prefix "M" for mobile file name.
   * @returns {Promise<any>}
   */
  patchWelcomeScreen(eventId, imageUrl, checkFbStorage, mobile: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (checkFbStorage) {
        // try get URL image from FB storage, because we don't know was it in storage before edit or not.
        this.getWelcomeScreenDownloadURL(eventId, mobile).then(function (c) {
          // image at the FB storage and url not change - return this url.
          if (imageUrl && vm.utils.isFBUrl(imageUrl, eventId)) {
            resolve({imageUrl: imageUrl});
          } else {
            // delete image from FB storage.
            vm.deleteWelcomeScreen(eventId, mobile).then(function (r) {
              // simple image. url in internet space
              if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
                resolve({imageUrl: imageUrl});
              } else
              // upload image into FB storage.
              if (vm.utils.isClipboardUrl(imageUrl)) {
                vm.uploadWelcomeScreen(eventId, imageUrl, mobile).then(function (snapshot) {
                  if (!snapshot) { return resolve({imageUrl: null}); }
                  snapshot.ref.getDownloadURL().then(url => {
                    resolve({imageUrl: url});
                  });
                }).catch(function (e) {
                  vm.common.log.error('error uploadWelcomeScreen, content will be not updated.', e);
                  reject(e);
                });
              } else {
                resolve({imageUrl: null});
              }
            }).catch(function (e) {
              vm.common.log.error('error uploadWelcomeScreen, content will be not updated.', e);
              reject(e);
            });
          }
        }).catch(function (e) {
          // e.g. can be if desktop WelcomeScreen is true, but mobile WelcomeScreen is null
          if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
            resolve({imageUrl: imageUrl});
          } else
          // upload image into FB storage.
          if (vm.utils.isClipboardUrl(imageUrl)) {
            vm.uploadWelcomeScreen(eventId, imageUrl, mobile).then(function (snapshot) {
              if (!snapshot) { return resolve({imageUrl: null}); }
              snapshot.ref.getDownloadURL().then(url => {
                resolve({imageUrl: url});
              });
            }).catch(function (er) {
              vm.common.log.error('error uploadWelcomeScreen, content will be not updated.', er);
              reject(e);
            });
          } else {
            resolve({imageUrl: null});
          }
        });
      } else {
        if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
          resolve({imageUrl: imageUrl});
        } else
        // upload image into FB storage.
        if (vm.utils.isClipboardUrl(imageUrl)) {
          vm.uploadWelcomeScreen(eventId, imageUrl, mobile).then(function (snapshot) {
            if (!snapshot) { return resolve({imageUrl: null}); }
            snapshot.ref.getDownloadURL().then(url => {
              resolve({imageUrl: url});
            });
          }).catch(function (er) {
            vm.common.log.error('error uploadWelcomeScreen, content will be not updated.', er);
            reject(er);
          });
        } else {
          resolve({imageUrl: null});
        }
      }
    });
  }

  addWelcomeScreen(eventId, imageUrl, mobile: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const vm = this;
      if (!imageUrl || (imageUrl && !vm.utils.isClipboardUrl(imageUrl))) {
        resolve({imageUrl: imageUrl});
      } else
      // upload image into FB storage.
      if (vm.utils.isClipboardUrl(imageUrl)) {
        vm.uploadWelcomeScreen(eventId, imageUrl, mobile).then(function (snapshot) {
          if (!snapshot) { return resolve({imageUrl: null}); }
          snapshot.ref.getDownloadURL().then(url => {
            resolve({imageUrl: url});
          });
        }).catch(function (er) {
          vm.common.log.error('error uploadWelcomeScreen, content will be not updated.', er);
          reject(er);
        });
      } else {
        resolve({imageUrl: null});
      }
    });
  }

  createTypedContent(abstractContent) {
    if (!abstractContent) {
      return null;
    } else if (abstractContent.type  === Constants.CONTENT_TYPE_SECTION || abstractContent.type  === Constants.CONTENT_TYPE_TIMELINE) {
      return new SectionTimeline(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_TEXT) {
      return new TextContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_LINK) {
      return new LinkContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_CLIPBOARD) {
      return new ClipboardContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_SLIDE) {
      return new SlideContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_SEPARATOR) {
      return new SeparatorContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_BREAK) {
      return new BreakContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_COMPETITION) {
      return new CompetitionContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
      return new QuestionnaireContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_MODULAR) {
      return new ModularContent(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_TASK_DOCUMENT) {
      return new TaskDocument(abstractContent);
    } else if (abstractContent.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
      return new ContentContainer(abstractContent);
    }
    return abstractContent?.type ? abstractContent : null;
  }

  updateContentPosition(eventId: string, content: AbstractContent, parentId: string, orderIndex: number,
                        parsms: {}, moveToNewPath?: boolean) {
    let updateContentFieldsAndParams = !moveToNewPath ? {parentId: parentId, orderIndex: orderIndex} :
      {parentId: content.parentId, newParentId: parentId, orderIndex: orderIndex, moveToNewPath: true};
    updateContentFieldsAndParams = merge(parsms, updateContentFieldsAndParams);
    switch (content.type) {
      case Constants.CONTENT_TYPE_BREAK:
        return this.editBreakContent(eventId, content.id, updateContentFieldsAndParams);
      case Constants.CONTENT_TYPE_QUESTIONNAIRE:
        return this.editQuestionnaireContentSimple(eventId, content.id, updateContentFieldsAndParams);
      case Constants.CONTENT_TYPE_TIMELINE:
      case Constants.CONTENT_TYPE_SECTION:
        return this.updateSectionContent(eventId, content.id, updateContentFieldsAndParams)
          .then(() => this.eventModeApi.saveChangeLogValue(content.eventId, {
          sourceId: content.id,
          sourceType: content.type,
          title: content.title,
          changeType: CHANGE_LOG_VALUE_TYPE.RELOCATE
        }));
      case Constants.CONTENT_TYPE_MODULAR:
        return this.editModularContent(eventId, content.id, updateContentFieldsAndParams);
      case Constants.CONTENT_TYPE_TASK_DOCUMENT:
        return this.editTaskDocumentContent(eventId, content.id, updateContentFieldsAndParams);
      default:
        return Promise.resolve(Constants.BREAK_PROCESS);
    }
  }

  getPublicEventsObject(hideFinished = false, onlyGuest = false) {
    return this.eventApi.getPublicEvents(hideFinished, onlyGuest)
      .pipe(
        map(list => {
          return list
            .filter(it => !it.dopState)
            .reduce((obj, item) => {
              obj[item.eventId] = item;
              return obj;
            }, {});
        }));
  }

  loadPermittedUserEvents(userId: string, clientId = null) {
    if (clientId) {
      return this.eventApi.getPermittedUserEvents(userId, clientId);
    }
    return this.eventApi.getUserEvents(userId);
  }

  loadPermittedUserEventsPromise(userId: string, clientId = null) {
    return this.eventApi.getPermittedUserEventsPromise(userId, clientId);
  }

  loadUserOwnerEvents(userId: string, clientId = null) {
    if (clientId) {
      return this.eventApi.getUserOwnerEventsByClient(userId, clientId);
    }
    return this.eventApi.getUserOwnerEvents(userId);
  }

  loadInvitedUserEvents(email: string) {
    return this.eventApi.getInvitedUserEvents(email);
  }

  loadInvitedUserEventsPromise(email: string, clientId: string) {
    return this.eventApi.getInvitedUserEventsPromise(email, clientId);
  }

  pushRefreshUserEvent(event: Event) {
    return this.eventModeApi.pushRefreshUserEvent(event);
  }

  getSectionsList(eventId: string, available = false): Observable<SectionContent[]> {
    return this.eventModeApi.getSectionsList(eventId, available);
  }

  getSections(eventId: string): Observable<SectionContent[]> {
    return this.eventModeApi.getSections(eventId);
  }

  getSectionsByMode(eventId: string, availableContentsMode: boolean): Observable<SectionContent[]> {
    const unionTimeline = (root, timeline, addition, fixed) => {
      const timelineAddition = timeline.map(s => {
        if (!s.originId) {
          return cloneDeep(s);
        } else {
          let origin = addition.find(o => o.id === s.originId && o['shortcuts']?.includes(s.id));
          if (origin) {
            origin = cloneDeep(origin);
            SectionContent.SHORTCUT_FIELDS.forEach(f => delete origin[f]);
            const shortcut = SectionContent.SHORTCUT_FIELDS.reduce((acc, f) => {
              acc[f] = s[f];
              return acc;
            }, {});
            return cloneDeep(Object.assign(shortcut, origin));
          } else {
            return cloneDeep(s);
          }
        }
      });
      return union(root, timelineAddition, fixed).map(it => new SectionContent(it));
    };

    const excludeNullType = (list: any[]) => list.filter(it => it.type === Constants.CONTENT_TYPE_SECTION);

    return this.eventModeApi.getSectionsByMode(eventId, availableContentsMode)
      .pipe(map(([root, timeline, addition, fixed]) => {
        return unionTimeline(
          excludeNullType(availableContentsMode ? root.map(o => new Object({...o, education: true})) : root),
          excludeNullType(timeline),
          excludeNullType(addition),
          excludeNullType(fixed));
      }));
  }

  getSectionsPromise(eventId, available = false) {
    return this.eventModeApi.getSectionsPromise(eventId, available);
  }

  checkGarbageSectionsPromise(eventId) {
    return this.eventModeApi.checkGarbageSectionsPromise(eventId);
  }

  getSection(eventId: string, sectionId: string) {
    return this.eventModeApi.getSection(eventId, sectionId);
  }

  getSectionPromise(eventId: string, sectionId: string) {
    return this.eventModeApi.getSectionPromise(eventId, sectionId);
  }

  getRootSection(eventId: string) {
    return this.eventModeApi.getRootSection(eventId);
  }

  getRootSectionPromise(eventId: string): Promise<SectionContent> {
    return this.eventModeApi.getRootSectionPromise(eventId);
  }

  getSectionsSpeakersPromise(eventId) {
    return this.eventModeApi.getSectionsSpeakersPromise(eventId);
  }

  getSectionContentsPromise(eventId: string, sectionId: string, pathType: CONTENT_PATH_TYPE = CONTENT_PATH_TYPE.DEFAULT): Promise<any> {
    return this.eventModeApi.getSectionContentsPromise(eventId, sectionId, pathType);
  }

  loadSectionContentsByLocation(eventId: string, sectionId: string, loadFromEducation: boolean): Promise<any> {
    return this.eventModeApi.loadSectionContentsByLocation(eventId, sectionId, loadFromEducation);
  }

  imageRewriteTo(eventIdFrom, eventIdTo, pathFrom, pathTo, clientIdTo?: string) {
    return this.eventModeApi.imageRewriteTo(eventIdFrom, eventIdTo, pathFrom, pathTo, clientIdTo);
  }

  copyWordCloudTemplateToContent(eventIdTo, pathFrom, pathTo) {
    return this.eventModeApi.copyWordCloudTemplateToContent(eventIdTo, pathFrom, pathTo);
  }

  /* registration */

  editRegistrationSettings(eventId: string, sectionId: string, content: any, autoInvite?: boolean): any {
    return this.eventModeApi.editRegistrationSettings(eventId, sectionId, content, autoInvite);
  }

  deleteRegistrationSettings(eventId: string, sectionId: string): any {
    return this.eventModeApi.deleteRegistrationSettings(eventId, sectionId);
  }

  getRegistrationSettings(eventId: string, sectionId: string): Observable<RegistrationSettings> {
    return this.eventModeApi.getRegistrationSettings(eventId, sectionId);
  }

  getRegistrationSettingsPromise(eventId: string, sectionId: string): Promise<RegistrationSettings> {
    return this.eventModeApi.getRegistrationSettingsPromise(eventId, sectionId);
  }

  getRegistrationSettingsCollection(eventId: string): Observable<RegistrationSettings[]> {
    return this.eventModeApi.getRegistrationSettingsCollection(eventId);
  }

  getRegistrationQuestionnaire(eventId: string, sectionId: string): Observable<QuestionnaireContent> {
    return this.eventModeApi.getRegistrationQuestionnaire(eventId, sectionId);
  }

  getRegistrationQuestionnairePromise(eventId: string, sectionId: string): Promise<QuestionnaireContent> {
    return this.eventModeApi.getRegistrationQuestionnairePromise(eventId, sectionId);
  }

  getRegistrationQuestionnaireCollectionAsMap(eventId: string): Observable<IQuestionnaireContentMap> {
    return this.eventModeApi.getRegistrationQuestionnaireCollectionAsMap(eventId);
  }

  getSectionRegistrationQuestionnaireAnswersByUserAsMap(userCode: string, eventId: string,
                                                        sectionIdList: string[]): Observable<IRegistrationQuestionnaireAnswers> {
    return this.eventModeApi.getSectionRegistrationQuestionnaireAnswersByUserAsMap(userCode, eventId, sectionIdList)
      .pipe(this.eventModeApi.log('getSectionRegistrationQuestionnaireAnswersByUserAsMap'),
        map((res: any[]) => res.reduce((accum, current) => {
        const userId = Object.keys(current)[0];
        const sectionId = Object.keys(current[userId])[0];
        if (isEmpty(accum[userId])) {
          accum[userCode] = {};
        }
        accum[userCode][sectionId] = current[userId][sectionId];
        return accum;
      }, {})),
        catchError(err => this.eventModeApi.firestoreCatchError(err)));
  }

  userRegistrationAction(eventId: string, sectionId: string, content: any): any {
    return this.eventApi.userRegistrationAction(eventId, sectionId, content);
  }

  userRegistrationActionTask(eventId: string, content: any): Promise<any> {
    return this.eventApi.userRegistrationActionTask(eventId, content);
  }

  getUserNotifications(eventId: string, sectionId: string): Observable<any> {
    return this.eventModeApi.getUserNotifications(eventId, sectionId);
  }

  getSectionsRegisteredUsers(eventId: string) {
    return this.eventModeApi.getSectionsRegisteredUsers(eventId);
  }

  getSectionUserRegistration(eventId: string, sectionId: string, userCode: string): Observable<any> {
    return this.eventModeApi.getSectionUserRegistration(eventId, sectionId, userCode)
      .pipe(
        map(item => {
          return item && item.sections && item.sections[sectionId] ? item.sections[sectionId] : null;
        })
      );
  }

  getUserRegistrationPromise(eventId: string, sectionId: string, userId: string) {
    return this.eventModeApi.getUserRegistrationPromise(eventId, userId)
      .then((user) => {
        return user && user.sections ? user.sections[sectionId] : null;
      });
  }

  getAssistantRegistrationUsers(eventId: string, sectionId: string): Observable<any> {
    return this.eventModeApi.getAssistantRegistrationUsers(eventId, sectionId)
      .pipe(
        map(users => {
            const res = {};
            for (const user of users) {
              if (user && user.sections) {
                const reduce = Object.keys(user.sections).filter(it => it === sectionId).reduce((d, val) => {
                  return {
                    sectionId: val, ...user.sections[val]
                  };
                }, {});
                if (!isEmpty(reduce)) {
                  res[user.id] = reduce;
                }
              }
            }
            return res;
          }
        )
      );
  }

  deleteUserRegistrationAnswers(userId: string, eventId: string, sectionId: string, list: any[]): any {
    return this.eventModeApi.deleteUserRegistrationAnswers(userId, eventId, sectionId, list);
  }

  getSelectedRegistrationQuestionnairesList(eventId: string, sectionIdList: any[]) {
    return this.eventModeApi.getRegistrationQuestionnairesList(eventId)
      .pipe(
        map(list => list.filter(
            obj => !isEmpty(obj.questions) && sectionIdList.findIndex(id => id === obj['id']) > -1)
        )
      );
  }

  getSectionRegisteredUsers(eventId: string, sectionId: string): Observable<any> {
    return this.eventModeApi.getSectionsRegisteredUsers(eventId)
      .pipe(
        map(users => {
            const res = {};
            for (const user of users) {
              if (user && user.sections) {
                const reduce = Object.keys(user.sections).filter(it => it === sectionId).reduce((d, val) => {
                  return {
                    sectionId: val, ...user.sections[val]
                  };
                }, {});
                if (!isEmpty(reduce)) {
                  res[user.id] = reduce;
                }
              }
            }
            return res;
          }
        )
      );
  }

  getUserRegisteredSectionIdsPromise(eventId: string, currentUserId: string): Promise<string[]> {
    return this.eventModeApi.getUserRegistrationPromise(eventId, currentUserId)
      .then((user) => {
        return user && user.sections ? Object.keys(user.sections) : [];
      });
  }

  getMandatoryQuestionnaireSectionsIdList(eventId: string) {
    return this.eventModeApi.getMandatoryQuestionnaireSectionsIdList(eventId).pipe(
      map((object: any[]) => object.map(it => it.id))
    );
  }

  getUserRegistration(eventId: string, currentUserEmailAsId: string) {
    return this.eventModeApi.getUserRegistration(eventId, currentUserEmailAsId);
  }

  // todo Deprecated?
  getUserSectionsRegistration(eventId: string, currentUserEmailAsId: string): Observable<IRegSectionStatuses[]> {
    return this.eventModeApi.getUserRegistration(eventId, currentUserEmailAsId)
      .pipe(
        map(res => {
          return res && res.sections ? Object.keys(res.sections).map(it => ({sectionId: it, status: res.sections[it].status})) : [];
        })
      );
  }

  /**
   * Create duplicate event.
   * @param {number} eventId
   * @param {number} newEventId
   * @param dateShift - shift sections planned time after duplicate event.
   * @param inviteUsers
   * @param clearUsers - clear section users
   * @param clientIdTo
   * @returns {any}
   */
  duplicateEvent(eventId: string, newEventId: string, dateShift: number, inviteUsers: string[], clearUsers: boolean, clientIdTo?: string) {
    return this.eventApi.duplicateEvent(eventId, newEventId, dateShift, inviteUsers, clearUsers, clientIdTo);
  }

  getDownloadUrl(eventId: string, path: string, clientId?: string) {
    return this.eventModeApi.getDownloadUrl(eventId, path, clientId);
  }

  /**
   * Get task "Duplicate event" status.
   * @param eventId
   * @param newEventId
   * @param userId
   */
  getDuplicateEventTaskStatus(eventId: string, newEventId: string, userId: string) {
    return this.eventModeApi.getDuplicateEventTaskStatus(eventId, newEventId, userId);
  }

  /**
   * Load count content likes.
   * @param eventId
   * @param sectionId
   */
  getContentsLikes(eventId: string, sectionId: string) {
    return this.eventModeApi.getContentsLikes(eventId, sectionId)
      .pipe(
        map((res: any[]) => res.reduce((prev, current) => {
          // todo temporary like = views. we don’t know how it (like) should work.
          prev[current.id] = {views: current.like};
          return prev;
        }, {}))
      );
  }

  /**
   * Load user contents likes.
   * @param eventId
   * @param sectionId
   */
  getContentsUserLikes(eventId: string, sectionId: string) {
    return this.eventModeApi.getContentsUserLikes(eventId, sectionId);
  }

  /**
   * Load count content messages.
   * @param eventId
   * @param sectionId
   */
  getRelevancySummary(eventId: string, sectionId: string) {
    return this.eventModeApi.getRelevancySummary(eventId, sectionId)
      .pipe(
        map((res: any[]) => res.reduce((prev, current) => {
          prev[current.id] = current;
          return prev;
        }, {}))
      );
  }

  /**
   * Return question word cloud png template url
   * @param eventId
   * @param contentId
   * @param questionId
   * @returns {any}
   */
  getWordCloudURL(eventId: string, contentId: string, questionId: string) {
    return this.eventModeApi.getWordCloudURL(eventId, contentId, questionId);
  }

  uploadQuestionWordCloudTemplate(eventId: string, contentId: string, questionId: string, base64: string) {
    return this.eventModeApi.uploadQuestionWordCloudTemplate(eventId, contentId, questionId, base64);
  }

  loadSectionContents(eventId: string, section: SectionContent | SectionTimeline, fixedSections: {[key: string]: SectionContent},
                      contentFromAudienceMode: CONTENT_FROM_AUDIENCE_MODE, secondParentId: string,
                      needLoadDependency: boolean, user: AppUser, isPresenter: boolean): Observable<ISectionContentsObject> {
    const sectionId = section.id;
    const isFixedSections = () => {
      return !isEmpty(fixedSections) && fixedSections[sectionId];
    };

    const observables: any[] = [
      this.getSectionContents(eventId, section, secondParentId, user, isPresenter),
      isFixedSections() ? this.getContentsLikes(eventId, sectionId) : of({}),
      isFixedSections() ? this.getContentsUserLikes(eventId, sectionId) : of({}),
      isFixedSections() ? this.getRelevancySummary(eventId, sectionId) : of({})
    ];
    return combineLatest(observables)
      .pipe(switchMap((items: any[]) => {
        const list = items[0];
        const likes = items[1];
        const userLikes = items[2];
        const relevancySummary = items[3];

        const unionList = list as any[];
        const unionViews = likes ? likes : {};
        const unionRelevancySummary = relevancySummary ? relevancySummary : {};
        if (needLoadDependency) {
          const dependencyList = this.createContentsDependencyList(unionList);
          if (!isEmpty(dependencyList)) {
            return from(this.loadDependencyQuestionnaireSectionsId(eventId, dependencyList).then(() => {
              return {list: unionList, views: unionViews, dependency: dependencyList,
                relevancySummary: unionRelevancySummary, userLikes: userLikes};
            }).catch((err) => {
              this.common.log.error(err);
            }));
          } else {
            return of({list: unionList, views: unionViews, dependency: [],
              relevancySummary: unionRelevancySummary, userLikes: userLikes});
          }
        } else {
          return of({list: unionList, views: unionViews, dependency: [],
            relevancySummary: unionRelevancySummary, userLikes: userLikes});
        }
      })) as Observable<ISectionContentsObject>;
  }

  /**
   * defines section id by content id. thus it is easy to track the position of the questionnaire after moving between sections
   * @param eventId
   * @param list
   * @private
   */
  loadDependencyQuestionnaireSectionsId(eventId, list: IDependencyQuestionnaire[]): Promise<any> {
    return firstValueFrom(this.eventModeApi.getContentsShort(eventId)).then(function(object) {
      if (!isEmpty(object)) {
        for (const sectionId of Object.keys(object)) {
          for (const contentId of Object.keys(object[sectionId])) {
            if (object[sectionId][contentId] === Constants.CONTENT_TYPE_QUESTIONNAIRE ||
                 object[sectionId][contentId] === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
              for (const dep of list) {
                if (dep.questionnaireId === contentId || dep.contentId === contentId) {
                  dep.sectionId = sectionId;
                }
              }
            }
          }
        }
      }
    });
  }

  createContentsDependencyList(list: AbstractContent[]): IDependencyQuestionnaire[] {
    const dependency: IDependencyQuestionnaire[] = [];
    for (const content of list) {
      if (content.type === Constants.CONTENT_TYPE_MODULAR ||
          content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER ||
          content.isTypeSection) {
        const mc: any = content;
        if (mc.dependencyQuestionnaire && mc.dependencyQuestionnaire.questionnaireId) {
          if (!dependency.find(o => o.contentId === mc.dependencyQuestionnaire.contentId &&
               o.questionnaireId === mc.dependencyQuestionnaire.questionnaireId &&
                 o.questionId === mc.dependencyQuestionnaire.questionId)) {
            dependency.push({
              contentId: mc.dependencyQuestionnaire.contentId,
              questionnaireId: mc.dependencyQuestionnaire.questionnaireId,
              questionId: mc.dependencyQuestionnaire.questionId
            });
          }
        }
      }
    }
    return dependency;
  }

  setRoundTime5MinutesDisable(eventId: string, disabled: boolean): any {
    return this.eventModeApi.setRoundTime5MinutesDisable(eventId, disabled);
  }

  postManagementNote(eventId: string, timelineId: string, noteText: string): any {
    return this.eventModeApi.postManagementNote(eventId, timelineId, noteText);
  }

  getSectionsManagementNotes(eventId: string) {
    return this.eventModeApi.getSectionsManagementNotes(eventId);
  }

  /**
   * Update sections fields for manage time.
   * @param eventId
   * @param task
   */
  updateManageTimeSections(eventId: string, task: RowTask[]) {
    return this.eventModeApi.updateManageTimeSections(eventId, task);
  }


  clearUserOnlineDevices(eventId: number, userId: string, devices: any) {
    return this.eventModeApi.clearUserOnlineDevices(eventId, userId, devices);
  }

  getUsersOnlineCount(eventId: string): Observable<number> {
    return this.eventModeApi.getUsersOnlineCount(eventId);
  }


  sendSelfLearningTime(eventId: string, parentId: string, timelineId: string, spentTime: number): any {
    return this.eventModeApi.sendSelfLearningTime(eventId, parentId, timelineId, spentTime);
  }

  getContentsShort(eventId: string) {
    return this.eventModeApi.getContentsShort(eventId);
  }

  getUserLearningContentsTime(eventId: string, userId: string) {
    return this.eventModeApi.getUserLearningContents(eventId, userId)
      .pipe(
        map(res => res ? res.contents_spent_time : res)
      );
  }

  getUserLearningContentsComplete(eventId: string, userId: string) {
    return this.eventModeApi.getUserLearningContents(eventId, userId)
      .pipe(
        map(res => res ? res.contents_complete : res)
      );
  }

  loadUsersLearningComplete(eventId: string) {
    return this.eventModeApi.getUsersLearningContents(eventId);
  }

  loadQuestionnaireContents(eventId: string): Promise<any[]> {
    return this.eventModeApi.getQuestionnaireContentsPromise(eventId);
  }

  subscribeUserOnDependencyQuestionnaire(eventId, userId, dependencyList: IDependencyQuestionnaire[]) {
    const allObservables = [];
    for (const d of dependencyList || []) {
      allObservables.push(this.getUserQuestionAnswers(eventId, d.sectionId, d.contentId, d.questionnaireId, d.questionId, userId)
        .pipe(map(obj =>
          new Object({
            [d.contentId ? `${d.contentId}-${d.questionnaireId}` : `${d.questionnaireId}`]: new Object({[d.questionId]: obj})
          }))));
    }
    return combineLatest(!isEmpty(allObservables) ? allObservables : [of({})])
      .pipe(map(list => (list || [] as any[])
        .reduce(function (result, value) {
      merge(result, value);
      return result;
    }, {})));
  }

  getUserQuestionAnswers(eventId: string, sectionId: string, contentId: string,
                         questionnaireId: string, questionId: string, userId: string) {
    return this.eventModeApi.getQuestionnaireAnswersByCurrentUser(eventId, sectionId, contentId, questionnaireId)
      .pipe(
        map(res => res && res[questionId] && res[questionId].answers ?
          res[questionId].answers : null)
      );
  }

  checkQuestionConstraint(eventId: string, questionnaireId: string, questionId: string) {
    return this.eventModeApi.getQuestionnaireDependencyPromise(eventId, questionnaireId)
      .then((value) => {
        return value ? Promise.resolve(isEmpty(value[questionId])) : Promise.resolve(true);
      });
  }

  getQuestionnaireDependencyPromise(eventId: string, questionnaireId: string) {
    return this.eventModeApi.getQuestionnaireDependencyPromise(eventId, questionnaireId)
      .then((value) => {
        return Promise.resolve(isEmpty(value));
      });
  }

  loadUserLastActivity(userId: string) {
    return this.eventModeApi.getUserLastActivity(userId);
  }

  getEventICS(eventId: string) {
    return this.eventModeApi.getEventICS(eventId);
  }

  setEventStatus(eventId: string) {
    return this.eventModeApi.setEventStatus(eventId);
  }

  userSubmitTaskDocument(eventId: string, contentId: string, userTaskObject: UserTaskDocumentObject) {
    return this.eventModeApi.userSubmitTaskDocument(eventId, contentId, userTaskObject);
  }

  loadUserTaskDocumentObject(eventId: string, contentId: string, userId: string) {
    return this.eventModeApi.loadUserTaskDocumentObject(eventId, contentId, userId);
  }

  userSubmitAndUploadTaskDocument(userTaskObject: UserTaskDocumentObject) {
    const vm = this;
    return new Promise<any>((resolve, reject) => {
      const allPromise = [];
      if (!isEmpty(userTaskObject.uploadFileLinks)) {
        for (const item of userTaskObject.uploadFileLinks) {
          if (item.upload === LINK_STATE.NEED_UPLOAD) {
            allPromise.push(vm.uploadUserAnyDocumentToStorage(userTaskObject.eventId, userTaskObject.contentId, userTaskObject.userId,
              item.id + '-' + item.name, item.src, item.metaType).then((snapshot) => {
              return snapshot.ref.getDownloadURL().then(url => {
                return {id: item['id'], src: url, upload: LINK_STATE.UPLOADED};
              });
            }));
          }
          if (item.upload === LINK_STATE.DELETED) {
            allPromise.push(vm.deleteUserAnyDocumentFromStorage(
              userTaskObject.eventId, userTaskObject.contentId, userTaskObject.userId, item.id + '-' + item.name)
              .then(() => {
                return {id: item['id'], src: null, upload: LINK_STATE.DELETED};
              }));
          }
        }
      }
      Promise.all(allPromise).then((result) => {
        for (const item of result) {
          const index = userTaskObject.uploadFileLinks.findIndex(it => it.id === item.id);
          if (index > -1) {
            const obj = userTaskObject.uploadFileLinks[index];
            if (obj.upload === LINK_STATE.NEED_UPLOAD && item.upload === LINK_STATE.UPLOADED) {
              obj.upload = LINK_STATE.UPLOADED;
              obj.src = item.src;
            } else if (item.upload === LINK_STATE.DELETED) {
              userTaskObject.uploadFileLinks.splice(index, 1);
            }
          }
        }
        this.userSubmitTaskDocument(userTaskObject.eventId, userTaskObject.contentId, userTaskObject).then(() => {
          return resolve(userTaskObject.contentId);
        });
      });
    });
  }

  deleteAllUserAnyDocumentFromStorage(eventId: string, contentId: string) {
    const deleteUserStorageObjects = (userTaskObject: UserTaskDocumentObject) => {
      let del = Promise.resolve();
      for (const upLink of (userTaskObject.uploadFileLinks || [])) {
        del = del.then(() => {
          return this.deleteUserAnyDocumentFromStorage(userTaskObject.eventId, userTaskObject.contentId,
            userTaskObject.userId, upLink.id + '-' + upLink.name);
        }).catch((err) => {
          this.common.log.error(err);
          return Promise.reject(err);
        });
      }
      return del;
    };
    return new Promise<any>((resolve, reject) => {
      this.eventModeApi.loadAllUsersTaskDocumentObjects(eventId, contentId)
        .pipe(take(1)).toPromise().then((objectList) => {
        let delUser = Promise.resolve();
        for (const userId of Object.keys(objectList || {})) {
          const taskObject = new UserTaskDocumentObject(objectList[userId]);
          delUser = delUser.then(() => {
            return deleteUserStorageObjects(taskObject);
          }).catch((err) => {
            this.common.log.error(err);
            return reject(err);
          });
        }
        return delUser.then(() => {
          resolve(true);
        });
      });
    });
  }

  loadAllUsersTaskDocumentObjects(eventId: string, contentId: string) {
    return this.eventModeApi.loadAllUsersTaskDocumentObjects(eventId, contentId)
      .pipe(
        map((res: any[]) => res.reduce((accum, item) => {
          accum[item.userId] = item;
          return accum;
        }, {}))
      );
  }

  setUserTaskFieldValue(user: AppUser, eventId: string, contentId: string, parentId: string, fieldName: USER_TASK_FIELDS,
                        fieldValue: string, speaker: ITaskDocumentSpeaker): any {
    return this.eventModeApi.setUserTaskFieldValue(user, eventId, contentId, parentId, fieldName, fieldValue, speaker);
  }

  getCustomActionValue(eventId: string, fieldName: string) {
    return this.eventModeApi.getCustomActionLongValue(eventId, fieldName);
  }

  setCustomActionLongValue(eventId: string, fieldName: string, fieldValue: number | string) {
    return this.eventModeApi.setCustomActionLongValue(eventId, fieldName, fieldValue);
  }

  getSignedUrl(path: string) {
    return this.eventApi.getSignedUrl(path);
  }

  getJitsiRoomToken(eventId: string, sectionId: string) {
    return this.eventModeApi.getJitsiRoomToken(eventId, sectionId);
  }

  extendEventPhase(eventId, phase: SECTION_EVENT_PHASE, extendEventParam: 'start' | 'end',
                   params?: {dateTime: number}) {
    return this.eventModeApi.extendEventPhase(eventId, phase, extendEventParam, params);
  }

  resetEventFollowMe(eventId) {
    return this.eventModeApi.resetEventFollowMe(eventId);
  }

  resetSectionFollowMe(eventId, sectionId: string) {
    return this.eventModeApi.resetSectionFollowMe(eventId, sectionId);
  }

  setEventFollowMe(eventId, followMeMode: FOLLOW_ME_MODE, followMePresenter: AppUser, action: IFollowMeAction) {
    return this.eventModeApi.setEventFollowMe(eventId, followMeMode, followMePresenter, action)
      .then(result => {
      if (result && !isEmpty(action)) {
        this.eventModeApi.watchFollowMe(eventId, action.userId)
          .pipe(take(1))
          .subscribe();
      }
      return Promise.resolve();
    });
  }

  setSectionFollowMe(eventId, sectionId: string, followMeMode: FOLLOW_ME_MODE, followMePresenter: AppUser, action: IFollowMeAction) {
    return this.eventModeApi.setSectionFollowMe(eventId, sectionId, followMeMode, followMePresenter, action)
      .then(result => {
        if (result && !isEmpty(action)) {
          this.eventModeApi.watchFollowMe(eventId, action.userId, sectionId)
            .pipe(take(1))
            .subscribe();
        }
        return Promise.resolve();
      })
      .catch(e => {
        if (e.name === 'FirebaseError' && e.code === 'already-exists' && e.message.includes('follow_me_action/speaker')) {
          this.common.log.log(e.message);
        } else {
          this.eventModeApi.firestoreCatchError(e);
        }
      });
  }

  setFollowMeAction(eventId, action: IFollowMeAction) {
    return this.eventModeApi.setFollowMeAction(eventId, action);
  }

  getFollowMeAction(eventId) {
    return this.eventModeApi.getFollowMeAction(eventId);
  }

  getFollowMeActionPromise(eventId) {
    return this.eventModeApi.getFollowMeActionPromise(eventId);
  }

  setFollowMeFullScreen(eventId, fullScreenMode: boolean) {
    return this.eventModeApi.setFollowMeFullScreen(eventId, fullScreenMode);
  }

  getFollowMeFullScreen(eventId) {
    return this.eventModeApi.getFollowMeFullScreen(eventId);
  }

  getFollowMePresenter(eventId) {
    return this.eventModeApi.getFollowMePresenter(eventId);
  }

  setSpeakerFollowMeAction(eventId, mainSpeakerSectionId: string, action: IFollowMeAction) {
    return this.eventModeApi.setSpeakerFollowMeAction(eventId, mainSpeakerSectionId, action);
  }

  getSpeakerFollowMeAction(eventId, mainSpeakerSectionId: string): Observable<IFollowMeAction> {
    return this.eventModeApi.getSpeakerFollowMeAction(eventId, mainSpeakerSectionId);
  }

  setSpeakerFollowMeFullScreen(eventId, mainSpeakerSectionId: string, fullScreenMode: boolean) {
    return this.eventModeApi.setSpeakerFollowMeFullScreen(eventId, mainSpeakerSectionId, fullScreenMode);
  }

  getFollowMeSpeaker(eventId, mainSpeakerSectionId: string) {
    return this.eventModeApi.getFollowMeSpeaker(eventId, mainSpeakerSectionId);
  }

  getFollowMeSpeakerFullScreen(eventId, mainSpeakerSectionId: string) {
    return this.eventModeApi.getFollowMeSpeakerFullScreen(eventId, mainSpeakerSectionId);
  }

  setFollowMeExtFullScreen(eventId, fullScreenMode: boolean, sectionId: string, contentId: string, moduleId: string) {
    return this.eventModeApi.setFollowMeExtFullScreen(eventId, fullScreenMode, sectionId, contentId, moduleId);
  }

  getFollowMeExtFullScreen(eventId) {
    return this.eventModeApi.getFollowMeExtFullScreen(eventId);
  }

  setSpeakerFollowMeExtFullScreen(eventId, mainSpeakerSectionId: string, fullScreenMode: boolean,
                                  sectionId: string, contentId: string, moduleId: string) {
    return this.eventModeApi.setSpeakerFollowMeExtFullScreen(eventId, mainSpeakerSectionId, fullScreenMode, sectionId, contentId, moduleId);
  }

  getFollowMeSpeakerExtFullScreen(eventId, mainSpeakerSectionId: string) {
    return this.eventModeApi.getFollowMeSpeakerExtFullScreen(eventId, mainSpeakerSectionId);
  }

  setFollowMeQAAction(eventId, action: IFollowMeQAAction) {
    return this.eventModeApi.setFollowMeQAAction(eventId, action);
  }

  getFollowMeQAAction(eventId) {
    return this.eventModeApi.getFollowMeQAAction(eventId);
  }

  setFollowMeSpeakerQAAction(eventId, mainSpeakerSectionId: string, action: IFollowMeQAAction) {
    return this.eventModeApi.setFollowMeSpeakerQAAction(eventId, mainSpeakerSectionId, action);
  }

  getFollowMeSpeakerQAAction(eventId, mainSpeakerSectionId: string): Observable<IFollowMeQAAction> {
    return this.eventModeApi.getFollowMeSpeakerQAAction(eventId, mainSpeakerSectionId);
  }

  setFollowMeScrollPosition(eventId, action: IFollowMeScrollTo) {
    return this.eventModeApi.setFollowMeScrollPosition(eventId, action);
  }

  getFollowMeScrollPosition(eventId) {
    return this.eventModeApi.getFollowMeScrollPosition(eventId);
  }

  setFollowMeSpeakerScrollPosition(eventId, mainSpeakerSectionId: string, action: IFollowMeScrollTo) {
    return this.eventModeApi.setFollowMeSpeakerScrollPosition(eventId, mainSpeakerSectionId, action);
  }

  getFollowMeSpeakerScrollPosition(eventId, mainSpeakerSectionId: string): Observable<IFollowMeScrollTo> {
    return this.eventModeApi.getFollowMeSpeakerScrollPosition(eventId, mainSpeakerSectionId);
  }

  setFollowMeZoomPosition(eventId, action: IFollowMeZoomTo) {
    return this.eventModeApi.setFollowMeZoomPosition(eventId, action);
  }

  getFollowMeZoomPosition(eventId) {
    return this.eventModeApi.getFollowMeZoomPosition(eventId);
  }

  setFollowMeSpeakerZoomPosition(eventId, mainSpeakerSectionId: string, action: IFollowMeZoomTo) {
    return this.eventModeApi.setFollowMeSpeakerZoomPosition(eventId, mainSpeakerSectionId, action);
  }

  getFollowMeSpeakerZoomPosition(eventId, mainSpeakerSectionId: string): Observable<IFollowMeZoomTo> {
    return this.eventModeApi.getFollowMeSpeakerZoomPosition(eventId, mainSpeakerSectionId);
  }

  setFollowMeCurrentMeetingPresenter(eventId, action: IFollowMeMeetingSpeaker) {
    return this.eventModeApi.setFollowMeCurrentMeetingPresenter(eventId, action);
  }

  getFollowMeCurrentMeetingPresenter(eventId): Observable<IFollowMeMeetingSpeaker> {
    return this.eventModeApi.getFollowMeCurrentMeetingPresenter(eventId);
  }

  setFollowMeCurrentMeetingSpeaker(eventId, mainSpeakerSectionId: string, action: IFollowMeMeetingSpeaker) {
    return this.eventModeApi.setFollowMeCurrentMeetingSpeaker(eventId, mainSpeakerSectionId, action);
  }

  getFollowMeCurrentMeetingSpeaker(eventId, mainSpeakerSectionId: string): Observable<IFollowMeMeetingSpeaker> {
    return this.eventModeApi.getFollowMeCurrentMeetingSpeaker(eventId, mainSpeakerSectionId);
  }

  setFollowMeCurrentMeetingViewMode(eventId, action: IFollowMeMeetingViewMode) {
    return this.eventModeApi.setFollowMeCurrentMeetingViewMode(eventId, action);
  }

  getFollowMeCurrentMeetingViewMode(eventId): Observable<IFollowMeMeetingViewModeMap> {
    return this.eventModeApi.getFollowMeCurrentMeetingViewMode(eventId);
  }

  setFollowMeCurrentMeetingViewModeSpeaker(eventId, mainSpeakerSectionId: string, action: IFollowMeMeetingViewMode) {
    return this.eventModeApi.setFollowMeCurrentMeetingViewModeSpeaker(eventId, mainSpeakerSectionId, action);
  }

  getFollowMeCurrentMeetingViewModeSpeaker(eventId, mainSpeakerSectionId: string): Observable<IFollowMeMeetingViewMode> {
    return this.eventModeApi.getFollowMeCurrentMeetingViewModeSpeaker(eventId, mainSpeakerSectionId);
  }

  setFollowMeCommand(eventId, command: IFollowMeCommand, sectionId?: string) {
    return this.eventModeApi.setFollowMeCommand(eventId, command, sectionId);
  }

  getFollowMeCommand(eventId): Observable<IFollowMeCommand> {
    return this.eventModeApi.getFollowMeCommand(eventId);
  }

  getFollowMeSpeakerCommand(eventId, sectionId: string): Observable<IFollowMeCommand> {
    return this.eventModeApi.getFollowMeCommand(eventId, sectionId);
  }

  deleteSectionCollectionsData(eventId: string, sectionId: string) {
    return this.eventModeApi.deleteSectionCollectionsData(eventId, sectionId);
  }

  deleteQuestionnaireAnswers(eventId: string, parentId: string, contentId: string) {
    return this.eventModeApi.deleteQuestionnaireAnswers(eventId, parentId, contentId);
  }

  copySectionBackgroundImage(eventId: string, sectionIdFrom: string, sectionIdTo: string) {
    return this.eventModeApi.copySectionBackgroundImage(eventId, sectionIdFrom, sectionIdTo);
  }

  buildQuestionnaireSummary(eventId: string, sectionId: string, contentId: string, deleteSummary: boolean) {
    return this.eventModeApi.buildQuestionnaireSummary(eventId, sectionId, contentId, deleteSummary);
  }

  saveSectionFeedback(eventId: string, sectionId: string, value: IFeedbackValue) {
    return this.eventModeApi.saveSectionFeedback(eventId, sectionId, value);
  }

  getSectionFeedback(eventId: string, sectionId: string): Observable<IFeedbackValue> {
    return this.eventModeApi.getSectionFeedback(eventId, sectionId);
  }

  getSectionFeedbackPromise(eventId: string, sectionId: string): Promise<IFeedbackValue> {
    return this.eventModeApi.getSectionFeedbackPromise(eventId, sectionId);
  }

  getSectionSummaryFeedback(eventId: string, sectionId: string): Observable<IFeedbackValue[]> {
    return this.eventModeApi.getSectionSummaryFeedback(eventId, sectionId);
  }

  getSectionSummaryFeedbackPromise(eventId: string, sectionId: string): Promise<ISectionFeedbackUserValue[]> {
    return this.eventModeApi.getSectionSummaryFeedbackPromise(eventId, sectionId);
  }

  /**
   * Update several sections task
   * @param eventId
   * @param task
   */
  updateSectionsTask(eventId: string, task: ISectionUpdateTask[]) {
    return this.eventModeApi.updateSectionsTask(eventId, task);
  }

  setPlayerPlaybackState(eventId: string, sectionId: string, state: string, time: number) {
    return this.eventModeApi.setPlayerPlaybackState(eventId, sectionId, state, time);
  }

  getPlayerPlaybackState(eventId: string, sectionId: string) {
    return this.eventModeApi.getPlayerPlaybackState(eventId, sectionId);
  }

  resendUserStatusChangeEmails(
    eventId: string, sectionId: string = null, email: string = null, emails: string[] = null, status: string = null
  ) {
    return this.eventModeApi.resendUserStatusChangeEmails(eventId, sectionId, email, emails, status);
  }

  getEventUsersByAssistantId(eventId: string, assistantId: string): Observable<ConferenceUser[]> {
    return this.eventModeApi.getEventUsersByAssistantId(eventId, assistantId)
      .pipe(map(users => {
          const res = [];
          for (const user of (users || [])) {
            res.push(new ConferenceUser(user));
          }
          return res;
        }
        )
      );
  }

  getRegistrationCounters(eventId: string): Observable<any> {
    return this.eventModeApi.getRegistrationCounters(eventId)
      .pipe(
        map((res: any[]) => res.reduce((accum, item) => {
          accum[item.id] = item;
          return accum;
        }, {}))
      );
  }

  saveStreamingStatus(eventId: string, streamId: string, status: boolean): Promise<any> {
    return this.eventModeApi.saveStreamingStatus(eventId, streamId, status);
  }

  getStreamingStatus(eventId: string, streamId: string): Observable<IStreamingStatus> {
    return this.eventModeApi.getStreamingStatus(eventId, streamId);
  }

  saveScreenSharingStatus(eventId: string, streamId: string, userId: string, status: boolean): Promise<any> {
    return this.eventModeApi.saveScreenSharingStatus(eventId, streamId, userId, status);
  }

  setFollowMePlayerPlaybackState(eventId: string, action: IPlayBackAction) {
    return this.eventModeApi.setFollowMePlayerPlaybackState(eventId, action);
  }

  getFollowMePlayerPlaybackState(eventId: string, sectionId: string, moduleId: string) {
    return this.eventModeApi.getFollowMePlayerPlaybackState(eventId, sectionId, moduleId);
  }

  setFollowMePlayerState(documentPathParams: IDocumentPathParams, action) {
    return this.eventModeApi.setFollowMePlayerState(documentPathParams, action);
  }

  getFollowMePlayerState(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.getFollowMePlayerState(documentPathParams);
  }

  addMeetingUserToDashboard(eventId: string, sectionId: string, userId: string,
                            object: {picture: string, name: string, left: string, top: string}) {
    return this.eventModeApi.addMeetingUserToDashboard(eventId, sectionId, userId, object);
  }

  updateMeetingUserOnDashboard(eventId: string, sectionId: string, userId: string, object: {microphone: boolean, webcam: boolean}) {
    return this.eventModeApi.updateMeetingUserOnDashboard(eventId, sectionId, userId, object);
  }

  deleteMeetingUserFromDashboard(eventId: string, sectionId: string, userId: string) {
    return this.eventModeApi.deleteMeetingUserFromDashboard(eventId, sectionId, userId);
  }

  getMeetingDashboardUsers(eventId: string, sectionId: string): Observable<DocumentChange<any>[]> {
    return this.eventModeApi.getMeetingDashboardUsers(eventId, sectionId);
  }

  getMeetingDashboardUsersList(eventId: string, sectionId: string) {
    return this.eventModeApi.getMeetingDashboardUsersList(eventId, sectionId);
  }

  addMeetingUserToFocusView(eventId: string, sectionId: string, userId: string, object: {picture: string, name: string}) {
    return this.eventModeApi.addMeetingUserToFocusView(eventId, sectionId, userId, object);
  }

  deleteMeetingUserFromFocusView(eventId: string, sectionId: string) {
    return this.eventModeApi.deleteMeetingUserFromFocusView(eventId, sectionId);
  }

  getMeetingFocusViewUser(eventId: string, sectionId: string) {
    return this.eventModeApi.getMeetingFocusViewUser(eventId, sectionId);
  }

  getMeetingFocusViewCurrentUser(eventId: string, sectionId: string) {
    return this.eventModeApi.getMeetingFocusViewCurrentUser(eventId, sectionId);
  }

  setMeetingParams(eventId: string, sectionId: string, params: any) {
    return this.eventModeApi.setMeetingParams(eventId, sectionId, params);
  }

  getMeetingParams(eventId: string, sectionId: string) {
    return this.eventModeApi.getMeetingParams(eventId, sectionId);
  }

  removeFromQueue(eventId: string, sectionId: string, userId: string) {
    return this.eventModeApi.removeFromQueue(eventId, sectionId, userId);
  }

  setQueue(eventId: string, sectionId: string, model: QueueModel) {
    return this.eventModeApi.setQueue(eventId, sectionId, model);
  }

  getQueue(eventId: string, sectionId: string) {
    return this.eventModeApi.getQueue(eventId, sectionId);
  }

  setWhoCanHear(eventId: string, sectionId: string, whoCanHearId: string, model: WhoCanHear) {
    return this.eventModeApi.setWhoCanHear(eventId, sectionId, whoCanHearId, model);
  }

  getWhoCanHear(eventId: string, sectionId: string, whoCanHearId: string) {
    return this.eventModeApi.getWhoCanHear(eventId, sectionId, whoCanHearId);
  }

  saveMeetingRoomParticipants(eventId: string, ownerSectionId: string, roomId: string, roomName: string,
                              action: 'add-user' | 'delete-user' | 'remove-all' | 'create-room', participantId?: string) {
    return this.eventModeApi.saveMeetingRoomParticipants(eventId, ownerSectionId, roomId, roomName, action, participantId);
  }

  addUpdateMeetingRoomInDB(eventId: string, ownerSectionId: string, room: IRoom) {
    return this.eventModeApi.addUpdateMeetingRoomInDB(eventId, ownerSectionId, room);
  }

  getMeetingRoomsParticipants(eventId: string, ownerSectionId: string) {
    return this.eventModeApi.getMeetingRoomsParticipants(eventId, ownerSectionId);
  }

  setConferenceRoomInvite(eventId: string, ownerSectionId: string, invite: ConferenceRoomInvite) {
    return this.eventModeApi.setConferenceRoomInvite(eventId, ownerSectionId, invite);
  }

  deleteMeetingRoomParticipants(eventId: string, ownerSectionId: string, roomId: string) {
    return this.eventModeApi.deleteMeetingRoomParticipants(eventId, ownerSectionId, roomId);
  }

  deleteAllParticipantsFromMeetingRoom(eventId: string, ownerSectionId: string, roomId: string) {
    return this.eventModeApi.deleteAllParticipantsFromMeetingRoom(eventId, ownerSectionId, roomId);
  }

  joinParticipantToMeetingRoom(eventId: string, ownerSectionId: string, roomId: string, participants: string[]) {
    return this.eventModeApi.joinParticipantToMeetingRoom(eventId, ownerSectionId, roomId, participants);
  }

  saveMeetingRoomPosition(eventId: string, ownerSectionId: string, roomId: string, position: {top: string, left: string}) {
    return this.eventModeApi.saveMeetingRoomPosition(eventId, ownerSectionId, roomId, position);
  }

  updateEducationModule(moduleId: string, content) {
    return this.eventModeApi.updateEducationModule(moduleId, content);
  }

  setFollowMeConferenceGrid(eventId, action: IFollowMeConferenceGrid) {
    return this.eventModeApi.setFollowMeConferenceGrid(eventId, action);
  }

  getFollowMeConferenceGrid(eventId) {
    return this.eventModeApi.getFollowMeConferenceGrid(eventId);
  }

  setFollowMePoolAction(eventId, action: IFollowMePoolAction) {
    return this.eventModeApi.setFollowMePoolAction(eventId, action);
  }

  getFollowMePoolAction(eventId) {
    return this.eventModeApi.getFollowMePoolAction(eventId);
  }

  saveUpdateDeleteQAMessages(eventId: string, message: QAMessage, remove: boolean): Promise<string> {
    return this.eventModeApi.saveUpdateDeleteQAMessages(eventId, message, remove);
  }

  getContentQAMessages(eventId: string, sectionId: string, contentId: string) {
    return this.eventModeApi.getContentQAMessages(eventId, sectionId, contentId);
  }

  getContentQAMessagesCount(eventId: string, sectionId: string, contentId: string) {
    return this.eventModeApi.getContentQAMessagesCount(eventId, sectionId, contentId);
  }

  addContentContainer(eventId: string, content: any, saveAsDraft?: boolean) {
    return this.eventModeApi.addContentContainer(eventId, content, saveAsDraft);
  }

  editContentContainer(eventId: string, timelineId: string, content: any, saveAsDraft: boolean, updateTime: number): any {
    return this.eventModeApi.editContentContainer(eventId, timelineId, content, saveAsDraft, updateTime);
  }

  deleteContentContainer(eventId: string, parentId: string, contentId: string, deleteDraft: boolean): any {
    return this.eventModeApi.deleteContentContainer(eventId, parentId, contentId, deleteDraft);
  }

  relocateContentContainer(content: ContentContainer, params: IRelocateParams): Promise<any> {
    return this.eventModeApi.relocateContentContainer(content, params);
  }

  /**
   * Copy content container
   * @param content - content
   * @param newEventId - copy to event
   * @param newParentId - copy to section
   * @param newOrderIndex - copy with new order index
   * @param copyPinLine
   * @param direction
   */
  copyContent(content: AbstractContent, newEventId: string, newParentId: string, newOrderIndex: number,
              copyPinLine: boolean, direction: DIRECTION_COPY_CONTENT): Promise<string> {
    return this.eventModeApi.copyContent(content, newEventId, newParentId, newOrderIndex, copyPinLine, direction);
  }

  loadSectionChildrenTree(section: AbstractContent, loadFromEducation: boolean): Promise<PlaneContent[]> {
    return this.eventModeApi.loadSectionChildrenTree(section, loadFromEducation).then((value: Object[]) => {
      if (!isEmpty(value) && Array.isArray(value)) {
        return value.map(o => new PlaneContent(new SectionTimeline(o), null));
      }
      return [];
    });
  }

  saveContentDrawnLines(content: AbstractContent, object, personalData) {
    return this.eventModeApi.saveContentDrawnLines(content, object, personalData);
  }

  updateContentDrawnLines(content: AbstractContent, object, personalData) {
    return this.eventModeApi.updateContentDrawnLines(content, object, personalData);
  }

  loadContentDrawnLines(content: AbstractContent, personalData) {
    return this.eventModeApi.loadContentDrawnLines(content, personalData);
  }

  saveContentPins(content: AbstractContent, object, personalData) {
    return this.eventModeApi.saveContentPins(content, object, personalData);
  }

  updateContentPins(content: AbstractContent, object, personalData) {
    return this.eventModeApi.updateContentPins(content, object, personalData);
  }

  getQaQuestions(eventId: string, sectionId: string) {
    return this.eventModeApi.getQaQuestions(eventId, sectionId);
  }

  loadContentPins(content: AbstractContent, personalData) {
    return this.eventModeApi.loadContentPins(content, personalData);
  }

  getContentDocument(documentPathParams: IDocumentPathParams, pathFrom?: string): Promise<any> {
    return this.eventModeApi.getContentDocument(documentPathParams, pathFrom)
      .then(snap => snap.exists ? new Object({id: snap.id, ...snap.data()}) : null);
  }

  sendQuizQuestionAnswer(documentPathParams: IDocumentPathParams, userKey: string, questionId: string,  questionType: number,
                         questionAnswersSummaryMethodName: string, questionAnswersSummarySaveType: SUMMARY_SAVE_TYPE,
                         answer: string[], anonymousAnswers:  boolean,
                         user: TUser, sendTime: number, properties: any): Promise<any> {
    return this.eventModeApi.sendQuizQuestionAnswer(documentPathParams, userKey, questionId, questionType,
      questionAnswersSummaryMethodName, questionAnswersSummarySaveType, answer,
      anonymousAnswers, user, sendTime, properties);
  }

  getQuizQuestionsAnswers(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.getQuizQuestionsAnswers(documentPathParams);
  }

  getQuizQuestionsAnswersByUser(documentPathParams: IDocumentPathParams, userId: string) {
    return this.eventModeApi.getQuizQuestionsAnswersByUser(documentPathParams, userId);
  }

  updateQuiz(documentPathParams: IDocumentPathParams, object: {[name: string]: any}) {
    return this.eventModeApi.updateQuiz(documentPathParams, object);
  }

  updateQuizQuestion(documentPathParams: IDocumentPathParams, questionId: string, object: {[name: string]: any}) {
    return this.eventModeApi.updateQuizQuestion(documentPathParams, questionId, object);
  }

  buildQuizSummary(clientId: string, documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.buildQuizSummary(clientId, documentPathParams);
  }

  getQuizSummary(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.getQuizSummary(documentPathParams);
  }

  getQuizTextSummary(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.getQuizTextSummary(documentPathParams);
  }

  getQuizConstraint(documentPathParams: IDocumentPathParams): Promise<IQuizConstraint> {
    return this.eventModeApi.getQuizConstraint(documentPathParams);
  }

  checkQuizQuestionHasAnswers(questionId: string, questionType: number, documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.checkQuizQuestionHasAnswers(questionId, questionType, documentPathParams);
  }

  checkQuizHasAnswers(documentPathParams: IDocumentPathParams): Promise<boolean> {
    return this.eventModeApi.checkQuizHasAnswers(documentPathParams);
  }

  deleteQuizUserAnswers(userId: string, documentPathParams: IDocumentPathParams,
                        questionTypes: {[questionId: string]: {methodName: string, saveType: SUMMARY_SAVE_TYPE}}) {
    return this.eventModeApi.deleteQuizUserAnswers(userId, documentPathParams, questionTypes);
  }

  deleteQuizAllAnswers(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.deleteQuizAllAnswers(documentPathParams);
  }

  deleteContainerDocumentWithInsideCollections(documentPathParams: IDocumentPathParams, collectionList: string[]): Promise<boolean> {
    return this.eventModeApi.deleteContainerDocumentWithInsideCollections(documentPathParams, collectionList);
  }

  async deleteContainerDocumentWithInsideCollectionsWithStoreData(documentPathParams: IDocumentPathParams,
                                                                  collectionList: IStorageCollectionPath[]): Promise<boolean> {
    return this.eventModeApi.deleteContainerDocumentWithInsideCollectionsWithStoreData(documentPathParams, collectionList);
  }
  relocateContainerDocumentInsideCollections(oldDocumentPathParams: IDocumentPathParams, newDocumentPathParams: IDocumentPathParams,
                                             collectionList: string[]): Promise<boolean> {
    return this.eventModeApi.relocateContainerDocumentInsideCollections(oldDocumentPathParams, newDocumentPathParams, collectionList);
  }

  getTaskUserAnswers(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.getTaskUserAnswers(documentPathParams);
  }

  userSubmitTask(documentPathParams: IDocumentPathParams, userTaskObject: any) {
    return this.eventModeApi.userSubmitTask(documentPathParams, userTaskObject);
  }

  loadAllUsersTaskAnswers(documentPathParams: IDocumentPathParams) {
    return this.eventModeApi.loadAllUsersTaskAnswers(documentPathParams)
      .pipe(
        map((res: any[]) => res.reduce((accum, item) => {
          accum[item.userId] = item;
          return accum;
        }, {}))
      );
  }

  incrementRegistered(): Promise<any> {
    return this.eventModeApi.incrementRegistered();
  }

  copyContentToMyKnowledge(params: ICopyTask) {
    return this.eventModeApi.copyContentToMyKnowledge(params);
  }

  setSectionSubtargetRating(eventId: string, sectionId: string, subtargetOrder: string, rating: string) {
    return this.eventModeApi.setSectionSubtargetRating(eventId, sectionId, subtargetOrder, rating);
  }

  getSectionSubtargetRatingByUser(eventId: string, sectionId: string) {
    return this.eventModeApi.getSectionSubtargetRatingByUser(eventId, sectionId);
  }

  getSectionSubtargetRatingSummary(eventId: string, sectionId: string) {
    return this.eventModeApi.getSectionSubtargetRatingSummary(eventId, sectionId);
  }

  getEncodedBase64ContentContainerItem(params: IExtDocumentPathParams, base64Scheme: IURLToBASE64Scheme) {
    return this.eventModeApi.getEncodedBase64ContentContainerItem(params, base64Scheme);
  }

  getEncodedBase64Path(eventId: string, path: string): Promise<Object> {
    return this.eventModeApi.getEncodedBase64Path(eventId, path);
  }

  copyEducationChangesToTimeline(params: IUpdateChangesParams) {
    return this.eventModeApi.copyEducationChangesToTimeline(params);
  }

  getEventSummaryByCountry(eventId: string): Observable<ICountryStatisticsMap> {
    return this.eventModeApi.getEventSummaryByCountry(eventId);
  }

  checkEventTimelineStructure(eventId: string, logPath: string): Promise<string> {
    return this.eventModeApi.checkEventTimelineStructure(eventId, logPath);
  }

  createContentByTemplate(params: IExtCopyTask) {
    return this.eventModeApi.createContentByTemplate(params);
  }

  recalcOrderIndex(eventId: string) {
    return this.eventModeApi.recalcOrderIndex(eventId);
  }

  getLockedSectionRef(eventId: string, sectionId: string) {
    return this.eventModeApi.getLockedSectionRef(eventId, sectionId);
  }

  convertContentToContentContainer(content: AbstractContent) {
    return this.eventModeApi.convertContentToContentContainer(content);
  }

  isExistsContent(content: ContentContainer, contentPath: CONTENT_PATH_TYPE) {
    return this.eventModeApi.isExistsContent(content, contentPath);
  }

  getSectionContentsByCondition(eventId: string, sectionId: string, condition: QueryFn, contentPath: CONTENT_PATH_TYPE) {
    return this.eventModeApi.getSectionContentsByCondition(eventId, sectionId, condition, contentPath);
  }

  updateContentFields(content: AbstractContent, params: {[fieldName: string]: any}, contentPath: CONTENT_PATH_TYPE) {
    return this.eventModeApi.updateContentFields(content, params, contentPath);
  }

  saveChangeLog(eventId: string, changelog: IChangeLogValue[]) {
    return this.eventModeApi.saveChangeLog(eventId, changelog);
  }

  saveChangeLogBackup(eventId: string, version: string, changelog: IChangeLogValue[]) {
    return this.eventModeApi.saveChangeLogBackup(eventId, version,  changelog);
  }

  getChangeLog(eventId: string) {
    return this.eventModeApi.getChangeLog(eventId);
  }

  async backupChangeLog(eventId: string, version: string) {
    return this.eventModeApi.backupChangeLog(eventId, version);
  }

  deleteChangeLog(eventId: string) {
    return this.eventModeApi.deleteChangeLog(eventId);
  }

  getUrlTimelineContentInModule(link: IContentEntityLink) {
    return this.eventModeApi.getUrlTimelineContentInModule(link);
  }

  updateContentFromModule(timelineContent: AbstractContent, moduleContent: AbstractContent) {
    return this.eventModeApi.updateContentFromModule(timelineContent, moduleContent);
  }

  checkUserHasDRMRightsToDeleteSectionContents(section: SectionContent) {
    return this.eventModeApi.checkUserHasDRMRightsToDeleteSectionContents(section);
  }

  saveQuizTemplate(templateId: string, templateType: TEMPLATE_TYPE, template: IQuizTemplate) {
    return this.eventModeApi.saveQuizTemplate(templateId, templateType, template);
  }

  deleteQuizTemplate(templateId: string, templateType: TEMPLATE_TYPE) {
    return this.eventModeApi.deleteQuizTemplate(templateId, templateType);
  }

  loadQuizTemplatesList(templateType: TEMPLATE_TYPE) {
    return this.eventModeApi.loadQuizTemplatesList(templateType);
  }

  loadQuizTemplate(templateId: string, templateType: TEMPLATE_TYPE): Promise<IQuizTemplate> {
    return firstValueFrom(this.eventModeApi.loadQuizTemplate(templateId, templateType));
  }

  saveQuizQuestionAnswersProperties(documentPathParams: IDocumentPathParams, userKey: string, questionId: string,
                                    anonymousAnswers: boolean, applicationMode: APP_MODE, properties: any): Promise<any> {
    return this.eventModeApi.saveQuizQuestionAnswersProperties(documentPathParams, userKey,
      questionId, anonymousAnswers, applicationMode, properties);
  }

  getDeletedContents(eventId: string, sectionId: string, hours: number) {
    return this.eventApi.getDeletedContents(eventId, sectionId, hours);
  }

  restoreContent(eventId: string, sectionId: string, contentId: string, hours: number) {
    return this.eventApi.restoreContent(eventId, sectionId, contentId, hours);
  }

  restoreSection(eventId: string, sectionId: string, hours: number) {
    return this.eventApi.restoreSection(eventId, sectionId, hours);
  }

  listenUpdateTimelineFromEducationLog(eventId: string) {
    return this.eventModeApi.listenUpdateTimelineFromEducationLog(eventId);
  }

  listenEventActivity() {
    return this.eventModeApi.listenEventActivity();
  }

  listenEngagedSessions(startAt: number) {
    return this.eventModeApi.listenEngagedSessions(startAt);
  }

  listenEngagedSessionsTotal() {
    return this.eventModeApi.listenEngagedSessionsTotal();
  }

}
