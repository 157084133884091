import {BehaviorSubject, combineLatest, filter, interval, Subject, Subscription, take, takeUntil} from 'rxjs';
import {isEmpty, merge, union} from 'lodash';
import * as Sketchfab from '@sketchfab/viewer-api';
import {FollowMeService} from '../../../../services/follow-me.service';
import {ANNOTATION_TYPE, ATTR_IS_PLAYING, MEDIA_LOOP, TEXT_MARK_TAGS} from '../../../../core/constants';
import {IAnimationParams, ICameraPosition, ISketchAnnotation, SKETCH_STATUS, TSketchfab} from './sketchfab-model/sketchfab-model';

export class SketchfabService {

  sketchStatus$ = new BehaviorSubject<SKETCH_STATUS>(SKETCH_STATUS.START_INIT);
  private sketchApi = new BehaviorSubject<any>(null);
  private unsub = new Subject();
  cameraPosition$ = new BehaviorSubject<ICameraPosition>(null);
  annotationList$ = new BehaviorSubject<ISketchAnnotation[]>(null) ;
  private animationEnded$ = new Subject<boolean>();
  private animationEndedSubscription: Subscription;
  private animationElementId: string;

  constructor(private followMeService?: FollowMeService) { }

  initSketch(iframe: Element, sketchfab: TSketchfab, runWithStopAnimation?: boolean) {
    this.unsubscribe();
    if (!sketchfab?.uid || isEmpty(sketchfab?.params) || !iframe) {
      return;
    }
    this.unsub = new Subject();
    const vm = this;
    this.sketchStatus$.next(SKETCH_STATUS.START_INIT);
    this.sketchStatus$.pipe(filter(status => status === SKETCH_STATUS.READY), takeUntil(this.unsub))
      .subscribe(() => {
        this.getAnnotationAnimationList();
      });
    let onSuccess = (api) => {
      this.sketchApi.next(api);
      api.addEventListener('viewerready', function () {
        vm.sketchStatus$.next(SKETCH_STATUS.READY);
      });
      api.addEventListener('annotationSelect', function(index) {
        vm.getCameraPosition(index);
      });
      api.addEventListener('animationEnded', function() {
        vm.animationEnded$.next(true);
      });
      api.addEventListener('animationStop', function() {
        vm.animationEnded$.next(true);
      });
      api.addEventListener('animationChange', function() {
        vm.animationEnded$.next(true);
      });
    };
    onSuccess = onSuccess.bind(this);

    let onError = () => {
      this.sketchStatus$.next(SKETCH_STATUS.ERROR);
    };
    onError = onError.bind(this);

    if (iframe) {
      const client = new Sketchfab(iframe);
      const params = merge(sketchfab.getInitializedParams(), runWithStopAnimation ? {animation_autoplay: 0} : {});
      client.init(sketchfab.uid, {
        ...params,
        success: onSuccess,
        error: onError
      });
    }
  }

  setCameraPosition(position: ICameraPosition) {
    const vm = this;
    if (this.sketchStatus$.getValue() === SKETCH_STATUS.READY && !isEmpty(position) && this.sketchApi.getValue()) {
      if (position.annotation_index === -1) {
        this.sketchApi.getValue().hideAnnotationTooltips(function(err) {
          if (err) {
            throw err;
          }
        });
      } else
      if (position.annotation_index !== null && position.annotation_index > -1) {
        this.sketchApi.getValue().gotoAnnotation(position.annotation_index,
          {preventCameraAnimation: false, preventCameraMove: false}, function(err, index) {
          if (err) {
            throw err;
          } else {
          vm.sketchApi.getValue().showAnnotationTooltip(index, function(er) {
              if (er) {
                throw er;
              }
            });
          }
        });
      } else {
        this.sketchApi.getValue().setCameraLookAt(position.position, position.target, 2, (err) => {
          if (err) {
            throw err;
          }
        });
      }
    }
  }

  getCameraPosition(annotation_index: any = null) {
    const vm = this;
    if (this.sketchStatus$.getValue() === SKETCH_STATUS.READY && this.sketchApi.getValue()) {
      this.sketchApi.getValue().getCameraLookAt((dummy, camera) => {
        this.cameraPosition$.next( {position: camera.position, target: camera.target, annotation_index: annotation_index});
        if (!this.followMeService ||
            (this.followMeService && !this.followMeService.isFollowMeEnable() && annotation_index !== null && annotation_index > -1)) {
          vm.sketchApi.getValue().showAnnotationTooltip(annotation_index, function(er) {
            if (er) {
              throw er;
            }
          });
        }
      });
    }
  }

  getCameraPositionInterval() {
    interval(3000).pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getCameraPosition();
    });
  }

  private getAnnotationAnimationList() {
    const clearHtml = (text: string) => {
      if (!text) {
        return '';
      }
      const div = document.createElement('span');
      div.innerHTML = text;
      return div.innerText;
    };

    this.sketchApi.pipe(filter(v => !!v), take(1))
      .subscribe(() => {
        this.annotationList$.next(null);
        const annotation = new BehaviorSubject<ISketchAnnotation[]>(null);
        const animation = new BehaviorSubject<ISketchAnnotation[]>(null);
        combineLatest([annotation.pipe(filter(v => !!v)), animation.pipe(filter(v => !!v))])
          .pipe(takeUntil(this.unsub))
          .subscribe(([ann, ani]) => {
            this.annotationList$.next(union(ann, ani));
          });
        this.sketchApi.getValue().getAnnotationList(function (error, annotations) {
          if (!error) {
            if (Array.isArray(annotations)) {
              const list = annotations
                .map((o, idx) => new Object({
                  annotation_index: idx,
                  name: o.name,
                  description: clearHtml(o.content.raw),
                  annotation_type: ANNOTATION_TYPE.TEXT
                }) as ISketchAnnotation);
              annotation.next((list || []).filter(it => !isEmpty(it)));
            }
          }
        });
        this.sketchApi.getValue().getAnimations(function(error, animations) {
          if (!error) {
            if (Array.isArray(animations)) {
              const list = animations
                .filter(it => !isEmpty(it))
                .sort((a, b) => a[3] < b[3] ? -1 : 1)
                .map((o, idx) => new Object({
                  annotation_index: o[0],
                  name: o[1],
                  annotation_type: ANNOTATION_TYPE.VIDEO,
                  options: {duration: o[2]}
                }) as ISketchAnnotation);
              animation.next((list || []).filter(it => !isEmpty(it)));
            }
          }
        });
      });
  }

  gotoAnnotation(annotation: ISketchAnnotation, params?: any) {
    const vm = this;
    if (vm.animationEndedSubscription) {
      vm.animationEndedSubscription.unsubscribe();
      vm.animationEndedSubscription = null;
      vm.animationElementId = null;
    }
    if (!this.sketchApi.getValue()) {
      return;
    }
    switch (annotation.annotation_type) {
      case 'text':
        this.sketchApi.getValue().gotoAnnotation(annotation.annotation_index, {preventCameraAnimation: false, preventCameraMove: false});
        break;
      case 'video':
        vm.playAnimation(annotation.annotation_index, params);
        break;
    }
  }

  private playAnimation(animation_uid, params: IAnimationParams) {
    this.animationElementId = params?.elementId;
    this.sketchApi.getValue().pause(() => {
      this.sketchApi.getValue().setCurrentAnimationByUID(animation_uid, (err) => {
        if (!err) {
          const anm = this.annotationList$.getValue().find(a => a.annotation_index === animation_uid);
          const speed = Number(params.video_options_speed ?? 1);
          this.sketchApi.getValue().setSpeed(speed);
          const startTime = params?.video_options_start_time ? Number(params.video_options_start_time) : 0;
          const endTime = params?.video_options_end_time ? Number(params.video_options_end_time) : anm.options?.duration;
          const loopMode = params.video_options_loop ?? MEDIA_LOOP.ONE;
          interval(10).pipe(take(1)).subscribe(() => {
            this.sketchApi.getValue().getCurrentTime((err, currentTime) => {
              if (!err) {
                const shouldContinueAnimation = params.continueAnimation && currentTime < endTime - .5;
                this.sketchApi.getValue().seekTo(Number(shouldContinueAnimation ? currentTime : startTime), () => {
                  this.sketchApi.getValue().setCycleMode(params.video_options_loop);
                  if (loopMode === MEDIA_LOOP.ONE) {
                    this.takeOneInterval(startTime, endTime, speed);
                  } else if (loopMode === MEDIA_LOOP.LOOP_ONE) {
                    this.takeInfinityInterval(startTime, endTime, speed);
                  }
                  this.sketchApi.getValue().play();
                });
              }
            });
          });
        }
      });
    });
  }

  private takeOneInterval(startTime: number, endTime: number, speed: number) {
    this.animationEndedSubscription = interval((endTime * 1000 - startTime * 1000) / speed).pipe(take(1), takeUntil(this.unsub))
      .subscribe(() => {
        this.sketchApi.getValue().pause(() => this.stopAnimationElementAndUnsubscribe());
      });
  }

  private takeInfinityInterval(startTime: number, endTime: number, speed: number) {
    this.animationEndedSubscription = interval((endTime * 1000 - startTime * 1000) / speed).pipe(takeUntil(this.unsub))
      .subscribe(() => {
        this.sketchApi.getValue().pause((error) => {
          if (!error) {
            this.sketchApi.getValue().seekTo(startTime, () => this.sketchApi.getValue().play());
          }
        });
      });
  }

  private stopAnimationElementAndUnsubscribe() {
    if (this.animationElementId) {
      Array.from(document.getElementsByTagName(TEXT_MARK_TAGS.HOTSPOT))
        .filter((it: HTMLElement) => it.dataset.element_id === this.animationElementId)
        .forEach((elem: HTMLElement) => {
          elem.removeAttribute(ATTR_IS_PLAYING.VIDEO);
        });
    }
    this.clearAnimationSubscription();
  }

  private clearAnimationSubscription() {
    if (this.animationEndedSubscription) {
      this.animationEndedSubscription.unsubscribe();
    }
    this.animationEndedSubscription = null;
    this.animationElementId = null;
  }

  stopAnimation(annotation: ISketchAnnotation) {
    const vm = this;
    this.clearAnimationSubscription();
    if (!this.sketchApi.getValue()) {
      return;
    }
    switch (annotation.annotation_type) {
      case 'video':
        this.sketchApi.getValue().pause();
        break;
    }
  }

  private unsubscribe() {
    this.clearAnimationSubscription();
    if (this.unsub) {
      this.unsub.next(true);
      this.unsub.complete();
      this.unsub = null;
    }
  }

  destroy() {
    this.unsubscribe();
  }

  restore() {
    if (!this.unsub) {
      this.unsub = new Subject();
    }
  }
}

