import {QueryFn} from '@angular/fire/compat/firestore';
import {Constants} from '../../../core/constants';
import {firstValueFrom} from 'rxjs';
import {CONTAINER_ITEM_TYPE, ContentContainer} from '../../../model/content/ContentContainer';
import {ServiceTasksComponent} from '../service-tasks.component';
import {IWriteCache} from '../st-intercace';
import {Quiz} from '../../../modules/content-container/components/quiz/quiz-model/quiz';
import {EventQuestion} from '../../../model/EventQuestion';
import {isEmpty} from 'lodash';

export const updateQuestionnaireFontSize = async (thiz: ServiceTasksComponent) => {
  const request = (collection: string, size: number, startAt) => {
    let queryFn: QueryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
      .orderBy('type').limit(size);
    if (startAt) {
      queryFn = q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER)
        .orderBy('type').limit(size).startAfter(startAt);
    }
    return thiz.afs.collectionGroup(collection, queryFn).get();
  };

  if (!await thiz.commonService.confirmation(
    'Run: Update question contents font size to small')) {
    return;
  }

  let cache: IWriteCache[] = [];
  console.log('Start process');

  const chunkSize = 500;

  console.log('query contents');
  let contentsCount = 0;
  let contents_ = await firstValueFrom(request('contents', chunkSize, null));
  while (contents_.docs.length > 0) {
    contentsCount += contents_.docs.length;
    console.log('loaded', contentsCount);
    for (const doc of contents_.docs) {
      if (!doc.ref.path.includes('conference')) {
        continue;
      }
      const cc = doc.data() as ContentContainer;
      if (cc.hasOwnProperty('items')) {
        let changed = false;
        for (let i = 0; i < cc.items.length; i++) {
          const item = cc.items[i];
          if (item.type !== CONTAINER_ITEM_TYPE.QUIZ) {
            continue;
          }
          const quiz = new Quiz(item.data);
          const questions = Object.values(quiz.questions) as EventQuestion[];
          for (const question of questions) {
            if (![Constants.QTYPE_CHECK, Constants.QTYPE_CHOICE].includes(question.storypoint)) {
              continue;
            }
            if (isEmpty(cc.items[i].data.options)) {
              cc.items[i].data.options = {};
            }
            cc.items[i].data.options.fontSize = '0.75em';
            changed = true;
          }
        }
        if (changed) {
          cache.push({ref: doc.ref, obj: cc});
        }
      }
    }
    if (cache.length) {
      // await thiz.commitCache(cache);
      cache = [];
    }
    contents_ = await firstValueFrom(request('contents', chunkSize, contents_.docs[contents_.docs.length - 1]));
  }

  thiz.commitCache(cache).finally(() => console.log('End process.'));
};
