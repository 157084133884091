export enum EXAM_ACTIONS {
  ADD_CONTENT = 'add-content',
  EDIT_CONTENT = 'edit-content',
  DELETE_CONTENT = 'delete-content',
  CONTENT_DRAFT = 'content-draft', // change content status: done => draft
  CONTENT_DONE = 'content-done', // change content status: draft <=> done
  CONTENT_REVIEWED = 'content-reviewed', // change content status: draft <=> done <=> reviewed;
  CONTENT_APPROVED = 'content-approved', // change content status: draft <=> done <=> approved;
  EDIT_EXAM_SETTINGS = 'edit-exam-settings',
  EDIT_SECTION_SETTINGS = 'edit-section-settings',
  EXAM_ASSESSMENT_STATUS_AUTO_ASSESSED = 'assessment-status-autoAssessed',
  EXAM_ASSESSMENT_STATUS_ASSESSED = 'assessment-status-assessed',
  EXAM_ASSESSMENT_STATUS_REVIEWED = 'assessment-status-reviewed',
  EXAM_ASSESSMENT_STATUS_APPROVED = 'assessment-status-approved'
}

export const MANAGE_CONTENT_ACTIONS = [EXAM_ACTIONS.ADD_CONTENT, EXAM_ACTIONS.EDIT_CONTENT, EXAM_ACTIONS.DELETE_CONTENT];
export const ASSESSMENT_ACTIONS = [
  EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_AUTO_ASSESSED,
  EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_ASSESSED,
  EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_APPROVED,
  EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_REVIEWED
];

export enum EXAM_ROLES {
  PRESENTER = 'presenter',
  CONTENT_CREATOR = 'content-creator',
  CONTENT_REVIEWER = 'content-reviewer',
  CONTENT_APPROVER = 'content-approver',
  VIEWER = 'viewer',
  EXAM_ASSESSMENT_ASSESSOR = 'exam-assessment-assessor',
  EXAM_ASSESSMENT_REVIEWER = 'exam-assessment-reviewer',
  EXAM_ASSESSMENT_APPROVER = 'exam-assessment-approver'
}

export enum ROLE_TYPE {
  EXAM = 'exam',
  SECTION = 'section'
}

interface IRoleSet {
  [roleId: string]: {
    actions: EXAM_ACTIONS[],
    title: string;
    roleUsedType?: ROLE_TYPE; // where role used in exam or section, or everywhere if empty
    disabled?: boolean // disable role and hide from list for select. this role ignored on check users roles.
  };
}

export const EXAM_ROLES_REFERENCE: IRoleSet = {
  [EXAM_ROLES.PRESENTER]: {
    actions: [...Object.values(EXAM_ACTIONS)],
    title: 'exams.roles.presenter',
    roleUsedType: ROLE_TYPE.EXAM
  },
  [EXAM_ROLES.CONTENT_CREATOR]: {
    actions: [EXAM_ACTIONS.ADD_CONTENT, EXAM_ACTIONS.EDIT_CONTENT, EXAM_ACTIONS.DELETE_CONTENT,
      EXAM_ACTIONS.CONTENT_DONE, EXAM_ACTIONS.CONTENT_DRAFT, EXAM_ACTIONS.EDIT_SECTION_SETTINGS],
    title: 'exams.roles.content.creator'
  },
  [EXAM_ROLES.CONTENT_REVIEWER]: {
    actions: [EXAM_ACTIONS.CONTENT_DONE, EXAM_ACTIONS.CONTENT_REVIEWED],
    title: 'exams.roles.content.reviewer'
  },
  [EXAM_ROLES.CONTENT_APPROVER]: {
    actions: [EXAM_ACTIONS.CONTENT_REVIEWED, EXAM_ACTIONS.CONTENT_APPROVED],
    title: 'exams.roles.content.approver'
  },
  [EXAM_ROLES.VIEWER]: {
    actions: [],
    title: 'exams.roles.viewer',
    roleUsedType: ROLE_TYPE.EXAM
  },
  [EXAM_ROLES.EXAM_ASSESSMENT_ASSESSOR]: {
    actions: [EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_AUTO_ASSESSED, EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_ASSESSED],
    title: 'exams.roles.assessment.assessor'
  },
  [EXAM_ROLES.EXAM_ASSESSMENT_REVIEWER]: {
    actions: [EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_REVIEWED],
    title: 'exams.roles.assessment.reviewer'
  },
  [EXAM_ROLES.EXAM_ASSESSMENT_APPROVER]: {
    actions: [EXAM_ACTIONS.EXAM_ASSESSMENT_STATUS_APPROVED],
    title: 'exams.roles.assessment.approver'
  }
};
