import { QuizQuestionSheetComponent } from '../../../quiz-question-sheet/quiz-question-sheet.component';
import {QUESTION_VIEW_MODE} from '../quiz-quiestion-types';

export const textBalloonsListView = (quiz: QuizQuestionSheetComponent) => {
  return quiz.dataService.updateQuizQuestion(quiz.documentPathParams, quiz.currentQKey$.getValue(),
    {'viewMode': QUESTION_VIEW_MODE.LIST_VIEW})
    .catch(e => quiz.common.log.error(e));
};

export const textBalloonsCloudView = (quiz: QuizQuestionSheetComponent) => {
  return quiz.dataService.updateQuizQuestion(quiz.documentPathParams, quiz.currentQKey$.getValue(),
    {'viewMode': QUESTION_VIEW_MODE.CLOUD_VIEW})
    .catch(e => quiz.common.log.error(e));
};

export const questionAbsoluteView = (quiz: QuizQuestionSheetComponent) => {
  return quiz.dataService.updateQuizQuestion(quiz.documentPathParams, quiz.currentQKey$.getValue(),
    {'viewMode': QUESTION_VIEW_MODE.ABSOLUTE})
    .catch(e => quiz.common.log.error(e));
};

export const questionPercentageView = (quiz: QuizQuestionSheetComponent) => {
  return quiz.dataService.updateQuizQuestion(quiz.documentPathParams, quiz.currentQKey$.getValue(),
    {'viewMode': QUESTION_VIEW_MODE.PERCENTAGE})
    .catch(e => quiz.common.log.error(e));
};
