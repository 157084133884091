import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[matBadgeIcon]'
})
export class MatBadgeIconDirective implements OnInit {

  badge;

  matBadgeOffsetTopValue = 5;
  @Input() set matBadgeOffsetTop(value) {
    this.matBadgeOffsetTopValue = value != null ? value : this.matBadgeOffsetTopValue;
    if (this.badge) {
      this.badge.style.top = this.matBadgeOffsetTopValue + 'px';
    }
  }
  matBadgeIconValue: string;
  @Input() set matBadgeIcon(value) {
    this.matBadgeIconValue = value;
    if (this.badge) {
      this.badge.style.top = this.matBadgeOffsetTopValue + 'px';
      this.badge.innerHTML = `<i class="material-icons" style="font-size: 16px">${value}</i>`;
    }
  }

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.badge = this.el.nativeElement.querySelector('.mat-badge-content');
      // badge.style.display = 'flex';
    this.badge.style.alignItems = 'center';
    this.badge.style.justifyContent = 'center';
    this.badge.style.top = this.matBadgeOffsetTopValue + 'px';
    this.badge.innerHTML = `<i class="material-icons" style="font-size: 16px">${this.matBadgeIconValue}</i>`;
  }
}
