// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-button.mr-auto {
  margin-right: auto;
}

.printHeader {
  gap: 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.filter-speaker-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 1rem;
}
.filter-speaker-list .active {
  border: 1px solid red !important;
}

.margin-bottom {
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/export-print-dialog/export-print-select-section-dialog/export-print-select-section-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,QAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,QAAA;EACA,mBAAA;AACF;AAAE;EACE,gCAAA;AAEJ;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".mat-mdc-button.mr-auto {\n  margin-right: auto;\n}\n\n.printHeader {\n  gap: 5px;\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n}\n\n.filter-speaker-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  margin-bottom: 1rem;\n  .active {\n    border: 1px solid red !important;\n  }\n}\n\n.margin-bottom {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
