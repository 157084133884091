import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientTermsAndPrivacyComponent} from './client-terms-and-privacy.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../core/core.module';
import {HttpClient} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [
    ClientTermsAndPrivacyComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  exports: [
    ClientTermsAndPrivacyComponent
  ]
})
export class ClientTermsAndPrivacyModule { }
