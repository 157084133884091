import { widthChangeAnim } from './../../animations/animations';
import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoginService} from '../../login/login.service';
import {ClientConfig} from '../../model/ClientConfig';
import {NavigationEnd, Router, RouterEvent, RouterLink, RouterLinkActive} from '@angular/router';
import {filter, Subject, take } from 'rxjs';
import {CommonService} from '../../core/common.service';
import {MatDialog} from '@angular/material/dialog';
import {GetSupportDialogComponent} from '../../modules/get-support-dialog/get-support-dialog.component';
import {TermsAndPrivacyDialogComponent} from '../../modules/terms-and-privacy-dialog/terms-and-privacy-dialog.component';
import * as data from '../../actions/data';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {RootLocalStorageService} from '../../core/root-local-storage.service';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {SessionStorageService} from '../../core/session-storage.service';
import {version as appVersion} from '../../../environments/appVersion';
import {EventCreateComponent} from '../../event-create/event-create/event-create.component';
import {FeaturesService} from '../../core/features.service';
import {AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage, NgStyle} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {ExamDataService} from '../../exams/exam/exam-service/exam-data.service';

@Component({
  standalone: true,
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  animations: [widthChangeAnim],
  imports: [
    AsyncPipe,
    MatMenuTrigger,
    MatIconButton,
    NgIf,
    MatIcon,
    TranslateModule,
    MatMenu,
    MatButton,
    NgClass,
    RouterLink,
    RouterLinkActive,
    NgForOf,
    NgStyle,
    NgOptimizedImage
  ]
})
export class MainMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() clientConfig: ClientConfig;
  _unsubscribeAll: Subject<any> = new Subject();
  menuItemFocused = 0;
  hiddenMenuItems = {};
  isDebugMode = false;
  timelineAboutURL = '';
  showTermsAndPrivacy = false;
  lastOpened: { img: string, name: string, link: string, isHttp: boolean; }[] = [];
  @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger;
  appVersion = appVersion;
  onChange: Subject<boolean> = new Subject<boolean>();

  usefulLinks: { title: string, link: string; }[] = [];
  usefulLinkIcon = ['tl-info', 'tl-report', 'tl-support'];
  openedMyNotes = false;
  loaded = false;

  isMobile$ = this.common.isMobile$();
  searchOpened$ = this.common.searchOpened$;

  isSuperadminOrAdmin = this.loginService.isSuperadmin() || this.loginService.isAdmin();

  isProduction = false;

  addIsHttp = (item) => {
    if (!('isHttp' in item)) {
      return {
        ...item,
        isHttp: item.img.includes('http')
      };
    }
    return item;
  }

  constructor (private router: Router,
    private common: CommonService,
    private dialog: MatDialog,
    private store: Store<fromRoot.State>,
    public loginService: LoginService,
    private localStorageService: RootLocalStorageService,
    private sessionStorage: SessionStorageService,
    private featuresService: FeaturesService,
    private examDataService: ExamDataService) {
    this.isProduction = this.common.getEnv().production;
    this.hiddeMenuItems();
    this.isDebugMode = this.common.getEnv().debug && this.common.getEnv().debug.console;
    this.timelineAboutURL = this.common.utils.getEnv()['timelineAboutURL'];
    this.showTermsAndPrivacy = this.common.utils.getEnv()['showTermsAndPrivacy'];
    this.router.events
      .pipe(filter(it => it instanceof NavigationEnd))
      .subscribe((value) => {
        let url = (<RouterEvent>value).url;
        this.openedMyNotes = url?.split('/').reverse()[0] === 'notes';
        if (url && url.includes('?')) {
          url = url.split('?')[0];
        }
        if (this.loginService.urlHelper.getValue() !== url) {
          this.loginService.urlHelper.next(url);
        }
        this.lastOpened = JSON.parse(this.localStorageService.get('last_opened', '[]')).map(this.addIsHttp);
        this.loaded = true;
      });
    this.lastOpened = JSON.parse(this.localStorageService.get('last_opened', '[]')).map(this.addIsHttp);
    this.usefulLinks = this.loginService.clientConfig$.getValue().usefulLinks || [];
  }

  ngOnInit(): void {
    this.loaded = this.router.url?.split('/').reverse()[0] === 'dashboard';
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
  }


  ngAfterViewInit(): void {
    this.matMenu.menuOpened
      .subscribe(value => {
        this.common.setBlurBackground(true);
      });
    this.matMenu.menuClosed
      .subscribe(value => {
        this.common.setBlurBackground(false);
        this.onChange.next(true);
      });
  }

  get requiredVerification() {
    return this.loginService.requiredVerification;
  }

  get isLogged() {
    return this.loginService.signedIn;
  }

  get hasSpecialRights() {
    return this.loginService.isAdmin() ||
      this.loginService.isModerator() ||
      this.loginService.isSimpleRegistration() ||
      this.loginService.isTrainer();
  }

  get isGuest() {
    return this.loginService.getAppUser()?.guest;
  }

  openDialog(component) {
    this.onChange
      .pipe(take(1))
      .subscribe((value) => {
        if (!!value) {
          this.dialog.open(component);
        }
      });
  }

  openAllEvents() {
    this.menuItemFocused = 3;
    this.sessionStorage.set('dashboard', 'my');
    this.router.navigate(['/dashboard']);
  }

  openMyTime() {
    this.menuItemFocused = 4;
    this.loginService.redirectTo('/my-time');
  }

  createNewEvent() {
    this.menuItemFocused = 5;
    this.openDialog(EventCreateComponent);
  }

  openMyKnowledge() {
    this.menuItemFocused = 13;
    this.loginService.redirectTo('/my/notes');
  }

  openMyExams() {
    this.menuItemFocused = 14;
    this.loginService.redirectTo('/user-exams');
  }

  printDBLogs() {
    this.common.log.printDBLog();
  }

  get isManageRights() {
    return this.loginService.isSuperadmin() || this.loginService.isManage();
  }

  get isAdminRights() {
    return this.loginService.isSuperadmin() || this.loginService.isAdmin();
  }

  get isSuperadmin() {
    return this.loginService.isSuperadmin();
  }

  get isAdminModeratorRights() {
    return this.loginService.isSuperadmin() || this.loginService.isAdmin() || this.loginService.isModerator();
  }

  openAbout() {
    window.open(this.timelineAboutURL);
  }

  openBugReportDialog() {
    const dialogRef = this.dialog.open(GetSupportDialogComponent, {
      panelClass: 'report-bug-get-support-dialog-panel',
      width: '450px',
      disableClose: true,
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  openTermsPrivacyDialog() {
    if (!this.showTermsAndPrivacy) {
      return;
    }
    const dialogRef = this.dialog.open(TermsAndPrivacyDialogComponent, {
      panelClass: 'report-bug-get-support-dialog-panel',
      width: '450px',
      disableClose: true,
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new data.SetCurrentUserAction(result));
      }
    });
  }

  navigateTo(link: string) {
    this.router.navigate(['event', link]);
  }

  deleteOpened(e, item) {
    e.stopPropagation();
    const lastOpenedJson = this.localStorageService.get('last_opened', '[]');
    const lastOpened: { img: string, name: string, link: string, isHttp: boolean; }[] = JSON.parse(lastOpenedJson).map(this.addIsHttp);
    const idx = lastOpened.findIndex(it => it.link === item.link);
    if (idx !== -1) {
      lastOpened.splice(idx, 1);
      this.localStorageService.set('last_opened', JSON.stringify(lastOpened));
      this.lastOpened = lastOpened;
    }
  }

  clearAllViewed() {
    this.localStorageService.remove('last_opened');
    this.lastOpened = [];
  }

  openUsefulLink(link: string): void {
    window.open(link, '_blank');
  }

  hiddeMenuItems() {
    this.hiddenMenuItems = this.common.utils.getEnv().hiddenMenuItems ? this.common.utils.getEnv().hiddenMenuItems : {};
    if (!this.featuresService.isMyKnowledgeEnabled()) {
      this.hiddenMenuItems['myKnowledge'] = true;
    }
    if (!this.featuresService.isQuestionCardsEnabled()) {
      this.hiddenMenuItems['questionCards'] = true;
    }
    if (!this.featuresService.isExamsEnabled()) {
      this.hiddenMenuItems['exams'] = true;
      this.hiddenMenuItems['my-exams'] = true;
    } else {
      this.hiddenMenuItems['my-exams'] = true;
      if (!this.isManageRights) {
        this.examDataService.getUserExamsCount(this.loginService.getAppUser().userId)
          .then(examsCount => {
            this.hiddenMenuItems['my-exams'] = !examsCount;
          });
      }
    }
  }
}
