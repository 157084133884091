// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  background-color: white;
  border-radius: 10px;
  margin: 1em;
  padding: 1em;
  box-sizing: border-box;
  width: calc(100% - 2em);
  height: calc(100% - 2em);
  overflow-y: auto;
}
.container h3 {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/terms-and-privacy/privacy-policy/privacy-policy.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,wBAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;AAEJ","sourcesContent":[".container {\n  background-color: white;\n  border-radius: 10px;\n  margin: 1em;\n  padding: 1em;\n  box-sizing: border-box;\n  width: calc(100% - 2em);\n  height: calc(100% - 2em);\n  overflow-y: auto;\n  h3 {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
