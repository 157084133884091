import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import {RouterModule, Routes} from '@angular/router';
import {CanActivateAuthGuard} from '../login/can-activate.authguard';
import { EventCardComponent } from './event-card/event-card.component';
import {MatCardModule} from '@angular/material/card';
import { DashboardToolbarComponent } from './dashboard-toolbar/dashboard-toolbar.component';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../core/core.module';
import {HttpClient} from '@angular/common/http';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {DirectivesModule} from '../directives/directives.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UserAvatarComponent} from '../user-avatar/user-avatar.component';
import {MatMenuModule} from '@angular/material/menu';
import {LoadingProgressModule} from '../components/loading-progress/loading-progress.module';
import { MatBadgeModule } from '@angular/material/badge';

const routes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [CanActivateAuthGuard]}
];

@NgModule({
  declarations: [
    DashboardComponent,
    EventCardComponent,
    DashboardToolbarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    UserAvatarComponent,
    DirectivesModule,
    MatTooltipModule,
    MatMenuModule,
    LoadingProgressModule,
    MatBadgeModule,
    NgOptimizedImage
  ],
    exports: [
      EventCardComponent
    ]
})
export class DashboardModule { }
