import {Component, Injector, OnDestroy} from '@angular/core';
import {MonoTypeOperatorFunction, Subject, takeUntil} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Component({
  selector: 'app-std',
  template: ''
})
export abstract class StdComponent implements OnDestroy {
  protected unsubscribeAll: Subject<any> = new Subject();
  mutationObservers: MutationObserver[] = [];

  protected constructor(protected injector: Injector) {
    const loginService = this.injector.get(AngularFireAuth);
    loginService.authState
      .pipe(this.takeUntilAlive())
      .subscribe(user => {
        if (!user) {
          this.unsubscribeAll.next(true);
          this.unsubscribeAll.complete();
        }
      });
  }

  ngOnDestroy(): void {
    this.mutationObservers.forEach(mo => mo.disconnect());
    this.unsubscribeAll.next(true);
    this.unsubscribeAll.complete();
    this.onDestroy();
  }

  takeUntilAlive<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.unsubscribeAll);
  }

  onDestroy(): void {
    //
  }
}
