import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {BehaviorSubject} from 'rxjs';
import {EventQuestion, IGapAnswerData, IGapResultDataMap} from '../../../../../../../model/EventQuestion';
import {merge} from 'lodash';

@Component({
  selector: 'app-question-checkbox-gap-filling-results',
  templateUrl: './question-checkbox-gap-filling-results.component.html',
  styleUrls: ['./question-checkbox-gap-filling-results.component.scss']
})
export class QuestionCheckboxGapFillingResultsComponent extends AbstractQuizQuestionResultsComponent {

  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapResultData$ = new BehaviorSubject<IGapResultDataMap>({});
  gapTaskText$ = new BehaviorSubject<string>(null);
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    this.gapAnswersData$
      .next(this.question.showCorrectAnswers || this.isPresenter || (!this.question.showCorrectAnswers && !this.editorMode) ?
        (this.question.correctEquality || []).map(v => new Object({id: v.id, answerId: v.answerId,
          answerValue: v.answerValue,
          answerCaption:
            this.question.items.find(a => a.id === v.answerId)?.getAnswerByLanguage(this.languageParams)}) as IGapAnswerData)
        : []);
    this.gapResultData$
      .next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.groupCheckboxGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$.next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
  }

  private groupCheckboxGapFillingSummaryAnswers(question: EventQuestion,
                                                        qContentAnswers: {[answerEquality: string]: number}) {
    const result = (question.correctEquality || []).reduce((acc, eq) => {
      acc = Object.assign(acc, {[eq.id]: 0});
      return acc;
    }, {});
    return merge(result, qContentAnswers);
  }

}
