import {Component, ElementRef, Inject, Injector, OnInit} from '@angular/core';
import {BehaviorSubject, filter} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../core/common.service';
import {UtilsService} from '../../core/utils.service';
import {cloneDeep} from 'lodash';
import {Constants, TRIPLE} from '../../core/constants';
import {StdComponent} from '../../core/std-component';
import {Exam} from '../exam-model/exam';
import {ExamUser} from '../exam-model/exam-user';
import {MatTableDataSource} from '@angular/material/table';
import {EXAM_LAUNCH_TYPE} from '../exam-constants/exam-constants';

enum SETTINGS_PAGE {
  GENERAL = 'general',
  PERMISSION = 'permission',
  COVER = 'cover',
  USER_MANAGEMENT = 'user_management',
  ASSESSMENT_SETTINGS = 'assessment_settings',
  LAUNCH_SETTINGS = 'launch_settings'
}

const DIALOG_HEIGHT = '700px';
const DIALOG_WIDTH = '800px';

@Component({
  selector: 'app-exam-settings-dialog',
  templateUrl: './exam-settings-dialog.component.html',
  styleUrl: './exam-settings-dialog.component.scss'
})
export class ExamSettingsDialogComponent extends StdComponent implements OnInit {
  readonly SETTINGS_PAGE = SETTINGS_PAGE;

  exam: Exam;

  dataHash: string;
  isModify = false;
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };
  activeSettings: SETTINGS_PAGE = SETTINGS_PAGE.GENERAL;
  examUsersDataSource: MatTableDataSource<TableRowExamUser> = new MatTableDataSource([]);
  userManagementLoaded = false;
  isModifyUserList = false;
  enableAllStatusItems = false;
  isProduction = false;
  examLaunchCodeValue: string[] = []; // includes edited value and source value

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<ExamSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private elementRef: ElementRef,
              private common: CommonService) {
    super(injector);
    this.isProduction = this.common.getEnv().production;
    dialogRef.addPanelClass('timeline');
    dialogRef.addPanelClass('questions-participants-detail');
    dialogRef.updateSize(DIALOG_WIDTH);
    this.elementRef.nativeElement.style.setProperty('--mdc-dialog__height', DIALOG_HEIGHT);
    dialogRef.disableClose = true;
    this.exam = UtilsService.wrapObjectToProxy(cloneDeep(data.exam), this.dataChangesHandler);
    this.examLaunchCodeValue = UtilsService.wrapObjectToProxy(
      [data.examLaunchCode ?? null, data.examLaunchCode ?? null], this.dataChangesHandler);
    this.dataHash = UtilsService.md5(UtilsService.jsonSorted(data.exam));
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get examLaunchCode() {
    return this.examLaunchCodeValue[0];
  }

  set examLaunchCode(value) {
    this.examLaunchCodeValue[0] = value;
  }

  ngOnInit(): void {
    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.exam)) ||
          this.examLaunchCodeValue[0] !== this.examLaunchCodeValue[1];
      });
  }

  onOkClick(): void {
    this.dialogRef.close({exam: cloneDeep(this.exam),
      examLaunchCode: this.examLaunchCode ?? null,
      addedUsers: this.examUsersDataSource.data.filter(u => u.added).map(u => new ExamUser(u)),
      deletedUsers: this.examUsersDataSource.data.filter(u => u.deleted).map(u => new ExamUser(u))});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  validate() {
    if (this.exam.examLaunchType === EXAM_LAUNCH_TYPE.START_BY_CODE && !this.examLaunchCode) {
      return false;
    }
    return !!this.exam.title;
  }
}

export class TableRowExamUser extends ExamUser {
  checked: boolean;
  added: boolean;
  deleted: boolean;

  constructor(obj) {
    super(obj);
    this.checked = obj.checked ?? false;
    this.added = obj.added;
    this.deleted = obj.deleted;
  }
}
