import {Injectable} from '@angular/core';
import {ExamApiService} from './exam-api.service';
import {ExamSection} from '../../exam-model/exam-section';
import {CONTAINER_ITEM_TYPE, ContentContainer} from '../../../model/content/ContentContainer';
import {Exam} from '../../exam-model/exam';
import {
  EXAM_ASSESSMENT_STATUS,
  EXAM_CLIPBOARD_TYPE,
  IExplanation,
  USER_EXAM_ACTION,
  USER_EXAM_STATE
} from '../../exam-constants/exam-constants';
import {firstValueFrom, map, Observable} from 'rxjs';
import {isEmpty} from 'lodash';
import {ExamUser} from '../../exam-model/exam-user';
import {Quiz} from '../../../modules/content-container/components/quiz/quiz-model/quiz';
import {
  questionUseCheckingCorrectnessOfAnswer
} from '../../../modules/content-container/components/quiz/quiz-components/shared/lib/quiz-question-common-lib';
import {APP_MODE} from '../../../login/login.service';
import {IDocumentPathParams} from '../../../services/event-mode-api.service';
import {AppUser} from '../../../model/AppUser';

@Injectable({
  providedIn: 'root'
})
export class ExamDataService {

  constructor(private examApiService: ExamApiService) { }

  generateNewDocumentId() {
    return this.examApiService.generateNewDocumentId();
  }

  getExam(examId: string) {
    return this.examApiService.getExam(examId);
  }

  loadExam(examId: string) {
    return this.examApiService.loadExam(examId);
  }

  loadExams() {
    return this.examApiService.loadExams();
  }

  saveExam(exam: Exam) {
    return this.examApiService.saveExam(exam);
  }

  deleteExam(examId: string) {
    return this.examApiService.deleteExam(examId);
  }

  loadExamContentsMap(examId: string) {
    return this.examApiService.loadExamContentsMap(examId);
  }

  loadExamSections(examId: string) {
    return this.examApiService.loadExamSections(examId);
  }

  loadExamSectionContents(examId: string, sectionId: string) {
    return this.examApiService.loadExamSectionContents(examId, sectionId);
  }

  loadExamSectionAttendeesStatisticsByContents(examId: string, sectionId: string) {
    return this.examApiService.loadExamSectionAttendeesStatisticsByContents(examId, sectionId);
  }

  loadExamSectionAttendeeStatisticsByContents(examId: string, sectionId: string, userId: string) {
    return this.examApiService.loadExamSectionAttendeeStatisticsByContents(examId, sectionId, userId);
  }

  loadExamSectionContentsStatistics(examId: string, sectionId: string) {
    return this.examApiService.loadExamSectionContentsStatistics(examId, sectionId);
  }

  loadExamSectionsAssessmentStatistics(examId: string) {
    return this.examApiService.loadExamSectionsAssessmentStatistics(examId);
  }

  loadExamAssessmentStatistics(examId: string) {
    return this.examApiService.loadExamAssessmentStatistics(examId);
  }

  saveExamSection(section: ExamSection) {
    return this.examApiService.saveExamSection(section);
  }

  deleteExamSection(section: ExamSection) {
    return this.examApiService.deleteExamSection(section);
  }

  saveContent(content: ContentContainer) {
    return this.examApiService.saveContent(content);
  }

  updateOrderIndex(content: ContentContainer, orderIndex: number) {
    return this.examApiService.updateOrderIndex(content, orderIndex);
  }

  updatePoints(content: ContentContainer) {
    return this.examApiService.updatePoints(content);
  }

  updateStatus(content: ContentContainer, status: string) {
    return this.examApiService.updateStatus(content, status);
  }

  deleteContent(content: ContentContainer) {
    return this.examApiService.deleteContent(content);
  }

  pasteContent(examId: string, sectionId: string, content: ContentContainer, orderIndex: number, pasteType: EXAM_CLIPBOARD_TYPE) {
    return this.examApiService.pasteContent(examId, sectionId, content, orderIndex, pasteType);
  }

  pasteSection(examId: string, parentId: string, content: ExamSection, orderIndex: number, pasteType: EXAM_CLIPBOARD_TYPE) {
    return this.examApiService.pasteSection(examId, parentId, content, orderIndex, pasteType);
  }

  loadExamSectionAdditionContent(section: ExamSection): Observable<ContentContainer> {
    return this.examApiService.loadExamSectionAdditionContents(section)
      .pipe(map(list => !isEmpty(list) ? list.sort((a, b) => a.orderIndex < b.orderIndex ? -1 : 1)[0] : null));
  }

  saveSectionAdditionContent(content: ContentContainer, section: ExamSection) {
    return this.examApiService.saveSectionAdditionContent(content, section);
  }

  deleteSectionAdditionContent(content: ContentContainer) {
    return this.examApiService.deleteSectionAdditionContent(content);
  }

  userStartPauseExam(exam: Exam, state: USER_EXAM_STATE) {
    return this.examApiService.userStartPauseExam(exam, state);
  }

  getUserExamTaskRunningTime(exam: Exam) {
    return this.examApiService.getUserExamTaskRunningTime(exam);
  }

  loadUserExamAnswersMap(examId: string, userId: string) {
    return this.examApiService.loadUserExamAnswersMap(examId, userId);
  }

  loadExamLaunchCodes(examId: string) {
    return this.examApiService.loadExamLaunchCodes(examId);
  }

  saveExamLaunchCode(examId: string, entityId: string, code: string) {
    return this.examApiService.saveExamLaunchCode(examId, entityId, code);
  }

  launchExamByCodeCode(examId: string, code: string) {
    return this.examApiService.launchExamByCodeCode(examId, code);
  }

  loadingAndApplyLazyFields(content: ContentContainer) {
    return this.examApiService.loadingAndApplyLazyFields(content);
  }

  loadExamUsers(examId: string) {
    return this.examApiService.loadExamUsers(examId);
  }

  addUsersToExam(exam: Exam, users: ExamUser[]) {
    return this.examApiService.addUsersToExam(exam, users);
  }

  deleteUsersFromExam(examId: string, users: ExamUser[]) {
    return this.examApiService.deleteUsersFromExam(examId, users);
  }

  loadUserExams(userId: string): Observable<string[]> {
    return this.examApiService.loadUserExams(userId);
  }

  getExamUser(examId: string, userId: string) {
    return this.examApiService.getExamUser(examId, userId);
  }

  loadUserContentAssessments(content: ContentContainer, user: ExamUser) {
    return this.examApiService.loadUserContentAssessments(content.eventId, content.id, user.userId);
  }

  saveUserContentAssessmentsExplanation(content: ContentContainer, userId: string, explanation: IExplanation) {
    return this.examApiService.saveUserContentAssessmentsExplanation(content, userId, explanation);
  }

  loadUserContentAssessmentsHistory(content: ContentContainer, user: ExamUser) {
    return this.examApiService.loadUserContentAssessmentsHistory(content.eventId, content.id, user.userId);
  }

  async autoAssessContent(content: ContentContainer, user: ExamUser, inspector: AppUser, description: string) {
    const attendeeAnswers = (snapshot) => {
      const answers = {};
      if (snapshot) {
        for (const qKey of Object.keys(snapshot)) {
          if (qKey !== 'userId') {
            if (!answers[qKey]) {
              answers[qKey] = {};
              answers[qKey][user.userId] = snapshot[qKey].answers;
            }
          }
        }
      }
      return answers;
    };

    let assessContent = await firstValueFrom(this.examApiService.loadExamContent(content.eventId, content.parentId, content.id));
    if (!assessContent) {
      throw new Error('Content not found');
    }
    const assessmentObject = {};
    assessContent = await this.examApiService.loadContentQuizzesAnswersGroups(assessContent);
    const items = assessContent.items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
    for (const item of items) {
      const pathParams: IDocumentPathParams = {...this.examApiService.getContentDocumentPathParams(content), containerId: item.id};
      assessmentObject[item.id] = {};
      const quiz = new Quiz(item.data);
      const answers = attendeeAnswers(await firstValueFrom(this.examApiService.loadQuizQuestionsAnswersByUser(user.userId, pathParams)));
      for (const question of Object.values(quiz.questions)) {
        if (questionUseCheckingCorrectnessOfAnswer(question)) {
          question.answers = answers[question.id];
          const check = await question.checkCorrectUserAnswers(user.userId, {...pathParams, questionId: question.id}, APP_MODE.EXAMS,
            this.examApiService.getContentContainerDataService());
          assessmentObject[item.id][question.id] = check?.checkResult ? question.points : 0;
        }
      }
    }
    return this.examApiService.setContentAssessment(content, assessmentObject, user, inspector,
      EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED, description, null, null, null);
  }

  async assessContent(assessmentObject, content: ContentContainer, user: ExamUser, inspector: AppUser,
                      status: EXAM_ASSESSMENT_STATUS, statusChangeDescription: string,
                      assessmentChangeDescription: string, assessmentFrom: any[], assessmentTo: any[]) {
    return this.examApiService.setContentAssessment(content, assessmentObject, user, inspector, status, statusChangeDescription,
      assessmentChangeDescription, assessmentFrom, assessmentTo);
  }

  saveContentAssessmentMessageHistory(content: ContentContainer,
                                      message: any, user: ExamUser, inspector: AppUser) {
    return this.examApiService.saveContentAssessmentMessageHistory(content, message, user, inspector);
  }

  getUserExamsCount(userId: string) {
    return this.examApiService.getUserExamsCount(userId);
  }

  increaseExamDurationForUser(examId: string, userId: string, duration: number) {
    return this.examApiService.increaseExamDurationForUser(examId, userId, duration);
  }

  executeExamActionForUser(examId: string, userId: string, action: USER_EXAM_ACTION, payload?: any) {
    return this.examApiService.executeExamActionForUser(examId, userId, action, payload);
  }

  updateExamUserPoints(examId: string, userId: string, pointsField: string, value: any) {
    return this.examApiService.executeExamActionForUser(examId, userId, USER_EXAM_ACTION.UPDATE_POINTS, {pointsField, value});
  }
}
