import {SectionContent} from './SectionContent';
import {Constants} from '../../core/constants';

export class SectionTimeline extends SectionContent {
  type = 'timeline';
  items: SectionTimeline[] = [];
  displayOnly;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }
    if (this.isSectionFixedStartDateNullValue(obj)) {
      obj.plannedTimeFixed = false;
    }
    if (this.isSectionFixedEndDateNullValue(obj)) {
      obj.endTimeFixed = false;
    }
    if (obj.items) {
      this.items = obj.items;
    }
    if (obj.displayOnly) {
      this.displayOnly = obj.displayOnly;
    }
  }

  private isSectionFixedStartDateNullValue(value: SectionTimeline) {
    return value.plannedTimeFixed && !value.plannedTimeFixedValue && !value.plannedTime;
  }

  private isSectionFixedEndDateNullValue(value: SectionTimeline) {
    return value.endTimeFixed && !value.endTimeFixedValue && !value.endTime;
  }

  mergeContent(src: SectionTimeline) {
    const vm = this;
    super.mergeContent(src, true);

    src.items.forEach(function (srcItem) {
      const icIdx = vm.items.findIndex(function (obj) {
        if (obj.id === srcItem.id) {
          return true;
        }
      });
      if (icIdx === -1) {
        vm.items.push(srcItem);
      } else {
        vm.items[icIdx].mergeContent(srcItem);
      }
    });
    const forRemove = [];
    for (let i = 0; i < this.items.length; i++) {
      const id = this.items[i].id;
      const idx = src.items.findIndex(function (obj) {
        if (obj.id === id) {
          return true;
        }
      });
      if (idx === -1) {
        forRemove.push(i);
      }
    }
    if (forRemove && forRemove.length) {
      forRemove.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      });
      for (let i = (forRemove.length - 1); i >= 0 ; i--) {
        this.items.splice(forRemove[i], 1);
      }
    }
    this.items.sort(this.comparator(Constants.ORDERINDEX));
  }

  public comparator(field, direction?) {
    return function (a, b) {
      if (!a.hasOwnProperty(field) && b.hasOwnProperty(field)) {return 1; }
      if (a.hasOwnProperty(field) && !b.hasOwnProperty(field)) {return -1; }
      if (!direction || direction === 'asc') {
        if (a[field] < b[field]) {return -1; }
        if (a[field] > b[field]) {return 1; }
      } else if (direction === 'desc') {
        if (a[field] > b[field]) {return -1; }
        if (a[field] < b[field]) {return 1; }
      }
      return 0;
    };
  }

  getSectionTimeIsNotActive() {
    if (!this.sectionTimeIsNotActive) {
      return false;
    } else if (this.container) {
      return this.sectionTimeIsNotActive;
    } else if (this.items && this.items.length === 0 && this.sectionTimeIsNotActive) {
      return true;
    } else if (this.items && this.items.length > 0 && this.sectionTimeIsNotActive) {
      return this.items.every(ch => ch.getSectionTimeIsNotActive());
    }
  }

  toObject() {
    const obj = super.toObject();
    delete obj.items;
    return obj;
  }
}
