import {APP_MODE, LoginService} from '../login/login.service';
import {LoggerService} from '../core/logger.service';
import {filter, map, merge, NEVER, Observable, tap, throwError} from 'rxjs';
import {UtilsService} from '../core/utils.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {BackendServerFailAction, FirestoreServerFailAction} from '../actions/data';
import {isEmpty} from 'lodash';
import firebase from 'firebase/compat/app';
import {trace} from '@angular/fire/compat/performance';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Reference} from '@angular/fire/compat/storage/interfaces';
import {Event} from '../model/Event';

export interface IRequestSection {
  eventId: string;
  sectionId: string;
}

export enum CHANGE_LOG_VALUE_TYPE {
  ADD = 'add',
  DELETE = 'delete',
  RELOCATE = 'relocate',
  EDITED = 'edited',
  CHANGE_SNIPPET = 'change-snippet',
}

export interface IChangeLogValue {
  sourceId: string;
  sourceType: string;
  changeType: CHANGE_LOG_VALUE_TYPE;
  title: string;
  parentId?: string;
  sourceIsDraft?: boolean;
  draftWithoutOriginal?: boolean;
}

export class StdApiService {
  protected REFERENCE_ID = {idField: '_key'};
  protected BACKEND_BASE: string;
  protected gapi: any;

  constructor(
    protected store: Store<fromRoot.State>,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected utils: UtilsService,
    protected angularFirestore: AngularFirestore,
    protected angularFireStorage: AngularFireStorage
  ) {
    if (this.utils) {
      this.BACKEND_BASE = this.utils.getEnv().BACKEND_BASE;
    }
  }

  generateNewDocumentId() {
    return this.angularFirestore.createId();
  }

  get clientStoragePath() {
    return 'client_data/' + this.auth.client_id$.getValue();
  }

  getClientStoragePath(clientId) {
    return `client_data/${clientId ?? this.auth.client_id$.getValue()}`;
  }

  get dataPath() {
    switch (this.auth.applicationMode) {
      case APP_MODE.TIMELINE:
        return Event.DB_TIMELINE_PATH;
      case APP_MODE.EDUCATION:
        return Event.DB_MODULE_TIMELINE_PATH;
      case APP_MODE.NOTES:
        return Event.DB_NOTES_PATH;
      case APP_MODE.QUESTION_CARDS:
        return Event.DB_QUESTION_CARDS;
    }
  }

  /**
   * path for different work mode. mode auto select "Timeline" or "Education" or "Notes"
   */
  get clientStorageDataPath() {
    return `${this.clientStoragePath}/${this.dataPath}`;
  }

  get afs(): AngularFirestoreDocument {
    return this.angularFirestore
      .collection('client_data')
      .doc(this.auth.client_id$.getValue());
  }

  getAfs(clientId): AngularFirestoreDocument {
    return this.angularFirestore
      .collection('client_data')
      .doc(clientId ?? this.auth.client_id$.getValue());
  }
  /**
   * path for different work mode. mode auto select "Timeline" or "Education"
   */
  get dataAfs(): AngularFirestoreCollection {
    return this.angularFirestore
      .collection('client_data')
      .doc(this.auth.client_id$.getValue())
      .collection(this.dataPath);
  }

  getStorageRef(path: string, clientId?: string): Reference {
    return this.angularFireStorage.storage.ref(`${this.getClientStoragePath(clientId)}/${path}`);
  }

  // todo quick fix until angularfire will be fixed. after refresh token (manual/hourly) all active subscriptions are emitted 2 times in row
  log = (collectionName: string) => <T>(source: Observable<T>) => {
    let skipNext = 0;
    let isFirst = true;
    return merge(
      this.auth.idTokenResult
        .pipe(map(() => {
          if (!isFirst) {
            skipNext += 2;
          }
          return 'skip';
        })),
      this.utils.getEnv().debug.trace ?
        source.pipe(
          filter(() => {
            isFirst = false;
            return skipNext ? !skipNext-- : true;
          }),
          trace(collectionName),
          tap((it: any) => {
            if (this.utils.getEnv().debug.console) {
              let iLogRecord;
              if (isEmpty(it)) {
                iLogRecord = {time: new Date().getTime(), collection: collectionName, reads: 0};
              } else if (it instanceof Array) {
                iLogRecord = {time: new Date().getTime(), collection: collectionName, reads: it.length};
              } else if (it instanceof firebase.firestore.QuerySnapshot) {
                iLogRecord = {time: new Date().getTime(), collection: collectionName, reads: it.docs.length};
              } else if (it.hasOwnProperty('payload')) {
                iLogRecord = {time: new Date().getTime(), collection: collectionName, reads: it.payload.exists ? 1 : 0};
              } else {
                iLogRecord = {time: new Date().getTime(), collection: collectionName, reads: 1};
              }
              if (!isEmpty(iLogRecord)) {
                this.logger.dbLog.push(iLogRecord);
                console.log(`Record ${this.logger.dbLog.length}`, iLogRecord);
              }
            }
          })
        ) : source.pipe(filter(() => {
          isFirst = false;
          return skipNext ? !skipNext-- : true;
        })))
      .pipe(filter(it => it !== 'skip'));
  }

  throwFirestoreError(err) {
    this.store.dispatch(FirestoreServerFailAction.of(err));
    return Promise.reject(err);
  }

  firestoreCatchError(err, methodName?: string) {
    this.store.dispatch(FirestoreServerFailAction.of(err));
    let error = err;
    if (methodName) {
      error = {
        message: err.message,
        stack: err.stack + ` [method=${methodName}]`,
        ...err
      };
    }
    return throwError(() => error);
  }

  catchServerError(err) {
    this.store.dispatch(BackendServerFailAction.of(err));
    // return NEVER;
    return Promise.reject(err);
  }

  catchServerErrorObservable(err) {
    this.store.dispatch(BackendServerFailAction.of(err));
    return NEVER;
  }

  get currentUserId() {
    return this.auth.getAuthUser().uid;
  }

  get currentUser() {
    return this.auth.getAuthUser();
  }

  get currentAppUser() {
    return this.auth.getAppUser();
  }

  protected isLoad() {
    return Promise.resolve(true);
/*
    return this.googleApiService.GetClient().then((gapi) => {
      this.gapi = gapi;
      return true;
    });
*/
  }

  isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
  }

  toObject(value) {
    if (this.isObject(value)) {
      Object.keys(value).forEach(key => value[key] === undefined && (value[key] = null));
      Object.keys(value).forEach(key => typeof value[key] === 'function' && delete value[key]);
      return value;
    } else {
      return value.toObject();
    }
  }

  protected api(func) {
    const self = this;
    if (!this.auth.signedIn) {
      return new Promise((resolve) => {
        resolve(null);
      });
    }
    return this.isLoad().then(() => this.auth.getAuthToken()).then((token) => {
      this.gapi.client.setToken({
        access_token: token
      });
      return func();
    }).catch((error) => {
      if (error && error.status === 401) {
        return this.auth.refreshToken().then(token => {
          if (token) {
            return self.api(func);
          } else {
            this.logger.error(JSON.stringify(error));
            throw error;
          }
        });
      } else if (error) {
        this.logger.error(JSON.stringify(error));
        throw error;
      }
    });
  }

  protected mapObject(obj: any, createItem: (item: any) => any) {
    if (!obj) {
      return obj;
    }
    if ((obj instanceof firebase.firestore.DocumentSnapshot && !obj.exists) ||
        (obj.payload instanceof firebase.firestore.DocumentSnapshot && !obj.payload.exists)) {
      return null;
    }
    return createItem(obj);
  }

  protected mapArray(obj: any, createItem: (item: any) => any) {
    if (!obj) {
      return obj;
    }
    const list = [];
    obj.forEach(it => list.push(createItem(it)));
    return list;
  }

  protected mapBoolean(obj: any): boolean {
    return !!obj;
  }

  protected mapString(obj: any): string {
    return obj ? obj.value : null;
  }

  protected booleanObject(value) {
    return {
      value: value
    };
  }

  protected stringObject(value) {
    return {
      value: value === undefined ? null : value
    };
  }

  saveChangeLogValue(eventId: string, value: IChangeLogValue) {
    const obj = {time: new Date(), userId: this.auth.getAppUser().userId, ...value};
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return this.auth.educationMode ?
      this.afs.collection(Event.DB_MODULE_TIMELINE_PATH)
        .doc(eventId)
        .collection('actions')
        .doc('changelog')
        .set({changelog: firebase.firestore.FieldValue.arrayUnion(obj)},
          {merge: true})
        .catch((err) => this.throwFirestoreError(err))
      :
      Promise.resolve();
  }
}
