import {computed, effect, Injectable, signal} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil
} from 'rxjs';
import {ExamSection, ExamSectionTreeItem} from '../../exam-model/exam-section';
import {ExamDataService} from './exam-data.service';
import {Constants, ILanguageParams, ILoadData, LOAD_STATE} from '../../../core/constants';
import {
  EXAM_ASSESSMENT_ANONYMOUS_DISABLED,
  EXAM_ASSESSMENT_STATUS,
  EXAM_ASSESSMENT_WORK_MODE,
  EXAM_CONTENT_STATUS,
  EXAM_MANAGEMENT_MODE,
  IAssessmentContentStatistics,
  IAssessmentContentStatisticsMap,
  IAssessmentUserStatisticsByContent,
  IExamClipboard,
  IExamContentsMap,
  IExamUserAnswersMap,
  PERCENT_ROUND_TO,
  SAVE_TO_POSITION,
  USER_EXAM_ACTION,
  USER_EXAM_STATE
} from '../../exam-constants/exam-constants';
import {CommonService} from '../../../core/common.service';
import {cloneDeep, isEmpty, max, pick} from 'lodash';
import {Event} from '../../../model/Event';
import {LANGUAGE} from '../../../core/language-constants';
import {TranslateApiService} from '../../../services/translate-api.service';
import {ContentContainer} from '../../../model/content/ContentContainer';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {Exam} from '../../exam-model/exam';
import {ExamSettingsDialogComponent} from '../../exam-settings-dialog/exam-settings-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LoginService} from '../../../login/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ExamRolesService} from '../exam-roles/exam-roles.service';
import {LanguageService} from '../../../services/language.service';
import {ExamComponent} from '../exam/exam.component';
import {ExamUser} from '../../exam-model/exam-user';
import {SessionStorageService} from '../../../core/session-storage.service';
import {ExamUserAssessmentsComponent} from '../exam-user-assessments/exam-user-assessments.component';
import {Title} from '@angular/platform-browser';
import {ExamManagementComponent} from '../exam-management/exam-management.component';

enum LOAD_DATA {
  EXAM = 'loadExam',
  EXAM_CONTENTS_MAP = 'loadExamContentsMap',
  EXAM_SECTIONS = 'loadExamSections',
  USER_ANSWERS_MAP = 'loadUserAnswersMap',
  EXAM_ATTENDEES = 'loadExamAttendees',
}

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  loaded$ = signal(false);
  process$ = signal(false);
  examManagementMode = signal<EXAM_MANAGEMENT_MODE>(EXAM_MANAGEMENT_MODE.RUN);
  examManagementMode$ = toObservable(this.examManagementMode);

  // to track the correct transition between operating modes
  mainComponent$ = new BehaviorSubject<ExamComponent | ExamUserAssessmentsComponent | ExamManagementComponent>(null);
  loadFromTimeline = signal(false);
  sectionContentsListLoaded$ = new BehaviorSubject<boolean>(false);

  currentExam = signal<Exam>(null);
  protected _exam$ = new BehaviorSubject<ILoadData<Exam>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  exam = toSignal(this._exam$.pipe(map(obj => obj.value)));
  protected _examContentMap$ = new BehaviorSubject<ILoadData<IExamContentsMap>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  protected _examUserAnswersMap$ = new BehaviorSubject<ILoadData<IExamUserAnswersMap>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  examContentsMap = signal<IExamContentsMap>(null);
  userAnswersMap = signal<IExamUserAnswersMap>(null);
  protected _examSections$ = new BehaviorSubject<ILoadData<ExamSection[]>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  sections = toSignal(this._examSections$.pipe(map(obj => obj.value)));
  protected _examSectionTree$ = new BehaviorSubject<ExamSectionTreeItem[]>([]);
  protected examSectionFlatTree: ExamSectionTreeItem[] = [];
  currentExamAdditionContent$ = new BehaviorSubject<ContentContainer>(null);
  currentExamSection$ = new BehaviorSubject<ExamSection>(null);
  currentExamSection = toSignal(this.currentExamSection$);
  currentSectionAttendeesStatisticsByContents$ = new BehaviorSubject<IAssessmentUserStatisticsByContent>(null);
  currentSectionAttendeesStatisticsByContents = toSignal(this.currentSectionAttendeesStatisticsByContents$);
  currentSectionContentsStatistics = signal<IAssessmentContentStatisticsMap>(null);
  sectionsAssessmentStatistics = signal<IAssessmentContentStatisticsMap>(null);
  examAssessmentStatistics = signal<IAssessmentContentStatistics>(null);
  examAssessmentStatistics$ = toObservable(this.examAssessmentStatistics);
  protected _examAttendees$ = new BehaviorSubject<ILoadData<ExamUser[]>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  examAttendees = toSignal(this.examAttendees$);
  attendeesCount = computed(() => this.examAttendees()?.length ?? 0);

  defaultLanguage = computed(() => this.languageService.DEFAULT_LANGUAGE());
  currentLanguage = signal<LANGUAGE>(null);
  multilingual = computed(() => this.currentExam()?.getMultilingual()?.multilingual);
  usedLanguages = computed(() => this.currentExam()?.getMultilingual()?.usedLanguages);

  languageParams = computed<ILanguageParams>(() => new Object({
    defaultLanguage: this.defaultLanguage(),
    currentLanguage: this.currentLanguage(),
    usedMultilingualContent: this.multilingual(),
    usedLanguages: this.usedLanguages()
  }) as ILanguageParams);
  translateHint = computed<string>(() => this.translateApiService.getTranslateHint(this.defaultLanguage(), this.currentLanguage()));
  userInterfaceLanguage = toSignal(this.languageService.userInterfaceLanguage$);

  openContentEditor$ = new BehaviorSubject<boolean>(false);
  currentExamSectionContentsList$ = new BehaviorSubject<ContentContainer[]>([]);
  currentContent$ = new BehaviorSubject<ContentContainer>(null);
  currentContent = toSignal(this.currentContent$);

  viewActiveStatus = signal<EXAM_CONTENT_STATUS>(EXAM_CONTENT_STATUS.DONE);
  viewActiveAssessmentStatus = signal<EXAM_ASSESSMENT_STATUS>(EXAM_ASSESSMENT_STATUS.AUTO_ASSESSED);

  clipboardContent = signal<IExamClipboard>(null);
  isClipboardTypeSection = computed(() => this.clipboardContent()?.content instanceof ExamSection);

  examUserManagementMode = signal<boolean>(false);
  examResultsMode = signal<boolean>(false);
  examAssessmentWorkMode = signal<EXAM_ASSESSMENT_WORK_MODE>(null);
  examAssessmentWorkMode$ = toObservable(this.examAssessmentWorkMode);
  examAssessmentAnonymousDisabled = signal<boolean>(false);
  currentAttendee = signal<ExamUser>(null);
  currentAttendee$ = toObservable(this.currentAttendee);
  currentAttendeeIndex = signal<number>(null);
  attendeeNavigator$ = new BehaviorSubject<number>(0);
  workModeSingleAttendee = computed(() => !this.examManagementMode() && this.examAssessmentWorkMode() &&
    ![EXAM_ASSESSMENT_WORK_MODE.OVERVIEW, EXAM_ASSESSMENT_WORK_MODE.ATTENDEE].includes(this.examAssessmentWorkMode()));

  private _unsubscribeAll = new Subject();
  private subscriptions: {
    [key: string]: {
      subscription?: Subscription,
      subject: BehaviorSubject<ILoadData<any>>
    }
  } = {
    [LOAD_DATA.EXAM]: {subject: this._exam$},
    [LOAD_DATA.EXAM_SECTIONS]: {subject: this._examSections$},
    [LOAD_DATA.EXAM_CONTENTS_MAP]: {subject: this._examContentMap$},
    [LOAD_DATA.USER_ANSWERS_MAP]: {subject: this._examUserAnswersMap$},
    [LOAD_DATA.EXAM_ATTENDEES]: {subject: this._examAttendees$}
  };

  public static percent(maxValue: number, value: number) {
    return !!maxValue && !!value ? Math.round((100 / (maxValue / value)) * PERCENT_ROUND_TO) / PERCENT_ROUND_TO : 0;
  }

  public static htmlPercentBackground(points: number, maxPoints: number, color: string, background: string, progressFn?: (value) => void) {
    const progress = ExamService.percent(maxPoints, points);
    if (progressFn) {
      progressFn(progress);
    }
    return 'linear-gradient(' + (progress < 50 ? 90 : 3.6 * progress - 270) +
      'deg, ' + (progress < 50 ? background : color) + ' 51%, transparent 50%, transparent), linear-gradient(' +
      (progress < 50 ? 90 + 3.6 * progress : 270) +
      'deg, ' + color + ' 51%, ' + background + ' 50%, ' + background + ')';
  }


  constructor(private loginService: LoginService,
              public examDataService: ExamDataService,
              public languageService: LanguageService,
              private translateApiService: TranslateApiService,
              public common: CommonService,
              private dialog: MatDialog,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public rolesService: ExamRolesService,
              public sessionStorageService: SessionStorageService,
              private browserTabTitle: Title) {
    effect(() => {
      if (this.common.utils.getEnv().debug.console) {
        if (!!this.currentExamSection()) {
          console.log(`%cSelected section%c`, 'color: red', 'color: black', this.currentExamSection());
        }
        if (!!this.currentContent()) {
          console.log(`%cSelected content%c`, 'color: red', 'color: black', this.currentContent());
        }
      }
      if (this.examUserManagementMode() && this.exam()) {
        this.browserTabTitle
          .setTitle(`${this.common.i18n('browser.tab.title.exam.user.management')} | ${this.exam().title}`);
      }
      if (this.examResultsMode() && this.exam()) {
        this.browserTabTitle
          .setTitle(`${this.common.i18n('browser.tab.title.exam.results')} | ${this.exam().title}`);
      }
      if (!this.examUserManagementMode() && !this.loadFromTimeline() && this.exam() && !this.workModeSingleAttendee()) {
        const staticTitle = this.examAssessmentWorkMode() ?
          this.common.i18n('browser.tab.title.exam.assessment') : this.common.i18n('browser.tab.title.exam.editor');
        this.browserTabTitle.setTitle(`${staticTitle} | ${this.exam().title}`);
      }
    });
  }

  get currentUser() {
    return this.loginService.getAppUser();
  }

  setWorkMode(examManagementMode: EXAM_MANAGEMENT_MODE | null, examAssessmentWorkMode: EXAM_ASSESSMENT_WORK_MODE | null = null) {
    const managementModeChanged = examManagementMode !== this.examManagementMode();
    this.examManagementMode.set(examManagementMode);
    this.examAssessmentWorkMode.set(examAssessmentWorkMode);
    this.examUserManagementMode.set(examManagementMode === EXAM_MANAGEMENT_MODE.RUN);
    this.examResultsMode.set(examManagementMode === EXAM_MANAGEMENT_MODE.RESULTS);
    if (managementModeChanged) {
      this.attendeeNavigator$.next(0);
    }
  }

  unsubscribe() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
    Object.keys(this.subscriptions)
      .forEach(it => {
        this.setSubscription(<LOAD_DATA> it, null);
      });
  }

  unsubscribeAll() {
    this.unsubscribe();
    this.resetServiceValues();
    this.rolesService.destroy();
  }

  private resetServiceValues() {
    if (this.loadFromTimeline()) {
      this.setWorkMode(null);
    }
    this.clipboardContent.set(null);
    this.openContentEditor$.next(false);
    this.currentExam.set(null);
    this.loaded$.set(false);
    this.sectionContentsListLoaded$.next(false);
    this.currentContent$.next(null);
    this.currentExamSection$.next(null);
    this.currentSectionAttendeesStatisticsByContents$.next(null);
    this.currentSectionContentsStatistics.set(null);
    this.examAssessmentStatistics.set(null);
    this.sectionsAssessmentStatistics.set(null);
    this.examSectionFlatTree = [];
    this._examSectionTree$.next([]);
    this.currentExamSectionContentsList$.next([]);
    this.viewActiveStatus.set(EXAM_CONTENT_STATUS.DONE);
    this.currentAttendee.set(null);
    this.currentAttendeeIndex.set(null);
    this.attendeeNavigator$.next(0);
  }

  private setSubscription(name: LOAD_DATA, sub: Subscription) {
    if (this.subscriptions[name]) {
      if (this.subscriptions[name].subscription) {
        this.subscriptions[name].subscription.unsubscribe();
      }
      this.subscriptions[name].subscription = sub;
      if (!sub && this.subscriptions[name].subject.getValue().loaded !== LOAD_STATE.NOT_LOADED) {
        this.subscriptions[name].subject.next({loaded: LOAD_STATE.NOT_LOADED, value: null});
      }
    }
  }

  getExam(examId: string) {
    return this.examDataService.getExam(examId);
  }

  async loadData(examId: string, loadFromTimeline?: boolean) {
    // wait OnDestroy MainComponent
    await firstValueFrom(this.mainComponent$.pipe(filter(v => !v)));
    this.loadFromTimeline.set(!!loadFromTimeline);
    this.unsubscribeAll();
    const loadList: Observable<any>[] = [];
    this.initObservers();
    if (this._exam$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExam(examId);
      loadList.push(this.exam$);
    }
    if (this._examContentMap$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExamContentsMap(examId);
      loadList.push(this.examContentsMap$);
    }
    if (this._examSections$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExamSections(examId);
      loadList.push(this.examSections$);
    }
    if (this.loadFromTimeline() && this._examUserAnswersMap$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExamUserAnswersMap(examId);
      loadList.push(this.examContentsMap$);
    }
    if (this.examManagementMode() && this._examAttendees$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExamAttendees(examId);
      loadList.push(this.examAttendees$);
    }
    if (((!this.loadFromTimeline() && this.examAssessmentWorkMode() && this.workModeSingleAttendee()) || this.loadFromTimeline()) &&
      this._examAttendees$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadExamAttendee(examId, this.currentUser.userId);
      loadList.push(this.examAttendees$);
    }
    return loadList.length ? firstValueFrom(combineLatest(loadList)) : Promise.resolve([]);
  }

  protected loadExam(examId: string) {
    this._exam$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadExam(examId)
      .subscribe(value => {
        this._exam$.next({
          loaded: LOAD_STATE.LOADED,
          value: value});
      });
    this.setSubscription(LOAD_DATA.EXAM, sub);
  }

  protected loadExamContentsMap(examId: string) {
    this._examContentMap$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadExamContentsMap(examId)
      .subscribe(value => {
        this._examContentMap$.next({
          loaded: LOAD_STATE.LOADED,
          value: value});
      });
    this.setSubscription(LOAD_DATA.EXAM_CONTENTS_MAP, sub);
  }

  protected loadExamSections(examId: string) {
    this._examSections$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadExamSections(examId)
      .subscribe((value: any[]) => {
        this._examSections$.next({
          loaded: LOAD_STATE.LOADED,
          value: (value || [])
        });
      });
    this.setSubscription(LOAD_DATA.EXAM_SECTIONS, sub);
  }

  get exam$(): Observable<Exam> {
    return this._exam$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get examContentsMap$(): Observable<IExamContentsMap> {
    return this._examContentMap$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get examAttendees$(): Observable<ExamUser[]> {
    return this.examManagementMode$.pipe(switchMap(() =>
      this._examAttendees$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED),
        map(it => (it.value || []).filter(u => this.filterAttendeesByStatus(u))
          .sort(this.common.utils.comparator('fullName'))))));
  }

  protected get examSections$(): Observable<ExamSection[]> {
    return this._examSections$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get examSections(): ExamSection[] {
    const object = this._examSections$.getValue();
    return object.loaded === LOAD_STATE.LOADED ? object.value : [];
  }

  get examSectionTree$() {
    return this._examSectionTree$.asObservable();
  }

  protected get examUserAnswersMap$(): Observable<IExamUserAnswersMap> {
    return this._examUserAnswersMap$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  protected filterAttendeesByStatus(user: ExamUser): boolean {
    if (this.examManagementMode() === EXAM_MANAGEMENT_MODE.RUN) {
      return this.rolesService.isAdmin() || !user.state ||
        [USER_EXAM_STATE.STARTED, USER_EXAM_STATE.PAUSED, USER_EXAM_STATE.ENDED].includes(user.state);
    } else if (this.examManagementMode() === EXAM_MANAGEMENT_MODE.ASSESSMENT) {
      return user.state === USER_EXAM_STATE.ASSESSMENT;
    } else if (this.examManagementMode() === EXAM_MANAGEMENT_MODE.RESULTS) {
      return user.state === USER_EXAM_STATE.RESULTS;
    }
    return true;
  }

  protected initObservers() {
    this.rolesService.init(this.exam$, this.examSections$, this.currentExamSection$, this.examAssessmentWorkMode$);
    this.examSettings();
    this.examContentsMapObserver();
    this.examSectionsTreeObserver();
    this.loadExamSectionContents();
    this.loadAdditionSectionContent();
    this.examUserAnswersMapObserver();
    this.examAttendeesNavigatorObservers();
    this.loadCurrentSectionStatisticsByContents();
    this.loadExamAndSectionsStatistics();
  }

  protected examSectionsTreeObserver() {
    combineLatest([this.exam$, this.examSections$])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([exam, sections]) => {
        this.examSectionFlatTree = [new ExamSectionTreeItem({id: exam.examId, ...exam})];
        sections.forEach(f => {
          this.examSectionFlatTree.push(new ExamSectionTreeItem({...f, parentId: f.parentId ?? exam.examId}));
        });
        this.examSectionFlatTree.sort(this.common.utils.comparator(Constants.ORDERINDEX));
        for (const ft of this.examSectionFlatTree) {
          if (ft.parentId) {
            const self = this.examSectionFlatTree.find(o => o.id === ft.id);
            const parent = this.examSectionFlatTree.find(o => o.id === ft.parentId);
            if (parent) {
              parent.items.push(self);
              parent.items.sort(this.common.utils.comparator(Constants.ORDERINDEX));
            }
          }
        }
        this._examSectionTree$.next(this.examSectionFlatTree.filter(f => !f.parentId));
        // push for reload roles
        if (this.currentExamSection()) {
          const cs = this.examSectionFlatTree.find(s => s.id === this.currentExamSection().id);
          if (cs) {
            this.currentExamSection$.next(cs);
            if (this.currentContent()?.parentId === cs.id) {
              this.currentContent$.next(cloneDeep(this.currentContent$.getValue()));
            }
          }
        }
      });
  }

  protected examSettings() {
    this.exam$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(exam => {
        this.currentExam.set(exam);
        // enable multilingual for ContentContainer editor and viewer
        if (!this.loadFromTimeline()) {
          this.languageService.event.set(exam ? new Event({...exam, eventId: exam.examId}) : null);
        }
        this.currentLanguage.set(this.defaultLanguage());
        const viewingNames = exam.anonymousAssessment && exam.anonymousAssessmentQuickViewingNames;
        if (this.workModeSingleAttendee()) {
          this.examAssessmentAnonymousDisabled.set(true);
        } else {
          this.examAssessmentAnonymousDisabled.set(!viewingNames ? !exam.anonymousAssessment :
            (this.sessionStorageService.get(EXAM_ASSESSMENT_ANONYMOUS_DISABLED) ?? !exam.anonymousAssessment));
        }
      });
  }

  protected examContentsMapObserver() {
    this.examContentsMap$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(examMap => {
        this.examContentsMap.set(examMap);
      });
  }

  protected loadExamUserAnswersMap(examId: string) {
    this._examUserAnswersMap$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadUserExamAnswersMap(examId, this.currentUser.userId)
      .subscribe(value => {
        this._examUserAnswersMap$.next({
          loaded: LOAD_STATE.LOADED,
          value: value});
      });
    this.setSubscription(LOAD_DATA.USER_ANSWERS_MAP, sub);
  }

  protected examUserAnswersMapObserver() {
    if (!this.loadFromTimeline()) {
      return;
    }
    this.examUserAnswersMap$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(answersMap => {
        this.userAnswersMap.set(answersMap);
      });
  }

  protected examAttendeesNavigatorObservers() {
    if (!this.examManagementMode()) {
      return;
    }
    combineLatest([this.examAttendees$, this.attendeeNavigator$])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([attendees, navigate]) => {
        if (!isEmpty(attendees)) {
          const index = attendees.findIndex(u => u.userId === (this.currentAttendee()?.userId ?? attendees[0].userId));
          if (index > -1) {
            if (navigate > 0) {
              this.currentAttendeeIndex.set(index + 1 > attendees.length - 1 ? 0 : index + 1);
            } else if (navigate < 0) {
              this.currentAttendeeIndex.set(index - 1 < 0 ? attendees.length - 1 : index - 1);
            }  else if (navigate === 0) {
              this.currentAttendeeIndex.set(index);
            }
            this.currentAttendee.set(attendees[this.currentAttendeeIndex()]);
          } else {
            this.currentAttendee.set(attendees[0]);
            this.currentAttendeeIndex.set(0);
          }
        } else {
          this.currentAttendee.set(null);
          this.currentAttendeeIndex.set(null);
        }
        if (!this.currentAttendee()) {
          if (this.examManagementMode() === EXAM_MANAGEMENT_MODE.ASSESSMENT) {
            this.examAssessmentWorkMode.set(EXAM_ASSESSMENT_WORK_MODE.OVERVIEW);
          } else {
            this.examAssessmentWorkMode.set(null);
          }
        }
      });
  }

  protected loadExamSectionContents() {
    this.currentExamSection$.pipe(
      filter(() => !!this.currentExam()),
      distinctUntilChanged((sp, sn) => sn?.id === sp?.id),
      switchMap(section => {
        this.sectionContentsListLoaded$.next(false);
        this.currentContent$.next(null);
        const examId = this.currentExam().examId;
        return !isEmpty(section) ? this.examDataService.loadExamSectionContents(examId, section.id) : of([]);
      }),
      takeUntil(this._unsubscribeAll))
      .subscribe((list: ContentContainer[]) => {
        list.sort(this.common.utils.comparator(Constants.ORDERINDEX));
        this.currentExamSectionContentsList$.next(list);
        if (this.loadFromTimeline() && this.currentExamSection()?.skipMainPage && !isEmpty(list)) {
          this.currentContent$.next(list[0]);
        }
        this.sectionContentsListLoaded$.next(true);
      });
  }

  protected loadCurrentSectionStatisticsByContents() {
    if (this.loadFromTimeline()) {
      return;
    }
    this.currentExamSection$.pipe(
      filter(() => !!this.currentExam()),
      distinctUntilChanged((sp, sn) => sn?.id === sp?.id),
      switchMap(section => {
        this.currentSectionAttendeesStatisticsByContents$.next(null);
        const examId = this.currentExam().examId;
        return isEmpty(section) ? of([]) :
          (!this.workModeSingleAttendee() ?
            this.examDataService.loadExamSectionAttendeesStatisticsByContents(examId, section.id) :
            this.examDataService.loadExamSectionAttendeeStatisticsByContents(examId, section.id, this.currentUser.userId));
      }),
      takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.currentSectionAttendeesStatisticsByContents$.next(value);
      });

    this.currentExamSection$.pipe(
      filter(() => !!this.currentExam() && !this.workModeSingleAttendee()),
      distinctUntilChanged((sp, sn) => sn?.id === sp?.id),
      switchMap(section => {
        this.currentSectionContentsStatistics.set(null);
        const examId = this.currentExam().examId;
        return !isEmpty(section) ? this.examDataService.loadExamSectionContentsStatistics(examId, section.id) : of([]);
      }),
      takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.currentSectionContentsStatistics.set(value);
      });
  }

  protected loadExamAndSectionsStatistics() {
    if (this.loadFromTimeline() || !this.examManagementMode()) {
      return;
    }
    this.exam$.pipe(
      distinctUntilChanged((ep, en) => en?.examId === ep?.examId),
      switchMap(exam => combineLatest([
        this.examDataService.loadExamSectionsAssessmentStatistics(exam.examId),
        this.examDataService.loadExamAssessmentStatistics(exam.examId),
      ])),
      takeUntil(this._unsubscribeAll))
      .subscribe(([sectionStatistics, examStatistics]) => {
        this.sectionsAssessmentStatistics.set(sectionStatistics);
        this.examAssessmentStatistics.set(examStatistics);
      });
  }

  protected loadAdditionSectionContent() {
    this.currentExamSection$.pipe(
      distinctUntilChanged((sp, sn) => sn?.id === sp?.id),
      switchMap(section => {
        this.currentExamAdditionContent$.next(null);
        return !isEmpty(section) ? this.examDataService.loadExamSectionAdditionContent(section) : of(null);
      }),
      takeUntil(this._unsubscribeAll))
      .subscribe(content => {
        this.currentExamAdditionContent$.next(content);
      });
  }

  protected loadExamAttendees(examId: string) {
    this._examAttendees$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.loadExamUsers(examId)
      .subscribe(value => {
        this._examAttendees$.next({
          loaded: LOAD_STATE.LOADED,
          value: value});
      });
    this.setSubscription(LOAD_DATA.EXAM_ATTENDEES, sub);
  }

  protected loadExamAttendee(examId: string, userId: string) {
    this._examAttendees$.next({loaded: LOAD_STATE.LOADING, value: null});
    const sub = this.examDataService.getExamUser(examId, userId)
      .subscribe((value: ExamUser) => {
        this._examAttendees$.next({
          loaded: LOAD_STATE.LOADED,
          value: [value]});
        this.currentAttendee.set(value);
        this.currentAttendeeIndex.set(0);
        this.viewActiveAssessmentStatus.set(null);
      });
    this.setSubscription(LOAD_DATA.EXAM_ATTENDEES, sub);
  }

  loadContentsByExamSection(examSection: ExamSection) {
    return this.examDataService.loadExamSectionContents(examSection.examId, examSection.id);
  }

  get currentExamSectionContentsList() {
    return this.currentExamSectionContentsList$.getValue();
  }

  onLanguageChange(value) {
    this.currentLanguage.set(value);
  }

  protected getOrderIndexForPosition(currentSection: ExamSection, position: SAVE_TO_POSITION) {
    if (!currentSection) {
      const indexes = this.examSectionFlatTree.map(s => s.orderIndex);
      return (!isEmpty(indexes) ? max(indexes) : 0) + Constants.ORDER_INDEX_INCREMENT;
    }
    if (position === SAVE_TO_POSITION.BELOW || position === SAVE_TO_POSITION.ABOVE) {
      const sectionsAround = this.examSectionFlatTree.filter(s => (s.parentId ?? null) === (currentSection.parentId ?? null))
        .sort(this.common.utils.comparator(Constants.ORDERINDEX));
      const index = sectionsAround.findIndex(s => s.id === currentSection.id);
      if (index === 0 && position === SAVE_TO_POSITION.ABOVE) {
        return currentSection.orderIndex / 2;
      } else if (index === sectionsAround.length - 1 && position === SAVE_TO_POSITION.BELOW) {
        if (sectionsAround.length === 1 || index === sectionsAround.length - 1) {
          return currentSection.orderIndex + Constants.ORDER_INDEX_INCREMENT;
        } else {
          return currentSection.orderIndex + ((sectionsAround[index + 1].orderIndex - currentSection.orderIndex) / 2);
        }
      } else {
        if (position === SAVE_TO_POSITION.BELOW) {
          return currentSection.orderIndex + ((sectionsAround[index + 1].orderIndex - currentSection.orderIndex) / 2);
        } else {
          return currentSection.orderIndex - ((currentSection.orderIndex - sectionsAround[index - 1].orderIndex) / 2);
        }
      }
    } else if (position === SAVE_TO_POSITION.WITHIN) {
      const flatCurrentSection = this.examSectionFlatTree.find(s => s.id === currentSection.id);
      const indexes = flatCurrentSection.items.map(s => s.orderIndex);
      return (!isEmpty(indexes) ? max(indexes) : 0) + Constants.ORDER_INDEX_INCREMENT;
    }
    return null;
  }

  protected getChildren(section: ExamSection) {
    const getChildren = (parent: ExamSection, tree: ExamSection[]) => {
      this.examSectionFlatTree.filter(s => s.parentId === parent.id)
        .sort(this.common.utils.comparator(Constants.ORDERINDEX))
        .forEach(c => {
          tree.push(c);
          getChildren(c, tree);
        });
    };
    const childrenTree = [section];
    getChildren(section, childrenTree);
    return childrenTree;
  }

  getParentsIdsInFlatTree(section: ExamSection) {
    const treeItem = this.examSectionFlatTree.find(s => s.id === section.id);
    if (!treeItem.parentId) {
      return [section.id];
    }
    const parentsIds = [];
    let parent = this.examSectionFlatTree.find(s => s.id === treeItem.parentId);
    while (!!parent) {
      parentsIds.push(parent.id);
      parent = this.examSectionFlatTree.find(s => s.id === parent.parentId);
    }
    return parentsIds;
  }

  saveExamSection(examSection: ExamSection, currentSection: ExamSection,  saveToPosition: SAVE_TO_POSITION = SAVE_TO_POSITION.BELOW) {
    if (!examSection.id) {
      examSection.examId = this.currentExam().examId;
      examSection.parentId = saveToPosition === SAVE_TO_POSITION.WITHIN ? currentSection.id : (currentSection?.parentId ?? null);
      examSection.orderIndex = this.getOrderIndexForPosition(currentSection, saveToPosition);
    }
    return this.examDataService.saveExamSection(examSection);
  }

  async deleteExamSection(examSection: ExamSection) {
    const childrenSections = this.getChildren(examSection).reverse();
    for (const section of childrenSections) {
      for (const content of await firstValueFrom(this.examDataService.loadExamSectionContents(section.examId, section.id))) {
        await this.deleteContent(content)
          .catch(e => {
            this.process$.set(false);
            throw e;
          });
      }
      const additionContent = await firstValueFrom(this.examDataService.loadExamSectionAdditionContent(section));
      if (additionContent) {
        await this.deleteSectionAdditionContent(additionContent);
      }
      await this.examDataService.deleteExamSection(section);
    }
  }

  addContent() {
    const orderIndex = () => {
      const isEmptyList = isEmpty(this.currentExamSectionContentsList);
      return (!isEmptyList ? max(this.currentExamSectionContentsList.map(cn => cn.orderIndex)) : 0) + Constants.ORDER_INDEX_INCREMENT;
    };

    const content = {
      eventId: this.currentExam().examId,
      parentId: this.currentExamSection$.getValue().id,
      description: '',
      isPublic: true,
      draft: false,
      orderIndex: orderIndex(),
      isPresenterContent: true,
      status: EXAM_CONTENT_STATUS.DRAFT
    };
    this.editContent(new ContentContainer(content));
  }

  async editContent(currentContent: ContentContainer) {
    const contentWithLazyFields = await this.examDataService.loadingAndApplyLazyFields(currentContent);
    this.currentContent$.next(new ContentContainer(contentWithLazyFields));
    this.openContentEditor$.next(true);
  }

  saveContent(content: ContentContainer) {
    this.common.showProgress.next(true);
    this.examDataService.saveContent(content)
      .then(contentId => {
        content.id = contentId;
        this.openContentEditor$.next(false);
        this.router.navigate([], {queryParams: {cid: content.id}, relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
      })
      .finally(() => this.common.showProgress.next(false));
  }

  deleteContent(content: ContentContainer) {
    return this.examDataService.deleteContent(content);
  }

  async deleteExam(examId: string) {
    this.process$.set(true);
    for (const section of await firstValueFrom(this.examDataService.loadExamSections(examId))) {
      await this.deleteExamSection(section)
        .catch(e => {
          this.process$.set(false);
          throw e;
        });
    }
    return this.examDataService.deleteExam(examId)
      .finally(() => this.process$.set(false));
  }

  saveExam(exam: Exam) {
    return this.examDataService.saveExam(exam);
  }

  async openExamSettingsDialog(examId) {
    firstValueFrom(this.dialog.open(ExamSettingsDialogComponent, {
      data: {
        exam: !examId ? new Exam({owner: pick(this.currentUser, ['userId', 'picture', 'email', 'fullName'])}) :
          await firstValueFrom(this.examDataService.loadExam(examId)),
        examLaunchCode: !!examId ? (await firstValueFrom(this.examDataService.loadExamLaunchCodes(examId)))?.[examId] : null
      }
    }).afterClosed())
      .then(result => {
        if (result) {
          this.process$.set(true);
          this.saveExam(result.exam)
            .then(async exId => {
              await this.examDataService.addUsersToExam({examId: exId, ...result.exam}, result.addedUsers);
              await this.examDataService.deleteUsersFromExam(exId, result.deletedUsers);
              await this.examDataService.saveExamLaunchCode(exId, exId, result.examLaunchCode);
              return;
            })
            .then(() => this.common.showPopupSuccess('common.dialog.success'))
            .finally(() => this.process$.set(false));
        }
      });
  }

  pasteFromClipboard(pasteToContent: ExamSection | ContentContainer, position?: SAVE_TO_POSITION) {
    if (this.isClipboardTypeSection()) {
      const clipboardContent = this.clipboardContent().content as ExamSection;
      const clipboardType = this.clipboardContent().clipboardType;
      const orderIndex = this.getOrderIndexForPosition(pasteToContent as ExamSection, position);
      const parentId = position === SAVE_TO_POSITION.WITHIN ? pasteToContent.id : (pasteToContent?.parentId ?? null);
      this.common.showProgress.next(true);
      this.examDataService.pasteSection((pasteToContent as ExamSection).examId, parentId, clipboardContent, orderIndex, clipboardType)
        .finally(() => {
          this.clipboardContent.set(null);
          this.common.showProgress.next(false);
        });
    } else if (pasteToContent instanceof ContentContainer) {
      const clipboardContent = this.clipboardContent().content as ContentContainer;
      const clipboardType = this.clipboardContent().clipboardType;
      const index = this.currentExamSectionContentsList.findIndex(cn => cn.id === pasteToContent.id);
      const orderIndex = index + 1 === this.currentExamSectionContentsList.length ?
        pasteToContent.orderIndex + Constants.ORDER_INDEX_INCREMENT :
        pasteToContent.orderIndex + ((this.currentExamSectionContentsList[index + 1].orderIndex - pasteToContent.orderIndex) / 2);
      this.common.showProgress.next(true);
      this.examDataService.pasteContent(pasteToContent.eventId, pasteToContent.parentId, clipboardContent, orderIndex, clipboardType)
        .then(contentId => {
          this.router.navigate([], {queryParams: {cid: contentId}, relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
        })
        .finally(() => {
          this.clipboardContent.set(null);
          this.common.showProgress.next(false);
        });
    } else if (pasteToContent instanceof ExamSection) {
      const clipboardContent = this.clipboardContent().content as ContentContainer;
      const clipboardType = this.clipboardContent().clipboardType;
      this.common.showProgress.next(true);
      this.examDataService.pasteContent(pasteToContent.examId, pasteToContent.id, clipboardContent, new Date().getTime(), clipboardType)
        .then(contentId => {
          this.router.navigate([], {queryParams: {sid: pasteToContent.id, cid: contentId},
            relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
        })
        .finally(() => {
          this.clipboardContent.set(null);
          this.common.showProgress.next(false);
        });
    }
  }

  createSectionAdditionContent() {
    const content = {
      eventId: this.currentExam().examId,
      description: '',
      isPublic: true,
      draft: false,
      orderIndex: new Date().getTime(),
      isPresenterContent: true,
      additionForSection: true,
      showTitleInTabOnly: true
    };
    return new ContentContainer(content);
  }

  getSectionAdditionContent(section: ExamSection) {
    return firstValueFrom(this.examDataService.loadExamSectionAdditionContent(section));
  }

  saveSectionAdditionContent(content: ContentContainer, section: ExamSection) {
    return this.examDataService.saveSectionAdditionContent(content, section);
  }

  deleteSectionAdditionContent(content: ContentContainer) {
    return this.examDataService.deleteSectionAdditionContent(content);
  }

  userStartPauseExam(exam: Exam) {
    this.process$.set(true);
    const state = this.currentAttendee()?.state !== USER_EXAM_STATE.STARTED ? USER_EXAM_STATE.STARTED : USER_EXAM_STATE.PAUSED;
    this.examDataService.userStartPauseExam(exam, state)
      .finally(() => this.process$.set(false));
  }

  userLaunchExamByCode(exam: Exam, code) {
    this.process$.set(true);
    this.examDataService.launchExamByCodeCode(exam.examId, code)
      .finally(() => this.process$.set(false));
  }

  loadExamUsers(examId: string) {
    return this.examDataService.loadExamUsers(examId);
  }

  getExamUser(examId: string, userId: string) {
    return this.examDataService.getExamUser(examId, userId);
  }

  getUserExamTaskRunningTime(exam: Exam) {
    return this.examDataService.getUserExamTaskRunningTime(exam);
  }

  onChangeExamManagementMode(mode: EXAM_MANAGEMENT_MODE) {
    switch (mode) {
      case EXAM_MANAGEMENT_MODE.RUN:
        return this.setWorkMode(EXAM_MANAGEMENT_MODE.RUN);
      case EXAM_MANAGEMENT_MODE.ASSESSMENT:
        return this.setWorkMode(EXAM_MANAGEMENT_MODE.ASSESSMENT, EXAM_ASSESSMENT_WORK_MODE.OVERVIEW);
      case EXAM_MANAGEMENT_MODE.RESULTS:
        return this.setWorkMode(EXAM_MANAGEMENT_MODE.RESULTS);
    }
  }

  getActionByUserState(state: USER_EXAM_STATE): USER_EXAM_ACTION {
    switch (state) {
      case USER_EXAM_STATE.EXAM_AVAILABLE:
        return USER_EXAM_ACTION.EXAM_AVAILABLE;
      case USER_EXAM_STATE.READY_TO_START:
        return USER_EXAM_ACTION.READY_TO_START;
      case USER_EXAM_STATE.STARTED:
        return USER_EXAM_ACTION.START;
      case USER_EXAM_STATE.PAUSED:
        return USER_EXAM_ACTION.PAUSE;
      case USER_EXAM_STATE.ENDED:
        return USER_EXAM_ACTION.END;
      case USER_EXAM_STATE.ASSESSMENT:
        return USER_EXAM_ACTION.ASSESSMENT;
      case USER_EXAM_STATE.RESULTS:
        return USER_EXAM_ACTION.RESULTS;
      case USER_EXAM_STATE.INTERRUPTED:
        return USER_EXAM_ACTION.INTERRUPT;
      default:
        throw new Error('User state not found.');
    }
  }

  getUserStateByAction(action: USER_EXAM_ACTION): USER_EXAM_STATE {
    switch (action) {
      case USER_EXAM_ACTION.EXAM_AVAILABLE:
        return USER_EXAM_STATE.EXAM_AVAILABLE;
      case USER_EXAM_ACTION.READY_TO_START:
        return USER_EXAM_STATE.READY_TO_START;
      case USER_EXAM_ACTION.START:
        return USER_EXAM_STATE.STARTED;
      case USER_EXAM_ACTION.PAUSE:
        return USER_EXAM_STATE.PAUSED;
      case USER_EXAM_ACTION.END:
        return USER_EXAM_STATE.ENDED;
      case USER_EXAM_ACTION.ASSESSMENT:
        return USER_EXAM_STATE.ASSESSMENT;
      case USER_EXAM_ACTION.RESULTS:
        return USER_EXAM_STATE.RESULTS;
      case USER_EXAM_ACTION.INTERRUPT:
        return USER_EXAM_STATE.INTERRUPTED;
      default:
        return null;
    }
  }
}
