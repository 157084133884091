import {Component, ElementRef, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Constants, ILanguageParams, TRIPLE} from '../../../../../core/constants';
import {BehaviorSubject, filter, Subject, take} from 'rxjs';
import {cloneDeep, isEmpty} from 'lodash';
import {TranslateApiService} from '../../../../../services/translate-api.service';
import {CommonService} from '../../../../../core/common.service';
import {StdComponent} from '../../../../../core/std-component';
import {UtilsService} from '../../../../../core/utils.service';
import {UPLOAD_TYPE, UploadService} from '../../../../../core/upload.service';
import {ITEM_MARKER} from '../../../shared/container-interface';
import {IContainerAnnotationsMap, ITextNodeFile} from '../text-note-model/text-note-model';
import {TextNoteService} from '../text-note.service';

@Component({
  selector: 'app-text-editor-dialog',
  templateUrl: './text-editor-dialog.component.html',
  styleUrls: ['./text-editor-dialog.component.scss']
})
export class TextEditorDialogComponent extends StdComponent implements OnInit {

  readonly ITEM_MARKER = ITEM_MARKER;

  private _text: string;
  private uppy: any;
  originalText: string;
  languageParams: ILanguageParams;
  translateHint: string;
  textHash: string;
  filesHash: string;
  isModify = false;
  checkModify$ = new Subject();
  audioFiles: ITextNodeFile[];
  externalAnnotations$ = new BehaviorSubject<IContainerAnnotationsMap>({});
  annotationToolbarLoaded$ = new BehaviorSubject<boolean>(false);
  editAnnotationElement$ = new Subject<HTMLElement>();

  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  @ViewChild('audioPlayer') audioPlayer: ElementRef;
  @ViewChild('appTextEditor') appTextEditor: ElementRef;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<TextEditorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private common: CommonService,
              private translateApiService: TranslateApiService,
              public dialog: MatDialog,
              private uploadService: UploadService) {
    super(injector);
    this._text = data.text;
    this.textHash = UtilsService.md5(data.text);
    this.audioFiles = cloneDeep(data.files);
    this.audioFiles.forEach(f => {
      if (f.id.startsWith(ITEM_MARKER.DELETED)) {
        f.id = f.id.substring(1);
      }
    });
    this.filesHash = UtilsService.md5(UtilsService.jsonSorted(data.files));
    this.audioFiles = UtilsService.wrapObjectToProxy(this.audioFiles, this.dataChangesHandler);
    this.originalText = data.originalText;
    dialogRef.addPanelClass('timeline');
    dialogRef.updateSize('800px');
    dialogRef.disableClose = true;
    this.data.languageParams$
      .pipe(filter(p => !isEmpty(p)), take(1))
      .subscribe(params => {
        this.languageParams = cloneDeep(params);
        this.translateHint = this.translateApiService
          .getTranslateHint(this.languageParams.defaultLanguage, this.languageParams.currentLanguage);
      });
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
    data.externalAnnotations$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        this.annotationToolbarLoaded$.next(value !== null);
        this.externalAnnotations$.next(cloneDeep(value));
      });
  }


  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
    this.checkModify$.next(true);
  }

  ngOnInit(): void {
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.AUDIO, null, result => {
      TextNoteService.addFileToList(result, this.languageParams, this.common, (file) => this.audioFiles.push(file));
      this.uploadService.closeModal(this.uppy);
    }, null, true);
    this.checkModify$.pipe(this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.textHash !== UtilsService.md5(this._text) ||
          this.filesHash !== UtilsService.md5(UtilsService.jsonSorted(this.audioFiles));
    });
    this.dataDetectChanges.pipe(this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.textHash !== UtilsService.md5(this._text) ||
          this.filesHash !== UtilsService.md5(UtilsService.jsonSorted(this.audioFiles));
      });
  }

  onDestroy() {
    this.uppy.close();
  }

  onOkClick(): void {
    this.dialogRef.close({text: this.text, files: cloneDeep(this.audioFiles)});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onRestoreTextTranslation() {
    if (await this.common.confirmation(this.common.i18n('translate.restore.to.default.confirmation'))) {
      this.text = this.originalText;
    }
  }

  onAudioFilesListChange(list: ITextNodeFile[]) {
    list.filter(f => f.id.startsWith(ITEM_MARKER.NEW) && !this.audioFiles.find(af => af.id === f.id))
      .forEach(f => this.audioFiles.push(f));
  }
}
