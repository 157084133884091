export interface IRegistrationUserCardBySection {
  [sectionId: string]: RegistrationUserCard;
}

export class RegistrationUserCard {
  userId: string;
  email: string;
  status: string;
  regDate: number;
  lastChangeDate: number;
  lastChangeType: string;
  lastChangeByUserId: string;
  assistantId: string;
  isAssistant = false;

  fullName: string;
  firstName: string;
  lastName: string;
  picture: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.email) {
      this.email = obj.email;
      this.userId = obj.email.replace(/\./g, '_').replace('@', '_');
    }
    if (obj.status) {
      this.status = obj.status;
    }
    if (obj.regDate) {
      this.regDate = obj.regDate;
    }
    if (obj.lastChangeDate) {
      this.lastChangeDate = obj.lastChangeDate;
    }
    if (obj.lastChangeType) {
      this.lastChangeType = obj.lastChangeType;
    }
    if (obj.lastChangeByUserId) {
      this.lastChangeByUserId = obj.lastChangeByUserId;
    }
    if (obj.assistantId) {
      this.assistantId = obj.assistantId;
    }
    if (obj.isAssistant) {
      this.isAssistant = obj.isAssistant;
    }

    if (obj.fullName) {
      this.fullName = obj.fullName;
    }
    if (obj.firstName) {
      this.firstName = obj.firstName;
    }
    if (obj.lastName) {
      this.lastName = obj.lastName;
    }
    if (obj.picture) {
      this.picture = obj.picture;
    }
  }
}
