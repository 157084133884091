import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateTimePickerComponent} from './date-time-picker/date-time-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import {TimePickerComponent} from './time-picker/time-picker.component';
import {TimeSelectDialogComponent} from './time-picker/time-select-dialog/time-select-dialog.component';
import {TimeSelectComponent} from './time-picker/time-select/time-select.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../core/core.module';
import {HttpClient} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { SelectHourMinutesComponent } from './time-picker/select-hour-minutes/select-hour-minutes.component';


@NgModule({
  declarations: [
    DateTimePickerComponent,
    TimePickerComponent,
    TimeSelectComponent,
    TimeSelectDialogComponent,
    SelectHourMinutesComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatInputModule
  ],
  exports: [
    DateTimePickerComponent,
    TimeSelectComponent,
    SelectHourMinutesComponent
  ]
})
export class DateTimePickerModule {
}
