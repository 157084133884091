import {Component, effect, ElementRef, Injector, viewChildren} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {MatCell, MatTableDataSource} from '@angular/material/table';
import {
  BASE_TABLE_GRID_COLUMNS,
  ExtTableRow,
  ITableGridColumn,
  TABLE_COLUMN_TYPE,
  TABLE_COLUMNS_WIDTH,
  TableColumn
} from '../question-table-editor/table-poll-model/TablePollModel';
import {Constants} from '../../../../../../../core/constants';
import {QUESTION_VIEW_MODE} from '../../shared/quiz-quiestion-types';
import {Event} from '../../../../../../../model/Event';
import {UtilsService} from '../../../../../../../core/utils.service';
import {BehaviorSubject, debounceTime} from 'rxjs';

const GRID_COLUMNS: ITableGridColumn[] = [
  ...BASE_TABLE_GRID_COLUMNS
];

const NUMERIC_COLUMNS = [TABLE_COLUMN_TYPE.CHECKBOX, TABLE_COLUMN_TYPE.DROPDOWN];

interface ISummaryColumn {
  [columnId: string]: any;
}

interface ISummaryTable {
  [rowId: string]: ISummaryColumn;
}

@Component({
  selector: 'app-question-table-results',
  templateUrl: './question-table-results.component.html',
  styleUrl: './question-table-results.component.scss'
})
export class QuestionTableResultsComponent extends AbstractQuizQuestionResultsComponent {

  readonly QUESTION_VIEW_MODE = QUESTION_VIEW_MODE;
  readonly TABLE_COLUMN_TYPE = TABLE_COLUMN_TYPE;

  viewMode = QUESTION_VIEW_MODE.PERCENTAGE;
  displayedColumns = [];
  dataSource = new MatTableDataSource<Partial<ExtTableRow>>([]);
  columns: TableColumn[] = [];
  gridColumns: ITableGridColumn[] = [];
  answers: any;
  totalAnswers = {};
  summaryTable: ISummaryTable = {};

  mainCellRowsElement = viewChildren(MatCell, {read: ElementRef});
  tableMainCellRowsHeight = {};

  horizontalScroll$ = new BehaviorSubject<number>(null);
  horizontalScrollBlock = false;
  horizontalScrollOnWheelBlocked = false;
  private resizeObserver: {[id: string]: ResizeObserver} = {};

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
    effect(() => {
      this.mainCellRowsElement().forEach(row => {
        if (!this.resizeObserver[row.nativeElement.id]) {
          this.resizeObserver[row.nativeElement.id] = new ResizeObserver(() => {
            this.mainCellRowsElement()?.forEach(r => {
              const domRect = (r.nativeElement as HTMLElement).getBoundingClientRect();
              this.tableMainCellRowsHeight[(r.nativeElement as HTMLElement).id] = `${Math.trunc((domRect.height / this.scale()) - 1)}px`;
            });
          });
        }
        this.resizeObserver[row.nativeElement.id].observe(row.nativeElement);
        const rect = (row.nativeElement as HTMLElement).getBoundingClientRect();
        this.tableMainCellRowsHeight[row.nativeElement.id] = `${Math.trunc(rect.height / this.scale() - 1)}px`;
      });
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.question$.pipe(this.takeUntilAlive()).subscribe(question => this.viewMode = question.viewMode ?? QUESTION_VIEW_MODE.PERCENTAGE);
    this.horizontalScroll$.pipe(debounceTime(50), this.takeUntilAlive())
      .subscribe(() => {
        this.horizontalScrollBlock = false;
      });
  }

  onDestroy() {
    Object.values(this.resizeObserver).forEach(o => o.disconnect());
  }

  initQuestionAnswersDataSource() {
    this.columns = (this.question.options?.tableColumns ?? []).map(c => new TableColumn(c));
    this.gridColumns = this.columns.filter(cl => !cl.isMainColumn)
      .sort(this.common.utils.comparator(Constants.ORDERINDEX))
      .map((cl, idx) => new Object({
        name: `${cl.type.inputType}_${idx}`,
        width: TABLE_COLUMNS_WIDTH[cl.type.inputType],
        id: cl.id,
        type: cl.type.inputType,
        columnName: cl.type.columnName,
        options: cl.type['options']?.sort(this.common.utils.comparator(Constants.ORDERINDEX))
      }) as ITableGridColumn);

    this.displayedColumns = [...GRID_COLUMNS.map(cl => cl.name), ...this.gridColumns.map(cl => cl.name)];
    const columnsCSS = [...GRID_COLUMNS.map(cl => cl.width), ...this.gridColumns.map(cl => cl.width)].join(' ');
    this.elementRef.nativeElement.style.setProperty('--table-grid-template-columns', columnsCSS);
    this.dataSource.data = (this.columns.find(c => c.isMainColumn).tableRows ?? [])
      .sort(this.common.utils.comparator(Constants.ORDERINDEX));
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.summaryQuestionAnswers;
    this.calcTotalAnswers();
    this.calcDataSourceFields();
  }

  private calcTotalAnswers() {
    this.totalAnswers = {};
    const calculatedColumnsIds = this.gridColumns.filter(cl => NUMERIC_COLUMNS.includes(cl.type)).map(cl => cl.id);
    for (const rowId of Object.keys(this.answers ?? {})) {
      const columns = this.answers[rowId];
      for (const columnId of Object.keys(columns).filter(id => calculatedColumnsIds.includes(id))) {
        if (typeof columns[columnId] === 'number') {
          this.totalAnswers[columnId] = (this.totalAnswers[columnId] ?? 0) + columns[columnId];
        } else if (typeof columns[columnId] === 'object') {
          for (const optionId of Object.keys(columns[columnId])) {
            if (!this.totalAnswers[rowId]) {
              this.totalAnswers[rowId] = {};
            }
            if (!this.totalAnswers[rowId][columnId]) {
              this.totalAnswers[rowId][columnId] = 0;
            }
            this.totalAnswers[rowId][columnId] += (columns[columnId][optionId] ?? 0);
          }
        }
      }
    }
  }

  private calcDataSourceFields() {
    for (const row of this.dataSource.data) {
      this.summaryTable[row.id] = {};
      for (const column of this.gridColumns) {
        if (column.type === TABLE_COLUMN_TYPE.CHECKBOX) {
          const rowColumnValue = this.answers?.[row.id]?.[column.id] ?? 0;
          this.summaryTable[row.id][column.id] = {
            percent: this.totalAnswers[column.id] ? `${Math.round((rowColumnValue / this.totalAnswers[column.id]) * 100)}%` : '0%',
            count: rowColumnValue
          };
        } else if (column.type === TABLE_COLUMN_TYPE.DROPDOWN) {
          this.summaryTable[row.id][column.id] = {};
          for (const option of column.options) {
            const optionValue = this.answers?.[row.id]?.[column.id]?.[option.id] ?? 0;
            const optionTotal = this.totalAnswers?.[row.id]?.[column.id] ?? 0;
            this.summaryTable[row.id][column.id][option.id] = {
              percent: optionTotal ? `${Math.round((optionValue / optionTotal) * 100)}%` : '0%',
              count: optionValue
            };
          }
        } else if (column.type === TABLE_COLUMN_TYPE.TEXT) {
          this.summaryTable[row.id][column.id] = (this.answers?.[row.id]?.[column.id] ?? [])
            .map(text => new Object({text: text, color: Event.getRandomColor(UtilsService.createId(5))}));
        } else if (column.type === TABLE_COLUMN_TYPE.FILE) {
          this.summaryTable[row.id][column.id] = this.answers?.[row.id]?.[column.id] ?? 0;
        }
      }
    }
  }

  onWheelContentScroller(event, table) {
    if (this.horizontalScrollOnWheelBlocked) {
      return;
    }
    const element = table._elementRef.nativeElement;
    const toLeft  = event.deltaY < 0 && element.scrollLeft > 0;
    const toRight = event.deltaY > 0 && element.scrollLeft < element.scrollWidth - element.clientWidth;
    if (toLeft || toRight) {
      event.preventDefault();
      event.stopPropagation();
      let deltaLeft = event?.deltaY ?? 0;
      deltaLeft = Math.abs(deltaLeft) < 100 ? (100 * (deltaLeft < 0 ? -1 : 1)) : deltaLeft;
      if (!this.horizontalScrollBlock || this.horizontalScroll$.getValue() !== deltaLeft) {
        element.scrollBy({left: deltaLeft * 3,  behavior: 'smooth'});
        this.horizontalScrollBlock = true;
      }
      this.horizontalScroll$.next(deltaLeft);
    }
  }

}
