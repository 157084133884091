import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {cloneDeep, isEmpty} from 'lodash';
import {AnswerEquality, TableRowAnswerQuestion} from '../../../../../../../../model/EventQuestion';
import {Constants, TRIPLE} from '../../../../../../../../core/constants';
import {StdComponent} from '../../../../../../../../core/std-component';
import {CommonService} from '../../../../../../../../core/common.service';
import {UtilsService} from '../../../../../../../../core/utils.service';

@Component({
  selector: 'app-matching-map-editor-dialog',
  templateUrl: './matching-map-editor-dialog.component.html',
  styleUrls: ['./matching-map-editor-dialog.component.scss']
})
export class MatchingMapEditorDialogComponent extends StdComponent implements OnInit {

  answerData: TableRowAnswerQuestion[] = [];
  correctEquality: AnswerEquality[] = [];
  imageUrl: string;
  validated = true;
  isModify = false;
  hashEquality;
  isDefault = true;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<MatchingMapEditorDialogComponent>,
              private common: CommonService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    dialogRef.updateSize('880px', '780px');
    this.answerData = cloneDeep(data.answerData);
    this.correctEquality = cloneDeep(data.correctEquality);
    this.hashEquality = UtilsService.md5(UtilsService.jsonSorted(data.correctEquality));
    this.imageUrl = data.imageUrl;
    this.isDefault = data.groupId === 'default';

    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  ngOnInit() {

  }

  onOkClick(): void {
    this.dialogRef.close({
     correctEquality: this.correctEquality
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChangeMatchingMap(value: AnswerEquality[]) {
    this.correctEquality = value;
    this.isModify = this.hashEquality !== UtilsService.md5(UtilsService.jsonSorted(this.correctEquality));
    this.validated = !isEmpty(this.correctEquality) && this.correctEquality.every(v => !!v.answerId);
  }
}
