import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {IAnswer} from '../../../quiz-model/quiz';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {Constants} from '../../../../../../../core/constants';

@Component({
  selector: 'app-question-check-choice-participant-learn',
  templateUrl: './question-check-choice-participant-learn.component.html',
  styleUrl: './question-check-choice-participant-learn.component.scss'
})
export class QuestionCheckChoiceParticipantLearnComponent extends AbstractQuizQuestionParticipantComponent {
  displayedColumns = ['answer'];
  dataSource: IAnswer[] = [];

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];
    if (this.question.items) {
      const correctAnswers = this.question.reduceGroupsCorrectAnswersItemsCorrectAnswer();
      this.question.items.forEach(answer => dataList.push(
        {check: '', answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id,
          orderIndex: answer.orderIndex, correctAnswer: correctAnswers?.[answer.id]}));
      let diff = this.dataSource.length !== dataList.length;
      if (!diff) {
        diff = this.dataSource.some(item => {
          const ind = dataList.findIndex(elem => {
            if (item.id === elem.id && item.answer === elem.answer && /*item.answerMatchingId === elem.answerMatchingId &&*/
              item.correctAnswer === elem.correctAnswer && item.orderIndex === elem.orderIndex) {
              return true;
            }
          });
          return ind === -1;
        });
      }
      if (diff) {
        dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
        this.dataSource = dataList;
      }
    }
  }

  switchItem(event, item) {
    if (!this.acceptAnswers) {
      return;
    }
    let answers: string[] = [];
    if (this.answers) {
      answers = this.answers;
      answers.splice(0, answers.length);
      const ind = answers.indexOf(item);
      if (ind >= 0) {
        answers.splice(ind, 1);
        this.answers = answers;
      } else {
        answers.push(item);
        this.answers = answers;
      }
    } else {
      answers.push(item);
      this.answers = answers;
    }
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }
}
