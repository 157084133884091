export class Tag {
  id: string;
  name: string;
  color: string;

  public static genId(): string {
    const dict = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let id = '';
    for (let i = 0; i < 6; i++) {
      const rnd = Math.floor(Math.random() * 25);
      id += dict.charAt(rnd);
    }
    return id;
  }

  constructor(obj?) {
    for (const name of Object.keys(obj ?? {})) {
      if (this.classFieldsNames().includes(name)) {
        this[name] = obj[name];
      }
    }
  }

  protected classFieldsNames(): string[] {
    return ['id', 'name', 'color'];
  }

  toObject() {
    return {...this};
  }

}
