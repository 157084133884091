import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {UsersDataService} from '../../services/users-data.service';
import {EventsDataService} from '../../services/events-data.service';
import {CommonService} from '../../core/common.service';
import {UtilsService} from '../../core/utils.service';
import {IUsersSectionsData, TimeLineService} from '../../services/time-line.service';
import * as ui from '../../actions/ui';
import {
  ADD_NEW_SLOT_MODE,
  AUTOPILOT_PRESTART_TIME,
  AVAILABLE_MEETING_VIEW_MODE,
  CLOCK_TICKING,
  Constants,
  CONTENT_FROM_AUDIENCE_MODE,
  EMULATE_RESULT,
  EVENT_REGISTRATION_MODE,
  ILanguageParams,
  MEETING_VIEW_MODE,
  REMINDER_TYPE,
  SECTION_USER_LIST_PAGE,
  TRIPLE,
  VIRTUAL_CONFERENCE_EVENT,
  VIRTUAL_CONFERENCE_TYPE
} from '../../core/constants';
import {Event} from '../../model/Event';
import {SectionContent, SectionUser} from '../../model/content/SectionContent';
import {clone, cloneDeep, isEmpty, merge, union} from 'lodash';
import {RegistrationSettings} from '../../model/event-mode/RegistrationSettings';
import {AppUser} from '../../model/AppUser';
import {StdComponent} from '../../core/std-component';
import {ConferenceUser} from '../../model/event-mode/ConferenceUser';
import {SectionTimeline} from '../../model/content/SectionTimeline';
import {InstantSettings, VirtualConferenceSettings} from '../../model/event-mode/InstantSettings';
import {UPLOAD_TYPE, UploadService} from '../../core/upload.service';
import {LoginService} from '../../login/login.service';
import {BehaviorSubject, filter, Subject} from 'rxjs';
import {IRoom} from '../meeting-frame/services/daily-co.constants';
import {ContentService} from '../../services/content.service';
import {TimeLineEmulator} from '../../time-line-emulator/time-line-emulator';
import {PlaneContent} from '../../model/content/PlaneContent';
import {ROW_TIME_TYPE, TimePoint} from '../../model/event-mode/RowDatesObject';
import {ContentDependencySettingsDialogComponent} from '../content-dependency-settings-dialog/content-dependency-settings-dialog.component';
import {TranslateApiService} from '../../services/translate-api.service';
import {LANGUAGE} from '../../core/language-constants';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DRM_MODE, DRMService} from '../../core/drm.service';

export interface INewSlotInitData {
  addMode?: ADD_NEW_SLOT_MODE;
  sectionInitiator?: SectionContent | SectionTimeline;
}

@Component({
  selector: 'app-edit-section-setting-dialog',
  templateUrl: './edit-section-setting-dialog.component.html',
  styleUrls: ['./edit-section-setting-dialog.component.scss']
})
export class EditSectionSettingDialogComponent extends StdComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly Constants = Constants;
  readonly REMINDER_TYPE = REMINDER_TYPE;
  readonly EVENT_REGISTRATION_MODE = EVENT_REGISTRATION_MODE;
  readonly CLOCK_TICKING = CLOCK_TICKING;
  readonly AUTOPILOT_PRESTART_TIME = AUTOPILOT_PRESTART_TIME;
  readonly CONTENT_FROM_AUDIENCE_MODE = CONTENT_FROM_AUDIENCE_MODE;
  readonly VIRTUAL_CONFERENCE_EVENT = VIRTUAL_CONFERENCE_EVENT;
  readonly VIRTUAL_CONFERENCE_TYPE = VIRTUAL_CONFERENCE_TYPE;
  readonly MEETING_VIEW_MODE = MEETING_VIEW_MODE;
  readonly AVAILABLE_MEETING_VIEW_MODE = AVAILABLE_MEETING_VIEW_MODE;
  readonly ROW_TIME_TYPE = ROW_TIME_TYPE;
  readonly DRM_MODE = DRM_MODE;

  private _inputFocus = false;
  private _detach = false;
  displayedColumns = ['picture', 'userName', 'speaker'];
  currentEvent: Event;
  currentUser: AppUser;
  section: SectionContent;
  title: string;
  registrationSettings: RegistrationSettings;
  requiredEventRegistration: boolean;
  isAdminOrModerator: boolean;
  isPresenter: boolean;
  isSpeaker: boolean;
  isFeatureLine: boolean;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  resultSet = [];
  initData = {};
  onlineUsers: ConferenceUser[] = [];
  presenters = {};
  viewerUsers = {};
  searchValue = '';
  speakers_attendees: number = Constants.SPEAKERS_ATTENDEES;
  sectionTypeList: any[] = [];
  containerFieldDisable;
  selfLearningSectionFieldsDisable;
  eventInstantSettings: InstantSettings;
  parentIsContainer = false;
  changedUserRole = {};
  pageLength = SECTION_USER_LIST_PAGE.INIT_PAGE_LENGTH;
  paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.changeDetector);
  isInstantGroupRestrictAccessMembers = false;
  loadedBackgroundImage = true;
  uppy: any;
  oldBackgroundImage: string;
  oldUsersList: SectionUser[] = [];
  firstOpen = true;
  usersData: IUsersSectionsData[];
  groupNumbers = [...Array(50)].map((item, index) => index + 1);
  prevLinkToLiveStreaming: string;
  prevTypeOfConference: VIRTUAL_CONFERENCE_TYPE;
  clearLinkToLiveStreamingFocused = false;
  freeSlotMode = false;
  freeSlotTimeChange = new Subject<{value: number, type: ROW_TIME_TYPE}>();
  slotTimeRestricted = false;
  srcVirtualSettings: VirtualConferenceSettings;
  newSlotInitData: INewSlotInitData = {};
  currentLanguage: LANGUAGE;
  defaultLanguage: LANGUAGE;
  multilingual = false;
  usedLanguages: string[];
  languageParams: ILanguageParams;
  translateHint: string;
  translating: any;
  linkToLiveStreamingValid = true;
  sectionHash: string;
  sectionDetectChanges = new BehaviorSubject<boolean>(false);
  isModify = false;
  isUsersModify = false;
  sectionChangesHandler = {
    detectChanges: this.sectionDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<EditSectionSettingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<fromRoot.State>,
              private userDataService: UsersDataService,
              public eventDataService: EventsDataService,
              public common: CommonService,
              private utils: UtilsService,
              public dialog: MatDialog,
              public timeLineService: TimeLineService,
              public changeDetector: ChangeDetectorRef,
              public uploadService: UploadService,
              public loginService: LoginService,
              public drmService: DRMService,
              public contentService: ContentService,
              private translateApiService: TranslateApiService,
              private elementRef: ElementRef) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    this.currentEvent = this.timeLineService.timelineEvent;
    this.oldBackgroundImage = data.section.backgroundImage;
    this.setSectionObjects(data);
    this.newSlotInitData = data.newSlotInitData;
    this.freeSlotMode = this.section.freeSlotType;
    if (!this.freeSlotMode) {
      this.title = !this.section.id ? this.utils.i18n('action.add_section') :
        this.utils.i18n('title.edit.content.section');
      if (this.timeLineService.event.slotMode && this.timeLineService.timelineSlotMode.getValue() && !this.section.id) {
        this.section.education = true;
      }
      if (this.loginService.educationMode && !this.section.id) {
        this.section.sectionTimeIsNotActive = true;
        this.section.education = true;
      }
    } else {
      this.title = !this.section.id ? this.utils.i18n('slot.mode.add.slot') :
        this.utils.i18n('slot.mode.edit.slot');
      if (!this.section.id) {
        this.setNewSlotCalculatedTime();
      }
    }
    this.isFeatureLine = data.isFeatureLine;
    this.currentUser = data.currentUser;
    this.isPresenter = data.isPresenter;
    this.isSpeaker = this.timeLineService.hasSpeakerAccessRightsToSection(this.section);
    this.isAdminOrModerator = data.isAdminOrModerator;
    this.requiredEventRegistration = data.requiredEventRegistration;
    this.sectionTypeList = data.sectionTypeList;
    this.displayedColumns = !this.section.isPublic ?
      ['picture', 'userName', 'speaker', 'attendee'] : ['picture', 'userName', 'speaker'];
    this.selfLearningSectionFieldsDisable = !isEmpty(
      this.timeLineService.isSectionHierarchicalParentSelfLearning(this.section)) ||
      (this.section.id && this.timeLineService.hasSelfLearningSectionInChilds(this.section)) || this.section.freeSlotType;
    this.containerFieldDisable = !this.selfLearningSectionFieldsDisable && this.sectionCanBeContainer();
    this.parentIsContainer = this.getParentContainerProperty();
    this.eventInstantSettings = this.timeLineService.eventInstantSettings;
    this.isInstantGroupRestrictAccessMembers =
      this.section.parentId && this.timeLineService.planeListContent[this.section.parentId] &&
      this.timeLineService.planeListContent[this.section.parentId].sectionContent.isInstantGroupRestrictAccessMembers();
    this.uppy = this.uploadService.createFileUploader(UPLOAD_TYPE.IMAGE, null, (result) => {
      this.section.backgroundImage = !isEmpty(result) ? result[0].response : null;
      this.uploadService.closeModal(this.uppy);
    });
    this.defaultLanguage = this.timeLineService.DEFAULT_LANGUAGE;
    this.currentLanguage = this.defaultLanguage;
    const ml = this.timeLineService.event.getMultilingual();
    this.multilingual = ml.multilingual;
    this.usedLanguages = ml.usedLanguages;
    this.languageParams = {
      defaultLanguage: this.defaultLanguage,
      currentLanguage: this.currentLanguage,
      usedMultilingualContent: this.multilingual,
      usedLanguages: this.usedLanguages
    };
    this.translateHint = this.translateApiService.getTranslateHint(this.defaultLanguage, this.currentLanguage);
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  get enabledLanguages(): string[] {
    return this.section.enabledLanguages || [];
  }

  set enabledLanguages(value: string[]) {
    this.section.enabledLanguages = value;
  }

  get excludeFromAutopilot(): boolean {
    return !this.section.excludeFromAutopilot;
  }

  set excludeFromAutopilot(value: boolean) {
    this.section.excludeFromAutopilot = !value;
  }

  set sectionTitle(value: string) {
    this.section.setTitleByLanguage(value, this.languageParams);
  }

  get sectionTitle() {
    return this.section.getTitleByLanguage(this.languageParams);
  }

  set sectionSubtitle(value: string) {
    this.section.setSubtitleByLanguage(value, this.languageParams);
  }

  get sectionSubtitle() {
    return this.section.getSubtitleByLanguage(this.languageParams);
  }

  set sectionDescription(value: string) {
    this.section.setDescriptionByLanguage(value, this.languageParams);
  }

  get sectionDescription() {
    return this.section.getDescriptionByLanguage(this.languageParams);
  }


  roleCanEditContent() {
    if (this.isPresenter || this.isSpeaker) {
      return false;
    }
    return this.timeLineService.conferenceUser.isEditor ||
      (this.timeLineService.conferenceUser.isTranslator && this.timeLineService.event.getMultilingual().multilingual);
  }

  isRoleTranslator() {
    if (this.isPresenter || this.isSpeaker) {
      return false;
    }
    return this.timeLineService.conferenceUser.isTranslator;
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this.sectionDetectChanges.pipe(filter(() => !!this.sectionHash && !!this.section?.id), this.takeUntilAlive())
      .subscribe(() => {
        this.isModify = this.sectionHash !== this.getSectionHash(this.section, this.registrationSettings);
      });
    if (this.roleCanEditContent()) {
      return;
    }
    this.paginator.pageSize = this.pageLength;
    this.paginator.ngOnInit();
    this.dataSource.paginator = this.paginator;
    this.store.select(fromRoot.getUsersOnline).pipe(this.takeUntilAlive())
      .subscribe(uOnline => {
        if (isEmpty(uOnline) && this.firstOpen) {
          this.firstOpen = false;
          this.eventDataService.dispatchUsersOnline(this.currentEvent.eventId);
        } else if (!isEmpty(uOnline)) {
          this.presenters = {};
          const ul = {};
          uOnline.forEach(u => {
            if (u.isViewer) {
              this.viewerUsers[u.userId] = true;
            }
          });
          this.onlineUsers = union(uOnline,
            Object.keys(this.currentEvent.managers || {})
              .map(userId => new ConferenceUser({userId: userId, ...this.currentEvent.managers[userId]})),
            Object.keys(this.currentEvent.speakers || {})
              .map(userId => new ConferenceUser({userId: userId, ...this.currentEvent.speakers[userId]})));
          const idList = Object.keys(this.currentEvent.presenters ? this.currentEvent.presenters : {});
          for (let i = 0; i < idList.length; i++) {
            const u = this.getOnlineUser(idList[i]);
            if (u) {
              ul[u.userId] = u;
            }
          }
          this.presenters = ul;
          this.dataSource.data = this.reinitData();
        }
      });
    if (!this.isPresenter && !!this.section.id) {
      this.eventDataService.getSectionUsersDataPromise(this.currentEvent.eventId, this.section.id)
        .then((value) => {
          this.dataSource.data = this.reinitData();
        });
    }
    this.eventDataService.getUsersDataPromise(this.currentEvent.eventId).then(value => {
      this.usersData = value;
      if (this.section.id && !isEmpty(this.usersData)) {
        this.oldUsersList = this.usersData.filter(u => (u.sections || []).find(sId => sId === this.section.id))
          .map(u => new SectionUser({userId: u.userId, attendee: true}));
      }
      this.dataSource.data = this.reinitData();
    });
    this.freeSlotTimeChange.pipe(this.takeUntilAlive())
      .subscribe(changeValue => {
        if (!this.section.id) {
          let sectionInitiator = this.newSlotInitData.sectionInitiator;
          const initiatorRoot = sectionInitiator.isRoot;
          if (this.newSlotInitData.addMode === ADD_NEW_SLOT_MODE.ABOVE) {
            const list = this.timeLineService.planeListContentSortedWithoutFixedAsSectionArray()
              .filter(ps => ps.parentId === this.timeLineService.rootSection.id);
            const index = list.findIndex(s => s.id === sectionInitiator.id);
            if (index === 0) {
              sectionInitiator = new SectionContent({...cloneDeep(this.timeLineService.rootSection),
                parentId: this.timeLineService.rootSection.id});
            } else {
              sectionInitiator = list[index - 1];
            }
          }
          this.contentService.cutContent(this.section, Constants.CONTENT_CLIPBOARD_CUT);
          this.contentService.clipboard.moveSectionList.push(new PlaneContent(new SectionTimeline(this.section), null));
          const emulator = new TimeLineEmulator(this.timeLineService, this.timeLineService.getManageTimeService(),
            this.contentService, this.utils, this.loginService.getAppUser(), true, sectionInitiator, !initiatorRoot);
          emulator.runEmulator(EMULATE_RESULT.AS_IS);
          const slotTP = this.timeLineService.getManageTimeService().testManageTimeMap['1'];
          const checkError = this.timeLineService.getManageTimeService()
            .checkCorrectTimeMap(slotTP, this.timeLineService.getManageTimeService().testManageTimeMap);
          this.slotTimeRestricted = !checkError.noError;
          this.contentService.cancelCutContent();
        } else {
          const slotTP = this.timeLineService.mangeTimeMap[this.section.id];
          const testTP = this.timeLineService.getManageTimeService()
            .createTestTimePoint(slotTP, changeValue.value, changeValue.type);
          this.timeLineService.createNewMangeTime(testTP, false);
          const checkError = this.timeLineService.getManageTimeService()
            .checkCorrectTimeMap(testTP, this.timeLineService.mangeTimeMap);
          this.slotTimeRestricted = !checkError.noError;
        }
      });
    this.setLinkToLiveStreamingValid();
  }

  onDestroy() {
    super.onDestroy();
    this.store.dispatch(new ui.SetModalDialogOpen(false));
    this.uppy.close();
  }

  ngAfterViewInit() {
    if (this.roleCanEditContent()) {
      const panels = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('mat-expansion-panel');
      const length = panels.length;
      for (let i = 0; i < length; i++) {
        panels.item(0).remove();
      }
    }
  }

  onOkClick() {
    this.setSectionResultValues();
    this.dialogRef.close({
      section: this.section,
      registrationSettings: this.registrationSettings,
      updatedMeetingRooms: this.checkPermanentRoomsBeforeSaveSetting()
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setSectionResultValues() {
    this.section.users = this.getSectionUsersFromTable();
    if (this.section.id && !this.section.sectionTypeId) {
      this.section = merge(this.section, {sectionTypeId: null});
    }
    if (!!this.currentEvent.readonlyHKVBS) {
      this.section.sectionTimeIsNotActive = true;
    }
    if (this.section.isInstantGroup) {
      if (!this.section.id) {
        this.section.sectionTimeIsNotActive = true;
      }
    }
    if (this.oldBackgroundImage && !this.section.backgroundImage) {
      this.section.backgroundImage = '-' + this.oldBackgroundImage;
    }
  }

  getOnlineUser(userId: string): ConferenceUser {
    return this.onlineUsers.find( function (user) {
      return user.userId === userId;
    });
  }

  private reinitData() {
    const localOnlineUsers = this.onlineUsers.slice();
    const sectionUserData = !isEmpty(this.usersData) ?
      this.usersData : [];

    for (const eUser of localOnlineUsers) {
      const uId = eUser.userId;
      if (eUser && !eUser.blocked) {
        let attendeeSection: IUsersSectionsData;
        this.initData[uId] = {
          userId: uId,
          picture: eUser.picture ? eUser.picture : 'assets/images/photo.jpg',
          userName: eUser.fullName ? eUser.fullName : eUser.email,
          email: eUser.email,
          speakers_attendees: (this.presenters[uId] || this.currentEvent.speakers[uId] || this.currentEvent.concierges[uId]) ?
            (this.currentEvent.concierges[uId] ? Constants.CONCIERGES : Constants.SPEAKERS) : Constants.ATTENDEES,
          rightAboveSpeaker: this.rightAboveSpeaker(eUser) === Constants.ACCESS_SET_ABOVE,
          rightAboveAttendee: this.rightAboveAttendee(eUser) === Constants.ACCESS_SET_ABOVE,
          speaker: !(this.changedUserRole[uId] &&
            this.changedUserRole[uId]['roleType'] === Constants.SPEAKERS && this.changedUserRole[uId]['changed']) ?
             this.timeLineService.isSpeakerContent(eUser, this.section as SectionTimeline) :
             this.initData[uId].speaker,
          attendee: !(this.changedUserRole[uId] &&
            this.changedUserRole[uId]['roleType'] === Constants.ATTENDEES && this.changedUserRole[uId]['changed']) ?
             this.timeLineService.isAttendeeContent(eUser, this.section as SectionTimeline,
               ((attendeeSection = sectionUserData.find(o => o.userId === uId)) ? attendeeSection.sections : [])) :
             this.initData[uId].attendee,
          userRoleViewer: this.viewerUsers[uId],
        };
        this.initData[uId].rowHash = UtilsService.jsonSorted({attendee: this.initData[uId].attendee, speaker: this.initData[uId].speaker});
      }
    }
    return this.getResultSet();
  }

  private getResultSet(): any [] {
    this.resultSet = [];
    for (const rowId of Object.keys(this.initData)) {
      if (this.searchFilter(this.initData[rowId]) &&
        this.speakersAttendesAddRow(this.initData[rowId])) {
        const rowData = this.initData[rowId];
        this.resultSet.push(rowData);
      }
    }
    return this.resultSet;
  }

  applyFilter(searchValue: string) {
    this.searchValue = searchValue;
    this.dataSource.data = this.getResultSet();
  }

  private searchFilter(row): boolean {
    if (this.searchValue.length > 0) {
      if ((row.userName && row.userName.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) ||
          (row.email && row.email.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  onSpeakersAttendeesFilter(status) {
    this.speakers_attendees = status;
    this.dataSource.data = this.getResultSet();
  }

  private speakersAttendesAddRow(row) {
    if (this.speakers_attendees === Constants.SPEAKERS_ATTENDEES) {
      return true;
    }
    if (this.speakers_attendees === Constants.SPEAKERS &&
      row.speakers_attendees === Constants.SPEAKERS) {
      return true;
    }
    if (this.speakers_attendees === Constants.ATTENDEES &&
      row.speakers_attendees === Constants.ATTENDEES) {
      return true;
    }
    return false;
  }

  private currentAccess(userId): SectionUser {
    if (!this.section.users) {return null; }
    return this.section.users.find(user => user.userId === userId);
  }

  private rightAboveSpeaker(cUser) {
    if (this.currentAccess(cUser.userId) && this.currentAccess(cUser.userId).speaker) {
      return Constants.ACCESS_SET_CURRENT;
    } else
    if (this.timeLineService.isSpeakerSectionExcludeItself(cUser, this.section as SectionTimeline)) {
      return Constants.ACCESS_SET_ABOVE;
    } else {
      return Constants.ACCESS_NOT_SET;
    }
  }

  private rightAboveAttendee(cUser) {
    const _userData = this.usersData;
    let sectionUserData: IUsersSectionsData;
    if (!isEmpty(_userData)) {
      sectionUserData = _userData.find(o => o.userId === cUser.userId);
    }
    if (this.currentAccess(cUser.userId) && this.currentAccess(cUser.userId).attendee) {
      return Constants.ACCESS_SET_CURRENT;
    } else
    if (this.timeLineService.isAttendeeSectionExcludeItself(
      cUser, this.section as SectionTimeline, !isEmpty(sectionUserData) ? sectionUserData.sections : [])
    ) {
      return Constants.ACCESS_SET_ABOVE;
    } else {
      return Constants.ACCESS_NOT_SET;
    }
  }

  getParentContainerProperty() {
    return this.timeLineService.planeListContent[this.section.parentId] &&
      this.timeLineService.planeListContent[this.section.parentId].container;
  }

    private sectionCanBeContainer() {
    let hasContainer, hasChildsChilds, hasFeatureLineWithin;
    // if content.id null this is new content.
    if (this.section.id) {
      const childList = this.timeLineService.childTreeInLineList(this.section.id);
      // check if some child section as container
      hasContainer = childList.some(item => item['container'] && item.id !== this.section.id);
      // check if some child have some child
      hasChildsChilds = childList.some(
        item => item.id !== this.section.id && item.items && item.items.length > 0
      );
      // check if some child is "featureLine" aka "currentTime"
      hasFeatureLineWithin = childList.some(item => item.id !== this.section.id && item.id === this.timeLineService.featureLineContentId);
    }
    // check if parent is container.
    const parentIsContainer = this.timeLineService.planeListContent[this.section.parentId] &&
      this.timeLineService.planeListContent[this.section.parentId].container;
    return hasContainer || hasChildsChilds || parentIsContainer || hasFeatureLineWithin;
  }

  displayPublicChange(event) {
    this.section.isPublic = !event.checked;
    this.displayedColumns = !this.section.isPublic ?
      ['picture', 'userName', 'speaker', 'attendee'] : ['picture', 'userName', 'speaker'];
  }

  onRoleChange(event: MatCheckboxChange, row, roleType) {
    const user = row;
    this.changedUserRole[row.userId] = {roleType: roleType,
      changed: !(this.changedUserRole[row.userId] ? this.changedUserRole[row.userId]['changed'] : false)};
    if (roleType === Constants.SPEAKERS) {
      const aboveValue = this.rightAboveSpeaker(user);
      if (row.rightAboveSpeaker && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.speaker = true;
        row.rightAboveSpeaker = false;
        event.checked = true;
      } else
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.speaker = true;
        row.rightAboveSpeaker = true;
        event.checked = true;
      }
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.speaker = this.timeLineService.isSpeakerSectionExcludeItself(user, this.section as SectionTimeline);
        row.rightAboveSpeaker = row.speaker;
        event.checked = row.speaker;
      }
      if (row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.speaker = this.timeLineService.isSpeakerSectionExcludeItself(user, this.section as SectionTimeline);
        row.rightAboveSpeaker = false;
        event.checked = row.speaker;
      }
    }

    if (roleType === Constants.ATTENDEES) {
      const _userData = this.usersData;
      let sectionUserData: IUsersSectionsData;
      if (!isEmpty(_userData)) {
        sectionUserData = _userData.find(o => o.userId === user.userId);
      }
      const aboveValue = this.rightAboveAttendee(user);
      if (row.rightAboveAttendee && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.attendee = true;
        row.rightAboveAttendee = false;
        event.checked = true;
      } else
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_ABOVE && !event.checked) {
        row.attendee = true;
        row.rightAboveAttendee = true;
        event.checked = true;
      }
      if (!row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.attendee = this.timeLineService.isAttendeeSectionExcludeItself(user,
          this.section as SectionTimeline, !isEmpty(sectionUserData) ? sectionUserData.sections : []);
        row.rightAboveAttendee = row.attendee;
        event.checked = row.attendee;
      }
      if (row.rightAbove && aboveValue === Constants.ACCESS_SET_CURRENT && !event.checked) {
        row.attendee = this.timeLineService.isAttendeeSectionExcludeItself(user,
          this.section as SectionTimeline, !isEmpty(sectionUserData) ? sectionUserData.sections : []);
        row.rightAboveAttendee = false;
        event.checked = row.attendee;
      }
    }
    this.isUsersModify = this.dataSource.data.some(u =>
        u.rowHash !== UtilsService.jsonSorted({attendee: this.initData[u.userId].attendee, speaker: this.initData[u.userId].speaker}));
  }

  inputFocus() {
    this._inputFocus = true;
  }

  inputKeyDown() {
    if (this._inputFocus && !this._detach) {
      this._detach = true;
      this.changeDetector.detach();
    }
  }

  inputKeyUp() {
    this.changeDetector.detectChanges();
  }

  inputBlur() {
    this._inputFocus = false;
    this._detach = false;
    this.changeDetector.reattach();
  }

  getMatSelectOptionByValue(options: any[], value): any {
    return options.find(obj => obj['id'] === value);
  }

  getSectionUsersFromTable() {
    const formatRow = (row) => {
      const ret = clone(row);
      ret.speaker = !ret.rightAboveSpeaker ? ret.speaker : false;
      ret.attendee = !ret.rightAboveAttendee ? ret.attendee : false;
      return ret;
    };

    const res: SectionUser[] = [];
    for (const row of this.utils.objectValues(this.initData)) {
      if (!row['rightAboveSpeaker'] && row['speaker']) {
        res.push(new SectionUser().setValues(formatRow(row)));
      } else
      if (!row['rightAboveAttendee'] && row['attendee']) {
        res.push(new SectionUser().setValues(formatRow(row)));
      }
    }
    // add and mark deleted users
    for (const u of this.oldUsersList) {
      if (isEmpty(res.find(o => o.userId === u.userId))) {
        u.userId = '*' + u.userId;
        res.push(u);
      }
    }
    return res;
  }

  onscroll(e) {
    const tableViewHeight = e.target.offsetHeight;
    const tableScrollHeight = e.target.scrollHeight;
    const scrollLocation = e.target.scrollTop;
    const buffer = SECTION_USER_LIST_PAGE.BUFFER;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.pageLength += SECTION_USER_LIST_PAGE.PAGE_STEP;
      this.paginator._changePageSize(this.pageLength);
    }
  }

  onChangeContainer(event) {
     if (this.section.isInstantGroup && !event.checked) {
       this.section.isInstantGroup = false;
       this.section.restrictAccessMembers = false;
     }
  }

  uploadImage() {
    if (!this.section.backgroundImage) {
      this.uploadService.openUploadWindow(this.uppy);
    } else {
      this.section.backgroundImage = null;
    }
  }

  exclusivityGroupChang(event) {
    if (event) {
      this.registrationSettings.mandatoryForEventRegistration = false;
    }
  }

  includeSectionInRegistrationForm(event) {
    if (event && !event.checked) {
      this.registrationSettings.mandatoryForEventRegistration = false;
    }
  }

  get selectedSectionSpecificVideoConferences() {
    return this.section.specificVideoConferences;
  }

  set selectedSectionSpecificVideoConferences(value: boolean) {
    this.section.specificVideoConferences = value;
    if (value && this.section.container) {
      if (isEmpty(this.section.virtualConferenceSettings)) {
        this.section.virtualConferenceSettings = VirtualConferenceSettings.getDefaultSectionVirtualConferenceSettings(true);
      }
      this.prevLinkToLiveStreaming = this.section.virtualConferenceSettings.linkToLiveStreaming;
      this.prevTypeOfConference = this.section.virtualConferenceSettings.typeOfConference;
    } else if (value && this.parentIsContainer) {
      if (!this.section.specificVideoConferences) {
        if (isEmpty(this.section.virtualConferenceSettings)) {
          this.section.virtualConferenceSettings = VirtualConferenceSettings.getDefaultSectionVirtualConferenceSettings(false);
        } else {
          const parent = this.timeLineService.getSectionContent(this.section.parentId);
          this.section.virtualConferenceSettings = cloneDeep(parent.virtualConferenceSettings);
        }
      } else if (this.section.specificVideoConferences && isEmpty(this.section.virtualConferenceSettings)) {
        this.section.virtualConferenceSettings = VirtualConferenceSettings.getDefaultSectionVirtualConferenceSettings(false);
      }
    } else if (value && !this.parentIsContainer && !this.section.container) {
      if (isEmpty(this.section.virtualConferenceSettings)) {
        this.section.virtualConferenceSettings = VirtualConferenceSettings.getDefaultSectionVirtualConferenceSettings(false);
      }
    }
  }

  get virtualConferenceSettingsMainEventOption() {
    return this.section.virtualConferenceSettings.mainEventOption;
  }

  set virtualConferenceSettingsMainEventOption(value: VIRTUAL_CONFERENCE_EVENT) {
    this.section.virtualConferenceSettings.mainEventOption = value;
  }

  changeLinkToLiveStreaming(url) {
    if (url) {
      const site = this.timeLineService.utils.getVideoSiteAndVideoIdFromUrl(url, true);
      let videoUrl = url;
      if (site) {
        this.section.virtualConferenceSettings.linkToLiveStreaming = site.url;
        videoUrl = site.url;
      }
      const sandbox = videoUrl && videoUrl.includes('https://streamingmedia.') ? 'sandbox="allow-forms allow-same-origin allow-scripts allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation"' : '';
      this.section.virtualConferenceSettings.embedVariables =
        `<iframe src="${videoUrl}?${this.section.virtualConferenceSettings.autoplay || this.section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING_THIRD_PARTY ? `autoplay=1` : ``}" style="display: block; position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%;"frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ${sandbox} allowfullscreen></iframe>`;
      const kaltura = this.section.virtualConferenceSettings.linkToLiveStreaming &&
        this.common.utils.isKalturaRecordedStreaming(this.section.virtualConferenceSettings.linkToLiveStreaming) &&
        this.common.utils.parseKalturaUrl(this.section.virtualConferenceSettings.linkToLiveStreaming);
      if (kaltura) {
        if (kaltura.partnerId) {
          this.section.virtualConferenceSettings.kalturaPartnerId = kaltura.partnerId;
        }
        if (kaltura.playerId) {
          this.section.virtualConferenceSettings.kalturaPlayerId = kaltura.playerId;
        }
      }
    } else {
      this.section.virtualConferenceSettings.linkToLiveStreaming = null;
      this.section.virtualConferenceSettings.embedVariables = null;
    }

    this.setLinkToLiveStreamingValid();
  }

  setLinkToLiveStreamingValid() {
    const streamings = [VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_STREAMING,
      VIRTUAL_CONFERENCE_EVENT.RECORDED_VIDEO_STREAMING,
      VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING];
    this.linkToLiveStreamingValid = !this.section.specificVideoConferences ||
      (!streamings.includes(this.section.virtualConferenceSettings?.mainEventOption) ||
        !!(this.section.virtualConferenceSettings?.linkToLiveStreaming || ``).trim());
  }

  get virtualConferenceSettingsTypeOfConference() {
    return this.section.virtualConferenceSettings.typeOfConference;
  }

  set virtualConferenceSettingsTypeOfConference(value: VIRTUAL_CONFERENCE_TYPE) {
    this.prevTypeOfConference = this.section.virtualConferenceSettings.typeOfConference;
    this.section.virtualConferenceSettings.typeOfConference = value;
  }

  saveSectionVideoConferencesSettings() {
    if (this.section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.EXTERNAL_VIDEO_STREAMING) {
      this.section.virtualConferenceSettings.defaultSize = MEETING_VIEW_MODE.MINIMIZED;
      this.section.virtualConferenceSettings.allowOpeningInSeparateTab = true;
    } else if (this.section.virtualConferenceSettings.defaultSize === MEETING_VIEW_MODE.MINIMIZED) {
      this.section.virtualConferenceSettings.defaultSize = MEETING_VIEW_MODE.INTERNAL;
    }

    this.setLinkToLiveStreamingValid();
  }

  addDefaultPermanentRoomsBeforeSaveSetting() {
    if (this.section.specificVideoConferences && this.section.virtualConferenceSettings &&
      this.section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE &&
      this.section.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO &&
      this.section.virtualConferenceSettings.dailyCoSettings &&
      this.section.virtualConferenceSettings.dailyCoSettings.usePermanentRooms &&
      isEmpty(this.section.virtualConferenceSettings.dailyCoSettings.permanentRooms)) {
      this.section.virtualConferenceSettings.dailyCoSettings.permanentRooms = [];
      this.section.virtualConferenceSettings.dailyCoSettings.permanentRooms
        .push({
          roomId: 'ROOM-' + new Date().getTime(),
          roomName: this.common.i18n('virtual.conference.dailyco.default.permanent.room.name'),
          permanent: true
        });
    }
  }

  checkPermanentRoomsBeforeSaveSetting() {
    if (this.section.specificVideoConferences && this.section.virtualConferenceSettings &&
      this.section.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE &&
      this.section.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO &&
      this.section.virtualConferenceSettings.dailyCoSettings &&
      this.section.virtualConferenceSettings.dailyCoSettings.usePermanentRooms) {
      // sync with created dailyCo room
      const srcRooms: IRoom[] = this.srcVirtualSettings?.dailyCoSettings?.permanentRooms;
      const curRooms: IRoom[] = this.section.virtualConferenceSettings.dailyCoSettings.permanentRooms;
      (srcRooms || []).forEach(r => r['draft'] = true);
      (curRooms || []).forEach(r => {
        const rObj = (srcRooms || []).find(sr => sr.roomId === r.roomId);
        if (rObj) {
          delete rObj['draft'];
        }
      });
      return {deleted: (srcRooms || []).filter(r => r['draft']), updated: curRooms};
    }
    return null;
  }

  setNewSlotCalculatedTime() {
    let sectionInitiator = this.newSlotInitData.sectionInitiator;
    const initiatorRoot = sectionInitiator.isRoot;
    if (this.newSlotInitData.addMode === ADD_NEW_SLOT_MODE.ABOVE) {
      const list = this.timeLineService.planeListContentSortedWithoutFixedAsSectionArray()
        .filter(ps => ps.parentId === this.timeLineService.rootSection.id);
      const index = list.findIndex(s => s.id === sectionInitiator.id);
      if (index === 0) {
        sectionInitiator = new SectionContent({...cloneDeep(this.timeLineService.rootSection),
          parentId: this.timeLineService.rootSection.id});
      } else {
        sectionInitiator = list[index - 1];
      }
    }
    this.contentService.cutContent(this.section, Constants.CONTENT_CLIPBOARD_CUT);
    this.contentService.clipboard.moveSectionList.push(new PlaneContent(new SectionTimeline(this.section), null));
    const emulator = new TimeLineEmulator(this.timeLineService, this.timeLineService.getManageTimeService(),
      this.contentService, this.utils, this.loginService.getAppUser(), true, sectionInitiator, !initiatorRoot);
    emulator.runEmulator(EMULATE_RESULT.AS_IS);
    const slotTP: TimePoint = this.timeLineService.getManageTimeService().testManageTimeMap['1'];

    this.section.plannedTime = slotTP.startValue;
    this.section.plannedTimeFixed = true;
    this.section.plannedTimeFixedValue = this.section.plannedTime;

    this.section.duration = Math.trunc((slotTP.endValue - slotTP.startValue) / Constants.ONE_MINUTE_MS);
    this.section.durationFixedValue = this.section.duration;
    this.section.durationFixed = false;

    this.section.endTime = slotTP.endValue;
    this.section.endTimeFixed = true;
    this.section.endTimeFixedValue = slotTP.endValue;

    this.contentService.cancelCutContent();
  }

  showDependencyDialog() {
    let dialogWidth = '450px';
    if (window.innerWidth < 450) {
      dialogWidth = '99%';
    }
    const dialogRef = this.dialog.open(ContentDependencySettingsDialogComponent, {
      width: dialogWidth
      , minWidth: dialogWidth
      , disableClose: true
      , data: {
        eventId: this.timeLineService.event.eventId,
        dependencyQuestionnaire: this.section.dependencyQuestionnaire
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.section.dependencyQuestionnaire = !isEmpty(result) ? result : null;
      }
    });
  }

  onLanguageChange(value) {
    this.currentLanguage = value;
    this.languageParams = {
      defaultLanguage: this.defaultLanguage,
      currentLanguage: this.currentLanguage,
      usedMultilingualContent: this.multilingual,
      usedLanguages: this.usedLanguages
    };
    this.translateHint = this.translateApiService.getTranslateHint(this.defaultLanguage, this.currentLanguage);
  }

  translate(text: string, fieldName: string) {
    this.translating = {[fieldName]: true};
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => this[fieldName] = v)
      .finally(() => this.translating = null);
  }

  async onRestoreDescriptionTranslation() {
    if (await this.common.confirmation(this.common.i18n('translate.restore.to.default.confirmation'))) {
      const params = {
        defaultLanguage: this.defaultLanguage,
        currentLanguage: this.defaultLanguage,
        usedMultilingualContent: this.multilingual,
        usedLanguages: this.usedLanguages
      };
      const text = this.section.getDescriptionByLanguage(params);
      this.section.setDescriptionByLanguage(text, this.languageParams);
    }
  }

  setSectionObjects(data) {
    this.section = UtilsService.wrapObjectToProxy(cloneDeep(data.section), this.sectionChangesHandler);
    this.registrationSettings = UtilsService.wrapObjectToProxy(data.registrationSettings ?
        cloneDeep(data.registrationSettings) : new RegistrationSettings(), this.sectionChangesHandler);
    this.srcVirtualSettings = cloneDeep(data.section.virtualConferenceSettings);
    this.sectionHash = this.getSectionHash(this.section, this.registrationSettings);
    this.isModify = !this.section.id;
  }

  getSectionHash(section: SectionContent, registrationSettings: RegistrationSettings, ) {
    const s = UtilsService.jsonSorted(section);
    const r = UtilsService.jsonSorted(registrationSettings);
    return UtilsService.md5(s + r);
  }

  changeSlotTime(value, type: ROW_TIME_TYPE.START | ROW_TIME_TYPE.END) {
    switch (type) {
      case ROW_TIME_TYPE.START:
        this.section.plannedTime = value;
        this.section.plannedTimeFixedValue = value;
        this.section.plannedTimeFixed = true;
        break;
      case ROW_TIME_TYPE.END:
        this.section.endTime = value;
        this.section.endTimeFixedValue = value;
        this.section.endTimeFixed = true;
        break;
    }
    this.section.duration = Math.trunc((this.section.endTime - this.section.plannedTime) / Constants.ONE_MINUTE_MS);
    this.section.durationFixedValue = this.section.duration;
    this.section.durationFixed = false;
    this.freeSlotTimeChange.next({value: value, type: type});
  }
}
