import {AbstractContent} from './AbstractContent';
import {Constants, LINK_STATE, USER_TASK_STATUS} from '../../core/constants';
import {isEmpty} from 'lodash';

export interface IUploadFileLink {id: string; src: string; name: string; upload: LINK_STATE; metaType: string; }
export interface ITaskDocumentSpeaker {speakerId: string; name: string; picture: string; }

export class TaskDocument extends AbstractContent {
  type = Constants.CONTENT_TYPE_TASK_DOCUMENT;
  description: string;
  directLink: string;
  uploadFileLinks: IUploadFileLink[];
  collectResultAsTextLink: boolean;
  collectResultAsFile: boolean;
  maximalPoints: number;
  // version control system for modules
  releaseVersion: string;
  dirty: boolean;

  constructor(obj: any) {
    super(obj);

    if (!obj) {
      return;
    }
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.directLink) {
      this.directLink = obj.directLink;
    }
    if (obj.collectResultAsTextLink) {
      this.collectResultAsTextLink = obj.collectResultAsTextLink;
    }
    if (obj.collectResultAsFile) {
      this.collectResultAsFile = obj.collectResultAsFile;
    }
    if (obj.maximalPoints) {
      this.maximalPoints = obj.maximalPoints;
    }
    if (!isEmpty(obj.uploadFileLinks)) {
      this.uploadFileLinks = obj.uploadFileLinks;
    }
    if (obj.hasOwnProperty('releaseVersion')) {
      this.releaseVersion = obj.releaseVersion;
    }
    if (obj.hasOwnProperty('dirty')) {
      this.dirty = obj.dirty;
    }
  }

  mergeContent(src: TaskDocument) {
    super.mergeContent(src);

    if (this.description !== src.description) {
      this.description = src.description;
    }
    if (this.directLink !== src.directLink) {
      this.directLink = src.directLink;
    }
    if (this.collectResultAsTextLink !== src.collectResultAsTextLink) {
      this.collectResultAsTextLink = src.collectResultAsTextLink;
    }
    if (this.collectResultAsFile !== src.collectResultAsFile) {
      this.collectResultAsFile = src.collectResultAsFile;
    }
    if (this.maximalPoints !== src.maximalPoints) {
      this.maximalPoints = src.maximalPoints;
    }
    if (this.releaseVersion !== src.releaseVersion) {
      this.releaseVersion = src.releaseVersion;
    }
    if (this.dirty !== src.dirty) {
      this.dirty = src.dirty;
    }
    this.uploadFileLinks = src.uploadFileLinks;
  }
}

export class UserTaskDocumentObject {
  userId: string;
  eventId: string;
  contentId: string;
  userStatus: USER_TASK_STATUS = USER_TASK_STATUS.NEW;
  textAnswer: string;
  uploadFileLinks: IUploadFileLink[] = [];
  feedback: string;
  points: string;
  grade: string;
  speaker: ITaskDocumentSpeaker;
  constructor(obj: any) {
    if (!obj) {
      return;
    }
    if (obj.userId) {
      this.userId = obj.userId;
    }
    if (obj.eventId) {
      this.eventId = obj.eventId;
    }
    if (obj.contentId) {
      this.contentId = obj.contentId;
    }
    if (obj.userStatus) {
      this.userStatus = obj.userStatus;
    }
    if (obj.textAnswer) {
      this.textAnswer = obj.textAnswer;
    }
    if (!isEmpty(obj.uploadFileLinks)) {
      this.uploadFileLinks = obj.uploadFileLinks;
    }
    if (obj.feedback) {
      this.feedback = obj.feedback;
    }
    if (obj.points) {
      this.points = obj.points;
    }
    if (obj.grade) {
      this.grade = obj.grade;
    }
    if (obj.speaker) {
      this.speaker = obj.speaker;
    }
  }

  getUploadFileNameList() {
    if (isEmpty(this.uploadFileLinks)) {
      return null;
    }
    let result = '';
    this.uploadFileLinks.forEach(l => result = result.length > 0 ? (result + ',' + l.name) : l.name );
    return result;
  }

  toObject() {
    return {...this};
  }
}
