import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {IAnswer, IAnswerRow, IMatching} from '../../../quiz-model/quiz';
import {cloneDeep, isEmpty, union, uniq} from 'lodash';
import {Constants} from '../../../../../../../core/constants';
import {CdkDragDrop, CdkDragStart} from '@angular/cdk/drag-drop';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {IQuestionMatchingOptions} from '../question-matching.module';

@Component({
  selector: 'app-question-matching-participant',
  templateUrl: './question-matching-participant.component.html',
  styleUrls: ['./question-matching-participant.component.scss']
})
export class QuestionMatchingParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  displayedColumnsMatching = ['answer', 'matching'];
  displayedMatching = ['matching'];
  dataSource: IAnswer[] = [];
  dataSourceMatching: IMatching[] = [];
  connections: string[] = [];
  startDrag = false;
  mouseDownDragElement: HTMLElement;
  mouseDownDragElementHeight: string;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  get questionOptions(): IQuestionMatchingOptions {
    return this.question.options;
  }

  protected initQuestionAnswersDataSource() {
    const dataMatchingList: IMatching[] = [];
    this.connections = [];
    (this.question.matchingItems || []).forEach(m => {
      const index = dataMatchingList.findIndex(o => o.id === m.id);
      if (index > -1) {
        dataMatchingList[index] = {id: m.id, orderIndex: m.orderIndex, answerMatching: m.getAnswerByLanguage(this.languageParams)};
      } else {
        dataMatchingList.push({id: m.id, orderIndex: m.orderIndex, answerMatching: m.getAnswerByLanguage(this.languageParams)});
      }
      this.connections.push('row-matching-' + m.id);
    });
    dataMatchingList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.dataSourceMatching = dataMatchingList;
    this.dataSource = this.updateAnswers();
  }

  protected onReceiveQuestionAnswers() {
    this.dataSource = this.updateAnswers();
  }

  private updateAnswers(): IAnswer[] {
    const dataList: IAnswer[] = [];
    if (isEmpty(this.answers)) {
      this.question.items.forEach(answer => {
        this.connections.push('row-answer-' + answer.id);
        dataList.push(
          {
            answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex,
            answersMatching: null
          });
      });
    } else {
      const preparedAnswers = this.answers.reduce((accum, item) => {
        const arr = item.split('-');
        const matchingId = arr[0];
        accum = union(accum, arr.filter(v => v !== matchingId).map(aId => `${matchingId}-${aId}`));
        return accum;
      }, []);
      const answers: {[id: string]: IAnswerRow[]} = preparedAnswers.reduce((accum, item) => {
        const arr = item.split('-');
        const mc = this.question.matchingItems.find(m => m.id === arr[1]);
        accum[arr[0]] = union(accum[arr[0]],
          [{answerMatchingId: arr[1], answerMatching: !isEmpty(mc) ? mc.getAnswerByLanguage(this.languageParams) : null}]);
        return accum;
      }, {});
      this.connections = this.connections.filter(id => !id.includes('row-answer-'));
      this.question.items.forEach((answer, index) => {
        this.connections.push('row-answer-' + answer.id);
        if (this.question.options.simpleMatching && answers[answer.id]) {
          this.dataSourceMatching.filter(o => answers[answer.id].map(a => a.answerMatchingId).includes(o.id))
            .forEach(v => v.answerMatching = null);
        }
        dataList.push({
          answer: answer.getAnswerByLanguage(this.languageParams), id: answer.id, orderIndex: answer.orderIndex,
          answersMatching: answers[answer.id]
        });
      });
    }
    return dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
  }

  startDragTab(event: CdkDragStart) {
    if (this.mouseDownDragElement) {
      this.mouseDownDragElement.style.height = this.mouseDownDragElementHeight;
    }
    this.startDrag = true;
  }

  dropListEntered(event) {
    const elem = document.getElementById(event.container.id + '-text');
    if (elem) {
      elem.style.opacity = '0';
    }
  }

  dropListExited(event) {
    const elem = document.getElementById(event.container.id + '-text');
    if (elem) {
      elem.style.opacity = '';
    }
  }

  dropListEnteredMatching(event) {
    const elem = document.getElementById(event.container.id + '-text');
    if (elem) {
      elem.style.opacity = '0';
    }
  }

  dropListExitedMatching(event) {
    const elem = document.getElementById(event.container.id + '-text');
    if (elem) {
      elem.style.opacity = '';
    }
  }

  sendMatchingAnswers() {
    const answers = [];
    this.dataSource.forEach(row => {
      if (!isEmpty(row.answersMatching)) {
        answers.sort(this.common.utils.comparator('answerMatchingId'))
          .push(row.id + '-' + row.answersMatching.map(am => am.answerMatchingId).join('-'));
      }
    });
    this.answers = answers;
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  dropToAnswer(event: CdkDragDrop<string[]>) {
    this.startDrag = false;
    this.connections.forEach(o => {
      const elem = document.getElementById(event.container.id + '-text');
      if (elem) {
        elem.style.opacity = '';
      }
    });
    this.restoreMouseDownDragElement(true);
    const prevId = event.previousContainer.id;
    const curId = event.container.id;
    const qDropId = event.previousContainer.id.split('-')[2];
    const qReceiveId = event.container.id.split('-')[2];
    if (prevId.includes('row-matching-') && curId.includes('row-answer-')) {
      const dropRowIndex = this.dataSourceMatching.findIndex(r => r.id === qDropId);
      const receiveRowIndex = this.dataSource.findIndex(r => r.id === qReceiveId);
      if (dropRowIndex > -1 && receiveRowIndex > -1) {
        const exist = (this.dataSource[receiveRowIndex].answersMatching || []).find(o => o.answerMatchingId === qDropId);
        const am = this.dataSourceMatching[dropRowIndex];
        if (!exist) {
          if (this.questionOptions.multipleAnswersForOneMatching) {
            this.dataSource[receiveRowIndex].answersMatching = union(this.dataSource[receiveRowIndex].answersMatching,
              [{answerMatchingId: am.id, answerMatching: am.answerMatching}]);
          } else {
            if (this.questionOptions.simpleMatching) {
              this.dataSource[receiveRowIndex].answersMatching?.forEach(m => {
                this.dataSourceMatching.find(dm => dm.id === m.answerMatchingId).answerMatching = m.answerMatching;
              });
            }
            this.dataSource[receiveRowIndex].answersMatching = [{answerMatchingId: am.id, answerMatching: am.answerMatching}];
          }
        }
      }
      if (this.question.options.simpleMatching) {
        this.dataSourceMatching[dropRowIndex].answerMatching = null;
      }
      this.sendMatchingAnswers();
    } else if (prevId.includes('row-answerMatching-') && curId.includes('row-answer-')) {
      const prevParent = event.previousContainer.element.nativeElement.parentElement as HTMLElement;
      const prevParentId = prevParent.id.split('-')[2];
      const dropRowIndex = this.dataSource.findIndex(r => r.id === prevParentId);
      const receiveRowIndex = this.dataSource.findIndex(r => r.id === qReceiveId);
      if (dropRowIndex > -1) {
        const dropRowObject = this.dataSource[dropRowIndex];
        const dropRowAnswerIndex = dropRowObject.answersMatching.findIndex(o => o.answerMatchingId === qDropId);
        if (dropRowAnswerIndex > -1 && receiveRowIndex > -1) {
          const exist = (this.dataSource[receiveRowIndex].answersMatching || []).find(o => o.answerMatchingId === qDropId);
          if (!exist) {
            const am = dropRowObject.answersMatching[dropRowAnswerIndex];
            this.dataSource[receiveRowIndex].answersMatching = union(this.dataSource[receiveRowIndex].answersMatching, [am]);
            dropRowObject.answersMatching = dropRowObject.answersMatching.filter(o => o.answerMatchingId !== qDropId);
          }
        }
      }
      this.sendMatchingAnswers();
    }
  }


  dropToMatching(event: CdkDragDrop<string[]>) {
    this.startDrag = false;
    this.connections.forEach(o => {
      const elem = document.getElementById(event.container.id + '-text');
      if (elem) {
        elem.style.opacity = '';
      }
    });
    this.restoreMouseDownDragElement(true);
    if (event.container.id.includes('row-matching-') && event.previousContainer.id.includes('row-matching-')) {
      return;
    }
    const prevId = event.previousContainer.id;
    const curId = event.container.id;
    const qDropId = event.previousContainer.id.split('-')[2];
    if (prevId.includes('row-answerMatching-') && curId.includes('row-matching-')) {
      const prevParent = event.previousContainer.element.nativeElement.parentElement as HTMLElement;
      const prevParentId = prevParent.id.split('-')[2];
      const dropRowIndex = this.dataSource.findIndex(r => r.id === prevParentId);
      if (dropRowIndex > -1) {
        const dropRowObject = this.dataSource[dropRowIndex];
        const dropRowAnswerIndex = dropRowObject.answersMatching.findIndex(o => o.answerMatchingId === qDropId);
        if (dropRowAnswerIndex > -1) {
          dropRowObject.answersMatching = dropRowObject.answersMatching.filter(o => o.answerMatchingId !== qDropId);
          if (!dropRowObject.answersMatching.length) {
            dropRowObject.answersMatching = null;
          }
        }
        const receiveObject = this.dataSourceMatching.find(r => r.id === qDropId);
        if (!receiveObject.answerMatching) {
          const m = this.question.matchingItems.find(o => o.id === receiveObject.id);
          receiveObject.answerMatching = m.getAnswerByLanguage(this.languageParams);
        }
        this.sendMatchingAnswers();
      }
    }
  }

  protected reduceGroupsCorrectAnswers() {
    return this.question.reduceGroupsCorrectAnswersItems()
      .reduce((acc: any[], it) => {
        const el = acc.find(o => o.id === it.id);
        if (!el) {
          acc.push(cloneDeep(it));
        } else {
          el.matching.push(...it.matching);
          el.matching = uniq(el.matching);
        }
        return acc;
      }, []);
  }

  correctMatching(row: IAnswer) {
    const correctItems = this.reduceGroupsCorrectAnswers();
    const answer = correctItems.find(a => a.id === row.id);
    const answersMatchingIdList = row.answersMatching.map(a => a.answerMatchingId);
    return answer && !isEmpty(answer.matching) && !isEmpty(answersMatchingIdList) &&
      answersMatchingIdList.every(id => answer.matching.includes(id));
  }

  onMouseUpDragElement() {
    this.restoreMouseDownDragElement(!this.startDrag);
  }

  private restoreMouseDownDragElement(restore: boolean) {
    if (this.mouseDownDragElement && restore) {
      this.mouseDownDragElement.style.height = '';
      this.mouseDownDragElement = null;
    }
  }

  onMouseDownDragElement(id) {
    const getComponentEnvironmentScale = () => {
      const contentBodyElement = document.getElementById('contentBodyElement');
      if (contentBodyElement) {
        const transform = contentBodyElement.style.transform ?? '';
        if (transform) {
          let scale = transform.substring(transform.indexOf('scale'));
          if (scale) {
            scale = scale.substring(scale.indexOf('(') + 1, scale.indexOf(')'));
            if (scale.match(/[0-9.]+/)) {
              return parseFloat(scale);
            }
          }
        }
      }
      return 1;
    };

    this.mouseDownDragElement = document.getElementById('row-answer-matching-' + id);
    const rect = this.mouseDownDragElement?.getBoundingClientRect();
    if (rect) {
      const scale = getComponentEnvironmentScale();
      this.mouseDownDragElementHeight = `${rect.height / scale}px`;
    }
  }

}
