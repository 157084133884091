import { widthChangeAnim } from './../../animations/animations';
import { CommonService } from './../../core/common.service';
import {AfterViewInit, Component, EventEmitter, Injector, Output, ViewChild} from '@angular/core';
import {MatButtonToggleGroup} from '@angular/material/button-toggle';
import {LoginService} from '../../login/login.service';
import {SessionStorageService} from '../../core/session-storage.service';
import {StdComponent} from '../../core/std-component';
import {Tag} from '@ninescopesoft/core';
import {union} from 'lodash';
import {ReferenceDataService} from '../../services/reference-data.service';
import {UtilsService} from '../../core/utils.service';
import { combineLatest } from 'rxjs';

export type MainToolbarTab = 'my' | 'public' | 'past' | 'all';
export type TEventTag = Pick<Tag, 'id' | 'name' | 'colorHEX'>;
export interface IEventTag extends TEventTag {
  backgroundColor: string;
}

@Component({
  selector: 'app-dashboard-toolbar',
  templateUrl: './dashboard-toolbar.component.html',
  styleUrls: ['./dashboard-toolbar.component.scss'],
  animations: [widthChangeAnim]
})
export class DashboardToolbarComponent extends StdComponent implements AfterViewInit {
  @Output() onChange: EventEmitter<MainToolbarTab> = new EventEmitter<MainToolbarTab>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onTagsFilter: EventEmitter<IEventTag[]> = new EventEmitter<IEventTag[]>();
  @ViewChild('group', {read: MatButtonToggleGroup}) group: MatButtonToggleGroup;
  openTab$: EventEmitter<string> = new EventEmitter<string>();
  isAmin = false;
  isGuest = false;
  value: string;
  eventTags: IEventTag[] = [];
  tagsFilter: IEventTag[] = [];
  filterWidth: string;
  widhtChangeAnimParams: { value: string, openWidth: string, closedWidth: string };
  isMobile$ = this.common.isMobile$();
  mobileSearch$ = this.common.mobileSearch$;
  searchOpened$ = this.common.searchOpened$;

  constructor(protected injector: Injector,
              private loginService: LoginService,
              private utils: UtilsService,
              private referenceDataService: ReferenceDataService,
              private sessionStorage: SessionStorageService,
              private common: CommonService
    ) {
    super(injector);
    this.isAmin = this.loginService.isSuperadmin() || this.loginService.isAdmin() || this.loginService.isModerator();
    this.isGuest = this.loginService.isGuest();
    let value = this.sessionStorage.get('dashboard', 'my');
    if (!this.isAmin && value === 'all') {
      value = 'my';
    }
    this.value = value;
    this.openTab$
      .pipe(this.takeUntilAlive())
      .subscribe((action: MainToolbarTab) => {
        if (this.value !== action) {
          this.value = action;
          this.onButton(action);
        }
      });
    if (this.isAmin) {
      this.referenceDataService.tags$
        .pipe(this.takeUntilAlive())
        .subscribe(tags => {
          this.eventTags = (tags || [])
            .sort(this.utils.comparator('name'))
            .map(it => ({
              id: it.id,
              colorHEX: it.colorHEX,
              backgroundColor: this.utils.convertHexToRGBA(it.colorHEX, 0.2),
              name: it.name
            }));
        });
    }

    combineLatest([this.searchOpened$, this.isMobile$])
      .pipe(this.takeUntilAlive())
      .subscribe(([searchOpened, isMobile]) => this.widhtChangeAnimParams =
        { value: searchOpened ? 'open' : 'closed', openWidth: isMobile ? '200px' : '', closedWidth:  isMobile ? '44px' : '' });
  }

  onButton(action: MainToolbarTab) {
    if (!this.isAmin && action === 'all') {
      action = 'my';
    }
    this.onChange.emit(action);
    this.sessionStorage.set('dashboard', action);
  }

  ngAfterViewInit(): void {
    this.onChange.emit(this.group.value);
  }

  applyFilter(value) {
    this.onSearch.emit(value);
  }

  setTagFilter(tag: IEventTag) {
    this.tagsFilter = !(this.tagsFilter || []).find(t => t.id === tag.id) ?
      union([tag], this.tagsFilter) : this.tagsFilter.filter(t => t.id !== tag.id);
    this.onTagsFilter.emit(this.tagsFilter);
    this.filterWidth = (24 * (this.tagsFilter.length ? 1 : 0) + 8 * (this.tagsFilter.length ? this.tagsFilter.length -1 : 0)) + 'px';
  }

  clearTagsFilter() {
    this.tagsFilter = [];
    this.onTagsFilter.emit(this.tagsFilter);
  }

  tagsFilterIncludes(tag: IEventTag) {
    return this.tagsFilter.find(t => t.id === tag.id);
  }
}
