import {Constants, DATE_FORMAT, MANAGE_TIME_ROW_TYPE, SECTION_EVENT_PHASE} from '../../core/constants';
import {Event, IManagerMap} from '../Event';
import {SectionContent} from '../content/SectionContent';
import {UtilsService} from '../../core/utils.service';
import {isEmpty} from 'lodash';
import {SectionTimeline} from '../content/SectionTimeline';
import {TimeLineService} from '../../services/time-line.service';
import {IDRMRights} from '../../core/drm.service';

export enum ROW_TIME_TYPE {
  DATE,
  START,
  DURATION,
  END
}

export interface DateEditValue {
  startDate: number;
  endDate: number;
  lockedStart: boolean;
  lockedEnd: boolean;
  timeType: ROW_TIME_TYPE;
}

export interface TimeEditValue {
  timeValue: number;
  locked: boolean;
  timeType: ROW_TIME_TYPE;
}

export interface IDaySection {
  id: string;
  eventId: string;
  level: number;
}

export interface IToolsSection {
  id: string;
  eventId: string;
  level: number;
  parentId: string;
}

export interface TimePointMap {[key: string]: TimePoint; }

export interface Notes {[key: string]: {text: string}; }

export interface RowTask {
  sectionId: string;
  fieldName: string;
  value: number | boolean;
  valueBoolean?: boolean;
  valueDouble?: number;
  type: 'event' | 'section'; }

export class RowDatesObject {
  private _rowStartDateValue: TimeValue;
  private _rowDurationValue: TimeValue;
  private _rowDurationMilliseconds: number;
  private _rowEndDateValue: TimeValue;
  public rowStartTimeStr: string;
  public rowEndTimeStr: string;
  public rowDurationStr: string;
  public rowStartDateStr: string;
  public rowEndDateStr: string;
  public rowType: MANAGE_TIME_ROW_TYPE;
  public sectionTitle;
  public sectionTitleSrc;
  public sectionTitleWithMacros;
  public showSrcTitle: boolean;
  public sectionLocation;
  public speakers: string[];
  public speakersTitle: string[];
  public levelShift = '0';
  public level: number;
  public sectionTypeIcon;
  public sectionTypeIconDefault;
  public container;
  public parentIsContainer;
  public parentId;
  public sectionId: string;
  public selfParent: boolean;
  public sectionTimeIsNotActive;
  public sectionStatus;
  public selected = false; // system flag for row checkbox
  public draft = false; // system flag for update main list of object.
  public orderIndex: number;
  public managementNoteText;
  public isSelfLearningSection = false;
  public excludeFromAutopilot: boolean;
  public readonlyHKVBS;
  public isSpeakerAccessRights = false;
  public requiredRegistration: boolean;
  public freeSlotType: boolean;
  public drmRights: IDRMRights;
  private emulationMode = false;

  constructor(object: Event | SectionTimeline | IDaySection | IToolsSection, phase: MANAGE_TIME_ROW_TYPE, appUsers: IManagerMap,
              managementNotesList: Notes, public sectionTypeIconList: {}, drmRights: IDRMRights,
              public utils: UtilsService, public timeLineService: TimeLineService,
               emulationMode: boolean) {
    this.emulationMode = emulationMode;
    this.setObjectValues(object, phase, appUsers, managementNotesList, drmRights);
    Object.defineProperty(this, 'utils', {enumerable: false});
    Object.defineProperty(this, 'timeLineService', {enumerable: false});
  }

  update(object: Event | SectionContent | IDaySection | IToolsSection, phase: MANAGE_TIME_ROW_TYPE,
         appUsers: IManagerMap, managementNotesList: Notes, drmRights: IDRMRights, emulationMode: boolean) {
    this.emulationMode = emulationMode;
    this.setObjectValues(object, phase, appUsers, managementNotesList, drmRights, true);
  }

  private setObjectValues(object: Event | SectionContent | IDaySection | IToolsSection, rowType: MANAGE_TIME_ROW_TYPE,
                          appUsers: IManagerMap, managementNotesList: Notes, drmRights: IDRMRights, isUpdate = false) {
    this.rowType = rowType;
    this.drmRights = drmRights ?? {canEdit: true, canCopy: true, canCut: true, canDelete: true};
    let timePoint: TimePoint;
    if ((object instanceof SectionTimeline)) {
      this.sectionId = object.id;
      this.parentId = object.parentId;
      this.freeSlotType = object.freeSlotType;
      this.selfParent = object.items.length > 0;
      const langParams = this.timeLineService.getEventLanguageParams();
      const title = !this.freeSlotType ? object.getTitleByLanguage(langParams) : this.utils.i18n('slot.mode.slot').toUpperCase();
      this.sectionTitleSrc = title;
      const text = this.timeLineService.textMacrosParserService.parse(this.sectionId, title);
      this.sectionTitle = this.utils.convertTextToRichText(text);
      this.sectionTitleWithMacros =  !!this.sectionTitle && !!title && this.sectionTitle !== title;
      this.sectionLocation = object.location;
      this.isSelfLearningSection = object.isSelfLearningSection ||
        !!this.timeLineService.isSectionHierarchicalParentSelfLearning(object);
      this.managementNoteText = managementNotesList[this.sectionId] ? managementNotesList[this.sectionId].text : null;
      this.speakers = [];
      this.speakersTitle = [];
      if (!isEmpty(object.users) && !isEmpty(appUsers)) {
        object.users.filter(u => u.speaker).forEach(sp => {
          const appUser = appUsers[sp.userId];
          if (appUser && appUser.picture) {
            this.speakers.push(appUser.picture);
            this.speakersTitle.push(appUser.fullName);
          }  else {
            this.speakers.push(Constants.DEFAULT_USER_LOGO);
          }
        });
      }
      this.level = this.getLevelShift(object);
      this.levelShift = (25 * this.level) + 'px';
      this.sectionTypeIcon = this.getSectionTypeIcon(object);
      this.container = object.container;
      this.sectionTimeIsNotActive = object.sectionTimeIsNotActive;
      this.parentIsContainer = this.checkParentIsContainer();
      this.orderIndex = object.orderIndex;
      this.sectionStatus = object.status;
      this.draft = false;
      this.excludeFromAutopilot = object.excludeFromAutopilot;
      this.readonlyHKVBS = object.readonlyHKVBS;
      this.isSpeakerAccessRights = this.timeLineService.hasSpeakerAccessRightsToSection(object);
      this.requiredRegistration = object.requiredRegistration;
      timePoint = this.getTimePoint(this.sectionId);
    } else if ((object instanceof Event)) {
      this.sectionId = object.eventId + '-' + rowType;
      this.level = 0;
      timePoint = this.getTimePoint(this.sectionId);
      if (isUpdate && timePoint && (this.rowStartDate.value !== timePoint.start.value || this.rowEndDate.value !== timePoint.end.value)) {
        this.timeLineService.pushRefreshUserEventSubject.next((new Date()).getTime());
      }
    } else {
      this.sectionId = object.id + '-' + rowType;
      timePoint = this.getTimePoint(object.id);
      if (rowType === MANAGE_TIME_ROW_TYPE.DAY) {
        this.level = object['level'];
      }
      if (rowType === MANAGE_TIME_ROW_TYPE.TOOLS) {
        this.sectionTimeIsNotActive = true;
        this.level = object['level'];
        this.levelShift = (25 * this.level) + 'px';
        this.parentId = object['parentId'];
      }
    }
    if (!timePoint) {
      timePoint = new TimePoint(this.sectionId, this.sectionTitle);
    }
    this.rowStartDate = timePoint.start;
    this.rowDuration = timePoint.duration;
    this.rowEndDate = timePoint.end;
  }

  public getTimePoint(sectionId: string) {
    if (!this.emulationMode) {
      if (sectionId.includes(MANAGE_TIME_ROW_TYPE.END)) {
        return this.timeLineService.mangeTimeMap[sectionId.replace(MANAGE_TIME_ROW_TYPE.END, MANAGE_TIME_ROW_TYPE.START)];
      } else if (sectionId.includes(MANAGE_TIME_ROW_TYPE.DAY)) {
        return this.timeLineService.mangeTimeMap[sectionId.replace('-' + MANAGE_TIME_ROW_TYPE.DAY, '')];
      } else if (sectionId.includes(MANAGE_TIME_ROW_TYPE.TOOLS)) {
        return null;
      } else {
        return this.timeLineService.mangeTimeMap[sectionId];
      }
    } else {
      if (sectionId.includes(MANAGE_TIME_ROW_TYPE.END)) {
        return this.timeLineService.getManageTimeService()
          .testManageTimeMap[sectionId.replace(MANAGE_TIME_ROW_TYPE.END, MANAGE_TIME_ROW_TYPE.START)];
      } else if (sectionId.includes(MANAGE_TIME_ROW_TYPE.DAY)) {
        return this.timeLineService.getManageTimeService()
          .testManageTimeMap[sectionId.replace('-' + MANAGE_TIME_ROW_TYPE.DAY, '')];
      } else if (sectionId.includes(MANAGE_TIME_ROW_TYPE.TOOLS)) {
        return null;
      } else {
        return this.timeLineService.getManageTimeService().testManageTimeMap[sectionId];
      }
    }
  }

  get rowStartDate(): TimeValue {
    return this._rowStartDateValue;
  }

  set rowStartDate(value: TimeValue) {
    this._rowStartDateValue = value;
    this.rowStartTimeStr = this.utils.formatDate(this._rowStartDateValue.value, DATE_FORMAT.HH_mm);
    this.rowStartDateStr = this.utils.formatDate(this._rowStartDateValue.value, DATE_FORMAT.DD_MM_YYYY);
  }

  get rowDuration(): TimeValue {
    return this._rowDurationValue;
  }

  get rowDurationMinutes(): number {
    return this._rowDurationValue && this._rowDurationValue.value ?
      (this._rowDurationValue.value / Constants.ONE_MINUTE_MS) : this._rowDurationValue.value;
  }

  set rowDuration(value: TimeValue) {
    this._rowDurationValue = value;
    this._rowDurationMilliseconds = value ? value.value : null;
    this.rowDurationStr = this.utils.millisTo_YY_MM_DD_hh_mm_Short(this._rowDurationMilliseconds,
      this.rowType === MANAGE_TIME_ROW_TYPE.SECTION);
  }

  get rowEndDate(): TimeValue {
    return this._rowEndDateValue;
  }

  set rowEndDate(value: TimeValue) {
    this._rowEndDateValue = value;
    this.rowEndTimeStr = this.utils.formatDate(this._rowEndDateValue.value, DATE_FORMAT.HH_mm);
    this.rowEndDateStr = this.utils.formatDate(this._rowEndDateValue.value, DATE_FORMAT.DD_MM_YYYY);
  }

  get isDifferentDays() {
    return this.utils.compareNotNullDatesDay(this._rowStartDateValue.value, this._rowEndDateValue.value) === 1;
  }

  get daysBetweenStartEnd() {
    const d = this.utils.getDayBetweenDates(this.rowStartDate.value, this.rowEndDate.value);
    return !d ? '' : '+' + d;
  }

  isDifferentWithPrevDays(list: RowDatesObject[], currentIndex: number) {
    return currentIndex > 0 && this.utils.compareNotNullDatesDay(this._rowStartDateValue.value,
      this.timeLineService.timelineEvent.startDate.getTime()) === 1 &&
      this.utils.compareNotNullDatesDay(this._rowStartDateValue.value, list[currentIndex - 1].rowStartDate.value) === 1;
  }

  get rowIsSection() {
    return this.rowType === MANAGE_TIME_ROW_TYPE.SECTION;
  }

  get rowIsPrepWrap() {
    return [MANAGE_TIME_ROW_TYPE.PREP, MANAGE_TIME_ROW_TYPE.WRAP_UP].includes(this.rowType);
  }

  get rowIsStart() {
    return this.rowType === MANAGE_TIME_ROW_TYPE.START;
  }

  get rowIsEnd() {
    return this.rowType === MANAGE_TIME_ROW_TYPE.END;
  }

  get rowIsDay() {
    return this.rowType === MANAGE_TIME_ROW_TYPE.DAY;
  }

  get rowIsTools() {
    return this.rowType === MANAGE_TIME_ROW_TYPE.TOOLS;
  }

  private getLevelShift(content: SectionContent): number {
    return this.timeLineService.contentLevel(content.id);
  }

  private getSectionTypeIcon(section: SectionContent) {
    this.sectionTypeIconDefault = !section.sectionTypeId || !this.sectionTypeIconList[section.sectionTypeId] ||
      this.sectionTypeIconList[section.sectionTypeId] === Constants.SECTION_TYPE_DEFAULT_ICON;
    return !section.sectionTypeId ? Constants.SECTION_TYPE_DEFAULT_ICON : this.sectionTypeIconList[section.sectionTypeId];
  }

  getSectionContent() {
    const section = this.timeLineService.planeFullListContentWithoutFixed[this.sectionId.split('-')[0]];
    return section ? section.sectionContent : null;
  }

  get isDateStartLocked() {
    return this.rowStartDate.fixed;
  }

  get isDateEndLocked() {
    return this.rowEndDate.fixed;
  }

  get isStartTimeLocked() {
    return this.rowStartDate.fixed;
  }

  get isDurationLocked() {
    return this.rowDuration.fixed;
  }

  get isEndTimeLocked() {
    return this.rowEndDate.fixed;
  }

  private checkParentIsContainer() {
    const obj = this.timeLineService.planeFullListContentWithoutFixed[this.sectionId];
    if (obj) {
      const parent = this.timeLineService.planeFullListContentWithoutFixed[obj.parentId];
      if (parent) {
        return parent.container;
      }
      return false;
    }
    return false;
  }

  public updateSpeakers(appUsers: {}) {
    const section = this.getSectionContent();
    this.speakers = [];
    this.speakersTitle = [];
    if (section && !isEmpty(section.users) && !isEmpty(appUsers)) {
      section.users.filter(u => u.speaker).forEach(sp => {
        const appUser = appUsers[sp.userId];
        if (appUser && appUser.picture) {
          this.speakers.push(appUser.picture);
          this.speakersTitle.push(appUser.fullName);
        }  else {
          this.speakers.push(Constants.DEFAULT_USER_LOGO);
        }
      });
    }
  }

  public updateManagementNotes(managementNotesList: Notes) {
    this.managementNoteText = managementNotesList[this.sectionId] ? managementNotesList[this.sectionId].text : null;
  }

  public updateTime() {
    let timePoint = this.timeLineService.mangeTimeMap[this.sectionId];
    if (!timePoint) {
      timePoint = new TimePoint(this.sectionId, this.sectionTitle);
    }
    this.rowStartDate = timePoint.start;
    this.rowDuration = timePoint.duration;
    this.rowEndDate = timePoint.end;
  }
}

export class TimeValue {
  constructor(public value: number = null, public fixed: boolean = false) {}
}

export class TimePoint {
  private readonly _isEventTimePoint: boolean;
  private readonly _eventPhase: SECTION_EVENT_PHASE;
  constructor(public sectionId, public title, public sectionEventPhase: SECTION_EVENT_PHASE = null,
              public start: TimeValue = new TimeValue(),
              public duration: TimeValue = new TimeValue(),
              public end: TimeValue = new TimeValue()) {
    if (sectionId && sectionId.toString().includes('-')) {
      const id = sectionId.toString().split('-');
      this._isEventTimePoint = true;
      this._eventPhase = id[1];
    } else {
      this._isEventTimePoint = false;
    }
  }

  calcUndefinedByFixed(): TimePoint {
    if (this.start.fixed && !this.duration.fixed && this.end.fixed) {
      this.duration.value = this.end.value - this.start.value;
    } else if (this.start.fixed && this.duration.fixed && !this.end.fixed) {
      this.end.value = this.start.value + this.duration.value;
    } else if (!this.start.fixed && this.duration.fixed && this.end.fixed) {
      this.start.value = this.end.value - this.duration.value;
    }
    return this;
  }

  set startValue(value: number) {
    if (!this.start.fixed) {
      this.start.value = value;
      if (!this.end.value && this.start.value && this.duration.value !== null) {
        this.end.value = this.start.value + this.duration.value;
      } else if (this.end.value && this.start.value && this.duration.value === null) {
        this.duration.value = this.end.value - this.start.value;
      }
    }
  }

  get startValue() {
    return this.start.value;
  }

  set endValue(value: number) {
    if (!this.end.fixed) {
      this.end.value = value;
      if (!this.start.value && this.end.value && this.duration.value !== null) {
        this.start.value = this.end.value - this.duration.value;
      } else if (this.start.value && this.end.value && this.duration.value === null) {
        this.duration.value = this.end.value - this.start.value;
      }
    }
  }

  get endValue() {
    return this.end.value;
  }

  set durationValue(value: number) {
    if (!this.duration.fixed) {
      this.duration.value = value;
      if (!this.start.value && this.end.value && this.duration.value !== null) {
        this.start.value = this.end.value - this.duration.value;
      } else if (this.start.value && !this.end.value && this.duration.value !== null) {
        this.end.value = this.start.value + this.duration.value;
      }
    } else if (this.duration.fixed && value === 0 && this.duration.value === null) {
      this.duration.value = value;
    }
  }

  get durationValue() {
    return this.duration.value;
  }

  get durationDataBaseValue() {
    return (this.duration.value || this.duration.value === 0) && this.duration.value >= 0 ?
      this.duration.value / Constants.ONE_MINUTE_MS : null;
  }

  get isEventTimePoint(): boolean {
    return this._isEventTimePoint;
  }

  get eventPhase(): SECTION_EVENT_PHASE {
    return this._eventPhase;
  }
}

export class LevelTimePoints {
  prep: TimePoint = new TimePoint(null, null);
  start: TimePoint = new TimePoint(null, null);
  end: TimePoint = new TimePoint(null, null);
  wrapUp: TimePoint = new TimePoint(null, null);
  daysStart: {[key: string]: TimePoint} = {};
  daysEnd: {sectionId: string, orderIndex: number, childTree: string[]}[] = [];
  constructor() {}
}
