import {StdComponent} from '../../../../../../../core/std-component';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {Constants, ILanguageParams} from '../../../../../../../core/constants';
import {EventEmitter, Injector} from '@angular/core';
import {CommonService} from '../../../../../../../core/common.service';
import {TranslateApiService} from '../../../../../../../services/translate-api.service';
import {BehaviorSubject, filter} from 'rxjs';
import {UtilsService} from '../../../../../../../core/utils.service';
import {IValidated, QUESTION_TYPES_COMPONENTS} from '../quiz-quiestion-types';

const NOT_EMITTED_FIELDS = ['items', 'matchingItems'];

export abstract class AbstractQuizQuestionEditorComponent extends StdComponent {

  readonly Constants = Constants;

  protected common: CommonService;
  protected translateApiService: TranslateApiService;

  protected languageParams: ILanguageParams;

  hideUseCorrectAnswersOption = false; // some questions can be used in the registration form
                                       // in this case, some question settings cannot be used.

  onQuestionChange = new EventEmitter<boolean>();

  protected questionDetectChanges$ = new BehaviorSubject<boolean>(false);
  protected questionDetectChangesHandler = {
    onChange: this.questionDetectChanges$,
    changesHandler: this.setObjectChangesHandler.bind(this),
    set(target, key, val, receiver) {
      if (Array.isArray(target) && key !== 'length' && key.match(/^[0-9]*$/) && typeof val === 'object') {
        val = this.changesHandler(val);
      }
      Reflect.set(target, key, val, receiver);
      if (!NOT_EMITTED_FIELDS.includes(key)) {
        this.onChange.next(true);
      }
      return true;
    }
  };

  translateHint: string;
  question: EventQuestion;

  protected constructor(protected injector: Injector) {
    super(injector);
    this.common = injector.get(CommonService);
    this.translateApiService = injector.get(TranslateApiService);
    this.hideUseCorrectAnswersOption = window.location.href?.endsWith('/settings');
    this.subscribeOnQuestionChange();
  }

  validate(): IValidated {
    return {validated: true};
  }

  protected applyQuestionData() {}

  get questionOptions() {
    return this.question.options;
  }

  get useCorrectAnswersOption() {
    return QUESTION_TYPES_COMPONENTS[this.question.storypoint].useCorrectAnswersOption;
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    this.setLanguage(languageParams);
    this.question = this.setObjectChangesHandler(question);
  }

  setLanguage(languageParams: ILanguageParams) {
    this.languageParams = languageParams;
    this.translateHint = this.translateApiService
      .getTranslateHint(this.languageParams.defaultLanguage, this.languageParams.currentLanguage);
  }

  setObjectChangesHandler(object) {
    return UtilsService.wrapObjectToProxy(object, this.questionDetectChangesHandler);
  }

  private subscribeOnQuestionChange() {
    this.questionDetectChanges$.pipe(filter(v => !!v), this.takeUntilAlive())
      .subscribe(() => {
        this.applyQuestionData();
        this.onQuestionChange.emit(true);
      });
  }

}
