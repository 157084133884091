import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {formatSize} from '../../../../shared/lib/question-upload-files-utils';
import {Constants} from '../../../../../../../../../core/constants';
import {MatExpansionModule} from '@angular/material/expansion';
import {TranslateModule} from '@ngx-translate/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ISettings} from '../../isettings';
import {BaseSettingsComponent} from '../base-settings';
import {LanguageSelectorModule} from '../../../../../../../../../components/language-selector/language-selector.module';
import {takeUntil} from 'rxjs';
import {LoadingProgressComponent} from '../../../../../../../../../components/loading-progress/loading-progress.component';
import {
  IUploadFileType,
  IUploadSelectedFileTypes,
  TABLE_COLUMN_TYPE,
  TableColumn,
  TableRowBase
} from '../../table-poll-model/TablePollModel';
import {cloneDeep, isEmpty} from 'lodash';
import {ObjectKeysPipe} from '../../../../../../../../../pipes/object-keys.pipe';
import {event, selectAll} from 'd3';

@Component({
  selector: 'app-upload-file-settings-dialog',
  standalone: true,
  imports: [
    FormsModule, ReactiveFormsModule, MatInputModule,
    CommonModule, MatIconModule, MatDialogModule, MatButtonModule,
    MatExpansionModule, TranslateModule, MatCheckboxModule,
    LanguageSelectorModule, LoadingProgressComponent, ObjectKeysPipe
  ],
  templateUrl: './upload-file-settings-dialog.component.html',
  styleUrl: './upload-file-settings-dialog.component.scss'
})
export class UploadFileSettingsDialogComponent extends BaseSettingsComponent implements ISettings {

  form: FormGroup;
  callerCtx: any;
  showError = false;
  showErrorForFiles = false;
  column: TableColumn;
  columnType: IUploadFileType;

  readonly formatSize = formatSize;

  isMediaPanelOpen = true;
  isDocumentPanelOpen = true;
  FT_IMAGE_SIZE = Constants.FT_IMAGE_SIZE;
  FT_VIDEO_SIZE = Constants.FT_VIDEO_SIZE;
  FT_AUDIO_SIZE = Constants.FT_AUDIO_SIZE;
  FT_DOC_SIZE = Constants.FT_DOC_SIZE;
  FT_OTHER_SIZE = Constants.FT_OTHER_SIZE;

  images = {
    jpg: false,
    jpeg: false,
    png: false,
    gif: false,
    svg: false,
    webp: false,
    heic: false,
  };

  audios = {
    mp3: false,
    aac: false,
  };

  videos = {
    mp4: false
  };

  documentTypes = {
    pdf: false,
    docx: false,
    xlsx: false,
    pptx: false
  };

  others = {
    csv: false,
    zip: false
  };

  private dialogRef = inject(MatDialogRef<UploadFileSettingsDialogComponent>);
  private readonly data = inject<{callerCtx: any, formGroup: FormGroup; }>(MAT_DIALOG_DATA);

  constructor() {
    super();
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.init(this.data.callerCtx);
    this.callerCtx = this.data.callerCtx;
    const formGroup = this.data.formGroup;
    this.column = new TableColumn(cloneDeep(formGroup?.value ?? {
      type: {
        inputType: TABLE_COLUMN_TYPE.FILE,
        selectedTypes: {images: [], audios: [], videos: [], documentTypes: [], others: []}
      },
      tableRows: [new TableRowBase()]
    }));
    this.columnType = <IUploadFileType>this.column.type;
    const storedSelectedTypes =  this.columnType.selectedTypes;
    const editMode = !isEmpty(this.data.formGroup);
    this.form = this.fb.group({
      columnName: [ this.column.getColumnNameByLanguage(this.callerCtx.languageParams),
        [ Validators.required, Validators.maxLength(256)]],
      imagesArray: this.fb.array(
        Object.keys(this.images).map(key => new FormControl(editMode ? storedSelectedTypes.images?.includes(key) : this.images[key]))
      ),
      audiosArray: this.fb.array(
        Object.keys(this.audios).map(key => new FormControl(editMode ? storedSelectedTypes.audios?.includes(key) : this.audios[key]))
      ),
      videosArray: this.fb.array(
        Object.keys(this.videos).map(key => new FormControl(editMode ? storedSelectedTypes.videos?.includes(key) : this.videos[key]))
      ),
      documentsArray: this.fb.array(
        Object.keys(this.documentTypes)
          .map(key => new FormControl(editMode ? storedSelectedTypes.documentTypes?.includes(key) : this.documentTypes[key]))
      ),
      othersArray: this.fb.array(
        Object.keys(this.others).map(key => new FormControl(editMode ? storedSelectedTypes.others?.includes(key) : this.others[key]))
      ),
    });

    this.initializeSelectedTypes();
    this.initializeCheckboxes();

    this.columnName.valueChanges.subscribe(value => {
      this.column.setColumnNameByLanguage(value, this.callerCtx.languageParams);
    });

    this.languageChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form.patchValue({...this.form.value, columnName: this.column.getColumnNameByLanguage(this.callerCtx.languageParams)});
    });
  }

  get columnName(): FormControl {
    return this.form.get('columnName') as FormControl;
  }

  get imagesArray(): FormArray {
    return this.form.get('imagesArray') as FormArray;
  }

  get audiosArray(): FormArray {
    return this.form.get('audiosArray') as FormArray;
  }
  get videosArray(): FormArray {
    return this.form.get('videosArray') as FormArray;
  }

  get documentsArray(): FormArray {
    return this.form.get('documentsArray') as FormArray;
  }
  get othersArray(): FormArray {
    return this.form.get('othersArray') as FormArray;
  }

  initializeCheckboxes() {
    // Initialize images
    Object.keys(this.images).forEach(key => {
      this.images[key] = this.columnType.selectedTypes?.images?.includes(key);
    });

    // Initialize audios
    Object.keys(this.audios).forEach(key => {
      this.audios[key] = this.columnType.selectedTypes?.audios?.includes(key);
    });

    // Initialize videos
    Object.keys(this.videos).forEach(key => {
      this.videos[key] = this.columnType.selectedTypes?.videos?.includes(key);
    });

    // Initialize documentTypes
    Object.keys(this.documentTypes).forEach(key => {
      this.documentTypes[key] = this.columnType.selectedTypes?.documentTypes?.includes(key);
    });

    Object.keys(this.others).forEach(key => {
      this.others[key] = this.columnType.selectedTypes?.others?.includes(key);
    });
  }

  trackByKey(index, row) {
    return row.key;
  }

  selectAllMedia(event: Event) {
    event.stopPropagation();
    const allMediaTypes = { ...this.images, ...this.audios, ...this.videos };
    const selectAll = !Object.values(allMediaTypes).every(v => v);
    Object.keys(this.images).forEach(key => this.images[key] = selectAll);
    Object.keys(this.audios).forEach(key => this.audios[key] = selectAll);
    Object.keys(this.videos).forEach(key => this.videos[key] = selectAll);
    this.form.patchValue({
      imagesArray: Object.values(this.images),
      videosArray: Object.values(this.videos),
      audiosArray: Object.values(this.audios)
    });
    this.updateSelectedTypes();
  }

  selectAllDocuments(event: Event) {
    event.stopPropagation();
    const allDocTypes = { ...this.documentTypes, ...this.others};
    const selectAll = !Object.values(allDocTypes).every(v => v);
    Object.keys(this.documentTypes).forEach(key => this.documentTypes[key] = selectAll);
    Object.keys(this.others).forEach(key => this.others[key] = selectAll);
    this.form.patchValue({
      documentsArray: Object.values(this.documentTypes),
      othersArray: Object.values(this.others)
    });
    this.updateSelectedTypes();
  }

  initializeSelectedTypes() {
    if (!this.columnType.selectedTypes) {
      this.columnType.selectedTypes = {
        images: [],
        audios: [],
        videos: [],
        documentTypes: [],
        others: [],
      };
      this.updateSelectedTypes();
    }
  }

  updateSelectedTypes() {
    if (!this.columnType.selectedTypes) {
      this.columnType.selectedTypes = {
        images: [],
        audios: [],
        videos: [],
        documentTypes: [],
        others: [],
      };
    }

    this.columnType.selectedTypes.images = Object.keys(this.images).filter((key, idx) => this.imagesArray?.value[idx]);
    this.columnType.selectedTypes.audios = Object.keys(this.audios).filter((key, idx) => this.audiosArray?.value[idx]);
    this.columnType.selectedTypes.videos = Object.keys(this.videos).filter((key, idx) => this.videosArray?.value[idx]);
    this.columnType.selectedTypes.documentTypes = Object.keys(this.documentTypes)
      .filter((key, idx) => this.documentsArray?.value[idx]);
    this.columnType.selectedTypes.others = Object.keys(this.others).filter((key, idx) => this.othersArray?.value[idx]);
  }

  getSelectedMediaTypes(): string {
    const selectedTypes = [
      ...Object.keys(this.images).filter(key => this.images[key]),
      ...Object.keys(this.audios).filter(key => this.audios[key]),
      ...Object.keys(this.videos).filter(key => this.videos[key])
    ];

    return selectedTypes.length > 0 ? this.common.i18n('questionnaire.upload.file.editor.files.selected',
      {count: selectedTypes.length}) : this.common.i18n('questionnaire.upload.file.editor.files.selected.none');
  }

  getSelectedDocumentTypes(): string {
    const selectedTypes = [
      ...Object.keys(this.documentTypes).filter(key => this.documentTypes[key]),
      ...Object.keys(this.others).filter(key => this.others[key])
    ];
    return selectedTypes.length > 0 ? this.common.i18n('questionnaire.upload.file.editor.files.selected',
      {count: selectedTypes.length}) : this.common.i18n('questionnaire.upload.file.editor.files.selected.none');
  }

  onCheckboxChange() {
    this.updateSelectedTypes();
  }

  onMediaPanelOpened() {
    this.isMediaPanelOpen = true;
  }

  onMediaPanelClosed() {
    this.isMediaPanelOpen = false;
  }

  onDocumentPanelOpened() {
    this.isDocumentPanelOpen = true;
  }

  onDocumentPanelClosed() {
    this.isDocumentPanelOpen = false;
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

  onDone(event): void {
    this.form?.markAllAsTouched();
    this.form?.updateValueAndValidity();
    const errorMsgs = this.checkTheRequiredFieldsAndReturnErrorMsgs();
    if (errorMsgs.length) {
      const targetRect = event.target.getBoundingClientRect();
      const topPosition =  - (targetRect.top * 0.1);
      // this.toasterService.openCustomSnackbarComponent(topPosition, errorMsgs, CustomSnackbarComponent, 3000);
    } else {
      this.showError = false;
      this.dialogRef.close({
        columnName: this.columnType.columnName,
        selectedTypes: this.columnType.selectedTypes
     });
    }
  }

  checkTheRequiredFieldsAndReturnErrorMsgs(): string[] {
    const errorMsgs: any[] = [];
    if (this.columnName.invalid) {
      this.columnName.markAsTouched();
      this.showError = true;
      errorMsgs.push(this.common.i18n('questionnaire.table.editor.settings.error.mgs.column.name.empty'));
    } else {
      this.showError = false;
    }
    if (!this.hasAtLeastOneTypeSelected(this.columnType.selectedTypes)) {
      errorMsgs.push('questionnaire.table.editor.settings.select.menu.error.mgs.options.least.one.type');
      this.showErrorForFiles = true;
    } else {
      this.showErrorForFiles = false;
    }
    return errorMsgs;
  }

  hasAtLeastOneTypeSelected(selectedTypes: IUploadSelectedFileTypes): boolean {
    return (selectedTypes) ? Object.values(selectedTypes).some((array) => array.length > 0) : false;
  }
}
