import { AnswersQuestion } from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import { map, of, take } from 'rxjs';
import {
  TEXT_AI_OPTION_LIST,
} from '../../../../../../../services/ai-text.service';
import { QuizQuestionSheetComponent } from '../../../quiz-question-sheet/quiz-question-sheet.component';
import { Constants } from '../../../../../../../core/constants';

export const textBalloonsAIAction = (
  aiTextOption: TEXT_AI_OPTION_LIST,
  quizQuestionSheet: QuizQuestionSheetComponent
) => {
  const answers = quizQuestionSheet.quizService.textBalloonsAnswers$.getValue();
  const aiGenerated = quizQuestionSheet.quizService.aiGenerated$.getValue();
  aiGenerated[aiTextOption] = aiTextOption;
  quizQuestionSheet.quizService.aiGenerated$.next(aiGenerated);
  const mapNewAiGeneration = (res: { data: string }) => {
    aiGenerated[aiTextOption] = res.data;
    quizQuestionSheet.quizService.aiGenerated$.next(aiGenerated);
    return res.data;
  };

  const sendAnswers = (res: string) => {
    quizQuestionSheet.sendAnswers(
      new AnswersQuestion(
        quizQuestionSheet.currentQKey$.getValue(),
        {
          a: res ? JSON.stringify({ [aiTextOption]: res }) : null,
          c: 'iaGen',
        },
        quizQuestionSheet.questionList[
          quizQuestionSheet.currentQKey$.getValue()
        ].timelineId
      )
    );
  };

  switch (aiTextOption) {
    case TEXT_AI_OPTION_LIST.TEXT_SUMMARIZE:
      const answersJoined = answers.join(', ');
      const question = quizQuestionSheet.questionList[(Object.keys(quizQuestionSheet.questionList)[0])].caption;

      return quizQuestionSheet.aiTextService
        .useAIForTest(Constants.AI_TEXT_FUNCTION_SUMMARIZE_ANSWERS, '-', question as string, answersJoined)
        .pipe(map(mapNewAiGeneration), take(1))
        .subscribe((res) => sendAnswers(res));
    case TEXT_AI_OPTION_LIST.CREATE_LYRICS:
      return quizQuestionSheet.aiTextService
        .createLyrics(answers.join(', '))
        .pipe(map(mapNewAiGeneration), take(1))
        .subscribe((res) => sendAnswers(res));
    default: sendAnswers(null);
  }
  return of(null);
};
