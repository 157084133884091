import { Directive } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Directive({
  selector: '[tlCenteredMenu]',
})
export class TlCenteredMenuDirective {
  constructor(private menu: MatMenu) {}

  ngAfterViewInit(): void {
    this.menu.overlayPanelClass = `mat-menu-center-${this.menu.xPosition}`
  }
}
