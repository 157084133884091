import {Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {AppUser} from '../../model/AppUser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {LoginService} from '../../login/login.service';
import * as ui from '../../actions/ui';
import {StdComponent} from '../../core/std-component';
import {UtilsService} from '../../core/utils.service';
import {cloneDeep} from 'lodash';
import {UsersDataService} from '../../services/users-data.service';

@Component({
  selector: 'app-terms-and-privacy-dialog',
  templateUrl: './terms-and-privacy-dialog.component.html',
  styleUrls: ['./terms-and-privacy-dialog.component.scss']
})
export class TermsAndPrivacyDialogComponent extends StdComponent implements OnInit, OnDestroy {

  eventId;
  currentUser: AppUser;
  acceptTermsAndPrivacy = false;
  customText: string;
  hideTermsAndPrivacyLicenseText = false;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<TermsAndPrivacyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: Store<fromRoot.State>,
              public utils: UtilsService,
              private usersDataService: UsersDataService,
              public loginService: LoginService) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.eventId = data.eventId;
    this.currentUser = cloneDeep(data.currentUser);
    this.customText = data.customText;
    if (this.currentUser) {
      this.acceptTermsAndPrivacy = this.currentUser.acceptTermsAndPrivacy;
    }
    this.hideTermsAndPrivacyLicenseText = this.utils.getEnv()['hideTermsAndPrivacyLicenseText'];
  }

  ngOnInit() {
    this.store.dispatch(new ui.SetModalDialogOpen(true));
  }

  ngOnDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onOkClick(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  accept() {
    this.usersDataService.setAcceptTermsAndPrivacy(this.currentUser.userId, true)
      .then(() => {
        this.currentUser.acceptTermsAndPrivacy = true;
        this.dialogRef.close(this.currentUser);
      });
  }

  onCloseEvent(): void {
    this.loginService.redirectTo('/dashboard');
    this.dialogRef.close();
  }
}
