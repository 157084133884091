import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {BehaviorSubject} from 'rxjs';
import {IGapAnswerData, IGapGroup, IGapGroupMatching} from '../../../../../../../model/EventQuestion';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {union} from 'lodash';

@Component({
  selector: 'app-question-select-gap-filling-participant',
  templateUrl: './question-select-gap-filling-participant.component.html',
  styleUrls: ['./question-select-gap-filling-participant.component.scss']
})
export class QuestionSelectGapFillingParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  gapGroups$ = new BehaviorSubject<IGapGroup[]>([]);
  gapGroupsMatching$ = new BehaviorSubject<IGapGroupMatching[]>([]);
  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  correctGapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapTaskText$ = new BehaviorSubject<string>(null);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    this.gapGroups$.next((this.question.matchingItems || [])
      .map(v => new Object({id: v.id, name: v.getAnswerByLanguage(this.languageParams)}) as IGapGroup));
    this.gapGroupsMatching$.next((this.question.items || [])
      .map(v => new Object({
        id: v.id, name: v.getAnswerByLanguage(this.languageParams),
        matchingId: v.matching[0]
      }) as IGapGroupMatching));
    this.gapAnswersData$.next(this.prepareAnswers(this.answers).map(obj => {
      const a = obj.split('=');
      return new Object({id: a[0], answerId: a[1]}) as IGapAnswerData;
    }));
    const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
    this.correctGapAnswersData$.next(this.question.showCorrectAnswers ?
      correctEquality.map(v => new Object({id: v.id, answerId: v.answerId}) as IGapAnswerData) : []);
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  protected onReceiveQuestionAnswers() {
    this.gapAnswersData$.next(this.prepareAnswers(this.answers).map(obj => {
      const a = obj.split('=');
      return new Object({id: a[0], answerId: a[1]}) as IGapAnswerData;
    }));
  }

  changeSelectGap(value: IGapAnswerData[]) {
    const groups: {[id: string]: string[]} = (value || []).filter(o => !!o.answerId).reduce((acc, row) => {
      acc[row.id] = union(acc[row.id], [row.answerId]);
      return acc;
    }, {});
    this.answers = Object.keys(groups).map(id => `${id}=${groups[id].sort((a, b) => a < b ? -1 : 1).join('=')}`);
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  private prepareAnswers(answers: string[]) {
    const result: string[] = [];
    for (const answer of (answers || [])) {
      const arr = answer.split('=');
      const groupId = arr[0];
      arr.filter(id => id !== groupId).forEach(id => result.push(`${groupId}=${id}`));
    }
    return result;
  }
}
