import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {IContentPriorityParams, IContentsTagsMap, IContentTagsParams} from '../tags-constants/tags-constants';
import {TagsApiService} from './tags-api.service';
import {Tag} from '../tags-model/Tag';
import {ContentContainer} from '../../../model/content/ContentContainer';

@Injectable({
  providedIn: 'root'
})
export class TagsDataService {

  constructor(private tagsApiService: TagsApiService) { }

  setTagToContent(params: IContentTagsParams) {
    return this.tagsApiService.setTagToContent(params);
  }

  setPriorityToContent(params: IContentPriorityParams) {
    return this.tagsApiService.setPriorityToContent(params);
  }

  loadContentsTags(eventId?: string): Observable<IContentsTagsMap> {
    return this.tagsApiService.loadContentsTags(eventId);
  }

  loadTagsReference(): Observable<Tag[]> {
    return this.tagsApiService.loadTagsReference();
  }

  deleteContentTags(content: ContentContainer) {
    return this.tagsApiService.deleteContentTags(content);
  }

  saveTagsReference(list: Tag[]) {
    return this.tagsApiService.saveTagsReference(list);
  }
}
