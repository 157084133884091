import { Component, computed, ElementRef, Injector, signal, ViewChild } from '@angular/core';
import {isEmpty, sum, union} from 'lodash';
import {BehaviorSubject, map, tap } from 'rxjs';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {QuizService} from '../../../quiz.service';
import {QUESTION_VIEW_MODE} from '../../shared/quiz-quiestion-types';
import {TEXT_AI_OPTION_LIST} from '../../../../../../../services/ai-text.service';


interface ISummary {
  answer: string;
  count: number;
  correct: boolean;
}

export enum AiGeneratedTitle {
  'text_summarize' = 'question.ai.gen.text.summarize',
  'create_lyrics' = 'question.ai.gen.create.lyrics',
  'group_by_color' = 'question.ai.gen.group.by.color'
}

@Component({
  selector: 'app-question-text-balloons-results',
  templateUrl: './question-text-balloons-results.component.html',
  styleUrl: './question-text-balloons-results.component.scss'
})
export class QuestionTextBalloonsResultsComponent extends AbstractQuizQuestionResultsComponent {
  readonly TEXT_AI_OPTION_LIST = TEXT_AI_OPTION_LIST;
  readonly AiGeneratedTitle = AiGeneratedTitle;
  @ViewChild('answersWrapper', { static: false }) private answersWrapper: ElementRef;

  readonly QUESTION_VIEW_MODE = QUESTION_VIEW_MODE;
  answers$ = new BehaviorSubject<any[]>([]);
  currentSelected$ = new BehaviorSubject<null | string>(null);
  currentSelectedAnswer = signal<null | string>(null);
  viewMode = QUESTION_VIEW_MODE.LIST_VIEW;

  constructor(
    protected injector: Injector,
    protected elementRef: ElementRef,
    private quizService: QuizService
  ) {
    super(injector, elementRef);

    this.answers$.pipe(map(answers => answers.map(a => a['a'])))
      .pipe(this.takeUntilAlive())
      .subscribe(answers => this.quizService.textBalloonsAnswers$.next(answers));
  }

  ngOnInit() {
    super.ngOnInit();
    this.question$.pipe(this.takeUntilAlive())
      .subscribe(question => this.viewMode = question.viewMode ?? QUESTION_VIEW_MODE.LIST_VIEW);
    this.quizService.getQuestionFollowMePoolAction(this.documentPathParams, this.qKey)
      .pipe(this.takeUntilAlive())
      .subscribe(action => {
        this.currentSelectedAnswer.set(action?.selectedAnswerId);
        if (action?.selectedAnswerId) {
          const elem = document.getElementById('answer-' + action.selectedAnswerId);
          elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
  }

  protected onReceiveQuestionAnswers() {
    this.summaryQuestionAnswers$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (isEmpty(value)) {
          this.answers$.next([]);
        } else if (Array.isArray(value)) {
          this.answers$.next(value.map(o => JSON.parse(o)));
        } else {
          const answers = Object.keys(value)
            .map(s => new Object({
              answer: s,
              count: value[s],
              correct: !!this.question.items.find(a => a.getAnswerByLanguage(this.languageParams).toLowerCase() === s.toLowerCase())
            }) as ISummary);
          const correct = this.question.items.map(it => new Object({
            answer: it.getAnswerByLanguage(this.languageParams),
            count: 0,
            correct: true
          }) as ISummary);
          correct.forEach(it => {
            it.count = sum(answers.filter(a => it.answer.toLowerCase() === a.answer.toLowerCase()).map(v => v.count));
          });
          this.answers$.next(union(correct, answers.filter(a => !a.correct)).sort((a, b) => a.correct ? -1 : 1));
        }
      });
  }

  handleSelectCurrent(current: string): void {
    const currentValue = this.currentSelected$.getValue();
    if (currentValue === current) {
      return this.currentSelected$.next(null);
    }
    this.currentSelected$.next(current);
  }


  scrollToBottom(): void {
    setTimeout(() => {
      if (this.answersWrapper && this.answersWrapper.nativeElement) {
        this.answersWrapper.nativeElement.scrollTo({
          top: this.answersWrapper.nativeElement.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 100);
  }


  onSelectAnswers(value) {
    this.quizService.setQuestionFollowMePoolAction(this.documentPathParams, this.qKey, value);
  }
}
