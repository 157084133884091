import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {VideoStreamEditorDialogComponent} from '../video-stream-editor-dialog/video-stream-editor-dialog.component';
import {BehaviorSubject, combineLatest, distinctUntilChanged, filter, firstValueFrom, of, Subscription, take} from 'rxjs';
import {CommonService} from '../../../../../core/common.service';
import {YoutubePlayer} from '../video-stream-players/youtube-player';
import {EventsDataService} from '../../../../../services/events-data.service';
import {ANNOTATION_TYPE, ILanguageParams} from '../../../../../core/constants';
import {UtilsService} from '../../../../../core/utils.service';
import {IMultilingual} from '../../../shared/container-interface';
import {LANGUAGE} from '../../../../../core/language-constants';
import {InternalContentExchangeService, REQUEST_TYPE} from '../../../service/internal-content-exchange.service';
import {LoginService} from '../../../../../login/login.service';

enum VIDEO_TYPES {
  YOUTUBE = 'YOUTUBE',
  VIMEO = 'VIMEO',
  ROCHE = 'ROCHE',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  SRF = 'SRF'
}

export const VIDEO_TYPES_CLASSES = {
  [VIDEO_TYPES.YOUTUBE]: YoutubePlayer
};

interface IUrl {
  [language: string]: string;
}

type TUrl = IUrl | string;


export interface IVideoData {
  type: VIDEO_TYPES;
  url: TUrl;
  synchronizePosition: boolean;
  autoplay: boolean;
}

enum CONFIG {
  PLAYER_ELEMENT_STYLE = 'display: block; position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%;',
  WRAPPER_NAME = 'videoWrapper'
}

@Component({
  selector: 'app-video-stream',
  templateUrl: './video-stream.component.html',
  styleUrls: ['./video-stream.component.scss']
})
export class VideoStreamComponent extends AbstractContainerComponent implements OnInit {

  readonly VIDEO_TYPES = VIDEO_TYPES;
  readonly VIDEO_TYPES_CLASSES = VIDEO_TYPES_CLASSES;

  url$ = new BehaviorSubject<any>(null);
  videoType: VIDEO_TYPES;
  isStreamingmedia = false;
  videoElementId: string;
  private videoWrapperReady$ = new BehaviorSubject<Element>(null);
  private player;
  private requestGetAnnotationSubscription: Subscription;
  userApplicationInteraction$ = new BehaviorSubject<boolean>(false);
  languageParams$ = new BehaviorSubject<ILanguageParams>(null);
  playerInitialized$ = new BehaviorSubject<boolean>(false);
  followMeEnable = false;

  private elementObserver = new MutationObserver(() => {
    const element: Element = this.elementRef.nativeElement;
    const wrapper = element.children.namedItem(CONFIG.WRAPPER_NAME);
    if (wrapper) {
      this.videoWrapperReady$.next(wrapper);
      this.elementObserver.disconnect();
    }
  });

  @ViewChild('videoElement') videoElement: ElementRef;

  constructor(protected injector: Injector,
              private dialog: MatDialog,
              private dataService: EventsDataService,
              private loginService: LoginService,
              private common: CommonService,
              private elementRef: ElementRef,
              private internalExchangeService: InternalContentExchangeService) {
    super(injector);
    this.elementObserver.observe(this.elementRef.nativeElement, {childList: true});
  }

  private getLanguageUrl(value: IVideoData) {
    return UtilsService.getByLanguage(value, 'url', this.languageParams$.getValue());
  }

  ngOnInit(): void {
    this.dataService.common.userApplicationInteraction
      .pipe(this.takeUntilAlive())
      .subscribe(value => this.userApplicationInteraction$.next(value));

    combineLatest([this.currentLanguage$, this.data$, this.usedMultilingualContent$])
      .pipe(this.takeUntilAlive())
      .subscribe(([currentLanguage, value, usedMultilingualContent]: [LANGUAGE, IVideoData, IMultilingual]) => {
        this.languageParams$.next({
          defaultLanguage: this.defaultLanguage$.getValue(),
          currentLanguage: currentLanguage,
          usedMultilingualContent: usedMultilingualContent.multilingual,
          usedLanguages: usedMultilingualContent.usedLanguages
        });
        const url = this.getLanguageUrl(value);
        this.isStreamingmedia = url?.includes('https://streamingmedia.');
        this.videoType = value?.type;
        this.url$.next(url ? this.common.utils.getURL(url) : null);
        this.emmitCurrentLanguageTranslatedState();
      });

    combineLatest([
      this.data$.pipe(distinctUntilChanged((p, c) => UtilsService.isObjectsEqual(p, c))),
      !this.editorMode ? this.followMeEnable$.pipe(distinctUntilChanged((p, c) => UtilsService.isObjectsEqual(p, c))) : of(false),
      !this.editorMode ? this.isFollowMeManager$.pipe(distinctUntilChanged((p, c) => UtilsService.isObjectsEqual(p, c))) : of(false),
      this.videoWrapperReady$.pipe(distinctUntilChanged((p, c) => UtilsService.isObjectsEqual(p, c))),
    ])
      .pipe(this.takeUntilAlive())
      .subscribe(([data, followMeEnable, isFollowMeManager, videoWrapper]: [IVideoData, boolean, boolean, Element]) => {
        this.followMeEnable = followMeEnable;
        if (videoWrapper && data && VIDEO_TYPES_CLASSES[data.type]) {
          this.initializePlayer(videoWrapper, followMeEnable && isFollowMeManager);
        } else if (!data || !VIDEO_TYPES_CLASSES[data.type]) {
          this.destroyPlayer();
        }
        if (!!data?.type && !VIDEO_TYPES_CLASSES[data.type]) {
          this.playerInitialized$.next(true);
        }
      });
    this.registerVideoHotSpot();
  }

  private registerVideoHotSpot() {
    combineLatest([
      this.internalExchangeService.request$.pipe(filter(r => r?.receiverId === this.documentPathParams.containerId)),
      this.playerInitialized$.pipe(filter(v => !!v))]
    )
      .pipe(this.takeUntilAlive())
      .subscribe(([request]) => {
        switch (request.requestType) {
          case REQUEST_TYPE.GET_ANNOTATION_LIST:
            if (!this.player) {
              this.internalExchangeService.response$.next({
                senderId: this.documentPathParams.containerId,
                value: []
              });
              break;
            }
            if (!this.requestGetAnnotationSubscription) {
              this.requestGetAnnotationSubscription = this.player.videoList$
                .pipe(filter(v => !!v), this.takeUntilAlive())
                .subscribe(video => {
                  this.internalExchangeService.response$.next({
                    senderId: this.documentPathParams.containerId,
                    value: [video]
                  });
                });
            } else {
              this.internalExchangeService.response$.next({
                senderId: this.documentPathParams.containerId,
                value: [this.player.videoList$.getValue()]
              });
            }
            break;
          case REQUEST_TYPE.GOTO_ANNOTATION:
            if (request.task && typeof request.task.annotationId === 'string' && !request.task.annotationId.match(/^[0-9]+$/)) {
              this.player.gotoVideo({elementId: request.task.elementId, ...request.task.params});
            }
            break;
          case REQUEST_TYPE.STOP_ANIMATION:
            if (request.task && typeof request.task.annotationId === 'string' && !request.task.annotationId.match(/^[0-9]+$/)) {
              this.player.stopVideo();
            }
            break;
        }
      });
  }

  afterDeleteContainer() {
    this.internalExchangeService.request$.next({
      receiverId: null,
      requestType: REQUEST_TYPE.DELETE_CONTAINER,
      task: {containerId: this.documentPathParams.containerId}
    });
  }

  onDestroy() {
    super.onDestroy();
    this.destroyPlayer();
  }

  protected inputFollowMeData(value) {
  }

  onEdit() {
    const dialogRef = this.dialog.open(VideoStreamEditorDialogComponent, {
      width: '500px',
      height: '310px',
      disableClose: true,
      data: {
        videoData: this.data,
        languageParams$: this.languageParams$
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = result;
        }
        return !!result;
      });
  }

  onNext(): boolean {
    return false;
  }

  onPrev(): boolean {
    return false;
  }

  userApplicationInteractionClick() {
    if (VIDEO_TYPES_CLASSES[this.data.type] && this.player) {
      this.player.playVideo();
    }
  }

  initializePlayer(videoWrapper: Element, isFollowMeManager) {
    this.playerInitialized$.next(false);
    let elem;
    if (this.videoElementId) {
      elem = document.getElementById(this.videoElementId);
      if (elem) {
        elem.remove();
      }
    }
    const url = this.getLanguageUrl(this.data);
    if (!url) {
      return;
    }
    const split = url.split('/');
    const videoId = split[split.length - 1];
    this.videoElementId = `player-${UtilsService.createId(5, true)}`;
    elem = document.createElement('player');
    elem.id = this.videoElementId;
    elem.setAttribute('style', CONFIG.PLAYER_ELEMENT_STYLE);
    videoWrapper.appendChild(elem);
    const clazz = VIDEO_TYPES_CLASSES[this.data.type];
    const params = {
      synchronizePosition: this.data.synchronizePosition,
      autoplay: this.data.autoplay,
      usedFollowMe: this.loginService.timelineMode && !this.editorMode && this.followMeEnable,
      isManager: isFollowMeManager
    };
    this.player = new clazz(this.videoElementId, videoId, params, this.documentPathParams, this.dataService);
    this.player.playerReady$.pipe(filter(v => !!v), take(1), this.takeUntilAlive())
      .subscribe(() => {
        this.playerInitialized$.next(true);
        this.slidesList.find(sl => sl.id === this.documentPathParams.containerId).loaded$.next(true);
      });
  }

  destroyPlayer() {
    const elem = document.getElementById(this.videoElementId);
    if (elem) {
      elem.remove();
    }
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }
    this.videoElementId = null;
    const url = this.getLanguageUrl(this.data);
    this.url$.next(url ? this.common.utils.getURL(url) : null);
  }
}
