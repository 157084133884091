import {AbstractContent} from './AbstractContent';

export class CompetitionContent extends AbstractContent {
  type = 'competition';
  description: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.description) {
      this.description = obj.description;
    }
  }

  mergeContent(src: CompetitionContent) {
    super.mergeContent(src);

    if (this.description !== src.description) {
      this.description = src.description;
    }
  }
}
