import {type} from './util';
import {Action} from '@ngrx/store';
import {AppUser} from '../model/AppUser';
import {AbstractContent} from '../model/content/AbstractContent';
import {HttpErrorResponse} from '@angular/common/http';
import firebase from 'firebase/compat/app';

export const ActionTypes = {
  EMPTY: type('[Empty]')
  /* Users */
  , SET_CURRENT_USER: type('[Data] Set Current User')
  , CREATE_USER: type('[Data] Create User')
  , CREATE_USER_SUCCESS: type('[Data] Create User Success')
  , UPDATE_USERS: type('[Data] Update Users')
  , LOCAL_UPDATE_USERS: type('[Data] Local Update Users')
  , SET_ROLE_TO_USER: type('[Data] Set Role To User')
  , SET_USER_BLOCKED: type('[Data] Set User Blocked')
  , USERS_SERVER_FAIL: type('[Data] Users Server Failure')
  /* Events */
  , REMOVE_EVENT: type('[Data] Remove Events')
  , REMOVE_EVENT_SUCCESS: type('[Data] Remove Events Success')
  , UPDATE_EVENTS: type('[Data] Update Events')
  , EVENTS_SERVER_FAIL: type('[Data] Events Server Failure')
  /* Event Mode */
  , SET_PROJECTOR_MODE: type('[Data] Projector Mode')
  , GET_USER_ONLINE: type('[Data] Online users')
  , GET_USER_ONLINE_SUCCESS: type('[Data] Online users Success')
  , GET_USER_ONLINE_CANCEL: type('[Data] Online users Cancel')
  , GET_USER_ONLINE_SHORT: type('[Data] Online users short data')
  , GET_USER_ONLINE_SHORT_SUCCESS: type('[Data] Online users short data Success')
  , GET_USER_ONLINE_SHORT_CANCEL: type('[Data] Online users short data Cancel')
  , SET_CURRENT_CONTENT: type('[Data] Current content')
  , CLEAR_EVENT_MODE: type('[Data] Clear Event Mode')
  , NOTIFY_EVENT_MESSAGE: type('[Data] Notify Event Message')
  , FIRESTORE_SERVER_FAIL: type('[Data] Firestore Server Failure')
  , BACKEND_SERVER_FAIL: type('[Data] Backend Server Failure')
  , LOGOUT: type('[Data] Logout')


};

export class EmptyAction implements Action {
  type = ActionTypes.EMPTY;
  constructor(public payload: any) {
  }
}

export class FirestoreServerFailAction implements Action {
  type = ActionTypes.FIRESTORE_SERVER_FAIL;

  constructor(public error: {status: string, message: string}, public payload: any) {
  }

  static of (response: firebase.firestore.FirestoreError) {
    return new FirestoreServerFailAction({status: response.code, message: response.message}, response);
  }
}

export class BackendServerFailAction implements Action {
  type = ActionTypes.BACKEND_SERVER_FAIL;

  constructor(public error: {status: number, message: string}, public payload: any) {
  }

  static of (response: HttpErrorResponse) {
    return new BackendServerFailAction({status: response.status, message: response.message}, response);
  }
}

export class LogoutAction implements Action {
  type = ActionTypes.LOGOUT;

  constructor() {
  }
}

/**
 * Users
 */

export class SetCurrentUserAction implements Action {
  type = ActionTypes.SET_CURRENT_USER;

  constructor(public payload: AppUser) {
  }
}

export class UpdateAction implements Action {
  type = ActionTypes.UPDATE_USERS;

  constructor(public payload: any) {
  }
}

export class LocalUpdateAction implements Action {
  type = ActionTypes.LOCAL_UPDATE_USERS;

  constructor(public payload: any) {
  }
}

export class CreateUserAction implements Action {
  type = ActionTypes.CREATE_USER;

  constructor(public payload: any) {
  }
}

export class CreateUserSuccessAction implements Action {
  type = ActionTypes.CREATE_USER_SUCCESS;

  constructor(public payload: any) {
  }
}

export class UsersServerFailAction implements Action {
  type = ActionTypes.USERS_SERVER_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * Events
 */

export class GetUsersOnlineAction implements Action {
  type = ActionTypes.GET_USER_ONLINE;

  constructor(public payload: string) {
  }
}

export class GetUsersOnlineSuccessAction implements Action {
  type = ActionTypes.GET_USER_ONLINE_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetUsersOnlineCancelAction implements Action {
  type = ActionTypes.GET_USER_ONLINE_CANCEL;

  constructor() {
  }
}

export class GetUsersOnlineShortAction implements Action {
  type = ActionTypes.GET_USER_ONLINE_SHORT;

  constructor(public payload: string) {
  }
}

export class GetUsersOnlineShortSuccessAction implements Action {
  type = ActionTypes.GET_USER_ONLINE_SHORT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetUsersOnlineShortCancelAction implements Action {
  type = ActionTypes.GET_USER_ONLINE_SHORT_CANCEL;

  constructor() {
  }
}

export class UpdateEventsAction implements Action {
  type = ActionTypes.UPDATE_EVENTS;

  constructor(public payload: any) {
  }
}

export class RemoveEventAction implements Action {
  type = ActionTypes.REMOVE_EVENT;

  constructor(public payload: any) {
  }
}

export class RemoveEventSuccessAction implements Action {
  type = ActionTypes.REMOVE_EVENT_SUCCESS;

  constructor(public payload: any) {
  }
}

export class EventServerFailAction implements Action {
  type = ActionTypes.EVENTS_SERVER_FAIL;

  constructor(public payload: any) {
  }
}

/**
 * Event Mode
 */

export class SetProjectorMode implements Action {
  type = ActionTypes.SET_PROJECTOR_MODE;

  constructor(public payload: boolean) {
  }
}

export class SetCurrentContent implements Action {
  type = ActionTypes.SET_CURRENT_CONTENT;

  constructor(public payload: AbstractContent) {
  }
}

export class SetNotifyEventMessage implements Action {
  type = ActionTypes.NOTIFY_EVENT_MESSAGE;

  constructor(public payload: any) {
  }
}

export class ClearEventModeAction implements Action {
  type = ActionTypes.CLEAR_EVENT_MODE;

  constructor() {
  }
}

export type Actions
  = EmptyAction
  /* Users */
  | SetCurrentUserAction
  | CreateUserAction
  | CreateUserSuccessAction
  | UpdateAction
  | UsersServerFailAction
  /* Events */
  | RemoveEventAction
  | RemoveEventSuccessAction
  | UpdateEventsAction
  | EventServerFailAction
  /* Event Mode */
  | SetProjectorMode
  | SetCurrentContent
  | GetUsersOnlineAction
  | GetUsersOnlineSuccessAction
  | GetUsersOnlineShortAction
  | GetUsersOnlineShortSuccessAction
  | ClearEventModeAction
  | SetNotifyEventMessage
  ;
