import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {BehaviorSubject} from 'rxjs';
import {isEmpty, sum, union} from 'lodash';
import {AnswerEquality} from '../../../../../../../model/EventQuestion';

interface ISummary {
  answer: string;
  count: number;
  correct: boolean;
}

@Component({
  selector: 'app-question-text-results',
  templateUrl: './question-text-results.component.html',
  styleUrls: ['./question-text-results.component.scss']
})
export class QuestionTextResultsComponent extends AbstractQuizQuestionResultsComponent {

  answers$ = new BehaviorSubject<string[] | ISummary[]>([]);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    this.rebuildSummaryAnswers();
  }

  protected onReceiveQuestionAnswers() {
    this.rebuildSummaryAnswers();
  }

  protected rebuildSummaryAnswers() {
    const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
    const sumQAnswers = this.summaryQuestionAnswers;
    if (isEmpty(sumQAnswers)) {
      this.answers$.next([]);
    } else if (Array.isArray(sumQAnswers)) {
      this.answers$.next(sumQAnswers);
    } else {
      const answers = Object.keys(sumQAnswers)
        .map(s => new Object({
          answer: s,
          count: sumQAnswers[s],
          correct: !!correctEquality.find(a => a.getAnswerByLanguage(this.languageParams).toLowerCase() === s.toLowerCase())
        }) as ISummary);
      const correct = correctEquality.map(it => new Object({
        answer: it.getAnswerByLanguage(this.languageParams),
        count: 0,
        correct: true
      }) as ISummary);
      correct.forEach(it => {
        it.count = sum(answers.filter(a => it.answer.toLowerCase() === a.answer.toLowerCase()).map(v => v.count));
      });
      this.answers$.next(union(correct, answers.filter(a => !a.correct)).sort((a, b) => a.correct ? -1 : 1));
    }
  }
}
