import {Injectable} from '@angular/core';
import {AbstractContent} from '../model/content/AbstractContent';
import {cloneDeep, isEmpty, merge, sum, union} from 'lodash';
import {Constants, DATE_FORMAT, ILanguageParams, SECTION_LEARNING_TYPE, SELF_LEARNING_MODE} from '../core/constants';
import {UtilsService} from '../core/utils.service';
import {QuestionnaireContent} from '../model/content/QuestionnaireContent';
import {EventQuestion} from '../model/EventQuestion';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {TimeLineService} from './time-line.service';
import {CONTAINER_ITEM_TYPE, ContentContainer} from '../model/content/ContentContainer';
import {Quiz} from '../modules/content-container/components/quiz/quiz-model/quiz';
import {LANGUAGE} from '../core/language-constants';
import {Event} from '../model/Event';
import {
  questionTypeUseCorrectAnswersOption, questionTypeWithoutCheckingCorrectness
} from '../modules/content-container/components/quiz/quiz-components/shared/lib/quiz-question-common-lib';

export interface ISectionSelfLearningSetting {
  isSelfLearningSection: boolean;
  fixedOrder: boolean;
  mainSelfLearningSectionId: string;
}
export interface ISectionContents {[sectionId: string]: {[contentId: string]: string}; }
export interface ILearningContentsTime {[sectionId: string]: {[contentId: string]: number}; }
export interface ILearningContentsComplete {[sectionId: string]: {[contentId: string]: any}; }
export interface ISectionsLearningOrder {[sectionId: string]: ILearningSection; }
export interface ILearningSection {id: string;
                                   items: ISectionComplete[];
                                   originId: string;
                                   parentId: string;
                                   completePercent: number;
                                   selfLearningType: SECTION_LEARNING_TYPE;
                                   itemsLearningType: SECTION_LEARNING_TYPE; }
export interface ISectionComplete {id: string; selfLearningType: SECTION_LEARNING_TYPE; completePercent: number; }
export interface ILearningComplete {learningType: SECTION_LEARNING_TYPE; completePercent: number; }

@Injectable()
export class SelfLearningService {

  userInterfaceLanguage: LANGUAGE;
  event: Event;

  private _sectionsContents: ISectionContents = {}; // sectionId/contentId = content type
  private _userLearningContentsTime: ILearningContentsTime = {};
  private _userLearningContentsComplete$ = new BehaviorSubject<ILearningContentsComplete>({});
  private _learningSectionsOrder: ISectionsLearningOrder = {};
  private _learningSectionsChangeSubject = new Subject();
  private _isInSelfLearningSectionSubject = new Subject<boolean>();
  private _selfLearningReadingCompleteProcess = new Subject<boolean>();
  private _selfLearningTimerValue = new Subject<number>();

  constructor(private utils: UtilsService) { }

  public resetServiceValues() {
    this._sectionsContents = {};
    this._userLearningContentsComplete$ = new BehaviorSubject<ILearningContentsComplete>({});
    this._userLearningContentsTime = {};
    this._learningSectionsOrder = {};
    this.event = null;
    this.userInterfaceLanguage = null;
  }

  get sectionsContents(): ISectionContents {
    return this._sectionsContents;
  }

  set sectionsContents(value: ISectionContents) {
    this._sectionsContents = value ? value : {};
  }

  get userLearningContentsTime(): ILearningContentsTime {
    return this._userLearningContentsTime;
  }

  set userLearningContentsTime(value: ILearningContentsTime) {
    this._userLearningContentsTime = value ? value : {};
  }

  get userLearningContentsComplete$(): Observable<ILearningContentsComplete> {
    return this._userLearningContentsComplete$.asObservable();
  }

  get userLearningContentsComplete(): ILearningContentsComplete {
    return this._userLearningContentsComplete$.getValue();
  }

  set userLearningContentsComplete(value: ILearningContentsComplete) {
    this._userLearningContentsComplete$.next(value ? value : {});
  }

  get learningSectionsOrder(): ISectionsLearningOrder {
    return this._learningSectionsOrder;
  }

  set learningSectionsOrder(value: ISectionsLearningOrder) {
    this._learningSectionsOrder = value ? value : {};
  }

  get learningSectionsChangeSubject(): Subject<any> {
    return this._learningSectionsChangeSubject;
  }

  get isInSelfLearningSectionSubject(): Subject<boolean> {
    return this._isInSelfLearningSectionSubject;
  }

  get selfLearningReadingCompleteProcess(): Subject<boolean> {
    return this._selfLearningReadingCompleteProcess;
  }

  get selfLearningTimerValue(): Subject<number> {
    return this._selfLearningTimerValue;
  }

  private get DEFAULT_LANGUAGE() {
    return (this.event?.defaultLanguage ?? Constants.TIMELINE_DEFAULT_LANGUAGE) as LANGUAGE;
  }

  private getEventLanguageParams(): ILanguageParams {
    const ml = this.event?.getMultilingual();
    return {
      defaultLanguage: this.DEFAULT_LANGUAGE,
      currentLanguage: this.userInterfaceLanguage,
      usedMultilingualContent: ml?.multilingual,
      usedLanguages: ml?.usedLanguages
    };
  }

  getContentLearningTime(content: AbstractContent) {
    return this.userLearningContentsTime[content.parentId] && this.userLearningContentsTime[content.parentId][content.id] ?
      this.userLearningContentsTime[content.parentId][content.id] : 0;
  }

  getContentLearningComplete(content: AbstractContent, userId: string) {
    if (content.type === Constants.CONTENT_TYPE_MODULAR ||
      (content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
        this.checkContentContainerContainOnlyOptionalQuestions(content))) {
      return this.userLearningContentsComplete[content.parentId] && this.userLearningContentsComplete[content.parentId][content.id] &&
        (this.getContentLearningTime(content) >= content.minimalTimeSpentOnContent ||
          this.getContentLearningTime(content) === Constants.LEARNING_RESULT_COMPLETE) ?
        this.userLearningContentsComplete[content.parentId][content.id] : false;
    } else if (content.type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
      return this.checkQuestionnaireContainOnlyOptionalQuestions(content as QuestionnaireContent) ||
        this.checkQuestionnaireAllCorrectTypeQuestionsAnsweredOrSimpleAnswered(content as QuestionnaireContent, userId);
    } else if (content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
      !this.checkContentContainerContainOnlyOptionalQuestions(content)) {
      return this.checkContentContainerAllQuizCorrectTypeQuestionsAnsweredOrSimpleAnswered(content as ContentContainer, userId);
    }
  }

  getContentReadingAndLearningComplete(content: AbstractContent, userId: string) {
    if (content.type === Constants.CONTENT_TYPE_MODULAR ||
        (content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
          this.checkContentContainerContainOnlyOptionalQuestions(content))) {
      return this.userLearningContentsComplete[content.parentId] && this.userLearningContentsComplete[content.parentId][content.id] &&
      (this.getContentLearningTime(content) >= content.minimalTimeSpentOnContent ||
        this.getContentLearningTime(content) === Constants.LEARNING_RESULT_COMPLETE) ?
        this.userLearningContentsComplete[content.parentId][content.id] : false;
    } else if (content.type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
      return this.userLearningContentsComplete[content.parentId] && this.userLearningContentsComplete[content.parentId][content.id] &&
        (this.checkQuestionnaireContainOnlyOptionalQuestions(content as QuestionnaireContent) ||
        this.checkQuestionnaireAllCorrectTypeQuestionsAnsweredOrSimpleAnswered(content as QuestionnaireContent, userId));
    } else if (content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER &&
      !this.checkContentContainerContainOnlyOptionalQuestions(content)) {
      return this.userLearningContentsComplete[content.parentId] && this.userLearningContentsComplete[content.parentId][content.id] &&
        this.checkContentContainerAllQuizCorrectTypeQuestionsAnsweredOrSimpleAnswered(content as ContentContainer, userId);
    }
  }

  private checkQuestionnaireContainOnlyOptionalQuestions(content: QuestionnaireContent) {
    const qIds = Object.keys(content.questions || {});
    return !qIds.some(id => !content.questions[id].optional);
  }

  private checkQuestionnaireAllCorrectTypeQuestionsAnsweredOrSimpleAnswered(content: QuestionnaireContent | Quiz, userId: string) {
    const qIds = Object.keys(content.questions || {});
    for (const id of qIds) {
      const q = new EventQuestion(content.questions[id]);
      if (q.optional) {
        continue;
      }
      if ((questionTypeUseCorrectAnswersOption(q.storypoint) && !q.useCorrectAnswers) ||
            questionTypeWithoutCheckingCorrectness(q.storypoint)) {
        if (isEmpty(q.getUserAnswers(userId))) {
          return false;
        } else {
          continue;
        }
      }
      const check = q.checkCorrectUserAnswers(userId, this.getEventLanguageParams());
      if (!check || !check.checkResult) {
        return false;
      }
    }
    return true;
  }

  checkContentContainerContainOnlyOptionalQuestions(content: AbstractContent) {
    if (content.selfLearningMode !== SELF_LEARNING_MODE.ALL_QUESTIONNAIRES_COMPLETE) {
      return true;
    }
    const quizList = (content as ContentContainer).items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ && !isEmpty(it.data));
    return !quizList.length  || !quizList.some(qItem => Object.keys((qItem.data as Quiz).questions || {})
      .some(qId => !(qItem.data as Quiz).questions[qId].optional));
  }

  private checkContentContainerAllQuizCorrectTypeQuestionsAnsweredOrSimpleAnswered(content: ContentContainer, userId: string) {
    const quizList = (content as ContentContainer).items.filter(it => it.type === CONTAINER_ITEM_TYPE.QUIZ);
    return quizList.length > 0 &&
      quizList.every(q => this.checkQuestionnaireAllCorrectTypeQuestionsAnsweredOrSimpleAnswered(q.data as Quiz, userId));
  }

  getContentSelfLearningMode(content: AbstractContent) {
    switch (content.type) {
      case Constants.CONTENT_TYPE_QUESTIONNAIRE:
        return null;
      case Constants.CONTENT_TYPE_MODULAR:
        return content.selfLearningMode;
      case Constants.CONTENT_TYPE_CONTENT_CONTAINER:
        return this.checkContentContainerContainOnlyOptionalQuestions(content) ? content.selfLearningMode : null;
      default:
        return null;
    }
  }

  private getSectionContentsLearningCompleteType(object: ILearningSection): ILearningComplete {
    const sectionId = object.id;
    const originId = object.originId;
    const directContentsObj = this._sectionsContents[sectionId] ?? {};
    const originalContentsObj = originId ? this._sectionsContents[originId] : {};
    let contentsIds = [];
    if (isEmpty(directContentsObj) && isEmpty(originalContentsObj)) {
      return {learningType: SECTION_LEARNING_TYPE.NOT_IN_LEARNING, completePercent: 0};
    } else {
      contentsIds = union(
        Object.keys(directContentsObj).filter(id =>
          (directContentsObj[id] === Constants.CONTENT_TYPE_MODULAR ||
            directContentsObj[id] === Constants.CONTENT_TYPE_CONTENT_CONTAINER ||
            directContentsObj[id] === Constants.CONTENT_TYPE_QUESTIONNAIRE) &&
          (!this._userLearningContentsTime[sectionId] ||
            (this._userLearningContentsTime[sectionId] && !this._userLearningContentsTime[sectionId][id]) ||
           (this._userLearningContentsTime[sectionId] && this._userLearningContentsTime[sectionId][id] &&
             this._userLearningContentsTime[sectionId][id] !== Constants.LEARNING_RESULT_NOT_USED))),
        Object.keys(originalContentsObj).filter(id =>
            (originalContentsObj[id] === Constants.CONTENT_TYPE_MODULAR ||
              originalContentsObj[id] === Constants.CONTENT_TYPE_CONTENT_CONTAINER ||
              originalContentsObj[id] === Constants.CONTENT_TYPE_QUESTIONNAIRE) &&
            (!this._userLearningContentsTime[originId] ||
              (this._userLearningContentsTime[originId] && !this._userLearningContentsTime[originId][id]) ||
              (this._userLearningContentsTime[originId] && this._userLearningContentsTime[originId][id] &&
                this._userLearningContentsTime[originId][id] !== Constants.LEARNING_RESULT_NOT_USED))));
    }
    if (isEmpty(contentsIds)) {
      return {learningType: SECTION_LEARNING_TYPE.NOT_IN_LEARNING, completePercent: 0};
    }
    const completeContents = merge(
      cloneDeep(this.userLearningContentsComplete[sectionId]),
      cloneDeep(this.userLearningContentsComplete[originId]));
    if (isEmpty(completeContents)) {
      return {learningType: SECTION_LEARNING_TYPE.NOT_OPEN, completePercent: 0};
    }
    const learningType = contentsIds.every(id => !!completeContents[id]) ?
      SECTION_LEARNING_TYPE.LEARNING_COMPLETE : SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE;
    const completePercent = learningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE ? 100 :
      (100 / (contentsIds.length / Object.keys(completeContents).length));
    return {learningType, completePercent};
  }

  mergeLearningSectionsResults() {
    const sectionsIds = Object.keys(this._learningSectionsOrder).map(id => id);
    for (const id of sectionsIds) {
      const obj = this._learningSectionsOrder[id];
      this.getSectionsSelfLearningType(obj);
    }
  }

  private getSectionsSelfLearningType(object: ILearningSection) {
    const inLearning = (v: ISectionComplete) => v.selfLearningType !== SECTION_LEARNING_TYPE.NOT_IN_LEARNING;
    if (!object) {
      return;
    }
    const lcr = this.getSectionContentsLearningCompleteType(object);
    object.selfLearningType = lcr.learningType;
    object.completePercent = lcr.completePercent;
    if (!isEmpty(object.items)) {
      for (const item of object.items) {
        this.getSectionsSelfLearningType(this._learningSectionsOrder[item.id]);
      }
      if (object.items.every(o => o.selfLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.NOT_IN_LEARNING;
      } else if (object.items.every(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.LEARNING_COMPLETE;
      } else if (object.items.every(o => o.selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.NOT_OPEN;
      } else if (object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE;
      } else if (object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE) &&
         object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE;
      } else if (!object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE) &&
        !object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE) &&
         object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING) &&
         object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.NOT_OPEN;
      } else if (!object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE) &&
          !object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN) &&
          object.items.some(o => o.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE)) {
        object.itemsLearningType = SECTION_LEARNING_TYPE.LEARNING_COMPLETE;
      }
      const itemsComplete = object.items.filter(it => inLearning(it))
        .every(it => it.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE);
      const items = object.items.filter(it => inLearning(it));
      const selfIncluded = object.selfLearningType !== SECTION_LEARNING_TYPE.NOT_IN_LEARNING ? 1 : 0;
      object.completePercent = object.itemsLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE && itemsComplete ? 100 :
        (object.completePercent + sum(items.map(o => o.completePercent))) / (items.length + selfIncluded);
    } else {
      object.itemsLearningType = object.selfLearningType;
    }
    if (object.parentId) {
      const parent = this._learningSectionsOrder[object.parentId];
      if (parent) {
        const item = parent.items.find(o => o.id === object.id);
        if (item) {
          if (object.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE ||
            object.itemsLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE) {
            item.selfLearningType = SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE;
          } else if (object.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE &&
            object.itemsLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE) {
            item.selfLearningType = SECTION_LEARNING_TYPE.LEARNING_COMPLETE;
          } else if ((object.selfLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE &&
            object.itemsLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING) ||
            (object.selfLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING &&
              object.itemsLearningType === SECTION_LEARNING_TYPE.LEARNING_COMPLETE)) {
            item.selfLearningType = SECTION_LEARNING_TYPE.LEARNING_COMPLETE;
          } else if (object.selfLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING &&
            object.itemsLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING) {
            item.selfLearningType = SECTION_LEARNING_TYPE.NOT_IN_LEARNING;
          } else if (object.selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN &&
            object.itemsLearningType === SECTION_LEARNING_TYPE.NOT_OPEN) {
            item.selfLearningType = SECTION_LEARNING_TYPE.NOT_OPEN;
          } else if ((object.selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN &&
            object.itemsLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING) ||
            (object.selfLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING &&
              object.itemsLearningType === SECTION_LEARNING_TYPE.NOT_OPEN)) {
            item.selfLearningType = SECTION_LEARNING_TYPE.NOT_OPEN;
          }
          item.completePercent = object.completePercent;
        }
      }
    }
  }

  getSelfLearningCompleteDateStr(content: AbstractContent) {
    const d = this.userLearningContentsComplete[content.parentId] &&
      this.userLearningContentsComplete[content.parentId][content.id];
    if (d) {
      return this.utils.formatDate(d, DATE_FORMAT.DD_MM_YYYY);
    } else {
      return '';
    }
  }


  isNotInLearning(section: SectionTimeline) {
    return this.learningSectionsOrder[section.getId()] &&
      this.learningSectionsOrder[section.getId()].selfLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING &&
      this.learningSectionsOrder[section.getId()].itemsLearningType === SECTION_LEARNING_TYPE.NOT_IN_LEARNING;
  }

  isLearningClosed(section: SectionTimeline, hasSectionAccess, service: TimeLineService) {
    const parentId = section.parentId;
    const getPrev = (p: SectionTimeline, index: number) => {
      let cIndex = index - 1;
      let sItem = p.items[cIndex] as SectionTimeline;
      while (sItem && this.isNotInLearning(sItem)) {
        cIndex--;
        sItem = cIndex >= 0 ? p.items[cIndex] as SectionTimeline : null;
      }
      return !sItem ? parent : sItem;
    };
    const parentSelfLearningSetting = service.isSectionHierarchicalParentSelfLearning(section);
    if (hasSectionAccess || section.isRoot || section.isSelfLearningSection || isEmpty(parentSelfLearningSetting) ||
      !parentSelfLearningSetting.isSelfLearningSection || !parentSelfLearningSetting.fixedOrder ||
      (this.learningSectionsOrder[section.getId()] &&
        this.learningSectionsOrder[section.getId()].selfLearningType !== SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE &&
        this.learningSectionsOrder[section.getId()].selfLearningType !== SECTION_LEARNING_TYPE.NOT_OPEN &&
        this.learningSectionsOrder[section.getId()].itemsLearningType !== SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE &&
        this.learningSectionsOrder[section.getId()].itemsLearningType !== SECTION_LEARNING_TYPE.NOT_OPEN)) {
      return false;
    }
    if (this.learningSectionsOrder[section.getId()] &&
      this.learningSectionsOrder[parentId].selfLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE) {
      return true;
    }
    const parent = service.planeListContent[parentId];
    if (parent) {
      const index = (parent.sectionContent.items || []).findIndex(o => o.id === section.getId());
      if (index === -1) {
        return false;
      } else if (index === 0) {
        return false;
      } else if (index > 0) {
        const prev = getPrev(parent.sectionContent, index);
        const isClose =
          this.learningSectionsOrder[prev.getId()].selfLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE ||
          this.learningSectionsOrder[prev.getId()].selfLearningType === SECTION_LEARNING_TYPE.NOT_OPEN ||
          this.learningSectionsOrder[prev.getId()].itemsLearningType === SECTION_LEARNING_TYPE.LEARNING_NOT_COMPLETE ||
          this.learningSectionsOrder[prev.getId()].itemsLearningType === SECTION_LEARNING_TYPE.NOT_OPEN;
        if (isClose) {
          section.expand = false;
        }
        return isClose;
      }
    }
    return false;
  }

}
