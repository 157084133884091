import {CommonModule} from '@angular/common';
import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {UtilsService} from '../../../../../../../../../core/utils.service';
import {BaseSettingsComponent} from '../base-settings';
import {LanguageSelectorModule} from '../../../../../../../../../components/language-selector/language-selector.module';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {DateTimePickerModule} from '../../../../../../../../date-time-picker/date-time-picker.module';
import {LoadingProgressComponent} from '../../../../../../../../../components/loading-progress/loading-progress.component';
import {cloneDeep, isEmpty} from 'lodash';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {PipeModule} from '../../../../../../../../../pipes/pipe.module';
import {
  deleteColumnGroupCorrectAnswers,
  getColumnGroupCorrectAnswers,
  getOrCreateColumnGroupCorrectAnswersOption
} from '../../../question-table-shared/question-table-utils';
import {AnswerQuestion, IGroupCorrectAnswers} from '../../../../../../../../../model/EventQuestion';
import {
  IColumnType,
  ICorrectTextOption,
  TABLE_COLUMN_TYPE,
  TableColumn,
  TableRowBase
} from '../../table-poll-model/TablePollModel';
import {MatMenu, MatMenuModule} from '@angular/material/menu';
import {MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {QuestionTableSelectStateService} from '../../../question-table-shared/question-table-select-state.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Constants} from '../../../../../../../../../core/constants';

@Component({
  selector: 'app-open-text-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    LanguageSelectorModule,
    DateTimePickerModule,
    LoadingProgressComponent,
    MatSlideToggle,
    PipeModule,
    MatMenu,
    MatOption,
    MatSelect,
    MatMenuModule
  ],
  templateUrl: './open-text-dialog.component.html',
  styleUrl: './open-text-dialog.component.scss',
  providers: [QuestionTableSelectStateService]
})
export class OpenTextDialogComponent extends BaseSettingsComponent {
  form: FormGroup;
  questions$ = new BehaviorSubject<{id: string, answer: object}[]>([]);
  hasEmptyCorrectAnswer = false;
  callerCtx: any;
  column: TableColumn;
  columnType: IColumnType;
  correctOptions: ICorrectTextOption[] = [];
  groupsCorrectAnswers: IGroupCorrectAnswers[];

  private readonly dialogRef = inject(MatDialogRef<OpenTextDialogComponent>);
  protected readonly data = inject<{
    callerCtx: any,
    formGroup: FormGroup;
    groupsCorrectAnswers: IGroupCorrectAnswers[];
    questions: {id: string, answer: object, orderIndex: number}[] }>(MAT_DIALOG_DATA);
  private cdf = inject(ChangeDetectorRef);
  private selectStateService = inject(QuestionTableSelectStateService);
  private subscriptions = new Map();


  constructor() {
    super();
    this.dialogRef.disableClose = true;
  }

  validateOption(c: AbstractControl) {
    if (this.isCorrectAnswerEnabled?.value && isEmpty(c.value)) {
      return {error: true};
    }
    return null;
  }

  ngOnInit(): void {
    this.init(this.data.callerCtx);
    this.callerCtx = this.data.callerCtx;
    const formGroup = this.data.formGroup;
    this.questions$.next(this.data.questions ?? []);

    this.column = new TableColumn(cloneDeep(formGroup?.value ?? {
      type: {
        inputType: TABLE_COLUMN_TYPE.TEXT,
      },
      tableRows: [new TableRowBase()]
    }));
    this.columnType = this.column.type;
    this.groupsCorrectAnswers = cloneDeep(this.data.groupsCorrectAnswers) ?? [];
    const columnGroupCorrectAnswers = cloneDeep(getColumnGroupCorrectAnswers(this.groupsCorrectAnswers, this.column.id));
    this.correctOptions = <ICorrectTextOption[]>Object.keys(columnGroupCorrectAnswers || {})
      .map(id => new Object({id, ...columnGroupCorrectAnswers[id]}));
    this.form = this.fb.group({
      isCorrectAnswerEnabled: [!!this.columnType.isCorrectAnswerEnabled],
      columnName: [this.column.getColumnNameByLanguage(this.callerCtx.languageParams),
        [Validators.required, Validators.maxLength(256)]],
      options: formGroup
        ? this.fb.array(
          cloneDeep(this.correctOptions)
            .sort(this.common.utils.comparator(Constants.ORDERINDEX))
            .map((v: ICorrectTextOption) => this.fb.group({
                id: [v.id],
                option: [UtilsService.getByLanguage(v, 'option', this.languageParams), [this.validateOption.bind(this)]],
                orderIndex: [v.orderIndex ?? new Date().getTime()],
                rows: [v.rows || [], [this.validateOption.bind(this)]]
              })
            )
        )
        : this.fb.array([])
    });

    if (formGroup) {
      this.resetSelectState();
      this.selectStateService.initializeOpenStates(0, this.options.value.length);
    }

    this.columnName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.column.setColumnNameByLanguage(value, this.callerCtx.languageParams);
    });
    this.isCorrectAnswerEnabled.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
      if (isEmpty(this.options.value)) {
        this.addOption();
      }
      this.columnType.isCorrectAnswerEnabled = value;
    });

    // Store subscriptions
    (this.form.get('options') as FormArray).controls.forEach(c => {
      // Handle changes to the options values
      if (!isEmpty(c.value)) {
        this.subscriptions.set(c.value.id, c.valueChanges.pipe(takeUntil(this.destroy$))
          .subscribe((value: ICorrectTextOption) => {
            const option = <ICorrectTextOption>value;
            const typeOption = this.correctOptions.find(o => o.id === option.id);
            if (typeOption) {
              const correctOption: ICorrectTextOption = getOrCreateColumnGroupCorrectAnswersOption(this.groupsCorrectAnswers,
                this.column.id, typeOption.id, TABLE_COLUMN_TYPE.TEXT);
              UtilsService.setByLanguage(typeOption, 'option', value.option, this.callerCtx.languageParams);
              correctOption.rows = value.rows;
              correctOption.option = typeOption.option;
              correctOption.orderIndex = typeOption.orderIndex;
            }
          }));
      }
    });
    // Handle changes to the options array
    this.options.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((list: ICorrectTextOption[]) => {
      for (const opt of list) {
        if (!this.subscriptions.has(opt.id)) {
          const ctrl = this.options.controls.find(c => c.value.id === opt.id);
          if (ctrl) {
            this.subscriptions.set(opt.id, ctrl.valueChanges.pipe(takeUntil(this.destroy$))
              .subscribe((value: ICorrectTextOption) => {
                const typeOption = this.correctOptions.find(o => o.id === value.id);
                if (typeOption) {
                  const correctOption: ICorrectTextOption = getOrCreateColumnGroupCorrectAnswersOption(this.groupsCorrectAnswers,
                    this.column.id, typeOption.id, TABLE_COLUMN_TYPE.TEXT);
                  UtilsService.setByLanguage(typeOption, 'option', value.option, this.callerCtx.languageParams);
                  correctOption.rows = value.rows;
                  correctOption.option = typeOption.option;
                  correctOption.orderIndex = typeOption.orderIndex;
                }
              }));
          }
        }
      }
      for (const id of this.subscriptions.keys()) {
        if (!this.options.controls.find(c => c.value.id === id)) {
          this.subscriptions.delete(id);
          this.correctOptions = this.correctOptions.filter(o => o.id !== id);
        }
      }
    });

    this.languageChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.columnName.patchValue(this.column.getColumnNameByLanguage(this.callerCtx.languageParams));
      this.options.controls
        .forEach(c => {
          const typeOption = this.correctOptions.find(o => o.id === c.value.id);
          if (typeOption) {
            c.patchValue({option: UtilsService.getByLanguage(typeOption, 'option', this.callerCtx.languageParams)});
          }
        });
      this.questions$.next(cloneDeep(this.data.questions ?? []));
    });

  }

  get columnName(): FormControl {
    return (this.form?.get('columnName') as FormControl);
  }

  get options(): FormArray {
    return this.form.get('options') as FormArray;
  }

  get isCorrectAnswerEnabled(): AbstractControl {
    return (this.form?.get('isCorrectAnswerEnabled') as FormControl);
  }

  addOption(option?: string): void {
    // delay for generating different orderIndex
    const start = performance.now();
    while (performance.now() - start < 10) { }
    const optionGroup = this.fb.group({
      id: [AnswerQuestion.genAnswerId()],
      option: [option || '', [this.validateOption.bind(this)]],
      rows: ['', [this.validateOption.bind(this)]],
      orderIndex: new Date().getTime()
    });
    this.options.push(optionGroup);
    this.correctOptions.push(optionGroup.value as any);
    this.resetSelectState();
    this.selectStateService.initializeOpenStates(0, this.options.value.length);
  }

  removeOption(index: number): void {
    const correctOption = getColumnGroupCorrectAnswers(this.groupsCorrectAnswers, this.column.id);
    if (!isEmpty(correctOption)) {
      const optionId = this.correctOptions.map(o => o.id)[index];
      delete correctOption[optionId];
    }
    this.options.removeAt(index);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onDone(event): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      const errorMsgs = this.checkTheRequiredFieldsAndReturnErrorMsgs();
      if (errorMsgs.length) {
        const targetRect = event.target.getBoundingClientRect();
        const topPosition = targetRect.top - (targetRect.top * 0.4);
        // this.toasterService.openCustomSnackbarComponent(topPosition, errorMsgs, CustomSnackbarComponent);
      }
    }
    if (this.form.valid) {
      if (!this.columnType.isCorrectAnswerEnabled) {
        deleteColumnGroupCorrectAnswers(this.groupsCorrectAnswers, this.column.id);
      }
      this.dialogRef.close({id: this.column.id, columnType: this.columnType, groupsCorrectAnswers: this.groupsCorrectAnswers});
    }
  }

  checkTheRequiredFieldsAndReturnErrorMsgs(): string[] {
    const errorMsgs: any[] = [];
    if (this.columnName?.invalid) {
      errorMsgs.push(this.common.i18n('questionnaire.table.editor.settings.error.mgs.column.name.empty'));
    }
    if (this.options?.invalid) {
      errorMsgs.push(this.common.i18n('questionnaire.table.editor.settings.select.menu.error.mgs.options.minimum.size'));
    }
    if (this.isCorrectAnswerEnabled?.value) {
      this.hasEmptyCorrectAnswer = this.options.controls.some(option => option.get('rows')?.value?.length === 0);
      if (this.hasEmptyCorrectAnswer) {
        errorMsgs.push(this.common.i18n('questionnaire.table.editor.settings.select.menu.error.mgs.correct.answer.empty'));
      } else {
        this.hasEmptyCorrectAnswer = false;
      }
    }
    return errorMsgs;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.options.controls, event.previousIndex, event.currentIndex);
      this.cdf.detectChanges();
      this.options.setValue(this.options.controls.map((control) => control.value));
    }
  }

  resetSelectState() {
    this.selectStateService.resetSelectState();
  }

  onSelectOpened(colIndex: number, rowIndex: number) {
    this.selectStateService.onSelectOpened(colIndex, rowIndex);
  }

  onSelectClosed(colIndex: number, rowIndex: number) {
    this.selectStateService.onSelectClosed(colIndex, rowIndex);
  }

  getSelectState(colIndex: number, rowIndex: number): boolean {
    return this.selectStateService.getOpenState(colIndex, rowIndex);
  }

  translateFormControlOption(ctrl: AbstractControl, translateId: any) {
    this.translateFormControlValue(ctrl as FormControl, translateId);
  }


}
