import { Injectable } from '@angular/core';
import {AbstractContainerService} from '../../service/abstract-container-service';
import {StorageDataService} from '../../../../services/storage-data.service';
import {CommonService} from '../../../../core/common.service';
import {IDocumentPathParams} from '../../../../services/event-mode-api.service';

@Injectable({
  providedIn: 'root'
})
export class ZoomifyDocumentService extends AbstractContainerService {

  constructor(private storageDataService: StorageDataService,
              private common: CommonService) {
    super();
  }

  async save(storagePath: string, data, documentPathParams?: IDocumentPathParams): Promise<any> {
    if (data && data.src.indexOf(';base64,') > -1) {
      const fileName = this.common.utils.addTimeToFileName(data.id);
      await this.storageDataService.uploadAnyObjectToStorageByDataPath(storagePath, fileName, data.src, 'application/pdf')
        .then(snapshot => snapshot.ref.getDownloadURL()
          .catch(e => this.common.log.error(e))
          .then(async url => {
            if (data.srcUrl && data.srcUrl.indexOf(';base64,') === -1) {
              const deleteName = this.common.utils.extractFileNameFromUrl(data.srcUrl);
              await this.storageDataService.deleteObjectFromStorageByDataPath(storagePath, deleteName)
                .catch(e => {
                  this.common.log.error(e);
                  throw e;
                });
            }
            delete data.srcUrl;
            data.src = url;
          }))
        .catch(e => this.common.log.error(e));
    }
    return Promise.resolve(data);
  }

  async delete(storagePath: string, data, documentPathParams?: IDocumentPathParams): Promise<boolean> {
    if (data?.src) {
      const fileName = this.common.utils.extractFileNameFromUrl(data.src);
      await this.storageDataService.deleteObjectFromStorageByDataPath(storagePath, fileName)
        .catch(e => this.common.log.error(e));
    }
    return Promise.resolve(true);
  }
}
