import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {BehaviorSubject} from 'rxjs';
import {EventQuestion, IGapAnswerData, IGapResultDataMap, TableRowAnswerQuestion} from '../../../../../../../model/EventQuestion';
import {merge} from 'lodash';
import {Constants} from '../../../../../../../core/constants';

@Component({
  selector: 'app-question-checkbox-gap-filling-results',
  templateUrl: './question-checkbox-gap-filling-results.component.html',
  styleUrls: ['./question-checkbox-gap-filling-results.component.scss']
})
export class QuestionCheckboxGapFillingResultsComponent extends AbstractQuizQuestionResultsComponent {

  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapResultData$ = new BehaviorSubject<IGapResultDataMap>({});
  gapTaskText$ = new BehaviorSubject<string>(null);
  rowsAnswersQuestion: TableRowAnswerQuestion[] = [];
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    (this.question.items || []).forEach(item => this.rowsAnswersQuestion.push(new TableRowAnswerQuestion(item, this.languageParams)));
    this.rowsAnswersQuestion.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.gapAnswersData$
      .next(this.question.showCorrectAnswers || this.isPresenter || (!this.question.showCorrectAnswers && !this.editorMode) ?
        this.question.groupsCorrectAnswers?.find(g => g.defaultGroup)
          ?.correctEquality
          ?.map(v => new Object({id: v.id, answerId: v.answerId, answerValue: v.answerValue}) as IGapAnswerData)
        : []);
    this.answers = this.groupCheckboxGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$
      .next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.groupCheckboxGapFillingSummaryAnswers(this.question, this.summaryQuestionAnswers);
    this.gapResultData$.next(!this.question.showCorrectAnswers && !this.editorMode ? this.answers : {});
  }

  private groupCheckboxGapFillingSummaryAnswers(question: EventQuestion,
                                                        qContentAnswers: {[answerEquality: string]: number}) {
    const correctEquality = question.reduceGroupsCorrectAnswersCorrectEquality();
    const result = correctEquality.reduce((acc, eq) => {
      acc = Object.assign(acc, {[eq.id]: 0});
      return acc;
    }, {});
    return merge(result, qContentAnswers);
  }

}
