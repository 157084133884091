import {StdComponent} from '../../../../../../../core/std-component';
import {Component, ElementRef, Injector, OnInit} from '@angular/core';
import {CommonService} from '../../../../../../../core/common.service';
import {Quiz} from '../../../quiz-model/quiz';
import {setQuizElementProperties} from '../../../quiz-question-sheet/quiz-question-sheet-lib';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {Constants, ILanguageParams} from '../../../../../../../core/constants';
import {BehaviorSubject, combineLatest, filter} from 'rxjs';
import {IDocumentPathParams} from '../../../../../../../services/event-mode-api.service';

@Component({
  selector: 'app-abstract-quiz-question-result',
  template: ''
})
export abstract class AbstractQuizQuestionResultsComponent  extends StdComponent implements OnInit {
  readonly Constants = Constants;

  protected common: CommonService;

  // required input variables. initialize order has the meaning.
  editorMode: boolean;
  qKey: string;
  isPresenter: boolean;
  currentQKey$: BehaviorSubject<string>;
  documentPathParams: IDocumentPathParams;

  set quiz(value: Quiz) {
    if (value) {
      setQuizElementProperties(value, this.elementRef);
      this.quiz$.next(value);
    }
  }
  set question(value: EventQuestion) {
    this.question$.next(value);
  }
  set languageParams(value: ILanguageParams) {
    this.languageParams$.next(value);
  }

  set answersSummary(value: any) {
    this.summaryQuestionAnswers$.next(value);
  }

  // local variables
  protected quiz$ = new BehaviorSubject<Quiz>(null);
  protected question$ = new BehaviorSubject<EventQuestion>(null);
  protected languageParams$ = new BehaviorSubject<ILanguageParams>(null);
  protected summaryQuestionAnswers$ = new BehaviorSubject<any>(null);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector);
    this.common = injector.get(CommonService);
  }

  get question(): EventQuestion {
    return this.question$.getValue();
  }

  get languageParams(): ILanguageParams {
    return this.languageParams$.getValue();
  }

  get summaryQuestionAnswers() {
    return this.summaryQuestionAnswers$.getValue();
  }

  protected initQuestionAnswersDataSource() {}

  protected onReceiveQuestionAnswers() {}

  ngOnInit() {
    this.summaryQuestionAnswers$.pipe(filter(v => !!v), this.takeUntilAlive())
      .subscribe(() => {
        this.onReceiveQuestionAnswers();
      });
    combineLatest([this.question$, this.languageParams$]).pipe(this.takeUntilAlive())
      .subscribe(([question, languageParams]) => {
        if (question && languageParams) {
          this.initQuestionAnswersDataSource();
        }
      });
  }
}
