import { Chart, ChartConfiguration } from "chart.js";
import { ChartInfo, QuestionOpenNumberEstimationResultsComponent } from "./question-open-number-estimation-results.component";


export const TIMELINE_ORANGE_COLOR = '#FC7723';
const BORDER_RADIUS = 2;


export function createChart(comp: QuestionOpenNumberEstimationResultsComponent, ctx: CanvasRenderingContext2D) {

  let chart = null;

  if (ctx) {
    const data = getChartData(comp.aggregatedData);
    const options = getChartOptions(comp.aggregatedData);

    const chartConfig: ChartConfiguration<any> = {
      type: 'bar',
      data: data,
      options: options,
      plugins: [createGradientZeroDashLinePlugin(comp), createHoverPlugin(comp), afterLayoutPlugin(comp)]
    }

    chart = new Chart(ctx, chartConfig);
  }

  return chart;
}

function getChartData(aggregatedData: ChartInfo[]) {
  return {
    labels: aggregatedData.map((_, index) => `Group ${index + 1}`),
    datasets: [{
      label: "",
      data:  aggregatedData.map(item => item.totalAnswer),
      borderRadius: BORDER_RADIUS
    }]
  };
}


function getChartOptions(aggregatedData: ChartInfo[]) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: -10
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true, // Removes both labels and the space
        grid: {
          display: true,
          color: "#DEE2E6",
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
          dash: [1, 1],
        }
      },
      x: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,  // Disable grid lines on x-axis
          drawTicks: false, // Remove ticks from x-axis
          drawBorder: false, // Remove the border from x-axis
          offset: false,     // Prevent space from appearing
        },
        boder: {
          display: false,
        },
        ticks: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,  // Disable default tooltip
        position: 'nearest',
        // TODO - Do not delete the line below, it will be use if future task, when
        // we know how to get user data from summary
        // To display tooltip, change opacity to 1 inside method 'externalTooltip' ->  tooltipEl.style.opacity = '1';
        external: (context) => externalTooltip(context, aggregatedData)
      },
      zeroDashLine: {
        xColor: 'blue'
      },
    },
  };
}


export const afterLayoutPlugin = (comp: QuestionOpenNumberEstimationResultsComponent) => {
  return {
    id: 'afterLayoutPlugin', // Plugin ID
    afterLayout(chart) {

      const { ctx, chartArea } = chart;

      if(!comp.isChartCreated){
        chart.data.datasets[0].backgroundColor = chart.data.datasets[0].data.map(() =>
            createGradientColor(chart.ctx, chartArea)
        );
      }
    }
  }
};


// Define the gradient dash line plugin
export const createGradientZeroDashLinePlugin = (comp: QuestionOpenNumberEstimationResultsComponent) => {
  return {
    id: 'zeroDashLine',
    beforeDatasetsDraw(chart, args, plugins){
      const { ctx, scales, chartArea } = chart;
      const xAxis = scales['x'];
      const yAxis = scales['y'];
      const yZeroPosition = yAxis.getPixelForValue(0); // Get the Y position for zero

      const dashGap = 4;
      const offset = dashGap / 2;

      const height = 4;
      const barWidthValue = (xAxis.getPixelForValue(1) - xAxis.getPixelForValue(0));

      if (yZeroPosition) {
        comp.gradient = createGradientColor(ctx, chartArea);
        // Draw dash line
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([barWidthValue - dashGap, dashGap]); // Dash and gap
        ctx.lineWidth = height;
        ctx.strokeStyle = comp.gradient;
        ctx.moveTo(xAxis.left + offset , yZeroPosition);
        ctx.lineTo(xAxis.right, yZeroPosition);
        ctx.stroke();
        ctx.restore();

        chart.data.datasets.at(0).categoryPercentage = 1;
        chart.data.datasets.at(0).barPercentage = 0.99;
      }
    },
  }
}

export const createHoverPlugin = (comp: QuestionOpenNumberEstimationResultsComponent) =>{

  return {
    id: 'hoverDashedLine',
    afterDraw(chart) {

      const { ctx, tooltip, chartArea: {top, bottom, left, right, width, height}, scales: {x, y} } = chart;

      const barWidth = (x.getPixelForValue(1) - x.getPixelForValue(0));
      const activeElements = chart.tooltip?.getActiveElements();
      const dataset = chart.data.datasets[0];  // Assuming you have one dataset

      // Only draw if there are active (hovered) elements
      if (activeElements && activeElements.length) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;

        const activeElement = activeElements[0].element;

        let dataSet =  chart.data.datasets[0];
        if(dataSet){

          const index = activeElement.$context.dataIndex;

          let xValue = x.getPixelForValue(index);
          let yValue = y.getPixelForValue(index);


          // Draw the dashed vertical line in the middle of the bar
          ctx.save();
          ctx.setLineDash([5, 5]);  // Set the dash pattern [dash, gap]
          ctx.strokeStyle = TIMELINE_ORANGE_COLOR;  // Set line color (orange here)
          ctx.lineWidth = 1;

          const xPos = xValue;

          ctx.beginPath();
          ctx.moveTo(xPos, activeElement.y);
          ctx.lineTo(xPos, chartArea.top);
          ctx.stroke();
          ctx.closePath();
          ctx.restore();
        }
      }
      if(comp.correctValue){
        dataset.backgroundColor[comp.correctValue] = '#16A34A';
      }
    },
    beforeTooltipDraw(chart) {
      const dataset = chart.data.datasets[0];  // Assuming you have one dataset
      const originalBackgroundColor = dataset.originalBackgroundColor || dataset.backgroundColor;

      const activeElements = chart.tooltip?.getActiveElements();

      // If there are active elements, we're hovering over a bar
      if (activeElements && activeElements.length) {
        const activeIndex = activeElements[0].index;  // Get the index of the hovered bar

        dataset.backgroundColor = dataset.data.map((value, index) => {
          return index === activeIndex ?  comp.gradient : '#ADB5BD';  // Change to orange for hover, gray for others
        });
      } else {
        dataset.backgroundColor = originalBackgroundColor;
      }

      if(comp.correctValue){
        dataset.backgroundColor[comp.correctValue] = '#16A34A';
      }

      // Save the original background color for future reference
      if (!dataset.originalBackgroundColor) {
        dataset.originalBackgroundColor = originalBackgroundColor;
      }

      chart.update();
    }
  }
}


export function externalTooltip(context, aggregatedData?: any[]) {
  const { chart, tooltip } = context;
  let tooltipEl = getOrCreateTooltip(chart, tooltip, aggregatedData);

  // Hide the tooltip if there's no active element
  if (!tooltip || tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
  }
  // Show the tooltip
  tooltipEl.style.opacity = '0';
}


function getOrCreateTooltip(chart, tooltip, aggregatedData: any[]){
  let tooltipEl = document.getElementById('chartjs-tooltip');

  // Create tooltip element if it doesn’t already exist
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.className ='timeline-chart-tooltip';

    let tooltipContent = document.createElement('div');
    tooltipContent.id = 'tooltipContent';
    tooltipContent.className = 'content';

    tooltipContent.innerHTML = tooltip.dataPoints[0].formattedValue;
    tooltipEl.appendChild(tooltipContent);

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  const activeElements = chart.tooltip?.getActiveElements();

  if (activeElements && activeElements.length) {
    // Update tooltip content
    const tooltipContent = tooltipEl.querySelector('#tooltipContent');
    if(tooltipContent){
      const maximumNumbersDisplayedInsideOneBar = 5;
      const dataIndex = tooltip.dataPoints[0].dataIndex;
      const details = aggregatedData[dataIndex].details;
      const tooltipDetails = (details.length > maximumNumbersDisplayedInsideOneBar)
                                ? `${details[0]} ... ${details[details.length - 1]}`
                                : details;

      tooltipContent.innerHTML = '[' + tooltipDetails + ']';
    }

  // Set tooltip content and position
    const bar = tooltip.dataPoints[0].element;
    const position = bar.getCenterPoint();
    tooltipEl.style.left = `${position.x + 100}px`;
    tooltipEl.style.top = `${position.y - 40}px`;
    tooltipEl.style.opacity = '1';
  }
  return tooltipEl;
}

function createGradientColor(ctx: CanvasRenderingContext2D, chartArea) {
  const colorStart = TIMELINE_ORANGE_COLOR;
  const colorEnd = '#FFB772';
  const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);

  gradient.addColorStop(0, colorStart); // Bottom color
  gradient.addColorStop(1, colorEnd);   // Top color
  return gradient;
}




function createEmptyStringArray(n: number): string[] {
  return new Array(n).fill('');
}



function getRandomIntArray(n: number, min: number = 1, max: number = 100): number[] {
  return Array.from({ length: n }, () => Math.floor(Math.random() * (max - min + 1)) + min);
}
