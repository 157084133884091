import {Component, Injector, Input} from '@angular/core';
import {StdComponent} from '../../../../../../../core/std-component';
import {MatDialogRef} from '@angular/material/dialog';
import {IDocumentPathParams} from '../../../../../../../services/event-mode-api.service';
import {Quiz} from '../../../quiz-model/quiz';
import {getExtension, isImage} from '../../shared/lib/question-upload-files-utils';
import {StorageDataService} from '../../../../../../../services/storage-data.service';
import {CommonService} from '../../../../../../../core/common.service';
import {catchError} from 'rxjs';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

@Component({
  selector: 'app-question-upload-files-answers-preview',
  templateUrl: './question-upload-files-answers-preview.component.html',
  styleUrl: './question-upload-files-answers-preview.component.scss'
})
export class QuestionUploadFilesAnswersPreviewComponent extends StdComponent {

  @Input() documentPathParams: IDocumentPathParams;
  @Input() set qContent(value: Quiz) {
    this._qContent = value;
  }
  @Input() userId: string;
  @Input() questionId: string;
  @Input() set answers(value: any[]) {
    this._qAnswers = value;
    this.getResultSet();
  }
  readonly getExtension = getExtension;
  dataSource: any[] = [];

  _qContent: Quiz;
  _qAnswers: any;

  constructor(protected injector: Injector,
              protected storageDataService: StorageDataService,
              protected common: CommonService,
              private functions: AngularFireFunctions,
              public dialogRef: MatDialogRef<QuestionUploadFilesAnswersPreviewComponent>) {
    super(injector);
  }

  private getResultSet() {
    if (this._qAnswers && this._qAnswers.f) {
      for (const answer of this._qAnswers.f) {
        const fileId = answer.id;
        const isFileImage = isImage(answer.name);
        this.dataSource.push({
          userId: this.userId,
          id: fileId,
          name: answer.name,
          isImage: isFileImage,
          imageUrl: null
        });
        if (isFileImage) {
          const answersUID = this._qAnswers.uid ?? this.userId;
          this.getFileUrl(answersUID, fileId, (url) => {
            const idx = this.dataSource.findIndex(entry => entry.userId === this.userId && entry.id === fileId);
            this.dataSource[idx].imageUrl = url;
          });
        }
      }
    }
  }

  downloadFile(fileId: string) {
    const idx = this.dataSource.findIndex(entry => entry.userId === this.userId && entry.id === fileId);
    if (idx > -1 && this.dataSource[idx].imageUrl) {
      window.open(this.dataSource[idx].imageUrl, '_blank');
    } else {
      this.getFileUrl(this.userId, fileId, (url) => {
        window.open(url, '_blank');
      });
    }
  }

  private getFileUrl(userId: string, fileId: string, success) {
    const eventId = this.documentPathParams.eventId;
    const contentId = this.documentPathParams.contentId;
    const containerId = this.documentPathParams.containerId;

    const path = `${eventId}/${contentId}/${containerId}/${this.questionId}/users_answers/${userId}/${fileId}`;
    this.storageDataService.getStorageDataURL(path)
      .then(url => {
        if (url) {
          success(url);
        }
      }).catch((err) => {
      this.common.log.error(err);
    });
  }

  downloadAllFiles(): void {
    if (this.dataSource.length === 0) {
      return;
    }
    this.common.showProgress.next(true);
    const eventId = this.documentPathParams.eventId;
    const contentId = this.documentPathParams.contentId;
    const containerId = this.documentPathParams.containerId;
    const answersUID = this._qAnswers.uid ?? this.userId;
    const path = `${eventId}/${contentId}/${containerId}/${this.questionId}/users_answers/${answersUID}`;
    const callable = this.functions.httpsCallable('downloadAll');
    callable({
      path: this.storageDataService.getClientStorageDataLocation(path),
      fileMap: this.dataSource.map(this.convertToFileMetadata)
    }).pipe(
        catchError((error) => {
          this.common.showPopupError(this.common.i18n('questionnaire.upload.file.result.files.download.all.error'));
          return null;
        })
      ).subscribe((value) => {
        this.common.showProgress.next(false);
        if (value && value.downloadUrl) {
          this.downloadFileFromUrl(value.downloadUrl);
        } else {
          this.common.showPopupError(this.common.i18n('questionnaire.upload.file.result.files.download.all.error'));
        }
      });
  }

  private downloadFileFromUrl(url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // This tells the browser to download rather than navigate
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convertToFileMetadata(fileObject: any): any {
    return {
      id: fileObject.id,
      name: fileObject.name
    };
  }

}
