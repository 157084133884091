import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import * as ui from '../../../../../../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../reducers';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';

@Component({
  selector: 'app-question-text-participant',
  templateUrl: './question-text-participant.component.html',
  styleUrls: ['./question-text-participant.component.scss']
})
export class QuestionTextParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  answerIsCorrect = false;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private store: Store<fromRoot.State>) {
    super(injector, elementRef);
  }


  protected initQuestionAnswersDataSource() {
    this.answerIsCorrect = this.question.showCorrectAnswers &&
      this.question.items
        .some(it => it.getAnswerByLanguage(this.languageParams$.getValue()).toLowerCase() === this.answers[0]?.toLowerCase());
  }

  getTextAnswerLength() {
    return this.answers && this.answers[0] ?
      this.answers[0].length : 0;
  }

  editAnswer(edit) {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(edit));
  }

  switchItem(event) {
    if (!this.acceptAnswers) {
      return;
    }
    let sendItem: string;
    sendItem = this.common.utils.sanitized(event.currentTarget.value ? event.currentTarget.value : '');
    sendItem = sendItem.length === 0 ? null : sendItem;
    const answers: string[] = [];
    answers.push(sendItem);
    this.answers = answers;
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  onDestroy() {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(false));
  }
}
