// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}

.mat-mdc-dialog-content {
  overflow: hidden;
}

.container {
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.container img {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog-components/edit-dialog/edit-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF","sourcesContent":["mat-form-field {\n  width: 100%;\n}\n\n.mat-mdc-dialog-content {\n  overflow: hidden;\n}\n\n.container {\n  height: 100%;\n  width: 100%;\n  margin-top: 10px;\n}\n\n.container img {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
