import {Injectable} from '@angular/core';
import {clone, isEmpty, merge} from 'lodash';
import {EventsDataService} from '../services/events-data.service';
import {UtilsService} from './utils.service';
import {CommonService} from './common.service';
import {DIRECTION, I3DWordCloudOptions, PROCESS_STATUS, SHAPE_3D_WORD_CLOUD} from './constants';
import {BehaviorSubject} from 'rxjs';
import * as WordCloud from 'wordcloud';
import {ListEntry} from 'wordcloud';
import {ToasterService} from './toaster.service';
import {WordCloudTemplate} from '../model/content/WordCloudTemplate';

declare const TagCanvas: any;

@Injectable({
  providedIn: 'root'
})
export class WordCloudService {
  private readonly MIN_NEEDED_WORDS = 650;
  private readonly MIN_FREQ = 4;
  private readonly MAX_FREQ = 8;
  private DEFAULT_TEMPLATE_ID_LIST = ['02', '01', '03', '05', '06'];
  private _defaultTemplatesList: {id, url}[] = [];
  private _tagWordCloudDefaultOptions: I3DWordCloudOptions = {
    noMouse: false,
    noSelect: false,
    textColour: '#000000',
    noTagsMessage: false,
    initial: [0.03, 0],
    shape: SHAPE_3D_WORD_CLOUD.VERTICAL_CYLINDER,
    direction: DIRECTION.MOVE_DOWN,
    textHeight: 42,
    lock: 'xy',
    outlineColour: '#75756e',
    outlineRadius: 5,
    outlineThickness: 1
  };
  public readonly SHAPE_3D_WORD_CLOUD_DEFAULT_INITIAL = {
    [SHAPE_3D_WORD_CLOUD.VERTICAL_CYLINDER]: [0, -0.03],
    [SHAPE_3D_WORD_CLOUD.HORIZONTAL_CYLINDER]: [0.03, 0],
    [SHAPE_3D_WORD_CLOUD.VERTICAL_RING]: [0, -0.03],
    [SHAPE_3D_WORD_CLOUD.HORIZONTAL_RING]: [0.03, 0],
    [SHAPE_3D_WORD_CLOUD.SPHERE]: [0.03, 0.02],
  };

  public readonly SHAPE_3D_WORD_CLOUD_DIRECTION = {
    [DIRECTION.MOVE_UP]: [0, 0.03],
    [DIRECTION.MOVE_DOWN]: [0, -0.03],
    [DIRECTION.MOVE_LEFT]: [-0.03, 0],
    [DIRECTION.MOVE_RIGHT]: [0.03, 0],
    [DIRECTION.MOVE_LEFT_TO_RIGHT]: [0.03, 0.02],
    getByValue: function(value: number[]) {
      for (const key of Object.keys(this)) {
        const v = this[key];
        if (v[0] === value[0] && v[1] === value[1]) {
          return key;
        }
      }
      return null;
    }
  };

  public loadedSubject = new BehaviorSubject<PROCESS_STATUS>(PROCESS_STATUS.STOP);

  constructor(private eventDataService: EventsDataService
    , private utils: UtilsService
    , private common: CommonService
    , private toasterService: ToasterService) {
    this.defineObjectProperties();
  }

  private defineObjectProperties() {
    Object.defineProperty(this.SHAPE_3D_WORD_CLOUD_DIRECTION, 'getByValue', {enumerable: false});
  }

  get defaultTemplatesList(): {id, url}[] {
    const deepFreeze = (obj) => {
      const propNames = Object.getOwnPropertyNames(obj);
      propNames.forEach(function(name) {
        const prop = obj[name];
        if (typeof prop === 'object' && prop !== null) {
          deepFreeze(prop);
        }
      });
      return Object.freeze(obj);
    };
    return deepFreeze(this._defaultTemplatesList);
  }

  private loadTemplate(id) {
    return new Promise<{id, url}>((resolve, reject) => {
      const vm = this;
      this.eventDataService.getWordCloudTemplateURL('templates/' + id + '.png')
        .then(function (url) {
          resolve({id: id, url: url});
        }).catch(function (err) {
        resolve(null);
        vm.common.log.error('Can not load template ' + id + '.png', err);
      });
    });
  }

  public loadDefaultTemplates() {
    return new Promise<boolean>((resolve, reject) => {
      const vm = this;
      this.loadedSubject.next(PROCESS_STATUS.START);
      const allPromise: Promise<{ id, url }>[] = [];
      this._defaultTemplatesList = [];
      for (const id of this.DEFAULT_TEMPLATE_ID_LIST) {
        allPromise.push(this.loadTemplate(id));
      }
      if (isEmpty(allPromise)) {
        return resolve(true);
      }
      Promise.all(allPromise).then(function (list) {
        (list || []).forEach(template => {
          if (template) {
            vm._defaultTemplatesList.push(template);
          }
        });
        vm.loadedSubject.next(PROCESS_STATUS.COMPLETE);
        return resolve(true);
      }).catch(function (err) {
        vm.common.log.error(err);
        vm.loadedSubject.next(PROCESS_STATUS.ERROR);
        return resolve(false);
      });
    });
  }

  private removeNotAvailableOptionsForSimpleUser(options: WordCloud.Options): WordCloud.Options {
    // if not did it we have division by zero in source code.
    options.rotateRatio = options.rotateRatio === 1 ? 0.99 : options.rotateRatio;
    // options not available for simple user
    options.clearCanvas = false;
    options.drawMask = false;
    options.shuffle = false;
    delete options.maskColor;
    delete options.maskGapWidth;
    delete options.classes;
    delete options.wait;
    delete options.abortThreshold;
    delete options.abort;
    delete options.hover;
    delete options.click;
    return options;
  }

  private calcWordFreq(text): ListEntry[] {
    if (!text) {
      return [];
    }
    // Normalize
    // s = s.toLowerCase();
    // Strip quotes and brackets
    let s = text;
    s = s.replace(/["“”(\[{}\])]|\B['‘]([^'’]+)['’]/g, '$1');
    // Strip dashes and ellipses
    s = s.replace(/[‒–—―…]|--|\.\.\./g, ' ');
    // Strip punctuation marks
    s = s.replace(/[!?;:.,]\B/g, '');
    const oWordFreq = (s.match(/\S+/g) || []).reduce(function (oFreq, sWord) {
      if (oFreq[sWord]) {
        ++oFreq[sWord];
      } else {
        oFreq[sWord] = 1;
      }
      return oFreq;
    }, {});
    const list: ListEntry[] = [];
    Object.keys(oWordFreq).forEach(word => list.push([word, oWordFreq[word]]));
    return list;
  }

    private buildTemplateOptions(userOptions: string, list: ListEntry[] | any[]): WordCloud.Options {
    if (!userOptions) {
      return null;
    }
    const evalOptions = eval;
    let options: WordCloud.Options;
    try {
      options = evalOptions('(' + userOptions + ')');
      options.list = list ? list : [];
      return options;
    } catch (e) {
      this.common.log.error(e);
      this.toasterService.pop('error', e);
      return null;
    }
  }

  public get defaultDisplayTemplateOptions(): string {
    return '{\n' +
      'gridSize: Math.round(2 * [width] / 424),\n' +
      'rotateRatio: 0.8,shape: \'square\',\n' +
      'weightFactor: function (size) {\n' +
      'return Math.pow(size, 1.6) * [width] /424;\n' +
      '},\n' +
      'color: function (word, weight) {\n' +
      'return (weight >= 8 && weight <= 10) ? \'rgb(0, 102, 204)\' : \'#8794c3\';\n' +
      '},\n' +
      'fontWeight: function (word, weight) {\n' +
      'return (weight > 11) ? \'bold\' : \'normal\';\n' +
      '}\n' +
      '}\n';
  }

  private fitImage(canvas, imageObj, context) {
    const imageAspectRatio = imageObj.width / imageObj.height;
    const canvasAspectRatio = canvas.width / canvas.height;
    let renderableHeight, renderableWidth, xStart, yStart;

    if (imageAspectRatio < canvasAspectRatio) {
      // If image's aspect ratio is less than canvas's we fit on height
      // and place the image centrally along width
      renderableHeight = canvas.height;
      renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
      xStart = (canvas.width - renderableWidth) / 2;
      yStart = 0;
    } else if (imageAspectRatio > canvasAspectRatio) {
      // If image's aspect ratio is greater than canvas's we fit on width
      // and place the image centrally along height
      renderableWidth = canvas.width;
      renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
      xStart = 0;
      yStart = (canvas.height - renderableHeight) / 2;
    } else {
      // Happy path - keep aspect ratio
      renderableHeight = canvas.height;
      renderableWidth = canvas.width;
      xStart = 0;
      yStart = 0;
    }
    if (canvas.height === 0 || canvas.width === 0) {
      return false;
    }
    context.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight);
    return true;
  }

  /**
   * Draw word cloud.
   * @param canvas document.getElementById('canvas_' + templateId)
   * @param template object of type WordCloudTemplate
   * @param image document.getElementById('image_' + templateId)
   * @param wordCloudOptions string with options.
   * @param list object with words
   */
  public drawWordCloud(canvas: any, template?: WordCloudTemplate, image?, wordCloudOptions?: string, list?: any) {
    return new Promise<any>((resolve, reject) => {
      if (!canvas || (!template && !wordCloudOptions)) {
        return resolve(true);
      }
      const vm = this;
      let options: WordCloud.Options;
      const words = wordCloudOptions && isEmpty(list) ? this.defaultText : (isEmpty(list) ? [] : list);
      const prepList = this.prepareWordList(words,
        template ? template.multiplyWordsToMatchShape : true,
        template ? (list ? template.singleWordMode : true) : true);
      options = this.buildTemplateOptions(
        this.parseOptionsMacros(wordCloudOptions ? wordCloudOptions : template.options, canvas.clientWidth), prepList);
      if (!options) {
        return resolve(true);
      }
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
      if (!image && (!template || (template && !template.url))) {
        WordCloud(canvas, options);
        return resolve(true);
      } else {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = image ? image['src'] : template.url;
        img.onload = () => {
          let ctx: any = canvas.getContext('2d');
          if (!this.fitImage(canvas, img, ctx)) {
            return resolve(true);
          }
          let imageData = ctx.getImageData(
            0, 0, img.width, img.height);
          let newImageData = ctx.createImageData(imageData);
          for (let i = 0; i < imageData.data.length; i += 4) {
            const tone = imageData.data[i] +
              imageData.data[i + 1] +
              imageData.data[i + 2];
            const alpha = imageData.data[i + 3];
            if (alpha < 128 || tone > 128 * 3) {
              // Area not to draw
              newImageData.data[i] =
                newImageData.data[i + 1] =
                  newImageData.data[i + 2] = 255;
              newImageData.data[i + 3] = 0;
            } else {
              // Area to draw
              newImageData.data[i] =
                newImageData.data[i + 1] =
                  newImageData.data[i + 2] = 0;
              newImageData.data[i + 3] = 255;
            }
          }
          ctx.putImageData(newImageData, 0, 0);
          let bctx = document.createElement('canvas').getContext('2d');
          bctx.fillStyle = options.backgroundColor || '#fff';
          bctx.fillRect(0, 0, 1, 1);
          let bgPixel = bctx.getImageData(0, 0, 1, 1).data;
          let maskCanvasScaled =
            document.createElement('canvas');
          maskCanvasScaled.width = canvas.width;
          maskCanvasScaled.height = canvas.height;
          ctx = maskCanvasScaled.getContext('2d');
          ctx.drawImage(canvas,
            0, 0, canvas.width, canvas.height,
            0, 0, maskCanvasScaled.width, maskCanvasScaled.height);
          imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          newImageData = ctx.createImageData(imageData);
          for (let i = 0; i < imageData.data.length; i += 4) {
            if (imageData.data[i + 3] > 128) {
              newImageData.data[i] = bgPixel[0];
              newImageData.data[i + 1] = bgPixel[1];
              newImageData.data[i + 2] = bgPixel[2];
              newImageData.data[i + 3] = bgPixel[3];
            } else {
              // This color must not be the same w/ the bgPixel.
              newImageData.data[i] = bgPixel[0];
              newImageData.data[i + 1] = bgPixel[1];
              newImageData.data[i + 2] = bgPixel[2];
              newImageData.data[i + 3] = bgPixel[3] ? (bgPixel[3] - 1) : 0;
            }
          }
          ctx.putImageData(newImageData, 0, 0);
          ctx = canvas.getContext('2d');
          ctx.drawImage(maskCanvasScaled, 0, 0);
          maskCanvasScaled = ctx = imageData = newImageData = bctx = bgPixel = undefined;
          options = vm.removeNotAvailableOptionsForSimpleUser(options);
          WordCloud(canvas, options);
          if (!options.backgroundColor) {
            this.transparentBackground(canvas, options);
          }
          return resolve(true);
        };
      }
    });
  }

  private transparentBackground(canvas, options: WordCloud.Options) {
    const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
    const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));
    const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);
    const hexToRGBA = (hex) => {
      if (!isValidHex(hex)) {return null; }
      const chunkSize = Math.floor((hex.length - 1) / 3);
      const hexArr = getChunksFromString(hex.slice(1), chunkSize);
      const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
      return [r, g, b];
    };
    const bkColor = hexToRGBA(options.backgroundColor ? options.backgroundColor : '#fff');
    if (!bkColor) {
      return;
    }
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for (let i = 0; i < imageData.data.length; i = i + 4) {
      const r  = imageData.data[i];
      const g = imageData.data[i + 1];
      const b  = imageData.data[i + 2];
      if (r === bkColor[0] && g === bkColor[1] && b === bkColor[2]) {
        imageData.data[i + 3] = 0; // alpha
      }
    }
    ctx.putImageData(imageData, 0, 0);
  }

  private parseOptionsMacros(options: string, canvasWidth: number) {
    return options.replace(new RegExp('\\[width]', 'g'), '' + canvasWidth);
  }

  private prepareWordList(list: any, multiplyWords, singleWordMode): ListEntry[] | any[] {
    if (isEmpty(list)) {return []; }
    const text: string | ListEntry[] = (typeof list === 'string') ? list :
      this.utils.objectValues(list).reduce(function (value, str) {
        if (singleWordMode) {
          value = value + ' ' + str;
        } else {
          value.push([str, 1]);
        }
        return value;
      }, singleWordMode ? '' : []);

    let baseText = singleWordMode ? this.calcWordFreq(text) : text as ListEntry[];
    baseText = this.multiplyWords(baseText, multiplyWords);
    return baseText;
  }

  private multiplyWords(baseWordFreqList: ListEntry[], multiplyWords) {
    const createPacket = (baseWordsSet: ListEntry[], minFreq) => {
      const result: ListEntry[] = [];
      baseWordsSet.forEach(obj => result.push([obj[0], minFreq - 1]));
      return result;
    };
    if (isEmpty(baseWordFreqList)) {return []; }
    const baseMinFreq = baseWordFreqList.reduce(function (value, obj) {
      return value < obj[1] ? value : obj[1];
    }, 1);
    const baseMaxFreq = baseWordFreqList.reduce(function (value, obj) {
      return value > obj[1] ? value : obj[1];
    }, 1);

    const intervalCount = this.MAX_FREQ - this.MIN_FREQ;
    const intervals = [baseMinFreq];
    let intervalValue = (baseMaxFreq - baseMinFreq) === 0 ? 1 : (baseMaxFreq - baseMinFreq) / intervalCount;
    intervalValue = intervalValue === 0 ? 1 : intervalValue;
    let val = baseMinFreq;
    for (let i = 2; i <= intervalCount; i++) {
      val = val + intervalValue;
      intervals.push(val);
    }
    intervals.push(baseMaxFreq);
    baseWordFreqList.forEach(obj => {
      let index = -1;
      for (let j = intervals.length - 1; j >= 0; j--) {
        if (((j !== 0 ? intervals[j - 1] : 0) < obj[1] && obj[1] <= intervals[j]) ||
             obj[1] === intervals[j]) {
          index = j;
          obj[1] = index + intervalCount;
          break;
        }
      }
      if (index < 0) {
        obj[1] = index + intervalCount;
      }
    });
    const packetCount = Math.floor(this.MIN_NEEDED_WORDS / baseWordFreqList.length);
    const multiplyResult: ListEntry[] = [];
    baseWordFreqList.forEach(obj => multiplyResult.push(clone(obj)));
    multiplyResult.sort(function (a, b) {
      if (a[1] === b[1]) {
        return a[0].length >= b[0].length ? -1 : 1;
      } else {
        return a[1] > b[1] ? -1 : 1;
      }
    });
    if (multiplyWords) {
      for (let i = 0; i < packetCount; i++) {
        createPacket(baseWordFreqList, this.MIN_FREQ).forEach(obj => multiplyResult.push(obj));
      }
    }
    return multiplyResult;
  }

  public drawTagWordCloud(canvasId, options: I3DWordCloudOptions, preview: boolean = false) {
    const opt = isEmpty(options) ? this._tagWordCloudDefaultOptions : merge(clone(this._tagWordCloudDefaultOptions), options);
    if (opt.shape === SHAPE_3D_WORD_CLOUD.HORIZONTAL_RING) {
        opt['offsetY'] = preview ? -60 : -150;
    }
    if (preview) {
      opt.textHeight = 14;
    }
    if (isEmpty(opt.initial)) {
      opt.initial = this.SHAPE_3D_WORD_CLOUD_DEFAULT_INITIAL[opt.shape];
    }
    TagCanvas.Start(canvasId, null, opt);
  }

  public reloadTagWordCloud(canvasId) {
    TagCanvas.Reload(canvasId);
  }

  public updateTagWordCloud(canvasId) {
    TagCanvas.Update(canvasId);
  }

  public pauseTagWordCloud(canvasId) {
    TagCanvas.Pause(canvasId);
  }

  public resumeTagWordCloud(canvasId) {
    TagCanvas.Resume(canvasId);
  }

  public tagToFrontTagWordCloud(canvasId, tagId) {
    TagCanvas.TagToFront(canvasId, {id: tagId});
  }

  public setSpeedTagWordCloud(canvasId, speed: number[]) {
    TagCanvas.SetSpeed(canvasId, speed);
  }

  public prepareTagWordList(list: string[] = [], singleWordMode, preview: boolean = false): string[] {
    const lst = preview ? this.defaultText : list;
    const wl: any[] = this.prepareWordList(lst, false, singleWordMode);
    return wl.map(value => value[0]);
  }

  private get defaultText() {
    return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc nec justo dictum, ornare lorem id, posuere elit.' +
      ' Sed malesuada urna id risus convallis tincidunt. Praesent pharetra massa sed finibus cursus. Ut iaculis semper purus,' +
      ' porta porttitor sem dictum nec. Nam interdum massa ac nisl tincidunt, volutpat ornare erat hendrerit. Pellentesque ' +
      'dolor nunc, vehicula vitae iaculis sit amet, congue nec nisl. Etiam quis massa ac purus ultricies fringilla et id lectus.\n' +
      'Phasellus magna mi, semper eget tincidunt eu, interdum quis lectus. Ut vehicula dictum erat sed varius. Quisque lobortis ' +
      'libero auctor odio malesuada, sit amet facilisis ligula interdum. Pellentesque egestas, ante ut sollicitudin vulputate, ' +
      'justo nibh volutpat neque, in mollis justo dui nec tellus. Quisque eros tortor, sodales eu magna ut, feugiat aliquam magna. ' +
      'Aenean mi tellus, feugiat sed porta in, bibendum ac tellus. In dictum purus dolor, vitae tristique nisi rutrum id.\n' +
      'Curabitur venenatis accumsan sollicitudin. In bibendum fringilla neque, vel vehicula ipsum dapibus et. Duis et vestibulum ' +
      'risus, quis dictum ex. Curabitur pharetra risus nibh, id faucibus nunc fringilla vel. Suspendisse efficitur nibh a fringilla ' +
      'efficitur. Mauris volutpat at quam sed interdum. Mauris varius bibendum egestas. Sed semper congue dolor vitae commodo. ' +
      'Phasellus laoreet luctus felis, sed placerat massa tincidunt sed. Cras malesuada id odio vitae auctor. Fusce dapibus ' +
      'consectetur sagittis. Aenean eget ligula laoreet, vehicula eros eu, ullamcorper ligula. Maecenas eu elementum odio. ' +
      'Suspendisse pellentesque mollis tellus dictum dictum. Cras vulputate malesuada varius.\n' +
      'Donec finibus lobortis vehicula. Vestibulum vitae dapibus lacus, ut vehicula massa. Vestibulum semper neque nec pulvinar ' +
      'tempus. Donec feugiat euismod nisi ut maximus. Ut lobortis, dolor sed aliquet facilisis, massa erat finibus justo, sit ' +
      'amet scelerisque nisi velit non odio. Nunc faucibus sem massa, ac malesuada mauris efficitur eget. Nunc iaculis nunc non ' +
      'arcu tristique, ac viverra massa cursus. Nullam commodo nisi massa, sit amet vestibulum nisi efficitur sit amet. Donec et ' +
      'consectetur augue, in vulputate ligula.\n' +
      'Vestibulum ultrices lacus at turpis suscipit, ac elementum turpis gravida. Sed viverra libero eu dolor vehicula pharetra. ' +
      'Quisque id elementum eros, vel vehicula orci. Nam imperdiet facilisis sem, at laoreet nunc. Integer at lorem nulla. Cras ' +
      'urna felis, blandit at aliquet ultricies, convallis ut nunc. Ut ac mauris mauris. Phasellus dui eros, molestie vel elementum ' +
      'id, venenatis non arcu. Mauris luctus nisi id urna pulvinar mollis. Nulla facilisi. Donec ex purus, aliquam a finibus at, ' +
      'finibus ac mauris. Sed est justo, malesuada ut lacus at, luctus ullamcorper sem. Donec dapibus euismod magna vitae feugiat.\n' +
      'Pellentesque at risus vitae massa ornare tincidunt sit amet sit amet leo. Praesent vel aliquet purus. Integer ut purus ' +
      'faucibus, ultricies est a, maximus mi. Phasellus pulvinar non leo in tempor. Vestibulum ut pretium neque. Duis vitae est ' +
      'ultricies, ultricies arcu ac, congue arcu. Fusce gravida mauris sed dolor elementum, in egestas est posuere. Donec mollis ' +
      'felis in libero rutrum, at porttitor augue lacinia. Nunc luctus magna ut eleifend placerat.\n' +
      'Pellentesque fermentum velit et convallis ullamcorper. Nunc tincidunt pellentesque aliquam. Integer eget lectus vitae ' +
      'est consectetur maximus sit amet et felis. Morbi in vehicula magna, a varius libero. Vestibulum scelerisque sem vitae ' +
      'erat consequat, et rutrum purus sodales. Nullam congue accumsan sollicitudin. Mauris vel augue eget magna convallis ' +
      'ullamcorper ut ac odio. Proin blandit semper egestas. In tempus quam sed nulla elementum fringilla. Sed ac rhoncus tortor. ' +
      'Pellentesque ut feugiat augue. In risus massa, gravida quis turpis eget, aliquam dapibus erat.\n' +
      'Ut felis diam, lacinia vel nibh ut, faucibus tempor risus. Suspendisse condimentum dolor id leo gravida, a faucibus est ' +
      'fringilla. Quisque fringilla dui pulvinar sem dignissim pellentesque. Aliquam dictum sapien sed dui imperdiet, ' +
      'in posuere libero tincidunt. Nullam est erat, fermentum vel augue in, ullamcorper viverra nulla. Curabitur nec ornare ' +
      'velit. Quisque commodo sit amet dolor quis eleifend. Nulla vel neque mauris. Nam sed imperdiet mi. Pellentesque ' +
      'faucibus sollicitudin ipsum nec ultricies. Fusce vel iaculis purus.\n' +
      'Phasellus at justo accumsan, rutrum elit at, porta est. Cras consectetur nisi in massa fringilla posuere. ' +
      'Donec eu neque dolor. Fusce vitae finibus mauris. Nam rutrum tempor lectus, a laoreet ligula congue id. Cras sit ' +
      'amet ultrices ligula. Etiam sit amet lectus et neque ultricies rhoncus. Pellentesque habitant morbi tristique ' +
      'senectus et netus et malesuada fames ac turpis egestas. Vivamus elementum metus sed elementum vulputate. Etiam ' +
      'sollicitudin semper massa ac tincidunt.\n' +
      'Nulla dapibus sit amet sapien ut dictum. Mauris tempus placerat velit, tempor lacinia purus porttitor condimentum. ' +
      'Nam nibh eros, fringilla non porta sit amet, bibendum nec neque. Phasellus facilisis tellus lorem, ut dapibus sem ' +
      'aliquam non. Sed tincidunt nulla rutrum ante pretium sodales. Mauris placerat, ipsum maximus congue pellentesque, ' +
      'nisi ipsum scelerisque justo, ut venenatis orci augue et purus. Donec pellentesque aliquam eleifend. Suspendisse ' +
      'accumsan accumsan dolor eget vestibulum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ' +
      'ridiculus mus. Pellentesque vehicula tristique ex, nec congue purus porttitor id. Suspendisse aliquet dictum mi. ' +
      'Pellentesque scelerisque rutrum orci, non lacinia erat ultricies fringilla. Aenean ultrices dolor nec dolor elementum,' +
      ' eu porta velit vestibulum. Donec a mi ac tellus vulputate sagittis. Ut scelerisque gravida quam, non cursus lacus' +
      ' tincidunt in. Quisque ac ultricies elit.';
  }
}

