import {I3DWordCloudOptions} from '../../core/constants';
import {isEmpty} from 'lodash';

export class WordCloudTemplate {
  id: string = this.genId();
  url: string; // only for visualisation
  options: string;
  multiplyWordsToMatchShape = false;
  singleWordMode = false;
  use3DWordCloud = true;
  options3DWordCloud: I3DWordCloudOptions;

  public static genId(): string {
    return 'WCLOUD' + (new Date()).getTime().toString();
  }

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('url')) {
      this.url = obj.url;
    }
    if (obj.hasOwnProperty('options')) {
      this.options = obj.options;
    }
    if (obj.hasOwnProperty('multiplyWordsToMatchShape')) {
      this.multiplyWordsToMatchShape = obj.multiplyWordsToMatchShape;
    }
    if (obj.hasOwnProperty('singleWordMode')) {
      this.singleWordMode = obj.singleWordMode;
    }
    if (obj.hasOwnProperty('use3DWordCloud')) {
      this.use3DWordCloud = obj.use3DWordCloud;
    } else if (!obj.hasOwnProperty('use3DWordCloud') && obj.id) {
      this.use3DWordCloud = false;
    }
    if (!isEmpty(obj.options3DWordCloud)) {
      this.options3DWordCloud = obj.options3DWordCloud;
    }
  }

  private genId(): string {
    return 'WCLOUD' + (new Date()).getTime().toString();
  }

  compare(template: WordCloudTemplate) {
    return !template || (this.multiplyWordsToMatchShape !== template.multiplyWordsToMatchShape ||
       this.singleWordMode !== template.singleWordMode || this.url !== template.url ||
      this.options !== template.options || this.use3DWordCloud !== template.use3DWordCloud ||
      this.compare3DOptions(template.options3DWordCloud));
  }

  private compare3DOptions(options: I3DWordCloudOptions) {
    return (!isEmpty(this.options3DWordCloud) && isEmpty(options)) ||
      (isEmpty(this.options3DWordCloud) && !isEmpty(options)) ||
      (!isEmpty(this.options3DWordCloud) && !isEmpty(options) &&
        (this.options3DWordCloud.shape !== options.shape ||
         this.options3DWordCloud.direction !== options.direction));
  }

  toObject() {
    delete this.url;
    return {...this};
  }
}
