import {AbstractContent} from './AbstractContent';
import {BehaviorSubject} from 'rxjs';

export class LinkContent extends AbstractContent {
  type = 'link';
  url: string;

  changeSubject = {
    subject: new BehaviorSubject<number>(0),
    toJSON: function() {
      return undefined;
    }
  };

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj.url) {
      this.url = obj.url;
    }
  }

  mergeContent(src: LinkContent) {
    super.mergeContent(src);

    if (this.url !== src.url) {
      this.url = src.url;
      this.changeSubject.subject.next((new Date()).getTime());
    }
  }
}
