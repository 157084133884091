import {DEPENDENCY, UNION_BY} from '../../core/constants';
import {UtilsService} from '../../core/utils.service';

export class DependencyQuestion {
  id: string = this.genId();
  dependencyType: DEPENDENCY = DEPENDENCY.NONE;
  questionId: string = null;
  answerIdList = [];
  answersUnionBy: UNION_BY = UNION_BY.AND;

  constructor(obj?) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.hasOwnProperty('dependencyType')) {
      this.dependencyType = obj.dependencyType;
    }
    if (obj.questionId) {
      this.questionId = obj.questionId;
    }
    if (obj.answerIdList) {
      this.answerIdList = obj.answerIdList;
    }
    if (obj.hasOwnProperty('answersUnionBy')) {
      this.answersUnionBy = obj.answersUnionBy;
    }
  }

  private genId(): string {
    return 'DQ' + UtilsService.createId();
  }

  private get answersUnionByAnd() {
    return this.answersUnionBy === UNION_BY.AND;
  }

  private get answersUnionByOr() {
    return this.answersUnionBy === UNION_BY.OR;
  }

  dependencyResult(userAnswers: string[]): boolean {
    let result = false;
    if (this.answersUnionByAnd) {
      // if userAnswers include count answerIdList then return true
      const answers = (userAnswers || []).filter(aId => this.answerIdList.includes(aId));
      result = answers.length === this.answerIdList.length;
    } else
    if (this.answersUnionByOr) {
      result = this.answerIdList.some(id => (userAnswers || []).indexOf(id) > -1);
    }
    return result;
  }

  get dependencyConditionShowIf() {
    return this.dependencyType === DEPENDENCY.SHOW_IF;
  }

  get dependencyConditionHideIf() {
    return this.dependencyType === DEPENDENCY.HIDE_IF;
  }

  toObject() {
    return {...this};
  }

}
