import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Constants} from '../../../core/constants';
import {IQuestions, QuestionnaireContent} from '../../../model/content/QuestionnaireContent';
import * as fromRoot from '../../../reducers';
import {Store} from '@ngrx/store';
import {UtilsService} from '../../../core/utils.service';
import {EventQuestion} from '../../../model/EventQuestion';
import {BehaviorSubject, combineLatest, map, Subject, Subscription} from 'rxjs';
import {cloneDeep, difference, isEmpty} from 'lodash';
import {TimeLineService} from '../../../services/time-line.service';
import {StdComponent} from '../../../core/std-component';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {CommonService} from '../../../core/common.service';
import {ToasterService} from '../../../core/toaster.service';
import {ContentService} from '../../../services/content.service';
import {MatTabGroup} from '@angular/material/tabs';
import {EventsDataService} from '../../../services/events-data.service';
import {FollowMeService} from '../../../services/follow-me.service';
import {LoginService} from '../../../login/login.service';
import {Event} from '../../../model/Event';

export interface IQContentAnswers {
  [questionId: string]: {[userId: string]: string[]; };
}

export interface IQContentAnswersLastChange {
  [questionId: string]: {[userId: string]: string[]; };
}

@Component({
  selector: 'app-questionnaire-tab',
  templateUrl: './questionnaire-tab.component.html',
  styleUrls: ['./questionnaire-tab.component.scss']
})
export class QuestionnaireTabComponent extends StdComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() currentEvent: Event;
  @Input() set isPresenter(value: boolean) {
    this._isPresenter = value;
  }
  @Input() isViewer: boolean;
  @Input() castContent: BehaviorSubject<QuestionnaireContent>;
  @Input() isRegistrationQuestionnaire: boolean;
  @Input() set currentRegisteredUserId(value) {
    if (value) {
      this.currentUserId = value;
      this.currentUserKey = UtilsService.md5(value);
    }
  }
  @Output() onCloseDetail = new EventEmitter<boolean>();
  @Output() onTabChangeEvent = new EventEmitter<string>();
  @Input() isPresenterAnswer: boolean;
  @ViewChild('tabGroup') tgroup: MatTabGroup;
  @ViewChild('buttonCustom') buttonCustom;
  @ViewChild('questionHeader') questionHeader;

  readonly Constants = Constants;

  currentQIndex = 0;
  currentQKey = new BehaviorSubject<string>(null);
  @Input() qContent: QuestionnaireContent;
  qContentAnswers: IQContentAnswers = {};
  qContentAnswersLastChange: IQContentAnswersLastChange = {};
  qContentAnswersSubject = new BehaviorSubject<any>(null);
  questionList: IQuestions = {};
  allUsersList: string[] = [];
  questionProgress = {};
  questionProgressTooltip = {};
  currentUserId = '';
  currentUserKey = '';
  questionKeyList: string[] = [];
  tabsInited = false;
  localQuestionCount  = 0;
  questionReceived = false;
  singleMode = false;
  headerOffset = 45;
  private _initNavigateSubscription = false;
  startDrag = false;
  startDropChangeIndex: number = null;
  dropChangeSubject = new Subject<number>();
  paginatorBefore;
  paginatorAfter;
  tabHeader;
  answersSubscribe: Subscription;
  contentChangedId: string;
  contentCurrentId: string;
  dependencyKeyDiff$ = new Subject<string[]>();
  private _isPresenter;

  constructor(protected injector: Injector,
              private store: Store<fromRoot.State>,
              private utils: UtilsService,
              private cdr: ChangeDetectorRef,
              public eventsDataService: EventsDataService,
              public timeLineService: TimeLineService,
              public contentService: ContentService,
              public common: CommonService,
              public toasterService: ToasterService,
              private zone: NgZone,
              private sanitizer: DomSanitizer,
              private followMeService: FollowMeService,
              private loginService: LoginService) {
    super(injector);
  }

  get isPresenter() {
    return this._isPresenter || this.loginService.educationMode;
  }

  questionHeaderMaxHeightScale(question: {}, media: boolean, fullscreen: boolean) {
    const fontSize = this.utils.fontSizeScale(question, media, fullscreen);
    const size = +fontSize.substr(0, fontSize.length - 2);
    return '' + (size * (fullscreen ? 7 : 5)) + 'vh';
  }

  getUserID() {
    if (!this.loginService.getAppUser()) {
      return;
    }
    if (!this.qContent.anonymousAnswers) {
      return this.currentUserId ? this.currentUserId : this.loginService.getAppUser().userId;
    } else {
      return this.currentUserKey = UtilsService.md5u(this.loginService.getAppUser());
    }
  }

  ngOnInit() {
    if (!this.isRegistrationQuestionnaire) {
      this.store.select(fromRoot.getCurrentUser)
        .pipe(this.takeUntilAlive())
        .subscribe(user => {
          if (user) {
            this.currentUserId = user.userId;
            this.currentUserKey = UtilsService.md5u(user);
          } else {
            this.currentUserId = '';
            this.currentUserKey = '';
          }
          this.cdr.markForCheck();
        });
      this.dependencyKeyDiff$.pipe(this.takeUntilAlive())
        .subscribe(diff => {
          const parent = this.timeLineService.planeListContent[this.qContent.parentId];
          this.eventsDataService.clearDependentAnswersQ(this.getUserID(), this.currentEvent.eventId, this.qContent.parentId,
            this.qContent.id, diff, this.qContent.anonymousAnswers).then(() => {
              this.prepareQuestionnaireContent();
          });
        });
    }
    if (this.isPresenter) {
      this.store.select(fromRoot.getUsersOnline)
        .pipe(this.takeUntilAlive())
        .subscribe( uOnline => {
          if (uOnline) {
            const list: string[] = [];
            uOnline.forEach(row => {
              if (row.userId !== this.currentUserId) {
                list.push(row.userId);
              }
            });
            this.allUsersList = list;
            this.calcProgress();
            this.cdr.markForCheck();
          }
        });
    }
    combineLatest([
      this.store.select(fromRoot.getCurrentContent)
        .pipe(
          this.takeUntilAlive(),
          map(it => this.eventsDataService.createTypedContent(it)),
        ),
      this.timeLineService.sectionChildContentsListSubject.pipe(this.takeUntilAlive())
    ])
      .pipe(this.takeUntilAlive())
      .subscribe(([val, contentsObjects]) => {
        let cnData;
        if (val && !isEmpty(contentsObjects) && (cnData = contentsObjects.list.find(o => o.id === val.id))) {
          val = cnData;
        }
        this.initContent(val);
        this.cdr.markForCheck();
      });
    this.store.select(fromRoot.getPresentationModeKeyAction)
      .pipe(this.takeUntilAlive())
      .subscribe(keyValue => {
        if (this._initNavigateSubscription) {
          const question = this.questionList[this.questionKeyList[this.currentQIndex]] as EventQuestion;
          if (!this.isPresenter && keyValue && question &&
            (![Constants.QTYPE_WORD_CLOUD, Constants.QTYPE_TEXT].includes(question.storypoint) ||
              ([Constants.QTYPE_WORD_CLOUD].includes(question.storypoint) && this.qContent.showResult))) {
            const keyCode = keyValue.split(',')[0];
            switch (keyCode) {
              case Constants.RIGHT_ARROW:
                this.changeTab(1, true);
                break;
              case Constants.LEFT_ARROW:
                this.changeTab(-1, true);
                break;
              default:
                break;
            }
          } else if (this.isPresenter && keyValue) {
            const keyCode = keyValue.split(',')[0];
            switch (keyCode) {
              case Constants.RIGHT_ARROW:
                this.changeTab(1, true);
                break;
              case Constants.LEFT_ARROW:
                this.changeTab(-1, true);
                break;
              default:
                break;
            }
          } else if (keyValue && question && [Constants.QTYPE_WORD_CLOUD, Constants.QTYPE_TEXT].includes(question.storypoint)) {
            const keyCode = keyValue.split(',')[0];
            switch (keyCode) {
              case Constants.FORCE_RIGHT_ARROW:
                this.changeTab(1, true);
                break;
              case Constants.FORCE_LEFT_ARROW:
                this.changeTab(-1, true);
                break;
              default:
                break;
            }
          }
        } else {
          this._initNavigateSubscription = true;
        }
      });
    this.dropChangeSubject.pipe(this.takeUntilAlive())
      .subscribe(index => {
        this.startDropChangeIndex = null;
        this.tgroup.selectedIndex = null;
        this.cdr.detectChanges();
        this.tgroup.selectedIndex = index;
        this.currentQIndex = index;
      });
  }

  initContent(val) {
    const init = (oldContentId, oldQuestionsCount) => {
      this.questionList = this.qContent.questions;
      this.singleMode = (this.questionList && Object.keys(this.questionList).length <= 1);
      this.questionReceived = true;
      this.fillQuestionKeyList();
      if (this.qContent.id !== oldContentId ||
        (this.qContent.id === oldContentId && oldQuestionsCount !== this.questionKeyList.length)) {
        this.timeLineService.currentQuestionnaireOpenLastQuestion.next(this.currentQIndex === this.questionKeyList.length - 1);
        this.timeLineService.currentQuestionnaireOpenTextQuestion.next(
          this.qContent.questions && this.qContent.questions[this.questionKeyList[this.currentQIndex]] &&
          [Constants.QTYPE_TEXT, Constants.QTYPE_WORD_CLOUD]
            .includes(this.qContent.questions[this.questionKeyList[this.currentQIndex]].storypoint));
      }
      if (this.isPresenter) {
        this.calcProgress();
      }
      if (this.startDropChangeIndex !== null) {
        this.dropChangeSubject.next(this.startDropChangeIndex);
      }
    };
    if (val && val.type === Constants.CONTENT_TYPE_QUESTIONNAIRE) {
      const oldContentId = this.qContent ? this.qContent.id : null;
      const oldQuestionsCount = isEmpty(this.questionKeyList) ? 0 : this.questionKeyList.length;
      this.qContent = cloneDeep(new QuestionnaireContent(val));
      this.contentChangedId = this.qContent.id;
      const prep = this.prepareQuestionnaireContent();
      if (prep instanceof Promise) {
        prep.then(() => init(oldContentId, oldQuestionsCount));
      } else {
        init(oldContentId, oldQuestionsCount);
      }
    }
  }

  prepareQuestionnaireContent(): Subscription | Promise<any> {
    const manager = (snapshot) => {
      const answers = {};
      if (snapshot) {
        for (const uAnswers of snapshot) {
          for (const qKey of Object.keys(uAnswers)) {
            if (qKey !== 'userId') {
              if (!answers[qKey]) {
                answers[qKey] = {};
              }
              answers[qKey][uAnswers.userId] = uAnswers[qKey].answers;
            }
          }
        }
      }
      return answers;
    };
    const attendee = (snapshot): {answers: any, lastChange: any} => {
      const answers = {};
      const lastChange = {};
      if (snapshot) {
        for (const qKey of Object.keys(snapshot)) {
          if (qKey !== 'userId') {
            if (!answers[qKey]) {
              answers[qKey] = {};
            }
            if (!lastChange[qKey]) {
              lastChange[qKey] = {};
            }
            const userKey = this.qContent.anonymousAnswers ? this.currentUserKey : this.currentUserId;
            answers[qKey][userKey] = snapshot[qKey].answers;
            lastChange[qKey][userKey] = snapshot[qKey].lastchange;
          }
        }
      }
      return {answers: answers, lastChange: lastChange};
    };

    if (this.answersSubscribe) {
      this.answersSubscribe.unsubscribe();
      this.answersSubscribe = null;
    }
    if (this.isRegistrationQuestionnaire) {
      if (this.isPresenter) {
        this.answersSubscribe = this.eventsDataService.getRegistrationQuestionnaireAnswers(
          this.currentEvent.eventId, this.qContent.parentId)
          .pipe(this.takeUntilAlive())
          .subscribe(snapshot => {
            this.qContentAnswers = manager(snapshot);
            this.qContentAnswersSubject.next(this.qContentAnswers);
            this.calcProgress();
            this.cdr.markForCheck();
          });
        return this.answersSubscribe;
      } else {
        // todo it is can't be? or it is assistant.
        return this.eventsDataService.getRegistrationQuestionnaireAnswersByUser(
          this.currentUserId, this.currentEvent.eventId, this.qContent.parentId)
          .then(snapshot => {
            this.qContentAnswers = attendee(snapshot).answers;
            for (const qKey of Object.keys(this.qContent.questions || {})) {
              this.qContent.questions[qKey].answers = this.qContentAnswers[qKey] ? this.qContentAnswers[qKey] : {};
            }
            this.fillQuestionKeyList();
            this.cdr.markForCheck();
          });
      }
    } else {
      if (this.isPresenter) {
        this.answersSubscribe = this.eventsDataService.getQuestionnaireAnswers(
          this.currentEvent.eventId, this.qContent.parentId, this.qContent.id)
          .pipe(this.takeUntilAlive())
          .subscribe(snapshot => {
            this.qContentAnswers = manager(snapshot);
            this.qContentAnswersSubject.next(this.qContentAnswers);
            this.calcProgress();
            this.cdr.markForCheck();
          });
        return this.answersSubscribe;
      } else {
        const userKey = this.qContent.anonymousAnswers ? this.currentUserKey : this.currentUserId;
        return this.eventsDataService.getQuestionnaireAnswersByUser(
          userKey, this.currentEvent.eventId, this.qContent.parentId, this.qContent.id, this.qContent.anonymousAnswers)
          .then(snapshot => {
            const answersObject = attendee(snapshot);
            this.qContentAnswers = answersObject.answers;
            this.qContentAnswersLastChange = answersObject.lastChange;
            for (const qKey of Object.keys(this.qContent.questions || {})) {
              this.qContent.questions[qKey].answers = this.qContentAnswers[qKey] ? this.qContentAnswers[qKey] : {};
            }
            this.fillQuestionKeyList();
            this.cdr.markForCheck();
          });
      }
    }
  }

  private fillQuestionKeyList() {
    let newKeyList = [];
    let oldKeyList = [];
    if (this.qContent && this.qContent[Constants.NODE_QUESTIONS]) {
        oldKeyList = cloneDeep(this.questionKeyList);
        newKeyList = this.isPresenter ? this.utils.getKeysByOrderField(this.qContent.questions) :
        this.applyDependency(this.utils.getKeysByOrderField(this.qContent.questions));
      if (!this.contentCurrentId) {
        this.contentCurrentId = this.qContent.id;
      }
      if (!this.isPresenter && !this.isRegistrationQuestionnaire && this.questionKeyList.length > newKeyList.length) {
        if (this.contentChangedId === this.contentCurrentId) {
          const qKeyDiff = difference(oldKeyList, newKeyList);
          this.dependencyKeyDiff$.next(qKeyDiff);
        } else {
          this.contentCurrentId = this.qContent.id;
        }
      }
      if (this.currentQIndex > newKeyList.length - 1) {
        this.currentQIndex = newKeyList.length - 1;
      }
      this.onTabChangeEvent.emit(newKeyList[this.currentQIndex]);
      this.currentQKey.next(newKeyList[this.currentQIndex]);
    } else {
      newKeyList = [];
    }
    const keyListChange = this.questionKeyList.length !== newKeyList.length ||
      (this.questionKeyList.some((key, index) => newKeyList.findIndex(nKey => nKey === key) !== index)) ||
        (this.questionKeyList.some( key => !newKeyList.includes(key)));
    this.questionKeyList = newKeyList;
    if (!this.tabsInited && this.questionKeyList && this.questionKeyList.length) {
      this.onTabChangeEvent.emit(this.questionKeyList[0]);
      this.currentQKey.next(this.questionKeyList[0]);
      this.tabsInited = true;
    }
    if (this.tgroup && keyListChange && this.startDropChangeIndex === null) {
      this.tgroup.selectedIndex = null;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
      this.tgroup.selectedIndex = this.currentQIndex;
    }
  }

  ngAfterViewInit() {
    const pel = this.tgroup._elementRef.nativeElement.firstElementChild;
    pel.appendChild(this.questionHeader.nativeElement);
    this.tabHeader = this.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-header');
    const tabs = this.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-label-container');
    this.paginatorBefore = this.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-header-pagination-before');
    this.paginatorAfter = this.tgroup._elementRef.nativeElement.getElementsByClassName('mat-mdc-tab-header-pagination-after');
    if (tabs) {
      tabs[0].setAttribute('style', 'border-radius: 4px');
      const div = document.createElement('paginator-tab-container');
      div.setAttribute('style', 'display: flex; padding-left: 2%; padding-right: 2%;');
      const newElem = this.tgroup._elementRef.nativeElement.appendChild(div);
      newElem.appendChild(this.paginatorBefore[0]);
      newElem.appendChild(tabs[0]);
      newElem.appendChild(this.paginatorAfter[0]);
      this.setPaginatorButtonVisible();
    }
  }

  setPaginatorButtonVisible() {
    const vm = this;
    if (this.tabHeader) {
      const mth = this.tabHeader[0];
      if (mth) {
        if (mth.className.includes('mat-mdc-tab-header-pagination-controls-enabled')) {
          let style = (this.paginatorBefore[0] as Element).getAttribute('style');
          if (!style || !style.includes(' display: flex;')) {
            style = (!style ? '' : style) + ' display: flex;';
            (this.paginatorBefore[0] as Element).setAttribute('style', style);
            style = (this.paginatorAfter[0] as Element).getAttribute('style');
            style = (!style ? '' : style) + ' display: flex;';
            (this.paginatorAfter[0] as Element).setAttribute('style', style);
          }
        } else {
          let style = (this.paginatorBefore[0] as Element).getAttribute('style');
          if (style && style.includes(' display: flex;')) {
            style = style.replace(' display: flex;', '');
            (this.paginatorBefore[0] as Element).setAttribute('style', style);
            style = (this.paginatorAfter[0] as Element).getAttribute('style');
            style = style.replace(' display: flex;', '');
            (this.paginatorAfter[0] as Element).setAttribute('style', style);
          }
        }
      }
    }
  }

  ngAfterViewChecked() {
    const self = this;
    const matTabHeader = this.tgroup._elementRef.nativeElement.firstElementChild;
    const headerHeight = matTabHeader.scrollHeight + 2 - (this.singleMode ? this.headerOffset : 0);
    if (this.questionReceived &&  Object.keys(this.questionKeyList).length !== this.localQuestionCount) {
      this.questionReceived = false;
      if (this.questionList && (Object.keys(this.questionList).length === 1)) {
        this.localQuestionCount = 0;
        const pel = this.tgroup._elementRef.nativeElement.firstElementChild;
        for (const value of pel.childNodes) {
          if (value.className === 'mat-mdc-tab-label-container') {
            value.style.height = 0;
            self.headerOffset = 0;
          }
          if (value.id === 'buttonCustom' && self.isRegistrationQuestionnaire && this.isPresenter) {
            value.hidden = true;
          }
          if (value.id === 'questionHeader') {
            value.style.marginTop = 0;
          }
        }
        pel.style.padding = 0;
      } else {
        if (this.localQuestionCount === 0 && 0 < Object.keys(this.questionList).length) {
          this.localQuestionCount = Object.keys(this.questionList).length;
          const pel = this.tgroup._elementRef.nativeElement.firstElementChild;
          for (const value of pel.childNodes) {
            if (document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH &&
                value.className.indexOf('mat-mdc-tab-header-pagination') > -1) {
              value.style.height = 0;
            }
            if (value.className === 'mat-mdc-tab-label-container') {
              value.style.height = 'auto';
              value.style.marginLeft = '2%';
              self.headerOffset = 0;
            }
            if (value.id === 'buttonCustom') {
              value.hidden = false;
            }
            if (value.id === 'questionHeader') {
              value.style.marginTop = '5px';
            }
          }
        }
      }
    }
    this.setPaginatorButtonVisible();
  }

  media() {
    return document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH;
  }

  get questionnaireUserList() {
    return this.allUsersList.filter(jUserId => !this.currentEvent.isManager(jUserId) &&
      !this.timeLineService.isSpeakerContent({userId: jUserId},
        this.timeLineService.planeListContent[this.qContent.parentId]?.sectionContent));
  }

  calcProgress() {
    if (!this.qContent || isEmpty(this.timeLineService.planeListContent)) {
      return;
    }
    const questions = this.qContent.getStrictQuestions();
    if (isEmpty(questions)) {
      return;
    }
    const vm = this;
    Object.keys(questions).forEach(function (key) {
      const answers = vm.qContentAnswers[key] ? vm.qContentAnswers[key] : {};
      if (isEmpty(answers)) {
        vm.questionProgress[key] = 0;
        vm.questionProgressTooltip[key] =
          '0 of ' + vm.questionnaireUserList.length  + vm.utils.i18n('question.process.tooltip');
      } else {
        vm.questionProgress[key] = 100 / (vm.questionnaireUserList.length / Object.keys(answers).length);
        vm.questionProgressTooltip[key] =
          Object.keys(answers).length + ' of ' + vm.questionnaireUserList.length + vm.utils.i18n('question.process.tooltip');
      }
    });
  }

  changeTab(val, emitGotoNextContent = false) {
    const vm = this;
    const qcount = this.questionKeyList.length;
    if (this.tgroup.selectedIndex > -1 && this.tgroup.selectedIndex < qcount) {
      this.tgroup.selectedIndex = this.tgroup.selectedIndex + val;
    }
  }

  onTabChange(event) {
    this.ngAfterViewChecked();
    this.currentQIndex = event.index;
    this.onTabChangeEvent.emit(this.questionKeyList[event.index]);
    this.currentQKey.next(this.questionKeyList[event.index]);
    if (this.questionKeyList && this.questionKeyList.length > 0 && event.index > -1) {
      this.timeLineService.currentQuestionnaireOpenLastQuestion.next(this.currentQIndex === this.questionKeyList.length - 1);
      this.timeLineService.currentQuestionnaireOpenTextQuestion.next(
        this.qContent.questions && this.qContent.questions[this.questionKeyList[this.currentQIndex]] &&
        [Constants.QTYPE_TEXT, Constants.QTYPE_WORD_CLOUD]
          .includes(this.qContent.questions[this.questionKeyList[this.currentQIndex]].storypoint));
    }
  }

  /**
   * Apply question dependency for participant questionnaire dialog.
   * @returns {string[]} - question id (key) list.
   * @param questionsIdLis
   */
  applyDependency(questionsIdLis: string[]): string[] {
    const result: string[] = [];
    const userId = this.currentUserId;
    const questions = this.qContent.questions;
    questionsIdLis.forEach(qKey => {
       const cq = questions[qKey];
       // for support ver 4.4 format question
       if (cq && !cq.id) {
         cq.id = qKey;
       }
       const question = new EventQuestion(cq);
       const dependency = question.getDependency();
       if (dependency) {
         if (dependency.dependencyConditionShowIf) {
           const dependedQuestion = new EventQuestion(questions[dependency.questionId]);
           if (dependency.dependencyResult(dependedQuestion.getUserAnswers(userId))) {
             result.push(question.id);
           }
         } else
         if (dependency.dependencyConditionHideIf) {
           const dependedQuestion = new EventQuestion(questions[dependency.questionId]);
           if (!dependency.dependencyResult(dependedQuestion.getUserAnswers(userId))) {
             result.push(question.id);
           }
         }
       } else {
         result.push(question.id);
       }
    });
    return result;
  }

  getSanitizedGradient(questionProgress): SafeStyle {
    const value = 'linear-gradient(' + (questionProgress < 50 ? 90 : 3.6 * questionProgress - 270) +
      'deg, ' + (questionProgress < 50 ? 'white' : Constants.PROGRESS_COLOR) + ' 51%, transparent 50%, transparent), linear-gradient(' +
      (questionProgress < 50 ? 90 + 3.6 * questionProgress : 270) +
      'deg, ' + Constants.PROGRESS_COLOR + ' 51%, white 50%, white)';
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }

  onDestroy(): void {
    this.timeLineService.currentQuestionnaireOpenLastQuestion.next(false);
    this.timeLineService.currentQuestionnaireOpenTextQuestion.next(false);
  }
}

export class AnswersQuestion {
  constructor(public questionId: string, public answers: any, public timelineId: any, public properties?: any) {}
}
