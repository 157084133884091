import {ChangeDetectorRef, Injectable} from '@angular/core';
import {take} from 'rxjs';
import {DuplicateEventDialogComponent} from './duplicate-event-dialog/duplicate-event-dialog.component';
import {TimeLineService} from '../services/time-line.service';
import {EventsDataService} from '../services/events-data.service';
import {CommonService} from '../core/common.service';
import {ContentService} from '../services/content.service';
import {LoginService} from '../login/login.service';
import * as ui from '../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {MatDialog} from '@angular/material/dialog';
import {Event, IEventCreateDto} from '../model/Event';
import {SectionContent} from '../model/content/SectionContent';
import {Router} from '@angular/router';
import {ReferenceDataService} from '../services/reference-data.service';
import {BANNER_TYPE, PAGES, VIRTUAL_CONFERENCE_EVENT, VIRTUAL_CONFERENCE_TYPE} from '../core/constants';
import {RegistrationProcessService} from '../services/registration-process.service';
import {MailTemplateApiService} from '../services/mail-template-api.service';
import {IInviteUserEmail} from '../services/event-mode-api.service';

export interface ShiftEventParams {
  useShift: boolean;
  oldStartDate: number;
  newStartDate: number;
  oldEndDate: number;
  newEndDate: number;
  endDateFixed: boolean;
  oldDuration: number;
  newDuration: number;
  durationFixed: boolean;
  prepPhaseStart: number;
  prepPhaseEnd: number;
  wrapUpPhaseStart: number;
  wrapUpPhaseEnd: number;
}

@Injectable({
  providedIn: 'root'
})
export class CreateEventService {

  eventId: string;

  constructor(private store: Store<fromRoot.State>
    , private dialog: MatDialog
    , private timeLineService: TimeLineService
    , private eventsDataService: EventsDataService
    , private contentService: ContentService
    , private referenceDataService: ReferenceDataService
    , private mailTemplateService: MailTemplateApiService
    , private loginService: LoginService
    , private router: Router
    , public regProcServices: RegistrationProcessService
    , private common: CommonService) {
  }

  updateInstantSettings(eventId, instantSettings) {
    const vm = this;
    this.common.showProgress.next(true);
    return this.eventsDataService.updateEventInstantSettings(eventId, instantSettings).then(() => {
      return vm.contentService.createFixedSections(eventId, instantSettings)
        .then(() => {
          return vm.eventsDataService.setCustomActionLongValue(eventId,
            'lastChangeEventSettings', vm.common.utils.now());
        })
        .then(() => {
          this.common.showProgress.next(false);
          return Promise.resolve(true);
        })
        .catch(err => {
          vm.common.log.error(err);
          vm.common.showProgress.next(false);
        });
    }).catch((err) => {
      vm.common.log.error(err);
      this.common.showProgress.next(false);
    });
  }

  updateEvent(event: Event) {
    if (!event.eventId) {
      return Promise.resolve();
    }
    this.common.showProgress.next(true);
    let isNeedToNotify = false;
    return this.eventsDataService.getEventPromise(String(event.eventId))
      .then((old) => {
        isNeedToNotify = old.supportMode !== event.supportMode;
        return this.eventsDataService.update(event);
      })
      .then(() => {
        return isNeedToNotify ? this.eventsDataService.sendSupportNotification(event.eventId) : Promise.resolve();
      })
      .then(() => {
        return this.eventsDataService.pushRefreshUserEvent(event)
          .then(() => {
            return this.eventsDataService.setCustomActionLongValue(event.eventId,
              'lastChangeEventSettings', this.common.utils.now());
          })
          .catch(err => {
            this.common.log.error(err);
            this.common.showProgress.next(false);
          });
      })
      .then(() => this.common.showProgress.next(false))
      .catch(err => {
        this.common.log.error(err);
        this.common.showProgress.next(false);
      });
  }

  /*
    updateEventLogo(result, templateEvent: Event) {
      if (!result.event.eventId) {
        return Promise.resolve();
      }
      const checkFbStorage = templateEvent.logo && this.common.utils.isFBUrl(templateEvent.logo, templateEvent.eventId);
      this.timeLineService.timelineProgress.next(true);
      this.eventsDataService.patchEventLogo(templateEvent.eventId, result.event.logo, checkFbStorage)
        .then((patchResult) => {
          result.event.logo = patchResult.imageUrl === undefined ? patchResult.imageUrl = '' : patchResult.imageUrl;
          return this.updateEvent(result.event);
        }).catch(err => {
          this.common.log.error(err);
          this.timeLineService.timelineProgress.next(false);
      });
    }
  */

  /*
    updateWelcomeScreen(result, srcWelcomeScreen, currentEvent: Event) {
      if (!result.event.eventId) {
        return Promise.resolve();
      }
      let checkFbStorage = srcWelcomeScreen && this.common.utils.isFBUrl(srcWelcomeScreen.imageUrl, currentEvent.eventId);
      this.timeLineService.timelineProgress.next(true);
      return this.eventsDataService.patchWelcomeScreen(currentEvent.eventId,
        result.welcomeScreen ? result.welcomeScreen.imageUrl : null, checkFbStorage, false)
        .then((patchResultWS) => {
          if (result.welcomeScreen) {
            result.welcomeScreen.imageUrl = patchResultWS.imageUrl;
          }
          checkFbStorage = srcWelcomeScreen && this.common.utils.isFBUrl(srcWelcomeScreen.imageUrlM, currentEvent.eventId);
          return this.eventsDataService.patchWelcomeScreen(currentEvent.eventId,
            result.welcomeScreen ? result.welcomeScreen.imageUrlM : null, checkFbStorage, true)
            .then((patchResultWSMobile) => {
              if (result.welcomeScreen) {
                result.welcomeScreen.imageUrlM = patchResultWSMobile.imageUrl;
              }
              return this.eventsDataService.editWelcomeScreen(result.event.eventId, result.welcomeScreen)
                .then(() => {
                  this.timeLineService.timelineProgress.next(false);
                });
            });
        }).catch(err => {
          this.common.log.error(err);
          this.timeLineService.timelineProgress.next(false);
        });
    }
  */

  updateEventSectionTypes(eventId, sectionsTypesList, deleteType: {id: any}) {
    if (!eventId) {
      return Promise.resolve();
    }
    this.common.showProgress.next(true);
    return this.eventsDataService.updateSectionTypes(eventId, sectionsTypesList, deleteType)
      .then(() => {
        return this.eventsDataService.setCustomActionLongValue(eventId,
          'lastChangeEventSettings', this.common.utils.now());
      })
      .then(() => {
        this.common.showProgress.next(false);
      })
      .catch(err => {
        this.common.log.error(err);
        this.common.showProgress.next(false);
      });
  }

  create(
    name: string,
    description: string,
    state: string,
    presenters: string[],
    concierges: string[],
    start: number,
    end: number,
    mode: string,
    image: string,
    defaultLanguage: string,
    hideSelfLearning: boolean
  ) {
    const request: IEventCreateDto = {
      name,
      description,
      state,
      presenters,
      concierges,
      start,
      end,
      mode,
      image,
      defaultLanguage,
      hideSelfLearning
    };
    return this.eventsDataService.create(request);
  }

  createEvent(result, edit, srcWelcomeScreen, currentEvent: Event, currentUser, navigateToRegistration, cdr: ChangeDetectorRef) {
    const vm = this;
    const addUser = (iu: IInviteUserEmail, eventId) => {
      return this.eventsDataService.addInvitedUsers(eventId, [iu], null)
        .catch((err) => {
          this.common.showProgress.next(false);
          this.common.log.error(err);
          throw err;
        });
    };
    const addInviteAndRegister = (eventId, inviteUsers: IInviteUserEmail[]) => {
      return this.eventsDataService.getRootSection(eventId)
        .pipe(take(1)).toPromise().then((r) => {
        if (r) {
          this.regProcServices.currentEventId = eventId;
          let add = Promise.resolve(null);
          for (const em of inviteUsers) {
            add = add.then(() => {
              return addUser(em, eventId);
            });
          }
          return add.then(() => {
            this.regProcServices.currentEventId = undefined;
          });
        }
      });
    };
    if (result && !result.actionDuplicateEvent) {
      const addedPresenters = !result.event['addPMails'] ? null : result.event['addPMails']
        .map(email => email.charAt(0) === '*' ? email.substring(1) : email);
      if (edit) {
        if (cdr) {
          cdr.detach();
        }
        this.common.showProgress.next(true);
        if (cdr) {
          cdr.detectChanges();
        }
        let checkFbStorage = currentEvent.logo && this.common.utils.isFBUrl(currentEvent.logo, currentEvent.eventId);
        vm.eventsDataService.updateEventInstantSettings(String(currentEvent.eventId), result.instantSettings);
        if (result.inviteUsers && result.inviteUsers.length && result.event && result.event.eventId) {
          addInviteAndRegister(result.event.eventId, result.inviteUsers);
        }
        this.eventsDataService.patchEventLogo(currentEvent.eventId, result.event.logo, checkFbStorage)
          .then(function (patchResult) {
            // todo: While the server ignores updating fields with null values, we execute this code.
            //  After corrections on the server, transfer to the usual assignment of a null value.
            result.event.logo = patchResult.imageUrl === undefined ? patchResult.imageUrl = '' : patchResult.imageUrl;
            checkFbStorage = currentEvent.verticalBanner && vm.common.utils.isFBUrl(currentEvent.verticalBanner, currentEvent.eventId);
            vm.eventsDataService
              .patchEventBanner(currentEvent.eventId, result.event.verticalBanner, checkFbStorage, BANNER_TYPE.VERTICAL)
              .then(function (vResult) {
                result.event.verticalBanner = vResult.imageUrl === undefined ? vResult.imageUrl = '' : vResult.imageUrl;
                checkFbStorage = currentEvent.horizontalBanner &&
                  vm.common.utils.isFBUrl(currentEvent.horizontalBanner, currentEvent.eventId);
                vm.eventsDataService
                  .patchEventBanner(currentEvent.eventId, result.event.horizontalBanner, checkFbStorage, BANNER_TYPE.HORIZONTAL)
                  .then(function (hResult) {
                    result.event.horizontalBanner = hResult.imageUrl === undefined ? hResult.imageUrl = '' : hResult.imageUrl;
                    checkFbStorage = currentEvent.background &&
                      vm.common.utils.isFBUrl(currentEvent.background, currentEvent.eventId);
                    vm.eventsDataService
                      .patchEventBanner(currentEvent.eventId, result.event.background, checkFbStorage, BANNER_TYPE.BACKGROUND)
                      .then(function (bResult) {
                        result.event.background = bResult.imageUrl === undefined ? bResult.imageUrl = '' : bResult.imageUrl;
                        checkFbStorage = currentEvent.mobileBackground &&
                          vm.common.utils.isFBUrl(currentEvent.mobileBackground, currentEvent.eventId);
                        vm.eventsDataService
                          .patchEventBanner(currentEvent.eventId,
                            result.event.mobileBackground, checkFbStorage, BANNER_TYPE.MOBILE_BACKGROUND)
                          .then(function (mbResult) {
                            result.event.mobileBackground = mbResult.imageUrl === undefined ? mbResult.imageUrl = '' : mbResult.imageUrl;
                    vm.eventsDataService.editWelcomeScreen(result.event.eventId, result.welcomeScreen).then(function () {
                      return vm.eventsDataService.update(result.event).then(function (r) {
                        return vm.eventsDataService.getRootSectionPromise(result.event.eventId).then(obj => {
                          if (obj) {
                            const rootSection = new SectionContent(obj);
                            return vm.eventsDataService.updateSectionContent(result.event.eventId, rootSection.id,
                              {requiredRegistration: result.instantSettings.attendeesRegistration});
                          } else {
                            return Promise.resolve();
                          }
                        }).then(() => {
                          return vm.timeLineService.planeList.pipe(take(1)).toPromise().then(() => {
                            return vm.contentService.createFixedSections(currentEvent.eventId, result.instantSettings)
                              .catch(err => {
                                vm.common.log.error(err);
                                vm.common.showProgress.next(false);
                                if (cdr) {
                                  cdr.reattach();
                                  if (!cdr['destroyed']) {
                                    cdr.detectChanges();
                                  }
                                }
                              });
                          });
                        }).then(() => {
                          if (addedPresenters && addedPresenters.length > 0) {
                            return vm.checkAndInvitePresenters(currentEvent, addedPresenters, result);
                          } else {
                            return vm.eventsDataService.pushRefreshUserEvent(result.event)
                              .catch(err => {
                                vm.common.log.error(err);
                                vm.common.showProgress.next(false);
                                if (cdr) {
                                  cdr.reattach();
                                  if (!cdr['destroyed']) {
                                    cdr.detectChanges();
                                  }
                                }
                              });
                          }
                        }).then(() => {
                          return vm.eventsDataService.setCustomActionLongValue(result.event.eventId,
                            'lastChangeEventSettings', vm.common.utils.now());
                        }).then(() => {
                          vm.common.showProgress.next(false);
                          if (cdr) {
                            cdr.reattach();
                            if (!cdr['destroyed']) {
                              cdr.detectChanges();
                            }
                          }
                        }).catch(err => {
                          vm.common.log.error(err);
                          vm.common.showProgress.next(false);
                          if (cdr) {
                            cdr.reattach();
                            if (!cdr['destroyed']) {
                              cdr.detectChanges();
                            }
                          }
                        });
                      }).catch(err => {
                        vm.common.log.error(err);
                        vm.common.showProgress.next(false);
                        if (cdr) {
                          cdr.reattach();
                          if (!cdr['destroyed']) {
                            cdr.detectChanges();
                          }
                        }
                      });
                    }).catch(err => {
                      vm.common.log.error(err);
                      vm.common.showProgress.next(false);
                      if (cdr) {
                        cdr.reattach();
                        if (!cdr['destroyed']) {
                          cdr.detectChanges();
                        }
                      }
                    });
                  });
                 });
                });
              });
          });
      } else {
        let rootSection = null;
        this.common.showProgress.next(true);
        const eventLogo = result.event.logo;
        const vEventBanner = result.event.verticalBanner;
        const hEventBanner = result.event.horizontalBanner;
        const background = result.event.background;
        const mobileBackground = result.event.mobileBackground;
        result.event.logo = null;
        result.event.verticalBanner = null;
        result.event.horizontalBanner = null;
        result.event.background = null;
        result.event.mobileBackground = null;
        let saveResult;
        let eventId;
        return this.eventsDataService.createEvent(result.event, result.event['addPMails'])
          .then((_saveResult) => {
            saveResult = _saveResult;
            eventId = saveResult.eventId;
            return this.eventsDataService.getRootSectionPromise(saveResult.eventId)
              .then((r) => {
                rootSection = r ? new SectionContent(r) : null;
                return this.contentService.createFixedSections(saveResult.eventId, result.instantSettings, rootSection)
                  .then(() => saveResult)
                  .catch(err => {
                    this.common.log.error(err);
                    this.common.showProgress.next(false);
                  });
              }).catch(err => {
                this.common.log.error(err);
                this.common.showProgress.next(false);
              });
          })
          .then(async () => {
            await this.eventsDataService.updateEventInstantSettings(eventId, result.instantSettings);
            if (result.instantSettings.canShowVirtualConferences && result.instantSettings.virtualConferenceSettings &&
              result.instantSettings.virtualConferenceSettings.mainEventOption === VIRTUAL_CONFERENCE_EVENT.INTEGRATED_LIVE_CONFERENCE &&
              result.instantSettings.virtualConferenceSettings.typeOfConference === VIRTUAL_CONFERENCE_TYPE.DAILY_CO &&
              result.instantSettings.virtualConferenceSettings.dailyCoSettings &&
              result.instantSettings.virtualConferenceSettings.dailyCoSettings.usePermanentRooms) {
              const rooms = result.instantSettings.virtualConferenceSettings.dailyCoSettings.permanentRooms ?? [];
              for (const r of rooms) {
                await this.eventsDataService.addUpdateMeetingRoomInDB(eventId, rootSection.id, r);
              }
            }
            await this.eventsDataService.updateSectionTypes(eventId, result.sectionsTypesList, null);
            if (result.inviteUsers && result.inviteUsers.length) {
              await addInviteAndRegister(eventId, result.inviteUsers);
            }
            if (result.instantSettings) {
              await this.eventsDataService.updateSectionContent(eventId, rootSection.id,
                {requiredRegistration: result.instantSettings.attendeesRegistration});
            }
            if (result.registrationSettings) {
              await this.eventsDataService.editRegistrationSettings(eventId, rootSection.id, result.registrationSettings)
                .then(() => {
                  if (result.questionnaire) {
                    result.questionnaire.content.id = rootSection.id;
                    result.questionnaire.content.parentId = rootSection.id;
                    result.questionnaire.content.eventId = eventId;
                    const questionKeyList = Object.keys(result.questionnaire.content.questions);
                    for (let i = 0; i < questionKeyList.length; i++) {
                      const qKey = questionKeyList[i];
                      result.questionnaire.content.questions[qKey].eventId = eventId;
                      result.questionnaire.content.questions[qKey].id = qKey;
                      result.questionnaire.content.questions[qKey].timelineId = rootSection.id;
                    }
                    return this.contentService.updateRegistrationQuestionnaireContent(eventId, result.questionnaire);
                  }
                });
            }
          if ((!eventLogo || !this.common.utils.isClipboardUrl(eventLogo)) && !result.welcomeScreen &&
            (!vEventBanner || !this.common.utils.isClipboardUrl(vEventBanner)) &&
            (!hEventBanner || !this.common.utils.isClipboardUrl(hEventBanner)) &&
            (!background || !this.common.utils.isClipboardUrl(background)) &&
            (!mobileBackground || !this.common.utils.isClipboardUrl(mobileBackground))) {
            this.closeMenu();
            this.common.showProgress.next(false);
            if (navigateToRegistration) {
              return this.router.navigate(['event', saveResult.shortLink, 'settings'], {state: {page: PAGES.DASHBOARD}});
            } else {
              return this.loginService.redirectTo('/event/' + saveResult.shortLink);
            }
          } else {
            await this.eventsDataService.addEventLogo(eventId, eventLogo)
              .then((logoUrl) => saveResult.logo = logoUrl.imageUrl);
            await this.eventsDataService.addEventBanner(eventId, vEventBanner, BANNER_TYPE.VERTICAL)
              .then((vUrl) => saveResult.verticalBanner = vUrl.imageUrl);
            await this.eventsDataService.addEventBanner(eventId, hEventBanner, BANNER_TYPE.HORIZONTAL)
              .then((hUrl) => saveResult.horizontalBanner = hUrl.imageUrl);
            await this.eventsDataService.addEventBanner(eventId, background, BANNER_TYPE.BACKGROUND)
              .then((bUrl) => saveResult.background = bUrl.imageUrl);
            await this.eventsDataService.addEventBanner(eventId, mobileBackground, BANNER_TYPE.MOBILE_BACKGROUND)
              .then((mbUrl) => saveResult.mobileBackground = mbUrl.imageUrl);
            if (result.welcomeScreen) {
              await this.eventsDataService.editWelcomeScreen(eventId, result.welcomeScreen);
            }
            return this.eventsDataService.update(saveResult).then(() => {
              this.closeMenu();
              this.common.showProgress.next(false);
              if (navigateToRegistration) {
                this.loginService.redirectTo('/event/' + saveResult.shortLink + '/settings');
              } else {
                this.loginService.redirectTo('/event/' + saveResult.shortLink);
              }
            });
          }
        }).catch((error) => {
          this.common.showProgress.next(false);
          throw error;
        });
      }
    } else if (result && result.actionDuplicateEvent) {
      const dialogDupRef = this.dialog.open(DuplicateEventDialogComponent, {
        width: '520px',
        data: {
          event: result.event
          , srcEvent: currentEvent
          , instantSettings: result.instantSettings
          , welcomeScreen: result.welcomeScreen
          , sectionTypes: result.sectionsTypesList
          , currentUserId: currentUser.userId
          , shiftEventParams: result.shiftEventParams
        }
      });
      dialogDupRef.afterClosed().subscribe(actionResult => {
      });
    }
  }

  private checkAndInvitePresenters(currentEvent, presentersEmail: [], saveResult) {
    const vm = this;
    return this.eventsDataService.getUsersOnlinePromise(currentEvent.eventId)
      .then(function (list) {
        if (list) {
          const eventUsersEmail = list.map(u => u.email);
          const invitePresenters = presentersEmail
            .filter(em => eventUsersEmail.findIndex(dem => dem === em) === -1);
          if (invitePresenters.length) {
            return vm.eventsDataService.addInvitedUsers(saveResult.event.eventId, invitePresenters, null).then(function (fr) {
              return vm.eventsDataService.pushRefreshUserEvent(saveResult.event)
                .catch(err => {
                  vm.common.log.error(err);
                  vm.common.showProgress.next(false);
                });
            }).catch(err => {
              vm.common.log.error(err);
              vm.common.showProgress.next(false);
            });
          } else {
            return vm.eventsDataService.pushRefreshUserEvent(saveResult.event).catch(err => {
              vm.common.log.error(err);
              vm.common.showProgress.next(false);
            });
          }
        } else {
          return Promise.resolve();
        }
      }).catch(err => {
        vm.common.log.error(err);
        vm.common.showProgress.next(false);
      });
  }

  closeMenu() {
    this.store.dispatch(new ui.SetMenuOpenAction(false));
  }
}
