import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {BehaviorSubject} from 'rxjs';
import {isEmpty, sum, union} from 'lodash';

interface ISummary {
  answer: string;
  count: number;
  correct: boolean;
}

@Component({
  selector: 'app-question-text-results',
  templateUrl: './question-text-results.component.html',
  styleUrls: ['./question-text-results.component.scss']
})
export class QuestionTextResultsComponent extends AbstractQuizQuestionResultsComponent {

  answers$ = new BehaviorSubject<string[] | ISummary[]>([]);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }


  protected onReceiveQuestionAnswers() {
    this.summaryQuestionAnswers$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (isEmpty(value)) {
          this.answers$.next([]);
        } else if (Array.isArray(value)) {
          this.answers$.next(value);
        } else {
          const answers = Object.keys(value)
            .map(s => new Object({
              answer: s,
              count: value[s],
              correct: !!this.question.items.find(a => a.getAnswerByLanguage(this.languageParams).toLowerCase() === s.toLowerCase())
            }) as ISummary);
          const correct = this.question.items.map(it => new Object({
            answer: it.getAnswerByLanguage(this.languageParams),
            count: 0,
            correct: true
          }) as ISummary);
          correct.forEach(it => {
            it.count = sum(answers.filter(a => it.answer.toLowerCase() === a.answer.toLowerCase()).map(v => v.count));
          });
          this.answers$.next(union(correct, answers.filter(a => !a.correct)).sort((a, b) => a.correct ? -1 : 1));
        }
      });
  }
}
