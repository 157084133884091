import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {LoggerService} from '../../../core/logger.service';
import {APP_MODE, LoginService} from '../../../login/login.service';
import {UtilsService} from '../../../core/utils.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {StdApiService} from '../../../services/std-api-service';
import {catchError, firstValueFrom, map, Observable} from 'rxjs';
import {isEmpty, merge} from 'lodash';
import {ContentContainer} from '../../../model/content/ContentContainer';
import {CommonService} from '../../../core/common.service';
import {
  DEFAULT_TAGS_REFERENCE,
  IContentPriorityParams,
  IContentsTagsMap,
  IContentTagsParams,
  TAGS_PATHS
} from '../tags-constants/tags-constants';
import {Tag} from '../tags-model/Tag';
import {deleteField} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TagsApiService extends StdApiService {

  constructor(protected store: Store<fromRoot.State>,
              protected logger: LoggerService,
              protected auth: LoginService,
              protected utils: UtilsService,
              protected common: CommonService,
              protected aFirestore: AngularFirestore,
              protected storage: AngularFireStorage) {
    super(store, logger, auth, utils, aFirestore, storage);
  }

  private tagsReferencesCollectionRef() {
    const userId = this.auth.getAppUser()?.userId;
    return this.afs.collection(`${TAGS_PATHS.MAIN}/${userId}/${TAGS_PATHS.TAGS_REFERENCE}`);
  }

  private contentsTagsCollectionRef() {
    const userId = this.auth.getAppUser()?.userId;
    return this.afs.collection(`${TAGS_PATHS.MAIN}/${userId}/${TAGS_PATHS.CONTENTS_TAGS}`);
  }

  loadContentsTags(eventId?: string): Observable<IContentsTagsMap> {
    if (eventId) {
      return this.contentsTagsCollectionRef()
        .doc(eventId)
        .valueChanges()
        .pipe(
          this.log('loadContentsTags'),
          catchError(err => this.firestoreCatchError(err))
        );
    } else {
      return this.contentsTagsCollectionRef()
        .valueChanges()
        .pipe(
          this.log('loadContentsTags'),
          map(res => Object.keys(res || {})
            .reduce((acc, id) => {
              acc = merge(acc, res[id]);
              return acc;
            }, {})),
          catchError(err => this.firestoreCatchError(err))
        );
    }
  }

  loadTagsReference(): Observable<Tag[]> {
    return this.tagsReferencesCollectionRef()
      .doc(TAGS_PATHS.TAGS_DOC_REFERENCE)
      .valueChanges()
      .pipe(
        this.log('loadTagsReference'),
        map(res => ((res || {}).reference || DEFAULT_TAGS_REFERENCE)
          .map(obj => new Tag(obj))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  saveTagsReference(list: Tag[]) {
    return this.aFirestore.firestore.runTransaction(transaction => {
      const ref = this.tagsReferencesCollectionRef()
        .doc(TAGS_PATHS.TAGS_DOC_REFERENCE).ref;
      const obj = {
        reference: list.map(it => it.toObject())
      };
      try {
        transaction.set(ref, obj, {merge: false});
        return Promise.resolve(true);
      } catch (e) {
        this.logger.error(e);
        throw e;
      }
    });
  }

  setTagToContent(params: IContentTagsParams) {
    const autoProcessed = this.auth.applicationMode === APP_MODE.NOTES;
    return this.aFirestore.firestore.runTransaction(transaction => {
      const ref = this.contentsTagsCollectionRef()
        .doc(params.eventId).ref;
      let obj;
      if (!params.itemId) {
        obj = {
          [params.contentId]: merge({
            tags: !isEmpty(params.tagsIdList) ? params.tagsIdList : deleteField()
          }, autoProcessed ? {
            processed: true
          } : {})
        };
      } else {
        obj = {
          [params.contentId]: {
            items: {
              [params.itemId]: merge({
                tags: !isEmpty(params.tagsIdList) ? params.tagsIdList : deleteField()
              }, autoProcessed ? {
                processed: true
              } : {})
            }
          }
        };
      }
      try {
        transaction.set(ref, obj, {merge: true});
        return Promise.resolve(true);
      } catch (e) {
        this.logger.error(e);
        throw e;
      }
    });
  }

  setPriorityToContent(params: IContentPriorityParams) {
    const autoProcessed = this.auth.applicationMode === APP_MODE.NOTES;
    return this.aFirestore.firestore.runTransaction(transaction => {
      const ref = this.contentsTagsCollectionRef()
        .doc(params.eventId).ref;
      let obj;
      if (!params.itemId) {
        obj = {
          [params.contentId]: merge({
            priority: params.priority ? params.priority : deleteField()
          }, autoProcessed ? {
            processed: true
          } : {})
        };
      } else {
        obj = {
          [params.contentId]: {
            items: {
              [params.itemId]: merge({
                priority: params.priority ? params.priority : deleteField()
              }, autoProcessed ? {
                processed: true
              } : {})
            }
          }
        };
      }
      try {
        transaction.set(ref, obj, {merge: true});
        return Promise.resolve(true);
      } catch (e) {
        this.logger.error(e);
        throw e;
      }
    });
  }

  deleteContentTags(content: ContentContainer) {
    const eventId = content.originalEventId ? content.originalEventId : content.eventId;
    const contentId = content.originalId ? content.originalId : content.id;
    return firstValueFrom(this.loadContentsTags(eventId))
      .then(tags => {
        return this.aFirestore.firestore.runTransaction(transaction => {
          const ref = this.contentsTagsCollectionRef().doc(eventId).ref;
          if (!tags || !tags[contentId]) {
            return Promise.resolve(true);
          }
          delete tags[contentId];
          try {
            transaction.set(ref, tags, {merge: false});
            return Promise.resolve(true);
          } catch (e) {
            this.logger.error(e);
            throw e;
          }
        });
      }).catch(e => {
        this.common.log.error(e);
        throw e;
      });
  }
}
