import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COUNTRY_LANGUAGE, IISOLanguage, COUNTRY, LANGUAGE } from '../../../core/language-constants';

const LANGUAGE_ENABLED = 'visibility';
const LANGUAGE_DISABLED = 'visibility_off';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {

  readonly languageFlagList: IISOLanguage[] = Object.values(COUNTRY_LANGUAGE);
  LANGUAGE_ENABLED = LANGUAGE_ENABLED;
  LANGUAGE_DISABLED = LANGUAGE_DISABLED;

  @Input() set languageCode(value: string) {
    this.selectedLanguageFlag = this.languageFlagList.find(f => f.languageISOCode === value);
  }
  @Input() set defaultLanguageCode(value: string) {
    this.defaultLanguageFlag = this.languageFlagList.find(f => f.languageISOCode === value);
  }
  @Input() usedLanguages: string[];
  @Input() enableLanguageSwitcher: boolean;
  @Input() stopPropagation: boolean;
  @Output() onLanguageChange = new EventEmitter<string>();
  @Output() onEnabledLanguagesChanged = new EventEmitter<string[]>();

  @Input() set enabledLanguages(value: string[]) {
    this.languagesEnabled = (value || [])
      .reduce((acc, lang) => {
        acc[lang] = true;
        return acc;
      }, {});
  }
  languagesEnabled: { [lang: string]: boolean; };

  defaultLanguageFlag: IISOLanguage;
  selectedLanguageFlag: IISOLanguage;

  constructor () {
    this.languageFlagList.forEach((languageFlag) => {
      this.languagesEnabled = { ...this.languagesEnabled, [languageFlag.languageISOCode]: true };
    });
  }

  onSelect(value: IISOLanguage) {
    this.selectedLanguageFlag = value;
    this.onLanguageChange.emit(value.languageISOCode);
  }

  handleEnableLanguages(languageISOCode: LANGUAGE): void {
    if (this.defaultLanguageFlag?.languageISOCode === languageISOCode) {
      return;
    }
    if (this.languagesEnabled[languageISOCode]) {
      delete this.languagesEnabled[languageISOCode];
    } else {
      this.languagesEnabled[languageISOCode] = true;
    }
    const enabledLanguages = Object.keys(this.languagesEnabled);
    this.onEnabledLanguagesChanged.emit(enabledLanguages);
  }

}
