import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexToRGBA'
})
export class HexToRGBA implements PipeTransform {

  transform(hex: string, opacity: number): any {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

}
