import {Constants, ILanguageParams, TEMPLATE_TYPE} from '../../../../../../core/constants';
import {
  QuestionCheckChoiceEditorComponent
} from '../question-check-choice/question-check-choice-editor/question-check-choice-editor.component';
import {QuestionScaleEditorComponent} from '../question-scale/question-scale-editor/question-scale-editor.component';
import {QuestionWordCloudEditorComponent} from '../question-word-cloud/question-word-cloud-editor/question-word-cloud-editor.component';
import {QuestionMatchingEditorComponent} from '../question-matching/question-matching-editor/question-matching-editor.component';
import {
  QuestionSelectGapFillingEditorComponent
} from '../question-select-gap-filling/question-select-gap-filling-editor/question-select-gap-filling-editor.component';
import {
  QuestionTextGapFillingEditorComponent
} from '../question-text-gap-filling/question-text-gap-filling-editor/question-text-gap-filling-editor.component';
import {
  QuestionCheckboxGapFillingEditorComponent
} from '../question-checkbox-gap-filling/question-checkbox-gap-filling-editor/question-checkbox-gap-filling-editor.component';
import {
  QuestionMapMathingEditorComponent
} from '../question-map-matching/question-map-mathing-editor/question-map-mathing-editor.component';
import {
  QuestionCheckChoiceParticipantComponent
} from '../question-check-choice/question-check-choice-participant/question-check-choice-participant.component';
import {
  QuestionMatchingParticipantComponent
} from '../question-matching/question-matching-participant/question-matching-participant.component';
import {QuestionTextParticipantComponent} from '../question-text/question-text-participant/question-text-participant.component';
import {QuestionScaleParticipantComponent} from '../question-scale/question-scale-participant/question-scale-participant.component';
import {
  QuestionSelectGapFillingParticipantComponent
} from '../question-select-gap-filling/question-select-gap-filling-participant/question-select-gap-filling-participant.component';
import {
  QuestionTextGapFillingParticipantComponent
} from '../question-text-gap-filling/question-text-gap-filling-participant/question-text-gap-filling-participant.component';
import {
  QuestionCheckboxGapFillingParticipantComponent
} from '../question-checkbox-gap-filling/question-checkbox-gap-filling-participant/question-checkbox-gap-filling-participant.component';
import {
  QuestionMapMatchingParticipantComponent
} from '../question-map-matching/question-map-mathing-participant/question-map-matching-participant.component';
import {
  QuestionCheckChoiceResultsComponent
} from '../question-check-choice/question-check-choice-results/question-check-choice-results.component';
import {QuestionMatchingResultsComponent} from '../question-matching/question-matching-results/question-matching-results.component';
import {QuestionTextResultsComponent} from '../question-text/question-text-results/question-text-results.component';
import {QuestionWordCloudResultsComponent} from '../question-word-cloud/question-word-cloud-results/question-word-cloud-results.component';
import {QuestionScaleResultsComponent} from '../question-scale/question-scale-results/question-scale-results.component';
import {
  QuestionSelectGapFillingResultsComponent
} from '../question-select-gap-filling/question-select-gap-filling-results/question-select-gap-filling-results.component';
import {
  QuestionTextGapFillingResultsComponent
} from '../question-text-gap-filling/question-text-gap-filling-results/question-text-gap-filling-results.component';
import {
  QuestionCheckboxGapFillingResultsComponent
} from '../question-checkbox-gap-filling/question-checkbox-gap-filling-results/question-checkbox-gap-filling-results.component';
import {
  QuestionMapMatchingResultsComponent
} from '../question-map-matching/question-map-matching-results/question-map-matching-results.component';
import {EventQuestion} from '../../../../../../model/EventQuestion';
import {
  tooltipQuestionTypeCheckboxGapFilling,
  tooltipQuestionTypeCheckChoice,
  tooltipQuestionTypeFileUpload,
  tooltipQuestionTypeMatching,
  tooltipQuestionTypeMatchingMap,
  tooltipQuestionTypePrioritization,
  tooltipQuestionTypeScale,
  tooltipQuestionTypeSelectGapFilling,
  tooltipQuestionTypeSequence,
  tooltipQuestionTypeTable,
  tooltipQuestionTypeOpenNumberEstimation,
  tooltipQuestionTypeText,
  tooltipQuestionTypeTextBalloons,
  tooltipQuestionTypeTextGapFilling
} from './lib/quiz-question-tooltip-lib';
import {QuestionTextEditorComponent} from '../question-text/question-text-editor/question-text-editor.component';
import {Quiz} from '../../quiz-model/quiz';
import {
  QuestionTextBalloonsEditorComponent
} from '../question-text-balloons/question-text-balloons-editor/question-text-balloons-editor.component';
import {
  QuestionTextBalloonsResultsComponent
} from '../question-text-balloons/question-text-balloons-results/question-text-balloons-results.component';
import {
  QuestionTextBalloonsParticipantComponent
} from '../question-text-balloons/question-text-balloons-participant/question-text-balloons-participant.component';
import {
  QuestionCheckChoiceParticipantLearnComponent
} from '../question-check-choice/question-check-choice-participant-learn/question-check-choice-participant-learn.component';
import {openTextAIAction, textBalloonsAIAction, textQuestionDisabledAIActions} from './lib/quiz-question-ai-lib';
import {
  questionAbsoluteView,
  questionPercentageView,
  textBalloonsCloudView,
  textBalloonsListView
} from './lib/quiz-question-extended-actions';
import {
  QuestionUploadFilesEditorComponent
} from '../question-upload-files/question-upload-files-editor/question-upload-files-editor.component';
import {
  QuestionUploadFilesParticipantComponent
} from '../question-upload-files/question-upload-files-participant/question-upload-files-participant.component';
import {
  QuestionUploadFilesResultsComponent
} from '../question-upload-files/question-upload-files-results/question-upload-files-results.component';
import {QuestionSequenceEditorComponent} from '../question-sequence/question-sequence-editor/question-sequence-editor.component';
import {
  QuestionSequenceParticipantComponent
} from '../question-sequence/question-sequence-participant/question-sequence-participant.component';
import {QuestionSequenceResultsComponent} from '../question-sequence/question-sequence-results/question-sequence-results.component';
import {
  QuestionPrioritizationEditorComponent
} from '../question-prioritization/question-prioritization-editor/question-prioritization-editor.component';
import {
  QuestionPrioritizationParticipantComponent
} from '../question-prioritization/question-prioritization-participant/question-prioritization-participant.component';
import {
  QuestionPrioritizationResultsComponent
} from '../question-prioritization/question-prioritization-results/question-prioritization-results.component';
import {
  QuestionUploadFilesAnswersPreviewComponent
} from '../question-upload-files/question-upload-files-answers-preview/question-upload-files-answers-preview.component';
import {UtilsService} from '../../../../../../core/utils.service';
import {
  QuestionCheckChoiceV2EditorComponent
} from '../question-check-choice-v2/question-check-choice-v2-editor/question-check-choice-v2-editor.component';
import {
  QuestionCheckChoiceV2ParticipantComponent
} from '../question-check-choice-v2/question-check-choice-v2-participant/question-check-choice-v2-participant.component';
import {
  QuestionCheckChoiceV2ResultsComponent
} from '../question-check-choice-v2/question-check-choice-v2-results/question-check-choice-v2-results.component';
import {TEXT_AI_OPTION_LIST} from '../../../../../../services/ai-text.service';
import {QuestionTableEditorComponent} from '../question-table/question-table-editor/question-table-editor.component';
import {QuestionTableParticipantComponent} from '../question-table/question-table-participant/question-table-participant.component';
import {QuestionTableResultsComponent} from '../question-table/question-table-results/question-table-results.component';
import {
  QuestionTableFilesAnswersPreviewComponent
} from '../question-table/question-table-files-answers-preview/question-table-files-answers-preview.component';
import { QuestionOpenTextEditorComponent } from '../question-open-text/question-open-text-editor/question-open-text-editor.component';
import { QuestionOpenNumberEstimationEditorComponent } from '../question-open-number-estimation/question-open-number-estimation-editor/question-open-number-estimation-editor.component';
import { QuestionOpenNumberEstimationParticipantComponent } from '../question-open-number-estimation/question-open-number-estimation-participant/question-open-number-estimation-participant.component';
import { QuestionOpenNumberEstimationResultsComponent } from '../question-open-number-estimation/question-open-number-estimation-results/question-open-number-estimation-results.component';
import { QuestionOpenTextParticipantComponent } from '../question-open-text/question-open-text-participant/question-open-text-participant.component';
import { QuestionOpenTextResultsComponent } from '../question-open-text/question-open-text-results/question-open-text-results.component';

export enum QUESTION_TYPE {
  CHECK = Constants.QTYPE_CHECK,
  CHOICE = Constants.QTYPE_CHOICE,
  TEXT = Constants.QTYPE_TEXT,
  WORD_CLOUD = Constants.QTYPE_WORD_CLOUD,
  MATCHING = Constants.QTYPE_MATCHING,
  SCALE = Constants.QTYPE_SCALE,
  SELECT_GAP_FILLING = Constants.QTYPE_SELECT_GAP_FILLING,
  TEXT_GAP_FILLING = Constants.QTYPE_TEXT_GAP_FILLING,
  CHECKBOX_GAP_FILLING = Constants.QTYPE_CHECKBOX_GAP_FILLING,
  MATCHING_MAP = Constants.QTYPE_MATCHING_MAP,
  TEXT_BALLOONS = Constants.QTYPE_TEXT_BALLON,
  FILE_UPLOAD = Constants.QTYPE_FILE_UPLOAD,
  SEQUENCE = Constants.QTYPE_SEQUENCE,
  PRIORITIZATION = Constants.QTYPE_PRIORITIZATION,
  CHECK_V2 = Constants.QTYPE_CHECK_V2,
  CHOICE_V2 = Constants.QTYPE_CHOICE_V2,
  OPEN_NUMBER_ESTIMATION = Constants.QTYPE_OPEN_NUMBER_ESTIMATION,
  TABLE = Constants.QTYPE_TABLE,
  OPEN_TEXT = Constants.QTYPE_OPEN_TEXT
}

export enum SUMMARY_SAVE_TYPE {
  MERGE = 'merge',
  OVERWRITE = 'overwrite',
  TEXT_COLLECTION = 'text_collection'
}

export const QUESTIONS_TYPES_INCLUDE_FILES = [QUESTION_TYPE.WORD_CLOUD, QUESTION_TYPE.MATCHING_MAP];

export const TEMPLATE_STORAGE_PATH = {
  [TEMPLATE_TYPE.USER_TEMPLATE]: 'app_users/{userId}/quiz-template/{templateId}',
  [TEMPLATE_TYPE.PUBLISHED_COMPANY]: 'quiz-template/{templateId}',
};

export interface IQuizTemplate {
  id?: string; // if new template id can be null
  title: string;
  description: string;
  icon: string;
  type: TEMPLATE_TYPE;
  template?: Quiz;
}

export interface IExtendedPoolAction {
  action: string;
  actionTitle: string;
  actionIcon: string;
  isDefault?: boolean;
  actionMethod?: (...params) => any;
  productionDisabled?: boolean;
  wrapToDivider?: boolean; // wrap menu item to mat-divider
}

export interface IExtQuizTemplate extends IQuizTemplate {
  findAnnotation: string;
}

export enum QUESTION_VIEW_MODE {
  LIST_VIEW = 'list-view',
  CLOUD_VIEW = 'cloud-view',
  ABSOLUTE = 'absolute',
  PERCENTAGE = 'percentage'
}

type TQuestionEditorComponentClass = typeof QuestionCheckChoiceEditorComponent | typeof QuestionScaleEditorComponent |
                                     typeof QuestionWordCloudEditorComponent | typeof QuestionMatchingEditorComponent |
                                     typeof QuestionSelectGapFillingEditorComponent | typeof QuestionTextGapFillingEditorComponent |
                                     typeof QuestionCheckboxGapFillingEditorComponent | typeof QuestionMapMathingEditorComponent |
                                     typeof QuestionTextEditorComponent | typeof QuestionTextBalloonsEditorComponent |
                                     typeof QuestionUploadFilesEditorComponent | typeof QuestionSequenceEditorComponent  |
                                     typeof QuestionPrioritizationEditorComponent | typeof QuestionCheckChoiceV2EditorComponent |
                                     typeof QuestionTableEditorComponent | typeof QuestionOpenTextEditorComponent |
                                     typeof QuestionOpenNumberEstimationEditorComponent;
type TQuestionParticipantComponentClass = typeof QuestionCheckChoiceParticipantComponent | typeof QuestionMatchingParticipantComponent |
                                     typeof QuestionTextParticipantComponent | typeof QuestionScaleParticipantComponent |
                                     typeof QuestionSelectGapFillingParticipantComponent |
                                     typeof QuestionTextGapFillingParticipantComponent |
                                     typeof QuestionCheckboxGapFillingParticipantComponent |
                                     typeof QuestionCheckChoiceParticipantLearnComponent | typeof QuestionUploadFilesParticipantComponent |
                                     typeof QuestionMapMatchingParticipantComponent | typeof QuestionTextBalloonsParticipantComponent |
                                     typeof QuestionSequenceParticipantComponent | typeof QuestionPrioritizationParticipantComponent |
                                     typeof QuestionCheckChoiceV2ParticipantComponent | typeof QuestionTableParticipantComponent |
                                     typeof QuestionOpenNumberEstimationParticipantComponent |  typeof QuestionOpenTextParticipantComponent;
type TQuestionResultsComponentClass = typeof QuestionCheckChoiceResultsComponent | typeof QuestionMatchingResultsComponent |
                                     typeof QuestionTextResultsComponent | typeof QuestionWordCloudResultsComponent |
                                     typeof QuestionScaleResultsComponent | typeof QuestionSelectGapFillingResultsComponent |
                                     typeof QuestionTextGapFillingResultsComponent | typeof QuestionCheckboxGapFillingResultsComponent |
                                     typeof QuestionMapMatchingResultsComponent | typeof QuestionTextBalloonsResultsComponent |
                                     typeof QuestionUploadFilesResultsComponent | typeof QuestionSequenceResultsComponent |
                                     typeof QuestionPrioritizationResultsComponent | typeof QuestionCheckChoiceV2ResultsComponent |
                                     typeof QuestionTableResultsComponent | typeof QuestionOpenNumberEstimationResultsComponent |
                                     typeof QuestionOpenTextResultsComponent;

type TQuestionAnswersPreviewComponentClass = typeof QuestionUploadFilesAnswersPreviewComponent |
                                             typeof QuestionTableFilesAnswersPreviewComponent;

export interface IQuestionComponent {
  /**
   * Class (html) form for editing a question
   */
  editorComponentClass: TQuestionEditorComponentClass;
  /**
   * Class (html) form for user answers to questions
   */
  participantComponentClass: TQuestionParticipantComponentClass;
  /**
   * Class (html) form for user answers to questions
   */
  participantLearnComponentClass?: TQuestionParticipantComponentClass;
  /**
   * Class (html) form of statistics/results of user responses to questions.
   */
  resultsComponentClass: TQuestionResultsComponentClass;
  /**
   * the name of the server method for creating a collection of summary information on users answers.
   */
  questionAnswersSummaryMethodName: (question?: EventQuestion) => string;
  /**
   * the way to save the results. usually the results are merged when saved,
   * but in some cases the results must be completely overwritten or saved in a separate collection.
   */
  questionAnswersSummarySaveType: (question?: EventQuestion) => SUMMARY_SAVE_TYPE;
  /**
   * showing or hiding other questions may depend on the answers to this question
   */
  canUseInDependency?: boolean;
  /**
   * this question type can use in registration questionnaire
   */
  canUseInRegistration?: boolean;
  /**
   * delay before send answer, default no delay.
   */
  sendAnswersDebounceTime?: number;
  /**
   * For types with use useCorrectAnswersOption equal true answers mode is determined
   * by the question setting EventQuestion.useCorrectAnswers
   *
   * values EventQuestion.useCorrectAnswers option:
   * FALSE: (for example “Simple survey”) - it doesn't matter what you answer, it’s just a survey.
   * TRUE: (for example "Knowledge test", etc.) - you must answer the question correctly.
   */
  useCorrectAnswersOption?: boolean;
  /**
   * the mode of loading correct answers for the presenter view.
   * if true, the responses are loaded depending on the settings of the question.showCorrectAnswers
   */
  loadCorrectAnswersByRequest?: boolean;
  /**
   * a method name for checking the correct answers.
   * if the method is not defined the question is working as answer mode only “Survey”
   * it can work in combination with the useCorrectAnswersOption setting
   */
  directFeedbackCheck?: string;
  /**
   * a method for creating a tooltip and cell string from user answers to display in the results table.
   */
  answersTooltip?: (question: EventQuestion, answers: any, languageParams?: ILanguageParams, utils?: UtilsService) => string;

  /**
   * Don't show MatTooltip popup on hover.
   */
  dontShowMatTooltipInDetailOnHover?: boolean;

  /**
   * used to create a complex form for viewing user answers in the "Detailed responses" dialog
   * (for an example, see the FILE_UPLOAD question type)
   */
  answersPreviewComponentClass?: TQuestionAnswersPreviewComponentClass;

  /**
   * parameters for working with AI actions
   * (for an example of how to use see the TEXT_BALLOONS question type)
   * (are currently under development)
   */
  aiAction?: (...params) => any;
  questionTypeAiActions?: IExtendedPoolAction[];
  checkDisabledAiActions?: (...params) => any;

  /**
   * List of additional actions for this type of question
   * (for example, switching the display of results in percentages or absolute values).
   */
  extendedQuestionTypeActions?: IExtendedPoolAction[];
  /**
   * name of the method for deleting objects from storage.
   * should be for those types of questions that use uploading files to storage in their answers.
   */
  questionDeleteUsersObjectFromStorageMethodName?: string;

  /**
   * disable question type for production build
   */
  productionDisabled?: boolean;

  /**
   * always enable if user has role from list
   */
  alwaysEnabledUserRoles?: string[];

  /**
   * disable question type for create new questions.
   */
  disabled?: boolean;

  /**
   * in toolbar show summary responses as users avatars list.
   */
  showToolbarOfResponsesAsUsersAvatarList?: boolean;

  /**
   * order index in <select> component or other lists
   */
  order: number;

  /**
   * determines which view mode is used by default when editing a question.
   * if the value is false or not specified, the default mode is "Participant"
   */
  questionEditorDefaultModePresenter?: boolean;

  /**
   * Shuffle multiple/single choice answers on poll start or try again
   */
  shuffleAnswers?: boolean;

  /**
   * Edit dialog width
   */
  questionEditorDialogWidth?: string;
}

export interface IQuestionComponentsMap {
  [type: string]: IQuestionComponent;
}

export const QUESTION_TYPES_COMPONENTS: IQuestionComponentsMap = {
  [QUESTION_TYPE.CHECK]: {
    editorComponentClass: QuestionCheckChoiceEditorComponent,
    participantComponentClass: QuestionCheckChoiceParticipantComponent,
    resultsComponentClass: QuestionCheckChoiceResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    canUseInDependency: true,
    canUseInRegistration: true,
    useCorrectAnswersOption: true,
    loadCorrectAnswersByRequest: true,
    directFeedbackCheck: 'checkFeedbackCheckChoice',
    answersTooltip: tooltipQuestionTypeCheckChoice,
    order: 150,
    disabled: false
  },
  [QUESTION_TYPE.CHOICE]: {
    editorComponentClass: QuestionCheckChoiceEditorComponent,
    participantComponentClass: QuestionCheckChoiceParticipantComponent,
    participantLearnComponentClass: QuestionCheckChoiceParticipantLearnComponent,
    resultsComponentClass: QuestionCheckChoiceResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    canUseInDependency: true,
    canUseInRegistration: true,
    useCorrectAnswersOption: true,
    loadCorrectAnswersByRequest: true,
    directFeedbackCheck: 'checkFeedbackCheckChoice',
    answersTooltip: tooltipQuestionTypeCheckChoice,
    order: 160,
    disabled: false
  },
  [QUESTION_TYPE.CHECK_V2]: {
    editorComponentClass: QuestionCheckChoiceV2EditorComponent,
    participantComponentClass: QuestionCheckChoiceV2ParticipantComponent,
    resultsComponentClass: QuestionCheckChoiceV2ResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    canUseInDependency: true,
    useCorrectAnswersOption: true,
    loadCorrectAnswersByRequest: true,
    directFeedbackCheck: 'checkFeedbackCheckChoice',
    answersTooltip: tooltipQuestionTypeCheckChoice,
    extendedQuestionTypeActions: [
      {action: QUESTION_VIEW_MODE.ABSOLUTE, actionTitle: 'questionnaire.detail.absolute_values',
        actionIcon: 'ti-number-12-small', actionMethod: questionAbsoluteView},
      {action: QUESTION_VIEW_MODE.PERCENTAGE, actionTitle: 'questionnaire.detail.percentage',
        actionIcon: 'ti-square-percentage', isDefault: true, actionMethod: questionPercentageView}
    ],
    order: 1,
    shuffleAnswers: true
  },
  [QUESTION_TYPE.CHOICE_V2]: {
    editorComponentClass: QuestionCheckChoiceV2EditorComponent,
    participantComponentClass: QuestionCheckChoiceV2ParticipantComponent,
    participantLearnComponentClass: QuestionCheckChoiceParticipantLearnComponent,
    resultsComponentClass: QuestionCheckChoiceV2ResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    canUseInDependency: true,
    useCorrectAnswersOption: true,
    loadCorrectAnswersByRequest: true,
    directFeedbackCheck: 'checkFeedbackCheckChoice',
    answersTooltip: tooltipQuestionTypeCheckChoice,
    extendedQuestionTypeActions: [
      {action: QUESTION_VIEW_MODE.ABSOLUTE, actionTitle: 'questionnaire.detail.absolute_values',
        actionIcon: 'ti-number-12-small', actionMethod: questionAbsoluteView},
      {action: QUESTION_VIEW_MODE.PERCENTAGE, actionTitle: 'questionnaire.detail.percentage',
        actionIcon: 'ti-square-percentage', isDefault: true, actionMethod: questionPercentageView}
    ],
    order: 2,
    shuffleAnswers: true
  },
  [QUESTION_TYPE.SCALE]: {
    editorComponentClass: QuestionScaleEditorComponent,
    participantComponentClass: QuestionScaleParticipantComponent,
    resultsComponentClass: QuestionScaleResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryScale',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    answersTooltip: tooltipQuestionTypeScale,
    order: 3
  },
  [QUESTION_TYPE.TEXT]: {
    editorComponentClass: QuestionTextEditorComponent,
    participantComponentClass: QuestionTextParticipantComponent,
    resultsComponentClass: QuestionTextResultsComponent,
    questionAnswersSummaryMethodName: (q) => q?.useCorrectAnswers ? 'questionSummaryCorrectText' : 'questionSummaryText',
    questionAnswersSummarySaveType: (q) => q?.useCorrectAnswers ? SUMMARY_SAVE_TYPE.OVERWRITE : SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    directFeedbackCheck: 'checkFeedbackText',
    answersTooltip: tooltipQuestionTypeText,
    useCorrectAnswersOption: true,
    canUseInRegistration: true,
    sendAnswersDebounceTime: 700,
    order: 4
  },
  [QUESTION_TYPE.WORD_CLOUD]: {
    editorComponentClass: QuestionWordCloudEditorComponent,
    participantComponentClass: QuestionTextParticipantComponent,
    resultsComponentClass: QuestionWordCloudResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryText',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    answersTooltip: tooltipQuestionTypeText,
    sendAnswersDebounceTime: 700,
    order: 5
  },
  [QUESTION_TYPE.MATCHING]: {
    editorComponentClass: QuestionMatchingEditorComponent,
    participantComponentClass: QuestionMatchingParticipantComponent,
    resultsComponentClass: QuestionMatchingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryMatching',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: 'checkFeedbackMatching',
    answersTooltip: tooltipQuestionTypeMatching,
    order: 6
  },
  [QUESTION_TYPE.SELECT_GAP_FILLING]: {
    editorComponentClass: QuestionSelectGapFillingEditorComponent,
    participantComponentClass: QuestionSelectGapFillingParticipantComponent,
    resultsComponentClass: QuestionSelectGapFillingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummarySelectGapFilling',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: 'checkFeedbackGapFillingMatchingMap',
    answersTooltip: tooltipQuestionTypeSelectGapFilling,
    questionEditorDefaultModePresenter: true,
    order: 7
  },
  [QUESTION_TYPE.TEXT_GAP_FILLING]: {
    editorComponentClass: QuestionTextGapFillingEditorComponent,
    participantComponentClass: QuestionTextGapFillingParticipantComponent,
    resultsComponentClass: QuestionTextGapFillingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryTextGapFilling',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.OVERWRITE,
    directFeedbackCheck: 'checkFeedbackTextGapFilling',
    answersTooltip: tooltipQuestionTypeTextGapFilling,
    questionEditorDefaultModePresenter: true,
    order: 8
  },
  [QUESTION_TYPE.CHECKBOX_GAP_FILLING]: {
    editorComponentClass: QuestionCheckboxGapFillingEditorComponent,
    participantComponentClass: QuestionCheckboxGapFillingParticipantComponent,
    resultsComponentClass: QuestionCheckboxGapFillingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryCheckChoice',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: 'checkFeedbackCheckboxGapFilling',
    answersTooltip: tooltipQuestionTypeCheckboxGapFilling,
    questionEditorDefaultModePresenter: true,
    order: 9
  },
  [QUESTION_TYPE.MATCHING_MAP]: {
    editorComponentClass: QuestionMapMathingEditorComponent,
    participantComponentClass: QuestionMapMatchingParticipantComponent,
    resultsComponentClass: QuestionMapMatchingResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryMatching',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: 'checkFeedbackGapFillingMatchingMap',
    answersTooltip: tooltipQuestionTypeMatchingMap,
    order: 10
  },
  [QUESTION_TYPE.TEXT_BALLOONS]: {
    editorComponentClass: QuestionTextBalloonsEditorComponent,
    participantComponentClass: QuestionTextBalloonsParticipantComponent,
    resultsComponentClass: QuestionTextBalloonsResultsComponent,
    questionAnswersSummaryMethodName: (q) => q?.useCorrectAnswers ? 'questionSummaryCorrectTextBalloons' : 'questionSummaryTextBalloons',
    questionAnswersSummarySaveType: (q) => q?.useCorrectAnswers ? SUMMARY_SAVE_TYPE.OVERWRITE : SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    answersTooltip: tooltipQuestionTypeTextBalloons,
    sendAnswersDebounceTime: 700,
    aiAction: textBalloonsAIAction,
    checkDisabledAiActions: textQuestionDisabledAIActions,
    questionTypeAiActions: [
      {action: TEXT_AI_OPTION_LIST.TEXT_SUMMARIZE, actionTitle: 'questionnaire.panel.text_balloon.create.summary',
        actionIcon: 'ti-clipboard-list ai-gradient'},
      {action: TEXT_AI_OPTION_LIST.CREATE_LYRICS, actionTitle: 'questionnaire.panel.text_balloon.create.lyric',
        actionIcon: 'ti-playlist ai-gradient'},
      // todo: Action does not have a handler. Disabled until details are clarified.
      {action: TEXT_AI_OPTION_LIST.GROUP_BY_COLOR, actionTitle: 'questionnaire.panel.text_balloon.group.by.colors',
        actionIcon: 'ti-circles ai-gradient', wrapToDivider: true, productionDisabled: true},
      // todo: Unfinished action that shows the list of user responses - duplicates Show users detail. Disabled until details are clarified.
      {action: null, actionTitle: 'questionnaire.panel.text_balloon.filter',
        actionIcon: 'ti-arrow-down-square ai-gradient', productionDisabled: true}
    ],
    extendedQuestionTypeActions: [
      {action: QUESTION_VIEW_MODE.LIST_VIEW, actionTitle: 'questionnaire.list_view',
        actionIcon: 'ti-layout-list', isDefault: true, actionMethod: textBalloonsListView},
      {action: QUESTION_VIEW_MODE.CLOUD_VIEW, actionTitle: 'questionnaire.cloud_view',
        actionIcon: 'ti-cloud', actionMethod: textBalloonsCloudView}
    ],
    showToolbarOfResponsesAsUsersAvatarList: true,
    order: 11,
    productionDisabled: true
  },
  [QUESTION_TYPE.OPEN_TEXT]: {
    editorComponentClass: QuestionOpenTextEditorComponent,
    participantComponentClass: QuestionOpenTextParticipantComponent,
    resultsComponentClass: QuestionOpenTextResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryOpenText',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    useCorrectAnswersOption: true,
    answersTooltip: tooltipQuestionTypeTextBalloons,
    aiAction: openTextAIAction,
    checkDisabledAiActions: textQuestionDisabledAIActions,
    directFeedbackCheck: 'checkFeedbackOpenText',
    questionTypeAiActions: [
      {action: TEXT_AI_OPTION_LIST.TEXT_SUMMARIZE, actionTitle: 'questionnaire.panel.text_balloon.create.summary',
        actionIcon: 'ti-clipboard-list ai-gradient'},
      {action: TEXT_AI_OPTION_LIST.CREATE_LYRICS, actionTitle: 'questionnaire.panel.text_balloon.create.lyric',
        actionIcon: 'ti-playlist ai-gradient'},
      // todo: Action does not have a handler. Disabled until details are clarified.
      {action: TEXT_AI_OPTION_LIST.GROUP_BY_COLOR, actionTitle: 'questionnaire.panel.text_balloon.group.by.colors',
        actionIcon: 'ti-circles ai-gradient', wrapToDivider: true, productionDisabled: true},
      // todo: Unfinished action that shows the list of user responses - duplicates Show users detail. Disabled until details are clarified.
      {action: null, actionTitle: 'questionnaire.panel.text_balloon.filter',
        actionIcon: 'ti-arrow-down-square ai-gradient', productionDisabled: true}
    ],
    extendedQuestionTypeActions: [
      {action: QUESTION_VIEW_MODE.LIST_VIEW, actionTitle: 'questionnaire.list_view',
        actionIcon: 'ti-layout-list', isDefault: true, actionMethod: textBalloonsListView},
      {action: QUESTION_VIEW_MODE.CLOUD_VIEW, actionTitle: 'questionnaire.cloud_view',
        actionIcon: 'ti-cloud', actionMethod: textBalloonsCloudView}
    ],
    questionEditorDialogWidth: '60vw',
    showToolbarOfResponsesAsUsersAvatarList: true,
    order: 11,
    productionDisabled: true
  },
  [QUESTION_TYPE.FILE_UPLOAD]: {
    editorComponentClass: QuestionUploadFilesEditorComponent,
    participantComponentClass: QuestionUploadFilesParticipantComponent,
    resultsComponentClass: QuestionUploadFilesResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryFileUpload',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.TEXT_COLLECTION,
    questionDeleteUsersObjectFromStorageMethodName: 'questionFileUploadDeleteStorageObjects',
    answersTooltip: tooltipQuestionTypeFileUpload,
    answersPreviewComponentClass: QuestionUploadFilesAnswersPreviewComponent,
    order: 12
  },
  [QUESTION_TYPE.SEQUENCE]: {
    editorComponentClass: QuestionSequenceEditorComponent,
    participantComponentClass: QuestionSequenceParticipantComponent,
    resultsComponentClass: QuestionSequenceResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummarySequence',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: 'checkFeedbackSequence',
    answersTooltip: tooltipQuestionTypeSequence,
    extendedQuestionTypeActions: [
      {action: QUESTION_VIEW_MODE.ABSOLUTE, actionTitle: 'questionnaire.detail.absolute_values',
        actionIcon: 'ti-123', actionMethod: questionAbsoluteView},
      {action: QUESTION_VIEW_MODE.PERCENTAGE, actionTitle: 'questionnaire.detail.percentage',
        actionIcon: 'ti-square-percentage', actionMethod: questionPercentageView, isDefault: true}
    ],
    order: 13
   },
  [QUESTION_TYPE.PRIORITIZATION]: {
    editorComponentClass: QuestionPrioritizationEditorComponent,
    participantComponentClass: QuestionPrioritizationParticipantComponent,
    resultsComponentClass: QuestionPrioritizationResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryPrioritization',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    answersTooltip: tooltipQuestionTypePrioritization,
    order: 14
  },
  [QUESTION_TYPE.OPEN_NUMBER_ESTIMATION]: {
    editorComponentClass: QuestionOpenNumberEstimationEditorComponent,
    participantComponentClass: QuestionOpenNumberEstimationParticipantComponent,
    resultsComponentClass: QuestionOpenNumberEstimationResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryOpenNumberEstimation',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    useCorrectAnswersOption: false,
    // directFeedbackCheck: checkFeedbackOpenNumberEstimation,
    answersTooltip: tooltipQuestionTypeOpenNumberEstimation,
    productionDisabled: true,
    order: 17
  },
  [QUESTION_TYPE.TABLE]: {
    editorComponentClass: QuestionTableEditorComponent,
    participantComponentClass: QuestionTableParticipantComponent,
    resultsComponentClass: QuestionTableResultsComponent,
    questionAnswersSummaryMethodName: () => 'questionSummaryTable',
    questionAnswersSummarySaveType: () => SUMMARY_SAVE_TYPE.MERGE,
    directFeedbackCheck: 'checkFeedbackTable',
    useCorrectAnswersOption: true,
    answersTooltip: tooltipQuestionTypeTable,
    answersPreviewComponentClass: QuestionTableFilesAnswersPreviewComponent,
    questionDeleteUsersObjectFromStorageMethodName: 'questionTableDeleteStorageObjects',
    extendedQuestionTypeActions: [
      {action: QUESTION_VIEW_MODE.ABSOLUTE, actionTitle: 'questionnaire.detail.absolute_values',
        actionIcon: 'ti-123', actionMethod: questionAbsoluteView},
      {action: QUESTION_VIEW_MODE.PERCENTAGE, actionTitle: 'questionnaire.detail.percentage',
        actionIcon: 'ti-square-percentage', actionMethod: questionPercentageView, isDefault: true}
    ],
    questionEditorDialogWidth: '60vw',
    dontShowMatTooltipInDetailOnHover: true,
    order: 18,
    productionDisabled: true,
    alwaysEnabledUserRoles: [Constants.ROLE_SUPERADMIN]
  }
};

export interface IValidated {
  validated: boolean;
  warning?: string;
}

export interface IDisabledAiAction {
  [TEXT_AI_OPTION_LIST.GROUP_BY_COLOR]?: boolean;
  disableAiGen: boolean;
}
