import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {TRIPLE} from '../../core/constants';

@Component({
  selector: 'app-confirm-dialog-three-button-component',
  templateUrl: './confirm-dialog-three-button.component.html',
  styleUrls: ['./confirm-dialog-three-button.component.scss']
})
export class ConfirmDialogThreeButtonComponent implements OnInit {
  readonly TRIPLE = TRIPLE;
  public title: string;
  public message: string;
  public customButtonTitle1: string;
  public customButtonTitle2: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogThreeButtonComponent>) {
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

}
