import {BehaviorSubject} from 'rxjs';
import {ILanguageParams} from '../../../../../core/constants';

export enum BACKGROUND_POSITION {
           // X    // Y
  CENTER = 'center',
  BOTTOM = 'center bottom',
  TOP = 'center top',
  LEFT = 'left center',
  RIGHT = 'right center',
  LEFT_TOP = 'left top',
  RIGHT_TOP = 'right top',
  LEFT_BOTTOM = 'left bottom',
  RIGHT_BOTTOM = 'right bottom'
}

export enum BACKGROUND_SIZE {
  CONTAIN = 'contain',
  COVER = 'cover'
}

export interface IPicture {
  id: string;
  src: string;
  lang?: string;
}

export interface IGallerySettings {
  backgroundPosition: BACKGROUND_POSITION;
  backgroundSize: BACKGROUND_SIZE;
}

export interface IPictureGallery {
  pictures: IPicture[];
  settings: IGallerySettings;
}

export interface IPictureGalleryDialogData extends IPictureGallery {
  languageParams$: BehaviorSubject<ILanguageParams>;
}

export interface IFollowMeData {
  index: number;
}

export const DEFAULT_SETTINGS: IGallerySettings = {
  backgroundPosition: BACKGROUND_POSITION.CENTER,
  backgroundSize: BACKGROUND_SIZE.CONTAIN
};
