import {Injectable} from '@angular/core';
import {SectionContent} from '../model/content/SectionContent';
import {firstValueFrom, pairwise, startWith, Subject, takeUntil} from 'rxjs';
import {ASSIGN_TO_SLOT_MODE, Constants} from '../core/constants';
import {EventsDataService} from './events-data.service';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {CommonService} from '../core/common.service';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {ClipboardService} from '../core/clipboard.service';
import {ContentService, ICopySectionResult} from './content.service';
import {TimeLineService} from './time-line.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TimeLineEmulator} from '../time-line-emulator/time-line-emulator';
import {ContentContainer} from '../model/content/ContentContainer';
import {CONTENT_PATH_TYPE} from '../model/content/AbstractContent';

const ASSIGN_PROCESS_TIME_NAME = 'processTime';

export interface IModuleChangesMap {[sectionId: string]: {
    section: SectionContent,
    contents: ContentContainer[]};
}

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  timeLineEmulator: TimeLineEmulator;

  constructor(private timelineService: TimeLineService,
              private dataService: EventsDataService,
              private clipboard: ClipboardService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private common: CommonService) {
    timelineService.event$.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]) => {
        if (timelineService.loginService.educationMode && prev?.eventId !== next?.eventId) {
          this.timelineService.educationViewDraftMode$.next(true);
        }
      });
  }

  async assignFreeSlotEducationSectionList(assignSectionId: string, assignSectionList: SectionTimeline[] | SectionContent[],
                                           assignMode: ASSIGN_TO_SLOT_MODE, contentService: ContentService) {
    const idMap: { [oldId: string]: string } = {}; // old -> new
    let firstNewId: string;
    await this.setAccessSlotAssignProcess(assignSectionId);
    this.timeLineEmulator = new TimeLineEmulator(this.timelineService, null,
      null, this.timelineService.utils, this.timelineService.currentUser, true, null, false);
    this.timeLineEmulator.refreshEmulatorTimeline(this.timelineService.event,
      this.timelineService.currentUser, true, this.timelineService.timelineSections);

    const getOrderIndex = () => incrementOrderIndex ? (currentOrderIndex += incrementOrderIndex) : null;

    const addSection = async (sectionTemplate: SectionTimeline, parentId) => {
      const durationValue = sectionTemplate.duration ?? 0;
      const originId = sectionTemplate.id;
      const s = new SectionContent({
        eventId: sectionTemplate.eventId,
        parentId: parentId,
        orderIndex: getOrderIndex(),
        expand: true,
        isPublic: true,
        education: false,
        status: Constants.SECTION_STATUS_OPEN,
        sectionTimeIsNotActive: false,
        duration: durationValue,
        durationFixed: true,
        durationFixedValue: durationValue,
        originId: originId
      });
      await this.setAccessSlotAssignProcess(assignSectionId);
      return this.dataService.addSectionContent(sectionTemplate.eventId, s)
        .then(newId => {
          idMap[sectionTemplate.id] = newId;
          firstNewId = firstNewId ?? newId;
          return this.setAccessSlotAssignProcess(assignSectionId);
        });
    };

    const addSectionChild = (sectionTemplate: SectionTimeline, parentId) => {
      return addSection(sectionTemplate, parentId).then(async () => {
        for (const child of sectionTemplate.items) {
          if (sectionList.find(o => o.id === child.id)) {
            const pId = idMap[child.parentId];
            await addSectionChild(child, pId);
          }
        }
      });
    };
    const params = this.calculateOrderIndexAndParent(assignSectionId, assignMode, assignSectionList.length);
    const incrementOrderIndex = params.incrementOrderIndex;
    let currentOrderIndex = params.startOrderIndex;
    const assignId = params.parentId;
    const sectionList: SectionTimeline[] = assignSectionList.map(s => cloneDeep(s))
      .sort(this.common.utils.comparator(Constants.ORDERINDEX));
    const educationSectionList = this.timelineService.educationSections;
    const sectionWithOutParent: SectionTimeline[] = [];
    for (const section of sectionList) {
      if (!sectionList.find(s => s.id === section.parentId)) {
        const rootId = this.timelineService.rootSection.id;
        let parentId = section.parentId;
        let parent: SectionTimeline;
        while (!(parent = sectionList.find(s => s.id === parentId)) && parentId !== rootId) {
          const ep = educationSectionList.find(es => es.id === parentId);
          parentId = ep.parentId;
        }
        if (!parent) {
          sectionWithOutParent.push(section);
        } else {
          section.parentId = parent.id;
          parent.items.push(section);
          parent.items = parent.items.sort(this.timelineService.utils.comparator(Constants.ORDERINDEX));
        }
      }
    }
    sectionWithOutParent.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    // create copy sections
    for (const s of sectionWithOutParent) {
      await addSection(s, assignId);
      for (const child of s.items) {
        if (sectionList.find(o => o.id === child.id)) {
          const parentId = idMap[child.parentId];
          await addSectionChild(child, parentId);
        }
      }
    }
    await this.removeAccessSlotAssignProcess(assignSectionId);
    if (!this.timelineService.isSlotMode) {
      if (!this.timelineService.sections.find(s => s.id === firstNewId)) {
        let unsub = new Subject();
        this.timelineService.sections$.pipe(takeUntil(unsub))
          .subscribe(value => {
            if (value.find(s => s.id === firstNewId)) {
              unsub.next(true);
              unsub.complete();
              unsub = null;
              this.router.navigate([], {queryParams: {sid: firstNewId}, relativeTo: this.activatedRoute});
            }
          });
      } else {
        await this.router.navigate([], {queryParams: {sid: firstNewId}, relativeTo: this.activatedRoute});
      }
    }
    this.timeLineEmulator = null;
  }

  private unsubscribeSubject(subject: Subject<any>) {
    subject.next(true);
    subject.complete();
    subject = null;
  }

  private calculateOrderIndexAndParent(assignSectionId: string,
                                      assignMode: ASSIGN_TO_SLOT_MODE,
                                      assignCount: number): {parentId, startOrderIndex, incrementOrderIndex} {
    if (assignMode === ASSIGN_TO_SLOT_MODE.ABOVE /*before*/) {
      const startIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed().findIndex(o => o.id === assignSectionId);
      const startOrderIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed()[startIndex - 1].orderIndex;
      const endOrderIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed()[startIndex]?.orderIndex;
      const incrementOrderIndex = (endOrderIndex - startOrderIndex) / (assignCount + 1);
      const parentId = this.timeLineEmulator.planeFullListContent[assignSectionId].parentId;
      return {startOrderIndex, incrementOrderIndex, parentId};
    }
    if (assignMode === ASSIGN_TO_SLOT_MODE.WITHIN /*within*/) {
      const chTree = this.timeLineEmulator.childTreeInLineList(assignSectionId);
      const start = chTree[chTree.length - 1];
      const startIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed().findIndex(o => o.id === start.id);
      const endOrderIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed()[startIndex + 1]?.orderIndex;
      const incrementOrderIndex = endOrderIndex ? ((endOrderIndex - start.orderIndex) / (assignCount + 1)) : null;
      const parentId = this.timeLineEmulator.planeFullListContent[assignSectionId].id;
      return {startOrderIndex: start.orderIndex, incrementOrderIndex, parentId};
    }
    if (assignMode === ASSIGN_TO_SLOT_MODE.BELOW /*after*/) {
      const chTree = this.timeLineEmulator.childTreeInLineList(assignSectionId);
      const start = chTree[chTree.length - 1];
      const startIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed().findIndex(o => o.id === start.id);
      const endOrderIndex = this.timeLineEmulator.planeListContentSortedWithoutFixed()[startIndex + 1]?.orderIndex;
      const incrementOrderIndex = endOrderIndex ? ((endOrderIndex - start.orderIndex) / (assignCount + 1)) : null;
      const parentId = this.timeLineEmulator.planeFullListContent[assignSectionId].parentId;
      return {startOrderIndex: start.orderIndex, incrementOrderIndex, parentId};
    }
  }

  /**
   * use in loginService.educationMode = true
   * @param listIds
   * @param resultList
   * @private
   */
  private calculate(listIds: string[], resultList: {sectionId: string, clazz: number, self: number, llp: number}[]) {
    for (const id of listIds) {
      const s = this.timelineService.getSectionContent(id);
      const dSum = s.items.reduce((accum, item) => {
        const sr = resultList.find(o => o.sectionId === item.id);
        if (!sr) {
          accum.clazz += (!item.clazz ? 0 : item.clazz);
          accum.self += (!item.self ? 0 : item.self);
          accum.llp += (!item.llp ? 0 : item.llp);
        } else {
          accum.clazz += sr.clazz;
          accum.self += sr.self;
          accum.llp += sr.llp;
        }
        return accum;
      }, {clazz: 0, self: 0, llp: 0});
      resultList.push({sectionId: id, clazz: dSum.clazz, self: dSum.self, llp: dSum.llp});
    }
  }

  /**
   * use in loginService.educationMode = true
   * @param updateList
   * @private
   */
  private updateCalculateResult(updateList: {sectionId: string, clazz: number, self: number, llp: number}[]) {
    const vSections: {sectionId: string, fieldName: string, value: number}[] = [];
    updateList.forEach(value => {
      vSections.push({sectionId: value.sectionId, fieldName: 'clazz', value: value.clazz});
      vSections.push({sectionId: value.sectionId, fieldName: 'self', value: value.self});
      vSections.push({sectionId: value.sectionId, fieldName: 'llp', value: value.llp});
    });
    const rootObj = updateList.find(o => o.sectionId === this.timelineService.rootSection.id);
    const vModule: {moduleId: string, fieldName: string, value: number}[] = [];
    if (rootObj) {
      vModule.push({moduleId: this.timelineService.event.eventId, fieldName: 'clazz', value: rootObj.clazz});
      vModule.push({moduleId: this.timelineService.event.eventId, fieldName: 'self', value: rootObj.self});
      vModule.push({moduleId: this.timelineService.event.eventId, fieldName: 'llp', value: rootObj.llp});
    }
    return this.dataService.updateSectionAndModulesFieldsValues(this.timelineService.event.eventId, vSections, vModule);
  }

  /**
   * use in loginService.educationMode = true
   * @param sectionId
   * @param fieldName
   * @param duration
   */
  calculateEducationDurationFieldValueChange(sectionId: string, fieldName: string, duration: number) {
    const sub = new Subject();
    this.timelineService.planeList.pipe(takeUntil(sub)).subscribe(list => {
      const s = this.timelineService.getSectionContent(sectionId);
      if (s && s[fieldName] === duration) {
        this.unsubscribeSubject(sub);
        const vSections: {sectionId: string, fieldName: string, value: number}[] = [];
        const parentsIds = this.timelineService.getSectionParentsByHierarchy(s);
        for (const parentId of parentsIds) {
          const p = this.timelineService.getSectionContent(parentId);
          const dSum = p.items.reduce((accum, item) => {
            const sr = vSections.find(o => o.sectionId === item.id);
            if (!sr) {
              accum += (!item[fieldName] ? 0 : item[fieldName]);
            } else {
              accum += sr.value;
            }
            return accum;
          }, 0);
          vSections.push({sectionId: parentId, fieldName: fieldName, value: dSum});
        }
        const rootObj = vSections.find(o => o.sectionId === this.timelineService.rootSection.id);
        const vModule: {moduleId: string, fieldName: string, value: number}[] = [];
        if (rootObj) {
          vModule.push({moduleId: this.timelineService.event.eventId, fieldName: rootObj.fieldName, value: rootObj.value});
        }
        return this.dataService.updateSectionAndModulesFieldsValues(this.timelineService.event.eventId, vSections, vModule);
      }
    });
  }

  /**
   * use in loginService.educationMode = true
   * @param deletedSectionId
   * @param deletedSectionParentId
   */
  calculateEducationDurationFieldsValuesAfterDelete(deletedSectionId: string, deletedSectionParentId: string) {
    const sub = new Subject();
    this.timelineService.planeList.pipe(takeUntil(sub)).subscribe(list => {
      const so = list[deletedSectionId];
      if (!so) {
        this.unsubscribeSubject(sub);
        const s = this.timelineService.getSectionContent(deletedSectionParentId);
        const parentsIds = this.timelineService.getSectionParentsByHierarchy(s);
        parentsIds.splice(0, 0, deletedSectionParentId);
        const updObjects: {sectionId: string, clazz: number, self: number, llp: number}[] = [];
        this.calculate(parentsIds, updObjects);
        // update fields
        this.updateCalculateResult(updObjects);
      }
    });
  }

  /**
   * use in loginService.educationMode = true
   * @param cutSection
   */
  calculateEducationDurationFieldsValuesAfterCutPaste(cutSection: SectionContent) {
    const sub = new Subject();
    this.timelineService.planeList.pipe(takeUntil(sub)).subscribe(list => {
      const s = this.timelineService.getSectionContent(cutSection.id);
      if (s && cutSection.parentId !== s.parentId) {
        this.unsubscribeSubject(sub);
        // dstParent
        let parent = this.timelineService.getSectionContent(s.parentId);
        let parentsIds = this.timelineService.getSectionParentsByHierarchy(parent);
        parentsIds.splice(0, 0, parent.id);
        const updObjects: {sectionId: string, clazz: number, self: number, llp: number}[] = [];
        this.calculate(parentsIds, updObjects);
        // srcParent
        parent = this.timelineService.getSectionContent(cutSection.parentId);
        parentsIds = this.timelineService.getSectionParentsByHierarchy(parent);
        parentsIds.splice(0, 0, cutSection.parentId);
        this.calculate(parentsIds, updObjects);
        // update fields
        this.updateCalculateResult(updObjects);
      } else if (!s) {
        this.unsubscribeSubject(sub);
      }
    });
  }

  /**
   * use in loginService.educationMode = true
   * @param copySection
   */
  calculateEducationDurationFieldsValuesAfterCopyPaste(copySection: SectionContent) {
    const sub = new Subject();
    this.clipboard.copySectionListResult$.pipe(takeUntil(sub)).subscribe(listInfo => {
      let copyInf: {newId: string, oldId: string, parentId: string} = null;
      if (!isEmpty(listInfo) && !isEmpty(copyInf = listInfo.find(o => o.oldId === copySection.id))) {
        this.timelineService.planeList.pipe(takeUntil(sub)).subscribe(list => {
          const s = this.timelineService.getSectionContent(copyInf.newId);
          if (s) {
            this.unsubscribeSubject(sub);
            const parent = this.timelineService.getSectionContent(copyInf.parentId);
            const parentsIds = this.timelineService.getSectionParentsByHierarchy(parent);
            parentsIds.splice(0, 0, copyInf.parentId);
            const updObjects: {sectionId: string, clazz: number, self: number, llp: number}[] = [];
            this.calculate(parentsIds, updObjects);
            // update fields
            this.updateCalculateResult(updObjects);
          }
        });
      } else {
        this.unsubscribeSubject(sub);
      }
    });
  }

  getAccessSlotAssignProcess(sectionId: string) {
    return this.dataService.getLockedSectionRef(this.timelineService.event.eventId, sectionId).child(ASSIGN_PROCESS_TIME_NAME).ref;
  }

  private setAccessSlotAssignProcess(sectionId: string) {
    return this.dataService.getLockedSectionRef(this.timelineService.event.eventId, sectionId)
      .ref.set({[ASSIGN_PROCESS_TIME_NAME]: new Date().getTime()});
  }

  removeAccessSlotAssignProcess(sectionId: string) {
    return this.dataService.getLockedSectionRef(this.timelineService.event.eventId, sectionId).remove();
  }

  async getModuleContentsChanges() {
    const result: IModuleChangesMap = {};
    for (const section of this.timelineService.planeListContentSortedWithoutFixedAsSectionArray()) {
      const list = await this.dataService.getSectionContentsByCondition(this.timelineService.event.eventId, section.id,
        q => q.where('type', '==', Constants.CONTENT_TYPE_CONTENT_CONTAINER), CONTENT_PATH_TYPE.DRAFT);
      const contents: ContentContainer[] = list
        .filter((cn: ContentContainer) => (cn.dirty && !cn.releaseVersion) || (!cn.dirty && !cn.releaseVersion) ||
          (cn.dirty && cn.releaseVersion))
        .map(cn => this.dataService.createTypedContent(cn));
      if (contents.length) {
        result[section.id] = {section, contents: contents.sort(this.common.utils.comparator('orderIndex'))};
      }
    }
    return result;
  }

  /**
   * Return sections list without deleted, planned, draft, archive. Exclude sections with children hierarchy.
   * @param sections
   */
  buildSectionReference(sections: SectionContent[]) {
    const archiveSections = sections.filter(s => s.archive);
    const deletedSections = sections.filter(s => s.deleted);
    const draftPlannedSection = sections.filter(s => s.status !== Constants.SECTION_STATUS_OPEN);
    const excludedIds: string[] = [];
    for (const section of archiveSections) {
      const children = this.common.utils.getSectionChildrenTree(section, sections);
      children.forEach(s => excludedIds.push(s.id));
    }
    for (const section of deletedSections) {
      const children = this.common.utils.getSectionChildrenTree(section, sections);
      children.forEach(s => excludedIds.push(s.id));
    }
    for (const section of draftPlannedSection) {
      const children = this.common.utils.getSectionChildrenTree(section, sections);
      children.forEach(s => excludedIds.push(s.id));
    }
    return sections.filter(s => !excludedIds.includes(s.id)).sort(this.common.utils.comparator(Constants.ORDERINDEX));
  }

}
