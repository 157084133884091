import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {UtilsService} from '../core/utils.service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {ClientConfig} from '../model/ClientConfig';
import {firstValueFrom, map, Observable} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFireDatabase} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class ClientApiService extends StdApiService {
  protected API_URL: string;

  constructor(
    private http: HttpClient,
    protected store: Store<fromRoot.State>,
    protected angularFireDatabase: AngularFireDatabase,
    protected aFirestore: AngularFirestore,
    protected utils: UtilsService,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/clientApi/v3/';
  }

  createDefaultClient(client: ClientConfig): Promise<string> {
    const obj = this.toObject(client);
    return this.http.post(this.API_URL + 'createDefaultClient', obj, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  createClient(client: ClientConfig): Promise<string> {
    const id = this.angularFireDatabase.createPushId();
    const obj = this.toObject({id: id, ...client});
    return this.http.post(this.API_URL + 'createClient', obj, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  updateClient(client: ClientConfig): Promise<any> {
    const obj = this.toObject(client);
    return this.http.post(this.API_URL + 'updateClient', obj)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getClientsDto(): Promise<ClientConfig[]> {
    return this.http.post<ClientConfig[]>(this.API_URL + 'getClients', null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getAllowedClientsDto(): Promise<ClientConfig[]> {
    return this.http.post<ClientConfig[]>(this.API_URL + 'getAllowedClients', null)
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

  getClients(): Observable<ClientConfig[]> {
    return this.aFirestore.collection('client_data')
      .valueChanges({idField: 'id'})
      .pipe(
        map(res => this.mapArray(res, it => new ClientConfig(it)))
      );
  }

  getClientPromise(client_id: string): Promise<ClientConfig> {
    return firstValueFrom(this.aFirestore.collection('client_data').doc(client_id)
      .valueChanges({idField: 'id'})
      .pipe(map(res => this.mapObject(res, (it) => new ClientConfig(it)))));
  }

  checkAvailabilityLink(link: string): Promise<string> {
    const obj = {
      value: link
    };
    return this.http.post(this.API_URL + 'checkAvailabilityLink', obj, {responseType: 'text'})
      .toPromise()
      .catch((e) => this.catchServerError(e));
  }

}
