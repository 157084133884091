import {ChangeDetectorRef, Component, HostListener, Inject, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Constants, EMULATE_RESULT, EVENT_DATE_MODE, MANAGE_TIME_ROW_TYPE} from '../../core/constants';
import {ROW_TIME_TYPE, RowDatesObject, TimeEditValue, TimePointMap} from '../../model/event-mode/RowDatesObject';
import {ManageTimeError, ManageTimeService} from '../../services/manage-time.service';
import {TimeLineService} from '../../services/time-line.service';
import {CommonService} from '../../core/common.service';
import {ContentService} from '../../services/content.service';
import {EventsDataService} from '../../services/events-data.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {clone, isEmpty, cloneDeep} from 'lodash';
import {StdComponent} from '../../core/std-component';
import {PlaneContent} from '../../model/content/PlaneContent';
import * as ui from '../../actions/ui';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Component({
  selector: 'app-edit-duration-dialog',
  templateUrl: './edit-duration-dialog.component.html',
  styleUrls: ['./edit-duration-dialog.component.scss']
})
export class EditDurationDialogComponent extends StdComponent implements OnInit, OnDestroy {

  readonly EVENT_DATE_MODE = EVENT_DATE_MODE;
  readonly ROW_TIME_TYPE = ROW_TIME_TYPE;
  readonly marginLeftList = [0, '35px', '20px', '19px', '18px', '21px', '20px', '24px', '22px', '11px', '29px', '29px'];
  readonly intervalList = [0, 9, 18, 27, 36, 45, 54, 63, 72, 81, 90, 99];
  readonly intervalDurationList = [0, 5, 10, 15, 20, 30, 45, 60, 90, 120, 240, 480];
  readonly intervalValueList = [
    0, 3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39,
    42, 45, 48, 51, 54, 57, 60, 63, 66, 69, 72, 75, 78, 81,
    84, 87, 90, 93, 96, 99];
  readonly upMax = ['8h+', '22px', 'orange', 'rgba(0,0,0,.26)'];
  readonly labelList = ['0', '5m', '10m', '15m', '20m', '30m', '45m', '1h', '1h 30m', '2h', '4h', '8h'];
  readonly durationList = [
    0, 1, 3, 5, 7, 9, 10, 12, 14, 15, 16, 18, 20, 24,
    27, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100, 110, 120,
    160, 200, 240, 350, 400, 480];

  editRowObject: RowDatesObject;
  editValues: TimeEditValue;
  editValuesSrc: TimeEditValue;
  okDisabled = false;
  forceSave = false;
  errorTimePoints: TimePointMap;
  warningTimePoints: TimePointMap;
  showDurationStrValue = false;
  durationSliderValue = 0;
  useUpMax = false;
  groupTaskList: Array<RowDatesObject[]>;
  groupTaskMode = false;
  groupTaskSelectedSectionId: string[] = [];
  simpleMode = false;
  durationTitle: string;
  inputOnlyNumber = false;

  @ViewChild('duration') durationInputControl;

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === Constants.ESCAPE) {
      this.onNoClick();
    }
  }

  constructor(protected injector: Injector,
              private manageTimeService: ManageTimeService,
              public timelineService: TimeLineService,
              private common: CommonService,
              private contentService: ContentService,
              private eventsDataService: EventsDataService,
              private changeDetector: ChangeDetectorRef,
              private store: Store<fromRoot.State>,
              public dialogRef: MatDialogRef<EditDurationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    dialogRef.addPanelClass('timeline');
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    this.inputOnlyNumber = data.inputOnlyNumber;
    this.simpleMode = data.simpleMode;
    this.durationTitle = data.durationTitle;
    if (this.simpleMode) {
      this.editValues = {locked: true, timeValue: data.duration, timeType: ROW_TIME_TYPE.DURATION};
      this.durationToInterval(this.editValues.timeValue);
    } else if (!isEmpty(data.rowObject) && isEmpty(data.groupTaskList)) {
      this.editRowObject = data.rowObject;
      this.editValues = data.editValues;
      this.editValuesSrc = clone(this.editValues);
      this.durationToInterval(this.editValues.timeValue);
    } else if (isEmpty(data.rowObject) && !isEmpty(data.groupTaskList)) {
      this.groupTaskMode = true;
      this.groupTaskList = data.groupTaskList.map(o => cloneDeep(o));
      this.editValuesSrc = {timeValue: 0, locked: true, timeType: ROW_TIME_TYPE.DURATION};
      this.editValues = {timeValue: 0, locked: true, timeType: ROW_TIME_TYPE.DURATION};
      this.durationToInterval(0);
      for (const list of this.groupTaskList) {
        for (const obj of list.filter(o => o.rowType === MANAGE_TIME_ROW_TYPE.SECTION)) {
          if (obj.selected) {
            obj.selected = false;
            if (obj.sectionTimeIsNotActive) {
              continue;
            }
            this.groupTaskSelectedSectionId.push(obj.sectionId);
          }
        }
      }
    } else {
      this.onNoClick();
    }
  }

  get editValuesLocked() {
    return !this.editValues.locked;
  }

  set editValuesLocked(value) {
    this.editValues.locked = !value;
  }

  /**
   * @param duration in minutes
   */
  durationToInterval(duration) {
    const dIndex = this.durationList.findIndex(el => duration <= el);
    this.useUpMax = false;
    if (dIndex > -1) {
      this.durationSliderValue = this.intervalValueList[dIndex];
    } else if (duration > 480) {
      this.durationSliderValue = this.intervalValueList[this.intervalValueList.length - 1];
      this.useUpMax = true;
    }
  }

  intervalToDuration(interval) {
    const iIndex = this.intervalList.findIndex(el => interval === el);
    this.useUpMax = false;
    if (iIndex > -1) {
      this.editValues.timeValue = this.intervalDurationList[iIndex];
      this.showDurationStrValue = false;
      this.durationInputControl.nativeElement.value = this.getDurationStrValue(null);
      this.parseInputValue(this.durationInputControl.nativeElement);
    }
  }

  ngOnInit(): void {
    if (!this.simpleMode) {
      this.manageTimeService.getCheckResultTimePoint.pipe(this.takeUntilAlive())
        .subscribe(checkError => {
          this.okDisabled = !checkError.noError;
        });
    }
  }

  onDestroy() {
    super.onDestroy();
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onOkClick(): void {
    if (!this.editValues.locked && !this.editValuesSrc.locked) {
      this.dialogRef.close();
    } else {
      const result = !this.simpleMode ?
        {editRowObject: this.editRowObject,
         editValues: this.editValues} :
        {duration: this.editValues.timeValue};
      this.dialogRef.close(result);
    }
  }

  onNoClick(): void {
    this.manageTimeService.resetErrorValues.next(true);
    this.dialogRef.close();
  }

  checkTimePointValue() {
    if (!this.editValues.locked) {
      return;
    }
  }

  set durationInputValue(value) {
    const val = value ? (value.toString().replace(/[^0-9]*/g, '')) : null;
    this.editValues.timeValue = Number(val);
  }

  get durationInputValue() {
    if (typeof this.editValues.timeValue !== 'number') {
      return null;
    } else {
      return this.editValues.timeValue;
    }
  }

  getDurationStrValue(inputControl) {
    if (!this.showDurationStrValue) {
      this.showDurationStrValue = true;
      if (!this.inputOnlyNumber) {
        return this.common.utils.millisTo_YY_MM_DD_hh_mm_Short(this.editValues.timeValue * Constants.ONE_MINUTE_MS, true);
      } else {
        return (this.editValues.timeValue !== null && this.editValues.timeValue !== undefined) ? this.editValues.timeValue.toString() : '0';
      }
    } else {
      if (!this.inputOnlyNumber) {
        inputControl.value = inputControl.value ? inputControl.value.toString().replace(/[^0-9a-zA-Z\s]*/g, '') : null;
        return inputControl.value;
      } else {
        inputControl.value = inputControl.value ? inputControl.value.toString().replace(/[^0-9]*/g, '') : null;
        return inputControl.value;
      }
    }
  }

  checkDuration(event, inputControl) {
    if (event instanceof KeyboardEvent && event.code.toLowerCase().includes('enter')) this.onOkClick();
    if (event instanceof KeyboardEvent && event.code &&
      !(event.code.toLowerCase().includes('key') || event.code.toLowerCase().includes('digit') ||
        Constants.DIGIT_NUMPAD_KEY.includes(event.code) ||
        event.code.toLowerCase().includes('backspace') || event.code.toLowerCase().includes('delete'))) {
      return;
    }
    this.parseInputValue(inputControl);
    return;
  }

  parseInputValue(inputControl) {
    const dStr = inputControl.value ? inputControl.value.replace(/-/g, '') : inputControl.value;
    const d = this.common.utils.parseDuration(dStr);
    if (!Number.isNaN(d) && (!!d || d === 0)) {
      this.okDisabled = false;
      this.editValues.timeValue = d;
      if (this.inputOnlyNumber) {
        return;
      }
      this.durationToInterval(d);
      if (!this.groupTaskMode && !this.simpleMode) {
        const tp = this.editRowObject.getTimePoint(this.editRowObject.sectionId);
        this.manageTimeService.runCheckTimePoint.next({
          timeValue: this.editValues.timeValue, timePoint: tp, rowTimeType: ROW_TIME_TYPE.DURATION
        });
      } else if (!this.simpleMode) {
        this.checkList();
      }
    } else {
      this.okDisabled = true;
    }
  }

  changeIntervalSlider(event) {
    this.intervalToDuration(event.value);
  }

  checkList() {
    this.manageTimeService.resetErrorValues.next(true);
    let checkError: ManageTimeError =  {noError: true, errorTimePoints: {}, warningTimePoints: {}, text: null, forceMarkError: false};
    const changeList: PlaneContent[] = [];
    this.groupTaskSelectedSectionId.forEach((id) => {
      const ps = cloneDeep(this.timelineService.planeListContent[id]);
      ps.sectionContent.duration = this.editValues.timeValue;
      ps.sectionContent.durationFixed = true;
      ps.sectionContent.durationFixedValue = this.editValues.timeValue;
      changeList.push(ps);
    });
    const emResult = this.contentService.emulateAnyListChangeDurationProcess(changeList,
      this.timelineService.timelineEvent, (e) => checkError = e);
    if (emResult === EMULATE_RESULT.STOP || !checkError.noError) {
      this.manageTimeService.resetErrorValues.next(checkError.errorTimePoints);
      this.okDisabled = true;
    } else {
      this.okDisabled = false;
    }
  }
}
