import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, from, Observable, switchMap} from 'rxjs';
import {LoginService} from '../login/login.service';
import {UtilsService} from '../core/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpInterceptor implements HttpInterceptor {

  env;

  constructor(protected auth: LoginService,
              private utils: UtilsService) {
    this.env = this.utils.getEnv();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.env.BACKEND_BASE)) {
      const currentTime = new Date().getTime();
      let refreshTime = 0;
      const authToken = this.auth.getAuthToken();
      const jwt = this.utils.parseJwt(authToken);
      if (jwt && jwt.exp) {
        refreshTime = (jwt.exp * 1000) - (10 * 60 * 1000);
      }

      if (refreshTime - currentTime < 0) {
        return from(this.auth.refreshToken(true))
          .pipe(
            switchMap((token: string) => {
              return next.handle(this.createRequest(req, token));
            })
          );
      } else {
        return next.handle(this.createRequest(req, authToken))
          .pipe(
            catchError((error: HttpErrorResponse) => {
              return from(this.auth.refreshToken(true))
                .pipe(
                  switchMap((token: string) => {
                    return next.handle(this.createRequest(req, token));
                  })
                );
            })
          );
      }
    } else {
      return next.handle(req);
    }
  }

  createRequest(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'TL-CLIENT-VERSION': this.env.version
      }
    });
  }

}
