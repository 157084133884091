import {Injectable} from '@angular/core';
import {CHANGE_LOG_VALUE_TYPE, StdApiService} from './std-api-service';
import {LoginService} from '../login/login.service';
import {Event} from '../model/Event';
import {LoggerService} from '../core/logger.service';
import {UtilsService} from '../core/utils.service';
import {catchError, map} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {arrayUnion} from '@angular/fire/firestore';
import {Constants} from '../core/constants';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {RecordingStartRequest, RecordingTimestamp, UserAvatar} from '../model/recording';
import {StorageApiService} from './storage-api.service';

@Injectable()
export class RecordTimestampService extends StdApiService {

  constructor(protected aFirestore: AngularFirestore,
              protected store: Store<fromRoot.State>,
              protected logger: LoggerService,
              protected auth: LoginService,
              protected utils: UtilsService,
              protected storage: AngularFireStorage,
              private storageApiService: StorageApiService
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
  }

  getTimelineCollectionPath() {
    return !this.auth.educationMode ? Event.DB_TIMELINE_PATH : Event.DB_MODULE_TIMELINE_PATH;
  }

  private async getRecordingContentTitle(object: RecordingTimestamp) {
    const splitPath = object.gcpPath.split('/');
    const mainCollection = splitPath[1];
    const mainId = splitPath[2];
    const mainRef = this.afs.collection(mainCollection).doc(mainId).collection('timeline');
    const contentRef = !object.contentId ? mainRef.doc(object.sectionId).ref :
      mainRef.doc(object.sectionId).collection('contents').doc(object.contentId).ref;
    return await contentRef.get().then(d => d.exists ? d.get('title') : null);
  }

  addRecordingContentChange(eventId: string, ownerStreamSectionId: string, sectionId: string, contentId: string,
                            recordingStart: number, userAvatar: UserAvatar) {
    const obj = {sectionId, contentId, userAvatar, timestamp: Date.now(), type: 'NEW_CONTENT'};
    return this.afs.collection('event_recording')
      .doc(ownerStreamSectionId)
      .set({
        [recordingStart]: {
          timestamps: arrayUnion(obj),
          processed: false
        },
        eventId
      }, {merge: true});
  }

  addRecordingMetadata(eventId: string, ownerStreamSectionId: string, metadataType: string, recordingStart: number, extraPropsObj = {}) {
    const obj = {timestamp: Date.now(), type: metadataType, ...extraPropsObj};
    return this.afs.collection('event_recording')
      .doc(ownerStreamSectionId)
      .set({
        [recordingStart]: {
          timestamps: arrayUnion(obj),
          processed: false
        },
        eventId
      }, {merge: true});
  }

  setRecordingStart(ownerStreamSectionId: string, model: RecordingStartRequest) {
    return this.afs.collection('event_recording')
      .doc(ownerStreamSectionId)
      .collection('started')
      .doc(model.recordingStart.toString())
      .set(model);
  }

  getRecordingTimestamps(eventId: string) {
    return this.afs.collection(this.getTimelineCollectionPath())
      .doc(eventId)
      .collection('timestamps')
      .snapshotChanges()
      .pipe(
        this.log('getRecordingTimestamps'),
        map(data => {
          return data.map((item: any) => {
            return {
              id: item.payload.doc.id,
              ...item.payload.doc.data()
            };
          });
        }),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  async renameRecordingTimestamp(eventId: string, id: string, name: string) {
    const docRef = this.afs.collection(this.getTimelineCollectionPath()).doc(eventId).collection('timestamps').doc(id).ref;
    const doc = (await docRef.get()).data();
    return this.afs.collection(this.getTimelineCollectionPath())
      .doc(eventId)
      .collection('timestamps')
      .doc(id)
      .set({name}, {merge: true})
      .then(async () => {
        await this.saveChangeLogValue(eventId, {
          sourceId: doc.contentId ?? doc.sectionId,
          sourceType: doc.contentId ? Constants.CONTENT_TYPE_CONTENT_CONTAINER : Constants.CONTENT_TYPE_SECTION,
          title: await this.getRecordingContentTitle(doc as RecordingTimestamp),
          changeType: CHANGE_LOG_VALUE_TYPE.CHANGE_SNIPPET
        });
        return id;
      })
      .catch((err) => this.throwFirestoreError(err));
  }

  addRecordingTimestamp(eventId: string, obj: RecordingTimestamp): Promise<string> {
    const id = this.generateNewDocumentId();
    return this.afs.collection(this.getTimelineCollectionPath())
      .doc(eventId)
      .collection('timestamps')
      .doc(id)
      .set(obj)
      .then(async () => {
        await this.saveChangeLogValue(eventId, {
          sourceId: obj.contentId ?? obj.sectionId,
          sourceType: obj.contentId ? Constants.CONTENT_TYPE_CONTENT_CONTAINER : Constants.CONTENT_TYPE_SECTION,
          title: await this.getRecordingContentTitle(obj),
          changeType: CHANGE_LOG_VALUE_TYPE.CHANGE_SNIPPET
        });
        return id;
      })
      .catch((err) => this.throwFirestoreError(err));
  }

  async deleteRecordingTimestamp(eventId: string, id: string, deleteFile: boolean) {
    const docRef = this.afs.collection(this.getTimelineCollectionPath()).doc(eventId).collection('timestamps').doc(id).ref;
    const doc = (await docRef.get()).data();
    return docRef
      .delete()
      .then(async () => {
        await this.saveChangeLogValue(eventId, {
          sourceId: doc.contentId ?? doc.sectionId,
          sourceType: doc.contentId ? Constants.CONTENT_TYPE_CONTENT_CONTAINER : Constants.CONTENT_TYPE_SECTION,
          title: await this.getRecordingContentTitle(doc as RecordingTimestamp),
          changeType: CHANGE_LOG_VALUE_TYPE.CHANGE_SNIPPET
        });
        return deleteFile ? this.storageApiService.deleteObjectFromClientStorage(doc.gcpPath) : null;
      })
      .catch((err) => this.throwFirestoreError(err));
  }

  async copyRecordingTimestamp(eventId: string, id: string) {
    const docRef = this.afs.collection(this.getTimelineCollectionPath()).doc(eventId).collection('timestamps').doc(id).ref;
    const doc = (await docRef.get()).data();
    if (!doc?.['gcpPath']) {
      return;
    }
    const gcpPath = doc['gcpPath'].split('/');
    const fileName = gcpPath[gcpPath.length - 1];
    const name = fileName.split('.')[0];
    const newName = new Date().getTime().toString();
    const newGcpPath = doc['gcpPath'].replace(name, newName);
    return this.storageApiService.copyStorageObject(doc['gcpPath'].substring(1), newGcpPath.substring(1))
      .then(() =>
        this.afs.collection(this.getTimelineCollectionPath())
          .doc(eventId)
          .collection('timestamps')
          .doc(id)
          .set({gcpPath: newGcpPath}, {merge: true})
      );
  }

  getRecordingVideoUrl(gcpPath: string) {
    const ref = this.getStorageRef(gcpPath);
    return ref.getDownloadURL()
      .catch(err => this.firestoreCatchError(err));
  }

  async deleteContentRecordedSnippets(eventId: string, objectId: string, type: 'section' | 'content') {
    const whereFieldName = type === 'section' ? 'sectionId' : 'contentId';
    const snippetsRef = this.afs.collection(this.getTimelineCollectionPath())
      .doc(eventId)
      .collection('timestamps').ref;
    const snippetsDocs: RecordingTimestamp[] = await snippetsRef.where(whereFieldName, '==', objectId).get()
      .then(snap => snap.docs.map(d => new Object({id: d.id, ...d.data()}) as RecordingTimestamp));
    for (const snippet of snippetsDocs) {
      if (type === 'section' && !!snippet.contentId) {
        continue;
      }
      await snippetsRef.doc(snippet.id).delete();
      await this.storageApiService.deleteObjectFromClientStorage(snippet.gcpPath);
    }
  }
}
