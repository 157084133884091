import {Component, Injector} from '@angular/core';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmpty} from 'lodash';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';

@Component({
  selector: 'app-question-text-editor',
  templateUrl: './question-text-editor.component.html',
  styleUrls: ['./question-text-editor.component.scss']
})
export class QuestionTextEditorComponent extends AbstractQuizQuestionAnswersEditorComponent  {

  constructor(protected injector: Injector) {
    super(injector);
  }

  validate(): IValidated {
    if (this.question.useCorrectAnswers) {
      if (isEmpty(this.dataSource.data)) {
        return {validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required')};
      }
      if (this.dataSource.data.some(o => isEmptyCaption(o.answer))) {
        return {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
      }
    }
    return {validated: true};
  }
}
