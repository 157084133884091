import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, Subscription, take, takeUntil} from 'rxjs';
import {IFollowMeCommand} from './follow-me.service';
import {EventsDataService} from './events-data.service';
import {SectionContent} from '../model/content/SectionContent';
import {Event} from '../model/Event';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {ConferenceUser} from '../model/event-mode/ConferenceUser';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {CommonService} from '../core/common.service';
import {Constants, DATE_FORMAT} from '../core/constants';
import {TextMacrosParserService} from './text-macros-parser.service';
import {RegistrationProcessService} from './registration-process.service';

export interface IFeedbackValue {
  rating?: number;
  note?: string;
}

export interface ISectionFeedbackUserValue {
  sectionId: string;
  userId: string;
  rating: number;
  note: string;
}

export interface IFeedbackSummary {
  starNumber: number;
  summary: number;
}

export interface IFeedbackSummaryRating {
  summary: IFeedbackSummary[];
  rating: number;
}

export interface IFeedbackExportSummary {[sectionId: string]: {
    sectionName: string;
    ratingAvailable: 'yes' | 'no';
    eventUsers: string;
    registeredUsers: string;
    numberOfVotes?: number;
    averageRating?: number;
    star1?: number;
    star2?: number;
    star3?: number;
    star4?: number;
    star5?: number;
    textFeedback?: number;
    orderIndex: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  private readonly _csvHeaders = [
    'feedback.event.report.field.section.name',
    'feedback.event.report.field.rating.available',
    'feedback.event.report.field.registered.users',
    'feedback.event.report.field.number.of.votes',
    'feedback.event.report.field.average.rating',
    'feedback.event.report.field.1.star',
    'feedback.event.report.field.2.star',
    'feedback.event.report.field.3.star',
    'feedback.event.report.field.4.star',
    'feedback.event.report.field.5.star',
    'feedback.event.report.field.text.feedback'];
  public readonly csvFields = ['sectionName', 'ratingAvailable', 'registeredUsersCount', 'numberOfVotes', 'averageRating',
    'star1', 'star2', 'star3', 'star4', 'star5', 'notes'];
  public csvHeaders = [];

  private _showFeedbackDialog = new BehaviorSubject<boolean>(false);
  readonly showFeedbackDialog$ = this._showFeedbackDialog.asObservable();
  private _feedbackUserValue$ = new BehaviorSubject<IFeedbackValue>(null);
  private _feedbackSummaryRating$ = new BehaviorSubject<IFeedbackSummaryRating>(null);
  private _showFeedbackDialogWithTimer$ = new BehaviorSubject<IFollowMeCommand>(null);
  private _feedbackSectionPresenterAccess = false;
  private _feedbackRatingCache: {[sectionId: string]: SectionFeedbackRating} = null;
  private _rootFeedbackRatingCache: SectionFeedbackRating = null;
  private _showTimelineUserFeedbackRating$ = new BehaviorSubject<boolean>(false);
  private _onlineUsers: ConferenceUser[] = [];
  private _usersLoaded = new Subject();
  private _reportEventFeedbackData$ = new Subject();

  constructor(private dataService: EventsDataService,
              private registrationProcessService: RegistrationProcessService,
              private common: CommonService,
              private store: Store<fromRoot.State>,
              private textMacrosParserService: TextMacrosParserService) { }

  get showFeedbackDialog(): BehaviorSubject<boolean> {
    return this._showFeedbackDialog;
  }

  get feedbackUserValue$(): BehaviorSubject<IFeedbackValue> {
    return this._feedbackUserValue$;
  }

  get feedbackSummaryRating$(): BehaviorSubject<IFeedbackSummaryRating> {
    return this._feedbackSummaryRating$;
  }

  get feedbackSectionPresenterAccess(): boolean {
    return this._feedbackSectionPresenterAccess;
  }

  set feedbackSectionPresenterAccess(value: boolean) {
    this._feedbackSectionPresenterAccess = value;
  }

  get showFeedbackDialogWithTimer$(): BehaviorSubject<IFollowMeCommand> {
    return this._showFeedbackDialogWithTimer$;
  }

  get showTimelineUserFeedbackRating$(): BehaviorSubject<boolean> {
    return this._showTimelineUserFeedbackRating$;
  }

  get reportEventFeedbackData$(): Subject<any> {
    return this._reportEventFeedbackData$;
  }

  resetServiceValues() {
    this._feedbackUserValue$.next(null);
    this._showFeedbackDialog.next(false);
    this._feedbackSummaryRating$.next(null);
    this._feedbackSectionPresenterAccess = false;
    this._showFeedbackDialogWithTimer$.next(null);
    this._showTimelineUserFeedbackRating$.next(false);
    if (this._rootFeedbackRatingCache) {
      this._rootFeedbackRatingCache.destroy();
      this._rootFeedbackRatingCache = null;
    }
    if (this._feedbackRatingCache) {
      Object.keys(this._feedbackRatingCache).forEach(sectionId => {
        this._feedbackRatingCache[sectionId].destroy();
        this._feedbackRatingCache[sectionId] = null;
      });
      this._feedbackRatingCache = null;
    }
  }

  initFeedbackCache(eventId, section: SectionContent[]) {
    if (!this._rootFeedbackRatingCache) {
      const rs = section.find(s => !s.parentId);
      if (rs) {
        this._rootFeedbackRatingCache = new SectionFeedbackRating(rs, eventId, null, this.dataService);
      } else {
        return;
      }
    }
    if (!this._feedbackRatingCache) {
      this._feedbackRatingCache = {};
    }
    const list = section.filter(s => !!s.parentId && !s.fixedSectionType);
    const aliveIds = [];
    for (const s of list) {
      aliveIds.push(s.id);
      if (!this._feedbackRatingCache[s.id]) {
        this._feedbackRatingCache[s.id] = new SectionFeedbackRating(s, eventId, this._rootFeedbackRatingCache, this.dataService);
      } else {
        this._feedbackRatingCache[s.id].update(s);
      }
    }
    const deadIds = list.filter(s => !aliveIds.includes(s.id));
    deadIds.forEach(s => {
      this._feedbackRatingCache[s.id].destroy();
      this._feedbackRatingCache[s.id] = null;
      delete this._feedbackRatingCache[s.id];
    });
  }

  getFeedbackRating(sectionId) {
    return this._feedbackRatingCache && this._feedbackRatingCache[sectionId] ? this._feedbackRatingCache[sectionId].getRating() : 0;
  }

  private getUsers(eventId) {
    this.store.select(fromRoot.getUsersOnline)
      .pipe(take(2))
      .subscribe( uOnline => {
        if (isEmpty(uOnline)) {
          this.dataService.dispatchUsersOnline(eventId);
        } else if (!isEmpty(uOnline)) {
          this._onlineUsers = cloneDeep(uOnline);
          this._usersLoaded.next(true);
        }
      });
  }

  reportEventFeedbackData(event: Event, sectionList: SectionContent[]) {
    const unsub = (subj: Subject<boolean>) => {
      subj.next(true);
      subj.complete();
      subj = null;
    };
    const parseTitle = (sectionId: string, title: string): string => {
      return this.common.utils.createTextLinks(
        this.common.utils.createTextLinksM(
          this.common.utils.createTextSplitLinks(
            this.textMacrosParserService.parse(sectionId, title))));
    };

    if (isEmpty(this.csvHeaders)) {
      this.csvHeaders = this.csvHeadersTranslate();
    }
    const sectionsRegisteredUsers = this.registrationProcessService.sectionsRegisteredUsers$.getValue();
    const getBySection = (id) => {
      return sectionsRegisteredUsers.filter(it => !isEmpty(it.sections[id])).map(it => it.sections[id]).filter(it => it.status === 'registration.completed');
    };
    const unsubj = new Subject<boolean>();
    this._usersLoaded.pipe(takeUntil(unsubj)).subscribe(() => {
      unsub(unsubj);
      let promise = Promise.resolve(null);
      const sList = sectionList.filter(s => !s.fixedSectionType && s.isStatusOpen);
      const data: IFeedbackExportSummary = {};
      for (const s of sList) {
        promise = promise.then(() => {
          return this.dataService.getSectionSummaryFeedbackPromise(event.eventId, s.id).then(value => {
            if (!data[s.id]) {
              data[s.id] = {sectionName: !s.isRoot ? parseTitle(s.id, s.title) : event.name, orderIndex: s.orderIndex, eventUsers: ' ',
                ratingAvailable: s.feedbackRating.activateFeedbackRating ? 'yes' : 'no', registeredUsers: ' '};
            }
            const sectionFeedback = this.calcSummary(value);
            const list = getBySection(s.id);
            if (!isEmpty(list) && !isEmpty(sectionFeedback)) {
              sectionFeedback.registeredUsersCount = list.length;
            }
            data[s.id] = merge(data[s.id], sectionFeedback);
          });
        });
      }
      promise.then(() => {
        const result = Object.keys(data).map(id => data[id]).sort(this.common.utils.comparator(Constants.ORDERINDEX));
        this._reportEventFeedbackData$.next(result);
      }).catch((e) => {
        throw new Error(e);
      });
    });
    this.getUsers(event.eventId);
  }

  exportFeedbackRatingsToCSV(event: Event, sectionList: SectionContent[]) {
    this.common.showProgress.next(true);
    this._reportEventFeedbackData$.pipe(take(1)).subscribe(value => {
      const result = value as any;
      this.common.utils.exportToCsvAndDownload(this.csvHeaders, this.csvFields, result,
        this.common.i18n('feedback.event.feedback') + '(' + event.name + ')' + ' ' +
      this.common.utils.formatDate(new Date().getTime(), DATE_FORMAT.DD_MM_YYYY_HH_mm));
      this.common.showProgress.next(false);
    }, () => this.common.showProgress.next(false));
    this.reportEventFeedbackData(event, sectionList);
  }

  private csvHeadersTranslate() {
    return this._csvHeaders.map(h => this.common.i18n(h));
  }

  calcSummary(list: ISectionFeedbackUserValue[]):
    {averageRating: number, numberOfVotes: number, notes: string,
      registeredUsersCount?: number,
      star1: number, star2: number, star3: number, star4: number, star5: number} {
    const feedbackSummary = [
      {starNumber: 1, summary: 0},
      {starNumber: 2, summary: 0},
      {starNumber: 3, summary: 0},
      {starNumber: 4, summary: 0},
      {starNumber: 5, summary: 0}];
    let notes = '';
    for (const obj of list || []) {
      const fs = feedbackSummary.find(o => o.starNumber === obj.rating);
      if (fs) {
        ++fs.summary;
      }
      if (obj.note) {
        notes += notes.length > 0 ? (',' + obj.note) : obj.note;
      }
    }
    let count = 0;
    let allRating = 0;
    for (const sm of feedbackSummary) {
      count += sm.summary;
      allRating += sm.starNumber * sm.summary;
    }

    const averageRating = count > 0 ? Math.round(allRating / count) : 0;
    return {averageRating: averageRating, numberOfVotes: list.filter(o => !!o.rating).length,
      notes: notes,
      star1: feedbackSummary[0].summary, star2: feedbackSummary[1].summary, star3: feedbackSummary[2].summary,
      star4: feedbackSummary[3].summary, star5: feedbackSummary[4].summary};
  }

}

class SectionFeedbackRating {
  private section: SectionContent;
  private subscription: Subscription;
  private rating = 0;
  private eventId: string;
  private rootCache: SectionFeedbackRating;
  private dataService: EventsDataService;
  constructor(section: SectionContent, eventId: string, rootCache: SectionFeedbackRating, dataService: EventsDataService) {
    this.section = section;
    this.eventId = eventId;
    this.dataService = dataService;
    this.rootCache = rootCache;
    this.subscribe();
  }

  private subscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.section.feedbackRating.activateFeedbackRating) {
      this.subscription = this.dataService.getSectionFeedback(this.eventId, this.section.id)
        .subscribe(value => {
          if (value) {
            this.rating = value.rating;
          }
        });
    }
  }

  update(section: SectionContent) {
    if (this.section.id === section.id &&
      this.section.feedbackRating.activateFeedbackRating !== section.feedbackRating.activateFeedbackRating) {
      this.subscribe();
    }
  }

  destroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getRating() {
    return this.section.feedbackRating.activateFeedbackRating ? this.rating : this.rootCache.getRating();
  }
}
