export class WelcomeScreen {
  subtitle: string;
  informationPoints: InformationPoint[];
  informationText: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.subtitle) {
      this.subtitle = obj.subtitle;
    }
    if (obj.informationPoints) {
      this.informationPoints = obj.informationPoints;
    }
    if (obj.informationText) {
      this.informationText = obj.informationText;
    }
  }

  toObject() {
    const obj = {...this};
    Object.keys(obj).forEach(key => obj[key] === undefined && (obj[key] = null));
    if (obj.informationPoints) {
      obj.informationPoints = obj.informationPoints.map(it => it instanceof InformationPoint ? it.toObject() : it);
    }
    return obj;
  }
}

// todo wait before angularfire start support it https://github.com/angular/angularfire/issues/2291
const welcomeScreenConverter = {
  toFirestore: function(self) {
    const obj = {...self};
    Object.keys(obj).forEach(key => obj[key] === undefined && (obj[key] = null));
    if (obj.informationPoints) {
      obj.informationPoints = obj.informationPoints.map(it => it.toObject());
    }
    return obj;
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    return new WelcomeScreen(data);
  }
};

export class InformationPoint {
  title: string;
  description: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.title) {
      this.title = obj.title;
    }
    if (obj.description) {
      this.description = obj.description;
    }
  }

  toObject() {
    return {...this};
  }
}
