import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {CanActivateAuthGuard} from './login/can-activate.authguard';
import {LoginGuard} from './login/login.guard';
import {UserCardDialogComponent} from './user-components/user-card-dialog/user-card-dialog.component';
import {ServiceTasksComponent} from './service-tasks/service-tasks/service-tasks.component';
import {AuthComponent} from './auth/auth.component';
import {GuestComponent} from './login/guest/guest.component';
import {JoinToClientComponent} from './login/join-to-client/join-to-client.component';
import {CanActivateAdminGuard} from './guards/can-activate-admin.guard';
import {CanActivateSuperadminGuard} from './guards/can-activate-superadmin.guard';
import {EmailVerificationComponent} from './login/email-verification/email-verification.component';
import {TermsOfUseComponent} from './terms-and-privacy/terms-of-use/terms-of-use.component';
import {PrivacyPolicyComponent} from './terms-and-privacy/privacy-policy/privacy-policy.component';
import {ClientTermsAndPrivacyComponent} from './modules/client-terms-and-privacy/client-terms-and-privacy.component';
import {AngularFireAuthGuard} from '@angular/fire/compat/auth-guard';
import {customClaims} from '@angular/fire/auth-guard';
import {map, pipe} from 'rxjs';
import {Constants} from './core/constants';
import {ParsedToken} from '@firebase/auth';

const superadminRoles = [Constants.ROLE_SUPERADMIN];
const superadminAdminRoles = [Constants.ROLE_SUPERADMIN, Constants.ROLE_ADMIN];
const superadminOrManageRoles = [Constants.ROLE_SUPERADMIN, Constants.ROLE_MANAGE, Constants.ROLE_ADMIN];

const isSuperadmin = () => pipe(customClaims, map((claims: ParsedToken) => {
  const roles = (claims.roles as string || '').split(',');
  return roles.some(v => superadminRoles.includes(v)) ? true : '';
}));

const isSuperadminAdmin = () => pipe(customClaims, map((claims: ParsedToken) => {
  const roles = (claims.roles as string || '').split(',');
  return roles.some(v => superadminAdminRoles.includes(v)) ? true : '';
}));

const isSuperadminOrManage = () => pipe(customClaims, map((claims: ParsedToken) => {
  const roles = (claims.roles as string || '').split(',');
  return roles.some(v => superadminOrManageRoles.includes(v)) ? true : '';
}));

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'}
  , {path: 'terms', component: TermsOfUseComponent, pathMatch: 'full'}
  , {path: 'privacy', component: PrivacyPolicyComponent, pathMatch: 'full'}
  , {path: 'auth/:provider', component: AuthComponent, canActivate: [LoginGuard]}
  , {path: 'login', component: LoginComponent, canActivate: [LoginGuard]}
  , {path: 'guest/:link/:code', component: GuestComponent}
  , {path: 'go', component: JoinToClientComponent, pathMatch: 'full'}
  , {path: 'go/:id', component: JoinToClientComponent, pathMatch: 'full'}
  , {path: 'verify', component: EmailVerificationComponent}
  , {path: 'accept', component: ClientTermsAndPrivacyComponent}
  , {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  }
  , {
    path: 'event',
    loadChildren: () => import('./create-event/create-event.module').then(m => m.CreateEventModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'event',
    loadChildren: () => import('./print-mode/print-mode.module').then(m => m.PrintModeModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'event',
    loadChildren: () => import('./event-mode/event-mode.module').then(m => m.EventModeModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'module',
    loadChildren: () => import('./event-mode/event-mode.module').then(m => m.EventModeModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'my-time',
    loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'my/notes',
    loadChildren: () => import('./my-notes/my-notes.module').then(m => m.MyNotesModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'question-cards',
    loadChildren: () => import('./question-cards/question-cards.module').then(m => m.QuestionCardsModule),
    canActivate: [CanActivateAuthGuard]
  }
  , {
    path: 'event-management',
    loadChildren: () => import('./event-management/event-management.module').then(m => m.EventManagementModule),
    canActivate: [CanActivateAuthGuard, AngularFireAuthGuard],
    data: {
      authGuardPipe: isSuperadminAdmin
    }
  }
  , {
    path: 'users',
    loadChildren: () => import('./user-management/users/users.module').then(m => m.UsersModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'groups',
    loadChildren: () => import('./user-management/groups/groups.module').then(m => m.GroupsModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'profile',
    component: UserCardDialogComponent,
    loadChildren: () => import('./user-components/user-components.module').then(m => m.UserComponentsModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'mail-template',
    loadChildren: () => import('./mail-templates/mail-templates.module').then(m => m.MailTemplatesModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'locations',
    loadChildren: () => import('./event-locations/event-locations.module').then(m => m.EventLocationsModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'event-tags',
    loadChildren: () => import('./event-tags/event-tags.module').then(m => m.EventTagsModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'support-types',
    loadChildren: () => import('./event-support-types/event-support-types.module').then(m => m.EventSupportTypesModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'service-tasks', component: ServiceTasksComponent,
    loadChildren: () => import('./service-tasks/service-tasks.module').then(m => m.ServiceTasksModule),
    canActivate: [CanActivateAdminGuard]
  }
  , {
    path: 'admin-console',
    loadChildren: () => import('./superadmin/superadmin.module').then(m => m.SuperadminModule),
    canActivate: [CanActivateSuperadminGuard]
  }
  , {
    path: 'curriculums',
    loadChildren: () => import('@ninescopesoft/manage').then(m => m.CurriculumsModule),
    canActivate: [CanActivateAuthGuard, AngularFireAuthGuard],
    data: {
      authGuardPipe: isSuperadminOrManage
    }
  },
  {
    path: 'paths',
    redirectTo: '/dashboard'
  },
  {
    path: 'modules',
    loadChildren: () => import('@ninescopesoft/manage').then(m => m.ModulesModule),
    canActivate: [CanActivateAuthGuard, AngularFireAuthGuard],
    data: {
      authGuardPipe: isSuperadminOrManage
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('@ninescopesoft/manage').then(m => m.SettingsModule),
    canActivate: [CanActivateAuthGuard, AngularFireAuthGuard],
    data: {
      authGuardPipe: isSuperadminOrManage
    }
  }
  , {path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
