import {computed, Injectable, signal} from '@angular/core';
import {intersection, isEmpty, union, uniq} from 'lodash';
import {
  EXAM_ASSESSMENT_STATUS,
  EXAM_ASSESSMENT_WORK_MODE,
  EXAM_CONTENT_STATUS,
  EXAM_STATUS,
  EXAM_STATUSES_ALLOW_ASSESSING,
  EXAM_STATUSES_ALLOW_EDIT_CONTENTS
} from '../../exam-constants/exam-constants';
import {EXAM_ACTIONS, EXAM_ROLES, EXAM_ROLES_REFERENCE, MANAGE_CONTENT_ACTIONS} from './exam-roles';
import {LoginService} from '../../../login/login.service';
import {Exam} from '../../exam-model/exam';
import {ExamSection} from '../../exam-model/exam-section';
import {ContentContainer} from '../../../model/content/ContentContainer';
import {Observable, Subject, takeUntil} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamRolesService {

  protected exam = signal<Exam>(null);
  protected sections = signal<ExamSection[]>(null);
  protected currentSections = signal<ExamSection>(null);
  protected examAssessmentWorkMode = signal<EXAM_ASSESSMENT_WORK_MODE>(null);
  protected isExamOwner = computed(() => this.exam()?.owner.userId === this.currentUserId);
  protected examUserRoles = computed(() => this.getEnabledRoles(this.exam()?.users?.[this.currentUserId]?.roles ?? []));

  canManageExamsList = computed(() => this.hasRoleAllowManageExamsList());
  canEditExamSettings = computed(() => this.hasRoleAllowEditExamSettings(this.examUserRoles()));
  currentRoles = computed(() => this.getRoles(this.currentSections()));

  private _unsubscribeAll = new Subject();

  constructor(private loginService: LoginService) {}

  public static checkStatusAvailable(checkStatus: EXAM_STATUS, examCurrentStatus: EXAM_STATUS) {
    const availableList = () => {
      switch (examCurrentStatus) {
        case EXAM_STATUS.READY_TO_CREATE:
          return [EXAM_STATUS.CREATING, EXAM_STATUS.CREATION_APPROVED];
        case EXAM_STATUS.CREATING:
          return [EXAM_STATUS.READY_TO_CREATE, EXAM_STATUS.CREATION_APPROVED];
        case EXAM_STATUS.CREATION_APPROVED:
          return [EXAM_STATUS.CREATING, EXAM_STATUS.READY_TO_RUN];
        case EXAM_STATUS.READY_TO_RUN:
          return [EXAM_STATUS.CREATION_APPROVED];
        default:
          return [EXAM_STATUS.READY_TO_CREATE];
      }
    };
    return availableList().includes(checkStatus);
  }

  init(exam: Observable<Exam>, sections: Observable<ExamSection[]>, currentSections: Observable<ExamSection>,
       examAssessmentWorkMode: Observable<EXAM_ASSESSMENT_WORK_MODE>) {
    exam.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => this.exam.set(value));
    sections.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => this.sections.set(value));
    currentSections.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => this.currentSections.set(value));
    examAssessmentWorkMode.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => this.examAssessmentWorkMode.set(value));
  }

  destroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
    this.exam.set(null);
    this.sections.set(null);
    this.currentSections.set(null);
    this.examAssessmentWorkMode.set(null);
  }

  get currentUserId() {
    return this.loginService.getAppUser().userId;
  }

  protected getEnabledRoles(roles: EXAM_ROLES[]) {
    return roles?.filter(r => !EXAM_ROLES_REFERENCE[r]?.disabled) ?? [];
  }

  protected hasRoleAllowManageExamsList() {
    return this.loginService.getAppUser().isSuperAdmin || this.loginService.getAppUser().isAdmin || this.loginService.getAppUser().isManage;
  }

  isAdmin() {
    return this.loginService.getAppUser().isSuperAdmin || this.loginService.getAppUser().isAdmin;
  }

  isManage() {
    return this.loginService.getAppUser().isManage;
  }

  // used inside exam
  protected hasRoleAllowEditExamSettings(rolesList: EXAM_ROLES[]) {
    if (this.isAdmin() || this.isExamOwner()) {
      return true;
    }
    const actions = [EXAM_ACTIONS.EDIT_EXAM_SETTINGS];
    const roles = this.getEnabledRoles(rolesList);
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  // use in exams list
  hasPermissionEditExamSettings(exam: Exam) {
    const actions = [EXAM_ACTIONS.EDIT_EXAM_SETTINGS];
    const roles = this.getEnabledRoles(exam.users?.[this.currentUserId]?.roles ?? []);
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  hasPermissionExamUserManagement(exam?: Exam) {
    const checkExam = exam ?? this.exam();
    if (!checkExam) {
      return false;
    }
    if (this.isAdmin() || this.isExamOwner()) {
      return true;
    }
    const actions = [EXAM_ACTIONS.EDIT_EXAM_SETTINGS];
    const roles = this.getEnabledRoles(checkExam.users?.[this.currentUserId]?.roles ?? []);
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  getRoles(content: ExamSection | ContentContainer | Exam, userId?: string, excludedRoles?: EXAM_ROLES[]) {
    const parentRoles = (sc: ExamSection) => {
      let list = this.getEnabledRoles(sc.users?.[userId ?? this.currentUserId]?.roles ?? []);
      let parent = this.sections().find(o => o.id === sc.parentId);
      while (parent) {
        list = uniq(union(list, this.getEnabledRoles(parent.users?.[userId ?? this.currentUserId]?.roles ?? [])));
        parent = this.sections().find(o => o.id === parent.parentId);
      }
      return list;
    };
    if (!content) {
      return [];
    }
    if (content instanceof ExamSection && userId) {
      return uniq(parentRoles(content));
    }
    if (!userId && this.isExamOwner()) {
      return [EXAM_ROLES.PRESENTER];
    }
    if (content instanceof Exam || content.id === this.exam().examId) {
      return !userId ? this.examUserRoles() : this.getEnabledRoles(this.exam()?.users?.[userId]?.roles ?? [])
        .filter(r => !(excludedRoles ?? []).includes(r));
    }
    const section = content instanceof ExamSection ? content : this.sections().find(s => s.id === content.parentId);
    return uniq(union(this.isExamOwner() ? [EXAM_ROLES.PRESENTER] : [], this.examUserRoles(), section ? parentRoles(section) : []))
      .filter(r => !(excludedRoles ?? []).includes(r));
  }

  hasRoleAllowManageSections(section: ExamSection | Exam) {
    const actions = [EXAM_ACTIONS.EDIT_SECTION_SETTINGS, ...MANAGE_CONTENT_ACTIONS];
    const roles = this.getRoles(section);
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  hasRoleAllowManageSectionsTree(section: ExamSection | Exam) {
    const actions = MANAGE_CONTENT_ACTIONS;
    const readonly = !EXAM_STATUSES_ALLOW_EDIT_CONTENTS.includes(this.exam()?.status) || this.examAssessmentWorkMode();
    const roles = !readonly ? this.getRoles(section) : [];
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  hasRoleAllowManageSectionSettings(section: ExamSection | Exam) {
    const actions = [EXAM_ACTIONS.EDIT_SECTION_SETTINGS];
    const roles = this.getRoles(section);
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  hasRoleAllowChangeContentStatus(content: ContentContainer) {
    const readonly = !EXAM_STATUSES_ALLOW_EDIT_CONTENTS.includes(this.exam()?.status);
    const roles = !readonly ? this.getRoles(content) : [];
    return !isEmpty(roles) &&
        roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, [`content-${content.status}`])));
  }

  hasRoleAllowChangeContentStatusFromTo(content: ContentContainer, statusTo: EXAM_CONTENT_STATUS, userId?: string) {
    const actionStatusFrom = `content-${content.status}`;
    const actionStatusTo = `content-${statusTo}`;
    const readonly = !EXAM_STATUSES_ALLOW_EDIT_CONTENTS.includes(this.exam()?.status);
    const roles = !readonly ? this.getRoles(content, userId) : [];
    if (!isEmpty(roles)) {
      const userActions = roles.reduce((acc, role) => union(acc, EXAM_ROLES_REFERENCE[role]?.actions), []);
      return userActions.includes(actionStatusFrom) && userActions.includes(actionStatusTo);
    }
    return false;
  }

  hasRoleAllowChangeStatusTo(content: ContentContainer | ExamSection,
                             status: EXAM_CONTENT_STATUS | EXAM_ASSESSMENT_STATUS, userId?: string) {
    const readonly = !(!this.examAssessmentWorkMode() ? EXAM_STATUSES_ALLOW_EDIT_CONTENTS : EXAM_STATUSES_ALLOW_ASSESSING)
      .includes(this.exam()?.status);
    const roles = !readonly ? this.getRoles(content, userId) : [];
    const checkStatus = !this.examAssessmentWorkMode() ? [`content-${status}`] : [`assessment-status-${status}`];
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, checkStatus)));
  }

  // used for filter in speakers panel
  checkStatusAvailability(content: ContentContainer | ExamSection | Exam,
                             status: EXAM_CONTENT_STATUS | EXAM_ASSESSMENT_STATUS, userId?: string, excludedRoles?: EXAM_ROLES[]) {
    const roles = this.getRoles(content, userId, excludedRoles);
    const checkStatus = !this.examAssessmentWorkMode() ? `content-${status}` : `assessment-status-${status}`;
    return !isEmpty(roles) && roles.some(role => EXAM_ROLES_REFERENCE[role]?.actions.includes(checkStatus as any));
  }

  hasRoleAllowManageStatuses(content: ContentContainer) {
    const actions = [EXAM_ACTIONS.CONTENT_REVIEWED, EXAM_ACTIONS.EDIT_CONTENT, EXAM_ACTIONS.CONTENT_APPROVED];
    const readonly = !EXAM_STATUSES_ALLOW_EDIT_CONTENTS.includes(this.exam()?.status);
    const roles = !readonly ? this.getRoles(content) : [];
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }

  hasRoleAllowManageContents(content: ContentContainer | ExamSection) {
    const actions = MANAGE_CONTENT_ACTIONS;
    const readonly = !EXAM_STATUSES_ALLOW_EDIT_CONTENTS.includes(this.exam()?.status);
    const roles = !readonly ? this.getRoles(content) : [];
    return !isEmpty(roles) && roles.some(role => !isEmpty(intersection(EXAM_ROLES_REFERENCE[role]?.actions, actions)));
  }
}
