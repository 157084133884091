export enum SOCIAL_MODE {
  DO_NOT_ACCEPT = 0, // show all and can draw personal objects
  ACCEPT_EVERYONE = 1, // no restricted
  ACCEPT_EVERYONE_HIDDEN = 2, // can draw and show from me and presenter (not from speaker)
  ACCEPT_FROM = 3 // not used
}

export enum PINS_MODE {
  PIN_ONLY = 0,
  PIN_AND_TEXT = 1,
  ENUM = 2
}

export class SocialSettings {
  show = false;
  socialMode = SOCIAL_MODE.DO_NOT_ACCEPT;
  pins = true;
  pinsMode = PINS_MODE.PIN_ONLY;
  drawing = false;


  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
  }

  toObject() {
    return {...this};
  }
}

export class ContentSocialSettings {
  socialMode = SOCIAL_MODE.DO_NOT_ACCEPT;
  pins = true;
  pinsMode = PINS_MODE.PIN_ONLY;
  drawing = false;


  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.hasOwnProperty('socialMode')) {
      this.socialMode = obj.socialMode;
    }
    if (obj.hasOwnProperty('pins')) {
      this.pins = obj.pins;
    }
    if (obj.hasOwnProperty('pinsMode')) {
      this.pinsMode = obj.pinsMode;
    }
    if (obj.hasOwnProperty('drawing')) {
      this.drawing = obj.drawing;
    }
  }

  toObject() {
    return {...this};
  }
}
