import {ServiceTasksComponent} from '../service-tasks.component';
import {firstValueFrom} from 'rxjs';
import {isEmpty, max} from 'lodash';
import {SectionContent} from '../../../model/content/SectionContent';

export const createEventsLastActivityStatistics = async (thiz: ServiceTasksComponent) => {

  const runCreateEventsLastActivityStatistics = async (clientId) => {
    let eventsLength = 0;
    let counter = 0;
    await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get())
      .then(async snapshot => {
        const events = snapshot.docs.filter(d => !d.data().deleted);
        eventsLength = events.length;
        for (const event of events) {
          console.log('process ', ++counter, ' of ', eventsLength);
          await firstValueFrom(thiz.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.id)
            .collection('users_online').get())
            .then(async snapshotUsers => {
              const activityTimeList = snapshotUsers.docs.map(d => d.get('lastActivity')) ?? [];
              return thiz.afDB.database.ref(`client_data/${clientId}/events_activity/${event.id}`)
                .update({lastActivity: max(activityTimeList) ?? 0});
            });
        }
      });
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Create events last activity statistics.'))) {
    return;
  }
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    await runCreateEventsLastActivityStatistics(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('End process.');
};

export const createEventsSelfLearningModulesStatistics = async (thiz: ServiceTasksComponent) => {

  const runCreateEventsLastActivityStatistics = async (clientId) => {
    let eventsLength = 0;
    let counter = 0;
    await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get())
      .then(async snapshot => {
        const events = snapshot.docs.filter(d => !d.data().deleted);
        eventsLength = events.length;
        for (const event of events) {
          console.log('process ', ++counter, ' of ', eventsLength);
          await firstValueFrom(thiz.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.id)
            .collection('timeline').get())
            .then(async snapshot => {
              const selfLearningList = snapshot.docs.map(d => d.get('isSelfLearningSection')) ?? [];
              return thiz.afDB.database.ref(`client_data/${clientId}/events_activity/${event.id}`)
                .update({selfLearningModulesCount: selfLearningList.filter(v => !!v).length});
            });
        }
      });
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Create events SelfLearning modules statistics.'))) {
    return;
  }
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    await runCreateEventsLastActivityStatistics(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('End process.');
};

export const createEventsSelfLearningModulesActivityStatistics = async (thiz: ServiceTasksComponent) => {
  const runCreateEventsLastActivityStatistics = async (clientId) => {
    let eventsLength = 0;
    let counter = 0;
    await firstValueFrom(thiz.afs.collection('client_data').doc(clientId)
      .collection('events')
      .get())
      .then(async snapshot => {
        const events = snapshot.docs.filter(d => !d.data().deleted);
        eventsLength = events.length;
        for (const event of events) {
          console.log('process ', ++counter, ' of ', eventsLength);
          await firstValueFrom(thiz.afs
            .collection('client_data').doc(clientId)
            .collection('conference').doc(event.id)
            .collection('timeline').get())
            .then(async snapshot => {
              const sections = snapshot.docs.map(d => new SectionContent({id: d.id, ...d.data()}));
              const selfLearningModulesList = sections.filter(s => s.isSelfLearningSection);
              if (isEmpty(selfLearningModulesList)) {
                return;
              }
              const selfLearningModulesActivity = {};
              const selfLearningModulesChildren = {};
              for (const slm of selfLearningModulesList) {
                const children = thiz.utils.getSectionChildrenTree(slm, sections);
                children.forEach(s => selfLearningModulesChildren[s.id] = slm.id);
              }
              await firstValueFrom(thiz.afs
                .collection('client_data').doc(clientId)
                .collection('conference').doc(event.id)
                .collection('self_learning').get())
                .then(async snapshot => {
                  for (const doc of snapshot.docs) {
                    const contentsComplete = doc.get('contents_complete');
                    if (isEmpty(contentsComplete)) {
                      continue;
                    }
                    const sectionIds = Object.keys(contentsComplete);
                    for (const sId of sectionIds) {
                      const mActivity = selfLearningModulesActivity[selfLearningModulesChildren[sId]] ?? 0;
                      const sActivity = max(Object.values(contentsComplete[sId]));
                      selfLearningModulesActivity[selfLearningModulesChildren[sId]] = max([mActivity, sActivity]);
                    }
                  }
                  return thiz.afDB.database.ref(`client_data/${clientId}/events_activity/${event.id}`)
                    .update({selfLearningModulesActivity: selfLearningModulesActivity});
                });

            });
        }
      });
  };

  let clientIds: string[];
  if (isEmpty(clientIds = await thiz.confirmationBeforeRun('Run: Create events SelfLearning modules activity statistics.'))) {
    return;
  }
  for (const clientId of clientIds) {
    console.log(`Client ${clientId} started`);
    await runCreateEventsLastActivityStatistics(clientId);
    console.log(`Client ${clientId} finished`);
  }
  console.log('End process.');
};
