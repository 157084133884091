import {TranslateLoader} from '@ngx-translate/core';
import {forkJoin, map, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export class TranslationLoaderService extends TranslateLoader {

  constructor(private http: HttpClient) {
    super();
  }

  getTranslation(lang: string): Observable<any> {
    return forkJoin([
      this.http.get(`/assets/i18n/${lang.toLowerCase()}.json`),
      this.http.get(`/assets/i18n/manage/${lang.toLowerCase()}.json`)
  ]).pipe(
      map(([ commonTranslation, manageTranslation ]) => Object.assign(
        {},
        commonTranslation,
        manageTranslation
      ))
    );
  }
}
