import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizService } from '../../../../quiz.service';
import {QUESTION_VIEW_MODE} from '../../../shared/quiz-quiestion-types';

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrl: './result-dialog.component.scss'
})
export class ResultDialogComponent {

  public QUESTION_VIEW_MODE = QUESTION_VIEW_MODE;
  viewMode: string;
  answers: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private quizService: QuizService) {
    this.viewMode = data.viewMode;
    this.answers = data.rowData;
  }

}
