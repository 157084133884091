import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {IAnswer, IMatching} from '../../../quiz-model/quiz';
import {Constants} from '../../../../../../../core/constants';
import {isEmpty, max, uniq} from 'lodash';

@Component({
  selector: 'app-question-matching-results',
  templateUrl: './question-matching-results.component.html',
  styleUrls: ['./question-matching-results.component.scss']
})
export class QuestionMatchingResultsComponent extends AbstractQuizQuestionResultsComponent {

  dataSource = [];
  dataSourceMatching = [];
  displayedColumns = ['answer'];
  displayedColumnsMatchingSrc = ['matching'];
  answers;

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    const dataList: IAnswer[] = [];
    const dataMatchingList: IMatching[] = [];
    for (const m of (this.question.matchingItems || [])) {
      const index = dataMatchingList.findIndex(o => o.id === m.id);
      if (index > -1) {
        dataMatchingList[index] = {id: m.id, orderIndex: m.orderIndex, answerMatching: m.getAnswerByLanguage(this.languageParams)};
      } else {
        dataMatchingList.push({id: m.id, orderIndex: m.orderIndex, answerMatching: m.getAnswerByLanguage(this.languageParams)});
      }
    }
    dataMatchingList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.dataSourceMatching = dataMatchingList;
    const correctItems = this.reduceGroupsCorrectAnswers();
    for (const answer of this.question.items) {
      dataList.push(
        {
          id: answer.id, orderIndex: answer.orderIndex,
          progress: '', unprogress: '',
          answer: answer.getAnswerByLanguage(this.languageParams),
          answersMatching: this.question.matchingItems
            .filter(m => correctItems.find(it => it.id === answer.id)?.matching.includes(m.id))
            .map(m => m.getAnswerByLanguage(this.languageParams))
        });
    }
    dataList.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.answers = this.getMatchingGroupedAnswers(this.question, this.summaryQuestionAnswers);
    this.dataSource = dataList;
    this.calcDataSourceFields();
  }

  protected onReceiveQuestionAnswers() {
    this.answers = this.getMatchingGroupedAnswers(this.question, this.summaryQuestionAnswers);
    this.calcDataSourceFields();
  }

  protected reduceGroupsCorrectAnswers() {
    return this.question.reduceGroupsCorrectAnswersItems()
      .reduce((acc: any[], it) => {
        const el = acc.find(o => o.id === it.id);
        if (!el) {
          acc.push(it);
        } else {
          el.matching.push(...it.matching);
          el.matching = uniq(el.matching);
        }
        return acc;
      }, []);
  }

  getMatchingGroupedAnswers(question: EventQuestion, answers: {}): {} {
    const correctPair: {} = {};
    if (isEmpty(question.items)) { return {}; }
    const correctItems = this.reduceGroupsCorrectAnswers();
    for (const a of question.items) {
      correctItems.find(it => it.id === a.id)?.matching?.forEach(id => correctPair[a.id + '-' + id] = true);
    }
    const groupAnswers: {} = {};
    for (const answer of Object.keys(answers || {})) {
      const list = answer.split('-');
      const groupId = list[0];
      const items = list.filter(id => id !== groupId);
      if (items.every(id => correctPair[groupId + '-' + id])) {
        groupAnswers[groupId] = (groupAnswers[groupId] ?? 0) + answers[answer];
      }
    }
    groupAnswers['scaleEnd'] = max(Object.values(groupAnswers));
    return groupAnswers;
  }

  private calcDataSourceFields() {
    const getPropValue = (qKey, propName: string, def: number = 0) => this.answers && this.answers[propName] ? this.answers[propName] : def;
    let maxLen = 0;
    for (const row of this.dataSource) {
      row.count = getPropValue(this.qKey, row.id);
      maxLen = maxLen < (row.count + '').length ? (row.count + '').length : maxLen;
      const scaleEnd = getPropValue(this.qKey, 'scaleEnd');
      const scaleEnd1 = getPropValue(this.qKey, 'scaleEnd', 1);
      row.progress = (scaleEnd !== 0 ? row.count * 100 / scaleEnd1 : 0) + '%';
      row.unprogress = (scaleEnd !== 0 ? (100 - (row.count * 100 / scaleEnd1)) : 100) + '%';
    }
    this.dataSource.forEach(row => row.minWidth = (maxLen === 1 ? 1 : (1.2 + 0.6 * (maxLen - 2))) + 'em');
  }

}
