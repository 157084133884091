import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {EnumToArrayPipe} from './enum-to-array.pipe';
import {SafePipe} from './safe.pipe';
import { TimeFromNow } from './time-from-now.pipe';
import {HexToRGBA} from './hex-to-rgba.pipe';
import {TranslateToLanguage} from './translated-to-language.pipe';
import {SafeHTMLPipe} from './safe-html.pipe';
import {DurationPipe} from './duration.pipe';

const pipes = [
  EnumToArrayPipe
  , SafePipe
  , TimeFromNow
  , HexToRGBA
  , TranslateToLanguage
  , SafeHTMLPipe
  , DurationPipe
];

@NgModule({
  declarations: pipes,
  imports: [
    CommonModule
  ],
  exports: pipes
})
export class PipeModule {
  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
        ngModule: PipeModule,
        providers: [],
    };
}
}
