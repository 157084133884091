import {REMINDER_TYPE} from '../../core/constants';

export interface IRegistrationSettingsMap {
  [sectionId: string]: RegistrationSettings;
}

export class RegistrationSettings {
  public static readonly DB_PATH = 'registration_settings';

  id: string;
  dateFrom: number;
  dateTo: number;
  maxAttendees: number;
  description: string;
  requiredApprove: boolean;
  requiredAllAnswers: boolean;
  keepWaitingList: boolean;
  mandatoryForEventRegistration: boolean;
  autoWaitingListInvite: boolean;
  keepUsersOnRegistrationScreen: boolean;
  emailAutoInvite: boolean;
  emailAutoInviteIncludeICS: boolean;
  emailAutoInviteDate: number;
  emailRemainderInvite: boolean;
  emailRemainderInviteIncludeICS: boolean;
  emailRemainderOnlyRegistered: boolean;
  emailRemainderType: REMINDER_TYPE = REMINDER_TYPE.INDEPENDENT_STATUS;
  emailRemainderDate: number;
  emailConfirmation: boolean;
  emailIncludeLink: boolean;
  emailIncludeICS: boolean;
  autoFreeSpaceInform: boolean;
  inheritEventRegistrationPeriod: boolean;
  registrationInstruction: string;
  includeSubtitleInRegistrationForm: boolean;
  displayMainEventSpeakers: boolean;
  individualEmail: boolean;
  mandatoryCalendar: boolean;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    if (obj.id) {
      this.id = obj.id;
    }
    if (obj.dateFrom) {
      this.dateFrom = obj.dateFrom;
    }
    if (obj.dateTo) {
      this.dateTo = obj.dateTo;
    }
    if (obj.maxAttendees) {
      this.maxAttendees = obj.maxAttendees;
    }
    if (obj.description) {
      this.description = obj.description;
    }
    if (obj.requiredApprove) {
      this.requiredApprove = obj.requiredApprove;
    }
    if (obj.requiredAllAnswers) {
      this.requiredAllAnswers = obj.requiredAllAnswers;
    }
    if (obj.keepWaitingList) {
      this.keepWaitingList = obj.keepWaitingList;
    }
    if (obj.mandatoryForEventRegistration) {
      this.mandatoryForEventRegistration = obj.mandatoryForEventRegistration;
    }
    if (obj.autoWaitingListInvite) {
      this.autoWaitingListInvite = obj.autoWaitingListInvite;
    }
    if (obj.keepUsersOnRegistrationScreen) {
      this.keepUsersOnRegistrationScreen = obj.keepUsersOnRegistrationScreen;
    }
    if (obj.emailAutoInvite) {
      this.emailAutoInvite = obj.emailAutoInvite;
    }
    if (obj.emailAutoInviteIncludeICS) {
      this.emailAutoInviteIncludeICS = obj.emailAutoInviteIncludeICS;
    }
    if (obj.emailAutoInviteDate) {
      this.emailAutoInviteDate = obj.emailAutoInviteDate;
    }
    if (obj.emailRemainderInvite) {
      this.emailRemainderInvite = obj.emailRemainderInvite;
    }
    if (obj.emailRemainderInviteIncludeICS) {
      this.emailRemainderInviteIncludeICS = obj.emailRemainderInviteIncludeICS;
    }
    if (obj.emailRemainderDate) {
      this.emailRemainderDate = obj.emailRemainderDate;
    }
    if (obj.emailConfirmation) {
      this.emailConfirmation = obj.emailConfirmation;
    }
    if (obj.emailIncludeLink) {
      this.emailIncludeLink = obj.emailIncludeLink;
    }
    if (obj.emailIncludeICS) {
      this.emailIncludeICS = obj.emailIncludeICS;
    }
    if (obj.autoFreeSpaceInform) {
      this.autoFreeSpaceInform = obj.autoFreeSpaceInform;
    }
    if (obj.inheritEventRegistrationPeriod) {
      this.inheritEventRegistrationPeriod = obj.inheritEventRegistrationPeriod;
    }
    if (obj.registrationInstruction) {
      this.registrationInstruction = obj.registrationInstruction;
    }
    if (obj.hasOwnProperty('emailRemainderOnlyRegistered')) {
      this.emailRemainderOnlyRegistered = obj.emailRemainderOnlyRegistered;
    } else {
      this.emailRemainderOnlyRegistered = true;
    }
    if (obj.includeSubtitleInRegistrationForm) {
      this.includeSubtitleInRegistrationForm = obj.includeSubtitleInRegistrationForm;
    }
    if (obj.displayMainEventSpeakers) {
      this.displayMainEventSpeakers = obj.displayMainEventSpeakers;
    }
    if (obj.hasOwnProperty('individualEmail')) {
      this.individualEmail = obj.individualEmail;
    }
    if (obj.emailRemainderType) {
      this.emailRemainderType = obj.emailRemainderType;
    }
    if (obj.hasOwnProperty('mandatoryCalendar')) {
      this.mandatoryCalendar = obj.mandatoryCalendar;
    }
  }

  toObject() {
    return {...this};
  }
}
