import {Injectable} from '@angular/core';
import {StdApiService} from './std-api-service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {IcsTemplate} from '../model/IcsTemplate';
import {catchError, firstValueFrom, map, Observable, take} from 'rxjs';
import {isEmpty} from 'lodash';
import {UtilsService} from '../core/utils.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IcsTemplateApiService extends StdApiService {
  protected API_URL: string;
  defaultTemplateId = '-Lq8P4a3dICh40HYbtnb';

  constructor(private http: HttpClient,
              protected logger: LoggerService,
              protected store: Store<fromRoot.State>,
              protected auth: LoginService,
              protected aFirestore: AngularFirestore,
              protected utils: UtilsService,
              protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/notificationApi/v3/';
  }

  save(eventId: string, template: IcsTemplate): Promise<any> {
    if (!template._key) {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(IcsTemplate.DB_PATH)
        .add(template.toObject());
    } else {
      return this.afs.collection('conference')
        .doc(eventId)
        .collection(IcsTemplate.DB_PATH)
        .doc(template._key)
        .set(template.toObject());
    }
  }

  delete(eventId: string, templateId: string) {
    return this.afs
      .collection('conference')
      .doc(eventId)
      .collection(IcsTemplate.DB_PATH)
      .doc(templateId).delete();
  }

  get(eventId: string, sectionId: string): Observable<IcsTemplate[]> {
    return this.afs.collection('conference')
      .doc(eventId)
      .collection(IcsTemplate.DB_PATH, query => query.where('sectionId', '==', sectionId))
      .valueChanges({idField: '_key'})
      .pipe(
        map(res => this.mapArray(res, (it) => new IcsTemplate(it)))
      );
  }

  getDefault() {
    return this.afs.collection<IcsTemplate>('ics_template')
      .doc(this.defaultTemplateId)
      .valueChanges()
      .pipe(
        map(res => this.mapObject(res, (it) => new IcsTemplate({_key: this.defaultTemplateId, ...it}))),
        catchError(err => this.firestoreCatchError(err))
      );
  }

  copyIcsTemplate(eventId: string, sectionIdFrom: string, sectionIdTo: string): Promise<any> {
    return firstValueFrom(this.get(eventId, sectionIdFrom))
      .then((list) => {
        if (!isEmpty(list)) {
          let p = Promise.resolve();
          for (const v of list) {
            p = p.then(() => {
              delete v._key;
              v.sectionId = sectionIdTo;
              const t = new IcsTemplate(v);
              return this.save(eventId, t);
            });
          }
          return p;
        } else {
          return Promise.resolve();
        }
      });
  }

  deleteIcsAllTemplates(eventId: string, sectionId: string) {
    return firstValueFrom(this.get(eventId, sectionId))
      .then((list) => {
        if (!isEmpty(list)) {
          let p = Promise.resolve();
          for (const v of list) {
            p = p.then(() => {
              return this.delete(eventId, v._key);
            });
          }
          return p;
        } else {
          return Promise.resolve();
        }
      });
  }

  test(eventId: string, template: IcsTemplate): Promise<any> {
    let url = this.API_URL + 'testICSTemplate?eventId=' + eventId;
    if (template.sectionId) {
      url = url + '&sectionId=' + template.sectionId;
    }
    return this.http.post(url, template).toPromise();
  }
}
