import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ToasterService} from '../core/toaster.service';
import {UsersDataService} from './users-data.service';
import {debounceTime, map, of, switchMap} from 'rxjs';
import * as data from '../actions/data';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {CommonService} from '../core/common.service';

@Injectable()
export class UsersDataEffects {

  constructor(private actions$: Actions
              , private dataService: UsersDataService
              , private loginService: LoginService
              , private toasterService: ToasterService
              , private logger: LoggerService
              , private commonService: CommonService
  ) { }

  serverFail$ = createEffect(() => this.actions$.pipe(
    ofType(data.ActionTypes.USERS_SERVER_FAIL),
    debounceTime(300),
    map((action: data.UpdateAction) => action.payload),
    switchMap(payload => {
      this.commonService.showProgress.next(false);
      this.logger.error(payload);
      if (payload && payload.result) {
        payload = payload.result;
      }
      if (payload && payload.code !== 'PERMISSION_DENIED') {
        if (payload.error) {
          payload = payload.error;
        }
        if (payload.code === 401) {
          this.loginService.gotoLogin().then(() => {
            this.toasterService.pop('error', 'Failure ' + payload.code, payload.message);
          });
        } else {
          this.toasterService.pop('error', 'Failure ' + payload.code, payload.message);
        }
      }
      return of(new data.EmptyAction(''));
    })), { dispatch: false });
}
