import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UserCardDialogComponent} from '../../user-components/user-card-dialog/user-card-dialog.component';
import {take} from 'rxjs';
import {EditDialogComponent} from '../../dialog-components/edit-dialog/edit-dialog.component';
import {CommonService} from '../../core/common.service';
import {UsersDataService} from '../../services/users-data.service';
import {TranslateService} from '@ngx-translate/core';
import {ClientConfig} from '../../model/ClientConfig';
import {MatDialog} from '@angular/material/dialog';
import {Constants} from '../../core/constants';
import {LoginService} from '../../login/login.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as data from '../../actions/data';
import {MatMenuTrigger} from '@angular/material/menu';
import {CountriesService} from '../../core/countries.service';
import {AppUserResponse} from '../../model/AppUser';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, AfterViewInit {
  @Input() clientConfig: ClientConfig;
  isAzure = false;
  @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger;
  isMobile$ = this.common.isMobile$();

  constructor(private store: Store<fromRoot.State>,
              private common: CommonService,
              private translate: TranslateService,
              public dialog: MatDialog,
              public loginService: LoginService,
              public userDataService: UsersDataService,
              private countriesService: CountriesService) {
    const loginProvider = this.loginService.getCurrentProviderId();
    this.isAzure = loginProvider === Constants.PROVIDER_HKVBS_MICROSOFT;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.matMenu.menuOpened
      .subscribe(value => {
        const toolbar = document.getElementById('main-toolbar');
        if (toolbar) {
          toolbar.classList.add('menu-blur');
        }
        const container = document.getElementById('main-container');
        if (container) {
          container.classList.add('menu-blur');
        }
      });
    this.matMenu.menuClosed
      .subscribe(value => {
        const toolbar = document.getElementById('main-toolbar');
        if (toolbar) {
          toolbar.classList.remove('menu-blur');
        }
        const container = document.getElementById('main-container');
        if (container) {
          container.classList.remove('menu-blur');
        }
      });
  }

  get currentUser() {
    return this.loginService.getAppUser();
  }

  showAccountSettings() {
    const activateSubscription = (res) => {
      if (this.currentUser.unsubscribe) {
        unsubscribe = res.receiveEmailNotifications ? 0 : this.currentUser.unsubscribe;
      } else {
        unsubscribe = res.receiveEmailNotifications ? this.currentUser.unsubscribe : 1;
      }
      if (this.currentUser.unsubscribe !== unsubscribe) {
        this.currentUser.unsubscribe = unsubscribe;
        return this.userDataService.activateSubscription(!!unsubscribe);
      } else {
        return Promise.resolve();
      }
    };
    const setUserInterfaceLanguage = (res) => {
      if ('default' === res.language) {
        this.currentUser.language = null;
        return this.userDataService.setUserInterfaceLanguage('default').then(() =>
          this.translate.use(browserLanguageCanBeUsed ? browserLanguage : this.clientConfig.language));
      } else if (userLanguage !== res.language) {
        this.currentUser.language = res.language;
        return this.userDataService.setUserInterfaceLanguage(res.language).then(() => this.translate.use(res.language));
      } else {
        return Promise.resolve();
      }
    };

    const receiveEmailNotifications = !this.currentUser.unsubscribe;
    let unsubscribe;
    const languageList = this.common.utils.getLanguageList();
    const browserLanguage = this.common.utils.getBrowserLanguage();
    const browserLanguageCanBeUsed = this.common.utils.checkBrowserLanguage(browserLanguage);
    if (browserLanguageCanBeUsed && browserLanguage !== this.clientConfig.language) {
      languageList.splice(0, 0, {
        name: this.common.i18n('language.default.browser',
          {
            name: this.common.i18n(
              languageList.find(l => l.value === browserLanguage).name)
          }),
        value: 'default'
      });
    }
    if (!browserLanguageCanBeUsed) {
      const defClientLanguage = languageList.find(l => l.value === this.clientConfig.language);
      if (defClientLanguage) {
        languageList.splice(0, 0, {
          name: this.common.i18n('language.default.timeline',
            {
              name: this.common.i18n(defClientLanguage.name)
            }),
          value: 'default'
        });
      }
    }

    const countryList = this.countriesService.getList().map(it => ({name: it.name, value: it.code}));
    const userCountry = this.currentUser.countryCode;

    const userLanguage = this.currentUser.language ? this.currentUser.language : 'default';

    const dialogData = {title: this.common.utils.i18n('account.settings')
      , fields: [
        {
          id: 'receiveEmailNotifications',
          type: 'checkbox',
          placeholder: this.common.i18n('checkbox.hide.unsubscribe'),
          hidden: this.currentUser?.guest
        },
        {
          id: 'language', type: 'select', placeholder: this.common.i18n('user.client.interface.language.description'),
          hidden: false, options: languageList, margin_top: '10px'
        },
        {
          id: 'country', type: 'select', placeholder: this.common.i18n('user.client.interface.country.description'),
          hidden: this.currentUser?.guest, options: countryList, margin_top: '10px'
        }
      ]
      , result: {receiveEmailNotifications: receiveEmailNotifications, language: userLanguage, country: userCountry}
    };
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
      if (result) {
        this.common.showProgress.next(true);
        await activateSubscription(result).catch((e) => this.common.log.error(e));
        await setUserInterfaceLanguage(result).catch((e) => this.common.log.error(e));
        const country = this.countriesService.getEntry(result.country);
        await this.userDataService.setGeolocation(country.name, country.code)
          .then((response: AppUserResponse) => {
            const cUser = cloneDeep(this.currentUser);
            cUser.country = country.name;
            cUser.countryCode = country.code;
            this.loginService.setAppUser(cUser);
            this.store.dispatch(new data.SetCurrentUserAction(cUser));
          })
          .catch((e) => this.common.log.error(e));
        this.common.showProgress.next(false);
      }
    });
  }

  onViewUserCard() {
    const appUser = this.currentUser;
    const dialogRef = this.dialog.open(UserCardDialogComponent, {
      width: '860px',
      data: {
        appUser: appUser,
        currentUser: appUser,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.common.showProgress.next(true);
        let promise = Promise.resolve();
        if (this.isAzure && (!result.picture || result.picture.startsWith('data:image'))) {
          promise = promise.then(() => this.userDataService.uploadProfilePicture(result.picture)
            .then(url => {
              result.picture = url;
            }))
            .catch((err) => {
              this.common.log.error(err);
              this.common.showProgress.next(false);
            });
        }
        this.currentUser.socialNetwork = result.socialNetwork;
        promise.then(() =>
          this.userDataService.updateUserCard(result.userId, result.description,
            result.socialNetwork, result.hideEmail, result.department).then(() => {
            if (this.currentUser.fullName !== result.fullName) {
              this.userDataService.updateUserName(result.fullName)
                .subscribe(() => {
                  this.loginService.appUser.fullName = result.fullName;
                });
            }
            this.store.dispatch(new data.SetCurrentUserAction(result));
            this.common.showProgress.next(false);
          }).catch((err) => {
            this.common.log.error(err);
            this.common.showProgress.next(false);
          })
        );
      }
    });
  }

  signOut() {
    this.loginService.signOut(true);
  }

}
