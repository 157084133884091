export class JoinMeetingInfo {

  eventId: string;
  meetingId: number;
  signature: string;
  userName: string;
  userEmail: string;
  c: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
  }

}
