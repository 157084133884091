import {AnswerQuestion, EventQuestion, TableRowAnswerQuestion} from '../../../../../../../model/EventQuestion';
import {Constants, ILanguageParams} from '../../../../../../../core/constants';
import {MatTableDataSource} from '@angular/material/table';
import {Injector} from '@angular/core';
import {isEmpty} from 'lodash';
import {interval, take} from 'rxjs';
import {AbstractQuizQuestionEditorComponent} from './abstract-quiz-question-editor-component';
import {EditDialogComponent} from '../../../../../../../dialog-components/edit-dialog/edit-dialog.component';

export abstract class AbstractQuizQuestionAnswersEditorComponent extends AbstractQuizQuestionEditorComponent {

  dataSource = new MatTableDataSource<TableRowAnswerQuestion>([]);
  displayedColumns = ['answer'];
  answerData: TableRowAnswerQuestion[] = [];
  dsSelectedRowId: string;
  addedId;
  translating = {};

  protected constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    (question.items || []).forEach(item => this.answerData.push(new TableRowAnswerQuestion(item, this.languageParams)));
    this.answerData.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.answerData = this.setObjectChangesHandler(this.answerData);
    this.dataSource.data = this.answerData;
  }

  protected genAnswerOrderIndex(): number {
    // order index base on getTime therefore it is necessary to make a delay so that there are no duplicates
    const start = performance.now();
    while (performance.now() - start < 10) { }
    return new Date().getTime();
  }

  applyQuestionData() {
    const items = [];
    for (let i = 0; i < this.answerData.length; i++) {
      items.push(new AnswerQuestion(this.answerData[i]));
    }
    this.question.items = items;
    this.groupsCorrectAnswers$.next(this.question.groupsCorrectAnswers?.filter(g => !g.defaultGroup));
    return this.question;
  }

  addAnswer() {
    const row = new TableRowAnswerQuestion(new AnswerQuestion(), this.languageParams);
    row.orderIndex = this.genAnswerOrderIndex();
    this.answerData.push(row);
    this.dataSource.data = this.answerData;
    this.addedId = row.id;
    this.dsSelectedRowId = row.id;
    this.setRowFocus(this.addedId);
  }

  deleteAnswer(rowId) {
    const index = this.answerData.findIndex(item => item.id === rowId);
    this.answerData.splice(index, 1);
    this.dataSource.data = this.answerData;
    this.dsSelectedRowId = null;
    this.removeAnswerFromGroups(rowId);
  }

  onClickUpDown(rowId, move: number) {
    const index = this.answerData.findIndex(item => item.id === rowId);
    const temp = this.answerData[index];
    this.answerData[index] = this.answerData[index + move];
    this.answerData[index + move] = temp;
    this.answerData.forEach((item, itemIndex) => item.orderIndex = (itemIndex + 1) * 100 );
    this.dataSource.data = this.answerData;
  }

  isFirst(rowId) {
    return !rowId || !this.answerData.findIndex(item => item.id === rowId) || isEmpty(this.answerData);
  }

  isLast(rowId) {
    return !rowId || (this.answerData.length - 1) === this.answerData.findIndex(item => item.id === rowId) || isEmpty(this.answerData);
  }

  canNotBeRemoved(rowId) {
    const answerList = this.question.answers;
    let answerKeyList;
    if (!answerList || (answerKeyList = Object.keys(answerList)).length === 0) {
      return false;
    }
    for (let i = 0; i < answerKeyList.length; i++) {
      if (answerList[answerKeyList[i]]?.indexOf(rowId) > -1) {
        return true;
      }
    }
    return false;
  }

  translateAnswer(rowId) {
    const row = this.answerData.find(item => item.id === rowId);
    const text = row.rowCaption;
    this.translating[rowId] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => row.rowCaption = v)
      .finally(() => delete this.translating[rowId]);
  }

  protected setRowFocus(rowId) {
    interval(20).pipe(take(1)).subscribe(() => {
      const element = document.getElementById(rowId);
      if (element) {
        element.focus();
      }
    });
  }

  protected removeAnswerFromGroups(rowId) {
    (this.question.groupsCorrectAnswers || []).forEach(group => {
      const idx = group.items?.findIndex(it => it.id === rowId);
      if (idx > -1) {
        group.items.splice(idx, 1);
      }
    });
  }

  addGroupAnswers() {
    return this.question.addGroupAnswers();
  }

  async deleteGroupAnswers(groupId) {
    if (await this.common.confirmation(this.common.i18n('action.tooltip.delete.group.answers') + '?')) {
      this.question.deleteGroupAnswers(groupId);
    }
  }

  setGroupRowValue(value, groupId: string | 'default', answerId) {
    const group = this.question.groupsCorrectAnswers.find(g => groupId === 'default' ? g.defaultGroup : g.id === groupId);
    const aIndex = (group.items || []).findIndex(a => a.id === answerId);
    if (aIndex > -1 && !value) {
      group.items?.splice(aIndex, 1);
    } else if (aIndex === -1 && value) {
      if (!group.items) {
        group.items = [];
      }
      group.items.push({id: answerId, correctAnswer: true} as any);
    }
  }

  async setGroupPoints(groupId) {
    this.dialog.open(EditDialogComponent, {
      width: '300px',
      data: {title: this.common.i18n('action.tooltip.set.group.points')
        , fields: [
          {id: 'points', type: 'text', placeholder: this.common.i18n('action.tooltip.group.points'), showClearButton: true}
        ]
        , result: {points: this.question.groupsCorrectAnswers.find(g => g.id === groupId).points}
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.question.groupsCorrectAnswers.find(g => g.id === groupId).points = result.points;
      }
    });
  }

  trackByGroup(index, group) {
    return group;
  }
}
