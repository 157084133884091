import { Injectable } from '@angular/core';
import {StdApiService} from './std-api-service';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {UtilsService} from '../core/utils.service';
import {LoggerService} from '../core/logger.service';
import {LoginService} from '../login/login.service';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatisticsApiServiceService extends StdApiService {
  protected API_URL: string;

  constructor(
    private http: HttpClient,
    protected store: Store<fromRoot.State>,
    protected aFirestore: AngularFirestore,
    protected utils: UtilsService,
    protected logger: LoggerService,
    protected auth: LoginService,
    protected storage: AngularFireStorage
  ) {
    super(store, logger, auth, utils, aFirestore, storage);
    this.API_URL = this.BACKEND_BASE + '/api/v3/statistics';
  }

  getUsersByLastLogin(time: number): Observable<number> {
    const path = `/logins/recent?time=${time}`;
    return this.http.get<number>(this.API_URL + path);
  }

  updateUsersByCreatedAt(): Observable<void> {
    const path = `/logins/created`;
    return this.http.post<void>(this.API_URL + path, null);
  }

}
