import { ChangeDetectorRef, Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { AbstractQuizQuestionParticipantComponent } from '../../shared/participant/abstract-quiz-question-participant-component';
import { AnswersQuestion } from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { debounceTime, pairwise, startWith, Subject, takeUntil } from 'rxjs';
import { START_VALUE_INDEX, END_VALUE_INDEX, CORRECT_VALUE_INDEX } from '../open-number-estimation-utils';

@Component({
  selector: 'app-question-open-number-estimation-participant',
  templateUrl: './question-open-number-estimation-participant.component.html',
  styleUrl: './question-open-number-estimation-participant.component.scss'
})
export class QuestionOpenNumberEstimationParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  startValue: any;
  endValue: any;
  correctValue: any;

  participantAnswerLength = 0;
  showCorrectAnswerTicks = false;

  form: FormGroup;
  inputControl: FormControl;
  sliderControl = new FormControl();
  private destroy$ = new Subject<void>();


  answerSubmitted = false;
  answerIsCorrect = false;

  iconPosition = 0;
  correctIconPosition = 0;

  @ViewChild('matSlider', {read: ElementRef,  static: false }) sliderElement!: ElementRef;


  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private cdRef: ChangeDetectorRef) {
      super(injector, elementRef);

      this.question$.asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          if(data){
            if(!this.inputControl){
              this.startValue = data.items[START_VALUE_INDEX].answer;
              this.endValue = data.items[END_VALUE_INDEX].answer;
              if(data.items.length >  CORRECT_VALUE_INDEX){
                this.correctValue = data.items[CORRECT_VALUE_INDEX].answer;
              }
              this.inputControl = new FormControl(this.startValue, [Validators.min(this.startValue), Validators.max(this.endValue)]);
            }

            if(this.questionsDirectFeedbackChecked){
              if(this.correctValue){
                this.iconPosition = this.getIconPositionInPixel(this.sliderControl.value);
                this.correctIconPosition =  this.getIconPositionInPixel(this.correctValue);
                this.sliderControl.setValue(this.correctValue, {emitEvent: false});
              }else{
                this.answerIsCorrect = true;
              }
            }
          }
        });
  }

  initQuestionAnswersDataSource(){
    this.sliderControl.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.destroy$)
    ).subscribe(value => {
      if(value || value === 0){
        this.sendAnswer(String(value));
        this.inputControl?.setValue(value, {emitEvent: false});
      }

      if(!this.answerSubmitted){
        this.answerSubmitted = true;
      }
    });

    this.inputControl?.valueChanges.pipe(
      debounceTime(200),
      startWith(null), // Provide an initial "previous value"
      pairwise(), // Emits the previous and current value as a tuple
      takeUntil(this.destroy$)
    ).subscribe(([prevValue, value]) => {
      if(!this.answerSubmitted){
        this.answerSubmitted = true;
        this.cdRef.detectChanges();
      }

      if((prevValue != value) && value >= this.startValue && value <= this.endValue){
        this.sendAnswer(String(value));
      }
    });

  }

  protected onReceiveQuestionAnswers() {
    if (this.answers && this.answers.length ) {

      const participantAnswer = parseInt(this.answers[0]);
      this.sliderControl.setValue(participantAnswer, {emitEvent: false});
      this.inputControl.setValue(participantAnswer, {emitEvent: false});
      this.answerIsCorrect =  participantAnswer === this.correctValue;

      this.participantAnswerLength = String(participantAnswer).length;
    }
  }


  dragEnd(event){
    this.sendAnswer(String(event.value));
  }

  private sendAnswer(answer){
    const answers: string[] = [answer];
    this.answerChange$.next(new AnswersQuestion(this.qKey, answers, this.question.timelineId));
  }


  onHover(event){
    if(this.correctValue){
      this.showCorrectAnswerTicks = true;
    }
  }

  onHoverEnd(event){
    this.showCorrectAnswerTicks = false;
  }

  private getIconPositionInPixel(sliderValue) {
    let iconPosition = 0;
    if(this.sliderElement){

      const sliderNativeEl = this.sliderElement.nativeElement;

      const sliderWidth = sliderNativeEl.offsetWidth;


      const max = this.endValue;
      const min = this.startValue;

      // Calculate position in pixels
      iconPosition = ((sliderValue - min) / (max - min)) * sliderWidth;
    }

    return iconPosition;
  }

  onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
