import {AnswerQuestion, EventQuestion, TableRowAnswerQuestion} from '../../../../../../../model/EventQuestion';
import {Constants, ILanguageParams} from '../../../../../../../core/constants';
import {MatTableDataSource} from '@angular/material/table';
import {Injector} from '@angular/core';
import {isEmpty} from 'lodash';
import {interval, take} from 'rxjs';
import {AbstractQuizQuestionEditorComponent} from './abstract-quiz-question-editor-component';

export abstract class AbstractQuizQuestionAnswersEditorComponent extends AbstractQuizQuestionEditorComponent {

  dataSource = new MatTableDataSource<TableRowAnswerQuestion>([]);
  displayedColumns = ['answer'];
  answerData: TableRowAnswerQuestion[] = [];
  dsSelectedRowId: string;
  addedId;
  translating = {};

  protected constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    (question.items || []).forEach(item => this.answerData.push(new TableRowAnswerQuestion(item, this.languageParams)));
    this.answerData.sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.answerData = this.setObjectChangesHandler(this.answerData);
    this.dataSource.data = this.answerData;
  }

  protected genAnswerOrderIndex(): number {
    return new Date().getTime();
  }

  applyQuestionData() {
    const items = [];
    for (let i = 0; i < this.answerData.length; i++) {
      items.push(new AnswerQuestion(this.answerData[i]));
    }
    this.question.items = items;
    return this.question;
  }

  addAnswer() {
    const row = new TableRowAnswerQuestion(new AnswerQuestion(), this.languageParams);
    row.orderIndex = this.genAnswerOrderIndex();
    this.answerData.push(row);
    this.dataSource.data = this.answerData;
    this.addedId = row.id;
    this.dsSelectedRowId = row.id;
    this.setRowFocus(this.addedId);
  }

  deleteAnswer(rowId) {
    const index = this.answerData.findIndex(item => item.id === rowId);
    this.answerData.splice(index, 1);
    this.dataSource.data = this.answerData;
    this.dsSelectedRowId = null;
  }

  onClickUpDown(rowId, move: number) {
    const index = this.answerData.findIndex(item => item.id === rowId);
    const temp = this.answerData[index];
    this.answerData[index] = this.answerData[index + move];
    this.answerData[index + move] = temp;
    this.answerData.forEach((item, itemIndex) => item.orderIndex = (itemIndex + 1) * 100 );
    this.dataSource.data = this.answerData;
  }

  isFirst(rowId) {
    return !rowId || !this.answerData.findIndex(item => item.id === rowId) || isEmpty(this.answerData);
  }

  isLast(rowId) {
    return !rowId || (this.answerData.length - 1) === this.answerData.findIndex(item => item.id === rowId) || isEmpty(this.answerData);
  }

  canNotBeRemoved(rowId) {
    const answerList = this.question.answers;
    let answerKeyList;
    if (!answerList || (answerKeyList = Object.keys(answerList)).length === 0) {
      return false;
    }
    for (let i = 0; i < answerKeyList.length; i++) {
      if (answerList[answerKeyList[i]]?.indexOf(rowId) > -1) {
        return true;
      }
    }
    return false;
  }

  translateAnswer(rowId) {
    const row = this.answerData.find(item => item.id === rowId);
    const text = row.rowCaption;
    this.translating[rowId] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => row.rowCaption = v)
      .finally(() => delete this.translating[rowId]);
  }

  protected setRowFocus(rowId) {
    interval(20).pipe(take(1)).subscribe(() => {
      const element = document.getElementById(rowId);
      if (element) {
        element.focus();
      }
    });
  }

}
