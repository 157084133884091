import {TimeLineService} from '../services/time-line.service';
import {UtilsService} from '../core/utils.service';
import {ManageTimeService} from '../services/manage-time.service';
import {SectionContent} from '../model/content/SectionContent';
import {Constants, EMULATE_RESULT} from '../core/constants';
import {ContentService, IAbstractContent, SectionEventPhaseChanger} from '../services/content.service';
import {AbstractContent} from '../model/content/AbstractContent';
import {cloneDeep, isEmpty, merge} from 'lodash';
import {PlaneContent} from '../model/content/PlaneContent';
import {Event} from '../model/Event';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {AppUser} from '../model/AppUser';

export interface ICopyContentResult {
  newId: string;
  oldId: string;
  parentId: string;
  newOrderIndex: string;
}

export class TimeLineEmulator {
  private _srcListContent: SectionContent[] = [];
  private _emListContent: SectionContent[] = []; // list for emulate process
  private _rootSection: SectionContent;
  private _childTreeInLineListCache = {};
  private _planeListContent: {[key: string]: PlaneContent} = {}; // for build display tree base on user rights and access
  private _planeFullListContent: {[key: string]: PlaneContent} = {}; // for build orderIndex contains all linked contents
  private _availableSectionIdList = {};
  private _planeFullListContentAsSortedArray: PlaneContent[] = [];
  private _planeListContentSortedWithoutFixed: PlaneContent[] = [];
  private _emulateResultWithout: EMULATE_RESULT;
  private idCounter = 0;
  private _testCopyList: PlaneContent[] = [];

  /**
   * @param timeLineService
   * @param manageTimeService
   * @param contentService
   * @param utils
   * @param currentUser
   * @param isPresenter
   * @param contentBelow - section receiver
   * @param below - drop type true if below, false if within
   */
  constructor(private timeLineService: TimeLineService
            , private manageTimeService: ManageTimeService
            , private contentService: ContentService
            , private utils: UtilsService
            , private currentUser: AppUser
            , private isPresenter: boolean
            , private contentBelow: IAbstractContent
            , private below: boolean) {
  }

  get testCopyList(): PlaneContent[] {
    return this._testCopyList;
  }

  get planeListContent(): { [p: string]: PlaneContent } {
    return this._planeListContent;
  }

  get planeFullListContent(): { [p: string]: PlaneContent } {
    return this._planeFullListContent;
  }

  planeListContentSortedWithoutFixed(): PlaneContent[] {
    return this._planeListContentSortedWithoutFixed;
  }

  public runEmulator(emulateResultWithout: EMULATE_RESULT, event?: Event) {
    this.idCounter = 0;
    this._availableSectionIdList = {};
    this._planeFullListContentAsSortedArray = [];
    this._emListContent = [];
    this._testCopyList = [];
    this._srcListContent = this.timeLineService.sections
      .filter(s => !s.fixedSectionType).map(obj => cloneDeep(obj));
    if (this.contentService.clipboard.isCutType &&
         this.contentService.clipboard.content['sectionEventPhase'] === this.contentBelow['sectionEventPhase'] &&
      this.contentService.checkPasteObjectIntoItself(this.contentBelow, this.below) === Constants.BREAK_PROCESS) {
      return false;
    }
    this._emulateResultWithout = emulateResultWithout;
    const parentId = this.below ? this.contentBelow.parentId : this.contentBelow.id;
    const orderIndex = this.below ? this.timeLineService.getOrderIndex(this.contentBelow.id,
        true, this.contentService.clipboard.content.id) :
      this.timeLineService.getOrderIndex(this.contentBelow.id);
    if (this.contentService.clipboard.isTypeSection && this.contentService.clipboard.isCutType) {
      if (this.contentService.clipboard.content.eventId === this.timeLineService.timelineEvent.eventId) {
        this.pasteSection(this.contentBelow, cloneDeep(this.contentService.clipboard.content),
          parentId, orderIndex !== null, this.below, Constants.CONTENT_CLIPBOARD_CUT, false, false);
      } else {
        this.pasteSection(this.contentBelow, cloneDeep(this.contentService.clipboard.content),
          parentId, orderIndex !== null, this.below, Constants.CONTENT_CLIPBOARD_COPY, true);
      }
    } else
    if (this.contentService.clipboard.isTypeSection && this.contentService.clipboard.isCopyType) {
      this.pasteSection(this.contentBelow, cloneDeep(this.contentService.clipboard.content),
        parentId, orderIndex !== null, this.below, Constants.CONTENT_CLIPBOARD_COPY);
    }
    this.buildTimeLineEmulator('copy-paste', event);
    return true;
  }

  private pasteSection(contentBelow, pasteSection: AbstractContent, parentId: string, calcIncrement: boolean, below: boolean,
                       copyType, cutAnotherEvent = false, notClearPlannedTimeOnCutPaste = false) {
    const vm = this;
    const drmFilterIds = this.contentService.drmService
      .drmListFilter(this.contentService.clipboard.moveSectionList.map(o => o instanceof SectionTimeline ? o : o['sectionContent']))
      .map(o => o.id);
    const moveList: SectionTimeline[] = this.contentService.clipboard.moveSectionList
      .filter(o => drmFilterIds.includes(o.id))
      .map(o => cloneDeep(o.sectionContent));
    this.contentService.updateMoveSectionList(contentBelow, moveList);
    let startIndex = null;
    let changeOnlyParent = false;
    let startCalcIncrementId = contentBelow.id;
    const sectionEventPhaseChanger: SectionEventPhaseChanger = {
      change: (pasteSection as SectionContent).sectionEventPhase !== (contentBelow as SectionContent).sectionEventPhase,
      phase: (contentBelow as SectionContent).sectionEventPhase ? (contentBelow as SectionContent).sectionEventPhase : null};
    if (!calcIncrement || (contentBelow.isTypeSection && below)) {
      const listTreebelow = this.timeLineService.childTreeInLineList(contentBelow.id);
      // check pasteSection is last child or single child in contentBelow childs tree.
      // if it's true that pasteSection need change only parent.
      if (pasteSection.eventId === this.timeLineService.timelineEvent.eventId && copyType === Constants.CONTENT_CLIPBOARD_CUT &&
        contentBelow.isTypeSection && contentBelow.items.length > 0 &&
        ((contentBelow.items.length === 1 && pasteSection.id === contentBelow.items[0]['id']) ||
          pasteSection.id === contentBelow.items[contentBelow.items.length - 1]['id'])) {
        changeOnlyParent = true;
      } else {
        startIndex = listTreebelow[listTreebelow.length - 1][Constants.ORDERINDEX];
        startCalcIncrementId = listTreebelow[listTreebelow.length - 1]['id'];
      }
    }
    if (!changeOnlyParent) {
      const nextOrderIndex = (calcIncrement && !contentBelow.isTypeSection) ||
      (calcIncrement && contentBelow.isTypeSection && !below) ? contentBelow.orderIndex : startIndex;
      const increment = calcIncrement ?
        this.timeLineService.getOrderIndexIncrement(startCalcIncrementId, moveList.length) :
        Constants.ORDER_INDEX_INCREMENT;
      this.sectionListCopyPaste(pasteSection.eventId, moveList, parentId, nextOrderIndex, increment, copyType,
        this.timeLineService.timelineEvent.eventId, sectionEventPhaseChanger, notClearPlannedTimeOnCutPaste);
    } else {
      // call only for current event. not call when copy/paste between events.
      // call if paste into very end of sections tree.
      if (copyType === Constants.CONTENT_CLIPBOARD_CUT) {
        for (let i = 0; i < moveList.length; i++) {
          const section = moveList[i];
          const parentIsContainer = vm.timeLineService.planeFullListContentWithoutFixed[pasteSection.id].container;
          const clearTimeObject = parentIsContainer ?
            this.contentService.clearTimeObjectAsNull :
            this.contentService.getPasteClearTimeLineObject(this._emulateResultWithout, section);
          let params = sectionEventPhaseChanger.change ? {sectionEventPhase: sectionEventPhaseChanger.phase} : {};
          params = merge(params, !notClearPlannedTimeOnCutPaste ? clearTimeObject : {});
          this._emListContent.push(this.updateContentPosition(this.timeLineService.timelineEvent.eventId,
            section, i === 0 ? parentId : section.parentId, section.orderIndex, params));
        }
      }
    }
  }

  private sectionListCopyPaste(eventId, moveList: any[], parentId, nextOrderIndex, increment, copyType, newEventId,
                       sectionEventPhaseChanger: SectionEventPhaseChanger, notClearPlannedTimeOnCutPaste = false) {
    const parentIsContainer = this.timeLineService.planeFullListContentWithoutFixed[parentId].container;
    const sectionCopyListParams: ICopyContentResult[] = [];
    for (let i = 0; i < moveList.length; i++) {
      nextOrderIndex = nextOrderIndex + increment;
      if (copyType === Constants.CONTENT_CLIPBOARD_CUT) {
        if (eventId === newEventId) {
          const emulateResult = i > 0 && this._emulateResultWithout === EMULATE_RESULT.WITH_START_WITHOUT_DURATION_WITHOUT_END ?
            EMULATE_RESULT.WITHOUT_DURATION : this._emulateResultWithout;
          const clearTimeObject = parentIsContainer ?
            this.contentService.clearTimeObjectAsNull :
            this.contentService.getPasteClearTimeLineObject(emulateResult, moveList[i]);
          let params = sectionEventPhaseChanger.change ? {sectionEventPhase: sectionEventPhaseChanger.phase} : {};
          params = merge(params, !notClearPlannedTimeOnCutPaste ? clearTimeObject : {});
          this._emListContent.push(this.updateContentPosition(eventId, moveList[i],
            i === 0 ? parentId : moveList[i].parentId, nextOrderIndex, params));
        }
      } else if (copyType === Constants.CONTENT_CLIPBOARD_COPY) {
        // all section copy insert into one parent because we don't know new section id
        const sectionObj = moveList[i] instanceof SectionContent ? moveList[i] : (moveList[i] as PlaneContent).sectionContent;
        const params = sectionEventPhaseChanger.change ? {
          sectionEventPhase: isEmpty(sectionEventPhaseChanger.phase) ? null : sectionEventPhaseChanger.phase
        } : {};
        // clear plannedTime, duration, endTime for all section if parent is container and
        // clear plannedTime, endTime if parent is not container.
        const clearTimeObject = parentIsContainer ?
          this.contentService.clearTimeObjectAsNull :
          this.contentService.getPasteClearTimeLineObject(this._emulateResultWithout, moveList[i]);
        const mergedContent = merge(cloneDeep(sectionObj), params, !notClearPlannedTimeOnCutPaste ? clearTimeObject : {});
        sectionCopyListParams.push(this.createCopyContent(mergedContent,
          newEventId, i === 0 ? parentId : moveList[i].parentId, nextOrderIndex));
        this._emListContent.push(new SectionContent(mergedContent));
      }
    }
    if (copyType === Constants.CONTENT_CLIPBOARD_COPY) {
      for (const obj of sectionCopyListParams) {
        const oldParentId = obj.parentId;
        const parentObj = sectionCopyListParams.find(item => item.oldId === oldParentId);
        // after all section copy correct parentId
        const newParentId = !parentObj ? obj.parentId : parentObj.newId;
        const cObj = this._emListContent.find(o => o.id === obj.newId);
        if (obj.oldId !== obj.parentId) {
          cObj.parentId = newParentId;
        }
      }
    }
  }

  private updateContentPosition(eventId: string, content: AbstractContent, parentId: string, orderIndex: number,
                        parsms: {}, moveToNewPath?: boolean) {
    let updateContentFieldsAndParams = !moveToNewPath ? {parentId: parentId, orderIndex: orderIndex} :
      {parentId: content.parentId, newParentId: parentId, orderIndex: orderIndex, moveToNewPath: true};
    updateContentFieldsAndParams = merge(parsms, updateContentFieldsAndParams);
    const cObj = cloneDeep(content);
    const result = merge(cObj, updateContentFieldsAndParams);
    return new SectionContent(result);
  }

  private createCopyContent(anyContent: AbstractContent, newEventId, newParentId, newOrderIndex): ICopyContentResult {
    this.idCounter++;
    const result: ICopyContentResult = {
      newId: this.idCounter.toString(),
      oldId: anyContent.id,
      parentId: newParentId,
      newOrderIndex: newOrderIndex};
    anyContent.parentId = newParentId;
    anyContent.orderIndex = newOrderIndex;
    anyContent.id = this.idCounter.toString();
    return result;
  }

  private buildTimeLineEmulator(processType: 'copy-paste' | 'change-list', event?: Event) {
    if (processType === 'copy-paste') {
      if (this.contentService.clipboard.isCutType) {
        this._srcListContent = this._srcListContent.filter(s => !this._emListContent.find(o => o.id === s.id));
      }
      this._emListContent.forEach(s => this._srcListContent.push(s));
    }
    this.refreshEmulatorTimeline(this.timeLineService.timelineEvent, this.currentUser, this.isPresenter);
    const round5Min = this.timeLineService.eventInstantSettings.manageTimeRound5Min;
    this.manageTimeService.calcTestEventMangeTimeMap(this._planeFullListContent, this._planeFullListContentAsSortedArray,
      !event ? this.timeLineService.timelineEvent : event, this._rootSection.id, round5Min);
    if (this.contentService.clipboard.isCopyType) {
      for (const s of this._emListContent) {
        const spl = this._planeFullListContent[s.id];
        if (spl) {
          this._testCopyList.push(spl);
        }
      }
    }
    return true;
  }

  refreshEmulatorTimeline(event: Event, currentUser, isPresenter, customList?: SectionContent[]) {
    const vm = this;
    const list = !customList ? this._srcListContent : customList.map(o => cloneDeep(o));
    this._rootSection = null;
    this._childTreeInLineListCache = {};
    if (list) {
      this._planeListContent = {};
      this._planeFullListContent = {};
      let contents = [];
      const srcContents = [];
      if (list.length > 0) {
        for (const item of list) {
          contents.push(new SectionTimeline(item));
          srcContents.push(new SectionTimeline(item));
          if (item.isRoot) {
            this._rootSection = new SectionTimeline(item);
          }
        }
        // Fill not filtered planeFullListContent
        srcContents.forEach(function (content) {
          vm.timeLineService.linkContentToParent(content, srcContents);
        });

        srcContents.forEach(function (content) {
          vm.timeLineService.linkFullPlaneContent(content, srcContents, vm._planeFullListContent);
        });
        // --------------------------------------

        contents = this.timeLineService.filterContents(contents, currentUser, isPresenter,
          this._planeFullListContent, this._availableSectionIdList);

        contents.forEach(function (content) {
          vm.timeLineService.linkContentToParent(content, contents);
        });

        contents.forEach(function (content) {
          vm.timeLineService.linkPlaneContent(content, contents, vm._planeListContent);
        });
      }
      this._planeFullListContentAsSortedArray = this.utils.objectValues(this._planeFullListContent)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      this._planeListContentSortedWithoutFixed = this._planeFullListContentAsSortedArray
        .filter(obj => !obj.sectionContent.fixedSectionType);
    }
  }

  runChangeEmulator(emulateResultWithout: EMULATE_RESULT, checkList: PlaneContent[], event: Event, startEndSections?: SectionContent[]) {
    this._emulateResultWithout = emulateResultWithout;
    this._srcListContent = this.timeLineService.sections
      .filter(s => !s.fixedSectionType).map(obj => cloneDeep(obj));
    if (!isEmpty(startEndSections)) {
      for (const s of startEndSections) {
        const ind = this._srcListContent.findIndex(o => o.id === s.id);
        if (ind > -1) {
          this._srcListContent[ind] = s;
        }
      }
    }
    for (const sObj of checkList) {
      const clearTimeObject = this.contentService.getPasteClearTimeLineObject(this._emulateResultWithout, sObj.sectionContent);
      const injectSection = merge(sObj.sectionContent, clearTimeObject);
      const index = this._srcListContent.findIndex(s => s.id === injectSection.id);
      if (index > -1) {
        this._srcListContent[index] = injectSection;
      }
    }
    this.buildTimeLineEmulator('change-list', event);
  }

  simpleEmulation() {
    const round5Min = this.timeLineService.eventInstantSettings.manageTimeRound5Min;
    this.manageTimeService.calcTestEventMangeTimeMap(this._planeFullListContent, this._planeFullListContentAsSortedArray,
      this.timeLineService.timelineEvent, this._rootSection.id, round5Min);
  }

  private contentLevel(sectionId: string, displayMode = false) {
    let level = 0;
    const section = this._planeFullListContent[sectionId];
    if (section) {
      let parent = this._planeFullListContent[section.parentId];
      while (parent && parent.id !== this.timeLineService.rootSection.id) {
        level++;
        parent = this._planeFullListContent[parent.parentId];
      }
    }
    return level;
  }

  childTreeInLineList(sectionId: string): PlaneContent[] {
    const getContentIndex = (sId): number => {
      const sortedPlaneList = this._planeListContentSortedWithoutFixed;
      return sortedPlaneList.findIndex(elem => sId === elem['id']);
    };

    const getNextContentId = (sId): string => {
      const parent = this._planeFullListContent[sId] && this._planeFullListContent[sId].trueParentItem ?
        this._planeFullListContent[this._planeFullListContent[sId].trueParentItem.id] : null;
      if (!parent) {
        return null;
      }
      const childList = parent.items;
      const myIndex = childList.findIndex(elem => sId === elem.id);
      if (parent.items.length === 1 || myIndex === parent.items.length - 1) {
        return sId;
      } else {
        return parent.items[myIndex + 1].id;
      }
    };

    const tree = (sId: string): PlaneContent[] => {
      const ret: any[] = [];
      const level = this.contentLevel(sId);
      const myIndex = getContentIndex(sId);
      if (myIndex < 0) {
        return ret;
      }
      const nextId = getNextContentId(sId);
      const sortedPlaneList = this._planeListContentSortedWithoutFixed;
      for (let i = myIndex; i < sortedPlaneList.length; i++) {
        if (i === myIndex ||
          (this.contentLevel(sortedPlaneList[i].id) > level && sortedPlaneList[i].id !== nextId)) {
          ret.push(sortedPlaneList[i]);
        } else {
          break;
        }
      }
      return ret;
    };
    if (this._childTreeInLineListCache[sectionId]) {
      return this._childTreeInLineListCache[sectionId];
    }
    const line = tree(sectionId);
    this._childTreeInLineListCache[sectionId] = line;
    return line;
  }

  getSectionHierarchicalParents(section: SectionContent): string[] {
    const parentsList = [];
    if (section && section.parentId) {
      const planeListContents = this._planeFullListContent;
      let curSection = planeListContents[section.getId()]?.sectionContent;
      while (curSection && curSection.parentId) {
        parentsList.push(curSection.parentId);
        curSection = curSection.parentId && planeListContents[curSection.parentId] ?
          planeListContents[curSection.parentId].sectionContent : null;
      }
    }
    return parentsList;
  }

}
