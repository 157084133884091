import {debounceTime, filter, map, of, switchMap, tap} from 'rxjs';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CommonService} from '../core/common.service';
import {ActionTypes} from '../actions/data';

@Injectable()
export class DataEffects {

  firestoreServerFail$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FIRESTORE_SERVER_FAIL),
    debounceTime(300),
    map(action => <any>action),
    filter(action => action.error),
    tap((action) => {
      this.common.showProgress.next(false);
    })
  ), {dispatch: false});

  backendServerFail$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.BACKEND_SERVER_FAIL),
    debounceTime(300),
    map(action => <any>action),
    filter(action => action.error),
    map((action: any) => action.payload),
    tap(action => {
      this.common.showProgress.next(false);
      this.common.showError(action);
    })
  ));

  constructor(private actions$: Actions,
              private common: CommonService) {
  }

}
