import {Injectable} from '@angular/core';
import {ClientApiService} from './client-api.service';
import {ClientConfig} from '../model/ClientConfig';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  constructor(
    protected clientApi: ClientApiService
  ) { }

  createClient(client: ClientConfig): Promise<string> {
    return this.clientApi.createClient(client);
  }

  updateClient(client: ClientConfig): Promise<any> {
    return this.clientApi.updateClient(client);
  }

  getClientsDto(): Promise<ClientConfig[]> {
    return this.clientApi.getClientsDto();
  }

  getAllowedClientsDto(): Promise<ClientConfig[]> {
    return this.clientApi.getAllowedClientsDto();
  }

  getClients(): Observable<ClientConfig[]> {
    return this.clientApi.getClients();
  }

  getClientPromise(client_id: string): Promise<ClientConfig> {
    return this.clientApi.getClientPromise(client_id);
  }

  checkAvailabilityLink(link: string): Promise<string> {
    return this.clientApi.checkAvailabilityLink(link);
  }
}
