// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.label {
  text-align: start;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: 600;
}

mat-form-field[formInput] {
  border-color: #dee2e6;
  border-radius: 16px;
  transition: 0.2s ease-in-out;
  font-size: 12px;
}
mat-form-field[formInput].errors {
  border-color: #ef4444;
}
mat-form-field[formInput] input {
  font-size: 14px;
}
mat-form-field[formInput] .mat-prefix {
  color: #adb5bd;
}
mat-form-field[formInput].reduced-size {
  font-size: 10px;
  padding: 2px 5px 4px 10px;
}
mat-form-field[formInput].reduced-size input {
  font-size: 12px;
}

mat-form-field[timeline].reduced-sizer .mdc-text-field.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-input-element {
  min-height: unset;
}

*::placeholder {
  font-size: 14px;
  color: #dee2e6;
  font-weight: 500;
}

.error-label {
  color: #ef4444;
  font-size: 14px;
  font-weight: 600;
  align-self: flex-start;
  margin-top: 6px;
  transition: 0.2 ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form-input/form-input.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;EACA,4BAAA;EACA,eAAA;AACF;AACE;EACE,qBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,eAAA;EACA,yBAAA;AAFJ;AAII;EACE,eAAA;AAFN;;AAUM;EACE,iBAAA;AAPR;;AAaA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAVF;;AAaA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,2BAAA;AAVF","sourcesContent":[":host {\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.label {\n  text-align: start;\n  font-size: 14px;\n  line-height: 16px;\n  margin-bottom: 4px;\n  font-weight: 600;\n}\n\nmat-form-field[formInput] {\n  border-color: #dee2e6;\n  border-radius: 16px;\n  transition: 0.2s ease-in-out;\n  font-size: 12px;\n\n  &.errors {\n    border-color: #ef4444;\n  }\n\n  input {\n    font-size: 14px;\n  }\n\n  .mat-prefix {\n    color: #adb5bd;\n  }\n\n  &.reduced-size {\n    font-size: 10px;\n    padding: 2px 5px 4px 10px;\n\n    input {\n      font-size: 12px;\n    }\n  }\n}\n\nmat-form-field[timeline] {\n  &.reduced-sizer {\n    .mdc-text-field.mat-mdc-text-field-wrapper {\n      .mat-mdc-form-field-infix .mat-mdc-input-element {\n        min-height: unset;\n      }\n    }\n  }\n}\n\n*::placeholder {\n  font-size: 14px;\n  color: #dee2e6;\n  font-weight: 500;\n}\n\n.error-label {\n  color: #ef4444;\n  font-size: 14px;\n  font-weight: 600;\n  align-self: flex-start;\n  margin-top: 6px;\n  transition: 0.2 ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
