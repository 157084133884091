import {ChangeDetectorRef, Component, Injector} from '@angular/core';
import {ILanguageParams} from '../../../../../../../core/constants';
import {BehaviorSubject} from 'rxjs';
import {AnswerEquality, AnswerRelation, EventQuestion, IGapAnswerData} from '../../../../../../../model/EventQuestion';
import {isEmpty, isEqual, uniqWith} from 'lodash';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';

@Component({
  selector: 'app-question-checkbox-gap-filling-editor',
  templateUrl: './question-checkbox-gap-filling-editor.component.html',
  styleUrls: ['./question-checkbox-gap-filling-editor.component.scss']
})
export class QuestionCheckboxGapFillingEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  gapFillingText$ = new BehaviorSubject<string>(null); // text for update text in groups
  gapFillingText: string; // text for default group

  constructor(protected injector: Injector,
              public changeDetector: ChangeDetectorRef) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.gapFillingText = this.question.getTaskTextByLanguage(this.languageParams);
    this.gapFillingText$.next(this.gapFillingText);
  }

  onGroupGapAnswersDataChange(value: IGapAnswerData[], groupId: string | 'default') {
    const group = this.question.groupsCorrectAnswers.find(g => groupId === 'default' ? g.defaultGroup : g.id === groupId);
    group.correctEquality = (value || []).map(o => new AnswerEquality({id: o.id, answerId: o.answerId, answerValue: o.answerValue}));
    this.question.relations = uniqWith(this.question.groupsCorrectAnswers.reduce((acc, g) => {
      acc.push(...g.correctEquality.map(ce => new AnswerRelation({id: ce.id, answerId: ce.answerId})));
      return acc;
    }, []), isEqual);
  }

  gapRowAnswerDisabled(rowId: string) {
    return !!(this.question.groupsCorrectAnswers || []).find(g => !!(g.correctEquality || []).find(o => o.answerId === rowId));
  }

  onGapFillingTextChange(value: string) {
    this.question.taskText = value;
    this.gapFillingText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  validate(): IValidated {
    return !isEmpty(this.dataSource.data) && !this.dataSource.data.some(o => isEmptyCaption(o.answer)) ? {validated: true} :
      {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }
}
