import {Component, Injector} from '@angular/core';
import {ILanguageParams, SMILES_RATING_SETS, SMILES_RATING_SETS_NAMES} from '../../../../../../../core/constants';
import {isEmpty} from 'lodash';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {IValidated} from '../../shared/quiz-quiestion-types';

@Component({
  selector: 'app-question-scale-editor',
  templateUrl: './question-scale-editor.component.html',
  styleUrls: ['./question-scale-editor.component.scss']
})
export class QuestionScaleEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {
  readonly SMILES_RATING_SETS = SMILES_RATING_SETS;

  smilesRatingSetsNames = [
    SMILES_RATING_SETS_NAMES.SET8, SMILES_RATING_SETS_NAMES.SET9,
    SMILES_RATING_SETS_NAMES.SET2, SMILES_RATING_SETS_NAMES.SET3,
    SMILES_RATING_SETS_NAMES.SET4, SMILES_RATING_SETS_NAMES.SET5,
    SMILES_RATING_SETS_NAMES.SET6, SMILES_RATING_SETS_NAMES.SET7,
    SMILES_RATING_SETS_NAMES.SET10, SMILES_RATING_SETS_NAMES.SET11,
    SMILES_RATING_SETS_NAMES.SET12, SMILES_RATING_SETS_NAMES.SET13,
    SMILES_RATING_SETS_NAMES.SET14, SMILES_RATING_SETS_NAMES.SET15,
    SMILES_RATING_SETS_NAMES.SET16, SMILES_RATING_SETS_NAMES.SET17,
    SMILES_RATING_SETS_NAMES.SET18, SMILES_RATING_SETS_NAMES.SET19,
    SMILES_RATING_SETS_NAMES.SET20, SMILES_RATING_SETS_NAMES.SET21,
    SMILES_RATING_SETS_NAMES.SET22, SMILES_RATING_SETS_NAMES.SET23,
    SMILES_RATING_SETS_NAMES.SET24, SMILES_RATING_SETS_NAMES.SET25,
    SMILES_RATING_SETS_NAMES.SET26, SMILES_RATING_SETS_NAMES.SET27,
    SMILES_RATING_SETS_NAMES.SET28, SMILES_RATING_SETS_NAMES.SET29
  ];

  constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    if (!this.question.options.smilesRatingSet) {
      this.question.options.smilesRatingSet = SMILES_RATING_SETS_NAMES.SET5;
    }
  }

  validate(): IValidated {
    return !!this.question.options.smilesRatingSet && !isEmpty(this.dataSource.data) &&
    !this.dataSource.data.some(o => isEmptyCaption(o.answer)) ?
      {validated: true} : {validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty')};
  }

}
