import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {BehaviorSubject} from 'rxjs';
import {AnswerEquality, IGapAnswerData} from '../../../../../../../model/EventQuestion';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';

@Component({
  selector: 'app-question-text-gap-filling-participant',
  templateUrl: './question-text-gap-filling-participant.component.html',
  styleUrls: ['./question-text-gap-filling-participant.component.scss']
})
export class QuestionTextGapFillingParticipantComponent  extends AbstractQuizQuestionParticipantComponent {

  gapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  correctGapAnswersData$ = new BehaviorSubject<IGapAnswerData[]>([]);
  gapTaskText$ = new BehaviorSubject<string>(null);

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
  }

  protected initQuestionAnswersDataSource() {
    this.gapAnswersData$
      .next((this.answers || []).map(obj => {
        const a = obj.split('=');
        return new Object({id: a[0], answerValue: a[1]}) as IGapAnswerData;
      }));
    const correctEquality = this.question.reduceGroupsCorrectAnswersCorrectEquality();
    this.correctGapAnswersData$.next(this.question.showCorrectAnswers ?
      correctEquality.map(v => new Object({id: v.id, answerId: v.answerId,
        answerValue: this.question.items.find(a => a.id === v.answerId)?.getAnswerByLanguage(this.languageParams)}) as IGapAnswerData)
      : []);
    this.gapTaskText$.next(this.question.getTaskTextByLanguage(this.languageParams));
  }

  protected onReceiveQuestionAnswers() {
    this.gapAnswersData$
      .next((this.answers || []).map(obj => {
        const a = obj.split('=');
        return new Object({id: a[0], answerValue: a[1]}) as IGapAnswerData;
      }));
  }

  changeInputTextGap(value: IGapAnswerData[]) {
    const newValue = (value || []).map(o => `${o.id}=${o.answerValue}`);
    const isChange = JSON.stringify(this.answers) !== JSON.stringify(newValue);
    this.answers = (value || []).map(o => `${o.id}=${o.answerValue}`);
    if (isChange) {
      this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
    }
  }

}
