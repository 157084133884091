import {FirestoreObject} from './FirestoreObject';

export abstract class FirestoreReference extends FirestoreObject {
  _key: string;

  constructor(obj: any) {
    super(obj);
    if (!obj) {
      return;
    }

    if (obj._key) {
      this._key = obj._key;
    }
  }

  toObject() {
    const result = super.toObject();
    delete result._key;
    return result;
  }
}
