// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videos-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 0 !important;
  padding: 56.25% 0 0 0;
  min-height: 0;
  border: 0;
}
.videos-wrapper.srf-video-player {
  overflow: hidden;
  border-radius: 16px;
}
.videos-wrapper.srf-video-player .videos-frame {
  transform: scale(1.07);
}
.videos-wrapper .videos-frame {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 16px;
}
.videos-wrapper .videos-frame.overlay {
  z-index: 999;
}

.stream-overlay {
  position: absolute;
  top: calc(50% - 26px);
  left: calc(50% - 78px);
  z-index: 9;
  font-size: 20px;
  padding: 10px 16px;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/content-container/components/video-stream/video-stream/video-stream.component.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,qBAAA;EACA,aAAA;EACA,SAAA;AAAF;AACE;EACE,gBAAA;EACA,mBAAA;AACJ;AAAI;EACE,sBAAA;AAEN;AACE;EACE,cAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAAI;EACE,YAAA;AAEN;;AAGA;EACE,kBAAA;EACA,qBAAA;EACA,sBAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;AAAF","sourcesContent":["\n.videos-wrapper {\n  display: block;\n  position: relative;\n  width: 100%;\n  height: 0 !important;\n  padding: 56.25% 0 0 0;\n  min-height: 0;\n  border: 0;\n  &.srf-video-player {\n    overflow: hidden;\n    border-radius: 16px;\n    .videos-frame {\n      transform: scale(1.07);\n    }\n  }\n  .videos-frame {\n    display: block;\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%;\n    border: 0;\n    border-radius: 16px;\n    &.overlay {\n      z-index: 999;\n    }\n  }\n}\n\n.stream-overlay {\n  position: absolute;\n  top: calc(50% - 26px);\n  left: calc(50% - 78px);\n  z-index: 9;\n  font-size: 20px;\n  padding: 10px 16px;\n  border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
