// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.label {
  text-align: start;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  font-weight: 600;
}

mat-form-field[formInput] {
  border-color: #DEE2E6;
  border-radius: 16px;
  transition: 0.2s ease-in-out;
}
mat-form-field[formInput].errors {
  border-color: #EF4444;
}
mat-form-field[formInput] input {
  font-size: 14px;
}

*::placeholder {
  font-size: 14px;
  color: #DEE2E6;
  font-weight: 500;
}

.error-label {
  color: #EF4444;
  font-size: 14px;
  font-weight: 600;
  align-self: flex-start;
  margin-top: 6px;
  transition: 0.2 ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form-input/form-input.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;EACA,4BAAA;AACF;AACE;EACE,qBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AADF;;AAIA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,eAAA;EACA,2BAAA;AADF","sourcesContent":[":host {\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.label {\n  text-align: start;\n  font-size: 14px;\n  line-height: 16px;\n  margin-bottom: 4px;\n  font-weight: 600;\n}\n\nmat-form-field[formInput] {\n  border-color: #DEE2E6;\n  border-radius: 16px;\n  transition: 0.2s ease-in-out;\n\n  &.errors {\n    border-color: #EF4444;\n  }\n\n  input {\n    font-size: 14px;\n  }\n}\n\n*::placeholder{\n  font-size: 14px;\n  color: #DEE2E6;\n  font-weight: 500;\n}\n\n.error-label {\n  color: #EF4444;\n  font-size: 14px;\n  font-weight: 600;\n  align-self: flex-start;\n  margin-top: 6px;\n  transition: .2 ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
