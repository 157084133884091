import {Component, ElementRef, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {TimeLineService} from '../../../../../services/time-line.service';
import * as ui from '../../../../../actions/ui';
import {StdComponent} from '../../../../../core/std-component';
import {Quiz} from '../quiz-model/quiz';
import {Constants, ILanguageParams, TEMPLATE_TYPE} from '../../../../../core/constants';
import {QuizService} from '../quiz.service';
import {BehaviorSubject, filter, take} from 'rxjs';
import {isEmpty} from 'lodash';
import {IExtQuizTemplate} from '../quiz-components/shared/quiz-quiestion-types';
import {CommonService} from '../../../../../core/common.service';
import {IDocumentPathParams} from '../../../../../services/event-mode-api.service';

@Component({
  selector: 'app-quiz-templates-list-dialog',
  templateUrl: './quiz-templates-list-dialog.component.html',
  styleUrl: './quiz-templates-list-dialog.component.scss'
})
export class QuizTemplatesListDialogComponent extends StdComponent implements OnInit {

  readonly Constants = Constants;
  readonly TEMPLATE_TYPE = TEMPLATE_TYPE;

  currentTemplateType: TEMPLATE_TYPE = TEMPLATE_TYPE.PUBLISHED_COMPANY;
  currentTemplate: IExtQuizTemplate;
  languageParams: ILanguageParams;
  documentPathParams: IDocumentPathParams;
  loaded$ = new BehaviorSubject<boolean>(false);
  progress = false;
  searchList: IExtQuizTemplate[] = null;
  templateMenuAccess = false;

  templatesLists: {[type: string]: IExtQuizTemplate[]} = Object.values(TEMPLATE_TYPE)
    .reduce((acc, type) => {
      acc[type] = [];
      return acc;
    }, {});

  constructor(protected injector: Injector,
              private dialogRef: MatDialogRef<QuizTemplatesListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private  store: Store<fromRoot.State>,
              private timeLineService: TimeLineService,
              private common: CommonService,
              private quizService: QuizService,
              private elementRef: ElementRef) {
    super(injector);
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    dialogRef.addPanelClass('timeline');
    dialogRef.updateSize('700px', '700px');
    this.documentPathParams = this.data.documentPathParams;
    this.setTemplateMenuAccess();
    this.data.languageParams$
      .pipe(filter(p => !isEmpty(p)), take(1))
      .subscribe(params => {
        this.languageParams = params;
        this.loaded$.next(true);
      });
  }

  ngOnInit(): void {
    this.loaded$.pipe(filter(v => !!v), take(1), this.takeUntilAlive())
      .subscribe(() => {
        this.init();
      });
  }

  onDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  init() {
    this.quizService.loadTemplatesList(TEMPLATE_TYPE.USER_TEMPLATE, this.languageParams).pipe(this.takeUntilAlive())
      .subscribe(list => {
        this.templatesLists[TEMPLATE_TYPE.USER_TEMPLATE] = list ?? [];
        this.setListRowCount();
      });
    this.quizService.loadTemplatesList(TEMPLATE_TYPE.PUBLISHED_COMPANY, this.languageParams).pipe(this.takeUntilAlive())
      .subscribe(list => {
        this.templatesLists[TEMPLATE_TYPE.PUBLISHED_COMPANY] = list ?? [];
        this.setListRowCount();
      });
  }

  addNewQuiz() {
    this.dialogRef.close({quiz: new Quiz()});
  }

  private setListRowCount() {
    this.elementRef.nativeElement.style.setProperty('--templates-list-count',
      String(Math.round((this.searchList ?? this.templatesLists[this.currentTemplateType]).length / 2)));
  }

  onSwitchTemplateType() {
    this.setListRowCount();
    this.setTemplateMenuAccess();
  }

  searchFilter(value: string) {
    if (!value) {
      this.searchList = null;
    } else {
      this.searchList = this.templatesLists[this.currentTemplateType]
        .filter(t => t.findAnnotation.toLowerCase().includes(value.toLowerCase()));
    }
    this.setListRowCount();
  }

  async deleteTemplate() {
    if (await this.common.confirmation('confirm.delete.template')) {
      this.progress = true;
      this.quizService.deleteTemplate(this.currentTemplate.id, this.currentTemplateType, this.currentTemplate.template)
        .then(() => this.common.showPopupSuccess(this.common.i18n('common.dialog.success')))
        .finally(() => this.progress = false);
    }
  }

  async createFromTemplate() {
    this.progress = true;
    const quiz = await this.quizService.createQuizFromTemplate(this.documentPathParams.eventId,
      this.currentTemplate.id, this.currentTemplateType, this.currentTemplate.template)
      .finally(() => this.progress = false);
    this.dialogRef.close({quiz});
  }

  async editTemplate() {
    this.progress = true;
    const quiz = await this.quizService.createQuizFromTemplate(this.documentPathParams.eventId,
      this.currentTemplate.id, this.currentTemplateType, this.currentTemplate.template)
      .finally(() => this.progress = false);
    this.dialogRef.close({quiz,
      template: Object.keys(this.currentTemplate).reduce((acc, key) => {
        if (key !== 'template' && key !== 'findAnnotation' ) {
          acc[key] = this.currentTemplate[key];
        }
        return acc;
      }, {})
    });
  }

  setTemplateMenuAccess() {
    switch (this.currentTemplateType) {
      case TEMPLATE_TYPE.PUBLISHED_COMPANY:
        this.templateMenuAccess = this.timeLineService.currentUser.isSuperAdmin ||
          this.timeLineService.currentUser.isModerator || this.timeLineService.currentUser.isAdmin;
        break;
      case TEMPLATE_TYPE.USER_TEMPLATE:
        this.templateMenuAccess = true;
        break;
    }
  }
}
